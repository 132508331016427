import React, { useState } from 'react';
import PropTypes from 'prop-types';
// import { useSelector } from 'react-redux';
import {
  Col,
  Row,
  // Col,
  Spin,
} from 'antd';
import TablaCausas from './TablaCausas';
import TablaMedios from './TablaMedios';
import TablaEfectos from './TablaEfectos';
import TablaFines from './TablaFines';

function ArbolDeProblemas({
  fatherId,
}) {
  const [loading] = useState(false);

  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        <Row gutter={10}>
          <Col span={12}>
            <TablaCausas fatherId={fatherId} />
          </Col>
          <Col span={12}>
            <TablaMedios fatherId={fatherId} />
          </Col>
          <Col span={12}>
            <TablaEfectos fatherId={fatherId} />
          </Col>
          <Col span={12}>
            <TablaFines fatherId={fatherId} />
          </Col>
        </Row>

      </Spin>
    </Row>
  );
}

ArbolDeProblemas.propTypes = {
  fatherId: PropTypes.arrayOf(PropTypes.number),

};

ArbolDeProblemas.defaultProps = {
  fatherId: [],
  // callback: () => { },
};
export default ArbolDeProblemas;
