/* eslint-disable react/jsx-filename-extension */
// import TiposDeDocumentosParaTramite from 'components/Catalogos/TiposDeDocumentosParaTramite';
import React from 'react';
import { FaToolbox } from 'react-icons/fa';

export default {
  title: 'Utilerías',
  icon: () => <FaToolbox />,
  showInSidebar: true,
  type: 'private',
  children: [
    {
      title: 'Tipos De Documento Para Tramite',
      icon: null,
      showInSidebar: true,
      // component: TiposDeDocumentosParaTramite,
      component: null,
      type: 'private',
    },
  ],
};
