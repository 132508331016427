import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Form,
  Button,
  Row,
  Col,
  Spin,
  Input,
  Card,
  message,
  Modal,
} from 'antd';

import Table from 'components/Table';
import FormSubmitControls from 'components/FormSubmitControls';
import API from 'utils/api';
import { onError, onSuccess } from 'utils/handlers';
import Select from 'components/Select';
import { formatReceived } from 'utils/formatValues';
import ModalDelete from 'components/ModalDelete';
import { InputSearchPro } from 'components/InputSearch';
import BusquedaClavesContables from 'components/BusquedaClavesContables';
import { SET_CONFIG_MASCARA_CONTABLE } from 'store/reducers/catalogs';
import fetchSome from 'utils/fetchSome';

function Detalle({
  mutable,
  idConfiguracion,
  setDetalles,
  modulo,
  documento,
  loading,
  setLoading,
}) {
  const baseURI = 'configuraciones/configuraciones-de-polizas/:id/cabecera/detalles/'
    .replace(':id', idConfiguracion);
  const configMascaraContable = useSelector(({ catalogs }) => catalogs.configMascaraContable);
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);
  const [isVariable, setIsVariable] = useState(false);

  const [importes, setImportes] = useState([]);

  const [cuentaContableFound, setCuentaContableFound] = useState([]);
  const [visibleBusquedaCuentas, setVisibleBusquedaCuentas] = useState(false);
  const [selectedClave, setSelectedClave] = useState();
  const [mascara, setMascara] = useState();
  const [separador, setSeparador] = useState();
  const [pattern, setPattern] = useState();
  const tipoConfig = [{ id: true, descripcion: 'Cargos' }, { id: false, descripcion: 'Abonos' }];

  const fetchData = async () => {
    try {
      setLoading(true);
      if (idConfiguracion !== 0) {
        const response = await API.get(baseURI);
        setData(formatReceived(response.data) || []);
        setDetalles(response.data);
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const setConfigsMask = (config) => {
    setMascara(config?.mascara);
    const separator = config?.separador;
    setSeparador(separator);
    const _segmentos = config?.mascara?.split(separator) || [];
    if (_segmentos?.length) {
      // eslint-disable-next-line prefer-template
      const patt = _segmentos.reduce((acum, curr, idx) => `${acum}[0-9]{${curr.length}}`
        + (idx !== _segmentos.length - 1 ? separator : '$'), '^');
      const regExp = new RegExp(`${patt}`);
      setPattern(regExp);
    }
  };

  const fetchAll = async () => {
    try {
      setLoading(true);
      if (configMascaraContable) {
        setConfigsMask(configMascaraContable);
      } else {
        await fetchSome('configuraciones/configuraciones-de-mascaras/', SET_CONFIG_MASCARA_CONTABLE);
        const resConfig = await API.get('configuraciones/configuraciones-de-mascaras/');
        setConfigsMask(resConfig.data[0]);
      }

      const resImportes = await API.get('configuraciones/variables-de-polizas/', {
        params: {
          es_para_detalle: true,
          documento,
          modulo,
        },
      });
      setImportes(resImportes.data);

      await fetchData();
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchAll();

    return () => API.source.cancel;
    // eslint-disable-next-line
  }, [idConfiguracion]);

  const onCancel = () => {
    setVisible(false);
    setSelectedRowKeys([]);
    form.resetFields();
    setIsVariable(false);
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      if (!values.es_cuenta_variable) {
        const claveMatch = cuentaContableFound.find((e) => e.id === values.clave_contable);
        values.cuenta = claveMatch.clave;
      }

      if (!selectedRowKeys.length) {
        const response = await API.post(baseURI, values);
        if (response?.status === 201) {
          onSuccess(response.data, 'Agregado correctamente');
          onCancel();
          await fetchData();
        }
      } else {
        const [key] = selectedRowKeys;
        const response = await API.put(`${baseURI}${key}/`, values);
        if (response?.status === 200) {
          onSuccess(response, 'Actualizado correctamente');
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    setIsVariable(record.es_cuenta_variable);
    form.setFieldsValue({
      ...formatReceived(record),
    });
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const columns = [
    {
      titleText: 'Nombre',
      dataIndex: 'nombre',
      key: 'nombre',
      width: 150,
    },
    {
      titleText: 'Cuenta',
      dataIndex: 'cuenta',
      key: 'cuenta',
      width: 250,
    },
    {
      titleText: '¿Para qué es?',
      dataIndex: 'es_cargo',
      key: 'es_cargo',
      width: 200,
      render: (e) => (e ? 'Cargo' : 'Abono'),
    },
    {
      titleText: 'Importe',
      dataIndex: 'importe',
      key: 'importe',
      width: 300,
      render: (e) => importes.find((i) => i.id === e)?.descripcion,
    },
    {
      titleText: 'Referencia',
      dataIndex: 'referencia',
      key: 'referencia',
      width: 150,
    },
  ];

  const resetAndValidateCuentaContable = () => {
    setCuentaContableFound([]);
    form.resetFields(['clave_contable']);
    form.validateFields(['clave_contable']);
  };

  const onClickAdd = () => {
    onCancel();
    resetAndValidateCuentaContable();
    setVisible(true);
  };

  const onClickEdit = async () => {
    try {
      setLoading(true);
      const [key] = selectedRowKeys;
      const clone = [...data];
      let values = clone.find((e) => e.id === key);
      if (!values.es_cuenta_variable) {
        const resCuenta = await API.get('contabilidad/cuentas-contables/', {
          params: {
            clave: values.cuenta,
          },
        });
        setCuentaContableFound(resCuenta.data);
        values = {
          ...values,
          clave_contable: resCuenta.data[0].id,
        };
      }
      setTimeout(() => form.setFieldsValue(values));
      setVisible(true);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onClickDelete = () => {
    if (mutable) {
      setVisibleAlert(true);
    } else {
      message.warn('No se puede eliminar un registro autorizado o cancelado');
      setSelectedRowKeys([]);
      setVisibleAlert(false);
    }
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`${baseURI}${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          setVisibleAlert(false);
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const rules = {
    required: [
      {
        message: 'El campo es requerido',
        required: true,
      },
    ],
    clave_contable__clave: [
      {
        validator: async (rule, value) => {
          if (value && pattern && (!pattern.test(value))) {
            throw new Error('Introduzca una clave válida');
          }
        },
      },
    ],
  };

  const fetchCuentaContable = async () => {
    try {
      form.validateFields(['clave_contable__clave']);
      const clave = form.getFieldValue('clave_contable__clave');
      if (clave) {
        setLoading(true);
        const res = await API.get('contabilidad/cuentas-contables/', {
          params: {
            clave,
            tipo: 7,
            conac: false,
          },
        });
        if (res?.status === 200 && res.data?.length) {
          const claveContable = res.data[0];
          setCuentaContableFound([claveContable]);
          form.setFieldsValue({
            clave_contable: claveContable.id,
          });
          setTimeout(() => {
            form.validateFields(['clave_contable']);
          });
        } else {
          message.info('No se encontraron coincidencias');
          setCuentaContableFound([]);
        }
        setLoading(false);
      }
    } catch (err) {
      onError(err, setLoading);
      setCuentaContableFound([]);
    }
  };

  const onFinishBusqueda = () => {
    if (selectedClave) {
      setCuentaContableFound([selectedClave]);
      setVisibleBusquedaCuentas(false);
    } else {
      message.info('No ha seleccionado una Cuenta Contable');
    }
  };

  const onChangeClaveContable = (e) => {
    const { target: { value }, keyCode } = e;
    let val = value;
    if (keyCode) {
      const char = String.fromCharCode(keyCode).toLowerCase();
      val += char;
    }
    if (mascara.charAt(val.length) === separador) {
      val += separador;
    }
    if (val.length === mascara?.length) {
      setTimeout(fetchCuentaContable);
    } else if (!val?.length) {
      resetAndValidateCuentaContable();
    }
    setTimeout(() => {
      form.setFieldsValue({ clave_contable__clave: val });
    });
  };

  return (
    <Row align="center" justify="center" className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible ? (
          <Table
            cols={columns}
            data={data}
            rowSelection={rowSelection}
            rowKey="id"
            mobileColIndex={0}
            handleOnRowClick={handleOnRowClick}
            controls={{
              onClickAdd,
              onClickEdit,
              onClickDelete,
            }}
            disabled={!mutable}
          />
        ) : (
          <Col span={24} style={{ height: '100%' }}>
            <Card
              className="form-container"
              title={(
                <FormSubmitControls
                  label={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Detalle`}
                  onCancel={onCancel}
                  onFinish={onFinish}
                  selectedRowKeys={selectedRowKeys}
                  loading={loading}
                  mutable={mutable}
                />
              )}
              bordered={false}
            >
              <Form
                layout="vertical"
                onFinish={onFinish}
                form={form}
                scrollToFirstError
                initialValues={{
                  estados_globales: 1,
                  es_cuenta_variable: false,
                  es_cargo: false,
                }}
              >
                <Row gutter={10}>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="es_cuenta_variable"
                      label="¿La cuenta es una variable?"
                    >
                      <Select
                        trueFalse
                        disabled={!mutable}
                        onChange={(val) => {
                          setIsVariable(val);
                          setCuentaContableFound([]);
                          form.resetFields(['cuenta']);
                        }}
                      />
                    </Form.Item>
                  </Col>
                  {isVariable ? (
                    <Col xs={24} sm={24} md={16}>
                      <Form.Item
                        name="cuenta"
                        rules={rules.required}
                        label="Cuenta"
                      >
                        <Input disabled={!mutable} allowClear />
                      </Form.Item>
                    </Col>
                  ) : (
                    <InputSearchPro
                      name="clave_contable__clave"
                      onClickAdvanced={() => setVisibleBusquedaCuentas(true)}
                      rules={rules.clave_contable__clave}
                      label="Búsqueda Cuenta Contable"
                      tooltip={`Debe concordar con la mascara ${mascara}`}
                      inputProps={{
                        onPressEnter: () => fetchCuentaContable(),
                        onChange: onChangeClaveContable,
                        maxLength: mascara?.length,
                      }}
                      breakPoints={{
                        xs: 24,
                        sm: 24,
                        md: 16,
                      }}
                      resultProps={{
                        name: 'clave_contable',
                        label: 'Cuenta Contable',
                        rules: rules.required,
                        dataSource: cuentaContableFound,
                        keyLabelRender: true,
                        keyProp: 'clave',
                        labelProp: 'nombre',
                        onClear: resetAndValidateCuentaContable,
                        breakPoints: {
                          md: 16,
                        },
                      }}
                    />
                  )}
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="nombre"
                      rules={rules.required}
                      label="Nombre"
                    >
                      <Input disabled={!mutable} allowClear />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="referencia"
                      rules={rules.required}
                      label="Referencia"
                    >
                      <Input disabled={!mutable} allowClear />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="es_cargo"
                      rules={rules.required}
                      label="¿Para qué és?"
                    >
                      <Select
                        dataSource={tipoConfig}
                        disabled={!mutable}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="importe"
                      rules={rules.required}
                      label="Importe"
                    >
                      <Select
                        dataSource={importes.filter((e) => {
                          if (!isVariable && documento === 1) {
                            return e.id === 1;
                          } if (!isVariable && documento === 2) {
                            return e.id === 4;
                          }
                          if (!isVariable && documento === 3) {
                            return e.id === 5;
                          }
                          return e;
                        })}
                        disabled={!mutable}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item hidden>
                  <Button htmlType="submit" />
                </Form.Item>
              </Form>
            </Card>
          </Col>
        )}
        <Modal
          visible={visibleBusquedaCuentas}
          title={(
            <Row justify="space-between">
              Búsqueda Avanzada Cuentas
              <FormSubmitControls
                onCancel={() => setVisibleBusquedaCuentas(false)}
                onFinish={onFinishBusqueda}
              />
            </Row>
          )}
          onCancel={() => setVisibleBusquedaCuentas(false)}
          closable={false}
          footer={null}
          width={800}
          forceRender
          centered
        >
          <BusquedaClavesContables callback={setSelectedClave} />
        </Modal>
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => setVisibleAlert(false)}
          visible={visibleAlert}
          content="La configuración de detalle seleccionada"
          loading={loading}
        />
      </Spin>
    </Row>
  );
}

Detalle.propTypes = {
  mutable: PropTypes.bool.isRequired,
  setDetalles: PropTypes.func.isRequired,
  idConfiguracion: PropTypes.number,
  modulo: PropTypes.number.isRequired,
  documento: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
  // variables: PropTypes.arrayOf(PropTypes.object).isRequired,
};

Detalle.defaultProps = {
  idConfiguracion: 0,
};

export default Detalle;
