import React from 'react';
import SingleForm from 'components/SingleForm';
import { getNaturalezasDeIngresos } from 'api/catalogos';

// eslint-disable-next-line react/prop-types
function TiposDeOperacion({ permission }) {
  return (
    <SingleForm
      title="Tipo de operación"
      withoutState
      readOnly
      permission={permission}
      get={getNaturalezasDeIngresos}
    />
  );
}
export default TiposDeOperacion;
