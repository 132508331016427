import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Spin,
  Card,
  DatePicker,
  message,
  Tabs,
} from 'antd';
import { useSelector } from 'react-redux';
import {
  DownOutlined,
  UpOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';

import Moment from 'moment';
import getFormattedValues from 'utils/formatValues';
import {
  defaultOptions,
  allRequirementsPassed,
  validatePasswordRequirements,
} from 'utils/passwordRequirements';
import Table from 'components/Table';
import Password from 'components/Password';
import API from 'utils/api';
import FormSubmitControls from 'components/FormSubmitControls';
import LadaNumero from 'components/LadaNumero';
import ModalDelete from 'components/ModalDelete';
import { onError, onSuccess } from 'utils/handlers';
import AvatarUploader from 'components/AvatarUploader';
import Uploader from 'components/Uploader';
import PlainTransfer from 'components/PlainTransfer';
import Select from 'components/Select';
// import { permissionbypass } from 'utils/permission';

export const permissionUsuarios = {
  permissionModel: 'usuario',
};

const { TextArea } = Input;
const { TabPane } = Tabs;

// eslint-disable-next-line react/prop-types
function Usuarios({ permission }) {
  const user = useSelector(({ auth }) => auth.user);
  const [form] = Form.useForm();
  const [formPersonales] = Form.useForm();
  const [formRoles] = Form.useForm();
  const [formPermisos] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visiblePwd, setVisiblePwd] = useState(false);
  const [empleados, setEmpleados] = useState([]);
  const [funciones, setFunciones] = useState([]);
  const [justificaciones, setJustificaciones] = useState([]);
  const [isExterno, setIsExterno] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);
  const [options, setOptions] = useState(defaultOptions);
  const [permisos, setPermisos] = useState([]);
  const [roles, setRoles] = useState([]);
  const [targetKeysRoles, setTargetKeysRoles] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [fileFoto, setFileFoto] = useState([]);
  // const [treeData, setTreeData] = useState([]);
  const [contentTypes, setContentTypes] = useState([]);
  const [currentTabKey, setCurrentTabKey] = useState('general');

  // const normalizeData = (_data) => {
  //   if (_data) {
  //     const output = _data.map((item) => {
  //       const clone = { ...item };
  //       const unidades_responsables = normalizeData(clone.unidades_responsables);
  //       const centros_de_costos = normalizeData(clone.centros_de_costos);
  //       if (!unidades_responsables.length && !centros_de_costos.length) {
  //         delete clone.unidades_responsables;
  //         delete clone.centros_de_costos;
  //         return ({
  //           title: clone.descripcion,
  //           value: `${clone.id}/${clone.nivel}`,
  //         });
  //       }
  //       if (unidades_responsables) {
  //         return ({
  //           title: clone.descripcion,
  //           value: `${clone.id}/${clone.nivel}`,
  //           children: unidades_responsables,
  //           disabled: true,
  //         });
  //       }
  //       return ({
  //         title: clone.descripcion,
  //         value: `${clone.id}/${clone.nivel}`,
  //         children: centros_de_costos,
  //         disabled: true,
  //       });
  //     });
  //     return output;
  //   }
  //   return false;
  // };

  const fetchData = async () => {
    try {
      const res = await API.get('usuarios/usuarios/');
      setData(res.data);

      const resEmpleados = await API.get('estructura-organizacional/empleados/');

      const usersWithEmpleado = res.data.filter((u) => !u.es_externo && u.empleado)
        .map((u) => u.empleado);

      const empleadosWithFlag = resEmpleados.data.map((e) => ({
        ...e,
        nombre_completo: `${e.correo_electronico} - ${e.nombre} ${e.apellido_paterno} ${e.apellido_materno || ''} `,
        hasUser: usersWithEmpleado.includes(e.id),
      }));

      setEmpleados(empleadosWithFlag
        .sort((a, b) => a.hasUser.toString().localeCompare(b.hasUser)));
    } catch (err) {
      onError(err);
    }
  };

  const fetchAll = async () => {
    try {
      setLoading(true);
      const resPermisos = await API.get('usuarios/permisos/');
      setPermisos(resPermisos.data);

      const resRoles = await API.get('estructura-organizacional/grupos/');
      const resRolesData = resRoles.data.map((item) => ({
        ...item,
        name: item.name.split(' - ').shift(),
      }));
      setRoles(resRolesData);

      const resFunciones = await API.get('usuarios/funciones/');
      setFunciones(resFunciones.data);

      const resJustificaciones = await API.get('usuarios/justificaciones-de-usuario-externo');
      setJustificaciones(resJustificaciones.data);

      // const resUR = await API.get('estructura-organizacional/unidades-de-trabajo');
      // const formattedUr = normalizeData(resUR.data);
      // // setTreeData(formattedUr);

      const resContentTypes = await API.get('catalogos/content-types-ur');
      setContentTypes(resContentTypes.data);

      await fetchData();
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchAll();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setOptions(defaultOptions);
    setVisible(false);
    form.resetFields();
    formPersonales.resetFields();
    formPermisos.resetFields();
    formRoles.resetFields();
    setSelectedRowKeys([]);
    setVisiblePwd(false);
    setIsExterno();
    setFileList([]);
    setFileFoto([]);
    setCurrentTabKey('general');
    setTargetKeysRoles([]);
  };
  const setRolesPermisos = async (key) => {
    try {
      const permisosUsuario = form.getFieldValue('permisos').filter((k) => typeof k === 'number');
      const rolesUsuario = targetKeysRoles.filter((k) => typeof k === 'number');
      const resRoles = await API.put(`/usuarios/agregar-roles/${key}/`, {
        groups: rolesUsuario,
      });
      if (resRoles && resRoles.status === 200) {
        const resPermisos = await API.put(`/usuarios/agregar-permisos/${key}/`, {
          user_permissions: permisosUsuario,
        });
        if (resPermisos && resPermisos.status === 200) {
          // message.info('Roles y Permisos asignados correctamente');
          onCancel();
          fetchData();
        }
      }
    } catch (err) {
      onError(err);
    }
  };

  const getURValuesToSend = () => {
    const unidad_responsable = form.getFieldValue('unidad_responsable');
    if (unidad_responsable) {
      const [object_id, nivel] = unidad_responsable.split('/');
      const content_type = contentTypes.find((ct) => ct.nivel.toString() === nivel).id;
      return { object_id, content_type };
    }
    return { object_id: undefined, content_type: undefined };
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = getFormattedValues(form.getFieldsValue());
      const dataValues = isExterno
        ? {
          ...values,
          is_active: values.is_active,
          ...getURValuesToSend(),
        } : {
          ...values,
          es_externo: values.es_externo,
          is_active: values.is_active,
          ...getURValuesToSend(),
        };
      if (values.empleado && !selectedRowKeys.length) {
        const selectedEmpleado = empleados.find((e) => e.id === values.empleado);
        dataValues.first_name = selectedEmpleado.nombre;
        dataValues.last_name = selectedEmpleado.apellido_paterno;
        dataValues.second_last_name = selectedEmpleado.apellido_materno;
      }
      const formData = new FormData();
      if (fileFoto) {
        formData.append('foto', fileFoto);
      }
      if (fileList.length) {
        formData.append('firma_electronica', fileList[0]);
      }
      const keys = Object.keys(dataValues);
      keys.forEach((key) => {
        if (dataValues[key]) {
          formData.append(`${key}`, dataValues[key]);
        }
      });
      if (!selectedRowKeys.length) {
        const response = await API.post('usuarios/usuarios/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        if (response && response.status === 201) {
          onSuccess(response, 'Agregado correctamente');
          await setRolesPermisos(response.data.id);
          onCancel();
          fetchData();
        }
      } else {
        const [key] = selectedRowKeys;
        const response = await API.put(`usuarios/usuarios/${key}/`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        if (response && response.status === 200) {
          setRolesPermisos(response.data.id);
          onSuccess(response, 'Actualizado correctamente');
          onCancel();
          fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`usuarios/usuarios/${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          setVisibleAlert(false);
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const getURForTreeSelect = (_data) => {
    if (_data.content_type && _data.object_id) {
      const { nivel } = contentTypes.find((ct) => ct.id === _data.content_type);
      const { object_id } = _data;
      form.setFieldsValue({ unidad_responsable: `${object_id}/${nivel}` });
    }
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
  };

  const onClickEdit = async () => {
    try {
      setLoading(true);
      const [key] = selectedRowKeys;
      const res = await API.get(`usuarios/usuarios/${key}`);
      if (res && res.data) {
        form.setFieldsValue({ permisos: res.data.permisos });
        setTargetKeysRoles(res.data.roles);
        // if (res.data.foto) {
        //   setImageURL(res.data.foto);
        // }
        // if (res.data.firma_electronica) {
        //   setHasFirma(true);
        // }
        getURForTreeSelect(res.data);
        setTimeout(() => {
          setIsExterno(!res.data.es_externo);
          form.setFieldsValue(res.data);
          formPersonales.setFieldsValue(res.data);
          formRoles.setFieldsValue(res.data);
          formPermisos.setFieldsValue(res.data);
        });
      }
      setLoading(false);
      setVisible(true);
    } catch (err) {
      onError(err);
    }
  };

  const onClickDelete = () => {
    const [id] = selectedRowKeys;
    if (user.id !== id) {
      setVisibleAlert(true);
    } else {
      message.info('No puede eliminarse el usuario de la sesión actual', 3);
    }
  };

  const columns = [
    {
      titleText: 'Correo electrónico',
      dataIndex: 'email',
      key: 'email',
      width: 380,
      inputWidth: 150,
    },
    {
      titleText: 'Nombre',
      dataIndex: 'first_name',
      key: 'first_name',
      width: 300,
      inputWidth: 150,
    },
    {
      titleText: 'Apellido Paterno',
      dataIndex: 'last_name',
      key: 'last_name',
      width: 360,
      inputWidth: 170,
    },
    {
      titleText: 'Apellido Materno',
      dataIndex: 'second_last_name',
      key: 'second_last_name',
      width: 360,
      inputWidth: 170,
    },
    {
      titleText: 'Estado',
      dataIndex: 'is_active',
      key: 'is_active',
      width: 200,
      inputWidth: 75,
      render: (val) => (val ? 'Activo' : 'Inactivo'),
    },
    {
      titleText: 'Empleado',
      dataIndex: 'es_externo',
      key: 'es_externo',
      width: 220,
      inputWidth: 75,
      render: (val) => (!val ? 'Sí' : 'No'),
    },
  ];

  const required = { required: true };
  const rules = {
    required: [required],
    requiredConditionally: [{ required: isExterno }],
    email_alternativo: [{ type: 'email' }],
    email: [required, { type: 'email' }],
    password: [
      {
        validator: async () => {
          const pwd = formPersonales.getFieldValue('password');
          if (!allRequirementsPassed(pwd)) {
            throw new Error('La contraseña no cumple con los requisitos');
          }
        },
      },
    ],
    confirmPassword: [
      {
        validator: async (rule, value) => {
          const pwd = formPersonales.getFieldValue('password');
          if (value && value !== pwd) {
            throw new Error('Las contraseñas no coinciden.');
          }
        },
      },
    ],
  };

  let timeout = null;
  const onFormValuesChange = (changedValues, allValues) => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      const { password } = changedValues;
      if (password) {
        const newOps = validatePasswordRequirements(password, 33);
        setOptions(newOps);
      } else if (!allValues.password) {
        setOptions(defaultOptions);
      }
      formPersonales.validateFields(['password', 'confirmPassword']);
    }, 500);
  };

  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible ? (
          <Table
            cols={columns}
            data={data}
            rowSelection={rowSelection}
            permission={permission}
            handleOnRowClick={handleOnRowClick}
            controls={{
              onClickAdd,
              onClickEdit,
              onClickDelete,
            }}
          />
        ) : (
          <Card
            className="form-container"
            title={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Usuario`}
            extra={(
              <FormSubmitControls
                onFinish={onFinish}
                onCancel={onCancel}
                loading={loading}
              />
              )}
            bordered={false}
          >
            <Tabs
              onChange={setCurrentTabKey}
              activeKey={currentTabKey}
              type="card"
            >
              <TabPane
                tab={(
                  <span>
                    <InfoCircleOutlined />
                    Informacion General
                  </span>
                  )}
                key="general"
                forceRender
              >
                <Form
                  layout="vertical"
                  form={form}
                  name="general"
                  onFinish={() => onFinish()}
                  scrollToFirstError
                  initialValues={{ es_externo: true }}
                >
                  <Row gutter={10}>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="es_externo"
                        label="¿Es un Empleado?"
                        hasFeedback
                      >
                        <Select
                          onChange={(value) => {
                            if (value) {
                              form.resetFields();
                              const [key] = selectedRowKeys;
                              const match = key ? data.find((e) => e.id === key) : {};
                              delete match.es_externo;
                              form.setFieldsValue({
                                is_active: false,
                                ...match,
                                es_externo: value,
                              });
                            }
                            setIsExterno(!value);
                          }}
                          trueFalse
                        />
                      </Form.Item>
                    </Col>

                    {!isExterno && (
                    <Col xs={24} sm={24} md={16}>
                      <Form.Item
                        name="empleado"
                        rules={rules.required}
                        label="Empleado"
                        hasFeedback
                      >
                        <Select
                          onChange={(key) => {
                            const selectedEmpleado = empleados
                              .find((e) => e.id === key);
                            form.setFieldsValue({
                              ...form.getFieldsValue(),
                              first_name: selectedEmpleado.nombre,
                              last_name: selectedEmpleado.apellido_paterno,
                              second_last_name: selectedEmpleado.apellido_materno,
                              email: selectedEmpleado.correo_electronico,
                              lada: selectedEmpleado.lada,
                              numero_de_celular: selectedEmpleado.numero_de_celular,
                            });
                            formPersonales.setFieldsValue({
                              ...formPersonales.getFieldsValue(),
                              observaciones: selectedEmpleado.observacion,
                            });
                            const { roles: _roles, permisos: _permisos } = selectedEmpleado;
                            formRoles.setFieldsValue({
                              roles: _roles,
                            });
                            formPermisos.setFieldsValue({
                              roles: _permisos,
                            });
                          }}
                          disabled={isExterno}
                          render={(f) => `${f.nombre}`}
                          dataSource={empleados}
                        />
                      </Form.Item>
                    </Col>
                    )}

                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="first_name"
                        rules={rules.required}
                        label="Nombre"
                        hasFeedback
                      >
                        <Input allowClear disabled={!isExterno && !selectedRowKeys.length} />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="last_name"
                        rules={rules.required}
                        label="Apellido Paterno"
                        hasFeedback
                      >
                        <Input allowClear disabled={!isExterno && !selectedRowKeys.length} />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="second_last_name"
                        rules={rules.second_last_name}
                        label="Apellido Materno"
                        hasFeedback
                      >
                        <Input allowClear disabled={!isExterno && !selectedRowKeys.length} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      {/* <NumericInput
                          name="numero_de_empleado"
                          label="Número de Empleado"
                        /> */}
                      <Form.Item
                        name="numero_de_empleado"
                        label="Número de Empleado"
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    {isExterno && (
                    <>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="funcion_del_usuario_externo"
                          label="Función"
                          hasFeedback
                        >
                          <Select dataSource={funciones} />
                        </Form.Item>
                      </Col>

                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="justificacion"
                          label="Justificación"
                          hasFeedback
                        >
                          <Select dataSource={justificaciones} />
                        </Form.Item>
                      </Col>
                    </>
                    )}
                    <LadaNumero
                      form={form}
                      rules={rules}
                      names={{
                        lada: 'lada',
                        numero: 'numero_de_celular',
                      }}
                      celular
                    />
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="email"
                        rules={rules.email}
                        label="Correo Electrónico"
                        hasFeedback
                      >
                        <Input allowClear />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="email_alternativo"
                        rules={rules.email_alternativo}
                        label="Correo Alternativo"
                        hasFeedback
                      >
                        <Input allowClear />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <AvatarUploader
                        form={form}
                        imageURL={form.getFieldValue('foto')}
                      />
                    </Col>
                  </Row>
                  <Form.Item hidden>
                    <Button htmlType="submit" />
                  </Form.Item>
                </Form>
              </TabPane>
              <TabPane
                tab={(
                  <span>
                    <InfoCircleOutlined />
                    Información del Usuario
                  </span>
                  )}
                key="info_usuario"
                forceRender
              >
                <Form
                  layout="vertical"
                  name="info_usuario"
                  form={formPersonales}
                  scrollToFirstError
                  onFinish={() => onFinish()}
                  onValuesChange={onFormValuesChange}
                >
                  <Row gutter={10} style={{ width: '100%' }}>
                    <Col xs={24} sm={24}>
                      <Row gutter={10}>
                        <Uploader
                          title="Firma Electrónica"
                          limitMB={2}
                          file={formPersonales.getFieldValue('firma_electronica')}
                          onError={onError}
                          rules={rules.firma_electronica}
                          formItemName="firma_electronica"
                          allowedExtensions={['cer']}
                          allowPreview={false}
                        />

                        <Col xs={24} sm={24} md={8}>
                          <Form.Item
                            name="is_active"
                            rules={rules.required}
                            label="Activo"
                            hasFeedback
                          >
                            <Select trueFalse />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={8}>
                          <Form.Item
                            name="fecha_de_vencimiento"
                            label="Fecha de Vencimiento"
                            hasFeedback
                          >
                            <DatePicker
                              disabledDate={(date) => date.isBefore(new Moment())}
                              format="DD/MM/YYYY"
                              placeholder=""
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={16}>
                          <Form.Item
                            name="observaciones"
                            label="Observaciones"
                            hasFeedback
                          >
                            <TextArea autoSize />
                          </Form.Item>
                        </Col>

                        <Col span={24}>
                          <Button
                            onClick={() => setVisiblePwd(!visiblePwd)}
                            className="no-color primary-on-hover"
                            style={{ borderRadius: 6, marginBottom: 10 }}
                          >
                            Asignar contraseña
                            {!visiblePwd ? (<DownOutlined />) : (<UpOutlined />)}
                          </Button>
                        </Col>

                        {visiblePwd && (
                        <Password rules={rules} options={options} />
                        )}
                        <br />
                        <br />
                      </Row>
                    </Col>
                  </Row>
                </Form>
              </TabPane>
              <TabPane
                tab={(
                  <span>
                    <InfoCircleOutlined />
                    Roles
                  </span>
                    )}
                key="roles"
                forceRender
              >
                <Form
                  layout="vertical"
                  name="roles"
                  form={formRoles}
                >
                  <Col span={24}>
                    <PlainTransfer
                      dataSource={roles}
                      label="Roles"
                      formItemName="roles"
                      form={formRoles}
                      filterProp="name"
                      showSelectAll
                      // disabled={!rolesPermission?.change}
                    />
                  </Col>
                </Form>
              </TabPane>
              <TabPane
                tab={(
                  <span>
                    <InfoCircleOutlined />
                    Permisos
                  </span>
                    )}
                key="permisos"
                forceRender
              >
                <Form
                  layout="vertical"
                  name="permisos"
                  form={formPermisos}
                >
                  <Col span={24}>
                    <PlainTransfer
                      dataSource={permisos}
                      label="Permisos"
                      formItemName="permisos"
                      form={formPermisos}
                      filterProp="name"
                      // disabled={!permissionPermission?.change}
                    />
                  </Col>
                </Form>
              </TabPane>
            </Tabs>
          </Card>
        )}
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => setVisibleAlert(false)}
          visible={visibleAlert}
          content={`Usuario ${form.getFieldValue('email')}`}
          loading={loading}
        />
      </Spin>
    </Row>
  );
}

export default Usuarios;
