import React from 'react';
import SingleForm from 'components/SingleForm';
import { getOrigenes } from 'api/control-patrimonial/';

export const permissionOrigen = {
  permissionModel: 'origen',
};
// eslint-disable-next-line react/prop-types
function Origen({ permission }) {
  return (
    <SingleForm
      title="Origen"
      withoutState
      readOnly
      permission={permission}
      get={getOrigenes}
    />
  );
}

export default Origen;
