import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Form,
  Button,
  Row,
  Col,
  Spin,
  Input,
  Card,
  DatePicker,
  message,
} from 'antd';

import {
  FileExcelOutlined, FilePdfOutlined, SearchOutlined,
} from '@ant-design/icons';

import Table from 'components/Table';
import FormSubmitControls from 'components/FormSubmitControls';
import API, { baseURL } from 'utils/api';
import {
  onError,
} from 'utils/handlers';
import Select from 'components/Select';
// import { InputSearchPro } from 'components/InputSearch';
import { SET_CONFIG_MASCARA_CONTABLE } from 'store/reducers/catalogs';
import fetchSome from 'utils/fetchSome';
import Previewer from 'components/Previewer';

const { RangePicker } = DatePicker;

const baseURI = 'contabilidad/auxiliar-contable/';
function AuxiliaresContables() {
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  const configMascaraContable = useSelector(({ catalogs }) => catalogs.configMascaraContable);
  const [form] = Form.useForm();
  const [formBusqueda] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState([]);

  const [pattern, setPattern] = useState();
  // const [mascara, setMascara] = useState();
  // const [separador, setSeparador] = useState();
  // const [cuentaContableFound, setCuentaContableFound] = useState([]);
  const [visiblePreview, setVisiblePreview] = useState(false);
  const [base64] = useState();
  const [setVisibleAlert] = useState(false);

  const setConfigsMask = (config) => {
    // setMascara(config?.mascara);
    const separator = config?.separador;
    // setSeparador(separator);
    const _segmentos = config?.mascara?.split(separator) || [];
    if (_segmentos?.length) {
      // eslint-disable-next-line prefer-template
      const patt = _segmentos.reduce((acum, curr, idx) => `${acum}[0-9]{${curr.length}}`
            + (idx !== _segmentos.length - 1 ? separator : '$'), '^');
      const regExp = new RegExp(`${patt}`);
      setPattern(regExp);
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      if (configMascaraContable) {
        setConfigsMask(configMascaraContable);
      } else {
        await fetchSome('configuraciones/configuraciones-de-mascaras/', SET_CONFIG_MASCARA_CONTABLE);
        const resConfig = await API.get('configuraciones/configuraciones-de-mascaras/');
        setConfigsMask(resConfig.data[0]);
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchData();

    return () => API.source.cancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setVisible(false);
    setSelectedRowKeys([]);
    form.resetFields();
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    setTimeout(() => {
      form.setFieldsValue(record);
    });
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(false);
  };

  const onClickDelete = () => {
    setVisibleAlert(false);
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const columns = [
    {
      titleText: 'Cuenta',
      dataIndex: 'cuenta',
      key: 'cuenta',
      width: 400,
    },
    {
      titleText: 'Descripción',
      dataIndex: 'nombre',
      key: 'nombre',
      width: 400,
    },
  ];

  const required = {
    message: 'El campo es requerido',
    required: true,
  };

  const rules = {
    required: [required],
    clave_contable__clave: [
      required,
      {
        validator: async (rule, value) => {
          if (value && pattern && (!pattern.test(value))) {
            throw new Error('Introduzca una clave válida');
          }
        },
      },
    ],
  };

  // const fetchCuentaContable = async () => {
  //   try {
  //     formBusqueda.validateFields(['clave_contable__clave']);
  //     const clave = formBusqueda.getFieldValue('clave_contable__clave');
  //     if (clave) {
  //       setLoading(true);
  //       const res = await API.get('contabilidad/cuentas-contables/', {
  //         params: {
  //           clave,
  //           tipo: 7,
  //           conac: false,
  //         },
  //       });
  //       if (res?.status === 200 && res.data?.length) {
  //         const claveContable = res.data[0];
  //         setCuentaContableFound([claveContable]);
  //         formBusqueda.setFieldsValue({
  //           clave_contable: claveContable.id,
  //         });
  //         setTimeout(() => {
  //           formBusqueda.validateFields(['clave_contable']);
  //         });
  //       } else {
  //         message.info('No se encontraron coincidencias');
  //         setCuentaContableFound([]);
  //       }
  //       setLoading(false);
  //     }
  //   } catch (err) {
  //     onError(err, setLoading);
  //     setCuentaContableFound([]);
  //   }
  // };

  // const resetAndValidateCuentaContable = () => {
  //   setCuentaContableFound([]);
  //   formBusqueda.resetFields(['clave_contable']);
  //   formBusqueda.validateFields(['clave_contable']);
  // };

  // const onChangeClaveContable = (e) => {
  //   const { target: { value }, keyCode } = e;
  //   let val = value;
  //   if (keyCode) {
  //     const char = String.fromCharCode(keyCode).toLowerCase();
  //     val += char;
  //   }
  //   if (mascara.charAt(val.length) === separador) {
  //     val += separador;
  //   }
  //   if (val.length === mascara?.length) {
  //     setTimeout(fetchCuentaContable);
  //   } else if (!val?.length) {
  //     resetAndValidateCuentaContable();
  //   }
  //   setTimeout(() => {
  //     formBusqueda.setFieldsValue({ clave_contable__clave: val });
  //   });
  // };

  const fetch = async () => {
    try {
      setLoading(true);
      await formBusqueda.validateFields();
      const values = formBusqueda.getFieldsValue();
      values.fecha_inicial = values.periodos[0].format('DD-MM-YYYY');
      values.fecha_final = values.periodos[1].format('DD-MM-YYYY');
      values.cuenta = values.clave_contable;
      delete values.periodos;

      const response = await API.post(baseURI, values);
      if (response?.status === 200 && response.data.length) {
        setData(response.data);
      } else {
        message.info('No se encontraron coincidencias');
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onClickPreviewPDF = async () => {
    try {
      setLoading(true);
      await formBusqueda.validateFields();
      const values = formBusqueda.getFieldsValue();
      const res = await API.post(`${baseURI}request-download/`, values);
      if (res?.status === 200 && res.data) {
        const { token } = res.data;
        window.open(`${baseURL}contabilidad/auxiliar-contable/pdf/?token=${token}`, '_blank');
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };
  const downloadExcel = async () => {
    try {
      setLoading(true);
      await formBusqueda.validateFields();
      const values = formBusqueda.getFieldsValue();
      const res = await API.post(`${baseURI}request-download/`, values);
      if (res?.status === 200 && res.data) {
        const { token } = res.data;
        window.open(`${baseURL}contabilidad/auxiliar-contable/excel/?token=${token}`, '_blank');
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };
  const onClickExpand = () => setVisible(true);

  return (
    <Row align="center" justify="center" className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible ? (
          <>
            <Form form={formBusqueda} layout="vertical">
              <Row gutter={10}>
                {/* <InputSearchPro
                  name="clave_contable__clave"
                  rules={rules.clave_contable__clave}
                  label="Búsqueda Cuenta Contable"
                  tooltip={`Debe concordar con la mascara ${mascara}`}
                  inputProps={{
                    onPressEnter: () => fetchCuentaContable(),
                    onChange: onChangeClaveContable,
                    maxLength: mascara?.length,
                  }}
                  breakPoints={{
                    xs: 24,
                    sm: 24,
                    md: 10,
                  }}
                  resultProps={{
                    name: 'clave_contable',
                    label: 'Cuenta Contable',
                    rules: rules.required,
                    dataSource: cuentaContableFound,
                    keyLabelRender: true,
                    keyProp: 'clave',
                    labelProp: 'nombre',
                    onClear: resetAndValidateCuentaContable,
                    breakPoints: {
                      md: 10,
                    },
                  }}
                /> */}
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    label="Periodo de Consulta"
                    rules={rules.required}
                    name=""
                  >
                    <RangePicker placeholder="" format="DD/MM/YYYY" />
                  </Form.Item>
                </Col>
                <Col style={{ display: 'flex', alignItems: 'center' }} />
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    label="Tipo Consulta"
                    rules={rules.required}
                    name=""
                  >
                    <Select dataSource={[{
                      id: 1,
                      descripcion: 'Todas',
                    }, {
                      id: 2,
                      descripcion: 'Una',
                    }, {
                      id: 3,
                      descripcion: 'Rango',
                    }, {
                      id: 4,
                      descripcion: 'Algunas',
                    }]}
                    />
                  </Form.Item>
                </Col>
                <Col style={{ display: 'flex', alignItems: 'center' }}>
                  <Button
                    onClick={fetch}
                    icon={<SearchOutlined />}
                    disabled
                  >
                    Buscar
                  </Button>
                </Col>

                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    label="Cuentas"
                    rules={rules.required}
                    name=""
                  >
                    <Select dataSource={[{
                      id: 1,
                      descripcion: 'Todas',
                    }, {
                      id: 2,
                      descripcion: 'Con movimientos',
                    }, {
                      id: 3,
                      descripcion: 'Con saldo final diferente de cero',
                    }, {
                      id: 4,
                      descripcion: 'Con moviminetos y saldo final diferente de cero',
                    }]}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    label="Póliza Cierre Contable y Presupuestal"
                    rules={rules.required}
                    name=""
                  >
                    <Select dataSource={[{
                      id: 1,
                      descripcion: 'No se incluyen',
                    }, {
                      id: 2,
                      descripcion: 'Sí se incluyen',
                    }, {
                      id: 3,
                      descripcion: 'Sólo las de cierre contable y presupuestal',
                    }]}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <Table
              cols={columns}
              data={data}
              rowSelection={rowSelection}
              rowKey="id"
              mobileColIndex={0}
              handleOnRowClick={handleOnRowClick}
              controls={{
                onClickExpand,
                onClickAdd,
                onClickDelete,
              }}
              customActions={[
                {
                  onClick: onClickPreviewPDF,
                  icon: <FilePdfOutlined />,
                  text: 'Descargar PDF',
                  disabledBySelection: false,
                },
                {
                  onClick: downloadExcel,
                  icon: <FileExcelOutlined />,
                  text: 'Descargar Excel',
                  disabledBySelection: false,
                },
              ]}
              allowExpand
            />
          </>
        ) : (
          <Col span={24} style={{ height: '100%' }}>
            <Card
              className="form-container"
              title={(
                <FormSubmitControls
                  label={`${selectedRowKeys.length ? 'Eliminar' : 'Agregar'} AuxiliaresContables`}
                  onCancel={onCancel}
                />
              )}
              bordered={false}
            >
              <Form
                layout="vertical"
                form={form}
                scrollToFirstError
                className="without-feedback"
                initialValues={{ estados_globales: 1 }}
              >
                <Row gutter={10}>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="fecha_inicial"
                      label="Fecha de la Solicitud"
                    >
                      <DatePicker disabled format="DD/MM/YYYY" placeholder="" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="fecha_final"
                      label="Fecha de Vencimiento"
                    >
                      <DatePicker format="DD/MM/YYYY" placeholder="" disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="saldo_inicial"
                      label="Saldo Inicial"
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="saldo_final"
                      label="Saldo Final"
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="cargo"
                      label="Cargo"
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="abono"
                      label="Abono"
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="saldo"
                      label="Saldo"
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="estados_globales"
                      label="Estado"
                    >
                      <Select dataSource={estadosGlobales} disabled />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item hidden>
                  <Button htmlType="submit" />
                </Form.Item>
              </Form>
            </Card>
          </Col>
        )}
        <Previewer
          base64={base64}
          baseURL={baseURI}
          id={selectedRowKeys[0]}
          visible={visiblePreview}
          setVisible={setVisiblePreview}
        />
      </Spin>
    </Row>
  );
}

export default AuxiliaresContables;
