import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Col,
  Button,
  TreeSelect,
  Spin,
  Row,
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import API from 'utils/api';
import Select from 'components/Select';
import { onError, onSuccess } from 'utils/handlers';
import makeMessager from 'utils/complexFormsMessages';
import { UPDATE_ENTIDAD_INFO } from 'store/reducers/auth';
import FormItemLabel from 'components/FormItemLabel';

function ConfiguracionesUR({
  editing,
  setFormConfigurations,
  setCurrentTabKey,
  callback,
}) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [clasificacionAdministrativa, setClasificacionesAdministrativa] = useState([]);
  const [conceptoUr, setConceptoUr] = useState([]);
  const periodoFiscalSelected = useSelector(({ auth }) => auth.periodoFiscalSelected);
  const [disabled, setDisabled] = useState(false);
  const entidad = useSelector(({ auth }) => auth.entidad);

  const onClickTitle = (e) => {
    const element = e.target;
    if (element) {
      let parent = element.parentElement;
      parent = parent?.parentElement;
      const clickableSpan = parent?.previousSibling;
      if (clickableSpan) {
        clickableSpan.click();
      }
    }
  };

  const renderTitle = (title, bold) => (
    // eslint-disable-next-line
    <span onClick={onClickTitle} className={bold ? 'bold' : ''}>
      {title}
    </span>
  );

  const normalizeTreeData = (_data) => {
    if (_data) {
      const output = _data.map((item) => {
        const clone = { ...item };
        const children = normalizeTreeData(clone.children);
        const title = renderTitle(`${clone.clave} - ${clone.concepto}`, true);
        if (!children.length) {
          delete clone.children;
          return ({
            ...clone,
            title,
            value: clone.id,
            selectable: true,
          });
        }
        return ({
          ...clone,
          title,
          value: clone.id,
          children,
          selectable: false,
        });
      });
      return output;
    }
    return [];
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      values.entidad = entidad.id;
      const res = await API.post(
        '/estructura-organizacional/entidades/configuracion-de-ur-administrador/',
        values,
      );
      if (res?.status === 200) {
        dispatch({
          type: UPDATE_ENTIDAD_INFO,
          entidad: {
            ...entidad,
            configuracion_de_ur: res.data,
          },
        });
        onSuccess(res, 'Configuración guardada correctamente');
        callback();
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const fetchAll = async () => {
    try {
      setLoading(true);
      const resClasificacionAdministrativa = await API
        .get('/catalogos/clasificaciones-administrativas-administrador/');
      const normalizedAdministrativas = normalizeTreeData(resClasificacionAdministrativa.data);
      setClasificacionesAdministrativa(normalizedAdministrativas);

      const resConceptosUr = await API.get('contabilidad/conceptos-unidad-responsable/');
      setConceptoUr(resConceptosUr.data || []);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const fetchBase = async () => {
    try {
      setLoading(true);
      await fetchAll();
      if (entidad?.configuracion_de_ur) {
        form.setFieldsValue({ ...entidad.configuracion_de_ur });
      } else {
        setDisabled(false);
      }
      setTimeout(() => {
        // eslint-disable-next-line no-use-before-define
        form.onFinishHandler = onFinishHandler;
        setFormConfigurations(form);
      });
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const showMsg = makeMessager(setCurrentTabKey, fetchBase);

  const onFinishHandler = async (tabKey = null, _continue = true) => {
    try {
      if (tabKey) {
        showMsg(tabKey);
      } else {
        await onFinish(null, _continue);
      }
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchBase();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const filterTreeNode = (input, node) => {
    const title = node.title?.props.children.toLowerCase() || node.title;
    if (title && title.includes(input.toLowerCase())) {
      return true;
    }
    if (node.children) {
      return filterTreeNode(input, node.children);
    }
    return false;
  };

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es requerido.',
      },
    ],
  };

  return (
    <Spin tip="Cargando..." spinning={loading}>
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        scrollToFirstError
        initialValues={{ periodo_fiscal: periodoFiscalSelected?.id }}
      >
        <Row gutter={10}>
          <Col xs={24} sm={24} md={8}>
            <Form.Item
              name="periodo_fiscal"
              rules={rules.required}
              label="Ejercicio"
              hasFeedback={!(disabled || editing)}
            >
              <Select
                dataSource={[periodoFiscalSelected]}
                render={(item) => item.periodo}
                disabled
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Form.Item
              name="clasificacion_administrativa"
              rules={rules.required}
              label={(
                <FormItemLabel
                  longTitle="Clasificación Administrativa"
                  shortTitle="Clasificación A."
                />
)}
              hasFeedback={!(disabled || editing)}
            >
              <TreeSelect
                showSearch
                treeNodeFilterProp="title"
                style={{ width: '100%' }}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                treeData={normalizeTreeData(clasificacionAdministrativa)}
                filterTreeNode={filterTreeNode}
                disabled={disabled || editing}
                showArrow={!(disabled || editing)}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Form.Item
              name="concepto_de_ur"
              rules={rules.required}
              label="Concepto de UR"
              hasFeedback={!(disabled || editing)}
            >
              <Select dataSource={conceptoUr} disabled={disabled || editing} />
            </Form.Item>
          </Col>
          <Form.Item hidden>
            <Button htmlType="submit" />
          </Form.Item>
        </Row>
      </Form>
    </Spin>
  );
}

ConfiguracionesUR.propTypes = {
  editing: PropTypes.bool.isRequired,
  setFormConfigurations: PropTypes.func.isRequired,
  setCurrentTabKey: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired,
};

export default ConfiguracionesUR;
