/* eslint-disable no-plusplus */
const _setChildren = (parent, children) => {
  // eslint-disable-next-line no-param-reassign
  parent.children = children[parent.id] || [];

  for (let i = 0; i < parent.children.length; i++) {
    _setChildren(parent.children[i], children);
  }
};

const makeTreeFromCuentasContables = (data) => {
  const parents = [];
  const children = {};

  for (let i = 0; i < data.length; i++) {
    if (data[i]?.conac && data[i]?.nivel === 1 && !data[i]?.cuenta_padre) {
      parents.push(data[i]);
    }

    if (data[i]?.cuenta_padre) {
      if (children[data[i].cuenta_padre]) {
        children[data[i].cuenta_padre].push(data[i]);
      } else {
        children[data[i].cuenta_padre] = [data[i]];
      }
    }
  }

  const output = [];
  for (let i = 0; i < parents.length; i++) {
    const item = parents[i];

    _setChildren(item, children);

    output.push(item);
  }

  return output;
};

export default makeTreeFromCuentasContables;
