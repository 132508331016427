import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  AutoComplete,
  Input,
  Typography,
  Layout,
  Divider,
  Badge,
  Avatar,
  Tooltip,
} from 'antd';
import {
  BellOutlined,
  SettingOutlined,
  MenuOutlined,
  CloseOutlined,
  UserOutlined,
  ArrowRightOutlined,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { foo } from 'mocks/hardcodedStuff';
import { getRoutes, flatRoutes } from 'routes/index';
import logo from 'assets/logo.png';

const { Header } = Layout;
const { Text } = Typography;

function LayoutHeader({
  isMobile,
  toggle,
  collapsed,
  onClickAvatar,
  onClickNotifications,
  onClickSetting,
}) {
  const [value, setValue] = React.useState();
  const entidad = useSelector(({ auth }) => auth.entidad);
  const history = useHistory();
  const collapsedSidebar = useSelector(({ app }) => app.collapsedSidebar);
  const periodoFiscalSelected = useSelector(({ auth }) => auth.periodoFiscalSelected);
  const user = useSelector(({ auth }) => auth.user);
  const routes = getRoutes();
  const flattedRoutes = flatRoutes(periodoFiscalSelected?.vigente
    ? routes : routes.filter((e, i) => i !== 2));

  function ToggleSidebarButton() {
    return React.createElement(MenuOutlined, {
      className: `toggle-sidebar-trigger ${collapsedSidebar ? 'close' : 'open'}`,
      style: {
        fontSize: '28px',
        lineHeight: '64px',
        padding: '0 26px',
      },
      onClick: toggle,
    });
  }

  function MobileToggleSidebarButton() {
    return React.createElement(collapsed ? MenuOutlined : CloseOutlined, {
      className: 'toggle-sidebar-trigger',
      style: {
        fontSize: '22px',
        lineHeight: '64px',
        padding: '0 15px',
      },
      onClick: toggle,
    });
  }

  // eslint-disable-next-line react/prop-types
  function LinkTo({ to, title }) {
    return (
      <span style={{ display: 'flex', flex: 1 }}>
        <span style={{ marginRight: 'auto' }}>
          {title}
        </span>
        <Link to={to} target="_blank" onClick={(e) => { e.stopPropagation(); }}>
          <Tooltip title="Abrir en nueva pestaña">
            <ArrowRightOutlined />
          </Tooltip>
        </Link>
      </span>
    );
  }

  const getTitle = (item) => (
    <LinkTo to={item.path} title={item.title} />
  );

  const searchOptions = flattedRoutes.map((r) => ({
    path: r.path,
    tags: r.tags,
    value: r.path,
    title: r.title,
    label: getTitle(r),
  }));

  function UserAvatar() {
    let props;
    if (user?.foto) {
      props = {
        src: user?.foto,
      };
    } else {
      props = {
        icon: <UserOutlined />,
      };
    }
    return (
      <Avatar
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        size="large"
      />
    );
  }

  return (
    <Header
      style={{
        padding: '0',
        position: 'absolute',
        left: 0,
        right: 0,
      }}
    >
      <Row style={{ height: 64 }} justify="space-between">
        <div className="logo-sidebar">
          {!isMobile ? (
            <div className="logo-wrapper">
              <ToggleSidebarButton />
              <img
                src={entidad?.logotipo || logo}
                alt="logo"
                style={{ cursor: 'pointer' }}
                onClick={() => history.push('/dashboard')}
                aria-hidden="true"
              />
            </div>
          ) : (<MobileToggleSidebarButton />)}
        </div>
        {!isMobile && (
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 'calc(100vw - 600px)',
          }}
          >
            <AutoComplete
              value={value}
              options={searchOptions}
              style={{ width: '80%', minWidth: 250, maxWidth: 350 }}
              onChange={setValue}
              onSelect={(val) => {
                history.push(val);
                setValue('');
              }}
              filterOption={(input, option) => {
                // eslint-disable-next-line no-param-reassign
                input = input?.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
                return option?.path.toLowerCase().includes(input)
                || (option?.tags?.find((tag) => tag?.toLowerCase().includes(input)))
                || option?.title?.toLowerCase()
                  .normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(input);
              }}
              dropdownClassName="wrap-text buscador-global__dropdown"
              onBlur={() => { setValue(''); }}
              onFocus={() => { setValue(''); }}
              // ref={inputSearch}
            >
              <Input placeholder="Buscar..." allowClear />
            </AutoComplete>
          </div>
        )}
        <div className="user-options">
          <Divider type="vertical" />
          <Badge count={foo.filter((a) => !a.readed).length}>
            <BellOutlined
              onClick={onClickNotifications}
            />
          </Badge>
          <Divider type="vertical" />
          <SettingOutlined
            onClick={onClickSetting}
          />
          <Divider type="vertical" />
          <div
            onClick={onClickAvatar}
            style={{ cursor: 'pointer' }}
            className="username"
            aria-hidden="true"
          >
            <UserAvatar />
            {user?.first_name && (
            <Text style={{ textTransform: 'capitalize' }}>
              {`${user.first_name.split(' ').shift()}` || ''}
            </Text>
            )}
          </div>
        </div>
      </Row>
    </Header>
  );
}

LayoutHeader.propTypes = {
  onClickAvatar: PropTypes.func.isRequired,
  onClickNotifications: PropTypes.func.isRequired,
  onClickSetting: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  collapsed: PropTypes.bool,
};

LayoutHeader.defaultProps = {
  collapsed: false,
  isMobile: false,
};

export default LayoutHeader;
