export default [
  {
    id: 1,
    fecha: '28/11/2022',
    cuenta_bancaria: '2 BANAMEX GASTO CORRIENTE INGRESOS 1427416271',
    concepto: 'Comisión cheques expedidos',
    importe: '$1,200.00 ',
    iva: '$192.00 ',
    total: '$1,392.00 ',
    clave_presupuestal: '0902-34101-1-1501',
    cog: 'Servicio bancario',
    elaboro: 'Juan Prado García',
    puesto: 'Asistente de Tesorería',
    area: 'Tesorería',
    poliza: 'PE-2022-11-00159',
    movimiento_presupuestal: 'MP-2022-12-00189',
    estatus: 'Procesado',
    observaciones: '',
  },
  {
    id: 2,
    fecha: '28/11/2022',
    cuenta_bancaria: '2 BANAMEX GASTO CORRIENTE INGRESOS DLLS.1429039459',
    concepto: 'Comisión manejo de cuenta',
    importe: '$500.00 ',
    iva: '$80.00 ',
    total: '$580.00 ',
    clave_presupuestal: '0902-34101-1-1501',
    cog: 'Servicio bancario',
    elaboro: 'Juan Prado García',
    puesto: 'Asistente de Tesorería',
    area: 'Tesorería',
    poliza: 'PE-2022-11-00258',
    movimiento_presupuestal: 'MP-2022-12-00205',
    estatus: 'Procesado',
    observaciones: '',
  }, {
    id: 3,
    fecha: '28/11/2022',
    cuenta_bancaria: '2 BANAMEX GASTO CORRIENTE NOMINA 1427415518',
    concepto: 'Intereses de préstamo',
    importe: '$5,800.00',
    iva: '$- ',
    total: '$5,800.00 ',
    clave_presupuestal: '0902-34101-1-1501',
    cog: 'Servicio bancario',
    elaboro: 'Juan Prado García',
    puesto: 'Asistente de Tesorería',
    area: 'Tesorería',
    poliza: 'PE-2022-11-00486',
    movimiento_presupuestal: 'MP-2022-12-00221',
    estatus: 'Procesado',
    observaciones: '',
  }, {
    id: 4,
    fecha: '28/11/2022',
    cuenta_bancaria: '2 BANAMEX GASTO CORRIENTE TPV 7384760',
    concepto: 'Comisión por sobregiro',
    importe: '$900.00 ',
    iva: '$144.00',
    total: '$1,044.00 ',
    clave_presupuestal: '0902-34101-1-1501',
    cog: 'Servicio bancario',
    elaboro: 'Juan Prado García',
    puesto: 'Asistente de Tesorería',
    area: 'Tesorería',
    poliza: 'PE-2022-11-00597',
    movimiento_presupuestal: 'MP-2022-12-00264',
    estatus: 'Procesado',
    observaciones: '',
  },
];
