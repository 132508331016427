import React, { useState, useEffect } from 'react';
import {
  Card,
  Form,
  Tabs,
  Select,
  Modal,
  Row,
  Col,
  Button,
  Input,
  DatePicker,
} from 'antd';

import FormSubmitControls from 'components/FormSubmitControls';
import API from 'utils/api';
import {
  CheckCircleOutlined,
  ArrowLeftOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import Table from 'components/Table';

const { TabPane } = Tabs;

function PagoObraPublica() {
  const [visible, setVisible] = useState(false);
  const [visibleForm, setVisibleForm] = useState(false);
  const [visibleModalCreate, setVisibleModalCreate] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [currentTabKey, setCurrentTabKey] = useState('control_pagos');
  const [loading, setLoading] = useState(false);
  const [mutable, setMutable] = useState(true);
  let addForm = 'anticipo';

  const [form] = Form.useForm();
  const requiredRule = {
    required: true,
    message: 'El campo es requerido',
  };
  const rules = {
    required: [requiredRule],
  };

  const fetchData = async () => {
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    setTimeout(() => form.setFieldsValue(record));
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickAdd = () => {
    setVisibleModalCreate(true);
  };

  const onClickEdit = () => { };

  const onClickDelete = () => {
    setMutable(true);
  };

  const onAutorizar = () => { };

  const onChangeTabKey = () => { };

  const onGuardarForm = () => { };

  const onAutorizarForm = () => { };

  const onRegresarForm = () => {
    setVisible(false);
    setVisibleForm(false);
    setVisibleModalCreate(false);
  };

  const onFinishModal = () => {
    setVisible(true);
    setVisibleForm(addForm === 'anticipo');
  };

  const handleOnChangeSelect = (e) => {
    addForm = e;
  };

  const onCancelModal = () => {
    setVisible(false);
    setVisibleModalCreate(false);
  };

  const onFinishAnticipo = () => { };

  const onFinishEstimacion = () => { };

  const columnsControlPago = [
    {
      titleText: 'Fecha de Documento de Pago',
      dataIndex: 'fecha_documento_pago',
      key: 'fecha_documento_pago',
      width: 250,
    },
    {
      titleText: 'Folio Agrupador',
      dataIndex: 'folio_agrupador',
      key: 'folio_agrupador',
      width: 200,
    },
    {
      titleText: 'Tipo de Pago',
      dataIndex: 'tipo_pago',
      key: 'tipo_pago',
      width: 200,
    },
    {
      titleText: 'No. de Estimación',
      dataIndex: 'numero_estimacion',
      key: 'numero_estimacion',
      width: 200,
    },
    {
      titleText: 'Periodo de la Estimación',
      dataIndex: 'periodo_estimacion',
      key: 'periodo_estimacion',
      width: 300,
    },
    {
      titleText: 'Número de Documento de Obra',
      dataIndex: 'numero_documento_obra',
      key: 'numero_documento_obra',
      width: 300,
    },
    {
      titleText: 'Importe de Documento de Pago',
      dataIndex: 'importe_documento_pago',
      key: 'importe_documento_pago',
      width: 300,
    },
    {
      titleText: 'Aplicación de Penalizaciones',
      dataIndex: 'aplicacion_penalizaciones',
      key: 'aplicacion_penalizaciones',
      width: 300,
    },
    {
      titleText: 'Recuperación de Penalizaciones',
      dataIndex: 'recuperacion_penalizaciones',
      key: 'recuperacion_penalizaciones',
      width: 300,
    },
    {
      titleText: 'Subtotal',
      dataIndex: 'subtotal',
      key: 'subtotal',
      width: 150,
    },
    {
      titleText: 'IVA',
      dataIndex: 'iva',
      key: 'iva',
      width: 150,
    },
    {
      titleText: 'Total',
      dataIndex: 'total',
      key: 'total',
      width: 150,
    },
    {
      titleText: 'Aplicación de Anticipo',
      dataIndex: 'aplicacion_anticipo',
      key: 'aplicacion_anticipo',
      width: 250,
    },
    {
      titleText: 'Retenciones',
      dataIndex: 'retenciones',
      key: 'retenciones',
      width: 150,
    },
    {
      titleText: 'Importe Neto de Pago',
      dataIndex: 'importe_neto_pago',
      key: 'importe_neto_pago',
      width: 300,
    },
    {
      titleText: 'Fecha de Autorización',
      dataIndex: 'fecha_autorizacion',
      key: 'fecha_autorizacion',
      width: 200,
    },
    {
      titleText: 'Pólizas',
      dataIndex: 'polizas',
      key: 'polizas',
      width: 150,
    },
    {
      titleText: 'Movimiento Presupuestal',
      dataIndex: 'movimiento_presupuestal',
      key: 'movimiento_presupuestal',
      width: 250,
    },
    {
      titleText: 'No. de Cuenta por Pagar',
      dataIndex: 'numero_cuenta_por_pagar',
      key: 'numero_cuenta_por_pagar',
      width: 300,
    },
    {
      titleText: 'Fecha de Pago',
      dataIndex: 'fecha_pago',
      key: 'fecha_pago',
      width: 200,
    },
    {
      titleText: 'No. de Pago',
      dataIndex: 'numero_pago',
      key: 'numero_pago',
      width: 200,
    },
  ];

  const columnsSaldoObra = [
    {
      titleText: 'Fecha de Documento de Pago',
      dataIndex: 'fecha_documento_pago',
      key: 'fecha_documento_pago',
      width: 250,
    },
    {
      titleText: 'Fecha de Aceptación',
      dataIndex: 'fecha_aceptacion',
      key: 'fecha_aceptacion',
      width: 250,
    },
    {
      titleText: 'Número de Documento de Obra',
      dataIndex: 'numero_documento_obra',
      key: 'numero_documento_obra',
      width: 300,
    },
    {
      titleText: 'Concepto',
      dataIndex: 'concepto',
      key: 'concepto',
      width: 150,
    },
    {
      titleText: 'Importe',
      dataIndex: 'importe',
      key: 'importe',
      width: 150,
    },
    {
      titleText: 'Saldo',
      dataIndex: 'saldo',
      key: 'saldo',
      width: 150,
    },
  ];

  const columnsSaldoAnticipo = [
    {
      titleText: 'Fecha de Documento de Pago',
      dataIndex: 'fecha_documento_pago',
      key: 'fecha_documento_pago',
      width: 250,
    },
    {
      titleText: 'Fecha de Aceptación',
      dataIndex: 'fecha_aceptacion',
      key: 'fecha_aceptacion',
      width: 250,
    },
    {
      titleText: 'Número de Documento de Obra',
      dataIndex: 'numero_documento_obra',
      key: 'numero_documento_obra',
      width: 300,
    },
    {
      titleText: 'Concepto',
      dataIndex: 'concepto',
      key: 'concepto',
      width: 150,
    },
    {
      titleText: 'Importe',
      dataIndex: 'importe',
      key: 'importe',
      width: 150,
    },
    {
      titleText: 'Saldo',
      dataIndex: 'saldo',
      key: 'saldo',
      width: 150,
    },
  ];

  function ControlPagos() {
    return (
      <>
        <Table
          cols={columnsControlPago}
          data={[]}
          rowSelection={rowSelection}
          handleOnRowClick={handleOnRowClick}
          mobileColIndex={0}
          controls={{
            onClickAdd,
            onClickEdit,
            onClickDelete,
          }}
          customActions={[
            {
              onClick: onAutorizar,
              text: 'Autorizar',
              icon: <CheckCircleOutlined />,
              disabledBySelection: false,
              className: 'success',
            },
          ]}
        />
        <Modal
          visible={visibleModalCreate}
          closable={false}
          maskClosable={!loading}
          keyboard={!loading}
          footer={null}
          title={(
            <FormSubmitControls
              label={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Comprobante de Solicitud de Pago`}
              onFinish={onFinishModal}
              onCancel={onCancelModal}
              loading={loading}
            />
        )}
          onCancel={onCancelModal}
          forceRender
        >
          <Form
            layout="vertical"
            form={form}
            onFinish={onFinishModal}
            scrollToFirstError
            name="addForm"
          >

            <Row gutter={10}>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="add_form"
                  label="Selecciona el Formulario de Pago"
                  hasFeedback
                >
                  <Select
                    value={addForm}
                    defaultValue="anticipo"
                    onChange={handleOnChangeSelect}
                    filterOption={false}
                    options={[
                      { value: 'anticipo', label: 'Anticipo' },
                      { value: 'estimacion', label: 'Estimación' },
                    ]}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </>
    );
  }

  function SaldoObra() {
    return (
      <Table
        cols={columnsSaldoObra}
        data={[]}
        rowSelection={rowSelection}
        handleOnRowClick={handleOnRowClick}
        mobileColIndex={0}
      />
    );
  }

  function SaldoAnticipo() {
    return (
      <Table
        cols={columnsSaldoAnticipo}
        data={[]}
        rowSelection={rowSelection}
        handleOnRowClick={handleOnRowClick}
        mobileColIndex={0}
      />
    );
  }

  function TabsPagos() {
    return (
      <Tabs
        activeKey={currentTabKey}
        onChange={mutable ? setCurrentTabKey : onChangeTabKey}
        type="card"
      >
        <TabPane
          tab="Control de Pagos"
          key="control_pagos"
          forceRender
        >
          <ControlPagos />
        </TabPane>
        <TabPane
          tab="Saldo de Obra"
          key="saldo_obra"
          forceRender
        >
          <SaldoObra />
        </TabPane>
        <TabPane
          tab="Saldo de Anticipo"
          key="saldo_anticipo"
          forceRender
        >
          <SaldoAnticipo />
        </TabPane>
      </Tabs>
    );
  }

  function FormsPagos() {
    return (
      <Card
        extra={(
          <>
            <Button
              icon={<SaveOutlined />}
              className="ant-btn ant-btn-link"
              onClick={onGuardarForm}
            >
              Guardar
            </Button>
            <Button
              icon={<CheckCircleOutlined />}
              className="ant-btn ant-btn-link"
              onClick={onAutorizarForm}
            >
              Autorizar
            </Button>
            <Button
              icon={<ArrowLeftOutlined />}
              className="ant-btn ant-btn-link"
              onClick={onRegresarForm}
            >
              Regresar
            </Button>
          </>
      )}
        bordered={false}
      >
        {visibleForm ? (<FormPagoAnticipo />) : (<FormPagoEstimacion />)}
      </Card>
    );
  }

  function FormPagoAnticipo() {
    return (
      <Row gutter={10}>
        <Col md={24} sm={24} xs={24}>
          <Form
            layout="vertical"
            form={form}
            onFinish={onFinishAnticipo}
            scrollToFirstError
            name="anticipo"
          >
            <Row gutter={10}>
              <Col xs={12} sm={24} md={8}>
                <Form.Item
                  name="fecha_documento_pago"
                  label="Fecha de Documento de Pago"
                  rules={rules.required}
                >
                  <DatePicker format="DD/MM/YYYY" />
                </Form.Item>
              </Col>
              <Col xs={12} sm={24} md={8}>
                <Form.Item name="folio_agrupador" label="Folio Agrupador">
                  <Input allowClear disabled />
                </Form.Item>
              </Col>
              <Col xs={12} sm={24} md={8}>
                <Form.Item name="tipo_pago" label="Tipo de Pago" rules={rules.required}>
                  <Select datasource={[]} />
                </Form.Item>
              </Col>
              <Col xs={12} sm={24} md={8}>
                <Form.Item name="numero_estimacion" label="No. de Estimación">
                  <Input allowClear disabled />
                </Form.Item>
              </Col>
              <Col xs={12} sm={24} md={8}>
                <Form.Item name="periodo_del" label="Del">
                  <DatePicker format="DD/MM/YYYY" disabled />
                </Form.Item>
              </Col>
              <Col xs={12} sm={24} md={8}>
                <Form.Item name="periodo_al" label="Al">
                  <DatePicker format="DD/MM/YYYY" disabled />
                </Form.Item>
              </Col>
              <Col xs={12} sm={24} md={8}>
                <Form.Item name="numero_documentos_obra" label="No. de Documento de Obra">
                  <Input allowClear disabled />
                </Form.Item>
              </Col>
              <Col xs={12} sm={24} md={8}>
                <Form.Item name="importe_anticipo" label="Importe de Anticipo" rules={rules.required}>
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col xs={12} sm={24} md={8}>
                <Form.Item name="concepto_penalizacion" label="Concepto de Penalización (Aplicación)">
                  <Select datasource={[]} disabled />
                </Form.Item>
              </Col>
              <Col xs={12} sm={24} md={8}>
                <Form.Item name="importe_penalizacion" label="Importe de Penalización (Aplicación)">
                  <Input allowClear disabled />
                </Form.Item>
              </Col>
              <Col xs={12} sm={24} md={8}>
                <Form.Item name="concepto_penalizacion" label="Concepto de Penalización (Recuperación)">
                  <Select datasource={[]} disabled />
                </Form.Item>
              </Col>
              <Col xs={12} sm={24} md={8}>
                <Form.Item name="importe_penalizacion" label="Importe de Penalización (Recuperación)">
                  <Input allowClear disabled />
                </Form.Item>
              </Col>
              <Col xs={12} sm={24} md={8}>
                <Form.Item name="subtotal" label="Subtotal">
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col xs={12} sm={24} md={8}>
                <Form.Item name="iva" label="IVA" rules={rules.required}>
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col xs={12} sm={24} md={8}>
                <Form.Item name="total" label="Total">
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col xs={12} sm={24} md={8}>
                <Form.Item name="aplicacion_anticipo" label="Aplicación de Anticipo">
                  <Input allowClear disabled />
                </Form.Item>
              </Col>
              <Col xs={12} sm={24} md={8}>
                <Form.Item name="2_supervision" label="2% Supervisión">
                  <Input allowClear disabled />
                </Form.Item>
              </Col>
              <Col xs={12} sm={24} md={8}>
                <Form.Item name="2_millar_colegio" label="2 al Millar de Colegio">
                  <Input allowClear disabled />
                </Form.Item>
              </Col>
              <Col xs={12} sm={24} md={8}>
                <Form.Item name="5_millar" label="5 al Millar">
                  <Input allowClear disabled />
                </Form.Item>
              </Col>
              <Col xs={12} sm={24} md={8}>
                <Form.Item name="importe_neto" label="Importe Neto">
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col xs={12} sm={24} md={8}>
                <Form.Item name="fecha_autorizacion" label="Fecha de Autorización">
                  <DatePicker format="DD/MM/YYYY" disabled />
                </Form.Item>
              </Col>
              <Col xs={12} sm={24} md={8}>
                <Form.Item name="polizas" label="Pólizas">
                  <Input allowClear disabled />
                </Form.Item>
              </Col>
              <Col xs={12} sm={24} md={8}>
                <Form.Item name="movimiento_presupuestal" label="Movimiento Presupuestal">
                  <Input allowClear disabled />
                </Form.Item>
              </Col>
              <Col xs={12} sm={24} md={8}>
                <Form.Item name="numero_cuenta_pagar" label="No. de Cuenta por Pagar">
                  <Input allowClear disabled />
                </Form.Item>
              </Col>
              <Col xs={12} sm={24} md={8}>
                <Form.Item name="fecha_pago" label="Fecha de Pago">
                  <DatePicker format="DD/MM/YYYY" disabled />
                </Form.Item>
              </Col>
              <Col xs={12} sm={24} md={8}>
                <Form.Item name="numero_pago" label="No. de Pago">
                  <Input allowClear disabled />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    );
  }

  function FormPagoEstimacion() {
    return (
      <Row gutter={10}>
        <Col md={24} sm={24} xs={24}>
          <Form
            layout="vertical"
            form={form}
            onFinish={onFinishEstimacion}
            scrollToFirstError
            name="estimacion"
          >
            <Row gutter={10}>
              <Col xs={12} sm={24} md={8}>
                <Form.Item
                  name="fecha_documento_pago"
                  label="Fecha de Documento de Pago"
                  rules={rules.required}
                >
                  <DatePicker format="DD/MM/YYYY" />
                </Form.Item>
              </Col>
              <Col xs={12} sm={24} md={8}>
                <Form.Item name="folio_agrupador" label="Folio Agrupador">
                  <Input allowClear disabled />
                </Form.Item>
              </Col>
              <Col xs={12} sm={24} md={8}>
                <Form.Item name="tipo_pago" label="Tipo de Pago" rules={rules.required}>
                  <Select datasource={[]} />
                </Form.Item>
              </Col>
              <Col xs={12} sm={24} md={8}>
                <Form.Item name="numero_estimacion" label="No. de Estimación" rules={rules.required}>
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col xs={12} sm={24} md={8}>
                <Form.Item name="periodo_del" label="Del" rules={rules.required}>
                  <DatePicker format="DD/MM/YYYY" />
                </Form.Item>
              </Col>
              <Col xs={12} sm={24} md={8}>
                <Form.Item name="periodo_al" label="Al" rules={rules.required}>
                  <DatePicker format="DD/MM/YYYY" />
                </Form.Item>
              </Col>
              <Col xs={12} sm={24} md={8}>
                <Form.Item name="numero_documentos_obra" label="No. de Documento de Obra">
                  <Input allowClear disabled />
                </Form.Item>
              </Col>
              <Col xs={12} sm={24} md={8}>
                <Form.Item name="importe_estimacion" label="Importe de Estimación" rules={rules.required}>
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col xs={12} sm={24} md={8}>
                <Form.Item name="concepto_penalizacion" label="Concepto de Penalización (Aplicación)">
                  <Select datasource={[]} />
                </Form.Item>
              </Col>
              <Col xs={12} sm={24} md={8}>
                <Form.Item name="importe_penalizacion" label="Importe de Penalización (Aplicación)">
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col xs={12} sm={24} md={8}>
                <Form.Item name="concepto_penalizacion" label="Concepto de Penalización (Recuperación)">
                  <Select datasource={[]} />
                </Form.Item>
              </Col>
              <Col xs={12} sm={24} md={8}>
                <Form.Item name="importe_penalizacion" label="Importe de Penalización (Recuperación)">
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col xs={12} sm={24} md={8}>
                <Form.Item name="subtotal" label="Subtotal">
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col xs={12} sm={24} md={8}>
                <Form.Item name="iva" label="IVA" rules={rules.required}>
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col xs={12} sm={24} md={8}>
                <Form.Item name="total" label="Total">
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col xs={12} sm={24} md={8}>
                <Form.Item name="aplicacion_anticipo" label="Aplicación de Anticipo">
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col xs={12} sm={24} md={8}>
                <Form.Item name="2_supervision" label="2% Supervisión">
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col xs={12} sm={24} md={8}>
                <Form.Item name="2_millar_colegio" label="2 al Millar de Colegio">
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col xs={12} sm={24} md={8}>
                <Form.Item name="5_millar" label="5 al Millar">
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col xs={12} sm={24} md={8}>
                <Form.Item name="importe_neto" label="Importe Neto">
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col xs={12} sm={24} md={8}>
                <Form.Item name="fecha_autorizacion" label="Fecha de Autorización">
                  <DatePicker format="DD/MM/YYYY" disabled />
                </Form.Item>
              </Col>
              <Col xs={12} sm={24} md={8}>
                <Form.Item name="polizas" label="Pólizas">
                  <Input allowClear disabled />
                </Form.Item>
              </Col>
              <Col xs={12} sm={24} md={8}>
                <Form.Item name="movimiento_presupuestal" label="Movimiento Presupuestal">
                  <Input allowClear disabled />
                </Form.Item>
              </Col>
              <Col xs={12} sm={24} md={8}>
                <Form.Item name="numero_cuenta_pagar" label="No. de Cuenta por Pagar">
                  <Input allowClear disabled />
                </Form.Item>
              </Col>
              <Col xs={12} sm={24} md={8}>
                <Form.Item name="fecha_pago" label="Fecha de Pago">
                  <DatePicker format="DD/MM/YYYY" disabled />
                </Form.Item>
              </Col>
              <Col xs={12} sm={24} md={8}>
                <Form.Item name="numero_pago" label="No. de Pago">
                  <Input allowClear disabled />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    );
  }

  return (
    !visible ? <TabsPagos /> : <FormsPagos />
  );
}

export default PagoObraPublica;
