/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Row,
  Col,
  Spin,
  Button,
  Form,
  Modal,
} from 'antd';
import FormSubmitControls from 'components/FormSubmitControls';
import API from 'utils/api';
import Table from 'components/Table';
import { onError, onSuccess } from 'utils/handlers';
import ModalDelete from 'components/ModalDelete';
import NumericInput from 'components/NumericInput';
import Select from 'components/Select';

const baseURI = '/obrapublica/importes-anuales-de-programas-generales-de-obras/';

function ImportesAnuales({
  selectedRowKey: programa_general_de_obra,
}) {
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      setLoading(true);
      if (programa_general_de_obra) {
        const response = await API.get(baseURI, {
          params: {
            programa_general_de_obra,
          },
        });
        if (response?.status === 200) {
          setData(response.data);
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchData();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, [programa_general_de_obra]);

  const onCancel = () => {
    setSelectedRowKeys([]);
    form.resetFields();
    setVisible(false);
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      if (programa_general_de_obra) {
        if (!selectedRowKeys.length) {
          values.programa_general_de_obra = programa_general_de_obra;
          const response = await API.post(baseURI, values);
          if (response?.status === 201) {
            onSuccess(response, 'Agregado Correctamente');
            onCancel();
            await fetchData();
          }
        } else {
          const [key] = selectedRowKeys;
          const response = await API.put(`${baseURI}${key}/`, values);
          if (response?.status === 200) {
            onSuccess(response, 'Actualizado Correctamente');
            onCancel();
            await fetchData();
          }
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      if (programa_general_de_obra) {
        if (selectedRowKeys.length) {
          const [key] = selectedRowKeys;
          const response = await API.delete(`${baseURI}/${key}/`);
          if (response?.status === 204) {
            onSuccess(response, 'Eliminado correctamente');
            setVisibleAlert(false);
            onCancel();
            await fetchData();
          }
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    form.setFieldsValue(
      {
        ...record,
        programa_general_de_obra: record.programa_general_de_obra?.id,
      },
    );
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const columns = [
    {
      titleText: 'Año',
      dataIndex: 'anio',
      key: 'anio',
      width: 150,
    },
    {
      titleText: 'Importe Anual',
      dataIndex: 'importe_anual',
      key: 'importe_anual',
      width: 150,
    },
    {
      titleText: 'Estado',
      dataIndex: 'estados_globales',
      key: 'estados_globales',
      width: 150,
      render: (val) => (estadosGlobales.find((e) => e.id === val)?.descripcion),
    },
  ];

  const requiredRule = {
    required: true,
    message: 'El campo es requerido',
  };

  const rules = {
    required: [requiredRule],
  };

  const onClickEdit = () => {
    setVisible(true);
  };

  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        <Table
          cols={columns}
          data={data}
          rowSelection={rowSelection}
          handleOnRowClick={handleOnRowClick}
          controls={{
            onClickAdd,
            onClickEdit,
            onClickDelete,
          }}
          mobileColIndex={0}
        >
          <Modal
            visible={visible}
            title={(
              <FormSubmitControls
                label={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Importe Anual`}
                onFinish={onFinish}
                onCancel={onCancel}
                loading={loading}
              />
              )}
            closable={false}
            maskClosable={!loading}
          >
            <Form
              layout="vertical"
              form={form}
              onFinish={onFinish}
              scrollToFirstError
              initialValues={{ estados_globales: 1 }}
            >
              <Row>
                <Col span={24}>
                  <NumericInput
                    name="anio"
                    label="Año"
                    hasFeedback
                    required
                  />
                </Col>
                <Col span={24}>
                  <NumericInput
                    name="importe_anual"
                    label="Importe Anual"
                    hasFeedback
                    required
                  />
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="estados_globales"
                    rules={rules.required}
                    label="Estado"
                    hasFeedback
                  >
                    <Select dataSource={estadosGlobales} disabled={!selectedRowKeys.length} />
                  </Form.Item>
                </Col>
                <Form.Item hidden>
                  <Button htmlType="submit" />
                </Form.Item>
              </Row>
            </Form>
          </Modal>
          <ModalDelete
            onDelete={deleteItem}
            onCancel={() => setVisibleAlert(false)}
            visible={visibleAlert}
            content="El Importe Seleccionado"
            loading={loading}
          />
        </Table>
      </Spin>
    </Row>
  );
}

ImportesAnuales.propTypes = {
  selectedRowKey: PropTypes.number,
};

ImportesAnuales.defaultProps = {
  selectedRowKey: 0,
};

export default ImportesAnuales;
