import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Input,
  Form,
  Typography,
} from 'antd';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';

const { Text } = Typography;

const Password = ({
  options,
  rules,
}) => (
  <>
    <Col xs={24} sm={24} md={12}>
      <Row gutter={10} style={{ width: '100%' }}>
        <Col span={24}>
          <Form.Item
            name="password"
            rules={rules.password}
            label="Contraseña"
            hasFeedback
          >
            <Input.Password allowClear />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item
            name="confirmPassword"
            rules={rules.confirmPassword}
            label="Confirmar contraseña"
            hasFeedback
          >
            <Input.Password allowClear />
          </Form.Item>
        </Col>
      </Row>
    </Col>
    <Col
      sm={24}
      xs={24}
      md={12}
      style={{ paddingLeft: 20 }}
      className="pwd-requirements"
    >
      <Text strong>Contraseña debe de contener</Text>
      {options.map((op) => (
        <Row key={`check${op.key}`} className={op.checked ? 'success' : ''}>
          {op.checked ? <CheckCircleOutlined /> : <CloseCircleOutlined />}
          <Text type={op.checked ? 'success' : 'secondary'}>{op.label}</Text>
        </Row>
      ))}
    </Col>
  </>
);

Password.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  rules: PropTypes.object.isRequired,
};

export default Password;
