import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  // Input,
  Col,
  Row,
  Spin,
  Form,
  DatePicker,
  // Button,
  Card,
  Typography,
} from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import API from 'utils/api';
import FormSubmitControls from 'components/FormSubmitControls';
import Previewer from 'components/Previewer';
import CustomTable from 'components/Table';
import { onError } from 'utils/handlers';
import Select from 'components/Select';
import { isMutable } from 'utils/estadosGlobales';
// import DetallesDePoliza from 'components/Catalogos/DetallesDePolizas';
import IngresosCobrados from 'views/Contabilidad/IngresosRecaudados/Index';

import {
  SET_DOCUMENTOS_DE_POLIZA,
  SET_MODULOS_DE_POLIZAS,
  SET_SECCIONES_DE_POLIZAS,
  SET_TIPOS_DE_POLIZA,
} from 'store/reducers/catalogs';
import fetchSome from 'utils/fetchSome';
import { formatReceived } from 'utils/formatValues';

const { useForm } = Form;

const baseURI = '/contabilidad/ingreso-general/';

function FichaDeDeposito() {
  const {
    estadosGlobales,
    modulosDePolizas,
    seccionesDePolizas,
    documentosDePoliza,
    // tiposDePoliza,
  } = useSelector((state) => state.catalogs);
  const periodoFiscal = useSelector(({ auth }) => auth.periodoFiscalSelected);
  // const collapsedSidebar = useSelector(({ app }) => app.collapsedSidebar);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [formInfo] = useForm();
  const [loading, setLoading] = useState(false);
  const [mutable, setMutable] = useState(false);
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [base64, setBase64] = useState();
  const [visiblePreview, setVisiblePreview] = useState(false);
  const [unidadesRecaudadoras, setUnidadesRecaudadoras] = useState([]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await API.get(baseURI, {
        params: {
          periodo_fiscal: periodoFiscal.id,
        },
      });
      setData(response.data);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const authorize = async () => {
    setLoading(true);
    const response = await API.patch(`${baseURI}${selectedRowKeys}/`, { estados_globales: 4 });
    if (response.status === 200) {
      await fetchData();
    }
    setLoading(false);
  };

  useEffect(() => {
    const fetch = async () => {
      try {
        setLoading(true);
        const resUnidades = await API.get('/tesoreria/unidades-recaudadoras/');
        setUnidadesRecaudadoras(resUnidades.data || []);

        if (!modulosDePolizas?.length) {
          await fetchSome('configuraciones/modulos-de-polizas/', SET_MODULOS_DE_POLIZAS);
        }

        if (!seccionesDePolizas?.length) {
          await fetchSome('configuraciones/secciones-de-polizas/', SET_SECCIONES_DE_POLIZAS);
        }

        if (!documentosDePoliza?.length) {
          await fetchSome('configuraciones/documentos-de-polizas/', SET_DOCUMENTOS_DE_POLIZA);
        }

        if (!documentosDePoliza?.length) {
          await fetchSome('configuraciones/tipos-de-polizas/', SET_TIPOS_DE_POLIZA);
        }

        await fetchData();

        setLoading(false);
      } catch (err) {
        onError(err, setLoading);
      }
    };

    fetch();

    return () => API.tryCancel;
    // eslint-disable-next-line
  }, [periodoFiscal]);

  const onCancel = () => {
    setBase64();
    setVisible();
    // form.resetFields();
    setSelectedRowKeys([]);
    formInfo.resetFields();
  };

  const onClickExpand = async () => {
    try {
      setLoading(true);
      const [key] = selectedRowKeys;
      const res = await API.get(`${baseURI}${key}/`);
      if (res?.status === 200) {
        formInfo.setFieldsValue(formatReceived(res.data));
        setVisible(true);
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const columns = [
    {
      titleText: 'Fecha de Ingreso',
      dataIndex: 'fecha_del_ingreso',
      key: 'fecha_del_ingreso',
      width: 150,
    },
    {
      titleText: 'Unidad Recaudadora',
      dataIndex: 'unidad_recaudadora',
      key: 'unidad_recaudadora',
      width: 150,
      render: (val) => unidadesRecaudadoras.find((e) => e?.id === val).descripcion,
    },

  ];

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    setMutable(isMutable(record));
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  // const onClickPreview = async () => {
  //   try {
  //     setLoading(true);
  //     const [key] = selectedRowKeys;
  //     const res = await API.get(`${baseURI}${key}/pdf/`);
  //     if (res?.status === 200 && res.data) {
  //       setBase64(`data:application/pdf;base64,${res.data.data}`);
  //       setVisiblePreview(true);
  //     }
  //     setLoading(false);
  //   } catch (err) {
  //     onError(err, setLoading);
  //   }
  // };

  return (
    <Row align="center" justify="center" className="container">
      <Spin
        tip="Cargando..."
        spinning={loading}
      >
        {!visible ? (
          <CustomTable
            cols={columns}
            data={data}
            rowSelection={rowSelection}
            mobileColIndex={0}
            loading={loading}
            handleOnRowClick={handleOnRowClick}
            controls={{
              onClickExpand,
            }}
            allowSort={false}
            allowAdd={false}
            allowDelete={false}
            allowEdit={false}
            allowExpand
            rowKey="id"
            customActions={[
              {
                onClick: authorize,
                icon: <CheckCircleOutlined />,
                text: 'Autorizar',
                disabled: selectedRowKeys.length,
              },
            ]}
          />
        ) : (
          <Card
            className="form-container"
            title="Información Ficha de Depósito"
            extra={(
              <FormSubmitControls
                onCancel={onCancel}
                mutable={mutable}
                callback={(id, observaciones) => {
                  const [key] = selectedRowKeys;
                  const normalized = data.map((e) => {
                    if (e.id === key) {
                      const match = estadosGlobales.find((eg) => eg.id === id);
                      formInfo.setFieldsValue({
                        estados_globales: match.descripcion,
                        observaciones,
                      });
                      setMutable(false);
                      return {
                        ...e,
                        estados_globales: id,
                      };
                    }
                    return e;
                  });
                  setData(normalized);
                }}
                allowAuthorize
                allowCancel
                baseURI={baseURI}
                selectedRowKeys={selectedRowKeys}
              />
            )}
            bordered={false}
          >
            <Form
              layout="vertical"
              form={formInfo}
              className="no-margin"
            >
              <Row gutter={10}>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    label="Fecha de Ingreso"
                    name="fecha_del_ingreso"
                  >
                    <DatePicker placeholder="" format="DD/MM/YYYY" disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    label="Unidad Recaudadora"
                    name="unidad_recaudadora"
                  >

                    <Select
                      disabled
                      dataSource={
                      unidadesRecaudadoras
                    }
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Typography.Title level={4}>Ingresos Cobrados</Typography.Title>
                </Col>
                <Col span={24}>
                  <IngresosCobrados polizaParent={selectedRowKeys[0]} />
                </Col>

              </Row>
            </Form>
          </Card>
        )}
      </Spin>
      <Previewer
        base64={base64}
        baseURL={baseURI}
        id={selectedRowKeys[0]}
        visible={visiblePreview}
        setVisible={setVisiblePreview}
      />
    </Row>
  );
}

export default FichaDeDeposito;
