import React, { useState, useEffect } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Row,
  Spin,
  Tabs,
  Tooltip,
  Typography,
} from 'antd';
import FormSubmitControls from 'components/FormSubmitControls';
import Table from 'components/Table';
import Select from 'components/Select';
import { onError, onSuccess } from 'utils/handlers';
import API from 'utils/api';
import ModalDelete from 'components/ModalDelete';
import { useSelector } from 'react-redux';
import currencyFormatter from 'utils/currencyFormatter';
import { InputSearchPro } from 'components/InputSearch';
import FormItemLabel from 'components/FormItemLabel';
import NumericInput from 'components/NumericInput';
import Actividades from './ActividadesFID';
import IndicadoresFID from './IndicadoresFID';
import AreasFid from './AreasFID.';
import MetasFID from './MetasFID';
import PresupuestacionFID from './PresupuestacionFID';
import AvancesFID from './AvancesFID';

export const permissionProgramaPresupuestarioFID = {
  permissionModel: 'programapresupuestariofid',
};

const { TabPane } = Tabs;
const { useForm, useWatch } = Form;

const baseURI = '/presupuestos/programa-presupuestario-fid/';

const baseURIs = [
  baseURI,
  '/contabilidad/programas/',
  '/presupuestos/eje-transversal/',
  '/contabilidad/proyectos/',
  '/presupuestos/niveles-de-indicador/',
  '/configuraciones/periodos-fiscales/',
  '/presupuestos/medida-del-indicador/',
  '/estructura-organizacional/puestos/',
  '/presupuestos/tipos-de-indicador/',
  '/presupuestos/dimensiones-del-indicador/',
  '/presupuestos/frecuencias-de-medicion/',
  '/presupuestos/catalogo-de-comportamiento/',
  '/presupuestos/catalogo-valor-meta/',
  '/presupuestos/catalogo-factibilidad/',
  '/catalogos/periodos/',
];

// eslint-disable-next-line react/prop-types
function ProgramasPresupuestariosFID({ permission }) {
  // const urs = useSelector(({ catalogs }) => catalogs.urs).sort(levelSorter);
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  const periodoFiscal = useSelector(({ auth }) => auth.periodoFiscalSelected);
  const [form] = useForm();
  const aplicaEje = useWatch('aplica_eje_transversal', form) || false;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [currentTabKey, setCurrentTabKey] = useState('general');
  const [mutable, setMutable] = useState(true);
  const [proyectos, setProyectos] = useState([]);
  const [ejeTransversal, setEjeTransversal] = useState([]);
  const [programas, setProgramas] = useState([]);
  const [nivelesDeIndicador, setNivelesDeIndicador] = useState([]);
  const [objetivos, setObjetivos] = useState([]);
  const [planFound, setPlanFound] = useState([]);
  const [planShow, setPlanShow] = useState();
  const [periodosFiscales, setPeriodosFiscales] = useState([]);
  const [medidaDelIndicador, setMedidaDelIndicador] = useState([]);
  const [puestos, setPuestos] = useState([]);
  const [tipoIndicadores, setTipoIndicadores] = useState([]);
  const [dimensionesIndicadores, setDimensionesIndicadores] = useState([]);
  const [frecuenciaMedicion, setFrecuenciasMedicion] = useState([]);
  const [comportamiento, setComportamiento] = useState([]);
  const [tipoMeta, setTipoMeta] = useState([]);
  const [factibilidadMeta, setFactibilidadMeta] = useState([]);
  const [periodos, setPeriodos] = useState([]);
  const [matrices, setMatrices] = useState([]);
  const [eje, setEje] = useState([]);

  const [actividadId, setActividadId] = useState();
  const [actividadFid, setActividadFid] = useState([]);

  const [activateAdd, setActivateAdd] = useState(false);

  const [indicadorId, setIndicadorId] = useState();
  const [indicadoresData, setIndicadoresData] = useState([]);

  const [areas, setAreas] = useState([]);

  const [metas, setMetas] = useState([]);

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    setSelectedRowKeys([]);
    setActivateAdd(false);
    setPlanFound([]);
    setCurrentTabKey('general');
    setPlanShow();
    setActividadId();
    setIndicadorId();
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await API.get(baseURI, {
        params: {
          periodo_fiscal: periodoFiscal.id,
        },
      });
      setData(response.data);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const fetchIndicadores = async () => {
    try {
      setLoading(true);
      const [key] = selectedRowKeys;
      const resIndicadores = await API.get('/presupuestos/indicador-de-programa-presupuestario-fid/', {
        params: {
          programa_fid: key,
        },
      });
      setMatrices(resIndicadores.data);
      setLoading(false);
    } catch (error) {
      onError(error, setLoading);
    }
  };

  useEffect(() => {
    let mounted = true;
    const fetchAll = async () => {
      try {
        setLoading(true);
        const promises = baseURIs.map((uri) => API.get(uri));
        const [
          _data,
          _programas,
          _ejeTransversal,
          _proyectos,
          _nivelesDeIndicador,
          _periodosFiscales,
          _medidaDeIndicador,
          _puestos,
          _tipoIndicadores,
          _dimensionesIndicadores,
          _frecuenciasMedicion,
          _comportamiento,
          _tipoMeta,
          _factibilidadMeta,
          _periodos,

        ] = (await Promise.all(promises)).map((response) => response.data);
        if (mounted) {
          setData(_data || []);
          setProgramas(_programas || []);
          setEjeTransversal(_ejeTransversal || []);
          setProyectos(_proyectos || []);
          setNivelesDeIndicador(_nivelesDeIndicador || []);
          setPeriodosFiscales(_periodosFiscales);
          setMedidaDelIndicador(_medidaDeIndicador);
          setPuestos(_puestos);
          setTipoIndicadores(_tipoIndicadores);
          setDimensionesIndicadores(_dimensionesIndicadores);
          setFrecuenciasMedicion(_frecuenciasMedicion);
          setComportamiento(_comportamiento);
          setTipoMeta(_tipoMeta);
          setFactibilidadMeta(_factibilidadMeta);
          setPeriodos(_periodos);
          const resOds = await API.get('/catalogos/objetivo-de-desarrollo-sustentable/');
          setObjetivos(resOds.data.map((e) => ({
            ...e,
            nombreConDescripcion: `${e?.nombre || ' '} - ${e?.descripcion || ' '}`,
          })));

          const resEje = await API.get('/catalogos/nivel-del-plan-de-desarrollo/');
          setEje(resEje.data);

          const resIndicadores = await API.get('/presupuestos/indicador-de-programa-presupuestario-fid/', {
            params: {
              programa_fid: selectedRowKeys[0],
            },
          });
          setIndicadoresData(resIndicadores.data);
          setLoading(false);
        }
      } catch (err) {
        onError(err, setLoading);
      }
    };
    fetchAll();
    return () => { mounted = false; };
    // eslint-disable-next-line
  }, []);

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();

      const values = {
        ...form.getFieldsValue(),
        periodo_fiscal: periodoFiscal.id,
      };
      if (!selectedRowKeys.length) {
        const response = await API.post(baseURI, values);
        if (response.status === 201) {
          onSuccess(response, 'Agregado Correctamente');
          onCancel();
          await fetchData();
        }
      } else {
        const [key] = selectedRowKeys;
        const response = await API.put(`${baseURI}${key}/`, values);
        if (response.status === 200) {
          onSuccess(response, 'Editado Correctamente');
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading, [form], setCurrentTabKey);
    }
  };

  const columns = [
    {
      titleText: 'Numero',
      dataIndex: 'numero',
      key: 'numero',
      width: 100,
    },
    {
      titleText: 'Clave',
      dataIndex: 'clave',
      key: 'clave',
      width: 100,
    },
    {
      titleText: 'Nombre Programa Presupuestario (PP)',
      dataIndex: 'nombre',
      key: 'nombre',
      width: 350,
    },
    {
      titleText: 'Presupuesto Aprobado',
      dataIndex: 'presupuesto_aprobado',
      key: 'presupuesto_aprobado',
      width: 300,
      render: (value) => currencyFormatter(value || 0),
    },
    {
      titleText: 'Estado',
      dataIndex: 'estados_globales',
      key: 'estados_globales',
      width: 160,
      render: (id) => estadosGlobales.find((eg) => eg.id === id)?.descripcion,
    },
  ];

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    setTimeout(() => {
      form.setFieldsValue(record);
    });
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const deleteItem = async () => {
    try {
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`${baseURI}${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          onCancel();
          await fetchData();
          setVisibleAlert(false);
        }
      }
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const resetAndValidatePlan = () => {
    setPlanFound([]);
    setPlanShow();
    form.resetFields(['plan_de_desarrollo']);
    form.validateFields(['plan_de_desarrollo']);
  };

  // const getODS = async (ids = []) => {
  //   try {
  //     setLoading(true);
  //     if (ids.length) {
  //       const promises = ids.map((e) =>
  // API.get(`catalogos/objetivo-de-desarrollo-sustentable/${e}/`));
  //       const _ODS = (await Promise.all(promises)).map((e) => e.data);
  //       setObjetivos(_ODS || []);
  //     }
  //     setLoading(false);
  //   } catch (error) {
  //     onError(error, setLoading);
  //   }
  // };

  const searchPlanDeDesarrollo = async () => {
    try {
      await form.validateFields(['plan_de_desarrollo']);
      const plan = form.getFieldValue('plan_de_desarrollo');
      if (plan) {
        setLoading(true);
        const res = await API.get('catalogos/registro-de-nivel-del-plan-de-desarrollo/', {
          params: {
            numero: plan,
            tipo: 3,
          },
        });
        if (res?.status === 200 && res.data?.length) {
          const planDeDesarrollo = res.data[0];
          const ejeEncontrado = eje.find((val) => val.nombre_del_nivel === 'Eje');

          setPlanFound([planDeDesarrollo]);
          setPlanShow(true);
          // getODS(planDeDesarrollo.objetivo_de_desarrollo_sostenible);
          form.setFieldsValue({
            // programa_pmd: planDeDesarrollo.nombre,
            plan_de_desarrollo: planDeDesarrollo.id,
            objetivo_del_nivel: planDeDesarrollo.objetivo_del_nivel,
            plan_estatal_de_desarrollo: planDeDesarrollo.alineacion_plan_estatal_de_desarrollo,
            plan_nacional_de_desarrollo: planDeDesarrollo.alineacion_plan_nacional_de_desarrollo,
            plan_municipal_de_desarrollo: planDeDesarrollo.alineacion_plan_municipal_de_desarrollo,
            estrategia_pmd: ejeEncontrado.id,
          });
          await form.validateFields(['plan_de_desarrollo']);
        } else {
          message.info('No se encontraron coincidencias');
          setPlanFound([]);
          setPlanShow();
        }
        setLoading(false);
      }
    } catch (err) {
      onError(err, setLoading);
      setPlanFound([]);
    }
  };

  const fetchPlanDeDesarrollo = async (planID) => {
    try {
      setLoading(true);
      const res = await API.get('catalogos/registro-de-nivel-del-plan-de-desarrollo/', {
        params: {
          id: planID,
          tipo: 3,
        },
      });
      if (res?.status === 200 && res.data?.length) {
        const planDeDesarrollo = res.data[0];
        setPlanFound([planDeDesarrollo]);
        setPlanShow(true);
        // getODS(planDeDesarrollo.objetivo_de_desarrollo_sostenible);
        form.setFieldsValue({
          // programa_pmd: planDeDesarrollo.nombre,
          plan_de_desarrollo: planDeDesarrollo.id,
          objetivo_del_nivel: planDeDesarrollo.objetivo_del_nivel,
          plan_estatal_de_desarollo: planDeDesarrollo.alineacion_plan_estatal_de_desarrollo,
          plan_nacional_de_desarrollo: planDeDesarrollo.alineacion_plan_nacional_de_desarrollo,
          plan_municipal_de_desarrollo: planDeDesarrollo.alineacion_plan_municipal_de_desarrollo,
        });
        await form.validateFields(['plan_de_desarrollo']);
      } else {
        message.info('No se encontraron coincidencias');
        setPlanFound([]);
        setPlanShow(false);
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
      setPlanFound([]);
    }
  };

  const onClickEdit = async () => {
    try {
      setLoading(true);
      const [key] = selectedRowKeys;
      const match = data.find((e) => e.id === key);
      if (match?.plan_de_desarrollo) {
        fetchPlanDeDesarrollo(match.plan_de_desarrollo);
      }
      setActivateAdd(true);
      setTimeout(() => {
        form.setFieldsValue({
          ...match,
        });
      });
      fetchIndicadores();
      setLoading(false);
      setVisible(true);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const requiredRule = {
    required: mutable,
    message: 'Este campo es requerido',
  };

  const rules = {
    required: [requiredRule],
  };

  return (
    <Row className="container" justify="center">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible ? (
          <Table
            cols={columns}
            data={data}
            permission={permission}
            rowSelection={rowSelection}
            handleOnRowClick={handleOnRowClick}
            controls={{
              onClickAdd,
              onClickEdit,
              onClickDelete,
            }}
            mobileColIndex={0}
          />
        ) : (
          <Card
            bordered={false}
            className="form-container"
            title={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Programa`}
            extra={(
              <FormSubmitControls
                onCancel={onCancel}
                onFinish={() => onFinish()}
                allowAuthorize
                requireAutorizationDate
                requireCancelDate
                allowCancel
                loading={loading}
                mutable={mutable}
                selectedRowKeys={selectedRowKeys}
                baseURI={baseURI}
                callback={(estados_globales) => {
                  const [key] = selectedRowKeys;
                  const normalized = [...data]
                    .map((e) => (e.id === key ? { ...e, estados_globales } : e));
                  const match = normalized.find((e) => e.id === key);
                  form.setFieldsValue(match);
                  setMutable(false);
                  setData(normalized);
                }}
              />
            )}
          >
            <Tabs
              activeKey={currentTabKey}
              onChange={setCurrentTabKey}
              // type="card"
              tabPosition="left"
            >
              <TabPane
                key="general"
                tab="Alineación"
                forceRender
              >
                <Form
                  form={form}
                  layout="vertical"
                  name="general"
                  onFinish={() => onFinish()}
                  className={!mutable && 'without-feedback'}
                  scrollToFirstError
                  initialValues={{
                    estados_globales: 1,
                    // aplica_un_anio: false,
                    aplica_eje_transversal: false,
                    es_proyecto_nuevo: false,
                  }}
                >
                  <Row gutter={10}>
                    <Col xs={24} sm={24} md={6}>
                      <Form.Item
                        label="Numero de Proyecto"
                        name="numero"
                        rules={rules.required}
                      >
                        <Input
                          allowClear
                          disabled={!mutable}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={6}>
                      <Form.Item
                        label="Clave de Proyecto"
                        name="clave"
                        rules={rules.required}
                      >
                        <Input
                          allowClear
                          disabled={!mutable}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item
                        label="Programa Presupuestario"
                        name="nombre"
                        rules={rules.required}
                      >
                        <Input
                          allowClear
                          disabled={!mutable}
                        />
                      </Form.Item>
                    </Col>
                    <InputSearchPro
                      label="Programa (PMD)"
                      tooltip="Búsqueda por clave de Programa (Este campo es opcional)"
                      name="plan_de_desarrollo"
                      disabled={!mutable}
                      // rules={rules.required}
                      onPressEnter={() => { }}
                      breakPoints={{
                        md: 8,
                      }}
                      inputProps={{
                        onPressEnter: () => searchPlanDeDesarrollo(),
                        onChange: ({ target: { value } }) => {
                          if (!value) {
                            resetAndValidatePlan();
                          }
                        },
                      }}
                      resultProps={{
                        disabled: !mutable,
                        name: 'plan_de_desarrollo',
                        label: 'Programa (PMD)',
                        dataSource: planFound,
                        labelProp: 'nombre',
                        keyLabelRender: true,
                        keyProp: 'numero',
                        onClear: () => resetAndValidatePlan(),
                        breakPoints: {
                          md: 8,
                        },
                      }}
                    />
                    {planShow && (
                    <>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          label="Objetivo PMD"
                          name="objetivo_del_nivel"
                        >
                          <Input.TextArea
                            autoSize={{ minRows: 3, maxRows: 3 }}
                            allowClear
                            disabled
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          label="Estrategia PMD"
                          name="estrategia_pmd"
                        >
                          <Select
                            dataSource={eje}
                            labelProp="nombre_del_nivel"
                            disabled
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          label={(
                            <FormItemLabel
                              longTitle="Plan Municipal de Desarrollo"
                              shortTitle="PMD"
                              hideOn={['sm', 'md']}
                            />
                        )}
                          name="plan_municipal_de_desarrollo"
                        >
                          <Input disabled allowclear />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          label={(
                            <FormItemLabel
                              longTitle="Plan Estatal de Desarrollo"
                              shortTitle="PED"
                              hideOn={['sm', 'md']}
                            />
                        )}
                          name="plan_estatal_de_desarollo"
                        >
                          <Input disabled allowclear />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          label={(
                            <FormItemLabel
                              longTitle="Plan Nacional de Desarrollo"
                              shortTitle="PND"
                              hideOn={['sm', 'md']}
                            />
                        )}
                          name="plan_nacional_de_desarrollo"
                        >
                          <Input disabled allowclear />
                        </Form.Item>
                      </Col>
                    </>
                    )}
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        label="Objetivos de Desarrollo Sostenible (ODS)"
                        name="objetivos_de_desarrollo_sostenible"
                      // rules={rules.required}
                      >
                        <Select
                          dataSource={objetivos}
                          labelProp="nombreConDescripcion"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        label="¿Aplica Eje Transversal?"
                        name="aplica_eje_transversal"
                      // rules={rules.required}
                      >
                        <Select trueFalse />
                      </Form.Item>
                    </Col>
                    {aplicaEje && (
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          label="Eje Transversal"
                          name="eje_transversal"
                        // rules={rules.required}
                        >
                          <Select
                            keyLabelRender
                            labelProp="nombre"
                            dataSource={ejeTransversal}
                          />
                        </Form.Item>
                      </Col>
                    )}
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        label="¿Es proyecto de Inversion?"
                        name="es_proyecto_inversion"
                      // rules={rules.required}
                      >
                        <Select trueFalse />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        label="Nuevo Proyecto"
                        name="es_proyecto_nuevo"
                      // rules={rules.required}
                      >
                        <Select trueFalse />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        label="Proyecto"
                        name="proyecto"
                      // rules={rules.required}
                      >
                        <Select
                          keyLabelRender
                          labelProp="nombre"
                          dataSource={proyectos}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        label="Programa"
                        name="programa"
                        rules={rules.required}
                      >
                        <Select
                          keyLabelRender
                          labelProp="nombre"
                          dataSource={programas}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        label="Proyecto en Proceso"
                        name="proyecto_en_proceso"
                      // rules={rules.required}
                      >
                        <Select trueFalse />
                      </Form.Item>
                    </Col>
                    {/* <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        label="¿Aplica a un solo Año?"
                        name="aplica_un_anio"
                      >
                        <Select trueFalse />
                      </Form.Item>
                    </Col> */}
                    <Col xs={24} sm={24} md={8}>
                      <NumericInput
                        label="Presupuesto Aprobado"
                        name="presupuesto_aprobado"
                        prefix="$"
                      />
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        label="Estado"
                        name="estados_globales"
                      >
                        <Select
                          dataSource={estadosGlobales}
                          disabled={!selectedRowKeys.length || !mutable}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Tooltip
                    title="Las areas solo se pueden agregar al editar un programa presupuestal, o al finalizar de agregar el programa."
                  >
                    <Typography.Title
                      level={4}
                    >
                      Areas
                    </Typography.Title>
                  </Tooltip>
                  <Col xs={24} sm={24} md={24}>
                    <AreasFid
                      selectedRowKey={selectedRowKeys}
                      activateAdd={activateAdd}
                      setAreas={setAreas}
                    />
                  </Col>

                  <Form.Item hidden>
                    <Button htmlType="submit" />
                  </Form.Item>
                </Form>
              </TabPane>

              <TabPane
                key="actividades"
                tab="Actividades"
                forceRender
                disabled={!selectedRowKeys.length}
              >
                <Actividades
                  selectedRowKey={selectedRowKeys}
                  setActividadId={setActividadId}
                  setActividadFid={setActividadFid}
                />
              </TabPane>
              <TabPane
                key="indicadores"
                tab="Indicadores"
                forceRender
                disabled={!selectedRowKeys.length || !actividadId}
              >
                <IndicadoresFID
                  selectedRowKey={selectedRowKeys}
                  medidaDelIndicador={medidaDelIndicador}
                  puestos={puestos}
                  tipoDeIndicador={tipoIndicadores}
                  dimensionDeIndicador={dimensionesIndicadores}
                  frecuenciaDeMedicion={frecuenciaMedicion}
                  comportamiento={comportamiento}
                  tipoDeMeta={tipoMeta}
                  factibilidadMeta={factibilidadMeta}
                  periodosFiscales={periodosFiscales}
                  calendarizacion={periodos}
                  actividadId={actividadId}
                  setIndicadorId={setIndicadorId}
                  setIndicadoresData={setIndicadoresData}

                />
              </TabPane>

              <TabPane
                key="metas"
                tab="Metas"
                forceRender
                disabled={!selectedRowKeys.length || !indicadorId}
              >
                <MetasFID
                  selectedRowKey={selectedRowKeys}
                  nivelesDeIndicador={nivelesDeIndicador}
                  matrices={matrices}
                  medidaDelIndicador={medidaDelIndicador}
                  frecuenciaDeMedicion={frecuenciaMedicion}
                  factibilidadMeta={factibilidadMeta}
                  tipoDeMeta={tipoMeta}
                  indicadorId={indicadorId}
                  indicadoresData={indicadoresData}
                  setMetas={setMetas}

                />
              </TabPane>

              <TabPane
                key="presupuestacion"
                tab="Presupuestación"
                forceRender
                disabled={!selectedRowKeys.length}
                // disabled
              >
                <PresupuestacionFID
                  areas={areas}

                />
              </TabPane>

              <TabPane
                key="avances"
                tab="Avances"
                forceRender
                disabled={!selectedRowKeys.length}
                // disabled
              >
                <AvancesFID
                  selectedRowKey={selectedRowKeys}
                  metas={metas}
                  indicadoresData={indicadoresData}
                  actividadFid={actividadFid}
                />
              </TabPane>

            </Tabs>
          </Card>
        )}
      </Spin>
      <ModalDelete
        onDelete={deleteItem}
        onCancel={() => {
          setVisibleAlert(false);
          setSelectedRowKeys([]);
        }}
        visible={visibleAlert}
        content="eliminar el programa seleccionado"
      />
    </Row>
  );
}

export default ProgramasPresupuestariosFID;
