import React, { useState, useEffect } from 'react';

import {
  Card,
  Form,
  Row,
  Spin,
  Col,
  Input,
  Button,
  Tabs,
} from 'antd';

import {
  ArrowLeftOutlined,
  StopOutlined,
  FilePdfOutlined,
  FileExcelOutlined,
  CheckCircleOutlined,
  CloseOutlined,
  FullscreenOutlined,
  FullscreenExitOutlined,
  FormOutlined,
} from '@ant-design/icons';
import API from 'utils/api';
import { onError } from 'utils/handlers';
import Table from 'components/Table';
import Requisiciones from './index';

function RequisicionesConsolidadas() {
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAdd, setVisibleAdd] = useState(false);
  const [currentTabKey, setCurrentTabKey] = useState('detalle');
  const [mutable, setMutable] = useState(true);

  const { TabPane } = Tabs;

  const rowSelection = {
    selectedRowKeys,
    type: 'check',
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchData();
    return () => API.tryCancel;
  }, []);

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    setTimeout(() => form.setFieldsValue(record));
  };

  const onChangeTabKey = () => { };

  const onClickAdd = () => {
    setVisible(true);
    setVisibleAdd(true);
  };

  const onClickEdit = () => {
    setVisible(true);
    setVisibleAdd(false);
  };

  const onAutorizar = () => {
    setVisible(true);
    setVisibleAdd(false);
  };

  const onCancelar = () => { };

  const onRechazar = () => { };

  const onConsolidar = () => { };

  const onDesconsolidar = () => { };

  const onAsignarCotizador = () => { };

  const onExportarPDF = () => { };

  const onExportarExcel = () => { };

  const onClickAddDetalle = () => { };

  const onClickDeleteDetalle = () => { };

  const onClickEstudioMercado = () => { };

  const onAutorizarDetalle = () => { };

  const onRechazarDetalle = () => { };

  const onCancelarDetalle = () => { };

  const onAplicarReservasDetalle = () => { };

  const columns = [
    {
      titleText: 'No. de Requisición Consolidada',
      dataIndex: 'numero_requisicion_consolidada',
      key: 'numero_requisicion_consolidada',
      width: 250,
    },
    {
      titleText: 'Folio Agrupador',
      dataIndex: 'folio_agrupador',
      key: 'folio_agrupador',
      width: 200,
    },
    {
      titleText: 'Tipo de Operación',
      dataIndex: 'tipo_operacion',
      key: 'tipo_operacion',
      width: 200,
    },
    {
      titleText: 'Fecha',
      dataIndex: 'fecha',
      key: 'fecha',
      width: 150,
    },
    {
      titleText: 'Importe',
      dataIndex: 'importe',
      key: 'importe',
      width: 150,
    },
    {
      titleText: 'Consolidó',
      dataIndex: 'consolido',
      key: 'consolido',
      width: 200,
    },
    {
      titleText: 'Puesto de quien Consolidó',
      dataIndex: 'puesto_consolido',
      key: 'puesto_consolido',
      width: 350,
    },
    {
      titleText: 'Área de quien Consolidó',
      dataIndex: 'area_consolido',
      key: 'area_consolido',
      width: 300,
    },
    {
      titleText: 'Prioridad',
      dataIndex: 'prioridad',
      key: 'prioridad',
      width: 150,
    },
    {
      titleText: 'Vo.Bo. Tesorero',
      dataIndex: 'vobo_tesorero',
      key: 'vobo_tesorero',
      width: 250,
    },
    {
      titleText: 'Estado',
      dataIndex: 'estado',
      key: 'estado',
      width: 150,
    },
    {
      titleText: 'Movimiento Presupuestal',
      dataIndex: 'movimiento_presupuestal',
      key: 'movimiento_presupuestal',
      width: 200,
    },
    {
      titleText: 'Cotizador',
      dataIndex: 'cotizador',
      key: 'cotizador',
      width: 250,
    },
    {
      titleText: 'Proceso Adquisitivo',
      dataIndex: 'proceso_adquisitivo',
      key: 'proceso_adquisitivo',
      width: 200,
    },
    {
      titleText: 'Orden de Compra/Contratación',
      dataIndex: 'orden_compra_contratacion',
      key: 'orden_compra_contratacion',
      width: 300,
    },
    {
      titleText: 'Recepción',
      dataIndex: 'recepcion',
      key: 'recepcion',
      width: 150,
    },
    {
      titleText: 'Cuenta por Pagar',
      dataIndex: 'cuenta_por_pagar',
      key: 'cuenta_por_pagar',
      width: 200,
    },
    {
      titleText: 'Pago',
      dataIndex: 'pago',
      key: 'pago',
      width: 150,
    },
  ];

  const columnsDetalle = [
    {
      titleText: 'Partida Presupuestal',
      dataIndex: 'partida_presupuestal',
      key: 'partida_presupuestal',
      width: 350,
    },
    {
      titleText: 'COG',
      dataIndex: 'cog',
      key: 'cog',
      width: 150,
    },
    {
      titleText: 'Código de Producto',
      dataIndex: 'codigo_producto',
      key: 'codigo_producto',
      width: 250,
    },
    {
      titleText: 'Descripción',
      dataIndex: 'descripcion',
      key: 'descripcion',
      width: 200,
    },
    {
      titleText: 'Unidad de Medida',
      dataIndex: 'unidad_medida',
      key: 'unidad_medida',
      width: 200,
    },
    {
      titleText: 'Cantidad',
      dataIndex: 'cantidad',
      key: 'cantidad',
      width: 150,
    },
    {
      titleText: 'Precio Unitario',
      dataIndex: 'precio_unitario',
      key: 'precio_unitario',
      width: 200,
    },
    {
      titleText: 'Subtotal',
      dataIndex: 'subtotal',
      key: 'subtotal',
      width: 150,
    },
    {
      titleText: 'IVA',
      dataIndex: 'iva',
      key: 'iva',
      width: 150,
    },
    {
      titleText: 'Total',
      dataIndex: 'total',
      key: 'total',
      width: 150,
    },
    {
      titleText: 'Techo Presupuestal',
      dataIndex: 'techo_presupuestal',
      key: 'techo_presupuestal',
      width: 200,
    },
    {
      titleText: 'Verificación de Existencia',
      dataIndex: 'verificacion_existencia',
      key: 'verificacion_existencia',
      width: 250,
    },
  ];

  const columnsResumen = [
    {
      titleText: 'Código Producto',
      dataIndex: 'codigo_producto',
      key: 'codigo_producto',
      width: 350,
    },
    {
      titleText: 'Descripción',
      dataIndex: 'descripcion',
      key: 'descripcion',
      width: 200,
    },
    {
      titleText: 'Unidad de Medida',
      dataIndex: 'unidad_medida',
      key: 'unidad_medida',
      width: 200,
    },
    {
      titleText: 'Cantidad',
      dataIndex: 'cantidad',
      key: 'cantidad',
      width: 150,
    },
    {
      titleText: 'Precio Unitario',
      dataIndex: 'precio_unitario',
      key: 'precio_unitario',
      width: 200,
    },
    {
      titleText: 'Subtotal',
      dataIndex: 'subtotal',
      key: 'subtotal',
      width: 150,
    },
    {
      titleText: 'IVA',
      dataIndex: 'iva',
      key: 'iva',
      width: 150,
    },
    {
      titleText: 'Total',
      dataIndex: 'total',
      key: 'total',
      width: 150,
    },
  ];

  const columnsFlujoProceso = [
    {
      titleText: 'No.',
      dataIndex: 'numero',
      key: 'numero',
      width: 150,
    },
    {
      titleText: 'Actividad',
      dataIndex: 'actividad',
      key: 'actividad',
      width: 150,
    },
    {
      titleText: 'Rol del Responsable',
      dataIndex: 'rol_responsable',
      key: 'rol_responsable',
      width: 200,
    },
    {
      titleText: 'Rol de Firma',
      dataIndex: 'rol_firma',
      key: 'rol_firma',
      width: 200,
    },
    {
      titleText: 'Fecha de Realización',
      dataIndex: 'fecha_realizacion',
      key: 'fecha_realizacion',
      width: 200,
    },
    {
      titleText: 'Puesto',
      dataIndex: 'puesto',
      key: 'puesto',
      width: 150,
    },
    {
      titleText: 'Empleado',
      dataIndex: 'empleado',
      key: 'empleado',
      width: 150,
    },
    {
      titleText: 'Unidad Responsable',
      dataIndex: 'unidad_responsable',
      key: 'unidad_responsable',
      width: 200,
    },
    {
      titleText: 'Estado de Proceso',
      dataIndex: 'estado_proceso',
      key: 'estado_proceso',
      width: 200,
    },
    {
      titleText: 'Aprobado/Rechazado',
      dataIndex: 'aprobado_rechazado',
      key: 'aprobado_rechazado',
      width: 200,
    },
    {
      titleText: 'Causas de Rechazo',
      dataIndex: 'causas_rechazo',
      key: 'causas_rechazo',
      width: 250,
    },
    {
      titleText: 'Observaciones',
      dataIndex: 'observaciones',
      key: 'observaciones',
      width: 250,
    },
  ];

  const onFinish = () => ({});
  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    setSelectedRowKeys([]);
    setMutable(true);
  };

  function ConsolidacionRequisicionesGrid() {
    return (
      <Table
        cols={columns}
        data={[]}
        rowSelection={rowSelection}
        handleOnRowClick={handleOnRowClick}
        mobileColIndex={0}
        controls={{
          onClickAdd,
          onClickEdit,
        }}
        customActions={[
          {
            onClick: onAutorizar,
            text: 'Autorizar',
            icon: <CheckCircleOutlined />,
            disabledBySelection: false,
            className: 'success',
          },
          {
            onClick: onCancelar,
            text: 'Cancelar',
            icon: <StopOutlined />,
            disabledBySelection: false,
          },
          {
            onClick: onRechazar,
            text: 'Rechazar',
            icon: <CloseOutlined />,
            disabledBySelection: false,
          },
          {
            onClick: onConsolidar,
            text: 'Consolidar',
            icon: <FullscreenExitOutlined />,
            disabledBySelection: false,
          },
          {
            onClick: onDesconsolidar,
            text: 'Desconsolidar',
            icon: <FullscreenOutlined />,
            disabledBySelection: false,
          },
          {
            onClick: onAsignarCotizador,
            text: 'Asignar Cotizador',
            icon: <FormOutlined />,
            disabledBySelection: false,
          },
          {
            onClick: onExportarPDF,
            icon: <FilePdfOutlined />,
            text: 'Exportar PDF',
            disabledBySelection: false,
          },
          {
            onClick: onExportarExcel,
            icon: <FileExcelOutlined />,
            text: 'Exportar Excel',
            disabledBySelection: false,
          },
        ]}
      />
    );
  }

  function ConsolidacionAdd() {
    return <Requisiciones />;
  }

  function ConsolidacionesEditContainer() {
    return (
      <Card
        extra={(
          <Button
            icon={<ArrowLeftOutlined />}
            className="ant-btn ant-btn-link"
            onClick={onCancel}
          >
            Regresar
          </Button>
        )}
        bordered={false}
      >
        {visibleAdd ? (<ConsolidacionAdd />) : (<ConsolidacionEdit />)}
      </Card>
    );
  }

  function ConsolidacionEdit() {
    return (
      <>
        <Card
          bordered={false}
        />
        <Tabs
          activeKey={currentTabKey}
          onChange={mutable ? setCurrentTabKey : onChangeTabKey}
          type="card"
        >
          <TabPane
            tab="Detalle"
            key="detalle"
            forceRender
          >
            <ConsolidacionEditDetalle />
          </TabPane>
          <TabPane
            tab="Resumen"
            key="resumen"
            forceRender
          >
            <ConsolidacionEditResumen />
          </TabPane>
          <TabPane
            tab="Criterios"
            key="criterios"
            forceRender
          >
            {/* <>TODO</> */}
          </TabPane>
          <TabPane
            tab="Anexos"
            key="anexos"
            forceRender
          >
            {/* <>TODO</> */}
          </TabPane>
          <TabPane
            tab="Justificación"
            key="justificacion"
            forceRender
          >
            {/* <>TODO</> */}
          </TabPane>
          <TabPane
            tab="Observaciones"
            key="observaciones"
            forceRender
          >
            {/* <>TODO</> */}
          </TabPane>
          <TabPane
            tab="Adicionales"
            key="adicionales"
            forceRender
          >
            {/* <>TODO</> */}
          </TabPane>
          <TabPane
            tab="Flujo de Proceso"
            key="flujo_proceso"
            forceRender
          >
            <ConsolidacionEditFlujoProceso />
          </TabPane>
        </Tabs>

      </>
    );
  }

  function ConsolidacionEditDetalle() {
    return (
      <>
        <Row gutter={10}>
          <Col md={24} sm={24} xs={24}>
            <Form
              layout="vertical"
              form={form}
              onFinish={onFinish}
              scrollToFirstError
              name="detalle"
            >
              <Row gutter={10}>
                <Col xs={12} sm={24} md={12}>
                  <Form.Item
                    name="proceso_adquisitivo"
                    label="Proceso Adquisitivo"
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={24} md={12}>
                  <Form.Item
                    name="numero_requisicion"
                    label="No. de Requisición"
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={24} md={12}>
                  <Form.Item
                    name="area"
                    label="Área"
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={24} md={12}>
                  <Form.Item
                    name="lugar_entrega"
                    label="Lugar de Entrega"
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
        <Table
          cols={columnsDetalle}
          data={[]}
          rowSelection={rowSelection}
          handleOnRowClick={handleOnRowClick}
          mobileColIndex={0}
          controls={{
            onClickAdd: onClickAddDetalle,
            onClickDelete: onClickDeleteDetalle,
          }}
          customActions={[
            {
              onClick: onClickEstudioMercado,
              text: 'Estudio de Mercado',
              icon: <FormOutlined />,
              disabledBySelection: false,
            },
            {
              onClick: onAutorizarDetalle,
              text: 'Autorizar',
              icon: <CheckCircleOutlined />,
              disabledBySelection: false,
              className: 'success',
            },
            {
              onClick: onRechazarDetalle,
              text: 'Rechazar',
              icon: <CloseOutlined />,
              disabledBySelection: false,
            },
            {
              onClick: onCancelarDetalle,
              text: 'Cancelar',
              icon: <CloseOutlined />,
              disabledBySelection: false,
            },
            {
              onClick: onAplicarReservasDetalle,
              icon: <CheckCircleOutlined />,
              text: 'Aplicar Reservas',
              disabledBySelection: false,
            },
          ]}
        />

      </>
    );
  }

  function ConsolidacionEditResumen() {
    return (
      <Table
        cols={columnsResumen}
        data={[]}
        rowSelection={rowSelection}
        handleOnRowClick={handleOnRowClick}
        mobileColIndex={0}
      />
    );
  }

  function ConsolidacionEditFlujoProceso() {
    return (
      <Table
        cols={columnsFlujoProceso}
        data={[]}
        rowSelection={rowSelection}
        handleOnRowClick={handleOnRowClick}
        mobileColIndex={0}
      />
    );
  }

  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible ? <ConsolidacionRequisicionesGrid /> : <ConsolidacionesEditContainer />}
      </Spin>
    </Row>
  );
}

export default RequisicionesConsolidadas;
