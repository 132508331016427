import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Spin,
  Card,
} from 'antd';
import API from 'utils/api';
import { onError, onSuccess } from 'utils/handlers';
import PlainTransfer from 'components/PlainTransfer';
import Table from 'components/Table';
import FormSubmitControls from 'components/FormSubmitControls';
import ModalDelete from 'components/ModalDelete';

export const permissionRoles = {
  permissionModel: 'group',
};

const baseURI = 'estructura-organizacional/grupos/';

// eslint-disable-next-line react/prop-types
function Roles({ permission }) {
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [permisos, setPermisos] = useState([]);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await API.get(baseURI);
      if (res?.status === 200) {
        const mappedData = res.data.map((item) => ({
          ...item,
          name: item.name.split('-').shift(),
        }));
        setData(mappedData);
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    const fetchAll = async () => {
      try {
        const resPermisos = await API.get('usuarios/permisos');
        setPermisos(resPermisos.data);
        await fetchData();
      } catch (err) {
        onError(err, setLoading);
      }
    };
    fetchAll();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    setSelectedRowKeys([]);
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      if (!selectedRowKeys.length) {
        const response = await API.post(baseURI, values);
        if (response?.status === 201) {
          onSuccess(response, 'Agregado correctamente');
          onCancel();
          await fetchData();
        }
      } else {
        const [key] = selectedRowKeys;
        const response = await API.put(`${baseURI}${key}/`, values);
        if (response?.status === 200) {
          onSuccess(response, 'Actualizado correctamente');
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const deleteItem = async () => {
    try {
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`${baseURI}${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          setVisibleAlert(false);
          onCancel();
          await fetchData();
        }
      }
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    setTimeout(() => {
      form.setFieldsValue(record);
    });
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const onClickEdit = () => {
    setVisible(true);
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
  };

  const columns = [
    {
      titleText: 'Rol',
      dataIndex: 'name',
      key: 'name',
    },
  ];

  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible ? (
          <Table
            cols={columns}
            mobileCols={columns}
            data={data}
            permission={permission}
            rowSelection={rowSelection}
            handleOnRowClick={handleOnRowClick}
            controls={{
              onClickAdd,
              onClickEdit,
              onClickDelete,
            }}
          />
        ) : (
          <Card
            className="form-container"
            title={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Rol`}
            extra={(
              <FormSubmitControls onFinish={onFinish} onCancel={onCancel} loading={loading} />
            )}
            bordered={false}
          >
            <Form
              layout="vertical"
              form={form}
              onFinish={onFinish}
              scrollToFirstError
            >
              <Col span={24}>
                <Form.Item
                  name="name"
                  rules={rules.required}
                  label="Nombre del Rol"
                  hasFeedback
                >
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col span={24}>
                <PlainTransfer
                  dataSource={permisos}
                  label="Permisos"
                  form={form}
                  formItemName="permissions"
                  filterProp="name"
                />
              </Col>
              <Form.Item hidden>
                <Button htmlType="submit" />
              </Form.Item>
            </Form>
          </Card>
        )}
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => setVisibleAlert(false)}
          visible={visibleAlert}
          content={`Rol ${form.getFieldValue('name')}`}
          loading={loading}
        />
      </Spin>
    </Row>
  );
}

export default Roles;
