import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Spin,
  message,
  Typography,
} from 'antd';

import API from 'utils/api';
import { onError, onSuccess } from 'utils/handlers';
import Table from 'components/Table';
import FormSubmitControls from 'components/FormSubmitControls';
import ModalDelete from 'components/ModalDelete';
import Select from 'components/Select';
import createClave from 'utils/keyGenerator';
import { isMutable, isAuthorized } from 'utils/estadosGlobales';
import getFormattedValues from 'utils/formatValues';
import NumericInput from 'components/NumericInput';
import currencyFormatter from 'utils/currencyFormatter';
import Proveedores from './Proveedores';

export const model = 'solicituddeproducto';
export const catalogsModels = ['unidaddemedida', 'clasificaciondeactivo'];
function SolicitudesDeProductos({
  requisicionId,
  disabled,
  permission,
}) {
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  const baseURI = `adquisiciones/requisiciones/${requisicionId}/solicitudes-de-productos/`;
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);
  const [mutable, setMutable] = useState(true);

  const [unidadesDeMedida, setUnidadesDeMedida] = useState([]);
  const [clasificaciones, setClasificaciones] = useState([]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await API.get(baseURI);
      setData(res.data);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const fetchAll = async () => {
    try {
      setLoading(true);

      const resUDM = await API.get('configuraciones/unidades-de-medida-administrador/');
      setUnidadesDeMedida(resUDM.data);

      const resclasificaciones = await API.get('controlpatrimonial/clasificaciones-de-activos/');
      setClasificaciones(resclasificaciones.data);

      await fetchData();
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchAll();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    setSelectedRowKeys([]);
    setMutable(true);
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      const formattedValues = getFormattedValues(values);
      if (!selectedRowKeys.length) {
        const response = await API.post(baseURI, formattedValues);
        if (response?.status === 201) {
          onSuccess(response, 'Agregado correctamente');
          onCancel();
        }
      } else {
        const [key] = selectedRowKeys;
        const response = await API.put(`${baseURI}${key}/`, formattedValues);
        if (response?.status === 200) {
          onSuccess(response, 'Actualizado correctamente');
          onCancel();
        }
      }
      await fetchData();
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          setVisibleAlert(false);
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    setMutable(isMutable(record));
    setTimeout(() => form.setFieldsValue(record));
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickAdd = () => {
    onCancel();
    createClave(data, form);
    setVisible(true);
  };

  const onClickEdit = () => setVisible(true);

  const onClickDelete = () => {
    const [key] = selectedRowKeys;
    const match = data.find((e) => e.id === key);
    if (isMutable(match)) {
      setVisibleAlert(true);
    } else if (isAuthorized(match)) {
      message.warn('No se puede eliminar un registro autorizado');
    } else {
      message.warn('No se puede eliminar un registro cancelado');
    }
  };

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
  };

  const columns = [
    {
      titleText: 'Producto',
      dataIndex: 'producto',
      key: 'producto',
      width: 200,
    },
    {
      titleText: 'Especificación',
      dataIndex: 'especificacion',
      key: 'especificacion',
      width: 300,
    },
    {
      titleText: 'Total Estimado',
      dataIndex: 'total_estimado',
      key: 'total_estimado',
      width: 150,
      render: (e) => currencyFormatter(e || 0),
      align: 'right',
    },
    {
      titleText: 'Unidad de Medida',
      dataIndex: 'unidad_de_medida',
      key: 'unidad_de_medida',
      width: 300,
      render: (e) => unidadesDeMedida.find((i) => i.id === e)?.nombre,
    },
    {
      titleText: 'Estado',
      dataIndex: 'estados_globales',
      key: 'estados_globales',
      width: 100,
      render: (e) => estadosGlobales.find((i) => i.id === e)?.descripcion,
    },
  ];

  const controls = () => ({
    onClickAdd,
    onClickEdit,
    onClickDelete,
  });

  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible ? (
          <Table
            cols={columns}
            data={data}
            rowSelection={rowSelection}
            handleOnRowClick={handleOnRowClick}
            controls={controls()}
            mobileColIndex={0}
            disabled={disabled}
            permission={permission}
          />
        ) : (
          <Row>
            <Col
              span={24}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                position: 'relative',
                top: 10,
                borderBottom: 'solid',
                marginBottom: 15,
              }}
            >
              {`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Solicitud`}
              <FormSubmitControls
                onFinish={mutable ? onFinish : null}
                onCancel={onCancel}
                loading={loading}
                permission={permission}
              />
            </Col>
            <Col span={24}>
              <Form
                layout="vertical"
                form={form}
                onFinish={onFinish}
                scrollToFirstError
                initialValues={{
                  estados_globales: 1,
                }}
              >
                <Row gutter={10}>
                  <Col xs={24} sm={24} md={16}>
                    <Form.Item
                      name="producto"
                      rules={rules.required}
                      label="Producto"
                      hasFeedback
                    >
                      <Input allowClear />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12}>
                    <Form.Item
                      name="especificacion"
                      rules={rules.required}
                      label="Especificación"
                      hasFeedback
                    >
                      <Input.TextArea autoSize={{ minRows: 2, maxRows: 2 }} allowClear />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12}>
                    <Form.Item
                      name="observaciones"
                      label="Observaciones"
                      hasFeedback
                    >
                      <Input.TextArea autoSize={{ minRows: 2, maxRows: 2 }} allowClear />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <NumericInput
                      name="precio_estimado"
                      label="Precio estimado"
                      required
                      decimal
                    />
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <NumericInput
                      name="iva_estimado"
                      label="IVA estimado"
                      required
                      decimal
                    />
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <NumericInput
                      name="total_estimado"
                      label="Total estimado"
                      required
                      decimal
                    />
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="clasificacion_de_activo"
                      rules={rules.required}
                      label="Clasificación del Activo"
                      hasFeedback
                    >
                      <Select dataSource={clasificaciones} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="unidad_de_medida"
                      rules={rules.required}
                      label="Unidad de medida"
                      hasFeedback
                    >
                      <Select dataSource={unidadesDeMedida} labelProp="nombre" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="estados_globales"
                      rules={rules.required}
                      label="Estado"
                      hasFeedback
                    >
                      <Select dataSource={estadosGlobales} disabled={!selectedRowKeys.length} />
                    </Form.Item>
                  </Col>
                  {!!selectedRowKeys.length && (
                    <>
                      <Col span={24}>
                        <Typography.Title level={4}>
                          Proveedores
                        </Typography.Title>
                      </Col>
                      <Col span={24}>
                        <Proveedores baseURI={`${baseURI}${selectedRowKeys[0]}/proveedores/`} />
                      </Col>
                    </>
                  )}
                </Row>
                <Form.Item hidden>
                  <Button htmlType="submit" />
                </Form.Item>
              </Form>
            </Col>
          </Row>

        )}
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => {
            setVisibleAlert(false);
            setSelectedRowKeys([]);
          }}
          visible={visibleAlert}
          content={`la solicitud del producto ${form.getFieldValue('producto')}`}
          loading={loading}
        />
      </Spin>
    </Row>
  );
}

SolicitudesDeProductos.propTypes = {
  requisicionId: PropTypes.number,
  disabled: PropTypes.bool.isRequired,
  permission: PropTypes.shape({}).isRequired,
};

SolicitudesDeProductos.defaultProps = {
  requisicionId: 0,
};

export default SolicitudesDeProductos;
