import React from 'react';
import SingleForm from 'components/SingleForm';
import {
  getClasificacionesDeActivos,
  postClasificacionesDeActivos,
  patchClasificacionesDeActivos,
  deleteClasificacionesDeActivos,
} from 'api/control-patrimonial/clasificacionesDeActivos';

export const permissionCatalogoDeClasificacionDeActivo = {
  permissionModel: 'catalogoclasificadordeactivo',
};

// eslint-disable-next-line react/prop-types
function ClasificacionesDeActivos({ permission }) {
  return (
    <SingleForm
      title="Clasificaciones De Activos"
      get={getClasificacionesDeActivos}
      post={postClasificacionesDeActivos}
      patch={patchClasificacionesDeActivos}
      delete={deleteClasificacionesDeActivos}
      permission={permission}
    />
  );
}
export default ClasificacionesDeActivos;
