import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  Form,
  Input,
  Row,
  Col,
  Spin,
  Card,
  Tabs,
  Button,
  message,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import API from 'utils/api';
import { onError, onSuccess } from 'utils/handlers';
import Table from 'components/Table';
import FormSubmitControls from 'components/FormSubmitControls';
import ModalDelete from 'components/ModalDelete';
import BusquedaEmpleados from 'components/BusquedaEmpleados';
import ClavesContablesTerceros from 'components/Catalogos/ClavesContablesTerceros';
import Select from 'components/Select';
import NumericInput from 'components/NumericInput';
import DatosPago from './DatosPagoProveedorContratista';

export const permissionBeneficiario = {
  permissionModel: 'beneficiario',
};

const baseURI = 'configuraciones/beneficiarios/';
const { TabPane } = Tabs;

// eslint-disable-next-line react/prop-types
function Beneficiarios({ permission }) {
  const collapsedSidebar = useSelector(({ app }) => app.collapsedSidebar);
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);
  const [visibleBusqueda, setVisibleBusqueda] = useState(false);
  const [selectedEmpleados, setSelectedEmpleados] = useState([]);
  const [empleados, setEmpleados] = useState([]);
  const [puestos, setPuestos] = useState([]);
  const [unidadesResponsables, setUnidadesResponsables] = useState([]);
  const [currentTabKey, setCurrentTabKey] = useState('1');
  const [generalInitValues, setGeneralInitValues] = useState();
  const [isMutable, setIsMutable] = useState(true);

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await API.get(baseURI);
      setData(res.data);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    const fetchAll = async () => {
      try {
        setLoading(true);
        const resPuestos = await API.get('estructura-organizacional/puestos/');
        setPuestos(resPuestos.data);
        await fetchData();
        setLoading(false);
      } catch (err) {
        onError(err, setLoading);
      }
    };
    fetchAll();

    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    setSelectedRowKeys([]);
    setCurrentTabKey('general');
    setIsMutable(true);
  };

  const setInitValues = (val) => {
    const values = { ...val };
    delete values.key;
    delete values.representante_legal;
    delete values.retencion;
    delete values.datos_de_pago;
    delete values.contacto;
    delete values.clave_contable;
    delete values.direccion;
    delete values.id;
    delete values.documento_de_tramite;
    delete values.clave_de_tercero;
    delete values.datos_corporativos;
    values.clasificador_de_objeto_de_gasto = values.clasificador_de_objeto_de_gasto || [];
    setGeneralInitValues(values);
    form.setFieldsValue(values);
  };

  const onFinish = async (close = false) => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      if (values.identificador_fiscal_de_extranjeros) {
        values.curp = null;
        values.RFC = null;
      } else if (values.curp) {
        values.RFC = null;
        values.identificador_fiscal_de_extranjeros = null;
      } else {
        values.curp = null;
        values.identificador_fiscal_de_extranjeros = null;
      }
      if (!selectedRowKeys.length
          || !_.isEqual(generalInitValues, values)) {
        if (!selectedRowKeys.length) {
          const response = await API.post(baseURI, values);
          if (response?.status === 201) {
            if (close) {
              onSuccess(response, 'Agregado correctamente');
              onCancel();
              await fetchData();
            } else {
              await fetchData();
              setInitValues(response.data);
              setSelectedRowKeys([response.data.id]);
              setIsMutable(response.data?.estados_globales < 4);
            }
          }
        } else {
          const [key] = selectedRowKeys;
          const response = await API.patch(`${baseURI}${key}/`, values);
          if (response?.status === 200) {
            if (close) {
              onSuccess(response, 'Actualizado correctamente');
              onCancel();
              await fetchData();
            } else {
              await fetchData();
              setInitValues(response.data);
              setSelectedRowKeys([response.data.id]);
              setIsMutable(response.data?.estados_globales < 4);
            }
          }
        }
      }
      setLoading(false);
    } catch (err) {
      if (!close) {
        throw err;
      } else {
        onError(err, setLoading);
      }
    }
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`${baseURI}${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          setVisibleAlert(false);
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    setTimeout(() => form.setFieldsValue(record));
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickEdit = () => {
    const [key] = selectedRowKeys;
    const match = data.find((item) => item.id === key);
    setInitValues(match);
    const {
      estados_globales,
    } = match;
    setIsMutable(estados_globales < 4);
    if (match) {
      setTimeout(() => {
        setVisible(true);
      });
    }
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const columns = [
    {
      titleText: 'Clave',
      key: 'clave',
      dataIndex: 'clave',
      width: 230,
    },
    {
      titleText: 'Empleado',
      key: 'empleado',
      dataIndex: 'empleado',
      width: 350,
    },
    {
      titleText: 'Estado',
      key: 'estados_globales',
      dataIndex: 'estados_globales',
      width: 200,
      render: (val) => estadosGlobales.find((e) => e.id === val)?.descripcion,
    },
  ];

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const onTabClick = async (key) => {
    try {
      if (key !== currentTabKey) {
        switch (currentTabKey) {
          case 'general':
            await onFinish();
            break;
          default:
            break;
        }
        if (key) {
          setCurrentTabKey(key);
        } else {
          onCancel();
        }
      }
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onFinishBusqueda = () => {
    if (selectedEmpleados.length) {
      const [empleado] = selectedEmpleados;
      const {
        numero_de_empleado,
        puesto,
        unidades_responsable,
      } = empleado;
      form.setFieldsValue({
        numero_de_empleado,
        puesto,
        unidades_responsable,
        empleado: empleado?.id,
      });
      setEmpleados([empleado]);
      setUnidadesResponsables([unidadesResponsables]);
      setSelectedEmpleados([]);
      setVisibleBusqueda(false);
    } else {
      message.info('No ha seleccionado un empleado');
    }
    setSelectedEmpleados([]);
  };

  const fetchEmpleados = async (clave) => {
    try {
      setLoading(true);
      const res = await API.get('estructura-organizacional/empleados/', {
        params: {
          numero_de_empleado: clave,
        },
      });
      if (res?.status === 200 && res.data?.length) {
        setEmpleados(res.data);
        form.setFieldsValue({
          empleado: res.data[0]?.id,
          puesto: res.data[0]?.puesto,
        });
      } else {
        message.info('Empleado no encontrado');
        setEmpleados([]);
        form.resetFields(['empleado', 'puesto']);
      }
      setLoading(false);
    } catch (err) {
      setEmpleados([]);
      form.resetFields(['empleado', 'puesto']);
    }
  };

  let timeoutEmpleado;
  const onValuesChange = async (changed) => {
    const { numero_de_empleado } = changed;
    const changedProp = Object.keys(changed)?.shift();
    // Handle change of numero_de_empleado
    if (changedProp === 'numero_de_empleado') {
      if (numero_de_empleado) {
        clearTimeout(timeoutEmpleado);
        timeoutEmpleado = setTimeout(() => {
          fetchEmpleados(numero_de_empleado);
        }, 2000);
      } else {
        clearTimeout(timeoutEmpleado);
        setEmpleados([]);
        form.resetFields(['empleado', 'puesto', 'numero_de_empleado, unidad_responsable']);
      }
    }
  };

  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible ? (
          <Table
            allowImport
            baseURI={baseURI}
            cols={columns}
            data={data}
            permission={permission}
            rowSelection={rowSelection}
            handleOnRowClick={handleOnRowClick}
            controls={{
              onClickAdd,
              onClickEdit,
              onClickDelete,
            }}
          />
        ) : (
          <Card
            className="form-container no-padding"
            title={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Beneficiario`}
            extra={(
              <FormSubmitControls
                onFinish={isMutable ? () => onTabClick(undefined) : null}
                onCancel={onCancel}
                loading={loading}
              />
            )}
            bordered={false}
          >
            <Tabs
              onChange={onTabClick}
              activeKey={currentTabKey}
              type="card"
            >
              <TabPane tab="General" key="general">
                <Form
                  layout="vertical"
                  form={form}
                  name="general"
                  onFinish={onFinish}
                  scrollToFirstError
                  initialValues={{ estados_globales: 1 }}
                  onValuesChange={onValuesChange}
                >
                  <Row gutter={10}>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="clave"
                        rules={rules.required}
                        label="Clave"
                        hasFeedback
                      >
                        <Input allowClear />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={collapsedSidebar ? 8 : 16} lg={8}>
                      <Form.Item noStyle>
                        <Input.Group className="input-button" style={{ display: 'flex' }} compact>
                          <NumericInput
                            name="numero_de_empleado"
                            label="No. de Empleado"
                            required
                          />
                          <Form.Item label=" ">
                            <Button onClick={() => setVisibleBusqueda(true)}>
                              <SearchOutlined />
                            </Button>
                          </Form.Item>
                        </Input.Group>
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="empleado"
                        label="Nombre Empleado"
                        rules={rules.required}
                      >
                        <Select
                          disabled
                          dataSource={empleados}
                          render={(e) => `${e.nombre} ${e.apellido_paterno} ${e.apellido_materno || ''}`}
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="puesto"
                        label="Puesto"
                        rules={rules.required}
                      >
                        <Select disabled dataSource={puestos} />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="unidad_responsable"
                        label="Unidad Responsable"
                        rules={rules.required}
                      >
                        <Select disabled dataSource={unidadesResponsables} />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="estados_globales"
                        label="Estado"
                        rules={rules.required}
                        hasFeedback
                      >
                        <Select
                          disabled={!isMutable || !selectedRowKeys.length}
                          dataSource={estadosGlobales}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item hidden>
                    <Button htmlType="submit" />
                  </Form.Item>
                </Form>
              </TabPane>
              <TabPane
                key="datos_pago"
                tab="Datos Pago"
                forceRender={selectedRowKeys.length}
              >
                <DatosPago activo={selectedRowKeys[0]} />
              </TabPane>
              <TabPane
                tab="Cuentas Contables"
                key="claves_contables"
              >
                <Col span={24}>
                  <ClavesContablesTerceros
                    uriTerceros={baseURI}
                    currentURI={baseURI}
                    selectedRowKey={selectedRowKeys[0]}
                  />
                </Col>
              </TabPane>
            </Tabs>
          </Card>
        )}
        <BusquedaEmpleados
          onFinish={onFinishBusqueda}
          setSelectedEmpleados={setSelectedEmpleados}
          visible={visibleBusqueda}
          setVisible={setVisibleBusqueda}
          puestos={puestos}
        />
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => setVisibleAlert(false)}
          visible={visibleAlert}
          content={`Beneficiario ${form.getFieldValue('nombre_comercial')}`}
          loading={loading}
        />
      </Spin>
    </Row>
  );
}

export default Beneficiarios;
