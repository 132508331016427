/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Form,
  // Input,
  Button,
  Spin,
  Row,
  Col,
  Modal,
} from 'antd';
import API from 'utils/api';
import { onError } from 'utils/handlers';
import FormSubmitControls from 'components/FormSubmitControls';
import Table from 'components/Table';
import ModalDelete from 'components/ModalDelete';
import Select from 'components/Select';

function Retenciones({
  currentURI,
  selectedRowKey,
  es_persona_moral,
}) {
  // eslint-disable-next-line no-param-reassign
  currentURI = currentURI[currentURI.length - 1] === '/'
    ? currentURI.substr(0, currentURI.length - 2) : currentURI;
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [tiposRetenciones, setTiposRetenciones] = useState([]);
  const [retenciones, setRetenciones] = useState([]);
  const [data, setData] = useState([]);

  const fetchData = async (_retenciones = retenciones) => {
    try {
      setLoading(true);
      if (selectedRowKey) {
        const resCatalogo = await API.get(`${currentURI}/${selectedRowKey}/`);
        if (resCatalogo?.status === 200) {
          const { retencion } = resCatalogo.data;
          if (retencion?.length) {
            setData(_retenciones.filter((e) => retencion.includes(e.id)));
          }
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const fetchAll = async () => {
    try {
      setLoading(true);
      const resTiposRetenciones = await API.get('configuraciones/tipos-de-retencion');
      setTiposRetenciones(resTiposRetenciones.data);
      const tipo_de_persona = es_persona_moral ? 1 : 2;
      const resRetenciones = await API.get('configuraciones/retenciones', {
        params: {
          tipo_de_persona,
        },
      });
      const _retenciones = resRetenciones.data
        .filter((e) => [3, tipo_de_persona].includes(e.tipo_de_persona));
      setRetenciones(_retenciones);
      await fetchData(_retenciones);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchAll();

    return () => API.tryCancel;
    // eslint-disable-next-line
  }, [selectedRowKey]);

  const onCancel = () => {
    form.resetFields();
    setVisible(false);
    setSelectedRowKeys([]);
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      if (selectedRowKey) {
        if (!selectedRowKeys.length) {
          const keys = data.map((item) => item.id);
          const resPatch = await API.patch(`${currentURI}/${selectedRowKey}/`, {
            retencion: [...keys, values.retencion],
          });
          if (resPatch?.status === 200) {
            onCancel();
            await fetchData();
          }
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    form.setFieldsValue(record);
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const onClickEdit = async () => {
    const [key] = selectedRowKeys;
    const match = retenciones.find((e) => e.id === key);
    form.setFieldsValue({
      ...match,
      retencion: match.id,
      clave_contable__clave: match.clavecontable?.cuenta_contable,
      clave_contable__nombre: match.clavecontable?.nombre,
    });
    setVisible(true);
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      const [key] = selectedRowKeys;
      if (selectedRowKey) {
        const keys = data.map((item) => item.id);
        const resPatch = await API.patch(`${currentURI}/${selectedRowKey}/`, {
          retencion: keys.filter((e) => e !== key),
        });
        if (resPatch?.status === 200) {
          onCancel();
          setVisibleAlert(false);
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
  };

  const columns = [
    {
      titleText: 'Concepto',
      dataIndex: 'concepto',
      key: 'concepto',
      width: 400,
      ellipsis: true,
    },
    // {
    //   titleText: 'Tasa',
    //   dataIndex: 'tasa',
    //   key: 'tasa',
    //   width: 100,
    //   render: (val) => `${val}%`,
    // },
    {
      titleText: 'Cuenta Contable',
      dataIndex: 'clave_contable',
      key: 'clave_contable',
      render: (clave_contable) => clave_contable?.nombre || null,
      width: 350,
    },
    {
      titleText: 'Estado',
      dataIndex: 'estados_globales',
      key: 'estados_globales',
      render: (estados_globales) => estadosGlobales
        .find((e) => e.id === estados_globales)?.descripcion,
      width: 120,
    },
  ];

  // eslint-disable-next-line react/prop-types
  function PInput({ value = '' }) {
    return (
      <p disabled className="ant-input ant-input-disabled auto-height">
        {value}
      </p>
    );
  }

  const onChangeRetencion = (val) => {
    if (val) {
      const match = retenciones.find((e) => e.id === val);
      form.setFieldsValue({
        ...match,
        clave_contable__clave: match.clavecontable?.cuenta_contable,
        clave_contable__nombre: match.clavecontable?.nombre,
      });
    } else {
      form.resetFields();
    }
  };

  return (
    <Spin tip="Cargando..." spinning={loading}>
      <Table
        cols={columns}
        data={data}
        rowSelection={rowSelection}
        handleOnRowClick={handleOnRowClick}
        controls={{
          onClickAdd,
          onClickEdit,
          onClickDelete,
        }}
        allowExport={false}
        allowSort={false}
        allowSearch={false}
        mobileColIndex={0}
      />
      <Modal
        visible={visible}
        closable={false}
        maskClosable={!loading}
        keyboard={!loading}
        footer={null}
        title={(
          <Row justify="space-between">
            {`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Retención`}
            <FormSubmitControls
              onFinish={!selectedRowKeys.length ? onFinish : null}
              onCancel={onCancel}
              loading={loading}
            />
          </Row>
        )}
        onCancel={onCancel}
        width={600}
        forceRender
        centered
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          initialValues={{
            estados_globales: 1,
          }}
        >
          <Col span={24}>
            <Form.Item
              label="Retención"
              name="retencion"
              rules={rules.required}
            >
              <Select
                disabled={!!selectedRowKeys.length}
                onChange={onChangeRetencion}
                dataSource={retenciones}
                labelProp="concepto"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Concepto"
              name="concepto"
            >
              <Select disabled dataSource={tiposRetenciones} labelProp="concepto" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Descripción"
              name="descripcion"
            >
              <Select disabled dataSource={tiposRetenciones} labelProp="descripcion" />
            </Form.Item>
          </Col>
          {/* <Col span={24}>
            <Form.Item
              label="Tasa"
              name="tasa"
            >
              <Input disabled />
            </Form.Item>
          </Col> */}
          <Col span={24}>
            <Form.Item
              label="Cuenta Contable"
              name="clave_contable__clave"
            >
              <PInput />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="clave_contable__nombre"
              label="Nombre Cuenta Contable"
            >
              <PInput />
            </Form.Item>
          </Col>
          <Form.Item hidden>
            <Button htmlType="submit" />
          </Form.Item>
        </Form>
      </Modal>
      <ModalDelete
        onDelete={deleteItem}
        onCancel={() => setVisibleAlert(false)}
        visible={visibleAlert}
        content={`Retención ${retenciones
          .find((r) => r.id === selectedRowKeys[0])?.concepto}`}
        loading={loading}
      />
    </Spin>
  );
}

Retenciones.propTypes = {
  selectedRowKey: PropTypes.number,
  currentURI: PropTypes.string.isRequired,
  es_persona_moral: PropTypes.bool,
};

Retenciones.defaultProps = {
  selectedRowKey: undefined,
  es_persona_moral: false,
};

export default Retenciones;
