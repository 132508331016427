/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { DashboardOutlined } from '@ant-design/icons';
import { routePermission } from 'utils/permission';

import configuracion from './configuracion';
import adquisiciones from './adquisiciones';
import contabilidad from './contabilidad';
import presupuestos from './presupuestos';
import almacen from './almacen';
import tesoreria from './tesoreria';
import obraPublica from './obra-publica';
import controlPatrimonial from './control-patrimonial';
import utilerias from './utilerias';
import presupuestoBasadoEnResultados from './presupuesto-basado-en-resultados';

export const initialRoutes = [
  {
    title: 'Dashboard',
    icon: () => <DashboardOutlined />,
    showInSidebar: true,
    type: 'private',
  },
  contabilidad,
  presupuestoBasadoEnResultados,
  presupuestos,
  adquisiciones,
  almacen,
  obraPublica,
  tesoreria,
  controlPatrimonial,
  configuracion,
  utilerias,
];

const normalizeRoutes = (_routes = [], parent = null) => _routes.map((route) => {
  const path = (`/${route.title}`).normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    .replace(/ /g, '-').toLowerCase();
  const children = route.children && normalizeRoutes(route.children, route).filter((e) => e);
  const permissionModel = route.permissionModel || parent?.permissionModel;
  if (children) {
    return {
      ...route,
      path,
      permissionModel,
      children,
    };
  }
  if (!permissionModel
    || routePermission(path, permissionModel, route.catalogsModels, route.tabsModels)) {
    return {
      ...route,
      path,
      permissionModel,
    };
  }
  return null;
});

const _flatRoutes = (_routes, parentPath = '') => _routes.map((route) => {
  if (route.children) {
    return _flatRoutes(route.children, parentPath + route.path);
  }
  return {
    ...route,
    path: parentPath + route.path,
  };
});

export const flatRoutes = (_routes = []) => {
  let _flattedRoutes = _flatRoutes(_routes);
  while (_flattedRoutes.find((r) => Array.isArray(r))) {
    _flattedRoutes = _flattedRoutes.flat();
  }
  return _flattedRoutes;
};

export const getRoutes = () => normalizeRoutes(initialRoutes).filter((e) => e);
