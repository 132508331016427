/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { DatabaseOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { AiOutlineUnorderedList } from 'react-icons/ai';

// import ConceptosDeProductos,
// { permissionConceptosDeProductos } from 'views/Almacen/Catalogos/ConceptosDeProductos';
import CAMB, { permissionCabm } from 'views/Almacen/Catalogos/CAMB';
import CUCOP, { permissionCUCOP } from 'views/Almacen/Catalogos/CUCOP';
import UnidadesDeMedida, { permissionUnidadDeMedida } from 'views/Configuracion/Otros/UnidadesDeMedida';
import Productos, { permissionProducto } from '../views/Presupuestos/PresupuestosAnuales/Productos';

export default {
  title: 'Almacén',
  icon: () => <DatabaseOutlined />,
  showInSidebar: true,
  children: [
    {
      title: 'Entradas',
      icon: null,
      type: 'private',
      showInSidebar: true,
      children: [
        {
          title: 'Recepciones',
          icon: null,
          component: null,
          type: 'private',
          showInSidebar: true,
        },
        {
          title: 'Devoluciones al Almacén',
          icon: null,
          component: null,
          type: 'private',
          showInSidebar: true,
        },
      ],
    },
    {
      title: 'Salidas',
      icon: null,
      type: 'private',
      showInSidebar: true,
      children: [
        {
          title: 'Vales de Salida',
          icon: null,
          component: null,
          type: 'private',
          showInSidebar: true,
        },
        {
          title: 'Devoluciones al Proveedor',
          icon: null,
          component: null,
          type: 'private',
          showInSidebar: true,
        },
      ],
    },
    {
      title: 'Kardex de Productos',
      icon: null,
      component: null,
      type: 'private',
      showInSidebar: true,
    },
    {
      title: 'Reportes',
      icon: null,
      type: 'private',
      showInSidebar: true,
      children: [
        {
          title: 'Existencias',
          icon: null,
          component: null,
          type: 'private',
          showInSidebar: true,
        },
        {
          title: 'Inventarios',
          icon: null,
          component: null,
          type: 'private',
          showInSidebar: true,
        },
      ],
    },
    {
      title: 'Catálogos',
      icon: () => <UnorderedListOutlined />,
      type: 'private',
      showInSidebar: true,
      children: [
        // {
        //   title: 'Almacenes',
        //   icon: null,
        //   component: null,
        //   type: 'private',
        //   showInSidebar: true,
        // },
        {
          title: 'Productos',
          icon: null,
          component: Productos,
          type: 'private',
          showInSidebar: true,
          ...permissionProducto,
          catalogsModels: [
            'configuraciondemascara',
            'tipodeproducto',
            'ubicaciondelalmacen',
            'conceptodeproducto',
            'clasificaciondeobjetodegasto',
            'categoriadelproducto',
            'familiadelproducto',
            'cuentacontable',
            'cucop',
            'tipodecosteo',
            'unidaddemedida',
          ],
        },
        {
          title: 'Unidades de Medida',
          icon: () => <AiOutlineUnorderedList />,
          showInSidebar: true,
          component: UnidadesDeMedida,
          type: 'private',
          ...permissionUnidadDeMedida,
        },
        {
          title: 'CABMS',
          icon: null,
          component: CAMB,
          type: 'private',
          showInSidebar: true,
          ...permissionCabm,
        },
        {
          title: 'CUCOP',
          icon: null,
          component: CUCOP,
          type: 'private',
          showInSidebar: true,
          ...permissionCUCOP,
        },
      ],
    },
  ],
};
