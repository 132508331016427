import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Row,
  Typography,
  Button,
  Input,
  Spin,
  Form,
  message,
} from 'antd';

import {
  LeftOutlined,
} from '@ant-design/icons';
import API from 'utils/api';
import Card from './components/Card';
import './index.scss';

const { Title } = Typography;

function ForgotPassword() {
  const history = useHistory();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    try {
      setLoading(true);
      const { email } = values;
      const response = await API.post('usuarios/solicitud-restablecer-contrasena', { email });
      if (response && response.status === 200) {
        setTimeout(() => {
          history.push(`recuperacion-de-contrasena?email=${email}`);
        }, 300);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      message.warn('Ha ocurrido un error');
    }
  };

  const rules = {
    email: [
      {
        type: 'email',
        message: 'Ingrese un correo electrónico válido.',
      },
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
  };

  return (
    <Card>
      <Spin tip="Loading..." spinning={loading} style={{ width: '100%', height: '100%', justifyContent: 'center' }}>
        <Row style={{ width: '100%' }}>
          <Button
            type="link"
            className="auth"
            style={{ textAlign: 'left', padding: 0 }}
            onClick={() => history.push('/inicio-de-sesion')}
          >
            <LeftOutlined />
            {' '}
            Volver
          </Button>
        </Row>
        <Title style={{ fontWeight: 'bold' }} level={3}>
          Recuperar contraseña
        </Title>
        <Form
          name="forgot-pwd-form"
          layout="vertical"
          form={form}
          onFinish={onFinish}
        >
          <Form.Item
            name="email"
            rules={rules.email}
            label="Correo electrónico"
            hasFeedback
          >
            <Input allowClear />
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <Button
              htmlType="submit"
              className="auth-submit-button"
              shape="round"
              block
            >
              Recuperar
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Card>
  );
}

export default ForgotPassword;
