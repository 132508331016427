export default [
  {
    id: 1,
    numero_de_cuenta_interna: 'SCI-2021-04-001',
    fecha_de_elaboracion: '04/04/2022',
    folio_agrupador: 'FA-2022-04-0001',
    concepto_solicitud: 'Fondos para Caja Chica',
    concepto_general: 'Fondos para Caja Chica',
    tipo_solicitud: 'Masiva',
    solicitante: 'Raúl Orizaba Mendoza',
    puesto_solicitante: 'Asistente Administrativo',
    area_del_beneficiario: 'Tesorería  ',
    justificacion: '',
    documento_soporte: '',
    periodo_de_pago: 'Abril 2022',
    importe_total: '$4,268.00',
    prioridad: 'Media',
    fecha_requerida_de_pago: '25/04/2022',
    observaciones: '',
    estatus_solicitud: 'Autorizado',
    estatus_comprobacion: 'No Aplica',
    numero_de_poliza: 'PD-2021-04-0001',
    numero_de_movimiento_presupuestal: 'MP-2021-04-0001',
    numero_de_contra_recibo: '',
    numero_de_cuenta_por_pagar: '',
    numero_de_orden_de_pago: '',
    numero_de_pago: '',

  }, {
    id: 2,
    numero_de_cuenta_interna: 'SCI-2021-04-002',
    fecha_de_elaboracion: '04/04/2022',
    folio_agrupador: 'FA-2022-04-0002',
    concepto_solicitud: 'Anticipo para Viáticos Nacionales',
    concepto_general: 'Anticipo para Viáticos',
    tipo_solicitud: 'Individual',
    solicitante: 'Rosa Delgadillo Núñez',
    puesto_solicitante: 'Secretaria Ejecutiva',
    area_del_beneficiario: 'Presidencia  ',
    justificacion: '',
    documento_soporte: '',
    periodo_de_pago: 'Abril 2022',
    importe_total: '$1,254.00',
    prioridad: 'Media',
    fecha_requerida_de_pago: '25/04/2022',
    observaciones: '',
    estatus_solicitud: 'Autorizado',
    estatus_comprobacion: 'Pendiente de Comprobar',
    numero_de_poliza: 'PD-2021-04-0002',
    numero_de_movimiento_presupuestal: 'MP-2021-04-0002',
    numero_de_contra_recibo: '',
    numero_de_cuenta_por_pagar: '',
    numero_de_orden_de_pago: '',
    numero_de_pago: '',

  }, {
    id: 3,
    numero_de_cuenta_interna: 'SCI-2021-04-003',
    fecha_de_elaboracion: '04/04/2022',
    folio_agrupador: 'FA-2022-04-0003',
    concepto_solicitud: 'Anticipo para Viáticos Internacionales',
    concepto_general: 'Anticipo para Viáticos',
    tipo_solicitud: 'Individual',
    solicitante: 'Octavio Frías Castrejón',
    puesto_solicitante: 'Supervisor',
    area_del_beneficiario: 'Adquisiciones',
    justificacion: '',
    documento_soporte: '',
    periodo_de_pago: 'Abril 2022',
    importe_total: '$4,697.00 ',
    prioridad: 'Media',
    fecha_requerida_de_pago: '25/04/2022',
    observaciones: '',
    estatus_solicitud: 'Autorizado',
    estatus_comprobacion: 'Pendiente de Comprobar',
    numero_de_poliza: 'PD-2021-04-0003',
    numero_de_movimiento_presupuestal: 'MP-2021-04-0003',
    numero_de_contra_recibo: '',
    numero_de_cuenta_por_pagar: '',
    numero_de_orden_de_pago: '',
    numero_de_pago: '',

  }, {
    id: 4,
    numero_de_cuenta_interna: 'SCI-2021-04-004',
    fecha_de_elaboracion: '04/04/2022',
    folio_agrupador: 'FA-2022-04-0004',
    concepto_solicitud: 'Anticipo para Gastos de Convención',
    concepto_general: 'Anticipo para Gastos',
    tipo_solicitud: 'Individual',
    solicitante: 'Claudia Martínez López',
    puesto_solicitante: 'Asistente Administrativo',
    area_del_beneficiario: 'Recursos Humanos ',
    justificacion: '',
    documento_soporte: '',
    periodo_de_pago: 'Abril 2022',
    importe_total: '$8,639.00 ',
    prioridad: 'Media',
    fecha_requerida_de_pago: '25/04/2022',
    observaciones: '',
    estatus_solicitud: 'Autorizado',
    estatus_comprobacion: 'Pendiente de Comprobar',
    numero_de_poliza: 'PD-2021-04-0004',
    numero_de_movimiento_presupuestal: 'MP-2021-04-0004',
    numero_de_contra_recibo: '',
    numero_de_cuenta_por_pagar: '',
    numero_de_orden_de_pago: '',
    numero_de_pago: '',

  }, {
    id: 5,
    numero_de_cuenta_interna: 'SCI-2021-04-005',
    fecha_de_elaboracion: '04/04/2022',
    folio_agrupador: 'FA-2022-04-0005',
    concepto_solicitud: 'Anticipo para Gastos de Informe',
    concepto_general: 'Anticipo para Gastos',
    tipo_solicitud: 'Individual',
    solicitante: 'Karla Atilano Cárdenas',
    puesto_solicitante: 'Asistente Administrativo',
    area_del_beneficiario: 'Tesorería',
    justificacion: '',
    documento_soporte: '',
    periodo_de_pago: 'Abril 2022',
    importe_total: '$2,658.00',
    prioridad: 'Media',
    fecha_requerida_de_pago: '25/04/2022',
    observaciones: '',
    estatus_solicitud: 'Autorizado',
    estatus_comprobacion: 'Pendiente de Comprobar',
    numero_de_poliza: 'PD-2021-04-0005',
    numero_de_movimiento_presupuestal: 'MP-2021-04-0005',
    numero_de_contra_recibo: '',
    numero_de_cuenta_por_pagar: '',
    numero_de_orden_de_pago: '',
    numero_de_pago: '',
  },
];
