import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import { useSelector } from 'react-redux';
import {
  Form,
  Button,
  Row,
  Col,
  Spin,
  Input,
  message,
} from 'antd';

import API from 'utils/api';
import { onError, onSuccess } from 'utils/handlers';
// import Select from 'components/Select';
// import FormItemLabel from 'components/FormItemLabel';
import makeMessager from 'utils/complexFormsMessages';

const ayudaIdentificacion = 'Identificar y describir el problema o necesidad que se pretende atender, aportando la cuantificación del problema, definiendo su localización geográfica y distribución entre grupos de población permitiendo generar hipótesis respecto a sus causas y consecuencias.';
const ayudaEVolucion = 'Análisis con información de tipo cuantitativo y cualitativo de la evolución del problema a lo largo del tiempo y entre las distintas regiones y grupos afectados con el fin de identificar cambios que puedan aportar a la determinación de las causas y consecuencias del problema, destacando porqué es importante su atención.';
const ayudaExperiencia = 'Recuento de las experiencias de políticas públicas, en el ámbito local y regional que tuvieron o tienen como objetivo la atención del problema señalando sus logros, fallos y resultados. El objetivo de esta sección es validar el análisis de las causas y consecuencias del problema e incorporar en el diseño de la intervención los elementos exitosos de otras estrategias.';
const ayudaSituacion = 'Con los elementos de los puntos anteriores, establecer la situación no deseada derivada el problemaidentificado. Redactar el problema como un hecho negativo y escrito en positivo.';

function SituacionNoDeseada({
  mutable,
  fatherId,
  // setForm,
  setCurrentTabKey,
  situacionNoDeseadaID,
  fatherURI,
  setFormSituacion,
}) {
  // const baseURI = 'presupuestos/situacion_no_deseada/:id/';
  const baseURI = 'presupuestos/situacion-no-deseada/';
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const formatValues = (values) => {
    const keys = Object.keys(values);
    const clone = {};
    keys.forEach((prop) => {
      const value = values[prop];
      if (value && value !== 'N/A') {
        clone[prop] = value;
      }
    });
    return clone;
  };

  const getSituacion = async () => {
    try {
      setLoading(true);
      let formattedValues = {};
      if (fatherId && situacionNoDeseadaID) {
        const resSituacion = await API.get(`${baseURI}${situacionNoDeseadaID}`);
        formattedValues = formatValues(resSituacion.data);
      }
      setTimeout(() => {
        form.setFieldsValue(formattedValues);
      });
      setLoading(false);
    } catch (error) {
      onError(error, setLoading);
    }
  };

  const showMsg = makeMessager(setCurrentTabKey, getSituacion);

  const onFinishHandler = async (tabKey = null, _continue = true) => {
    try {
      if (tabKey) {
        showMsg(tabKey);
      } else {
        // eslint-disable-next-line no-use-before-define
        await onFinish(null, _continue);
      }
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    // eslint-disable-next-line no-use-before-define
    form.onFinishHandler = onFinishHandler;
    setFormSituacion(form);
    const fetch = async () => {
      await getSituacion();
    };
    fetch();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, [situacionNoDeseadaID]);

  const patchDiagnostico = async (situacionID) => {
    try {
      setLoading(true);
      const resPatch = await API.patch(`${fatherURI}/${fatherId}`, {
        situacion_no_deseada: situacionID,
      });
      if (resPatch.status === 200) {
        message.info('Situacion Agregada Correctamente');
      }
      setLoading(false);
    } catch (error) {
      onError(error, setLoading);
    }
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      // const [key] = fatherId;
      if (situacionNoDeseadaID) {
        const response = await API.put(`${baseURI}${situacionNoDeseadaID}`, values);
        if (response?.status === 200) {
          onSuccess(response, 'Actualizado Correctamente');
        }
      } else {
        // values.situacion_no_deseada = values.id;
        const response = await API.post(baseURI, values);
        if (response?.status === 201) {
          onSuccess(response, 'Agregado Correctamente');

          await patchDiagnostico(response.data.id);
          await getSituacion();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const rules = {

    required: [
      {
        message: 'El campo es requerido',
        required: true,
      },
    ],
  };

  return (
    <Row align="center" justify="center" className="container">
      <Spin tip="Cargando..." spinning={loading}>
        <Form
          name="situacionTab"
          form={form}
          layout="vertical"
          onFinish={onFinish}
          scrollToFirstError
          initialValues={{
            ayuda_e_identificacion_y_descripcion_de_problema: ayudaIdentificacion,
            ayuda_en_evolucion_del_problema: ayudaEVolucion,
            ayuda_experiencia_en_atencion: ayudaExperiencia,
            ayuda_situacion_no_deseada: ayudaSituacion,
          }}
        >

          <Row gutter={10}>
            <Col xs={24} sm={24} md={16}>
              <Form.Item
                tooltip={ayudaIdentificacion}
                name="identificacion_descripcion_de_problema"
                rules={rules.required}
                label="Identificacion y descripcion del problema"
              >
                <Input.TextArea
                  autoSize={{ minRows: 3, maxRows: 4 }}
                  allowClear
                  disabled={!mutable}
                />
              </Form.Item>
            </Col>
            <Form.Item
              name="ayuda_e_identificacion_y_descripcion_de_problema"
              hidden
            >
              <Input.TextArea />
            </Form.Item>
            <Col xs={24} sm={24} md={16}>
              <Form.Item
                tooltip={ayudaEVolucion}
                name="evolucion_de_problema"
                label="Evolución del problema"
              >
                <Input.TextArea
                  autoSize={{ minRows: 2, maxRows: 4 }}
                  allowClear
                  disabled={!mutable}
                />
              </Form.Item>
            </Col>
            <Form.Item
              name="ayuda_en_evolucion_del_problema"
              hidden
            >
              <Input.TextArea />
            </Form.Item>
            <Col xs={24} sm={24} md={16}>
              <Form.Item
                tooltip={ayudaExperiencia}
                name="experiencia_en_atencion"
                label="Experiencia en la atencion"
              >
                <Input.TextArea
                  autoSize={{ minRows: 2, maxRows: 4 }}
                  allowClear
                  disabled={!mutable}
                />
              </Form.Item>
            </Col>
            <Form.Item
              name="ayuda_experiencia_en_atencion"
              hidden
            >
              <Input.TextArea />
            </Form.Item>
            <Col xs={24} sm={24} md={16}>
              <Form.Item
                tooltip={ayudaSituacion}
                name="situacion_no_deseada"
                rules={rules.required}
                label="Situación no deseada"
              >
                <Input.TextArea
                  autoSize={{ minRows: 2, maxRows: 4 }}
                  allowClear
                  disabled={!mutable}
                />
              </Form.Item>
            </Col>
            <Form.Item
              name="ayuda_situacion_no_deseada"
              hidden
            >
              <Input.TextArea />
            </Form.Item>
          </Row>
          <Form.Item hidden name="id">
            <Input />
          </Form.Item>
          <Form.Item hidden>
            <Button htmlType="submit" />
          </Form.Item>
        </Form>
      </Spin>
    </Row>
  );
}

SituacionNoDeseada.propTypes = {
  // setForm: PropTypes.func.isRequired,
  mutable: PropTypes.bool.isRequired,
  setCurrentTabKey: PropTypes.func.isRequired,
  fatherId: PropTypes.arrayOf(PropTypes.number),
  fatherURI: PropTypes.string.isRequired,
  setFormSituacion: PropTypes.func.isRequired,
  situacionNoDeseadaID: PropTypes.number,
  // callback: PropTypes.func,
};

SituacionNoDeseada.defaultProps = {
  fatherId: [],
  // callback: () => { },
  situacionNoDeseadaID: 0,
};

export default SituacionNoDeseada;
