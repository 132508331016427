import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  Row,
  Col,
  Spin,
  Card,
  Form,
  Input,
  Button,
  TreeSelect,
  Tooltip,
  message,
  Typography,
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import FormSubmitControls from 'components/FormSubmitControls';
import FormItemLabel from 'components/FormItemLabel';
import Table from 'components/Table';
import ModalDelete from 'components/ModalDelete';
import Select from 'components/Select';
import API from 'utils/api';
import fetchSome from 'utils/fetchSome';
import { onError, onSuccess } from 'utils/handlers';
import {
  SET_CUENTAS_PADRES,
  SET_AFECTACIONES_PRESUPUESTALES,
  SET_MOMENTOS_PRESUPUESTALES_DE_INGRESO,
  SET_MOMENTOS_PRESUPUESTALES_DE_EGRESO,
  SET_NATURALEZAS_DE_INGRESO,
  SET_CLASIFICACIONES_DE_CUENTA_CONTABLES,
  SET_TIPOS_CUENTAS_CONTABLES,
} from 'store/reducers/catalogs';
import makeTree from 'utils/makeTreeFromCuentasContables';
import { isMutable } from 'utils/estadosGlobales';
import { findItemNested } from 'utils/filters';

export const permissionCuentaContable = {
  permissionModel: 'cuentacontable',
};

const baseURI = 'contabilidad/cuentas-contables/';
const baseURIs = [
  'configuraciones/configuraciones-de-mascaras/',
  'catalogos/content-types-ur/',
  'catalogos/content-types-cri/',

];

// eslint-disable-next-line react/prop-types
function ClavesContables({ permission }) {
  const timeoutCuentaContable = useRef();
  const {
    afectacionesPresupuestales,
    estadosGlobales,
    cuentasPadres,
    momentosPresupuestalesDeIngreso,
    momentosPresupuestalesDeEgreso,
    naturalezasDeIngreso,
    clasificacionesDeCuentasContables,
    tiposDeCuentasContables,
  } = useSelector((state) => state.catalogs);
  const history = useHistory();
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [programas, setProgramas] = useState([]);
  const [proyectos, setProyectos] = useState([]);
  const [clasificadoresDeGasto, setClasificadoresDeGasto] = useState([]);
  const [fuentesDeFinanciamiento, setFuentesDeFinanciamiento] = useState([]);
  const [cuentasContables, setCuentasContables] = useState([]);
  const [unidadesResponsables, setUnidadesResponsables] = useState([]);
  const [contentTypesUR, setContentTypesUR] = useState([]);
  const [contentTypesCRI, setContentTypesCRI] = useState([]);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [mascara, setMascara] = useState();
  const [separador, setSeparador] = useState();
  const [pattern, setPattern] = useState();
  const [hasAllConfigurations, setHasAllConfigurations] = useState(false);
  const [lengthsToAddSeparator, setLengthsToAddSeparator] = useState([]);
  const [configuracionesDeSegmentos, setConfiguracionesDeSegmentos] = useState([]);
  const [requiredFields, setRequiredFields] = useState([]);
  const [segmentos, setSegmentos] = useState([]);
  const [clasificadorRubroIngresos, setClasificadorRubroIngresos] = useState([]);
  const [mutable, setMutable] = useState(false);
  const [disabledTipoDeCuentaContable, setDisabledTipoDeCuentaContable] = useState(false);
  const [flatCuentas, setFlatCuentas] = useState([]);
  const [capitulosCOG, setCapitulosCOG] = useState([]);
  const [currentCuenta, setCurrentCuenta] = useState({ conac: true });

  const onClickTitle = (e) => {
    e.preventDefault();
    const element = e.target;
    if (element) {
      let parent = element.parentElement;
      parent = parent ? parent.parentElement : null;
      if (parent) {
        const clickableSpan = parent.previousSibling;
        if (clickableSpan) {
          clickableSpan.click();
        }
      }
    }
  };

  const renderTitle = (title, selectable) => (
    // eslint-disable-next-line
    <span onClick={onClickTitle} className={selectable ? 'bold' : ''}>
      {title}
    </span>
  );

  const normalizeCuentasContables = (_data) => {
    if (_data) {
      const output = _data.map((item) => {
        const clone = { ...item };
        const children = normalizeCuentasContables(clone.children);
        const title = `${clone.clave} - ${clone.nombre}`;
        const hasCuentasChildren = children.some((e) => e.conac);
        const selectable = clone.tipo !== 7 && !hasCuentasChildren;
        if (!children.length) {
          // console.log(clone);
          delete clone.children;
          return ({
            ...clone,
            title: renderTitle(title, selectable),
            value: clone.id,
            key: clone.id,
            disabled: !selectable,
          });
        }
        return ({
          ...clone,
          title: renderTitle(title, selectable),
          value: clone.id,
          key: clone.id,
          children,
          disabled: !selectable,
        });
      });
      return output;
    }
    return [];
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await API.get(baseURI);
      const normalizedCuentas = normalizeCuentasContables(makeTree(res.data));
      setCuentasContables(normalizedCuentas);
      // const filteredData = res.data.filter((e) => !e.conac);
      setData(normalizedCuentas);
      setFlatCuentas(normalizeCuentasContables(res.data));
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const getCuentasPadres = async () => {
    if (!cuentasPadres?.length) {
      await fetchSome('catalogos/clasificaciones-de-cuentas-contables/', SET_CUENTAS_PADRES);
    }
  };

  const getAfectacionesPresupuestales = async () => {
    if (!afectacionesPresupuestales?.length) {
      await fetchSome('catalogos/afectaciones-presupuestales/', SET_AFECTACIONES_PRESUPUESTALES);
    }
  };

  const getMomentosPresupuestalesDeIngresos = async () => {
    if (!momentosPresupuestalesDeIngreso?.length) {
      await fetchSome(
        'catalogos/momentos-presupuestales-de-egresos/',
        SET_MOMENTOS_PRESUPUESTALES_DE_INGRESO,
      );
    }
  };

  const getMomentosPresupuestalesDeEgresos = async () => {
    if (!momentosPresupuestalesDeEgreso?.length) {
      await fetchSome(
        'catalogos/momentos-presupuestales-de-ingresos/',
        SET_MOMENTOS_PRESUPUESTALES_DE_EGRESO,
      );
    }
  };

  const getNaturalezasDeIngresos = async () => {
    if (!naturalezasDeIngreso?.length) {
      await fetchSome('catalogos/naturalezas-de-ingreso/', SET_NATURALEZAS_DE_INGRESO);
    }
  };

  const getTiposDeCuentasContables = async () => {
    if (!tiposDeCuentasContables?.length) {
      await fetchSome('catalogos/tipos-de-cuentas-contables/', SET_TIPOS_CUENTAS_CONTABLES);
    }
  };

  const getClasificacionesDeCuentasContables = async () => {
    if (!clasificacionesDeCuentasContables?.length) {
      await fetchSome(
        'catalogos/clasificaciones-de-cuentas-contables/',
        SET_CLASIFICACIONES_DE_CUENTA_CONTABLES,
      );
    }
  };

  const fetchAll = async () => {
    try {
      setLoading(true);
      const promises = [
        ...baseURIs,
        getCuentasPadres,
        getAfectacionesPresupuestales,
        getMomentosPresupuestalesDeIngresos,
        getMomentosPresupuestalesDeEgresos,
        getNaturalezasDeIngresos,
        getTiposDeCuentasContables,
        getClasificacionesDeCuentasContables,
      ].map((catalog) => (typeof catalog === 'string' ? API.get(catalog) : catalog()));
      const [
        _configMask,
        _contenTypesUR,
        _contentTypesCRI,
      ] = (await Promise.all(promises)).map((r) => r?.data);
      const configMascara = _configMask?.find((c) => c.tipo === 1);
      setMascara(configMascara?.mascara);
      const separator = configMascara?.separador;
      setSeparador(separator);
      const _segmentos = configMascara?.mascara?.split(separator);
      if (_segmentos.length) {
        const patt = _segmentos.reduce((acum, curr, idx) => {
          let next = '';
          if (curr.tipo?.id !== 5) {
            next = `${acum}[0-9]{${curr.length}}`;
          } else {
            next = `${acum}[az]{${curr.length}}`;
          }
          if (idx !== _segmentos.length - 1) {
            next += separator;
          }
          return next;
        }, '^');
        const regExp = new RegExp(`${patt}$`, 'i');
        setPattern(regExp);
        const lengths = [...configMascara.mascara]
          .map((c, idx) => ((c === separator) ? idx : -1));
        const filteredLengths = lengths.filter((l) => l !== -1);
        setLengthsToAddSeparator(filteredLengths);
      }
      setContentTypesUR(_contenTypesUR || []);//
      setContentTypesCRI(_contentTypesCRI || []);//
      await fetchData();
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const hasAllConfigs = async () => {
    try {
      setLoading(true);
      const res = await API.get('/configuraciones/configuraciones-de-segmentos-contables/');
      if (res && res.status === 200) {
        const configuraciones = res.data.filter((r) => r.estados_globales === 4);
        if (configuraciones.length === 10) {
          const promises = configuraciones.map((e) => API.get(`/configuraciones/configuraciones-de-segmentos-contables/${e.id}/segmentos/`));
          const responses = await Promise.all(promises);
          const configSegmentos = configuraciones
            .map((e, i) => ({ ...e, catalogo_de_segmento_contable: responses[i].data }));
          setConfiguracionesDeSegmentos(configSegmentos);
          return true;
        }
      }
      return false;
    } catch (err) {
      return false;
    }
  };

  const showAlertConfigs = () => {
    message.info(
      (
        <>
          <span>
            No se han registrado/autorizado todas las Configuraciones de Segmentos Contables
          </span>
          <Button
            type="link"
            onClick={() => {
              history.push('/configuracion/contabilidad/segmentos-contables');
            }}
          >
            Ir
          </Button>
        </>
      ), 3,
    );
  };

  useEffect(() => {
    const validateConfigs = async () => {
      if (await hasAllConfigs()) {
        setHasAllConfigurations(true);
        await fetchAll();
      } else {
        setLoading(false);
        setHasAllConfigurations(false);
        showAlertConfigs();
      }
    };
    validateConfigs();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setSelectedRowKeys([]);
    form.resetFields();
    setVisible(false);
    setDisabledTipoDeCuentaContable(false);
    setCurrentCuenta({ conac: true });
  };

  const getURValuesToSend = () => {
    const [unidad_responsable] = unidadesResponsables;
    if (unidad_responsable) {
      const { id, nivel } = unidad_responsable;
      const tipo_de_ur = contentTypesUR
        .find((ct) => ct.nivel.toString() === nivel.toString())?.id;
      return { ur_id: id, tipo_de_ur };
    }
    return { ur_id: undefined, tipo_de_ur: undefined };
  };

  const getCRIValuesToSend = () => {
    const [cri_id] = clasificadorRubroIngresos;
    if (cri_id) {
      const { id, nivel } = cri_id;
      const nivel_cri = contentTypesCRI
        .find((ct) => ct.nivel.toString() === nivel.toString())?.id;
      return { cri_id: id, nivel_cri };
    }
    return { cri_id: undefined, nivel_cri: undefined };
  };

  const insertUpdateChild = (_data, child, parentVal) => _data.map((item) => {
    if (item.children?.length) {
      if (item.value === parentVal) {
        let { children } = item;
        if (item.children.some((e) => e.value === child.value)) {
          children = children.map((e) => (e.value === item.value ? item : e));
        } else {
          children.push(child);
        }
        return {
          ...item,
          children: insertUpdateChild(children, child, parentVal),
        };
      }
      return {
        ...item,
        children: insertUpdateChild(item.children, child, parentVal),
      };
    }
    if (item.value === parentVal) {
      return {
        ...item,
        children: [child],
      };
    }
    if (item.value === child.value) {
      return { ...child };
    }
    return { ...item };
  });

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const URValues = getURValuesToSend();
      const CRIValues = getCRIValuesToSend();
      const values = {
        ...form.getFieldsValue(),
        ...URValues,
        ...CRIValues,
      };
      const { cuenta_padre } = values;
      if (!selectedRowKeys.length) {
        const res = await API.post(baseURI, values);
        if (res?.status === 201) {
          onSuccess(res, 'Agregado correctamente');
          onCancel();
          const child = {
            ...res.data,
            selectable: res.data.tipo !== 7,
            disabled: res.data.tipo === 7,
            title: renderTitle(`${res.data.clave} - ${res.data.nombre}`, res.data.tipo !== 7),
            value: res.data.id,
            conac: false,
            key: res.data.id,
          };
          setTimeout(() => {
            const updated = insertUpdateChild(cuentasContables, child, cuenta_padre);
            setCuentasContables(updated);
            const newData = [...data, { ...child, key: child.id }];
            setData(newData);
          });
        }
      } else {
        const [key] = selectedRowKeys;
        const res = await API.put(`${baseURI}${key}/`, values);
        if (res?.status === 200) {
          onSuccess(res, 'Actualizado correctamente');
          onCancel();
          const child = {
            ...res.data,
            selectable: res.data.tipo !== 7,
            disabled: res.data.tipo === 7,
            title: renderTitle(`${res.data.clave} - ${res.data.nombre}`, res.data.tipo !== 7),
            value: res.data.id,
            conac: false,
            key: res.data.id,
          };
          setTimeout(() => {
            const updated = insertUpdateChild(cuentasContables, child, cuenta_padre);
            setCuentasContables(updated);
            const filtered = data.filter((e) => e.id !== key);
            const newData = [...filtered, { ...child, key: child.id }];
            setData(newData);
          });
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`${baseURI}${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          onCancel();
          setVisibleAlert(false);
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.key]);
    setCurrentCuenta(record);
    setTimeout(() => {
      form.setFieldsValue(record);
    });
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickAdd = () => {
    onCancel();
    if (hasAllConfigurations) {
      onCancel();
      setCurrentCuenta({ conac: false });
      setVisible(true);
      setMutable(true);
    } else {
      showAlertConfigs();
    }
  };

  const onClickDelete = () => {
    if (mutable) {
      setVisibleAlert(true);
    } else {
      message.info('No se puede eliminar Cuentas Contables autorizadas/canceladas');
      onCancel();
    }
  };

  const columns = [
    {
      titleText: 'Cuenta Contable',
      dataIndex: 'clave',
      key: 'clave',
      width: 400,
    },
    {
      titleText: 'Nombre',
      dataIndex: 'nombre',
      key: 'nombre',
      width: 300,
    },
    {
      titleText: 'Descripción',
      dataIndex: 'descripcion',
      key: 'descripcion',
      width: 800,
    },
    {
      titleText: 'Nivel',
      dataIndex: 'nivel',
      key: 'nivel',
      width: 100,
    },
    {
      titleText: 'Estado',
      dataIndex: 'estados_globales',
      key: 'estados_globales',
      width: 150,
      render: (val) => (estadosGlobales.find((e) => e.id === val)?.descripcion),
    },
  ];

  const getValues = (val) => {
    const match = findItemNested(data, val);
    if (match) {
      const {
        nivel,
        clave,
        estados_globales,
        clasificacion,
        naturaleza,
        tipo,
        afectacion_presupuestal,
        momento_presupuestal_de_egreso,
        momento_presupuestal_de_ingreso,
        value,
      } = match;
      return {
        tipo,
        value,
        nivel: nivel + 1,
        nivel_cc: nivel,
        cuenta: clave.substr(0, 5),
        naturaleza: naturaleza || undefined,
        clasificacion: clasificacion || undefined,
        estados_globales_cuenta: estados_globales,
        afectacion_presupuestal: afectacion_presupuestal || undefined,
        momento_presupuestal_de_egreso: momento_presupuestal_de_egreso || undefined,
        momento_presupuestal_de_ingreso: momento_presupuestal_de_ingreso || undefined,
      };
    }
    return {};
  };

  const getInputValFor = (segmento) => {
    const idx = segmentos.indexOf(segmentos.find((s) => s.prop === segmento));
    const splittedValues = form.getFieldValue('clave').split(separador);
    if (typeof splittedValues[idx] !== 'number') {
      return parseInt(splittedValues[idx], 10);
    }
    return splittedValues[idx];
  };

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
    clave: [
      {
        validator: async (_, value) => {
          const inputVal = getInputValFor('conac');
          const values = getValues(form.getFieldValue('cuenta_padre'), !!selectedRowKeys.length);
          if (value) {
            if (pattern && !pattern.test(value)) {
              throw new Error('Introduzca una clave válida');
            } else if (inputVal?.toString() !== values.cuenta?.toString()) {
              throw new Error('Primer segmento no concuerda con Cuenta Contable seleccionada');
            }
          } else {
            throw new Error('El campo es requerido');
          }
        },
      },
    ],
    programa: [
      {
        validator: async (_, value) => {
          if (requiredFields.includes('programa')) {
            const inputVal = getInputValFor('programa');
            if (inputVal && !value) {
              throw new Error('Se ha ingresado una clave inválida.');
            } else if (!value && form.getFieldValue('tipo') === 7) {
              throw new Error('El campo es requerido.');
            }
          }
        },
      },
    ],
    proyecto: [
      {
        validator: async (_, value) => {
          if (requiredFields.includes('proyecto')) {
            const inputVal = getInputValFor('proyecto');
            if (inputVal && !value) {
              throw new Error('Se ha ingresado una clave inválida.');
            } else if (!value && form.getFieldValue('tipo') === 7) {
              throw new Error('El campo es requerido.');
            } else if (value && form.getFieldValue('tipo') === 7) {
              const proyecto = proyectos.find((p) => p.id === form.getFieldValue('proyecto'));
              const programa = programas.find((p) => p.id === form.getFieldValue('programa'));
              if (proyecto?.programas.id !== programa.id) {
                throw new Error('El proyecto no pertenece al programa seleccionado.');
              }
            }
          }
        },
      },
    ],
    fuente_de_financiamiento: [
      {
        validator: async (_, value) => {
          if (requiredFields.includes('fuente_de_financiamiento')) {
            const inputVal = getInputValFor('fuente_de_financiamiento');
            if (inputVal && !value) {
              throw new Error('Se ha ingresado una clave inválida.');
            } else if (!value && form.getFieldValue('tipo') === 7) {
              throw new Error('El campo es requerido.');
            }
          }
        },
      },
    ],
    clasificador_de_objeto_gasto: [
      {
        validator: async (_, value) => {
          if (requiredFields.includes('clasificador_de_objeto_gasto')) {
            const inputVal = getInputValFor('clasificador_de_objeto_gasto');
            if (inputVal && !value) {
              throw new Error('Se ha ingresado una clave inválida.');
            } else if (!value && form.getFieldValue('tipo') === 7) {
              throw new Error('El campo es requerido.');
            }
          }
        },
      },
    ],
    unidad_responsable: [
      {
        validator: async (_, value) => {
          if (requiredFields.includes('unidad_responsable')) {
            const inputVal = getInputValFor('unidad_responsable');
            if (inputVal && !value) {
              throw new Error('Se ha ingresado una clave inválida.');
            } else if (!value && form.getFieldValue('tipo') === 7) {
              throw new Error('El campo es requerido.');
            }
          }
        },
      },
    ],
    cri_id: [
      {
        validator: async (_, value) => {
          if (requiredFields.includes('cri_id')) {
            const inputVal = getInputValFor('cri_id');
            if (inputVal && !value) {
              throw new Error('Se ha ingresado una clave inválida.');
            } else if (!value && form.getFieldValue('tipo') === 7) {
              throw new Error('El campo es requerido.');
            }
          }
        },
      },
    ],
    cap_cog_id: [
      {
        validator: async (_, value) => {
          if (requiredFields.includes('capitulo_cog')) {
            const inputVal = getInputValFor('capitulo_cog');
            if (inputVal && !value) {
              throw new Error('Se ha ingresado una clave inválida.');
            } else if (!value && form.getFieldValue('tipo') === 7) {
              throw new Error('El campo es requerido.');
            }
          }
        },
      },
    ],
    separador: [
      {
        required: true,
        message: 'El campo es requerido',
      },
      {
        max: 1,
        message: 'El campo debe de constar un solo carácter',
      },
    ],
  };

  const onChangeCuentaContable = (val) => {
    form.resetFields();
    const values = getValues(val, !!selectedRowKeys.length);
    values.clave = values.cuenta + separador;
    values.cuenta_padre = val;
    values.tipo_de_cuenta_contable_padre = values.tipo;
    values.tipo = values.tipo_de_cuenta_contable_padre === 6 ? 7 : 6;
    setDisabledTipoDeCuentaContable(values.tipo_de_cuenta_contable_padre === 6);
    setTimeout(() => {
      form.setFieldsValue(values);
    });

    const cuenta = typeof values.cuenta === 'string' ? values.cuenta : values.cuenta?.toString();
    const numero = cuenta[0] !== '8' ? cuenta[0] : cuenta.substr(0, 1);
    const cuentaPadre = cuentasPadres.find((cp) => cp.clave === numero);
    const configuracion = configuracionesDeSegmentos
      .find((cs) => cs.cuenta === cuentaPadre.id);
    const segs = configuracion.catalogo_de_segmento_contable
      .sort((a, b) => a.posicion.toString().localeCompare(b.posicion))
      .map((s) => ({
        ...s,
        prop: s.tipo.nombre?.normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '').replace(/ /g, '_').toLowerCase(),
      }));
    setSegmentos(segs);
    const segmentosArr = mascara?.split(separador);
    const patt = segmentosArr.reduce((acum, curr, idx) => {
      let next = '';
      const segmento = segs.find((e) => e.posicion === idx + 1);
      if (segmento.tipo.id !== 4) {
        next = `${acum}[0-9]{${curr.length}}`;
      } else {
        next = `${acum}[0-9a-z]{${curr.length}}`;
      }
      if (idx !== segmentosArr.length - 1) {
        next += separador;
      }
      return next;
    }, '^');
    const regExp = new RegExp(`${patt}$`, 'i');
    setPattern(regExp);
  };

  const getURValues = async (level, id) => {
    try {
      let uris = null;
      uris = {
        1: 'estructura-organizacional/unidades-operativas',
        2: 'estructura-organizacional/unidades-responsables',
        3: 'estructura-organizacional/centros-de-costos',
      };
      const res = await API.get(`${uris[level]}/${id}/`);
      if (res?.status === 200 && res.data) {
        setUnidadesResponsables([res.data]);
      }
    } catch (err) {
      onError(err);
    }
  };

  const getCRIValues = async (id) => {
    try {
      const res = await API.get(`configuraciones/clases/${id}/`);
      if (res?.status === 200 && res.data) {
        setClasificadorRubroIngresos([res.data]);
        form.setFieldsValue({ cri_id: id });
      }
    } catch (err) {
      onError(err);
    }
  };

  const getUnidadResponsable = async (clave) => {
    const { tipo_de_ur, ur_id } = clave;
    if (tipo_de_ur && ur_id) {
      const { nivel } = contentTypesUR.find((ct) => ct.id === tipo_de_ur);
      await getURValues(nivel, ur_id);
      form.setFieldsValue({ unidad_responsable: ur_id });
    }
  };

  const getCRI = async (clave) => {
    const { cri_id } = clave;
    if (cri_id) {
      await getCRIValues(cri_id);
    }
  };

  const retrieve = async (key) => {
    try {
      const res = await API.get(`${baseURI}${key}/`);
      if (res?.status === 200) {
        const {
          clasificador_de_objeto_gasto,
          fuente_de_financiamiento,
          proyecto,
        } = res.data;
        setTimeout(() => {
          form.setFieldsValue({
            clasificador_de_objeto_gasto: clasificador_de_objeto_gasto?.id,
            fuente_de_financiamiento: fuente_de_financiamiento?.id,
            programa: proyecto?.programas?.id,
            proyecto: proyecto?.id,
          });
        });
        setProgramas(proyecto?.programas?.id ? [proyecto.programas] : []);
        setProyectos(proyecto?.id ? [proyecto] : []);
        setFuentesDeFinanciamiento(fuente_de_financiamiento?.id ? [fuente_de_financiamiento] : []);
        setClasificadoresDeGasto(clasificador_de_objeto_gasto?.id
          ? [clasificador_de_objeto_gasto] : []);
      }
    } catch (err) {
      onError(err);
    }
  };

  const filterTreeNode = (input, node) => {
    const title = node.title ? node.title.props.children.toLowerCase() : node.title;
    if (title && title.includes(input.toLowerCase())) {
      return true;
    }
    if (node.children) {
      return filterTreeNode(input, node.children);
    }
    return false;
  };

  const getURIAndSetter = (prop) => {
    let uri = null;
    let setter = null;
    let byParam = false;
    switch (prop) {
      case 'programa':
        uri = 'contabilidad/programas/';
        setter = setProgramas;
        break;
      case 'proyecto':
        uri = 'contabilidad/proyectos/';
        setter = setProyectos;
        break;
      case 'fuente_de_financiamiento':
        uri = 'contabilidad/clasificaciones-de-fuentes-de-financiamiento/';
        setter = setFuentesDeFinanciamiento;
        break;
      case 'clasificador_por_objeto_del_gasto':
        uri = 'contabilidad/clasificaciones-objeto-gasto-interno/';
        setter = setClasificadoresDeGasto;
        break;
      case 'unidad_responsable':
        uri = 'estructura-organizacional/unidades-de-trabajo-clave/';
        setter = setUnidadesResponsables;
        byParam = true;
        break;
      case 'clasificador_de_rubro_de_ingresos':
        uri = '/contabilidad/cri/';
        setter = setClasificadorRubroIngresos;
        break;
      case 'capitulo_cog':
        uri = 'configuraciones/capitulo-cog/';
        setter = setCapitulosCOG;
        break;
      default:
        uri = null;
        break;
    }
    return {
      uri,
      setter,
      byParam,
    };
  };

  const getFk = async (clave, uri, byParam, setter) => {
    try {
      const params = {};
      if (byParam) {
        // eslint-disable-next-line no-param-reassign
        uri = `${uri}${clave}`;
      } else {
        params.clave = clave;
      }
      const res = await API.get(uri, { params });
      if (res?.status === 200 && res.data) {
        if (Array.isArray(res.data)) {
          if (res.data.length) {
            const match = res.data.find((e) => e?.clave === clave);
            if (match) {
              setter([match]);
              return match?.id;
            }
            return undefined;
          }
          return undefined;
        }
        if (setter) {
          setter([res.data]);
        }
        if (res.data.seleccionable) {
          return res.data?.id;
        }
        return undefined;
      }
      setter([]);
      return undefined;
    } catch (err) {
      setter([]);
      return undefined;
    }
  };

  const setFks = async (value) => {
    const splittedValues = value.split(separador);
    const required = ['clave'];
    await segmentos.map(async (segmento, idx) => {
      const clave = splittedValues[idx].replace(/^0+/, '');
      const { prop } = segmento;
      if (prop && prop !== 'control_interno' && prop !== 'conac') {
        const {
          uri, setter, byParam,
        } = getURIAndSetter(prop);
        const id = clave ? await getFk(clave, uri, byParam, setter) : undefined;
        required.push(prop);
        form.setFieldsValue({ [`${prop}`]: id });
        setRequiredFields(required);
      }
    });
  };

  useEffect(() => {
    if (requiredFields.length) {
      setTimeout(() => {
        form.validateFields(requiredFields);
      });
    }
    // eslint-disable-next-line
  }, [requiredFields]);

  const onChangeClaveContable = (value) => {
    if (lengthsToAddSeparator.find((l) => l === value.length)
      && value[value.length - 1] !== separador) {
      setTimeout(() => {
        form.setFieldsValue({ clave: `${value}${separador}` });
      });
    }
    if (value.length === mascara.length && form.getFieldValue('cuenta_padre')) {
      if (pattern?.test(value)) {
        setFks(value);
      }
    }
  };

  const onClickEdit = async () => {
    const [key] = selectedRowKeys;
    const match = flatCuentas.find((c) => c.id === key) || findItemNested(data, key);
    setMutable(isMutable(match));
    if (!match.conac) {
      onChangeCuentaContable(match?.id);
      onChangeClaveContable(match.clave);
      await Promise.all([
        getUnidadResponsable(match),
        getCRI(match),
        retrieve(key),
      ]);
      const {
        clasificacion,
        naturaleza,
        afectacion_presupuestal,
        momento_presupuestal_de_egreso,
        momento_presupuestal_de_ingreso,
        tipo: tipo_de_cuenta_contable_padre,
        nivel: nivel_cc,
        estados_globales: estados_globales_cuenta,
      } = flatCuentas.find((cc) => cc.id === match.cuenta_padre);
      const valuesForm = {
        cuenta_padre: match.cuenta_padre,
        clave: match.clave,
        nombre: match.nombre,
        descripcion: match.descripcion,
        estados_globales: match.estados_globales,
        tipo: match.tipo,
        nivel: match.nivel,
        clasificacion,
        naturaleza,
        tipo_de_cuenta_contable_padre,
        afectacion_presupuestal,
        momento_presupuestal_de_ingreso,
        momento_presupuestal_de_egreso,
        nivel_cc,
        estados_globales_cuenta,
      };
      setTimeout(() => form.setFieldsValue(valuesForm));
    } else {
      setTimeout(() => form.setFieldsValue({ ...match }));
    }
    setVisible(true);
  };

  const onClickExpand = () => {
    const [key] = selectedRowKeys;
    const match = flatCuentas.find((c) => c.id === key) || findItemNested(data, key);
    setMutable(isMutable(match));
    setTimeout(() => form.setFieldsValue({ ...match }));
    setVisible(true);
  };

  const getControls = () => {
    if (currentCuenta?.conac) {
      return {
        onClickExpand,
        onClickAdd,
      };
    }
    return {
      onClickAdd,
      onClickEdit,
      onClickDelete,
    };
  };

  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible ? (
          <Table
            justImport
            baseURI={baseURI}
            cols={columns}
            data={data}
            allowExpand
            permission={permission}
            rowSelection={rowSelection}
            childrenProp="children"
            filterNested
            rowKey="key"
            handleOnRowClick={handleOnRowClick}
            loading={loading}
            controls={getControls()}
          />
        ) : (
          <Card
            className="form-container"
            title={`${!selectedRowKeys.length ? 'Agregar' : 'Editar'} Cuenta Contable`}
            extra={(
              <FormSubmitControls
                onFinish={onFinish}
                onCancel={onCancel}
                allowAuthorize
                allowCancel
                mutable={mutable}
                baseURI={baseURI}
                selectedRowKeys={selectedRowKeys}
                callback={(estados_globales) => {
                  const [key] = selectedRowKeys;
                  const normalized = data.map((e) => {
                    if (e.id === key) {
                      setMutable(false);
                      return {
                        ...e,
                        estados_globales,
                      };
                    }
                    return e;
                  });
                  setData(normalized);
                  form.setFieldsValue({ estados_globales });
                }}
              />
            )}
            bordered={false}
          >
            <Form
              name="cuenta_contable_cliente"
              form={form}
              layout={currentCuenta?.conac ? 'horizontal' : 'vertical'}
              onFinish={onFinish}
              scrollToFirstError
              onValuesChange={(changedValues) => {
                const { clave } = changedValues;
                if (clave) {
                  onChangeClaveContable(clave);
                }
              }}
              initialValues={{ estados_globales: 2 }}
            >
              {!currentCuenta?.conac ? (
                <Row gutter={10}>
                  {(!currentCuenta?.conac || !currentCuenta?.id) && (
                  <Col xs={24} sm={24} md={16} lg={16} xl={8}>
                    <Form.Item
                      name="cuenta_padre"
                      label="Cuenta Superior"
                      rules={rules.required}
                    >
                      <TreeSelect
                        showSearch
                        showArrow={!selectedRowKeys.length}
                        treeNodeFilterProp="title"
                        style={{ width: '100%' }}
                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                        treeData={cuentasContables}
                        onChange={onChangeCuentaContable}
                        filterTreeNode={(input, node) => {
                          clearTimeout(timeoutCuentaContable.current);
                          timeoutCuentaContable.current = setTimeout(
                            () => filterTreeNode(input, node),
                            1000,
                          );
                          return timeoutCuentaContable.current ? filterTreeNode(input, node) : true;
                        }}
                        disabled={!mutable || selectedRowKeys.length}
                      />
                    </Form.Item>
                  </Col>
                  )}
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="clave"
                      label={(
                        <span>
                          <span style={{ paddingRight: 5 }}>
                            Cuenta Contable
                          </span>
                          {mascara && (
                          <Tooltip title={(
                            <>
                              <span>
                                {`Debe concordar con la mascara ${mascara}`}
                              </span>
                              <br />
                              <span>
                                {segmentos.map((e) => (e.tipo.nombre)).join(', ')}
                              </span>
                            </>
                          )}
                          >
                            <QuestionCircleOutlined />
                          </Tooltip>
                          )}
                        </span>
                    )}
                      rules={rules.clave}
                    >
                      <Input
                        onChange={onChangeClaveContable}
                        maxLength={mascara.length}
                        disabled={!mutable}
                        allowClear
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="nombre"
                      label="Nombre"
                      rules={rules.required}
                    >
                      <Input allowClear disabled={!mutable} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={4}>
                    <Form.Item
                      name="nivel"
                      label="Nivel"
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                      name="descripcion"
                      label="Descripción"
                      rules={rules.required}
                    >
                      <Input allowClear disabled={!mutable} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="tipo"
                      label="Tipo de cuenta"
                      rules={rules.required}
                    >
                      <Select
                        keyLabelRender
                        labelProp="nombre"
                        disabled={disabledTipoDeCuentaContable || !mutable}
                        dataSource={tiposDeCuentasContables.filter((e) => [6, 7].includes(e.id))}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="estados_globales"
                      label="Estado"
                      rules={rules.required}
                    >
                      <Select disabled dataSource={estadosGlobales} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="clasificacion"
                      label="Clasificación"
                    >
                      <Select
                        dataSource={clasificacionesDeCuentasContables}
                        disabled
                        labelProp="nombre"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="naturaleza"
                      label="Naturaleza"
                    >
                      <Select dataSource={naturalezasDeIngreso} disabled labelProp="nombre" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="afectacion_presupuestal"
                      label="Afectación Presupuestal"
                    >
                      <Select disabled dataSource={afectacionesPresupuestales} labelProp="nombre" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="momento_presupuestal_de_ingreso"
                      label={(
                        <FormItemLabel
                          longTitle="Momento Presupuestal Ingreso"
                          shortTitle="MPI"
                        />
                    )}
                    >
                      <Select
                        disabled
                        dataSource={momentosPresupuestalesDeIngreso}
                        labelProp="nombre"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="momento_presupuestal_de_egreso"
                      label={(
                        <FormItemLabel
                          longTitle="Momento Presupuestal Egreso"
                          shortTitle="MPE"
                        />
                    )}
                    >
                      <Select
                        disabled
                        dataSource={momentosPresupuestalesDeEgreso}
                        labelProp="nombre"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Typography.Title level={3}>Segmentos Presupuestales</Typography.Title>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="programa"
                      label="Programa"
                      rules={rules.programa}
                    >
                      <Select
                        disabled
                        dataSource={programas}
                        labelProp="nombre"
                        keyLabelRender
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      label="Proyecto"
                      name="proyecto"
                      rules={rules.proyecto}
                    >
                      <Select
                        disabled
                        dataSource={proyectos}
                        labelProp="nombre"
                        keyLabelRender
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      label="Unidad Responsable"
                      name="unidad_responsable"
                      rules={rules.unidad_responsable}
                    >
                      <Select
                        disabled
                        dataSource={unidadesResponsables}
                        keyLabelRender
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      label={(
                        <FormItemLabel
                          longTitle="Clasificador por Objeto del Gasto"
                          shortTitle="COG"
                        />
                    )}
                      name="clasificador_por_objeto_del_gasto"
                      rules={rules.clasificador_de_objeto_gasto}
                    >
                      <Select
                        disabled
                        dataSource={clasificadoresDeGasto}
                        labelProp="concepto"
                        keyLabelRender
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="fuente_de_financiamiento"
                      label={(
                        <FormItemLabel
                          longTitle="Fuente de Financiamiento"
                          shortTitle="FF"
                        />
                    )}
                      rules={rules.fuente_de_financiamiento}
                    >
                      <Select
                        disabled
                        dataSource={fuentesDeFinanciamiento}
                        labelProp="nombre"
                        keyLabelRender
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      label={(
                        <FormItemLabel
                          longTitle="Clasificador de Rubro de Ingresos"
                          shortTitle="CRI"
                        />
                    )}
                      name="clasificador_de_rubro_de_ingresos"
                      rules={rules.cri_id}
                    >
                      <Select
                        disabled
                        dataSource={clasificadorRubroIngresos}
                        labelProp="denominacion"
                        keyLabelRender
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      label={(
                        <FormItemLabel
                          longTitle="Capitulo COG"
                          shortTitle="Cap. COG"
                        />
                    )}
                      name="capitulo_cog"
                      rules={rules.cap_cog_id}
                    >
                      <Select
                        disabled
                        dataSource={capitulosCOG}
                        labelProp="nombre"
                        keyLabelRender
                      />
                    </Form.Item>
                  </Col>
                  <Form.Item hidden>
                    <Button htmlType="submit" />
                  </Form.Item>
                </Row>
              ) : (
                <Row gutter={[10, 15]} className="cuenta-contable">
                  <Form
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 28 }}
                    name="cuenta-contable"
                    form={form}
                    style={{ width: '100%' }}
                    colon={false}
                  >
                    <Form.Item
                      name="clave"
                      label="Cuenta Contable"
                    >
                      <Input disabled />
                    </Form.Item>
                    <Form.Item
                      name="nombre"
                      label="Nombre"
                    >
                      <Input disabled />
                    </Form.Item>
                    <Form.Item
                      name="descripcion"
                      label="Descripción"
                    >
                      <Input.TextArea autoSize={{ minRows: 5, maxRows: 5 }} disabled />
                    </Form.Item>
                    <Form.Item
                      name="nivel"
                      label="Nivel"
                    >
                      <Input disabled />
                    </Form.Item>
                    <Form.Item
                      name="posicion_cuenta_mayor"
                      label={(
                        <FormItemLabel
                          inline
                          longTitle="Posición Cuenta Mayor"
                          shortTitle="PCM"
                        />
                  )}
                    >
                      <Input disabled />
                    </Form.Item>
                    <Form.Item
                      name="clasificacion"
                      label="Clasificación"
                    >
                      <Select
                        dataSource={clasificacionesDeCuentasContables}
                        labelProp="nombre"
                        disabled
                      />
                    </Form.Item>
                    <Form.Item
                      name="naturaleza"
                      label="Naturaleza"
                    >
                      <Select
                        dataSource={naturalezasDeIngreso}
                        labelProp="nombre"
                        disabled
                      />
                    </Form.Item>
                    <Form.Item
                      name="tipo"
                      label="Tipo"
                    >
                      <Select
                        dataSource={tiposDeCuentasContables}
                        labelProp="nombre"
                        disabled
                      />
                    </Form.Item>
                    <Form.Item
                      name="afectacion_presupuestal"
                      label={(
                        <FormItemLabel
                          inline
                          longTitle="Afectación presupuestal"
                          shortTitle="AP"
                        />
                  )}
                    >
                      <Select disabled dataSource={afectacionesPresupuestales} labelProp="nombre" />
                    </Form.Item>
                    <Form.Item
                      name="momento_presupuestal_del_ingreso"
                      label={(
                        <FormItemLabel
                          inline
                          longTitle="Momento Presupuestal Ingresos"
                          shortTitle="MPI"
                        />
                  )}
                    >
                      <Select
                        disabled
                        dataSource={momentosPresupuestalesDeIngreso}
                        labelProp="nombre"
                      />
                    </Form.Item>
                    <Form.Item
                      name="momento_presupuestal_de_egreso"
                      label={(
                        <FormItemLabel
                          inline
                          longTitle="Momento Presupuestal Egresos"
                          shortTitle="MPE"
                        />
                  )}
                    >
                      <Select
                        disabled
                        dataSource={momentosPresupuestalesDeEgreso}
                        labelProp="nombre"
                      />
                    </Form.Item>
                    <Form.Item
                      name="estados_globales"
                      label="Estado"
                    >
                      <Select disabled globalStates />
                    </Form.Item>
                  </Form>
                </Row>
              )}
            </Form>
          </Card>
        )}
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => setVisibleAlert(false)}
          visible={visibleAlert}
          content={`Cuenta Contable ${form.getFieldValue('nombre')}`}
          loading={loading}
        />
      </Spin>
    </Row>
  );
}

export default ClavesContables;
