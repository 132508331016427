/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import {
  DollarOutlined,
  BankOutlined,
} from '@ant-design/icons';

import Retentions, { permissionRetenciones } from 'views/Adquisiciones/Retenciones';
// import FormasDePago from 'views/Configuracion/Tesoreria/FormasDePago';
import IngresosRecaudados, { permissionIngresoRecaudado } from 'views/Contabilidad/IngresosRecaudados/Index';
import CuentasPorPagar from 'views/Configuracion/Tesoreria/CuentasPorPagar';
import Pagos from 'views/Configuracion/Tesoreria/Pagos';
import Conceptos from 'views/Configuracion/Tesoreria/Conceptos';
import Ingresos from 'views/Configuracion/Tesoreria/Ingresos';
import Bancos, { permissionBanco } from 'views/Configuracion/Administracion/Bancos';
import CargosBancarios from 'components/Tesoreria/CargosBancarios';
import SolicitudesDePago from 'views/Tesoreria/SolicitudesDePago';
import InversionesBancarias from 'views/Tesoreria/InversionesBancarias';
import RecepcionOrdenCompra from 'views/Tesoreria/RecepcionOrdenCompra';
import FinActivoFijo from 'views/Tesoreria/FinActivoFijo';
import OtrosDepositos from 'views/Tesoreria/OtrosDepositos';
import blank from './blank';
import Beneficiarios, { permissionBeneficiario } from '../views/Configuracion/Administracion/Beneficiarios';
import CuentasBancarias, { permissionCuentasBancarias } from '../views/Configuracion/Administracion/CuentasBancarias';
import SolicitudCuentasInternas from '../views/Configuracion/Tesoreria/SolicitudCuentasInternas';
import Acreedores, { permissionAcreedor } from '../views/Configuracion/Administracion/Acreedores';
import FlujoSolicitudCuentasInternas from '../views/Tesoreria/SolicitudCuentasInternas';
import ContraRecibo from '../views/Tesoreria/ContraRecibo';
import OrdenDePago from '../views/Tesoreria/OrdenDePago';
import TransferenciasInternas from '../views/Tesoreria/TransferenciasInternas';
import CargosBancariosGrid from '../views/Tesoreria/CargosBancariosGrid';
// import Bancos from '../views/Configuracion/Administracion/Bancos';

export default {
  title: 'Tesorería',
  icon: () => <DollarOutlined />,
  showInSidebar: true,
  children: [
    {
      title: 'Fin Activo Fijo',
      icon: null,
      component: FinActivoFijo,
      type: 'private',
      showInSidebar: true,
    },
    {
      title: 'Recepción de Orden de Compra',
      icon: null,
      component: RecepcionOrdenCompra,
      type: 'private',
      showInSidebar: true,
    },
    {
      title: 'Cuentas por Pagar',
      icon: null,
      component: CuentasPorPagar,
      type: 'private',
      showInSidebar: true,
    },
    {
      title: 'Pagos Directos',
      icon: null,
      component: blank,
      type: 'private',
      showInSidebar: true,
    },
    {
      title: 'Anticipos',
      icon: null,
      component: blank,
      type: 'private',
      showInSidebar: true,
    },
    {
      title: 'Cuentas Internas',
      icon: null,
      type: 'private',
      showInSidebar: true,
      children: [
        {
          title: 'Solicitud para Gastos',
          icon: null,
          component: blank,
          type: 'private',
          showInSidebar: true,
        },
        {
          title: 'Comprobación de Gastos',
          icon: null,
          component: blank,
          type: 'private',
          showInSidebar: true,
        },
        {
          title: 'Flujo Solicitudes de Cuentas Internas',
          icon: null,
          component: FlujoSolicitudCuentasInternas,
          type: 'private',
          showInSidebar: true,
        },
      ],
    },
    {
      title: 'Contra-Recibo',
      icon: null,
      component: ContraRecibo,
      type: 'private',
      showInSidebar: true,
    },
    {
      title: 'Orden de Pago',
      icon: null,
      component: OrdenDePago,
      type: 'private',
      showInSidebar: true,
    },
    {
      title: 'Bancos',
      icon: null,
      type: 'private',
      showInSidebar: true,
      ...permissionBanco,
      children: [
        {
          title: 'Entradas',
          icon: null,
          type: 'private',
          showInSidebar: true,
          children: [
            {
              title: 'Ingresos Corte de Caja',
              icon: null,
              component: Ingresos,
              type: 'private',
              showInSidebar: true,
            },
            {
              title: 'Ingresos Cobrados',
              icon: null,
              component: IngresosRecaudados,
              type: 'private',
              showInSidebar: true,
              ...permissionIngresoRecaudado,
            },
            {
              title: 'Otros Depósitos',
              icon: null,
              component: OtrosDepositos,
              type: 'private',
              showInSidebar: true,
            },
          ],
        },
        {
          title: 'Salidas',
          icon: null,
          type: 'private',
          showInSidebar: true,
          children: [

            {
              title: 'Pagos',
              icon: null,
              component: Pagos,
              type: 'private',
              showInSidebar: true,
            },
            {
              title: 'Transferencias Internas',
              icon: null,
              component: TransferenciasInternas,
              type: 'private',
              showInSidebar: true,
            },
            {
              title: 'Inversiones',
              icon: null,
              component: InversionesBancarias,
              type: 'private',
              showInSidebar: true,
            },
            {
              title: 'Cargos Bancarios',
              icon: null,
              component: CargosBancariosGrid,
              type: 'private',
              showInSidebar: true,
            },
          ],
        },

      ],
    },
    {
      title: 'Catálogos',
      icon: null,
      type: 'private',
      showInSidebar: true,
      children: [
        {
          title: 'Beneficiarios',
          icon: null,
          component: Beneficiarios,
          type: 'private',
          showInSidebar: true,
          ...permissionBeneficiario,
        },
        {
          title: 'Acreedores',
          icon: null,
          component: Acreedores,
          type: 'private',
          showInSidebar: true,
          ...permissionAcreedor,
        },
        {
          title: 'Bancos',
          icon: null,
          showInSidebar: true,
          component: Bancos,
          type: 'private',
          ...permissionBanco,
        },
        {
          title: 'Cuentas Bancarias',
          icon: () => <BankOutlined />,
          component: CuentasBancarias,
          type: 'private',
          showInSidebar: true,
          ...permissionCuentasBancarias,
        },
        {
          title: 'Conceptos de Pago',
          icon: null,
          component: Retentions,
          type: 'private',
          showInSidebar: true,
        },
        {
          title: 'Retenciones',
          icon: null,
          component: Retentions,
          type: 'private',
          showInSidebar: true,
          ...permissionRetenciones,
        },
        //! Este no aparece en el mockUp pero tiene funcionalidad
        {
          title: 'Conceptos',
          icon: null,
          component: Conceptos,
          type: 'private',
          showInSidebar: true,
        },
        {
          title: 'Cargos Bancarios',
          icon: null,
          component: CargosBancarios,
          type: 'private',
          showInSidebar: true,
        },
        {
          title: 'Solicitudes de Pago',
          icon: null,
          component: SolicitudesDePago,
          type: 'private',
          showInSidebar: true,
        },
        {
          title: 'Configurador de Solicitudes de Cuentas Internas',
          icon: null,
          component: SolicitudCuentasInternas,
          type: 'private',
          showInSidebar: true,
        },
        //!
      ],
    },
  ],
};
