import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Row,
  Col,
  Spin,
  Card,
  Form,
  Button,
  Modal,
  message,
  Input,
  Tabs,
  DatePicker,
} from 'antd';
import FormSubmitControls from 'components/FormSubmitControls';
import Table from 'components/Table';
import ModalDelete from 'components/ModalDelete';
import API from 'utils/api';
import fetchSome from 'utils/fetchSome';
import { onError, onSuccess } from 'utils/handlers';
import { InputSearchPro } from 'components/InputSearch';
import { SET_CONFIG_MASCARA_CONTABLE } from 'store/reducers/catalogs';
import BusquedaClavesContables from 'components/BusquedaClavesContables';
import AvatarUploader from 'components/AvatarUploader';
import getFormattedValues, { DATE_FRONTEND_FORMAT, formatReceived } from 'utils/formatValues';
import Select from 'components/Select';
import makeMessager from 'utils/complexFormsMessages';
import { isMutable } from 'utils/estadosGlobales';
import Afectaciones from 'components/Catalogos/Afectaciones';
import Origenes from 'components/Catalogos/Origenes';
import Identificadores from 'components/Catalogos/Identificadores';
import Bitacoras from 'components/Catalogos/Bitacoras';
import AreaDeUbicacion from 'views/Configuracion/Catalogos/ControlPatrimonial/AreaDeUbicacion';
import Avaluos from 'components/Catalogos/Avaluos';
import ExpedientesDeActivos from 'components/Catalogos/ExpedientesDeActivos';
import Direccion from 'components/Direccion';
import DatosDeControl from 'components/Catalogos/DatosDeControl';
import ControlYSeguimientos from 'components/Catalogos/ControlYSeguimientos';
import Registros from 'components/Catalogos/Registros';

export const permissionActivoFijo = {
  permissionModel: 'activofijo',
};

const baseURI = 'controlpatrimonial/activos-fijos/';
const { TabPane } = Tabs;

// eslint-disable-next-line react/prop-types
function ActivosFijos({ permission }) {
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  const configMascaraContable = useSelector(({ catalogs }) => catalogs.configMascaraContable);
  // const [form] = Form.useForm();
  const [formTab] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [formAddress, setFormAddress] = useState();
  const [formControl, setFormControl] = useState();
  const [tipoActivo, setTipoActivo] = useState([]);
  const [clasificacionActivo, setClasificacionActivo] = useState([]);
  const [bienInmueble, setBienInmueble] = useState([]);
  const [estado, setEstado] = useState([]);
  // const [asignacion, setAsignacion] = useState([]);
  const [uso, setUso] = useState([]);
  // const [productos, setProductos] = useState([]);
  const [configuradorDeActivo, setConfiguradorDeActivo] = useState([]);

  const [productoFound, setProductoFound] = useState([]);
  const [visibleBusquedaCuentas, setVisibleBusquedaCuentas] = useState(false);
  const [mascara, setMascara] = useState();
  const [separador, setSeparador] = useState();
  const [pattern, setPattern] = useState();
  const [cuentaContableFound, setCuentaContableFound] = useState([]);
  const [selectedClave, setSelectedClave] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  const basicKeys = [
    'generalTab',
    'origen',
    'identificaciones',
    'datosDeControl',
    'registro',
    'afectaciones',
    'areaDeUbicacion',
    'avaluos',
    'bitacoras',
    'expediente',
  ];

  const complexForms = [
    {
      key: 'direccion',
      formInstance: formAddress,
    },
    {
      key: 'controlSeguimiento',
      formInstance: formControl,
    },
  ];

  const [currentTabKey, setCurrentTabKey] = useState('generalTab');
  const [mutable, setMutable] = useState(true);

  const fetchData = async (page = 1, q = null) => {
    try {
      setLoading(true);
      setCurrentPage(page);
      const response = await API.get(baseURI, { params: { page, ...q } });
      const results = formatReceived(response.data.results);
      setData({ ...response.data, results });
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const fetchAll = async () => {
    try {
      setLoading(true);
      const resTipo = await API.get('/controlpatrimonial/tipos-de-activos/');
      setTipoActivo(resTipo.data);
      const resConfiguradorDeActivo = await API.get('/controlpatrimonial/configurador-de-activo-fijo/');
      setConfiguradorDeActivo(resConfiguradorDeActivo.data || []);
      const resClasificacion = await API.get('/adquisiciones/catalogo-clasificador-de-activo/');
      setClasificacionActivo(resClasificacion.data);
      const resEstado = await API.get('/controlpatrimonial/estados-de-los-bienes/');
      setEstado(resEstado.data);
      const resUso = await API.get('/controlpatrimonial/usos/');
      setUso(resUso.data);
      // const resAsignacion = await API.get('/controlpatrimonial/estatus-de-asignaciones/');
      // setAsignacion(resAsignacion.data);
      const resBienInmueble = await API.get('/controlpatrimonial/sub-grupos-bienes-inmuebles');
      setBienInmueble(resBienInmueble.data);
      if (configMascaraContable) {
        setMascara(configMascaraContable?.mascara);
        const separator = configMascaraContable?.separador;
        setSeparador(separator);
        const _segmentos = configMascaraContable?.mascara?.split(separator) || [];
        if (_segmentos?.length) {
          // eslint-disable-next-line prefer-template
          const patt = _segmentos.reduce((acum, curr, idx) => `${acum}[0-9]{${curr.length}}`
            + (idx !== _segmentos.at(-1) ? separator : '$'), '^');
          const regExp = new RegExp(`${patt}`);
          setPattern(regExp);
        }
      } else {
        await fetchSome('configuraciones/configuraciones-de-mascaras/', SET_CONFIG_MASCARA_CONTABLE);
      }
      await fetchData();
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchAll();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setSelectedRowKeys([]);
    // form.resetFields();
    setVisible(false);
    setCurrentTabKey('generalTab');
    setMutable(true);
    setProductoFound([]);
    setCuentaContableFound([]);
  };

  // TODO: onfinish para fechas
  const onFinish = async (_values, _continue = false) => {
    try {
      setLoading(true);
      // await form.validateFields();
      await formTab.validateFields();
      const values = {
        // ...form.getFieldsValue(),
        ...formTab.getFieldsValue(),
      };
      const formatedValues = getFormattedValues(values);
      const productMatch = productoFound.find((e) => e.id === values.producto);
      values.clave_del_producto = productMatch.clave;

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      const formattedValues = getFormattedValues(values, {
        files: ['foto'],
        formData: config.headers,
        clean: false,
      });
      if (!selectedRowKeys.length) {
        const response = await API.post(baseURI, formattedValues, config, formatedValues);
        if (response?.status === 201) {
          if (!_continue) {
            onSuccess(response, 'Agregado correctamente');
            onCancel();
            await fetchData();
          } else {
            await fetchData();
            setSelectedRowKeys([response.data.id]);
          }
        }
      } else {
        const [key] = selectedRowKeys;
        const response = await API.put(`${baseURI}${key}/`, formattedValues, config, formatedValues);
        if (response?.status === 200) {
          if (!_continue) {
            onSuccess(response, 'Editado correctamente');
            onCancel();
            await fetchData();
          } else {
            await fetchData();
            setSelectedRowKeys([response.data.id]);
          }
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`${baseURI}${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          setVisibleAlert(false);
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const showMsg = makeMessager(setCurrentTabKey, fetchData);

  const onChangeTabKey = (key) => {
    const fromBasic = basicKeys.includes(currentTabKey);
    const toBasic = basicKeys.includes(key);
    const match = complexForms.find((e) => e.key === currentTabKey);
    if (!match) {
      if (fromBasic && toBasic) {
        setCurrentTabKey(key);
      } else if (fromBasic) {
        showMsg(key);
      } else {
        setCurrentTabKey(key);
      }
    } else {
      match.formInstance.onFinishHandler(key, true);
    }
  };

  const handleOnFinish = (vals, _continue = false) => {
    const match = complexForms.find((e) => e.key === currentTabKey);
    if (match) {
      match.formInstance.onFinishHandler(null, _continue);
    } else {
      onFinish(null, _continue);
    }
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    // setCuentaContableFound(record.cuenta_contable ? [record.cuenta_contable] : []);
    // form.setFieldsValue(record);
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const onClickDelete = () => {
    if (mutable) {
      setVisibleAlert(true);
    } else {
      message.info('No se puede eliminar Cuentas Contables autorizadas/canceladas');
      onCancel();
    }
  };

  const columns = [
    {
      titleText: 'Clave',
      dataIndex: 'clave_interna',
      key: 'clave_interna',
      width: 150,
    },
    {
      titleText: 'Descripción',
      dataIndex: 'descripcion',
      key: 'descripcion',
      width: 200,
    },
    {
      titleText: 'Clave del Producto',
      dataIndex: 'clave_del_producto',
      key: 'clave_del_producto',
      width: 200,
    },
    {
      titleText: 'Estado',
      dataIndex: 'estados_globales',
      key: 'estados_globales',
      width: 150,
      render: (val) => (estadosGlobales.find((e) => e.id === val)?.descripcion),
    },
  ];

  const onFinishBusqueda = () => {
    if (selectedClave) {
      setCuentaContableFound([selectedClave]);
      setTimeout(() => {
        formTab.setFieldsValue({
          cuenta_contable: selectedClave.id,
        });
      });
      setVisibleBusquedaCuentas(false);
    } else {
      message.info('No ha seleccionado una Cuenta Contable');
    }
  };

  const requiredRule = {
    required: true,
    message: 'El campo es requerido',
  };

  const rules = {
    required: [requiredRule],
    clave_contable__clave: [
      requiredRule,
      {
        validator: async (rule, value) => {
          if (value && pattern && (!pattern.test(value))) {
            throw new Error('Introduzca una clave válida');
          }
        },
      },
    ],
  };

  const onClickEdit = async () => {
    try {
      setLoading(true);
      const [key] = selectedRowKeys;
      const clone = [...data.results];
      const match = clone.find((e) => e.id === key);
      const resProduct = await API.get(`configuraciones/productos/${match.producto}`);
      // if (match?.cuenta_contable) {
      //   const resCuenta = await API
      //     .get(`contabilidad/cuentas-contables/${match.cuenta_contable}/`);
      //   setCuentaContableFound([resCuenta.data]);
      // }
      setProductoFound(formatReceived([resProduct.data]));
      setMutable(isMutable(match));
      setVisible(true);
      setTimeout(() => {
        formTab.setFieldsValue({
          ...match,
          // cuenta_contable: resCuenta.data.id,
        });
      });
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const resetAndValidateProductos = () => {
    setProductoFound([]);
    formTab.resetFields(['clave']);
    formTab.validateFields(['clave']);
  };

  const busquedaProducto = async () => {
    try {
      setLoading(true);
      await formTab.validateFields(['clave']);
      const { clave } = formTab.getFieldsValue();
      // const clone = [...data];
      const response = await API.get('configuraciones/productos/', {
        params: {
          clave_interior: clave,
        },
      });

      if (response?.status === 200 && response?.data?.length) {
        const [product] = response.data;
        const productMatch = response.data.find((e) => e.producto === product.id);
        if (!productMatch) {
          formTab.setFieldsValue({
            producto: product.id,
            clave_del_producto: product.clave_anterior,
            clave_cucop_producto: product.cucop,
          });
          setProductoFound([product]);
        } else {
          message.warn('Ya existe un activo fijo con ese bien', 2.5);
        }
      } else {
        message.info('No se encontraron coincidencias');
        setProductoFound([]);
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const resetAndValidateCuentaContable = () => {
    setCuentaContableFound([]);
    formTab.resetFields(['clave_contable']);
    formTab.validateFields(['clave_contable']);
  };

  const fetchCuentaContable = async () => {
    try {
      formTab.validateFields(['clave_contable__clave']);
      const clave = formTab.getFieldValue('clave_contable__clave');
      if (clave) {
        setLoading(true);
        const res = await API.get('contabilidad/cuentas-contables/', {
          params: {
            clave,
            tipo_de_cuenta_contable: 7,
          },
        });
        if (res?.status === 200 && res.data?.length) {
          const claveContable = res.data[0];
          setCuentaContableFound([claveContable]);
          formTab.setFieldsValue({
            cuenta_contable: claveContable.id,
          });
          setTimeout(() => {
            formTab.validateFields(['cuenta_contable']);
          });
        } else {
          message.info('No se encontraron coincidencias');
          setCuentaContableFound([]);
        }
        setLoading(false);
      }
    } catch (err) {
      onError(err, setLoading);
      setCuentaContableFound([]);
    }
  };

  const onChangeClaveContable = (e) => {
    const { target: { value }, keyCode } = e;
    let val = value;
    if (keyCode) {
      const char = String.fromCharCode(keyCode).toLowerCase();
      val += char;
    }
    if (mascara?.charAt(val.length) === separador) {
      val += separador;
    }
    if (val.length === mascara?.length) {
      setTimeout(fetchCuentaContable);
    } else if (!val?.length) {
      resetAndValidateCuentaContable();
    }
    setTimeout(() => {
      formTab.setFieldsValue({ clave_contable__clave: val });
    });
  };

  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible ? (
          <Table
            allowImport
            baseURI={baseURI}
            permission={permission}
            cols={columns}
            data={data?.results}
            rowSelection={rowSelection}
            handleOnRowClick={handleOnRowClick}
            currentPage={currentPage}
            loading={loading}
            controls={{
              onClickAdd,
              onClickEdit,
              onClickDelete,
            }}
            pagination={false}
            paginationCallback={fetchData}
            count={data?.count}
            mobileColIndex={0}
          />
        ) : (
          <Card
            className="form-container"
            title={`${!selectedRowKeys.length ? 'Agregar' : 'Editar'} Activo Fijo`}
            extra={(
              <FormSubmitControls
                onFinish={basicKeys
                  .concat(complexForms.map((e) => e.key))
                  .includes(currentTabKey)
                  ? handleOnFinish : null}
                onCancel={onCancel}
                allowAuthorize
                allowCancel
                allowSaveAndContinue
                mutable={mutable}
                baseURI={baseURI}
                selectedRowKeys={selectedRowKeys}
                callback={(estados_globales) => {
                  const [key] = selectedRowKeys;
                  const normalized = data.map((e) => {
                    if (e.id === key) {
                      setMutable(false);
                      return {
                        ...e,
                        estados_globales,
                      };
                    }
                    return e;
                  });
                  setData(normalized);
                  formTab.setFieldsValue({ estados_globales });
                }}
              />
            )}
            bordered={false}
          >
            <Tabs
              type="card"
              activeKey={currentTabKey}
              onChange={mutable ? setCurrentTabKey : onChangeTabKey}
            >
              <TabPane
                key="generalTab"
                tab="General"
                forceRender
              >
                <Form
                  name="generalTab"
                  form={formTab}
                  layout="vertical"
                  onFinish={onFinish}
                  scrollToFirstError
                  initialValues={{ estados_globales: 1 }}
                >
                  <Row gutter={10}>
                    <Col xs={24} sm={24} md={8}>
                      <AvatarUploader
                        form={formTab}
                        imageURL={formTab.getFieldValue('foto')}
                        formItemName="foto"
                        formItemLabel="Foto"
                        disabled={!mutable}
                      />
                    </Col>
                  </Row>
                  <Row gutter={10}>
                    <Col xs={24} sm={24} md={6}>
                      <Form.Item
                        name="fecha_de_alta"
                        label="Fecha de Alta"
                      >
                        <DatePicker format={DATE_FRONTEND_FORMAT} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={6}>
                      <Form.Item
                        name="tipo_de_activo"
                        rules={rules.required}
                        label="Tipo de Activo"
                      >
                        <Select dataSource={tipoActivo} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={6}>
                      <Form.Item
                        name="clasificador_de_activo"
                        // rules={rules.required}
                        label="Clasificador de Activo"
                      >
                        <Select
                          dataSource={clasificacionActivo}
                          labelProp="concepto"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={6}>
                      <Form.Item
                        name="configuracion_de_activo"
                        // rules={rules.required}
                        label="Configurador de Activo"
                      >
                        <Select
                          dataSource={configuradorDeActivo}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={6}>
                      <Form.Item
                        name="clave_del_producto"
                        // rules={rules.required}
                        label="Clave del Producto"
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={6}>
                      <Form.Item
                        name="clave_cucop_producto"
                        // rules={rules.required}
                        label="Clave CUCOP"
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={6}>
                      <Form.Item
                        name="conac_bien_inmueble"
                        rules={rules.required}
                        label="Clave CONAC Bienes Inmuebles"
                      >
                        <Select
                          dataSource={bienInmueble}
                          keyLabelRender
                          keyProp="clave"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={6}>
                      <Form.Item
                        name="clave_interna"
                        // rules={rules.required}
                        label="Clave interna"
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <InputSearchPro
                      label="Producto"
                      tooltip="Búsqueda por clave del bien"
                      name="clave"
                      // rules={rules.required}
                      disabled={!mutable}
                      onPressEnter={() => busquedaProducto()}
                      breakPoints={{
                        xs: 24,
                        sm: 24,
                        md: 12,
                      }}
                      inputProps={{
                        onPressEnter: () => busquedaProducto(),
                        onChange: ({ target: { value } }) => {
                          if (!value) {
                            resetAndValidateProductos();
                          }
                        },
                      }}
                      resultProps={{
                        name: 'producto',
                        label: 'Producto',
                        dataSource: productoFound,
                        labelProp: 'descripcion',
                        rules: rules.required,
                        keyLabelRender: true,
                        keyProp: 'clave',
                        onClear: resetAndValidateProductos,
                        breakPoints: {
                          xs: 24,
                          sm: 24,
                          md: 12,
                        },
                      }}
                    />

                    <InputSearchPro
                      name="clave_contable__clave"
                      onClickAdvanced={() => setVisibleBusquedaCuentas(true)}
                      // rules={rules.clave_contable__clave}
                      disabled={!mutable}
                      label="Cuenta Contable"
                      inputProps={{
                        onPressEnter: () => fetchCuentaContable(),
                        onChange: onChangeClaveContable,
                        maxLength: mascara?.length,
                      }}
                      breakPoints={{
                        xs: 24,
                        sm: 24,
                        md: 12,
                      }}
                      resultProps={{
                        name: 'cuenta_contable',
                        label: 'Cuenta Contable',
                        // rules: rules.required,
                        dataSource: cuentaContableFound,
                        keyLabelRender: true,
                        keyProp: 'clave',
                        labelProp: 'nombre',
                        onClear: resetAndValidateCuentaContable,
                        breakPoints: {
                          md: 12,
                        },
                      }}
                    />
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="codigo"
                        label="Código"
                      >
                        <Input allowClear disabled={!mutable} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="numero_economico"
                        label="Número económico"
                      >
                        <Input allowClear disabled={!mutable} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="estado_fisico"
                        label="Estado Fisico"
                      >
                        <Select dataSource={estado} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="uso"
                        label="Uso"
                      >
                        <Select dataSource={uso} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="bien_economico"
                        label="Bien Económico"
                      >
                        <Select trueFalse />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item
                        name="observaciones"
                        label="Observaciones"
                      >
                        <Input.TextArea
                          allowClear
                          disabled={!mutable || !!selectedRowKeys.length}
                          autoSize={{ minRows: 4, maxRows: 4 }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item
                        name="finalidad"
                        label="Finalidad"
                      >
                        <Input.TextArea
                          allowClear
                          disabled={!mutable || !!selectedRowKeys.length}
                          autoSize={{ minRows: 4, maxRows: 4 }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item
                        name="descripcion"
                        label="Descripción"
                      >
                        <Input.TextArea
                          allowClear
                          disabled={!mutable || !!selectedRowKeys.length}
                          autoSize={{ minRows: 4, maxRows: 4 }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item
                        name="codigo"
                        label="Codigo de Barras, QR o RFID"
                      >
                        <Input.TextArea
                          allowClear
                          disabled={!mutable || !!selectedRowKeys.length}
                          autoSize={{ minRows: 4, maxRows: 4 }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        label="Estado"
                        name="estados_globales"
                      >
                        <Select
                          dataSource={estadosGlobales}
                          disabled={!selectedRowKeys.length || !mutable}
                        />
                      </Form.Item>
                    </Col>
                    <Form.Item hidden>
                      <Button htmlType="submit" />
                    </Form.Item>
                  </Row>
                </Form>
              </TabPane>
              <TabPane
                key="origen"
                tab="Origen"
                disabled={!selectedRowKeys.length}
                forceRender={selectedRowKeys.length}
              >
                <Origenes activo={selectedRowKeys[0]} />
              </TabPane>
              {/* TODO: Necesita tabla de cambios hay endpoint */}
              <TabPane
                key="identificaciones"
                tab="Identificación"
                // disabled={!selectedRowKeys.length}
                forceRender={!selectedRowKeys.length}
              >
                <Identificadores activo={selectedRowKeys} />
              </TabPane>
              <TabPane
                key="datosDeControl"
                tab="Datos de Control"
                // disabled={!selectedRowKeys.length}
                forceRender={!selectedRowKeys.length}
              >
                <DatosDeControl activo={selectedRowKeys} />
              </TabPane>
              <TabPane
                key="registro"
                tab="Registro"
                // disabled={!selectedRowKeys.length}
                forceRender={!selectedRowKeys.length}
              >
                <Registros activo={selectedRowKeys} />
              </TabPane>
              {/* TODO: Tablas necesitan la relacion */}
              <TabPane
                key="afectaciones"
                tab="Afectación"
                // disabled={!selectedRowKeys.length}
                forceRender={!selectedRowKeys.length}
              >
                <Afectaciones activo={selectedRowKeys} />
              </TabPane>
              <TabPane
                key="controlSeguimiento"
                tab="Control y Seguimiento"
                // disabled={!selectedRowKeys.length}
                forceRender={!selectedRowKeys.length}
              >
                <ControlYSeguimientos
                  activo={selectedRowKeys}
                  setForm={setFormControl}
                  setCurrentTabKey={setCurrentTabKey}
                />
              </TabPane>
              <TabPane
                key="areaDeUbicacion"
                tab="Area de ubicacion"
                // disabled={!selectedRowKeys.length}
                forceRender={!selectedRowKeys.length}
              >
                <AreaDeUbicacion activo_fijo={selectedRowKeys[0]} />
              </TabPane>
              <TabPane
                key="direccion"
                tab="Dirección"
              // disabled={!selectedRowKeys.length}
              >
                <Direccion
                  setFormAddress={setFormAddress}
                  parentID={selectedRowKeys[0]}
                  currentURI={baseURI}
                  setLoading={setLoading}
                  setCurrentTabKey={setCurrentTabKey}
                  callback={(values, _continue) => {
                    if (!_continue) {
                      onCancel();
                    } else {
                      const [key] = selectedRowKeys;
                      const clone = [...data];
                      const mappedData = clone.map((e) => (e.id === key
                        ? ({ ...e, domicilio_fiscal: values?.id }) : e));
                      setData(mappedData);
                    }
                  }}
                />
              </TabPane>
              <TabPane
                key="avaluos"
                tab="Avalúos"
                // disabled={!selectedRowKeys.length}
                forceRender={!selectedRowKeys.length}
              >
                <Avaluos activo={selectedRowKeys} />
              </TabPane>
              <TabPane
                key="bitacoras"
                tab="Bitacoras"
                // disabled={!selectedRowKeys.length}
                forceRender={!selectedRowKeys.length}
              >
                <Bitacoras activo={selectedRowKeys} />
              </TabPane>
              <TabPane
                key="expediente"
                tab="Expediente"
                // disabled={!selectedRowKeys.length}
                forceRender={!selectedRowKeys.length}
              >
                <ExpedientesDeActivos activo={selectedRowKeys} />
              </TabPane>
            </Tabs>

          </Card>
        )}
        <Modal
          visible={visibleBusquedaCuentas}
          title={(
            <Row justify="space-between">
              Búsqueda Avanzada Cuentas
              <FormSubmitControls
                onCancel={() => setVisibleBusquedaCuentas(false)}
                onFinish={onFinishBusqueda}
              />
            </Row>
          )}
          onCancel={() => setVisibleBusquedaCuentas(false)}
          closable={false}
          footer={null}
          width={800}
          forceRender
          centered
        >
          <BusquedaClavesContables callback={setSelectedClave} />
        </Modal>
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => setVisibleAlert(false)}
          visible={visibleAlert}
          content="El activo seleccionado"
          loading={loading}
        />
      </Spin>
    </Row>
  );
}

export default ActivosFijos;
