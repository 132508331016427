import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Row,
  Col,
  Spin,
  Form,
  Input,
  Card,
  Button,
  message,
  DatePicker,
} from 'antd';

import FormSubmitControls from 'components/FormSubmitControls';
import Table from 'components/Table';
import API from 'utils/api';
import { onError, onSuccess } from 'utils/handlers';
import Select from 'components/Select';
import { isMutable } from 'utils/estadosGlobales';
import ModalDelete from 'components/ModalDelete';
import { InputSearchPro } from 'components/InputSearch';
import { SET_CONFIG_MASCARA_CONTABLE } from 'store/reducers/catalogs';
import fetchSome from 'utils/fetchSome';
import NumericInput from 'components/NumericInput';
import getFormattedValues, { DATE_FRONTEND_FORMAT, formatReceived } from 'utils/formatValues';
import Moment from 'moment';

export const permissionRetenciones = {
  permissionModel: 'retenciones',
};

const baseURI = 'configuraciones/retenciones-impuestos/';

// eslint-disable-next-line react/prop-types
function ClasificacionAdminsitrativa({ permission }) {
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  const configMascaraContable = useSelector(({ catalogs }) => catalogs.configMascaraContable);
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [mutable, setMutable] = useState(true);

  const [tiposDeRetenciones, setTiposDeRetenciones] = useState([]);
  const [cuentaContableFound, setCuentaContableFound] = useState([]);
  const [mascara, setMascara] = useState();
  const [separador, setSeparador] = useState();
  const [pattern, setPattern] = useState();

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await API.get(baseURI);
      setData(formatReceived(res.data));
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const fetchAll = async () => {
    try {
      setLoading(true);

      if (configMascaraContable) {
        setMascara(configMascaraContable?.mascara);
        const separator = configMascaraContable?.separador;
        setSeparador(separator);
        const _segmentos = configMascaraContable?.mascara?.split(separator) || [];
        if (_segmentos?.length) {
          // eslint-disable-next-line prefer-template
          const patt = _segmentos.reduce((acum, curr, idx) => `${acum}[0-9]{${curr.length}}`
            + (idx !== _segmentos.length - 1 ? separator : '$'), '^');
          const regExp = new RegExp(`${patt}`);
          setPattern(regExp);
        }
      } else {
        await fetchSome(
          'configuraciones/configuraciones-de-mascaras/',
          SET_CONFIG_MASCARA_CONTABLE,
        );
      }

      const resTiposRetenciones = await API.get('configuraciones/tipos-de-retencion/');
      setTiposDeRetenciones(resTiposRetenciones.data);

      await fetchData();
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchAll();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setVisible(false);
    setSelectedRowKeys([]);
    form.resetFields();
    setMutable(true);
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      if (values.estados_globales === 3) {
        values.fecha_de_inactivacion = new Moment();
      }
      const formattedValues = getFormattedValues(values);
      if (!selectedRowKeys.length) {
        const response = await API.post(baseURI, formattedValues);
        if (response?.status === 201) {
          onSuccess(response, 'Agregado correctamente');
          onCancel();
          await fetchData();
        }
      } else {
        const [key] = selectedRowKeys;
        const response = await API.put(`${baseURI}${key}/`, formattedValues);
        if (response?.status === 200) {
          onSuccess(response, 'Actualizado correctamente');
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    form.setFieldsValue(record);
    setMutable(isMutable(record));
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const columns = [
    {
      titleText: 'Concepto',
      dataIndex: 'concepto',
      key: 'concepto',
      width: 150,
    },
    {
      titleText: 'Descripción',
      dataIndex: 'descripcion',
      key: 'descripcion',
      width: 300,
    },

    {
      titleText: 'Tipo De Retención',
      dataIndex: 'tipo_de_retencion',
      key: 'tipo_de_retencion',
      width: 200,
      render: (e) => tiposDeRetenciones.find((i) => i.id === e)?.concepto,
    },
    {
      titleText: 'Fecha De Activación',
      dataIndex: 'fecha_de_activacion',
      key: 'fecha_de_activacion',
      width: 200,
      render: (value) => value?.format(DATE_FRONTEND_FORMAT),
    },
    {
      titleText: 'Fecha De Inactivación',
      dataIndex: 'fecha_de_inactivacion',
      key: 'fecha_de_inactivacion',
      width: 200,
      render: (val) => (Moment.isMoment(val) ? val.format('DD/MM/YYYY') : '-'),
    },
    {
      titleText: '% Tasa',
      dataIndex: 'tasa',
      key: 'tasa',
      width: 100,
    },
    {
      titleText: 'Estado',
      dataIndex: 'estados_globales',
      key: 'estados_globales',
      width: 300,
      render: (e) => estadosGlobales.find((i) => i.id === e)?.descripcion,
    },
    // {
    //   titleText: 'Cuenta Contable',
    //   dataIndex: 'cuenta_contable',
    //   key: 'cuenta_contable',
    //   width: 300,
    // },
  ];

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const onClickEdit = async () => {
    try {
      setLoading(true);
      const clone = [...data];
      const [key] = selectedRowKeys;
      const match = clone.find((e) => e.id === key);
      if (match?.cuenta_contable) {
        const resCuenta = await API
          .get(`contabilidad/cuentas-contables/${match.cuenta_contable}/`);
        setCuentaContableFound([resCuenta.data]);
      }
      setTimeout(() => form.setFieldsValue(match));
      setVisible(true);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`${baseURI}${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          setVisibleAlert(false);
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const requiredRule = {
    message: 'El campo es requerido',
    required: true,
  };

  const rules = {
    required: [requiredRule],
    cuenta_contable__clave: [
      {
        validator: async (_, value) => {
          if (value && pattern && (!pattern.test(value))) {
            throw new Error('Introduzca una clave válida');
          }
        },
      },
    ],
  };

  const resetAndValidateCuentaContable = () => {
    setCuentaContableFound([]);
    form.resetFields(['cuenta_contable']);
    form.validateFields(['cuenta_contable']);
  };

  const fetchCuentaContable = async () => {
    try {
      form.validateFields(['cuenta_contable__clave']);
      const clave = form.getFieldValue('cuenta_contable__clave');
      if (clave) {
        setLoading(true);
        const res = await API.get('contabilidad/cuentas-contables/', { params: { clave, tipo: 7 } });
        if (res?.status === 200 && res.data?.length) {
          const claveContable = res.data[0];
          setCuentaContableFound([claveContable]);
          form.setFieldsValue({ cuenta_contable: claveContable.id });
          setTimeout(() => {
            form.validateFields(['cuenta_contable']);
          });
        } else {
          message.info('No se encontraron coincidencias');
          setCuentaContableFound([]);
        }
        setLoading(false);
      }
    } catch (err) {
      onError(err, setLoading);
      setCuentaContableFound([]);
    }
  };

  const onChangeClaveContable = (e) => {
    const { target: { value }, keyCode } = e;
    let val = value;
    if (keyCode) {
      const char = String.fromCharCode(keyCode).toLowerCase();
      val += char;
    }
    if (mascara?.charAt(val?.length) === separador) {
      val += separador;
    }
    if (val?.length === mascara?.length) {
      setTimeout(fetchCuentaContable);
    } else if (!val?.length) {
      resetAndValidateCuentaContable();
    }
    setTimeout(() => {
      form.setFieldsValue({ cuenta_contable__clave: val });
    });
  };

  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible ? (
          <Table
            cols={columns}
            data={data}
            rowSelection={rowSelection}
            handleOnRowClick={handleOnRowClick}
            controls={{
              onClickAdd,
              onClickDelete,
              onClickEdit,
            }}
            mobileColInde={0}
            permission={permission}
          />
        ) : (
          <Card
            className="form-container"
            title={`${!selectedRowKeys.length ? 'Agregar' : 'Editar'} Retención`}
            extra={(
              <FormSubmitControls
                onFinish={onFinish}
                onCancel={onCancel}
                allowAuthorize
                allowCancel
                mutable={mutable}
                baseURI={baseURI}
                selectedRowKeys={selectedRowKeys}
                callback={(estados_globales) => {
                  const [key] = selectedRowKeys;
                  const normalized = data.map((e) => (e.id === key
                    ? ({ ...e, estados_globales }) : e));
                  setData(normalized);
                  form.setFieldsValue({ estados_globales });
                }}
              />
            )}
            bordered={false}
          >
            <Form
              name="general"
              form={form}
              layout="vertical"
              onFinish={onFinish}
              scrollToFirstError
              initialValues={{
                estados_globales: 1,
                aplica_retencion: true,
                fecha_de_activacion: Moment(),
              }}
            >
              <Row gutter={10}>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="concepto"
                    rules={rules.required}
                    label="Concepto"
                  >
                    <Input allowClear disabled={!mutable} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={16}>
                  <Form.Item
                    name="descripcion"
                    rules={rules.required}
                    label="Descripción"
                  >
                    <Input
                      allowClear
                      disabled={!mutable}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="tipo_de_retencion"
                    rules={rules.required}
                    label="Tipo de retencion"
                  >
                    <Select
                      dataSource={tiposDeRetenciones}
                      labelProp="concepto"
                      disabled={!mutable}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <NumericInput
                    label="% Tasa"
                    name="tasa"
                    allowZero
                    decimal
                    prefix="%"
                  />
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="fecha_de_activacion"
                    label="Fecha de Activación"
                  >
                    <DatePicker
                      format="DD/MM/YYYY"
                      placeholder=""
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="fecha_de_inactivacion"
                    label="Fecha de Inactivación"
                  >
                    <DatePicker
                      format="DD/MM/YYYY"
                      placeholder=""
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    label="Estado"
                    rules={rules.required}
                    name="estados_globales"
                  >
                    <Select
                      dataSource={estadosGlobales}
                      disabled={!selectedRowKeys.length || !mutable}
                    />
                  </Form.Item>
                </Col>
                <InputSearchPro
                  name="cuenta_contable__clave"
                  rules={rules.cuenta_contable__clave}
                  label="Búsqueda Cuenta Contable"
                  tooltip={`Debe concordar con la mascara ${mascara}`}
                  inputProps={{
                    onPressEnter: () => fetchCuentaContable(),
                    onChange: onChangeClaveContable,
                    maxLength: mascara?.length,
                  }}
                  breakPoints={{
                    xs: 24,
                    sm: 24,
                    md: 16,
                  }}
                  resultProps={{
                    name: 'cuenta_contable',
                    label: 'Cuenta Contable',
                    rules: rules.required,
                    dataSource: cuentaContableFound,
                    keyLabelRender: true,
                    keyProp: 'cuenta_contable',
                    labelProp: 'nombre',
                    onClear: resetAndValidateCuentaContable,
                    breakPoints: {
                      md: 16,
                    },
                  }}
                />
                <Form.Item hidden>
                  <Button htmlType="submit" />
                </Form.Item>
              </Row>
            </Form>
          </Card>
        )}
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => setVisibleAlert(false)}
          visible={visibleAlert}
          content={`la retencion ${form.getFieldValue('descripcion')}`}
          loading={loading}
        />
      </Spin>
    </Row>
  );
}

export default ClasificacionAdminsitrativa;
