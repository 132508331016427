import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Spin,
  Card,
  message,
  Typography,
} from 'antd';

import API from 'utils/api';
import { onError, onSuccess } from 'utils/handlers';
import Table from 'components/Table';
import FormSubmitControls from 'components/FormSubmitControls';
import ModalDelete from 'components/ModalDelete';
import Select from 'components/Select';
import createClave from 'utils/keyGenerator';
import { isMutable, isAuthorized } from 'utils/estadosGlobales';
import _data from 'mocks/cuentasPorPagar';
import currencyFormatter from 'utils/currencyFormatter';

function CuentasPorPagar() {
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);

  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRowKeysCompras, setSelectedRowKeysCompras] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);
  const [mutable, setMutable] = useState(true);
  const [selected, setSelected] = useState();
  const inputDescripcion = useRef();

  const fetchData = async () => {
    try {
      setLoading(true);
      // const res = await API.get(baseURI);
      setData(_data);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchData();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    setSelectedRowKeys([]);
    setSelected();
    setMutable(true);
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      // await form.validateFields();
      // const values = form.getFieldsValue();
      // if (!selectedRowKeys.length) {
      //   const response = await API.post('', values);
      //   if (response?.status === 201) {
      //     onSuccess(response, 'Agregado correctamente');
      //     onCancel();
      //     await fetchData();
      //   }
      // } else {
      //   const [key] = selectedRowKeys;
      //   const response = await API.put(`${key}/`, values);
      //   if (response?.status === 200) {
      //     onSuccess(response, 'Actualizado correctamente');
      //     onCancel();
      //     await fetchData();
      //   }
      // }
      onCancel();
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          setVisibleAlert(false);
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    setSelected(record);
    setSelectedRowKeys([record.id]);
    setMutable(isMutable(record));
    form.setFieldsValue(record);
  };

  const handleOnRowClickCompras = (record) => {
    setSelectedRowKeysCompras([record.id]);
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const rowSelectionCompras = {
    selectedRowKeys: selectedRowKeysCompras,
    type: 'radio',
  };

  const onClickAdd = () => {
    onCancel();
    createClave(data, form);
    setVisible(true);
  };

  const onClickEdit = () => setVisible(true);

  const onClickDelete = () => {
    const [key] = selectedRowKeys;
    const match = data.find((e) => e.id === key);
    if (isMutable(match)) {
      setVisibleAlert(true);
    } else if (isAuthorized(match)) {
      message.warn('No se puede eliminar un registro autorizado');
    } else {
      message.warn('No se puede eliminar un registro cancelado');
    }
  };

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
  };

  const columnsCompras = [
    {
      titleText: 'Partida Presupuestal',
      dataIndex: 'partida_presupuestal',
      key: 'partida_presupuestal',
      width: 400,
    },
    {
      titleText: 'COG',
      dataIndex: 'cog',
      key: 'cog',
      width: 100,
    },
    {
      titleText: 'Descripción',
      dataIndex: 'descripcion',
      key: 'descripcion',
      width: 200,
    },
    {
      titleText: 'Subtotal',
      dataIndex: 'subtotal',
      key: 'subtotal',
      width: 150,
      align: 'right',
      render: (a) => currencyFormatter(a || 0),
    },
    {
      titleText: 'IVA',
      dataIndex: 'iva',
      key: 'iva',
      width: 150,
      align: 'right',
      render: (a) => currencyFormatter(a || 0),
    },
    {
      titleText: 'Total',
      dataIndex: 'total',
      key: 'total',
      width: 150,
      align: 'right',
      render: (a) => currencyFormatter(a || 0),
    },
  ];

  const columns = [
    {
      titleText: 'Fecha',
      dataIndex: 'fecha',
      key: 'fecha',
      width: 100,
    },
    {
      titleText: 'Folio C x P',
      dataIndex: 'folio_cxp',
      key: 'folio_cxp',
      width: 200,
    },
    {
      titleText: 'Folio Agrupador',
      dataIndex: 'folio_agrupador',
      key: 'folio_agrupador',
      width: 200,
    },
    {
      titleText: 'Documento de Pago',
      dataIndex: 'documento_de_pago',
      key: 'documento_de_pago',
      width: 200,
    },
    {
      titleText: 'Beneficiario',
      dataIndex: 'beneficiario',
      key: 'beneficiario',
      width: 200,
    },
    {
      titleText: 'Monto',
      dataIndex: 'monto',
      key: 'monto',
      width: 150,
      align: 'right',
      render: (a) => currencyFormatter(a || 0),
    },
    {
      titleText: 'Estado',
      key: 'estados_globales',
      dataIndex: 'estados_globales',
      width: 150,
      render: (val) => estadosGlobales.find((e) => e.id === val)?.descripcion,
    },
    {
      titleText: 'Folio Presupuestal',
      dataIndex: 'folio_presupuestal',
      key: 'folio_presupuestal',
      width: 200,
    },
    {
      titleText: 'Folio Pago',
      dataIndex: 'folio_pago',
      key: 'folio_pago',
      width: 200,
    },
  ];

  const controls = () => ({
    onClickAdd,
    onClickEdit,
    onClickDelete,
  });

  const getRules = () => {
    if (!mutable) {
      return null;
    }
    return rules.required;
  };

  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible ? (
          <Table
            cols={columns}
            data={data}
            rowSelection={rowSelection}
            handleOnRowClick={handleOnRowClick}
            controls={controls()}
            mobileColIndex={0}
          />
        ) : (
          <Card
            className="form-container"
            title={`${selectedRowKeys.length ? 'Editar' : 'Agregar'}`}
            extra={(
              <FormSubmitControls
                onFinish={mutable ? onFinish : null}
                onCancel={onCancel}
                loading={loading}
                baseURI="a"
                selectedRowKeys={selectedRowKeys}
                allowAuthorize
                allowCancel
                callback={(estados_globales) => {
                  const [key] = selectedRowKeys;
                  const normalized = data.map((e) => {
                    if (e.id === key) {
                      setMutable(false);
                      return {
                        ...e,
                        estados_globales,
                      };
                    }
                    return e;
                  });
                  setData(normalized);
                  const match = normalized.find((e) => e.id === key);
                  onClickEdit(match);
                }}
              />
            )}
            bordered={false}
          >
            <Form
              layout="vertical"
              form={form}
              onFinish={onFinish}
              scrollToFirstError
              // className={!mutable ? 'without-feedback' : ''}
              initialValues={{
                estados_globales: 1,
              }}
            >
              <Row gutter={10}>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="fecha"
                    rules={getRules()}
                    label="Fecha"
                    hasFeedback
                  >
                    <Input
                      allowClear
                      ref={inputDescripcion}
                      disabled={!mutable}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="documento_de_pago"
                    rules={getRules()}
                    label="Folio de Documento"
                    hasFeedback
                  >
                    <Input
                      disabled={!mutable}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="folio_agrupador"
                    rules={getRules()}
                    label="Folio Agrupador"
                    hasFeedback
                  >
                    <Input
                      disabled={!mutable}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="unidad_responsable"
                    rules={getRules()}
                    label="Unidad Responsable"
                    hasFeedback
                  >
                    <Input
                      disabled={!mutable}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="estados_globales"
                    label="Estado"
                    rules={getRules()}
                  >
                    <Select
                      disabled={!selectedRowKeys.length || !mutable}
                      dataSource={estadosGlobales}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="beneficiario"
                    rules={getRules()}
                    label="Beneficiario"
                    hasFeedback
                  >
                    <Input
                      disabled={!mutable}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="forma_de_pago"
                    rules={getRules()}
                    label="Forma de Pago"
                    hasFeedback
                  >
                    <Input
                      disabled={!mutable}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="concepto"
                    rules={getRules()}
                    label="Concepto"
                    hasFeedback
                  >
                    <Input
                      disabled={!mutable}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="descripcion"
                    rules={getRules()}
                    label="Descripción"
                    hasFeedback
                  >
                    <Input.TextArea
                      autoSize={{ minRows: 4, maxRows: 4 }}
                      disabled={!mutable}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="observaciones"
                    rules={getRules()}
                    label="Observaciones"
                    hasFeedback
                  >
                    <Input.TextArea
                      autoSize={{ minRows: 4, maxRows: 4 }}
                      disabled={!mutable}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Typography.Title level={4}>Comprobante</Typography.Title>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="fecha_comprobante"
                    rules={getRules()}
                    label="Fecha del Comprobante"
                    hasFeedback
                  >
                    <Input
                      disabled={!mutable}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="tipo_de_documento"
                    rules={getRules()}
                    label="Tipo de Documento"
                    hasFeedback
                  >
                    <Input
                      disabled={!mutable}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="numero_documento"
                    rules={getRules()}
                    label="Número de Documento"
                    hasFeedback
                  >
                    <Input
                      disabled={!mutable}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item hidden>
                <Button htmlType="submit" />
              </Form.Item>
            </Form>
            <Table
              cols={columnsCompras}
              data={selected?.data_table || []}
              rowSelection={rowSelectionCompras}
              handleOnRowClick={handleOnRowClickCompras}
              controls={controls()}
              mobileColIndex={0}
              disabled={!mutable}
            />
          </Card>
        )}
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => {
            setVisibleAlert(false);
            setSelectedRowKeys([]);
          }}
          visible={visibleAlert}
          content={`${form.getFieldValue('descripcion')}`}
          loading={loading}
        />
      </Spin>
    </Row>
  );
}

export default CuentasPorPagar;
