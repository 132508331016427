export default (data = [], form = {}, options = {}) => {
  const { filter = null, keyProp = 'clave' } = options;
  let filterFunc;
  const prop = filter?.prop;
  const parent = filter?.parent;
  if (prop && parent?.id) {
    filterFunc = (e) => e[prop] === parent.id;
  }
  const clone = typeof filterFunc === 'function' ? [...data.filter(filterFunc)] : [...data];
  const lastKey = clone.pop()?.[keyProp];
  const parsedLastKey = data.length ? parseInt(lastKey, 10) : 0;
  if (typeof parsedLastKey === 'number') {
    let nextKey;
    if (!parent) {
      // make basic key
      nextKey = parsedLastKey + 1;
    } else {
      // storing last key by removing parent key
      nextKey = lastKey?.replace(parent?.[keyProp], '') || 0;
      // concatening the parentkey at the begginig to the next key value
      nextKey = parseInt(`${parent[keyProp]}${parseInt(nextKey, 10) + 1}`, 10);
    }
    if (form) {
      form.setFieldsValue({ [keyProp]: nextKey });
    }
  }
};
