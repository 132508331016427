import React, { useState, useEffect } from 'react';
import {
  Card, Form, Row, Spin, Col, Input, DatePicker, Select, Button,
} from 'antd';

import {
  SaveOutlined,
  ArrowLeftOutlined,
  FilePdfOutlined,
  FileExcelOutlined,
} from '@ant-design/icons';
import API from 'utils/api';
import { onError } from 'utils/handlers';
import Table from 'components/Table';
import _data from 'mocks/solicitudCuentasInternas';

function ContraRecibo() {
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [data, setData] = useState([]);
  const [estado, setEstado] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [mutable, setMutable] = useState(true);
  const fetchData = async () => {
    try {
      setLoading(true);
      setData(_data);
      setEstado([]);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchData();
    return () => API.tryCancel;
  }, []);

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    setTimeout(() => form.setFieldsValue(record));
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'check',
  };

  const onClickEdit = () => {
    setVisible(true);
  };

  const onClickDelete = () => {};

  const onDescargarPDF = () => {
    setVisible(true);
  };

  const onDescargarExcel = () => {};

  const columns = [
    {
      titleText: 'Módulo',
      dataIndex: 'modulo',
      key: 'modulo',
      width: 200,
    },
    {
      titleText: 'Documento Operativo',
      dataIndex: 'documento_operativo',
      key: 'documento_operativo',
      width: 220,
    },
    {
      titleText: 'Folio Agrupador',
      dataIndex: 'folio_agrupador',
      key: 'folio_agrupador',
      width: 200,
    },
    {
      titleText: 'Beneficiario',
      dataIndex: 'beneficiario',
      key: 'beneficiario',
      width: 250,
    },
    {
      titleText: 'No. de Factura',
      dataIndex: 'numero_factura',
      key: 'numero_factura',
      width: 300,
    },
    {
      titleText: 'Importe de Factura',
      dataIndex: 'importe_factura',
      key: 'importe_factura',
      width: 200,
    },
    {
      titleText: 'Penalizaciones',
      dataIndex: 'penalizaciones',
      key: 'penalizaciones',
      width: 200,
    },
    {
      titleText: 'Importe Neto',
      dataIndex: 'importe_neto',
      key: 'importe_neto',
      width: 200,
    },
    {
      titleText: 'Fecha de Contra-Recibo',
      dataIndex: 'fecha_contra_recibo',
      key: 'fecha_contra_recibo',
      width: 200,
    },
    {
      titleText: 'No. de Contra-Recibo',
      dataIndex: 'numero_contra_recibo',
      key: 'numero_contra_recibo',
      width: 250,
    },
    {
      titleText: 'Emitió',
      dataIndex: 'emitio',
      key: 'emitio',
      width: 200,
    },
    {
      titleText: 'Puesto',
      dataIndex: 'puesto',
      key: 'puesto',
      width: 250,
    },
    {
      titleText: 'Área',
      dataIndex: 'area',
      key: 'area',
      width: 220,
    },
    {
      titleText: 'Fecha de Compromiso de Pago',
      dataIndex: 'fecha_compromiso_pago',
      key: 'fecha_compromiso_pago',
      width: 250,
    },
    {
      titleText: 'Importe de Compromiso de Pago',
      dataIndex: 'importe_compromiso_pago',
      key: 'importe_compromiso_pago',
      width: 250,
    },
    {
      titleText: 'Importe Pagado',
      dataIndex: 'importe_pagado',
      key: 'importe_pagado',
      width: 200,
    },
    {
      titleText: 'Saldo de Contra-Recibo',
      dataIndex: 'saldo_contra_recibo',
      key: 'saldo_contra_recibo',
      width: 200,
    },
    {
      titleText: 'Fecha de Pagado',
      dataIndex: 'fecha_pagado',
      key: 'fecha_pagado',
      width: 200,
    },
    {
      titleText: 'Póliza de Pago',
      dataIndex: 'poliza_pago',
      key: 'poliza_pago',
      width: 200,
    },
    {
      titleText: 'Observaciones',
      dataIndex: 'observaciones',
      key: 'observaciones',
      width: 220,
    },
    {
      titleText: 'Estado',
      dataIndex: 'estado',
      key: 'estado',
      width: 200,
    },
  ];

  const onFinish = () => ({});
  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    setSelectedRowKeys([]);
    setMutable(true);
  };

  function ResultContraReciboGrid() {
    return (
      <Table
        cols={columns}
        data={[]}
        rowSelection={rowSelection}
        handleOnRowClick={handleOnRowClick}
        mobileColIndex={0}
        controls={{
          onClickEdit,
          onClickDelete,
        }}
        customActions={[
          {
            onClick: onDescargarPDF,
            icon: <FilePdfOutlined />,
            text: 'Descargar PDF',
            disabledBySelection: false,
          },
          {
            onClick: onDescargarExcel,
            icon: <FileExcelOutlined />,
            text: 'Descargar Excel',
            disabledBySelection: false,
          },
        ]}
      />
    );
  }

  function ResultContraReciboForm() {
    return (
      <>
        <Card
          title={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Contra-Recibo`}
          extra={(
            <>
              <Button
                icon={<SaveOutlined />}
                className="ant-btn ant-btn-link"
                onClick={onFinish}
              >
                Guardar
              </Button>
              <Button
                icon={<ArrowLeftOutlined />}
                className="ant-btn ant-btn-link"
                onClick={onCancel}
              >
                Regresar
              </Button>
            </>
          )}
          bordered={false}
        />
        <Row gutter={10}>
          <Col md={24} sm={24} xs={24}>
            <Form
              layout="vertical"
              form={form}
              onFinish={onFinish}
              scrollToFirstError
              name="contra_recibo"
            >
              <Row gutter={10}>
                <Col xs={12} sm={24} md={8}>
                  <Form.Item name="modulo" label="Módulo">
                    <Select datasource={estado} disabled />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={24} md={8}>
                  <Form.Item
                    name="numero_orden_de_compra"
                    label="No. de Orden de Compra"
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={24} md={8}>
                  <Form.Item name="folio_agrupador" label="Folio Agrupador">
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={24} md={8}>
                  <Form.Item
                    name="beneficiario"
                    label="Beneficiario"
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={24} md={8}>
                  <Form.Item name="numero_factura" label="No. de Factura">
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={24} md={8}>
                  <Form.Item
                    name="importe_factura"
                    label="Importe de Factura"
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={24} md={8}>
                  <Form.Item
                    name="penalizaciones"
                    label="Penalizaciones"
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={24} md={8}>
                  <Form.Item
                    name="importe_neto"
                    label="Importe Neto"
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={24} md={8}>
                  <Form.Item
                    name="fecha_contra_recibo"
                    label="Fecha de Contra-Recibo"
                  >
                    <DatePicker placeholder="" format="DD/MM/YYYY" disabled />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={24} md={8}>
                  <Form.Item
                    name="numero_contra_recibo"
                    label="No. de Contra-Recibo"
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={24} md={8}>
                  <Form.Item
                    name="emitio"
                    label="Emitió"
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={24} md={8}>
                  <Form.Item
                    name="puesto"
                    label="Puesto"
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={24} md={8}>
                  <Form.Item
                    name="area"
                    label="Área"
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={24} md={8}>
                  <Form.Item
                    name="fecha_compromiso_de_pago"
                    label="Fecha de Compromiso de Pago"
                  >
                    <DatePicker placeholder="" format="DD/MM/YYYY" />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={24} md={8}>
                  <Form.Item
                    name="importe_compromiso_de_pago"
                    label="Importe de Compromiso de Pago"
                  >
                    <Input allowClear />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={24} md={8}>
                  <Form.Item
                    name="importe_pagado"
                    label="Importe Pagado"
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={24} md={8}>
                  <Form.Item
                    name="saldo_contra_recibo"
                    label="Saldo de Contra-Recibo"
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={24} md={8}>
                  <Form.Item
                    name="fecha_pagado"
                    label="Fecha de Pagado"
                  >
                    <DatePicker placeholder="" format="DD/MM/YYYY" disabled />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={24} md={8}>
                  <Form.Item
                    name="poliza_de_pago"
                    label="Póliza de Pago"
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={24} md={8}>
                  <Form.Item
                    name="observaciones"
                    label="Observaciones"
                  >
                    <Input allowClear />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={24} md={8}>
                  <Form.Item
                    name="estado"
                    label="Estado"
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </>
    );
  }

  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {
          !visible ? <ResultContraReciboGrid /> : <ResultContraReciboForm />
        }
      </Spin>
    </Row>
  );
}

export default ContraRecibo;
