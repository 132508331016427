import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Button,
  Col,
  // DatePicker,
  Form,
  Input,
  Row,
  Spin,
  Tag,
  // message,
} from 'antd';
import API from 'utils/api';
import getFormattedValues, { formatReceived } from 'utils/formatValues';
import { onError, onSuccess } from 'utils/handlers';
import Table from 'components/Table';
import FormSubmitControls from 'components/FormSubmitControls';
import ModalDelete from 'components/ModalDelete';
import Select from 'components/Select';
import PlainTransfer from 'components/PlainTransfer';

const baseURI = 'catalogos/registro-de-nivel-del-plan-de-desarrollo/';

function PlanesDeDesarrollo({
  nivel: nivel_de_plan_de_desarrollo,
  disabled,
  // nivelesPadre,
  nivelesDeDesarrollo,
  planDeDesarrollo,
}) {
  const { primaryText } = useSelector(({ app }) => app.theme);
  const { estadosGlobales } = useSelector(({ catalogs }) => catalogs);
  // const urs = useSelector(({ catalogs }) => catalogs.urs).sort(levelSorter);
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);
  const [currentLevel, setCurrentLevel] = useState({});
  const [ur, setUr] = useState([]);
  const [uo, setUo] = useState([]);
  const [cc, setCC] = useState([]);
  const [ods, setOds] = useState([]);
  // const [nivelesPadre, setNivelesPadre] = useState([]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await API.get(baseURI);
      setTimeout(() => {
        setData(formatReceived(res.data));
      });
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const fetchNivelesPadre = async (nivel) => {
    try {
      setLoading(true);
      const val = nivel - 1;
      if (val !== 0) {
        // const resNiveles = await API.get('catalogos/registro-de-nivel-del-plan-de-desarrollo/');
        // const resNiveles = await API.get('catalogos/registro-de-nivel-del-plan-de-desarrollo/', {
        //   params: {
        //     nivel_de_plan_de_desarrollo: val,
        //   },
        // });
        // setNivelesPadre([{
        //   ...resNiveles.data,
        //   nombre_clave: `${resNiveles.data?.numero} - ${resNiveles.data?.nombre || ''}`,
        // }]);
        // setNivelesPadre(resNiveles.data);
      }
      setLoading(false);
    } catch (error) {
      onError(error, setLoading);
    }
  };
  useEffect(() => {
    const fetchAll = async () => {
      try {
        setLoading(true);

        const resUR = await API.get('/estructura-organizacional/unidades-responsables/');
        setUr(resUR.data || []);

        const resUO = await API.get('/estructura-organizacional/unidades-de-trabajo/');
        setUo(resUO.data || []);

        const resCentroCostos = await API.get('/estructura-organizacional/centros-de-costos/');
        setCC(resCentroCostos.data || []);

        const resOds = await API.get('/catalogos/objetivo-de-desarrollo-sustentable/');
        setOds(resOds.data.map((e) => ({
          ...e,
          nombreConDescripcion: `${e?.nombre || ' '} - ${e?.descripcion || ' '}`,
        })));
        // setOds(resOds.data || []);

        await fetchData();
        setLoading(false);
      } catch (err) {
        onError(err, setLoading);
      }
    };
    fetchAll();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, [nivel_de_plan_de_desarrollo]);

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    // setUrsFound([]);
    // setNivelesPadre([]);
    setSelectedRowKeys([]);
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = getFormattedValues(form.getFieldsValue());

      if (!selectedRowKeys.length) {
        const response = await API.post(baseURI, values);
        if (response?.status === 201) {
          onSuccess(response, 'Agregado correctamente');
          onCancel();
          await fetchData();
        }
      } else {
        const [key] = selectedRowKeys;
        const response = await API.put(`${baseURI}${key}/`, values);
        if (response?.status === 200) {
          onSuccess(response, 'Actualizado correctamente');
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`${baseURI}${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          setVisibleAlert(false);
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
  };

  const columns = [
    {
      titleText: 'Nivel del Plan',
      dataIndex: 'nivel_de_plan_de_desarrollo',
      key: 'nivel_de_plan_de_desarrollo',
      width: 200,
      render: (val) => nivelesDeDesarrollo.find((t) => t.id === val)?.nombre_del_nivel,
    },
    {
      titleText: 'Número',
      dataIndex: 'numero',
      key: 'numero',
      width: 150,
    },
    {
      titleText: 'Nombre',
      dataIndex: 'nombre',
      key: 'nombre',
      width: 250,
    },
    {
      titleText: 'Objetivo',
      dataIndex: 'objetivo_del_nivel',
      key: 'objetivo_del_nivel',
      width: 250,
    },
    {
      titleText: 'Alineacion PND',
      dataIndex: 'alineacion_plan_nacional_de_desarrollo',
      key: 'alineacion_plan_nacional_de_desarrollo',
      width: 250,
    },
    {
      titleText: 'Alineacion PED',
      dataIndex: 'alineacion_plan_estatal_de_desarrollo',
      key: 'alineacion_plan_estatal_de_desarrollo',
      width: 250,
    },
    {
      titleText: 'Alineacion PMD',
      dataIndex: 'alineacion_plan_municipal_de_desarrollo',
      key: 'alineacion_plan_municipal_de_desarrollo',
      width: 250,
    },
    {
      titleText: 'Objetivo de Desarrollo Sostenible',
      dataIndex: 'objetivo_de_desarrollo_sostenible',
      key: 'objetivo_de_desarrollo_sostenible',
      width: 250,
      render: (val = []) => {
        if (val?.length && ods?.length) {
          const matches = val.map((e) => ods?.find((i) => i?.id === e));
          if (matches.length) {
            const output = matches.map((e) => (
              <Tag key={e?.id}>{e.nombre}</Tag>
            ));
            return output;
          }
        }
        return (<span />);
      },
    },
    {
      titleText: 'Unidades Operativas',
      dataIndex: 'unidades_operativas',
      key: 'unidades_operativas',
      width: 250,
      render: (val = []) => {
        if (val?.length && uo?.length) {
          const matches = val.map((e) => uo?.find((i) => i?.id === e));
          if (matches.length) {
            const output = matches.map((e) => (
              <Tag key={e?.id}>{e.descripcion}</Tag>
            ));
            return output;
          }
        }
        return (<span />);
      },
    },
    {
      titleText: 'Unidades Responsables',
      dataIndex: 'unidades_responsables',
      key: 'unidades_responsables',
      width: 250,
      render: (val = []) => {
        if (val?.length && ur?.length) {
          const matches = val.map((e) => ur?.find((i) => i?.id === e));
          if (matches.length) {
            const output = matches.map((e) => (
              <Tag key={e?.id}>{e.descripcion}</Tag>
            ));
            return output;
          }
        }
        return (<span />);
      },
    },
    {
      titleText: 'Centros de Costos',
      dataIndex: 'centro_de_costos',
      key: 'centro_de_costos',
      width: 250,
      render: (val = []) => {
        if (val?.length && cc?.length) {
          const matches = val.map((e) => cc?.find((i) => i?.id === e));
          if (matches.length) {
            const output = matches.map((e) => (
              <Tag key={e?.id}>{e.descripcion}</Tag>
            ));
            return output;
          }
        }
        return (<span />);
      },
    },
    {
      titleText: 'Estado',
      dataIndex: 'estados_globales',
      key: 'estados_globales',
      width: 300,
      render: (val) => estadosGlobales.find((t) => t.id === val)?.descripcion,
    },
  ];

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    form.setFieldsValue(record);
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const onClickEdit = async () => {
    try {
      setLoading(true);
      const [key] = selectedRowKeys;
      const match = data.find((item) => item.id === key);
      await fetchNivelesPadre(match.nivel_de_plan_de_desarrollo);
      setTimeout(() => {
        form.setFieldsValue(match);
      });
      const matchNivel = nivelesDeDesarrollo.find(
        (e) => e.id === match.nivel_de_plan_de_desarrollo,
      );
      setCurrentLevel(matchNivel);

      setLoading(false);
      setVisible(true);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible ? (
          <Table
            // allowImport
            baseURI={baseURI}
            cols={columns}
            data={data}
            rowSelection={rowSelection}
            handleOnRowClick={handleOnRowClick}
            controls={{
              onClickAdd,
              onClickEdit,
              onClickDelete,
            }}
            allowImport
            disabled={disabled}
          />
        ) : (
          <Row>
            <Col
              span={24}
              style={{
                color: primaryText,
                display: 'flex',
                justifyContent: 'space-between',
                borderBottom: 'solid #000 2px',
              }}
            >
              <span style={{ fontSize: 16, paddingInlineStart: 15 }}>
                {selectedRowKeys.length ? 'Editar' : 'Agregar'}
                {' '}
                Plan
              </span>
              <FormSubmitControls
                onFinish={onFinish}
                onCancel={onCancel}
                loading={loading}
              />
            </Col>
            <Col span={24}>
              <Form
                layout="vertical"
                form={form}
                onFinish={onFinish}
                scrollToFirstError
                initialValues={{ estados_globales: 1, plan_de_desarrollo: planDeDesarrollo }}
              >
                <Row gutter={10}>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="nivel_de_plan_de_desarrollo"
                      rules={rules.required}
                      label="Nivel de plan de desarrollo"
                      hasFeedback
                    >
                      <Select
                        dataSource={nivelesDeDesarrollo}
                        labelProp="nombre_del_nivel"
                        onChange={async (val) => {
                          const match = nivelesDeDesarrollo.find((e) => e.id === val);
                          setCurrentLevel(match);
                          await fetchNivelesPadre(val);
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="registro_plan_de_desarrollo_padre"
                      label="Nivel Superior Padre"
                      hasFeedback
                    >
                      <Select
                        dataSource={data}
                        labelProp="nombre"
                        keyProp="id"
                        keyLabelRender
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="numero"
                      rules={rules.required}
                      label="Número"
                    >
                      <Input allowClear />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="nombre"
                      rules={rules.required}
                      label="Nombre"
                      hasFeedback
                    >
                      <Input allowClear />
                    </Form.Item>
                  </Col>
                  {currentLevel?.objetivo && (
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="objetivo_del_nivel"
                      rules={rules.required}
                      label="Objetivo"
                      hasFeedback
                    >
                      <Input.TextArea
                        allowClear
                        autoSize={{ minRows: 2, maxRows: 2 }}
                      />
                    </Form.Item>
                  </Col>
                  )}
                  {currentLevel?.plan_estatal_de_desarrollo && (
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="alineacion_plan_estatal_de_desarrollo"
                      rules={rules.required}
                      label="Alineacion Plan Estatal de Desarrollo"
                      hasFeedback
                    >
                      <Input.TextArea
                        allowClear
                        autoSize={{ minRows: 2, maxRows: 2 }}
                      />
                    </Form.Item>
                  </Col>
                  )}
                  {currentLevel?.plan_nacional_de_desarrollo && (
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="alineacion_plan_nacional_de_desarrollo"
                      rules={rules.required}
                      label="Alineacion Plan Nacional de Desarrollo"
                      hasFeedback
                    >
                      <Input.TextArea
                        allowClear
                        autoSize={{ minRows: 2, maxRows: 2 }}
                      />
                    </Form.Item>
                  </Col>
                  )}
                  {currentLevel?.plan_municipal_de_desarrollo && (
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="alineacion_plan_municipal_de_desarrollo"
                      rules={rules.required}
                      label="Alineacion Plan Municipal de Desarrollo"
                      hasFeedback
                    >
                      <Input.TextArea
                        allowClear
                        autoSize={{ minRows: 2, maxRows: 2 }}
                      />
                    </Form.Item>
                  </Col>
                  )}
                  {currentLevel?.dependencia_responsable && (
                  <Col span={24}>
                    <PlainTransfer
                      dataSource={uo}
                      label="Unidades Operativas"
                      form={form}
                      formItemName="unidades_operativas"
                      filterProp="descripcion"
                    />
                  </Col>
                  )}
                  {currentLevel?.dependencia_responsable && (
                    <Col span={24}>
                      <PlainTransfer
                        dataSource={ur}
                        label="Unidades Responsables"
                        form={form}
                        formItemName="unidades_responsables"
                        filterProp="descripcion"
                      />
                    </Col>
                  )}
                  {currentLevel?.dependencia_responsable && (
                    <Col span={24}>
                      <PlainTransfer
                        dataSource={cc}
                        label="Centros de Costos"
                        form={form}
                        formItemName="centro_de_costos"
                        filterProp="descripcion"
                      />
                    </Col>
                  )}
                  {currentLevel?.objetivos_de_desarrollo_sostenible && (
                    <Col span={24}>
                      <PlainTransfer
                        dataSource={ods}
                        label="Objetivo de Desarrollo Sostenible"
                        form={form}
                        formItemName="objetivo_de_desarrollo_sostenible"
                        filterProp="nombreConDescripcion"
                      />
                    </Col>
                  )}
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      label="Estado"
                      rules={rules.required}
                      name="estados_globales"
                    >
                      <Select
                        dataSource={estadosGlobales}
                        disabled={!selectedRowKeys.length}
                      />
                    </Form.Item>
                  </Col>
                  <Form.Item
                    name="plan_de_desarrollo"
                    hidden
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item hidden>
                    <Button htmlType="submit" />
                  </Form.Item>
                </Row>
              </Form>
            </Col>
          </Row>
        )}
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => setVisibleAlert(false)}
          visible={visibleAlert}
          content={`el plan ${form.getFieldValue('nombre')}`}
        />
      </Spin>
    </Row>
  );
}

PlanesDeDesarrollo.propTypes = {
  nivel: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
  // nivelesPadre: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  nivelesDeDesarrollo: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  planDeDesarrollo: PropTypes.number.isRequired,
};

export default PlanesDeDesarrollo;
