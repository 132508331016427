import React from 'react';
import SingleForm from 'components/SingleForm';
import {
  getResguardos,
  postResguardos,
  patchResguardos,
  deleteResguardos,
} from 'api/control-patrimonial/resguardos';

export const permissionResguardo = {
  permissionModel: 'resguardo',
};

// eslint-disable-next-line react/prop-types
function Resguardos({ permission }) {
  return (
    <SingleForm
      title="Alta de Resguardos"
      get={getResguardos}
      post={postResguardos}
      patch={patchResguardos}
      delete={deleteResguardos}
      permission={permission}
    />
  );
}
export default Resguardos;
