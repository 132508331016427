import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Typography,
  Row,
  Col,
  Button,
  Form,
  Input,
  Spin,
  Grid,
} from 'antd';

import {
  ArrowLeftOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';
import { onError } from 'utils/handlers';

const { Text } = Typography;

function ModalChangeStatus({
  onSubmit,
  onCancel,
  visible,
  authorize,
  onCancelText,
  requireObservation,
  observationPropName,
}) {
  const screens = Grid.useBreakpoint();
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      if (authorize) {
        await onSubmit();
      } else {
        await onSubmit(values);
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  function ModalControls() {
    return (
      <Row className="form-submit-controls">
        <Button
          type="link"
          onClick={onCancel}
          disabled={loading}
        >
          <ArrowLeftOutlined />
          {screens.md && onCancelText}
        </Button>
        <Button
          type="link"
          onClick={() => onFinish()}
          style={{ marginLeft: 10 }}
          className={`no-color ${authorize ? 'success' : 'danger'}`}
          disabled={loading}
          autoFocus
        >
          {authorize ? <CheckCircleOutlined /> : <CloseCircleOutlined />}
          {(screens.md && authorize) && 'Autorizar'}
          {(screens.md && !authorize) && 'Cancelar'}
        </Button>
      </Row>
    );
  }

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
  };

  return (
    <Modal
      title={(
        <Row justify="space-between">
          <Text>
            {authorize ? 'Autorización' : 'Cancelación'}
          </Text>
          <ModalControls />
        </Row>
        )}
      className="modal-delete"
      visible={visible}
      footer={null}
      closable={false}
      maskClosable={!loading}
      keyboard={!loading}
    >
      <Spin tip="Cargando..." spinning={loading}>
        {authorize && (
          <Text style={{ fontSize: 16 }}>
            ¿Desea Autorizar el registro?
          </Text>
        )}
        {(!authorize && !requireObservation) && (
          <Text style={{ fontSize: 16 }}>
            ¿Desea Cancelar el registro?
          </Text>
        )}
        {requireObservation && (
          <Form
            layout="vertical"
            form={form}
            onFinish={() => onFinish()}
          >
            <Col span={24}>
              <Form.Item
                name={observationPropName}
                rules={rules.required}
                hasFeedback
                label="Observaciones"
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            <Form.Item hidden>
              <Button htmlType="submit" />
            </Form.Item>
          </Form>
        )}
      </Spin>
    </Modal>
  );
}

ModalChangeStatus.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  visible: PropTypes.bool,
  authorize: PropTypes.bool,
  onCancelText: PropTypes.string.isRequired,
  requireObservation: PropTypes.bool,
  observationPropName: PropTypes.string,
};

ModalChangeStatus.defaultProps = {
  visible: false,
  authorize: false,
  observationPropName: 'required',
  requireObservation: false,
};

export default ModalChangeStatus;
