import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import { useSelector } from 'react-redux';
import {
  Form,
  Input,
  Button,
  Spin,
  Row,
  Col,
  Card,
  DatePicker,
  Typography,
} from 'antd';
import {
  FilePdfOutlined,
  FileOutlined,
  EditOutlined,
  DeleteOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import API from 'utils/api';
import fetchSome from 'utils/fetchSome';
import getFormattedValues, { formatReceived } from 'utils/formatValues';
import { onError } from 'utils/handlers';
import { SET_PERIODOS } from 'store/reducers/catalogs';
import FormSubmitControls from 'components/FormSubmitControls';
import ModalDelete from 'components/ModalDelete';
import Previewer from 'components/Previewer';
import Dragger from 'components/Dragger';
import Select from 'components/Select';

const { Text } = Typography;
const baseURI = 'configuraciones/documentos-del-tramite/';

function Contactos({
  currentURI,
  selectedRowKey,
  allowedExtensions,
}) {
  const periodos = useSelector(({ catalogs }) => catalogs.periodos);
  const collapsedSidebar = useSelector(({ app }) => app.collapsedSidebar);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [visibleForm, setVisibleForm] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [documentos, setDocumentos] = useState([]);
  const [tiposDocumentos, setTiposDocumentos] = useState([]);
  const [currentDoc, setCurrentDoc] = useState();
  const [visiblePreview, setVisiblePreview] = useState(false);

  const fetchData = async () => {
    try {
      setLoading(true);
      if (selectedRowKey) {
        const resCatalogo = await API.get(`${currentURI}/${selectedRowKey}/`);
        if (resCatalogo?.status === 200) {
          const promises = [];
          const list = resCatalogo.data.documento_de_tramite || [];
          list.forEach((item) => {
            const id = typeof item === 'number' ? item : item.id;
            const res = API.get(`${baseURI}${id}/`);
            promises.push(res);
          });
          const responses = promises.length ? await Promise.all(promises) : [];
          const datos = responses.map((res) => formatReceived({
            ...res.data,
            fecha_de_creacion: res.data.fecha_de_creacion?.split(' ')[0],
          }));
          setDocumentos(datos);
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    const fetch = async () => {
      try {
        setLoading(true);
        const res = await API.get('configuraciones/tipos-de-documento-para-tramites');
        setTiposDocumentos(res.data);
        if (!periodos?.length) {
          await fetchSome('/catalogos/periodos/', SET_PERIODOS);
        }
        await fetchData();
        setLoading(false);
      } catch (err) {
        onError(err, setLoading);
      }
    };
    fetch();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, [selectedRowKey]);

  const onCancel = () => {
    form.resetFields();
    setCurrentDoc();
    setVisibleForm(false);
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      values.documento = values.documento?.file;
      const file = values.documento;
      if (file) {
        values.tamanio = (file.size / 1024 / 1024).toFixed(2);
        values.extension_del_documento = file?.name.split('.').pop().toLowerCase();
      } else {
        delete values.documento;
      }
      const formattedValues = getFormattedValues(values, {
        formData: true,
        clean: false,
      });
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      if (selectedRowKey) {
        if (!currentDoc) {
          const res = await API.post(baseURI, formattedValues, config);
          if (res?.status === 201) {
            const keys = documentos.map((item) => item.id);
            const resPatch = await API.patch(`${currentURI}/${selectedRowKey}/`, {
              documento_de_tramite: [...keys, res.data.id],
            });
            if (resPatch?.status === 200) {
              onCancel();
              await fetchData();
            }
          }
        } else {
          const { key } = currentDoc;
          const res = await API.patch(`${baseURI}${key}/`, formattedValues, config);
          if (res?.status === 200) {
            onCancel();
            await fetchData();
          }
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      const { key } = currentDoc;
      if (selectedRowKey) {
        const response = await API.delete(`${baseURI}${key}/`);
        if (response?.status === 204) {
          setVisibleAlert(false);
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
  };

  const isImageExt = (ext) => ext === 'jpeg' || ext === 'jpg' || ext === 'png';

  const calcDate = (tipoDocumento) => {
    const { tiempo_de_vencimiento, periodo_de_vencimiento } = tipoDocumento;
    const periodo = periodos?.find((e) => e.id === periodo_de_vencimiento);
    let output = new Moment();
    switch (periodo?.nombre.toLowerCase()) {
      case 'anual':
        output = output.add(tiempo_de_vencimiento, 'y');
        break;
      case 'semestral':
        output = output.add(tiempo_de_vencimiento * 6, 'M');
        break;
      case 'cuatrimestral':
        output = output.add(tiempo_de_vencimiento * 4, 'M');
        break;
      case 'trimestral':
        output = output.add(tiempo_de_vencimiento * 3, 'M');
        break;
      case 'bimestral':
        output = output.add(tiempo_de_vencimiento * 2, 'M');
        break;
      case 'mensual':
        output = output.add(tiempo_de_vencimiento * 1, 'M');
        break;
      case 'quincenal':
        output = output.add(tiempo_de_vencimiento * 15, 'd');
        break;
      case 'semanal':
        output = output.add(tiempo_de_vencimiento, 'w');
        break;
      case 'diario':
        output = output.add(tiempo_de_vencimiento, 'd');
        break;
      default:
        output = null;
        break;
    }

    return output;
  };

  return (
    <Spin tip="Cargando..." spinning={loading}>
      {!visibleForm ? (
        <Row gutter={10} className="documents">
          {documentos.map((doc) => (
            <Col
              lg={collapsedSidebar ? 6 : 8}
              md={collapsedSidebar ? 8 : 12}
              sm={12}
              xs={24}
              key={doc.id}
            >
              <div className="gutter-row customCard document">
                <Row
                  className="img-wrapper"
                  onClick={() => {
                    setCurrentDoc(doc);
                    setVisiblePreview(true);
                  }}
                >
                  <div className="image">
                    {isImageExt(doc.extension_del_documento) && (
                      <img src={doc.documento} alt="" />
                    )}
                    {doc.extension_del_documento === 'pdf' && <FilePdfOutlined />}
                    {doc.extension_del_documento === 'docx' && <FileOutlined />}
                  </div>
                </Row>
                <Row className="info">
                  <Col>
                    <Text className="doc-title">
                      {`${tiposDocumentos
                        ?.find((d) => d.id === doc.tipo_documento)
                        ?.nombre} - ${doc.fecha_de_creacion?.format('DD/MM/YYYY')}`}
                    </Text>
                    <Text className="doc-date">
                      {doc.fecha_modificacion?.locale('mx').fromNow()}
                    </Text>
                  </Col>
                  <Row className="controls">
                    <Button
                      className="edit no-color"
                      type="link"
                      onClick={() => {
                        form.setFieldsValue(doc);
                        setVisibleForm(true);
                        setCurrentDoc(doc);
                      }}
                    >
                      Actualizar
                      <EditOutlined />
                    </Button>
                    <Button
                      className="delete no-color"
                      type="link"
                      danger
                      onClick={() => {
                        setCurrentDoc(doc);
                        setVisibleAlert(true);
                      }}
                    >
                      Eliminar
                      <DeleteOutlined />
                    </Button>
                  </Row>
                </Row>
              </div>
            </Col>
          ))}
          <Col
            lg={collapsedSidebar ? 6 : 8}
            md={collapsedSidebar ? 8 : 12}
            sm={12}
            xs={24}
            className="gutter-row customCard add-document"
            onClick={() => {
              onCancel();
              setVisibleForm(true);
            }}
          >
            <UploadOutlined />
            <Text>Agregar</Text>
          </Col>
        </Row>
      ) : (
        <Card
          className="form-container no-padding"
          title={`${currentDoc ? 'Editar' : 'Agregar'} Documento`}
          extra={(
            <FormSubmitControls
              onFinish={onFinish}
              onCancel={onCancel}
              loading={loading}
            />
          )}
          bordered={false}
        >
          <Form
            form={form}
            layout="vertical"
            name="documento"
            onFinish={onFinish}
            onValuesChange={(changedValues, allValues) => {
              const tipo = changedValues.tipo_documento;
              if (tipo && !allValues.fecha_vencimiento) {
                const tipoDocumento = tiposDocumentos.find((p) => p.id === tipo);
                const fecha_vencimiento = calcDate(tipoDocumento);
                form.setFieldsValue({ fecha_vencimiento });
              }
            }}
          >
            <Row gutter={10}>
              <Col sx={24} sm={24} md={8}>
                <Form.Item
                  name="clave"
                  label="Clave"
                  rules={rules.required}
                >
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  name="fecha_vencimiento"
                  label="Fecha de vencimiento"
                  hasFeedback
                  rules={rules.required}
                >
                  <DatePicker
                    format="DD/MM/YYYY"
                    placeholder=""
                  />
                </Form.Item>
              </Col>
              <Col sx={24} sm={24} md={8}>
                <Form.Item
                  name="tipo_documento"
                  label="Tipo de Documento"
                  rules={rules.required}
                >
                  <Select dataSource={tiposDocumentos} labelProp="nombre" />
                </Form.Item>
              </Col>
              <Col sx={24} sm={24} md={16}>
                <Form.Item
                  name="observaciones"
                  label="Observaciones"
                  rules={rules.required}
                >
                  <Input allowClear />
                </Form.Item>
              </Col>
              {!!currentDoc && (
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="fecha_de_creacion"
                    label="Fecha de creación"
                    hasFeedback
                  >
                    <DatePicker
                      disabled
                      placeholder=""
                      format="DD/MM/YYYY"
                    />
                  </Form.Item>
                </Col>
              )}
              <Dragger
                label="Documento"
                limitMB={30}
                rules={rules.required}
                name="documento"
                allowedExtensions={allowedExtensions}
              />
              <Form.Item hidden>
                <Button htmlType="submit" />
              </Form.Item>
            </Row>
          </Form>
        </Card>
      )}
      <Previewer
        visible={visiblePreview}
        setVisible={setVisiblePreview}
        iFrameUrl={currentDoc?.documento}
        isImage={isImageExt(currentDoc?.extension_del_documento)}
      />
      <ModalDelete
        onDelete={deleteItem}
        onCancel={() => setVisibleAlert(false)}
        visible={visibleAlert}
        content={`Documento${tiposDocumentos
          .find((t) => t.id === currentDoc
            ?.tipo_documento)?.nombre} - ${currentDoc?.fecha_de_creacion?.format('DD/MM/YYYY')}`}
        loading={loading}
      />
    </Spin>
  );
}

Contactos.propTypes = {
  selectedRowKey: PropTypes.number,
  currentURI: PropTypes.string.isRequired,
  allowedExtensions: PropTypes.arrayOf(PropTypes.string),
};

Contactos.defaultProps = {
  selectedRowKey: null,
  allowedExtensions: ['PNG', 'JPEG', 'JPG', 'PDF', 'DOCX'],
};

export default Contactos;
