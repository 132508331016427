import React from 'react';
import SingleForm from 'components/SingleForm';
import {
  getCatalogoDeCriterioDeRequesicion,
  postCatalogoDeCriterioDeRequesicion,
  patchCatalogoDeCriterioDeRequesicion,
  deleteCatalogoDeCriterioDeRequesicion,
} from 'api/adquisiciones/catalogoDeCriterioDeRequisicion';

export const criteriosDeRequisicionPermission = { permissionModel: 'criterioderequisicion' };

// eslint-disable-next-line react/prop-types
function CriteriosDeRequisicion({ permission }) {
  return (
    <SingleForm
      title="Criterio"
      get={getCatalogoDeCriterioDeRequesicion}
      post={postCatalogoDeCriterioDeRequesicion}
      patch={patchCatalogoDeCriterioDeRequesicion}
      delete={deleteCatalogoDeCriterioDeRequesicion}
      permission={permission}
    />
  );
}
export default CriteriosDeRequisicion;
