export default [{
  id: 1,
  fecha: '23/7/2021',
  folio_recepcion: 'RA-2021-0456',
  folio_orden_de_compra: 'CO-2021-06-0036',
  folio_agrupador: 'FA-2021-0057',
  proveedor: 'La Nacional, SA de CV',
  importe: 27458.36,
  folio_presupuestal: 'FP-2021-0094',
  estados_globales: 4,
  tipo_de_documento: 'Factura',
  remisiones: 'Única',
  nombre: 'Carmen Aguilar Ríos',
  puesto: 'Almacenista',
  almacen: 'Almacén materiales',
  ubicacion: 'Calle Hidalgo No. 851',
  tipo_de_operacion: 'Bienes Almacenables',
  folio_remision: 'RE-2021-0057',
  fecha_comproboante: '7/24/2021',
  numero_documento: 'A123',
  data_table: [{
    cantidad_pedida: 12,
    cantidad_pendiente: '-',
    cantidad_recibida: 12,
    cog: '21101',
    descripcion: 'Cajas archivo muerto',
    id: 1,
    iva: 124.8,
    partida_presupuestal: '2021-1-311111-510-M-152-83-001-155-21101-1-11721',
    precio_unitario: 65,
    subtotal: 780,
    total: 904.80,
    unidad_medida: 'Pieza',
  },
  {
    cantidad_pedida: 6,
    cantidad_pendiente: '-',
    cantidad_recibida: 6,
    cog: '21101',
    descripcion: 'Carpetas',
    id: 2,
    iva: 124.81,
    partida_presupuestal: '2021-1-311111-504-M-152-53-001-155-21101-1-11721',
    precio_unitario: 58,
    subtotal: 348,
    total: 4036,
    unidad_medida: 'Pieza',
  },
  {
    cantidad_pedida: 15,
    cantidad_pendiente: '-',
    cantidad_recibida: 15,
    cog: '21101',
    descripcion: 'Carpetas',
    id: 3,
    iva: 124.82,
    partida_presupuestal: '2021-1-311111-510-M-152-83-001-155-21101-1-11721',
    precio_unitario: 58,
    subtotal: 870,
    total: 1009.20,
    unidad_medida: 'Pieza',
  },
  {
    cantidad_pedida: 6,
    cantidad_pendiente: '-',
    cantidad_recibida: 6,
    cog: '21101',
    descripcion: 'Cuadernos',
    id: 4,
    iva: 124.83,
    partida_presupuestal: '2021-1-311111-510-M-152-83-001-155-21101-1-11721',
    precio_unitario: 44,
    subtotal: 264,
    total: 306.24,
    unidad_medida: 'Pieza',
  },
  {
    cantidad_pedida: 2,
    cantidad_pendiente: '-',
    cantidad_recibida: 2,
    cog: '21101',
    descripcion: 'Folders beige',
    id: 5,
    iva: 124.84,
    partida_presupuestal: '2021-1-311111-504-M-152-53-001-155-21101-1-11721',
    precio_unitario: 150,
    subtotal: 300,
    total: 348.00,
    unidad_medida: 'Caja',
  },
  {
    cantidad_pedida: 3,
    cantidad_pendiente: '-',
    cantidad_recibida: 3,
    cog: '21101',
    descripcion: 'Folders beige',
    id: 6,
    iva: 124.85,
    partida_presupuestal: '2021-1-311111-504-M-152-53-001-155-21101-1-11721',
    precio_unitario: 150,
    subtotal: 450,
    total: 522.00,
    unidad_medida: 'Caja',
  },
  {
    cantidad_pedida: 3,
    cantidad_pendiente: '-',
    cantidad_recibida: 3,
    cog: '21101',
    descripcion: 'Grapas estándar',
    id: 7,
    iva: 124.86,
    partida_presupuestal: '2021-1-311111-504-M-152-53-001-155-21101-1-11721',
    precio_unitario: 28,
    subtotal: 84,
    total: 97.44,
    unidad_medida: 'Caja',
  },
  {
    cantidad_pedida: 8,
    cantidad_pendiente: '-',
    cantidad_recibida: 8,
    cog: '21101',
    descripcion: 'Hojas de papel',
    id: 8,
    iva: 124.87,
    partida_presupuestal: '2021-1-311111-504-M-152-53-001-155-21101-1-11721',
    precio_unitario: '1200',
    subtotal: 9600,
    total: 11136.00,
    unidad_medida: 'Caja',
  },
  {
    cantidad_pedida: 4,
    cantidad_pendiente: '-',
    cantidad_recibida: 4,
    cog: '21101',
    descripcion: 'Hojas de papel',
    id: 9,
    iva: 124.88,
    partida_presupuestal: '2021-1-311111-510-M-152-83-001-155-21101-1-11721',
    precio_unitario: 1200,
    subtotal: 4800,
    total: 5568.00,
    unidad_medida: 'Caja',
  },
  {
    cantidad_pedida: 5,
    cantidad_pendiente: '-',
    cantidad_recibida: 5,
    cog: '21101',
    descripcion: 'Hojas de papel',
    id: 10,
    iva: 124.89,
    partida_presupuestal: '2021-1-311111-504-M-152-53-001-155-21101-1-11721',
    precio_unitario: '1200',
    subtotal: 6000,
    total: 6960.00,
    unidad_medida: 'Caja',
  },
  {
    cantidad_pedida: 3,
    cantidad_pendiente: '-',
    cantidad_recibida: 3,
    cog: '21101',
    descripcion: 'Plumas',
    id: 11,
    iva: 124.9,
    partida_presupuestal: '2021-1-311111-504-M-152-53-001-155-21101-1-11721',
    precio_unitario: 35,
    subtotal: 105,
    total: 121.80,
    unidad_medida: 'Caja',
  },
  {
    cantidad_pedida: 2,
    cantidad_pendiente: '-',
    cantidad_recibida: 2,
    cog: '21101',
    descripcion: 'Plumas',
    id: 12,
    iva: 124.91,
    partida_presupuestal: '2021-1-311111-504-M-152-53-001-155-21101-1-11721',
    precio_unitario: 35,
    subtotal: 70,
    total: 81.20,
    unidad_medida: 'Caja',
  }],
},
{
  id: 2,
  fecha: '24/8/2021',
  folio_recepcion: 'RA-2021-0457',
  folio_orden_de_compra: 'CO-2021-06-0037',
  folio_agrupador: 'FA-2021-0018',
  proveedor: 'La Vanguardista, SA de CV',
  importe: 389038.45,
  folio_presupuestal: 'FP-2021-0135',
  estados_globales: 4,
  tipo_de_documento: 'Factura',
  remisiones: 'Única',
  nombre: 'Carmen Aguilar Ríos',
  puesto: 'Almacenista',
  almacen: 'Almacén materiales',
  ubicacion: 'Calle Hidalgo No. 851',
  tipo_de_operacion: 'Bienes Almacenables',
  folio_remision: 'RE-2021-0090',
  fecha_comproboante: '8/25/2021',
  numero_documento: 'A124',
  data_table: [{
    cantidad_pedida: 12,
    cantidad_pendiente: '-',
    cantidad_recibida: 12,
    cog: '21101',
    descripcion: 'Cajas archivo muerto',
    id: 1,
    iva: 124.8,
    partida_presupuestal: '2021-1-311111-510-M-152-83-001-155-21101-1-11721',
    precio_unitario: 65,
    subtotal: 780,
    total: 904.80,
    unidad_medida: 'Pieza',
  },
  {
    cantidad_pedida: 6,
    cantidad_pendiente: '-',
    cantidad_recibida: 6,
    cog: '21101',
    descripcion: 'Carpetas',
    id: 2,
    iva: 124.81,
    partida_presupuestal: '2021-1-311111-504-M-152-53-001-155-21101-1-11721',
    precio_unitario: 58,
    subtotal: 348,
    total: 4036,
    unidad_medida: 'Pieza',
  },
  {
    cantidad_pedida: 15,
    cantidad_pendiente: '-',
    cantidad_recibida: 15,
    cog: '21101',
    descripcion: 'Carpetas',
    id: 3,
    iva: 124.82,
    partida_presupuestal: '2021-1-311111-510-M-152-83-001-155-21101-1-11721',
    precio_unitario: 58,
    subtotal: 870,
    total: 1009.20,
    unidad_medida: 'Pieza',
  },
  {
    cantidad_pedida: 6,
    cantidad_pendiente: '-',
    cantidad_recibida: 6,
    cog: '21101',
    descripcion: 'Cuadernos',
    id: 4,
    iva: 124.83,
    partida_presupuestal: '2021-1-311111-510-M-152-83-001-155-21101-1-11721',
    precio_unitario: 44,
    subtotal: 264,
    total: 306.24,
    unidad_medida: 'Pieza',
  },
  {
    cantidad_pedida: 2,
    cantidad_pendiente: '-',
    cantidad_recibida: 2,
    cog: '21101',
    descripcion: 'Folders beige',
    id: 5,
    iva: 124.84,
    partida_presupuestal: '2021-1-311111-504-M-152-53-001-155-21101-1-11721',
    precio_unitario: 150,
    subtotal: 300,
    total: 348.00,
    unidad_medida: 'Caja',
  },
  {
    cantidad_pedida: 3,
    cantidad_pendiente: '-',
    cantidad_recibida: 3,
    cog: '21101',
    descripcion: 'Folders beige',
    id: 6,
    iva: 124.85,
    partida_presupuestal: '2021-1-311111-504-M-152-53-001-155-21101-1-11721',
    precio_unitario: 150,
    subtotal: 450,
    total: 522.00,
    unidad_medida: 'Caja',
  },
  {
    cantidad_pedida: 3,
    cantidad_pendiente: '-',
    cantidad_recibida: 3,
    cog: '21101',
    descripcion: 'Grapas estándar',
    id: 7,
    iva: 124.86,
    partida_presupuestal: '2021-1-311111-504-M-152-53-001-155-21101-1-11721',
    precio_unitario: 28,
    subtotal: 84,
    total: 97.44,
    unidad_medida: 'Caja',
  },
  {
    cantidad_pedida: 8,
    cantidad_pendiente: '-',
    cantidad_recibida: 8,
    cog: '21101',
    descripcion: 'Hojas de papel',
    id: 8,
    iva: 124.87,
    partida_presupuestal: '2021-1-311111-504-M-152-53-001-155-21101-1-11721',
    precio_unitario: '1200',
    subtotal: 9600,
    total: 11136.00,
    unidad_medida: 'Caja',
  },
  {
    cantidad_pedida: 4,
    cantidad_pendiente: '-',
    cantidad_recibida: 4,
    cog: '21101',
    descripcion: 'Hojas de papel',
    id: 9,
    iva: 124.88,
    partida_presupuestal: '2021-1-311111-510-M-152-83-001-155-21101-1-11721',
    precio_unitario: 1200,
    subtotal: 4800,
    total: 5568.00,
    unidad_medida: 'Caja',
  },
  {
    cantidad_pedida: 5,
    cantidad_pendiente: '-',
    cantidad_recibida: 5,
    cog: '21101',
    descripcion: 'Hojas de papel',
    id: 10,
    iva: 124.89,
    partida_presupuestal: '2021-1-311111-504-M-152-53-001-155-21101-1-11721',
    precio_unitario: '1200',
    subtotal: 6000,
    total: 6960.00,
    unidad_medida: 'Caja',
  },
  {
    cantidad_pedida: 3,
    cantidad_pendiente: '-',
    cantidad_recibida: 3,
    cog: '21101',
    descripcion: 'Plumas',
    id: 11,
    iva: 124.9,
    partida_presupuestal: '2021-1-311111-504-M-152-53-001-155-21101-1-11721',
    precio_unitario: 35,
    subtotal: 105,
    total: 121.80,
    unidad_medida: 'Caja',
  },
  {
    cantidad_pedida: 2,
    cantidad_pendiente: '-',
    cantidad_recibida: 2,
    cog: '21101',
    descripcion: 'Plumas',
    id: 12,
    iva: 124.91,
    partida_presupuestal: '2021-1-311111-504-M-152-53-001-155-21101-1-11721',
    precio_unitario: 35,
    subtotal: 70,
    total: 81.20,
    unidad_medida: 'Caja',
  }],
},
{
  id: 3,
  fecha: '18/9/2021',
  folio_recepcion: 'RA-2021-0458',
  folio_orden_de_compra: 'CO-2021-06-0038',
  folio_agrupador: 'FA-2021-0022',
  proveedor: 'Integradora, SA de CV',
  importe: 189352.90,
  folio_presupuestal: 'FP-2021-0204',
  estados_globales: 4,
  tipo_de_documento: 'Factura',
  remisiones: 'Única',
  nombre: 'Carmen Aguilar Ríos',
  puesto: 'Almacenista',
  almacen: 'Almacén materiales',
  ubicacion: 'Calle Hidalgo No. 851',
  tipo_de_operacion: 'Bienes Almacenables',
  folio_remision: 'RE-2021-0125',
  fecha_comproboante: '9/10/2021',
  numero_documento: 'A125',
  data_table: [{
    cantidad_pedida: 12,
    cantidad_pendiente: '-',
    cantidad_recibida: 12,
    cog: '21101',
    descripcion: 'Cajas archivo muerto',
    id: 1,
    iva: 124.8,
    partida_presupuestal: '2021-1-311111-510-M-152-83-001-155-21101-1-11721',
    precio_unitario: 65,
    subtotal: 780,
    total: 904.80,
    unidad_medida: 'Pieza',
  },
  {
    cantidad_pedida: 6,
    cantidad_pendiente: '-',
    cantidad_recibida: 6,
    cog: '21101',
    descripcion: 'Carpetas',
    id: 2,
    iva: 124.81,
    partida_presupuestal: '2021-1-311111-504-M-152-53-001-155-21101-1-11721',
    precio_unitario: 58,
    subtotal: 348,
    total: 4036,
    unidad_medida: 'Pieza',
  },
  {
    cantidad_pedida: 15,
    cantidad_pendiente: '-',
    cantidad_recibida: 15,
    cog: '21101',
    descripcion: 'Carpetas',
    id: 3,
    iva: 124.82,
    partida_presupuestal: '2021-1-311111-510-M-152-83-001-155-21101-1-11721',
    precio_unitario: 58,
    subtotal: 870,
    total: 1009.20,
    unidad_medida: 'Pieza',
  },
  {
    cantidad_pedida: 6,
    cantidad_pendiente: '-',
    cantidad_recibida: 6,
    cog: '21101',
    descripcion: 'Cuadernos',
    id: 4,
    iva: 124.83,
    partida_presupuestal: '2021-1-311111-510-M-152-83-001-155-21101-1-11721',
    precio_unitario: 44,
    subtotal: 264,
    total: 306.24,
    unidad_medida: 'Pieza',
  },
  {
    cantidad_pedida: 2,
    cantidad_pendiente: '-',
    cantidad_recibida: 2,
    cog: '21101',
    descripcion: 'Folders beige',
    id: 5,
    iva: 124.84,
    partida_presupuestal: '2021-1-311111-504-M-152-53-001-155-21101-1-11721',
    precio_unitario: 150,
    subtotal: 300,
    total: 348.00,
    unidad_medida: 'Caja',
  },
  {
    cantidad_pedida: 3,
    cantidad_pendiente: '-',
    cantidad_recibida: 3,
    cog: '21101',
    descripcion: 'Folders beige',
    id: 6,
    iva: 124.85,
    partida_presupuestal: '2021-1-311111-504-M-152-53-001-155-21101-1-11721',
    precio_unitario: 150,
    subtotal: 450,
    total: 522.00,
    unidad_medida: 'Caja',
  },
  {
    cantidad_pedida: 3,
    cantidad_pendiente: '-',
    cantidad_recibida: 3,
    cog: '21101',
    descripcion: 'Grapas estándar',
    id: 7,
    iva: 124.86,
    partida_presupuestal: '2021-1-311111-504-M-152-53-001-155-21101-1-11721',
    precio_unitario: 28,
    subtotal: 84,
    total: 97.44,
    unidad_medida: 'Caja',
  },
  {
    cantidad_pedida: 8,
    cantidad_pendiente: '-',
    cantidad_recibida: 8,
    cog: '21101',
    descripcion: 'Hojas de papel',
    id: 8,
    iva: 124.87,
    partida_presupuestal: '2021-1-311111-504-M-152-53-001-155-21101-1-11721',
    precio_unitario: '1200',
    subtotal: 9600,
    total: 11136.00,
    unidad_medida: 'Caja',
  },
  {
    cantidad_pedida: 4,
    cantidad_pendiente: '-',
    cantidad_recibida: 4,
    cog: '21101',
    descripcion: 'Hojas de papel',
    id: 9,
    iva: 124.88,
    partida_presupuestal: '2021-1-311111-510-M-152-83-001-155-21101-1-11721',
    precio_unitario: 1200,
    subtotal: 4800,
    total: 5568.00,
    unidad_medida: 'Caja',
  },
  {
    cantidad_pedida: 5,
    cantidad_pendiente: '-',
    cantidad_recibida: 5,
    cog: '21101',
    descripcion: 'Hojas de papel',
    id: 10,
    iva: 124.89,
    partida_presupuestal: '2021-1-311111-504-M-152-53-001-155-21101-1-11721',
    precio_unitario: '1200',
    subtotal: 6000,
    total: 6960.00,
    unidad_medida: 'Caja',
  },
  {
    cantidad_pedida: 3,
    cantidad_pendiente: '-',
    cantidad_recibida: 3,
    cog: '21101',
    descripcion: 'Plumas',
    id: 11,
    iva: 124.9,
    partida_presupuestal: '2021-1-311111-504-M-152-53-001-155-21101-1-11721',
    precio_unitario: 35,
    subtotal: 105,
    total: 121.80,
    unidad_medida: 'Caja',
  },
  {
    cantidad_pedida: 2,
    cantidad_pendiente: '-',
    cantidad_recibida: 2,
    cog: '21101',
    descripcion: 'Plumas',
    id: 12,
    iva: 124.91,
    partida_presupuestal: '2021-1-311111-504-M-152-53-001-155-21101-1-11721',
    precio_unitario: 35,
    subtotal: 70,
    total: 81.20,
    unidad_medida: 'Caja',
  }],
},
];
