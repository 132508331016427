import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Spin,
  DatePicker,
  TreeSelect,
  Card,
  Tabs,
} from 'antd';

import
getFormattedValues,
{ formatReceived } from 'utils/formatValues';
import API from 'utils/api';
import {
  RFC_PATTERN,
  digitoVerificador,
  CURP_PATTERN,
} from 'utils/patterns';
import { onError, onSuccess } from 'utils/handlers';
import Table from 'components/Table';
import FormSubmitControls from 'components/FormSubmitControls';
import ModalDelete from 'components/ModalDelete';
import LadaNumero from 'components/LadaNumero';
import NumericInput from 'components/NumericInput';
// import DatosDePago from 'components/DatosDePago';
import DatosDePago from 'components/Catalogos/DatosDePago';

import Direccion from 'components/Direccion';
import AvatarUploader from 'components/AvatarUploader';
import Uploader from 'components/Uploader';
import Select from 'components/Select';
import makeMessager from 'utils/complexFormsMessages';
import { isMutable } from 'utils/estadosGlobales';

export const permissionEmpleado = {
  permissionModel: 'empleado',
};

const { TabPane } = Tabs;

const baseURI = 'estructura-organizacional/empleados/';

// eslint-disable-next-line react/prop-types
function Empleados({ permission }) {
  const [form] = Form.useForm();
  const [formDatos] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);
  const [estadosEmpleado, setDataEstadosEmpleado] = useState([]);
  const [puestos, setPuestos] = useState([]);
  const [formasDePago, setFormasDePago] = useState([]);
  const [treeData, setTreeData] = useState([]);
  const [generos, setGeneros] = useState([]);
  const [estadoCivil, setEstadoCivil] = useState([]);
  const [currentTabKey, setCurrentTabKey] = useState('general');
  const [formAddress, setFormAddress] = useState();
  const [mutable, setMutable] = useState(true);
  const [contentTypes, setContentTypes] = useState([]);

  const basicKeys = [
    'general',
    'datos',
    'formasDePago',
  ];

  const formInstances = [
    form,
    formDatos,
  ];

  const complexForms = [{ key: 'domicilio', formInstance: formAddress }];

  const normalizeData = (_data) => {
    if (_data) {
      const output = _data.map((item) => {
        const clone = { ...item };
        const unidad_responsable = normalizeData(clone.unidad_responsable);
        const centro_de_costo = normalizeData(clone.centro_de_costo);
        if (!unidad_responsable.length && !centro_de_costo.length) {
          delete clone.unidad_responsable;
          delete clone.centro_de_costo;
          return ({
            title: clone.descripcion,
            value: `${clone.id}/${clone.nivel}`,
          });
        }
        if (unidad_responsable) {
          return ({
            title: clone.descripcion,
            value: `${clone.id}/${clone.nivel}`,
            children: unidad_responsable,
            // disabled: true,
          });
        }
        return ({
          title: clone.descripcion,
          value: `${clone.id}/${clone.nivel}`,
          children: centro_de_costo,
          // disabled: true,
        });
      });
      return output;
    }
    return false;
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await API.get(baseURI);
      setData(formatReceived(res.data));
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const fetchAll = async () => {
    try {
      setLoading(true);
      const resEstadosEmpleado = await API.get('/estructura-organizacional/estados-de-empleado/');
      setDataEstadosEmpleado(resEstadosEmpleado.data);

      const resGeneros = await API.get('/usuarios/generos/');
      setGeneros(resGeneros.data);

      const resEstadoCivil = await API.get('/usuarios/estados-civiles/');
      setEstadoCivil(resEstadoCivil.data);

      const resPuestos = await API.get('/estructura-organizacional/puestos/');
      setPuestos(resPuestos.data);

      const resUR = await API.get('estructura-organizacional/unidades-de-trabajo');
      const formattedUr = normalizeData(resUR.data);
      setTreeData(formattedUr);

      const resFP = await API.get('/catalogos/formas-de-pago');
      setFormasDePago(resFP.data);

      const resContentTypes = await API.get('catalogos/content-types-ur');
      setContentTypes(resContentTypes.data);

      await fetchData();
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchAll();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  // const postDatosDePago = async () => {
  //   const promises = [];
  //   datosDePago.forEach((dato) => {
  //     const promise = API.post('/configuraciones/datos-de-pago/', {
  //       ...dato,
  //       estado: dato.estado_dato,
  //     });
  //     promises.push(promise);
  //   });
  //   const responses = promises.length ? await Promise.all(promises) : [];
  //   return responses.map((res) => (res && res.data ? res.data.id : null)).filter((i) => !!i);
  // };

  const onCancel = () => {
    setSelectedRowKeys([]);
    setVisible(false);
    form.resetFields();
    formDatos.resetFields();
    setMutable(true);
    setCurrentTabKey('general');
  };

  const getURValuesToSend = () => {
    const unidad_responsable = formDatos.getFieldValue('unidad_responsable');
    if (unidad_responsable) {
      const [object_id, nivel] = unidad_responsable.split('/');
      const content_type = contentTypes.find((ct) => ct.nivel.toString() === nivel).id;
      return { object_id, content_type };
    }
    return { object_id: undefined, content_type: undefined };
  };

  const getURForTreeSelect = (_data) => {
    if (_data.content_type && _data.object_id) {
      const { nivel } = contentTypes.find((ct) => ct.id === _data.content_type);
      const { object_id } = _data;
      formDatos.setFieldsValue({ unidad_responsable: `${object_id}/${nivel}` });
    }
  };

  const onClickEdit = async (responseData = null) => {
    try {
      setLoading(true);
      const [key] = selectedRowKeys;
      const match = responseData || data.find((item) => item.id === key);
      const res = await API.get(`/estructura-organizacional/empleados/${match.id}`);
      setTimeout(() => {
        if (res.data) {
          setMutable(isMutable(res.data.estados_globales));
          form.resetFields();
          formDatos.resetFields();
          setTimeout(() => {
            form.setFieldsValue(formatReceived(res.data));
            formDatos.setFieldsValue(formatReceived(res.data));
            getURForTreeSelect(res.data);
            setVisible(true);
          });
        }
      });
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onFinish = async (_values, _continue = false) => {
    try {
      setLoading(true);
      await form.validateFields();
      await formDatos.validateFields();
      const values = {
        ...form.getFieldsValue(),
        ...formDatos.getFieldsValue(),
        ...getURValuesToSend(),
      };

      const files = ['foto', 'firma_electronica'];
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      const formattedValues = getFormattedValues(values, {
        files,
        formData: config.headers,
        clean: true,
      });

      if (!selectedRowKeys.length) {
        const response = await API.post(baseURI, formattedValues, config);
        if (response?.status === 201) {
          if (_continue) {
            onSuccess(response, 'Agregado correctamente');
            await fetchData();
            setSelectedRowKeys([response.data.id]);
            onClickEdit(response.data);
          } else {
            onSuccess(response.data, 'Agregado correctamente');
            onCancel();
            await fetchData();
          }
        }
      } else {
        const [key] = selectedRowKeys;
        const response = await API.put(`${baseURI}${key}/`, formattedValues, config);
        if (response?.status === 200) {
          onSuccess(response, 'Actualizado correctamente');
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(
        err,
        setLoading,
        formInstances,
        setCurrentTabKey,
      );
    }
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`${baseURI}${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          setVisibleAlert(false);
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    form.setFieldsValue(record);
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const showMsg = makeMessager(setCurrentTabKey, fetchData);

  // validar si se mueve entre tabs de datos básicos/generales/'propios'
  // o complejos que requieran llamar su propio handler
  const onChangeTabKey = (key) => {
    const fromBasic = basicKeys.includes(currentTabKey);
    const toBasic = basicKeys.includes(key);
    const match = complexForms.find((e) => e.key === currentTabKey);
    if (!match) {
      if (fromBasic && toBasic) {
        setCurrentTabKey(key);
      } else if (fromBasic) {
        showMsg(key);
      } else {
        setCurrentTabKey(key);
      }
    } else {
      match.formInstance.onFinishHandler(key, true);
    }
  };

  // validar tab por guardar
  const handleOnFinish = (vals, _continue = false) => {
    const match = complexForms.find((e) => e.key === currentTabKey);
    if (match) {
      match.formInstance.onFinishHandler(null, _continue);
    } else {
      onFinish(null, _continue);
    }
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const columns = [
    {
      titleText: 'Número de empleado',
      dataIndex: 'numero_de_empleado',
      key: 'numero_de_empleado',
      width: 200,
    },
    {
      titleText: 'Nombre',
      dataIndex: 'nombre',
      key: 'nombre',
      width: 250,
    },
    {
      titleText: 'Apellido Paterno',
      dataIndex: 'apellido_paterno',
      key: 'apellido_paterno',
      width: 250,
    },
    {
      titleText: 'Apellido Materno',
      dataIndex: 'apellido_materno',
      key: 'apellido_materno',
      width: 250,
    },
    {
      titleText: 'Estado',
      dataIndex: 'estado_del_empleado',
      key: 'estado_del_empleado',
      width: 200,
      render: (val) => estadosEmpleado.find((e) => e.id === val)?.descripcion,
    },
    {
      titleText: 'Puesto',
      dataIndex: 'puesto',
      key: 'puesto',
      width: 200,
      render: (val) => (puestos.find((item) => item.id === val)?.descripcion),
    },
  ];

  const requiredRule = {
    required: true,
    message: 'El campo es requerido',
  };

  const rules = {
    required: [requiredRule],
    lada: [requiredRule],
    numero_de_telefono: [requiredRule],
    RFC: [
      requiredRule,
      {
        pattern: RFC_PATTERN,
        message: 'Ingrese un RFC válido',
      },
    ],
    curp: [
      requiredRule,
      {
        validator: async (rule, value) => {
          if (value) {
            const validado = value.toUpperCase().match(CURP_PATTERN);
            if (!validado || validado[2] !== digitoVerificador(validado[1])) {
              throw new Error('Ingrese una CURP válida');
            }
          }
        },
      },
    ],
    correo_electronico: [
      requiredRule,
      {
        type: 'email',
        message: 'Ingrese un correo válido',
      },
    ],
  };

  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible
          ? (
            <Table
              cols={columns}
              data={data}
              rowSelection={rowSelection}
              handleOnRowClick={handleOnRowClick}
              permission={permission}
              controls={{
                onClickAdd,
                onClickEdit: () => onClickEdit(),
                onClickDelete,
              }}
            />
          )
          : (
            <Card
              className="form-container"
              title={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Empleado`}
              extra={(
                <FormSubmitControls
                  onFinish={basicKeys
                    .concat(complexForms.map((e) => e.key))
                    .includes(currentTabKey)
                    ? handleOnFinish : null}
                  onCancel={onCancel}
                  loading={loading}
                  allowSaveAndContinue
                />
              )}
              bordered={false}
            >
              <Tabs
                activeKey={currentTabKey}
                onChange={mutable ? setCurrentTabKey : onChangeTabKey}
                type="card"
              >
                <TabPane
                  tab="General"
                  key="general"
                  forceRender
                >
                  <Form
                    layout="vertical"
                    form={form}
                    onFinish={onFinish}
                    scrollToFirstError
                    name="general"
                  >
                    <Row gutter={10}>
                      <Col xs={24} sm={24} md={8}>
                        <AvatarUploader
                          form={form}
                          imageURL={form.getFieldValue('foto')}
                        />
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="numero_de_empleado"
                          label="Número de Empleado"
                          disabled={!!selectedRowKeys.length}
                        >
                          <Input allowClear disabled={!!selectedRowKeys.length} />
                        </Form.Item>
                      </Col>
                      <Col xs={0} sm={0} md={8} />
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="nombre"
                          rules={rules.required}
                          label="Nombre"
                          hasFeedback
                        >
                          <Input allowClear />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="apellido_paterno"
                          rules={rules.required}
                          label="Apellido Paterno"
                          hasFeedback
                        >
                          <Input allowClear />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="apellido_materno"
                          label="Apellido Materno"
                          hasFeedback
                        >
                          <Input allowClear />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="fecha_de_nacimiento"
                          rules={rules.required}
                          label="Fecha de Nacimiento"
                          hasFeedback
                        >
                          <DatePicker
                            placeholder=""
                            format="DD/MM/YYYY"
                          />
                        </Form.Item>
                      </Col>
                      <LadaNumero
                        rules={rules}
                        form={form}
                        names={{
                          lada: 'lada',
                          numero_de_telefono: 'numero_de_telefono',
                        }}
                        breakPoints={{
                          md: 8,
                          lg: 8,
                          xl: 8,
                          xxl: 8,
                        }}
                      />
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="genero"
                          label="Género"
                          hasFeedback
                        >
                          <Select dataSource={generos} />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="estado_civil"
                          label="Estado Civil"
                          hasFeedback
                        >
                          <Select dataSource={estadoCivil} />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="RFC"
                          rules={rules.RFC}
                          label="RFC"
                          hasFeedback
                        >
                          <Input allowClear />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="curp"
                          rules={rules.curp}
                          label="CURP"
                          hasFeedback
                        >
                          <Input allowClear />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item hidden>
                      <Button htmlType="submit" />
                    </Form.Item>
                  </Form>
                </TabPane>
                <TabPane
                  key="datos"
                  tab="Datos de Empleado"
                  forceRender
                >
                  <Form
                    name="datos"
                    form={formDatos}
                    layout="vertical"
                    onFinish={onFinish}
                    scrollToFirstError
                  >
                    <Row gutter={10}>
                      <Col xs={24} sm={24} md={8}>
                        <NumericInput
                          name="numero_de_plaza"
                          label="No. de Plaza"
                        />
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="puesto"
                          label="Puesto"
                          rules={rules.required}
                          hasFeedback
                        >
                          <Select dataSource={puestos} />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="correo_electronico"
                          rules={rules.correo_electronico}
                          label="Correo Electrónico"
                          hasFeedback
                        >
                          <Input allowClear />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          label="Unidad Responsable / Unidad Operativa / Centro de Costo"
                          name="unidad_responsable"
                        >
                          <TreeSelect
                            showSearch
                            treeNodeFilterProp="title"
                            style={{ width: '100%' }}
                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                            treeData={treeData}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="estado_del_empleado"
                          rules={rules.required}
                          label="Estado del Empleado"
                          hasFeedback
                        >
                          <Select dataSource={estadosEmpleado} />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="forma_de_pago"
                          label="Forma de Pago"
                          hasFeedback
                        >
                          <Select dataSource={formasDePago} />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={16}>
                        <Form.Item
                          name="observacion"
                          label="Observaciones"
                        >
                          <Input allowClear />
                        </Form.Item>
                      </Col>
                      <Uploader
                        title="Firma Electrónica"
                        limitMB={50}
                        file={form.getFieldValue('firma_electronica')}
                        formItemName="firma_electronica"
                        allowedExtensions={['cer']}
                        allowPreview={false}
                      />
                    </Row>
                    <Form.Item hidden>
                      <Button htmlType="submit" />
                    </Form.Item>
                  </Form>
                </TabPane>
                <TabPane
                  key="direccion"
                  tab="Dirección"
                  disabled={!selectedRowKeys.length}
                >
                  <Direccion
                    setFormAddress={setFormAddress}
                    parentID={selectedRowKeys[0]}
                    currentURI={baseURI}
                    setLoading={setLoading}
                    setCurrentTabKey={setCurrentTabKey}
                    callback={(values, _continue) => {
                      if (!_continue) {
                        onCancel();
                      } else {
                        const [key] = selectedRowKeys;
                        const clone = [...data];
                        const mappedData = clone.map((e) => (e.id === key
                          ? ({ ...e, domicilio_fiscal: values?.id }) : e));
                        setData(mappedData);
                      }
                    }}
                  />
                </TabPane>
                <TabPane
                  key="formasDePago"
                  tab="Datos de Pago"
                  disabled={!selectedRowKeys.length}
                >
                  <DatosDePago
                    selectedRowKey={selectedRowKeys[0]}
                    currentURI={baseURI}
                  />
                </TabPane>
              </Tabs>
            </Card>
          )}
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => setVisibleAlert(false)}
          visible={visibleAlert}
          content={`empleado ${form.getFieldValue('nombre')} ${form.getFieldValue('apellido_paterno')}`}
          loading={loading}
        />
      </Spin>
    </Row>
  );
}

export default Empleados;
