import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Spin,
  Tabs,
  message,
} from 'antd';
import PropTypes from 'prop-types';
import { CloseCircleOutlined } from '@ant-design/icons';
import ModalDelete from 'components/ModalDelete';
import FormSubmitControls from 'components/FormSubmitControls';
import Table from 'components/Table';
import getFormatedValues, { formatReceived } from 'utils/formatValues';
import API from 'utils/api';
import { onError, onSuccess } from 'utils/handlers';
import Select from 'components/Select';
import currencyFormatter from 'utils/currencyFormatter';
import makeMessager from 'utils/complexFormsMessages';
import { isMutable } from 'utils/estadosGlobales';
import { InputSearchPro } from 'components/InputSearch';
import {
  SET_URS,
  SET_TIPOS_DE_DEPOSITOS,
} from 'store/reducers/catalogs';
import fetchSome from 'utils/fetchSome';
import NumericInput from 'components/NumericInput';
import FormItemLabel from 'components/FormItemLabel';
// import mock from 'mocks/mock_ingresos_recaudados';
import DesgloseDeConceptosDeIngreso from './DesgloseDeConceptosDeIngreso';

export const permissionIngresoRecaudado = {
  permissionModel: 'ingresorecaudado',
};

const { TabPane } = Tabs;

const baseURI = 'contabilidad/ingreso-recaudado/';

const uris = {
  1: '/unidades-operativas/',
  2: '/unidades-responsables/',
  3: '/centros-de-costos/',
};

// eslint-disable-next-line react/prop-types
function IngresosRecaudados({ permission, polizaParent }) {
  const {
    estadosGlobales,
    tiposDeDepositos,
  } = useSelector((state) => state.catalogs);
  const history = useHistory();
  const collapsedSidebar = useSelector(({ app }) => app.collapsedSidebar);
  const urs = useSelector(({ catalogs }) => catalogs.urs).sort((a, b) => a.nivel - b.nivel);
  const [form] = Form.useForm();
  const [formDatosBancarios] = Form.useForm();
  const [formCajaInfo] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [tipoDeDeposito, setTipoDeDeposito] = useState(0);

  const [currentTabKey, setCurrentTabKey] = useState('general');
  const [mutable, setMutable] = useState(true);
  const [tiposDeDocumentos, setTiposDeDocumentos] = useState([]);

  const [ursFound, setUrsFound] = useState([]);
  const [contentType, setContentType] = useState(0);

  const [cajeroFound, setCajeroFound] = useState([]);
  const [conceptosDeIngreso, setConceptosDeIngreso] = useState([]);

  const [tiposDeCaja, setTiposDeCaja] = useState([]);
  const [bancos, setBancos] = useState([]);
  const [cuentasBancarias, setCuentasBancarias] = useState([]);
  const [banco, setBanco] = useState();
  const basicKeys = ['general', 'cuentasBancarias', 'detalle', 'cajaInfo'];
  const complexForms = [];

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await API.get(baseURI, {
        params: {
          ingreso_general: polizaParent,
        },
      });
      setData(formatReceived(response.data));
      // setData(formatReceived(mock));
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const fetchAll = async () => {
    try {
      setLoading(true);

      const resDocs = await API.get('configuraciones/tipos-de-documentos-para-polizas/');
      setTiposDeDocumentos(resDocs.data);

      if (!urs?.length) {
        await fetchSome('/catalogos/content-types-ur/', SET_URS);
      }

      const resBancos = await API.get('configuraciones/bancos/');
      setBancos(resBancos.data);

      const resTiposDeCaja = await API.get('catalogos/tipo-de-caja/');
      setTiposDeCaja(resTiposDeCaja.data);

      const resCuentasBancarias = await API.get('configuraciones/cuentas-bancarias/');
      setCuentasBancarias(resCuentasBancarias.data);

      const resConceptos = await API.get('configuraciones/conceptos-de-ingresos/');
      setConceptosDeIngreso(resConceptos.data);

      if (!tiposDeDepositos?.length) {
        await fetchSome('catalogos/opciones-de-depositos/', SET_TIPOS_DE_DEPOSITOS);
      }
      if (polizaParent) {
        await fetchData();
      }

      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchAll();
    return () => API.source.cancel;
    // eslint-disable-next-line
  },[])

  const onCancel = () => {
    setVisible(false);
    setSelectedRowKeys([]);
    form.resetFields();
    formDatosBancarios.resetFields();
    formCajaInfo.resetFields();
    setMutable(true);
    setTipoDeDeposito(0);
    setContentType(0);
    setUrsFound([]);
    setCajeroFound([]);
    setCurrentTabKey('general');
    setBanco(0);
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    setTimeout(() => {
      form.setFieldsValue(record);
      formDatosBancarios.setFieldsValue(record);
      formCajaInfo.setFieldsValue(record);
      setMutable(isMutable(record));
    });
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickEdit = async (response = {}) => {
    try {
      setLoading(true);
      const clone = [...data];
      const [key] = selectedRowKeys;
      const match = response?.id ? response : clone.find((e) => e.id === key);
      if (match?.tipo_de_unidad_responsable) {
        setContentType(match.tipo_de_unidad_responsable);
        const { nivel } = urs.find((e) => e.id === match.tipo_de_unidad_responsable);
        const resUR = await API
          .get(`/estructura-organizacional${uris[nivel]}${match.unidad_responsable_id}/`);
        setUrsFound([resUR.data]);
      }
      if (match?.usuario_id) {
        const resCajero = await API.get(`estructura-organizacional/empleados/${match.usuario_id}`);
        const result = {
          ...resCajero.data,
          descripcion: `${resCajero.data?.numero_de_empleado} - ${resCajero.data?.nombre || ''} ${resCajero.data?.apellido_paterno || ''} ${resCajero.data?.apellido_materno || ''}`,
        };
        setCajeroFound([result]);
      }
      setTipoDeDeposito(match?.depositado);
      setBanco(match?.banco);
      setMutable(isMutable(match));
      setTimeout(() => {
        form.setFieldsValue(formatReceived(match));
        formDatosBancarios.setFieldsValue(formatReceived(match));
        formCajaInfo.setFieldsValue(formatReceived(match));
      });
      setLoading(false);
      setVisible(true);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onFinish = async (_, _continue) => {
    try {
      setLoading(true);
      await form.validateFields();
      await formDatosBancarios.validateFields();
      await formCajaInfo.validateFields();

      const values = {
        ...form.getFieldsValue(),
        ...formDatosBancarios.getFieldsValue(),
        ...formCajaInfo.getFieldsValue(),
      };
      values.ingreso_general = polizaParent;
      values.tipo_de_unidad_responsable = contentType;
      const formattedValues = getFormatedValues(values);

      if (!selectedRowKeys.length) {
        const response = await API.post(baseURI, formattedValues);
        if (response?.status === 201) {
          if (_continue) {
            onSuccess(response, 'Agregado correctamente');
            await fetchData();
            setSelectedRowKeys([response.data.id]);
            onClickEdit(response.data);
          } else {
            onSuccess(response.data, 'Agregado correctamente');
            onCancel();
            await fetchData();
          }
        }
      } else {
        const [key] = selectedRowKeys;
        const response = await API.put(`${baseURI}${key}/`, formattedValues);
        if (response?.status === 200) {
          if (_continue) {
            await fetchData();
            onClickEdit(response.data);
          } else {
            onSuccess(response, 'Actualizado correctamente');
            onCancel();
            await fetchData();
          }
        }
      }

      setLoading(false);
    } catch (err) {
      onError(err, setLoading, [form, formDatosBancarios, formCajaInfo], setCurrentTabKey);
    }
  };

  const columns = [
    {
      titleText: 'Fecha del Ingreso',
      dataIndex: 'fecha_de_captura',
      key: 'fecha_de_captura',
      width: 200,
      render: (e) => e?.format('DD/MM/YYYY'),
    },
    {
      titleText: 'Documento',
      dataIndex: 'tipo_de_documento',
      key: 'tipo_de_documento',
      width: 300,
      render: (id) => tiposDeDocumentos.find((e) => e.id === id)?.descripcion || '-',
    },
    {
      titleText: 'No. Documento',
      dataIndex: 'numero_de_documento',
      key: 'numero_de_documento',
      width: 150,
      render: (e) => e || '-',
    },
    {
      titleText: 'Tipo de Caja',
      dataIndex: 'tipo_de_caja',
      key: 'tipo_de_caja',
      width: 150,
      render: (id) => tiposDeCaja.find((e) => e.id === id)?.descripcion || '-',
    },
    {
      titleText: 'No. de Registro de Ingresos',
      dataIndex: 'numero_del_registro_de_ingresos',
      key: 'numero_del_registro_de_ingresos',
      width: 300,
      render: (e) => e || '-',
    },
    {
      titleText: 'Descripción',
      dataIndex: 'descripcion',
      key: 'descripcion',
      width: 400,
      render: (e) => e || '-',
    },
    {
      titleText: 'Fecha de Depósito',
      dataIndex: 'fecha_de_deposito',
      key: 'fecha_de_deposito',
      width: 200,
      render: (e) => e?.format('DD/MM/YYYY') || '-',
    },
    {
      titleText: 'Banco',
      dataIndex: 'banco',
      key: 'banco',
      width: 200,
      render: (id) => bancos.find((e) => e.id === id)?.nombre || '-',
    },
    {
      titleText: 'Cuenta Bancaria',
      dataIndex: 'cuenta_bancaria',
      key: 'cuenta_bancaria',
      width: 300,
      render: (id) => cuentasBancarias.find((e) => e.id === id)?.numero_de_cuenta || '-',
    },
    {
      titleText: 'Importe',
      dataIndex: 'total_depositado',
      key: 'total_depositado',
      width: 200,
      render: (val) => currencyFormatter(val || 0),
      align: 'right',
    },
    {
      titleText: 'Estado',
      dataIndex: 'estados_globales',
      key: 'estados_globales',
      width: 200,
      render: (id) => estadosGlobales.find((e) => e.id === id)?.descripcion || '-',
    },
    {
      titleText: 'Folio Presupuestal',
      dataIndex: 'folio_presupuestal',
      key: 'folio_presupuestal',
      width: 300,
      render: (e) => (e || '-'),
    },
  ];

  const deleteItem = async () => {
    try {
      setLoading(true);
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`${baseURI}${key}/`);
        if (response?.status === 204) {
          setVisibleAlert(false);
          onSuccess(response, 'Eliminado correctamente', 2.5);
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onClickAdd = async () => {
    setVisible(true);
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const showMsg = makeMessager(setCurrentTabKey, fetchData);

  const onChangeTabKey = (key) => {
    const fromBasic = basicKeys.includes(currentTabKey);
    const toBasic = basicKeys.includes(key);
    const match = complexForms.find((e) => e.key === currentTabKey);
    if (!match) {
      if (fromBasic && toBasic) {
        setCurrentTabKey(key);
      } else if (fromBasic) {
        showMsg(key);
      } else {
        setCurrentTabKey(key);
      }
    } else {
      match.formInstance.onFinishHandler(key, true);
    }
  };

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
    conditional_required: [
      {
        required: tipoDeDeposito === 2,
        message: 'El campo es requerido',
      },
    ],
  };

  const resetAndValidateURs = () => {
    setUrsFound([]);
    form.resetFields(['tipo_de_unidad_responsable']);
    form.validateFields(['tipo_de_unidad_responsable', 'unidad_responsable_id']);
  };

  const resetAndValidateCajero = () => {
    setCajeroFound([]);
    form.resetFields(['q_cajero']);
    form.validateFields(['q_cajero']);
  };

  const busquedaUR = async () => {
    try {
      setLoading(true);
      await form.validateFields(['tipo_de_unidad_responsable', 'unidad_responsable_id']);
      const { tipo_de_unidad_responsable, unidad_responsable_id: q } = form.getFieldsValue();
      const { nivel } = urs.find((e) => e.id === tipo_de_unidad_responsable);
      setContentType(tipo_de_unidad_responsable);
      const response = await API.get(`/estructura-organizacional${uris[nivel]}`, {
        params: { q },
      });

      if (response?.status === 200 && response?.data?.length) {
        form.setFieldsValue({
          unidad_responsable_id: response.data[0].id,
        });
        setUrsFound([response.data[0]]);
      } else {
        message.info('No se encontraron coincidencias');
        setUrsFound([]);
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const busquedaCajero = async () => {
    try {
      setLoading(true);
      await form.validateFields(['q_cajero']);
      const { q_cajero: q } = form.getFieldsValue();
      const response = await API.get('estructura-organizacional/empleados/', {
        params: { numero_de_empleado: q },
      });

      if (response?.status === 200 && response?.data?.length) {
        const results = response.data.map((e) => ({
          ...e,
          descripcion: `${e?.numero_de_empleado} - ${e?.nombre || ''} ${e?.apellido_paterno || ''} ${e.apellido_materno || ''}`,
        }));
        form.setFieldsValue({
          usuario_id: results[0].id,
        });
        setCajeroFound([results[0]]);
      } else {
        message.info('No se encontraron coincidencias');
        setCajeroFound([]);
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const validateOnAuthorize = async () => {
    try {
      setLoading(true);
      const documento = 3; // documento de corte de caja
      const estados_globales = 4;
      const [ingreso_recaudado] = selectedRowKeys;
      const errors = [];
      const resConfiguracionDePoliza = await API.get('/configuraciones/configuraciones-de-polizas/', {
        params: {
          documento,
          estados_globales,
        },
      });
      if (resConfiguracionDePoliza?.status === 200 && !resConfiguracionDePoliza.data?.length) {
        errors.push({
          message: 'Configuración de Póliza para Ingresos Recaudados',
          path: '/contabilidad/configuraciones-de-polizas',
        });
      }

      const resMovimientos = await API.get('configuraciones/movimientos-de-polizas/', {
        params: {
          concepto: documento,
          documento,
          estados_globales,
        },
      });
      if (resMovimientos.status === 200 && !resMovimientos.data?.length) {
        errors.push({
          message: 'Movimientos de Póliza para Ingresos Recaudados',
          path: '/contabilidad/movimiento-de-polizas',
        });
      }

      const resFolioAgrupador = await API.get('configuraciones/folios-de-documentos-operativos/', {
        params: {
          documento,
          estados_globales,
        },
      });
      if (resFolioAgrupador.status === 200 && !resFolioAgrupador.data?.length) {
        errors.push({
          message: 'Folio Agrupador para Ingresos Recaudados',
          path: '/configuracion/otros/foliador-de-documentos-operativos',
        });
      }

      const resDesgloces = await API.get('contabilidad/desglose-de-concepto-de-ingresos/', {
        params: { ingreso_recaudado },
      });
      if (resDesgloces.status === 200) {
        const desgloces = resDesgloces.data;
        const desglocesConClave = desgloces.filter((e) => e?.clave_presupuestal);
        if ((desgloces.length !== desglocesConClave.length)
          || !desglocesConClave.length || !desgloces.length) {
          errors.push({
            message: 'Completar los desgloces',
            tabKey: 'detalle',
          });
        }
      }
      if (errors.length) {
        message.warn({
          content: (
            <Col>
              <strong>
                Para autorizar es requerido registrar/autorizar los siguientes datos:
              </strong>
              <br />
              <br />
              {errors.map((item) => (
                <React.Fragment key={item.path}>
                  <Row
                    justify="space-between"
                  >
                    <strong style={{ marginRight: 3 }}>
                      {item.message}
                    </strong>
                    <Button
                      type="link"
                      onClick={() => {
                        if ('path' in item) {
                          history.push(item.path);
                        }
                        if ('tabKey' in item) {
                          setCurrentTabKey(item.tabKey);
                        }
                        message.destroy();
                      }}
                    >
                      Ir

                    </Button>
                  </Row>
                </React.Fragment>
              ))}
              <Button
                icon={<CloseCircleOutlined />}
                onClick={() => message.destroy()}
                type="link"
                style={{
                  position: 'absolute',
                  top: -30,
                  right: -20,
                }}
              />
            </Col>
          ),
          duration: 6,
        });
        setLoading(false);
        return false;
      }
      setLoading(false);
      return true;
    } catch (err) {
      onError(err, setLoading);
      return false;
    }
  };

  return (
    <Row justify="center" className="container">
      <Col span={24}>
        <Spin tip="Cargando..." spinning={loading}>
          {!visible ? (
            <Table
              cols={columns}
              permission={permission}
              data={data}
              baseURI={baseURI}
              allowImport
              mobileColIndex={0}
              rowSelection={rowSelection}
              handleOnRowClick={handleOnRowClick}
              controls={{
                onClickAdd,
                onClickEdit,
                onClickDelete,
              }}
              loading={loading}
            />
          ) : (
            <Col span={24}>
              <Card
                className="form-container"
                title={(
                  <FormSubmitControls
                    allowAuthorize
                    allowCancel
                    allowDestroyModal
                    allowSaveAndContinue
                    baseURI={baseURI}
                    label={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Ingreso`}
                    loading={loading}
                    mutable={mutable}
                    onCancel={onCancel}
                    onFinish={onFinish}
                    selectedRowKeys={selectedRowKeys}
                    validateAuthorizable={validateOnAuthorize}
                    callback={(estados_globales) => {
                      const [key] = selectedRowKeys;
                      const normalized = [...data]
                        .map((e) => (e.id === key ? { ...e, estados_globales } : e));
                      setMutable(false);
                      setData(normalized);
                      const match = normalized.find((e) => e.id === key);
                      form.setFieldsValue(match);
                      onClickEdit(match);
                    }}
                  />
                )}
                bordered={false}
              >
                <Tabs
                  type="card"
                  onChange={mutable ? onChangeTabKey : setCurrentTabKey}
                  activeKey={currentTabKey}
                >
                  <TabPane
                    key="general"
                    tab="General"
                  >
                    <Form
                      layout="vertical"
                      form={form}
                      onFinish={(values) => onFinish(values)}
                      initialValues={{
                        estados_globales: 1,
                        depositado: false,
                        tipo_de_caja: 3,
                      }}
                      scrollToFirstError
                      name="general"
                    >
                      <Row gutter={10}>
                        <Col xs={24} sm={24} md={8}>
                          <Form.Item
                            name="folio_presupuestal"
                            label="Folio presupuestal"
                          >
                            <Input allowClear disabled={!mutable} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                          <Form.Item
                            name="folio_presupuestal_contable"
                            label={(
                              <FormItemLabel
                                longTitle="Folio presupuestal contable"
                                shortTitle="Folio PC"
                              />
                            )}
                          >
                            <Input allowClear disabled={!mutable} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                          <Form.Item
                            name="fecha_de_deposito"
                            label="Fecha de Ingreso"
                          >
                            <DatePicker placeholder="" format="DD/MM/YYYY" disabled={!mutable} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                          <Form.Item
                            name="descripcion"
                            rules={rules.required}
                            label="Descripción"
                          >
                            <Input allowClear disabled={!mutable} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                          <Form.Item
                            name="tipo_de_caja"
                            rules={rules.required}
                            label="Tipo de caja"
                          >
                            <Select
                              dataSource={tiposDeCaja}
                              disabled={!mutable}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                          <Form.Item
                            name="fecha_de_captura"
                            rules={rules.required}
                            label="Fecha de captura"
                          >
                            <DatePicker placeholder="" format="DD/MM/YYYY" disabled={!mutable} />
                          </Form.Item>
                        </Col>
                        <InputSearchPro
                          label={collapsedSidebar ? 'Unidad responsable' : 'UR'}
                          tooltip="Búsqueda por clave o descripción"
                          name="unidad_responsable_id"
                          rules={rules.required}
                          onPressEnter={() => busquedaUR()}
                          prefix={{
                            style: {
                              marginTop: 'auto',
                              width: '45%',
                              minWidth: 180,
                            },
                            name: 'tipo_de_unidad_responsable',
                            label: 'Nivel',
                            render: (e) => e.model,
                            dataSource: urs,
                            disabled: !mutable,
                          }}
                          breakPoints={{
                            xs: 24,
                            sm: 24,
                            md: 16,
                          }}
                          inputProps={{
                            onPressEnter: () => busquedaUR(),
                            onChange: ({ target: { value } }) => {
                              if (!value) {
                                resetAndValidateURs();
                              }
                            },
                            disabled: !mutable,
                          }}
                          resultProps={{
                            name: 'unidad_responsable_id',
                            label: 'Unidad responsable',
                            dataSource: ursFound,
                            rules: rules.required,
                            keyProp: 'clave',
                            labelProp: 'descripcion',
                            keyLabelRender: true,
                            onClear: resetAndValidateURs,
                            disabled: !mutable,
                            breakPoints: {
                              xs: 24,
                              sm: 24,
                              md: 16,
                            },
                          }}
                        />
                        <Col xs={24} sm={24} md={8}>
                          <Form.Item
                            name="tipo_de_documento"
                            rules={rules.required}
                            label={collapsedSidebar ? 'Tipo de documento' : 'Tipo'}
                            tooltip={!collapsedSidebar ? 'Tipo de documento' : null}
                          >
                            <Select
                              dataSource={tiposDeDocumentos}
                              disabled={!mutable}
                            />
                          </Form.Item>
                        </Col>
                        <InputSearchPro
                          label="Cajero"
                          tooltip="Búsqueda de cajero por correo"
                          name="q_cajero"
                          rules={rules.required}
                          onPressEnter={() => busquedaCajero()}
                          disabled={!mutable}
                          breakPoints={{
                            xs: 24,
                            sm: 24,
                            md: 16,
                          }}
                          inputProps={{
                            onPressEnter: () => busquedaCajero(),
                            onChange: ({ target: { value } }) => {
                              if (!value) {
                                resetAndValidateCajero();
                              }
                            },
                            disabled: !mutable,
                          }}
                          resultProps={{
                            name: 'usuario_id',
                            label: 'Cajero',
                            dataSource: cajeroFound,
                            rules: rules.required,
                            labelProp: 'descripcion',
                            onClear: resetAndValidateCajero,
                            disabled: !mutable,
                            breakPoints: {
                              xs: 24,
                              sm: 24,
                              md: 16,
                            },
                          }}
                        />
                        <Col xs={24} sm={24} md={8}>
                          <Form.Item
                            name="numero_de_documento"
                            rules={rules.required}
                            label={collapsedSidebar ? 'Número de documento' : 'No. Doc.'}
                            tooltip={!collapsedSidebar ? 'Número de documento' : null}
                          >
                            <Input allowClear disabled={!mutable} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                          <Form.Item
                            name="estados_globales"
                            label="Estado"
                          >
                            <Select
                              dataSource={estadosGlobales}
                              disabled={!selectedRowKeys.length || !mutable}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Form.Item hidden>
                        <Button htmlType="submit" />
                      </Form.Item>
                    </Form>
                  </TabPane>
                  <TabPane
                    key="cuentasBancarias"
                    tab="Datos Bancarios"
                    forceRender
                  >
                    <Form
                      layout="vertical"
                      name="cuentasBancarias"
                      form={formDatosBancarios}
                      onFinish={(values) => onFinish(values)}
                    >
                      <Row gutter={10}>
                        <Col xs={24} sm={24} md={8}>
                          <Form.Item
                            name="banco"
                            rules={rules.conditional_required}
                            label="Banco"
                          >
                            <Select
                              dataSource={bancos}
                              labelProp="nombre"
                              onChange={(val) => {
                                setBanco(val);
                                formDatosBancarios.resetFields(['cuenta_bancaria']);
                              }}
                              disabled={!mutable}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                          <Form.Item
                            name="cuenta_bancaria"
                            rules={rules.conditional_required}
                            label="Cuenta bancaria"
                          >
                            <Select
                              dataSource={cuentasBancarias.filter((e) => e?.banco === banco)}
                              labelProp="numero_de_cuenta"
                              disabled={!mutable}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                          <Form.Item
                            name="fecha_de_deposito"
                            rules={rules.required}
                            label="Fecha de depósito"
                          >
                            <DatePicker
                              placeholder=""
                              format="DD/MM/YYYY"
                              disabled={!mutable}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                          <NumericInput
                            decimal
                            name="total_depositado"
                            rules={rules.required}
                            label="Total depositado"
                            disabled={!mutable}
                          />
                        </Col>
                      </Row>
                      <Form.Item hidden>
                        <Button htmlType="submit" />
                      </Form.Item>
                    </Form>
                  </TabPane>
                  <TabPane
                    tab="Información de Caja"
                    key="cajaInfo"
                    forceRender
                  >
                    <Form
                      layout="vertical"
                      name="cajaInfo"
                      form={formCajaInfo}
                      onFinish={(values) => onFinish(values)}
                    >
                      <Row gutter={10}>
                        <Col xs={24} sm={24} md={8}>
                          <Form.Item
                            name="corte_id"
                            rules={rules.required}
                            label="Corte ID"
                          >
                            <Input allowClear disabled={!mutable} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                          <Form.Item
                            name="depositado"
                            rules={rules.required}
                            label="Tipo de depósito"
                          >
                            <Select
                              dataSource={tiposDeDepositos}
                              onChange={setTipoDeDeposito}
                              disabled={!mutable}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                          <Form.Item
                            name="deposito_pendiente"
                            rules={rules.required}
                            label="¿Depósito pendiente?"
                          >
                            <Select trueFalse disabled={!mutable} />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={8}>
                          <Form.Item
                            name="faltante"
                            rules={rules.required}
                            label="Faltante"
                          >
                            <Input allowClear disabled={!mutable} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                          <Form.Item
                            name="importe_del_corte"
                            rules={rules.required}
                            label="Importe del corte"
                          >
                            <Input allowClear disabled={!mutable} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                          <Form.Item
                            name="sobrante"
                            rules={rules.required}
                            label="Sobrante"
                          >
                            <Input allowClear disabled={!mutable} />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Form.Item hidden>
                        <Button htmlType="submit" />
                      </Form.Item>
                    </Form>
                  </TabPane>
                  <TabPane
                    tab="Detalle"
                    key="detalle"
                    disabled={!selectedRowKeys.length}
                    forceRender={!!selectedRowKeys.length}
                  >
                    <DesgloseDeConceptosDeIngreso
                      ingreso_recaudado={data.find((e) => e.id === selectedRowKeys[0])}
                      conceptosDeIngreso={conceptosDeIngreso}
                      disabled={!mutable}
                      // contentTypeUR={contentType}
                      unidadResponsable={ursFound}
                      setCurrentTabKey={setCurrentTabKey}
                    />
                  </TabPane>
                </Tabs>
              </Card>
            </Col>
          )}
          <ModalDelete
            onDelete={deleteItem}
            onCancel={() => setVisibleAlert(false)}
            visible={visibleAlert}
            content={`el ingreso ${form.getFieldValue('descripcion')}`}
          />
        </Spin>
      </Col>
    </Row>
  );
}
IngresosRecaudados.propTypes = {
  polizaParent: PropTypes.number,
};
IngresosRecaudados.defaultProps = {
  polizaParent: null,
};

export default IngresosRecaudados;
