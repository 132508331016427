import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Form,
  Input,
  Button,
  Spin,
  Row,
  Col,
  Modal,
  Grid,
} from 'antd';

import API from 'utils/api';
import { onError } from 'utils/handlers';
import FormSubmitControls from '../FormSubmitControls';
import Table from '../Table';
import ModalDelete from '../ModalDelete';
import LadaNumero from '../LadaNumero';
import Select from '../Select';

const baseURI = 'configuraciones/contactos/';

function Contactos({
  currentURI,
  selectedRowKey,
}) {
  const screens = Grid.useBreakpoint();
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      setLoading(true);
      if (selectedRowKey) {
        const resCatalogo = await API.get(`${currentURI}/${selectedRowKey}/`);
        if (resCatalogo && resCatalogo.status === 200) {
          const { contactos } = resCatalogo.data;
          if (contactos?.length) {
            if (typeof contactos[0] === 'number') {
              const promises = [];
              contactos.forEach((id) => {
                const res = API.get(`${baseURI}${id}/`);
                promises.push(res);
              });
              const responses = promises.length ? await Promise.all(promises) : [];
              const datos = responses.map((res) => res.data);
              setData(datos);
            } else {
              setData(contactos);
            }
          }
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchData();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, [selectedRowKey]);

  const onCancel = () => {
    form.resetFields();
    setVisible(false);
  };

  const onFinish = async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue();
      if (selectedRowKey) {
        if (!selectedRowKeys.length) {
          const res = await API.post(baseURI, values);
          if (res?.status === 201) {
            const keys = data.map((item) => item.id);
            const resPatch = await API.patch(`${currentURI}/${selectedRowKey}/`, {
              contactos: [...keys, res.data.id],
              contacto: [...keys, res.data.id],
            });
            if (resPatch?.status === 200) {
              onCancel();
              await fetchData();
            }
          }
        } else {
          const [key] = selectedRowKeys;
          const res = await API.put(`${baseURI}${key}/`, values);
          if (res?.status === 200) {
            onCancel();
            await fetchData();
          }
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    form.setFieldsValue(record);
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const onClickEdit = () => {
    setVisible(true);
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const deleteItem = async () => {
    try {
      const [key] = selectedRowKeys;
      setLoading(true);
      if (selectedRowKey) {
        const response = await API.delete(`${baseURI}${key}/`);
        if (response?.status === 204) {
          setVisibleAlert(false);
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
    correo_electronico: [
      {
        type: 'email',
        message: 'Ingrese un correo electrónico válido',
      },
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
  };

  const columns = [
    {
      titleText: 'Nombre',
      key: 'nombre',
      dataIndex: 'nombre',
      width: 300,
      render: (_, values) => `${values.nombre} ${values.apellido_paterno || ''}`,
    },
    {
      titleText: 'Correo Electrónico',
      key: 'correo_electronico',
      dataIndex: 'correo_electronico',
      width: 250,
    },
    {
      titleText: 'Número de Teléfono',
      key: 'numero_de_telefono',
      dataIndex: 'numero_de_telefono',
      width: 200,
    },
    {
      titleText: 'Puesto',
      key: 'puesto',
      dataIndex: 'puesto',
      width: 150,
    },
    {
      titleText: 'Observaciones',
      key: 'observaciones',
      dataIndex: 'observaciones',
      width: 250,
    },
    {
      titleText: 'Estado',
      key: 'estados_globales',
      dataIndex: 'estados_globales',
      render: (val) => estadosGlobales.find((e) => e.id === val)?.descripcion,
      width: 150,
    },
  ];

  return (
    <Spin tip="Cargando..." spinning={loading}>
      <Table
        cols={columns}
        data={data}
        rowSelection={rowSelection}
        handleOnRowClick={handleOnRowClick}
        controls={{
          onClickAdd,
          onClickEdit,
          onClickDelete,
        }}
        allowSort={false}
        allowSearch={false}
      />
      <Modal
        visible={visible}
        closable={false}
        maskClosable={!loading}
        keyboard={!loading}
        footer={null}
        title={(
          <FormSubmitControls
            label={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Contacto`}
            onFinish={onFinish}
            onCancel={onCancel}
            loading={loading}
          />
        )}
        onCancel={onCancel}
        forceRender
        width={screens.md ? 600 : 400}
        destroyOnClose
      >
        <Form
          form={form}
          layout="vertical"
          name="contacto"
          onFinish={onFinish}
          initialValues={{ estados_globales: 1 }}
        >
          <Row gutter={10}>
            <Col xs={24} sm={24} md={12}>
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="nombre"
                    label="Nombre(s)"
                    rules={rules.required}
                  >
                    <Input allowClear />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="apellido_paterno"
                    label="Apellido Paterno"
                    rules={rules.required}
                  >
                    <Input allowClear />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="apellido_materno"
                    label="Apellido Materno"
                  >
                    <Input allowClear />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="puesto"
                    label="Puesto"
                    rules={rules.required}
                    hasFeedback
                  >
                    <Input allowClear />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="correo_electronico"
                    label="Correo Electrónico"
                    rules={rules.correo_electronico}
                  >
                    <Input allowClear />
                  </Form.Item>
                </Col>
                <LadaNumero
                  form={form}
                  fullSize
                  required
                />
                <LadaNumero
                  form={form}
                  names={{
                    numero: 'numero_fijo',
                  }}
                  fullSize
                  extension
                />
                <Col span={24}>
                  <Form.Item
                    name="estados_globales"
                    label="Estado"
                    rules={rules.estado}
                  >
                    <Select disabled={!selectedRowKeys.length} dataSource={estadosGlobales} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Form.Item
                name="observaciones"
                label="Observaciones"
                hasFeedback
              >
                <Input.TextArea autoSize={{ minRows: 2, maxRows: 3 }} maxLength={250} allowClear />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item hidden>
            <Button htmlType="submit" />
          </Form.Item>
        </Form>
      </Modal>
      <ModalDelete
        onDelete={deleteItem}
        onCancel={() => setVisibleAlert(false)}
        visible={visibleAlert}
        content={`Contacto ${form.getFieldValue('nombre')}`}
        loading={loading}
      />
    </Spin>
  );
}

Contactos.propTypes = {
  selectedRowKey: PropTypes.number,
  currentURI: PropTypes.string.isRequired,
};

Contactos.defaultProps = {
  selectedRowKey: undefined,
};

export default Contactos;
