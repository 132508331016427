import API from 'utils/api';
import { onSuccess, onError } from 'utils/handlers';

export const baseURI = 'configuraciones/servicios/';

/**
 * GET Servicios
 * @returns {Promise<Array<Object>>}
 */
export const getServicios = async () => {
  try {
    const response = await API.get(baseURI);
    return response.data;
  } catch (err) {
    onError(err);
    return [];
  }
};

/**
 * GET Servicio
 * @param {number} id
 * @returns {Promise<object|null>}
 */
export const getServicio = async (id) => {
  try {
    const response = await API.get(`${baseURI}${id}`);
    return response.data;
  } catch (err) {
    onError(err);
    return null;
  }
};

/**
 * POST Servicio
 * @param {object} values
 * @returns {Promise<object|boolean>}
 */
export const postServicio = async (values) => {
  try {
    const response = await API.post(baseURI, values);
    onSuccess(response, 'Agregado correctamente');
    return response.data;
  } catch (err) {
    onError(err);
    return false;
  }
};

/**
 * PATCH Servicio
 * @param {number} id
 * @param {object} values
 * @returns {Promise<object|boolean>}
 */
export const patchServicio = async (id, values) => {
  try {
    const response = await API.patch(`${baseURI}${id}`, values);
    onSuccess(response, 'Editado correctamente');
    return response.data;
  } catch (err) {
    onError(err);
    return false;
  }
};

/**
 * DELETE Servicio
 * @param {number} id
 * @returns {Promise<boolean>}
 */
export const deleteServicio = async (id) => {
  try {
    const response = await API.delete(`${baseURI}${id}`);
    onSuccess(response, 'Eliminado correctamente');
    return true;
  } catch (err) {
    onError(err);
    return false;
  }
};
