import React from 'react';
import SingleForm from 'components/SingleForm';
import {
  getMovimientoDePoliza,
  postMovimientoDePoliza,
  patchMovimientoDePoliza,
  deleteMovimientoDePoliza,
} from 'api/configuracion/movimientoDePoliza';

export const permissionMovimientoDePoliza = {
  permissionModel: 'movimientodepoliza',
};

// eslint-disable-next-line react/prop-types
function MovimientoDePoliza({ permission }) {
  return (
    <SingleForm
      title="Movimiento de poliza"
      get={getMovimientoDePoliza}
      post={postMovimientoDePoliza}
      patch={patchMovimientoDePoliza}
      delete={deleteMovimientoDePoliza}
      permission={permission}
    />
  );
}
export default MovimientoDePoliza;
