import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Spin,
  Card,
  Modal,
  message,
} from 'antd';

import API from 'utils/api';
import FormSubmitControls from 'components/FormSubmitControls';
import Table from 'components/Table';
import ModalDelete from 'components/ModalDelete';
import Select from 'components/Select';
import fetchSome from 'utils/fetchSome';
import { SET_CONFIG_MASCARA_CONTABLE } from 'store/reducers/catalogs';
import { InputSearchPro } from 'components/InputSearch';
import { onError, onSuccess } from 'utils/handlers';
import NumericInput from 'components/NumericInput';
import BusquedaClavesContables from 'components/BusquedaClavesContables';

export const permissionImpuesto = {
  permissionModel: 'impuesto',
};

const baseURI = '/configuraciones/catalogos-de-impuestos/';
// eslint-disable-next-line react/prop-types
function Impuestos({ permission }) {
  const [form] = Form.useForm();
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  const configMascaraContable = useSelector(({ catalogs }) => catalogs.configMascaraContable);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);
  const [cuentaContableFound, setCuentaContableFound] = useState([]);
  const [selectedClave, setSelectedClave] = useState();
  const [mascara, setMascara] = useState();
  const [separador, setSeparador] = useState();
  const [pattern, setPattern] = useState();
  const [visibleBusquedaCuentas, setVisibleBusquedaCuentas] = useState(false);

  const fetchData = async () => {
    try {
      const res = await API.get(baseURI);
      if (res?.status === 200) {
        setData(res.data);
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const fetchAll = async () => {
    try {
      if (configMascaraContable) {
        setMascara(configMascaraContable?.mascara);
        const separator = configMascaraContable?.separador;
        setSeparador(separator);
        const _segmentos = configMascaraContable?.mascara?.split(separator) || [];
        if (_segmentos?.length) {
          // eslint-disable-next-line prefer-template
          const patt = _segmentos.reduce((acum, curr, idx) => `${acum}[0-9]{${curr.length}}`
            + (idx !== _segmentos.length - 1 ? separator : '$'), '^');
          const regExp = new RegExp(`${patt}`);
          setPattern(regExp);
        }
      } else {
        await fetchSome('configuraciones/configuraciones-de-mascaras/', SET_CONFIG_MASCARA_CONTABLE);
      }
      await fetchData();
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const fetchCuentaContable = async () => {
    try {
      form.validateFields(['clave_contable__clave']);
      const clave = form.getFieldValue('clave_contable__clave');
      if (clave) {
        setLoading(true);
        const res = await API.get('contabilidad/cuentas-contables/', {
          params: {
            clave_contable: clave,
            tipo_de_cuenta_contable: 7,
          },
        });
        if (res?.status === 200 && res.data?.length) {
          const claveContable = res.data[0];
          setCuentaContableFound([claveContable]);
          form.setFieldsValue({
            clave_contable: claveContable.id,
          });
          setTimeout(() => {
            form.validateFields(['clave_contable']);
          });
        } else {
          message.info('No se encontraron coincidencias');
          setCuentaContableFound([]);
        }
        setLoading(false);
      }
    } catch (err) {
      onError(err, setLoading);
      setCuentaContableFound([]);
    }
  };

  useEffect(() => {
    fetchAll();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    setSelectedRowKeys([]);
  };

  const resetAndValidateCuentaContable = () => {
    setCuentaContableFound([]);
    form.resetFields(['clave_contable']);
    form.validateFields(['clave_contable']);
  };

  const onChangeClaveContable = (e) => {
    const { target: { value }, keyCode } = e;
    let val = value;
    if (keyCode) {
      const char = String.fromCharCode(keyCode).toLowerCase();
      val += char;
    }
    if (mascara.charAt(val.length) === separador) {
      val += separador;
    }
    if (val.length === mascara?.length) {
      setTimeout(fetchCuentaContable);
    } else if (!val?.length) {
      resetAndValidateCuentaContable();
    }
    setTimeout(() => {
      form.setFieldsValue({ clave_contable__clave: val });
    });
  };

  const onFinishBusqueda = () => {
    if (selectedClave) {
      setCuentaContableFound([selectedClave]);
      setVisibleBusquedaCuentas(false);
    } else {
      message.info('No ha seleccionado una Cuenta Contable');
    }
  };
  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      delete values.clave_contable__clave;
      if (!selectedRowKeys.length) {
        const response = await API.post(baseURI, values);
        if (response?.status === 201) {
          onSuccess(response, 'Agregado correctamente');
          onCancel();
          await fetchData();
        }
      } else {
        const [key] = selectedRowKeys;
        await form.validateFields();
        const response = await API.put(`${baseURI}${key}/`, values);
        if (response?.status === 200) {
          onSuccess(response, 'Actualizado correctamente');
          form.resetFields();
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const deleteItem = async () => {
    try {
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`${baseURI}${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          onCancel();
          await fetchData();
          setVisibleAlert(false);
        }
      }
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    form.setFieldsValue(record);
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickAdd = () => {
    setVisible(true);
    setSelectedRowKeys([]);
    form.resetFields();
  };

  const onClickEdit = () => {
    setVisible(true);
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const required = {
    required: true,
    message: 'El campo es requerido',
  };

  const rules = {
    required: [
      required,
    ],
    clave_contable__clave: [
      {
        validator: async (rule, value) => {
          if (value && pattern && (!pattern.test(value))) {
            throw new Error('Introduzca una clave válida');
          }
        },
      },
    ],
    numero_de_cuenta: [
      required,
      {
        validator: async (rule, val) => {
          const value = val?.toString();
          if (value?.length) {
            if (value.length < 16) {
              throw new Error('El campo debe tener una longitud de 16 dígitos');
            }
          }
        },
      },
    ],
  };

  const columns = [
    {
      titleText: 'Concepto',
      dataIndex: 'concepto',
      key: 'concepto',
      width: 200,
    },
    {
      titleText: 'Descripción',
      dataIndex: 'descripcion',
      key: 'descripcion',
      width: 200,
    },
    {
      titleText: 'Estado',
      dataIndex: 'estados_globales',
      key: 'estados_globales',
      width: 75,
      render: (val) => estadosGlobales.find((e) => e.id === val)?.descripcion,

    },

  ];

  return (
    <Row align="center" justify="center" className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {visible ? (
          <Card
            className="form-container"
            title={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Impuesto`}
            extra={(
              <FormSubmitControls onFinish={onFinish} onCancel={onCancel} />
            )}
            bordered={false}
          >
            <Form
              layout="vertical"
              form={form}
              onFinish={onFinish}
              scrollToFirstError
              initialValues={{ estados_globales: 1 }}
            >
              <Row gutter={10}>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="concepto"
                    label="Concepto"
                    rules={rules.required}
                    hasFeedback
                  >
                    <Input allowClear />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="descripcion"
                    label="Descripción"
                    rules={rules.required}
                    hasFeedback
                  >
                    <Input allowClear />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <NumericInput
                    name="tasa"
                    label="Tasa"
                    decimal
                    required
                  />
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    label="Estado"
                    name="estados_globales"
                  >
                    <Select dataSource={estadosGlobales} disabled />
                  </Form.Item>
                </Col>
                <InputSearchPro
                  name="clave_contable__clave"
                  onClickAdvanced={() => setVisibleBusquedaCuentas(true)}
                  rules={rules.clave_contable__clave}
                  label="Búsqueda Cuenta Contable"
                  tooltip={`Debe concordar con la mascara ${mascara}`}
                  inputProps={{
                    onPressEnter: () => fetchCuentaContable(),
                    onChange: onChangeClaveContable,
                    maxLength: mascara?.length,
                  }}
                  breakPoints={{
                    md: 16,
                    lg: 16,
                    xl: 8,
                  }}
                  resultProps={{
                    name: 'clave_contable',
                    label: 'Cuenta Contable',
                    rules: rules.required,
                    dataSource: cuentaContableFound,
                    keyLabelRender: true,
                    keyProp: 'clave_contable',
                    labelProp: 'nombre',
                    onClear: resetAndValidateCuentaContable,
                  }}
                />
                <Form.Item hidden>
                  <Button htmlType="submit" />
                </Form.Item>
              </Row>
            </Form>
          </Card>

        ) : (
          <Table
            cols={columns}
            permission={permission}
            data={data}
            rowSelection={rowSelection}
            handleOnRowClick={handleOnRowClick}
            controls={{
              onClickAdd,
              onClickEdit,
              onClickDelete,
            }}
            rowKey="id"
            mobileColIndex={0}
          />
        )}
        <Modal
          visible={visibleBusquedaCuentas}
          title={(
            <Row justify="space-between">
              Búsqueda Avanzada Cuentas
              <FormSubmitControls
                onCancel={() => setVisibleBusquedaCuentas(false)}
                onFinish={onFinishBusqueda}
              />
            </Row>
          )}
          onCancel={() => setVisibleBusquedaCuentas(false)}
          closable={false}
          footer={null}
          width={800}
          forceRender
          centered
        >
          <BusquedaClavesContables callback={setSelectedClave} />
        </Modal>
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => {
            setVisibleAlert(false);
            setSelectedRowKeys([]);
          }}
          visible={visibleAlert}
          content={`Impuesto ${form.getFieldValue('descripcion')}`}
        />
      </Spin>
    </Row>
  );
}

export default Impuestos;
