import React from 'react';
import SingleForm from 'components/SingleForm';
import {
  getActivarUsuarios,
  postActivarUsuarios,
  patchActivarUsuarios,
  deleteActivarUsuarios,
} from 'api/usuarios/activarUsuarios';

export const permissionActivarUsuarios = {
  permissionModel: 'usuario',
};

// eslint-disable-next-line react/prop-types
function ActivarUsuarios({ permission }) {
  return (
    <SingleForm
      title="Activar Usuarios"
      get={getActivarUsuarios}
      post={postActivarUsuarios}
      patch={patchActivarUsuarios}
      delete={deleteActivarUsuarios}
      permission={permission}
    />
  );
}
export default ActivarUsuarios;
