/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { GrDocumentConfig } from 'react-icons/gr';
import { FaUserTie } from 'react-icons/fa';
import { GiDesk } from 'react-icons/gi';
import {
  AiOutlineUnorderedList,
  AiOutlineBlock,
  AiOutlineTeam,
  AiOutlineUsergroupAdd,
  AiOutlineSetting,
  AiOutlineKey,
  AiOutlineLock,
  AiOutlineAudit,
  AiOutlineDollarCircle,
} from 'react-icons/ai';

// Componentes de las rutas
import Entidad, { permissionEntidad } from 'views/Configuracion/Entidad';
import TiposDeRetencion from 'components/Catalogos/TiposDeRentencion';
// import CatalogoDeModulos from 'views/Configuracion/Contabilidad/CatalogoDeModulos';
// import CatalogoDeDocumentos from 'views/Configuracion/Contabilidad/CatalogoDeDocumentos';
import ConfigMomentosPresupuestales, { permissionConfiguracionMomentoPresupuestal } from 'views/Configuracion/Presupuestos/ConfigMomentosPresupuestales';
import ConceptosCargosBancarios from 'views/Configuracion/Catalogos/Tesoreria/ConceptosCargosBancarios';
import MomentosPresupuestalesDeEgresos, { permissionMomentoPresupuestalDeEgreso } from 'views/Configuracion/Presupuestos/MomentosPresupuestales/Egresos';
import MomentosPresupuestalesDeIngresos, { permissionMomentoPresupuestalDeIngreso } from 'views/Configuracion/Presupuestos/MomentosPresupuestales/Ingresos';
import Empleados, { permissionEmpleado } from 'views/Configuracion/Accesos/Empleados';
import Impuestos, { permissionImpuesto } from 'views/Configuracion/Tesoreria/Impuestos';
import Puestos, { permissionPuestos } from 'views/Configuracion/Accesos/Puestos';
import Usuarios, { permissionUsuarios } from 'views/Configuracion/Accesos/Usuarios';
import Funciones, { permissionFunciones } from 'views/Configuracion/Accesos/Funciones';
import Justificaciones, { permissionJustificaciones } from 'views/Configuracion/Accesos/Justificaciones';
import Roles, { permissionRoles } from 'views/Configuracion/Accesos/Roles';
import RolesAUsuarios from 'views/Configuracion/Accesos/RolesAUsuarios';
import Administracion from 'views/Configuracion/Administracion';
import TiposPolizas, { permissionTipoPoliza } from 'views/Configuracion/Contabilidad/TiposPolizas';
// import OrigenesPolizas from 'views/Configuracion/Contabilidad/OrigenesPolizas';
// import AutorizacionPolizas from 'views/Configuracion/Contabilidad/AutorizacionPolizas';
// import FirmasPolizas from 'views/Configuracion/Contabilidad/FirmasPolizas';
import UnidadesResponsables, {
  permissionMomentoUnidadOperativa,
  permissionMomentoUnidadResponsable,
  permissionMomentoCentroDeCostos,
} from 'views/Configuracion/Presupuestos/UnidadesResponsables/index';
import ActivarUsuarios, { permissionActivarUsuarios } from 'views/Configuracion/Accesos/ActivarUsuarios';
import ConfiguracionDeSegmentos, { permissionConfigSegmentoPresupuestal } from 'views/Configuracion/Presupuestos/ConfiguracionDeSegmentos/index';
import SegmentosContables, { permissionConfigSegmentoContable } from 'views/Configuracion/Contabilidad/SegmentosContables';
import DirectorioURs from 'views/Configuracion/Accesos/DirectorioURs';
import Foliador, { permissionFoliador } from 'views/Configuracion/Foliador/index';
import Naturalezas from 'views/Configuracion/Naturalezas';
import TipoDeCuentaContable, { permissionTipoCuentaContable } from 'views/Configuracion/Contabilidad/TipoDeCuentaContable';
import FinalidadDeLaCuentaBancaria from 'views/Configuracion/Contabilidad/FinalidadDeLaCuentaBancaria';
import TipoDeFirmaDeCuentaBancaria, { permissionTipoCuentaBancaria } from 'views/Configuracion/Contabilidad/TipoDeFirmaDeCuentaBancaria';
import ClasificacionProgramatica, { permissionClasificacionProgramatica } from 'views/Contabilidad/ClasificacionProgramatica';
import ClasificacionAdministrativa, { permissionClasificacionAdministrativa } from 'views/Contabilidad/ClasificacionAdministrativa';
import ClasificacionFuncional, { permissionClasificacionFuncional } from 'views/Contabilidad/ClasificacionFuncional';
import TipoDeGasto from 'views/Contabilidad/ClasificacionTipoGasto';
import FormasDePago, { permissionFormaDePago } from 'views/Configuracion/Tesoreria/FormasDePago';
import ConfiguracionDeEncabezados, { permissionEncabezadoDocumento } from 'views/Configuracion/Otros/ConfiguracionDeEncabezados';
import ConfiguracionDePie, { permissionPieDocumento } from 'views/Configuracion/Otros/ConfiguracionDePie';
import MotivoDeBaja, { permissionMotivoDeBaja } from 'views/Configuracion/Catalogos/ControlPatrimonial/MotivosDeBaja';
import DocumentoParaBaja, { permissionDocumentoDeBaja } from 'views/Configuracion/Catalogos/ControlPatrimonial/DocumentosParaBaja';
import Origen, { permissionOrigen } from 'views/Configuracion/Catalogos/ObraPublica/Origen';
import EstadoDeLaAsignacion, { permissionEstadoDeLaAsignacion } from 'views/Configuracion/Catalogos/ControlPatrimonial/EstadoDeLaAsignacion';
import MotivoDeTraslados, { permissionModalidadTraslado } from 'views/Configuracion/Catalogos/ObraPublica/MotivoDeTraslados';
import EstadosDelBien, { permissionEstadoDelBien } from 'views/Configuracion/Catalogos/ControlPatrimonial/EstadosDelBien';
import TiposDeUso, { permissionTipoDeUso } from 'views/Configuracion/Catalogos/ControlPatrimonial/TiposDeUso';
import TiposDeAdquisicion from 'views/Configuracion/Catalogos/ControlPatrimonial/TiposDeAdquisicion';
import TiposDeResguardo, { permissionTipoDeResguardo } from 'views/Configuracion/Catalogos/ControlPatrimonial/TiposDeResguardo';
import FolioDeDocumentosOperativos, { permissionFoliadorDocumento } from 'views/Configuracion/Otros/FolioDeDocumentosOperativos';
import ConfiguracionesDePresupuestosDeIngreso, { permissionConfiguracionPresupuestalIngreso } from 'views/Configuracion/Presupuestos/ConfiguracionesDePresupuestosDeIngreso';
import ConfiguracionDePasos, { permissionConfiguracionDeFlujo } from 'views/Configuracion/ConfiguracionDePasos/index';
import GirosEmpresariales, { permissionGiroEmpresarial } from 'views/Configuracion/Catalogos/GirosEmpresariales';
import RegimenEmpresarial, { permissionRegimenEmpresarial } from 'views/Configuracion/Catalogos/RegimenEmpresariales';
import CatalogoPBR from 'views/Configuracion/Catalogos/CatalogoPBR';
import ConfiguracionesDePolizas, { permissionConfiguracionDePoliza } from 'views/Configuracion/Contabilidad/ConfiguracionesDePolizas';
import CuentaContable, { permissionCuentaContable } from 'views/Configuracion/Contabilidad/CuentaContable';
import MovimientoDePoliza, { permissionMovimientoDePoliza } from 'views/Configuracion/Contabilidad/MovimientoDePoliza';
import PeriodoFiscal, { permissionPeriodoFiscal } from 'views/Configuracion/Otros/PeriodoFiscal';
import ConfiguradorActivosFijos, { permissionConfiguradorActivoFijo } from 'views/Configuracion/Catalogos/ControlPatrimonial/ConfiguradorDeActivoFijo';
import ClasificacionesDeActivos, { permissionCatalogoDeClasificacionDeActivo } from 'views/Configuracion/Catalogos/ControlPatrimonial/ClasificacionesDeActivos';
import CatalogoTipoInversion from 'views/Configuracion/Catalogos/TiposDeInversion/TiposInversion';
import ConceptosSolicitudPago from 'views/Configuracion/Catalogos/Tesoreria/ConceptosSolicitudPago';
import CatalogoOtrosDepositos from 'views/Configuracion/Catalogos/OtrosDepositos/OtrosDepositos';
import FinalidadGasto from 'views/Configuracion/Catalogos/Tesoreria/FinalidadGasto';
import ConceptoPenalizaciones from 'views/Configuracion/Catalogos/Tesoreria/ConceptosPenalizaciones';
import CatalogoPlazoInversion from 'views/Configuracion/Catalogos/PlazosDeInversion/PlazoInversion';
// import Productos from '../views/Configuracion/PresupuestoAnual/Productos';

export default {
  title: 'Configuración',
  icon: () => <AiOutlineSetting />,
  showInSidebar: true,
  children: [
    {
      title: 'Entidad Municipal',
      icon: null,
      showInSidebar: true,
      component: Entidad,
      type: 'private',
      ...permissionEntidad,
    },
    {
      title: 'Configuración de Pasos de Flujo',
      icon: null,
      showInSidebar: true,
      component: ConfiguracionDePasos,
      type: 'private',
      ...permissionConfiguracionDeFlujo,
      catalogsModels: [
        'catalogodemodulo',
        'catalogodedocumento',
        'configuraciondefolio',
        'actividad',
        'group',
        'usuario',
        'unidadoperativa',
        'unidadresponsable',
        'centrodecostos',
      ],
    },
    {
      title: 'Contabilidad',
      icon: null,
      showInSidebar: true,
      type: 'private',
      children: [
        {
          title: 'Configuraciones de Pólizas',
          icon: null,
          component: ConfiguracionesDePolizas,
          type: 'private',
          showInSidebar: true,
          ...permissionConfiguracionDePoliza,
        },
        {
          title: 'Catálogo Maestro',
          icon: null,
          component: CuentaContable,
          type: 'private',
          showInSidebar: true,
          ...permissionCuentaContable,
        },
        {
          title: 'Movimiento de Pólizas',
          icon: null,
          component: MovimientoDePoliza,
          type: 'private',
          showInSidebar: true,
          ...permissionMovimientoDePoliza,
        },
        {
          title: 'Segmentos Contables',
          icon: null,
          showInSidebar: true,
          component: SegmentosContables,
          type: 'private',
          ...permissionConfigSegmentoContable,
          catalogsModels: [
            'configuraciondemascara',
            'cuentacontableconac',
          ],
        },
      ],
    },
    {
      title: 'Catálogos',
      showInSidebar: true,
      children: [
        {
          title: 'Contabilidad',
          icon: null,
          showInSidebar: true,
          type: 'private',
          children: [
            {
              title: 'Naturalezas',
              icon: null,
              showInSidebar: true,
              component: Naturalezas,
              type: 'private',
              permissionModel: 'naturalezadeingreso',
            },
            {
              title: 'Tipo de Cuenta Contable',
              icon: null,
              showInSidebar: true,
              component: TipoDeCuentaContable,
              type: 'private',
              ...permissionTipoCuentaContable,
            },
            {
              title: 'Tipos de Pólizas',
              icon: null,
              showInSidebar: true,
              component: TiposPolizas,
              type: 'private',
              ...permissionTipoPoliza,
            },
          ],
        },
        {
          title: 'Presupuesto Basado en Resultados',
          icon: null,
          showInSidebar: true,
          type: 'private',
          children: [
            {
              title: 'Frecuencia de Medición',
              icon: null,
              showInSidebar: true,
              component: null,
              type: 'private',
            },
            {
              title: 'Tipo de Dimensión',
              icon: null,
              showInSidebar: true,
              component: null,
              type: 'private',
            },
            {
              title: 'Tipo de Indicador',
              icon: null,
              showInSidebar: true,
              component: null,
              type: 'private',
            },
            {
              title: 'Elementos de la MIR',
              icon: null,
              showInSidebar: true,
              component: CatalogoPBR,
              type: 'private',
            },
          ],
        },
        {
          title: 'Prespuestos',
          icon: null,
          showInSidebar: true,
          type: 'private',
          children: [
            {
              title: 'Momentos Presupuestales',
              icon: null,
              showInSidebar: true,
              type: 'private',
              children: [
                {
                  title: 'Egresos',
                  icon: null,
                  showInSidebar: true,
                  component: MomentosPresupuestalesDeEgresos,
                  type: 'private',
                  ...permissionMomentoPresupuestalDeEgreso,
                },
                {
                  title: 'Ingresos',
                  icon: null,
                  showInSidebar: true,
                  component: MomentosPresupuestalesDeIngresos,
                  type: 'private',
                  ...permissionMomentoPresupuestalDeIngreso,
                },
              ],
            },
            {
              title: 'Clasificación Programática',
              icon: null,
              showInSidebar: true,
              component: ClasificacionProgramatica,
              type: 'private',
              ...permissionClasificacionProgramatica,
            },
            {
              title: 'Clasificación Administrativa',
              icon: null,
              showInSidebar: true,
              component: ClasificacionAdministrativa,
              type: 'private',
              ...permissionClasificacionAdministrativa,
            },
            {
              title: 'Clasificación Funcional',
              icon: null,
              showInSidebar: true,
              component: ClasificacionFuncional,
              type: 'private',
              ...permissionClasificacionFuncional,
            },
            {
              title: 'Clasificación Tipo de Gasto',
              icon: null,
              showInSidebar: true,
              component: TipoDeGasto,
              type: 'private',
              permissionModel: 'clasificadordetipodegasto',
            },
          ],
        },
        {
          title: 'Adquisiciones',
          icon: null,
          showInSidebar: true,
          type: 'private',
          children: [
            {
              title: 'Giros Empresariales',
              icon: null,
              showInSidebar: true,
              component: GirosEmpresariales,
              type: 'private',
              ...permissionGiroEmpresarial,
            },
            {
              title: 'Régimen de Contribuyentes',
              icon: null,
              showInSidebar: true,
              component: RegimenEmpresarial,
              type: 'private',
              ...permissionRegimenEmpresarial,
            },
            {
              title: 'Tipo de Adquisiciones',
              icon: null,
              showInSidebar: true,
              component: TiposDeAdquisicion,
              type: 'private',
            },
            {
              title: 'Tipo de Movimiento Corporativo',
              icon: null,
              showInSidebar: true,
              component: null,
              type: 'private',
            },
          ],
        },
        {
          title: 'Almacén',
          icon: null,
          showInSidebar: true,
          type: 'private',
          children: [
            {
              title: 'Tipo de Almacén',
              icon: null,
              showInSidebar: true,
              component: null,
              type: 'private',
            },
          ],
        },
        {
          title: 'Obra Pública',
          icon: null,
          showInSidebar: true,
          type: 'private',
          children: [
            {
              title: 'Origen de Recursos',
              icon: null,
              showInSidebar: true,
              component: Origen,
              type: 'private',
              ...permissionOrigen,
            },
            {
              title: 'Tipos de Fianzas',
              icon: null,
              showInSidebar: true,
              component: null,
              type: 'private',
            },
            {
              title: 'Tipos de Obras',
              icon: null,
              showInSidebar: true,
              component: null,
              type: 'private',
            },
            {
              title: 'Tipos de Anticipos',
              icon: null,
              showInSidebar: true,
              component: null,
              type: 'private',
            },
            // {
            //   title: 'Tipos de Fianzas',
            //   icon: null,
            //   showInSidebar: true,
            //   component: blank,
            //   type: 'private',
            // },
            // {
            //   title: 'Tipos de Obras',
            //   icon: null,
            //   showInSidebar: true,
            //   component: blank,
            //   type: 'private',
            // },
            // {
            //   title: 'Tipos de Anticipos',
            //   icon: null,
            //   showInSidebar: true,
            //   component: blank,
            //   type: 'private',
            // },
            {
              title: 'Modalidad de Asignacion',
              icon: null,
              showInSidebar: true,
              component: null,
              type: 'private',
            },
            {
              title: 'Tipos de Contratos',
              icon: null,
              showInSidebar: true,
              component: null,
              type: 'private',
            },
            {
              title: 'Tipo de Convenio',
              icon: null,
              showInSidebar: true,
              component: null,
              type: 'private',
            },
            // {
            //   title: 'Tipos de Contratos',
            //   icon: null,
            //   showInSidebar: true,
            //   component: blank,
            //   type: 'private',
            // },
          ],
        },
        {
          title: 'Tesorería',
          icon: AiOutlineDollarCircle,
          showInSidebar: true,
          type: 'private',
          children: [
            // {
            //   title: 'Finalidad Cuenta Bancaria',
            //   icon: null,
            //   showInSidebar: true,
            //   component: FinalidadDeLaCuentaBancaria,
            //   type: 'private',
            //   permissionModel: 'banco',
            // },
            {
              title: 'Tipo de Cuenta Bancaria',
              icon: null,
              showInSidebar: true,
              component: null,
              type: 'private',
            },
            {
              title: 'Finalidad Cuenta Bancaria',
              icon: null,
              showInSidebar: true,
              component: FinalidadDeLaCuentaBancaria,
              type: 'private',
              permissionModel: 'finalidaddelacuentabancaria',
            },
            {
              title: 'Tipo de Firma',
              icon: null,
              showInSidebar: true,
              component: TipoDeFirmaDeCuentaBancaria,
              type: 'private',
              ...permissionTipoCuentaBancaria,
            },
            {
              title: 'Métodos de Pago',
              icon: null,
              showInSidebar: true,
              component: FormasDePago,
              type: 'private',
              ...permissionFormaDePago,
            },
            {
              title: 'Impuestos',
              icon: null,
              showInSidebar: true,
              component: Impuestos,
              type: 'private',
              ...permissionImpuesto,
            },
            {
              title: 'Tipo de Retenciones',
              icon: null,
              showInSidebar: true,
              component: TiposDeRetencion,
              type: 'private',
              permissionModel: 'tipoderetencion',
            },
            {
              title: 'Tipo Beneficiarios',
              icon: null,
              showInSidebar: true,
              component: null,
              type: 'private',
            },
            {
              title: 'Catálogo Tipos de Inversión',
              icon: null,
              showInSidebar: true,
              component: CatalogoTipoInversion,
              type: 'private',
            },
            {
              title: 'Catálogo Plazos de Inversión',
              icon: null,
              showInSidebar: true,
              component: CatalogoPlazoInversion,
              type: 'private',
            },
            {
              title: 'Conceptos de Cargos Bancarios',
              icon: null,
              showInSidebar: true,
              component: ConceptosCargosBancarios,
              type: 'private',
            },
            {
              title: 'Conceptos de Solicitud de Pago',
              icon: null,
              showInSidebar: true,
              component: ConceptosSolicitudPago,
              type: 'private',
            },
            {
              title: 'Catálogo Otros Depósitos',
              icon: null,
              showInSidebar: true,
              component: CatalogoOtrosDepositos,
              type: 'private',
            },
            {
              title: 'Finalidad de Gasto',
              icon: null,
              showInSidebar: true,
              component: FinalidadGasto,
              type: 'private',
            },
            {
              title: 'Conceptos de Penalización',
              icon: null,
              showInSidebar: true,
              component: ConceptoPenalizaciones,
              type: 'private',
            },
          ],
        },
        {
          title: 'Control Patrimonial',
          icon: null,
          showInSidebar: true,
          type: 'private',
          children: [
            {
              title: 'Configurador de Activo Fijo',
              icon: null,
              showInSidebar: true,
              component: ConfiguradorActivosFijos,
              type: 'private',
              ...permissionConfiguradorActivoFijo,
            },
            {
              title: 'Tipo de Aquisicion',
              icon: null,
              showInSidebar: true,
              component: null,
              type: 'private',
            },
            {
              title: 'Tipo de Uso',
              icon: null,
              showInSidebar: true,
              component: TiposDeUso,
              type: 'private',
              ...permissionTipoDeUso,
            },
            {
              title: 'Motivo de Baja',
              icon: null,
              showInSidebar: true,
              component: MotivoDeBaja,
              type: 'private',
              ...permissionMotivoDeBaja,
            },
            {
              title: 'Documentos para Baja',
              icon: null,
              showInSidebar: true,
              component: DocumentoParaBaja,
              type: 'private',
              ...permissionDocumentoDeBaja,
            },
            {
              title: 'Estados de la Asignación',
              icon: null,
              showInSidebar: true,
              component: EstadoDeLaAsignacion,
              type: 'private',
              ...permissionEstadoDeLaAsignacion,
            },
            {
              title: 'Estados del Bien',
              icon: null,
              showInSidebar: true,
              component: EstadosDelBien,
              type: 'private',
              ...permissionEstadoDelBien,
            },
            {
              title: 'Motivos de Traslado',
              icon: null,
              showInSidebar: true,
              component: MotivoDeTraslados,
              type: 'private',
              ...permissionModalidadTraslado,
            },
            {
              title: 'Tipos de Resguardo',
              icon: null,
              showInSidebar: true,
              component: TiposDeResguardo,
              type: 'private',
              ...permissionTipoDeResguardo,
            },
            {
              title: 'Clasificaciones de Activos',
              icon: null,
              showInSidebar: true,
              component: ClasificacionesDeActivos,
              type: 'private',
              ...permissionCatalogoDeClasificacionDeActivo,
            },
          ],
        },
        {
          title: 'Accesos',
          icon: null,
          showInSidebar: true,
          type: 'private',
          children: [
            {
              title: 'Empleados',
              icon: () => <FaUserTie />,
              showInSidebar: true,
              component: Empleados,
              type: 'private',
              ...permissionEmpleado,
              catalogsModels: [
                'estadodelempleado',
                'genero',
                'estadocivil',
                'puesto',
                'POR_PREGUNTAR_PERMISO_URS_ANIDADAS',
                'formadepago',
              ],
            },
            {
              title: 'Puestos',
              icon: () => <GiDesk />,
              showInSidebar: true,
              component: Puestos,
              type: 'private',
              ...permissionPuestos,
            },
            {
              title: 'Roles',
              icon: null,
              showInSidebar: true,
              component: Roles,
              type: 'private',
              ...permissionRoles,
              catalogsModels: ['permission'],
            },
            {
              title: 'Funciones',
              icon: null,
              showInSidebar: true,
              component: Funciones,
              type: 'private',
              ...permissionFunciones,
            },
            {
              title: 'Justificaciones',
              icon: null,
              showInSidebar: true,
              component: Justificaciones,
              type: 'private',
              ...permissionJustificaciones,
            },
          ],
        },
        // {
        //   title: 'Orígenes de Pólizas',
        //   icon: null,
        //   showInSidebar: true,
        //   component: OrigenesPolizas,
        //   type: 'private',
        // },
        {
          title: 'Pólizas Contables',
          icon: null,
          showInSidebar: true,
          component: null,
          type: 'private',
        },
        {
          title: 'Giros Empresariales',
          icon: null,
          showInSidebar: true,
          component: GirosEmpresariales,
          type: 'private',
          ...permissionGiroEmpresarial,
        },
        {
          title: 'Regimen de Empresas',
          icon: null,
          showInSidebar: true,
          component: RegimenEmpresarial,
          type: 'private',
          ...permissionRegimenEmpresarial,
        },
        // {
        //   title: 'Campos Adicionales Pólizas',
        //   icon: null,
        //   showInSidebar: true,
        //   component: null,
        //   type: 'private',
        // },
        // {
        //   title: 'Autorización Pólizas',
        //   icon: null,
        //   showInSidebar: true,
        //   component: AutorizacionPolizas,
        //   type: 'private',
        // },
        // {
        //   title: 'Firmas Pólizas',
        //   icon: null,
        //   showInSidebar: true,
        //   component: FirmasPolizas,
        //   type: 'private',
        // },
        // {
        //   title: 'Módulos',
        //   icon: null,
        //   showInSidebar: true,
        //   component: CatalogoDeModulos,
        //   type: 'private',
        // },
        // {
        //   title: 'Documentos',
        //   icon: null,
        //   showInSidebar: true,
        //   component: CatalogoDeDocumentos,
        //   type: 'private',
        // },
      ],
    },
    {
      title: 'Presupuestos',
      icon: () => <AiOutlineAudit />,
      showInSidebar: true,
      children: [
        {
          title: 'Momentos Presupuestales',
          icon: null,
          showInSidebar: true,
          type: 'private',
          children: [
            {
              title: 'Egresos',
              icon: null,
              showInSidebar: true,
              component: MomentosPresupuestalesDeEgresos,
              type: 'private',
              ...permissionMomentoPresupuestalDeEgreso,
            },
            {
              title: 'Ingresos',
              icon: null,
              showInSidebar: true,
              component: MomentosPresupuestalesDeIngresos,
              type: 'private',
              ...permissionMomentoPresupuestalDeIngreso,
            },
          ],
        },
        {
          title: 'Segmentos Presupuestales',
          icon: null,
          showInSidebar: true,
          component: ConfiguracionDeSegmentos,
          type: 'private',
          ...permissionConfigSegmentoPresupuestal,
          catalogsModels: ['tipodepresupuesto'],
        },
        {
          title: 'Configuración de Presupuestos de Ingresos',
          icon: null,
          showInSidebar: true,
          component: ConfiguracionesDePresupuestosDeIngreso,
          type: 'private',
          ...permissionConfiguracionPresupuestalIngreso,
          catalogsModels: [
            'clase',
            'clasificaciondefuentedefinanciamiento',
            'conceptodeingreso',
          ],
        },
        {
          title: 'Configuración de Momentos Presupuestales',
          icon: null,
          showInSidebar: true,
          component: ConfigMomentosPresupuestales,
          type: 'private',
          ...permissionConfiguracionMomentoPresupuestal,
          catalogsModels: [
            'catalogodemodulo',
            'catalogodedocumento',
            'momentopresupuestaldeegreso',
            'momentopresupuestaldeingreso',
          ],
        },
        {
          title: 'Unidades Responsables',
          icon: null,
          showInSidebar: true,
          type: 'private',
          catalogsModels: [
            'tipodepresupuesto',
            'configuraciondesegmentopresupuestal',
            'tipodeunidadresponsable',
            'clasificacionfuncional',
            'clasificacionprogramatica',
            'clasificaciondeobjetodegastointerno',
            'clasificaciondefuentedefinanciamientointerna',
            'clasificadordetipodegasto',
            'clase',
            'funcionespresupuestales',
            'configuraciondeur',
          ],
          children: [

            {
              title: 'Unidades Operativas',
              icon: null,
              showInSidebar: true,
              component: UnidadesResponsables,
              type: 'private',
              ...permissionMomentoUnidadOperativa,
            },
            {
              title: 'Unidades Responsables',
              icon: null,
              showInSidebar: true,
              component: UnidadesResponsables,
              type: 'private',
              ...permissionMomentoUnidadResponsable,
            },
            {
              title: 'Centros de Costos',
              icon: null,
              showInSidebar: true,
              component: UnidadesResponsables,
              type: 'private',
              ...permissionMomentoCentroDeCostos,
            },
            {
              title: 'Directorio de URs',
              icon: null,
              showInSidebar: true,
              component: DirectorioURs,
              type: 'private',
              permissionModel: 'centrodecostos',
            },
          ],
        },
      ],
    },
    {
      title: 'Accesos',
      icon: () => <AiOutlineLock />,
      showInSidebar: true,
      children: [
        {
          title: 'Usuarios',
          icon: () => <AiOutlineTeam />,
          showInSidebar: true,
          component: Usuarios,
          type: 'private',
          ...permissionUsuarios,
          // permissionModel: 'usuario',
          // catalogsModels: [
          //   'empleado',
          //   'permission',
          //   'group',
          //   'funcion',
          //   'justificaciondeusuarioexterno',
          //   'centrodecosto',
          // ],
        },
        {
          title: 'Perfil de Usuarios',
          icon: () => <AiOutlineTeam />,
          showInSidebar: true,
          component: null,
          type: 'private',
        },
        {
          title: 'Roles a Usuarios',
          icon: () => <AiOutlineUsergroupAdd />,
          showInSidebar: true,
          component: RolesAUsuarios,
          type: 'private',
        },
        {
          title: 'Activar Usuarios',
          icon: null,
          showInSidebar: true,
          component: ActivarUsuarios,
          type: 'private',
          ...permissionActivarUsuarios,
        },
      ],
    },
    {
      title: 'Otros',
      icon: null,
      showInSidebar: true,
      type: 'private',
      children: [
        {
          title: 'Ejercicios',
          icon: null,
          showInSidebar: true,
          component: null,
          type: 'private',
        },
        {
          title: 'Foliador documentos',
          icon: null,
          showInSidebar: true,
          component: Foliador,
          type: 'private',
          ...permissionFoliador,
        },
        {
          title: 'Foliador de documentos operativos',
          icon: null,
          showInSidebar: true,
          component: FolioDeDocumentosOperativos,
          type: 'private',
          ...permissionFoliadorDocumento,
          catalogsModels: [
            'configuraciondefolio',
            'documentodepoliza',
          ],
        },
        {
          title: 'Periodo Fiscal',
          icon: null,
          component: PeriodoFiscal,
          type: 'private',
          showInSidebar: true,
          ...permissionPeriodoFiscal,
        },
        {
          title: 'Configuración Encabezados',
          icon: () => <GrDocumentConfig />,
          showInSidebar: true,
          component: ConfiguracionDeEncabezados,
          type: 'private',
          ...permissionEncabezadoDocumento,
        },
        {
          title: 'Configuración Pie',
          icon: () => <GrDocumentConfig />,
          showInSidebar: true,
          component: ConfiguracionDePie,
          type: 'private',
          ...permissionPieDocumento,
          catalogsModels: ['documentooperativo'],
        },
        {
          title: 'Blockchain',
          icon: () => <AiOutlineBlock />,
          showInSidebar: true,
          component: null,
          type: 'private',
        },
        {
          title: 'Firma Electrónica',
          icon: () => <AiOutlineKey />,
          showInSidebar: true,
          component: null,
          type: 'private',
        },
        {
          title: 'Bitácora',
          icon: () => <AiOutlineUnorderedList />,
          showInSidebar: true,
          component: null,
          type: 'private',
        },
      ],
    },
    {
      title: 'Clientes',
      icon: null,
      showInSidebar: true,
      component: Administracion,
      type: 'private',
      permissionModel: 'cliente',
      catalogsModels: [
        'formadepago',
        'clasificaciondeobjetodegastointerno',
        'estratificacion',
        'giroempresarial',
      ],
    },
  ],
};
