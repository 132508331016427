/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import {
  ShoppingCartOutlined,
} from '@ant-design/icons';
import Servicios, { permissionServicios } from 'views/Adquisiciones/Catalogos/Servicios';
import OrdenDeCompra from 'views/Adquisiciones/Requisiciones/OrdenDeCompra';
import Remisiones from 'views/Adquisiciones/Requisiciones/Remisiones';
import Requisiciones from 'views/Adquisiciones/Requisiciones/Requisiciones/index';
import ProcesosAdquisitivos, { permissionProcesosAdquisitivos } from 'views/Adquisiciones/ProcesosAdquisitivos';
import CriteriosDeRequisicion, { criteriosDeRequisicionPermission } from 'views/Adquisiciones/Catalogos/CriteriosDeRequisicion';
import TiposDeOperacion, { permissionTiposDeOperacion } from 'views/Adquisiciones/Catalogos/TiposDeOperacion';
import Prioridades, { permissionPrioridades } from 'views/Adquisiciones/Catalogos/Prioridades';
import ConfigRequisiciones from 'views/Adquisiciones/Requisiciones/Requisiciones/ConfigRequisiciones';
// import ActivosFijos from 'views/Adquisiciones/ActivosFijos';
import Recepcion from 'views/Adquisiciones/Requisiciones/Recepcion';
import EstudioDeMercado, { permissionEstudioDeMercado } from 'views/Adquisiciones/EstudioDeMercado';
import CuadroComparativo from 'views/Adquisiciones/Requisiciones/CuadroComparativo';
import RequisicionesConsolidadas from 'views/Adquisiciones/Requisiciones/Requisiciones/RequisicionesConsolidadas';
import blank from './blank';
import Administracion from '../views/Configuracion/Administracion';

export default {
  title: 'Adquisiciones',
  icon: () => <ShoppingCartOutlined />,
  showInSidebar: true,
  children: [
    {
      title: 'Proveedores',
      icon: null,
      component: Administracion,
      type: 'private',
      showInSidebar: true,
    },
    {
      title: 'Procesos Adquisitivos',
      icon: null,
      type: 'private',
      showInSidebar: true,
      children: [
        {
          title: 'Requisiciones',
          icon: null,
          type: 'private',
          showInSidebar: true,
          children: [
            {
              title: 'Requisiciones Individuales',
              icon: null,
              component: Requisiciones,
              type: 'private',
              showInSidebar: true,
            },
            {
              title: 'Requisiciones Consolidadas',
              icon: null,
              component: RequisicionesConsolidadas,
              type: 'private',
              showInSidebar: true,
            },
            {
              title: 'Estudio de Mercado',
              icon: null,
              component: EstudioDeMercado,
              type: 'private',
              showInSidebar: true,
              ...permissionEstudioDeMercado,
            },
            {
              title: 'Configuraciones de Requisiciones',
              icon: null,
              component: ConfigRequisiciones,
              type: 'private',
              showInSidebar: true,
            },
          ],
        },
        {
          title: 'Procesos',
          icon: null,
          type: 'private',
          showInSidebar: true,
          children: [
            {
              title: 'Licitaciones',
              icon: null,
              component: blank,
              type: 'private',
              showInSidebar: true,
            },
            {
              title: 'Invitacion a 3',
              icon: null,
              component: blank,
              type: 'private',
              showInSidebar: true,
            },
            {
              title: 'Adjudicación Directa',
              icon: null,
              component: blank,
              type: 'private',
              showInSidebar: true,
            },
          ],
        },
        {
          title: 'Cuadro Comparativo',
          icon: null,
          type: 'private',
          showInSidebar: true,
          component: CuadroComparativo,

        },
        {
          title: 'Orden de Compra',
          icon: null,
          component: OrdenDeCompra,
          type: 'private',
          showInSidebar: true,
        },
        {
          title: 'Remesas',
          icon: null,
          component: Remisiones,
          type: 'private',
          showInSidebar: true,
        },
        {
          title: 'Recepciones',
          icon: null,
          type: 'private',
          showInSidebar: true,
          children: [
            {
              title: 'Bienes No Almacenables',
              icon: null,
              component: blank,
              type: 'private',
              showInSidebar: true,
            },
            {
              title: 'Recepción de Servicios',
              icon: null,
              component: Recepcion,
              type: 'private',
              showInSidebar: true,
            },

          ],
        },
      ],
    },
    {
      title: 'Devoluciones Bienes No Almacenables',
      icon: null,
      component: blank,
      type: 'private',
      showInSidebar: true,
    },
    {
      title: 'Penalizaciones',
      icon: null,
      component: blank,
      type: 'private',
      showInSidebar: true,
    },
    {
      title: 'Expediente Digital',
      icon: null,
      component: blank,
      type: 'private',
      showInSidebar: true,
    },
    {
      title: 'Cerrar Contratos',
      icon: null,
      component: blank,
      type: 'private',
      showInSidebar: true,
    },
    {
      title: 'Catálogos',
      icon: null,
      type: 'private',
      showInSidebar: true,
      children: [
        {
          title: 'Servicios',
          icon: null,
          component: Servicios,
          type: 'private',
          showInSidebar: true,
          ...permissionServicios,
        },
        {
          title: 'Montos Procesos Adquisitivos',
          icon: null,
          component: ProcesosAdquisitivos,
          type: 'private',
          showInSidebar: true,
          ...permissionProcesosAdquisitivos,
        },
        {
          title: 'Criterios de Requisición',
          icon: null,
          component: CriteriosDeRequisicion,
          type: 'private',
          showInSidebar: true,
          ...criteriosDeRequisicionPermission,
        },
        {
          title: 'Tipos de Operación',
          icon: null,
          component: TiposDeOperacion,
          type: 'private',
          showInSidebar: true,
          ...permissionTiposDeOperacion,
        },
        {
          title: 'Prioridades',
          icon: null,
          component: Prioridades,
          type: 'private',
          showInSidebar: true,
          ...permissionPrioridades,
        },
      ],
    },
  ],
};
