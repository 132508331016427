import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Spin,
  Row,
  Col,
  Button,
  message,
  Input,
  Modal,
} from 'antd';

import { SearchOutlined } from '@ant-design/icons';
import API from 'utils/api';
import { onError } from 'utils/handlers';
import Table from './Table';
import FormSubmitControls from './FormSubmitControls';
import Select from './Select';

function BusquedaEmpleados({
  type,
  onFinish,
  setSelectedEmpleados,
  visible,
  setVisible,
  puestos,
}) {
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const onFinishForm = async () => {
    try {
      setLoading(true);
      const values = form.getFieldsValue();
      const entries = Object.entries(values);
      const validEntries = entries.filter((e) => e[1]);
      const params = Object.fromEntries(validEntries);
      if (validEntries.length) {
        const res = await API.get('estructura-organizacional/empleados/', {
          params,
        });
        if (res?.status === 200) {
          if (res.data?.length) {
            setData(res.data);
          } else {
            message.info('No se encontraron coincidencias');
            setData([]);
            setSelectedEmpleados([]);
          }
        }
      } else {
        message.info('Introduzca al menos un parámetro de búsqueda');
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onClickRadio = (record) => {
    setSelectedRowKeys([record.id]);
    setSelectedEmpleados([record]);
  };

  const onClickCheckbox = (record) => {
    const { key } = record;
    const selecteds = [...selectedRowKeys];
    if (selecteds.contains(key)) {
      selecteds.remove(key);
    } else {
      selecteds.push(key);
    }
    setSelectedRowKeys(selecteds);
    setSelectedEmpleados(data.filter((e) => selecteds.contains(e.id)));
  };

  const onRow = (record) => ({
    onClick: () => {
      if (type === 'radio') {
        onClickRadio(record);
      } else if (type === 'checkbox') {
        onClickCheckbox(record);
      }
    },
    onDoubleClick: () => {
      onFinish();
    },
  });

  const rowSelection = {
    type,
    selectedRowKeys,
  };

  const columns = [
    {
      titleText: 'No. de Empleado',
      dataIndex: 'numero_de_empleado',
      key: 'numero_de_empleado',
      width: 100,
    },
    {
      titleText: 'Nombre',
      dataIndex: 'nombre',
      key: 'nombre',
      width: 200,
      render: (val, values) => `${val} ${values?.apellido_paterno} ${values?.apellido_materno || ''}`,
    },
    {
      titleText: 'RFC',
      dataIndex: 'RFC',
      key: 'RFC',
      width: 100,
    },
    {
      titleText: 'CURP',
      dataIndex: 'curp',
      key: 'curp',
      width: 100,
    },
  ];

  const onCancel = () => {
    setVisible(false);
    setSelectedRowKeys([]);
    setData([]);
  };

  return (
    <Modal
      width="90%"
      visible={visible}
      closable={false}
      maskClosable={!loading}
      keyboard={!loading}
      footer={null}
      title={(
        <FormSubmitControls
          label="Busqueda Empleados"
          onFinish={onFinish}
          onCancel={onCancel}
          loading={loading}
        />
      )}
      onCancel={onCancel}
      forceRender
      centered
    >
      <Spin tip="Cargando..." spinning={loading}>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinishForm}
        >
          <Row gutter={10}>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="nombre"
                label="Nombre"
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="apellido_paterno"
                label="Apellido Paterno"
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="apellido_materno"
                label="Apellido Materno"
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="RFC"
                label="RFC"
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="curp"
                label="CURP"
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="puesto"
                label="Puesto"
              >
                <Select dataSource={puestos} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item>
                <Button
                  type="link"
                  htmlType="submit"
                  style={{ marginTop: 20 }}
                  className="primary-on-hover"
                >
                  <SearchOutlined />
                  {' '}
                  Buscar
                </Button>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item hidden>
            <Button htmlType="submit" />
          </Form.Item>
        </Form>
        <Table
          cols={columns}
          data={data}
          rowSelection={rowSelection}
          onRow={onRow}
          noControls
        />
      </Spin>
    </Modal>
  );
}

BusquedaEmpleados.propTypes = {
  type: PropTypes.oneOf(['radio', 'checkbox']),
  onFinish: PropTypes.func.isRequired,
  setSelectedEmpleados: PropTypes.func.isRequired,
  setVisible: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  puestos: PropTypes.arrayOf(PropTypes.object).isRequired,
  visible: PropTypes.bool,
};

BusquedaEmpleados.defaultProps = {
  type: 'radio',
  visible: false,
};

export default BusquedaEmpleados;
