import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Spin,
  Tabs,
  Card,
  TreeSelect,
} from 'antd';
import { useSelector } from 'react-redux';
import Table from 'components/Table';
import FormSubmitControls from 'components/FormSubmitControls';
import Select from 'components/Select';
import API from 'utils/api';
import { onError } from 'utils/handlers';
import LadaNumero from 'components/LadaNumero';
import Transfer from 'components/Transfer';
import makeTreeFromCOG from 'utils/makeTree';
import fetchSome from 'utils/fetchSome';
import Direccion from 'components/Direccion';
import { SET_TIPOS_PRESUPUESTOS } from 'store/reducers/catalogs';

const baseURI = 'estructura-organizacional/unidades-de-trabajo/';
const { TabPane } = Tabs;

function DirectorioURs() {
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  const collapsedSidebar = useSelector(({ app }) => app.collapsedSidebar);
  const tiposPresupuestos = useSelector(({ catalogs }) => catalogs.tiposPresupuestos);
  const [form] = Form.useForm();
  const [formConfigPresupuestal] = Form.useForm();
  const [formConfigEntidad] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState([]);
  const [selectedItem, setSelectedItem] = useState();
  const [filteredProgramatica, setFilteredProgramatica] = useState([]);
  const [unidadesOperativas, setUnidadesOperativas] = useState([]);
  const [unidadesResponsables, setUnidadesResponsables] = useState([]);
  const [filteredFuncionales, setFilteredFuncionales] = useState([]);
  const [configSegmentoPresupuestalIngresos, setConfigSegmentoPresupuestalIngresos] = useState([]);
  const [configSegmentoPresupuestalEgresos, setConfigSegmentoPresupuestalEgresos] = useState([]);
  const [tipoDeUr, setTiposUr] = useState([]);
  const [tipoClaveUr, setTiposClaveUr] = useState([]);
  const [clasesCRI, setClasesCRI] = useState([]);
  const [filteredCOGInterno, setFilteredCOGInterno] = useState([]);
  const [filteredClasificacionesFF, setFilteredClasificacionesFF] = useState([]);
  const [filteredProgramas, setFilteredProgramas] = useState([]);
  const [filteredTiposDeGasto, setFilteredTiposDeGasto] = useState([]);
  const [completeURI, setCompleteURI] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [formAddress, setFormAddress] = useState();

  const onClickTitle = (e) => {
    const element = e.target;
    if (element) {
      let parent = element.parentElement;
      parent = parent?.parentElement;
      const clickableSpan = parent?.previousSibling;
      if (clickableSpan) {
        clickableSpan.click();
      }
    }
  };

  const renderTitle = (title, bold) => (
    // eslint-disable-next-line
    <span onClick={onClickTitle} className={bold ? 'bold' : ''}>
      {title}
    </span>
  );

  const normalizeData = (_data) => {
    if (_data) {
      const output = _data.map((item) => {
        const clone = { ...item };
        const unidades_responsables = normalizeData(clone.unidad_responsable);
        const centros_de_costos = normalizeData(clone.centro_de_costo);
        if (!unidades_responsables.length && !centros_de_costos.length) {
          delete clone.unidad_responsable;
          delete clone.centro_de_costo;
          return ({
            ...clone,
            descripcion: clone.descripcion,
            key: `${clone.id}/${clone.nivel}`,
          });
        }
        if (unidades_responsables) {
          return ({
            ...clone,
            clave: clone.clave,
            descripcion: clone.descripcion,
            nivel: clone.nivel,
            key: `${clone.id}/${clone.nivel}`,
            children: unidades_responsables,
          });
        }
        delete clone.centro_de_costo;
        return ({
          ...clone,
          clave: clone.clave,
          descripcion: clone.descripcion,
          key: `${clone.id}/${clone.nivel}`,
          nivel: clone.nivel,
          children: centros_de_costos,
        });
      });
      return output;
    }
    return false;
  };
  const normalizeTreeData = (_data) => {
    if (_data) {
      const output = _data.map((item) => {
        const clone = { ...item };
        const children = normalizeTreeData(clone.children);
        const title = renderTitle(`${clone.clave} - ${clone.concepto}`, true);
        if (!children.length) {
          delete clone.children;
          return ({
            ...clone,
            title,
            value: clone.id,
            selectable: true,
          });
        }
        return ({
          ...clone,
          title,
          value: clone.id,
          children,
          selectable: false,
        });
      });
      return output;
    }
    return [];
  };

  const normalizeDataForTransfer = (_data, prop) => {
    if (_data?.length) {
      const output = _data.map((item) => {
        const clone = { ...item };
        const children = normalizeDataForTransfer(clone.children, prop);
        const identificator = `${clone.id.toString()}${item?.seleccionable ? '' : '_parent'}`;
        delete clone.children;
        if (!children.length) {
          return ({
            ...clone,
            value: identificator,
            title: `${item.clave} - ${item[prop]}`,
            key: identificator,
            selectable: item?.seleccionable || true,
            checkable: item?.seleccionable || true,
          });
        }
        return ({
          ...clone,
          value: identificator,
          title: `${item.clave} - ${item[prop]}`,
          children,
          disabled: true,
          selectable: item?.seleccionable || false,
          checkable: item?.seleccionable || false,
          key: identificator,
        });
      });
      return output;
    }
    return [];
  };

  const normalizePlainDataForTransfer = (
    _data = [],
    prop,
  ) => _data.map((item) => ({
    ...item,
    value: item.id,
    title: `${item.clave} - ${item[prop]}`,
    key: item.id,
    selectable: item.seleccionable,
    checkable: item.seleccionable,
  }));

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await API.get(baseURI);
      const normalized = normalizeData(response.data);
      setData(normalized);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const normalizeCog = (_data = []) => _data.map((item) => {
    const clone = { ...item };
    const children = clone.children?.length && typeof clone.id === 'string'
      ? normalizeCog(clone.children) : [];
    if (!children.length || typeof clone.id === 'number') {
      delete clone.children;
      return ({
        ...clone,
        key: clone.clave,
        clave: clone.clave,
        disabled: !clone.seleccionable,
      });
    }
    return ({
      ...clone,
      key: clone.clave,
      children,
      disabled: !clone.seleccionable,
    });
  });

  const fetchAll = async () => {
    try {
      setLoading(true);
      if (!tiposPresupuestos?.length) {
        await fetchSome('catalogos/tipos-de-presupuestos/', SET_TIPOS_PRESUPUESTOS);
      }

      const resProgramatica = await API.get('catalogos/clasificaciones-programaticas/');
      const mappedProgramatica = normalizeTreeData(resProgramatica.data || []);
      setFilteredProgramatica(mappedProgramatica);

      const resOperativas = await API.get('estructura-organizacional/unidades-operativas/');
      setUnidadesOperativas(resOperativas.data || []);

      const resResponsables = await API.get('estructura-organizacional/unidades-responsables/');
      setUnidadesResponsables(resResponsables.data || []);

      const resFuncionales = await API.get('catalogos/clasificaciones-funcionales/');
      const mappedFuncionales = normalizeTreeData(resFuncionales.data || []);
      setFilteredFuncionales(mappedFuncionales);

      const resConfigSegmentoPresupuestalIngresos = await API
        .get('configuraciones/configuraciones-de-segmentos-presupuestales/', {
          params: {
            estados_globales: 4,
            tipo_de_presupuesto: 1,
          },
        });
      setConfigSegmentoPresupuestalIngresos(resConfigSegmentoPresupuestalIngresos.data || []);

      const resConfigSegmentoPresupuestalEgresos = await API
        .get('configuraciones/configuraciones-de-segmentos-presupuestales/', {
          params: {
            estados_globales: 4,
            tipo_de_presupuesto: 2,
          },
        });
      setConfigSegmentoPresupuestalEgresos(resConfigSegmentoPresupuestalEgresos.data || []);

      const resTiposUr = await API.get('contabilidad/tipos-unidad-responsable/');
      setTiposUr(resTiposUr.data || []);

      const resTipoClaveDeUr = await API.get('contabilidad/tipos-de-clave-unidad-responsable/');
      setTiposClaveUr(resTipoClaveDeUr.data || []);

      const resClaseCRI = await API.get('contabilidad/cri/');
      const mappedClases = normalizeDataForTransfer(resClaseCRI.data, 'denominacion');
      setClasesCRI(mappedClases);

      const resCOGInterno = await API.get('/contabilidad/clasificaciones-objeto-gasto/');
      const cogTree = makeTreeFromCOG(resCOGInterno.data.map((e) => (!e.seleccionable ? ({
        ...e,
        id: `${e.id}`,
      }) : e)).sort((a, b) => a.id - b.id));
      const normalizedCog = normalizeCog(cogTree);

      const mappedCOGInterno = normalizeDataForTransfer(normalizedCog, 'concepto');
      setFilteredCOGInterno(mappedCOGInterno);

      const resFinanciamiento = await API
        .get('contabilidad/clasificaciones-de-fuentes-de-financiamiento/');
      const fuentesFTree = makeTreeFromCOG(resFinanciamiento.data.map((e) => (!e.seleccionable ? ({
        ...e,
        id: `${e.id}`,
      }) : e)).sort((a, b) => a.id - b.id));
      const normalizedFF = normalizeCog(fuentesFTree);

      const mappedFF = normalizeDataForTransfer(normalizedFF, 'nombre');
      setFilteredClasificacionesFF(mappedFF);

      const resPrograma = await API.get('contabilidad/programas/');
      const mappedProgramas = normalizePlainDataForTransfer(resPrograma.data, 'nombre');
      setFilteredProgramas(mappedProgramas);

      const resTiposDeGasto = await API.get('catalogos/clasificaciones-tipo-de-gasto/');
      const mappedTiposGasto = normalizePlainDataForTransfer(resTiposDeGasto.data, 'nombre');
      setFilteredTiposDeGasto(mappedTiposGasto);

      await fetchData();
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchAll();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setVisible(false);
    setSelectedRowKeys([]);
    form.resetFields();
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.key]);
    setSelectedItem(record);
    switch (record.nivel) {
      case 1:
        setCompleteURI('estructura-organizacional/unidades-operativas');
        break;
      case 2:
        setCompleteURI('estructura-organizacional/unidades-responsables');
        break;
      case 3:
        setCompleteURI('estructura-organizacional/centros-de-costos');
        break;
      default:
        break;
    }
    setTimeout(() => {
      form.setFieldsValue(record);
      formConfigEntidad.setFieldsValue(record);
      formConfigPresupuestal.setFieldsValue(record);
    });
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const columns = [
    {
      titleText: 'Clave',
      dataIndex: 'clave',
      key: 'clave',
      width: 300,
    },
    {
      titleText: 'Descripción',
      dataIndex: 'descripcion',
      key: 'descripcion',
      width: 300,
    },
    {
      titleText: 'Es UR Presupuesta de Ingreso',
      dataIndex: 'es_UR_presupuestal_de_ingreso',
      key: 'es_UR_presupuestal_de_ingreso',
      width: 250,
      render: (val) => (val ? 'Sí' : 'No'),
    },
    {
      titleText: 'Nivel',
      dataIndex: 'nivel',
      key: 'nivel',
      width: 200,
    },
  ];

  const getTitle = (nivel, pluralize = false) => {
    let output = '';
    switch (nivel) {
      case 1:
        output = pluralize ? 'Unidades Operativas' : 'Unidad Operativa';
        break;
      case 2:
        output = pluralize ? 'Unidades Responsables' : 'Unidad Responsable';
        break;
      default:
        output = pluralize ? 'Centros de Costos' : 'Centro de Costos';
        break;
    }

    return output;
  };

  const filterTreeNode = (input, node) => {
    const title = node.title?.props.children.toLowerCase() || node.title;
    if (title && title.includes(input.toLowerCase())) {
      return true;
    }
    if (node.children) {
      return filterTreeNode(input, node.children);
    }
    return false;
  };

  const setTimeoutFilter = (input, node) => setTimeout(() => filterTreeNode(input, node), 1000);

  let timeoutFilter;

  const filterTree = (input, node) => {
    clearTimeout(timeoutFilter);
    timeoutFilter = setTimeoutFilter(input, node);
    return timeoutFilter ? filterTreeNode(input, node) : true;
  };

  const onClickExpand = async () => {
    try {
      setLoading(true);
      const response = await API.get(`${completeURI}/${selectedItem?.id}`);
      if (response?.status === 200) {
        const resData = response.data;
        switch (resData.nivel) {
          case 2:
            resData.unidad_operativa = resData.unidad_operativa.id;
            break;
          case 3:
            resData.unidad_responsable = resData.unidad_responsable.id;
            break;
          default:
            break;
        }
        form.setFieldsValue(resData);
        formConfigEntidad.setFieldsValue(resData);
        formConfigPresupuestal.setFieldsValue(resData);
      }
      setVisible(true);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible ? (
          <Table
            rowKey="key"
            cols={columns}
            data={data}
            rowSelection={rowSelection}
            handleOnRowClick={handleOnRowClick}
            loading={loading}
            allowAdd={false}
            allowEdit={false}
            allowDelete={false}
            controls={{
              onClickExpand,
            }}
            childrenProp="children"
            filterNested
            allowExpand
            mobileColIndex={0}
          />
        ) : (
          <Card
            className="form-container"
            title={`${getTitle(selectedItem?.nivel)}`}
            extra={(
              <FormSubmitControls
                onCancel={onCancel}
              />
            )}
            bordered={false}
            bodyStyle={{ padding: 5, overflow: 'hidden' }}
          >
            <Tabs
              type="card"
            >
              <TabPane tab="General" key="general" forceRender>
                <Form
                  layout="vertical"
                  form={form}
                  name="general"
                  scrollToFirstError
                >
                  <Row gutter={10}>
                    {selectedItem.nivel === 2 && (
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="unidad_operativa"
                          label="Unidad Operativa"
                          hasFeedback
                        >
                          <Select
                            dataSource={unidadesOperativas}
                            keyLabelRender
                            disabled
                          />
                        </Form.Item>
                      </Col>
                    )}

                    {selectedItem.nivel === 3 && (
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="unidad_responsable"
                          label="Unidad Responsable"
                          hasFeedback
                        >
                          <Select
                            dataSource={unidadesResponsables}
                            disabled
                          />
                        </Form.Item>
                      </Col>
                    )}

                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="clave"
                        label="Clave"
                        hasFeedback
                        normalize={(val) => val?.toUpperCase()}
                      >
                        <Input
                          className="upper"
                          disabled
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="descripcion"
                        label="Descripción"
                      >
                        <Input allowClear disabled />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="correo_electronico"
                        label="Correo Electrónico"
                        hasFeedback
                      >
                        <Input allowClear disabled />
                      </Form.Item>
                    </Col>

                    <LadaNumero
                      form={form}
                      disabled
                    />

                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="portal_web"
                        label="Portal Web"
                        hasFeedback
                      >
                        <Input allowClear disabled />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={10}>
                    <Col xs={24} sm={24} md={12} xl={8}>
                      <Form.Item
                        name="clasificacion_programatica"
                        label="Clasificación Programática"
                        hasFeedback
                      >
                        <TreeSelect
                          showSearch
                          multiple
                          showArrow
                          treeNodeFilterProp="title"
                          style={{ width: '100%' }}
                          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                          treeData={filteredProgramatica}
                          filterTreeNode={filterTree}
                          disabled
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={12} xl={8}>
                      <Form.Item
                        name="clasificacion_funcional"
                        label="Clasificación Funcional"
                        hasFeedback
                      >
                        <TreeSelect
                          showSearch
                          multiple
                          showArrow
                          treeNodeFilterProp="title"
                          style={{ width: '100%' }}
                          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                          treeData={filteredFuncionales}
                          disabled
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="estados_globales"
                        label="Estado"
                        hasFeedback
                      >
                        <Select dataSource={estadosGlobales} disabled />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item hidden>
                    <Button htmlType="submit" />
                  </Form.Item>
                </Form>
              </TabPane>

              <TabPane tab="Configuración Entidad" key="configEntidad" forceRender>
                <Form
                  layout="vertical"
                  name="configEntidad"
                  form={formConfigEntidad}
                  scrollToFirstError
                >
                  <Row gutter={10}>
                    <Col
                      xs={24}
                      sm={24}
                      md={12}
                      lg={collapsedSidebar ? 8 : 12}
                      xl={8}
                    >
                      <Form.Item
                        name="es_UR_presupuestal_de_ingreso"
                        label="¿Es UR Presupuesta de Ingreso?"
                        hasFeedback
                      >
                        <Select
                          disabled
                          trueFalse
                        />
                      </Form.Item>
                    </Col>

                    {selectedItem.es_UR_presupuestal_de_ingreso && (
                      <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={collapsedSidebar ? 8 : 12}
                        xl={8}
                      >
                        <Form.Item
                          name="configuracion_de_segmento_presupuestal_ingresos"
                          label="Configuración Presupuestal Ingresos"
                          hasFeedback
                        >
                          <Select
                            dataSource={configSegmentoPresupuestalIngresos}
                            disabled
                            render={(item) => `${item.identificador}-${tiposPresupuestos
                              ?.find((e) => e.id === item.tipo_de_presupuesto)
                              ?.descripcion}`}
                          />
                        </Form.Item>
                      </Col>
                    )}
                    <Col
                      xs={24}
                      sm={24}
                      md={12}
                      lg={collapsedSidebar ? 8 : 12}
                      xl={8}
                    >
                      <Form.Item
                        name="configuracion_de_segmento_presupuestal_egresos"
                        label="Configuración Presupuestal Egresos"
                        hasFeedback
                      >
                        <Select
                          dataSource={configSegmentoPresupuestalEgresos}
                          disabled
                          render={(item) => `${item.identificador}-${tiposPresupuestos
                            ?.find((e) => e.id === item.tipo_de_presupuesto)
                            ?.descripcion}`}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} xl={8}>
                      <Form.Item
                        label="Tipo de UR"
                        hasFeedback
                      >
                        <Select
                          disabled
                          labelProp="nombre"
                          dataSource={tipoDeUr}
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={12} xl={8}>
                      <Form.Item
                        name="tipo_de_clave_de_ur"
                        label="Tipo de Clave de UR"
                        hasFeedback
                      >
                        <Select
                          disabled
                          labelProp="nombre"
                          dataSource={tipoClaveUr}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  {selectedItem.es_UR_presupuestal_de_ingreso && (
                    <Transfer
                      dataSource={clasesCRI}
                      treeData={clasesCRI}
                      filterTreeNode={filterTree}
                      label="Clases CRI"
                      formItemName="clase_CRI"
                      form={formConfigEntidad}
                      disabled
                    />
                  )}
                  <Form.Item hidden>
                    <Button htmlType="submit" />
                  </Form.Item>
                </Form>
              </TabPane>

              <TabPane tab="Configuración Presupuestal" key="configPresupuestos" forceRender>
                <Form
                  layout="vertical"
                  name="configPresupuestos"
                  form={formConfigPresupuestal}
                  scrollToFirstError
                  initialValues={{
                    clasificacion_de_objeto_de_gasto_interno: [],
                    fuentes_de_financiamiento_interna: [],
                    programa: [],
                    tipo_de_gasto: [],
                  }}
                >
                  <Row gutter={10}>
                    <Transfer
                      dataSource={filteredCOGInterno}
                      treeData={filteredCOGInterno}
                      filterTreeNode={filterTree}
                      label="Clasificación de Objeto de Gasto Interno"
                      formItemName="clasificacion_de_objeto_de_gasto_interno"
                      form={formConfigPresupuestal}
                      disabled
                      nested
                      mixed
                    />

                    <Transfer
                      dataSource={filteredClasificacionesFF}
                      treeData={filteredClasificacionesFF}
                      filterTreeNode={filterTree}
                      label="Clasificación de Fuentes de Financiamiento Interno"
                      formItemName="fuentes_de_financiamiento_interna"
                      form={formConfigPresupuestal}
                      disabled
                      nested
                      mixed
                    />

                    <Transfer
                      dataSource={filteredProgramas}
                      filterTreeNode={filterTree}
                      label="Programas"
                      formItemName="programa"
                      form={formConfigPresupuestal}
                      disabled
                    />

                    <Transfer
                      dataSource={filteredTiposDeGasto}
                      filterTreeNode={filterTree}
                      label="Tipos de Gasto"
                      formItemName="tipo_de_gasto"
                      form={formConfigPresupuestal}
                      disabled
                    />
                  </Row>
                  <Form.Item hidden>
                    <Button htmlType="submit" />
                  </Form.Item>
                </Form>
              </TabPane>

              <TabPane
                tab="Dirección"
                key="direccion"
                forceRender
              >
                <Direccion
                  currentURI={completeURI}
                  parentID={selectedItem?.id}
                  setLoading={setLoading}
                  setFormAddress={setFormAddress}
                  addressPropName="direccion"
                  setCurrentTabKey={() => { }}
                  callback={(values, _continue) => {
                    if (!_continue) {
                      onCancel();
                    } else {
                      const [key] = selectedRowKeys;
                      const clone = [...data];
                      const mappedData = clone.map((e) => (e.id === key
                        ? ({ ...e, direccion: values?.id }) : e));
                      setData([...mappedData]);
                    }
                  }}
                  mutable={false}
                />
              </TabPane>
            </Tabs>
          </Card>
        )}
      </Spin>
    </Row>
  );
}

export default DirectorioURs;
