import React, { useState, useEffect } from 'react';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Row,
  Spin,
  Tabs,
} from 'antd';
import FormSubmitControls from 'components/FormSubmitControls';
import Table from 'components/Table';
import formatValues, { DATE_FRONTEND_FORMAT, formatReceived } from 'utils/formatValues';
import Select from 'components/Select';
import { onError, onSuccess } from 'utils/handlers';
import { isMutable } from 'utils/estadosGlobales';
import ModalDelete from 'components/ModalDelete';
import API from 'utils/api';
import { useSelector } from 'react-redux';
import NumericInput from 'components/NumericInput';
import currencyFormatter from 'utils/currencyFormatter';
import { createDecimalRegExp } from 'utils/patterns';
import { InputSearchPro } from 'components/InputSearch';
// import Depreciacion from './Depreciaciones';

export const permissionResguardo = {
  permissionModel: 'resguardo',
};

const { RangePicker } = DatePicker;
const { TabPane } = Tabs;

const baseURI = '/controlpatrimonial/calculo-de-activo-fijo/';
const baseURIs = [
  {
    uri: '/controlpatrimonial/tipos-de-activos/',
    params: null,
  },
  {
    uri: '/controlpatrimonial/clasificaciones-de-activos/',
    params: null,
  },
  // {
  //   uri: 'controlpatrimonial/activos-fijos/',
  //   params: null,
  // },
  {
    uri: baseURI,
    params: null,
  },
];

// eslint-disable-next-line react/prop-types
function DepreciacionAmortizacionYDeterioros({ permission }) {
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [formFiltros] = Form.useForm();
  const [formCalculos] = Form.useForm();
  const [formConfig] = Form.useForm();
  const [decimal] = createDecimalRegExp(4);
  // const ocultarActivosFijos = Form.useWatch('ocultar_activos_con_calculo_total', form) || false;
  // console.log(ocultarActivosFijos);
  const [data, setData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [mutable, setMutable] = useState(true);

  const [tiposDeActivos, setTiposDeActivos] = useState([]);
  const [clasificacionesDeActivos, setClasificacionesDeActivos] = useState([]);
  const [currentItem, setCurrentItem] = useState(null);
  const [activoFound, setActivoFound] = useState([]);
  // const [activosFijos, setActivosFijos] = useState([]);

  const [currentTabKey, setCurrentTabKey] = useState('general');

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    formCalculos.resetFields();
    formConfig.resetFields();
    formFiltros.resetFields();
    setSelectedRowKeys([]);
    setCurrentTabKey('general');
    setMutable(true);
    setActivoFound([]);
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await API.get(baseURI);
      setData(formatReceived(response.data));
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    let mounted = true;
    const fetchAll = async () => {
      try {
        setLoading(true);
        const promises = baseURIs.map((bu) => API.get(bu.uri, bu.params));
        const [
          _tiposDeActivos,
          _clasificacionesDeActivosFijos,
          // _activosFijos,
          _data,
        ] = (await Promise.all(promises)).map((r) => r.data);

        if (mounted) {
          setTiposDeActivos(_tiposDeActivos || []);
          setClasificacionesDeActivos(_clasificacionesDeActivosFijos || []);
          // setActivosFijos(_activosFijos || []);
          setData(formatReceived(_data) || []);
          setLoading(false);
        }
      } catch (err) {
        onError(err, setLoading);
      }
    };
    fetchAll();

    return () => { mounted = false; };
  }, []);

  const columns = [
    {
      titleText: 'Fecha de Cálculo',
      dataIndex: 'fecha_de_calculo',
      key: 'fecha_de_calculo',
      width: 160,
      render: (value) => value?.format(DATE_FRONTEND_FORMAT),
    },
    {
      titleText: 'Fecha Inicial',
      dataIndex: 'fecha_inicial',
      key: 'fecha_inicial',
      width: 160,
      render: (value) => value?.format(DATE_FRONTEND_FORMAT),
    },
    {
      titleText: 'Fecha Final',
      dataIndex: 'fecha_final',
      key: 'fecha_final',
      width: 160,
      render: (value) => value?.format(DATE_FRONTEND_FORMAT),
    },
    {
      titleText: 'Número de Documento',
      dataIndex: 'numero_documento_de_calculo',
      key: 'numero_documento_de_calculo',
      width: 200,
    },
    {
      titleText: 'Tipo de Activo',
      dataIndex: 'tipo_activo',
      key: 'tipo_activo',
      width: 300,
      render: (id) => tiposDeActivos.find((ta) => ta.id === id)?.descripcion,
    },
    {
      titleText: 'Clasificación',
      dataIndex: 'clasificacion_activo',
      key: 'clasificacion_activo',
      width: 300,
      render: (id) => clasificacionesDeActivos.find((ta) => ta.id === id)?.descripcion,
    },
    {
      titleText: 'Monto Total',
      dataIndex: 'monto_total',
      key: 'monto_total',
      width: 150,
      align: 'right',
      render: (value) => currencyFormatter(value || 0),
    },
    {
      titleText: 'Fecha de Autorización',
      dataIndex: 'fecha_de_autorizacion',
      key: 'fecha_de_autorizacion',
      width: 190,
      render: (value) => value?.format(DATE_FRONTEND_FORMAT),
    },
    {
      titleText: 'Fecha de Cancelación',
      dataIndex: 'fecha_de_cancelacion',
      key: 'fecha_de_cancelacion',
      width: 190,
      render: (value) => value?.format(DATE_FRONTEND_FORMAT),
    },
    {
      titleText: 'Depreciación',
      dataIndex: 'depreciacion',
      key: 'depreciacion',
      width: 150,
      align: 'right',
      render: (value) => currencyFormatter(value || 0),
    },
    {
      titleText: 'Amortización',
      dataIndex: 'amortizacion',
      key: 'amortizacion',
      width: 150,
      align: 'right',
      render: (value) => currencyFormatter(value || 0),
    },
    {
      titleText: 'Deterioro',
      dataIndex: 'deterioro',
      key: 'deterioro',
      width: 150,
      align: 'right',
      render: (value) => currencyFormatter(value || 0),
    },
    {
      titleText: 'Estado',
      dataIndex: 'estados_globales',
      key: 'estados_globales',
      width: 150,
      render: (id) => estadosGlobales.find((eg) => eg.id === id)?.descripcion,
    },
  ];

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    setTimeout(() => {
      formCalculos.setFieldsValue(record);
      formConfig.setFieldsValue(record);
      form.setFieldsValue({
        ...record,
        fecha_inicial_y_final: [record.fecha_inicial, record.fecha_final],
      });
    });
    setMutable(isMutable(record));
    setCurrentItem(record);
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const requiredRule = {
    required: mutable,
    message: 'Este campo es requerido',
  };

  const rules = {
    required: [requiredRule],
    decimal_rules: [
      // requiredRule,
      {
        validator: async (_, val) => {
          if (val && !decimal.test(val)) {
            throw new Error('Ingrese un valor decimal válido');
          }
        },
      },
    ],
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      await formCalculos.validateFields();
      await formConfig.validateFields();
      const values = {
        ...form.getFieldsValue(),
        ...formCalculos.getFieldsValue(),
        ...formConfig.getFieldsValue(),
      };
      const formattedValues = formatValues({
        ...values,
        fecha_inicial: values.fecha_inicial_y_final[0],
        fecha_final: values.fecha_inicial_y_final[1],
      });
      delete formattedValues.fecha_inicial_y_final;
      if (!selectedRowKeys.length) {
        const response = await API.post(baseURI, formattedValues);
        if (response.status === 201) {
          onSuccess(response, 'Agregado Correctamente');
          await fetchData();
          onCancel();
        }
      } else {
        const [key] = selectedRowKeys;
        const response = await API.put(`${baseURI}${key}/`, formattedValues);
        if (response.status === 200) {
          onSuccess(response, 'Agregado Correctamente');
          await fetchData();
          onCancel();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading, [form, formCalculos, formConfig], setCurrentTabKey);
    }
  };

  const onFinishFiltros = async () => {
    try {
      setLoading(true);
      await formFiltros.validateFields();
      const values = formFiltros.getFieldsValue();
      const formattedValues = formatValues(values);
      const response = await API.post('/controlpatrimonial/calculo-de-activo-fijo/', formattedValues);
      if (response.status === 201) {
        onSuccess(response, 'Calculo ejecutado correctamente');
        setData(response.data);
      }
      setLoading(false);
    } catch (error) {
      onError(error, setLoading);
    }
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const onClickEdit = async () => {
    try {
      setLoading(true);
      const [key] = selectedRowKeys;
      const match = data.find((e) => e.id === key);
      const { data: found } = await API
        .get(`controlpatrimonial/activos-fijos/${match.activo_fijo}/`);
      setActivoFound(found);
      setTimeout(() => {
        form.setFieldsValue({
          ...match,
          fecha_inicial_y_final: [match.fecha_inicial, match.fecha_final],
        });
        formCalculos.setFieldsValue(match);
        formConfig.setFieldsValue(match);
      });
      setVisible(true);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const deleteItem = async () => {
    try {
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`${baseURI}${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          onCancel();
          await fetchData();
          setVisibleAlert(false);
        }
      }
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const busquedaActivoFijo = async () => {
    try {
      setLoading(true);
      await form.validateFields(['clave']);
      const { clave } = form.getFieldsValue();
      const response = await API.get('controlpatrimonial/activos-fijos/', {
        params: { clave_interna: clave },
      });

      if (response?.status === 200 && response?.data?.length) {
        form.setFieldsValue({
          activo_fijo: response.data[0].id,
        });
        setActivoFound([response.data[0]]);
      } else if (response?.status === 204) {
        message.info('No se encontraron coincidencias');
        setActivoFound([]);
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const busquedaActivoFijoFilter = async () => {
    try {
      setLoading(true);
      await formFiltros.validateFields(['clave']);
      const { clave } = formFiltros.getFieldsValue();
      const response = await API.get('controlpatrimonial/activos-fijos/', {
        params: { clave_interna: clave },
      });

      if (response?.status === 200 && response?.data?.length) {
        formFiltros.setFieldsValue({
          activo_fijo: response.data[0].id,
        });
        setActivoFound([response.data[0]]);
      } else if (response?.status === 204) {
        message.info('No se encontraron coincidencias');
        setActivoFound([]);
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const resetAndValidateActivo = () => {
    setActivoFound([]);
    form.resetFields(['clave']);
    form.validateFields(['clave']);
    formFiltros.resetFields(['clave']);
    formFiltros.validateFields(['clave']);
  };

  return (
    <Row className="container" justify="center">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible ? (
          <>
            <Form
              form={formFiltros}
              layout="vertical"
              name="general"
              onFinish={() => onFinishFiltros()}
              className={!mutable && 'without-feedback'}
              scrollToFirstError
              initialValues={{
                ocultar_activos: false,
                recalculo: false,
                recalculo_borrado: false,
              }}
            >
              <Row gutter={10}>
                <InputSearchPro
                  label="Activo Fijo"
                  tooltip="Búsqueda por clave de Activo fijo"
                  name="clave"
                  disabled={!mutable}
                  rules={rules.required}
                  onPressEnter={() => busquedaActivoFijoFilter()}
                  breakPoints={{
                    md: 16,
                  }}
                  inputProps={{
                    onPressEnter: () => busquedaActivoFijoFilter(),
                    onChange: ({ target: { value } }) => {
                      if (!value) {
                        resetAndValidateActivo();
                      }
                    },
                  }}
                  resultProps={{
                    disabled: !mutable,
                    name: 'activo_fijo',
                    label: 'Activo Fijo',
                    dataSource: activoFound,
                    labelProp: 'descripcion',
                    keyLabelRender: true,
                    keyProp: 'clave_interna',
                    onClear: resetAndValidateActivo,
                    breakPoints: {
                      md: 16,
                    },
                  }}
                />
                {!!selectedRowKeys.length && (
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    label="No. de Documento"
                    name="numero_documento_de_calculo"
                    rules={rules.required}
                  >
                    <Input allowClear />
                    {/* Elimine un disable */}
                  </Form.Item>
                </Col>
                )}
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    label="Tipo de Activo"
                    name="tipo_activo"
                    rules={rules.required}
                  >
                    <Select dataSource={tiposDeActivos} disabled={!mutable} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    label="Clasificación de Activo"
                    name="clasificacion_activo"
                  >
                    <Select dataSource={clasificacionesDeActivos} disabled={!mutable} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    label="Fecha Final del Periodo de Cálculo"
                    name="fecha_ultima_de_calculo_aplicado"
                    rules={rules.required}
                  >
                    <DatePicker placeholder="" format={DATE_FRONTEND_FORMAT} disabled={!mutable} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    label="Ocultar Activos con Calculo Final"
                    name="ocultar_activos"
                  >
                    <Select
                      trueFalse
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    label="Fecha de Registro Contable"
                    name="fecha_de_registro_contable"
                  >
                    <DatePicker
                      placeholder=""
                      disabled={!mutable}
                      format={DATE_FRONTEND_FORMAT}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    label="Recálculo Depreciación, Amortización y/o Deterioro"
                    name="recalculo"
                  >
                    <Select
                      trueFalse
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    label="Borrar Recálculo Depreciación, Amortización y/o Deterioro"
                    name="recalculo_borrado"
                  >
                    <Select
                      trueFalse
                    />
                  </Form.Item>
                </Col>
                <Form.Item
                  label=" "
                >
                  {/* <Button htmlType="submit" /> */}
                  <Button onClick={() => onFinishFiltros()}>Calcular</Button>
                </Form.Item>
              </Row>
            </Form>
            <Table
              cols={columns}
              permission={permission}
              data={data}
              rowSelection={rowSelection}
              handleOnRowClick={handleOnRowClick}
              controls={{
                onClickAdd,
                onClickEdit,
                onClickDelete,
              }}
              allowImport
              baseURI={baseURI}
              mobileColIndex={0}
            />
          </>
        ) : (
          <Card
            bordered={false}
            className="form-container"
            title={`${selectedRowKeys.length ? 'Editar' : 'Agregar'}`}
            extra={(
              <FormSubmitControls
                onCancel={onCancel}
                onFinish={() => onFinish()}
                allowAuthorize
                requireAutorizationDate
                requireCancelDate
                allowCancel
                loading={loading}
                mutable={mutable}
                selectedRowKeys={selectedRowKeys}
                baseURI={baseURI}
                callback={(estados_globales) => {
                  const [key] = selectedRowKeys;
                  const normalized = [...data]
                    .map((e) => (e.id === key ? { ...e, estados_globales } : e));
                  const match = normalized.find((e) => e.id === key);
                  form.setFieldsValue(match);
                  setMutable(false);
                  setData(normalized);
                }}
              />
            )}
          >
            <Tabs
              activeKey={currentTabKey}
              onChange={setCurrentTabKey}
              type="card"
            >
              <TabPane
                key="general"
                tab="General"
                forceRender
              >
                <Form
                  form={form}
                  layout="vertical"
                  name="general"
                  onFinish={() => onFinish()}
                  className={!mutable && 'without-feedback'}
                  scrollToFirstError
                  initialValues={{
                    estados_globales: 1,
                  }}
                >
                  <Row gutter={10}>
                    {!!selectedRowKeys.length && (
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          label="No. de Documento"
                          name="numero_documento_de_calculo"
                          rules={rules.required}
                        >
                          <Input allowClear />
                          {/* Elimine un disable */}
                        </Form.Item>
                      </Col>
                    )}
                    <InputSearchPro
                      label="Activo Fijo"
                      tooltip="Búsqueda por clave de Activo fijo"
                      name="clave"
                      disabled={!mutable}
                      rules={rules.required}
                      onPressEnter={() => busquedaActivoFijo()}
                      breakPoints={{
                        md: 16,
                      }}
                      inputProps={{
                        onPressEnter: () => busquedaActivoFijo(),
                        onChange: ({ target: { value } }) => {
                          if (!value) {
                            resetAndValidateActivo();
                          }
                        },
                      }}
                      resultProps={{
                        disabled: !mutable,
                        name: 'activo_fijo',
                        label: 'Activo Fijo',
                        dataSource: activoFound,
                        labelProp: 'descripcion',
                        keyLabelRender: true,
                        keyProp: 'clave_interna',
                        onClear: resetAndValidateActivo,
                        breakPoints: {
                          md: 16,
                        },
                      }}
                    />
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        label="Tipo de Activo"
                        name="tipo_activo"
                        rules={rules.required}
                      >
                        <Select dataSource={tiposDeActivos} disabled={!mutable} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={16}>
                      <Form.Item
                        label="Fecha Inicial y Fecha Final"
                        name="fecha_inicial_y_final"
                        rules={rules.required}
                      >
                        <RangePicker
                          placeholder=""
                          format={DATE_FRONTEND_FORMAT}
                          disabled={!mutable}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        label="Clasificación"
                        name="clasificacion_activo"
                        rules={rules.required}
                      >
                        <Select dataSource={clasificacionesDeActivos} disabled={!mutable} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        label="Fecha de Cálculo"
                        name="fecha_de_calculo"
                        rules={rules.required}
                      >
                        <DatePicker placeholder="" format={DATE_FRONTEND_FORMAT} disabled={!mutable} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        label="Fecha de Baja"
                        name="fecha_de_baja"
                      >
                        <DatePicker
                          placeholder=""
                          disabled={!mutable}
                          format={DATE_FRONTEND_FORMAT}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        label="Fecha de Registro Contable"
                        rules={rules.required}
                        name="fecha_de_registro_contable"
                      >
                        <DatePicker
                          placeholder=""
                          disabled={!mutable}
                          format={DATE_FRONTEND_FORMAT}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        label="Estado"
                        name="estados_globales"
                        rules={rules.required}
                      >
                        <Select
                          dataSource={estadosGlobales}
                          disabled={!selectedRowKeys.length || !mutable}
                        />
                      </Form.Item>
                    </Col>
                    {currentItem?.estados_globales === 4 && (
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        label="Fecha de Autorización"
                        name="fecha_de_autorizacion"
                      >
                        <DatePicker
                          placeholder=""
                          format={DATE_FRONTEND_FORMAT}
                          disabled
                        />
                      </Form.Item>
                    </Col>
                    )}
                    {currentItem?.estados_globales === 5 && (
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        label="Fecha de Cancelación"
                        name="fecha_de_cancelacion"
                      >
                        <DatePicker
                          placeholder=""
                          format={DATE_FRONTEND_FORMAT}
                          disabled
                        />
                      </Form.Item>
                    </Col>
                    )}
                  </Row>
                  <Form.Item hidden>
                    <Button htmlType="submit" />
                  </Form.Item>
                </Form>
              </TabPane>
              <TabPane
                key="config"
                tab="Opciones"
                forceRender
              >
                <Form
                  scrollToFirstError
                  onFinish={() => onFinish()}
                  form={formConfig}
                  className={!mutable && 'without-feedback'}
                  layout="vertical"
                  name="config"
                  initialValues={{
                    ocultar_activos_con_calculo_total: false,
                    recalculo: false,
                    recalculo_borrado: false,
                    calculo_total: false,
                  }}
                >
                  <Row gutter={10}>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        label="Recálculo"
                        name="recalculo"
                        rules={rules.required}
                        tooltip="Si el valor de este campo es 'Si' el registro se pondrá en estado inactivo."
                      >
                        <Select trueFalse disabled={!mutable} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        label="Borrar Recálculo"
                        name="recalculo_borrado"
                        rules={rules.required}
                        tooltip="Si el valor de este campo es 'Si' el registro se cancelará"
                      >
                        <Select trueFalse disabled={!mutable} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="calculo_total"
                        label="Cálculo Total"
                      >
                        <Select trueFalse disabled={!mutable} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        label="Ocultar activos con Cálculo Total"
                        name="ocultar_activos_con_calculo_total"
                        rules={rules.required}
                      >
                        <Select trueFalse disabled={!mutable} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item hidden>
                    <Button htmlType="submit" />
                  </Form.Item>
                </Form>
              </TabPane>
              <TabPane
                key="calculos"
                tab="Cálculos"
                forceRender
              >
                <Form
                  form={formCalculos}
                  onFinish={() => onFinish()}
                  layout="vertical"
                  className={!mutable && 'without-feedback'}
                  scrollToFirstError
                  name="calculos"
                >
                  <Row gutter={10}>
                    <Col xs={24} sm={24} md={8}>
                      <NumericInput
                        name="depreciacion"
                        label="Depreciación"
                        decimal
                        disabled={!mutable}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <NumericInput
                        name="amortizacion"
                        label="Amortización"
                        decimal
                        disabled={!mutable}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <NumericInput
                        name="deterioro"
                        label="Deterioro"
                        decimal
                        disabled={!mutable}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <NumericInput
                        name="monto_total"
                        label="Monto Total"
                          // required
                        decimal
                        disabled={!mutable}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <NumericInput
                        name="dias_de_calculo_total"
                        label="Días de Calculo Total"
                        disabled={!mutable}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <NumericInput
                        name="dias_de_calculo_acumulado"
                        label="Días de Calculo Acumulado"
                        disabled={!mutable}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <NumericInput
                        name="dias_por_aplicar_calculo"
                        label="Días por Aplicar Cálculo"
                        disabled={!mutable}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <NumericInput
                        name="monto_acumulado"
                        label="Monto Acumulado"
                        disabled={!mutable}
                        rules={rules.decimal_rules}
                        decimal
                      />
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <NumericInput
                        name="monto_de_calculo_del_periodo"
                        label="Monto de cálculo del periodo"
                        disabled={!mutable}
                        rules={rules.decimal_rules}
                        decimal
                      />
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <NumericInput
                        name="saldo_por_aplicar"
                        label="Saldo por Aplicar"
                        disabled={!mutable}
                        decimal
                        allowNegative
                      />
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        label="Fecha de último Cálculo"
                        name="fecha_ultima_de_calculo_aplicado"
                      >
                        <DatePicker
                          placeholder=""
                          format={DATE_FRONTEND_FORMAT}
                          disabled={!mutable}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item hidden>
                    <Button htmlType="submit" />
                  </Form.Item>
                </Form>
              </TabPane>
            </Tabs>
          </Card>
        )}
      </Spin>
      <ModalDelete
        onDelete={deleteItem}
        onCancel={() => {
          setVisibleAlert(false);
          setSelectedRowKeys([]);
        }}
        visible={visibleAlert}
        content="eliminar registro seleccionado"
      />
    </Row>
  );
}

export default DepreciacionAmortizacionYDeterioros;
