import React from 'react';
import SingleForm from 'components/SingleForm';

export const permissionAfianzadora = {
  permissionModel: 'afianzadora',
};

// eslint-disable-next-line react/prop-types
function Fianzas({ permission }) {
  return (
    <SingleForm
      baseURI="obrapublica/afianzadora/"
      title="Fianzas"
      permission={permission}
    />
  );
}

export default Fianzas;
