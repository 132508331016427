import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import { useSelector } from 'react-redux';
import {
  Row,
  Col,
  Spin,
  Form,
  Button,
  Input,
  // Modal,
  // DatePicker,
  // Card,
  Modal,
  TreeSelect,
} from 'antd';
import FormSubmitControls from 'components/FormSubmitControls';
import Table from 'components/Table';
import ModalDelete from 'components/ModalDelete';
import API from 'utils/api';
import { onError, onSuccess } from 'utils/handlers';
// import Select from 'components/Select';
import { isMutable } from 'utils/estadosGlobales';
import Select from 'components/Select';
import { useSelector } from 'react-redux';

const baseURI = '/presupuestos/area-de-programa-presupuestario-mir/';

function AreasMIR({
  selectedRowKey,
  activateAdd,
  setAreas,

}) {
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  const periodoFiscal = useSelector(({ auth }) => auth.periodoFiscalSelected);
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [mutable, setMutable] = useState(true);

  const [treeData, setTreeData] = useState([]);
  const [contentTypes, setContentTypes] = useState([]);

  const [clasificacionProgramatica, setClasificacionProgramatica] = useState([]);
  const [clasificacionFuncionales, setClasificacionFuncionales] = useState([]);
  const [funcionPresupuestal, setFuncionPresupuestal] = useState([]);
  const fetchData = async () => {
    try {
      setLoading(true);
      if (selectedRowKey.length !== 0) {
        setData([]);
        const res = await API.get(baseURI, {
          params: {
            programa_presupuestario_mir: selectedRowKey[0],
          },
        });
        setData(res.data);
        setAreas(res.data);
      } else {
        setData([]);
      }

      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const getURValuesToSend = () => {
    const unidad_responsable = form.getFieldValue('area_responsable');
    if (unidad_responsable) {
      const [object_id, nivel] = unidad_responsable.split('/');
      const content_type = contentTypes.find((ct) => ct.nivel.toString() === nivel).id;
      return { object_id, content_type };
    }
    return { object_id: undefined, content_type: undefined };
  };

  const getURForTreeSelect = (_data) => {
    if (_data.area) {
      const { nivel } = contentTypes.find((ct) => ct.id === _data.area);
      const { area_content_id } = _data;
      setTimeout(() => {
        form.setFieldsValue({ area_responsable: `${area_content_id}/${nivel}` });
      });
    }
  };

  const normalizeDataUrs = (_data) => {
    if (_data) {
      const output = _data.map((item) => {
        const clone = { ...item };
        const unidad_responsable = normalizeDataUrs(clone.unidad_responsable);
        const centro_de_costo = normalizeDataUrs(clone.centro_de_costo);
        if (!unidad_responsable.length && !centro_de_costo.length) {
          delete clone.unidad_responsable;
          delete clone.centro_de_costo;
          return ({
            title: clone.descripcion,
            value: `${clone.id}/${clone.nivel}`,
          });
        }
        if (unidad_responsable) {
          return ({
            title: clone.descripcion,
            value: `${clone.id}/${clone.nivel}`,
            children: unidad_responsable,
            // disabled: true,
          });
        }
        return ({
          title: clone.descripcion,
          value: `${clone.id}/${clone.nivel}`,
          children: centro_de_costo,
          // disabled: true,
        });
      });
      return output;
    }
    return false;
  };

  useEffect(() => {
    const fetchAll = async () => {
      await fetchData();
      const resUR = await API.get('estructura-organizacional/unidades-de-trabajo');
      const formattedUr = normalizeDataUrs(resUR.data);
      setTreeData(formattedUr);
      const resContentTypes = await API.get('catalogos/content-types-ur');
      setContentTypes(resContentTypes.data);

      const resProgramaticas = await API.get('/catalogos/clasificaciones-programaticas/');
      setClasificacionProgramatica(resProgramaticas.data);

      const resFuncionales = await API.get('/catalogos/clasificaciones-funcionales/');
      setClasificacionFuncionales(resFuncionales.data);

      const resFuncionPresupuesal = await API.get('/catalogos/funcion-presupuestal/');
      setFuncionPresupuestal(resFuncionPresupuesal.data);
    };
    fetchAll();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setSelectedRowKeys([]);
    form.resetFields();
    setVisible(false);
    setMutable(true);
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const contentValues = getURValuesToSend();
      const values = {
        ...form.getFieldValue(),
        area: contentValues?.content_type,
        area_content_id: contentValues?.object_id,
        periodo_fiscal: periodoFiscal.id,
        programa_presupuestario_mir: selectedRowKey[0],
      };

      if (!selectedRowKeys.length) {
        const res = await API.post(baseURI, values);
        if (res?.status === 201) {
          onSuccess(res, 'Agregado correctamente');
          onCancel();
          await fetchData();
        }
      } else {
        const [key] = selectedRowKeys;
        const res = await API.put(`${baseURI}${key}/`, values);
        if (res?.status === 200) {
          onSuccess(res, 'Actualizado correctamente');
          onCancel();
          await fetchData();
        }
      }

      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`${baseURI}${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          setVisibleAlert(false);
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    form.setFieldsValue(record);
    setMutable(isMutable(record));
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const columns = [
    {
      titleText: 'Tipo de Área',
      dataIndex: 'tipo',
      key: 'tipo',
      width: 60,
    },
    {
      titleText: 'Clasificacion Programatica',
      dataIndex: 'clasificacion_programatica',
      key: 'clasificacion_programatica',
      width: 150,
      render: (id) => clasificacionProgramatica.find((e) => e.id === id)?.concepto,
    },
    {
      titleText: 'Clasificacion Funcional',
      dataIndex: 'clasificacion_funcional',
      key: 'clasificacion_funcional',
      width: 150,
      render: (id) => clasificacionFuncionales.find((e) => e.id === id)?.concepto,
    },
    {
      titleText: 'Clasificacion Funcional Presupuestal',
      dataIndex: 'clasificacion_funcional_presupuestal',
      key: 'clasificacion_funcional_presupuestal',
      width: 150,
      render: (id) => funcionPresupuestal.find((e) => e.id === id)?.nombre,
    },
    {
      titleText: 'Es Responsable',
      dataIndex: 'es_responsable',
      key: 'es_responsable',
      width: 60,
      render: (val) => (val ? 'Si' : 'No'),
    },
    {
      titleText: 'Es Ejecutora',
      dataIndex: 'es_ejecutora',
      key: 'es_ejecutora',
      width: 50,
      render: (val) => (val ? 'Si' : 'No'),
    },

  ];

  const requiredRule = {
    required: true,
    message: 'El campo es requerido',
  };

  const rules = {
    required: [requiredRule],
  };

  const onClickEdit = () => {
    setVisible(true);
    const [key] = selectedRowKeys;
    const match = data.find((e) => e.id === key);
    getURForTreeSelect(match);
    setTimeout(() => {
      form.setFieldsValue({
        ...match,
      });
    });
  };

  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        <Table
          cols={columns}
          data={data}
          rowSelection={rowSelection}
          handleOnRowClick={handleOnRowClick}
          allowAdd={activateAdd}
          allowEdit={activateAdd}
          allowDelete={activateAdd}
          loading={loading}
          controls={{
            onClickAdd,
            onClickEdit,
            onClickDelete,
          }}
          mobileColIndex={0}
        />

        <Modal
          visible={visible}
          title={(
            <FormSubmitControls
              label={`${!selectedRowKeys.length ? 'Agregar' : 'Editar'} Area`}
              onFinish={onFinish}
              onCancel={onCancel}
              mutable={mutable}
            />
            )}
          onCancel={onCancel}
          footer={null}
          closable={false}
          maskClosable={!loading}
          keyboard={!loading}
          forceRender
        >
          <Form
            name="general"
            form={form}
            layout="vertical"
            onFinish={onFinish}
            scrollToFirstError
            initialValues={{
              estados_globales: 1,
              es_responsable: false,
              es_ejecutora: false,

            }}
          >
            <Row gutter={10}>
              <Col span={24}>
                <Form.Item
                  rules={rules.required}
                  label="Tipo de Área"
                  name="tipo"
                  hasFeedback
                >
                  <Input
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="¿Es Responsable?"
                  name="es_responsable"
                  hasFeedback
                >
                  <Select
                    trueFalse
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="¿Es Ejecutora?"
                  name="es_ejecutora"
                  hasFeedback
                >
                  <Select
                    trueFalse
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Clasificación Programatica"
                  name="clasificacion_programatica"
                >
                  <Select
                    dataSource={clasificacionProgramatica}
                    labelProp="concepto"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Clasificación Funcional"
                  name="clasificacion_funcional"
                >
                  <Select
                    dataSource={clasificacionFuncionales}
                    labelProp="concepto"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Clasificación Funcional Presupuestal"
                  name="clasificacion_funcional_presupuestal"
                >
                  <Select
                    dataSource={funcionPresupuestal}
                    labelProp="nombre"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Área Responsable"
                  name="area_responsable"
                >
                  <TreeSelect
                    showSearch
                    treeNodeFilterProp="title"
                    style={{ width: '100%' }}
                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                    treeData={treeData}
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="estados_globales"
                  label="Estado"
                  rules={rules.required}
                >
                  <Select
                    dataSource={estadosGlobales}
                  />
                </Form.Item>
              </Col>
              <Form.Item hidden>
                <Button htmlType="submit" />
              </Form.Item>
            </Row>
          </Form>
        </Modal>

        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => setVisibleAlert(false)}
          visible={visibleAlert}
          content="El identificador seleccionado"
          loading={loading}
        />
      </Spin>
    </Row>
  );
}

AreasMIR.propTypes = {
  selectedRowKey: PropTypes.arrayOf(PropTypes.number),
  activateAdd: PropTypes.number,
  setAreas: PropTypes.func.isRequired,

};

AreasMIR.defaultProps = {
  selectedRowKey: [],
  activateAdd: 0,
};
export default AreasMIR;
