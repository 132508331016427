import API from 'utils/api';
import { onError } from 'utils/handlers';

const baseURI = 'adquisiciones/';

/**
 * GET Configuraciones De Requisiciones
 * @returns {Promise<Array<object>>}
 */
export const getConfiguracionesDeRequisiciones = async () => {
  try {
    const response = await API.get(`${baseURI}configuraciones-de-requisiciones`);
    return response.data;
  } catch (err) {
    onError(err);
    return [];
  }
};

/**
 * GET Prioridades
 * @returns {Promise<Array<object>>}
 */
export const getPrioridades = async () => {
  try {
    const response = await API.get(`${baseURI}prioridades-de-las-adquisiciones`);
    return response.data;
  } catch (err) {
    onError(err);
    return [];
  }
};

/**
 * GET Tipos de operaciones
 * @returns {Promise<Array<object>>}
 */
export const getTiposDeOperaciones = async () => {
  try {
    const response = await API.get(`${baseURI}tipos-de-operaciones`);
    return response.data;
  } catch (err) {
    onError(err);
    return [];
  }
};

/**
 * GET Estados de Requisiciones
 * @returns {Promise<Array<object>>}
 */
export const getEstadosDeRequisiciones = async () => {
  try {
    const response = await API.get(`${baseURI}estados-de-las-requisiciones`);
    return response.data;
  } catch (err) {
    onError(err);
    return [];
  }
};
