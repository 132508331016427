import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import { useSelector } from 'react-redux';
import {
  Form,
  Button,
  Row,
  Col,
  Spin,
  Input,
} from 'antd';

import API from 'utils/api';
import { onError, onSuccess } from 'utils/handlers';
import Select from 'components/Select';
import FormItemLabel from 'components/FormItemLabel';
import makeMessager from 'utils/complexFormsMessages';

function CabecerasDePolizas({
  mutable,
  idConfiguracion,
  setFormHeader,
  setCurrentTabKey,
  setCabecera,
  variables,
  loading,
  setLoading,
}) {
  const baseURI = 'configuraciones/configuraciones-de-polizas/:id/cabecera/';
  const [form] = Form.useForm();

  const [tiposDePolizas, setTiposDePolizas] = useState([]);
  const [secciones, setSecciones] = useState([]);
  const [momentosDeIngreso, setMomentosDeIngreso] = useState([]);
  const [momentosDeEgreso, setMomentosDeEgreso] = useState([]);
  const [afectaciones, setAfectaciones] = useState([]);

  const [seccion, setSeccion] = useState();

  const getTitleToToolTip = () => {
    if (variables?.length && variables.some((e) => !e.es_para_detalle)) {
      const mapped = variables
        .filter((e) => !e.es_para_detalle)
        .map((e) => e.nombre);
      return `Variables disponibles para usar [ ${mapped} ]. 
      \n Ejemplo: ${mapped[0]} {${mapped[0]}} `;
    }
    return false;
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      if (idConfiguracion?.length) {
        const [key] = idConfiguracion;
        const uri = baseURI.replace(':id', key);
        const response = await API.get(uri);
        setSeccion(response.data.seccion);
        setCabecera(response.data);
        form.setFieldsValue(response.data);
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const fetchAll = async () => {
    try {
      setLoading(true);

      const resIng = await API.get('/catalogos/momentos-presupuestales-de-ingresos/');
      setMomentosDeIngreso(resIng.data || []);
      const resEgre = await API.get('/catalogos/momentos-presupuestales-de-egresos/');
      setMomentosDeEgreso(resEgre.data || []);
      const resSecciones = await API.get('/configuraciones/secciones-de-polizas/');
      setSecciones(resSecciones.data || []);
      const resTiposPol = await API.get('/configuraciones/tipos-de-polizas/');
      setTiposDePolizas(resTiposPol.data || []);
      const resAfectaciones = await API.get('/catalogos/afectaciones-presupuestales/');
      setAfectaciones(resAfectaciones.data || []);

      await fetchData();

      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const showMsg = makeMessager(setCurrentTabKey, fetchData);

  const onFinishHandler = async (tabKey = null, _continue = true) => {
    try {
      if (tabKey) {
        showMsg(tabKey);
      } else {
        // eslint-disable-next-line no-use-before-define
        await onFinish(null, _continue);
      }
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    form.onFinishHandler = onFinishHandler;
    setFormHeader(form);
    fetchAll();

    return () => API.source.cancel;
    // eslint-disable-next-line
  }, [idConfiguracion]);

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      const [key] = idConfiguracion;
      const uri = baseURI.replace(':id', key);
      const response = await API.post(uri, values);
      if (response?.status === 201) {
        if (!values.id) {
          onSuccess(response.data, 'Agregado correctamente');
        } else {
          onSuccess(response.data, 'Actualizado correctamente');
        }
        await fetchData();
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const rules = {
    required: [
      {
        message: 'El campo es requerido',
        required: true,
      },
    ],
  };

  return (
    <Row align="center" justify="center" className="container">
      <Spin tip="Cargando..." spinning={loading}>
        <Form
          layout="vertical"
          name="cabecera"
          onFinish={onFinish}
          form={form}
          scrollToFirstError
          initialValues={{
            estados_globales: 1,
          }}
        >
          <Row gutter={10}>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="concepto"
                hasFeedback
                rules={rules.required}
                label="Concepto"
                tooltip={getTitleToToolTip()}
              >
                <Input
                  disabled={!mutable}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={16}>
              <Form.Item
                name="descripcion"
                hasFeedback
                rules={rules.required}
                label="Descripción"
                tooltip={getTitleToToolTip()}
              >
                <Input disabled={!mutable} allowClear />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="tipo"
                hasFeedback
                rules={rules.required}
                label="Tipo"
              >
                <Select
                  dataSource={tiposDePolizas}
                  disabled={!mutable}
                  labelProp="nombre"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="seccion"
                hasFeedback
                rules={rules.required}
                label="Sección"
              >
                <Select
                  labelProp="nombre"
                  dataSource={secciones}
                  disabled={!mutable}
                  onChange={setSeccion}
                />
              </Form.Item>
            </Col>
            {seccion === 2 && (
              <>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="afectacion_presupuestal"
                    hasFeedback
                    rules={rules.required}
                    label="Afectación Presupuestal"
                  >
                    <Select
                      dataSource={afectaciones}
                      disabled={!mutable}
                      labelProp="nombre"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="momento_presupuestal_de_egreso"
                    hasFeedback
                    rules={rules.required}
                    label={(
                      <FormItemLabel
                        longTitle="Momento Presupuestal de Egresos"
                        shortTitle="MPE"
                        hideOn={['md', 'lg']}
                      />
                )}
                  >
                    <Select
                      dataSource={momentosDeEgreso}
                      labelProp="nombre"
                      disabled={!mutable}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="momento_presupuestal_de_ingreso"
                    hasFeedback
                    rules={rules.required}
                    label={(
                      <FormItemLabel
                        longTitle="Momento Presupuestal de Ingresos"
                        shortTitle="MPI"
                        hideOn={['md', 'lg']}
                      />
                )}
                  >
                    <Select
                      dataSource={momentosDeIngreso}
                      labelProp="nombre"
                      disabled={!mutable}
                    />
                  </Form.Item>
                </Col>
              </>
            )}
          </Row>
          <Form.Item hidden name="id">
            <Input />
          </Form.Item>
          <Form.Item hidden>
            <Button htmlType="submit" />
          </Form.Item>
        </Form>
      </Spin>
    </Row>
  );
}
CabecerasDePolizas.propTypes = {
  mutable: PropTypes.bool.isRequired,
  setFormHeader: PropTypes.func.isRequired,
  setCurrentTabKey: PropTypes.func.isRequired,
  setCabecera: PropTypes.func.isRequired,
  idConfiguracion: PropTypes.arrayOf(PropTypes.number),
  // eslint-disable-next-line react/forbid-prop-types
  variables: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
};

CabecerasDePolizas.defaultProps = {
  idConfiguracion: [],
};

export default CabecerasDePolizas;
