import React, { useEffect, useMemo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import socketio from 'socket.io-client';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import socketEvents from 'utils/sockets';
import API from 'utils/api';
import {
  UPDATE_ENTIDAD_INFO,
  UPDATE_USER_INFO,
  SET_PERIODO_FISCAL_SELECTED,
} from 'store/reducers/auth';
import { onError } from 'utils/handlers';
import AuthRoute from 'components/AuthRoute';
import { getRoutes as _getRoutes } from 'routes/index';
import Landing from 'views/Landing/index';
import Login from 'views/Auth/Login/index';
import ForgotPassword from 'views/Auth/ForgotPassword';
import RecuperacionDeContrasena from 'views/Auth/RecuperacionDeContrasena';
import ActivarCuenta from 'views/Auth/ActivarCuenta';
import RestablecerContrasena from 'views/Auth/RestablecerContrasena';
import CancelarRestablecerContraseña from 'views/Auth/CancelarRestablecerContrasena';
import { getModelPermission } from 'utils/permission';
import { model as addressModel } from 'components/Direccion';
import Blank from 'routes/blank';
import NotFound from './NotFound';
import Layout from './Layout';

const { REACT_APP_SOCKETS_URL } = process.env;

function AppRouter() {
  const dispatch = useDispatch();
  const { token, periodoFiscalSelected } = useSelector(({ auth }) => auth);
  const { isAuthenticated } = useSelector(({ auth }) => auth);
  const io = useRef();

  useEffect(() => {
    if (token && REACT_APP_SOCKETS_URL) {
      if (!io.current) {
        io.current = socketio(REACT_APP_SOCKETS_URL, { auth: { token } });
      }
      socketEvents(io.current, token);
    } else {
      io.current = null;
    }
  }, [token]);

  useEffect(() => {
    const updateInfo = async () => {
      try {
        const response = await API.get('usuarios/id/');
        const { data } = response;
        const { entidad, periodo_fiscal } = data;
        delete data.entidad;
        if (response?.status === 200) {
          dispatch({
            type: UPDATE_USER_INFO,
            payload: data,
          });
          dispatch({
            type: UPDATE_ENTIDAD_INFO,
            payload: entidad,
          });
          const selected = { ...periodoFiscalSelected };
          if (selected?.vigente && selected?.id !== periodo_fiscal?.id) {
            selected.vigente = false;
          } else if (!selected?.vigente && selected?.id === periodo_fiscal?.id) {
            selected.vigente = true;
          }
          if (selected?.vigente !== periodoFiscalSelected?.vigente) {
            dispatch({
              type: SET_PERIODO_FISCAL_SELECTED,
              payload: selected,
            });
          }
        }
      } catch (err) {
        onError(err);
      }
    };
    if (token) {
      updateInfo();
    }
    // eslint-disable-next-line
  }, [token]);

  const routes = useMemo(() => {
    const getRoutes = (_routes, parentPath = '') => _routes.map((r) => {
      if (r.children) {
        return getRoutes(r.children, parentPath + r.path);
      }
      const addressPermission = getModelPermission(addressModel);
      const permission = getModelPermission(r.permissionModel, r.catalogsModels, r.tabsModels);
      const Component = r.component || Blank;
      if (r.withoutLayout) {
        return (
          <AuthRoute path={parentPath + r.path} type={r.type} key={parentPath + r.path} exact>
            <Component permission={permission} addressPermission={addressPermission} />
          </AuthRoute>
        );
      }
      return (
        <AuthRoute path={parentPath + r.path} type={r.type} key={parentPath + r.path} exact>
          <Layout>
            <Component permission={permission} addressPermission={addressPermission} />
          </Layout>
        </AuthRoute>
      );
    });
    const _routes = _getRoutes();
    if (!isAuthenticated) {
      return null;
    }
    return getRoutes(periodoFiscalSelected?.vigente
      ? _routes : _routes.filter((e, i) => i !== 2));
  }, [periodoFiscalSelected, isAuthenticated]);

  return (
    <Router>
      <div className="app-container">
        <Switch>
          {/* Layout routes */}
          {routes}
          {/* Main redirect */}
          <Redirect exact from="/" to={isAuthenticated ? '/dashboard' : '/landing'} />
          {/* Guest/Auth routes */}
          <Route path="/landing" exact>
            <Landing />
          </Route>
          <AuthRoute path="/inicio-de-sesion">
            <Login />
          </AuthRoute>
          <AuthRoute path="/recuperar-contrasena">
            <ForgotPassword />
          </AuthRoute>
          <AuthRoute path="/recuperacion-de-contrasena">
            <RecuperacionDeContrasena />
          </AuthRoute>
          <AuthRoute path="/activar-cuenta">
            <ActivarCuenta />
          </AuthRoute>
          <AuthRoute path="/restablecer-contrasena">
            <RestablecerContrasena />
          </AuthRoute>
          <AuthRoute path="/cancelar-restablecer-contrasena">
            <CancelarRestablecerContraseña />
          </AuthRoute>
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default AppRouter;
