/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Row,
  Spin,
  Typography,
} from 'antd';
import FormSubmitControls from 'components/FormSubmitControls';
import Table from 'components/Table';
import Select from 'components/Select';
import { onError, onSuccess } from 'utils/handlers';
import API from 'utils/api';
import { InputSearchPro } from 'components/InputSearch';
import ModalDelete from 'components/ModalDelete';
import Supuestos from './Supuestos';

const baseURI = 'presupuestos/matriz-del-indicador-de-resultado/';

function MatrizDeIndicadores({
  selectedRowKey: programa_con_gestion_mir,
  nivelesDeIndicador,
  data,
  setData,
  fetchData,
  setMatrizId,
}) {
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [planFound, setPlanFound] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [mutable, setMutable] = useState(true);
  const [visibleTable, setVisibleTable] = useState(false);

  const onCancel = () => {
    setVisibleTable(false);
    setMatrizId();
    setPlanFound([]);
    setVisible(false);
    form.resetFields();
    setSelectedRowKeys([]);
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      if (programa_con_gestion_mir) {
        if (!selectedRowKeys.length) {
          values.programa_con_gestion_mir = programa_con_gestion_mir;
          const response = await API.post(baseURI, values);
          if (response.status === 201) {
            onSuccess(response, 'Agregado Correctamente');
            await fetchData();
            onCancel();
          }
        } else {
          const [key] = selectedRowKeys;
          values.programa_con_gestion_mir = programa_con_gestion_mir;
          const response = await API.put(`${baseURI}${key}/`, values);
          if (response.status === 200) {
            onSuccess(response, 'Editado Correctamente');
            await fetchData();
            onCancel();
          }
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading, [form]);
    }
  };

  const columns = [
    {
      titleText: 'Nivel',
      dataIndex: 'nivel_del_indicador',
      key: 'nivel_del_indicador',
      width: 200,
      render: (id) => nivelesDeIndicador.find((n) => n.id === id)?.descripcion,
    },
    {
      titleText: 'Resumen Narrativo',
      dataIndex: 'resumen_narrativo',
      key: 'resumen_narrativo',
      width: 350,
    },
    {
      titleText: 'Indicador',
      dataIndex: 'indicador',
      key: 'indicador',
      width: 350,
    },
    {
      titleText: 'Medio de Verificación',
      dataIndex: 'fuente_de_verificacion',
      key: 'fuente_de_verificacion',
      width: 350,
    },
    {
      titleText: 'Estado',
      dataIndex: 'estados_globales',
      key: 'estados_globales',
      width: 160,
      render: (id) => estadosGlobales.find((eg) => eg.id === id)?.descripcion,
    },
  ];

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    setMatrizId(record.id);
    setTimeout(() => {
      form.setFieldsValue(record);
    });
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const onClickEdit = async () => {
    try {
      setLoading(true);
      const [key] = selectedRowKeys;
      const match = data.find((e) => e.id === key);
      const response = await API.get(`catalogos/registro-de-nivel-del-plan-de-desarrollo/${match.linea_de_accion_pmd}`);
      setPlanFound([response.data]);
      setTimeout(() => {
        form.setFieldsValue(match);
      });
      setLoading(false);
      setVisible(true);
      setVisibleTable(true);
    } catch (error) {
      onError(error, setLoading);
    }
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const deleteItem = async () => {
    try {
      const [key] = selectedRowKeys;
      if (programa_con_gestion_mir) {
        if (selectedRowKeys.length) {
          const response = await API.delete(`${baseURI}${key}/`);
          if (response?.status === 204) {
            onSuccess(response, 'Eliminado correctamente');
            onCancel();
            await fetchData();
            setVisibleAlert(false);
          }
        }
      }
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const resetAndValidatePlan = () => {
    setPlanFound([]);
    form.resetFields(['plan_de_desarrollo']);
    form.validateFields(['plan_de_desarrollo']);
  };

  const fetchPlanDeDesarrollo = async () => {
    try {
      await form.validateFields(['plan_de_desarrollo']);
      const plan = form.getFieldValue('plan_de_desarrollo');
      if (plan) {
        setLoading(true);
        const res = await API.get('catalogos/registro-de-nivel-del-plan-de-desarrollo/', {
          params: {
            numero: plan,
            tipo: 3,
          },
        });
        if (res?.status === 200 && res.data?.length) {
          const planDeDesarrollo = res.data[0];
          setPlanFound([planDeDesarrollo]);
          form.setFieldsValue({
            linea_de_accion_pmd: planDeDesarrollo.id,
          });
          await form.validateFields(['plan_de_desarrollo']);
        } else {
          message.info('No se encontraron coincidencias');
          setPlanFound([]);
        }
        setLoading(false);
      }
    } catch (err) {
      onError(err, setLoading);
      setPlanFound([]);
    }
  };

  const requiredRule = {
    required: mutable,
    message: 'Este campo es requerido',
  };

  const rules = {
    required: [requiredRule],
  };

  return (
    <Row className="container" justify="center">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible ? (
          <Table
            cols={columns}
            data={data}
            rowSelection={rowSelection}
            handleOnRowClick={handleOnRowClick}
            controls={{
              onClickAdd,
              onClickEdit,
              onClickDelete,
            }}
            mobileColIndex={0}
          />
        ) : (
          <Card
            bordered={false}
            className="form-container"
            title={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Matriz`}
            extra={(
              <FormSubmitControls
                onCancel={onCancel}
                onFinish={() => onFinish()}
                allowAuthorize
                allowCancel
                loading={loading}
                mutable={mutable}
                selectedRowKeys={selectedRowKeys}
                baseURI={baseURI}
                callback={(estados_globales) => {
                  const [key] = selectedRowKeys;
                  const normalized = [...data]
                    .map((e) => (e.id === key ? { ...e, estados_globales } : e));
                  const match = normalized.find((e) => e.id === key);
                  form.setFieldsValue(match);
                  setMutable(false);
                  setData(normalized);
                }}
              />
            )}
          >
            <Form
              form={form}
              layout="vertical"
              name="general"
              onFinish={() => onFinish()}
              className={!mutable && 'without-feedback'}
              scrollToFirstError
              initialValues={{
                estados_globales: 1,
              }}
            >
              <Row gutter={10}>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    label="Nivel de la MIR"
                    name="nivel_del_indicador"
                  >
                    <Select
                      dataSource={nivelesDeIndicador}
                      onChange={(val) => {
                        if (val === 1) {
                          form.setFieldsValue({
                            nivel: 1,
                          });
                        } else if (val === 2) {
                          form.setFieldsValue({
                            nivel: 2,
                          });
                        } else if (val === 3) {
                          form.setFieldsValue({
                            nivel: 3,
                          });
                        } else if (val === 4) {
                          form.setFieldsValue({
                            nivel: 4,
                          });
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    label="Clave"
                    name="clave"
                  >
                    <Input
                      allowClear
                      disabled={!mutable}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    label="Resumen Narrativo"
                    name="resumen_narrativo"
                    rules={rules.required}
                  >
                    <Input.TextArea
                      autoSize={{ minRows: 4, maxRows: 4 }}
                      allowClear
                      disabled={!mutable}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    label="Indicador"
                    name="indicador"
                    rules={rules.required}
                  >
                    <Input.TextArea
                      autoSize={{ minRows: 4, maxRows: 4 }}
                      allowClear
                      disabled={!mutable}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Typography.Title level={4}>Verificación</Typography.Title>
              <Row gutter={10}>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    label="Fuente de Verificación"
                    name="fuente_de_verificacion"
                    rules={rules.required}
                  >
                    <Input.TextArea
                      autoSize={{ minRows: 4, maxRows: 4 }}
                      allowClear
                      disabled={!mutable}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    label="Medio de Verificación"
                    name="medio_de_verificacion"
                    rules={rules.required}
                  >
                    <Input.TextArea
                      autoSize={{ minRows: 4, maxRows: 4 }}
                      allowClear
                      disabled={!mutable}
                    />
                  </Form.Item>
                </Col>

              </Row>
              <Row gutter={10}>
                <InputSearchPro
                  label="Linea de acción (PMD)"
                  tooltip="Búsqueda por clave de Programa"
                  name="plan_de_desarrollo"
                  disabled={!mutable}
                  // rules={rules.required}
                  onPressEnter={() => { }}
                  breakPoints={{
                    md: 12,
                  }}
                  inputProps={{
                    onPressEnter: () => fetchPlanDeDesarrollo(),
                    onChange: ({ target: { value } }) => {
                      if (!value) {
                        resetAndValidatePlan();
                      }
                    },
                  }}
                  resultProps={{
                    disabled: !mutable,
                    name: 'linea_de_accion_pmd',
                    label: 'Linea de acción (PMD)',
                    dataSource: planFound,
                    labelProp: 'nombre',
                    keyLabelRender: true,
                    keyProp: 'numero',
                    onClear: () => resetAndValidatePlan(),
                    breakPoints: {
                      md: 12,
                    },
                  }}
                />
                <Col xs={24} sm={24} md={4}>
                  <Form.Item
                    label="Nivel"
                    name="nivel"
                    rules={rules.required}
                  >
                    <Input
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    label="Estado"
                    name="estados_globales"
                  >
                    <Select
                      dataSource={estadosGlobales}
                      disabled={!selectedRowKeys.length || !mutable}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                {visibleTable && (
                  <>
                    <Col>
                      <Typography.Title level={4}>Supuestos</Typography.Title>
                    </Col>
                    <Supuestos
                      selectedRowKey={selectedRowKeys[0]}
                    />
                  </>
                )}
              </Row>
              <Form.Item hidden>
                <Button htmlType="submit" />
              </Form.Item>
            </Form>
          </Card>
        )}
      </Spin>
      <ModalDelete
        onDelete={deleteItem}
        onCancel={() => {
          setVisibleAlert(false);
          setSelectedRowKeys([]);
        }}
        visible={visibleAlert}
        content="Eliminar la Matriz seleccionada"
      />
    </Row>
  );
}

MatrizDeIndicadores.propTypes = {
  selectedRowKey: PropTypes.number,
  setMatrizId: PropTypes.number,
  nivelesDeIndicador: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  setData: PropTypes.func.isRequired,
  fetchData: PropTypes.func.isRequired,
};

MatrizDeIndicadores.defaultProps = {
  selectedRowKey: 0,
  setMatrizId: 0,
};

export default MatrizDeIndicadores;
