import React, { useState, useEffect, useRef } from 'react';
import {
  Row,
  Col,
  Spin,
  Form,
  Input,
  Button,
  TreeSelect,
  Modal,
  message,
} from 'antd';
import FormSubmitControls from 'components/FormSubmitControls';
import API from 'utils/api';
import ModalDelete from 'components/ModalDelete';
import Table from 'components/Table';
import { onError, onSuccess } from 'utils/handlers';
// import { integerRegExp } from 'utils/patterns';
import { findItemNested } from 'utils/filters';
import Select from 'components/Select';

const baseURIs = [
  '/catalogos/familia-de-activo-fijo/',
  '/catalogos/grupo-de-activo-fijo/',
  '/catalogos/subgrupo-de-activo-fijo/',
  '/catalogos/subsubgrupo-de-activo-fijo/',
];

const propNames = {
  1: 'familia_de_activo_fijo',
  2: 'grupo_de_activo_fijo',
  3: 'sub_grupo_de_activo_fijo',
  4: 'subsubgrupo_de_activo_fijo',
};

function CatalogosDeActivoFijo() {
  const timeoutFilter = useRef();
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [father, setFather] = useState(false);
  const [data, setData] = useState([]);
  const [dataTree, setDataTree] = useState([]);
  const [selected, setSelected] = useState();

  const [familias, setFamilias] = useState([]);
  const [grupos, setGrupos] = useState([]);
  const [subGrupos, setSubGrupos] = useState([]);
  const [mascara, setMascara] = useState();
  const [separador, setSeparador] = useState();
  const [configMascara, setConfigMascara] = useState();
  const [folio, setFolio] = useState([]);
  // const [subSubGrupos, setSubSubGrupos] = useState([]);

  /**
   * /catalogos/familia-de-activo-fijo/
   *   /catalogos/grupo-de-activo-fijo/
   *     /catalogos/subgrupo-de-activo-fijo/
   *       /catalogos/subgrupo-de-activo-fijo/
   */

  const onClickTitle = (e) => {
    const element = e.target;
    if (element) {
      let parent = element.parentElement;
      parent = parent ? parent.parentElement : null;
      if (parent) {
        const clickableSpan = parent.previousSibling;
        if (clickableSpan) {
          clickableSpan.click();
        }
      }
    }
  };

  const renderTitle = (title, bold) => (
    // eslint-disable-next-line
    <span onClick={onClickTitle} className={bold ? 'bold' : ''}>
      {title}
    </span>
  );

  const normalizeData = (
    _data = [],
    isSelectable = (_item = {}) => (_item.nivel !== 4),
    excludeLvl3 = false,
    currLvl = 1,
  ) => _data.map((item) => {
    const clone = { ...item };
    const children = normalizeData(!excludeLvl3
      || currLvl !== 2 ? clone.children : [], isSelectable, excludeLvl3, currLvl + 1);
    if (!children.length) {
      delete clone.children;
      return ({
        ...clone,
        key: `${clone.id}_${clone.nivel}`,
        title: renderTitle(`${clone.clave} - ${clone.nombre}`, isSelectable(clone)),
        value: `${clone.id}_${clone.nivel}`,
        selectable: isSelectable(clone),
      });
    }
    return ({
      ...clone,
      key: `${clone.id}_${clone.nivel}`,
      children,
      title: renderTitle(`${clone.clave} - ${clone.nombre}`, isSelectable(clone)),
      value: `${clone.id}_${clone.nivel}`,
      selectable: isSelectable(clone),
    });
  });

  const fetchData = async () => {
    try {
      setLoading(true);
      const promises = baseURIs.map((uri) => API.get(uri));
      const [
        _familias,
        _grupos,
        _subGrupos,
        _subSubGrupos,
      ] = await (await Promise.all(promises)).map((response) => response.data);

      const formattedSubgrupos = _subGrupos.map((subgrupo) => ({
        ...subgrupo,
        nivel: 3,
        children: _subSubGrupos
          .filter((subsubgrupo) => subsubgrupo.sub_grupo_de_activo_fijo === subgrupo.id)
          .map((e) => ({ ...e, nivel: 4 })),
      }));
      const formattedGrupos = _grupos.map((grupo) => ({
        ...grupo,
        nivel: 2,
        children: formattedSubgrupos
          .filter((subgrupo) => subgrupo.grupo_de_activo_fijo === grupo.id),
      }));
      const formattedFamilias = _familias.map((familia) => ({
        ...familia,
        nivel: 1,
        children: formattedGrupos.filter((grupo) => grupo.familia_de_activo_fijo === familia.id),
      }));
      const normalizedData = normalizeData(formattedFamilias);
      setData(normalizedData);
      setDataTree(normalizedData);
      setFamilias(formattedFamilias);
      setGrupos(formattedGrupos);
      setSubGrupos(formattedSubgrupos);
      // setSubSubGrupos(_subSubGrupos);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    const fetchAll = async () => {
      try {
        setLoading(true);
        const _configMask = (await API.get('configuraciones/configuraciones-de-mascaras/')).data;
        const _configMascara = _configMask?.find((c) => c.tipo === 2);
        const _foliador = (await API.get('/configuraciones/configuraciones-de-folios/')).data;
        if (_configMascara) {
          setMascara(_configMascara?.mascara);
          const separator = _configMascara?.separador;
          setSeparador(separator);
          setConfigMascara(_configMascara.id);
        }
        setFolio(_foliador);
        await fetchData();
        setLoading(false);
      } catch (error) {
        onError(error, setLoading);
      }
    };
    fetchAll();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setVisible(false);
    setSelectedRowKeys([]);
    form.resetFields();
    setSelected({ selectable: true });
    setFather(false);
  };

  const getUriAndCatalog = (_values = {}) => {
    if (!_values.father) {
      return {
        uri: baseURIs[0],
        values: _values,
        lvl: 0,
      };
    }
    const key = parseInt(_values.father.split('_')[0], 10);
    const lvl = parseInt(_values.father.split('_')[1], 10);
    return {
      uri: baseURIs[parseInt(_values.father[2], 10)],
      values: {
        ..._values,
        [`${propNames[lvl]}`]: key,
      },
      lvl,
      key,
    };
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const _values = form.getFieldsValue();
      _values.configuracion_de_mascara = configMascara;
      const { uri, values } = getUriAndCatalog(_values);
      if (!values?.id) {
        const response = await API.post(uri, values);
        if (response?.status === 201) {
          onSuccess(response, 'Agregado correctamente', 2.5);
          onCancel();
          await fetchData();
        }
      } else {
        await form.validateFields();
        const response = await API.put(`${uri}${values.id}/`, values);
        if (response?.status === 200) {
          onSuccess(response, 'Actualizado correctamente');
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      const values = form.getFieldsValue();
      const { uri } = getUriAndCatalog(values);
      const match = findItemNested(dataTree, `${values.id}_${values.nivel}`, 'key');
      if (values?.id) {
        if (!match?.children?.length) {
          const response = await API.delete(`${uri}${values.id}/`);
          if (response?.status === 204) {
            onSuccess(response, 'Eliminado correctamente');
            setVisibleAlert(false);
            onCancel();
            await fetchData();
          }
        } else {
          message.warn('No se puede eliminar el registro porque tiene catálogos que dependen de él.');
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    const [id, lvl] = record.key.split('_').map((e) => parseInt(e, 10));
    let key = null;
    if (lvl === 2) {
      const match = familias.find((e) => e.children.some((i) => i.id === id));
      key = `${match?.id}_${match?.nivel}`;
    } else if (lvl === 3) {
      const match = grupos.find((e) => e.children.some((i) => i.id === id));
      key = `${match?.id}_${match?.nivel}`;
    } else if (lvl === 4) {
      const match = subGrupos.find((e) => e.children.some((i) => i.id === id));
      key = `${match?.id}_${match?.nivel}`;
    }
    setFather(lvl !== 1);
    setSelectedRowKeys([record.key]);
    setSelected({ ...record, father: key });
    setTimeout(() => form.setFieldsValue({ ...record, father: key }));
  };

  const setConcatenado = () => {
    const allValues = form.getFieldsValue();
    const { lvl, key } = getUriAndCatalog(allValues);
    const sourcesByLvl = {
      1: familias,
      2: grupos,
      3: subGrupos,
    };

    const _lenght = mascara.split(separador)[lvl].length;
    if (lvl >= 1) {
      const match = sourcesByLvl[lvl].find((e) => e.id === key);
      const concatenado = (match.concatenado || match.clave) + separador + allValues.clave.toString().padStart(_lenght, '0');
      form.setFieldsValue({ concatenado: concatenado.split(separador).map((e) => e.padStart(_lenght, '0')).join(separador) });
    } else {
      const concatenado = allValues.clave.toString().padStart(_lenght, '0');
      form.setFieldsValue({ concatenado });
    }
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const onClickEdit = () => {
    setVisible(true);
    setTimeout(() => setConcatenado());
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const columns = [
    {
      titleText: 'Clave de Nivel',
      dataIndex: 'clave',
      key: 'clave',
      width: 150,
    },
    {
      titleText: 'Clave Secuencial',
      dataIndex: 'concatenado',
      key: 'concatenado',
      width: 150,
    },
    {
      titleText: 'Nombre',
      dataIndex: 'nombre',
      key: 'nombre',
      width: 200,
    },
  ];

  const requiredRule = {
    required: true,
    message: 'El campo es requerido',
  };

  const rules = {
    required: [requiredRule],
    clave: [
      requiredRule,
      {
        validator: async (rule, value) => {
          const _father = form.getFieldValue('father');
          const { lvl } = getUriAndCatalog({ father: _father });
          const _lenght = mascara.split(separador)[lvl]?.length;
          if (value.length > _lenght) {
            throw new Error(`El campo debe tener una longitud máxima de ${_lenght}`);
          }
        },
      },
    ],
  };

  const onSelectTipo = (i) => {
    if (i) {
      const match = findItemNested(dataTree, i, 'key');
      let newClave;
      let beginWithZero = false;
      if (match?.children?.length) {
        const { clave } = match.children[match.children.length - 1];
        beginWithZero = clave[0] === '0';
        newClave = parseInt(clave, 10) + 1;
      } else {
        newClave = `${match.clave}1`;
      }
      if (beginWithZero) {
        newClave = `0${newClave}`;
      }
      form.setFieldsValue({ clave: newClave });
    }
    setFather(!!i);
  };

  const filterTreeNode = (input, node) => {
    const title = node.title?.props.children.toLowerCase() || node.title;
    if (title && title.includes(input.toLowerCase())) {
      return true;
    }
    if (node.children) {
      return filterTreeNode(input, node.children);
    }
    return false;
  };

  const setTimeoutFilter = (input, node) => setTimeout(() => filterTreeNode(input, node), 1000);

  const filterTree = (input, node) => {
    clearTimeout(timeoutFilter.current);
    timeoutFilter.current = setTimeoutFilter(input, node);
    return timeoutFilter ? filterTreeNode(input, node) : true;
  };

  return (
    <Row align="center" justify="center" className="container">
      <Spin tip="Cargando..." spinning={loading}>
        <Table
          cols={columns}
          data={data}
          rowSelection={rowSelection}
          handleOnRowClick={handleOnRowClick}
          controls={{
            onClickAdd,
            onClickEdit,
            onClickDelete,
          }}
          rowKey="key"
          childrenProp="children"
          filterNested
          allowExpand
          allowImport
          allowedExtensions={['xlsx', 'csv', 'json']}
        />
      </Spin>
      <Modal
        visible={visible}
        title={(
          <FormSubmitControls
            label={!selected?.selectable
              ? 'Vista Previa'
              : `${selectedRowKeys.length ? 'Editar' : 'Agregar'}`}
            onFinish={selected?.selectable ? onFinish : null}
            onCancel={onCancel}
            loading={loading}
          />
          )}
        onCancel={onCancel}
        footer={null}
        closable={false}
        keyboard={!loading}
        maskClosable={!loading}
        forceRender
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          scrollToFirstError
          initialValues={{ estados_globales: 1 }}
          onValuesChange={(changedValues) => {
            if ('clave' in changedValues) {
              setConcatenado();
            } else if ('father' in changedValues) {
              setTimeout(() => setConcatenado());
            }
          }}
        >
          <Row>
            {(selected?.father || !selectedRowKeys.length) && (
              <Col span={24}>
                <Form.Item
                  name="father"
                  label="Catálogo Padre"
                  // rules={rules.required}
                >
                  <TreeSelect
                    onChange={onSelectTipo}
                    showSearch
                    treeNodeFilterProp="title"
                    style={{ width: '100%' }}
                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                    treeData={dataTree}
                    allowClear
                    filterTreeNode={filterTree}
                    disabled={!selected?.selectable || selectedRowKeys.length}
                    showArrow={!selectedRowKeys.length}
                  />
                </Form.Item>
              </Col>
            )}
            <Col span={24}>
              <Form.Item
                name="clave"
                label="Clave"
                rules={rules.clave}
              >
                <Input disabled={!!selectedRowKeys.length} allowClear />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="nombre"
                label="Nombre"
                rules={rules.required}
                hasFeedback
              >
                <Input
                  // disabled={!selected?.selectable}
                  allowClear
                />
              </Form.Item>
            </Col>
            {father && (
            <Col span={24}>
              <Form.Item
                name="concatenado"
                label="Clave completa"
                rules={rules.required}
                hasFeedback
              >
                <Input
                  disabled
                />
              </Form.Item>
            </Col>
            )}
            <Col span={24}>
              <Form.Item
                name="foliador"
                label="Foliador"
                hasFeedback
                rules={rules.required}
              >
                <Select dataSource={folio} labelProp="nombre" />
              </Form.Item>
            </Col>
            <Form.Item name="id" hidden>
              <Input disabled />
            </Form.Item>
            <Form.Item name="nivel" hidden>
              <Input disabled />
            </Form.Item>
            <Form.Item hidden>
              <Button htmlType="submit" />
            </Form.Item>
          </Row>
        </Form>
      </Modal>
      <ModalDelete
        onDelete={deleteItem}
        onCancel={() => {
          setVisibleAlert(false);
          onCancel();
        }}
        visible={visibleAlert}
        content="El registro seleccionado"
        loading={loading}
      />
    </Row>
  );
}

export default CatalogosDeActivoFijo;
