import React from 'react';
import SingleForm from 'components/SingleForm';
import { getClasificacionFuncional } from 'api/catalogos/';

export const permissionClasificacionFuncional = {
  permissionModel: 'clasificacionfuncional',
};

// eslint-disable-next-line react/prop-types
function ClasificacionFuncional({ permission }) {
  return (
    <SingleForm
      title="Clasificación Funcional"
      get={getClasificacionFuncional}
      readOnly
      withoutState
      permission={permission}
    />
  );
}

export default ClasificacionFuncional;
