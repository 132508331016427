/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { message, Button, Row } from 'antd';

export default (setCurrentTabKey, fetchData) => (tabKey) => message
  .info({
    content: (
      <>
        <br />
        <Row style={{ width: '100%' }}>
          Los cambios que hayan sido realizados serán descartados.
        </Row>
        <Row align="middle" style={{ width: '100%' }}>
          ¿Desea continuar?
          <Button
            type="link"
            onClick={async () => {
              message.destroy();
              if (fetchData) {
                await fetchData();
              }
              if (setCurrentTabKey) {
                setCurrentTabKey(tabKey);
              }
            }}
          >
            Si
          </Button>
          <Button
            type="link"
            onClick={() => message.destroy()}
          >
            No
          </Button>
        </Row>
      </>
    ),
    duration: 0,
  });
