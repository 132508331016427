import API from 'utils/api';
import getFormattedValues, { formatReceived, hasFiles } from 'utils/formatValues';
import { onSuccess, onError } from 'utils/handlers';

export const baseURI = 'adquisiciones/requisiciones/';

/**
 * GET Requisiciones
 * @returns {Promise<Array<Object>>}
 */
export const getRequisiciones = async () => {
  try {
    const response = await API.get(baseURI);
    return formatReceived(response.data);
  } catch (err) {
    onError(err);
    return [];
  }
};

/**
 * GET Requisición
 * @param {number} id
 * @returns {Promise<object|null>}
 */
export const getRequisicion = async (id) => {
  try {
    const response = await API.get(`${baseURI}${id}`);
    return formatReceived(response.data);
  } catch (err) {
    onError(err);
    return null;
  }
};

/**
 * POST Requisición
 * @param {object} values
 * @returns {Promise<object|boolean>}
 */
export const postRequisicion = async (values) => {
  try {
    const formatedValues = getFormattedValues(values);
    const response = await API.post(baseURI, formatedValues);
    onSuccess(response, 'Agregado correctamente');
    return response.data;
  } catch (err) {
    onError(err);
    return false;
  }
};

/**
 * PATCH Requisición
 * @param {number} id
 * @param {object} values
 * @returns {Promise<object|boolean>}
 */
export const patchRequisicion = async (id, values) => {
  try {
    const response = await API.patch(`${baseURI}${id}`, values);
    onSuccess(response, 'Editado correctamente');
    return response.data;
  } catch (err) {
    onError(err);
    return false;
  }
};

/**
 * DELETE Requisición
 * @param {number} id
 * @returns {Promise<boolean>}
 */
export const deleteRequisicion = async (id) => {
  try {
    const response = await API.delete(`${baseURI}${id}`);
    onSuccess(response, 'Eliminado correctamente');
    return true;
  } catch (err) {
    onError(err);
    return false;
  }
};

// #region CRUD Anexos Requisición
const formatValuesAnexos = (values) => {
  const files = ['documento'];
  const config = hasFiles(values, files) ? {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  } : {};
  const formattedValues = getFormattedValues(values, {
    files,
    formData: config.headers,
    clean: false,
  });
  return formattedValues;
};

/**
 * GET Anexos Requisición
 * @param {number} idRequisicion
 * @returns {Promise<object>}
 */
export const getAnexosRequisicion = async (idRequisicion) => {
  try {
    const response = await API.get(`${baseURI}${idRequisicion}/anexos/`);
    return formatReceived(response.data);
  } catch (err) {
    onError(err);
    return [];
  }
};

/**
 * POST Anexo Requisición
 * @param {number} idRequisicion
 * @param {object} values
 * @returns {Promise<object|boolean>}
 */
export const postAnexoRequisicion = async (idRequisicion, values) => {
  try {
    const response = await API
      .post(`${baseURI}${idRequisicion}/anexos/`, formatValuesAnexos(values));
    onSuccess(response, 'Agregado correctamente');
    return response.data;
  } catch (err) {
    onError(err);
    return null;
  }
};

/**
 * PATCH Anexo Requisición
 * @param {number} idRequisicion
 * @param {number} id
 * @param {object} values
 * @returns {Promise<object|boolean>}
 */
export const patchAnexoRequisicion = async (idRequisicion, id, values) => {
  try {
    const response = await API
      .patch(`${baseURI}${idRequisicion}/anexos/${id}`, formatValuesAnexos(values));
    onSuccess(response, 'Editado correctamente');
    return response.data;
  } catch (err) {
    onError(err);
    return null;
  }
};

/**
 * DELETE Anexo Requisición
 * @param {number} idRequisicion
 * @param {number} id
 * @returns {Promise<boolean>}
 */
export const deleteAnexoRequisicion = async (idRequisicion, id) => {
  try {
    const response = await API.delete(`${baseURI}${idRequisicion}/anexos/${id}`);
    onSuccess(response, 'Eliminado correctamente');
    return true;
  } catch (err) {
    onError(err);
    return false;
  }
};
// #endregion

// #region CRUD Compradores Requisición
/**
 * GET Compradores Requisición
 * @param {number} idRequisicion
 * @returns {Promise<boolean>}
 */
export const getCompradoresRequisicion = async (idRequisicion) => {
  try {
    const response = await API.get(`${baseURI}${idRequisicion}/compradores/`);
    return formatReceived(response.data);
  } catch (err) {
    onError(err);
    return [];
  }
};
/**
 * GET Comprador Requisición
 * @param {number} idRequisicion
 * @param {number} id
 * @returns {Promise<boolean>}
 */
export const getCompradorRequisicion = async (idRequisicion, id) => {
  try {
    const response = await API.get(`${baseURI}${idRequisicion}/compradores/${id}`);
    return formatReceived(response.data);
  } catch (err) {
    onError(err);
    return [];
  }
};

/**
 * POST Comprador requisicion
 * @param {number} idRequisicion
 * @param {object} values
 * @returns {Promise<object|boolean>}
 */
export const postCompradorRequisicion = async (idRequisicion, values) => {
  try {
    const response = await API
      .post(`${baseURI}${idRequisicion}/compradores/`, getFormattedValues(values, {}, true));
    onSuccess(response, 'Agregado correctamente');
    return response.data;
  } catch (err) {
    onError(err);
    return null;
  }
};

/**
 * PATCH Comprador requisicion
 * @param {number} idRequisicion
 * @param {number} id
 * @param {object} values
 * @returns {Promise<object|boolean>}
 */
export const patchCompradorRequisicion = async (idRequisicion, id, values) => {
  try {
    const response = await API
      .patch(`${baseURI}${idRequisicion}/compradores/${id}`, getFormattedValues(values, {}, true));
    onSuccess(response, 'Editado correctamente');
    return response.data;
  } catch (err) {
    onError(err);
    return null;
  }
};

/**
 * DELETE Comprador requisicion
 * @param {number} idRequisicion
 * @param {number} id
 * @returns {Promise<boolean>}
 */
export const deleteCompradorRequisicion = async (idRequisicion, id) => {
  try {
    const response = await API.delete(`${baseURI}${idRequisicion}/compradores/${id}`);
    onSuccess(response, 'Eliminado correctamente');
    return true;
  } catch (err) {
    onError(err);
    return false;
  }
};
// #endregion
