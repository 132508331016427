export default [{
  id: 1,
  fecha: '06/01/2021',
  folio_de_remision: 'RE-2021-0057',
  folio_orden_de_compra: 'CO-2021-06-0036',
  folio_agrupador: 'FA-2021-0004',
  tipo_de_operacion: 'Bienes Almacenables',
  proveedor: 'La Nacional, SA de CV',
  remisiones: 'Única',
  folio_de_recepcion: 'RA-2021-0456',
  estados_globales: 4,
  unidad_responsable: 'Contabilidad',
  lugar_de_entrega: 'Almacén: Av. Juárez No. 521',
  remision: 'Única',
  fecha_de_entrega: '7/15/2021',
  table_data: [{
    id: 1,
    partida_presupuestal: '2021-1-311111-504-M-152-53-001-155-21101-1-11721',
    cog: '21101',
    descripcion: 'Hojas de papel',
    unidad_de_medida: 'Caja',
    cantidad: 8,
    precio_unitario: 1200,
    sub_total: 9600,
    iva: 1536,
    total: 11136,
  },
  {
    id: 2,
    partida_presupuestal: '2021-1-311111-504-M-152-53-001-155-21101-1-11721',
    cog: '21101',
    descripcion: 'Folders beige',
    unidad_de_medida: 'Caja',
    cantidad: 2,
    precio_unitario: 150,
    sub_total: 300,
    iva: 48,
    total: 348,
  },
  {
    id: 3,
    partida_presupuestal: '2021-1-311111-504-M-152-53-001-155-21101-1-11721',
    cog: '21101',
    descripcion: 'Carpetas',
    unidad_de_medida: 'Pieza',
    cantidad: 6,
    precio_unitario: 58,
    sub_total: 348,
    iva: 55.68,
    total: 403.68,
  },
  {
    id: 4,
    partida_presupuestal: '2021-1-311111-504-M-152-53-001-155-21101-1-11721',
    cog: '21101',
    descripcion: 'Plumas',
    unidad_de_medida: 'Caja',
    cantidad: 3,
    precio_unitario: 35,
    sub_total: 105,
    iva: 16.80,
    total: 121.80,
  },
  ],
},
{
  id: 2,
  fecha: '06/18/2021',
  folio_de_remision: 'RE-2021-0058',
  folio_orden_de_compra: 'CO-2021-06-0037',
  folio_agrupador: 'FA-2021-0018',
  tipo_de_operacion: 'Servicios',
  proveedor: 'La Vanguardista, SA de CV',
  remisiones: 'Única',
  folio_de_recepcion: 'RA-2021-0457',
  estados_globales: 4,
  unidad_responsable: 'Contabilidad',
  lugar_de_entrega: 'Almacén: Av. Juárez No. 521',
  remision: 'Única',
  fecha_de_entrega: '7/15/2021',
  table_data: [{
    id: 5,
    partida_presupuestal: '2021-1-311111-510-M-152-83-001-155-21101-1-11721',
    cog: '21101',
    descripcion: 'Cajas archivo muerto',
    unidad_de_medida: 'Pieza',
    cantidad: 12,
    precio_unitario: 65,
    sub_total: 780,
    iva: 124.80,
    total: 904.80,
  },
  {
    id: 6,
    partida_presupuestal: '2021-1-311111-510-M-152-83-001-155-21101-1-11721',
    cog: '21101',
    descripcion: 'Cuadernos',
    unidad_de_medida: 'Pieza',
    cantidad: 6,
    precio_unitario: 44,
    sub_total: 264,
    iva: 42.24,
    total: 306.24,
  },
  {
    id: 7,
    partida_presupuestal: '2021-1-311111-510-M-152-83-001-155-21101-1-11721',
    cog: '21101',
    descripcion: 'Carpetas',
    unidad_de_medida: 'Pieza',
    cantidad: 15,
    precio_unitario: 28,
    sub_total: 870,
    iva: 139.20,
    total: 1009.20,
  },
  {
    id: 8,
    partida_presupuestal: '2021-1-311111-510-M-152-83-001-155-21101-1-11721',
    cog: '21101',
    descripcion: 'Hojas de papel',
    unidad_de_medida: 'Caja',
    cantidad: 4,
    precio_unitario: 1200,
    sub_total: 4800,
    iva: 768,
    total: 5568,
  }],
},
{
  id: 3,
  fecha: '06/19/2021',
  folio_de_remision: 'RE-2021-0059',
  folio_orden_de_compra: 'CO-2021-06-0038',
  folio_agrupador: 'FA-2021-0022',
  tipo_de_operacion: 'Bienes No Almacenables',
  proveedor: 'Integradora, SA de CV',
  remisiones: 'Varias',
  folio_de_recepcion: 'RA-2021-0458',
  estados_globales: 4,
  unidad_responsable: 'Recursos Humanos',
  lugar_de_entrega: 'Almacén: Av. Juárez No. 521',
  remision: 'Única',
  fecha_de_entrega: '7/15/2021',
  table_data: [{
    id: 9,
    partida_presupuestal: '2021-1-311111-504-M-152-53-001-155-21101-1-11721',
    cog: '21101',
    descripcion: 'Grapas estándar',
    unidad_de_medida: 'Caja',
    cantidad: 3,
    precio_unitario: 28,
    sub_total: 84,
    iva: 13.44,
    total: 97.44,
  },
  {
    id: 10,
    partida_presupuestal: '2021-1-311111-504-M-152-53-001-155-21101-1-11721',
    cog: '21101',
    descripcion: 'Plumas',
    unidad_de_medida: 'Caja',
    cantidad: 2,
    precio_unitario: 35,
    sub_total: 70,
    iva: 11.20,
    total: 81.20,
  },
  {
    id: 11,
    partida_presupuestal: '2021-1-311111-504-M-152-53-001-155-21101-1-11721',
    cog: '21101',
    descripcion: 'Folders beige',
    unidad_de_medida: 'Caja',
    cantidad: 3,
    precio_unitario: 150,
    sub_total: 450,
    iva: 72,
    total: 522,
  },
  {
    id: 12,
    partida_presupuestal: '2021-1-311111-504-M-152-53-001-155-21101-1-11721',
    cog: '21101',
    descripcion: 'Hojas de papel',
    unidad_de_medida: 'Caja',
    cantidad: 5,
    precio_unitario: 1200,
    sub_total: 6000,
    iva: 960,
    total: 6960,
  }],
},
];
