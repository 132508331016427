/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Button,
  Input,
  Tooltip,
  Col,
} from 'antd';
import styled from 'styled-components';
import {
  CloseCircleOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import Select from './Select';

function InputSearch({
  disabled,
  label,
  name,
  onChange,
  onClickAdvanced,
  rules,
  title,
  setVisibleBusqueda,
  onPressEnter,
  dataSelect,
  dataSelectLabel,
  inputProps,
  onChangeSelect,
  selectRules,
}) {
  const input = useRef();
  return (
    <Form.Item noStyle style={{ marginBottom: '0px !important' }}>
      <Input.Group style={{ display: 'flex' }} compact>
        {!!dataSelect.length && (
          <Form.Item
            name="content_type"
            style={{
              marginTop: 'auto',
              width: '45%',
              minWidth: 180,
            }}
            rules={selectRules}
            label={dataSelectLabel}
          >
            <Select
              style={{ width: '100%' }}
              dataSource={dataSelect}
              render={(e) => e.model}
              onChange={onChangeSelect}
              onSelect={() => {
                input.current.focus();
              }}
            />
          </Form.Item>
        )}
        <Form.Item
          style={{ width: '100%' }}
          name={name}
          label={(
            <span>
              {label}
              {title && (
                <>
                  {' '}
                  <Tooltip
                    title={title}
                  >
                    <QuestionCircleOutlined />
                  </Tooltip>
                </>
              )}
            </span>
          )}
          rules={rules}
        >
          <Input
            allowClear
            ref={input}
            onChange={onChange}
            disabled={disabled}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...inputProps}
            onPressEnter={(e) => {
              if (e) {
                e.preventDefault();
              }
              onPressEnter();
              if (inputProps.onPressEnter) {
                inputProps.onPressEnter();
              }
            }}
          />
        </Form.Item>
        <Form.Item>
          <Button
            disabled={disabled}
            style={{
              marginTop: 23,
            }}
            onClick={() => {
              onPressEnter();
              if (inputProps.onPressEnter) {
                inputProps.onPressEnter();
              }
            }}
          >
            <SearchOutlined />
          </Button>
        </Form.Item>
        {!!onClickAdvanced && (
          <Form.Item className="primary-on-hover" label="">
            <Button
              onClick={() => {
                if (onClickAdvanced) {
                  onClickAdvanced();
                } else if (setVisibleBusqueda) {
                  setVisibleBusqueda(true);
                }
              }}
              disabled={disabled}
              style={{ marginTop: 23 }}
            >
              ...
            </Button>
          </Form.Item>
        )}

      </Input.Group>
    </Form.Item>
  );
}

InputSearch.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  name: PropTypes.string,
  onChange: PropTypes.func,
  onClickAdvanced: PropTypes.func,
  rules: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  setVisibleBusqueda: PropTypes.func,
  onPressEnter: PropTypes.func,
  dataSelect: PropTypes.arrayOf(PropTypes.object),
  selectRules: PropTypes.arrayOf(PropTypes.object),
  dataSelectLabel: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  inputProps: PropTypes.object,
  onChangeSelect: PropTypes.func,
};

InputSearch.defaultProps = {
  disabled: false,
  label: null,
  name: 'q',
  onChange: null,
  onClickAdvanced: null,
  rules: [],
  title: '',
  setVisibleBusqueda: () => { },
  onPressEnter: () => { },
  dataSelect: [],
  dataSelectLabel: null,
  inputProps: {},
  onChangeSelect: () => { },
  selectRules: [],
};

const defBreakpoints = {
  xs: 24,
  sm: 24,
  md: 8,
};

const getBreakPoints = (resultProps, breakPoints) => {
  if (Array.isArray(resultProps.dataSource)
    ? resultProps.dataSource.length : resultProps.dataSource) {
    return { ...defBreakpoints, ...resultProps.breakPoints } || defBreakpoints;
  }
  return { ...defBreakpoints, ...breakPoints };
};

export function InputSearchPro({
  disabled,
  label,
  name,
  rules,
  onClickAdvanced,
  onPressEnter,
  inputProps,
  prefix,
  tooltip,
  resultProps,
  breakPoints,
}) {
  const inputProp = useRef();
  return (
    <Col {...getBreakPoints(resultProps, breakPoints)}>
      {(Array.isArray(resultProps.dataSource)
        ? resultProps.dataSource.length : resultProps.dataSource)
        ? (
          <FormItemRelative>
            <Form.Item
              name={resultProps.name}
              label={resultProps.label}
              rules={resultProps.rules}
            >
              <Select
                dataSource={(Array.isArray(resultProps.dataSource)
                  ? resultProps.dataSource : [resultProps.dataSource])}
                keyLabelRender={!!resultProps.keyLabelRender}
                keyProp={resultProps.keyProp}
                labelProp={resultProps.labelProp}
                disabled
              />
            </Form.Item>
            {!resultProps.disabled && (<CloseCircleOutlined onClick={resultProps.onClear} />)}
          </FormItemRelative>
        ) : (
          <Form.Item noStyle>
            <Input.Group style={{ display: 'flex' }} compact>
              {!!prefix && (
                <Form.Item
                  name={prefix.name}
                  style={prefix.style}
                  rules={prefix.rules}
                  label={prefix.label}
                >
                  <Select
                    style={{ width: '100%' }}
                    dataSource={prefix.dataSource}
                    render={prefix.render}
                    onChange={prefix.onChange}
                    onSelect={() => {
                      inputProp.current.focus();
                    }}
                  />
                </Form.Item>
              )}
              <Form.Item
                style={{ width: '100%' }}
                name={name}
                label={(
                  <span>
                    {label}
                    {tooltip && (
                      <>
                        {' '}
                        <Tooltip
                          title={tooltip}
                        >
                          <QuestionCircleOutlined />
                        </Tooltip>
                      </>
                    )}
                  </span>
                )}
                rules={rules}
              >
                <Input
                  allowClear
                  ref={inputProp}
                  {...inputProps}
                  onPressEnter={(e) => {
                    if (e) {
                      e.preventDefault();
                    }
                    if (inputProps.onPressEnter) {
                      inputProps.onPressEnter();
                    }
                  }}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  disabled={disabled}
                  style={{
                    marginTop: 23,
                  }}
                  onClick={() => {
                    onPressEnter();
                    if (inputProps.onPressEnter) {
                      inputProps.onPressEnter();
                    }
                  }}
                >
                  <SearchOutlined />
                </Button>
              </Form.Item>
              {!!onClickAdvanced && (
                <Form.Item className="primary-on-hover">
                  <Button
                    onClick={() => {
                      if (onClickAdvanced) {
                        onClickAdvanced();
                      }
                    }}
                    disabled={disabled}
                    style={{ marginTop: 23 }}
                  >
                    ...
                  </Button>
                </Form.Item>
              )}

            </Input.Group>
          </Form.Item>
        )}
    </Col>
  );
}

const FormItemRelative = styled.div`
  position: relative;
  .anticon {
    position: absolute;
    bottom: 8px;
    right: 10px;
    z-index: 2;
  }
`;

InputSearchPro.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  name: PropTypes.string,
  rules: PropTypes.arrayOf(PropTypes.object),
  onClickAdvanced: PropTypes.func,
  onPressEnter: PropTypes.func,
  inputProps: PropTypes.object,
  prefix: PropTypes.object,
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  resultProps: PropTypes.object,
  breakPoints: PropTypes.object,
};

InputSearchPro.defaultProps = {
  disabled: false,
  label: null,
  name: 'q',
  onClickAdvanced: null,
  rules: [],
  onPressEnter: () => { },
  inputProps: {},
  prefix: null,
  tooltip: null,
  resultProps: null,
  breakPoints: {},
};

export default InputSearch;
