import API from 'utils/api';
import { onSuccess, onError } from 'utils/handlers';

const baseURI = 'configuraciones/encabezados-de-documentos/';

/**
 * GET Encabezados De Documentos
 * @returns {Promise<Array<Object>>}
 */
export const getEncabezadosDeDocumentos = async () => {
  try {
    const response = await API.get(baseURI);
    return response.data;
  } catch (err) {
    onError(err);
    return [];
  }
};

/**
 * POST Encabezados De Documentos
 * @param {object} values
 * @returns {Promise<object|boolean>}
 */
export const postEncabezadosDeDocumentos = async (values) => {
  try {
    const response = await API.post(baseURI, values);
    onSuccess(response, 'Agregado correctamente');
    return response.data;
  } catch (err) {
    onError(err);
    return false;
  }
};

/**
 * PATCH Encabezados De Documentos
 * @param {number} id
 * @param {object} values
 * @returns {Promise<object|boolean>}
 */
export const patchEncabezadosDeDocumentos = async (id, values) => {
  try {
    const response = await API.patch(`${baseURI}${id}`, values);
    onSuccess(response, 'Editado correctamente');
    return response.data;
  } catch (err) {
    onError(err);
    return false;
  }
};

/**
 * DELETE Encabezados De Documentos
 * @param {number} id
 * @returns {Promise<boolean>}
 */
export const deleteEncabezadosDeDocumentos = async (id) => {
  try {
    const response = await API.delete(`${baseURI}${id}`);
    onSuccess(response, 'Eliminado correctamente');
    return true;
  } catch (err) {
    onError(err);
    return false;
  }
};
