/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { AuditOutlined } from '@ant-design/icons';
import NivelesDeDesarrollo, { permissionNivelPlanDeDesarrollo } from 'views/Configuracion/Catalogos/NivelesDeDesarrollo/Index';
import ObjetivosDeDesarrolloSustentable, { permissionObjetivoDeDesarrolloSostenible } from 'views/Presupuestos/Catalogos/Objetivos';
// import TipoPlanDeDesarrollo from 'views/
// Configuracion/Catalogos/NivelesDeDesarrollo/TipoPlanDeDesarrollo';
import ProgramasPresupuestariosMIR, { permissionProgramaPresupuestarioMIR } from 'views/PresupuestoBasadoEnResultados/ProgramasPresupuestariosMIR';
import DiagnosticoArbolDeProblemaObjetivo, { permissionDiagnosticoArbolDeProblemasyObjetivos } from 'views/Configuracion/Catalogos/DiagnosticoArbolDeProblemas';
import MedidasDelIndicador, { permissionMedidaDelIndicador } from 'views/Presupuestos/Catalogos/MedidasDelIndicador';
import ProgramasPresupuestariosFID, { permissionProgramaPresupuestarioFID } from 'views/PresupuestoBasadoEnResultados/ProgramasFID/ProgramasFID';
import EjeTransversal, { permissionEjeTransversal } from 'views/PresupuestoBasadoEnResultados/EjeTransversal';
import blank from './blank';

export default {
  title: 'Presupuesto Basado En Resultados',
  icon: () => <AuditOutlined />,
  type: 'private',
  showInSidebar: true,
  component: blank,
  children: [
    {
      title: 'Plan de Desarrollo',
      icon: null,
      component: NivelesDeDesarrollo,
      type: 'private',
      showInSidebar: true,
      ...permissionNivelPlanDeDesarrollo,
    },
    {
      title: 'Diagnósticos, Árbol de Problemas y Objetivos',
      icon: null,
      showInSidebar: true,
      component: DiagnosticoArbolDeProblemaObjetivo,
      type: 'private',
      ...permissionDiagnosticoArbolDeProblemasyObjetivos,
    },
    {
      title: 'Programas Presupuestarios con Gestión MIR',
      icon: null,
      showInSidebar: true,
      component: ProgramasPresupuestariosMIR,
      type: 'private',
      ...permissionProgramaPresupuestarioMIR,
    },
    {
      title: 'Programas Presupuestrarios con Gestión FID',
      icon: null,
      showInSidebar: true,
      component: ProgramasPresupuestariosFID,
      type: 'private',
      ...permissionProgramaPresupuestarioFID,
    },
    // {
    //   title: 'Reportes',
    //   icon: null,
    //   showInSidebar: true,
    //   component: blank,
    //   type: 'private',
    // },
    {
      title: 'Catálogos',
      icon: null,
      showInSidebar: true,
      type: 'private',
      children: [
        {
          title: 'Eje Transversal',
          icon: null,
          component: EjeTransversal,
          type: 'private',
          showInSidebar: true,
          ...permissionEjeTransversal,
        },
        {
          title: 'Medidas del Indicador',
          icon: null,
          showInSidebar: true,
          component: MedidasDelIndicador,
          type: 'private',
          ...permissionMedidaDelIndicador,
        },
        {
          title: 'Objetivos de Desarrollo Sostenible',
          icon: null,
          component: ObjetivosDeDesarrolloSustentable,
          type: 'private',
          showInSidebar: true,
          ...permissionObjetivoDeDesarrolloSostenible,
        },
        // {
        //   title: 'Tipos de Planes De Desarrollo',
        //   icon: null,
        //   component: TipoPlanDeDesarrollo,
        //   type: 'private',
        //   showInSidebar: true,
        // },
      ],
    },
  ],
};
