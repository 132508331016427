import React from 'react';
import SingleForm from 'components/SingleForm';

function ConceptoPenalizaciones() {
  return (
    <SingleForm
      baseURI="obrapublica/tipo-de-concepto-de-penalizacion/"
      title="Concepto Penalizaciones"
    />
  );
}

export default ConceptoPenalizaciones;
