import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Form,
  Input,
  Button,
  Spin,
  Row,
  Col,
  Modal,
  message,
} from 'antd';
import API from 'utils/api';
import { onError } from 'utils/handlers';
import { RFC_PATTERN, createDecimalRegExp } from 'utils/patterns';
import FormSubmitControls from 'components/FormSubmitControls';
import Table from 'components/Table';
import ModalDelete from 'components/ModalDelete';
import Select from 'components/Select';

const baseURI = 'configuraciones/accionistas/';

function Accionistas({
  selectedRowKey,
}) {
  const [decimalRegExp] = createDecimalRegExp(null, { post: 2 });
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      setLoading(true);
      if (selectedRowKey) {
        const response = await API.get(baseURI, {
          params: {
            datos_corporativos: selectedRowKey,
          },
        });
        if (response?.status === 200) {
          setData(response.data);
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchData();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, [selectedRowKey]);

  const onCancel = () => {
    form.resetFields();
    setVisible(false);
    setSelectedRowKeys([]);
  };

  const onFinish = async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue();
      values.datos_corporativos = selectedRowKey;
      if (selectedRowKey) {
        if (!selectedRowKeys.length) {
          const res = await API.post(baseURI, values);
          if (res?.status === 201) {
            onCancel();
            await fetchData();
          }
        } else {
          const [key] = selectedRowKeys;
          const res = await API.put(`${baseURI}${key}/`, values);
          if (res?.status === 200) {
            onCancel();
            await fetchData();
          }
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    form.setFieldsValue(record);
  };

  const onClickAdd = () => {
    onCancel();
    const currentPercentaje = data.reduce((acum, curr) => acum + curr.capital, 0);
    const diff = 100 - currentPercentaje;
    if (diff) {
      setVisible(true);
      form.setFieldsValue({ capital: diff });
    } else {
      message.info('El porcentaje del capital ya ha alcanzado el 100%, actualicé el porcentaje de los demas accionistas de ser necesario');
    }
  };

  const onClickEdit = () => {
    setVisible(true);
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const deleteItem = async () => {
    try {
      const [key] = selectedRowKeys;
      setLoading(true);
      if (selectedRowKey) {
        const response = await API.delete(`${baseURI}${key}/`);
        if (response?.status === 204) {
          setVisibleAlert(false);
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const required = {
    required: true,
    message: 'El campo es requerido',
  };

  const rules = {
    required: [
      required,
    ],
    rfc: [
      {
        required: true,
        message: 'El campo es requerido',
      },
      {
        validator: async (rule, value) => {
          if (value) {
            if (!RFC_PATTERN.test(value) || value.length !== 13) {
              throw new Error('Ingrese un RFC válido');
            }
          }
        },
      },
    ],
    capital: [
      required,
      {
        validator: async (rule, value) => {
          if (value) {
            const [key] = selectedRowKeys;
            const currentPercentaje = (key ? data.filter((e) => e.id !== key) : data)
              .reduce((acum, curr) => acum + curr.capital, 0);
            const diff = 100 - currentPercentaje;
            if (!decimalRegExp.test(value)) {
              throw new Error('Ingrese un entero o decimal válido');
            }
            if (parseFloat(value) > diff) {
              throw new Error(`No puede ser mayor a ${diff}`);
            }
          }
        },
      },
    ],
  };

  const getFullName = (values = null) => {
    const { nombre, apellido_paterno, apellido_materno } = values || form.getFieldsValue();
    return `${nombre} ${apellido_paterno} ${apellido_materno}`.trim();
  };

  const columns = [
    {
      titleText: 'Nombre Completo',
      key: 'nombre',
      dataIndex: 'nombre',
      width: 300,
      render: (_, values) => getFullName(values),
    },
    {
      titleText: 'RFC',
      key: 'rfc',
      dataIndex: 'rfc',
      width: 150,
    },
    {
      titleText: 'Capital',
      key: 'capital',
      dataIndex: 'capital',
      width: 150,
      align: 'right',
      render: (val) => `${val}%`,
    },
    {
      titleText: 'Estado',
      key: 'estados_globales',
      dataIndex: 'estados_globales',
      render: (val) => estadosGlobales.find((e) => e.id === val)?.descripcion,
      width: 150,
    },
  ];

  return (
    <Spin tip="Cargando..." spinning={loading}>
      <Table
        cols={columns}
        data={data}
        rowSelection={rowSelection}
        handleOnRowClick={handleOnRowClick}
        controls={{
          onClickAdd,
          onClickEdit,
          onClickDelete,
        }}
        allowSort={false}
        allowSearch={false}
      />
      <Modal
        visible={visible}
        closable={false}
        maskClosable={!loading}
        keyboard={!loading}
        footer={null}
        title={(
          <FormSubmitControls
            label={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Accionista`}
            onFinish={onFinish}
            onCancel={onCancel}
            loading={loading}
          />
        )}
        onCancel={onCancel}
        forceRender
        width={600}
        destroyOnClose
      >
        <Form
          form={form}
          layout="vertical"
          name="accionista"
          onFinish={onFinish}
          initialValues={{ estados_globales: 1 }}
        >
          <Row>
            <Col span={24}>
              <Form.Item
                label="Nombre(s)"
                name="nombre"
                rules={rules.required}
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Apellido Paterno"
                name="apellido_paterno"
                rules={rules.required}
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Apellido Materno"
                name="apellido_materno"
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="RFC"
                name="rfc"
                rules={rules.rfc}
                normalize={(val) => val?.toUpperCase()}
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Capital (%)"
                name="capital"
                rules={rules.capital}
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Estado"
                name="estados_globales"
                rules={rules.estado}
              >
                <Select disabled={!selectedRowKeys.length} dataSource={estadosGlobales} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item hidden>
            <Button htmlType="submit" />
          </Form.Item>
        </Form>
      </Modal>
      <ModalDelete
        onDelete={deleteItem}
        onCancel={() => setVisibleAlert(false)}
        visible={visibleAlert}
        content={`Accionista ${getFullName()}}`}
        loading={loading}
      />
    </Spin>
  );
}

Accionistas.propTypes = {
  selectedRowKey: PropTypes.number,
};

Accionistas.defaultProps = {
  selectedRowKey: undefined,
};

export default Accionistas;
