import React from 'react';
import SingleForm from 'components/SingleForm';
import {
  getPuestos,
  postPuestos,
  patchPuestos,
  deletePuestos,
} from 'api/estructura-organizacional/puestos';

export const permissionPuestos = {
  permissionModel: 'puesto',
};

// eslint-disable-next-line react/prop-types
function Puestos({ permission }) {
  return (
    <SingleForm
      title="Puestos"
      get={getPuestos}
      post={postPuestos}
      patch={patchPuestos}
      delete={deletePuestos}
      permission={permission}
    />
  );
}
export default Puestos;
