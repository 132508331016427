import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Form,
  Button,
  Row,
  Col,
  Spin,
  Input,
  Card,
  DatePicker,
  message,
} from 'antd';

import { EyeOutlined, SearchOutlined } from '@ant-design/icons';

import Table from 'components/Table';
import FormSubmitControls from 'components/FormSubmitControls';
import API from 'utils/api';
import {
  onError,
} from 'utils/handlers';
import Select from 'components/Select';
import currencyFormater from 'utils/currencyFormatter';
import Previewer from 'components/Previewer';

const { RangePicker } = DatePicker;

const baseURI = 'contabilidad/libro-mayor/';
function LibrosMayores() {
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  const [form] = Form.useForm();
  const [formBusqueda] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState([]);

  const [visiblePreview, setVisiblePreview] = useState(false);
  const [base64, setBase64] = useState();

  const onCancel = () => {
    setVisible(false);
    setSelectedRowKeys([]);
    form.resetFields();
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    form.setFieldsValue(record);
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const columns = [
    {
      titleText: 'Descripción',
      dataIndex: 'descripcion',
      key: 'descripcion',
      width: 100,
    },
    {
      titleText: 'Debe',
      dataIndex: 'debe',
      key: 'debe',
      width: 100,
      align: 'right',
      render: (v) => currencyFormater(v || 0),
    },
    {
      titleText: 'Haber',
      dataIndex: 'haber',
      key: 'haber',
      width: 100,
      align: 'right',
      render: (v) => currencyFormater(v || 0),
    },
    {
      titleText: 'Saldo',
      dataIndex: 'saldo',
      key: 'saldo',
      width: 100,
      align: 'right',
      render: (v) => currencyFormater(v || 0),
    },
    {
      titleText: 'Fecha',
      dataIndex: 'fecha',
      key: 'fecha',
      width: 100,
    },
    {
      titleText: 'Número de Evento',
      dataIndex: 'numero_de_evento',
      key: 'numero_de_evento',
      width: 100,
    },
  ];

  const required = {
    message: 'El campo es requerido',
    required: true,
  };

  const rules = {
    required: [required],
  };

  const fetch = async () => {
    try {
      setLoading(true);
      await formBusqueda.validateFields();
      const values = formBusqueda.getFieldsValue();
      values.fecha_inicial = values.periodos[0].format('DD-MM-YYYY');
      values.fecha_final = values.periodos[1].format('DD-MM-YYYY');
      delete values.periodos;

      const response = await API.post(baseURI, values);
      if (response?.status === 200 && response.data.length) {
        setData(response.data);
      } else {
        message.info('No se encontraron coincidencias');
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onClickPreview = async () => {
    try {
      setLoading(true);
      const [key] = selectedRowKeys;
      const res = await API.get(`${baseURI}${key}/pdf/`);
      if (res?.status === 200 && res.data) {
        const _base64 = window.btoa(unescape(encodeURIComponent(res.data)));
        setBase64(`data:application/pdf;base64,${_base64}`);
        setVisiblePreview(true);
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  // const onClickExpand = () => setVisible(true);

  return (
    <Row align="center" justify="center" className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible ? (
          <>
            <Form form={formBusqueda} layout="vertical">
              <Row gutter={10}>
                <Col xs={24} sm={24} md={10}>
                  <Form.Item
                    label="Periodo"
                    rules={rules.required}
                    name="periodos"
                  >
                    <RangePicker placeholder="" format="DD/MM/YYYY" />
                  </Form.Item>
                </Col>
                <Col style={{ display: 'flex', alignItems: 'center' }}>
                  <Button
                    onClick={fetch}
                    icon={<SearchOutlined />}
                  >
                    Buscar
                  </Button>
                </Col>
              </Row>
            </Form>
            <Table
              cols={columns}
              data={data}
              rowSelection={rowSelection}
              rowKey="id"
              mobileColIndex={0}
              handleOnRowClick={handleOnRowClick}
              // controls={{
              //   onClickExpand,
              // }}
              customActions={[
                {
                  onClick: onClickPreview,
                  icon: <EyeOutlined />,
                  text: 'Vista Previa',
                  disabled: selectedRowKeys.length,
                },
              ]}
              allowExpand
            />
          </>
        ) : (
          <Col span={24} style={{ height: '100%' }}>
            <Card
              className="form-container"
              title={(
                <FormSubmitControls
                  label={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Reserva`}
                  onCancel={onCancel}

                />
              )}
              bordered={false}
            >
              <Form
                layout="vertical"
                form={form}
                scrollToFirstError
                className="without-feedback"
                initialValues={{ estados_globales: 1 }}
              >
                <Row gutter={10}>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="fecha_inicial"
                      label="Fecha de la Solicitud"
                    >
                      <DatePicker disabled format="DD/MM/YYYY" placeholder="" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="fecha_final"
                      label="Fecha de Vencimiento"
                    >
                      <DatePicker format="DD/MM/YYYY" placeholder="" disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="saldo_inicial"
                      label="Saldo Inicial"
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="saldo_final"
                      label="Saldo Final"
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="cargo"
                      label="Cargo"
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="abono"
                      label="Abono"
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="saldo"
                      label="Saldo"
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  {/* <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="cabecera_de_poliza"
                      label="Cabecera de Póliza"
                    >
                      <Select dataSource={cabeceras} disabled />
                    </Form.Item>
                  </Col> */}
                  {/* <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="cuenta_contable"
                      label="Cuenta Contable"
                    >
                      <Select dataSource={cuentasContables} disabled />
                    </Form.Item>
                  </Col> */}
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="estados_globales"
                      label="Estado"
                    >
                      <Select dataSource={estadosGlobales} disabled />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item hidden>
                  <Button htmlType="submit" />
                </Form.Item>
              </Form>
            </Card>
          </Col>
        )}
        <Previewer
          base64={base64}
          baseURL={baseURI}
          id={selectedRowKeys[0]}
          visible={visiblePreview}
          setVisible={setVisiblePreview}
        />
      </Spin>
    </Row>
  );
}

export default LibrosMayores;
