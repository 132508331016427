import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Form,
  Row,
  Spin,
  Col,
  Input,
  InputNumber,
  Card,
  Button,
  DatePicker,
} from 'antd';

import API from 'utils/api';
import {
  onError,
  onSuccess,
  onInfo,
  onWarning,
} from 'utils/handlers';
import currencyFormatter from 'utils/currencyFormatter';
import {
  CheckCircleOutlined,
  FileExcelOutlined,
  FilePdfOutlined,
  ArrowLeftOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import Table from 'components/Table';
import Select from 'components/Select';
import moment from 'moment';
import Rutas from '../../utils/rutas-endpoints';

const OtrosDepositos = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  // const [visibleAlert, setVisibleAlert] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [dataGridOtrosDepositos, setDataGridOtrosDepositos] = useState([]);
  const [dataOtrosDepositos, setDataOtrosDepositos] = useState([]);
  const [dataLiquidacionInterna, setDataLiquidacionInterna] = useState([]);
  const [dataCuentasBancarias, setDataCuentasBancarias] = useState([]);
  const [dataEmpleados, setDataEmpleados] = useState([]);
  const [dataEmpleado, setDataEmpleado] = useState({});
  const [puestoEmpleado, setPuestoEmpleado] = useState('');
  const [waiting, setWaiting] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [onAdd, setOnAdd] = useState(false);
  const [onEdit, setOnEdit] = useState(false);

  const periodoFiscalSelected = useSelector(({ auth }) => {
    const value = form.getFieldValue('fecha');
    if (value
      && !value.isBetween(moment(auth.periodoFiscalSelected.fecha_inicial),
        moment(auth.periodoFiscalSelected.fecha_final))) {
      form.setFieldsValue({
        fecha: null,
      });
      form.validateFields();
    }
    return auth.periodoFiscalSelected;
  });

  const user = useSelector(({ auth }) => auth.user);

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    setSelectedRowKeys([]);
    setWaiting(false);
    setOnAdd(false);
    setOnEdit(false);
    setIsUpdated(false);
  };

  // CODIGO DE PROVEEDOR

  const fetchData = async () => {
    try {
      setLoading(true);
      setDataEmpleados([]);
      setDataLiquidacionInterna([]);
      setDataOtrosDepositos([]);
      setDataCuentasBancarias([]);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const requiredRule = {
    required: true,
    message: 'El campo es requerido',
  };
  const rules = {
    required: [requiredRule],
  };

  useEffect(() => {
    fetchData();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  // METODOS

  const consultarGridOtrosDepositos = async () => {
    setLoading(true);
    const res = await API.get(Rutas.gridOtrosDeposito);
    const otrosDeptos = res.data;
    const gridOtrosDeptos = [];

    for (let i = 0; i < otrosDeptos.length; i += 1) {
      gridOtrosDeptos[i] = {
        id: otrosDeptos[i].id,
        fecha: otrosDeptos[i].fecha,
        numDeposito: otrosDeptos[i].numero_de_deposito,
        concepto_descripcion: otrosDeptos[i].concepto_descripcion,
        nombreEmpleado: `${otrosDeptos[i].data_empleado.nombre} ${otrosDeptos[i].data_empleado.apellido_paterno}`,
        puesto_empleado: otrosDeptos[i].data_empleado.puesto_descripcion,
        area_empleado: otrosDeptos[i].data_empleado.area_usuario,
        importe: otrosDeptos[i].importe,
        referencia: otrosDeptos[i].referencia,
        entrego: otrosDeptos[i].data_liquidacion.concepto,
        nombreElaboro: `${otrosDeptos[i].data_usuario.first_name} ${otrosDeptos[i].data_usuario.last_name}`,
        puestoElaboro: otrosDeptos[i].data_usuario.puesto_usuario,
        areaElaboro: otrosDeptos[i].data_usuario.usuario_area,
        poliza: otrosDeptos[i].data_poliza,
        estado: otrosDeptos[i].estado_descripcion,
        observaciones: otrosDeptos[i].observaciones,
        cuenta_bancaria: `${otrosDeptos[i].data_cuenta_bancaria.numero_de_cuenta} ${otrosDeptos[i].data_cuenta_bancaria.banco_nombre}`,
      };
    }
    setIsUpdated(false);
    setDataGridOtrosDepositos(gridOtrosDeptos);
    setLoading(false);
  };

  const consultarEmpleados = async () => {
    const res = await API.get(Rutas.empleados);
    const { data } = res;
    const empleados = [];

    for (let i = 0; i < data.length; i += 1) {
      empleados[i] = {
        id: data[i].id,
        descripcion: `${data[i].numero_de_empleado} - ${data[i].nombre} ${data[i].apellido_paterno}`,
      };
    }

    setDataEmpleados(empleados);
  };

  const consultarCatalogoOtrosDepositos = async () => {
    const res = await API.get(Rutas.otrosDepositos);
    const dataCatalogo = await res.data;
    const otrosDeptos = dataCatalogo.filter((item) => item.estados_globales === 1);

    setDataOtrosDepositos(otrosDeptos);
  };

  const consultarLiquidacionInterna = async () => {
    const res = await API.get(Rutas.liquidacionInterna);
    const liquidaciones = res.data;

    setDataLiquidacionInterna(liquidaciones);
  };

  const consultarCuentasBancarias = async () => {
    const res = await API.get(Rutas.cuentasBancarias);
    const cuentas = await res.data;

    setDataCuentasBancarias(cuentas);
  };

  const consultarDataEmpleado = async (idEmpleado) => {
    const dataRest = await API.get(`${Rutas.empleados}${idEmpleado}/`);
    const empleadoF = await dataRest.data;
    setDataEmpleado(empleadoF);
    setPuestoEmpleado(empleadoF.puesto_descripcion);

    form.setFieldsValue({
      puesto_empleado: empleadoF.puesto_descripcion,
      area_empleado: empleadoF.area_usuario,
    });
  };

  const consultarUsuario = async () => {
    const resUsuario = await API.get('usuarios/id/');
    const usuario = await resUsuario.data;

    form.setFieldsValue({
      usuario_que_elaboro: `${usuario.id} - ${usuario.first_name} ${usuario.last_name}`,
      puesto_elaboro: usuario.puesto_usuario,
      area_usuario: usuario.usuario_area,
    });
  };

  const crearDataDeposito = (objeto) => {
    const fechaFormat = moment(objeto.fecha);

    const deposito = {

      fecha: fechaFormat,
      numero_de_deposito: objeto.numero_de_deposito,
      concepto_de_otro_de_deposito: objeto.concepto_de_otro_de_deposito,
      concepto_descripcion: objeto.concepto_descripcion,
      empleado: objeto.data_empleado.id,
      datos_empleado: `${objeto.data_empleado.nombre} ${objeto.data_empleado.apellido_paterno}`,
      numero_empleado: objeto.data_empleado.numero_de_empleado,
      puesto_empleado: objeto.data_empleado.puesto_descripcion,
      area_empleado: objeto.data_empleado.area_usuario ? objeto.data_empleado.area_usuario : '',
      importe: objeto.importe,
      referencia: objeto.referencia,
      liquidacion_interna_entregada: objeto.data_liquidacion.id,
      entrego: objeto.data_liquidacion.concepto,
      nombreElaboro: objeto.data_usuario.id,
      usuario_que_elaboro: `${objeto.data_usuario.id} - ${objeto.data_usuario.first_name} ${objeto.data_usuario.last_name}`,
      puesto_elaboro: objeto.data_usuario.puesto_usuario,
      area_usuario: objeto.data_usuario.usuario_area,
      poliza: objeto.data_poliza,
      estados_globales: objeto.estado_descripcion,
      estado_id: objeto.estado_descripcion,
      observaciones: objeto.observaciones,
      cuenta_bancaria: objeto.cuenta_bancaria,

    };

    return deposito;
  };

  const consultarDataDeposito = async (idOtroDeposito) => {
    setLoading(true);
    const resBusqueda = await API.get(`${Rutas.gridOtrosDeposito}${idOtroDeposito}/`);
    const dataObjeto = await resBusqueda.data;
    const dataDeposito = crearDataDeposito(dataObjeto);

    form.setFieldsValue(dataDeposito);
    setLoading(false);
  };

  // EFECTOS

  useEffect(() => {
    consultarGridOtrosDepositos();
  }, []);

  useEffect(() => {
    consultarEmpleados();
  }, [dataEmpleados.length]);

  useEffect(() => {
    consultarCatalogoOtrosDepositos();
  }, [dataOtrosDepositos.length]);

  useEffect(() => {
    consultarLiquidacionInterna();
  }, [dataLiquidacionInterna.length]);

  useEffect(() => {
    const [key] = selectedRowKeys;
    if (waiting) {
      consultarDataDeposito(key);
    }
    // eslint-disable-next-line
  }, [waiting]);

  useEffect(() => {
    if (isUpdated) {
      consultarGridOtrosDepositos();
    }
  }, [isUpdated]);

  useEffect(() => {
    consultarCuentasBancarias();
    // eslint-disable-next-line
  }, [dataCuentasBancarias.length]);

  // FUNCIONES

  function consultarEmpleadoDescripcion(idEmpleado) {
    const empleado = dataEmpleados.find((item) => item.id === idEmpleado);
    const empleadoDesc = empleado.descripcion;

    return empleadoDesc;
  }

  function consultarConceptoDescripcion(idConcepto) {
    const concepto = dataOtrosDepositos.find((item) => item.id === idConcepto);
    const conceptoDesc = concepto.concepto;

    return conceptoDesc;
  }

  // EVENTOS
  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    // setTimeout(() => form.setFieldsValue(record));
    if (record.estado === 'Activo') {
      setOnEdit(true);
    }
  };

  const onHandleSelectChange = (e) => {
    consultarDataEmpleado(e);
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onFinish = async () => { };

  const onClickAdd = () => {
    const fechaSis = moment();
    form.setFieldsValue({
      fecha: fechaSis,
    });
    setSelectedRowKeys([]);
    consultarUsuario();
    setOnAdd(true);

    setVisible(true);
  };

  const disabledDate = (current) => {
    // eslint-disable-next-line
    const val = moment(current).isBetween(periodoFiscalSelected.fecha_inicial, periodoFiscalSelected.fecha_final);
    return !val;
  };

  const onActivar = () => {
    setOnAdd(true);
  };

  const onClickEdit = () => {
    setWaiting(true);
    setVisible(true);
  };

  const onClickDelete = async () => {
    try {
      if (selectedRowKeys.length > 0) {
        const [key] = selectedRowKeys;

        const dataDelete = dataGridOtrosDepositos.find((item) => item.id === key);

        if (dataDelete.estado === 'Autorizado') {
          onInfo('Solo se puede eliminar registros con estado activo.');
        } else {
          const response = await API.patch(`${Rutas.gridOtrosDeposito}${key}/eliminar/`);
          if (response?.status === 200) {
            onSuccess(response, 'Eliminado correctamente.');
            onCancel();
            // Posible setIsUpdated(true);
            consultarGridOtrosDepositos();
          }
        }
      }
    } catch (error) {
      onError(error, setLoading);
    }
  };

  const onAutorizar = () => {
    const [key] = selectedRowKeys;
    const array = [];
    const obj = {
      id: key,
    };

    const rowSelected = dataGridOtrosDepositos.find((item) => item.id === key);

    if (rowSelected.estado === 'Autorizado') {
      onInfo('Acción no realizada, solo se pueden autorizar registros con estado activo.');
    } else {
      obj.id = rowSelected.id;
      array.push(obj);
      const resApi = API.patch(`${Rutas.gridOtrosDeposito}autorizar/`, array);
      onSuccess(resApi, 'Autorizado correctamente.');
      onCancel();
      setIsUpdated(true);
      consultarGridOtrosDepositos();
    }
  };

  const onDownloadPdf = () => { };

  const onDownLoadExcel = () => { };

  const handleOnFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();

      const values = form.getFieldsValue();
      const { fecha } = values;
      const dateFormat = 'YYYY-MM-DD';
      if (fecha !== null || fecha !== '') {
        const date = moment(fecha).format(dateFormat);
        values.fecha = date;
      }
      const fechaFormated = moment(fecha).format(dateFormat);

      // eslint-disable-next-line
      values.concepto_descripcion = consultarConceptoDescripcion(values.concepto_de_otro_de_deposito);
      values.datos_del_empleado = consultarEmpleadoDescripcion(values.empleado);
      values.estados_globales = 1;
      values.usuario_que_elaboro = user.id;

      if (selectedRowKeys.length === 0) {
        // eslint-disable-next-line
        if (!moment(fechaFormated).isBetween(periodoFiscalSelected.fecha_inicial, periodoFiscalSelected.fecha_final)) {
          onWarning('La fecha seleccionada no se encuentra dentro del periodo fiscal.');
          setTimeout(() => {
            onInfo('Por favor, cambie la fecha, a una dentro del periodo fiscal en curso.');
          }, 3500);
        } else {
          const response = await API.post(Rutas.gridOtrosDeposito, values);

          if (response?.status === 201) {
            onSuccess(response, 'Agregado correctamente.');
            onCancel();
            consultarGridOtrosDepositos();
          }
        }
      } else {
        const [key] = selectedRowKeys;
        // eslint-disable-next-line
        if (!moment(fechaFormated).isBetween(periodoFiscalSelected.fecha_inicial, periodoFiscalSelected.fecha_final)) {
          onWarning('La fecha seleccionada no se encuentra dentro del periodo fiscal.');
          setTimeout(() => {
            onInfo('Por favor, cambie la fecha, a una dentro del periodo fiscal en curso.');
          }, 3500);
        } else {
          const response = await API.patch(`${Rutas.gridOtrosDeposito}${key}/`, values);

          if (response.status === 200) {
            onSuccess(response, 'Actualizado correctamente.');
            form.resetFields();
            onCancel();
            consultarGridOtrosDepositos();
          }
        }
      }
      setOnEdit(false);
      setOnAdd(false);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const columns = [
    {
      titleText: 'Fecha',
      dataIndex: 'fecha',
      key: 'fecha',
      width: 200,
    },
    {
      titleText: 'No. de Depósito',
      dataIndex: 'numDeposito',
      key: 'numDeposito',
      width: 200,
    },
    {
      titleText: 'Concepto',
      dataIndex: 'concepto_descripcion',
      key: 'concepto_descripcion',
      width: 200,
    },
    {
      titleText: 'Empleado',
      dataIndex: 'nombreEmpleado',
      key: 'nombreEmpleado',
      width: 200,
    },
    {
      titleText: 'Puesto',
      dataIndex: 'puesto_empleado',
      key: 'puesto_empleado',
      width: 200,
    },
    {
      titleText: 'Área',
      dataIndex: 'area_empleado',
      key: 'area_empleado',
      width: 200,
    },
    {
      titleText: 'Importe',
      dataIndex: 'importe',
      key: 'importe',
      width: 200,
      render: (val) => (val > 0 ? currencyFormatter(val || 0) : '-'),
    },
    {
      titleText: 'Referencia',
      dataIndex: 'referencia',
      key: 'referencia',
      width: 200,
    },
    {
      titleText: 'Cuenta Bancaria',
      dataIndex: 'cuenta_bancaria',
      key: 'cuenta_bancaria',
      width: 250,
    },
    {
      titleText: 'Entregó',
      dataIndex: 'entrego',
      key: 'entrego',
      width: 200,
    },
    {
      titleText: 'Elaboró',
      dataIndex: 'nombreElaboro',
      key: 'nombreElaboro',
      width: 200,
    },
    {
      titleText: 'Puesto',
      dataIndex: 'puestoElaboro',
      key: 'puestoElaboro',
      width: 200,
    },
    {
      titleText: 'Área',
      dataIndex: 'areaElaboro',
      key: 'areaElaboro',
      width: 200,
    },
    {
      titleText: 'Póliza',
      dataIndex: 'poliza',
      key: 'poliza',
      width: 700,
    },
    {
      titleText: 'Estado',
      dataIndex: 'estado',
      key: 'estado',
      width: 200,
    },
    {
      titleText: 'Observaciones',
      dataIndex: 'observaciones',
      key: 'observaciones',
      width: 200,
    },
  ];

  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible ? (
          <Table
            cols={columns}
            data={dataGridOtrosDepositos}
            rowSelection={rowSelection}
            handleOnRowClick={handleOnRowClick}
            mobileColIndex={0}
            controls={{
              onClickAdd,
              onClickEdit,
              onClickDelete,
            }}
            customActions={[
              {
                onClick: onAutorizar,
                text: 'Autorizar',
                icon: <CheckCircleOutlined />,
                disabledBySelection: true,
              },
              {
                onClick: onDownloadPdf,
                icon: <FilePdfOutlined />,
                text: 'Descargar PDF',
                disabledBySelection: false,
              },
              {
                onClick: onDownLoadExcel,
                icon: <FileExcelOutlined />,
                text: 'Descargar Excel',
                disabledBySelection: false,
              },
            ]}
          />
        ) : (
          <Card
            className="form-container"
            title={`${selectedRowKeys.length > 0 ? 'Editar' : 'Agregar'} Depósito`}
            extra={(
              <>
                {(onAdd || onEdit) && (
                  <Button
                    icon={<SaveOutlined />}
                    className="ant-btn ant-btn-link"
                    onClick={handleOnFinish}
                  >
                    Guardar
                  </Button>
                )}

                {(waiting && onEdit) && (
                  <Button
                    icon={<CheckCircleOutlined />}
                    className="ant-btn ant-btn-link"
                    onClick={onAutorizar}
                  >
                    Autorizar
                  </Button>
                )}
                <Button
                  icon={<ArrowLeftOutlined />}
                  className="ant-btn ant-btn-link"
                  onClick={onCancel}
                >
                  Regresar
                </Button>
              </>
            )}
            bordered={false}
          >
            <Form
              form={form}
              layout="vertical"
              name="deposito"
              onFinish={onFinish}
              labelAlign="left"
              labelWrap
              initialValues={{ estados_globales: 'Activo' }}
            >
              <Row gutter={10}>
                <Col xs={12} sm={12} md={8}>
                  <Form.Item
                    name="fecha"
                    label="Fecha"
                    rules={rules.required}
                    hasFeedback
                  >
                    <DatePicker
                      placeholder=""
                      format="DD/MM/YYYY"
                      disabledDate={disabledDate}
                      onChange={onActivar}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={8}>
                  <Form.Item
                    name="numero_de_deposito"
                    label="No. de Depósito"
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={8}>
                  <Form.Item
                    name="concepto_de_otro_de_deposito"
                    label="Concepto"
                    rules={rules.required}
                  >
                    <Select
                      dataSource={dataOtrosDepositos}
                      render={(e) => `${e?.concepto}`}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={8}>
                  <Form.Item
                    name="empleado"
                    label="Empleado"
                    rules={rules.required}
                    hasFeedback
                  >
                    <Select
                      dataSource={dataEmpleados}
                      onChange={(e) => onHandleSelectChange(e)}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={8}>
                  <Form.Item
                    name="puesto_empleado"
                    label="Puesto"
                  >
                    <Input
                      value={puestoEmpleado}
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={8}>
                  <Form.Item
                    name="area_empleado"
                    label="Área"
                  >
                    <Input
                      value={dataEmpleado.numero_de_empleado}
                      render={dataEmpleado.numero_de_empleado}
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={8}>
                  <Form.Item
                    name="importe"
                    label="Importe"
                    rules={rules.required}
                  >
                    <InputNumber
                      formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={8}>
                  <Form.Item
                    name="referencia"
                    label="Referencia"
                    rules={rules.required}
                    hasFeedback
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={24} md={8}>
                  <Form.Item
                    name="cuenta_bancaria"
                    label="Cuenta Bancaria"
                    rules={rules.required}
                  >
                    <Select
                      dataSource={dataCuentasBancarias}
                      render={(e) => `${e?.banco_nombre} - ${e?.numero_de_cuenta}`}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={8}>
                  <Form.Item
                    name="liquidacion_interna_entregada"
                    label="Entregó"
                    rules={rules.required}
                  >
                    <Select
                      dataSource={dataLiquidacionInterna}
                      labelProp="concepto"
                      render={(e) => `${e?.concepto}`}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={8}>
                  <Form.Item
                    name="usuario_que_elaboro"
                    label="Elaboró"
                  >
                    <Input disabled />

                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={8}>
                  <Form.Item
                    name="puesto_elaboro"
                    label="Puesto"
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={8}>
                  <Form.Item
                    name="area_usuario"
                    label="Área"
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={8}>
                  <Form.Item
                    name="poliza"
                    label="Póliza"
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={8}>
                  <Form.Item
                    name="estados_globales"
                    label="estado"
                  >
                    <Input disabled allowClear />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={8}>
                  <Form.Item
                    name="observaciones"
                    label="observaciones"
                  >
                    <Input.TextArea
                      name="observaciones"
                      allowClear
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        )}
      </Spin>
    </Row>
  );
};

export default OtrosDepositos;
