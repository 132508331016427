const numberCount = (str = '') => {
  const match = str.match(/([0-9])\1*/g) || [];
  return Object.fromEntries(match.map((itm) => [parseInt(itm.charAt(0), 10), itm.length]));
};

const parse = (val) => parseInt(`0${val}`, 10) || null;

export const integerNormalizer = (val, allowZero = false) => {
  if (val) {
    const numbersCount = numberCount(val);
    const parsedVal = parse(val);
    if (allowZero && numbersCount['0']) {
      return `${'0'.repeat(numbersCount['0'])}${parsedVal || ''}` || null;
    }
    return parsedVal;
  }
  return null;
};

// Regex to keep only digits and hyppen
const replaceNonDigitAndHyphen = /[^\d-]/g;
// Regex to keep only digits and spaces
const replaceNonDigitAndSpace = /[^\d ]/g;

export const customIntegerNormalizer = (_val, allowZero) => {
  const allowedChars = ['-', ' '];
  let val = _val?.toString() || '';
  const char = [...val].find((c) => allowedChars.includes(c));
  val = val.replace(char === '-' ? replaceNonDigitAndHyphen : replaceNonDigitAndSpace);
  // Replace multiple spaces and hyppen
  val = val.replace(/-+/g, '-').replace(/ +/g, ' ');
  if (char) {
    const split = val.split(char);
    const normalized = split.map((e) => integerNormalizer(e, allowZero));
    return normalized
      .reduce((accum, curr, idx) => `${accum}${curr || ''}${idx < normalized.length - 1 ? char : ''}`, '');
  }
  return integerNormalizer(_val, true);
};

export const toInteger = (val) => val.replace(/[^0-9]+/g, '') || null;
export const toUpper = (val) => val;

export default {
  toInteger,
  toUpper,
  integerNormalizer,
  customIntegerNormalizer,
};
