import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Spin,
  Card,
  Tabs,
  message,
} from 'antd';

import {
  CloseCircleOutlined,
} from '@ant-design/icons';

import {
  SET_MODULOS_DE_POLIZAS,
  SET_DOCUMENTOS_DE_POLIZA,
  SET_VARIABLES_DE_POLIZA,
} from 'store/reducers/catalogs';

import { formatReceived } from 'utils/formatValues';
import API from 'utils/api';
import { onError, onSuccess } from 'utils/handlers';
import Table from 'components/Table';
import FormSubmitControls from 'components/FormSubmitControls';
import ModalDelete from 'components/ModalDelete';
import Select from 'components/Select';
import { isMutable, isAuthorized } from 'utils/estadosGlobales';
import makeMessager from 'utils/complexFormsMessages';
import fetchSome from 'utils/fetchSome';
import CabecerasDePolizas from './CabecerasDePolizas';
import Detalles from './ConfiguracionesDeDetalle';

export const permissionConfiguracionDePoliza = {
  permissionModel: 'configuraciondepoliza',
};

const { TabPane } = Tabs;

const baseURI = '/configuraciones/configuraciones-de-polizas/';

// eslint-disable-next-line react/prop-types
function ConfiguracionDePolizas({ permission }) {
  const {
    estadosGlobales,
    modulosDePolizas,
    documentosDePoliza,
    variablesDePoliza,
  } = useSelector((sate) => sate.catalogs);
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);

  const [folios, setFolios] = useState([]);
  const [mutable, setMutable] = useState(true);
  // const [filteredModulos, setFilteredDocumentos] = useState([]);
  const [filteredDocumentos, setFilteredDocumentos] = useState([]);

  const [cabecera, setCabecera] = useState();
  const [detalles, setDetalles] = useState([]);

  const [currentTabKey, setCurrentTabKey] = useState('general');
  const [formHeader, setFormHeader] = useState();

  const complexForms = [{ key: 'cabecera', formInstance: formHeader }];
  const basicKeys = ['general'];

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await API.get(baseURI);
      setData(formatReceived(res.data));
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const fetchAll = async () => {
    try {
      setLoading(true);

      if (!modulosDePolizas?.length) {
        await fetchSome('configuraciones/modulos-de-polizas/', SET_MODULOS_DE_POLIZAS);
      }
      if (!documentosDePoliza?.length) {
        await fetchSome('configuraciones/documentos-de-polizas/', SET_DOCUMENTOS_DE_POLIZA);
      }

      const resFolios = await API.get('/configuraciones/configuraciones-de-folios/');
      setFolios(resFolios.data);

      if (!variablesDePoliza?.length) {
        await fetchSome('configuraciones/variables-de-polizas/', SET_VARIABLES_DE_POLIZA);
      }

      await fetchData();
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchAll();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setSelectedRowKeys([]);
    setVisible(false);
    form.resetFields();
    setMutable(true);
    setCurrentTabKey('general');
    setCabecera();
  };

  const onChangeModulo = (mod, reset = true) => {
    if (mod) {
      const filtered = documentosDePoliza.filter((e) => e.modulo === mod);
      setFilteredDocumentos(filtered);
      if (reset) {
        form.resetFields(['documento']);
      }
    }
  };

  const onClickEdit = (responseData = null, fromCallback = false) => {
    if (fromCallback) {
      const [key] = selectedRowKeys;
      const match = responseData || data.find((item) => item.id === key);
      setTimeout(() => {
        onChangeModulo(match.modulo, false);
        form.setFieldsValue(match);
      });
    }
    setVisible(true);
  };

  const onFinish = async (_values, _continue = false, isAuthorizing = false) => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();

      if (!selectedRowKeys.length) {
        const response = await API.post(baseURI, values);
        if (response?.status === 201) {
          if (_continue) {
            onSuccess(response, 'Agregado correctamente');
            await fetchData();
            setSelectedRowKeys([response.data.id]);
            onClickEdit(response.data);
          } else {
            onSuccess(response.data, 'Agregado correctamente');
            onCancel();
            await fetchData();
          }
        }
      } else if (!isAuthorizing) {
        const [key] = selectedRowKeys;
        const response = await API.put(`${baseURI}${key}/`, values);
        if (response?.status === 200) {
          if (_continue) {
            await fetchData();
            onClickEdit(response.data);
          } else {
            onSuccess(response, 'Actualizado correctamente');
            onCancel();
            await fetchData();
          }
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading, [form, formHeader], setCurrentTabKey);
    }
  };

  const handleOnFinish = (vals, _continue = false, isAuthorizing = false) => {
    const match = complexForms.find((e) => e.key === currentTabKey);
    if (match) {
      match.formInstance.onFinishHandler(null, _continue);
    } else {
      onFinish(null, _continue, isAuthorizing);
    }
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`${baseURI}${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          setVisibleAlert(false);
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    form.setFieldsValue(record);
    setMutable(isMutable(record));
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const onClickDelete = () => {
    const [key] = selectedRowKeys;
    const match = data.find((e) => e.id === key);
    if (isMutable(match)) {
      setVisibleAlert(true);
    } else if (isAuthorized(match)) {
      message.warn('No se puede eliminar una configuración autorizada');
    } else {
      message.warn('No se puede eliminar una configuración cancelada');
    }
  };

  const columns = [
    {
      titleText: 'Operación',
      dataIndex: 'operacion',
      key: 'operacion',
      width: 300,
    },
    {
      titleText: 'Módulo',
      dataIndex: 'modulo',
      key: 'modulo',
      width: 200,
      render: (val) => modulosDePolizas.find((e) => e.id === val)?.nombre,
    },
    {
      titleText: 'Documento',
      dataIndex: 'documento',
      key: 'documento',
      width: 250,
      render: (val) => documentosDePoliza.find((e) => e.id === val)?.nombre,
    },
    {
      titleText: 'Folio',
      dataIndex: 'folio',
      key: 'folio',
      width: 300,
      render: (val) => folios.find((e) => e.id === val)?.nombre,
    },
    {
      titleText: 'Estado',
      key: 'estados_globales',
      dataIndex: 'estados_globales',
      width: 150,
      render: (val) => estadosGlobales.find((e) => e.id === val)?.descripcion,
    },
  ];

  const requiredRule = {
    required: true,
    message: 'El campo es requerido',
  };

  const rules = {
    required: [requiredRule],
  };

  const showMsg = makeMessager(setCurrentTabKey, fetchData);

  const onChangeTabKey = (key) => {
    const fromBasic = basicKeys.includes(currentTabKey);
    const toBasic = basicKeys.includes(key);
    const match = complexForms.find((e) => e.key === currentTabKey);
    if (!match) {
      if (fromBasic && toBasic) {
        setCurrentTabKey(key);
      } else if (fromBasic) {
        showMsg(key);
      } else {
        setCurrentTabKey(key);
      }
    } else {
      match.formInstance.onFinishHandler(key, true);
    }
  };

  const validateAuthorizable = () => {
    const errors = [];
    let tab = 'detalles';
    if (!cabecera?.id) {
      errors.push('Es necesaria configurar la cabecera');
      tab = 'cabecera';
    }
    if (detalles.length && cabecera?.id) {
      const hasConfigCargo = detalles.filter((e) => e.es_cargo);
      const hasConfigAbono = detalles.filter((e) => !e.es_cargo);
      if (!hasConfigCargo.length) {
        errors.push('Es necesaria una configuración de detalle de póliza para cargos.');
      }
      if (!hasConfigAbono.length) {
        errors.push('Es necesaria una configuración de detalle de póliza para abonos.');
      }
    } else {
      errors.push('Se necesita una configuración de detalle para cargos y otra para abonos');
    }
    if (errors.length) {
      message.warn({
        content: (
          <Col>
            <strong>Para autorizar es requerido complete los siguientes datos:</strong>
            <br />
            <br />
            {errors.map((item, idx) => (
              // eslint-disable-next-line react/no-array-index-key
              <React.Fragment key={idx}>
                <p style={{ margin: 0, display: 'block', textAlign: 'left' }}>
                  <strong style={{ marginRight: 3 }}>
                    {item}
                  </strong>
                </p>
              </React.Fragment>
            ))}
            <Button
              icon={<CloseCircleOutlined />}
              onClick={() => message.destroy()}
              type="link"
              style={{
                position: 'absolute',
                top: -30,
                right: -20,
              }}
            />
          </Col>
        ),
        duration: 6,
      });
      setCurrentTabKey(tab);
      return false;
    }
    return true;
  };

  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible ? (
          <Table
            cols={columns}
            data={data}
            permission={permission}
            rowSelection={rowSelection}
            handleOnRowClick={handleOnRowClick}
            controls={{
              onClickAdd,
              onClickEdit: () => onClickEdit(null, true),
              onClickDelete,
            }}
            mobileColIndex={0}
          />
        ) : (
          <Col span={24} style={{ height: '100%' }}>
            <Card
              className="form-container"
              title={(
                <FormSubmitControls
                  label={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Configuración de Póliza`}
                  onCancel={onCancel}
                  onFinish={basicKeys
                    .concat(complexForms.map((e) => e.key))
                    .includes(currentTabKey)
                    ? handleOnFinish : null}
                  selectedRowKeys={selectedRowKeys}
                  allowAuthorize
                  allowCancel
                  baseURI={baseURI}
                  loading={loading}
                  allowSaveAndContinue
                  validateAuthorizable={validateAuthorizable}
                  callback={(estados_globales) => {
                    const [key] = selectedRowKeys;
                    const normalized = [...data]
                      .map((e) => (e.id === key ? { ...e, estados_globales } : e));
                    setMutable(false);
                    setData(normalized);
                    const match = normalized.find((e) => e.id === key);
                    onClickEdit(match, true);
                  }}
                  mutable={mutable}
                />
              )}
              bordered={false}
            >
              <Tabs
                type="card"
                activeKey={currentTabKey}
                onChange={mutable ? onChangeTabKey : setCurrentTabKey}
              >
                <TabPane
                  tab="General"
                  key="general"
                  forceRender
                >
                  <Form
                    layout="vertical"
                    name="general"
                    form={form}
                    onFinish={(values) => onFinish(values, true)}
                    scrollToFirstError
                    initialValues={{ estados_globales: 1 }}
                  >
                    <Row gutter={10}>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="operacion"
                          rules={rules.required}
                          label="Operación"
                          hasFeedback
                        >
                          <Input
                            allowClear
                            disabled={!mutable}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="modulo"
                          rules={rules.required}
                          label="Módulo"
                          hasFeedback
                        >
                          <Select
                            labelProp="nombre"
                            disabled={!mutable}
                            dataSource={modulosDePolizas}
                            onChange={onChangeModulo}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="documento"
                          rules={rules.required}
                          label="Documento"
                          hasFeedback
                        >
                          <Select
                            dataSource={filteredDocumentos}
                            labelProp="nombre"
                            disabled={!mutable}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="folio"
                          rules={rules.required}
                          label="Folio"
                          hasFeedback
                        >
                          <Select
                            dataSource={folios}
                            disabled={!mutable}
                            render={(e) => `${e.nombre} (${e.separador})`}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="estados_globales"
                          label="Estado"
                          rules={rules.required}
                        >
                          <Select
                            disabled
                            globalStates
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item hidden>
                      <Button htmlType="submit" />
                    </Form.Item>
                  </Form>
                </TabPane>
                <TabPane
                  tab="Cabecera"
                  key="cabecera"
                  forceRender
                  disabled={!selectedRowKeys.length}
                >
                  <CabecerasDePolizas
                    mutable={mutable}
                    idConfiguracion={selectedRowKeys}
                    setFormHeader={setFormHeader}
                    setCurrentTabKey={setCurrentTabKey}
                    setCabecera={setCabecera}
                    variables={variablesDePoliza}
                    loading={loading}
                    setLoading={setLoading}
                  />
                </TabPane>
                <TabPane
                  tab="Detalle"
                  key="detalles"
                  forceRender
                  disabled={!cabecera?.id}
                >
                  <Detalles
                    mutable={mutable}
                    idConfiguracion={selectedRowKeys[0]}
                    setDetalles={setDetalles}
                    modulo={form.getFieldValue('modulo')}
                    documento={form.getFieldValue('documento')}
                    variables={variablesDePoliza}
                    loading={loading}
                    setLoading={setLoading}
                  />
                </TabPane>
              </Tabs>
            </Card>
          </Col>
        )}

        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => setVisibleAlert(false)}
          visible={visibleAlert}
          content={`Configuracion ${form.getFieldValue('operacion')}`}
          loading={loading}
        />
      </Spin>
    </Row>
  );
}

export default ConfiguracionDePolizas;
