import React, { useState, useEffect } from 'react';
import {
  Form,
  Row,
  Spin,
  Col,
  Card,
  Input,
  DatePicker,
  Button,
  Tabs,
  Space,
  Select,
} from 'antd';

import API from 'utils/api';
import { onError } from 'utils/handlers';
import {
  SearchOutlined,
  FileExcelOutlined,
  FilePdfOutlined,

} from '@ant-design/icons';
import Table from 'components/Table';
import FormSubmitControls from 'components/FormSubmitControls';

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

function KardexSaldosMomentos() {
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState('');
  const [visible, setVisible] = useState(true);
  const [mutable, setMutable] = useState(true);
  const fetchData = async () => {
    try {
      setLoading(true);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchData();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const basicKeys = [
    'Kardex',
    'Saldos de Momentos',
  ];

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    setTimeout(() => form.setFieldsValue(record));
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const showTabKardex = () => {
    setVisible(false);
    setCurrentTab('kardex');
    setMutable(true);
  };

  const showTabSaldos = () => {
    setVisible(false);
    setMutable(true);
    setCurrentTab('saldos');
  };

  const onDownLoadExcel = () => {
  };

  const onDownloadPdf = () => {};

  const onChangeTab = () => {};

  const onCancel = () => {
    setVisible(true);
  };

  const onDownLoadExcelKardex = () => {};

  const onDownloadPdfKardex = () => {};

  const onDownloadPdfMomentos = () => {};

  const onDownLoadExcelMomentos = () => {};

  const columns = [
    {
      titleText: 'Clave Presupuestal',
      dataIndex: 'clave_presupuestal',
      key: 'clave_presupuestal',
      width: 200,
    },
    {
      titleText: 'Programa',
      dataIndex: 'programa',
      key: 'programa',
      width: 200,
    },
    {
      titleText: 'Proyecto',
      dataIndex: 'proyecto',
      key: 'proyecto',
      width: 200,
    },
    {
      titleText: 'Clasificador de Objeto de Gasto',
      dataIndex: 'clasificador_obj_gas',
      key: 'clasificador_obj_gas',
      width: 250,
    },
    {
      titleText: 'Área',
      dataIndex: 'area',
      key: 'area',
      width: 200,
    },
    {
      titleText: 'Fuente de Financiamiento',
      dataIndex: 'fuente_financiamiento',
      key: 'fuente_financiamiento',
      width: 250,
    },
    {
      titleText: 'Aprobado',
      dataIndex: 'aprobado',
      key: 'aprobado',
      width: 200,
    },
    {
      titleText: 'Modificaciones',
      dataIndex: 'modificaciones',
      key: 'modificaciones',
      width: 200,
    },
    {
      titleText: 'Vigente',
      dataIndex: 'vigente',
      key: 'vigente',
      width: 200,
    },
    {
      titleText: 'Pre-Comprometido',
      dataIndex: 'pre-comprometido',
      key: 'pre-comprometido',
      width: 200,
    },
    {
      titleText: 'Disponible',
      dataIndex: 'disponible',
      key: 'disponible',
      width: 200,
    },
    {
      titleText: 'Comprometido',
      dataIndex: 'comprometido',
      key: 'comprometido',
      width: 200,
    },
    {
      titleText: 'Devengado',
      dataIndex: 'devengado',
      key: 'devengado',
      width: 200,
    },
    {
      titleText: 'Ejercido',
      dataIndex: 'ejercido',
      key: 'ejercido',
      width: 200,
    },
    {
      titleText: 'Pagado',
      dataIndex: 'pagado',
      key: 'pagado',
      width: 200,
    },
  ];

  const columnsKardex = [
    {
      titleText: 'Fecha',
      dataIndex: 'fecha',
      key: 'fecha',
      width: 200,
    },
    {
      titleText: 'Módulo',
      dataIndex: 'modulo',
      key: 'modulo',
      width: 200,
    },
    {
      titleText: 'Documento',
      dataIndex: 'documento',
      key: 'documento',
      width: 200,
    },
    {
      titleText: 'No. de Folio de Documento',
      dataIndex: 'no_folio_de_documento',
      key: 'no_folio_de_documento',
      width: 250,
    },
    {
      titleText: 'Folio Agrupador',
      dataIndex: 'folio_agrupador',
      key: 'area',
      width: 200,
    },
    {
      titleText: 'Póliza',
      dataIndex: 'poliza',
      key: 'poliza',
      width: 200,
    },
    {
      titleText: 'Referencia',
      dataIndex: 'referencia',
      key: 'referencia',
      width: 200,
    },
    {
      titleText: 'Momento',
      dataIndex: 'momento',
      key: 'momento',
      width: 200,
    },
    {
      titleText: 'Movimiento Presupuestal',
      dataIndex: 'movimiento_presupuestal',
      key: 'movimiento_presupuestal',
      width: 200,
    },
    {
      titleText: 'Autorizaciones',
      dataIndex: 'autorizaciones',
      key: 'autorizaciones',
      width: 200,
    },
    {
      titleText: 'Cancelaciones',
      dataIndex: 'cancelaciones',
      key: 'cancelaciones',
      width: 200,
    },
  ];

  const columnsMomentos = [
    {
      titleText: 'Fecha',
      dataIndex: 'fecha',
      key: 'fecha',
      width: 200,
    },
    {
      titleText: 'Módulo',
      dataIndex: 'modulo',
      key: 'modulo',
      width: 200,
    },
    {
      titleText: 'Documento',
      dataIndex: 'documento',
      key: 'documento',
      width: 200,
    },
    {
      titleText: 'Folio de Documento',
      dataIndex: 'no_folio_de_documento',
      key: 'no_folio_de_documento',
      width: 250,
    },
    {
      titleText: 'Folio Agrupador',
      dataIndex: 'folio_agrupador',
      key: 'area',
      width: 200,
    },
    {
      titleText: 'No. de Póliza',
      dataIndex: 'poliza',
      key: 'poliza',
      width: 200,
    },
    {
      titleText: 'Referencia',
      dataIndex: 'referencia',
      key: 'referencia',
      width: 200,
    },
    {
      titleText: 'Movimiento Presupuestal',
      dataIndex: 'movimiento_presupuestal',
      key: 'movimiento_presupuestal',
      width: 200,
    },
    {
      titleText: 'Aumentos',
      dataIndex: 'aumentos',
      key: 'aumentos',
      width: 200,
    },
    {
      titleText: 'Cancelaciones',
      dataIndex: 'cancelaciones',
      key: 'cancelaciones',
      width: 200,
    },
    {
      titleText: 'Saldo Final',
      dataIndex: 'saldo_final',
      key: 'saldo_final',
      width: 200,
    },
  ];

  const onFinish = () => ({});

  const complexForms = [{ key: '', formInstance: null }];
  const handleOnFinish = (vals, _continue = false) => {
    const match = complexForms.find((e) => e.key === currentTab);
    if (match) {
      match.formInstance.onFinishHandler(null, _continue);
    }
  };

  function ActionButtons() {
    return (
      <Space wrap>
        <Button type="link" onClick={showTabKardex}>
          Kardex
        </Button>
        <Button type="link" onClick={showTabSaldos}>
          Saldos de Momentos
        </Button>
      </Space>
    );
  }

  function AddTabs() {
    return (
      <>
        <Row gutter={10}>
          <Col md={24} sm={24} xs={24}>
            <Form
              layout="vertical"
              labelAlign="left"
              labelWrap
              scrollToFirstError
              name="solicitud"
            >
              <Row gutter={10}>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="clave_presupuestal"
                    label="Clave Presupuestal"
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="segmentos_clave_presupuestal"
                    label="Segmentos de Clave Presupuestal"
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="momento_presupuestal"
                    label="Momento Presupuestal"
                  >
                    <Select allowClear />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="periodo"
                    label="Periodo"
                  >
                    <RangePicker format="DD/MM/YYYY" disabled />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
        <Card
          className="form-container"
          extra={(
            <FormSubmitControls
              onFinish={basicKeys
                .concat(complexForms.map((e) => e.key))
                .includes(currentTab)
                ? handleOnFinish : null}
              onCancel={onCancel}
              loading={loading}
            />
          )}
          bordered={false}
        >
          <Tabs
            activeKey={currentTab}
            onChange={mutable ? setCurrentTab : onChangeTab}
            type="card"
          >
            <TabPane
              tab="Kardex"
              key="kardex"
              forceRender
            >
              <Table
                cols={columnsKardex}
                data={[]}
                rowSelection={rowSelection}
                handleOnRowClick={handleOnRowClick}
                mobileColIndex={0}
                customActions={[
                  {
                    onClick: onDownloadPdfKardex,
                    icon: <FilePdfOutlined />,
                    text: 'Descargar PDF',
                    disabledBySelection: false,
                  },
                  {
                    onClick: onDownLoadExcelKardex,
                    icon: <FileExcelOutlined />,
                    text: 'Descargar Excel',
                    disabledBySelection: false,
                  },
                ]}
              />
            </TabPane>
            <TabPane
              tab="Saldos de Momentos"
              key="saldos"
              forceRender
            >
              <Table
                cols={columnsMomentos}
                data={[]}
                rowSelection={rowSelection}
                handleOnRowClick={handleOnRowClick}
                mobileColIndex={0}
                customActions={[
                  {
                    onClick: onDownloadPdfMomentos,
                    icon: <FilePdfOutlined />,
                    text: 'Descargar PDF',
                    disabledBySelection: false,
                  },
                  {
                    onClick: onDownLoadExcelMomentos,
                    icon: <FileExcelOutlined />,
                    text: 'Descargar Excel',
                    disabledBySelection: false,
                  },
                ]}
              />
            </TabPane>
          </Tabs>

        </Card>
      </>
    );
  }

  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {visible
          ? (
            <>
              <Row gutter={10}>
                <Col md={24} sm={24} xs={24}>
                  <Form
                    layout="vertical"
                    labelAlign="left"
                    labelWrap
                    onFinish={onFinish}
                    scrollToFirstError
                    name="solicitud"
                  >
                    <Row gutter={10}>
                      <Col xs={24} sm={24} md={12}>
                        <Form.Item
                          name="periodo"
                          label="Periodo"
                        >
                          <RangePicker picker="month" placeholder={['Mes inicial', 'Mes final']} />
                        </Form.Item>
                      </Col>
                      <Col style={{ display: 'flex', alignItems: 'center' }}>
                        <Button
                          onClick={fetch}
                          icon={<SearchOutlined />}
                        >
                          Buscar
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
              <Card
                className="form-container"
                title={<ActionButtons />}
                bordered={false}
              >
                <Table
                  cols={columns}
                  data={[]}
                  rowSelection={rowSelection}
                  handleOnRowClick={handleOnRowClick}
                  mobileColIndex={0}
                  customActions={[
                    {
                      onClick: onDownloadPdf,
                      icon: <FilePdfOutlined />,
                      text: 'Descargar PDF',
                      disabledBySelection: false,
                    },
                    {
                      onClick: onDownLoadExcel,
                      icon: <FileExcelOutlined />,
                      text: 'Descargar Excel',
                      disabledBySelection: false,
                    },
                  ]}
                />
              </Card>
            </>
          )
          : (
            <AddTabs />
          )}
      </Spin>
    </Row>
  );
}

export default KardexSaldosMomentos;
