import React, { useState, useEffect } from 'react';
import {
  Form,
  Button,
  Row,
  Col,
  Spin,
  Card,
  message,
  Input,
} from 'antd';
import { useSelector } from 'react-redux';
import API from 'utils/api';
import { onError, onSuccess } from 'utils/handlers';
import Table from 'components/Table';
import Separador from 'components/Separador';
import FormSubmitControls from 'components/FormSubmitControls';
import ModalDelete from 'components/ModalDelete';
import Select from 'components/Select';
import { isMutable } from 'utils/estadosGlobales';
import SegmentosFoliador from './SegmentosFoliador';

export const permissionFoliador = {
  permissionModel: 'foliodedocumentooperativo',
};

const baseURI = '/configuraciones/configuraciones-de-folios/';

// eslint-disable-next-line react/prop-types
function Foliadores({ permission }) {
  const [form] = Form.useForm();
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);
  const [segmentos, setSegmentos] = useState([]);
  const [mutable, setMutable] = useState(true);

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await API.get(baseURI);
      setData(res.data);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchData();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    setSelectedRowKeys([]);
    setMutable(true);
    setVisibleAlert(false);
  };

  const postSegmentos = async (id) => {
    const promises = [];
    segmentos.forEach((segmento) => {
      const promise = API.post(`configuraciones/configuraciones-de-folios/${id}/segmentos`, {
        ...segmento,
        // configuracion_de_folio: id,
      });
      promises.push(promise);
    });
    if (promises.length && id) {
      await Promise.all(promises);
    }
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      const hasConsecutivo = segmentos.some((s) => s.tipo_de_segmento === 2);
      if (!selectedRowKeys.length) {
        if (hasConsecutivo) {
          const response = await API.post(baseURI, values);
          if (response?.status === 201) {
            await postSegmentos(response.data.id);
            onSuccess(response, 'Agregado correctamente');
            onCancel();
            await fetchData();
          }
        } else {
          message.info('Debe agregar al menos un segmento de tipo consecutivo');
        }
      } else {
        const [key] = selectedRowKeys;
        const response = await API.put(`${baseURI}${key}/`, values);
        if (response?.status === 200) {
          onSuccess(response, 'Actualizado correctamente');
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      if (selectedRowKeys.length) {
        const estado = form.getFieldValue('estados_globales');
        if (estado < 4) {
          const [key] = selectedRowKeys;
          const response = await API.delete(`${baseURI}${key}/`);
          if (response?.status === 204) {
            onSuccess(response, 'Eliminado correctamente');
            setVisibleAlert(false);
            onCancel();
            await fetchData();
          }
        } else {
          const status = estado === 4 ? 'autorizado' : 'cancelado';
          message.info(`No se puede eliminar un Foliador ${status}`);
          onCancel();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    form.setFieldsValue(record);
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const onClickEdit = (authorized) => {
    setVisible(true);
    const [key] = selectedRowKeys;
    const match = authorized || data.find((e) => e.id === key);
    setTimeout(() => {
      setMutable(isMutable(match));
      form.setFieldsValue(match);
    });
  };

  const onClickDelete = () => {
    if (mutable) {
      setVisibleAlert(true);
    } else {
      const status = form.getFieldValue('estados_globales') === 4 ? 'autorizado' : 'cancelado';
      message.info(`No se puede eliminar un Foliador ${status}`);
      onCancel();
    }
  };

  const columns = [
    {
      titleText: 'Nombre',
      dataIndex: 'nombre',
      key: 'nombre',
      width: 250,
    },
    {
      titleText: 'Separador',
      dataIndex: 'separador',
      key: 'separador',
      width: 250,
    },
    {
      titleText: 'Estado',
      dataIndex: 'estados_globales',
      key: 'estados_globales',
      width: 250,
      render: (val) => estadosGlobales.find((t) => t.id === val)?.descripcion,
    },
  ];

  const mobileCols = [
    {
      titleText: 'Módulo - Documento',
      dataIndex: 'modulo',
      key: 'modulo',
    },
  ];

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
  };

  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible ? (
          <Table
            cols={columns}
            mobileCols={mobileCols}
            data={data}
            permission={permission}
            rowSelection={rowSelection}
            handleOnRowClick={handleOnRowClick}
            controls={{
              onClickAdd,
              onClickEdit,
              onClickDelete,
            }}
          />
        ) : (
          <Card
            className="form-container"
            title={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Foliador`}
            extra={(
              <FormSubmitControls
                onFinish={mutable ? onFinish : false}
                onCancel={onCancel}
                loading={loading}
                baseURI={baseURI}
                selectedRowKeys={selectedRowKeys}
                allowAuthorize
                allowCancel
                callback={(estados_globales) => {
                  const [key] = selectedRowKeys;
                  const normalized = data.map((e) => {
                    if (e.id === key) {
                      setMutable(false);
                      return {
                        ...e,
                        estados_globales,
                      };
                    }
                    return e;
                  });
                  setData(normalized);
                  const match = normalized.find((e) => e.id === key);
                  onClickEdit(match);
                }}
              />
            )}
            bordered={false}
          >
            <Form
              layout="vertical"
              form={form}
              onFinish={onFinish}
              scrollToFirstError
              initialValues={{ estados_globales: 1, separador: '-' }}
            >
              <Row gutter={10}>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="nombre"
                    label="Nombre"
                    rules={rules.required}
                  >
                    <Input allowClear />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Separador disabled={!mutable} rule={rules.required} />
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="estados_globales"
                    rules={rules.required}
                    label="Estado"
                    hasFeedback
                  >
                    <Select
                      disabled={!selectedRowKeys.length || !mutable}
                      dataSource={estadosGlobales}
                    />
                  </Form.Item>
                </Col>
                <SegmentosFoliador
                  idConfiguracion={selectedRowKeys[0]}
                  setSegmentos={setSegmentos}
                  isMutable={mutable}
                />
                <Form.Item hidden>
                  <Button htmlType="submit" />
                </Form.Item>
              </Row>
            </Form>
          </Card>
        )}
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => setVisibleAlert(false)}
          visible={visibleAlert}
          content={`Foliador para el documento ${form.getFieldValue('nombre')}`}
          loading={loading}
        />
      </Spin>
    </Row>
  );
}

export default Foliadores;
