import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Spin,
  Modal,
  DatePicker,
} from 'antd';

import API from 'utils/api';
import FormSubmitControls from 'components/FormSubmitControls';
import Table from 'components/Table';
import ModalDelete from 'components/ModalDelete';
import Select from 'components/Select';
import getFormattedValues, { formatReceived } from 'utils/formatValues';
import { onError, onSuccess } from 'utils/handlers';

export const permissionRegimenEmpresarial = {
  permissionModel: 'regimendeempresa',
};

const baseURI = 'catalogos/regimen-empresa/';
// eslint-disable-next-line react/prop-types
function RegimenEmpresarial({ permission }) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await API.get(baseURI);
      setData(formatReceived(res.data));
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };
  useEffect(() => {
    fetchData();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    setSelectedRowKeys([]);
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      values.persona_moral = !values.persona_fisica;
      const formattedValues = getFormattedValues(values);
      if (!selectedRowKeys.length) {
        const response = await API.post(baseURI, formattedValues);
        if (response?.status === 201) {
          onSuccess(response, 'Agregado correctamente');
          onCancel();
          await fetchData();
        }
      } else {
        const [key] = selectedRowKeys;
        const response = await API.put(`${baseURI}${key}/`, formattedValues);
        if (response?.status === 200) {
          onSuccess(response, 'Actualizado correctamente');
          form.resetFields();
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const deleteItem = async () => {
    try {
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`${baseURI}${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          onCancel();
          await fetchData();
          setVisibleAlert(false);
        }
      }
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    form.setFieldsValue(record);
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const onClickEdit = async () => {
    setVisible(true);
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const required = {
    required: true,
    message: 'El campo es requerido',
  };

  const rules = {
    required: [
      required,
    ],
  };

  const columns = [
    {
      titleText: 'Código',
      dataIndex: 'codigo_regimen_fiscal',
      key: 'codigo_regimen_fiscal',
      width: 150,
    },
    {
      titleText: 'Descripción',
      dataIndex: 'descripcion',
      key: 'descripcion',
      width: 500,
    },
    {
      titleText: '¿Es persona física o moral?',
      dataIndex: 'persona_fisica',
      key: 'persona_fisica',
      width: 300,
      render: (e) => (e ? 'Física' : 'Moral'),
    },
    {
      titleText: 'Inicio de vigencia',
      dataIndex: 'fecha_de_inicio_de_vigencia',
      key: 'fecha_de_inicio_de_vigencia',
      width: 200,
      render: (e) => (e ? e?.format('DD/MM/YYYY') : '-'),
    },
    {
      titleText: 'Fin de vigencia',
      dataIndex: 'fecha_de_fin_de_vigencia',
      key: 'fecha_de_fin_de_vigencia',
      width: 200,
      render: (e) => (e ? e?.format('DD/MM/YYYY') : '-'),
    },
  ];

  return (
    <Row justify="center" className="container">
      <Spin tip="Cargando..." spinning={loading}>
        <Table
          cols={columns}
          data={data}
          permission={permission}
          rowSelection={rowSelection}
          handleOnRowClick={handleOnRowClick}
          controls={{
            onClickAdd,
            onClickEdit,
            onClickDelete,
          }}
          rowKey="id"
          mobileColIndex={0}
        />
        <Modal
          visible={visible}
          title={(
            <FormSubmitControls
              label={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Regimen`}
              onFinish={onFinish}
              onCancel={onCancel}
            />
          )}
          closable={false}
          maskClosable={!loading}
          keyboard={!loading}
          footer={null}
          onCancel={onCancel}
          forceRender
          destroyOnClose
        >
          <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
            scrollToFirstError
            initialValues={{ estados_globales: 1 }}
          >
            <Row gutter={10}>
              <Col span={24}>
                <Form.Item
                  name="codigo_regimen_fiscal"
                  rules={rules.required}
                  label="Código"
                  hasFeedback
                >
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="descripcion"
                  rules={rules.required}
                  label="Descripción"
                  hasFeedback
                >
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="persona_fisica"
                  rules={rules.required}
                  label="¿Es persona física?"
                  hasFeedback
                >
                  <Select trueFalse />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="fecha_de_inicio_de_vigencia"
                  rules={rules.required}
                  label="Inicio de vigencia"
                  hasFeedback
                >
                  <DatePicker format="DD/MM/YYYY" placeholder="" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="fecha_de_fin_de_vigencia"
                  label="Fin de vigencia"
                  hasFeedback
                >
                  <DatePicker format="DD/MM/YYYY" placeholder="" />
                </Form.Item>
              </Col>
              <Form.Item hidden>
                <Button htmlType="submit" />
              </Form.Item>
            </Row>
          </Form>
        </Modal>
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => {
            setVisibleAlert(false);
            setSelectedRowKeys([]);
          }}
          visible={visibleAlert}
          content={`Retención ${form.getFieldValue('descripcion')}`}
        />
      </Spin>
    </Row>
  );
}

export default RegimenEmpresarial;
