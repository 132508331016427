import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Form,
  Input,
  Button,
  Spin,
  Row,
  Col,
  Card,
  DatePicker,
  message,
} from 'antd';
import Moment from 'moment';
import API from 'utils/api';
import fetchSome from 'utils/fetchSome';
import getFormattedValues, { formatReceived, hasFiles } from 'utils/formatValues';
import { onError } from 'utils/handlers';
import {
  RFC_PATTERN,
  CURP_PATTERN,
  INE_PATTERN,
  digitoVerificador,
} from 'utils/patterns';
import FormSubmitControls from 'components/FormSubmitControls';
import Uploader from 'components/Uploader';
import ModalDireccion from 'components/ModalDireccion';
import Table from 'components/Table';
import ModalDelete from 'components/ModalDelete';
import Select from 'components/Select';
import { SET_TIPOS_PODER } from 'store/reducers/catalogs';

const baseURI = 'configuraciones/representantes-legales/';

function RepresentantesLegales({
  currentURI,
  selectedRowKey,
  content_type_terceros,
}) {
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  const tiposDePoder = useSelector(({ catalogs }) => catalogs.tiposDePoder);
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);
  const [datosCorporativos, setDatosCorporativos] = useState([]);
  const [datosCorporativosIniciales, setDatosCorporativosIniciales] = useState([]);
  const [requiredFile, setRequiredFile] = useState(false);

  const fetchData = async () => {
    try {
      if (selectedRowKey) {
        setLoading(true);
        const resCatalogo = await API.get(`${currentURI}/${selectedRowKey}/`);
        if (resCatalogo?.status === 200) {
          const { representantes_legales, datos_corporativos } = resCatalogo.data;
          let representantesList = [];
          if (representantes_legales?.length) {
            if (typeof representantes_legales[0] === 'number') {
              representantesList = representantes_legales;
              const promises = [];
              representantesList.forEach((id) => {
                const res = API.get(`configuraciones/representantes-legales/${id}`);
                promises.push(res);
              });
              const responses = promises.length ? await Promise.all(promises) : [];
              const datos = responses
                .map((res) => ({
                  ...formatReceived(res.data),
                  datos_corporativos: datos_corporativos
                    .filter((d) => d.representante_legal.includes(res.data.id)).map((e) => e.id),
                }));
              setData(datos);
            } else {
              representantesList = representantes_legales
                .map((values) => ({
                  ...formatReceived(values),
                  datos_corporativos: datos_corporativos
                    .filter((d) => d.representante_legal.includes(values.id)).map((e) => e.id),
                }));
              setData(representantesList);
            }
          } else {
            setData([]);
          }
          setDatosCorporativos(datos_corporativos);
        }
        setLoading(false);
      }
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    const fetchAll = async () => {
      if (!tiposDePoder?.length) {
        await fetchSome('catalogos/tipos-de-poderes/', SET_TIPOS_PODER);
      }
      await fetchData();
    };
    fetchAll();

    return () => API.tryCancel;
    // eslint-disable-next-line
  }, [selectedRowKey]);

  const onCancel = () => {
    setVisible(false);
    setVisibleAlert(false);
    setSelectedRowKeys([]);
    setRequiredFile(false);
    form.resetFields();
    setDatosCorporativosIniciales([]);
    setLoading(false);
  };

  const patchDatosCorporativos = async (datos_corporativos, IDRepresentante, onPost = false) => {
    // eslint-disable-next-line no-param-reassign
    datos_corporativos = [datos_corporativos];
    const promises = [];
    if (onPost) {
      datos_corporativos.forEach((id) => {
        const match = datosCorporativos.find((d) => d.id === id);
        const { representante_legal } = match;
        representante_legal.push(IDRepresentante);
        const res = API.patch(`configuraciones/datos-corporativos/${id}`, {
          representante_legal,
        });
        promises.push(res);
      });
    } else {
      const diffAdd = datos_corporativos.filter((d) => !datosCorporativosIniciales.includes(d));
      diffAdd.forEach((id) => {
        const match = datosCorporativos.find((d) => d.id === id);
        const { representante_legal } = match;
        representante_legal.push(IDRepresentante);
        const res = API.patch(`configuraciones/datos-corporativos/${id}`, {
          representante_legal,
        });
        promises.push(res);
      });
      const diffRemove = datosCorporativosIniciales.filter((d) => !datos_corporativos.includes(d));
      diffRemove.forEach((id) => {
        const match = datosCorporativos.find((d) => d.id === id);
        let { representante_legal } = match;
        representante_legal = representante_legal.filter((r) => r !== IDRepresentante);
        const res = API.patch(`configuraciones/datos-corporativos/${id}`, {
          representante_legal,
        });
        promises.push(res);
      });
    }
    await Promise.all(promises);
  };

  const getRepresentante = async () => {
    const RFC = form.getFieldValue('RFC');
    const res = await API.get('configuraciones/representantes-legales/', {
      params: {
        RFC,
      },
    });
    if (res?.status === 200 && res.data.length) {
      return res.data[0];
    }
    return undefined;
  };

  const importRepresentante = async () => {
    try {
      setLoading(true);
      const representante = await getRepresentante();
      // eslint-disable-next-line no-use-before-define
      await onFinish(null, representante.id);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const showAlertRFC = () => {
    message.info({
      content: (
        <Row align="middle">
          El Representante Legal ya se encuentra dado de alto en el sistema.
          ¿Desea ligar su información?
          <Col>
            <Button
              type="link"
              onClick={importRepresentante}
            >
              Si
            </Button>
            <Button
              type="link"
              onClick={() => {
                message.destroy();
                onCancel();
              }}
            >
              No
            </Button>
          </Col>
        </Row>
      ),
      duration: 2.5,
    });
  };

  const onFinish = async (e, id) => {
    try {
      setLoading(true);
      await form.validateFields();
      if (selectedRowKey) {
        const values = form.getFieldsValue();
        const { datos_corporativos } = values;
        delete values.datos_corporativos;
        const files = ['carta_poder'];
        values.content_type_terceros = content_type_terceros;
        values.object_id_terceros = selectedRowKey;
        const config = hasFiles(values, files) ? {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        } : {};
        const formattedValues = getFormattedValues(values, {
          files,
          formData: config.headers,
          clean: false,
        });
        if (!selectedRowKeys.length && !id) {
          const res = await API.post(baseURI, formattedValues, config);
          if (res?.status === 201) {
            await patchDatosCorporativos(datos_corporativos, res.data.id, true);
            onCancel();
            await fetchData();
          }
        } else {
          const key = id || selectedRowKeys[0];
          const res = await API.patch(`${baseURI}${key}/`, formattedValues, config);
          if (res?.status === 200) {
            await patchDatosCorporativos(datos_corporativos, key);
            onCancel();
            await fetchData();
          }
        }
      }
      setLoading(false);
    } catch (err) {
      if (err.response?.data) {
        const { data: errors } = err.response;
        const rfcError = errors?.RFC?.[0]?.toLowerCase().includes('ya está registrado');
        const RFC = form.getFieldValue('RFC');
        if (rfcError && !selectedRowKeys.length) {
          if (!data.find((i) => i.RFC === RFC)) {
            showAlertRFC();
          } else {
            message.warn('El representante ya se encuentra agregado');
          }
        } else {
          onError(err, setLoading);
        }
      } else {
        onError(err, setLoading);
      }
    }
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    form.setFieldsValue(record);
  };

  const onClickAdd = () => {
    onCancel();
    form.resetFields();
    setVisible(true);
  };

  const onClickEdit = async () => {
    try {
      setLoading(true);
      const [key] = selectedRowKeys;
      const match = data.find((item) => item.id === key);
      setDatosCorporativosIniciales(match.datos_corporativos);
      if (match.poder) {
        match.tipo_de_poder = match.poder?.tipo_de_poder;
        match.carta_poder = match.poder?.carta_poder;
        setRequiredFile(true);
      }
      form.setFieldsValue(match);
      setVisible(true);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onClickDelete = () => {
    const [key] = selectedRowKeys;
    const match = data.find((item) => item.id === key);
    setDatosCorporativosIniciales(match.datos_corporativos);
    form.setFieldsValue(match);
    setVisibleAlert(true);
  };

  const deleteItem = async () => {
    try {
      const [key] = selectedRowKeys;
      if (selectedRowKey) {
        const response = await API.delete(`${baseURI}${key}/`);
        if (response?.status === 204) {
          onCancel();
          await fetchData();
          setVisibleAlert(false);
        }
      }
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const beforeDeleteItem = async () => {
    try {
      await patchDatosCorporativos([], selectedRowKeys[0]);
      await deleteItem();
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
    INE: [
      {
        validator: async (rule, value) => {
          if (value) {
            if (!INE_PATTERN.test(value)) {
              throw new Error('Ingrese un RFC válido');
            }
          }
        },
      },
    ],
    RFC: [
      {
        required: true,
        message: 'El campo es requerido',
      },
      {
        validator: async (rule, value) => {
          if (value) {
            if (!RFC_PATTERN.test(value) || value.length !== 13) {
              throw new Error('Ingrese un RFC válido');
            }
          }
        },
      },
    ],
    CURP: [
      {
        validator: async (rule, value) => {
          if (value) {
            const validado = value.toUpperCase().match(CURP_PATTERN);
            if (!validado || validado[2] !== digitoVerificador(validado[1])) {
              throw new Error('Ingrese una CURP válida');
            }
          }
        },
      },
    ],
  };

  const columns = [
    {
      titleText: 'Nombre',
      dataIndex: 'nombre',
      key: 'nombre',
      width: 200,
    },
    {
      titleText: 'Apellido Paterno',
      dataIndex: 'apellido_paterno',
      key: 'apellido_paterno',
      width: 200,
    },
    {
      titleText: 'Apellido Materno',
      dataIndex: 'apellido_materno',
      key: 'apellido_materno',
      width: 200,
    },
    {
      titleText: 'RFC',
      dataIndex: 'RFC',
      key: 'RFC',
      width: 200,
    },
    {
      titleText: 'Fecha de Nacimiento',
      dataIndex: 'fecha_de_nacimiento',
      key: 'fecha_de_nacimiento',
      width: 200,
      render: (val) => (val?.format('DD/MM/YYYY')),
    },
    {
      titleText: 'Estado',
      dataIndex: 'estados_globales',
      key: 'estados_globales',
      render: (val) => estadosGlobales.find((e) => e.id === val)?.descripcion,
      width: 120,
    },
  ];

  const onChangeRFC = ({ target: { value } }) => {
    if (value?.length === 13) {
      const date = value.substr(4, 6);
      const fecha_de_nacimiento = new Moment(date, 'YYMMDD');
      const currYear = parseInt(new Moment().format('YY'), 10);
      const dateYear = parseInt(fecha_de_nacimiento.format('YY'), 10);
      form.setFieldsValue({
        fecha_de_nacimiento: Math.abs(currYear - dateYear) >= 18
          ? fecha_de_nacimiento : fecha_de_nacimiento.add(-100, 'y'),
      });
    }
  };

  return (
    <Spin tip="Cargando..." spinning={loading}>
      {!visible ? (
        <Table
          cols={columns}
          data={data}
          rowSelection={rowSelection}
          handleOnRowClick={handleOnRowClick}
          controls={{
            onClickAdd,
            onClickEdit,
            onClickDelete,
          }}
          allowSort={false}
          allowSearch={false}
        />
      ) : (
        <Card
          className="form-container auto-heigth"
          title={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Representante Legal`}
          extra={(
            <FormSubmitControls
              onFinish={onFinish}
              onCancel={onCancel}
              loading={loading}
            />
          )}
          bordered={false}
        >
          <Form
            layout="vertical"
            form={form}
            name="representante_legal"
            onFinish={onFinish}
            scrollToFirstError
            initialValues={{ estados_globales: 1, tipo_de_poder: 2 }}
          >
            <Row gutter={10}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  name="nombre"
                  label="Nombre"
                  rules={rules.required}
                >
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  name="apellido_paterno"
                  label="Apellido Paterno"
                  rules={rules.required}
                >
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  name="apellido_materno"
                  label="Apellido Materno"
                >
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  name="RFC"
                  label="RFC"
                  rules={rules.RFC}
                  hasFeedback
                  normalize={(val) => val?.toUpperCase()}
                >
                  <Input allowClear maxLength={13} onChange={onChangeRFC} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  name="CURP"
                  rules={rules.CURP}
                  label="CURP"
                  hasFeedback
                  normalize={(val) => val?.toUpperCase()}
                >
                  <Input allowClear maxLength={18} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  name="INE"
                  rules={rules.INE}
                  label="Identificacion"
                  hasFeedback
                  normalize={(val) => val?.toUpperCase()}
                >
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  name="fecha_de_nacimiento"
                  label="Fecha de Nacimiento"
                  hasFeedback
                >
                  <DatePicker
                    placeholder=""
                    format="DD/MM/YYYY"
                    disabled
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  name="datos_corporativos"
                  label="Acta Constitutiva"
                  rules={rules.required}
                  hasFeedback
                >
                  <Select
                    render={(item) => `${item.numero_de_acta} - ${item.concepto_de_acta}`}
                    dataSource={datosCorporativos}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  name="tipo_de_poder"
                  label="Tipo de Poder"
                  rules={rules.required}
                  hasFeedback
                >
                  <Select
                    onChange={(val) => {
                      if (val) {
                        const match = tiposDePoder?.find((e) => e.id === val);
                        setRequiredFile(!!match?.requiere_documento);
                      } else {
                        setRequiredFile(false);
                      }
                    }}
                    dataSource={tiposDePoder}
                    labelProp="nombre"
                  />
                </Form.Item>
              </Col>
              {requiredFile && (
                <Uploader
                  title="Carta Poder"
                  limitMB={10}
                  file={form.getFieldValue('carta_poder')}
                  formItemName="carta_poder"
                  allowedExtensions={['pdf']}
                  rules={rules.required}
                />
              )}
              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  name="estados_globales"
                  label="Estado"
                  rules={rules.required}
                  hasFeedback
                >
                  <Select disabled={!selectedRowKeys.length} dataSource={estadosGlobales} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <ModalDireccion
                  currentURI="configuraciones/representantes-legales"
                  parent={data.find((item) => item.id === selectedRowKeys[0])}
                  form={form}
                />
              </Col>
            </Row>
            <Form.Item hidden>
              <Button htmlType="submit" />
            </Form.Item>
          </Form>
        </Card>
      )}
      <ModalDelete
        onDelete={beforeDeleteItem}
        onCancel={() => setVisibleAlert(false)}
        visible={visibleAlert}
        content={`Representante Legal ${form.getFieldValue('nombre')}`}
        loading={loading}
      />
    </Spin>
  );
}

RepresentantesLegales.propTypes = {
  selectedRowKey: PropTypes.number,
  currentURI: PropTypes.string.isRequired,
  content_type_terceros: PropTypes.number.isRequired,
};

RepresentantesLegales.defaultProps = {
  selectedRowKey: undefined,
};

export default RepresentantesLegales;
