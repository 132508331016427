import React from 'react';
import SingleForm from 'components/SingleForm';

export const permissionTipoDeFianza = {
  permissionModel: 'tipodefianza',
};

// eslint-disable-next-line react/prop-types
function TipoDeFianzas({ permission }) {
  return (
    <SingleForm
      permission={permission}
      baseURI="obrapublica/tipo-de-fianza/"
      title="TipoDeFianzass"
    />
  );
}

export default TipoDeFianzas;
