/* eslint-disable no-use-before-define */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-param-reassign */
import React, { useEffect, useRef, useState } from 'react';
import {
  Table as AntTable,
  Row,
  Col,
  Button,
  Menu,
  Input,
  Dropdown,
  Popover,
  Select,
  Typography,
  Grid,
  Form,
  Modal,
  Spin,
  Tooltip,
} from 'antd';
import {
  PlusOutlined,
  DownloadOutlined,
  EditOutlined,
  DeleteOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
  SearchOutlined,
  CloseOutlined,
  FileExcelOutlined,
  FilePdfOutlined,
  SettingOutlined,
  TableOutlined,
  ArrowLeftOutlined,
  ExpandOutlined,
  DownOutlined,
  RightOutlined,
  UploadOutlined,
} from '@ant-design/icons';

import PropTypes from 'prop-types';
import API, { baseURL } from 'utils/api';
import { docHeight } from 'utils/responsive';
import { onError, onSuccess } from 'utils/handlers';
import { hasActionPermission, permissionbypass } from 'utils/permission';
import Dragger from './Dragger';
import FormSubmitControls from './FormSubmitControls';
import Pagination from './Pagination';

const { Option } = Select;
const { Text } = Typography;

function Table({
  cols,
  mobileCols: _mobileCols,
  data,
  rowSelection,
  onRow: _onRow,
  editText,
  noControls,
  controls,
  allowEdit,
  allowAdd,
  allowDelete,
  allowSearch,
  allowSort,
  allowConfig,
  allowExpand,
  expandable: _expandable,
  filterNested,
  childrenProp,
  RTLHead,
  rowKey,
  customActions,
  handleOnRowClick,
  mobileColIndex,
  pageSize,
  disabled,
  allowColumnSelector,
  baseURI,
  indentSize,
  loading: tableLoading,
  justImport,
  allowedExtensions,
  permission,
  hasAllViewPermission: _hasAllViewPermission,
  pagination,
  paginationCallback,
  count,
  currentPage,
}) {
  rowKey = _expandable || childrenProp || filterNested ? 'key' : 'id';
  childrenProp = filterNested ? 'children' : null;
  const screens = Grid.useBreakpoint();
  const mobileCols = _mobileCols.length ? _mobileCols : [cols[mobileColIndex]];
  const [visible, setVisible] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [tableHeight, setTableheight] = useState(0);
  const [lastDataSource, setLastDataSource] = useState([]);
  const [focusedInput, setFocusedInput] = useState();
  const [filters, setFilters] = useState([...cols
    .map((col) => ({ prop: col.dataIndex, value: null }))]);
  const [scrollX, setScrollX] = useState(0);
  const [visibleSarch, setVisibleSearch] = useState(false);
  const [componentKey, setComponentKey] = useState();

  const allowedCustomActions = customActions.filter(({ permission: _permission }) => !_permission
    || hasActionPermission(_permission?.model, _permission?.action));

  // normalize/validate params
  allowExpand = allowExpand && !!controls?.onClickExpand;
  allowAdd = allowAdd && !!controls?.onClickAdd;
  allowEdit = allowEdit && !!controls?.onClickEdit;
  allowDelete = allowDelete && !!controls?.onClickDelete;
  const allowExport = baseURI && (permission?.export || !permission);
  const allowImport = baseURI && (permission?.import || !permission);
  const ref = useRef();
  const [page, setPage] = useState(1);
  const [q, setQ] = useState();

  let expandable = null;

  if (!_expandable && childrenProp) {
    expandable = {
      expandRowByClick: true,
      childrenColumnName: childrenProp,
      indentSize,
      expandIcon: ({ expanded, onExpand, record }) => (record[childrenProp] && (
        expanded ? (
          <DownOutlined onClick={(e) => onExpand(record, e)} />
        ) : (
          <RightOutlined onClick={(e) => onExpand(record, e)} />
        )
      )),
    };
  }

  const onRow = _onRow || ((record) => ({
    onClick: () => {
      if (_expandable && !_expandable.expandRowByClick) {
        _expandable.expandRowByClick = true;
      }
      if (handleOnRowClick) {
        handleOnRowClick(record);
      }
    },
    onDoubleClick: () => {
      if (controls?.onClickExpand) {
        controls.onClickExpand();
      } else if (controls?.onClickEdit && permission?.change) {
        controls.onClickEdit();
      } else if (controls?.onClickConfig && permission?.change) {
        controls.onClickConfig();
      }
    },
  }));

  function SortControls(prop, onAscend, onDescend) {
    return (
      <Col id={`sort-controls-${prop}`}>
        <CaretUpOutlined onClick={onAscend} />
        <CaretDownOutlined onClick={onDescend} />
      </Col>
    );
  }

  const highlight = (prop, ascend = false) => {
    const controlsCont = document.getElementById(`sort-controls-${prop}`);
    const up = controlsCont.querySelector('.anticon-caret-up');
    const down = controlsCont.querySelector('.anticon-caret-down');
    // Clear all active styles
    const all = document.querySelectorAll('.anticon-caret-up, .anticon-caret-down');
    all.forEach((element) => {
      element.classList.remove('active');
    });
    // Set active style
    if (ascend) {
      up.classList.add('active');
    } else {
      down.classList.add('active');
    }
  };

  const sortData = (prop, ascend = true) => {
    let sortedData;
    const dataClone = [...dataSource];
    if (ascend) {
      sortedData = dataClone.sort((a, b) => {
        if (a[prop] !== null) {
          return a[prop].toString().localeCompare(b[prop]);
        }
        return false;
      });
      highlight(prop, true);
    } else {
      sortedData = dataClone.sort((a, b) => {
        if (b[prop] !== null) {
          return b[prop].toString().localeCompare(a[prop]);
        }
        return false;
      });
      highlight(prop);
    }
    setDataSource(sortedData);
  };

  let filtNested = [];

  const filter = (array, fn) => array.reduce((r, o) => {
    const children = filter(o[childrenProp] || [], fn);
    if (fn(o) || children.length) {
      r.push({ ...o });
      if (!o[childrenProp]) {
        filtNested.push({ ...o });
      }
    }
    return r;
  }, []);

  const filterNestedData = () => {
    const dataClone = [...data];
    const filterList = filters.filter((item) => !!item.value);
    filtNested = [];
    if (filterList.length) {
      filterList.forEach(({ prop, value }) => {
        filter(dataClone, (i) => (i[prop] || '').toString().toLowerCase().includes(value));
      });
    }
    if (filtNested.length) {
      setDataSource(filtNested);
    } else {
      setDataSource(data);
    }
  };

  useEffect(() => {
    if (focusedInput && (!dataSource.length || (!lastDataSource.length && dataSource.length))) {
      const input = document.getElementById(focusedInput);
      setTimeout(() => {
        input.focus();
      });
    }
    // eslint-disable-next-line
  }, [dataSource]);

  useEffect(() => {
    if (filterNested) {
      filterNestedData();
      filterData();
    } else {
      filterData();
    }
    // eslint-disable-next-line
  }, [filters]);

  useEffect(() => {
    if (page !== currentPage) {
      setPage(currentPage);
    }
    // eslint-disable-next-line
  }, [currentPage]);

  const setFilter = (prop, value) => {
    let filtersClone = [...filters];
    filtersClone = filtersClone.filter((elem) => elem.prop !== prop);
    filtersClone.push({ prop, value });
    setFilters(filtersClone);
    if (paginationCallback && !pagination) {
      clearTimeout(ref.current);
      const entries = filtersClone.map((e) => [e.prop, e.value]);
      const _q = Object.fromEntries(entries);
      setQ(_q);
      ref.current = setTimeout(() => paginationCallback(page, _q), 2000);
      setPage(1);
    }
  };

  let timeoutSearch;

  const getTitle = (title, prop) => {
    function Icon() {
      return filters.find((a) => a.prop === prop).value
        ? <CloseOutlined onClick={() => setFilter(prop, null)} /> : <SearchOutlined />;
    }
    return (
      <>
        <Row>
          <span className={RTLHead ? 'head headRight' : 'head'}>
            {title}
          </span>
          {allowSort ? SortControls(
            prop,
            () => sortData(prop),
            () => sortData(prop, false),
          ) : ''}
        </Row>
        {(allowSearch && visibleSarch) && (
          <Row>
            <Input
              id={`input-${prop}`}
              placeholder=""
              // value={filters.find((a) => a.prop === prop).value}
              onChange={(e) => {
                const value = e?.target?.value;
                if (timeoutSearch) {
                  clearTimeout(timeoutSearch);
                }
                timeoutSearch = setTimeout(() => {
                  setFilter(prop, value);
                });
              }}
              onFocus={() => setFocusedInput(`input-${prop}`)}
            />
            <Icon />
          </Row>
        )}
      </>
    );
  };

  useEffect(() => {
    setTableheight(docHeight - 366);
    const key = new Date().getTime();
    setComponentKey(`${key}_table`);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setDataSource(data);
    // eslint-disable-next-line
  }, [data]);

  const mobileColumns = mobileCols?.length > 1
    ? mobileCols.map((c) => ({
      ...c, key: c.dataIndex, title: c.titleText, width: c.width,
    }))
    : mobileCols.map((c) => ({
      ...c, key: c.dataIndex, title: c.titleText, width: '100%',
    }));

  const [selectedCols, setSelectedCols] = useState(cols.map(({ dataIndex }) => dataIndex));

  const columns = cols.map((col) => ({
    ...col,
    key: col.dataIndex,
    title: getTitle(col.titleText, col.key),
  }));

  const filterData = () => {
    setLastDataSource(dataSource);
    const dataClone = filtNested.length ? [...filtNested] : [...data];
    let filtered = dataClone;
    const filterList = filters.filter((item) => !!item.value);
    if (filterList.length) {
      filterList.forEach(({ prop, value }) => {
        filtered = filtered.filter((item) => {
          if (item[prop] && item[prop] !== null) {
            const col = columns.find((e) => e.key === prop);
            return (col?.render?.(item[prop]) || item[prop])
              .toString().toLowerCase().includes(value);
          }
          return false;
        });
      });
    }
    setDataSource(filtered);
  };

  useEffect(() => {
    if (filterNested) {
      filterNestedData();
      filterData();
    } else {
      filterData();
    }
    // eslint-disable-next-line
  }, [filters]);

  // eslint-disable-next-line
  const onExport = (type) => { };

  const exportMenu = (
    <Menu>
      <Menu.Item onClick={() => onExport('xlsx')} key={1}>
        <FileExcelOutlined />
        Excel
      </Menu.Item>
      <Menu.Item onClick={() => onExport('pdf')} key={2}>
        <FilePdfOutlined />
        PDF
      </Menu.Item>
    </Menu>
  );

  const onGetTemplate = async () => {
    try {
      const uri = baseURI.at(-1) === '/' ? baseURI : `${baseURI}/`;
      const resSign = await API.get(`${uri}carga/sign/`);
      const { sign } = resSign.data;
      window.open(`${baseURL}${uri}carga/plantilla?sign=${sign}`, '_blank');
    } catch (err) {
      onError(err);
    }
  };

  const importMenu = (
    <Menu>
      <Menu.Item onClick={onGetTemplate} key={1}>
        <DownloadOutlined />
        Obtener plantilla
      </Menu.Item>
      <Menu.Item onClick={() => setVisibleModal(true)} key={2}>
        <UploadOutlined />
        Cargar
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    const filtered = columns.filter((col) => col.width && selectedCols.includes(col.key));
    const sum = filtered.reduce((prev, next) => prev + next.width, 0);
    setScrollX(sum);
    // eslint-disable-next-line
  }, [selectedCols]);

  const content = () => {
    const options = columns.map(({ key, titleText }) => ({
      key,
      text: titleText,
    }));
    return (
      <div style={{ maxWidth: 350 }}>
        <Text>
          Seleccione las columnas que desea mostrar
        </Text>
        <Select
          mode="multiple"
          style={{ width: '100%' }}
          value={selectedCols}
          onChange={(val) => {
            if (val.length >= 1) {
              setSelectedCols(val);
            }
          }}
        >
          {options.map((o) => (
            <Option value={o.value} key={o.key}>
              {o.text}
            </Option>
          ))}
        </Select>
      </div>
    );
  };

  const hasAllViewPermission = _hasAllViewPermission
    || permission?.hasAllViewPermission || !permission;

  function ButtonWrapper({
    children,
    hasPermission,
    edit,
    delete: _delete,
  }) {
    if (hasPermission && hasAllViewPermission) {
      const needKey = edit || _delete;
      if ((needKey && rowSelection?.selectedRowKeys.length) || !needKey) {
        return children;
      }
      return (
        <Tooltip title="Seleccione un elemento para realizar esta acción">
          {children}
        </Tooltip>
      );
    }

    return (
      <Tooltip title="No tienes los permisos necesarios para realizar esta acción">
        {children}
      </Tooltip>
    );
  }

  ButtonWrapper.propTypes = {
    children: PropTypes.node.isRequired,
    hasPermission: PropTypes.bool,
    edit: PropTypes.bool,
    delete: PropTypes.bool,
  };

  ButtonWrapper.defaultProps = {
    hasPermission: false,
    edit: false,
    delete: false,
  };

  function TableControls() {
    return (
      <Row
        align="middle"
        justify="space-between"
        style={{ paddingBottom: 10 }}
        className="table-controls"
      >
        <div>
          {(allowColumnSelector && screens.md) && (
          <Popover
            visible={visible}
            placement="bottomRight"
            content={content}
            title={(
              <Row
                align="middle"
                justify="space-between"
              >
                <Text className="title">
                  Selector de columnas
                </Text>
                <Button type="link" onClick={() => setVisible(false)}>
                  <ArrowLeftOutlined />
                  Cancelar
                </Button>
              </Row>
            )}
            trigger="click"
          >
            <Button type="link" onClick={() => setVisible(true)}>
              <TableOutlined />
              Selector de columnas
            </Button>
          </Popover>
          )}
        </div>
        <div style={{ marginLeft: 'auto' }}>
          {allowConfig && (
          <Button
            onClick={controls.onClickConfig}
            disabled={!rowSelection.selectedRowKeys.length}
            type="link"
          >
            <SettingOutlined />
            {screens.md && 'Configurar'}
          </Button>
          )}
          {allowAdd && (
          <ButtonWrapper hasPermission={permission?.add}>
            <Button
              onClick={() => controls.onClickAdd()}
              disabled={!hasAllViewPermission || !permission?.add}
              type="link"
            >
              <PlusOutlined />
              {screens.md && 'Agregar'}
            </Button>
          </ButtonWrapper>
          )}
          {allowEdit && (
          <ButtonWrapper hasPermission={permission?.change} edit>
            <Button
              disabled={!hasAllViewPermission
                || !rowSelection?.selectedRowKeys.length || !permission?.change}
              onClick={() => controls.onClickEdit()}
              type="link"
            >
              <EditOutlined />
              {screens.md && editText}
            </Button>
          </ButtonWrapper>
          )}
          {allowDelete && (
          <ButtonWrapper hasPermission={permission?.delete} delete>
            <Button
              disabled={!rowSelection?.selectedRowKeys.length || !permission?.delete}
              onClick={() => {
                controls.onClickDelete();
                setTimeout(() => {
                  const submitBtnDelete = document.querySelector('.submit-delete');
                  if (submitBtnDelete) {
                    submitBtnDelete.focus();
                  }
                });
              }}
              type="link"
            >
              <DeleteOutlined />
              {screens.md && 'Eliminar'}
            </Button>
          </ButtonWrapper>
          )}
          {allowExpand && (
          <Button
            disabled={!hasAllViewPermission || !rowSelection?.selectedRowKeys.length}
            onClick={() => controls.onClickExpand()}
            type="link"
          >
            <ExpandOutlined />
            {screens.md && 'Vista completa'}
          </Button>
          )}
          {allowExport && (
          <Dropdown
            overlay={exportMenu}
            placement="top"
            style={{
              heigth: 32,
            }}
          >
            <Button
              style={{
                padding: '4px 15px',
              }}
              type="link"
              onClick={(e) => e.preventDefault()}
            >
              <DownloadOutlined />
              {screens.md && 'Exportar'}
            </Button>
          </Dropdown>
          )}
          {allowImport && (
          <Dropdown
            overlay={importMenu}
            placement="top"
            style={{
              heigth: 32,
            }}
          >
            <Button
              style={{
                padding: '4px 15px',
              }}
              type="link"
              onClick={(e) => e.preventDefault()}
            >
              <UploadOutlined />
              {screens.md && 'Importar'}
            </Button>
          </Dropdown>
          )}
          {(justImport && baseURI) && (
          <Button
            style={{
              padding: '4px 15px',
            }}
            type="link"
            onClick={() => {
              setVisibleModal(true);
            }}
          >
            <UploadOutlined />
            {screens.md && 'Importar'}
          </Button>
          )}
          {allowSearch && (
          <Button
            onClick={() => setVisibleSearch(!visibleSarch)}
            type="link"
          >
            <SearchOutlined />
            {screens.md && 'Búsqueda'}
          </Button>
          )}
          {!!allowedCustomActions.length && (
            allowedCustomActions.map(({ disabledBySelection = true, ...item }) => (
              <Button
                disabled={disabledBySelection
                  ? !rowSelection.selectedRowKeys.length : !!item.disabled}
                onClick={item.onClick}
                type="link"
                className={item?.className || ''}
                key={item.text}
              >
                {item.icon}
                {screens.md && item.text}
              </Button>
            ))
          )}
        </div>
      </Row>
    );
  }

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
  };

  function ModalUpload() {
    const [formCarga] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const onCancel = () => {
      setVisibleModal(false);
      formCarga.resetFields();
    };

    const onUpload = async () => {
      try {
        await formCarga.validateFields();
        const { file } = formCarga.getFieldsValue();
        if (file) {
          setLoading(true);
          const formData = new FormData();
          formData.append('file', file.file);
          const uri = baseURI.at(-1) === '/' ? baseURI : `${baseURI}/`;
          const response = await API.post(`${baseURL}${uri}carga/`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
          if (response?.status === 200) {
            onSuccess(response, null, 5);
            onCancel();
          }
        }
      } catch (err) {
        onError(err, setLoading);
      }
    };

    return (
      <Modal
        visible={visibleModal}
        title={(
          <FormSubmitControls
            label="Carga masiva"
            onFinish={onUpload}
            onCancel={onCancel}
            loading={loading}
          />
        )}
        onCancel={onCancel}
        footer={null}
        closable={false}
        maskClosable={!loading}
        keyboard={!loading}
        forceRender
        destroyOnClose
      >
        <Spin tip="Cargando..." spinning={loading}>
          <Form
            layout="vertical"
            form={formCarga}
            name={`carga_${componentKey}`}
            onFinish={onUpload}
          >
            <Dragger
              label="Archivo"
              limitMB={30}
              rules={rules.required}
              name="file"
              allowedExtensions={allowedExtensions}
            />
          </Form>
        </Spin>
      </Modal>
    );
  }

  const locale = {
    emptyText: hasAllViewPermission
      ? 'No hay datos' : 'Hacen falta permisos en los catálogos relacionados a este módulo',
  };

  const _dataSource = hasAllViewPermission ? dataSource : [];

  if (screens.md || !mobileColumns || mobileColumns.length > 1 || expandable) {
    return (
      <React.Fragment key={componentKey}>
        {(!noControls && !disabled) && (<TableControls />)}
        <AntTable
          size="small"
          columns={columns.filter((col) => selectedCols.includes(col.key))}
          dataSource={_dataSource}
          pagination={pagination && {
            position: ['bottomLeft'],
            pageSize,
          }}
          scroll={{
            y: tableHeight,
            x: !Number.isNaN(scrollX) ? scrollX : 0,
            scrollToFirstRowOnChange: true,
          }}
          rowSelection={disabled ? null : rowSelection}
          expandable={expandable}
          onRow={disabled ? null : onRow}
          className={`custom-table ${rowSelection && !disabled ? 'selectable' : ''}`}
          rowKey={rowKey}
          loading={tableLoading}
          locale={locale}
        />
        {(!pagination && count > 1) && (
          <Pagination
            page={page}
            setPage={setPage}
            callback={paginationCallback}
            count={count}
            q={q}
          />
        )}
        {(allowImport || justImport) && <ModalUpload />}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment key={componentKey}>
      {(!noControls && !disabled) && (<TableControls />)}
      <AntTable
        size="small"
        columns={mobileColumns}
        dataSource={_dataSource}
        pagination={pagination && {
          position: ['bottomLeft'],
        }}
        scroll={{ y: tableHeight, scrollToFirstRowOnChange: true }}
        rowSelection={disabled ? null : rowSelection}
        onRow={onRow}
        expandRowByClick
        className={`custom-table expandable-responsive-table ${rowSelection && disabled ? 'selectable' : ''}`}
        expandable={{
          expandedRowRender: (record) => cols
            .filter((c) => !c.hide)
            .map((column) => (
              <Row
                key={column.dataIndex}
                justify="space-between"
              >
                <b>
                  {`${column.titleText}:`}
                </b>
                <span>
                  {column.render
                    ? column.render(record[column.dataIndex], record)
                    : record[column.dataIndex]}
                </span>
              </Row>
            )),
          expandIcon: ({ expanded, onExpand, record }) => (record && (
            expanded ? (
              <DownOutlined onClick={(e) => {
                e.stopPropagation();
                onExpand(record, e);
              }}
              />
            ) : (
              <RightOutlined onClick={(e) => {
                e.stopPropagation();
                onExpand(record, e);
              }}
              />
            )
          )),
        }}
        rowKey={rowKey}
        loading={tableLoading}
        locale={locale}
      />
      {(!pagination && count > 1) && (
        <Pagination
          page={page}
          setPage={setPage}
          callback={paginationCallback}
          count={count}
        />
      )}
    </React.Fragment>
  );
}

Table.propTypes = {
  cols: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  mobileCols: PropTypes.arrayOf(PropTypes.object),
  rowSelection: PropTypes.shape({
    selectedRowKeys: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.number),
      PropTypes.arrayOf(PropTypes.string),
    ]),
    type: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  editText: PropTypes.string,
  noControls: PropTypes.bool,
  controls: PropTypes.shape({
    onClickAdd: PropTypes.func,
    onClickEdit: PropTypes.func,
    onClickExpand: PropTypes.func,
    onClickDelete: PropTypes.func,
    onClickConfig: PropTypes.func,
  }),
  onRow: PropTypes.func,
  allowEdit: PropTypes.bool,
  allowAdd: PropTypes.bool,
  allowDelete: PropTypes.bool,
  allowSearch: PropTypes.bool,
  allowSort: PropTypes.bool,
  allowConfig: PropTypes.bool,
  allowExpand: PropTypes.bool,
  expandable: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
  filterNested: PropTypes.bool,
  childrenProp: PropTypes.string,
  RTLHead: PropTypes.bool,
  rowKey: PropTypes.string,
  customActions: PropTypes.arrayOf(PropTypes.shape({
    onClick: PropTypes.func.isRequired,
    icon: PropTypes.node.isRequired,
    text: PropTypes.string.isRequired,
    disabledBySelection: PropTypes.bool,
  })),
  handleOnRowClick: PropTypes.func,
  mobileColIndex: PropTypes.number,
  pageSize: PropTypes.number,
  disabled: PropTypes.bool,
  allowColumnSelector: PropTypes.bool,
  baseURI: PropTypes.string,
  indentSize: PropTypes.number,
  loading: PropTypes.bool,
  justImport: PropTypes.bool,
  allowedExtensions: PropTypes.arrayOf(PropTypes.string),
  permission: PropTypes.shape({
    add: PropTypes.bool.isRequired,
    authorize: PropTypes.bool.isRequired,
    cancel: PropTypes.bool.isRequired,
    change: PropTypes.bool.isRequired,
    delete: PropTypes.bool.isRequired,
    export: PropTypes.bool.isRequired,
    import: PropTypes.bool.isRequired,
    sync: PropTypes.bool.isRequired,
    view: PropTypes.bool.isRequired,
    hasAllViewPermission: PropTypes.bool.isRequired,
  }),
  hasAllViewPermission: PropTypes.bool,
  pagination: PropTypes.bool,
  paginationCallback: PropTypes.func,
  count: PropTypes.number,
  currentPage: PropTypes.number,
};

Table.defaultProps = {
  mobileCols: [],
  data: [],
  editText: 'Editar',
  noControls: false,
  controls: null,
  rowSelection: null,
  onRow: null,
  allowEdit: true,
  allowAdd: true,
  allowDelete: true,
  allowSearch: true,
  allowSort: true,
  allowConfig: false,
  allowExpand: true,
  expandable: null,
  filterNested: false,
  childrenProp: null,
  RTLHead: false,
  rowKey: 'id',
  customActions: [],
  handleOnRowClick: null,
  mobileColIndex: 1,
  pageSize: 10,
  disabled: false,
  allowColumnSelector: true,
  baseURI: null,
  indentSize: 25,
  loading: false,
  justImport: false,
  allowedExtensions: ['xlsx'],
  permission: permissionbypass,
  hasAllViewPermission: false,
  pagination: true,
  paginationCallback: null,
  count: 0,
  currentPage: 1,
};

export default Table;
