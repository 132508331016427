import React, { useState, useEffect } from 'react';
import {
  Form,
  Button,
  Row,
  Col,
  Spin,
  Modal,
  message,
} from 'antd';
import Table from 'components/Table';
import API from 'utils/api';
import FormSubmitControls from 'components/FormSubmitControls';
import ModalDelete from 'components/ModalDelete';
import Select from 'components/Select';
import { onError, onSuccess } from 'utils/handlers';
import { isMutable } from 'utils/estadosGlobales';
import { useSelector } from 'react-redux';

export const permissionConfiguracionMomentoPresupuestal = {
  permissionModel: 'configuraciondemomentopresupuestal',
};

const baseURI = '/configuraciones/configuraciones-de-momento-presupuestal/';

// eslint-disable-next-line react/prop-types
function ConfigMomentosPresupuestales({ permission }) {
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [modulos, setModulos] = useState([]);
  const [documentos, setDocumentos] = useState([]);
  const [momentoEgreso, setMomentoEgreso] = useState([]);
  const [data, setData] = useState([]);
  const [mutable, setMutable] = useState(true);
  const [setFilteredModulos] = useState([]);
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  const [momentoIngreso, setMomentoIngreso] = useState([]);
  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await API.get(baseURI);
      setData(res.data);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const fetchAll = async () => {
    try {
      setLoading(true);
      const resModulo = await API.get('/catalogos/catalogos-de-modulos/');
      setModulos(resModulo.data || []);
      const resDoc = await API.get('/catalogos/catalogos-de-documentos/');
      setDocumentos(resDoc.data || []);
      const resMomentoEgreso = await API.get('/catalogos/momentos-presupuestales-de-egresos/');
      setMomentoEgreso(resMomentoEgreso.data || []);
      const resMomentoIngreso = await API.get('/catalogos/momentos-presupuestales-de-egresos/');
      setMomentoIngreso(resMomentoIngreso.data || []);
      await fetchData();
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchAll();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    setSelectedRowKeys([]);
    setMutable(true);
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      if (!selectedRowKeys.length) {
        const response = await API.post(baseURI, values);
        if (response?.status === 201) {
          onSuccess(response, 'Agregado correctamente');
          onCancel();
          await fetchData();
        }
      } else {
        const [key] = selectedRowKeys;
        const response = await API.put(`${baseURI}${key}/`, values);
        if (response?.status === 200) {
          onSuccess(response, 'Actualizado correctamente');
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const deleteItem = async () => {
    try {
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`${baseURI}${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          setVisibleAlert(false);
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    form.setFieldsValue(record);
    setMutable(isMutable(record));
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const onChangeDocs = (doc, reset = true) => {
    if (doc) {
      const matchDoc = documentos.find((e) => e.id === doc);
      const filtered = modulos.filter((e) => e.id === matchDoc.modulo);
      setFilteredModulos(filtered);
      if (reset) {
        form.resetFields(['modulo']);
      }
    }
  };

  const onClickEdit = () => {
    const [key] = selectedRowKeys;
    const clone = [...data];
    const match = clone.find((e) => e.id === key);
    onChangeDocs(match.documento, false);

    setVisible(true);
  };

  const onClickDelete = () => {
    if (mutable) {
      setVisibleAlert(true);
    } else {
      message.warn('No se pueden eliminar Configuraciones autorizadas o canceladas');
      setSelectedRowKeys([]);
    }
  };
  const statusList = [
    {
      label: 'Incremento',
      value: true,
    },
    {
      label: 'Disminución',
      value: false,
    },
  ];

  const columns = [
    {
      titleText: 'Módulo',
      dataIndex: 'modulo',
      key: 'modulo',
      width: 300,
      render: (val) => modulos.find((e) => e.id === val)?.descripcion,
    },
    {
      titleText: 'Documento',
      dataIndex: 'documento',
      key: 'documento',
      width: 250,
      render: (val) => documentos.find((e) => e.id === val)?.descripcion,
    },
    {
      titleText: 'Momento',
      dataIndex: 'momento_de_egreso',
      key: 'momento_de_egreso',
      width: 250,
      render: (val) => momentoEgreso.find((e) => e.id === val)?.nombre,
    },
    // {
    //   titleText: 'Momento de Ingreso',
    //   dataIndex: 'momento_de_ingreso',
    //   key: 'momento_de_ingreso',
    //   width: 250,
    //   render: (val) => momentoIngreso.find((e) => e.id === val)?.nombre,
    // },
    {
      titleText: 'Comportamiento',
      dataIndex: 'comportamiento_de_incremento',
      key: 'comportamiento_de_incremento',
      width: 250,
      render: (val) => (val ? 'Incremento' : 'Disminución'),
    },
    // {
    //   titleText: 'Estado',
    //   dataIndex: 'estados_globales',
    //   key: 'estados_globales',
    //   width: 250,
    //   render: (val) => estadosGlobales.find((e) => e.id === val)?.descripcion,
    // },
  ];

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
  };

  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        <Table
          cols={columns}
          permission={permission}
          data={data}
          rowSelection={rowSelection}
          handleOnRowClick={handleOnRowClick}
          controls={{
            onClickAdd,
            onClickEdit,
            onClickDelete,
          }}
          mobileColIndex={0}
        />
        <Modal
          visible={visible}
          title={(
            <FormSubmitControls
              label={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Momento Presupuestal`}
              onFinish={onFinish}
              onCancel={onCancel}
              loading={loading}
            />
          )}
          closable={false}
          maskClosable={!loading}
          keyboard={!loading}
          onCancel={onCancel}
          footer={null}
          forceRender
        >
          <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
            scrollToFirstError
            initialValues={{ estados_globales: 1 }}
          >
            <Row>
              <Col span={24}>
                <Form.Item
                  name="modulo"
                  label="Módulo"
                  rules={rules.required}
                  hasFeedback
                >
                  <Select dataSource={modulos} disabled={!mutable} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="documento"
                  label="Documento Operativo"
                  rules={rules.required}
                  hasFeedback
                >
                  <Select
                    dataSource={documentos}
                    disabled={!mutable}
                    onChange={onChangeDocs}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="momentos_de_egreso"
                  label="Momentos de Egreso"
                  rules={rules.required}
                  hasFeedback
                >
                  <Select
                    dataSource={momentoEgreso}
                    disabled={!mutable}
                    mode="multiple"
                    labelProp="nombre"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="momentos_de_ingreso"
                  label="Momentos de Ingreso"
                  rules={rules.required}
                  hasFeedback
                >
                  <Select
                    dataSource={momentoIngreso}
                    mode="multiple"
                    disabled={!mutable}
                    labelProp="nombre"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="estatus_del_proceso"
                  label="Estatus del Proceso"
                  rules={rules.required}
                  hasFeedback
                >
                  <Select
                    dataSource={momentoEgreso}
                    disabled={!mutable}
                    mode="multiple"
                    labelProp="nombre"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="comportamiento_de_incremento"
                  label="Comportamiento"
                  rules={rules.required}
                  hasFeedback
                >
                  <Select
                    dataSource={statusList}
                    labelProp="label"
                    valueProp="value"
                    disabled={!mutable}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="estados_globales"
                  label="Estado"
                  rules={rules.required}
                >
                  <Select
                    disabled={!selectedRowKeys.length || !mutable}
                    dataSource={estadosGlobales}
                  />
                </Form.Item>
              </Col>
              <Form.Item hidden>
                <Button htmlType="submit" />
              </Form.Item>
            </Row>
          </Form>
        </Modal>
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => {
            setSelectedRowKeys([]);
            setVisibleAlert(false);
          }}
          visible={visibleAlert}
          content="la configuración de momento presupuestal seleccionado "
          loading={loading}
        />
      </Spin>
    </Row>
  );
}
export default ConfigMomentosPresupuestales;
