import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Row,
  Col,
  Spin,
  Form,
  Button,
  Input,
  Modal,
  Tooltip,
  Typography,
} from 'antd';
import FormSubmitControls from 'components/FormSubmitControls';
import Table from 'components/Table';
import ModalDelete from 'components/ModalDelete';
import API from 'utils/api';
import { onError, onSuccess } from 'utils/handlers';
import Select from 'components/Select';
import { isMutable } from 'utils/estadosGlobales';
// import NumericInput from 'components/NumericInput';

const baseURI = '/presupuestos/justificacion-del-objetivo/';

function TablaJustificaciones({
  objetivoID,
}) {
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  // const user = useSelector(({ auth }) => auth.user);
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const [mutable, setMutable] = useState(true);

  const fetchData = async (idObjetivo = 0) => {
    try {
      setLoading(true);
      const id = objetivoID || idObjetivo;
      if (id !== 0) {
        const res = await API.get(baseURI, {
          params: {
            objetivo_problemas_objetivos: id,
          },
        });
        setData(res.data);
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const fetchAll = async () => {
    try {
      setLoading(true);

      await fetchData();
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchAll();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    // setSelectedRowKeys([]);
    form.resetFields();
    setVisible(false);
    setMutable(true);
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      if (!selectedRowKeys.length) {
        const res = await API.post(baseURI, values);
        if (res?.status === 201) {
          onSuccess(res, 'Agregado correctamente');
          onCancel();
          await fetchData();
        }
      } else {
        const [key] = selectedRowKeys;
        const res = await API.put(`${baseURI}${key}/`, values);
        if (res?.status === 200) {
          onSuccess(res, 'Actualizado correctamente');
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`${baseURI}${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          setVisibleAlert(false);
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    form.setFieldsValue(record);
    setMutable(isMutable(record));
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const columns = [
    {
      titleText: 'Descripcion',
      dataIndex: 'descripcion',
      key: 'descripcion',
      width: 200,
    },
    {
      titleText: 'Estado',
      dataIndex: 'estados_globales',
      key: 'estados_globales',
      width: 150,
      render: (val) => (estadosGlobales.find((e) => e.id === val)?.descripcion),
    },
  ];

  const requiredRule = {
    required: true,
    message: 'El campo es requerido',
  };

  const rules = {
    required: [requiredRule],
  };

  const onClickEdit = () => {
    setVisible(true);
  };

  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        <Row gutter={10}>
          <Col span={24}>
            <Tooltip
              title="Identificar y describir el problema o necesidad que se pretende atender, aportando la cuantificación del problema, definiendo su localización geográfica y distribución entre grupos de población permitiendo generar hipótesis respecto a sus causas y consecuencias."
            >
              <Typography.Title
                level={4}
              >
                Justificaciones
              </Typography.Title>
            </Tooltip>
            <Table // SEPARACION---------------------------------------------
              cols={columns}
              data={data}
              rowSelection={rowSelection}
              handleOnRowClick={handleOnRowClick}
              loading={loading}
              controls={{
                onClickAdd,
                onClickEdit,
                onClickDelete,
              }}
              mobileColIndex={0}
            />
          </Col>
          <Modal
            visible={visible}
            title={(
              <FormSubmitControls
                label={`${!selectedRowKeys.length ? 'Agregar' : 'Editar'} Justificacion`}
                onFinish={onFinish}
                onCancel={onCancel}
                mutable={mutable}
              />
              )}
            onCancel={onCancel}
            footer={null}
            closable={false}
            maskClosable={!loading}
            keyboard={!loading}
            forceRender
          >
            <Form
              name="general"
              form={form}
              layout="vertical"
              onFinish={onFinish}
              scrollToFirstError
              initialValues={{
                estados_globales: 1,
                objetivo_problemas_objetivos: objetivoID,
              }}
            >
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="Descripción"
                    name="descripcion"
                    hasFeedback
                  >
                    <Input.TextArea
                      autoSize={{ minRows: 2, maxRows: 2 }}
                      allowClear
                      disabled={!mutable}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="Estado"
                    rules={rules.required}
                    name="estados_globales"
                    hasFeedback
                  >
                    <Select
                      dataSource={estadosGlobales}
                      disabled={!selectedRowKeys.length || !mutable}
                    />
                  </Form.Item>
                </Col>
                <Form.Item
                  name="objetivo_problemas_objetivos"
                  hidden
                />
                <Form.Item hidden>
                  <Button htmlType="submit" />
                </Form.Item>
              </Row>
            </Form>
          </Modal>
          <ModalDelete
            onDelete={deleteItem}
            onCancel={() => setVisibleAlert(false)}
            visible={visibleAlert}
            content="la justificacion seleccionada"
            loading={loading}
          />
        </Row>
      </Spin>
    </Row>
  );
}

TablaJustificaciones.propTypes = {
  objetivoID: PropTypes.arrayOf(PropTypes.number),
};

TablaJustificaciones.defaultProps = {
  objetivoID: 0,
  // callback: () => { },
};
export default TablaJustificaciones;
