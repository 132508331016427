/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Form,
  Button,
  Row,
  Col,
  Spin,
  message,
  Card,
  Modal,
  Input,
} from 'antd';

import Table from 'components/Table';
import FormSubmitControls from 'components/FormSubmitControls';
import ModalDelete from 'components/ModalDelete';
import API from 'utils/api';
import { onError, onSuccess } from 'utils/handlers';
import { isMutable } from 'utils/estadosGlobales';
import makeMessager from 'utils/complexFormsMessages';
import TablaJustificaciones from './TablaJustificaciones';

const baseURI = 'presupuestos/objetivos-problemas-y-objetivos/';

const ayudaJustificacion = 'Identificar y describir el problema o necesidad que se pretende atender, aportando la cuantificación del problema, definiendo su localización geográfica y distribución entre grupos de población permitiendo generar hipótesis respecto a sus causas y consecuencias.';

function Objetivo({
  objetivoID,
  fatherId,
  fatherURI,
  setForm,
  setCurrentTabKey,

}) {
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);
  const [mutable, setMutable] = useState(true);

  const fetchData = async (idObjetivo = 0) => {
    try {
      setLoading(true);
      const id = objetivoID || idObjetivo;
      if (id !== 0) {
        const res = await API.get(`${baseURI}${id}`);
        setData([res.data]);
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const showMsg = makeMessager(setCurrentTabKey, async () => {
    setVisible(false);
    setSelectedRowKeys([]);
    await fetchData();
  });

  const onFinishHandler = async (tabKey = null, _continue = true) => {
    try {
      if (tabKey) {
        showMsg(tabKey);
      } else {
        // eslint-disable-next-line no-use-before-define
        await onFinish(null, _continue);
      }
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    form.onFinishHandler = onFinishHandler;
    setForm(form);
    fetchData();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, [objetivoID]);

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    setSelectedRowKeys([]);
  };

  const patchDiagnostico = async (objetivoIdData) => {
    try {
      setLoading(true);
      const resPatch = await API.patch(`${fatherURI}/${fatherId}`, {
        objetivo: objetivoIdData,
      });
      if (resPatch.status === 200) {
        message.info('Objetivo Agregada Correctamente');
      }
      setLoading(false);
    } catch (error) {
      onError(error, setLoading);
    }
  };

  const onFinish = async (values) => {
    try {
      setLoading(true);
      if (!selectedRowKeys.length) {
        const response = await API.post(baseURI, values);
        if (response?.status === 201) {
          onSuccess(response, 'Agregado correctamente');
          onCancel();
          patchDiagnostico(response.data.id);
          await fetchData(response.data.id);
        }
      } else {
        const [key] = selectedRowKeys;
        const response = await API.put(`${baseURI}${key}/`, values);
        if (response?.status === 200) {
          onSuccess(response, 'Actualizado correctamente');
          onCancel();
          await fetchData(response.data.id);
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    setMutable(isMutable(record));
    setTimeout(() => form.setFieldsValue({ ...record }));
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const onClickEdit = () => {
    setVisible(true);
    const [key] = selectedRowKeys;
    const match = data.find((e) => e.id === key);
    setMutable(isMutable(match));
    setTimeout(() => {
      form.setFieldsValue(match);
    });
  };

  const onClickDelete = () => {
    if (mutable) {
      setVisibleAlert(true);
    } else {
      message.info('No se pueden eliminar los Segmentos autorizados');
    }
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`${baseURI}${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          onCancel();
          setVisibleAlert(false);
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const columns = [
    {
      titleText: 'Objetivo',
      dataIndex: 'objetivo',
      key: 'objetivo',
      width: 130,
    },
    {
      titleText: 'Estado',
      dataIndex: 'estados_globales',
      key: 'estados_globales',
      width: 120,
      render: (val) => (estadosGlobales.find((g) => g.id === val)?.descripcion),
    },
  ];

  const handleSubmit = () => {
    form.submit();
  };

  function Formulario() {
    let props;
    const modal = !selectedRowKeys.length;
    if (modal) {
      props = {
        span: 24,
      };
    } else {
      props = {
        xs: 24,
        sm: 24,
        md: 8,
      };
    }
    return (
      <Form
        name="objetivo"
        layout="vertical"
        form={form}
        onFinish={() => onFinish(form.getFieldsValue())}
        scrollToFirstError
        initialValues={{ estados_globales: 1, ayuda_de_justificacion_objetivo: ayudaJustificacion }}
      >
        <Row gutter={10}>
          <Col {...props}>

            <Form.Item
              name="objetivo"
              label="Objetivo"
              rules={rules.required}
              // disabled={!!selectedRowKeys.length}
            >
              <Input.TextArea
                autoSize={{ minRows: 4, maxRows: 4 }}
              />
            </Form.Item>
          </Col>

          <Form.Item
            name="ayuda_de_justificacion_objetivo"
            hidden
          >
            <Input.TextArea />
          </Form.Item>

          {!modal && (
            <TablaJustificaciones
              objetivoID={selectedRowKeys[0]}
            />
          )}
          <Form.Item hidden>
            <Button htmlType="submit" />
          </Form.Item>
        </Row>
      </Form>
    );
  }

  return (
    <Row id="objetivo-form" className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!(visible && selectedRowKeys.length) && (
          <Table
            cols={columns}
            data={data}
            rowSelection={rowSelection}
            handleOnRowClick={handleOnRowClick}
            allowAdd={data.length === 0}
            controls={{
              onClickAdd,
              onClickEdit,
              onClickDelete,
            }}
            mobileColIndex={0}
          />
        )}
        {(visible && !!selectedRowKeys.length) && (
          <Card
            className="form-container"
            title={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Objetivo`}
            extra={(
              <FormSubmitControls
                onFinish={() => handleSubmit()}
                onCancel={onCancel}
                mutable={mutable}
                loading={loading}
                baseURI={baseURI}
                selectedRowKeys={selectedRowKeys}

              />
            )}
            bordered={false}
          >
            <Formulario />
          </Card>
        )}
      </Spin>
      {!selectedRowKeys.length && (
        <Modal
          visible={visible && !selectedRowKeys.length}
          title={(
            <FormSubmitControls
              label={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Objetivo`}
              onFinish={() => handleSubmit()}
              onCancel={onCancel}
              loading={loading}
            />
          )}
          closable={false}
          maskClosable={!loading}
          keyboard={!loading}
          onCancel={onCancel}
          footer={null}
          forceRender
        >
          <Formulario modal />
        </Modal>
      )}
      <ModalDelete
        onDelete={deleteItem}
        onCancel={() => setVisibleAlert(false)}
        visible={visibleAlert}
        loading={loading}
      />
    </Row>
  );
}

Objetivo.propTypes = {
  objetivoID: PropTypes.number,
  fatherId: PropTypes.arrayOf(PropTypes.number),
  fatherURI: PropTypes.string.isRequired,
  setCurrentTabKey: PropTypes.func.isRequired,
  setForm: PropTypes.func.isRequired,

};

Objetivo.defaultProps = {
  fatherId: [],
  // callback: () => { },
  objetivoID: 0,
};

export default Objetivo;
