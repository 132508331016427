import React from 'react';
import SingleForm from 'components/SingleForm';
import { getFormasDePago } from 'api/catalogos/';

export const permissionFormaDePago = {
  permissionModel: 'formadepago',
};

// eslint-disable-next-line react/prop-types
function FormasDePago({ permission }) {
  return (
    <SingleForm
      title="Formas De Pago"
      permission={permission}
      readOnly
      needsKey
      get={getFormasDePago}
    />
  );
}

export default FormasDePago;
