import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Row,
} from 'antd';
import {
  RocketOutlined,
} from '@ant-design/icons';

const { Text } = Typography;

function CardLanding({
  nombre,
  descripcion,
  color,
  url,
}) {
  return (
    <div className="cuadros-URL">
      <Row style={{ height: 150 }} align="center" justify="middle">
        LOGO HERE
      </Row>
      <div
        style={{
          margin: 'auto',
          width: 300,
          borderBlockEnd: '3px solid #C8C8C8',
          borderBottomWidth: '50%',
        }}
      />
      <div
        style={{
          height: 350,
          width: 300,
        }}
      >
        <br />
        <div style={{ height: 130, padding: '0 20px' }}>
          <Text className="titulos-tarjetas">
            {nombre}
          </Text>
          <p className="texto-tarjetas">
            {descripcion}
          </p>
        </div>
        <div style={{
          width: 300,
          height: 100,
          background: '#E9EFF2',
          borderRadius: '0px 0px 10px 10px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        >
          <a
            style={{
              width: '50px',
              height: '50px',
              background: color,
              boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.37)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '50%',
            }}
            href={url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <RocketOutlined className="user-icon-color-tarjetas" style={{ fontSize: '27px' }} />
          </a>
        </div>
      </div>
    </div>
  );
}

CardLanding.propTypes = {
  descripcion: PropTypes.string.isRequired,
  nombre: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};
export default CardLanding;
