import React from 'react';
import SingleForm from 'components/SingleForm';
import { getClasificacionAdministrativa } from 'api/catalogos/';

export const permissionClasificacionAdministrativa = {
  permissionModel: 'clasificacionadministrativa',
};

// eslint-disable-next-line react/prop-types
function ClasificacionAdministrativa({ permission }) {
  return (
    <SingleForm
      title="Clasificacion Administrativa"
      get={getClasificacionAdministrativa}
      readOnly
      withoutState
      permission={permission}
    />
  );
}

export default ClasificacionAdministrativa;
