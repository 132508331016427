import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Row,
  Col,
  Spin,
  Form,
  Button,
  // Input,
  Modal,
  Input,
  TreeSelect,
  message,
  Card,
} from 'antd';
import { SET_CONFIG_MASCARA_CONTABLE } from 'store/reducers/catalogs';
import FormSubmitControls from 'components/FormSubmitControls';
import Table from 'components/Table';
import ModalDelete from 'components/ModalDelete';
import API from 'utils/api';
import { onError, onSuccess } from 'utils/handlers';
// import Select from 'components/Select';
import { isMutable } from 'utils/estadosGlobales';
import NumericInput from 'components/NumericInput';
import Select from 'components/Select';
import { formatReceived } from 'utils/formatValues';
import FormItemLabel from 'components/FormItemLabel';
import makeThreeFromCOG from 'utils/makeTree';
import { findItemNested } from 'utils/filters';
import { InputSearchPro } from 'components/InputSearch';
import fetchSome from 'utils/fetchSome';
import BusquedaClavesContables from 'components/BusquedaClavesContables';
// import CuentasContables from 'views/Contabilidad/CuentaContable';

export const permissionCatalogoClasificadorDeActivo = {
  permissionModel: 'catalogoclasificadordeactivo',
};

const baseURI = '/adquisiciones/catalogo-clasificador-de-activo/';

// eslint-disable-next-line react/prop-types
function ClasificadorDeActivos({ permission }) {
  const configMascaraContable = useSelector(({ catalogs }) => catalogs.configMascaraContable);
  const [form] = Form.useForm();
  const requiereRegistroCuentaContable = Form.useWatch('requiere_registro_cuentas_contables', form) || false;
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [cog, setCog] = useState([]);
  const [cuentaContableFound, setCuentaContableFound] = useState([]);
  const [cuentaGastoFound, setCuentaGastoFound] = useState([]);
  const [cuentaDepreciacionFound, setCuentaDepreciacionFound] = useState([]);
  const [visibleBusquedaCuentas, setVisibleBusquedaCuentas] = useState(false);
  const [visibleBusquedaCuentasGastos, setVisibleBusquedaCuentasGastos] = useState(false);
  const [visibleBusquedaCuentaDepreciacion, setVisibleBusquedaCuentaDepreciacion] = useState(false);
  const [mascara, setMascara] = useState();
  const [separador, setSeparador] = useState();
  const [selectedClave, setSelectedClave] = useState();
  const [pattern, setPattern] = useState();

  const [tipoDeAplicacion, setTipoDeAplicacion] = useState([]);
  const [tipoDeActivo, setTipoDeActivo] = useState([]);
  const [cuentaContable, setCuentaContable] = useState([]);
  const [mutable, setMutable] = useState(true);

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await API.get(baseURI);
      setData(res.data);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const normalizeCog = (_data = []) => _data.map((item) => {
    const clone = { ...item };
    const children = clone.children?.length && typeof clone.id === 'string'
      ? normalizeCog(clone.children) : [];
    if (!children.length || typeof clone.id === 'number') {
      delete clone.children;
      return ({
        ...clone,
        key: clone.clave,
        clave: clone.clave,
        disabled: !clone.seleccionable,
      });
    }
    return ({
      ...clone,
      key: clone.clave,
      children,
      disabled: !clone.seleccionable,
    });
  });

  const normalizeData = (_data, prop = 'concepto', nested = true) => {
    if (_data?.length) {
      const output = _data.map((item) => {
        const clone = { ...item };
        const children = normalizeData(clone.children, prop);
        const identificator = nested
          ? `${clone.id.toString()}${item.seleccionable ? '' : '_parent'}` : clone.id;
        delete clone.children;
        if (!children.length) {
          return ({
            ...clone,
            value: parseInt(identificator, 10),
            title: `${item.clave} - ${item[prop]}`,
            key: parseInt(identificator, 10),
            selectable: item.seleccionable,
            checkable: item.seleccionable,
          });
        }
        return ({
          ...clone,
          value: identificator,
          title: `${item.clave} - ${item[prop]}`,
          children,
          disabled: true,
          selectable: item.seleccionable,
          checkable: item.seleccionable,
          key: identificator,
        });
      });
      return output;
    }
    return [];
  };

  const fetchAll = async () => {
    try {
      const resTipoDeUbicacion = await API.get('/controlpatrimonial/tipos-de-aplicaciones/');
      setTipoDeAplicacion(resTipoDeUbicacion.data || []);
      const resTipoDeActivo = await API.get('/controlpatrimonial/tipos-de-activos/');
      setTipoDeActivo(resTipoDeActivo.data || []);

      const resCuentaContable = await API.get('contabilidad/cuentas-contables/');
      setCuentaContable(resCuentaContable.data || []);

      const resCOG = await API.get('contabilidad/clasificaciones-objeto-gasto/');
      const tree = makeThreeFromCOG(resCOG.data.map((e) => (!e.seleccionable ? ({
        ...e,
        id: `${e.id}`,
      }) : e)).sort((a, b) => a.id - b.id));
      setCog(normalizeData(normalizeCog(tree)));

      if (configMascaraContable) {
        setMascara(configMascaraContable?.mascara);
        const separator = configMascaraContable?.separador;
        setSeparador(separator);
        const _segmentos = configMascaraContable?.mascara?.split(separator) || [];
        if (_segmentos?.length) {
          // eslint-disable-next-line prefer-template
          const patt = _segmentos.reduce((acum, curr, idx) => `${acum}[0-9]{${curr.length}}`
            + (idx !== _segmentos.at(-1) ? separator : '$'), '^');
          const regExp = new RegExp(`${patt}`);
          setPattern(regExp);
        }
      } else {
        await fetchSome('configuraciones/configuraciones-de-mascaras/', SET_CONFIG_MASCARA_CONTABLE);
      }

      await fetchData();
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };
  useEffect(() => {
    fetchAll();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setSelectedRowKeys([]);
    form.resetFields();
    setVisible(false);
    setMutable(true);
    setCuentaContableFound([]);
    setCuentaGastoFound([]);
    setCuentaDepreciacionFound([]);
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();

      if (!selectedRowKeys.length) {
        const res = await API.post(baseURI, values);
        if (res?.status === 201) {
          onSuccess(res, 'Agregado correctamente');
          onCancel();
          await fetchData();
        }
      } else {
        const [key] = selectedRowKeys;
        const res = await API.put(`${baseURI}${key}/`, values);
        if (res?.status === 200) {
          onSuccess(res, 'Actualizado correctamente');
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`${baseURI}${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          setVisibleAlert(false);
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    // form.setFieldsValue(record);
    form.setFieldsValue(formatReceived({ ...record }));
    setMutable(isMutable(record));
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const columns = [
    {
      titleText: 'Nivel',
      dataIndex: 'nivel',
      key: 'nivel',
      width: 150,
    },
    {
      titleText: 'Concepto',
      dataIndex: 'concepto',
      key: 'concepto',
      width: 200,
    },
    {
      titleText: 'Tipo de Aplicacion',
      dataIndex: 'tipo_de_aplicacion',
      key: 'tipo_de_aplicacion',
      width: 150,
      render: (val) => tipoDeAplicacion.find((e) => e.id === val)?.descripcion,
    },
    {
      titleText: 'Porcentaje %',
      dataIndex: 'porcentaje',
      key: 'porcentaje',
      width: 150,
      align: 'right',
    },
    {
      titleText: 'Tipo de Activo',
      dataIndex: 'tipo_de_activo',
      key: 'tipo_de_activo',
      width: 150,
      render: (val) => tipoDeActivo.find((e) => e.id === val)?.descripcion,
    },
    {
      titleText: 'COG',
      dataIndex: 'cog',
      key: 'cog',
      render: (val) => {
        const match = findItemNested(cog, val, 'key');
        return match?.clave;
      },
      width: 150,
    },
    {
      titleText: 'Cuenta Alta de Activo Fijo',
      dataIndex: 'cuenta_alta_activo_fijo',
      key: 'cuenta_alta_activo_fijo',
      width: 150,
      render: (val) => cuentaContable.find((e) => e.id === val)?.nombre,

    },
    {
      titleText: 'Cuentas Gastos',
      dataIndex: 'cuentas_de_gasto',
      key: 'cuentas_de_gasto',
      width: 150,
      render: (val) => cuentaContable.find((e) => e.id === val)?.nombre,

    },
    {
      titleText: 'Cuenta Depreciación, Amortización y Deterioro',
      dataIndex: 'cuenta_depreciacion_amortizacion_deterioro',
      key: 'cuenta_depreciacion_amortizacion_deterioro',
      width: 150,
      render: (val) => cuentaContable.find((e) => e.id === val)?.nombre,
    },
  ];

  const resetAndValidateCuentaContable = () => {
    setCuentaContableFound([]);
    form.resetFields(['clave_contable']);
    form.validateFields(['clave_contable']);
  };
  const resetAndValidateCuentaGasto = () => {
    setCuentaGastoFound([]);
    form.resetFields(['cuentas_de_gasto']);
    form.validateFields(['cuentas_de_gasto']);
  };
  const resetAndValidateCuentaDepreciacion = () => {
    setCuentaDepreciacionFound([]);
    form.resetFields(['cuenta_depreciacion_amortizacion_deterioro']);
    form.validateFields(['cuenta_depreciacion_amortizacion_deterioro']);
  };

  const filterTreeNode = (input, node) => {
    const title = node.title?.props.children.toLowerCase() || node.title;
    if (title && title.includes(input.toLowerCase())) {
      return true;
    }
    if (node.children) {
      return filterTreeNode(input, node.children);
    }
    return false;
  };

  let timeoutFilter;

  const setTimeoutFilter = (input, node) => setTimeout(() => filterTreeNode(input, node), 1000);

  const filterTree = (input, node) => {
    clearTimeout(timeoutFilter);
    timeoutFilter = setTimeoutFilter(input, node);
    return timeoutFilter ? filterTreeNode(input, node) : true;
  };

  const fetchCuentaContable = async () => {
    try {
      form.validateFields(['clave_contable__clave']);
      const clave = form.getFieldValue('clave_contable__clave');
      if (clave) {
        setLoading(true);
        const res = await API.get('contabilidad/cuentas-contables/', {
          params: {
            // clave_contable: clave,
            clave,
            // tipo_de_cuenta_contable: 1,
            tipo: 4,
          },
        });
        if (res?.status === 200 && res.data?.length) {
          const claveContable = res.data[0];
          setCuentaContableFound(claveContable);
          setTimeout(() => {
            form.validateFields(['cuenta_alta_activo_fijo']);
          });
          setTimeout(() => {
            form.setFieldsValue({
              cuenta_alta_activo_fijo: claveContable.id,
            });
          });
        } else {
          message.info('No se encontraron coincidencias');
          setCuentaContableFound([]);
        }
        setLoading(false);
      }
    } catch (err) {
      onError(err, setLoading);
      setCuentaContableFound([]);
    }
  };

  const onChangeClaveContable = (e) => {
    const { target: { value }, keyCode } = e;
    let val = value;
    if (keyCode) {
      const char = String.fromCharCode(keyCode).toLowerCase();
      val += char;
    }
    if (mascara.charAt(val.length) === separador) {
      val += separador;
    }
    if (val.length === mascara?.length) {
      setTimeout(fetchCuentaContable);
    } else if (!val?.length) {
      resetAndValidateCuentaContable();
    }
    setTimeout(() => {
      form.setFieldsValue({ clave_contable__clave: val });
    });
  };

  const onFinishBusqueda = () => {
    if (selectedClave) {
      setCuentaContableFound([selectedClave]);
      setTimeout(() => {
        form.setFieldsValue({ cuenta_alta_activo_fijo: selectedClave.id });
      });
      setVisibleBusquedaCuentas(false);
    } else {
      message.info('No ha seleccionado una Clave Contable');
    }
  };

  const fetchCuentaGasto = async () => {
    try {
      form.validateFields(['cuenta_gasto__clave']);
      const clave = form.getFieldValue('cuenta_gasto__clave');
      if (clave) {
        setLoading(true);
        const res = await API.get('contabilidad/cuentas-contables/', {
          params: {
            // clave_contable: clave,
            // tipo_de_cuenta_contable: 5,
            clave,
            tipo: 4,
          },
        });
        if (res?.status === 200 && res.data?.length) {
          const claveContable = res.data[0];
          setCuentaGastoFound([claveContable]);
          setTimeout(() => {
            form.setFieldsValue({
              cuentas_de_gasto: claveContable.id,
            });
          });
          setTimeout(() => {
            form.validateFields(['cuenta_gasto__clave']);
          });
        } else {
          message.info('No se encontraron coincidencias');
          setCuentaGastoFound([]);
        }
        setLoading(false);
      }
    } catch (err) {
      onError(err, setLoading);
      setCuentaGastoFound([]);
    }
  };

  const onChangeCuentaGasto = (e) => {
    const { target: { value }, keyCode } = e;
    let val = value;
    if (keyCode) {
      const char = String.fromCharCode(keyCode).toLowerCase();
      val += char;
    }
    if (mascara.charAt(val.length) === separador) {
      val += separador;
    }
    if (val.length === mascara?.length) {
      setTimeout(fetchCuentaGasto);
    } else if (!val?.length) {
      resetAndValidateCuentaGasto();
    }
    setTimeout(() => {
      form.setFieldsValue({ cuenta_gasto__clave: val });
    });
  };

  const onFinishBusquedaCuentaGasto = () => {
    if (selectedClave) {
      setCuentaGastoFound([selectedClave]);
      setTimeout(() => {
        form.setFieldsValue({ cuentas_de_gasto: selectedClave.id });
      });
      setVisibleBusquedaCuentasGastos(false);
    } else {
      message.info('No ha seleccionado una Clave Contable');
    }
  };

  const fetchCuentaDepreciacion = async () => {
    try {
      form.validateFields(['cuenta_depreciacion__clave']);
      const clave = form.getFieldValue('cuenta_depreciacion__clave');
      if (clave) {
        setLoading(true);
        const res = await API.get('contabilidad/cuentas-contables/', {
          params: {
            // clave_contable: clave,
            // tipo_de_cuenta_contable: 5,
            clave,
            tipo: 4,
          },
        });
        if (res?.status === 200 && res.data?.length) {
          const claveContable = res.data[0];
          setCuentaDepreciacionFound([claveContable]);
          setTimeout(() => {
            form.setFieldsValue({
              cuenta_depreciacion_amortizacion_deterioro: claveContable.id,
            });
          });
          setTimeout(() => {
            form.validateFields(['cuenta_depreciacion__clave']);
          });
        } else {
          message.info('No se encontraron coincidencias');
          setCuentaDepreciacionFound([]);
        }
        setLoading(false);
      }
    } catch (err) {
      onError(err, setLoading);
      setCuentaDepreciacionFound([]);
    }
  };

  const onChangeCuentaDepreciacion = (e) => {
    const { target: { value }, keyCode } = e;
    let val = value;
    if (keyCode) {
      const char = String.fromCharCode(keyCode).toLowerCase();
      val += char;
    }
    if (mascara.charAt(val.length) === separador) {
      val += separador;
    }
    if (val.length === mascara?.length) {
      setTimeout(fetchCuentaDepreciacion);
    } else if (!val?.length) {
      resetAndValidateCuentaDepreciacion();
    }
    setTimeout(() => {
      form.setFieldsValue({ cuenta_depreciacion__clave: val });
    });
  };

  const onFinishBusquedaCuentaDepreciacion = () => {
    if (selectedClave) {
      setCuentaDepreciacionFound([selectedClave]);
      setTimeout(() => {
        form.setFieldsValue({ cuenta_depreciacion_amortizacion_deterioro: selectedClave.id });
      });
      setVisibleBusquedaCuentaDepreciacion(false);
    } else {
      message.info('No ha seleccionado una Clave Contable');
    }
  };

  const requiredRule = {
    required: true,
    message: 'El campo es requerido',
  };

  const rules = {
    required: [requiredRule],
    clave_contable__clave: [
      requiredRule,
      {
        validator: async (rule, value) => {
          if (value && pattern && (!pattern.test(value))) {
            throw new Error('Introduzca una clave válida');
          }
        },
      },
    ],
    cuenta_gasto__clave: [
      requiredRule,
      {
        validator: async (rule, value) => {
          if (value && pattern && (!pattern.test(value))) {
            throw new Error('Introduzca una clave válida');
          }
        },
      },
    ],
    cuenta_depreciacion_amortizacion_deterioro: [
      requiredRule,
      {
        validator: async (rule, value) => {
          if (value && pattern && (!pattern.test(value))) {
            throw new Error('Introduzca una clave válida');
          }
        },
      },
    ],
  };

  const onClickEdit = async () => {
    try {
      setLoading(true);
      const [key] = selectedRowKeys;
      const clone = [...data];
      const match = clone.find((e) => e.id === key);
      if (match.cuenta_alta_activo_fijo != null) {
        const resCuenta = await API.get(`contabilidad/cuentas-contables/${match.cuenta_alta_activo_fijo}`);
        setCuentaContableFound([resCuenta.data]);
        const resCuentaGasto = await API.get(`contabilidad/cuentas-contables/${match.cuentas_de_gasto}`);
        setCuentaGastoFound([resCuentaGasto.data]);
        const resCuentaDepreciacion = await API.get(`contabilidad/cuentas-contables/${match.cuenta_depreciacion_amortizacion_deterioro}`);
        setCuentaDepreciacionFound([resCuentaDepreciacion.data]);
      }
      setMutable(isMutable(match));
      setVisible([true]);
      setTimeout(() => {
        form.setFieldsValue({
          ...match,
        });
      });
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible ? (
          <Table
            cols={columns}
            data={data}
            rowSelection={rowSelection}
            handleOnRowClick={handleOnRowClick}
            loading={loading}
            permission={permission}
            controls={{
              onClickAdd,
              onClickEdit,
              onClickDelete,
            }}
            mobileColIndex={0}
          />
        ) : (
          <Card
            visible={visible}
            title={(
              <FormSubmitControls
                label={`${!selectedRowKeys.length ? 'Agregar' : 'Editar'} Clasificador de Activos`}
                onFinish={onFinish}
                onCancel={onCancel}
                mutable={mutable}
              />
            )}
            onCancel={onCancel}
            footer={null}
            closable={false}
            maskClosable={!loading}
            keyboard={!loading}
            forceRender
          >
            <Form
              name="general"
              form={form}
              layout="vertical"
              onFinish={onFinish}
              scrollToFirstError
            >
              <Row gutter={10}>
                <Col xs={24} sm={24} md={8}>
                  <NumericInput
                    name="nivel"
                    label="Nivel"
                    required
                  />
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    label="Concepto"
                    rules={rules.required}
                    name="concepto"
                    hasFeedback
                  >
                    <Input
                    // autoSize={{ minRows: 1, maxRows: 2 }}
                      allowClear
                      disabled={!mutable}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    label="Tipo de aplicación"
                    rules={rules.required}
                    name="tipo_de_aplicacion"
                    hasFeedback
                  >
                    <Select
                      dataSource={tipoDeAplicacion}

                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    label="Porcentaje %"
                    rules={rules.required}
                    name="porcentaje"
                    hasFeedback
                  >
                    <NumericInput
                      allowClear
                      decimal
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    label="Tipo de activo"
                    rules={rules.required}
                    name="tipo_de_activo"
                    hasFeedback
                  >
                    <Select
                      dataSource={tipoDeActivo}

                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="cog"
                    rules={rules.required}
                    label={(
                      <FormItemLabel
                        longTitle="Clasificador por Objeto del Gasto"
                        shortTitle="COG"
                        hideOn={['sm', 'md']}
                      />
                              )}
                  >
                    <TreeSelect
                      showSearch
                      showArrow
                      treeNodeFilterProp="title"
                      style={{ width: '100%' }}
                      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                      treeData={cog}
                      filterTreeNode={filterTree}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="requiere_registro_cuentas_contables"
                    label="Requiere registro de cuentas contables"
                  >
                    <Select
                      trueFalse
                    />
                  </Form.Item>
                </Col>
              </Row>
              {requiereRegistroCuentaContable && (
              <Row gutter={10}>
                <InputSearchPro // ?
                  name="clave_contable__clave"
                  onClickAdvanced={() => setVisibleBusquedaCuentas(true)}
                  rules={rules.clave_contable__clave}
                  label="Cuenta Contable del Activo"
                  tooltip={`Debe concordar con la mascara ${mascara}`}
                  inputProps={{
                    onPressEnter: () => fetchCuentaContable(),
                    onChange: onChangeClaveContable,
                    maxLength: mascara?.length,
                  }}
                  breakPoints={{
                    xs: 24,
                    sm: 24,
                    md: 8,
                  }}
                  resultProps={{
                    name: 'cuenta_alta_activo_fijo',
                    label: 'Cuenta Contable del Activo',
                    rules: rules.required,
                    dataSource: cuentaContableFound,
                    keyLabelRender: true,
                    keyProp: 'clave',
                    labelProp: 'nombre',
                    onClear: resetAndValidateCuentaContable,
                    breakPoints: {
                      xs: 24,
                      sm: 24,
                      md: 8,
                    },
                  }}
                />
                <InputSearchPro // ?
                  name="cuenta_gasto__clave"
                  onClickAdvanced={() => setVisibleBusquedaCuentasGastos(true)}
                  rules={rules.cuenta_gasto__clave}
                  label="Cuenta Contable de gasto"
                  tooltip={`Debe concordar con la mascara ${mascara}`}
                  inputProps={{
                    onPressEnter: () => fetchCuentaGasto(),
                    onChange: onChangeCuentaGasto,
                    maxLength: mascara?.length,
                  }}
                  breakPoints={{
                    xs: 24,
                    sm: 24,
                    md: 8,
                  }}
                  resultProps={{
                    name: 'cuentas_de_gasto',
                    label: 'Cuenta Contable de gasto',
                    rules: rules.required,
                    dataSource: cuentaGastoFound,
                    keyLabelRender: true,
                    keyProp: 'clave',
                    labelProp: 'nombre',
                    onClear: resetAndValidateCuentaGasto,
                    breakPoints: {
                      xs: 24,
                      sm: 24,
                      md: 8,
                    },
                  }}
                />
                <InputSearchPro
                  name="cuenta_depreciacion__clave"
                  onClickAdvanced={() => setVisibleBusquedaCuentaDepreciacion(true)}
                  rules={rules.cuenta_depreciacion_amortizacion_deterioro}
                  label="Cuenta Contable del Cálculo"
                  tooltip={`Debe concordar con la mascara ${mascara}`}
                  inputProps={{
                    onPressEnter: () => fetchCuentaDepreciacion(),
                    onChange: onChangeCuentaDepreciacion,
                    maxLength: mascara?.length,
                  }}
                  breakPoints={{
                    xs: 24,
                    sm: 24,
                    md: 8,
                  }}
                  resultProps={{
                    name: 'cuenta_depreciacion_amortizacion_deterioro',
                    label: 'Cuenta Contable del Cálculo',
                    rules: rules.required,
                    dataSource: cuentaDepreciacionFound,
                    keyLabelRender: true,
                    keyProp: 'clave',
                    labelProp: 'nombre',
                    onClear: resetAndValidateCuentaDepreciacion,
                    breakPoints: {
                      xs: 24,
                      sm: 24,
                      md: 8,
                    },
                  }}
                />

              </Row>
              )}
              <Form.Item hidden>
                <Button htmlType="submit" />
              </Form.Item>
            </Form>
          </Card>
        )}
        <Modal
          visible={visibleBusquedaCuentas}
          title={(
            <Row justify="space-between">
              Búsqueda Avanzada Cuentas
              <FormSubmitControls
                onCancel={() => setVisibleBusquedaCuentas(false)}
                onFinish={onFinishBusqueda}
              />
            </Row>
            )}
          onCancel={() => setVisibleBusquedaCuentas(false)}
          closable={false}
          footer={null}
          width={800}
          forceRender
          centered
        >
          <BusquedaClavesContables callback={setSelectedClave} />
        </Modal>
        <Modal
          visible={visibleBusquedaCuentasGastos}
          title={(
            <Row justify="space-between">
              Búsqueda Avanzada Cuentas
              <FormSubmitControls
                onCancel={() => setVisibleBusquedaCuentasGastos(false)}
                onFinish={onFinishBusquedaCuentaGasto}
              />
            </Row>
            )}
          onCancel={() => setVisibleBusquedaCuentasGastos(false)}
          closable={false}
          footer={null}
          width={800}
          forceRender
          centered
        >
          <BusquedaClavesContables callback={setSelectedClave} />
        </Modal>
        <Modal
          visible={visibleBusquedaCuentaDepreciacion}
          title={(
            <Row justify="space-between">
              Búsqueda Avanzada Cuentas
              <FormSubmitControls
                onCancel={() => setVisibleBusquedaCuentaDepreciacion(false)}
                onFinish={onFinishBusquedaCuentaDepreciacion}
              />
            </Row>
            )}
          onCancel={() => setVisibleBusquedaCuentaDepreciacion(false)}
          closable={false}
          footer={null}
          width={800}
          forceRender
          centered
        >
          <BusquedaClavesContables callback={setSelectedClave} />
        </Modal>
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => setVisibleAlert(false)}
          visible={visibleAlert}
          content="El identificador seleccionado"
          loading={loading}
        />
      </Spin>
    </Row>
  );
}

export default ClasificadorDeActivos;
