import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Row,
  Col,
  Spin,
  Card,
  Form,
  Button,
  message,
  Input,
  DatePicker,
  Tabs,
  Typography,
} from 'antd';
import FormSubmitControls from 'components/FormSubmitControls';
import Table from 'components/Table';
import ModalDelete from 'components/ModalDelete';
import API from 'utils/api';
import { onError, onSuccess } from 'utils/handlers';
import getFormattedValues, { formatReceived } from 'utils/formatValues';
import Select from 'components/Select';
import { isMutable } from 'utils/estadosGlobales';
// import { InputSearchPro } from 'components/InputSearch';
import makeMessager from 'utils/complexFormsMessages';
import fetchSome from 'utils/fetchSome';
import { SET_URS } from 'store/reducers/catalogs';
import SituacionNoDeseada from './SituacionNoDeseada';
import Objetivo from './Objetivo';
import Cobertura from './Cobertura';
import ArbolDeProblemas from './ArbolDeProblemas';

export const permissionDiagnosticoArbolDeProblemasyObjetivos = {
  permissionModel: 'diagnosticoarboldeproblemasyobjetivos',
};

const baseURI = '/presupuestos/diagnosticos-de-arbol-de-problemas-y-objetivos';
const { TabPane } = Tabs;
const { Text } = Typography;

// eslint-disable-next-line react/prop-types
function DiagnosticoArbolDeProblemaObjetivo({ permission }) {
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  const urs = useSelector(({ catalogs }) => catalogs.urs);
  const usuario = useSelector(({ auth }) => auth.user);
  // const [form] = Form.useForm();
  const [formTab] = Form.useForm();
  const soloUnAnio = Form.useWatch('un_solo_ejercicio', formTab) || false;
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [formSituacionNoDeseada, setFormSituacionNoDeseada] = useState();
  const [formCobertura, setFormCobertura] = useState();
  const [formObjetivo, setFormObjetivo] = useState();
  const [puestos, setPuestos] = useState([]);
  const [programas, setProgramas] = useState([]);
  const [unidadResponsable, setUnidadResponsable] = useState([]);
  const [unidadesOperativas, setUnidadesOperativas] = useState([]);
  const [centroDeCostos, setCentroDeCostos] = useState([]);
  const [currentTabKey, setCurrentTabKey] = useState('generalTab');
  const [mutable, setMutable] = useState(true);
  const [empleados, setEmpleados] = useState([]);
  const [dataSituacion, setDataSituacion] = useState(0);
  const [dataCobertura, setDataCobertura] = useState(0);
  const [dataObjetivo, setDataObjetivo] = useState(0);
  const [currenteContentType, setCurrenteContentType] = useState(null);
  const [usuarioSeleccionado, setUsuarioSeleccionado] = useState([]);
  const basicKeys = [
    'generalTab',
    'arbolDeProblemas',
  ];
  const complexForms = [
    { key: 'situacionNoDeseada', formInstance: formSituacionNoDeseada },
    { key: 'cobertura', formInstance: formCobertura },
    { key: 'objetivo', formInstance: formObjetivo },
  ];

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await API.get(baseURI);
      setData(formatReceived(res.data));
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };
  const fetchAll = async () => {
    try {
      setLoading(true);

      const resCC = await API.get('/estructura-organizacional/centros-de-costos/');
      setCentroDeCostos(resCC.data || []);

      const resUR = await API.get('/estructura-organizacional/unidades-responsables/');
      setUnidadResponsable(resUR.data || []);

      const resUO = await API.get('/estructura-organizacional/unidades-de-trabajo/');
      setUnidadesOperativas(resUO.data || []);

      const resPrograma = await API.get('/contabilidad/programas/');
      setProgramas(resPrograma.data || []);

      const resEmpleados = await API.get('/estructura-organizacional/empleados/');
      setEmpleados(resEmpleados.data || []);

      const resPuestos = await API.get('/estructura-organizacional/puesto/');
      setPuestos(resPuestos.data || []);

      const resUsuario = await API.get('/usuarios/usuarios/');
      setUsuarioSeleccionado(resUsuario.data);

      if (!urs?.length) {
        await fetchSome('catalogos/content-types-ur', SET_URS);
      }
      await fetchData();
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchAll();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setSelectedRowKeys([]);
    setVisible(false);
    setCurrentTabKey('generalTab');
    setMutable(true);
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`${baseURI}/${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          setVisibleAlert(false);
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onClickEdit = async () => {
    try {
      setLoading(true);
      const [key] = selectedRowKeys;
      const clone = [...data];
      const match = clone.find((e) => e.id === key);
      setMutable(isMutable(match));
      setVisible(true);
      if (match.un_solo_ejercicio) {
        setTimeout(() => {
          formTab.setFieldsValue({
            ...match,
          });
        });
      } else {
        setTimeout(() => {
          formTab.setFieldsValue({
            ...match,
            anio_inicial_y_final: [
              match.anio_inicial,
              match.anio_final,
            ],
          });
        });
      }
      if (match.content_type_unidad_responsable) {
        const contentType = urs.find((e) => e.id === match.content_type_unidad_responsable);
        setCurrenteContentType(contentType);
        const allURS = {
          1: unidadesOperativas,
          2: unidadResponsable,
          3: centroDeCostos,
        };
        const matchUR = allURS?.[contentType.nivel].find(
          (i) => i.id === match.object_id_unidad_responsable,
        );
        setTimeout(() => {
          formTab.setFieldsValue({
            content_type_unidad_responsable: match.content_type_unidad_responsable,
            object_id_unidad_responsable: matchUR?.id,
          });
        });
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onFinish = async (_values, _continue = false) => {
    try {
      setLoading(true);
      await formTab.validateFields();
      const values = {
        ...formTab.getFieldsValue(),
        empleado: usuario?.empleado,
      };

      if (!values.un_solo_ejercicio) {
        const formattedValues = getFormattedValues({
          ...values,
          anio_inicial: values.anio_inicial_y_final[0],
          anio_final: values.anio_inicial_y_final[1],
        }, {
          clean: false,
        });
        if (!selectedRowKeys.length) {
          const response = await API.post(baseURI, formattedValues);
          if (response?.status === 201) {
            if (!_continue) {
              onSuccess(response, 'Agregado correctamente');
              onCancel();
              await fetchData();
            } else {
              onSuccess(response, 'Agregado correctamente');
              await fetchData();
              setSelectedRowKeys([response.data.id]);
              onClickEdit(response.data);
            }
          }
        } else {
          const [key] = selectedRowKeys;
          const response = await API.put(`${baseURI}/${key}/`, formattedValues);
          if (response?.status === 200) {
            if (response?.status === 201) {
              if (!_continue) {
                onSuccess(response, 'Actualizado correctamente');
                onCancel();
                await fetchData();
              } else {
                await fetchData();
                setSelectedRowKeys([response.data.id]);
              }
            }
          }
        }
      } else {
        const formattedValues = getFormattedValues({
          ...values,
          anio: values.anio,
        }, {
          clean: false,
        });
        if (!selectedRowKeys.length) {
          const response = await API.post(baseURI, formattedValues);
          if (response?.status === 201) {
            if (!_continue) {
              onSuccess(response, 'Agregado correctamente');
              onCancel();
              await fetchData();
            } else {
              onSuccess(response, 'Agregado correctamente');
              await fetchData();
              setSelectedRowKeys([response.data.id]);
              onClickEdit(response.data);
            }
          }
        } else {
          const [key] = selectedRowKeys;
          const response = await API.put(`${baseURI}/${key}/`, formattedValues);
          if (response?.status === 200) {
            if (!_continue) {
              onSuccess(response, 'Actualizado correctamente');
              onCancel();
              await fetchData();
            } else {
              await fetchData();
              setSelectedRowKeys([response.data.id]);
            }
          }
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };
  const showMsg = makeMessager(setCurrentTabKey, fetchData);

  const onChangeTabKey = (key) => {
    const formBasic = basicKeys.includes(currentTabKey);
    const toBasic = basicKeys.includes(key);
    const match = complexForms.find((e) => e.key === currentTabKey);
    if (!match) {
      if (formBasic && toBasic) {
        setCurrentTabKey(key);
      } else if (formBasic) {
        showMsg(key);
      } else {
        setCurrentTabKey(key);
      }
    } else {
      match.formInstance.onFinishHandler(key, true);
    }
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    setMutable(isMutable(record));
    setDataSituacion(record?.situacion_no_deseada);
    setDataCobertura(record?.cobertura);
    setDataObjetivo(record?.objetivo);
    formTab.setFieldsValue({
      ...record,
      anio_inicial_y_final: [record.anio_inicial, record.anio_final],
    });
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickAdd = async () => {
    onCancel();
    const match = empleados.find((e) => e.id === usuario.empleado);
    if (match.puesto) {
      const matchPuesto = puestos.find((i) => i.id === match.puesto);
      setTimeout(() => {
        formTab.setFieldsValue({
          puesto: matchPuesto?.descripcion || '',
        });
      });
    }
    const usuarioSelected = usuarioSeleccionado?.find((e) => e.id === usuario.id);
    if (usuarioSelected.content_type) {
      const contentType = urs.find((e) => e.id === usuarioSelected.content_type);
      setCurrenteContentType(contentType);
      const allURS = {
        1: unidadesOperativas,
        2: unidadResponsable,
        3: centroDeCostos,
      };
      const matchUR = allURS?.[contentType.nivel].find((i) => i.id === usuarioSelected.object_id);
      setTimeout(() => {
        formTab.setFieldsValue({
          content_type_unidad_responsable: usuarioSelected.content_type,
          object_id_unidad_responsable: matchUR?.id,
        });
      });
    }
    setTimeout(() => {
      formTab.setFieldsValue({
        elaboro: `${usuario?.first_name || ' '} ${usuario?.last_name || ' '}${usuario?.second_last_name || ' '}`,
      });
    });
    setVisible(true);
  };

  const onClickDelete = () => {
    if (mutable) {
      setVisibleAlert(true);
    } else {
      message.info('No se puede eliminar el Diagnostico');
      onCancel();
    }
  };

  const columns = [
    {
      titleText: 'Fecha de Creación',
      dataIndex: 'fecha_de_creacion',
      key: 'fecha_de_creacion',
      width: 150,
      render: (val) => val?.format('YYYY/MM/DD'),
    },
    {
      titleText: 'Clave',
      dataIndex: 'clave',
      key: 'clave',
      width: 150,
    },
    {
      titleText: 'Nombre Diagnostico',
      dataIndex: 'nombre_de_diagnostico',
      key: 'nombre_diagnostico',
      width: 200,
    },
    {
      titleText: 'Elaboro',
      dataIndex: 'elaboro',
      key: 'elaboro',
      width: 200,
    },
    {
      titleText: 'Puesto',
      dataIndex: 'puesto',
      key: 'puesto',
      width: 200,
    },
    {
      titleText: 'Año',
      dataIndex: 'anio',
      key: 'anio',
      width: 150,
      render: (val) => val?.format('YYYY'),
    },
    {
      titleText: 'Año inicial',
      dataIndex: 'anio_inicial',
      key: 'fecha',
      width: 150,
      render: (val) => val?.format('YYYY'),
    },
    {
      titleText: 'Año Final',
      dataIndex: 'anio_final',
      key: 'fecha',
      width: 150,
      render: (val) => val?.format('YYYY'),
    },
    {
      titleText: 'Estatus',
      dataIndex: 'estados_globales',
      key: 'estados_globales',
      width: 150,
      render: (val) => (estadosGlobales.find((e) => e.id === val)?.descripcion),
    },
  ];

  const requiredRule = {
    required: true,
    message: 'El campo es requerido',
  };

  const rules = {
    required: [requiredRule],
  };

  const handleOnFinish = (vals, _continue = false) => {
    const match = complexForms.find((e) => e.key === currentTabKey);
    if (match) {
      match.formInstance.onFinishHandler(null, _continue);
    } else {
      onFinish(null, _continue);
    }
  };

  const selectUrs = {
    1: (<Select
      dataSource={unidadesOperativas}
      disabled
    />),
    2: (<Select
      dataSource={unidadResponsable}
      disabled
    />),
    3: (<Select
      dataSource={centroDeCostos}
      disabled
    />),
  };

  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible ? (
          <Table
            // allowImport
            baseURI={baseURI}
            cols={columns}
            data={data}
            permission={permission}
            rowSelection={rowSelection}
            handleOnRowClick={handleOnRowClick}
            loading={loading}
            controls={{
              onClickAdd,
              onClickEdit,
              onClickDelete,
            }}
            mobileColIndex={0}
          />
        ) : (
          <Card
            className="form-container"
            title={`${!selectedRowKeys.length ? 'Agregar' : 'Editar'} Diagnostico`}
            extra={(
              <FormSubmitControls
                onFinish={basicKeys
                  .concat(complexForms.map((e) => e.key))
                  .includes(currentTabKey) ? handleOnFinish : null}
                onCancel={onCancel}
                // allowAuthorize
                // allowCancel
                allowSaveAndContinue
                mutable={mutable}
                baseURI={baseURI}
                selectedRowKeys={selectedRowKeys}
                callback={(estados_globales) => {
                  const [key] = selectedRowKeys;
                  const normalized = data.map((e) => {
                    if (e.id === key) {
                      setMutable(false);
                      return {
                        ...e,
                        estados_globales,
                      };
                    }
                    return e;
                  });
                  setData(normalized);
                  formTab.setFieldsValue({ estados_globales });
                }}
              />
            )}
            bordered={false}
          >
            <Tabs
              type="card"
              activeKey={currentTabKey}
              onChange={mutable ? onChangeTabKey : setCurrentTabKey}
            >
              <TabPane // General tab------------------------------------------------------------
                key="generalTab"
                tab="General"
                forceRender
              >
                <Form
                  name="generalTab"
                  form={formTab}
                  layout="vertical"
                  onFinish={onFinish}
                  scrollToFirstError
                  initialValues={{ estados_globales: 1, un_solo_ejercicio: true }}
                >

                  <Row gutter={10}>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="clave"
                        rules={rules.required}
                        label="Clave"
                      >
                        <Input allowClear disabled={!mutable || !!selectedRowKeys.length} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="nombre_de_diagnostico"
                        rules={rules.required}
                        label="Nombre Diagnostico"
                      >
                        <Input allowClear disabled={!mutable} />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="elaboro"
                        rules={rules.required}
                        label="Elaboro"
                      >
                        <Input disabled />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        label="Puesto"
                        name="puesto"
                      >
                        <Input
                          disabled
                        />
                      </Form.Item>
                    </Col>
                    <Form.Item
                      name="content_type_unidad_responsable"
                      hidden
                    >
                      <Input />
                    </Form.Item>

                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        label="Unidad Responsable / Unidad Operativa / Centro de Costo"
                        name="object_id_unidad_responsable"
                      >
                        {selectUrs?.[currenteContentType?.nivel]}
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        label="Programa"
                        name="programa"
                      >
                        <Select
                          labelProp="nombre"
                          dataSource={programas}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Text>
                        Ejercicio de Aplicación
                      </Text>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="un_solo_ejercicio"
                        label="Un año"
                      >
                        <Select
                          trueFalse
                          disabled={!mutable || !!selectedRowKeys.length}
                        />
                      </Form.Item>
                    </Col>
                    {soloUnAnio ? (
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                        // rules={rules.required}
                          label="Año"
                          name="anio"
                          hasFeedback={mutable}
                        >
                          <DatePicker
                            format="YYYY"
                            disabled={!mutable}
                            placeholder=""
                            picker="year"
                          />
                        </Form.Item>
                      </Col>
                    ) : (
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                        // rules={rules.required}
                          label="Año Inicial y Final"
                          name="anio_inicial_y_final"
                          hasFeedback
                        >
                          <DatePicker.RangePicker
                            format="YYYY"
                            disabled={!mutable}
                            placeholder=""
                            picker="year"
                          />
                        </Form.Item>
                      </Col>
                    )}

                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        label="Estatus"
                        name="estados_globales"
                      >
                        <Select
                          dataSource={estadosGlobales}
                          disabled={!selectedRowKeys.length || !mutable}
                        />
                      </Form.Item>
                    </Col>

                    <Form.Item hidden>
                      <Button htmlType="submit" />
                    </Form.Item>
                  </Row>
                </Form>
              </TabPane>
              <TabPane
                key="situacionNoDeseada"
                tab="Situación No Deseada"
                disabled={!selectedRowKeys.length}
                forceRender={!selectedRowKeys.length}
              >
                <SituacionNoDeseada
                  mutable={mutable}
                  fatherId={selectedRowKeys}
                  setCurrentTabKey={setCurrentTabKey}
                  situacionNoDeseadaID={dataSituacion}
                  fatherURI={baseURI}
                  setFormSituacion={setFormSituacionNoDeseada}

                />
              </TabPane>
              <TabPane
                key="objetivo"
                tab="Objetivo"
                disabled={!selectedRowKeys.length}
                forceRender={!selectedRowKeys.length}
              >
                <Objetivo
                  mutable={mutable}
                  fatherId={selectedRowKeys}
                  setForm={setFormObjetivo}
                  setCurrentTabKey={setCurrentTabKey}
                  objetivoID={dataObjetivo}
                  fatherURI={baseURI}

                />
              </TabPane>
              <TabPane
                key="cobertura"
                tab="Cobertura"
                disabled={!selectedRowKeys.length}
                forceRender={!selectedRowKeys.length}
              >
                <Cobertura
                  mutable={mutable}
                  fatherId={selectedRowKeys}
                  setCurrentTabKey={setCurrentTabKey}
                  coberturaID={dataCobertura}
                  fatherURI={baseURI}
                  setFormCobertura={setFormCobertura}

                />
              </TabPane>
              <TabPane // Arbol de problema tab------------------------------------------------
                key="arbolDeProblemas"
                tab="Arbol de Problemas"
                disabled={!selectedRowKeys.length}
                forceRender={!selectedRowKeys.length}
              >
                <ArbolDeProblemas
                  fatherId={selectedRowKeys}

                />
              </TabPane>

            </Tabs>

          </Card>
        )}
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => setVisibleAlert(false)}
          visible={visibleAlert}
          content="El activo seleccionado"
          loading={loading}
        />
      </Spin>
    </Row>
  );
}

export default DiagnosticoArbolDeProblemaObjetivo;
