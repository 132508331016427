/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Col,
  Form,
  message,
  // Input,
  Row,
  Spin,
} from 'antd';
import FormSubmitControls from 'components/FormSubmitControls';
import API from 'utils/api';
import { onError, onSuccess } from 'utils/handlers';
import Select from 'components/Select';
import { useSelector } from 'react-redux';
import ModalDelete from 'components/ModalDelete';
import Table from 'components/Table';
import NumericInput from 'components/NumericInput';
import { toInteger } from 'lodash';

function PresupuestacionFID({
  selectedRowKey,
  areas,

}) {
  const baseURI = '/presupuestos/presupuestacion-programa-presupuestario-fid/';
  const [formGeneral] = Form.useForm();
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [contentTypes, setContentTypes] = useState([]);

  const igualPorMes = Form.useWatch('cantidad_igual_por_periodo', formGeneral) || false;

  const porServicio = Form.useWatch('por_productos_servicios', formGeneral) || false;

  const [cog, setCog] = useState([]);
  const [tiposDeGatos, setTiposDeGatos] = useState([]);
  const [fuentesFinanciamiento, setFuentesFinanciamiento] = useState([]);

  const [tablaCog, setTablaCog] = useState([]);
  const [tablaTipos, setTablaTipos] = useState([]);
  const [tablaFuentes, setTablaFuentes] = useState([]);

  const [ur, setUr] = useState([]);
  const [uo, setUo] = useState([]);
  const [cc, setCC] = useState([]);

  const formInstances = [
    formGeneral,
  ];

  const fetchData = async () => {
    try {
      setLoading(true);
      if (selectedRowKey[0] !== 0) {
        const res = await API.get(baseURI, {
          params: {
            programa_fid: selectedRowKey[0],
          },
        });
        if (res?.status === 200) {
          setData(res.data);
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const getFromUr = async (arr = [], uri = '', setter = null) => {
    if (arr?.length !== 0) {
      const promises = arr.map((e) => API.get(`${uri}${e}`));
      const responses = await Promise.all(promises);
      const datas = responses.map((e) => e.data);
      setter(datas);
    }
  };

  const getURForTreeSelect = (_data) => {
    if (_data.area_content_type) {
      const { nivel } = contentTypes.find((ct) => ct.id === _data.area_content_type);
      const { area_content_id } = _data;
      return { nivel, area_content_id };
    }
    return null;
  };

  const setSelects = async (obj = {}) => {
    if (obj?.cog_interno?.length) {
      await getFromUr(obj?.cog_interno, '/contabilidad/clasificaciones-objeto-gasto-interno/', setCog);
    } else {
      setCog([]);
      message.warn('El área responsable seleccionado no cuenta con COG seleccionados.');
    }
    if (obj?.tipo_de_gasto?.length) {
      await getFromUr(obj?.tipo_de_gasto, '/catalogos/clasificaciones-tipo-de-gasto/', setTiposDeGatos);
    } else {
      setTiposDeGatos([]);
      message.warn('El área responsable seleccionado no cuenta con Tipos de Gastos seleccionados.');
    }
    if (obj?.fuentes_de_financiamiento_interna?.length) {
      await getFromUr(obj?.fuentes_de_financiamiento_interna, '/contabilidad/clasificaciones-de-fuentes-de-financiamiento-interna/', setFuentesFinanciamiento);
    } else {
      setFuentesFinanciamiento([]);
      message.warn('El área responsable seleccionado no cuenta con Fuentes de Financiamiento seleccionadas.');
    }
  };
  const fetchSelectsData = async (val) => {
    try {
      setLoading(true);
      const resArea = areas.find((e) => e.id === val);
      const { nivel, area_content_id } = getURForTreeSelect(resArea);
      // ----------------------------
      if (nivel === 1) {
        const resUO = uo.find((e) => e.id === area_content_id);
        setSelects(resUO);
      } else if (nivel === 2) {
        const resUR = ur.find((e) => e.id === area_content_id);
        setSelects(resUR);
      } else if (nivel === 3) {
        const resCC = cc.find((e) => e.id === area_content_id);
        setSelects(resCC);
      }

      setLoading(false);
    } catch (error) {
      onError(error, setLoading);
    }
  };

  const onCancel = () => {
    setVisible(false);
    formGeneral.resetFields();
    setSelectedRowKeys([]);
  };

  useEffect(() => {
    let mounted = true;
    const fetchAll = async () => {
      try {
        setLoading(true);
        if (mounted) {
          // Esta parte es para llenar la tabla en el fetchData
          const resCog = await API.get('/contabilidad/clasificaciones-objeto-gasto-interno/');
          setTablaCog(resCog.data);
          const resTipoDeGasto = await API.get('/catalogos/clasificaciones-tipo-de-gasto/');
          setTablaTipos(resTipoDeGasto.data);
          const resFuentes = await API.get('/contabilidad/clasificaciones-de-fuentes-de-financiamiento-interna/');
          setTablaFuentes(resFuentes.data);

          const resContentTypes = await API.get('catalogos/content-types-ur');
          setContentTypes(resContentTypes.data);

          const resUnidadesR = await API.get('/estructura-organizacional/unidades-responsables/');
          setUr(resUnidadesR.data || []);

          const resUO = await API.get('/estructura-organizacional/unidades-operativas/');
          setUo(resUO.data || []);

          const resCentroCostos = await API.get('/estructura-organizacional/centros-de-costos/');
          setCC(resCentroCostos.data || []);
          await fetchData();
          setLoading(false);
        }
      } catch (err) {
        onError(err, setLoading);
      }
    };
    fetchAll();
    return () => { mounted = false; };
    // eslint-disable-next-line
  }, [selectedRowKey[0]]);

  const onFinish = async () => {
    try {
      await formGeneral.validateFields();
      const values = {
        ...formGeneral.getFieldsValue(),
        programa_fid: selectedRowKey[0],
      };
      if (selectedRowKeys.length === 0) {
        const res = await API.post(baseURI, values);
        if (res?.status === 201) {
          onCancel();
          await fetchData();
        }
      } else {
        const [key] = selectedRowKeys;
        const res = await API.patch(`${baseURI}${key}/`, values);
        if (res?.status === 200) {
          onCancel();
          await fetchData();
        }
      }

      setLoading(false);
    } catch (err) {
      onError(err, formInstances, setLoading);
    }
  };

  const columns = [
    {
      titleText: 'COG',
      dataIndex: 'cog',
      key: 'cog',
      render: (val) => tablaCog?.find((e) => e.id === val)?.concepto,
      width: 300,
    },
    {
      titleText: 'Fuente De Financiamiento',
      dataIndex: 'fuente_de_financiamiento',
      key: 'fuente_de_financiamiento',
      render: (val) => tablaFuentes?.find((e) => e.id === val)?.nombre,
      width: 300,
    },
    {
      titleText: 'Tipo de Gasto',
      dataIndex: 'tipo_de_gasto',
      key: 'tipo_de_gasto',
      render: (val) => tablaTipos?.find((e) => e.id === val)?.nombre,
      width: 300,
    },
    {
      titleText: 'Presupuesto Aprobado',
      dataIndex: 'presupuesto_aprobado',
      key: 'preupuesto_aprobado',
      width: 200,
    },
    {
      titleText: 'Presupuesto Asignado',
      dataIndex: 'presupuesto_asignado',
      key: 'presupuesto_asignado',
      width: 200,
    },
    {
      titleText: 'Presupuesto Por Asignar',
      dataIndex: 'presupuesto_por_asignar',
      key: 'presupuesto_por_asignado',
      width: 200,
    },
    {
      titleText: 'Estado',
      dataIndex: 'estados_globales',
      key: 'estados_globales',
      width: 160,
      render: (id) => estadosGlobales.find((eg) => eg.id === id)?.descripcion,
    },
  ];

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    setTimeout(() => {
      formGeneral.setFieldsValue(record);
    });
  };

  const onClickAdd = async () => {
    onCancel();

    setVisible(true);
  };

  const onClickEdit = async () => {
    try {
      setLoading(true);
      // const [key] = selectedRowKeys;
      // const match = data.find((e) => e.id === key);

      // Si el match tiene respnsable del indicador, entonces rellenara los campos del modal
      setLoading(false);
    } catch (error) {
      onError(error, setLoading);
    }
    setVisible(true);
  };

  const onChangeMeses = () => {
    const values = formGeneral.getFieldsValue();
    if (values?.presupuesto_aprobado) {
      if (values.cantidad_igual_por_periodo === false) {
        const presupuestoAprobado = toInteger(values.presupuesto_aprobado);
        const presupuestoAsignado = (toInteger(values.importe_de_enero)
                        + toInteger(values.importe_de_febrero)
                        + toInteger(values.importe_de_marzo)
                        + toInteger(values.importe_de_abril)
                        + toInteger(values.importe_de_mayo)
                        + toInteger(values.importe_de_junio)
                        + toInteger(values.importe_de_julio)
                        + toInteger(values.importe_de_agosto)
                        + toInteger(values.importe_de_septiembre)
                        + toInteger(values.importe_de_octubre)
                        + toInteger(values.importe_de_noviembre)
                        + toInteger(values.importe_de_diciembre)
        );
        const presupuestoPorAsignar = (toInteger(values.presupuesto_aprobado
                          - presupuestoAsignado));
        if (presupuestoAprobado < presupuestoAsignado) {
          message.warn('El presupuesto de los meses excede el presupuesto aprobado');
        }

        setTimeout(
          formGeneral.setFieldsValue({
            presupuesto_asignado: presupuestoAsignado,
            presupuesto_por_asignar: presupuestoPorAsignar,
          }),
        );
      } else {
        const cantidadPorMes = toInteger(values.presupuesto_aprobado) / 12;
        const cero = 0;
        setTimeout(
          formGeneral.setFieldsValue({
            importe_de_enero: cantidadPorMes.toFixed(2),
            importe_de_febrero: cantidadPorMes.toFixed(2),
            importe_de_marzo: cantidadPorMes.toFixed(2),
            importe_de_abril: cantidadPorMes.toFixed(2),
            importe_de_mayo: cantidadPorMes.toFixed(2),
            importe_de_junio: cantidadPorMes.toFixed(2),
            importe_de_julio: cantidadPorMes.toFixed(2),
            importe_de_agosto: cantidadPorMes.toFixed(2),
            importe_de_septiembre: cantidadPorMes.toFixed(2),
            importe_de_octubre: cantidadPorMes.toFixed(2),
            importe_de_noviembre: cantidadPorMes.toFixed(2),
            importe_de_diciembre: cantidadPorMes.toFixed(2),
            presupuesto_asignado: values.presupuesto_aprobado,
            presupuesto_por_asignar: cero,
          }),
        );
      }
    }
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const deleteItem = async () => {
    try {
      const [key] = selectedRowKeys;
      if (selectedRowKey) {
        if (selectedRowKeys.length) {
          const response = await API.delete(`${baseURI}${key}/`);
          if (response?.status === 204) {
            onSuccess(response, 'Eliminado correctamente');
            onCancel();
            await fetchData();
            setVisibleAlert(false);
          }
        }
      }
    } catch (err) {
      onError(err, setLoading);
    }
  };

  // const requiredRule = {
  //   message: 'Este campo es requerido',
  // };

  // const rules = {
  //   required: [requiredRule],
  // };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  return (
    <Row align="center" justify="center" className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible ? (
          <Table
            cols={columns}
            data={data}
            rowSelection={rowSelection}
            handleOnRowClick={handleOnRowClick}
            controls={{
              onClickAdd,
              onClickEdit,
              onClickDelete,
            }}
            mobileColIndex={0}
          />
        ) : (
          <Card
            bordered={false}
            className="form-container"
            title={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Presupuesto`}
            extra={(
              <FormSubmitControls
                onFinish={onFinish}
                onCancel={onCancel}
                loading={loading}
              />
            )}
          >

            <Form
              layout="vertical"
              form={formGeneral}
              onFinish={onFinish}
              allowAuthorize
              scrollToFirstError
              initialValues={{
                estados_globales: 1,
                importe_de_enero: 0,
                importe_de_febrero: 0,
                importe_de_marzo: 0,
                importe_de_abril: 0,
                importe_de_mayo: 0,
                importe_de_junio: 0,
                importe_de_julio: 0,
                importe_de_agosto: 0,
                importe_de_septiembre: 0,
                importe_de_octubre: 0,
                importe_de_noviembre: 0,
                importe_de_diciembre: 0,
                presupuesto_aprobado: 0,
                cantidad_igual_por_periodo: false,
              }}
            >

              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  name="area"
                  label="Area FID"
                >
                  <Select
                    dataSource={areas}
                    labelProp="tipo"
                    onChange={(val) => {
                      fetchSelectsData(val);
                      formGeneral.resetFields(['fuente_de_financiamiento', 'tipo_de_gasto', 'cog']);
                    }}
                  />
                </Form.Item>
              </Col>
              <Row gutter={10}>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="cog"
                    label="Clasificador por Objeto del Gasto"
                  >
                    <Select
                      dataSource={cog}
                      labelProp="concepto"
                      keyProp="clave"
                      keyLabelRender
                      required
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="fuente_de_financiamiento"
                    label="Fuente de Financiamiento"
                  >
                    <Select
                      dataSource={fuentesFinanciamiento}
                      labelProp="nombre"
                      keyProp="clave"
                      keyLabelRender
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="tipo_de_gasto"
                    label="Tipo de Gasto"
                  >
                    <Select
                      dataSource={tiposDeGatos}
                      labelProp="nombre"
                      keyProp="clave"
                      keyLabelRender
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <NumericInput
                    label="Presupuesto Aprobado"
                    name="presupuesto_aprobado"
                    decimal
                    prefix="$"
                  />
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <NumericInput
                    label="Presupuesto Asignado"
                    name="presupuesto_asignado"
                    decimal
                    prefix="$"
                    disabled
                  />
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <NumericInput
                    label="Presupuesto Por Asignar"
                    name="presupuesto_por_asignar"
                    decimal
                    allowNegative
                    prefix="$"
                    disabled
                  />
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="cantidad_igual_por_periodo"
                    label="Igual Por Periodo"
                  >
                    <Select
                      trueFalse
                      // disabled={igualPorMes}
                      // disabled
                      onChange={(val) => {
                        onChangeMeses();
                        const values = formGeneral.getFieldsValue();
                        if (val === false) {
                          setTimeout(() => {
                            formGeneral.setFieldsValue({
                              importe_de_enero: 0,
                              importe_de_febrero: 0,
                              importe_de_marzo: 0,
                              importe_de_abril: 0,
                              importe_de_mayo: 0,
                              importe_de_junio: 0,
                              importe_de_julio: 0,
                              importe_de_agosto: 0,
                              importe_de_septiembre: 0,
                              importe_de_octubre: 0,
                              importe_de_noviembre: 0,
                              importe_de_diciembre: 0,
                              presupuesto_por_asignar: values?.presupuesto_aprobado || 0,
                              presupuesto_asignado: 0,
                            });
                          });
                        } else if (
                          values?.presupuesto_aprobado === 0 || !values?.presupuesto_aprobado) {
                          message.warning('No se asignó ningun presupuesto');
                        }
                      }}

                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="por_productos_servicios"
                    label="Por Producto o servicio"
                  >
                    <Select
                      trueFalse
                      // disabled={igualPorMes}
                      disabled

                    />
                  </Form.Item>
                </Col>
                {!porServicio && (
                <Row gutter={10}>
                  <Col xs={24} sm={24} md={8}>
                    <NumericInput
                      label="Enero"
                      name="importe_de_enero"
                      decimal
                      allowZero
                      prefix="$"
                      disabled={igualPorMes}
                      onChange={() => {
                        onChangeMeses();
                      }}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <NumericInput
                      label="Febrero"
                      name="importe_de_febrero"
                      decimal
                      allowZero
                      prefix="$"
                      disabled={igualPorMes}
                      onChange={() => {
                        onChangeMeses();
                      }}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <NumericInput
                      label="Marzo"
                      name="importe_de_marzo"
                      decimal
                      allowZero
                      prefix="$"
                      disabled={igualPorMes}
                      onChange={() => {
                        onChangeMeses();
                      }}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <NumericInput
                      label="Abril"
                      name="importe_de_abril"
                      decimal
                      allowZero
                      prefix="$"
                      disabled={igualPorMes}
                      onChange={() => {
                        onChangeMeses();
                      }}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <NumericInput
                      label="Mayo"
                      name="importe_de_mayo"
                      decimal
                      allowZero
                      prefix="$"
                      disabled={igualPorMes}
                      onChange={() => {
                        onChangeMeses();
                      }}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <NumericInput
                      label="Junio"
                      name="importe_de_junio"
                      decimal
                      allowZero
                      prefix="$"
                      disabled={igualPorMes}
                      onChange={() => {
                        onChangeMeses();
                      }}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <NumericInput
                      label="Julio"
                      name="importe_de_julio"
                      decimal
                      allowZero
                      prefix="$"
                      disabled={igualPorMes}
                      onChange={() => {
                        onChangeMeses();
                      }}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <NumericInput
                      label="Agosto"
                      name="importe_de_agosto"
                      decimal
                      allowZero
                      prefix="$"
                      disabled={igualPorMes}
                      onChange={() => {
                        onChangeMeses();
                      }}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <NumericInput
                      label="Septiembre"
                      name="importe_de_septiembre"
                      decimal
                      allowZero
                      prefix="$"
                      disabled={igualPorMes}
                      onChange={() => {
                        onChangeMeses();
                      }}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <NumericInput
                      label="Octubre"
                      name="importe_de_octubre"
                      decimal
                      allowZero
                      prefix="$"
                      disabled={igualPorMes}
                      onChange={() => {
                        onChangeMeses();
                      }}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <NumericInput
                      label="Noviembre"
                      name="importe_de_noviembre"
                      decimal
                      allowZero
                      prefix="$"
                      disabled={igualPorMes}
                      onChange={() => {
                        onChangeMeses();
                      }}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <NumericInput
                      label="Diciembre"
                      name="importe_de_diciembre"
                      decimal
                      allowZero
                      prefix="$"
                      disabled={igualPorMes}
                      onChange={() => {
                        onChangeMeses();
                      }}
                    />
                  </Col>
                </Row>
                )}
              </Row>
              <Form.Item hidden>
                <Button htmlType="submit" />
              </Form.Item>
            </Form>
          </Card>
        )}
      </Spin>
      <ModalDelete
        onDelete={deleteItem}
        onCancel={() => {
          setVisibleAlert(false);
          setSelectedRowKeys([]);
        }}
        visible={visibleAlert}
        content="Eliminar la Matriz seleccionada"
      />
    </Row>
  );
}

PresupuestacionFID.propTypes = {
  selectedRowKey: PropTypes.arrayOf(PropTypes.number),
  areas: PropTypes.arrayOf(PropTypes.object),
};

PresupuestacionFID.defaultProps = {
  selectedRowKey: [],
  areas: [],
};

export default PresupuestacionFID;
