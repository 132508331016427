import React from 'react';
import SingleForm from 'components/SingleForm';
import { getTiposDeOperaciones } from 'api/adquisiciones';

export const permissionTiposDeOperacion = {
  permissionModel: 'tipodeoperacion',
};

// eslint-disable-next-line react/prop-types
function TiposDeOperacion({ permission }) {
  return (
    <SingleForm
      title="Tipo de operación"
      withoutState
      readOnly
      permission={permission}
      get={getTiposDeOperaciones}
    />
  );
}
export default TiposDeOperacion;
