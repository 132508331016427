/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Form,
  Input,
  Button,
  Spin,
  Row,
  Col,
  Card,
  DatePicker,
} from 'antd';

import API from 'utils/api';
import Select from 'components/Select';
import { onError, onSuccess } from 'utils/handlers';
import { formatReceived } from 'utils/formatValues';
import NumericInput from 'components/NumericInput';
import Table from 'components/Table';
import FormSubmitControls from 'components/FormSubmitControls';
import ModalDelete from 'components/ModalDelete';
import FormItemLabel from 'components/FormItemLabel';

const baseURI = '/estructura-organizacional/cuenta-de-la-entidad/';

function Cuentas() {
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await API.get(baseURI);
      setData(formatReceived(res.data));
      // form.setFieldsValue();
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onCancel = () => {
    setVisible(false);
    setSelectedRowKeys([]);
    form.resetFields();
  };

  useEffect(() => {
    fetchData();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    form.setFieldsValue({
      ...formatReceived(record),
    });
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`${baseURI}${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          setVisibleAlert(false);
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const columns = [
    {
      titleText: 'UUID',
      dataIndex: 'uuid',
      key: 'uuid',
      width: 350,
    },
    {
      titleText: 'Número de Usuarios Totales',
      dataIndex: 'numero_de_usuarios_totales',
      key: 'numero_de_usuarios_totales',
      width: 250,
    },
    {
      titleText: 'Número de Usuarios Disponibles',
      dataIndex: 'numero_de_usuarios_disponibles',
      key: 'numero_de_usuarios_disponibles',
      width: 250,
    },
    {
      titleText: 'Cantidad de Movimientos',
      dataIndex: 'cantidad_movimientos',
      key: 'cantidad_movimientos',
      width: 250,
    },
    {
      titleText: 'Último Pago',
      dataIndex: 'ultimo_pago',
      key: 'ultimo_pago',
      width: 150,
      render: (e) => (e ? e?.format('DD/MM/YYYY') : '-'),
    },
    {
      titleText: 'Vencimiento',
      dataIndex: 'vencimiento',
      key: 'vencimiento',
      width: 150,
      render: (e) => (e ? e?.format('DD/MM/YYYY') : '-'),
    },
    {
      titleText: 'Timbres Usados',
      dataIndex: 'timbres_usados',
      key: 'timbres_usados',
      width: 200,
    },
    {
      titleText: 'Timbres Restantes',
      dataIndex: 'timbres_restantes',
      key: 'timbres_restantes',
      width: 200,
    },
  ];

  return (
    <Spin tip="Cargando..." spinning={loading}>
      {!visible ? (
        <Table
          cols={columns}
          data={data}
          rowSelection={rowSelection}
          rowKey="id"
          mobileColIndex={0}
          handleOnRowClick={handleOnRowClick}
          controls={{
            onClickExpand: () => setVisible(true),
          }}
        />
      ) : (
        <Card
          className="form-container"
          title={(
            <FormSubmitControls
              label="Vista Completa de Cuenta"
              onCancel={onCancel}
            />
          )}
          bordered={false}
        >
          <Form
            layout="vertical"
            form={form}
            scrollToFirstError
            className="without-feedback"
          >
            <Row gutter={10}>
              <Col xs={24} sm={24} md={16} lg={8}>
                <Form.Item
                  label="UUID"
                  name="uuid"
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <NumericInput
                  name="numero_de_usuarios_totales"
                  label={(
                    <FormItemLabel
                      longTitle="Número de Usuarios Totales"
                      shortTitle="Usuarios Totales"
                    />
                  )}
                  disabled
                />
              </Col>
              <Col xs={24} sm={24} md={8}>
                <NumericInput
                  name="numero_de_usarios_disponibles"
                  label={(
                    <FormItemLabel
                      longTitle="Número de Usuarios Disponibles"
                      shortTitle="Usuarios Disponibles"
                    />
                  )}
                  disabled
                />
              </Col>
              <Col xs={24} sm={24} md={8}>
                <NumericInput
                  name="cantidad_movimientos"
                  label="Cantidad de Movimientos"
                  disabled
                />
              </Col>
              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  name="ultimo_pago"
                  label="Último Pago"
                >
                  <DatePicker
                    placeholder=""
                    format="DD/MM/YYYY"
                    disabled
                    suffixIcon={null}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  name="vencimiento"
                  label="Vencimiento"
                >
                  <DatePicker
                    placeholder=""
                    format="DD/MM/YYYY"
                    disabled
                    suffixIcon={null}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <NumericInput
                  name="timbres_usados"
                  label="Timbres Usados"
                  disabled
                />
              </Col>
              <Col xs={24} sm={24} md={8}>
                <NumericInput
                  name="timbres_restantes"
                  label="Timbres Restantes"
                  disabled
                />
              </Col>
              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  label="Estado"
                  name="estados_globales"
                >
                  <Select dataSource={estadosGlobales} disabled />
                </Form.Item>
              </Col>
              <Form.Item hidden>
                <Button htmlType="submit" />
              </Form.Item>
            </Row>
          </Form>
        </Card>
      )}
      <ModalDelete
        onDelete={deleteItem}
        onCancel={() => setVisibleAlert(false)}
        visible={visibleAlert}
        content="La cuenta"
        loading={loading}
      />
    </Spin>
  );
}

export default Cuentas;
