import React from 'react';
import PropTypes from 'prop-types';
import {
  Pagination as AntPagination,
} from 'antd';

function Pagination({
  callback,
  page,
  setPage,
  count,
  q,
}) {
  return (
    <>
      <br />
      <AntPagination
        onChange={(pag) => {
          setPage(pag);
          callback(pag, q);
        }}
        total={count}
        current={page}
        showSizeChanger={false}
        showQuickJumper={false}
      />
      <br />
    </>
  );
}

Pagination.propTypes = {
  count: PropTypes.number,
  callback: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  q: PropTypes.shape({}).isRequired,
};

Pagination.defaultProps = {
  count: 0,
};

export default Pagination;
