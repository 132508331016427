import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Form,
  Button,
  Row,
  Col,
  Spin,
  Modal,
  Input,
  message,
} from 'antd';
import Table from 'components/Table';
import API from 'utils/api';
import FormSubmitControls from 'components/FormSubmitControls';
import ModalDelete from 'components/ModalDelete';
import Select from 'components/Select';
import { onError, onSuccess } from 'utils/handlers';
import { InputSearchPro } from 'components/InputSearch';
import NumericInput from 'components/NumericInput';

export const model = 'partida';
export const catalogsModels = ['producto'];

function Partidas({
  requisicionId,
  disabled,
  permission,
}) {
  const baseURI = `adquisiciones/requisiciones/${requisicionId}/partidas/`;
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);

  const [productoFound, setProductoFound] = useState([]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await API.get(baseURI);
      setData(res.data);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchData();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, [requisicionId]);

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    setProductoFound([]);
    setSelectedRowKeys([]);
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      if (!selectedRowKeys.length) {
        const response = await API.post(baseURI, values);
        if (response?.status === 201) {
          onSuccess(response, 'Agregado correctamente');
          onCancel();
        }
      } else {
        const [key] = selectedRowKeys;
        const response = await API.put(`${baseURI}${key}/`, values);
        if (response?.status === 200) {
          onSuccess(response, 'Actualizado correctamente');
          onCancel();
        }
      }
      await fetchData();
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const deleteItem = async () => {
    try {
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`${baseURI}${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          setVisibleAlert(false);
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const onClickEdit = async () => {
    try {
      setLoading(true);
      const [key] = selectedRowKeys;
      const { data: resData } = await API.get(`${baseURI}${key}/`);
      const resProducto = await API.get(`configuraciones/productos/${resData?.producto}`);
      setProductoFound([resProducto.data]);

      setTimeout(() => form.setFieldsValue({
        ...resData,
        producto: resProducto.data.id,
      }));
      setVisible(true);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const columns = [
    {
      titleText: 'Partida Presupuestal',
      dataIndex: 'partida_presupuestal',
      key: 'partida_presupuestal',
      width: 350,
    },
    {
      titleText: 'Cantidad',
      dataIndex: 'cantidad_de_producto',
      key: 'cantidad_de_producto',
      width: 200,
    },
    {
      titleText: 'Total',
      dataIndex: 'total',
      key: 'total',
      width: 200,
    },
    {
      titleText: 'Techo Presupuestal',
      dataIndex: 'techo_presupuestal',
      key: 'techo_presupuestal',
      width: 200,
    },
    {
      titleText: 'Estado',
      dataIndex: 'estados_globales',
      key: 'estados_globales',
      width: 200,
      render: (e) => estadosGlobales.find((i) => i.id === e)?.descripcion,
    },
  ];

  const requiredRule = {
    required: true,
    message: 'El campo es requerido',
  };

  const rules = {
    required: [requiredRule],
  };

  const busquedaProducto = async () => {
    try {
      setLoading(true);
      await form.validateFields(['clave']);
      const { clave } = form.getFieldsValue();
      const response = await API.get('configuraciones/productos/', {
        params: {
          clave,
        },
      });

      if (response?.status === 200 && response?.data?.length) {
        form.setFieldsValue({
          producto: response.data[0].id,
        });
        setProductoFound([response.data[0]]);
      } else if (response?.status === 204) {
        message.info('No se encontraron coincidencias');
        setProductoFound([]);
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const resetAndValidateProductos = () => {
    setProductoFound([]);
    form.resetFields(['clave']);
    form.validateFields(['clave']);
  };

  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        <Table
          cols={columns}
          data={data}
          rowSelection={rowSelection}
          handleOnRowClick={handleOnRowClick}
          controls={{
            onClickAdd,
            onClickEdit,
            onClickDelete,
          }}
          mobileColIndex={0}
          disabled={disabled}
          permission={permission}
        />
        <Modal
          visible={visible}
          title={(
            <FormSubmitControls
              label={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} partida`}
              onFinish={onFinish}
              onCancel={onCancel}
              loading={loading}
              permission={permission}
            />
          )}
          closable={false}
          maskClosable={!loading}
          keyboard={!loading}
          onCancel={onCancel}
          footer={null}
          forceRender
        >
          <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
            scrollToFirstError
            initialValues={{ estados_globales: 1 }}
          >
            <Row>
              <Col span={24}>
                <Form.Item
                  name="partida_presupuestal"
                  rules={rules.required}
                  label="Partida Presupuestal"
                  hasFeedback
                >
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col span={24}>
                <NumericInput
                  name="cantidad_de_producto"
                  label="Cantidad del Producto"
                  hasFeedback
                  required
                />
              </Col>
              <Col span={24}>
                <NumericInput
                  name="total"
                  label="Total"
                  hasFeedback
                  required
                  decimal
                />
              </Col>
              <Col span={24}>
                <NumericInput
                  name="techo_presupuestal"
                  label="Techo Presupuestal"
                  hasFeedback
                  required
                  decimal
                />
              </Col>
              <InputSearchPro
                label="Producto"
                tooltip="Búsqueda por clave de producto"
                name="clave"
                rules={rules.required}
                onPressEnter={() => busquedaProducto()}
                breakPoints={{
                  md: 24,
                }}
                inputProps={{
                  onPressEnter: () => busquedaProducto(),
                  onChange: ({ target: { value } }) => {
                    if (!value) {
                      resetAndValidateProductos();
                    }
                  },
                }}
                resultProps={{
                  name: 'producto',
                  label: 'Producto',
                  dataSource: productoFound,
                  labelProp: 'descripcion',
                  keyLabelRender: true,
                  keyProp: 'clave',
                  onClear: resetAndValidateProductos,
                  breakPoints: {
                    md: 24,
                  },
                }}
              />
              <Col span={24}>
                <Form.Item
                  name="estados_globales"
                  rules={rules.required}
                  label="Estado"
                  hasFeedback
                >
                  <Select dataSource={estadosGlobales} disabled={!selectedRowKeys.length} />
                </Form.Item>
              </Col>
              <Form.Item hidden>
                <Button htmlType="submit" />
              </Form.Item>
            </Row>
          </Form>
        </Modal>
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => setVisibleAlert(false)}
          visible={visibleAlert}
          content="la partida seleccionada"
          loading={loading}
        />
      </Spin>
    </Row>
  );
}

Partidas.propTypes = {
  requisicionId: PropTypes.number,
  disabled: PropTypes.bool.isRequired,
  permission: PropTypes.shape({}).isRequired,
};

Partidas.defaultProps = {
  requisicionId: 0,
};

export default Partidas;
