import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Form,
  Button,
  Row,
  Col,
  Spin,
  Card,
  Typography,
  Input,
  Modal,
  message,
} from 'antd';
import {
  CloseCircleOutlined,
  SyncOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';
import { onError, onSuccess } from 'utils/handlers';
import { useHistory } from 'react-router-dom';
import Table from 'components/Table';
import FormSubmitControls from 'components/FormSubmitControls';
import API from 'utils/api';
import { createDecimalRegExp } from 'utils/patterns';
import fetchSome from 'utils/fetchSome';
import {
  SET_MESES,
  SET_URS,
  SET_TIPOS_DE_PRESUPUESTOS_DE_EGRESO,
} from 'store/reducers/catalogs/index';
import ModalDelete from 'components/ModalDelete';
import NumericInput from 'components/NumericInput';
import Select from 'components/Select';
import currencyFormater from 'utils/currencyFormatter';
import { isMutable } from 'utils/estadosGlobales';
import TableMontoDetallado from './TableMontoDetallado';

export const permissionPresupuestoAnualIngreso = {
  permissionModel: 'presupuestoanualdeingreso',
};

export const permissionPresupuestoAnualEgreso = {
  permissionModel: 'presupuestoanualdeegreso',
};

const { Text } = Typography;
const levelSorter = (a, b) => {
  if (a.nivel < b.nivel) {
    return -1;
  }
  if (a.nivel > b.nivel) {
    return 1;
  }
  return 0;
};

const montosEn0 = {
  1: '0.00',
  2: '0.00',
  3: '0.00',
  4: '0.00',
  5: '0.00',
  6: '0.00',
  7: '0.00',
  8: '0.00',
  9: '0.00',
  10: '0.00',
  11: '0.00',
  12: '0.00',
};

// eslint-disable-next-line react/prop-types
function PresupuestoAnual({ permission }) {
  const egresosURI = '/configuraciones/presupuestos-anuales-de-egreso/';
  const ingresosURI = '/configuraciones/presupuestos-anules-de-ingreso/';
  const egresosPath = '/presupuestos/presupuesto-de-egresos/presupuesto-anual-de-egresos';
  const currentPath = window.location.pathname;
  const isEgresos = currentPath === egresosPath;

  const baseURI = isEgresos ? egresosURI : ingresosURI;
  const meses = useSelector(({ catalogs }) => catalogs.meses);
  const tiposDePresupuesto = useSelector(({ catalogs }) => catalogs
    .tiposDePresupuestosDeEgreso);
  const urs = useSelector(({ catalogs }) => catalogs.urs).sort(levelSorter);
  const history = useHistory();
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  const periodoFiscalSelected = useSelector(({ auth }) => auth.periodoFiscalSelected);
  const [decimal] = createDecimalRegExp(2);
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [visibleConfirmSync, setVisibleConfirmSync] = useState(false);
  const [visibleConfirmAuthorize, setVisibleConfirmAuthorize] = useState(false);
  const [data, setData] = useState([]);
  const [showMeses, setShowMeses] = useState(true);
  const [disabled, setDisabled] = useState(true);
  const [tipoPresupuesto, setTipoPresupuesto] = useState(0);
  const [detalladoMensual, setDetalladoMensual] = useState([]);

  const [asignationMensual, setAsignationMensual] = useState([]);
  const [mutable, setMutable] = useState(true);

  const [clases, setClases] = useState([]);
  const [showAsignaciones, setShowAsignaciones] = useState(false);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await API.get(baseURI, {
        params: {
          periodo_fiscal: periodoFiscalSelected.id,
        },
      });
      setData(response.data.map((e) => { delete e.periodo_fiscal; return e; }) || []);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    const fetchAll = async () => {
      try {
        setLoading(true);
        if (!tiposDePresupuesto?.length) {
          await fetchSome(
            '/configuraciones/tipo-de-presupuestos-de-egresos/',
            SET_TIPOS_DE_PRESUPUESTOS_DE_EGRESO,
          );
        }
        if (!meses?.length) {
          await fetchSome('/catalogos/meses/', SET_MESES);
        }
        if (!urs?.length) {
          await fetchSome('/catalogos/content-types-ur/', SET_URS);
        }
        if (!isEgresos) {
          const resClases = await API.get('configuraciones/clases/');
          setClases(resClases.data);
        }
        await fetchData();
        setLoading(false);
      } catch (err) {
        onError(err, setLoading);
      }
    };
    fetchAll();
    // eslint-disable-next-line
  }, [periodoFiscalSelected]);

  const onCancel = () => {
    setVisible(false);
    setShowMeses(true);
    setDisabled(true);
    form.resetFields();
    setSelectedRowKeys([]);
    setTipoPresupuesto(0);
  };

  const postAsignacionMensualIgual = async ({ id, values }) => {
    try {
      const URI = `${baseURI}${id}/asignaciones-mensuales/`;
      const mappedPromises = asignationMensual
        .map((e, idx) => (e.mes === values[idx].catalogo_de_mes
          ? ({ ...e, importe: values[idx].importe }) : e)).sort((a, b) => a.importe - b.importe);
      const promises = mappedPromises.map((e, idx) => API.put(`${URI}${e.id}`, mappedPromises[idx]));
      const responses = promises.length ? await Promise.all(promises) : [];
      const datos = responses.map((res) => res.data);
      const asMen = Object
        .fromEntries(datos.map((e) => [e.mes, e.importe]));
      form.setFieldsValue(asMen);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onFinish = async (_, _continue = false) => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      values.saldo_por_asignar = values.saldo_por_asignar || 0;
      const asignacion_mensual = [];
      if (values.tipo_de_presupuesto !== 3) {
        if (!values.monto_mensual_igual) {
          values.monto_total = parseFloat(values.monto_total);
        }
        meses.forEach((mes) => {
          const catalogo_de_mes = mes.id;
          const importe = parseFloat(values[mes.id]) || 0;
          const val = {
            catalogo_de_mes,
            importe,
          };
          asignacion_mensual.push(val);
          delete values[mes.id];
        });
      }
      if (isEgresos) {
        values.importe = parseFloat(values.monto_total);
      }
      const [key] = selectedRowKeys;
      const response = await API.put(`${baseURI}${key}/`, values);
      if (response?.status === 200) {
        if ([2, 1].includes(response.data.tipo_de_presupuesto)
          || !isEgresos) {
          await postAsignacionMensualIgual({
            id: response.data.id,
            values: asignacion_mensual,
          });
        }

        onSuccess(response, 'Actualizado correctamente');
        await fetchData();
        if (!_continue) {
          onCancel();
        }
      }

      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`${baseURI}${key}/`);
        if (response && response.status === 204) {
          onSuccess(response, 'Eliminado correctamente', 2.5);
          onCancel();
          fetchData();
          setVisibleAlert(false);
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    setTimeout(() => {
      form.resetFields();
      setSelectedRowKeys([record.id]);
      form.setFieldsValue(record);
      setMutable(isMutable(record));
    });
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const getUR = async (values) => {
    try {
      setLoading(true);
      await form.validateFields(['content_type', 'object']);
      const { content_type, object_id } = values;
      const { nivel } = urs.find((e) => e.id === content_type);
      const uris = {
        1: 'unidades-operativas',
        2: 'unidades-responsables',
        3: 'centros-de-costos',
      };
      const response = await API.get(`/estructura-organizacional/${uris[nivel]}/${object_id}/`);
      if (response?.status === 200 && response.data) {
        setLoading(false);
        return `${response.data.clave} - ${response.data.descripcion}`;
      }
    } catch (err) {
      onError(err, setLoading);
    }
    return null;
  };

  const getAsgnacionesMensuales = async ({ id, presupuestoTipo }) => {
    try {
      setLoading(true);
      const response = await API.get(`${baseURI}${id}/asignaciones-mensuales/`);
      setAsignationMensual(response.data);
      if (presupuestoTipo === 3) {
        setDetalladoMensual(response.data);
      }
      setLoading(false);
      return response.data;
    } catch (err) {
      onError(err, setLoading);
      return [];
    }
  };

  const onClickEdit = async () => {
    try {
      form.setFieldsValue({ monto_mensual_igual: undefined });
      const clone = [...data];
      const [key] = selectedRowKeys;
      const match = clone.find((item) => item.id === key);
      if (isEgresos) {
        const ur = await getUR(match);
        form.setFieldsValue({ ur });
        setTipoPresupuesto(match.tipo_de_presupuesto);
        setDisabled(true);
        setShowMeses(true);
      } else {
        setDisabled(match.monto_mensual_igual);
        if (match?.importe > 0) {
          form.setFieldsValue({ monto_mensual_igual: match.monto_mensual_igual });
        } else {
          form.setFieldsValue({ monto_mensual_igual: undefined });
        }
      }
      setShowAsignaciones(true);
      const asignacionesMensuales = await getAsgnacionesMensuales({ id: key });
      setShowAsignaciones(!asignacionesMensuales.every((e) => e.importe === 0)
      || [1, 2].includes(match?.tipo_de_presupuesto));
      const asMen = Object
        .fromEntries(asignacionesMensuales.map((e) => [e.mes, e.importe]));
      form.setFieldsValue(asMen);

      if (match.tipo_de_presupuesto === 2) {
        setDisabled(false);
      }
      setVisible(true);
    } catch (err) {
      onError(err);
    }
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const columns = [
    {
      titleText: 'Clave Presupuestal Visible',
      dataIndex: 'clave_presupuestal_visible',
      key: 'clave_presupuestal_visible',
      width: 200,
    },
    {
      titleText: 'Tipo de Presupuesto',
      dataIndex: 'tipo_de_presupuesto',
      key: 'tipo_de_presupuesto',
      render: (val) => {
        if (!val) {
          return <span style={{ color: '#ff4949' }}>Por definir</span>;
        }
        return tiposDePresupuesto.find((e) => e.id === val)?.descripcion;
      },
      width: 200,
    },
    {
      titleText: 'Monto Total',
      dataIndex: 'monto_total',
      key: 'monto_total',
      align: 'right',
      render: (val) => currencyFormater(val),
      width: 200,
    },
  ];

  const columnsIngresos = [
    {
      titleText: 'Clave Presupuestal Visible',
      dataIndex: 'clave_presupuestal_visible',
      key: 'clave_presupuestal_visible',
      width: 200,
    },
    {
      titleText: 'Tipo de Presupuesto',
      dataIndex: 'monto_mensual_igual',
      key: 'monto_mensual_igual',
      render: (val) => {
        if (typeof val === 'boolean') {
          return val ? 'Monto mensual igual' : 'Asignación mensual';
        }
        return <span style={{ color: '#ff4949' }}>Por definir</span>;
      },
      width: 200,
    },
    {
      titleText: 'Monto Total',
      dataIndex: 'importe',
      key: 'importe',
      align: 'right',
      render: (val) => currencyFormater(val || 0),
      width: 200,
    },
  ];

  const requiredRule = {
    required: true,
    message: 'El campo es requerido',
  };

  const rules = {
    required: [
      requiredRule,
    ],
    importe: [
      {
        validator: async (_, val) => {
          if (val && !decimal.test(val)) {
            throw new Error('Ingrese un valor decimal válido');
          }
        },
      },
    ],
    q_ur: [
      requiredRule,
    ],
    content_type: [
      requiredRule,
    ],
  };

  const asignacionMensual = () => {
    const { monto_total, importe } = form.getFieldsValue();
    const montoTtl = monto_total || importe;
    const montoMensual = (montoTtl / 12).toFixed(2);
    setShowMeses(true);
    setDisabled(true);
    if (!Number.isNaN(montoMensual) && montoMensual !== 'NaN') {
      form.setFieldsValue({
        1: montoMensual,
        2: montoMensual,
        3: montoMensual,
        4: montoMensual,
        5: montoMensual,
        6: montoMensual,
        7: montoMensual,
        8: montoMensual,
        9: montoMensual,
        10: montoMensual,
        11: montoMensual,
        12: montoMensual,
        saldo_por_asignar: 0,
      });
      setShowAsignaciones(true);
    }
  };

  const onChangeMonth = () => {
    const values = form.getFieldsValue();
    const montoTtl = values?.monto_total || values?.importe;
    const totalAsignado = meses
      .map((e) => parseFloat(values[e.id]))
      .reduce((acum, curr) => acum + (parseFloat(curr) || 0), 0);
    form.setFieldsValue({
      saldo_por_asignar: (montoTtl - totalAsignado).toFixed(2) || 0,
    });
    form.validateFields(['saldo_por_asignar']);
  };

  const getLabel = () => {
    const content_type = form.getFieldValue('content_type');
    const ur = urs.find((e) => e.id === content_type);
    return ur?.model;
  };

  const onConfirmSync = async () => {
    try {
      setLoading(true);
      const res = await API.post(`${baseURI}sincronizar-cuentas-contables/`, {
        periodo_fiscal: periodoFiscalSelected?.id,
      });
      if (res?.status === 200) {
        onSuccess(res);
        setVisibleConfirmSync(false);
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const validateOnAuthorize = async () => {
    try {
      setLoading(true);
      const documento = isEgresos ? 1 : 2;
      const estados_globales = 4;

      const errors = [];
      const resConfiguracionDePoliza = await API.get('/configuraciones/configuraciones-de-polizas/', {
        params: {
          documento,
          estados_globales,
        },
      });
      if (resConfiguracionDePoliza?.status === 200 && !resConfiguracionDePoliza.data?.length) {
        errors.push({
          message: `Configuración de Póliza para ${isEgresos ? 'Egresos' : 'Ingresos'}`,
          path: '/contabilidad/configuraciones-de-polizas',
        });
      }

      const resMovimientos = await API.get('configuraciones/movimientos-de-polizas/', {
        params: {
          concepto: documento,
          documento,
          estados_globales,
        },
      });
      if (resMovimientos.status === 200 && !resMovimientos.data?.length) {
        errors.push({
          message: `Movimientos de Póliza para ${isEgresos ? 'Egresos' : 'Ingresos'}`,
          path: '/contabilidad/movimiento-de-polizas',
        });
      }

      const resFolioAgrupador = await API.get('configuraciones/folios-de-documentos-operativos/', {
        params: {
          documento,
          estados_globales,
        },
      });
      if (resFolioAgrupador.status === 200 && !resFolioAgrupador.data?.length) {
        errors.push({
          message: `Folio Agrupador para ${isEgresos ? 'Egresos' : 'Ingresos'}`,
          path: '/configuracion/otros/foliador-de-documentos-operativos',
        });
      }

      if (errors.length) {
        message.warn({
          content: (
            <Col>
              <strong>
                Para autorizar es requerido registrar/autorizar los siguientes datos:
              </strong>
              <br />
              <br />
              {errors.map((item) => (
                <React.Fragment key={item.path}>
                  <Row
                    justify="space-between"
                  >
                    <strong style={{ marginRight: 3 }}>
                      {item.message}
                    </strong>
                    <Button
                      type="link"
                      onClick={() => {
                        history.push(item.path);
                        message.destroy();
                      }}
                    >
                      Ir

                    </Button>
                  </Row>
                </React.Fragment>
              ))}
              <Button
                icon={<CloseCircleOutlined />}
                onClick={() => message.destroy()}
                type="link"
                style={{
                  position: 'absolute',
                  top: -30,
                  right: -20,
                }}
              />
            </Col>
          ),
          duration: 6,
        });
        return false;
      }
      setLoading(false);
      return true;
    } catch (err) {
      onError(err, setLoading);
      return false;
    }
  };

  const onConfirmAuthorize = async () => {
    try {
      setLoading(true);
      const _continue = await validateOnAuthorize();
      if (_continue) {
        const res = await API.post(`${baseURI}autorizar/`, {
          periodo_fiscal: periodoFiscalSelected?.id,
        });
        if (res?.status === 200) {
          onSuccess(res);
          setVisibleConfirmAuthorize(false);
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  return (
    <Row align="center" justify="center" className="container">
      <Col span={24}>
        <Spin tip="Cargando..." spinning={loading}>
          {!visible ? (
            <Table
              cols={isEgresos ? columns : columnsIngresos}
              data={data}
              allowImport
              permission={permission}
              baseURI={baseURI}
              rowSelection={rowSelection}
              handleOnRowClick={handleOnRowClick}
              controls={{
                onClickEdit,
                onClickDelete,
              }}
              rowKey="id"
              customActions={[
                {
                  onClick: () => setVisibleConfirmSync(true),
                  text: 'Sincronizar',
                  icon: <SyncOutlined />,
                  disabledBySelection: false,
                  disabled: !(data.length && periodoFiscalSelected?.id)
                    || data.every((e) => typeof e.cuenta_abono === 'string'
                      && typeof e.cuenta_cargo === 'string'),
                },
                {
                  onClick: () => setVisibleConfirmAuthorize(true),
                  text: 'Autorizar',
                  icon: <CheckCircleOutlined />,
                  disabledBySelection: false,
                  className: 'success',
                  disabled: !(data.length && periodoFiscalSelected?.id)
                    || data.every((e) => e.estados_globales === 4),
                },
              ]}
            />
          ) : (
            <Col span={24} style={{ height: '100%' }}>
              <Card
                className="form-container"
                title={(
                  <FormSubmitControls
                    label={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Presupuesto Anual`}
                    onFinish={onFinish}
                    onCancel={onCancel}
                    mutable={mutable}
                  />
                )}
                bordered={false}
              >
                <Form
                  layout="vertical"
                  form={form}
                  onFinish={onFinish}
                  initialValues={{
                    estados_globales: 1,
                    periodo_fiscal: periodoFiscalSelected?.id,
                    saldo_por_asignar: 0,
                    monto_mensual_igual: undefined,
                    ...montosEn0,
                  }}
                  scrollToFirstError
                  onValuesChange={(changedValues, allValues) => {
                    if (('monto_total' in changedValues
                      && allValues.tipo_de_presupuesto === 1)
                      || ('monto_mensual_igual' in changedValues
                        && changedValues?.monto_mensual_igual)) {
                      asignacionMensual();
                    }
                  }}
                >
                  <Row gutter={10}>
                    {isEgresos ? (
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item name="ur" label={getLabel()}>
                          <Input disabled />
                        </Form.Item>
                      </Col>
                    ) : (
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item name="clase_cri" label="CRI">
                          <Select
                            dataSource={clases}
                            keyLabelRender
                            disabled={!mutable}
                            labelProp="denominacion"
                          />
                        </Form.Item>
                      </Col>
                    )}
                    <Form.Item hidden name="clave_presupuestal">
                      <Input disabled />
                    </Form.Item>
                    <Form.Item name="content_type" hidden>
                      <Input />
                    </Form.Item>
                    <Form.Item name="object_id" hidden>
                      <Input />
                    </Form.Item>
                    <Col xs={24} sm={24} md={8}>
                      <NumericInput
                        label="Monto Total"
                        rules={rules.required}
                        name={isEgresos ? 'monto_total' : 'importe'}
                        disabled={!mutable}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        label="Saldo por asignar"
                        rules={rules.saldo_por_asignar}
                        name="saldo_por_asignar"
                      >
                        <Input disabled={!mutable} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        label="Ejercicio"
                        rules={rules.required}
                        name="periodo_fiscal"
                      >
                        <Select
                          disabled={!mutable}
                          dataSource={[periodoFiscalSelected]}
                          render={(item) => item.periodo}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      {isEgresos ? (
                        <Form.Item
                          label="Tipo de Presupuesto"
                          rules={rules.required}
                          name="tipo_de_presupuesto"
                        >
                          <Select
                            dataSource={tiposDePresupuesto
                              .filter((e) => (!isEgresos ? [1, 2].includes(e.id) : e))}
                            onChange={(val) => {
                              setTipoPresupuesto(val);
                              if (val === 1) {
                                asignacionMensual();
                              } else {
                                setShowMeses(true);
                                setDisabled(false);
                                form.setFieldsValue(montosEn0);
                              }
                              if (val === 3) {
                                getAsgnacionesMensuales({
                                  id: selectedRowKeys[0],
                                  presupuestoTipo: val,
                                });
                              }
                              onChangeMonth();
                            }}
                            disabled={!mutable}
                          />
                        </Form.Item>
                      ) : (
                        <Form.Item
                          name="monto_mensual_igual"
                          label="¿Es monto mensual igual?"
                        >
                          <Select
                            disabled={!mutable}
                            trueFalse
                            onChange={(val) => {
                              if (val) {
                                asignacionMensual();
                              } else {
                                setDisabled(false);
                                form.setFieldsValue(montosEn0);
                              }
                            }}
                          />
                        </Form.Item>
                      )}
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="estados_globales"
                        label="Estado"
                        rules={rules.required}
                        hasFeedback
                      >
                        <Select
                          disabled={!mutable}
                          dataSource={estadosGlobales}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item name="clave_presupuestal" label="Clave presupuestal">
                        <Input disabled={!mutable} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={isEgresos ? 8 : 12}>
                      <Form.Item name="cuenta_de_ingresos" label="Cuenta de Ingresos">
                        <Input disabled={!mutable} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={isEgresos ? 8 : 12}>
                      <Form.Item name="cuenta_de_modificacion" label="Cuenta de Modificacion">
                        <Input disabled={!mutable} />
                      </Form.Item>
                    </Col>
                    {!isEgresos && (
                      <>
                        <Col xs={24} sm={24} md={12} lg={12}>
                          <Form.Item
                            name="cuenta_de_recaudado"
                            label="Cuenta de Recaudado"
                          >
                            <Input disabled={!mutable} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12}>
                          <Form.Item
                            name="cuenta_devengado"
                            label="Cuenta Devengado"
                          >
                            <Input disabled={!mutable} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12}>
                          <Form.Item
                            name="cuenta_estimado"
                            label="Cuenta Estimado"
                          >
                            <Input disabled={!mutable} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12}>
                          <Form.Item
                            name="cuenta_por_ejecutar"
                            label="Cuenta por Ejecutar"
                          >
                            <Input disabled={!mutable} />
                          </Form.Item>
                        </Col>
                      </>
                    )}
                  </Row>
                  {((tipoPresupuesto && tipoPresupuesto !== 3) || showAsignaciones) && (
                    <Row gutter={10}>
                      {meses.map((mes) => showMeses && (
                        <Col xs={24} sm={24} md={6} key={mes.id}>
                          <NumericInput
                            rules={rules.importe}
                            label={mes.nombre}
                            name={`${mes.id}`}
                            disabled={disabled}
                            decimal
                            onChange={onChangeMonth}
                          />
                        </Col>
                      ))}
                    </Row>
                  )}
                  {tipoPresupuesto === 3 && (
                    <TableMontoDetallado
                      data={detalladoMensual}
                      setData={setDetalladoMensual}
                      baseURI={`${baseURI}${selectedRowKeys[0]}/asignaciones-mensuales/`}
                      callback={onFinish}
                    />
                  )}
                  <Form.Item hidden>
                    <Button htmlType="submit" />
                  </Form.Item>
                </Form>
              </Card>
            </Col>
          )}
          <ModalDelete
            onDelete={deleteItem}
            onCancel={() => setVisibleAlert(false)}
            visible={visibleAlert}
            content={(
              <Text style={{ fontSize: 16 }}>
                ¿Desea eliminar el Presupuesto Anual de Egresos
                {data.find((e) => e.id === selectedRowKeys[0])?.clave_presupuestal_visible}
                ?
              </Text>
            )}
          />
        </Spin>
      </Col>
      <Modal
        visible={visibleConfirmSync}
        title={(
          <Row justify="space-between">
            Confirmar sincronización
            <FormSubmitControls
              onFinish={onConfirmSync}
              onCancel={() => setVisibleConfirmSync(false)}
              onFinishText="Sincronizar"
              onFinishIcon={<SyncOutlined />}
            />
          </Row>
        )}
        footer={null}
        closable={false}
      >
        <Row justify="center" style={{ flex: 1 }}>
          <Text style={{ textAlign: 'center' }}>
            ¿Seguro que desea iniciar el proceso de sincronización
            <br />
            de Cuentas Contables correspondientes al Periodo Fiscal
            <br />
            {periodoFiscalSelected?.periodo}
            {' '}
            -
            {' '}
            {periodoFiscalSelected?.identificador}
            ?
          </Text>
        </Row>
      </Modal>
      <Modal
        visible={visibleConfirmAuthorize}
        title={(
          <Row justify="space-between">
            Confirmar Autorización
            <FormSubmitControls
              onFinish={onConfirmAuthorize}
              onCancel={() => setVisibleConfirmAuthorize(false)}
              onFinishText="Autorizar"
              onFinishIcon={<CheckCircleOutlined />}
            />
          </Row>
        )}
        footer={null}
        closable={false}
      >
        <Row justify="center" style={{ flex: 1 }}>
          <Text style={{ textAlign: 'center' }}>
            ¿Seguro que desea iniciar el proceso de autorización
            <br />
            de Cuentas Contables correspondientes al Periodo Fiscal
            <br />
            {periodoFiscalSelected?.periodo}
            {' '}
            -
            {' '}
            {periodoFiscalSelected?.identificador}
            ?
          </Text>
        </Row>
      </Modal>
    </Row>
  );
}

export default PresupuestoAnual;
