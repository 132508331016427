export default [
  {
    id: 1,
    no_de_estudio: 'EM-2021-05-0120',
    fecha: '6/1/2021',
    elaboro: 'Martha Arreola Salas',
    solicitante: 'Claudia Reyes Ornelas',
    puesto_solicitante: 'Asistente',
    unidad_organizacional: 'Contabilidad',
    tipo_de_producto_servicio: '2110 Materiales y….',
    numero_requisicion: 'RE-2021-06-0036',
    folio_agrupador: 'FA-2021-0004',
  },
  {
    id: 2,
    no_de_estudio: 'EM-2021-05-0185',
    fecha: '6/18/2021',
    elaboro: 'Gilberto Ocampo Zeres',
    solicitante: 'Gilberto Ocampo Zeres',
    puesto_solicitante: 'Auxiliar',
    unidad_organizacional: 'Tesorería',
    tipo_de_producto_servicio: '2110 Materiales y….',
    numero_requisicion: 'RE-2021-06-0037',
    folio_agrupador: 'FA-2021-0018',
  },
  {
    id: 3,
    no_de_estudio: 'EM-2021-05-0201',
    fecha: '6/19/2021',
    elaboro: 'Gregorio Esparza Torres',
    solicitante: 'Gregorio Esparza Torres',
    puesto_solicitante: 'Capturista',
    unidad_organizacional: 'Recursos Humanos',
    tipo_de_producto_servicio: '2110 Materiales y….',
    numero_requisicion: 'RE-2021-06-0038',
    folio_agrupador: 'FA-2021-0022',
  },
];
