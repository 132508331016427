import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import { useSelector } from 'react-redux';
import {
  Row,
  Col,
  Spin,
  Form,
  Button,
  Input,
  Typography,
  Card,
  message,
} from 'antd';
import FormSubmitControls from 'components/FormSubmitControls';
import Table from 'components/Table';
import ModalDelete from 'components/ModalDelete';
import API from 'utils/api';
import { onError } from 'utils/handlers';
// import Select from 'components/Select';
import { isMutable } from 'utils/estadosGlobales';
import NumericInput from 'components/NumericInput';
import Select from 'components/Select';

const baseURI = '/presupuestos/avance-de-actividad-de-ppfid/';

function AvanceActividadFID({
  meta,
  metasFID,
  indicadoresData,
  allowAdd,
  avanceID,
  presupuestoAnual,
  actividadFid,
}) {
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const [mutable, setMutable] = useState(true);
  const [disableAdd, setdisableAdd] = useState();

  const [calendarizacionSeleccionada, setCalendarizacionSeleccionada] = useState();

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await API.get(baseURI, {
        params: {
          avance_general: avanceID,
          numero: 1,
        },
      });
      setData(res.data);
      if (res.data.length !== 0) {
        setdisableAdd(true);
      } else {
        setdisableAdd(false);
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const fetchAll = async () => {
    try {
      await fetchData();
    } catch (error) {
      onError(error, setLoading);
    }
  };
  useEffect(() => {
    fetchAll();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = async () => {
    setSelectedRowKeys([]);
    form.resetFields();
    setVisible(false);
    setMutable(true);
    setdisableAdd(false);
    await fetchData();
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = {
        ...form.getFieldsValue(),
        programa_fid: meta.programa_fid,
        avance_general: avanceID,
        presupuesto_anual_de_egresos: presupuestoAnual,
      };
      const metasKeys = Object.keys(values).filter((e) => /\d+/.test(e));
      const _metas = [];
      metasKeys.forEach((e) => {
        _metas.push(values[e]);
        delete values[e];
      });
      if (!selectedRowKeys.length) {
        const fullValues = [];
        const promises = [];
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < _metas.length; i++) {
          fullValues.push({
            numero: i + 1,
            ...values,
            ..._metas[i],
          });
          promises.push(API.post(baseURI, fullValues[i]));
        }
        const responses = await Promise.allSettled(promises);
        if (!responses.every((response) => response.status === 'fulfilled')) {
          message.info('No se agrego la meta');
        } else {
          message.info('Se agrego correctamente');
          onCancel();
          await fetchData();
        }
      } else {
        const resMetas = await API.get(baseURI, {
          params: {
            avance_general: avanceID,
          },
        });
        const sortedMetas = resMetas.data.sort((x, y) => x.numero - y.numero);
        const promises = [];
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < _metas.length; i++) {
          promises.push(API.patch(`${baseURI}${sortedMetas[i].id}`, _metas[i]));
        }
        const responses = await Promise.allSettled(promises);
        if (!responses.every((response) => response.status === 'fulfilled')) {
          message.info('No se agrego la meta');
        } else {
          message.info('Se edito correctamente');
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      const resMetas = await API.get(baseURI, {
        params: {
          avance_general: avanceID,
        },
      });
      const sortedMetas = resMetas.data.sort((x, y) => x.numero - y.numero);
      if (selectedRowKeys.length) {
        const promises = [];
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < sortedMetas.length; i++) {
          promises.push(API.delete(`${baseURI}${sortedMetas[i].id}`));
        }
        const responses = await Promise.allSettled(promises);
        if (!responses.every((response) => response.status === 'fulfilled')) {
          message.info('No se elimino el avance');
        } else {
          message.info('Se elimino correctamente');
          setVisibleAlert(false);
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    form.setFieldsValue(record);
    setMutable(isMutable(record));
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickAdd = async () => {
    try {
      onCancel();
      setVisible(true);
      if (meta) {
        const resIndicador = indicadoresData.find((e) => e.id === meta.indicador_fid);
        setTimeout(() => {
          form.setFieldsValue({
            formula_de_calculo: resIndicador?.formula,
          });
        });
        const resMetas = await API.get('/presupuestos/calendarizacion-de-meta-de-ppfid/', {
          parms: {
            meta_fid: meta?.id,
          },
        });
        const metas = resMetas.data;
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < metas.length; i++) {
          delete metas[i].variable_a;
          delete metas[i].resultado;
        }
        const values = Object.fromEntries(metas.map((e) => [e.numero - 1, e]));
        setTimeout(() => {
          form.setFieldsValue({
            ...values,
          });
        });
      }
      setCalendarizacionSeleccionada(meta.calendarizacion);
    } catch (error) {
      onError(error, setLoading);
    }
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const columns = [
    {
      titleText: 'Meta de Programa FID',
      dataIndex: 'meta_de_programa_presupuestario_fid',
      key: 'meta_de_programa_presupuestario_fid',
      width: 350,
      render: (val) => metasFID.find((e) => e.id === val)?.meta_de_administracion,
    },
    {
      titleText: 'Actividad FID',
      dataIndex: 'actividad',
      key: 'actividad',
      width: 350,
      render: (val) => actividadFid.find((e) => e.id === val)?.nombre,
    },
  ];

  const calendarizacionesMap = {
    1: ['Enero - Diciembre'],

    2: ['1er Semestre Enero - Junio',
      '2do Semestre Julio - Diciembre',
    ],

    3: ['1er Cuatrimestre Enero - Abril',
      '2do Cuatrimestre Mayo - Agosto',
      '3er Cuatrimestre Septiembre - Diciembre',
    ],

    4: ['1er Trimestre Enero - Marzo',
      '2do Trimestre Abril - Junio',
      '3er Trimestre Julio - Septiembre',
      '4to Trimestre Octubre - Diciembre',
    ],

    5: ['1er Bimestre Enero - Febrero',
      '2do Bimestre Marzo - Abril',
      '3er Bimestre Mayo - Junio',
      '4to Bimestre Julio - Agosto',
      '5to Bimestre Septiembre - Octubre',
      '6to Bimestre Noviembre - Diciembre',
    ],

    6: ['Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ],
  };

  // const requiredRule = {
  //   required: true,
  //   message: 'El campo es requerido',
  // };

  // const rules = {
  //   required: [requiredRule],
  // };

  const onClickEdit = async () => {
    try {
      setVisible(true);
      const [key] = selectedRowKeys;
      const resActividades = await API.get(baseURI, {
        params: {
          avance_general: avanceID,
        },
      });
      const valuesMetas = Object.fromEntries(resActividades.data.map((e) => [e.numero - 1, e]));
      const match = resActividades.data.find((e) => e.id === key);
      const matchMeta = metasFID.find((e) => e.id === match.meta_de_programa_presupuestario_fid);
      setCalendarizacionSeleccionada(matchMeta.calendarizacion);
      const matchIndicador = indicadoresData.find((e) => e.id === matchMeta.indicador_fid);
      setTimeout(() => {
        form.setFieldsValue({
          ...valuesMetas,
          actividad: match.actividad,
          formula_de_calculo: matchIndicador.formula,
        });
      });
    } catch (error) {
      onError(error, setLoading);
    }
  };
  const calculate = (expression, a, b) => {
    const newString = expression.replace('(', '')
      .replace(')', '')
      .replace('a', a.toString())
      .replace('b', b.toString())
      .replace('A', a.toString())
      .replace('B', b.toString());

    if (newString.substr(-1) !== '/'
    && newString.substr(-1) !== '*'
    && newString.substr(-1) !== '-'
    && newString.substr(-1) !== '+') {
      const regex = /(\d+)([+\-*/]\d+)*/g;
      const isValid = regex.test(newString);
      if (isValid) {
      // eslint-disable-next-line no-new-func
        return Function(`
      'use strict';
      try {
        return (${newString});
      } catch {
        return 0
      }
      `)();
      }
    }
    return 0;
  };

  // const getMetas = (meta) => {

  // };
  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible ? (
          <Table
            cols={columns}
            data={data}
            rowSelection={rowSelection}
            handleOnRowClick={handleOnRowClick}
            loading={loading}
            controls={{
              onClickAdd,
              onClickEdit,
              onClickDelete,
            }}
            mobileColIndex={0}
            allowAdd={allowAdd && !disableAdd}
          />
        ) : (
          <Card
            visible={visible}
            title={(
              <FormSubmitControls
                label={`${!selectedRowKeys.length ? 'Agregar' : 'Editar'} Avance Fisico`}
                onFinish={onFinish}
                onCancel={onCancel}
                mutable={mutable}
              />
            )}
            onCancel={onCancel}
            footer={null}
            closable={false}
            maskClosable={!loading}
            keyboard={!loading}
            forceRender
          >
            <Form
              name="general"
              form={form}
              layout="vertical"
              onFinish={onFinish}
              scrollToFirstError
              initialValues={{
                meta_de_programa_presupuestario_fid: meta.id,
              }}
            >
              <Row gutter={10}>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    label="Meta FID"
                    name="meta_de_programa_presupuestario_fid"
                  >
                    <Select
                      dataSource={metasFID}
                      labelProp="meta_de_administracion"
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    label="Actividad FID"
                    name="actividad"
                  >
                    <Select
                      dataSource={actividadFid}
                      labelProp="nombre"
                    />
                  </Form.Item>
                </Col>
              </Row>
              {/* Calculos con base a las metas y nuevos periodos ----------------------- */}
              {calendarizacionSeleccionada && (
              <>
                <Typography.Title level={4}>
                  Planeado contra Real
                </Typography.Title>
                <Row gutter={10}>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      label="Formula de Calculo"
                      name="formula_de_calculo"
                    >
                      <Input
                        disabled
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </>
              )}
              {calendarizacionSeleccionada
                    && calendarizacionesMap[calendarizacionSeleccionada].map((label, i) => (
                      <Row gutter={10}>
                        <Col xs={24} sm={24} md={6}>
                          <h3>{label}</h3>
                        </Col>
                        <Col xs={24} sm={24} md={6}>
                          <NumericInput
                            label={i === 0 ? ('Variable A') : ('')}
                            name={[i, 'variable_a']}
                            decimal
                              // disabled
                            allowZero
                            allowNegative
                            onChange={() => {
                              const values = {
                                ...form.getFieldsValue(),
                              };
                              if (values?.formula_de_calculo) {
                                const metasKeys = Object.keys(values).filter((e) => /\d+/.test(e));
                                // Aqui saco los datos de las tablas y las meto en arrays
                                const _metas = [];
                                metasKeys.forEach((e) => {
                                  _metas.push(values[e]);
                                  delete values[e];
                                });
                                // eslint-disable-next-line no-plusplus
                                for (let j = 0, sum = 0, sumPor = 0; j < _metas.length; j++) {
                                  if (_metas[j]?.variable_a && _metas[j]?.variable_b) {
                                    // Aqui se calculan los resultados reals
                                    if (_metas[j].variable_a === '0') {
                                      _metas[j].resultado = _metas[j].variable_b;
                                    } else {
                                      _metas[j].resultado = parseFloat(calculate(
                                        values.formula_de_calculo,
                                        _metas[j].variable_a,
                                        _metas[j].variable_b,
                                      ).toFixed(2));
                                    }

                                    // Aqui se calcula la diferencia en unidades por periodo
                                    _metas[j].diferencia_en_unidades_por_periodo = parseFloat((
                                      _metas[j].variable_b
                                        - parseFloat(
                                          parseFloat(_metas[j].variable_a),
                                        )
                                    ).toFixed(2));

                                    // Aqui se calcula la diferencia en porcentaje por periodo
                                    _metas[j].diferencia_en_porcentaje_por_periodo = parseFloat((
                                      (_metas[j].diferencia_en_unidades_por_periodo * 100)
                                        / _metas[j].variable_b).toFixed(2));

                                    // Aqui se calcula la diferencia en unidades acumuladas
                                    sum += parseFloat(
                                      _metas[j]?.diferencia_en_unidades_por_periodo,
                                      10,
                                    );
                                    _metas[j].diferencia_en_unidad_acumulada = sum;

                                    // Aqui se calcula la diferencia en porcentaje acumlado
                                    sumPor += parseFloat(
                                      (_metas[j]?.diferencia_en_porcentaje_por_periodo
                                        .toFixed(2)),
                                    );
                                    _metas[j].diferencia_porcentaje_acumulada = parseFloat(
                                      sumPor.toFixed(2),
                                    );
                                  } else {
                                    _metas[j].resultado = 0;
                                    _metas[j].diferencia_en_unidades_por_periodo = 0;
                                    _metas[j].diferencia_porcentaje_acumulada = 0;
                                  }
                                }

                                setTimeout(() => {
                                  form.setFieldsValue({
                                    ..._metas,
                                  });
                                });
                              }
                            }}
                          />
                        </Col>
                        <Col xs={24} sm={24} md={6}>
                          <NumericInput
                            label={i === 0 ? ('Variable B') : ('')}
                            name={[i, 'variable_b']}
                            decimal
                            disabled
                            allowZero
                            allowNegative
                          />
                        </Col>
                        <Col xs={24} sm={24} md={6}>
                          <NumericInput
                            label={i === 0 ? ('Resultado') : ('')}
                            name={[i, 'resultado']}
                            // prefix="%"
                            decimal
                            allowZero
                            allowNegative
                            disabled
                          />
                        </Col>
                        <Form.Item
                          name={[i, 'id']}
                          hidden
                        >
                          <Input allowClear />
                        </Form.Item>
                      </Row>
                    ))}
              {calendarizacionSeleccionada && (
              <Typography.Title level={4}>
                Diferencias Avance Físico
              </Typography.Title>
              )}
              {calendarizacionSeleccionada && (
              <Typography.Title level={5}>
                Por Periodo
              </Typography.Title>
              )}
              {calendarizacionSeleccionada
                    && calendarizacionesMap[calendarizacionSeleccionada].map((label, i) => (
                      <Row gutter={10}>
                        <Col xs={24} sm={24} md={6}>
                          <h3>{label}</h3>
                        </Col>
                        <Col xs={24} sm={24} md={6}>
                          <NumericInput
                            label={i === 0 ? ('Diferencia en Unidad por periodo') : ('')}
                            name={[i, 'diferencia_en_unidades_por_periodo']}
                            decimal
                            disabled
                            allowZero
                            allowNegative
                          />
                        </Col>
                        <Col xs={24} sm={24} md={6}>
                          <NumericInput
                            label={i === 0 ? ('Diferencia en Porcentaje por Periodo') : ('')}
                            name={[i, 'diferencia_en_porcentaje_por_periodo']}
                            prefix="%"
                            decimal
                            disabled
                            allowZero
                            allowNegative
                          />
                        </Col>
                        <Form.Item
                          name={[i, 'id']}
                          hidden
                        >
                          <Input allowClear />
                        </Form.Item>
                      </Row>
                    ))}
              {/* Diferencias acumladas -------------------------------------------------*/}
              {calendarizacionSeleccionada && (
              <Typography.Title level={5}>
                Acumuladas
              </Typography.Title>
              )}
              {calendarizacionSeleccionada
                    && calendarizacionesMap[calendarizacionSeleccionada].map((label, i) => (
                      <Row gutter={10}>
                        <Col xs={24} sm={24} md={6}>
                          <h3>{label}</h3>
                        </Col>
                        <Col xs={24} sm={24} md={6}>
                          <NumericInput
                            label={i === 0 ? ('Diferencia en Unidades Acumulada') : ('')}
                            name={[i, 'diferencia_en_unidad_acumulada']}
                            decimal
                            disabled
                            allowZero
                            allowNegative
                          />
                        </Col>
                        <Col xs={24} sm={24} md={6}>
                          <NumericInput
                            label={i === 0 ? ('Diferencia en Porcentajes Acumulada') : ('')}
                            name={[i, 'diferencia_porcentaje_acumulada']}
                            prefix="%"
                            decimal
                            disabled
                            allowZero
                            allowNegative
                          />
                        </Col>
                        <Form.Item
                          name={[i, 'id']}
                          hidden
                        >
                          <Input allowClear />
                        </Form.Item>
                      </Row>
                    ))}
              {/* Justificaciones de diferencias -----------------------------------------*/}
              {calendarizacionSeleccionada && (
              <Typography.Title level={4}>
                Justificaciones De Diferencias
              </Typography.Title>
              )}
              {calendarizacionSeleccionada
                    && calendarizacionesMap[calendarizacionSeleccionada].map((label, i) => (
                      <Row gutter={10}>
                        <Col xs={24} sm={24} md={24}>
                          <h3>{label}</h3>
                        </Col>
                        <Col xs={24} sm={24} md={24}>
                          <Form.Item
                              // label="Justificación de diferencias"
                            name={[i, 'justificacion']}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Form.Item
                          name={[i, 'id']}
                          hidden
                        >
                          <Input allowClear />
                        </Form.Item>
                      </Row>
                    ))}
              <Form.Item hidden>
                <Button htmlType="submit" />
              </Form.Item>
            </Form>
          </Card>
        )}
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => setVisibleAlert(false)}
          visible={visibleAlert}
          content="El identificador seleccionado"
          loading={loading}
        />
      </Spin>
    </Row>
  );
}

AvanceActividadFID.propTypes = {
  meta: PropTypes.shape().isRequired,
  metasFID: PropTypes.arrayOf(PropTypes.shape()),
  indicadoresData: PropTypes.arrayOf(PropTypes.shape()),
  actividadFid: PropTypes.arrayOf(PropTypes.shape()),
  allowAdd: PropTypes.bool.isRequired,
  avanceID: PropTypes.number.isRequired,
  presupuestoAnual: PropTypes.number.isRequired,
};

AvanceActividadFID.defaultProps = {
  metasFID: [],
  indicadoresData: [],
  actividadFid: [],
};

export default AvanceActividadFID;
