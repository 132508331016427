import React from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Row,
  Typography,
} from 'antd';
import Moment from 'moment';
import { useSelector } from 'react-redux';
import ModalChangeFiscalPeriod from './ModalChangeFiscalPeriod';

const { Text } = Typography;
function Footer({
  margin,
  editable,
}) {
  // const periodoFiscalSelected = useSelector(({ auth }) => auth.periodoFiscalSelected);
  const entidad = useSelector(({ auth }) => auth.entidad);

  return (
    <Col
      xs={0}
      sm={0}
      md={24}
      className="footer"
      style={{
        left: margin,
      }}
    >
      <Row
        align="bottom"
        justify="space-around"
      >
        <Text>
          <ModalChangeFiscalPeriod editable={editable} />
        </Text>
        <Text>
          Entidad:
          {' '}
          {entidad?.razon_social}
        </Text>
        <Text>
          Fecha:
          {' '}
          {new Moment().format('DD/MM/YYYY')}
        </Text>
      </Row>
    </Col>
  );
}

Footer.propTypes = {
  margin: PropTypes.number,
  editable: PropTypes.bool,
};

Footer.defaultProps = {
  margin: 0,
  editable: true,
};

export default Footer;
