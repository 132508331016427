import React, { useState, useEffect } from 'react';
import {
  Form,
  Row,
  Spin,
  Col,
  Input,
  Modal,
  Card,
  Tabs,
  DatePicker,
  Select,
} from 'antd';

import API from 'utils/api';
import { onError } from 'utils/handlers';
import Table from 'components/Table';
import Direccion from 'components/Direccion';
import PagoObraPublica from 'views/ObraPublica/PagosObraPublica';
import FormSubmitControls from 'components/FormSubmitControls';

const { TabPane } = Tabs;

function ObraPublica() {
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([1, 2]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [currentTabKey, setCurrentTabKey] = useState('obra');
  const [openModalAgregarFinanza, setVisibleModalAgregarFinanza] = useState(false);
  const [mutable, setMutable] = useState(true);
  const [formAddressObra, setFormAddress] = useState();
  const [formAddressContratista, setFormAddressContratista] = useState();
  const [disabled, setDisabled] = useState(true);
  const [openModalAgregarObra, setOpenModelAgregarObra] = useState(false);
  const baseURI = '/estructura-organizacional/entidades/';
  const requiredRule = {
    required: true,
    message: 'El campo es requerido',
  };
  const rules = {
    required: [requiredRule],
  };

  const complexForms = [
    { key: 'obra', formInstance: formAddressObra },
    { key: 'contratista', formInstance: formAddressContratista },
  ];
  const fetchData = async () => {
    try {
      setLoading(true);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchData();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    setTimeout(() => form.setFieldsValue(record));
  };

  const onFinish = () => { };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onEditar = () => {
    setVisible(true);
    setMutable(true);
    setDisabled(true);
  };

  const onAgregaObra = () => {
    setOpenModelAgregarObra(true);
  };

  const onEditarObra = () => {
    setOpenModelAgregarObra(true);
  };

  const onDeleteObra = () => { };

  const onAgregarFinanza = () => {
    setVisibleModalAgregarFinanza(true);
  };

  const onEditarFinanza = () => { };

  const onDeleteFinanza = () => { };

  const onCancel = () => {
    setVisible(false);
  };

  const onCancelModalObra = () => {
    setOpenModelAgregarObra(false);
  };

  const columns = [
    {
      titleText: 'Clave',
      dataIndex: 'clave',
      key: 'clave',
      width: 200,
    },
    {
      titleText: 'Concepto de Obra',
      dataIndex: 'concepto_obra',
      key: 'concepto_obra',
      width: 200,
    },
    {
      titleText: 'Ubicación',
      dataIndex: 'ubicacion',
      key: 'ubicacion',
      width: 200,
    },
    {
      titleText: 'Tipo de Obra',
      dataIndex: 'tipo_obra',
      key: 'tipo_obra',
      width: 200,
    },
    {
      titleText: 'Situación de Obra',
      dataIndex: 'situcacion_obra',
      key: 'situcacion_obra',
      width: 200,
    },
    {
      titleText: 'Ejecicio de Realización',
      dataIndex: 'ejercicio_realizacion',
      key: 'ejercicio_realizacion',
      width: 200,
    },
    {
      titleText: 'Importe Total',
      dataIndex: 'importe_total',
      key: 'importe_total',
      width: 200,
    },
    {
      titleText: 'No. de Documento de Adjudicación',
      dataIndex: 'no_documento',
      key: 'no_documento',
      width: 300,
    },
    {
      titleText: 'Fecha de Adjudicación',
      dataIndex: 'fecha_adjudicacion',
      key: 'fecha_adjudicacion',
      width: 220,
    },
    {
      titleText: 'Contratista Asignado',
      dataIndex: 'contratista_asignado',
      key: 'contratista_asignado',
      width: 200,
    },
    {
      titleText: 'No. de Contrato',
      dataIndex: 'no_contrato',
      key: 'no_contrato',
      width: 200,
    },
    {
      titleText: 'Estado',
      dataIndex: 'estado',
      key: 'estado',
      width: 200,
    },
  ];

  const columnsObra = [
    {
      titleText: 'Claves Presupuestales',
      dataIndex: 'claves_presupuestales',
      key: 'claves_presupuestales',
      width: 200,
    },
    {
      titleText: 'Importe',
      dataIndex: 'importe',
      key: 'importe',
      width: 200,
    },
  ];

  const columnsFianza = [
    {
      titleText: 'Tipo de Fianza',
      dataIndex: 'tipo_finanza',
      key: 'tipo_finanza',
      width: 200,
    },
    {
      titleText: 'Afianzadora',
      dataIndex: 'afianzadora',
      key: 'afianzadora',
      width: 200,
    },
    {
      titleText: 'Fecha de Expedición',
      dataIndex: 'fecha_expedicion',
      key: 'fecha_expedicion',
      width: 200,
    },
    {
      titleText: 'No. de Fianza',
      dataIndex: 'no_fianza',
      key: 'no_fianza',
      width: 200,
    },
    {
      titleText: 'Monto de Fianza',
      dataIndex: 'monto_fianza',
      key: 'monto_fianza',
      width: 200,
    },
    {
      titleText: 'Motivo de Fianza',
      dataIndex: 'motivo_fianza',
      key: 'motivo_fianza',
      width: 200,
    },
    {
      titleText: 'Contratante',
      dataIndex: 'contratante',
      key: 'contratante',
      width: 200,
    },
    {
      titleText: 'Beneficiario',
      dataIndex: 'beneficiario',
      key: 'beneficiario',
      width: 200,
    },
    {
      titleText: 'No de Contrato Afianzado',
      dataIndex: 'no_contrato_afianzado',
      key: 'no_contrato_afianzado',
      width: 250,
    },
    {
      titleText: 'Observaciones',
      dataIndex: 'observaciones',
      key: 'observaciones',
      width: 200,
    },
    {
      titleText: 'Estado',
      dataIndex: 'estado',
      key: 'estado',
      width: 200,
    },
  ];

  const columnsFlujoProceso = [
    {
      titleText: 'No.',
      dataIndex: 'numero',
      key: 'numero',
      width: 150,
    },
    {
      titleText: 'Actividad',
      dataIndex: 'actividad',
      key: 'actividad',
      width: 150,
    },
    {
      titleText: 'Rol de Firma',
      dataIndex: 'rol_firma',
      key: 'rol_firma',
      width: 200,
    },
    {
      titleText: 'Fecha de Realización',
      dataIndex: 'fecha_realizacion',
      key: 'fecha_realizacion',
      width: 200,
    },
    {
      titleText: 'Puesto',
      dataIndex: 'puesto',
      key: 'puesto',
      width: 150,
    },
    {
      titleText: 'Empleado',
      dataIndex: 'empleado',
      key: 'empleado',
      width: 150,
    },
    {
      titleText: 'Área',
      dataIndex: 'area',
      key: 'area',
      width: 150,
    },
    {
      titleText: 'Estado de Proceso',
      dataIndex: 'estado_proceso',
      key: 'estado_proceso',
      width: 200,
    },
    {
      titleText: 'Documento a Adjuntar',
      dataIndex: 'documento_a_adjuntar',
      key: 'documento_a_adjuntar',
      width: 250,
    },
    {
      titleText: 'Documento que se Adjunta',
      dataIndex: 'documento_que_se_adjunta',
      key: 'documento_que_se_adjunta',
      width: 250,
    },
    {
      titleText: 'Falta Adjunto',
      dataIndex: 'falta_adjunto',
      key: 'falta_adjunto',
      width: 200,
    },
    {
      titleText: 'Autorizado/Rechazado',
      dataIndex: 'autorizado_rechazado',
      key: 'autorizado_rechazado',
      width: 200,
    },
    {
      titleText: 'Causas de Rechazo',
      dataIndex: 'causas_rechazo',
      key: 'causas_rechazo',
      width: 250,
    },
    {
      titleText: 'Observaciones',
      dataIndex: 'observaciones',
      key: 'observaciones',
      width: 250,
    },
    {
      titleText: 'Días de Dilación',
      dataIndex: 'dias_dilacion',
      key: 'dias_dilacion',
      width: 250,
    },
  ];

  const onChangeTabKey = () => {
    complexForms.find((e) => e.key === currentTabKey);
  };

  const onCancelModaFianza = () => {
    setVisibleModalAgregarFinanza(false);
  };
  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible
          ? (
            <Table
              cols={columns}
              data={[]}
              rowSelection={rowSelection}
              handleOnRowClick={handleOnRowClick}
              mobileColIndex={0}
              controls={{
                onClickEdit: () => onEditar(),
              }}
              allowEdit
            />
          )
          : (
            <Card
              className="form-container"
              title="Obra Publica"
              bordered={false}
              extra={(
                <FormSubmitControls
                  onCancel={onCancel}
                  loading={loading}
                />
                )}
            >
              <Tabs
                activeKey={currentTabKey}
                onChange={mutable ? setCurrentTabKey : onChangeTabKey}
                type="card"
              >
                <TabPane
                  tab="Obra"
                  key="obra"
                  forceRender
                >
                  <Form
                    layout="vertical"
                    name="obra"
                    labelAlign="left"
                    labelWrap
                  >
                    <Row gutter={10}>
                      <Col xs={12} sm={12} md={8}>
                        <Form.Item
                          name="clave"
                          label="Clave"
                        >
                          <Input allowClear disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={12} sm={12} md={8}>
                        <Form.Item
                          name="concepto_obra"
                          label="Concepto de Obra"
                        >
                          <Input allowClear disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={12} sm={12} md={8}>
                        <Form.Item
                          name="tipo_obra"
                          label="Tipo de Obra"
                        >
                          <Input allowClear disabled />
                        </Form.Item>
                      </Col>
                      <Direccion
                        useParentID={false}
                        currentURI={baseURI}
                        setLoading={setLoading}
                        setFormAddress={setFormAddress}
                        parentID={setCurrentTabKey}
                        setCurrentTabKey={setCurrentTabKey}
                        mutable={!disabled}
                      />
                      <Col xs={12} sm={12} md={8}>
                        <Form.Item
                          name="ejercicio_realizacion"
                          label="Ejercicio de Realización"
                        >
                          <Input allowClear disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={12} sm={12} md={8}>
                        <Form.Item
                          name="importe_anio_1"
                          label="Importe Año 1"
                        >
                          <Input allowClear disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={12} sm={12} md={8}>
                        <Form.Item
                          name="importe_anio_1"
                          label="Importe Año 2"
                        >
                          <Input allowClear disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={12} sm={12} md={8}>
                        <Form.Item
                          name="importe_total"
                          label="Importe Total"
                        >
                          <Input allowClear disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={12} sm={12} md={8}>
                        <Form.Item
                          name="modalidad_ejecucion"
                          label="Modalidad de Ejecución"
                        >
                          <Input allowClear disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={12} sm={12} md={8}>
                        <Form.Item
                          name="forma_adjudicacion"
                          label="Forma de Adjudicación"
                        >
                          <Input allowClear disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={12} sm={12} md={8}>
                        <Form.Item
                          name="fuente_financiamiento"
                          label="Fuente de Financiamiento"
                        >
                          <Input allowClear disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={12} sm={12} md={8}>
                        <Form.Item
                          name="programa_presupuestario"
                          label="Programa Presupuestario"
                        >
                          <Input allowClear disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={12} sm={12} md={8}>
                        <Form.Item
                          name="programa_pmd"
                          label="Programa PMD"
                        >
                          <Input allowClear disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={12} sm={12} md={8}>
                        <Form.Item
                          name="objetivo_pms"
                          label="Objetivo PMD"
                        >
                          <Input allowClear disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={12} sm={12} md={8}>
                        <Form.Item
                          name="estrategia_pmd"
                          label="Estrategia PMD"
                        >
                          <Input allowClear disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={12} sm={12} md={8}>
                        <Form.Item
                          name="plan_municipal_desarrollo"
                          label="Plan Municipal de Desarrollo(PMD)"
                        >
                          <Input allowClear disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={12} sm={12} md={8}>
                        <Form.Item
                          name="plan_estatal_desarrollo"
                          label="Plan Estatal de Desarrollo(PED)"
                        >
                          <Input allowClear disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={12} sm={12} md={8}>
                        <Form.Item
                          name="plan_nacional_desarrollo"
                          label="Plan Nacional de Desarrollo(PND)"
                        >
                          <Input allowClear disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={12} sm={12} md={8}>
                        <Form.Item
                          name="obra_proceso"
                          label="Cuenta Contable de Obra en Proceso"
                        >
                          <Select allowClear />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                  <Table
                    cols={columnsObra}
                    data={[]}
                    rowSelection={rowSelection}
                    handleOnRowClick={handleOnRowClick}
                    mobileColIndex={0}
                    controls={{
                      onClickAdd: () => onAgregaObra(),
                      onClickEdit: () => onEditarObra(),
                      onClickDelete: () => onDeleteObra(),
                    }}
                  />

                </TabPane>
                <TabPane
                  tab="Contratista"
                  key="contratista"
                  forceRender
                >
                  <Form
                    layout="vertical"
                    name="obra"
                    labelAlign="left"
                    labelWrap
                  >
                    <Row gutter={10}>
                      <Col xs={12} sm={12} md={8}>
                        <Form.Item
                          name="nombre_contratista"
                          label="Nombre del Contratista"
                        >
                          <Input allowClear disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={12} sm={12} md={8}>
                        <Form.Item
                          name="rfc"
                          label="RFC"
                        >
                          <Input allowClear disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={12} sm={12} md={8}>
                        <Form.Item
                          name="clave_contratista"
                          label="Clave del Contratista"
                        >
                          <Input allowClear disabled />
                        </Form.Item>
                      </Col>
                      <Direccion
                        useParentID={false}
                        currentURI={baseURI}
                        setLoading={setLoading}
                        setFormAddress={setFormAddressContratista}
                        parentID={setCurrentTabKey}
                        setCurrentTabKey={setCurrentTabKey}
                        mutable={!disabled}
                      />
                      <Col xs={12} sm={12} md={8}>
                        <Form.Item
                          name="documento_adjudicacion"
                          label="No. de Documento de Adjudicación"
                        >
                          <Input allowClear disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={12} sm={12} md={8}>
                        <Form.Item
                          name="fecha_adjudicacion"
                          label="Fecha de Adjudicación"
                        >
                          <Input allowClear disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={12} sm={12} md={8}>
                        <Form.Item
                          name="no_contrato"
                          label="No. de Contrato"
                        >
                          <Input allowClear disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={12} sm={12} md={8}>
                        <Form.Item
                          name="fecha_contrato"
                          label="Fecha de Contrato"
                        >
                          <Input allowClear disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={12} sm={12} md={8}>
                        <Form.Item
                          name="fecha_inicio_contrato"
                          label="Fecha de Inicio de Contrato"
                        >
                          <Input allowClear disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={12} sm={12} md={8}>
                        <Form.Item
                          name="fecha_inicio_real"
                          label="Fecha de Inicio Real"
                        >
                          <DatePicker allowClear />
                        </Form.Item>
                      </Col>
                      <Col xs={12} sm={12} md={8}>
                        <Form.Item
                          name="fecha_terminacion_contrato"
                          label="Fecha de Terminación de Contrato"
                        >
                          <Input allowClear disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={12} sm={12} md={8}>
                        <Form.Item
                          name="fecha_terminacion_real"
                          label="Fecha de Terminación Real"
                        >
                          <DatePicker allowClear />
                        </Form.Item>
                      </Col>
                      <Col xs={12} sm={12} md={8}>
                        <Form.Item
                          name="aplica_anticipo"
                          label="Aplica anticipo"
                        >
                          <Select allowClear />
                        </Form.Item>
                      </Col>
                      <Col xs={12} sm={12} md={8}>
                        <Form.Item
                          name="procentaje_anticipo"
                          label="Porcentaje de Anticipo"
                        >
                          <Input allowClear disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={12} sm={12} md={8}>
                        <Form.Item
                          name="anticipo_obra"
                          label="Anticipo de Obra"
                        >
                          <Input allowClear disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={12} sm={12} md={8}>
                        <Form.Item
                          name="contatista"
                          label="Contratista"
                        >
                          <Input allowClear disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={12} sm={12} md={8}>
                        <Form.Item
                          name="acreedores_diversos"
                          label="Acreedores Diversos"
                        >
                          <Input allowClear disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={12} sm={12} md={8}>
                        <Form.Item
                          name="deudores_diversos"
                          label="Deudores Diversos"
                        >
                          <Input allowClear disabled />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </TabPane>
                <TabPane
                  tab="Fianzas"
                  key="finanza"
                  forceRender
                >
                  <Table
                    cols={columnsFianza}
                    data={[]}
                    rowSelection={rowSelection}
                    handleOnRowClick={handleOnRowClick}
                    mobileColIndex={0}
                    controls={{
                      onClickAdd: () => onAgregarFinanza(),
                      onClickEdit: () => onEditarFinanza(),
                      onClickDelete: () => onDeleteFinanza(),
                    }}
                  />
                </TabPane>
                <TabPane
                  tab="Pagos"
                  key="pagos"
                  forceRender
                >
                  <PagoObraPublica />
                </TabPane>
                <TabPane
                  tab="Expediente"
                  key="expediente"
                  forceRender
                >
                  {/* <>TODO</>  */}
                </TabPane>
                <TabPane
                  tab="Flujo de Proceso"
                  key="flujo_proceso"
                  forceRender
                >
                  <Table
                    cols={columnsFlujoProceso}
                    data={[]}
                    rowSelection={rowSelection}
                    handleOnRowClick={handleOnRowClick}
                    mobileColIndex={0}
                  />
                </TabPane>
              </Tabs>
            </Card>
          )}
        <Modal
          visible={openModalAgregarObra}
          closable={false}
          maskClosable={!loading}
          keyboard={!loading}
          footer={null}
          title={(
            <FormSubmitControls
              label={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Obra`}
              onFinish={onFinish}
              onCancel={onCancelModalObra}
              loading={loading}
            />
          )}
          onCancel={onCancelModalObra}
          forceRender
        >
          <Form
            form={form}
            layout="vertical"
            name="obra"
            onFinish={onFinish}
            initialValues={{ estados_globales: 1 }}
          >
            <Col span={24}>
              <Form.Item
                name="clave_presupuesta"
                label="Clave Presupuestal"
                rules={rules.required}
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="importe"
                label="Importe"
                rules={rules.required}
              >
                <Input allowClear />
              </Form.Item>
            </Col>
          </Form>
        </Modal>
        <Modal
          visible={openModalAgregarFinanza}
          closable={false}
          maskClosable={!loading}
          keyboard={!loading}
          footer={null}
          title={(
            <FormSubmitControls
              label={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Obra`}
              onFinish={onFinish}
              onCancel={onCancelModaFianza}
              loading={loading}
            />
          )}
          onCancel={onCancelModaFianza}
          forceRender
        >
          <Form
            form={form}
            layout="vertical"
            name="fianza"
            onFinish={onFinish}
            initialValues={{ estados_globales: 1 }}
          >
            <Col span={24}>
              <Form.Item
                name="tipo_fianza"
                label="Tipo de Fianza"
                rules={rules.required}
              >
                <Select allowClear />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="afianzadora"
                label="Afianzadora"
                rules={rules.required}
              >
                <Select allowClear />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="fecha_expedicion"
                label="Fecha de Expedición"
                rules={rules.required}
              >
                <DatePicker allowClear />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="no_fianza"
                label="No de Fianza"
                rules={rules.required}
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="monto_fianza"
                label="Monto de Fianza"
                rules={rules.required}
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="motivo_fianza"
                label="Motivo de Fianza"
                rules={rules.required}
              >
                <Select allowClear />
              </Form.Item>
              <Col span={24}>
                <Form.Item
                  name="contratante"
                  label="Contratante"
                  rules={rules.required}
                >
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="beneficiario"
                  label="Beneficiario"
                  rules={rules.required}
                >
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="non_contrato_afianzado"
                  label="No. de Contrato Afianzado"
                  rules={rules.required}
                >
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="observaciones"
                  label="Observaciones"
                >
                  <Input.TextArea allowClear />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="estado"
                  label="Estado"
                >
                  <Input allowClear disabled defaultValue="Activa" />
                </Form.Item>
              </Col>
            </Col>
          </Form>
        </Modal>
      </Spin>
    </Row>
  );
}

export default ObraPublica;
