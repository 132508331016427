/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import {
  BankOutlined,
} from '@ant-design/icons';
import blank from 'routes/blank';
import AuxiliaresContables from 'views/Contabilidad/AuxiliaresContables';
import ClavesContables, { permissionCuentaContable } from 'views/Contabilidad/ClavesContables';
import LibrosMayores from 'views/Contabilidad/ReportesGubernamentales/OtrosReportes/LibrosMayores';
import LibrosDiarios from 'views/Contabilidad/ReportesGubernamentales/OtrosReportes/LibrosDiarios';

import Polizas, { permissionPoliza } from 'views/Configuracion/Contabilidad/Polizas';
import BalanzaDeComprobacion, { permissionBalanzaDeComprobacion } from 'views/Contabilidad/BalanzaDeComprobacion';
import Programas, { permissionPrograma } from 'views/Contabilidad/Programas';
import FichaDeDeposito from 'views/Configuracion/Tesoreria/FichaDeDeposito';

export default {
  title: 'Contabilidad',
  icon: () => <BankOutlined />,
  showInSidebar: true,
  children: [
    {
      title: 'Programas',
      icon: null,
      component: Programas,
      type: 'private',
      showInSidebar: true,
      ...permissionPrograma,
    },
    {
      title: 'Repositorio De Pólizas',
      icon: null,
      component: Polizas,
      type: 'private',
      showInSidebar: true,
      ...permissionPoliza,
    },
    {
      title: 'Catálogo Contable',
      icon: null,
      component: ClavesContables,
      type: 'private',
      showInSidebar: true,
      ...permissionCuentaContable,
    },
    {
      title: 'Balanza de Comprobación',
      icon: null,
      component: BalanzaDeComprobacion,
      type: 'private',
      showInSidebar: true,
      ...permissionBalanzaDeComprobacion,
    },
    {
      title: 'Fichas De Deposito',
      icon: null,
      component: FichaDeDeposito,
      type: 'private',
      showInSidebar: true,
    },
    {
      title: 'Auxiliares Contables',
      icon: null,
      component: AuxiliaresContables,
      type: 'private',
      showInSidebar: true,
    },
    {
      title: 'Libro Diario',
      icon: null,
      component: LibrosDiarios,
      type: 'private',
      showInSidebar: true,
    },
    {
      title: 'Libro Mayor',
      icon: null,
      component: LibrosMayores,
      type: 'private',
      showInSidebar: true,
    },
    {
      title: 'Reportes Conac',
      icon: null,
      showInSidebar: true,
      children: [
        {
          title: 'Conciliacion Contable-Presupuestal',
          icon: null,
          component: blank,
          type: 'private',
          showInSidebar: true,
        },
      ],
    },
    {
      title: 'Reportes Disciplina Financiera',
      icon: null,
      component: blank,
      type: 'private',
      showInSidebar: true,
    },
    {
      title: 'Cuenta Pública',
      icon: null,
      component: blank,
      type: 'private',
      showInSidebar: true,
    },
  ],
};
