/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { FundProjectionScreenOutlined, UnorderedListOutlined } from '@ant-design/icons';

// Componentes de las rutas
import Fianzas, { permissionAfianzadora } from 'views/Configuracion/Catalogos/ObraPublica/Fianzas';
import TipoDeFianzas, { permissionTipoDeFianza } from 'views/Configuracion/Catalogos/ObraPublica/TipoDeFianzas';
import TipoDeConvenios, { permissionTipoDeConvenio } from 'views/Configuracion/Catalogos/ObraPublica/TipoDeConvenios';
import ConceptoPenalizaciones from 'views/Configuracion/Catalogos/ObraPublica/ConceptoPenalizaciones';
import TipoDeEstimaciones, { permissionTipoDeEstimacion } from 'views/Configuracion/Catalogos/ObraPublica/TipoDeEstimaciones';
import TipoDePago, { permissionTipoDePago } from 'views/Configuracion/Catalogos/ObraPublica/TipoDePago';
import TipoDeObra, { permissionTipoDeObra } from 'views/Configuracion/Catalogos/ObraPublica/TipoDeObra';
import ModalidadDeEjecucion, { permissionModalidadEjecucion } from 'views/Configuracion/Catalogos/ObraPublica/ModalidadDeEjecucion';
import SituacionDeObra, { permissionSituacionDeObra } from 'views/Configuracion/Catalogos/ObraPublica/SituacionDeObra';
import ProgramaGeneralDeObra, { permissionProgramaGeneralDeObra } from 'views/Configuracion/Catalogos/ObraPublica/ProgramaGeneralDeObra';
import ObraPublica from 'views/ObraPublica/ObraPublica';
import blank from './blank';
import Administracion from '../views/Configuracion/Administracion';

export default {
  title: 'Obra Pública',
  icon: () => <FundProjectionScreenOutlined />,
  showInSidebar: true,
  type: 'private',
  children: [
    {
      title: 'Contratistas',
      icon: null,
      showInSidebar: true,
      component: Administracion,
      type: 'private',
    },
    {
      title: 'Programa General de Obra',
      icon: null,
      component: ProgramaGeneralDeObra,
      showInSidebar: true,
      type: 'private',
      ...permissionProgramaGeneralDeObra,
    },
    {
      title: 'Obras',
      icon: null,
      showInSidebar: true,
      component: ObraPublica,
      type: 'private',
    },
    {
      title: 'Contratos',
      icon: null,
      component: blank,
      showInSidebar: true,
      type: 'private',
    },
    {
      title: 'Convenios',
      icon: null,
      component: blank,
      showInSidebar: true,
      type: 'private',
      children: [
        {
          title: 'Programas',
          icon: null,
          showInSidebar: true,
          component: null,
          type: 'private',
        },
        {
          title: 'Obras',
          icon: null,
          showInSidebar: true,
          component: null,
          type: 'private',
        },
        {
          title: 'Contratos',
          icon: null,
          component: null,
          showInSidebar: true,
          type: 'private',
        },
        {
          title: 'Convenios',
          icon: null,
          component: null,
          showInSidebar: true,
          type: 'private',
        },
      ],
    },
    {
      title: 'Pagos',
      icon: null,
      showInSidebar: true,
      type: 'private',
      children: [
        {
          title: 'Anticipos',
          icon: null,
          component: null,
          showInSidebar: true,
          type: 'private',
        },
        {
          title: 'Estimaciones',
          icon: null,
          component: null,
          showInSidebar: true,
          type: 'private',
        },
        {
          title: 'Liquidaciones',
          icon: null,
          component: null,
          showInSidebar: true,
          type: 'private',
        },
      ],
    },
    {
      title: 'Contratistas',
      icon: null,
      showInSidebar: true,
      component: Administracion,
      type: 'private',
      permissionModel: 'contratista',
      catalogsModels: [
        'formadepago',
        'clasificaciondeobjetodegastointerno',
        'estratificacion',
        'giroempresarial',
      ],
    },
    {
      title: 'Expediente Digital',
      icon: null,
      component: null,
      showInSidebar: true,
      type: 'private',
    },
    {
      title: 'Reportes',
      icon: null,
      component: null,
      showInSidebar: true,
      type: 'private',
    },
    {
      title: 'Catálogos',
      icon: () => <UnorderedListOutlined />,
      showInSidebar: true,
      type: 'private',
      children: [
        {
          title: 'Afianzadoras',
          icon: null,
          component: Fianzas,
          showInSidebar: true,
          type: 'private',
          ...permissionAfianzadora,
        },
        {
          title: 'Modalidad De Ejecución',
          icon: null,
          component: ModalidadDeEjecucion,
          showInSidebar: true,
          type: 'private',
          ...permissionModalidadEjecucion,
        },
        {
          title: 'Situación De Obra',
          icon: null,
          component: SituacionDeObra,
          showInSidebar: true,
          type: 'private',
          ...permissionSituacionDeObra,
        },
        {
          title: 'Tipo de Concepto de Penalización',
          icon: null,
          component: ConceptoPenalizaciones,
          showInSidebar: true,
          type: 'private',
        },
        {
          title: 'Tipo De Convenio',
          icon: null,
          component: TipoDeConvenios,
          showInSidebar: true,
          type: 'private',
          ...permissionTipoDeConvenio,
        },
        {
          title: 'Tipo De Estimaciones',
          icon: null,
          component: TipoDeEstimaciones,
          showInSidebar: true,
          type: 'private',
          ...permissionTipoDeEstimacion,
        },
        {
          title: 'Tipo De Fianzas',
          icon: null,
          component: TipoDeFianzas,
          showInSidebar: true,
          type: 'private',
          ...permissionTipoDeFianza,
        },
        {
          title: 'Tipo De Obra',
          icon: null,
          component: TipoDeObra,
          showInSidebar: true,
          type: 'private',
          ...permissionTipoDeObra,
        },
        {
          title: 'Tipo De Pago',
          icon: null,
          component: TipoDePago,
          showInSidebar: true,
          type: 'private',
          ...permissionTipoDePago,
        },

      ],
    },

  ],
};
