/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Form,
  Button,
  Row,
  Col,
  Spin,
  message,
  Card,
  Modal,
} from 'antd';

import Table from 'components/Table';
import FormSubmitControls from 'components/FormSubmitControls';
import ModalDelete from 'components/ModalDelete';
import Select from 'components/Select';
import API from 'utils/api';
import { onError, onSuccess } from 'utils/handlers';
import { isMutable } from 'utils/estadosGlobales';
import { InputSearchPro } from 'components/InputSearch';
import Pasos from './Pasos';

export const permissionConfiguracionDeFlujo = {
  permissionModel: 'configuraciondeflujodeprocesooperativo',
};

const baseURI = 'configuraciones/configuracion-flujo-de-procesos-operativos/';
const uris = {
  1: '/unidades-operativas/',
  2: '/unidades-responsables/',
  3: '/centros-de-costos/',
};

const levelSorter = (a, b) => a.nivel - b.nivel;

// eslint-disable-next-line react/prop-types
function ConfiguracionDePasos({ permission }) {
  const { estadosGlobales } = useSelector((state) => state.catalogs);
  const urs = useSelector(({ catalogs }) => catalogs.urs).sort(levelSorter);
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);
  const [mutable, setMutable] = useState(true);

  const [modulos, setModulos] = useState([]);
  const [documentos, setDocumentos] = useState([]);
  const [folios, setFolios] = useState([]);

  const [ursFound, setUrsFound] = useState([]);
  const [urContentType, setUrContentType] = useState();
  const [actividades, setActividades] = useState([]);
  const [roles, setRoles] = useState([]);
  const [usuarios, setUsuarios] = useState([]);
  const [filteredDocs, setFilteredDocs] = useState([]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await API.get(baseURI);
      setData(res.data);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    const fetchAll = async () => {
      try {
        setLoading(true);
        const resModulos = await API.get('catalogos/catalogos-de-modulos/');
        setModulos(resModulos.data || []);

        const resDocs = await API.get('catalogos/catalogos-de-documentos/');
        setDocumentos(resDocs.data || []);

        const resFolios = await API.get('/configuraciones/configuraciones-de-folios/');
        setFolios(resFolios.data || []);

        const resActividades = await API.get('catalogos/actividad/');
        setActividades(resActividades.data || []);
        const resRoles = await API.get('estructura-organizacional/grupos/');
        const formatedData = resRoles.data.map((item) => ({
          ...item,
          name: item.name.split('-').shift(),
        }));
        setRoles(formatedData);

        const resUsers = await API.get('/usuarios/usuarios/');
        const mappedData = resUsers.data.map((e) => ({
          ...e,
          descripcion: `${e?.email} - ${e?.first_name || ''} ${e?.last_name || ''} ${e?.second_last_name || ''}`,
        }));
        setUsuarios(mappedData);

        await fetchData();
        setLoading(false);
      } catch (err) {
        onError(err, setLoading);
      }
    };
    fetchAll();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    setSelectedRowKeys([]);
    setUrsFound([]);
    setUrContentType();
    setMutable(true);
  };

  const getPasos = async () => {
    try {
      setLoading(true);
      const [configuracion_de_flujo_de_proceso_operativo] = selectedRowKeys;
      const response = await API.get('configuraciones/pasos-de-configuraciones-del-flujo/', {
        params: { configuracion_de_flujo_de_proceso_operativo },
      });
      if (response?.status === 200) {
        return response.data;
      }
      setLoading(false);
      return [];
    } catch (err) {
      onError(err, setLoading);
      return [];
    }
  };

  const onFinish = async (_, _continue) => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      values.content_type = urContentType;
      if (!selectedRowKeys.length) {
        const response = await API.post(baseURI, values);
        if (response?.status === 201) {
          if (!_continue) {
            onSuccess(response, 'Agregado correctamente');
            onCancel();
            await fetchData();
          } else {
            await fetchData();
            setSelectedRowKeys([response.data.id]);
          }
          return response.data;
        }
      } else {
        const [key] = selectedRowKeys;
        const response = await API.put(`${baseURI}${key}/`, values);
        if (response?.status === 200) {
          if (!_continue) {
            onSuccess(response, 'Actualizado correctamente');
            onCancel();
            await fetchData();
          } else {
            await fetchData();
            setSelectedRowKeys([response.data.id]);
          }
          return response.data;
        }
      }
      setLoading(false);
      return null;
    } catch (err) {
      onError(err, setLoading);
      return null;
    }
  };

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    form.setFieldsValue(record);
    setMutable(isMutable(record));
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const onChangeModulo = (val, reset = true) => {
    if (val) {
      const filterData = documentos.filter((e) => e.id === val);
      setFilteredDocs(filterData);
      if (reset) {
        form.resetFields(['documento']);
      }
    }
  };

  const onClickEdit = async (response) => {
    try {
      setLoading(true);
      const [key] = selectedRowKeys;
      const clone = [...data];
      const match = typeof response === 'object' ? response : clone.find((e) => e.id === key);
      if (match?.content_type) {
        setUrContentType(match.content_type);
        const { nivel } = urs.find((e) => e.id === match.content_type);
        const resUR = await API
          .get(`/estructura-organizacional${uris[nivel]}${match.object_id}`);
        setUrsFound([resUR.data]);
      }
      onChangeModulo(match.modulo, false);
      setLoading(false);
      setVisible(true);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onClickDelete = () => {
    if (mutable) {
      setVisibleAlert(true);
    } else {
      message.info('No se pueden eliminar los Pasos autorizados');
    }
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`${baseURI}${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          onCancel();
          setVisibleAlert(false);
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const columns = [
    {
      titleText: 'Módulo',
      dataIndex: 'modulo',
      key: 'modulo',
      width: 140,
      render: (id) => modulos.find((e) => e.id === id)?.descripcion,
    },
    {
      titleText: 'Documento',
      dataIndex: 'documento',
      key: 'documento',
      width: 130,
      render: (id) => documentos.find((e) => e.id === id)?.descripcion,
    },
    {
      titleText: 'Configuración de Folio',
      dataIndex: 'foliador',
      key: 'foliador',
      width: 200,
      render: (id) => folios.find((e) => e.id === id)?.nombre,
    },
    {
      titleText: 'Estado',
      dataIndex: 'estados_globales',
      key: 'estados_globales',
      width: 120,
      render: (id) => estadosGlobales.find((e) => e.id === id)?.descripcion,
    },
  ];

  const busquedaUR = async () => {
    try {
      setLoading(true);
      await form.validateFields(['content_type', 'q_ur']);
      const { content_type, q_ur: q } = form.getFieldsValue();
      setUrContentType(content_type);
      const { nivel } = urs.find((e) => e.id === content_type);
      const response = await API.get(`/estructura-organizacional${uris[nivel]}`, {
        params: {
          clave: q,
          estados_globales: 4,
        },
      });

      if (response?.status === 200 && response?.data?.length) {
        form.setFieldsValue({
          object_id: response.data[0].id,
        });
        setUrsFound([response.data[0]]);
      } else if (response?.status === 204) {
        message.info('No se encontraron coincidencias');
        setUrsFound([]);
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const resetAndValidateURs = () => {
    setUrsFound([]);
    form.resetFields(['object_id']);
    form.validateFields(['object_id', 'q_ur']);
  };

  function Formulario({ modal }) {
    let props;
    let inpSearchProps;
    if (modal) {
      inpSearchProps = {
        md: 24,
      };
      props = {
        span: 24,
      };
    } else {
      props = {
        xs: 24,
        sm: 24,
        md: 8,
      };
      inpSearchProps = {
        xs: 24,
        sm: 24,
        md: 16,
      };
    }
    return (
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        scrollToFirstError
        initialValues={{ estados_globales: 1 }}
      >
        <Row gutter={10}>
          <Col {...props}>
            <Form.Item
              name="modulo"
              rules={rules.required}
              label="Módulo"
            >
              <Select
                dataSource={modulos}
                onChange={onChangeModulo}
                disabled={!mutable}
              />
            </Form.Item>
          </Col>
          <Col {...props}>
            <Form.Item
              name="documento"
              rules={rules.required}
              label="Documento"
            >
              <Select dataSource={filteredDocs} disabled={!mutable} />
            </Form.Item>
          </Col>
          <Col {...props}>
            <Form.Item
              name="foliador"
              rules={rules.required}
              label="Foliador"
            >
              <Select dataSource={folios} labelProp="nombre" disabled={!mutable} />
            </Form.Item>
          </Col>

          <InputSearchPro
            label="Unidad responsable"
            tooltip="Búsqueda por clave o descripción"
            name="q_ur"
            rules={rules.required}
            onPressEnter={() => busquedaUR()}
            prefix={{
              style: {
                marginTop: 'auto',
                width: '45%',
                minWidth: 180,
              },
              rules: rules.required,
              name: 'content_type',
              label: 'Nivel',
              render: (e) => e.model,
              dataSource: urs,
              onChange: resetAndValidateURs,
            }}
            breakPoints={inpSearchProps}
            inputProps={{
              onPressEnter: () => busquedaUR(),
              onChange: ({ target: { value } }) => {
                if (!value) {
                  resetAndValidateURs();
                }
              },
            }}
            resultProps={{
              rules: rules.required,
              name: 'object_id',
              label: 'Unidad responsable',
              dataSource: ursFound,
              keyLabelRender: true,
              onClear: resetAndValidateURs,
              disabled: !mutable,
              breakPoints: inpSearchProps,
            }}
          />

          <Col {...props}>
            <Form.Item
              name="estados_globales"
              rules={rules.required}
              label="Estado"
              hasFeedback
            >
              <Select
                disabled={!selectedRowKeys.length || !mutable}
                globalStates
              />
            </Form.Item>
          </Col>

          {!modal && (
            <Pasos
              actividades={actividades}
              disabled={!mutable}
              parentId={selectedRowKeys}
              roles={roles}
              ur={ursFound[0]}
              urContentType={urContentType}
              usuarios={usuarios}
            />
          )}
          <Form.Item hidden>
            <Button htmlType="submit" />
          </Form.Item>
        </Row>
      </Form>
    );
  }

  Formulario.propTypes = {
    modal: PropTypes.bool,
  };

  Formulario.defaultProps = {
    modal: false,
  };

  const normalizeDataAuthorized = (estados_globales) => {
    const [key] = selectedRowKeys;
    const normalized = [...data]
      .map((e) => (e.id === key ? { ...e, estados_globales } : e));
    setMutable(false);
    setData(normalized);
    const match = normalized.find((e) => e.id === key);
    form.setFieldsValue(match);
    onClickEdit(match, true);
  };

  const validateAuthorizable = async () => {
    try {
      if (selectedRowKeys.length) {
        setLoading(true);
        const results = await getPasos();
        if (results.length) {
          return true;
        }
        message.warn('Para autorizar es necesario agregar pasos');
        setLoading(false);
        return false;
      }
      setLoading(false);
      return false;
    } catch (err) {
      onError(err, setLoading);
      return false;
    }
  };

  return (
    <Row id="presupuestos-segmentos" className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!(visible && selectedRowKeys.length) && (
          <Table
            cols={columns}
            data={data}
            permission={permission}
            rowSelection={rowSelection}
            handleOnRowClick={handleOnRowClick}
            controls={{
              onClickAdd,
              onClickEdit,
              onClickDelete,
            }}
            mobileColIndex={0}
          />
        )}
        {(visible && !!selectedRowKeys.length) && (
          <Card
            className="form-container"
            title={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Configuración de Pasos`}
            extra={(
              <FormSubmitControls
                allowAuthorize
                allowCancel
                allowSaveAndContinue
                baseURI={baseURI}
                callback={normalizeDataAuthorized}
                loading={loading}
                mutable={mutable}
                onCancel={onCancel}
                onFinish={onFinish}
                selectedRowKeys={selectedRowKeys}
                validateAuthorizable={validateAuthorizable}
              />
            )}
            bordered={false}
          >
            <Formulario />
          </Card>
        )}
      </Spin>
      {!selectedRowKeys.length && (
        <Modal
          visible={visible && !selectedRowKeys.length}
          title={(
            <FormSubmitControls
              label={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Configuración de Pasos`}
              onFinish={onFinish}
              onCancel={onCancel}
              loading={loading}
            />
          )}
          closable={false}
          maskClosable={!loading}
          keyboard={!loading}
          onCancel={onCancel}
          footer={null}
          forceRender
        >
          <Formulario modal />
        </Modal>
      )}
      <ModalDelete
        onDelete={deleteItem}
        onCancel={() => setVisibleAlert(false)}
        visible={visibleAlert}
        content="Configuración de pasos"
        loading={loading}
      />
    </Row>
  );
}

export default ConfiguracionDePasos;
