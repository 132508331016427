import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Form,
  Button,
  Row,
  Col,
  Spin,
  message,
  Modal,
} from 'antd';

import Table from 'components/Table';
import FormSubmitControls from 'components/FormSubmitControls';
import API from 'utils/api';
import {
  onError, onSuccess,
} from 'utils/handlers';
import Select from 'components/Select';
import {
  formatReceived,
} from 'utils/formatValues';
import ModalDelete from 'components/ModalDelete';
import { isMutable } from 'utils/estadosGlobales';

export const permissionPieDocumento = {
  permissionModel: 'piededocumento',
};

const baseURI = '/configuraciones/pies-de-documentos/';

// eslint-disable-next-line react/prop-types
function ConfiguracionDePie({ permission }) {
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);
  const [mutable, setMutable] = useState(true);
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);

  const [documentos, setDocumentos] = useState([]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await API.get(baseURI);
      setData(formatReceived(response.data) || []);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const fetchAll = async () => {
    try {
      setLoading(true);

      const resDoc = await API.get('/configuraciones/documentos-operativos-administrador/');
      setDocumentos(resDoc.data || []);

      await fetchData();
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchAll();

    return () => API.source.cancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setVisible(false);
    setSelectedRowKeys([]);
    form.resetFields();
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();

      if (!selectedRowKeys.length) {
        const response = await API.post(baseURI, values);
        if (response?.status === 201) {
          onSuccess(response.data, 'Agregado correctamente');
          onCancel();
          await fetchData();
        }
      } else {
        const [key] = selectedRowKeys;
        const response = await API.put(`${baseURI}${key}/`, values);
        if (response?.status === 200) {
          onSuccess(response, 'Actualizado correctamente');
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    setMutable(isMutable(record));
    form.setFieldsValue({
      ...formatReceived(record),
    });
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const columns = [
    {
      titleText: 'Documento',
      dataIndex: 'documento',
      key: 'documento',
      width: 100,
      render: (e) => documentos.find((i) => i.id === e)?.descripcion,
    },
    {
      titleText: '¿Requiere número de página?',
      dataIndex: 'requiere_numero_de_pagina',
      key: 'requiere_numero_de_pagina',
      width: 100,
      render: (e) => (e ? 'Si' : 'No'),
    },
    {
      titleText: '¿Requiere hora de impresión?',
      dataIndex: 'requiere_hora_de_impresion',
      key: 'requiere_hora_de_imprecion',
      width: 100,
      render: (e) => (e ? 'Si' : 'No'),
    },
    {
      titleText: '¿Requiere fecha de impresión?',
      dataIndex: 'requiere_fecha_de_imprecion',
      key: 'requiere_fecha_de_imprecion',
      width: 100,
      render: (e) => (e ? 'Si' : 'No'),
    },

  ];

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const onClickEdit = (record) => {
    if (data?.id) {
      form.setFieldsValue(record);
    }
    setVisible(true);
  };

  const onClickDelete = () => {
    if (mutable) {
      setVisibleAlert(true);
    } else {
      message.warn('No se puede eliminar un registro autorizado o cancelado');
      setSelectedRowKeys([]);
      setVisibleAlert(false);
    }
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`${baseURI}${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          setVisibleAlert(false);
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const rules = {
    required: [
      {
        message: 'El campo es requerido',
        required: true,
      },
    ],
  };

  return (
    <Row align="center" justify="center" className="container">
      <Spin tip="Cargando..." spinning={loading}>
        <Table
          cols={columns}
          data={data}
          rowSelection={rowSelection}
          rowKey="id"
          permission={permission}
          mobileColIndex={0}
          handleOnRowClick={handleOnRowClick}
          controls={{
            onClickAdd,
            onClickEdit,
            onClickDelete,
          }}
          allowExpand
        />
        <Modal
          visible={visible}
          title={(
            <FormSubmitControls
              label={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Configuración de Píe`}
              onFinish={onFinish}
              onCancel={onCancel}
              loading={loading}
            />
        )}
          onCancel={onCancel}
          footer={null}
          closable={false}
          maskClosable={!loading}
          keyboard={!loading}
          forceRender
        >
          <Form
            layout="vertical"
            onFinish={onFinish}
            form={form}
            scrollToFirstError
            initialValues={{
              requiere_numero_de_pagina: true,
              requiere_hora_de_impresion: true,
              requiere_fecha_de_imprecion: true,
              estados_globales: 1,
            }}
          >
            <Row gutter={10}>
              <Col span={24}>
                <Form.Item
                  name="documento"
                  hasFeedback
                  rules={rules.required}
                  label="Documento"
                >
                  <Select dataSource={documentos} disabled={!mutable} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="requiere_numero_de_pagina"
                  hasFeedback
                  rules={rules.required}
                  label="¿Requiere número de página?"
                >
                  <Select trueFalse disabled={!mutable} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="requiere_hora_de_impresion"
                  hasFeedback
                  rules={rules.required}
                  label="¿Requiere hora de impresión?"
                >
                  <Select trueFalse disabled={!mutable} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="requiere_fecha_de_imprecion"
                  hasFeedback
                  rules={rules.required}
                  label="¿Requiere fecha de impresión?"
                >
                  <Select trueFalse disabled={!mutable} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="estados_globales"
                  hasFeedback
                  rules={rules.required}
                  label="Estado"
                >
                  <Select
                    dataSource={estadosGlobales}
                    disabled={!selectedRowKeys.length || !mutable}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item hidden>
              <Button htmlType="submit" />
            </Form.Item>
          </Form>
        </Modal>
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => setVisibleAlert(false)}
          visible={visibleAlert}
          content="la configuración seleccionada"
          loading={loading}
        />
      </Spin>
    </Row>
  );
}

export default ConfiguracionDePie;
