import React from 'react';
import SingleForm from 'components/SingleForm';

export const permissionTipoDePago = {
  permissionModel: 'tipodepago',
};

// eslint-disable-next-line react/prop-types
function TipoDePago({ permission }) {
  return (
    <SingleForm
      permission={permission}
      baseURI="obrapublica/tipo-de-pago/"
      title="Tipo De Pago"
    />
  );
}

export default TipoDePago;
