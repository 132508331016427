/* eslint-disable consistent-return */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { Col, Button, message } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import axios from 'axios';

const capitalizeFirstLetter = ([first, ...rest]) => [first.toUpperCase(), ...rest].join('');

export const onError = (err, setLoading = null, formsInstances = [], setCurrentTabKey) => {
  // eslint-disable-next-line no-console
  console.error(err);
  if (!axios.isCancel(err)) {
    if (typeof setLoading === 'function') {
      setLoading(false);
    }
    if (err?.response?.data?.detail) {
      const { detail } = err.response.data;
      return message.warn(Array.isArray(detail) ? `${detail.join(', ').replace('.', '')}.` : detail);
    }
    if (err?.response?.data
        && err?.response?.headers['content-type'].includes('application/json')) {
      const keys = Object.keys(err.response.data);
      let normalized = keys.map((key) => {
        let errors = '';
        if (typeof err.response.data[key] === 'object') {
          errors = `${Object.values(err.response.data[key]).flat().join(', ').replace('.', '')}.`;
        } else if (Array.isArray(err.response.data[key])) {
          errors = `${err.response.data[key].join(', ').replace('.', '')}.`;
        } else if (typeof err.response.data[key] === 'string') {
          errors = err.response.data[key];
        }
        return ({
          prop: capitalizeFirstLetter(key.replace(/_/g, ' ')),
          errors,
        });
      });
      normalized = normalized.filter((i) => i.errors.length);
      if (normalized.length) {
        return message.warn({
          content: (
            <Col>
              <strong>Solucione las siguientes advertencias</strong>
              <br />
              {normalized.map((item) => (
                <React.Fragment key={item.prop}>
                  <p style={{ margin: 0, display: 'block', textAlign: 'left' }}>
                    <strong style={{ marginRight: 3 }}>
                      {item.prop}
                      :
                    </strong>
                    {item.errors}
                  </p>
                </React.Fragment>
              ))}
              <Button
                icon={<CloseCircleOutlined />}
                onClick={() => message.destroy()}
                type="link"
                style={{
                  position: 'absolute',
                  top: -30,
                  right: -20,
                }}
              />
            </Col>
          ),
          duration: 30,
        });
      }
    } else if (!err?.errorFields) {
      return message.warn(err?.message && err?.response?.config?.baseURL?.includes('localhost')
        ? err.message : 'Ha ocurrido un error');
    } else if (err.errorFields) {
      const forms = formsInstances.map(({ __INTERNAL__, getFieldsError }) => ({
        tabKey: __INTERNAL__?.name,
        keys: getFieldsError()?.filter((e) => e?.errors?.length)?.map((e) => e.name[0]),
      }));
      const errorKeys = err.errorFields.map((e) => e.name[0]);
      const formWithErrors = forms.find((f) => f.keys.some((k) => errorKeys.includes(k)));
      if (formWithErrors) {
        setTimeout(() => {
          if (typeof setCurrentTabKey === 'function') {
            setCurrentTabKey(formWithErrors.tabKey);
          }
        });
      }
    }
  }
};

export const onSuccess = (res = {}, msg = 'Acción/Operación realizada correctamente', duration = 2.5) => {
  message.destroy();
  if (res?.data) {
    return message.info(res.data.detail || msg, duration);
  }
  return message.info(msg, duration);
};

export const onInfo = (mensaje, duration = 3.8) => {
  message.destroy();

  return message.info(mensaje, duration);
};

export const onWarning = (mensaje, duration = 3.8) => {
  message.destroy();

  return message.warning(mensaje, duration);
};
