import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Spin,
  Modal,
} from 'antd';

import API from 'utils/api';
import { onError, onSuccess } from 'utils/handlers';
import Table from 'components/Table';
import FormSubmitControls from 'components/FormSubmitControls';
import ModalDelete from 'components/ModalDelete';
import Select from 'components/Select';

const baseURI = 'presupuestos/supuesto-matriz-del-indicador/';

function Supuestos({
  selectedRowKey: mir,
}) {
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      setLoading(true);
      if (mir) {
        const response = await API.get(baseURI, {
          params: {
            mir,
          },
        });
        if (response?.status === 200) {
          setData(response.data);
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchData();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, [mir]);

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    setSelectedRowKeys([]);
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      if (mir) {
        if (!selectedRowKeys.length) {
          values.mir = mir;
          const response = await API.post(baseURI, values);
          if (response.status === 201) {
            onSuccess(response, 'Agregado Correctamente');
            await fetchData();
            onCancel();
          }
        } else {
          const [key] = selectedRowKeys;
          const response = await API.put(`${baseURI}${key}/`, values);
          if (response.status === 200) {
            onSuccess(response, 'Editado Correctamente');
            await fetchData();
            onCancel();
          }
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading, [form]);
    }
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`${baseURI}${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          setVisibleAlert(false);
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
  };

  const columns = [
    {
      titleText: 'Descripción',
      dataIndex: 'descripcion',
      key: 'descripcion',
      width: 300,
    },
    {
      titleText: 'Estado',
      dataIndex: 'estados_globales',
      key: 'estados_globales',
      width: 160,
      render: (id) => estadosGlobales.find((eg) => eg.id === id)?.descripcion,
    },
  ];

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    setTimeout(() => {
      form.setFieldsValue(record);
    });
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const onClickEdit = () => {
    setVisible(true);
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        <Table
          cols={columns}
          data={data}
          rowSelection={rowSelection}
          handleOnRowClick={handleOnRowClick}
          controls={{
            onClickAdd,
            onClickEdit,
            onClickDelete,
          }}
        />
        <Modal
          visible={visible}
          title={(
            <FormSubmitControls
              onFinish={onFinish}
              onCancel={onCancel}
              oading={loading}
              label={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Supuesto`}
            />
          )}
          closable={false}
          maskClosable={!loading}
          keyboard={!loading}
          footer={null}
          forceRender
        >
          <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
            scrollToFirstError
            initialValues={{ estados_globales: 1 }}
          >
            <Col span={24}>
              <Form.Item
                name="descripcion"
                rules={rules.required}
                label="Descripción"
                hasFeedback
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Estado"
                name="estados_globales"
              >
                <Select
                  dataSource={estadosGlobales}
                  disabled={!selectedRowKeys.length}
                />
              </Form.Item>
            </Col>
            <Form.Item hidden>
              <Button htmlType="submit" />
            </Form.Item>
          </Form>
        </Modal>
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => setVisibleAlert(false)}
          visible={visibleAlert}
          content="eliminar el supuesto seleccionado"
          loading={loading}
        />
      </Spin>
    </Row>
  );
}

Supuestos.propTypes = {
  selectedRowKey: PropTypes.number,
};

Supuestos.defaultProps = {
  selectedRowKey: 0,
};

export default Supuestos;
