import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Spin,
  // Modal,
  Card,
  message,
  Typography,
} from 'antd';

import API from 'utils/api';
import { onError, onSuccess } from 'utils/handlers';
import Table from 'components/Table';
import FormSubmitControls from 'components/FormSubmitControls';
import ModalDelete from 'components/ModalDelete';
import Select from 'components/Select';
import createClave from 'utils/keyGenerator';
import { isMutable, isAuthorized } from 'utils/estadosGlobales';
import _data from 'mocks/conceptos';

function Conceptos() {
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);

  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);
  const [mutable, setMutable] = useState(true);

  const fetchData = async () => {
    try {
      setLoading(true);
      setData(_data);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchData();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    setSelectedRowKeys([]);
    setMutable(true);
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      // eslint-disable-next-line no-console
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          setVisibleAlert(false);
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    setMutable(isMutable(record));
    setTimeout(() => form.setFieldsValue(record));
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickAdd = () => {
    onCancel();
    createClave(data, form);
    setVisible(true);
  };

  const onClickEdit = () => setVisible(true);

  const onClickDelete = () => {
    const [key] = selectedRowKeys;
    const match = data.find((e) => e.id === key);
    if (isMutable(match)) {
      setVisibleAlert(true);
    } else if (isAuthorized(match)) {
      message.warn('No se puede eliminar un registro autorizado');
    } else {
      message.warn('No se puede eliminar un registro cancelado');
    }
  };

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
  };

  const columns = [
    {
      titleText: 'Clave',
      dataIndex: 'clave',
      key: 'clave',
      width: 100,
    },
    {
      titleText: 'Concepto',
      dataIndex: 'concepto',
      key: 'concepto',
      width: 350,
    },
    {
      titleText: 'CRI',
      dataIndex: 'clasificador_rubro_de_ingresos',
      key: 'clasificador_rubro_de_ingresos',
      width: 220,
    },
    {
      titleText: 'Unidad Responsable',
      dataIndex: 'unidad_responsable',
      key: 'unidad_responsable',
      width: 220,
    },
    {
      titleText: 'Fuente de Financiamiento',
      dataIndex: 'fuente_financiamiento',
      key: 'fuente_financiamiento',
      width: 250,
    },
    {
      titleText: 'Control Interno',
      dataIndex: 'control_interno',
      key: 'control_interno',
      width: 150,
      align: 'right',
      render: (txt) => (
        <span style={{ color: '#ff4949' }}>
          {txt}
        </span>
      ),
    },
    {
      titleText: 'Fundamento',
      dataIndex: 'fundamento',
      key: 'fundamento',
      width: 200,
    },
    {
      titleText: '¿Causa IVA?',
      dataIndex: 'causa_iva',
      key: 'causa_iva',
      width: 200,
      render: (e) => (e ? 'Si' : 'No'),
    },
    {
      titleText: 'Estado',
      key: 'estados_globales',
      dataIndex: 'estados_globales',
      width: 150,
      render: (val) => estadosGlobales.find((e) => e.id === val)?.descripcion,
    },
    {
      titleText: 'Clave Presupuestal',
      dataIndex: 'clave_presupuestal',
      key: 'clave_presupuestal',
      width: 200,
    },
  ];

  const controls = () => ({
    onClickAdd,
    onClickEdit,
    onClickDelete,
  });

  const getRules = () => {
    if (!mutable) {
      return null;
    }
    return rules.required;
  };

  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible ? (
          <Table
            cols={columns}
            data={data}
            rowSelection={rowSelection}
            handleOnRowClick={handleOnRowClick}
            controls={controls()}
            mobileColIndex={0}
          />
        ) : (
          <Card
            className="form-container"
            title={`${selectedRowKeys.length ? 'Editar' : 'Agregar'}`}
            extra={(
              <FormSubmitControls
                onFinish={mutable ? onFinish : null}
                onCancel={onCancel}
                loading={loading}
                baseURI="a"
                selectedRowKeys={selectedRowKeys}
                allowAuthorize
                allowCancel
                callback={(estados_globales) => {
                  const [key] = selectedRowKeys;
                  const normalized = data.map((e) => {
                    if (e.id === key) {
                      setMutable(false);
                      return {
                        ...e,
                        estados_globales,
                      };
                    }
                    return e;
                  });
                  setData(normalized);
                  const match = normalized.find((e) => e.id === key);
                  onClickEdit(match);
                }}
              />
            )}
            bordered={false}
          >
            <Form
              layout="vertical"
              form={form}
              onFinish={onFinish}
              scrollToFirstError
              initialValues={{
                estados_globales: 1,
              }}
            >
              <Row gutter={10}>
                <Col span={24}>
                  <Typography.Title level={4}>General</Typography.Title>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="afectable"
                    rules={getRules()}
                    label="Afectable"
                    hasFeedback
                  >
                    <Select
                      trueFalse
                      disabled={!mutable}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="es_ingreso"
                    rules={getRules()}
                    label="¿Es ingreso?"
                    hasFeedback
                  >
                    <Select
                      trueFalse
                      disabled={!mutable}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="clasificador_rubro_de_ingresos"
                    rules={getRules()}
                    label="CRI Padre"
                    hasFeedback
                  >
                    <Input
                      disabled={!mutable}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="clave"
                    rules={getRules()}
                    label="Clave"
                    hasFeedback
                  >
                    <Input
                      disabled={!mutable}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="concepto"
                    rules={getRules()}
                    label="Nombre"
                    hasFeedback
                  >
                    <Input
                      disabled={!mutable}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="cri"
                    rules={getRules()}
                    label="CRI"
                    hasFeedback
                  >
                    <Input
                      disabled={!mutable}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="unidad_responsable"
                    rules={getRules()}
                    label="Unidad Responsable"
                    hasFeedback
                  >
                    <Input
                      disabled={!mutable}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="fuente_financiamiento"
                    rules={getRules()}
                    label="Fuente de Financiamiento"
                    hasFeedback
                  >
                    <Input
                      disabled={!mutable}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="aplica_control_interno"
                    rules={getRules()}
                    label=" ¿Aplica Número de control interno?"
                    hasFeedback
                  >
                    <Select
                      trueFalse
                      disabled={!mutable}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="control_interno"
                    rules={getRules()}
                    label="Control Interno"
                    hasFeedback
                  >
                    <Input
                      disabled={!mutable}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="fundamento"
                    label="Fundamento"
                    hasFeedback
                  >
                    <Input
                      disabled={!mutable}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="causa_iva"
                    rules={getRules()}
                    label="¿Causa IVA?"
                    hasFeedback
                  >
                    <Select
                      trueFalse
                      disabled={!mutable}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={16}>
                  <Form.Item
                    name="observaciones"
                    rules={getRules()}
                    label="Observaciones"
                    hasFeedback
                  >
                    <Input.TextArea
                      autoSize={{ minRows: 4, maxRows: 4 }}
                      disabled={!mutable}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="estados_globales"
                    label="Estado"
                    rules={getRules()}
                  >
                    <Select
                      disabled={!selectedRowKeys.length || !mutable}
                      dataSource={estadosGlobales}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item hidden>
                <Button htmlType="submit" />
              </Form.Item>
            </Form>
          </Card>
        )}
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => {
            setVisibleAlert(false);
            setSelectedRowKeys([]);
          }}
          visible={visibleAlert}
          content={`${form.getFieldValue('descripcion')}`}
          loading={loading}
        />
      </Spin>
    </Row>
  );
}

export default Conceptos;
