import React, { useState, useEffect } from 'react';
import {
  Row,
  Spin,
  Card,
  Form,
  Input,
} from 'antd';
import { useSelector } from 'react-redux';
import FormSubmitControls from 'components/FormSubmitControls';
import Table from 'components/Table';
import Select from 'components/Select';
import FormItemLabel from 'components/FormItemLabel';
import API from 'utils/api';
import fetchSome from 'utils/fetchSome';
import { onError } from 'utils/handlers';
import {
  SET_MOMENTOS_PRESUPUESTALES_DE_INGRESO,
  SET_MOMENTOS_PRESUPUESTALES_DE_EGRESO,
  SET_AFECTACIONES_PRESUPUESTALES,
  SET_NATURALEZAS_DE_INGRESO,
  SET_CLASIFICACIONES_DE_CUENTA_CONTABLES,
  SET_TIPOS_CUENTAS_CONTABLES,
} from 'store/reducers/catalogs';
import makeThree from 'utils/makeTree';

export const permissionCuentaContable = {
  permissionModel: 'cuentacontable',
};

const baseURI = 'contabilidad/cuentas-contables/';

// eslint-disable-next-line react/prop-types
function CuentasContables({ permission }) {
  const [form] = Form.useForm();
  const {
    estadosGlobales,
    momentosPresupuestalesDeIngreso,
    momentosPresupuestalesDeEgreso,
    afectacionesPresupuestales,
    naturalezasDeIngreso,
    clasificacionesDeCuentasContables,
    tiposDeCuentasContables,
  } = useSelector((state) => state.catalogs);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selected, setSelected] = useState();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const normalizeData = (_data = []) => {
    const output = _data.map((item) => {
      const clone = { ...item };
      const children = normalizeData(clone.children);
      const isClave = 'clave_contable' in clone;
      const key = isClave ? `${clone.id}_clave` : clone.id;
      if (!children.length) {
        delete clone.children;
      } else {
        clone.children = children;
      }
      clone.key = key;
      return clone;
    });
    return output;
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await API.get(baseURI);
      const normalizedData = normalizeData(makeThree(response.data, 'cuenta_padre'));
      setData(normalizedData);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    const fetchAll = async () => {
      try {
        setLoading(true);

        if (!afectacionesPresupuestales?.length) {
          await fetchSome(
            'catalogos/afectaciones-presupuestales/',
            SET_AFECTACIONES_PRESUPUESTALES,
          );
        }

        if (!momentosPresupuestalesDeIngreso?.length) {
          await fetchSome(
            'catalogos/momentos-presupuestales-de-egresos/',
            SET_MOMENTOS_PRESUPUESTALES_DE_INGRESO,
          );
        }
        if (!momentosPresupuestalesDeEgreso?.length) {
          await fetchSome(
            'catalogos/momentos-presupuestales-de-ingresos/',
            SET_MOMENTOS_PRESUPUESTALES_DE_EGRESO,
          );
        }
        if (!naturalezasDeIngreso?.length) {
          await fetchSome('catalogos/naturalezas-de-ingreso/', SET_NATURALEZAS_DE_INGRESO);
        }

        if (!clasificacionesDeCuentasContables?.length) {
          await fetchSome(
            'catalogos/clasificaciones-de-cuentas-contables/',
            SET_CLASIFICACIONES_DE_CUENTA_CONTABLES,
          );
        }

        if (!tiposDeCuentasContables?.length) {
          await fetchSome('catalogos/tipos-de-cuentas-contables/', SET_TIPOS_CUENTAS_CONTABLES);
        }

        await fetchData();
        setLoading(false);
      } catch (err) {
        onError(err, setLoading);
      }
    };
    fetchAll();

    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    form.setFieldsValue(record);
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',

    getCheckboxProps: (record) => ({
      disabled: !!record.children,
    }),
  };

  const columns = [
    {
      titleText: 'Cuenta Contable 5 dígitos',
      dataIndex: 'clave',
      key: 'clave',
      width: 350,
      render: (val) => (val ? val.split('-')[0] : '-'),
    },
    {
      titleText: 'Nombre',
      dataIndex: 'nombre',
      key: 'nombre',
      width: 400,
    },
    {
      titleText: 'Descripción',
      dataIndex: 'descripcion',
      key: 'descripcion',
      width: 600,
    },
    {
      titleText: 'Nivel',
      dataIndex: 'nivel',
      key: 'nivel',
      width: 100,
    },
    {
      titleText: 'Posición Cuenta Mayor',
      dataIndex: 'posicion_cuenta_mayor',
      key: 'posicion_cuenta_mayor',
      width: 200,
    },
    {
      titleText: 'Clasificación',
      dataIndex: 'clasificacion',
      key: 'clasificacion',
      width: 200,
      render: (val) => clasificacionesDeCuentasContables.find((e) => e.id === val)?.nombre,
    },
    {
      titleText: 'Naturaleza',
      dataIndex: 'naturaleza',
      key: 'naturaleza',
      width: 200,
      render: (val) => naturalezasDeIngreso.find((e) => e.id === val)?.nombre,
    },
    {
      titleText: 'Tipo',
      dataIndex: 'tipo',
      key: 'tipo',
      width: 150,
      render: (val) => tiposDeCuentasContables.find((a) => a.id === val)?.nombre,
    },
    {
      titleText: 'Afectación Presupuestal',
      dataIndex: 'afectacion_presupuestal',
      key: 'afectacion_presupuestal',
      width: 250,
      render: (val) => afectacionesPresupuestales.find((a) => a.id === val)?.nombre,
    },
    {
      titleText: 'Momento Presupuestal Ingreso',
      dataIndex: 'momento_presupuestal_del_ingreso',
      key: 'momento_presupuestal_del_ingreso',
      width: 500,
      render: (val) => momentosPresupuestalesDeIngreso.find((a) => a.id === val)?.nombre,
    },
    {
      titleText: 'Momento Presupuestal Egreso',
      dataIndex: 'momento_presupuestal_de_egreso',
      key: 'momento_presupuestal_de_egreso',
      width: 500,
      render: (val) => momentosPresupuestalesDeIngreso.find((a) => a.id === val)?.nombre,
    },
    {
      titleText: 'Estado',
      dataIndex: 'estados_globales',
      key: 'estados_globales',
      width: 250,
      render: (val) => estadosGlobales.find((a) => a.id === val)?.descripcion,
    },
  ];

  const onCancel = () => {
    setVisible(false);
    setSelected();
    setSelectedRowKeys([]);
  };

  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible ? (
          <Table
            cols={columns}
            data={data}
            rowSelection={rowSelection}
            handleOnRowClick={handleOnRowClick}
            controls={{
              onClickExpand: () => setVisible(true),
            }}
            filterNested
            permission={permission}
          />
        ) : (
          <Card
            className="form-container"
            title={selected?.nombre}
            extra={(
              <FormSubmitControls onCancel={onCancel} permission={permission} />
            )}
            bordered={false}
          >
            <Row gutter={[10, 15]} className="cuenta-contable">
              <Form
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 28 }}
                name="cuenta-contable"
                form={form}
                style={{ width: '100%' }}
                colon={false}
              >
                <Form.Item
                  name="clave"
                  label="Cuenta Contable"
                >
                  <Input disabled />
                </Form.Item>
                <Form.Item
                  name="nombre"
                  label="Nombre"
                >
                  <Input disabled />
                </Form.Item>
                <Form.Item
                  name="descripcion"
                  label="Descripción"
                >
                  <Input.TextArea autoSize={{ minRows: 5, maxRows: 5 }} disabled />
                </Form.Item>
                <Form.Item
                  name="nivel"
                  label="Nivel"
                >
                  <Input disabled />
                </Form.Item>
                <Form.Item
                  name="posicion_cuenta_mayor"
                  label={(
                    <FormItemLabel
                      inline
                      longTitle="Posición Cuenta Mayor"
                      shortTitle="PCM"
                    />
                  )}
                >
                  <Input disabled />
                </Form.Item>
                <Form.Item
                  name="clasificacion"
                  label="Clasificación"
                >
                  <Select
                    dataSource={clasificacionesDeCuentasContables}
                    labelProp="nombre"
                    disabled
                  />
                </Form.Item>
                <Form.Item
                  name="naturaleza"
                  label="Naturaleza"
                >
                  <Select
                    dataSource={naturalezasDeIngreso}
                    labelProp="nombre"
                    disabled
                  />
                </Form.Item>
                <Form.Item
                  name="tipo"
                  label="Tipo"
                >
                  <Select
                    dataSource={tiposDeCuentasContables}
                    labelProp="nombre"
                    disabled
                  />
                </Form.Item>
                <Form.Item
                  name="afectacion_presupuestal"
                  label={(
                    <FormItemLabel
                      inline
                      longTitle="Afectación presupuestal"
                      shortTitle="AP"
                    />
                  )}
                >
                  <Select disabled dataSource={afectacionesPresupuestales} labelProp="nombre" />
                </Form.Item>
                <Form.Item
                  name="momento_presupuestal_del_ingreso"
                  label={(
                    <FormItemLabel
                      inline
                      longTitle="Momento Presupuestal Ingresos"
                      shortTitle="MPI"
                    />
                  )}
                >
                  <Select
                    disabled
                    dataSource={momentosPresupuestalesDeIngreso}
                    labelProp="nombre"
                  />
                </Form.Item>
                <Form.Item
                  name="momento_presupuestal_de_egreso"
                  label={(
                    <FormItemLabel
                      inline
                      longTitle="Momento Presupuestal Egresos"
                      shortTitle="MPE"
                    />
                  )}
                >
                  <Select
                    disabled
                    dataSource={momentosPresupuestalesDeEgreso}
                    labelProp="nombre"
                  />
                </Form.Item>
                <Form.Item
                  name="estados_globales"
                  label="Estado"
                >
                  <Select disabled globalStates />
                </Form.Item>
              </Form>
            </Row>
          </Card>
        )}
      </Spin>
    </Row>
  );
}

export default CuentasContables;
