import React, { useState, useEffect } from 'react';
import {
  Form,
  Button,
  Row,
  Col,
  Spin,
  Card,
  Input,
} from 'antd';

import API from 'utils/api';
import { onError, onSuccess } from 'utils/handlers';
import PlainTransfer from 'components/PlainTransfer';
import Table from 'components/Table';
import FormSubmitControls from 'components/FormSubmitControls';

const baseURI = 'usuarios/usuarios/';

function RolesAUsuarios() {
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState([]);
  const [permisos, setPermisos] = useState([]);
  const [roles, setRoles] = useState([]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await API.get(baseURI);
      if (res?.status === 200) {
        const dataWithKeys = res.data.map((item) => ({
          ...item,
          fullName: `${item.first_name} ${item.last_name} ${item.second_last_name || ''}`,
        }));
        setData(dataWithKeys);
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    const fetchAll = async () => {
      try {
        setLoading(true);
        const resPermisos = await API.get('usuarios/permisos');
        setPermisos(resPermisos.data);
        const resRoles = await API.get('estructura-organizacional/grupos/');
        const formatedData = resRoles.data.map((item) => ({
          ...item,
          name: item.name.split('-').shift(),
        }));
        setRoles(formatedData);
        await fetchData();
      } catch (err) {
        onError(err, setLoading);
      }
    };
    fetchAll();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    setSelectedRowKeys([]);
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      const [key] = selectedRowKeys;
      const resRoles = await API.put(`/usuarios/agregar-roles/${key}/`, {
        groups: values.groups,
      });
      if (resRoles?.status === 200) {
        const resPermisos = await API.put(`/usuarios/agregar-permisos/${key}/`, {
          user_permissions: values.user_permissions,
        });
        if (resPermisos?.status === 200) {
          onSuccess(null, 'Roles y Permisos asignados correctamente');
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    form.setFieldsValue(record);
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickEdit = async () => {
    try {
      setLoading(true);
      const [key] = selectedRowKeys;
      const res = await API.get(`${baseURI}${key}/`);
      if (res?.data) {
        setTimeout(() => {
          form.setFieldsValue({
            permissions: res.data.permisos,
            groups: res.data.roles,
          });
        });
      }
      setLoading(false);
      setVisible(true);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const columns = [
    {
      titleText: 'Correo',
      dataIndex: 'email',
      key: 'email',
      width: 250,
    },
    {
      titleText: 'Nombre',
      dataIndex: 'fullName',
      key: 'fullName',
      width: 250,
    },
  ];

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
  };

  return (
    <Row className="container">
      <Spin
        tip="Cargando..."
        spinning={loading}
      >
        {!visible ? (
          <Table
            cols={columns}
            data={data}
            rowSelection={rowSelection}
            handleOnRowClick={handleOnRowClick}
            controls={{
              onClickEdit,
            }}
            mobileColIndex={0}
          />
        ) : (
          <Card
            className="form-container"
            title="Editar roles y permisos de usuario"
            extra={(
              <FormSubmitControls onFinish={onFinish} onCancel={onCancel} loading={loading} />
            )}
            bordered={false}
          >
            <Form
              layout="vertical"
              form={form}
              onFinish={onFinish}
              scrollToFirstError
            >
              <Row gutter={10}>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="email"
                    label="Correo Electrónico"
                    hasFeedback
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="fullName"
                    label="Nombre Completo"
                    hasFeedback
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <PlainTransfer
                    dataSource={roles}
                    label="Roles"
                    form={form}
                    formItemName="groups"
                    filterProp="name"
                    rules={rules.required}
                  />
                </Col>
                <Col span={24}>
                  <PlainTransfer
                    dataSource={permisos}
                    label="Permisos"
                    form={form}
                    formItemName="permissions"
                    filterProp="name"
                    rules={rules.required}
                  />
                </Col>
                <Form.Item hidden>
                  <Button htmlType="submit" />
                </Form.Item>
              </Row>
            </Form>
          </Card>
        )}
      </Spin>
    </Row>
  );
}

export default RolesAUsuarios;
