import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Form,
  Button,
  Row,
  Col,
  Spin,
  Modal,
} from 'antd';
import Table from 'components/Table';
import API from 'utils/api';
import FormSubmitControls from 'components/FormSubmitControls';
import ModalDelete from 'components/ModalDelete';
import { onError, onSuccess } from 'utils/handlers';
import getFormattedValues, { formatReceived } from 'utils/formatValues';
import Select from 'components/Select';

const baseURI = 'adquisiciones/configuraciones-de-requisiciones/';

// eslint-disable-next-line react/prop-types
function ConfigRequisiciones({ permission }) {
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);
  const [usuarios, setUsuarios] = useState([]);
  const [folios, setFolios] = useState([]);
  const [tiposDeOperacion, setTiposDeOperacion] = useState([]);
  const [requireAuth, setRequireAuth] = useState(false);

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await API.get(baseURI);
      setData(formatReceived(res.data));
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };
  const fetchAll = async () => {
    try {
      setLoading(true);
      const params = {
        params: {
          estados_globales: 4,
        },
      };

      const resFolios = await API.get('/configuraciones/configuraciones-de-folios/', params);
      setFolios(resFolios.data);

      const resUsers = await API.get('usuarios/usuarios/');
      setUsuarios(resUsers.data);

      const resTipos = await API.get('adquisiciones/tipos-de-operaciones/');
      setTiposDeOperacion(resTipos.data);

      await fetchData();
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchAll();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    setSelectedRowKeys([]);
    setRequireAuth(false);
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      const formatedValues = getFormattedValues(values, {}, true);
      if (!selectedRowKeys.length) {
        const response = await API.post(baseURI, formatedValues);
        if (response?.status === 201) {
          onSuccess(response, 'Agregado correctamente');
          onCancel();
        }
      } else {
        const [key] = selectedRowKeys;
        const response = await API.put(`${baseURI}${key}/`, formatedValues);
        if (response?.status === 200) {
          onSuccess(response, 'Actualizado correctamente');
          onCancel();
        }
      }
      await fetchData();
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const deleteItem = async () => {
    try {
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`${baseURI}${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          setVisibleAlert(false);
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    setRequireAuth(record.requiere_autorizacion);
    setTimeout(() => form.setFieldsValue(formatReceived(record)));
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const onClickEdit = async () => {
    setVisible(true);
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const columns = [
    {
      titleText: 'Foliador',
      dataIndex: 'foliador',
      key: 'foliador',
      width: 200,
      render: (val) => folios.find((e) => e.id === val)?.nombre,
    },
    {
      titleText: '¿Requiere autorización?',
      dataIndex: 'requiere_autorizacion',
      key: 'requiere_autorizacion',
      width: 200,
      render: (val) => (val ? 'Si' : 'No'),
    },
    {
      titleText: 'Tipo de operación',
      dataIndex: 'tipo_de_operacion',
      key: 'tipo_de_operacion',
      width: 200,
      render: (val) => tiposDeOperacion.find((e) => e.id === val)?.descripcion,
    },
    {
      titleText: 'Estado',
      key: 'estados_globales',
      dataIndex: 'estados_globales',
      width: 150,
      render: (e) => estadosGlobales.find((i) => i.id === e)?.descripcion,
    },
  ];

  const requiredRule = {
    required: true,
    message: 'El campo es requerido',
  };

  const rules = {
    required: [requiredRule],
    tipo_de_operacion: [
      requiredRule,
      {
        validator: async () => {
          const clone = [...data];
          const tipoDeOperacion = form.getFieldValue('tipo_de_operacion');
          const match = clone.find((e) => e.tipo_de_operacion === tipoDeOperacion);
          if (match && !selectedRowKeys.length) {
            throw new Error('Ya existe una configuración para este tipo de operación.');
          }
        },
      },
    ],
  };

  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        <Table
          cols={columns}
          data={data}
          rowSelection={rowSelection}
          handleOnRowClick={handleOnRowClick}
          controls={{
            onClickAdd,
            onClickEdit,
            onClickDelete,
          }}
          mobileColIndex={0}
          permission={permission}
        />
        <Modal
          visible={visible}
          title={(
            <FormSubmitControls
              label={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Configuración`}
              onFinish={onFinish}
              onCancel={onCancel}
              loading={loading}
              permission={permission}
            />
          )}
          closable={false}
          maskClosable={!loading}
          keyboard={!loading}
          onCancel={onCancel}
          footer={null}
          forceRender
        >
          <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
            scrollToFirstError
            initialValues={{ estados_globales: 1, requiere_autorizacion: false }}
          >
            <Row>
              <Col span={24}>
                <Form.Item
                  name="foliador"
                  rules={rules.required}
                  label="Foliador"
                  hasFeedback
                >
                  <Select
                    dataSource={folios}
                    render={(e) => `${e.nombre} (${e.separador})`}
                    disabled={!!selectedRowKeys.length}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="requiere_autorizacion"
                  rules={rules.required}
                  label="¿Requiere autorización?"
                  hasFeedback
                >
                  <Select
                    trueFalse
                    onChange={setRequireAuth}
                    disabled={!!selectedRowKeys.length}
                  />
                </Form.Item>
              </Col>
              {requireAuth && (
                <Col span={24}>
                  <Form.Item
                    name="quien_autoriza"
                    rules={rules.required}
                    label="¿Quién autoriza?"
                    hasFeedback
                  >
                    <Select
                      dataSource={usuarios}
                      labelProp="email"
                      disabled={!!selectedRowKeys.length}
                    />
                  </Form.Item>
                </Col>
              )}
              <Col span={24}>
                <Form.Item
                  name="tipo_de_operacion"
                  rules={rules.tipo_de_operacion}
                  label="Tipo de Operación"
                  hasFeedback
                >
                  <Select
                    dataSource={tiposDeOperacion}
                    disabled={!!selectedRowKeys.length}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="estados_globales"
                  rules={rules.required}
                  label="Estado"
                  hasFeedback
                >
                  <Select
                    globalStates
                    disabled={!selectedRowKeys.length}
                  />
                </Form.Item>
              </Col>
              <Form.Item hidden>
                <Button htmlType="submit" />
              </Form.Item>
            </Row>
          </Form>
        </Modal>
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => setVisibleAlert(false)}
          visible={visibleAlert}
          content={`El comprador ${form.getFieldValue('concepto')}`}
          loading={loading}
        />
      </Spin>
    </Row>
  );
}

export default ConfigRequisiciones;
