const mutableStatus = [1, 3, 2];
const usableStatus = [4, 3];

export const isMutable = (obj = {}) => {
  const statusId = obj.estados_globales?.id || obj.estados_globales;
  return mutableStatus.includes(statusId);
};

export const isAuthorized = (obj = {}) => {
  const statusId = obj.estados_globales?.id || obj.estados_globales;
  return statusId === 4;
};

export const isUsable = (obj = {}) => {
  const statusId = obj.estados_globales?.id || obj.estados_globales;
  return usableStatus.includes(statusId);
};
