import React, { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
import {
  Row,
  Col,
  Spin,
  Form,
  Button,
  Input,
  // Modal,
  DatePicker,
  Card,
  Tabs,
  message,
} from 'antd';
import FormSubmitControls from 'components/FormSubmitControls';
import Table from 'components/Table';
import ModalDelete from 'components/ModalDelete';
import API from 'utils/api';
import { onError, onSuccess } from 'utils/handlers';
// import Select from 'components/Select';
import { isMutable } from 'utils/estadosGlobales';
import Select from 'components/Select';
import { useSelector } from 'react-redux';
import getFormattedValues, { formatReceived } from 'utils/formatValues';
import PlainTransfer from 'components/PlainTransfer';
import makeTree from 'utils/makeTree';
import { findItemNested } from 'utils/filters';
import ProyectoProgramas from './ProyectoProgramas';

export const permissionPrograma = {
  permissionModel: 'programa',
};

const baseURI = 'contabilidad/programas/';

// eslint-disable-next-line react/prop-types
function Programas({ permission }) {
  const { TabPane } = Tabs;
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  const periodoFiscal = useSelector(({ auth }) => auth.periodoFiscalSelected);
  const [form] = Form.useForm();
  const [formSubprograma] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [mutable, setMutable] = useState(true);
  const [ur, setUr] = useState([]);
  const [uo, setUo] = useState([]);
  const [cc, setCC] = useState([]);
  const [funcionesPresupuestales, setFuncionesPresupuestales] = useState([]);
  const [programasPadres, setProgramasPadres] = useState([]);
  const [currentTabKey, setCurrentTabKey] = useState('general');

  const basicKeys = [
    'general',
    'subprograma',
  ];
  const complexForms = [];

  const normalize = (_data) => _data.map((e) => {
    if (!e.children.length) {
      delete e.children;
    } else {
      normalize(e.children);
    }
    return e;
  });

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await API.get(baseURI);
      const _data = formatReceived(res.data);
      const tree = normalize(makeTree(_data, 'programa_padre'));
      setData(tree);
      setProgramasPadres(_data);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    const fetchAll = async () => {
      await fetchData();
      const resUR = await API.get('/estructura-organizacional/unidades-responsables/');
      setUr(resUR.data || []);

      const resUO = await API.get('/estructura-organizacional/unidades-de-trabajo/');
      setUo(resUO.data || []);

      const resCentroCostos = await API.get('/estructura-organizacional/centros-de-costos/');
      setCC(resCentroCostos.data || []);

      const resFunciones = await API.get('/catalogos/funcion-presupuestal/');
      setFuncionesPresupuestales(resFunciones.data || []);
    };
    fetchAll();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setSelectedRowKeys([]);
    form.resetFields();
    setVisible(false);
    setMutable(true);
    fetchData();
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = {
        ...form.getFieldValue(),
        periodo_fiscal: periodoFiscal.id,
      };
      const [periodo_inicial, periodo_final] = values.vigencia;
      const formattedValues = getFormattedValues({
        ...values,
        vigencia_de_inicio: periodo_inicial,
        vigencia_de_fin: periodo_final,
      });
      if (!selectedRowKeys.length) {
        const res = await API.post(baseURI, formattedValues);
        if (res?.status === 201) {
          onSuccess(res, 'Agregado correctamente');
          onCancel();
          await fetchData();
        }
      } else {
        const [key] = selectedRowKeys;
        const res = await API.put(`${baseURI}${key}/`, formattedValues);
        if (res?.status === 200) {
          onSuccess(res, 'Actualizado correctamente');
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`${baseURI}${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          setVisibleAlert(false);
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    form.setFieldsValue(record);
    setMutable(isMutable(record));
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const columns = [
    {
      titleText: 'Clave',
      dataIndex: 'clave',
      key: 'clave',
      width: 150,
    },
    {
      titleText: 'Nombre',
      dataIndex: 'nombre',
      key: 'nombre',
      width: 150,
    },
    {
      titleText: 'Descripción',
      dataIndex: 'descripcion',
      key: 'descripcion',
      width: 150,
    },
    {
      titleText: 'Función Presupuestal',
      dataIndex: 'funcion_presupuestal',
      key: 'funcion_presupuestal',
      width: 150,
      render: (id) => funcionesPresupuestales.find((eg) => eg.id === id)?.nombre,

    },

  ];

  const requiredRule = {
    required: true,
    message: 'El campo es requerido',
  };

  const rules = {
    required: [requiredRule],
  };

  const onClickEdit = () => {
    setVisible(true);
    const [key] = selectedRowKeys;
    const match = findItemNested(data, key);
    const filter = programasPadres.filter((e) => e.id !== match.id);
    if (match?.vigencia_de_inicio && match?.vigencia_de_fin) {
      const vigencia = [match?.vigencia_de_inicio, match?.vigencia_de_fin];
      setTimeout(() => {
        form.setFieldsValue({
          vigencia,
        });
      });
    }
    setProgramasPadres(filter);
    setTimeout(() => {
      form.setFieldsValue({
        ...match,
      });
    });
  };

  const showMsg = (tabKey) => {
    message.info({
      content: (
        <>
          <br />
          <Row style={{ width: '100%' }}>
            Los cambios que hayan sido realizados serán descartados.
          </Row>
          <Row align="middle" style={{ width: '100%' }}>
            ¿Desea continuar?
            <Button
              type="link"
              onClick={() => {
                setCurrentTabKey(tabKey);
                message.destroy();
                onClickEdit();
              }}
            >
              Si
            </Button>
            <Button
              type="link"
              onClick={() => message.destroy()}
            >
              No
            </Button>
          </Row>
        </>
      ),
      duration: 0,
    });
  };

  const onChangeTabKey = (key) => {
    const fromBasic = basicKeys.includes(currentTabKey);
    const toBasic = basicKeys.includes(key);
    const match = complexForms.find((e) => e.key === currentTabKey);
    if (!match) {
      if (fromBasic && toBasic) {
        setCurrentTabKey(key);
      } else if (fromBasic) {
        showMsg(key);
      } else {
        setCurrentTabKey(key);
      }
    } else {
      match.formInstance.onFinishHandler(key, true);
    }
  };

  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible ? (
          <Table
            cols={columns}
            data={data}
            rowSelection={rowSelection}
            permission={permission}
            handleOnRowClick={handleOnRowClick}
            childrenProp="children"
            filterNested
            loading={loading}
            controls={{
              onClickAdd,
              onClickEdit,
              onClickDelete,
            }}
            mobileColIndex={0}
          />

        ) : (
          <Card
            visible={visible}
            title={(
              <FormSubmitControls
                label={`${!selectedRowKeys.length ? 'Agregar' : 'Editar'} Programa`}
                onCancel={onCancel}
                allowSaveAndContinue
                allowAuthorize
                allowCancel
                mutable={mutable}
                baseURI={baseURI}
                selectedRowKeys={selectedRowKeys}
                callback={(estados_globales) => {
                  const [key] = selectedRowKeys;
                  const normalized = [...data]
                    .map((e) => (e.id === key ? { ...e, estados_globales } : e));
                  setMutable(false);
                  setData(normalized);
                  const match = normalized.find((e) => e.id === key);
                  onClickEdit(match);
                }}
              />
            )}
            onCancel={onCancel}
            footer={null}
            closable={false}
            maskClosable={!loading}
            keyboard={!loading}
            forceRender
          >
            <Tabs
              activeKey={currentTabKey}
              onChange={mutable ? setCurrentTabKey : onChangeTabKey}
              type="card"
            >
              <TabPane
                tab="General"
                key="general"
                forceRender
              >

                <Form
                  name="general"
                  form={form}
                  layout="vertical"
                  onFinish={onFinish}
                  scrollToFirstError
                  initialValues={{
                    estados_globales: 1,

                  }}
                >
                  <Row gutter={10}>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        label="Programa padre"
                        name="programa_padre"
                        hasFeedback
                      >
                        <Select
                          allowClear
                          dataSource={programasPadres}
                          labelProp="nombre"
                          keyLabelRender
                        />
                      </Form.Item>
                    </Col>
                    {/* <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="numero"
                        label="Programa Padre"
                      >
                        <Select dataSource={programaPadre} keyLabelRender labelProp="nombre" />
                      </Form.Item>
                    </Col> */}
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        rules={rules.required}
                        label="Clave"
                        name="clave"
                        hasFeedback
                      >
                        <Input
                          allowClear
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        rules={rules.required}
                        label="Nombre"
                        name="nombre"
                        hasFeedback
                      >
                        <Input
                          allowClear
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        rules={rules.required}
                        label="Descripción"
                        name="descripcion"
                        hasFeedback
                      >
                        <Input.TextArea
                          autoSize={{ minRows: 2, maxRows: 3 }}
                          allowClear
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        label="Vigencia"
                        name="vigencia"
                        tooltip="Vigencia Inicial - Vigencia Final"
                        hasFeedback={mutable}
                        rules={rules.required}
                      >
                        <DatePicker.RangePicker
                          format="DD/MM/YYYY"
                          placeholder=""
                          // picker="year"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        label="Función Presupuestal"
                        name="funcion_presupuestal"
                        hasFeedback={mutable}
                      >
                        <Select
                          dataSource={funcionesPresupuestales}
                          labelProp="nombre"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24}>
                      <PlainTransfer
                        dataSource={uo}
                        label="Unidades Operativas"
                        form={form}
                        formItemName="unidad_operativa"
                        filterProp="descripcion"
                      />
                    </Col>
                    <Col xs={24} sm={24} md={24}>
                      <PlainTransfer
                        dataSource={ur}
                        label="Unidades Responsables"
                        form={form}
                        formItemName="unidad_responsable"
                        filterProp="descripcion"
                      />
                    </Col>
                    <Col xs={24} sm={24} md={24}>
                      <PlainTransfer
                        dataSource={cc}
                        label="Centros de Costos"
                        form={form}
                        formItemName="centro_de_costos"
                        filterProp="descripcion"
                      />
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="estados_globales"
                        label="Estado"
                        rules={rules.required}
                      >
                        <Select
                          dataSource={estadosGlobales}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item hidden>
                    <Button htmlType="submit" />
                  </Form.Item>
                </Form>
              </TabPane>

              <TabPane
                key="programa"
                tab="Programa"
                forceRender={!selectedRowKeys.length}
              >
                <Form
                  layout="vertical"
                  name="subprograma"
                  form={formSubprograma}
                  onFinish={onFinish}
                  scrollToFirstError
                >
                  <Row gutter={10}>
                    <ProyectoProgramas
                      programas={data}
                      programaPadre={selectedRowKeys}
                      programaSeleccionado={selectedRowKeys}
                    />
                  </Row>
                </Form>
              </TabPane>
            </Tabs>
          </Card>
        )}
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => setVisibleAlert(false)}
          visible={visibleAlert}
          content="El identificador seleccionado"
          loading={loading}
        />
      </Spin>
    </Row>
  );
}

export default Programas;
