/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Form,
  Input,
  Button,
  Spin,
  Row,
  Col,
  Modal,
  message,
} from 'antd';
import API from 'utils/api';
import { onError } from 'utils/handlers';
import { customIntegerNormalizer } from 'utils/normalizers';
import { InputSearchPro } from '../InputSearch';
import FormSubmitControls from '../FormSubmitControls';
import Table from '../Table';
import ModalDelete from '../ModalDelete';
import Select from '../Select';
import BusquedaClavesContables from '../BusquedaClavesContables';

const baseURI = 'configuraciones/cuentas-de-inversion-y-tarjetas-de-pago/';

function CuentasDeInvercionOTarjetasDePago({
  selectedRowKey: cuenta_bancaria,
  finalidades,
  mascara,
  pattern,
  separador,
  cuentasDeInversion,
}) {
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);
  const [cuentaContableFound, setCuentaContableFound] = useState([]);
  const [visibleBusquedaCuentas, setVisibleBusquedaCuentas] = useState(false);
  const [selectedClave, setSelectedClave] = useState();

  const fetchData = async () => {
    try {
      setLoading(true);
      if (cuenta_bancaria) {
        const res = await API.get(baseURI, {
          params: {
            cuenta_bancaria,
            es_cuenta_de_inversion: cuentasDeInversion,
          },
        });
        if (res?.status === 200) {
          setData(res.data);
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchData();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, [cuenta_bancaria]);

  const onCancel = () => {
    form.resetFields();
    setCuentaContableFound([]);
    setSelectedRowKeys([]);
    setVisible(false);
  };

  const onFinish = async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue();
      if (cuenta_bancaria) {
        if (!selectedRowKeys.length) {
          values.cuenta_bancaria = cuenta_bancaria;
          values.es_cuenta_de_inversion = cuentasDeInversion;
          const res = await API.post(baseURI, values);
          if (res?.status === 201) {
            onCancel();
            await fetchData();
          }
        } else {
          const [key] = selectedRowKeys;
          const res = await API.patch(`${baseURI}${key}/`, values);
          if (res?.status === 200) {
            onCancel();
            await fetchData();
          }
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    form.setFieldsValue({ ...record, cuenta_contable: record.cuenta_contable?.id });
    setCuentaContableFound([record.cuenta_contable]);
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const onClickEdit = () => {
    setVisible(true);
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const deleteItem = async () => {
    try {
      const [key] = selectedRowKeys;
      setLoading(true);
      if (cuenta_bancaria) {
        const response = await API.delete(`${baseURI}${key}/`);
        if (response?.status === 204) {
          setVisibleAlert(false);
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const required = {
    required: true,
    message: 'El campo es requerido',
  };

  const rules = {
    required: [
      required,
    ],
    numero_de_tarjeta: [
      required,
      {
        validator: async (rule, val) => {
          const value = val?.toString();
          if (value?.length) {
            if (value.length < 16) {
              throw new Error('El campo debe tener una longitud mínima de 16 dígitos');
            }
          }
        },
      },
    ],
    numero_de_cuenta_de_inversion: [
      required,
      {
        validator: async (rule, val) => {
          const value = val?.toString() || '';
          if (value) {
            if (value.length < 10) {
              throw new Error('El campo debe tener una longitud mínima de 10 dígitos');
            }
          }
        },
      },
    ],
    clave_contable__clave: [
      required,
      {
        validator: async (rule, value) => {
          if (value && pattern && (!pattern.test(value))) {
            throw new Error('Introduzca una clave válida');
          }
        },
      },
    ],
  };

  const columns = [
    {
      titleText: cuentasDeInversion ? 'Número de cuenta' : 'Número de tarjeta',
      key: cuentasDeInversion ? 'numero_de_cuenta_de_inversion' : 'numero_de_tarjeta',
      dataIndex: cuentasDeInversion ? 'numero_de_cuenta_de_inversion' : 'numero_de_tarjeta',
      width: 300,
    },
    {
      titleText: 'Número de contrato',
      key: 'numero_de_contrato',
      dataIndex: 'numero_de_contrato',
      width: 250,
    },
    !cuentasDeInversion ? {
      titleText: 'Finalidad',
      key: 'finalidad',
      dataIndex: 'finalidad',
      width: 200,
      render: (val) => finalidades.find((e) => e.id === val)?.descripcion,
    } : null,
    {
      titleText: 'Estado',
      key: 'estados_globales',
      dataIndex: 'estados_globales',
      render: (val) => estadosGlobales.find((e) => e.id === val)?.descripcion,
      width: 150,
    },
  ].filter((e) => e);

  const resetAndValidateCuentaContable = () => {
    setCuentaContableFound([]);
    form.resetFields(['clave_contable']);
    form.validateFields(['clave_contable']);
  };

  const fetchCuentaContable = async () => {
    try {
      form.validateFields(['clave_contable__clave']);
      const clave = form.getFieldValue('clave_contable__clave');
      if (clave) {
        setLoading(true);
        const res = await API.get('contabilidad/cuentas-contables/', {
          params: {
            clave_contable: clave,
            tipo_de_cuenta_contable: 7,
          },
        });
        if (res?.status === 200 && res.data?.length) {
          const claveContable = res.data[0];
          setCuentaContableFound([claveContable]);
          form.setFieldsValue({
            cuenta_contable: claveContable.id,
          });
          setTimeout(() => {
            form.validateFields(['cuenta_contable']);
          });
        } else {
          message.info('No se encontraron coincidencias');
          setCuentaContableFound([]);
        }
        setLoading(false);
      }
    } catch (err) {
      onError(err, setLoading);
      setCuentaContableFound([]);
    }
  };

  const onChangeClaveContable = (e) => {
    const { target: { value }, keyCode } = e;
    let val = value;
    if (keyCode) {
      const char = String.fromCharCode(keyCode).toLowerCase();
      val += char;
    }
    if (mascara.charAt(val.length) === separador) {
      val += separador;
    }
    if (val.length === mascara?.length) {
      setTimeout(fetchCuentaContable);
    } else if (!val?.length) {
      resetAndValidateCuentaContable();
    }
    setTimeout(() => {
      form.setFieldsValue({ clave_contable__clave: val });
    });
  };

  const onFinishBusqueda = () => {
    if (selectedClave) {
      setCuentaContableFound([selectedClave]);
      setTimeout(() => form.setFieldsValue({
        cuenta_contable: selectedClave.id,
      }));
      setVisibleBusquedaCuentas(false);
    } else {
      message.info('No ha seleccionado una Cuenta Contable');
    }
  };

  const onCancelBusqueda = () => {
    setVisibleBusquedaCuentas(false);
    setSelectedClave();
  };

  const getLabel = () => {
    if (visibleBusquedaCuentas) {
      return 'Búsqueda Cuentas';
    }
    return `${selectedRowKeys.length ? 'Editar' : 'Agregar'} ${cuentasDeInversion
      ? 'Cuenta de inversión' : 'Tarjeta de Pago'}`;
  };

  return (
    <>
      <Table
        cols={columns}
        data={data}
        rowSelection={rowSelection}
        handleOnRowClick={handleOnRowClick}
        controls={{
          onClickAdd,
          onClickEdit,
          onClickDelete,
        }}
        allowSort={false}
        allowSearch={false}
        loading={loading}
      />
      <Modal
        visible={visible}
        closable={false}
        maskClosable={!loading}
        keyboard={!loading}
        footer={null}
        title={(
          <FormSubmitControls
            label={getLabel()}
            onFinish={visibleBusquedaCuentas ? onFinishBusqueda : onFinish}
            onCancel={visibleBusquedaCuentas ? onCancelBusqueda : onCancel}
            loading={loading}
          />
        )}
        onCancel={onCancel}
        forceRender
        width={visibleBusquedaCuentas ? 800 : 600}
      >
        <Spin tip="Cargando..." spinning={loading}>
          {visibleBusquedaCuentas ? (
            <BusquedaClavesContables callback={(cuentaContable) => {
              setSelectedClave(cuentaContable);
            }}
            />
          ) : (

            <Form
              form={form}
              layout="vertical"
              name={cuentasDeInversion ? 'cuentas_de_inversion' : 'tarjetas_de_pago'}
              onFinish={onFinish}
              initialValues={{ estados_globales: 1 }}
            >
              <Row gutter={10}>
                <Col span={24}>
                  <Form.Item
                    name={cuentasDeInversion ? 'numero_de_cuenta_de_inversion' : 'numero_de_tarjeta'}
                    label={cuentasDeInversion ? 'Número de Cuenta' : 'Número de tarjeta'}
                    rules={rules[cuentasDeInversion
                      ? 'numero_de_cuenta_de_inversion' : 'numero_de_tarjeta']}
                    normalize={(val) => customIntegerNormalizer(val, cuentasDeInversion)}
                  >
                    <Input maxLength={cuentasDeInversion ? 12 : 19} allowClear />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="numero_de_contrato"
                    label="Número de Contrato"
                    normalize={customIntegerNormalizer}
                  >
                    <Input allowClear />
                  </Form.Item>
                </Col>
                {!cuentasDeInversion && (
                <Col span={24}>
                  <Form.Item
                    name="finalidad"
                    label="Finalidad"
                    rules={rules.required}
                    hasFeedback
                  >
                    <Select dataSource={finalidades} />
                  </Form.Item>
                </Col>
                )}
                <InputSearchPro
                  name="clave_contable__clave"
                  onClickAdvanced={() => setVisibleBusquedaCuentas(true)}
                  rules={rules.clave_contable__clave}
                  label="Cuenta Contable"
                  tooltip={`Debe concordar con la mascara ${mascara}`}
                  inputProps={{
                    onPressEnter: () => fetchCuentaContable(),
                    onChange: onChangeClaveContable,
                    maxLength: mascara?.length,
                  }}
                  breakPoints={{
                    md: 24,
                  }}
                  resultProps={{
                    name: 'cuenta_contable',
                    label: 'Cuenta Contable',
                    rules: rules.required,
                    dataSource: cuentaContableFound,
                    keyLabelRender: true,
                    keyProp: 'clave',
                    labelProp: 'nombre',
                    onClear: resetAndValidateCuentaContable,
                    breakPoints: {
                      md: 24,
                    },
                  }}
                />
                <Col span={24}>
                  <Form.Item
                    name="observaciones"
                    label="Observaciones"
                    hasFeedback
                  >
                    <Input.TextArea autoSize={{ minRows: 2, maxRows: 3 }} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="estados_globales"
                    label="Estado"
                    rules={rules.required}
                    hasFeedback
                  >
                    <Select
                      disabled={!selectedRowKeys.length}
                      dataSource={estadosGlobales}
                    />
                  </Form.Item>
                </Col>
                <Form.Item hidden>
                  <Button htmlType="submit" />
                </Form.Item>
              </Row>
            </Form>
          )}
        </Spin>
      </Modal>
      <ModalDelete
        onDelete={deleteItem}
        onCancel={() => setVisibleAlert(false)}
        visible={visibleAlert}
        content={`Contacto ${form.getFieldValue('nombre')}`}
        loading={loading}
      />
    </>
  );
}

CuentasDeInvercionOTarjetasDePago.propTypes = {
  selectedRowKey: PropTypes.number,
  finalidades: PropTypes.arrayOf(PropTypes.object).isRequired,
  mascara: PropTypes.string,
  separador: PropTypes.string,
  pattern: PropTypes.instanceOf(RegExp),
  cuentasDeInversion: PropTypes.bool,
};

CuentasDeInvercionOTarjetasDePago.defaultProps = {
  selectedRowKey: undefined,
  mascara: null,
  separador: null,
  pattern: null,
  cuentasDeInversion: false,
};

export default CuentasDeInvercionOTarjetasDePago;
