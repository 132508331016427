import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Spin,
  Form,
  Button,
  Input,
  Modal,
  TreeSelect,
  message,
  Tooltip,
} from 'antd';
import FormSubmitControls from 'components/FormSubmitControls';
import Table from 'components/Table';
import ModalDelete from 'components/ModalDelete';
import API from 'utils/api';
import { onError, onSuccess } from 'utils/handlers';
import Select from 'components/Select';
import { isMutable } from 'utils/estadosGlobales';
import { findItemNested } from 'utils/filters';
import { QuestionCircleOutlined } from '@ant-design/icons';
// import { findItemNested } from 'utils/filters';

export const permissionAreaDeUbicacion = {
  permissionModel: 'areadeubicacion',
};

const baseURI = [
  '/controlpatrimonial/areas-de-ubicaciones-generales/',
  '/controlpatrimonial/areas-de-ubicaciones-especificas/',
  '/controlpatrimonial/areas-de-ubicaciones-particulares/',
  '/controlpatrimonial/areas-de-ubicaciones/',
];

const propNames = {
  1: 'ubicacion_general',
  2: 'ubicacion_especifica',
  3: 'ubicacion_particular',
};

// eslint-disable-next-line react/prop-types
function AreaDeUbicacion({ activo_fijo, permission }) {
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  const [form] = Form.useForm();
  const timeoutFilter = useRef();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState({});
  const [data, setData] = useState([]);
  const [areaDeUbicacionParticular, setAreaDeUbicacionParticular] = useState([]);
  const [areaDeUbicacionEspecifica, setAreaDeUbicacionEspecifica] = useState([]);
  const [areaDeUbicacionGeneral, setAreaDeUbicacionGeneral] = useState([]);
  const [mutable, setMutable] = useState(true);

  const onClickTitle = (e) => {
    const element = e.target;
    if (element) {
      let parent = element.parentElement;
      parent = parent ? parent.parentElement : null;
      if (parent) {
        const clickableSpan = parent.previousSibling;
        if (clickableSpan) {
          clickableSpan.click();
        }
      }
    }
  };

  const renderTitle = (title, bold) => (
    // eslint-disable-next-line
    <span onClick={onClickTitle} className={bold ? 'bold' : ''}>
      {title}
    </span>
  );

  const normalizeData = (
    _data = [],
    isSelectable = (_item = {}) => (_item.nivel !== 4),
    excludeLvl3 = false,
    currLvl = 1,
  ) => _data.map((item) => {
    const clone = { ...item };
    const children = normalizeData(!excludeLvl3
      || currLvl !== 2 ? clone.children : [], isSelectable, excludeLvl3, currLvl + 1);
    if (!children.length) {
      delete clone.children;
      return ({
        ...clone,
        key: `${clone.id}_${clone.nivel}`,
        title: renderTitle(clone?.descripcion || clone?.observaciones, isSelectable(clone)),
        value: `${clone.id}_${clone.nivel}`,
        selectable: isSelectable(clone),
      });
    }
    return ({
      ...clone,
      key: `${clone.id}_${clone.nivel}`,
      children,
      title: renderTitle(clone?.descripcion || clone?.observaciones, isSelectable(clone)),
      value: `${clone.id}_${clone.nivel}`,
      selectable: isSelectable(clone),
    });
  });

  const fetchData = async () => {
    try {
      setLoading(true);
      if (activo_fijo) {
        const promises = baseURI.map((e) => API.get(e, { params: { activo_fijo } }));
        const [
          _ubicacionGeneral,
          _ubicacionEspecificia,
          _ubicacionParticular,
          _areaDeUbicacion,
        ] = (await Promise.all(promises)).map((r) => r.data);
        const mappedUbicacionesParticulares = _ubicacionParticular.map((e) => ({
          ...e,
          nivel: 3,
          children: _areaDeUbicacion.filter((i) => i.ubicacion_particular === e.id)
            .map((au) => ({ ...au, nivel: 4, descripcion: au.observaciones })),
        }));
        const mappedUbicacionesEspecificas = _ubicacionEspecificia.map((e) => ({
          ...e,
          nivel: 2,
          children: mappedUbicacionesParticulares.filter((i) => i.ubicacion_especifica === e.id),
        }));
        const mappedUbicacionesGenerales = _ubicacionGeneral.map((e) => ({
          ...e,
          nivel: 1,
          children: mappedUbicacionesEspecificas.filter((i) => i.ubicacion_general === e.id),
        }));
        setAreaDeUbicacionGeneral(mappedUbicacionesGenerales);
        setAreaDeUbicacionEspecifica(mappedUbicacionesEspecificas);
        setAreaDeUbicacionParticular(mappedUbicacionesParticulares);
        setData(normalizeData(mappedUbicacionesGenerales));
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchData();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, [activo_fijo]);

  const onCancel = () => {
    setSelectedRowKeys([]);
    form.resetFields();
    setVisible(false);
    setMutable(true);
  };

  const getUriAndCatalog = (_values = {}) => {
    if (!_values.father) {
      return {
        uri: baseURI[0],
        values: { ..._values, nivel: 1 },
      };
    }
    const key = parseInt(_values.father.split('_')[0], 10);
    const lvl = parseInt(_values.father.split('_')[1], 10);
    return {
      uri: baseURI[parseInt(_values.father[2], 10)],
      values: {
        ..._values,
        nivel: lvl + 1,
        [`${propNames[lvl]}`]: key,
      },
    };
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const _values = form.getFieldsValue();
      const { uri, values } = getUriAndCatalog(_values);
      if (values.nivel === 4) {
        values.observaciones = values.descripcion;
      }
      if (!values?.id) {
        const res = await API.post(uri, values);
        if (res?.status === 201) {
          onSuccess(res, 'Agregado correctamente');
          onCancel();
          await fetchData();
        }
      } else {
        const res = await API.put(`${uri}${values.id}/`, values);
        if (res?.status === 200) {
          onSuccess(res, 'Actualizado correctamente');
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      const values = form.getFieldsValue();
      const { uri } = getUriAndCatalog(values);
      const match = findItemNested(data, `${values.id}_${values.nivel}`, 'key');
      if (values?.id) {
        if (!match?.children?.length) {
          const response = await API.delete(`${uri}${values.id}/`);
          if (response?.status === 204) {
            onSuccess(response, 'Eliminado correctamente');
            setVisibleAlert(false);
            onCancel();
            await fetchData();
          }
        } else {
          message.warn('No se puede eliminar el registro porque tiene catálogos que dependen de él.');
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  // const filterUbicaciones = (val) => {
  //   if (val) {
  //     const match = areaDeUbicacionParticular.find((e) => e.id === val);
  //     const secondMatch = areaDeUbicacionEspecifica
  //       .find((e) => e.id === match.ubicacion_especifica);
  //     const thirdMatch = areaDeUbicacionGeneral
  //       .find((e) => e.id === secondMatch.ubicacion_general);
  //     form.setFieldsValue({
  //       ubicacion_especifica: secondMatch.id,
  //       ubicacion_general: thirdMatch.id,
  //     });
  //   }
  // };

  const handleOnRowClick = (record) => {
    const [id, lvl] = record.key.split('_').map((e) => parseInt(e, 10));
    let father = '';
    if (lvl === 2) {
      const match = areaDeUbicacionGeneral.find((e) => e.children.some((i) => i.id === id));
      father = `${match.id}_${match.nivel}`;
    }
    if (lvl === 3) {
      const match = areaDeUbicacionEspecifica.find((e) => e.children.some((i) => i.id === id));
      father = `${match.id}_${match.nivel}`;
    }
    if (lvl === 4) {
      const match = areaDeUbicacionParticular.find((e) => e.children.some((i) => i.id === id));
      father = `${match.id}_${match.nivel}`;
    }
    setSelectedRowKeys([record.key]);
    setSelected(record);
    setTimeout(() => form.setFieldsValue({ ...record, father }));
    setMutable(isMutable(record));
    // filterUbicaciones(record.ubicacion_particular);
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const columns = [
    {
      titleText: 'Clave',
      dataIndex: 'clave',
      key: 'clave',
      width: 150,
    },
    {
      titleText: 'Descripción',
      dataIndex: 'descripcion',
      key: 'descripcion',
      width: 150,
    },
    {
      titleText: 'Estado',
      dataIndex: 'estados_globales',
      key: 'estados_globales',
      width: 300,
      render: (val) => estadosGlobales.find((t) => t.id === val)?.descripcion,
    },
  ];

  const requiredRule = {
    required: true,
    message: 'El campo es requerido',
  };

  const rules = {
    required: [requiredRule],
  };

  const onClickEdit = () => {
    setVisible(true);
  };

  const filterTreeNode = (input, node) => {
    const title = node.title?.props.children.toLowerCase() || node.title;
    if (title && title.includes(input.toLowerCase())) {
      return true;
    }
    if (node.children) {
      return filterTreeNode(input, node.children);
    }
    return false;
  };

  const setTimeoutFilter = (input, node) => setTimeout(() => filterTreeNode(input, node), 1000);

  const filterTree = (input, node) => {
    clearTimeout(timeoutFilter.current);
    timeoutFilter.current = setTimeoutFilter(input, node);
    return timeoutFilter ? filterTreeNode(input, node) : true;
  };

  const initialValues = {
    estados_globales: 1,
    activo_fijo,
    value: 1,
  };

  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        <Table
          cols={columns}
          data={data}
          rowSelection={rowSelection}
          permission={permission}
          handleOnRowClick={handleOnRowClick}
          controls={{
            onClickAdd,
            onClickEdit,
            onClickDelete,
          }}
          rowKey="key"
          childrenProp="children"
          filterNested
          allowExpand
          allowImport
          allowedExtensions={['xlsx', 'csv', 'json']}
        />
        <Modal
          visible={visible}
          title={(
            <FormSubmitControls
              label={`${!selectedRowKeys.length ? 'Agregar' : 'Editar'} Área de Ubicación`}
              onFinish={onFinish}
              onCancel={onCancel}
              mutable={mutable}
            />
            )}
          onCancel={onCancel}
          footer={null}
          closable={false}
          maskClosable={!loading}
          keyboard={!loading}
          forceRender
        >
          <Form
            name="general"
            form={form}
            layout="vertical"
            onFinish={onFinish}
            scrollToFirstError
            initialValues={initialValues}
          >
            <Row>
              {(selected?.nivel !== 1 || !selectedRowKeys.length) && (
                <Col span={24}>
                  <Form.Item
                    name="father"
                    // label="Ubicación Padre"
                    label={(
                      <span>
                        <span style={{ paddingRight: 5 }}>
                          Ubicación Padre
                        </span>
                        <Tooltip title={(
                          <span>
                            Jerarquía:
                            1.Ubicación General
                            2.Ubicación Especifica
                            3.Ubicación Particular
                            4.Área de Ubicación
                          </span>
                          )}
                        >
                          <QuestionCircleOutlined />
                        </Tooltip>
                      </span>
                    )}
                  >
                    <TreeSelect
                      showSearch
                      treeNodeFilterProp="title"
                      style={{ width: '100%' }}
                      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                      treeData={data}
                      allowClear
                      filterTreeNode={filterTree}
                      disabled={selectedRowKeys.length}
                      showArrow={!selectedRowKeys.length}
                    />
                  </Form.Item>
                </Col>
              )}
              {/* <Col span={24}>
                <Form.Item
                name="ubicacion_particular"
                label="Ubicación Particular"
                >
                <Select
                dataSource={areaDeUbicacionParticular}
                    onChange={filterUbicaciones}
                    disabled={[1].includes(value)}
                  />
                  </Form.Item>
              </Col> */}
              <Col span={24}>
                <Form.Item
                  name="clave"
                  rules={rules.required}
                  label="Clave"
                  hasFeedback
                >
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Descripción"
                  rules={rules.required}
                  name="descripcion"
                  hasFeedback
                >
                  <Input.TextArea
                    autoSize={{ minRows: 4, maxRows: 4 }}
                    allowClear
                    disabled={!mutable}
                  />
                </Form.Item>
              </Col>
              <Form.Item
                hidden
                name="activo_fijo"
              >
                <Input />
              </Form.Item>

              <Form.Item
                hidden
                name="id"
              >
                <Input />
              </Form.Item>
              <Col span={24}>
                <Form.Item
                  name="estados_globales"
                  rules={rules.required}
                  label="Estado"
                  hasFeedback
                >
                  <Select
                    disabled={!selectedRowKeys.length || !mutable}
                    dataSource={estadosGlobales}
                  />
                </Form.Item>
              </Col>
              <Form.Item hidden>
                <Button htmlType="submit" />
              </Form.Item>
            </Row>
          </Form>
        </Modal>
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => setVisibleAlert(false)}
          visible={visibleAlert}
          content="El identificador seleccionado"
          loading={loading}
        />
      </Spin>
    </Row>
  );
}

AreaDeUbicacion.propTypes = {
  activo_fijo: PropTypes.number,
};

AreaDeUbicacion.defaultProps = {
  activo_fijo: 0,
};

export default AreaDeUbicacion;
