import React, { useState, useEffect } from 'react';
import {
  Card,
  Col,
  Form,
  Row,
  Spin,
  Input,
  Tabs,
  Select,
  DatePicker,
  Divider,
  Typography,
  Modal,
  Button,
} from 'antd';

import API from 'utils/api';
import {
  FileExcelOutlined,
  FilePdfOutlined,
  CheckCircleOutlined,
  CloseOutlined,
  SaveOutlined,
  ArrowLeftOutlined,
} from '@ant-design/icons';
import { onError } from 'utils/handlers';
import Table from 'components/Table';
import FormSubmitControls from 'components/FormSubmitControls';
import Uploader from 'components/Uploader';

const { TabPane } = Tabs;
const { Text } = Typography;

function SolicitudesDePago() {
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentTabKey, setCurrentTabKey] = useState('solicitud');
  const [visibleModalAgregarComprobante, setModalAgregarComprobante] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleFactura, setVisibleFactura] = useState(false);
  const [mutable, setMutable] = useState(true);
  const [data, setData] = useState([]);
  const [currentTabFactura, setCurrentTabFactura] = useState('general');
  const [mutableFactura, setMutableFactura] = useState(true);
  const basicKeys = [
    'Solicitud',
    'Factura',
    'Comprobante',
    'Flujo de Proceso',
  ];
  const requiredRule = {
    required: true,
    message: 'El campo es requerido',
  };
  const rules = {
    required: [requiredRule],
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      setData([]);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchData();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    setTimeout(() => form.setFieldsValue(record));
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickAdd = () => {
    setVisible(true);
    setMutableFactura(true);
  };

  const onClickEdit = () => {
    setVisible(true);
  };

  const onClickDelete = () => {};

  const onRechazar = () => { };

  const onAutorizar = () => { };

  const onDownLoadExcel = () => { };

  const onDownloadPdf = () => { };

  const onAplicarReserva = () => { };

  const onRechazarSolicitud = () => { };

  const onAutorizarSolicitudPago = () => { };

  const onAgregarComprobante = () => {
    setModalAgregarComprobante(true);
  };

  const onEditarComprobante = () => {
    setModalAgregarComprobante(true);
  };

  const onEliminarComprobante = () => {};

  const onCancelModalComprobante = () => {
    setModalAgregarComprobante(false);
  };

  const onCancel = () => {
    setMutable(true);
    setVisible(false);
  };

  const onChangeTabKey = () => { };

  const onFinish = () => { };

  const onAgregarFileFactura = () => {
    setVisibleFactura(true);
  };

  const onChangeTabFactura = () => {};

  const onExportPDFProceso = () => {};

  const onExportExcelProceso = () => {};

  const columns = [
    {
      titleText: 'No. de Solicitud de Pago',
      dataIndex: 'numero_solicitud_de_pago',
      key: 'numero_solicitud_de_pago',
      width: 200,
    },
    {
      titleText: 'Fecha de Elaboracíon',
      dataIndex: 'fecha_de_elaboracion',
      key: 'fecha_de_elaboracion',
      width: 200,
    },
    {
      titleText: 'Folio Agrupador',
      dataIndex: 'folio_agrupador',
      key: 'folio_agrupador',
      width: 220,
    },
    {
      titleText: 'Concepto de Solicitud',
      dataIndex: 'concepto_solicitud',
      key: 'concepto_solicitud',
      width: 200,
    },
    {
      titleText: 'Beneficiario',
      dataIndex: 'beneficiario',
      key: 'beneficiario',
      width: 200,
    },
    {
      titleText: 'Concepto General',
      dataIndex: 'concepto_general',
      key: 'concepto_general',
      width: 200,
    },
    {
      titleText: 'Periodo de Pago',
      dataIndex: 'periodo_de_pago',
      key: 'periodo_de_pago',
      width: 200,
    },
    {
      titleText: 'Finalidad del Gasto',
      dataIndex: 'finalidad_del_gasto',
      key: 'finalidad_del_gasto',
      width: 200,
    },
    {
      titleText: 'Importe Total',
      key: 'importe_total',
      dataIndex: 'importe_total',
      width: 150,
      align: 'right',
    },
    {
      titleText: 'Solicitante',
      dataIndex: 'solicitante',
      key: 'solicitante',
      width: 200,
    },
    {
      titleText: 'Puesto del Solicitante',
      dataIndex: 'puesto_solicitante',
      key: 'puesto_solicitante',
      width: 200,
    },
    {
      titleText: 'Área del Solicitante',
      dataIndex: 'area_solicitante',
      key: 'area_solicitante',
      width: 200,
    },
    {
      titleText: 'Elaboró',
      dataIndex: 'elaboro',
      key: 'elaboro',
      width: 200,
    },
    {
      titleText: 'Puesto de quien Elaboró',
      dataIndex: 'puesto_elaboro',
      key: 'puesto_elaboro',
      width: 200,
    },
    {
      titleText: 'Área de quien Elaboró',
      dataIndex: 'area_elaboro',
      key: 'area_elaboro',
      width: 200,
    },
    {
      titleText: 'Prioridad',
      dataIndex: 'prioridad',
      key: 'prioridad',
      width: 200,
    },
    {
      titleText: 'Fecha Requerida de Pago',
      dataIndex: 'fecha_requerida_de_pago',
      key: 'fecha_requerida_de_pago',
      width: 230,
    },
    {
      titleText: 'Observaciones',
      dataIndex: 'observaciones',
      key: 'observaciones',
      width: 200,
    },
    {
      titleText: 'Estado',
      dataIndex: 'estado',
      key: 'estado',
      width: 200,
    },
    {
      titleText: 'Póliza',
      dataIndex: 'poliza',
      key: 'poliza',
      width: 200,
    },
    {
      titleText: 'Movimiento Presupuestal',
      dataIndex: 'movimiento_presupuestal',
      key: 'movimiento_presupuestal',
      width: 200,
    },
    {
      titleText: 'Contra-Recibo',
      dataIndex: 'contra_recibo',
      key: 'contra_recibo',
      width: 200,
    },
    {
      titleText: 'Cuenta por Pagar',
      dataIndex: 'cuenta_por_pagar',
      key: 'cuenta_por_pagar',
      width: 200,
    },
    {
      titleText: 'Orden de Pago',
      dataIndex: 'orden_de_pago',
      key: 'orden_de_pago',
      width: 200,
    },
    {
      titleText: 'Pago',
      dataIndex: 'pago',
      key: 'pago',
      width: 200,
    },
  ];

  const columnsSolicitud = [
    {
      titleText: 'Rubro',
      dataIndex: 'rubro',
      key: 'rubro',
      width: 200,
    },
    {
      titleText: 'Clave Presupuestal',
      dataIndex: 'clave_presupuestal',
      key: 'clave_presupuestal',
      width: 200,
    },
    {
      titleText: 'COG',
      dataIndex: 'cog',
      key: 'cog',
      width: 220,
    },
    {
      titleText: 'Concepto de COG',
      dataIndex: 'concepto_cog',
      key: 'concepto_cog',
      width: 200,
    },
    {
      titleText: 'Techo Presupuestal',
      dataIndex: 'techo_presupuestal',
      key: 'techo_presupuestal',
      width: 200,
    },
    {
      titleText: 'Subtotal',
      dataIndex: 'subtotal',
      key: 'subtotal',
      width: 200,
    },
    {
      titleText: '%IVA',
      dataIndex: 'porcentaje_iva',
      key: 'porcentaje_iva',
      width: 200,
    },
    {
      titleText: 'IVA',
      dataIndex: 'iva',
      key: 'iva',
      width: 200,
    },
    {
      titleText: 'Total',
      key: 'total',
      dataIndex: 'total',
      width: 150,
      align: 'right',
    },
  ];

  const columnComprobante = [
    {
      titleText: 'Tipo de comprobante',
      dataIndex: 'tipo_comprobante',
      key: 'tipo_comprobante',
      width: 200,
    },
    {
      titleText: 'Fecha',
      dataIndex: 'fecha',
      key: 'fecha',
      width: 200,
    },
    {
      titleText: 'Folio',
      dataIndex: 'folio',
      key: 'folio',
      width: 200,
    },
    {
      titleText: 'Emisor',
      dataIndex: 'emisor',
      key: 'emisor',
      width: 200,
    },
    {
      titleText: 'RFC',
      dataIndex: 'rfc',
      key: 'rfc',
      width: 200,
    },
    {
      titleText: 'Importe Total',
      dataIndex: 'importe_total',
      key: 'importe_total',
      width: 200,
    },
  ];

  const columnFactura = [
    {
      titleText: 'Folio Fiscal',
      dataIndex: 'folio_fiscal',
      key: 'folio_fiscal',
      width: 200,
    },
    {
      titleText: 'Folio',
      dataIndex: 'folio',
      key: 'folio',
      width: 200,
    },
    {
      titleText: 'Nombre de Emisor',
      dataIndex: 'nombre_emisor',
      key: 'nombre_emisor',
      width: 200,
    },
    {
      titleText: 'Tipo CFDI',
      dataIndex: 'tipo_cfdi',
      key: 'tipo_cfdi',
      width: 200,
    },
    {
      titleText: 'RFC',
      dataIndex: 'rfc',
      key: 'rfc',
      width: 200,
    },
    {
      titleText: 'Importe Total',
      dataIndex: 'importe_total',
      key: 'importe_total',
      width: 200,
    },
  ];

  const columnFlujo = [
    {
      titleText: 'No.',
      dataIndex: 'no',
      key: 'no',
      width: 200,
    },
    {
      titleText: 'Actividad',
      dataIndex: 'actividad',
      key: 'actividad',
      width: 200,
    },
    {
      titleText: 'Rol de Firma',
      dataIndex: 'rol_firma',
      key: 'rol_firma',
      width: 200,
    },
    {
      titleText: 'Fecha de Realización',
      dataIndex: 'fecha_realizacion',
      key: 'fecha_realizacion',
      width: 220,
    },
    {
      titleText: 'Puesto',
      dataIndex: 'puesto',
      key: 'puesto',
      width: 200,
    },
    {
      titleText: 'Empleado',
      dataIndex: 'empleado',
      key: 'empleado',
      width: 200,
    },
    {
      titleText: 'Área',
      dataIndex: 'area',
      key: 'area',
      width: 200,
    },
    {
      titleText: 'Estado de Proceso',
      dataIndex: 'estado_proceso',
      key: 'area',
      width: 200,
    },
    {
      titleText: 'Documento a Adjuntar',
      dataIndex: 'documento_adjuntar',
      key: 'documento_adjuntar',
      width: 200,
    },
    {
      titleText: 'Documento que se Adjunta',
      dataIndex: 'documento_adjuntar',
      key: 'documento_que_adjunta',
      width: 250,
    },
    {
      titleText: 'Falta Adjunto',
      dataIndex: 'falta_adjunto',
      key: 'falta_adjunto',
      width: 200,
    },
    {
      titleText: 'Autorizado/Rechazado',
      dataIndex: 'autorizado_rechazado',
      key: 'autorizado_rechazado',
      width: 200,
    },
    {
      titleText: 'Causas de Rechazo',
      dataIndex: 'causas_rechazo',
      key: 'causas_rechazo',
      width: 200,
    },
    {
      titleText: 'Observaciones',
      dataIndex: 'observaciones',
      key: 'observaciones',
      width: 200,
    },
    {
      titleText: 'Días de Dilación',
      dataIndex: 'dias_dilacion',
      key: 'dias_dilacion',
      width: 200,
    },
  ];

  function AddFactura() {
    return (
      <>
        <Form
          layout="vertical"
        >
          <Row gutter={10}>
            <Col xs={12} sm={12} md={4}>
              <Text>Cargar CFDI</Text>
            </Col>
            <Col xs={24} sm={24} md={11}>
              <Uploader
                limitMB={50}
                file={form.getFieldValue('cfdi')}
                formItemName="cfdi"
                allowedExtensions={['pdf']}
                allowPreview={false}
              />
            </Col>
            <Col xs={24} sm={24} md={12} />
          </Row>
        </Form>
        <Row gutter={10}>
          <Col md={24} sm={24} xs={24}>
            <Form
              layout="vertical"
              labelAlign="left"
              labelWrap
            >
              <Row gutter={10}>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="nombre_receptor"
                    label="Nombre de Receptor"
                    hasFeedback
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="rfc_receptor"
                    label="RFC de Receptor"
                    hasFeedback
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="version_cfdi"
                    label="Versión CFDI"
                    hasFeedback
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="folio_cfdi"
                    label="Folio CFDI"
                    hasFeedback
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="folio_factura"
                    label="Folio de Factura"
                    hasFeedback
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="fecha"
                    label="Fecha"
                    hasFeedback
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="lugar_expedicion"
                    label="Lugar de Expedición"
                    hasFeedback
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="tipo_cfdi"
                    label="Tipo de CFDI"
                    hasFeedback
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="nombre_proveedor"
                    label="Nombre de Proveedor"
                    hasFeedback
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="RFC"
                    label="RFC"
                    hasFeedback
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="regimen_fiscal"
                    label="Régimen Fiscal"
                    hasFeedback
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="uso_cdfi"
                    label="Uso CFDI"
                    hasFeedback
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="metodo_pago"
                    label="Método de Pago"
                    hasFeedback
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="forma_pago"
                    label="Forma de Pago"
                    hasFeedback
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="moneda"
                    label="Moneda"
                    hasFeedback
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="subtotal"
                    label="Subtotal"
                    hasFeedback
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="iva"
                    label="IVA"
                    hasFeedback
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="total"
                    label="Total"
                    hasFeedback
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="isr"
                    label="Retención ISR"
                    hasFeedback
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="retencioniva"
                    label="Retención IVA"
                    hasFeedback
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="retencion_cedular"
                    label="Retención Cedular"
                    hasFeedback
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="neto"
                    label="Neto"
                    hasFeedback
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </>
    );
  }

  function Blank() {
    return <Row />;
  }

  function TabsFactura() {
    return (
      <Tabs
        activeKey={currentTabFactura}
        onChange={mutableFactura ? setCurrentTabFactura : onChangeTabFactura}
        type="card"
      >
        <TabPane
          tab="General"
          key="general"
          forceRender
        >
          <Table
            cols={columnFactura}
            data={[]}
            rowSelection={rowSelection}
            handleOnRowClick={handleOnRowClick}
            mobileColIndex={0}
            controls={{
              onClickAdd: () => onAgregarFileFactura(),
            }}
          />
          <Divider plain> </Divider>
          {
          visibleFactura ? (<AddFactura />) : (<Blank />)
        }
        </TabPane>
      </Tabs>
    );
  }

  const complexForms = [{ key: 'solicitud', formInstance: null }];
  const handleOnFinish = (vals, _continue = false) => {
    const match = complexForms.find((e) => e.key === currentTabKey);
    if (match) {
      match.formInstance.onFinishHandler(null, _continue);
    }
  };

  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible
          ? (
            <Table
              cols={columns}
              data={data}
              rowSelection={rowSelection}
              handleOnRowClick={handleOnRowClick}
              mobileColIndex={0}
              controls={
                {
                  onClickAdd,
                  onClickEdit,
                  onClickDelete,
                }
              }
              customActions={[
                {
                  onClick: onRechazar,
                  text: 'Rechazar',
                  icon: <CloseOutlined />,
                  disabledBySelection: false,
                },
                {
                  onClick: onAutorizar,
                  text: 'Autorizar',
                  icon: <CheckCircleOutlined />,
                  disabledBySelection: false,
                },
                {
                  onClick: onDownloadPdf,
                  icon: <FilePdfOutlined />,
                  text: 'Descargar PDF',
                  disabledBySelection: false,
                },
                {
                  onClick: onDownLoadExcel,
                  icon: <FileExcelOutlined />,
                  text: 'Descargar Excel',
                  disabledBySelection: false,
                },
              ]}
            />
          )
          : (
            <>
              <Row gutter={10}>
                <Col md={24} sm={24} xs={24}>
                  <Form
                    layout="vertical"
                    labelAlign="left"
                    labelWrap
                    onFinish={onFinish}
                    scrollToFirstError
                    name="solicitud"
                  >
                    <Row gutter={10}>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="solicitud_pago"
                          label="No. de Solicitud de Pago"
                        >
                          <Input allowClear disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="fecha_elaboracion"
                          label="Fecha de Elaboración"
                        >
                          <Input allowClear disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="folio_agrupado"
                          label="Folio Agrupador"
                        >
                          <Input allowClear disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="solicitante"
                          label="Solicitante"
                          rules={rules.required}
                        >
                          <Select allowClear />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="puesto_solicitante"
                          label="Puesto del Solicitante"
                        >
                          <Input allowClear disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="area_solicitante"
                          label="Área del Solicitante"
                        >
                          <Input allowClear disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="elaboro"
                          label="Elaboró"
                        >
                          <Input allowClear disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="puesto_quien_elaboro"
                          label="Puesto de quien Elaboró"
                        >
                          <Input allowClear disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="area_quien_elaboro"
                          label="Área de quien Elaboró"
                        >
                          <Input allowClear disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="prioridad"
                          label="Prioridad"
                          rules={rules.required}
                        >
                          <Input allowClear />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="fecha_requerida_pago"
                          label="Fecha Requerida de Pago"
                          rules={rules.required}
                        >
                          <DatePicker allowClear />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="concepto_solicitud_pago"
                          label="Concepto de Solicitud de Pago"
                          rules={rules.required}
                        >
                          <Input allowClear />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="periodo_pago"
                          label="Periodo de Pago"
                          rules={rules.required}
                        >
                          <Input allowClear />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="concepto_general"
                          label="Concepto General"
                        >
                          <Input allowClear disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="finalidad_gasto"
                          label="Finalidad del Gasto"
                        >
                          <Input allowClear disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="beneficiario"
                          label="Beneficiario"
                        >
                          <Input allowClear disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="forma_pago"
                          label="Forma de Pago"
                        >
                          <Input allowClear disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="baco"
                          label="Banco"
                        >
                          <Input allowClear disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="clabe_interbancaria"
                          label="Clabe Interbancaria"
                        >
                          <Input allowClear disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="no_convenio_ciec"
                          label="No. de Convenio CIEC"
                        >
                          <Input allowClear disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="no_de_referencia"
                          label="No. de Referencia"
                        >
                          <Input allowClear disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={16}>
                        <Form.Item
                          name="pago_referenciado"
                          label="Pago Referenciado"
                        >
                          <Input allowClear disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="estado"
                          label="Estado"
                        >
                          <Input allowClear disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={24}>
                        <Form.Item
                          name="observaciones"
                          label="Observaciones"
                        >
                          <Input allowClear />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
              <Card
                className="form-container"
                title={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Solicitud`}
                extra={(
                  <>
                    <Button
                      icon={<ArrowLeftOutlined />}
                      className="ant-btn ant-btn-link"
                      onClick={onCancel}
                    >
                      Regresar
                    </Button>
                    <Button
                      icon={<SaveOutlined />}
                      className="ant-btn ant-btn-link"
                      onClick={basicKeys
                        .concat(complexForms.map((e) => e.key))
                        .includes(currentTabKey)
                        ? handleOnFinish : null}
                    >
                      Guardar
                    </Button>
                    <Button
                      icon={<CheckCircleOutlined />}
                      className="ant-btn ant-btn-link"
                      onClick={onAutorizarSolicitudPago}
                    >
                      Autorizar
                    </Button>
                  </>
                )}
                bordered={false}
              >
                <Tabs
                  activeKey={currentTabKey}
                  onChange={mutable ? setCurrentTabKey : onChangeTabKey}
                  type="card"
                >
                  <TabPane
                    tab="Solicitud"
                    key="solicitud"
                    forceRender
                  >
                    <Table
                      cols={columnsSolicitud}
                      data={data}
                      rowSelection={rowSelection}
                      handleOnRowClick={handleOnRowClick}
                      mobileColIndex={0}
                      customActions={[
                        {
                          onClick: onAplicarReserva,
                          text: 'Aplicar Reserva',
                          icon: <CheckCircleOutlined />,
                          disabledBySelection: false,
                        },
                        {
                          onClick: onRechazarSolicitud,
                          icon: <CloseOutlined />,
                          text: 'Rechazar',
                          disabledBySelection: false,
                        },
                      ]}
                    />
                  </TabPane>
                  <TabPane
                    tab="Factura"
                    key="factura"
                    forceRender
                  >
                    <Row gutter={10}>
                      <Col span={24}>
                        <TabsFactura />
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane
                    tab="Comprobante"
                    key="comprobante"
                    forceRender
                  >
                    <Form
                      layout="horizontal"
                    >
                      <Row gutter={10}>
                        <Col span={24}>
                          <Uploader
                            title="Adjuntar Comprobante"
                            limitMB={50}
                            file={form.getFieldValue('documento_comprobante')}
                            formItemName="documento_comprobante"
                            allowedExtensions={['pdf']}
                            allowPreview={false}
                          />
                        </Col>
                      </Row>
                    </Form>
                    <Table
                      cols={columnComprobante}
                      data={data}
                      rowSelection={rowSelection}
                      handleOnRowClick={handleOnRowClick}
                      mobileColIndex={0}
                      controls={{
                        onClickAdd: () => onAgregarComprobante(),
                        onClickEdit: () => onEditarComprobante(),
                        onClickDelete: () => onEliminarComprobante(),
                      }}
                    />
                  </TabPane>
                  <TabPane
                    tab="Flujo Proceso Operativo"
                    key="flujo_proceso_operativo"
                    forceRender
                  >
                    <Table
                      cols={columnFlujo}
                      data={data}
                      rowSelection={rowSelection}
                      handleOnRowClick={handleOnRowClick}
                      mobileColIndex={0}
                      customActions={[
                        {
                          onClick: onExportPDFProceso,
                          text: 'Descargar PDF',
                          icon: <FilePdfOutlined />,
                          disabledBySelection: false,
                        },
                        {
                          onClick: onExportExcelProceso,
                          icon: <FileExcelOutlined />,
                          text: 'Descargar Excel',
                          disabledBySelection: false,
                        },
                      ]}
                    />
                  </TabPane>
                </Tabs>
              </Card>
            </>
          )}
        <Modal
          visible={visibleModalAgregarComprobante}
          closable={false}
          maskClosable={!loading}
          keyboard={!loading}
          footer={null}
          title={(
            <FormSubmitControls
              label={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Comprobante de Pago`}
              onFinish={onFinish}
              onCancel={onCancelModalComprobante}
              loading={loading}
            />
        )}
          onCancel={onCancelModalComprobante}
          forceRender
        >
          <Form
            form={form}
            layout="vertical"
            name="agregar_comprobante"
            onFinish={onFinish}
            initialValues={{ estados_globales: 1 }}
          >
            <Col span={24}>
              <Form.Item
                name="tipo_comprobante"
                label="Tipo de Comprobante"
                rules={rules.required}
              >
                <Input allowClear disabled />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="fecha"
                label="Fecha"
                rules={rules.required}
              >
                <DatePicker allowClear />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="folio"
                label="Folio"
                rules={rules.required}
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="importe"
                label="Importe"
                rules={rules.required}
              >
                <Input allowClear />
              </Form.Item>
            </Col>
          </Form>
        </Modal>
      </Spin>
    </Row>
  );
}

export default SolicitudesDePago;
