import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Typography,
  Row,
} from 'antd';

import ModalDeleteControls from './ModalDeleteControls';

const { Text } = Typography;

function ModalDelete({
  onDelete,
  onCancel,
  content,
  visible,
  loading,
}) {
  return (
    <Modal
      title={(
        <Row justify="space-between">
          <Text>
            Eliminación
          </Text>
          <ModalDeleteControls
            onDelete={onDelete}
            onCancel={onCancel}
            loading={loading}
          />
        </Row>
    )}
      className="modal-delete"
      visible={visible}
      footer={null}
      closable={false}
      maskClosable={!loading}
      keyboard={!loading}
      destroyOnClose
    >
      {typeof content === 'string' ? (
        <>
          <Text style={{ fontSize: 16 }}>
            ¿Desea Eliminar
            {' '}
            {content}
            ?
          </Text>
          <Text>
            Solo será posible si no ha sido utilizado en otros procesos
          </Text>
        </>
      ) : (
        content
      )}
    </Modal>
  );
}

ModalDelete.propTypes = {
  onDelete: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
  visible: PropTypes.bool,
  loading: PropTypes.bool,
};

ModalDelete.defaultProps = {
  visible: false,
  loading: false,
};

export default ModalDelete;
