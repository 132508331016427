import React, { useState } from 'react';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Spin,
  Typography,
  message,
} from 'antd';
import { SET_ESTADOS_GLOBALES } from 'store/reducers/catalogs';
import { LOGIN } from 'store/reducers/auth';
import { SET_THEME } from 'store/reducers/app';
import { useDispatch } from 'react-redux';
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import '../index.scss';
import { useHistory } from 'react-router-dom';
import logoEntity from 'assets/logo.png';
import API from 'utils/api';
import fetchSome from 'utils/fetchSome';
import { onError } from 'utils/handlers';
import { defSigob, themesAsArray } from 'themes';

const { Title, Text } = Typography;

function Login() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      const response = await API.post('usuarios/login/', values);
      if (response?.status === 200) {
        const resInfoUser = await API.get('usuarios/id/', {
          headers: {
            authorization: `Bearer ${response.data.access}`,
          },
        });
        if (resInfoUser?.status === 200) {
          const { data: infoUser } = resInfoUser;
          const { access, refresh } = response.data;
          if (infoUser.entidad || infoUser.entidad_municipal) {
            const theme = themesAsArray.find((t) => t.name === infoUser.tema) || defSigob;
            dispatch({
              type: SET_THEME,
              payload: theme,
            });
            dispatch({
              type: LOGIN,
              payload: {
                token: access,
                refreshToken: refresh,
                ...infoUser,
              },
            });
            await fetchSome('catalogos/estados-globales/', SET_ESTADOS_GLOBALES);
          } else {
            message.warn('No se ha podido iniciar sesión');
          }
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
  };

  return (
    <Row align="center" justify="end" className="container-login">
      <Col className="bgContainer" />
      <Col className="footer">
        <Title style={{ fontWeight: 'bold' }} level={2}>
          Planificador de Recursos de Gobierno
        </Title>
      </Col>
      <Col xs={24} sm={24} md={6} id="login-wrapper">
        <Spin tip="Cargando..." spinning={loading}>
          <Col md={24} sm={12} id="login-container">
            <Row style={{ marginBottom: 15 }}>
              <img src={logoEntity} style={{ width: 150 }} alt="logo" />
            </Row>
            <br />
            <Title
              style={{
                fontWeight: 'bold',
                color: '#808080',
              }}
              level={2}
            >
              Bienvenido
            </Title>
            <br />
            <Form
              name="login-form"
              layout="vertical"
              form={form}
              onFinish={onFinish}
              scrollToFirstError
            >
              <Form.Item
                name="email"
                rules={rules.required}
                label="Correo"
                hasFeedback
              >
                <Input prefix={<MailOutlined />} allowClear />
              </Form.Item>
              <Form.Item
                name="password"
                rules={rules.required}
                label="Contraseña"
                hasFeedback
              >
                <Input.Password prefix={<LockOutlined />} allowClear />
              </Form.Item>
              <Form.Item style={{ marginBottom: 0 }}>
                <Button
                  type="link"
                  className="auth"
                  style={{ width: '100%', textAlign: 'right' }}
                  onClick={() => history.push('/recuperar-contrasena')}
                >
                  ¿Olvidó su contraseña?
                </Button>
              </Form.Item>
              <Form.Item style={{ marginBottom: 0 }}>
                <Button
                  htmlType="submit"
                  shape="round"
                  className="auth-submit-button"
                  block
                >
                  Iniciar sesión
                </Button>
              </Form.Item>
            </Form>
          </Col>
          <Text className="version">
            Versión 1.0
          </Text>
        </Spin>
      </Col>
    </Row>
  );
}

export default Login;
