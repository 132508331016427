/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Tabs,
  Spin,
  Typography,
  message,
} from 'antd';
import FormSubmitControls from 'components/FormSubmitControls';
// import getFormatedValues, { formatReceived } from 'utils/formatValues';
import API from 'utils/api';
import { onError, onSuccess } from 'utils/handlers';
import Select from 'components/Select';
import { useSelector } from 'react-redux';
import ModalDelete from 'components/ModalDelete';
import Table from 'components/Table';
import { toInteger } from 'lodash';
import NumericInput from 'components/NumericInput';

const { TabPane } = Tabs;

function AvancesProgramasPresupuestarios({
  selectedRowKey: programa_presupuestario_mir,

}) {
  const baseURI = '/presupuestos/avance-programas-mir/';
  // const periodoFiscal = useSelector(({ auth }) => auth.periodoFiscalSelected);
  const [form] = Form.useForm();
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentTabKey, setCurrentTabKey] = useState('fisico');
  const [data, setData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [fichas, setFichas] = useState([]);
  const [calendarizacionSeleccionada, setCalendarizacionSeleccionada] = useState();
  const [avances, setAvances] = useState([]);

  const [presupuestoAnual, setPresupuestoAnual] = useState();
  const [presupuestosAnuales, setPresupuestosAnuales] = useState([]);

  const [fichaFlag, setFichaFlag] = useState();
  const [presupuestoFlag, setPresupuestoFlag] = useState();

  const [allowAdd, setAllowAdd] = useState(false);
  const formInstances = [
    form,
  ];
  const fetchData = async () => {
    try {
      setLoading(true);
      if (programa_presupuestario_mir) {
        const res = await API.get(baseURI, {
          params: {
            programa_presupuestario_mir,
            numero_de_periodo: 1,
          },
        });
        if (res.data.length === 0) {
          setAllowAdd(true);
        }
        if (res?.status === 200) {
          setData(res.data);
        }
        const resAvances = await API.get(baseURI, {
          params: {
            programa_presupuestario_mir,
          },
        });
        setAvances(resAvances.data);
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    setCurrentTabKey('fisico');
    setSelectedRowKeys([]);
    setCalendarizacionSeleccionada();
    setPresupuestoAnual();
    setFichaFlag();
    setPresupuestoFlag();
    setAllowAdd(false);
  };

  useEffect(() => {
    let mounted = true;
    const fetchAll = async () => {
      try {
        setLoading(true);
        if (mounted) {
          setLoading(false);
          await fetchData();
        }
        const resFichas = await API.get('/presupuestos/ficha-tecnica-del-indicador/');
        setFichas(resFichas.data);

        const resPresupuesto = await API.get('/configuraciones/presupuestos-anuales-de-egreso/');
        setPresupuestosAnuales(resPresupuesto.data);

        const resAvances = await API.get(baseURI, {
          params: {
            programa_presupuestario_mir,
          },
        });
        setAvances(resAvances.data);
      } catch (err) {
        onError(err, setLoading);
      }
    };
    fetchAll();
    return () => { mounted = false; };
    // eslint-disable-next-line
  }, [programa_presupuestario_mir]);

  // console.log(response.data.reduce((acum, current) => acum + current.importe, 0));

  const getAsgnacionesMensuales = async (id) => {
    try {
      setLoading(true);
      const response = await API.get(`/configuraciones/presupuestos-anuales-de-egreso/${id}/asignaciones-mensuales/`);
      const resPresupuesto = presupuestosAnuales.find((e) => e.id === id);
      setPresupuestoAnual(resPresupuesto.monto_total);
      const asignaciones = [];
      let valorModulo = 0;
      switch (calendarizacionSeleccionada) {
        case 1:
          valorModulo = 12;
          break;
        case 2:
          valorModulo = 6;
          break;
        case 3:
          valorModulo = 4;
          break;
        case 4:
          valorModulo = 3;
          break;
        case 5:
          valorModulo = 2;
          break;
        case 6:
          valorModulo = 1;
          break;
        default:
          message.info('No se encontro calendarizacion');
          break;
      }
      // eslint-disable-next-line no-plusplus
      for (let i = 0, suma = 0, mod = 1, numero = 0; i < response.data.length; i++, mod++) {
        suma += response.data[i].importe;
        if (mod % valorModulo === 0) {
          asignaciones.push({
            numero: numero + 1,
            presupuesto_aprobado: suma,
            presupuesto_ejercido: 0,
          });
          mod = 0;
          suma = 0;
          numero += 1;
        }
      }
      const values = Object.fromEntries(asignaciones.map((e) => [e.numero - 1, e]));
      setTimeout(() => {
        form.setFieldsValue({
          ...values,
        });
      });
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const getAvanceFinanciero = () => {
    try {
      const values = {
        ...form.getFieldsValue(),
      };
      const metasKeys = Object.keys(values).filter((e) => /\d+/.test(e));
      // Aqui saco los datos de las tablas y las meto en arrays
      const _metas = [];
      metasKeys.forEach((e) => {
        _metas.push(values[e]);
        delete values[e];
      });

      const formattedValues = _metas.map((e) => ({
        ...e,
        diferencia_en_pesos: e.presupuesto_aprobado - parseFloat(e?.presupuesto_ejercido || 0),
        presupuesto_ejercido: parseFloat(e.presupuesto_ejercido),
      }));

      // Avance Financiero Acumulado
      // eslint-disable-next-line no-plusplus
      for (let i = 0, aprob = 0, ejer = 0, dif = 0; i < formattedValues.length; i++) {
        // Aprob = Acumulador del presupuesto aprobado
        aprob += formattedValues[i].presupuesto_aprobado;
        formattedValues[i].presupuesto_aprobado_acumulado = aprob;

        // Ejer = Acumulador del presupuesto ejercido
        ejer += formattedValues[i].presupuesto_ejercido;
        formattedValues[i].presupuesto_ejercido_acumulado = parseFloat(ejer.toFixed(2));

        // Dif = Acumulador de la diferencia de pesos
        dif += formattedValues[i].diferencia_en_pesos;
        formattedValues[i].diferencia_en_pesos_acumulados = parseFloat(dif.toFixed(2));

        // Calculo del Avance Planeado en Porcentaje Acumulado
        formattedValues[i].avance_planeado_en_porcentaje_acumulado = parseFloat(((formattedValues[i]
          .presupuesto_aprobado_acumulado * 100) / presupuestoAnual).toFixed(2));

        // Calculo del Avance Planeado en Porcentaje Acumulado
        formattedValues[i]
          .avance_planeado_real_porcentaje_acumulado = parseFloat(((formattedValues[i]
            .presupuesto_ejercido_acumulado * 100) / presupuestoAnual).toFixed(2));

        // Calculo de diferecias de porcentaje acumulado
        formattedValues[i].diferencia_de_porcentaje_acumulado = parseFloat((formattedValues[i]
          .avance_planeado_en_porcentaje_acumulado
        - formattedValues[i].avance_planeado_real_porcentaje_acumulado).toFixed(2));
      }
      setTimeout(() => {
        form.setFieldsValue({
          ...formattedValues,
        });
      });
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = {
        ...form.getFieldsValue(),
        presupuestacion_mir: programa_presupuestario_mir,
      };
      const resFicha = fichas.find((e) => e.id === values.ficha_tecnica_del_indicador);

      values.matriz_de_indicadores = resFicha.matriz_de_indicadores;

      const metasKeys = Object.keys(values).filter((e) => /\d+/.test(e));
      const _metas = [];
      metasKeys.forEach((e) => {
        _metas.push(values[e]);
        delete values[e];
      });
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < _metas.length; i++) {
        // Aqui se agregan los campos que se necesitan dentro del arreglo que se guardara
        _metas[i].numero_de_periodo = i + 1;
        _metas[i].ficha_tecnica_del_indicador = values.ficha_tecnica_del_indicador;
        _metas[i].matriz_de_indicador_de_resultados = values.matriz_de_indicadores;
        _metas[i].programa_presupuestario_mir = values.presupuestacion_mir;
        _metas[i].presupuesto_anual_de_egresos = values.presupuesto_anual_de_egresos;
      }
      if (!selectedRowKeys.length) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < _metas.length; i++) {
          // eslint-disable-next-line no-await-in-loop
          const response = await API.post(baseURI, _metas[i]);
          if (response.status === 201 && i === _metas.length - 1) {
            onSuccess(response, 'Agregado Correctamente');
            // eslint-disable-next-line no-await-in-loop
            await fetchData();
            onCancel();
          }
        }
      } else {
        // const [key] = selectedRowKeys;
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < avances.length; i++) {
          // eslint-disable-next-line no-await-in-loop
          const response = await API.put(`${baseURI}${avances[i].id}/`, _metas[i]);
          if (response.status === 200 && i === avances.length - 1) {
            onSuccess(response, 'Editado Correctamente');
            onCancel();
            // eslint-disable-next-line no-await-in-loop
            await fetchData();
          }
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading, formInstances, setCurrentTabKey);
    }
  };

  const columns = [
    {
      titleText: 'Ficha del Indicador',
      dataIndex: 'ficha_tecnica_del_indicador',
      key: 'ficha_tecnica_del_indicador',
      width: 150,
      render: (val) => fichas.find((e) => e.id === val)?.definicion_del_indicador,
    },
    {
      titleText: 'Presupuesto Anual de Egreso',
      dataIndex: 'presupuesto_anual_de_egresos',
      key: 'presupuesto_anual_de_egresos',
      width: 350,
      render: (val) => presupuestosAnuales.find((e) => e.id === val)?.clave_presupuestal_visible,
    },
    {
      titleText: 'Estado',
      dataIndex: 'estados_globales',
      key: 'estados_globales',
      width: 160,
      render: (id) => estadosGlobales.find((eg) => eg.id === id)?.descripcion,
    },
  ];

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    setTimeout(() => {
      form.setFieldsValue(record);
    });
  };

  const onClickAdd = async () => {
    onCancel();
    fetchData();
    setVisible(true);
  };

  const onClickEdit = async () => {
    try {
      setLoading(true);
      const [key] = selectedRowKeys;
      const match = data.find((e) => e.id === key);
      const matchFicha = fichas.find((e) => e.id === match.ficha_tecnica_del_indicador);
      setFichaFlag(true);
      setPresupuestoFlag(true);
      setCalendarizacionSeleccionada(matchFicha.frecuencia_de_medicion);
      const values = Object.fromEntries(avances.map((e) => [e.numero_de_periodo - 1, e]));

      setTimeout(() => {
        form.setFieldsValue({
          ...values,
          formula_de_calculo: matchFicha?.formula_de_calculo,
          nivel: toInteger(match.nivel),
        });
      });

      setLoading(false);
    } catch (error) {
      onError(error, setLoading);
    }
    setVisible(true);
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const deleteItem = async () => {
    try {
      if (programa_presupuestario_mir) {
        if (selectedRowKeys.length) {
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < avances.length; i++) {
            // eslint-disable-next-line no-await-in-loop
            const response = await API.delete(`${baseURI}${avances[i].id}/`);
            if (response?.status === 204 && i === avances.length - 1) {
              onSuccess(response, 'Eliminado correctamente');
              onCancel();
              // eslint-disable-next-line no-await-in-loop
              await fetchData();
              setVisibleAlert(false);
            }
          }
        }
      }
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const calculate = (expression, a, b) => {
    const newString = expression.replace('(', '')
      .replace(')', '')
      .replace('a', a.toString())
      .replace('b', b.toString())
      .replace('A', a.toString())
      .replace('B', b.toString());

    if (newString.substr(-1) !== '/'
    && newString.substr(-1) !== '*'
    && newString.substr(-1) !== '-'
    && newString.substr(-1) !== '+') {
      const regex = /(\d+)([+\-*/]\d+)*/g;
      const isValid = regex.test(newString);
      if (isValid) {
      // eslint-disable-next-line no-new-func
        return Function(`
      'use strict';
      try {
        return (${newString});
      } catch {
        return 0
      }
      `)();
      }
    }
    return 0;
  };

  // const requiredRule = {
  //   message: 'Este campo es requerido',
  // };

  // const rules = {
  //   required: [requiredRule],
  // };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const calendarizacionesMap = {
    1: ['Enero - Diciembre'],

    2: ['1er Semestre Enero - Junio',
      '2do Semestre Julio - Diciembre',
    ],

    3: ['1er Cuatrimestre Enero - Abril',
      '2do Cuatrimestre Mayo - Agosto',
      '3er Cuatrimestre Septiembre - Diciembre',
    ],

    4: ['1er Trimestre Enero - Marzo',
      '2do Trimestre Abril - Junio',
      '3er Trimestre Julio - Septiembre',
      '4to Trimestre Octubre - Diciembre',
    ],

    5: ['1er Bimestre Enero - Febrero',
      '2do Bimestre Marzo - Abril',
      '3er Bimestre Mayo - Junio',
      '4to Bimestre Julio - Agosto',
      '5to Bimestre Septiembre - Octubre',
      '6to Bimestre Noviembre - Diciembre',
    ],

    6: ['Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ],
  };

  return (
    <Row align="center" justify="center" className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible ? (
          <Table
            cols={columns}
            data={data}
            rowSelection={rowSelection}
            handleOnRowClick={handleOnRowClick}
            allowAdd={allowAdd}
            controls={{
              onClickAdd,
              onClickEdit,
              onClickDelete,
            }}
            mobileColIndex={0}
          />
        ) : (
          <Card
            bordered={false}
            className="form-container"
            title={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Avance de Programa Presupuestarios`}
            extra={(
              <FormSubmitControls
                onFinish={onFinish}
                onCancel={onCancel}
                loading={loading}
              />
            )}
          >
            <Tabs
              activeKey={currentTabKey}
              onChange={setCurrentTabKey}
              // type="card"
              tabPosition="left"

            >
              <TabPane
                key="fisico"
                tab="Avance Físico"
                forceRender
              >
                <Form
                  layout="vertical"
                  form={form}
                  onFinish={onFinish}
                  scrollToFirstError
                  initialValues={{
                    es_a_nivel_actividad: false,
                  }}
                >
                  <Form.Item
                    name="id"
                    hidden
                  >
                    <Input />
                  </Form.Item>
                  <Row gutter={10}>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        label="Ficha tecnica del indicador"
                        name="ficha_tecnica_del_indicador"
                      >
                        <Select
                          dataSource={fichas}
                          labelProp="definicion_del_indicador"
                          disabled={fichaFlag}
                          onChange={async (val) => {
                            // Se verifica la ficha seleccionada para asi pasar la id
                            const resFicha = fichas.find((e) => e.id === val);
                            setTimeout(() => {
                              form.setFieldsValue({
                                formula_de_calculo: resFicha?.formula_de_calculo,
                                matriz_de_indicadores: resFicha?.matriz_de_indicadores,
                              });
                            });
                            setCalendarizacionSeleccionada(resFicha?.frecuencia_de_medicion);

                            // Con la ID seleccionada de fichas se filtran las metas
                            const resMetas = await API.get('/presupuestos/calendarizacion-de-meta-mir/', {
                              params: {
                                ficha: val,
                              },
                            });

                            // Se crean arreglos con las metas encontradas en el filtro
                            const dataPlaneado = [];
                            const dataReal = [];
                            // eslint-disable-next-line no-plusplus
                            for (let i = 0; i < resMetas.data.length; i++) {
                              dataPlaneado.push({
                                id: resMetas.data[i].id,
                                numero: resMetas.data[i].numero,
                                variable_planeado_a: resMetas.data[i].variable_a,
                                variable_planeado_b: resMetas.data[i].variable_b,
                                resultado_estimado: resMetas.data[i].resultado,
                              });
                              dataReal.push({
                                id: resMetas.data[i].id,
                                numero: resMetas.data[i].numero,
                                variable_real_b: resMetas.data[i].variable_b,
                              });
                            }

                            // Se mapean los arrays en objetos que se utilizan para llenar los
                            // campos
                            const valuesMetas = Object
                              .fromEntries(dataPlaneado.map((e) => [e.numero - 1, e]));
                            const valuesReales = Object
                              .fromEntries(dataReal.map((e) => [e.numero - 1, e]));

                            setTimeout(() => {
                              form.setFieldsValue({
                                ...valuesMetas,
                              });
                              form.setFieldsValue({
                                ...valuesReales,
                              });
                            });
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  {calendarizacionSeleccionada && (
                  <>
                    <Typography.Title level={4}>
                      Planeado en Unidades por Periodo
                    </Typography.Title>
                    <Row gutter={10}>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          label="Formula de Calculo"
                          name="formula_de_calculo"
                        >
                          <Input
                            disabled
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                  )}
                  {/* Metas sacadas de Ficha de Indicadores --------------------------------*/}
                  {calendarizacionSeleccionada
                    && calendarizacionesMap[calendarizacionSeleccionada].map((label, i) => (
                      <Row gutter={10}>
                        <Col xs={24} sm={24} md={6}>
                          <h3>{label}</h3>
                        </Col>
                        <Col xs={24} sm={24} md={6}>
                          <NumericInput
                            label={i === 0 ? ('Variable A') : ('')}
                            name={[i, 'variable_planeado_a']}
                            decimal
                            disabled
                            allowZero
                          />
                        </Col>
                        <Col xs={24} sm={24} md={6}>
                          <NumericInput
                            label={i === 0 ? ('Variable B') : ('')}
                            name={[i, 'variable_planeado_b']}
                            decimal
                            disabled
                            allowZero
                          />
                        </Col>
                        <Col xs={24} sm={24} md={6}>
                          <NumericInput
                            label={i === 0 ? ('Resultado') : ('')}
                            name={[i, 'resultado_estimado']}
                            // prefix="%"
                            decimal
                            allowZero
                            disabled
                          />
                        </Col>
                        <Form.Item
                          name={[i, 'id']}
                          hidden
                        >
                          <Input allowClear />
                        </Form.Item>
                      </Row>
                    ))}
                  {/* Calculos con base a las metas y nuevos periodos ----------------------- */}
                  {calendarizacionSeleccionada && (
                    <>
                      <Typography.Title level={4}>
                        Real en Unidades por Periodo
                      </Typography.Title>
                      <Row gutter={10}>
                        <Col xs={24} sm={24} md={8}>
                          <Form.Item
                            label="Formula de Calculo"
                            name="formula_de_calculo"
                          >
                            <Input
                              disabled
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </>
                  )}
                  {calendarizacionSeleccionada
                    && calendarizacionesMap[calendarizacionSeleccionada].map((label, i) => (
                      <Row gutter={10}>
                        <Col xs={24} sm={24} md={6}>
                          <h3>{label}</h3>
                        </Col>
                        <Col xs={24} sm={24} md={6}>
                          <NumericInput
                            label={i === 0 ? ('Variable A') : ('')}
                            name={[i, 'variable_real_a']}
                            decimal
                              // disabled
                            allowZero
                            onChange={() => {
                              const values = {
                                ...form.getFieldsValue(),
                              };
                              if (values?.formula_de_calculo) {
                                const metasKeys = Object.keys(values).filter((e) => /\d+/.test(e));
                                // Aqui saco los datos de las tablas y las meto en arrays
                                const _metas = [];
                                metasKeys.forEach((e) => {
                                  _metas.push(values[e]);
                                  delete values[e];
                                });
                                // eslint-disable-next-line no-plusplus
                                for (let j = 0, sum = 0, sumPor = 0; j < _metas.length; j++) {
                                  if (_metas[j]?.variable_real_a && _metas[j]?.variable_real_b) {
                                    // Aqui se calculan los resultados reals
                                    _metas[j].resultado_real = parseFloat(calculate(
                                      values.formula_de_calculo,
                                      _metas[j].variable_real_a,
                                      _metas[j].variable_real_b,
                                    ).toFixed(2));

                                    // Aqui se calcula la diferencia en unidades por periodo
                                    _metas[j].diferencia_en_unidades_por_periodo = parseFloat((
                                      _metas[j].variable_planeado_a
                                        - parseFloat(
                                          parseFloat(_metas[j].variable_real_a),
                                        )
                                    ).toFixed(2));

                                    // Aqui se calcula la diferencia en porcentaje por periodo
                                    _metas[j].diferencia_de_porcentaje_por_periodo = parseFloat((
                                      _metas[j].resultado_estimado
                                        - parseFloat(
                                          parseFloat(_metas[j].resultado_real, 10),
                                        )
                                    ).toFixed(2));

                                    // Aqui se calcula la diferencia en unidades acumuladas
                                    sum += parseFloat(
                                      _metas[j]?.diferencia_en_unidades_por_periodo,
                                      10,
                                    );
                                    _metas[j].diferencia_en_unidades_acumulada = sum;

                                    // Aqui se calcula la diferencia en porcentaje acumlado
                                    sumPor += parseFloat(_metas[j]
                                      ?.diferencia_de_porcentaje_por_periodo, 10);
                                    _metas[j].diferencia_en_porcentaje_acumulada = parseFloat(
                                      sumPor.toFixed(2),
                                    );
                                  } else {
                                    _metas[j].resultado_real = 0;
                                    _metas[j].diferencia_en_unidades_por_periodo = 0;
                                    _metas[j].diferencia_de_porcentaje_por_periodo = 0;
                                  }
                                }

                                setTimeout(() => {
                                  form.setFieldsValue({
                                    ..._metas,
                                  });
                                });
                              }
                            }}
                          />
                        </Col>
                        <Col xs={24} sm={24} md={6}>
                          <NumericInput
                            label={i === 0 ? ('Variable B') : ('')}
                            name={[i, 'variable_real_b']}
                            decimal
                            disabled
                            allowZero
                          />
                        </Col>
                        <Col xs={24} sm={24} md={6}>
                          <NumericInput
                            label={i === 0 ? ('Resultado') : ('')}
                            name={[i, 'resultado_real']}
                            // prefix="%"
                            decimal
                            allowZero
                            disabled
                          />
                        </Col>
                        <Form.Item
                          name={[i, 'id']}
                          hidden
                        >
                          <Input allowClear />
                        </Form.Item>
                      </Row>
                    ))}
                  {calendarizacionSeleccionada && (
                    <Typography.Title level={4}>
                      Diferencias Avance Físico
                    </Typography.Title>
                  )}
                  {calendarizacionSeleccionada && (
                    <Typography.Title level={5}>
                      Por Periodo
                    </Typography.Title>
                  )}
                  {calendarizacionSeleccionada
                    && calendarizacionesMap[calendarizacionSeleccionada].map((label, i) => (
                      <Row gutter={10}>
                        <Col xs={24} sm={24} md={6}>
                          <h3>{label}</h3>
                        </Col>
                        <Col xs={24} sm={24} md={6}>
                          <NumericInput
                            label={i === 0 ? ('Diferencia en Unidad por periodo') : ('')}
                            name={[i, 'diferencia_en_unidades_por_periodo']}
                            decimal
                            disabled
                            allowZero
                            allowNegative
                          />
                        </Col>
                        <Col xs={24} sm={24} md={6}>
                          <NumericInput
                            label={i === 0 ? ('Diferencia en Porcentaje por Periodo') : ('')}
                            name={[i, 'diferencia_de_porcentaje_por_periodo']}
                            prefix="%"
                            decimal
                            disabled
                            allowZero
                            allowNegative
                          />
                        </Col>
                        <Form.Item
                          name={[i, 'id']}
                          hidden
                        >
                          <Input allowClear />
                        </Form.Item>
                      </Row>
                    ))}
                  {/* Diferencias acumladas -------------------------------------------------*/}
                  {calendarizacionSeleccionada && (
                    <Typography.Title level={5}>
                      Acumuladas
                    </Typography.Title>
                  )}
                  {calendarizacionSeleccionada
                    && calendarizacionesMap[calendarizacionSeleccionada].map((label, i) => (
                      <Row gutter={10}>
                        <Col xs={24} sm={24} md={6}>
                          <h3>{label}</h3>
                        </Col>
                        <Col xs={24} sm={24} md={6}>
                          <NumericInput
                            label={i === 0 ? ('Diferencia en Unidades Acumulada') : ('')}
                            name={[i, 'diferencia_en_unidades_acumulada']}
                            decimal
                            disabled
                            allowZero
                            allowNegative
                          />
                        </Col>
                        <Col xs={24} sm={24} md={6}>
                          <NumericInput
                            label={i === 0 ? ('Diferencia en Porcentajes Acumulada') : ('')}
                            name={[i, 'diferencia_en_porcentaje_acumulada']}
                            prefix="%"
                            decimal
                            disabled
                            allowZero
                            allowNegative
                          />
                        </Col>
                        <Form.Item
                          name={[i, 'id']}
                          hidden
                        >
                          <Input allowClear />
                        </Form.Item>
                      </Row>
                    ))}
                  {/* Justificaciones de diferencias -----------------------------------------*/}
                  {calendarizacionSeleccionada && (
                    <Typography.Title level={4}>
                      Justificaciones De Diferencias
                    </Typography.Title>
                  )}
                  {calendarizacionSeleccionada
                    && calendarizacionesMap[calendarizacionSeleccionada].map((label, i) => (
                      <Row gutter={10}>
                        <Col xs={24} sm={24} md={24}>
                          <h3>{label}</h3>
                        </Col>
                        <Col xs={24} sm={24} md={24}>
                          <Form.Item
                              // label="Justificación de diferencias"
                            name={[i, 'justificacion_de_diferencias']}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Form.Item
                          name={[i, 'id']}
                          hidden
                        >
                          <Input allowClear />
                        </Form.Item>
                      </Row>
                    ))}
                  <Form.Item
                    name="matriz_de_indicadores"
                    hidden
                  />
                  <Form.Item hidden>
                    <Button htmlType="submit" />
                  </Form.Item>
                </Form>
              </TabPane>
              <TabPane
                key="financiero"
                tab="Avance Financiero"
                forceRender
                // disabled={!selectedRowKeys.length}

              >
                <Form
                  layout="vertical"
                  form={form}
                  onFinish={onFinish}
                  scrollToFirstError
                >
                  <Col xs={24} sm={24} md={12}>
                    <Form.Item
                      name="presupuesto_anual_de_egresos"
                      label="Presupuesto Anual De Egreso"
                    >
                      <Select
                        dataSource={presupuestosAnuales}
                        labelProp="clave_presupuestal_visible"
                        disabled={presupuestoFlag}
                        onChange={(val) => {
                          getAsgnacionesMensuales(val);
                        }}
                      />
                    </Form.Item>
                  </Col>
                  {calendarizacionSeleccionada && (
                    <Typography.Title level={4}>
                      Avance Financiero
                    </Typography.Title>
                  )}

                  {/* Avances financieros --------------- --------------------------------*/}
                  {calendarizacionSeleccionada
                    && calendarizacionesMap[calendarizacionSeleccionada].map((label, i) => (
                      <Row gutter={10}>
                        <Col xs={24} sm={24} md={6}>
                          <h3>{label}</h3>
                        </Col>
                        <Col xs={24} sm={24} md={6}>
                          <NumericInput
                            label={i === 0 ? ('Presupuesto aprobado') : ('')}
                            name={[i, 'presupuesto_aprobado']}
                            prefix="$"
                            decimal
                            disabled
                            allowZero
                          />
                        </Col>
                        <Col xs={24} sm={24} md={6}>
                          <NumericInput
                            label={i === 0 ? ('Presupuesto Ejercido') : ('')}
                            name={[i, 'presupuesto_ejercido']}
                            prefix="$"
                            decimal
                            allowZero
                            onChange={() => {
                              getAvanceFinanciero();
                            }}
                          />
                        </Col>
                        <Col xs={24} sm={24} md={6}>
                          <NumericInput
                            label={i === 0 ? ('Diferencia en pesos') : ('')}
                            name={[i, 'diferencia_en_pesos']}
                            prefix="$"
                            decimal
                            allowNegative
                            allowZero
                            disabled
                          />
                        </Col>
                        <Form.Item
                          name={[i, 'id']}
                          hidden
                        >
                          <Input allowClear />
                        </Form.Item>
                      </Row>
                    ))}

                  {/* Avance FinancieroAcumulado ----------------------------------------- */}
                  {calendarizacionSeleccionada && (
                    <Typography.Title level={5}>
                      Avance Financiero Acumulado
                    </Typography.Title>
                  )}

                  {calendarizacionSeleccionada
                    && calendarizacionesMap[calendarizacionSeleccionada].map((label, i) => (
                      <Row gutter={10}>
                        <Col xs={24} sm={24} md={6}>
                          <h3>{label}</h3>
                        </Col>
                        <Col xs={24} sm={24} md={6}>
                          <NumericInput
                            label={i === 0 ? ('Presupuesto Aprobado Acumulado') : ('')}
                            name={[i, 'presupuesto_aprobado_acumulado']}
                            decimal
                            disabled
                            allowZero
                            prefix="$"
                          />
                        </Col>
                        <Col xs={24} sm={24} md={6}>
                          <NumericInput
                            label={i === 0 ? ('Presupuesto Ejercido Acumulado') : ('')}
                            name={[i, 'presupuesto_ejercido_acumulado']}
                            decimal
                            disabled
                            allowZero
                            prefix="$"
                          />
                        </Col>
                        <Col xs={24} sm={24} md={6}>
                          <NumericInput
                            label={i === 0 ? ('Diferencia en pesos Acumulado') : ('')}
                            name={[i, 'diferencia_en_pesos_acumulados']}
                            decimal
                            allowZero
                            disabled
                            allowNegative
                            prefix="$"
                          />
                        </Col>
                        <Form.Item
                          name={[i, 'id']}
                          hidden
                        >
                          <Input allowClear />
                        </Form.Item>
                      </Row>
                    ))}

                  {calendarizacionSeleccionada && (
                  <Typography.Title level={5}>
                    Avance Financiero Acumulado en Porcentaje
                  </Typography.Title>
                  )}
                  {calendarizacionSeleccionada
                    && calendarizacionesMap[calendarizacionSeleccionada].map((label, i) => (
                      <Row gutter={10}>
                        <Col xs={24} sm={24} md={6}>
                          <h3>{label}</h3>
                        </Col>
                        <Col xs={24} sm={24} md={6}>
                          <NumericInput
                            label={i === 0 ? ('Avance en Porcentaje Acumulado') : ('')}
                            name={[i, 'avance_planeado_en_porcentaje_acumulado']}
                            decimal
                            disabled
                            allowZero
                            prefix="%"
                          />
                        </Col>
                        <Col xs={24} sm={24} md={6}>
                          <NumericInput
                            label={i === 0 ? ('Avance Real Acumulado') : ('')}
                            name={[i, 'avance_planeado_real_porcentaje_acumulado']}
                            decimal
                            disabled
                            allowZero
                            prefix="%"
                          />
                        </Col>
                        <Col xs={24} sm={24} md={6}>
                          <NumericInput
                            label={i === 0 ? ('Diferencia en % Acumulado') : ('')}
                            name={[i, 'diferencia_de_porcentaje_acumulado']}
                            decimal
                            allowZero
                            allowNegative
                            disabled
                            prefix="%"
                          />
                        </Col>
                        <Form.Item
                          name={[i, 'id']}
                          hidden
                        >
                          <Input allowClear />
                        </Form.Item>
                      </Row>
                    ))}

                  {/* Justificaciones de diferencias de avance financiero -----------------*/}
                  {calendarizacionSeleccionada && (
                    <Typography.Title level={4}>
                      Justificaciones De Diferenecias
                    </Typography.Title>
                  )}
                  {calendarizacionSeleccionada
                    && calendarizacionesMap[calendarizacionSeleccionada].map((label, i) => (
                      <Row gutter={10}>
                        <Col xs={24} sm={24} md={24}>
                          <h3>{label}</h3>
                        </Col>
                        <Col xs={24} sm={24} md={24}>
                          <Form.Item
                              // label="Justificación de diferencias"
                            name={[i, 'justificacion_de_avance_financiero']}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Form.Item
                          name={[i, 'id']}
                          hidden
                        >
                          <Input allowClear />
                        </Form.Item>
                      </Row>
                    ))}

                  <Form.Item
                    name="presupuestacion_mir"
                    hidden
                  >
                    <Input />
                  </Form.Item>
                </Form>
              </TabPane>
            </Tabs>
          </Card>
        )}
      </Spin>
      <ModalDelete
        onDelete={deleteItem}
        onCancel={() => {
          setVisibleAlert(false);
          setSelectedRowKeys([]);
        }}
        visible={visibleAlert}
        content="Eliminar la Matriz seleccionada"
      />
    </Row>
  );
}

AvancesProgramasPresupuestarios.propTypes = {
  selectedRowKey: PropTypes.number,
};

AvancesProgramasPresupuestarios.defaultProps = {
  selectedRowKey: null,
};

export default AvancesProgramasPresupuestarios;
