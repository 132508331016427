import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Row,
  Col,
  Spin,
  Card,
  Form,
  Button,
  // Input,
  Tabs,
} from 'antd';
import FormSubmitControls from 'components/FormSubmitControls';
import Table from 'components/Table';
import ModalDelete from 'components/ModalDelete';
import API from 'utils/api';
import { onError, onSuccess } from 'utils/handlers';
import Select from 'components/Select';
import Origenes from 'views/Configuracion/Catalogos/ControlPatrimonial/Origenes';
import Identificacion from 'views/Configuracion/Catalogos/ControlPatrimonial/Identificacion';
import Registros from 'views/Configuracion/Catalogos/ControlPatrimonial/Registros';
import Afectacion from 'views/Configuracion/Catalogos/ControlPatrimonial/Afectacion';
import Ubicacion from 'views/Configuracion/Catalogos/ControlPatrimonial/Ubicacion';
import Avaluos from 'views/Configuracion/Catalogos/ControlPatrimonial/Avaluos';
import BitacoraResguardos from 'views/Configuracion/Catalogos/ControlPatrimonial/BitacoraResguardos';
import Expediente from 'views/Configuracion/Catalogos/ControlPatrimonial/Expediente';

export const permissionConfiguradorActivoFijo = {
  permissionModel: 'configuradordeactivofijo',
};

const baseURI = '/control-patrimonial/configurador-de-activo-fijo/';
const { TabPane } = Tabs;

// eslint-disable-next-line react/prop-types
function ConfiguradorActivosFijos({ permission }) {
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  // const [form] = Form.useForm();
  const [formTab] = Form.useForm();
  const [formBitacora] = Form.useForm();
  const [formOrigenes] = Form.useForm();
  const [formIdentificacion] = Form.useForm();
  const [formRegistro] = Form.useForm();
  const [formAfectacion] = Form.useForm();
  const [formUbicacion] = Form.useForm();
  const [formAvaluos] = Form.useForm();
  const [formExpediente] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [estadoCampo, setEstadoCampo] = useState([]);
  const [tipoActivo, setTipoActivo] = useState([]);

  const [currentTabKey, setCurrentTabKey] = useState('generalTab');

  const fetchData = async (page = 1, q = null) => {
    try {
      setLoading(true);
      setCurrentPage(page);
      const response = await API.get(baseURI, { params: { page, ...q } });
      if (response?.status === 200) {
        setData(response.data);
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const fetchAll = async () => {
    try {
      setLoading(true);
      const resEstadoCampo = await API.get('/controlpatrimonial/estados-del-campo/');
      setEstadoCampo(resEstadoCampo.data);
      const resTipoActivo = await API.get('/controlpatrimonial/tipos-de-activos/');
      setTipoActivo(resTipoActivo.data);
      await fetchData();
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchAll();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setSelectedRowKeys([]);
    setVisible(false);
    setCurrentTabKey('generalTab');
  };

  const onFinish = async (_values, _continue = false) => {
    try {
      setLoading(true);
      // await form.validateFields();
      await formTab.validateFields();
      await formBitacora.validateFields();
      await formOrigenes.validateFields();
      await formIdentificacion.validateFields();
      await formRegistro.validateFields();
      await formAfectacion.validateFields();
      await formUbicacion.validateFields();
      await formAvaluos.validateFields();
      await formExpediente.validateFields();
      const values = {
        ...formTab.getFieldsValue(),
        ...formBitacora.getFieldsValue(),
        ...formOrigenes.getFieldsValue(),
        ...formIdentificacion.getFieldsValue(),
        ...formRegistro.getFieldsValue(),
        ...formAfectacion.getFieldsValue(),
        ...formUbicacion.getFieldsValue(),
        ...formAvaluos.getFieldsValue(),
        ...formExpediente.getFieldsValue(),
      };
      if (!selectedRowKeys.length) {
        const response = await API.post(baseURI, values);
        if (response?.status === 201) {
          if (!_continue) {
            onSuccess(response, 'Agregado correctamente');
            onCancel();
            await fetchData();
          } else {
            await fetchData();
            setSelectedRowKeys([response.data.id]);
          }
        }
      } else {
        const [key] = selectedRowKeys;
        const response = await API.patch(`${baseURI}${key}/`, values);
        if (response?.status === 200) {
          onSuccess(response, 'Editado correctamente');
          if (!_continue) {
            onCancel();
            await fetchData();
          } else {
            await fetchData();
            setSelectedRowKeys([response.data.id]);
          }
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`${baseURI}${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          setVisibleAlert(false);
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    setTimeout(() => {
      formTab.setFieldsValue(record);
      formBitacora.setFieldsValue(record);
      formOrigenes.setFieldsValue(record);
      formIdentificacion.setFieldsValue(record);
      formRegistro.setFieldsValue(record);
      formAfectacion.setFieldsValue(record);
      formUbicacion.setFieldsValue(record);
      formAvaluos.setFieldsValue(record);
      formExpediente.setFieldsValue(record);
    });
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const columns = [
    {
      titleText: 'Tipo de Activo',
      dataIndex: 'indicador_tipo_de_activo',
      key: 'indicador_tipo_de_activo',
      width: 150,
      render: (val) => (tipoActivo.find((e) => e.id === val)?.descripcion),
    },
    {
      titleText: 'Estado',
      dataIndex: 'estados_globales',
      key: 'estados_globales',
      width: 150,
      render: (val) => (estadosGlobales.find((e) => e.id === val)?.descripcion),
    },
  ];

  const onClickEdit = () => {
    setVisible(true);
  };

  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible ? (
          <Table
            allowImport
            baseURI={baseURI}
            cols={columns}
            data={data?.results}
            permission={permission}
            rowSelection={rowSelection}
            handleOnRowClick={handleOnRowClick}
            currentPage={currentPage}
            loading={loading}
            controls={{
              onClickAdd,
              onClickEdit,
              onClickDelete,
            }}
            pagination={false}
            paginationCallback={fetchData}
            count={data?.count}
            mobileColIndex={0}
          />
        ) : (
          <Card
            className="form-container"
            title={`${!selectedRowKeys.length ? 'Agregar' : 'Editar'} Configurador de Activo Fijo`}
            extra={(
              <FormSubmitControls
                onFinish={onFinish}
                onCancel={onCancel}
                allowSaveAndContinue
                baseURI={baseURI}
                selectedRowKeys={selectedRowKeys}
              />
            )}
            bordered={false}
          >
            <Tabs
              tabPosition="left"
              type="card"
              activeKey={currentTabKey}
              onChange={setCurrentTabKey}
            >
              <TabPane
                key="generalTab"
                tab="General"
                forceRender
              >
                <Form
                  name="generalTab"
                  form={formTab}
                  layout="vertical"
                  onFinish={onFinish}
                  scrollToFirstError
                >
                  <Row gutter={10}>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="indicador_tipo_de_activo"
                        label="Indicador de Tipo de Activo"
                      >
                        <Select
                          dataSource={tipoActivo}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="descripcion"
                        label="Descripción"
                      >
                        <Select
                          dataSource={estadoCampo}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="finalidad"
                        label="Finalidad"
                      >
                        <Select
                          dataSource={estadoCampo}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="clave_del_producto"
                        label="Clave Del Producto"
                      >
                        <Select
                          dataSource={estadoCampo}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="clave_interna"
                        label="Clave Interna"
                      >
                        <Select
                          dataSource={estadoCampo}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="codigo"
                        label="Código"
                      >
                        <Select
                          dataSource={estadoCampo}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="foto"
                        label="Foto"
                      >
                        <Select
                          dataSource={estadoCampo}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="numero_economico"
                        label="Número Económico"
                      >
                        <Select
                          dataSource={estadoCampo}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="fecha_de_alta"
                        label="Fecha De Alta"
                      >
                        <Select
                          dataSource={estadoCampo}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="observaciones_generales"
                        label="Observaciones Generales"
                      >
                        <Select
                          dataSource={estadoCampo}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="es_un_bien_economico"
                        label="Es un Bien Económico"
                      >
                        <Select
                          dataSource={estadoCampo}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="producto"
                        label="Producto"
                      >
                        <Select
                          dataSource={estadoCampo}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="clasificador_de_activo"
                        label="Clasificador de Activo"
                      >
                        <Select
                          dataSource={estadoCampo}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="conac_bien_inmueble"
                        label="Conac Bien Inmueble"
                      >
                        <Select
                          dataSource={estadoCampo}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="sub_sub_grupo_activo_fijo"
                        label="Sub sub Grupo Activo Fijo"
                      >
                        <Select
                          dataSource={estadoCampo}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="uso"
                        label="Uso"
                      >
                        <Select
                          dataSource={estadoCampo}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="estado_fisico"
                        label="Estado Físico"
                      >
                        <Select
                          dataSource={estadoCampo}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="tipo_de_activo"
                        label="Tipo de Activo"
                      >
                        <Select
                          dataSource={estadoCampo}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="estatus_de_asignacion"
                        label="Estado de Asignación"
                      >
                        <Select
                          dataSource={estadoCampo}
                        />
                      </Form.Item>
                    </Col>
                    <Form.Item hidden>
                      <Button htmlType="submit" />
                    </Form.Item>
                  </Row>
                </Form>
              </TabPane>
              <TabPane
                key="origen"
                tab="Origen"
                // disabled={!selectedRowKeys.length}
                forceRender
              >
                <Origenes form={formOrigenes} />
              </TabPane>
              <TabPane
                key="identificaciones"
                tab="Identificación"
                forceRender={!selectedRowKeys.length}
              >
                <Identificacion
                  form={formIdentificacion}
                />
              </TabPane>
              <TabPane
                key="registro"
                tab="Registro"
                forceRender={!selectedRowKeys.length}
              >
                <Registros form={formRegistro} />
              </TabPane>
              <TabPane
                key="afectaciones"
                tab="Afectación"
                forceRender={!selectedRowKeys.length}
              >
                <Afectacion form={formAfectacion} />
              </TabPane>
              <TabPane
                key="ubicacion"
                tab="Ubicación"
                forceRender={!selectedRowKeys.length}
              >
                <Ubicacion form={formUbicacion} />
              </TabPane>
              <TabPane
                key="avaluos"
                tab="Avalúos"
                forceRender={!selectedRowKeys.length}
              >
                <Avaluos form={formAvaluos} />
              </TabPane>
              <TabPane
                key="bitacoras"
                tab="Bitácoras"
                // disabled={!selectedRowKeys.length}
                forceRender={!selectedRowKeys.length}
              >
                <BitacoraResguardos
                  form={formBitacora}
                />
              </TabPane>
              <TabPane
                key="expediente"
                tab="Expediente"
                // disabled={!selectedRowKeys.length}
                forceRender={!selectedRowKeys.length}
              >
                <Expediente form={formExpediente} />
              </TabPane>
            </Tabs>
          </Card>
        )}
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => setVisibleAlert(false)}
          visible={visibleAlert}
          content="La configuracion seleccionada"
          loading={loading}
        />
      </Spin>
    </Row>
  );
}

export default ConfiguradorActivosFijos;
