import React from 'react';
import PropTypes from 'prop-types';
import {
  Upload,
  Form,
  Col,
  message,
} from 'antd';
import { InboxOutlined } from '@ant-design/icons';

function Dragger({
  rules,
  name,
  label,
  allowedExtensions,
  limitMB,
}) {
  // eslint-disable-next-line no-param-reassign
  allowedExtensions = allowedExtensions.map((e) => e.toLowerCase());
  const [fileList, setFileList] = React.useState([]);
  const accept = allowedExtensions.map((e) => `.${e}`).join(',').toLowerCase();
  const formats = allowedExtensions.join(', ').toUpperCase();

  const beforeUpload = (_file) => {
    const ext = _file.name.split('.').pop();
    const isValidExt = allowedExtensions.includes(ext);

    const isLmt = _file.size / 1024 / 1024 < limitMB;
    if (!isLmt) {
      message.warn(`El archivo no debe sobrepasar los ${limitMB}MB`);
    }

    if (!isValidExt) {
      message.warn(`Solo se admiten archivos en formato ${formats}`);
    }

    if (isValidExt && isLmt) {
      setFileList([_file]);
    }
    return false;
  };

  return (
    <Col span={24}>
      <Form.Item
        name={name}
        label={label}
        rules={rules}
      >
        <Upload.Dragger
          beforeUpload={beforeUpload}
          className="custom-dragger"
          onRemove={() => {
            setFileList([]);
          }}
          fileList={fileList}
          accept={accept}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Haga click o arrastre el documento en esta área.
          </p>
          <p className="ant-upload-hint">
            Soporte para subir un solo archivo.
          </p>
        </Upload.Dragger>
      </Form.Item>
    </Col>
  );
}

Dragger.propTypes = {
  allowedExtensions: PropTypes.arrayOf(PropTypes.string).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  rules: PropTypes.arrayOf(PropTypes.object),
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  limitMB: PropTypes.number,
};

Dragger.defaultProps = {
  rules: [],
  limitMB: 20,
};

export default Dragger;
