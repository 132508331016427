import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Modal,
  Spin,
  message,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import API from 'utils/api';
import { onError } from 'utils/handlers';
import Table from '../Table';
import FormSubmitControls from '../FormSubmitControls';
import Select from '../Select';

function SearchDireccion({
  callback,
  setVisible,
  visible,
}) {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [filteredStates, setFilteredStates] = useState([]);
  const [filteredMunicipalities, setFilteredMunicipalities] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const resStates = await API.get('catalogos/estados/');
        setFilteredStates(resStates.data || []);
        setLoading(false);
      } catch (err) {
        onError(err, setLoading);
      }
    };
    fetchData();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const changeState = async (stateID) => {
    try {
      form.setFieldsValue({
        estado: stateID,
        municipio: undefined,
      });
      setFilteredMunicipalities([]);
      const res = await API.get('catalogos/municipios/', {
        params: {
          estado: stateID,
        },
      });
      const municipalitiesOfState = res.data;
      setFilteredMunicipalities(municipalitiesOfState);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
  };

  const columns = [
    {
      titleText: 'Código',
      dataIndex: 'd_codigo',
      key: 'd_codigo',
      render: (text, record) => (
        <Button
          type="link"
          className="primary-text no-color"
          onClick={() => callback(record)}
          style={{ padding: 0 }}
        >
          {text}
        </Button>
      ),
    },
    {
      titleText: 'Asentamiento',
      dataIndex: 'd_asenta',
      key: 'd_asenta',
    },
    {
      titleText: 'Tipo de d_asenta',
      dataIndex: 'd_tipo_asenta',
      key: 'd_tipo_asenta',
    },
    {
      titleText: 'Municipio',
      dataIndex: 'nom_mun',
      key: 'nom_mun',
    },
    {
      titleText: 'Estado',
      dataIndex: 'nombre_de_AGEE',
      key: 'nombre_de_AGEE',
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const params = form.getFieldsValue();
      const res = await API.get('catalogos/codigos-postales/', { params });
      const resData = res.data.map((item) => ({
        ...item,
        nom_mun: item.municipio.nom_mun,
        nombre_de_AGEE: item.municipio.estado.nombre_de_AGEE,
      }));
      setData(resData);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onFinishControls = () => {
    const [key] = selectedRowKeys;
    if (key) {
      const match = data.find((e) => e.id === key);
      callback(match);
    } else {
      message.warn('Seleccione un elemento');
    }
  };

  const onValuesChange = async (changedValues) => {
    const {
      pais,
      estado,
    } = changedValues;
    if (pais >= 0) {
      // changeCountry(pais);
    } else if (estado >= 0) {
      await changeState(estado);
    }
  };

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    setData();
  };

  return (
    <Modal
      width="90%"
      visible={visible}
      closable={false}
      maskClosable={!loading}
      keyboard={!loading}
      footer={null}
      title={(
        <FormSubmitControls
          label="Búsqueda Código Postal"
          onFinish={onFinishControls}
          onCancel={onCancel}
          loading={loading}
        />
      )}
      onCancel={onCancel}
      forceRender
      centered
    >
      <Spin tip="Cargando..." spinning={loading}>
        <Form
          name="search_address"
          layout="vertical"
          form={form}
          initialValues={{ pais: 'México' }}
          onValuesChange={onValuesChange}
          onFinish={onFinish}
          scrollToFirstError
        >
          <Row gutter={10}>
            <Col md={6} sm={24} xs={24}>
              <Form.Item
                label="Estado"
                name="estado"
                rules={rules.estado}
              >
                <Select dataSource={filteredStates} labelProp="nombre_de_AGEE" />
              </Form.Item>
            </Col>

            <Col md={6} sm={24} xs={24}>
              <Form.Item
                label="Municipio"
                name="municipio"
                rules={rules.municipio}
              >
                <Select dataSource={filteredMunicipalities} labelProp="nom_mun" />
              </Form.Item>
            </Col>

            <Col md={6} sm={24} xs={24}>
              <Form.Item
                label="Asentamiento/Colonia"
                name="d_asenta"
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col md={6} sm={24} xs={24}>
              <Form.Item>
                <Button
                  type="link"
                  htmlType="submit"
                  style={{ marginTop: 20 }}
                  className="primary-on-hover"
                >
                  <SearchOutlined />
                  {' '}
                  Buscar
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
      <Table
        cols={columns}
        data={data}
        noControls
        allowSort={false}
        allowSearch={false}
        rowSelection={rowSelection}
        onRow={(record) => ({
          onDoubleClick: () => callback(record),
          onClick: () => {
            setSelectedRowKeys([record.id]);
          },
        })}
      />
    </Modal>
  );
}

SearchDireccion.propTypes = {
  callback: PropTypes.func.isRequired,
  visible: PropTypes.bool,
  setVisible: PropTypes.func.isRequired,
};

SearchDireccion.defaultProps = {
  visible: false,
};

export default SearchDireccion;
