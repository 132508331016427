import React, { useState, useEffect } from 'react';
import {
  Card,
  Form,
  Row,
  Spin,
  Tabs,
  Col,
  Input,
  InputNumber,
  Button,
  Modal,
  DatePicker,
} from 'antd';
import currencyFormatter from 'utils/currencyFormatter';
import API from 'utils/api';
import {
  onInfo,
  onSuccess,
  onError,
  onWarning,
} from 'utils/handlers';
import {
  CheckCircleOutlined,
} from '@ant-design/icons';
import Table from 'components/Table';
import Select from 'components/Select';
import FormSubmitControls from 'components/FormSubmitControls';
import ModalDelete from 'components/ModalDelete';
import Rutas from 'utils/rutas-endpoints';
import moment from 'moment';

const { TextArea } = Input;
const { TabPane } = Tabs;

const solicitud_text = ('Individual: Se realiza para una sola persona Masiva: Se realiza para varias personas, en una sola solicitud');
const comprobacion_text = ('Individual. Por cada Solicitud se deberá realizar una Comprobación.'
  + 'Relacionada. Una Solicitud admite varias Comprobaciones. Aplica para Cajas Chicas, Fondos Fijos o Fondos Revolventes.'
  + 'Global. Se comprueba por saldo, sin identificar una Solicitud en específico.');

const catBasisSelect = [{ id: 1, concepto: 'Si' }, { id: 2, concepto: 'No' }];
const catTextoAdicional = [{ id: 1, concepto: 'Si' }, { id: 2, concepto: 'No' }];
const catAplicaImporteFijo = [{ id: 1, concepto: 'Si' }, { id: 2, concepto: 'No' }];

function SolicitudCuentasInternas() {
  const [form] = Form.useForm();
  const [formRegistro] = Form.useForm();
  const [formTextoAdicional] = Form.useForm();
  const [formTextoAdicionalTab] = Form.useForm();
  const [formImporteFijo] = Form.useForm();
  const [formImporteFijoTab] = Form.useForm();
  const [formSeguimiento] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRowKeysChildren, setSelectedRowKeysChildren] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [currentTabKey, setCurrentTabKey] = useState('general');
  const [mutable, setMutable] = useState(true);
  const [visibleModalCreate, setVisibleModalCreate] = useState(false);
  const [visibleModalCreateImporteFijo, setVisibleModalCreateImporteFijo] = useState(false);
  const [basicSelect, setBasicSelect] = useState([]);
  const [dataCatalogoCuentasInternas, setDataCatalogoCuentasInternas] = useState([]);
  const [dataCatalogoTextoAdicional, setDataCatalogoTextoAdicional] = useState(catTextoAdicional);
  const [dataCatalogoImporteFijo, setDataCatalogoImporteFijo] = useState(catAplicaImporteFijo);
  const [dataTextoAdicional, setDataTextoAdicional] = useState([]);
  const [dataImporteFijo, setDataImporteFijo] = useState([]);
  const [dataCatalogoMovimientosPoliza, setDataCatalogoMovimientosPoliza] = useState([]);
  const [dataCatalogoTipoSolicitud, setDataCatalogoTiposSolicitud] = useState([]);
  const [dataCatalogoTipoComprobacion, setDataCatalogoTipoComprobacion] = useState([]);
  const [dataCatalogoDocumentosOperativos, setDataCatalogoDocumentosOperativos] = useState([]);
  const [dataCuentaBeneficiarioContable, setDataCuentaBeneficiarioContable] = useState([]);
  const [dataMomentosPresupuestales, setDataMomentosPresupuestales] = useState([]);
  const [isAfectaMomentoPresupuestal, setIsAfectaMomentoPresupuestal] = useState(false);
  // eslint-disable-next-line
  const [isAfectaCuentaContableBeneficiario, setIsAfectaCuentaContableBeneficiario] = useState(false);
  const [isRequiereComprobacion, setIsRequiereComprobacion] = useState(false);
  const [isAplicaMaximaComprobacion, setIsAplicaMaximaComprobacion] = useState(false);
  const [isTextoAdicional, setIsTextoAdicional] = useState(false);
  const [isImporteFijo, setIsImporteFijo] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [isTextoAdicionalEditable, setIsTextoAdicionalEditable] = useState(false);
  const [isImporteFijoEditable, setIsImporteFijoEditable] = useState(false);

  const requiredRule = {
    required: true,
    message: 'El campo es requerido',
  };
  const rules = {
    required: [requiredRule],
  };

  const requiredRuleAfectaCuentaContableBeneficiario = {
    required: isAfectaCuentaContableBeneficiario,
    message: 'El campo es requerido',
  };
  const rulesAfectaCuentaContableBeneficiario = {
    required: [requiredRuleAfectaCuentaContableBeneficiario],
  };

  const requiredRuleAfectaMomentoPresupuestal = {
    required: isAfectaMomentoPresupuestal,
    message: 'El campo es requerido',
  };
  const rulesAfectaMomentoPresupuestal = {
    required: [requiredRuleAfectaMomentoPresupuestal],
  };

  const requiereFechaVigente = {
    required: selectedRowKeysChildren.length > 0,
    message: 'El campo es requerido',
  };
  const rulesFechaVigente = {
    required: [requiereFechaVigente],
  };

  const requiredRuleRequiereComprobacion = {
    required: isRequiereComprobacion,
    message: 'El campo es requerido',
  };
  const rulesRequiereComprobacion = {
    required: [requiredRuleRequiereComprobacion],
  };

  const rulesRequiereComprobacionDias = [{
    required: isRequiereComprobacion && isAplicaMaximaComprobacion,
    message: 'El campo es requerido',
  }, {
    validator(_, value) {
      if (value && !Number.isInteger(+value)) {
        return Promise.reject(new Error('El campo solo acepta números enteros'));
      }
      if (value && +value < 1) {
        return Promise.reject(new Error('Los días deben de ser mayor a cero'));
      }
      return Promise.resolve();
    },
  }];

  const fetchData = async () => {
    try {
      setLoading(true);
      setDataCatalogoMovimientosPoliza([]);
      setDataCatalogoTiposSolicitud([]);
      setDataCatalogoDocumentosOperativos([]);
      setDataCuentaBeneficiarioContable([]);
      setDataMomentosPresupuestales([]);
      setBasicSelect(catBasisSelect);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onCancel = () => {
    setSelectedRowKeys([]);
    setVisible(false);
    form.resetFields();
    formTextoAdicional.resetFields();
    formImporteFijo.resetFields();
    formTextoAdicionalTab.resetFields();
    formImporteFijoTab.resetFields();
    formSeguimiento.resetFields();
    formRegistro.resetFields();
    setDataTextoAdicional([]);
    setDataImporteFijo([]);
    setMutable(true);
    setCurrentTabKey('general');
    setWaiting(false);
    setIsTextoAdicionalEditable(false);
    setIsImporteFijoEditable(false);
  };

  // MÉTODOS

  const getBooleano = (dif) => dif === 1;
  function getBoleanoInverso(booleano) {
    if (booleano) {
      return 1;
    }
    return 2;
  }

  const consultarTextoAdicional = async (idSolicitud) => {
    const url = '/tesoreria/textos-adicionales-de-cuentas-internas/';
    const resServer = await API.get(url, {
      params: {
        concepto_de_solicitud: idSolicitud,
      },
    });
    const textoAdicional = await resServer.data;
    return textoAdicional;
  };

  const consultarImporteFijo = async (idImporte) => {
    const resServer = await API.get(Rutas.importeFijo, {
      params: {
        concepto_de_solicitud: idImporte,
      },
    });
    const importeFijo = await resServer.data;
    return importeFijo;
  };

  const consultarGridCuentasInternas = async () => {
    setLoading(true);
    const resApi = await API.get(Rutas.solicitudCuentasInternas);
    const cuentasInternas = await resApi.data;
    const gridCuentasInternas = [];
    cuentasInternas.forEach((item, index) => {
      const momentos = item.momento_presupuestal;
      let cadenaMomento = '';
      momentos.map((mom) => {
        cadenaMomento += `${mom.momento_presupuestal_clave} `;
        return cadenaMomento;
      });
      gridCuentasInternas[index] = {
        id: item.id,
        concepto_solicitud: item.concepto,
        tipo_tercero: 'Beneficiario Interno',
        concepto_general: item.concepto,
        tipo_de_solicitud: item.data_tipo_solicitud.concepto,
        aplica_justificacion: item.aplica_justificacion,
        aplica_tercero_relacionado: item.aplica_tercero_relacionado,
        afecta_presupuesto: item.afecta_presupuesto,
        momentos_presupuesto_solicitud: cadenaMomento,
        documento_soporte: item.documento_soporte,
        aplica_texto_adicional: item.aplica_texto_adicional,
        aplica_importe_fijo: item.aplica_importe_fijo,
        aplica_contra_recibo: item.aplica_contra_recibo,
      };
    });
    setDataCatalogoCuentasInternas(gridCuentasInternas);
    setLoading(false);
  };

  const getConstruccionSolicitudCuentaInterna = async (data) => {
    const dataServiceTextoAdicional = await consultarTextoAdicional(data.id);
    const dataServiceImporteFijo = await consultarImporteFijo(data.id);
    setIsTextoAdicional(data.aplica_texto_adicional);
    setIsRequiereComprobacion(data.requiere_comprobacion);
    setIsAplicaMaximaComprobacion(data.aplica_fecha_maxima_de_comprobacion);
    setIsAfectaMomentoPresupuestal(data.afecta_presupuesto);
    setIsAfectaCuentaContableBeneficiario(data.aplica_cuenta_contable_del_beneficiario);
    const cuentaInterna = {
      general: {
        concepto_solicitud_cta_interna: data.concepto,
        concepto_movimiento: data.concepto_de_movimiento,
        tipo_solicitud: data.tipo_de_solicitud,
        aplica_justificacion: getBoleanoInverso(data.aplica_justificacion),
        aplica_tercero: getBoleanoInverso(data.aplica_tercero_relacionado),
        documento_soporte: data.documento_soporte,
        aplica_periodo_pago: getBoleanoInverso(data.aplica_periodo_de_pago),
        aplica_contra_recibo: getBoleanoInverso(data.aplica_contra_recibo),
        observaciones: data.observaciones,
        estados_globales: data.estados_globales,
      },
      adicional: {
        aplica_texto_adicional: getBoleanoInverso(data.aplica_texto_adicional),
        texto_adicional: dataServiceTextoAdicional,
      },
      importe: {
        aplica_importe_fijo: getBoleanoInverso(data.aplica_importe_fijo),
        importe_fijo: dataServiceImporteFijo,
      },
      seguimiento: {
        requiere_comprobacion: getBoleanoInverso(data.requiere_comprobacion),
        tipo_comprobacion: data.tipo_de_comprobacion,
        // eslint-disable-next-line
        aplica_fecha_maxima_comprobacion: getBoleanoInverso(data.aplica_fecha_maxima_de_comprobacion),
        dias_maximos: data.días_maximos_de_comprobacion,
      },
      registro: {
        afecta_presupuesto: getBoleanoInverso(data.afecta_presupuesto),
        momento_presupuestal: data.momento_presupuestal,
        momentos_presupuestales: data.momentos_presupuestales_de_la_solicitud,
        aplica_finalidad_gasto: getBoleanoInverso(data.aplica_finalidad_del_gasto),
        // eslint-disable-next-line
        aplica_cuenta_contable_beneficiario: getBoleanoInverso(data.aplica_cuenta_contable_del_beneficiario),
        rubro_cuenta_contable: data.rubro_de_cuenta_contable,
        aplica_kardex: getBoleanoInverso(data.aplica_kardex),
        tipo_documento_operativo: data.tipo_documento_operativo,
      },
    };
    return cuentaInterna;
  };

  const consultarDataSolicitudCuentasInternas = async (idSolicitud) => {
    setLoading(true);
    const dataRest = await API.get(`${Rutas.solicitudCuentasInternas}${idSolicitud}/`);
    const solicitud = await dataRest.data;
    const cuentaInterna = await getConstruccionSolicitudCuentaInterna(solicitud);
    const array_momento_presupuestal = cuentaInterna.registro.momento_presupuestal;
    const listaIds = [];
    if (array_momento_presupuestal.length > 0) {
      array_momento_presupuestal.forEach((item, index) => {
        listaIds[index] = item.momento_presupuestal;
      });
    }

    form.setFieldsValue(cuentaInterna.general);
    formTextoAdicionalTab.setFieldsValue({
      aplica_texto_adicional: cuentaInterna.adicional.aplica_texto_adicional,
    });
    setDataTextoAdicional(cuentaInterna.adicional.texto_adicional);
    formImporteFijoTab.setFieldsValue({
      aplica_importe_fijo: cuentaInterna.importe.aplica_importe_fijo,
    });
    setDataImporteFijo(cuentaInterna.importe.importe_fijo);
    formSeguimiento.setFieldsValue(cuentaInterna.seguimiento);
    formRegistro.setFieldsValue(cuentaInterna.registro);
    formRegistro.setFieldsValue({
      momento_presupuestales_comprobacion: listaIds,
    });
    setLoading(false);
  };

  const consultarCatalogoMovimientosPolizas = async () => {
    const respuesta = await API.get(Rutas.conceptoMovimientoPolizas);
    const movimientosPolizas = await respuesta.data;
    setDataCatalogoMovimientosPoliza(movimientosPolizas
      .filter((item) => item.estados_globales === 4));
  };

  const consultarCatalogoTipoSolicitud = async () => {
    const resApi = await API.get(Rutas.tipoSolicitud);
    const tipoSolicitud = await resApi.data;
    setDataCatalogoTiposSolicitud(tipoSolicitud);
  };

  const consultarCatalogoTipoComprobacion = async () => {
    const resApi = await API.get(Rutas.tipoComprobacion);
    const tipoComprobacion = resApi.data;
    setDataCatalogoTipoComprobacion(tipoComprobacion);
  };

  const consultarCatalogoDocumentosOperativos = async () => {
    const resApi = await API.get(Rutas.documentosOperativos);
    const documentosOperativos = resApi.data;
    setDataCatalogoDocumentosOperativos(documentosOperativos);
  };

  const consultarCatalogoCuentaBeneficiarioContable = async () => {
    const resApi = await API.get(Rutas.cuentaBeneficiarioContable);
    const beneficiario = await resApi.data;
    setDataCuentaBeneficiarioContable(beneficiario);
  };

  const consultarCatalogoMomentosPresupuestales = async () => {
    const resApi = await API.get(Rutas.momentosPresupuestales);
    const momentos = await resApi.data;
    setDataMomentosPresupuestales(momentos);
  };

  // EFECTOS

  useEffect(() => {
    consultarGridCuentasInternas();
  }, []);

  useEffect(() => {
    consultarCatalogoMovimientosPolizas();
  }, [dataCatalogoMovimientosPoliza.length]);

  useEffect(() => {
    consultarCatalogoTipoSolicitud();
  }, [dataCatalogoTipoSolicitud.length]);

  useEffect(() => {
    consultarCatalogoTipoComprobacion();
  }, [dataCatalogoTipoComprobacion.length]);

  useEffect(() => {
    consultarCatalogoDocumentosOperativos();
  }, [dataCatalogoDocumentosOperativos.length]);

  useEffect(() => {
    consultarCatalogoCuentaBeneficiarioContable();
  }, [dataCuentaBeneficiarioContable.length]);

  useEffect(() => {
    consultarCatalogoMomentosPresupuestales();
  }, [dataMomentosPresupuestales.length]);

  useEffect(() => {
    const [key] = selectedRowKeys;
    if (waiting) {
      consultarDataSolicitudCuentasInternas(key);
    }
    // eslint-disable-next-line
  }, [waiting]);

  useEffect(() => {
    fetchData();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  // EVENTOS

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
  };

  const handleOnRowClickImporteFijo = (record) => {
    setSelectedRowKeysChildren([record.id]);
    const fecha = moment(record.fin_de_vigencia);
    if (record.estado_descripcion === 'Inactivo' || record.estado_descripcion === 'Autorizado') {
      setIsImporteFijoEditable(true);
    } else {
      setIsImporteFijoEditable(false);
    }
    setTimeout(() => formImporteFijo.setFieldsValue({
      importe: record.importe,
      fin_de_vigencia: fecha,
    }));
  };

  const handleOnRowClickTextoAdicional = (record) => {
    setSelectedRowKeysChildren([record.id]);
    const fecha = moment(record.fin_de_vigencia);
    if (record.estado_descripcion === 'Inactivo' || record.estado_descripcion === 'Autorizado') {
      setIsTextoAdicionalEditable(true);
    } else {
      setIsTextoAdicionalEditable(false);
    }
    setTimeout(() => formTextoAdicional.setFieldsValue({
      descripcion: record.descripcion,
      fin_de_vigencia: fecha,
    }));
  };

  const textoAdicionalHandleChange = (e) => {
    if (e === 1) {
      setIsTextoAdicional(true);
    }

    if (e === 2) {
      setDataTextoAdicional([]);
      setIsTextoAdicional(false);
      formTextoAdicional.resetFields();
      let resAdicional = {};

      if (waiting) {
        const copiaDataTextoAdicional = dataTextoAdicional;
        copiaDataTextoAdicional.forEach(async (item) => {
          resAdicional = await API.delete(`${Rutas.textoAdicional}${item.id}`);
        });
        onSuccess(resAdicional, 'Registros eliminados exitosamente.');
      }
    }
  };

  const importeFijolHandleChange = (e) => {
    if (e === 1) {
      setIsImporteFijo(true);
    }

    if (e === 2) {
      setDataImporteFijo([]);
      setIsImporteFijo(false);
      formImporteFijo.resetFields();
      let resImporte = {};

      if (waiting) {
        const copiaDataImporteFijo = dataImporteFijo;
        copiaDataImporteFijo.forEach(async (item) => {
          resImporte = await API.delete(`${Rutas.importeFijo}${item.id}`);
        });
        onSuccess(resImporte, 'Registros eliminados exitosamente.');
      }
    }
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const rowSelectionChildren = {
    selectedRowKeys: selectedRowKeysChildren,
    type: 'radio',
  };

  const onClickAdd = () => {
    setSelectedRowKeys([]);
    formTextoAdicional.setFieldsValue([]);
    formImporteFijo.setFieldsValue([]);
    setDataCatalogoTextoAdicional(catTextoAdicional);
    setDataCatalogoImporteFijo(catAplicaImporteFijo);
    setVisible(true);
  };

  const onModalCreate = () => {
    setSelectedRowKeysChildren([]);
    formTextoAdicional.setFieldsValue({
      fin_de_vigencia: '',
    });
    formTextoAdicional.resetFields();
    setVisibleModalCreate(true);
  };

  const onModalCreateImporteFijo = () => {
    setSelectedRowKeysChildren([]);
    formImporteFijo.resetFields();
    setVisibleModalCreateImporteFijo(true);
  };

  const onModalCancel = () => {
    setVisibleModalCreate(false);
  };

  const onModalCancelImporteFijo = () => {
    setVisibleModalCreateImporteFijo(false);
  };

  const onHandleChangeAfectaPresupuesto = (e) => {
    if (e === 2) {
      setIsAfectaMomentoPresupuestal(false);
      formRegistro.setFieldsValue({
        momentos_presupuestales: '',
        momento_presupuestales_comprobacion: '',
      });
    } else {
      setIsAfectaMomentoPresupuestal(true);
    }
  };

  const onHandleChangeAplicaCuentaContableBeneficiario = (e) => {
    if (e === 2) {
      setIsAfectaCuentaContableBeneficiario(false);
      formRegistro.setFieldsValue({
        rubro_cuenta_contable: '',
      });
    } else {
      setIsAfectaCuentaContableBeneficiario(true);
    }
  };

  const onHandleRequiereComprobación = (e) => {
    if (e === 1) {
      setIsRequiereComprobacion(true);
    } else {
      formSeguimiento.setFieldsValue({
        tipo_comprobacion: '',
        aplica_fecha_maxima_comprobacion: '',
        dias_maximos: null,
      });
      setIsRequiereComprobacion(false);
    }
  };

  const onHandleAplicaFechaComprobacion = (e) => {
    if (e === 1) {
      setIsAplicaMaximaComprobacion(true);
    } else {
      formSeguimiento.setFieldsValue({
        dias_maximos: null,
      });
      setIsAplicaMaximaComprobacion(false);
    }
  };

  const onClickEditImporteFijo = () => {
    const [key] = selectedRowKeysChildren;
    const objToUpdate = dataImporteFijo.find((item) => item.id === key);
    const fecha = moment(objToUpdate.fin_de_vigencia);
    objToUpdate.fin_de_vigencia = fecha;
    if (!moment(objToUpdate.fin_de_vigencia).isValid()) {
      objToUpdate.fin_de_vigencia = '';
    }
    setVisibleModalCreateImporteFijo(true);
    setTimeout(() => formImporteFijo.setFieldsValue(objToUpdate));
  };

  const onClickEditTextoAdicional = () => {
    const [key] = selectedRowKeysChildren;
    const objToUpdate = dataTextoAdicional.find((item) => item.id === key);
    const fecha = moment(objToUpdate.fin_de_vigencia);
    objToUpdate.fin_de_vigencia = fecha;
    if (!moment(objToUpdate.fin_de_vigencia).isValid()) {
      objToUpdate.fin_de_vigencia = '';
    }
    setVisibleModalCreate(true);
    setTimeout(() => formTextoAdicional.setFieldsValue(objToUpdate));
  };

  const onClickEdit = () => {
    setWaiting(true);
    setVisible(true);
  };

  const onClickDeleteTextoAdicional = async () => {
    const [key] = selectedRowKeysChildren;
    if (selectedRowKeys.length > 0) {
      const response = await API.delete(`${Rutas.textoAdicional}${key}`);
      if (response?.status === 204) {
        const [concepto_de_solicitud] = selectedRowKeys;
        // eslint-disable-next-line
        const dataTextoAdicionalAct = await consultarTextoAdicional(concepto_de_solicitud);
        setDataTextoAdicional(dataTextoAdicionalAct);
      }
    } else {
      const filtrado = dataTextoAdicional.filter((item) => item.id !== key);
      setDataTextoAdicional(filtrado);
    }
  };

  const onClickDeleteImporteFijo = async () => {
    const [key] = selectedRowKeysChildren;
    if (selectedRowKeys.length > 0) {
      const response = await API.delete(`${Rutas.importeFijo}${key}`);
      if (response?.status === 200 || response?.status === 204) {
        const [concepto_de_solicitud] = selectedRowKeys;
        // eslint-disable-next-line
        const dataImporteFijoAct = await consultarImporteFijo(concepto_de_solicitud);
        setDataImporteFijo(dataImporteFijoAct);
      }
    } else {
      const filtrado = dataImporteFijo.filter((item) => item.id !== key);
      setDataImporteFijo(filtrado);
    }
  };

  const onClickDelete = async () => {
    const [key] = selectedRowKeys;
    try {
      const response = await API.delete(`${Rutas.solicitudCuentasInternas}${key}/`);
      if (response?.status === 204) {
        onSuccess(response, 'Eliminado correctamente.');
        onCancel();
        consultarGridCuentasInternas();
      }
    } catch (error) {
      onError(error, setLoading);
    }
  };

  const onChangeTabKey = () => { };

  const deleteItem = async () => {
    try {
      setLoading(true);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onClickAutorizarTextoAdicional = async () => {
    const [key] = selectedRowKeysChildren;
    const rowSelected = dataTextoAdicional.find((item) => item.id === key);
    let disponibilidad = true;

    if (rowSelected.estado_descripcion === 'Autorizado' || rowSelected.estado_descripcion === 'Inactivo') {
      onInfo('Acción no realizada, solo se pueden autorizar registros con estado activo.');
      return;
    }

    if (selectedRowKeys.length > 0) {
      dataTextoAdicional.forEach((item) => {
        if (item.estado_descripcion === 'Autorizado') {
          disponibilidad = false;
          onWarning('Acción no realizada, solo se puede haber un registro con estatus Autorizado.');
        }
      });

      if (disponibilidad) {
        const resApi = API.patch(`${Rutas.textoAdicional}autorizar/`, [{ id: key }]);
        onSuccess(resApi, 'Autorizado correctamente.');
        const newData = await consultarTextoAdicional(rowSelected.concepto_de_solicitud);
        setDataTextoAdicional(newData);
        setSelectedRowKeysChildren([]);
      }
    } else {
      rowSelected.estado_descripcion = 'Autorizado';
      const newData = dataTextoAdicional.map((obj) => {
        if (obj.id === key) {
          return {
            ...obj,
            estados_globales: 4,
            estado_descripcion: 'Autorizado',
          };
        }
        return obj;
      });
      setDataTextoAdicional(newData);
      setSelectedRowKeysChildren([]);
    }
  };

  const onClickAutorizarImporteFijo = async () => {
    const [key] = selectedRowKeysChildren;
    const rowSelected = dataImporteFijo.find((item) => item.id === key);
    let disponibilidad = true;

    if (rowSelected.estado_descripcion === 'Autorizado' || rowSelected.estado_descripcion === 'Inactivo') {
      onInfo('Acción no realizada, solo se pueden autorizar registros con estado activo.');
      return;
    }
    if (selectedRowKeys.length > 0) {
      dataImporteFijo.forEach((item) => {
        if (item.estado_descripcion === 'Autorizado') {
          disponibilidad = false;
          onWarning('Acción no realizada, solo se puede haber un registro con estatus Autorizado.');
        }
      });

      if (disponibilidad) {
        const resApi = API.patch(`${Rutas.importeFijo}autorizar/`, [{ id: key }]);
        onSuccess(resApi, 'Autorizado correctamente.');
        const newData = await consultarImporteFijo(rowSelected.concepto_de_solicitud);
        setDataImporteFijo(newData);
        setSelectedRowKeysChildren([]);
      }
    } else {
      rowSelected.estado_descripcion = 'Autorizado';
      const newData = dataImporteFijo.map((obj) => {
        if (obj.id === key) {
          return {
            ...obj,
            estados_globales: 4,
            estado_descripcion: 'Autorizado',
          };
        }
        return obj;
      });
      setDataImporteFijo(newData);
      setSelectedRowKeysChildren([]);
    }
  };

  const onFinishImporteFijo = async () => {
    await formImporteFijo.validateFields();
    const objImporteFijo = {
      fin_de_vigencia: null,
    };
    const values = formImporteFijo.getFieldsValue();
    if (values.fin_de_vigencia) {
      objImporteFijo.fin_de_vigencia = moment(values.fin_de_vigencia).format('YYYY-MM-DD');
    }
    objImporteFijo.importe = values.importe;
    if (selectedRowKeysChildren.length > 0) {
      const [key] = selectedRowKeysChildren;
      if (selectedRowKeys.length > 0) {
        const objToUpdate = dataImporteFijo.find((item) => item.id === key);
        objToUpdate.importe = objImporteFijo.importe;
        objToUpdate.fin_de_vigencia = objImporteFijo.fin_de_vigencia;
        const response = await API.patch(`${Rutas.importeFijo}${objToUpdate.id}/`, objToUpdate);
        if (response.status === 200) {
          const newData = await consultarImporteFijo(response.data.concepto_de_solicitud);
          setDataImporteFijo(newData);
          setVisibleModalCreateImporteFijo(false);
          setSelectedRowKeysChildren([]);
        }
      } else {
        const newData = dataImporteFijo.map((obj) => {
          if (obj.id === key) {
            return {
              ...obj,
              importe: objImporteFijo.importe,
              fin_de_vigencia: objImporteFijo.fin_de_vigencia,
            };
          }
          return obj;
        });
        setDataImporteFijo(newData);
        setVisibleModalCreateImporteFijo(false);
        setSelectedRowKeysChildren([]);
      }
    } else {
      objImporteFijo.id = Math.floor(Math.random() * 10000) + 1;
      objImporteFijo.estado_descripcion = 'Activo';

      if (selectedRowKeys.length > 0) {
        const [key] = selectedRowKeys;
        objImporteFijo.concepto_de_solicitud = key;
        const response = await API.post(Rutas.importeFijo, objImporteFijo);
        if (response?.status === 201) {
          const newData = await consultarImporteFijo(response.data.concepto_de_solicitud);
          setDataImporteFijo(newData);
          setVisibleModalCreateImporteFijo(false);
          setSelectedRowKeysChildren([]);
        }
      } else {
        objImporteFijo.id = Math.floor(Math.random() * 10000) + 1;
        objImporteFijo.estado_descripcion = 'Activo';
        if (selectedRowKeys.length > 0) {
          const [key] = selectedRowKeys;
          objImporteFijo.concepto_de_solicitud = key;
          const response = await API.post(Rutas.importeFijo, objImporteFijo);
          if (response?.status === 201) {
            const newData = await consultarImporteFijo(response.data.concepto_de_solicitud);
            setDataImporteFijo(newData);
            setVisibleModalCreateImporteFijo(false);
            setSelectedRowKeysChildren([]);
          }
        } else {
          setDataImporteFijo((current) => [...current, objImporteFijo]);
          setVisibleModalCreateImporteFijo(false);
          setSelectedRowKeysChildren([]);
        }
      }
    }
  };

  const onFinishTextoAdicional = async () => {
    await formTextoAdicional.validateFields();
    const objTextoAdicional = {
      fin_de_vigencia: null,
    };
    const values = formTextoAdicional.getFieldsValue();
    if (values.fin_de_vigencia) {
      objTextoAdicional.fin_de_vigencia = moment(values.fin_de_vigencia).format('YYYY-MM-DD');
    }
    objTextoAdicional.descripcion = values.descripcion;
    if (selectedRowKeysChildren.length > 0) {
      const [key] = selectedRowKeysChildren;
      if (selectedRowKeys.length > 0) {
        const objToUpdate = dataTextoAdicional.find((item) => item.id === key);
        objToUpdate.descripcion = objTextoAdicional.descripcion;
        objToUpdate.fin_de_vigencia = objTextoAdicional.fin_de_vigencia;
        const response = await API.patch(`${Rutas.textoAdicional}${objToUpdate.id}/`, objToUpdate);
        if (response.status === 200) {
          const newData = await consultarTextoAdicional(response.data.concepto_de_solicitud);
          setDataTextoAdicional(newData);
          setVisibleModalCreate(false);
          setSelectedRowKeysChildren([]);
        }
      } else {
        const newData = dataTextoAdicional.map((obj) => {
          if (obj.id === key) {
            return {
              ...obj,
              descripcion: objTextoAdicional.descripcion,
              fin_de_vigencia: objTextoAdicional.fin_de_vigencia,
            };
          }
          return obj;
        });
        setDataTextoAdicional(newData);
        setVisibleModalCreate(false);
        setSelectedRowKeysChildren([]);
      }
    } else {
      objTextoAdicional.id = Math.floor(Math.random() * 10000) + 1;
      objTextoAdicional.estado_descripcion = 'Activo';
      if (selectedRowKeys.length > 0) {
        const [key] = selectedRowKeys;
        objTextoAdicional.concepto_de_solicitud = key;
        const response = await API.post(Rutas.textoAdicional, objTextoAdicional);
        if (response?.status === 201) {
          const newData = await consultarTextoAdicional(response.data.concepto_de_solicitud);
          setDataTextoAdicional(newData);
          setVisibleModalCreate(false);
          setSelectedRowKeysChildren([]);
        }
      } else {
        setDataTextoAdicional((current) => [...current, objTextoAdicional]);
        setVisibleModalCreate(false);
        setSelectedRowKeysChildren([]);
      }
    }
  };

  const columnsGrid = [
    {
      titleText: 'Concepto de Solicitud',
      dataIndex: 'concepto_solicitud',
      key: 'concepto_solicitud',
      width: 200,
    },
    {
      titleText: 'Tipo de Tercero',
      dataIndex: 'tipo_tercero',
      key: 'tipo_tercero',
      width: 200,
    },
    {
      titleText: 'Concepto General',
      dataIndex: 'concepto_general',
      key: 'concepto_general',
      width: 220,
    },
    {
      titleText: 'Tipo de Solicitud',
      dataIndex: 'tipo_de_solicitud',
      key: 'tipo_de_solicitud',
      width: 200,
    },
    {
      titleText: 'Aplica Justificación',
      dataIndex: 'aplica_justificacion',
      key: 'aplica_justificacion',
      width: 200,
      render: (val) => (val === true ? 'Si' : 'No'),
    },
    {
      titleText: 'Aplica Tercero Relacionado',
      dataIndex: 'aplica_tercero_relacionado',
      key: 'aplica_tercero_relacionado',
      width: 230,
      render: (val) => (val === true ? 'Si' : 'No'),
    },
    {
      titleText: 'Afecta Presupuesto',
      dataIndex: 'afecta_presupuesto',
      key: 'afecta_presupuesto',
      width: 200,
      render: (val) => (val === true ? 'Si' : 'No'),
    },
    {
      titleText: 'Momentos de Presupuesto de Solicitud',
      key: 'momentos_presupuesto_solicitud',
      dataIndex: 'momentos_presupuesto_solicitud',
      width: 300,
    },
    {
      titleText: 'Documento Soporte',
      key: 'documento_soporte',
      dataIndex: 'documento_soporte',
      width: 200,
      align: 'right',
    },
    {
      titleText: 'Aplica texto adicional',
      key: 'aplica_texto_adicional',
      dataIndex: 'aplica_texto_adicional',
      width: 200,
      render: (val) => (val === true ? 'Si' : 'No'),
    },
    {
      titleText: 'Aplica importe fijo',
      dataIndex: 'aplica_importe_fijo',
      key: 'aplica_importe_fijo',
      width: 200,
      render: (val) => (val === true ? 'Si' : 'No'),
    },
    {
      titleText: 'Aplica Contra Recibo',
      dataIndex: 'aplica_contra_recibo',
      key: 'aplica_contra_recibo',
      width: 200,
      render: (val) => (val === true ? 'Si' : 'No'),
    },
  ];

  const columnsTextAdicional = [
    {
      titleText: 'Descripcion',
      dataIndex: 'descripcion',
      key: 'descripcion',
      width: 200,
    },
    {
      titleText: 'Vigente Hasta',
      key: 'fin_de_vigencia',
      dataIndex: 'fin_de_vigencia',
      width: 200,
    },
    {
      titleText: 'Estado',
      key: 'estado_descripcion',
      dataIndex: 'estado_descripcion',
      width: 200,
    },
  ];

  const columnsImporteFijo = [
    {
      titleText: 'Importe',
      dataIndex: 'importe',
      key: 'importe',
      width: 200,
      render: (val) => (val > 0 ? currencyFormatter(val || 0) : '-'),
    },
    {
      titleText: 'Vigente Hasta',
      key: 'fin_de_vigencia',
      dataIndex: 'fin_de_vigencia',
      width: 200,
    },
    {
      titleText: 'Estado',
      key: 'estado_descripcion',
      dataIndex: 'estado_descripcion',
      width: 200,
    },
  ];

  const handleOnFinish = async () => {
    try {
      try {
        await form.validateFields();
      } catch (error) {
        setCurrentTabKey('general');
        return;
      }

      try {
        await formTextoAdicionalTab.validateFields();
      } catch (error) {
        setCurrentTabKey('texto_adicional');
        return;
      }
      const tabTextoAdicional = formTextoAdicionalTab.getFieldsValue();
      if (getBooleano(tabTextoAdicional.aplica_texto_adicional)
        && dataTextoAdicional.length === 0) {
        onWarning('Debe de capturar al menos un texto adicional');
        setCurrentTabKey('texto_adicional');
        return;
      }

      try {
        await formImporteFijoTab.validateFields();
      } catch (error) {
        setCurrentTabKey('importe_fijo');
        return;
      }
      const tabImporteFijo = formImporteFijoTab.getFieldsValue();
      if (getBooleano(tabImporteFijo.aplica_importe_fijo)
        && dataImporteFijo.length === 0) {
        onWarning('Debe de capturar al menos un importe fijo');
        setCurrentTabKey('importe_fijo');
        return;
      }

      try {
        await formSeguimiento.validateFields();
      } catch (error) {
        setCurrentTabKey('seguimiento');
        return;
      }

      try {
        await formRegistro.validateFields();
      } catch (error) {
        setCurrentTabKey('registro');
        return;
      }

      setLoading(true);

      const generalValues = form.getFieldsValue();
      const seguimientoValues = formSeguimiento.getFieldsValue();
      const registroValues = formRegistro.getFieldsValue();

      let momentos = registroValues.momento_presupuestales_comprobacion;
      if (!momentos) {
        momentos = [];
      }
      momentos = momentos.filter((item) => item !== '');
      const momentosPresupuestales = momentos.map((item) => {
        const obj = {
          momento_presupuestal: item,
        };
        return obj;
      });

      const objJsonAEnviar = {
        concepto: generalValues.concepto_solicitud_cta_interna,
        tipo_de_comprobacion: seguimientoValues.tipo_comprobacion,
        tipo_de_solicitud: generalValues.tipo_solicitud,
        aplica_justificacion: getBooleano(generalValues.aplica_justificacion),
        aplica_tercero_relacionado: getBooleano(generalValues.aplica_tercero),
        documento_soporte: generalValues.documento_soporte,
        aplica_periodo_de_pago: getBooleano(generalValues.aplica_periodo_pago),
        aplica_contra_recibo: getBooleano(generalValues.aplica_contra_recibo),
        observaciones: generalValues.observaciones,
        aplica_texto_adicional: getBooleano(tabTextoAdicional.aplica_texto_adicional),
        aplica_importe_fijo: getBooleano(tabImporteFijo.aplica_importe_fijo),
        // eslint-disable-next-line
        requiere_comprobacion: getBooleano(seguimientoValues.requiere_comprobacion),
        // eslint-disable-next-line
        aplica_fecha_maxima_de_comprobacion: getBooleano(seguimientoValues.aplica_fecha_maxima_comprobacion),
        días_maximos_de_comprobacion: seguimientoValues.dias_maximos,
        rubro_de_cuenta_contable: registroValues.rubro_cuenta_contable,
        concepto_de_movimiento: generalValues.concepto_movimiento,
        momentos_presupuestales_de_la_solicitud: registroValues.momentos_presupuestales,
        momento_presupuestal: momentosPresupuestales,
        tipo_documento_operativo: registroValues.tipo_documento_operativo,
        estados_globales: generalValues.estado === 'Activo' ? 1 : 0,
        texto_adicional: dataTextoAdicional,
        importe_fijo: dataImporteFijo,
        afecta_presupuesto: getBooleano(registroValues.afecta_presupuesto),
        aplica_kardex: getBooleano(registroValues.aplica_kardex),
        // eslint-disable-next-line max-len
        aplica_cuenta_contable_beneficiario: getBooleano(registroValues.aplica_cuenta_contable_beneficiario),
        aplica_finalidad_gasto: getBooleano(registroValues.aplica_finalidad_gasto),
      };

      if (selectedRowKeys.length === 0) {
        const response = await API.post(Rutas.solicitudCuentasInternas, objJsonAEnviar);
        if (response?.status === 201) {
          onSuccess(response, 'Agregado correctamente.');
          onCancel();
          consultarGridCuentasInternas();
        }
      } else {
        const [key] = selectedRowKeys;
        const response = await API.patch(`${Rutas.solicitudCuentasInternas}${key}/`, objJsonAEnviar);
        if (response?.status === 200) {
          onSuccess(response, 'Actualizado correctamente.');
          onCancel();
          consultarGridCuentasInternas();
        }
      }
      setLoading(false);
    } catch (error) {
      onError(error, setLoading);
    }
  };

  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible
          ? (
            <Table
              cols={columnsGrid}
              data={dataCatalogoCuentasInternas}
              rowSelection={rowSelection}
              handleOnRowClick={handleOnRowClick}
              controls={
                {
                  onClickAdd,
                  onClickEdit,
                  onClickDelete,
                }
              }
              mobileColIndex={0}
            />
          )
          : (
            <Card
              className="form-container"
              title={`${selectedRowKeys.length > 0 ? 'Editar' : 'Agregar'} Solicitud`}
              extra={(
                <FormSubmitControls
                  onFinish={currentTabKey === 'registro' ? handleOnFinish : null}
                  onCancel={onCancel}
                  loading={loading}
                />
              )}
              bordered={false}
            >
              <Tabs
                activeKey={currentTabKey}
                onChange={mutable ? setCurrentTabKey : onChangeTabKey}
                type="card"
              >
                <TabPane
                  tab="General"
                  key="general"
                  forceRender
                >
                  <Form
                    name="general"
                    layout="vertical"
                    form={form}
                    onFinish={handleOnFinish}
                    scrollToFirstError
                    initialValues={{ tipo_tercero: 'Beneficiario Interno', estado: 'Activo' }}
                  >
                    <Row gutter={10}>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="concepto_solicitud_cta_interna"
                          label="Concepto de Solicitud de Cuenta Interna"
                          rules={rules.required}
                        >
                          <Input allowClear />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="tipo_tercero"
                          label="Tipo de Tercero"
                          hasFeedback
                          rules={rules.required}
                        >
                          <Input disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="concepto_movimiento"
                          label="Concepto de Movimiento"
                          hasFeedback
                          rules={rules.required}
                        >
                          <Select
                            dataSource={dataCatalogoMovimientosPoliza}
                            render={(e) => `${e?.concepto_de_movimiento}`}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="tipo_solicitud"
                          label="Tipo de Solicitud"
                          rules={rules.required}
                          tooltip={solicitud_text}
                        >
                          <Select
                            dataSource={dataCatalogoTipoSolicitud}
                            render={(e) => `${e?.concepto}`}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="aplica_justificacion"
                          label="Aplica Justificación"
                          hasFeedback
                          rules={rules.required}
                        >
                          <Select
                            dataSource={basicSelect}
                            render={(e) => `${e?.concepto}`}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="aplica_tercero"
                          label="Aplica Tercero Relacionado"
                          hasFeedback
                          rules={rules.required}
                        >
                          <Select
                            dataSource={basicSelect}
                            render={(e) => `${e?.concepto}`}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="documento_soporte"
                          label="Documento Soporte"
                          rules={rules.required}
                        >
                          <Input allowClear />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="aplica_periodo_pago"
                          label="Aplica Periodo de Pago"
                          hasFeedback
                        >
                          <Select
                            dataSource={basicSelect}
                            render={(e) => `${e?.concepto}`}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="aplica_contra_recibo"
                          label="Aplica Contra-Recibo"
                          rules={rules.required}
                          hasFeedback
                        >
                          <Select
                            dataSource={basicSelect}
                            render={(e) => `${e?.concepto}`}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="observaciones"
                          label="Observaciones"
                          hasFeedback
                        >
                          <TextArea rows={6} />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="estado"
                          label="Estado"
                          hasFeedback
                        >
                          <Input disabled />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </TabPane>
                <TabPane
                  key="texto_adicional"
                  tab="Texto Adicional"
                  forceRender
                >
                  <Form
                    name="texto_add"
                    layout="horizontal"
                    form={formTextoAdicionalTab}
                  >
                    <Row gutter={10}>
                      <Col xs={24} sm={24} md={24}>
                        <Form.Item
                          name="aplica_texto_adicional"
                          label="Aplica texto adicional"
                          rules={rules.required}
                          hasFeedback
                        >
                          <Select
                            dataSource={dataCatalogoTextoAdicional}
                            render={(e) => `${e?.concepto}`}
                            onChange={textoAdicionalHandleChange}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={10}>
                      <Col xs={24} sm={24} md={24}>
                        <Table
                          cols={columnsTextAdicional}
                          data={dataTextoAdicional}
                          rowSelection={rowSelectionChildren}
                          handleOnRowClick={handleOnRowClickTextoAdicional}
                          controls={{
                            onClickAdd: () => onModalCreate(),
                            onClickEdit: () => onClickEditTextoAdicional(),
                            onClickDelete: () => onClickDeleteTextoAdicional(),
                            onClickAutorizar: () => onClickAutorizarTextoAdicional(),
                          }}
                          customActions={[
                            {
                              onClick: onClickAutorizarTextoAdicional,
                              text: 'Autorizar',
                              icon: <CheckCircleOutlined />,
                              disabledBySelection: true,
                            },
                          ]}
                          mobileColIndex={0}
                          allowAdd={isTextoAdicional}
                          allowEdit={!isTextoAdicionalEditable}
                          allowDelete={!isTextoAdicionalEditable}
                        />
                      </Col>
                    </Row>
                    <Form.Item hidden>
                      <Button htmlType="submit" />
                    </Form.Item>
                  </Form>
                </TabPane>
                <TabPane
                  key="importe_fijo"
                  tab="Importe Fijo"
                  forceRender
                >
                  <Form
                    name="texto_add"
                    layout="horizontal"
                    form={formImporteFijoTab}
                  >
                    <Row gutter={10}>
                      <Col xs={24} sm={24} md={24}>
                        <Form.Item
                          name="aplica_importe_fijo"
                          label="Aplica importe fijo"
                          rules={rules.required}
                        >
                          <Select
                            dataSource={dataCatalogoImporteFijo}
                            onChange={importeFijolHandleChange}
                            render={(e) => `${e?.concepto}`}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={10}>
                      <Col xs={24} sm={24} md={24}>
                        <Table
                          cols={columnsImporteFijo}
                          data={dataImporteFijo}
                          rowSelection={rowSelectionChildren}
                          handleOnRowClick={handleOnRowClickImporteFijo}
                          allowAdd={isImporteFijo}
                          allowEdit={!isImporteFijoEditable}
                          allowDelete={!isImporteFijoEditable}
                          controls={{
                            onClickAdd: () => onModalCreateImporteFijo(),
                            onClickEdit: () => onClickEditImporteFijo(),
                            onClickDelete: () => onClickDeleteImporteFijo(),
                            onClickAutorizar: () => onClickAutorizarImporteFijo(),
                          }}
                          mobileColIndex={0}
                          customActions={[
                            {
                              onClick: onClickAutorizarImporteFijo,
                              text: 'Autorizar',
                              icon: <CheckCircleOutlined />,
                              disabledBySelection: true,
                            },
                          ]}
                        />
                      </Col>
                    </Row>
                    <Form.Item hidden>
                      <Button htmlType="submit" />
                    </Form.Item>
                  </Form>
                </TabPane>
                <TabPane
                  key="seguimiento"
                  tab="Seguimiento"
                  forceRender
                >
                  <Form
                    name="seguimientio"
                    layout="vertical"
                    form={formSeguimiento}
                  >
                    <Row gutter={10}>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="requiere_comprobacion"
                          label="Requiere Comprobación"
                          rules={rules.required}
                        >
                          <Select
                            dataSource={basicSelect}
                            onChange={onHandleRequiereComprobación}
                            render={(e) => `${e?.concepto}`}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="tipo_comprobacion"
                          label="Tipo de Comprobación"
                          rules={rulesRequiereComprobacion.required}
                          tooltip={comprobacion_text}
                        >
                          <Select
                            dataSource={dataCatalogoTipoComprobacion}
                            disabled={!isRequiereComprobacion}
                            render={(e) => `${e?.concepto}`}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="aplica_fecha_maxima_comprobacion"
                          label="Aplica Fecha Máxima Comprobación"
                          rules={rulesRequiereComprobacion.required}
                        >
                          <Select
                            dataSource={basicSelect}
                            onChange={onHandleAplicaFechaComprobacion}
                            disabled={!isRequiereComprobacion}
                            render={(e) => `${e?.concepto}`}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="dias_maximos"
                          label="Días Maximos para Comprobar"
                          rules={rulesRequiereComprobacionDias}
                        >
                          <Input
                            allowClear
                            disabled={!isRequiereComprobacion || !isAplicaMaximaComprobacion}
                          />
                        </Form.Item>
                      </Col>

                    </Row>

                  </Form>
                </TabPane>
                <TabPane
                  key="registro"
                  tab="Registro"
                  forceRender
                >
                  <Form
                    name="registro"
                    layout="vertical"
                    form={formRegistro}
                    onFinish={handleOnFinish}
                    scrollToFirstError
                  >
                    <Row gutter={10}>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="afecta_presupuesto"
                          label="Afecta Presupuesto"
                          hasFeedback
                          rules={rules.required}
                        >
                          <Select
                            dataSource={basicSelect}
                            render={(e) => `${e?.concepto}`}
                            onChange={onHandleChangeAfectaPresupuesto}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="momentos_presupuestales"
                          label="Momentos Presupuestales de la Solicitud"
                          rules={rulesAfectaMomentoPresupuestal.required}
                          hasFeedback
                        >
                          <Select
                            dataSource={dataMomentosPresupuestales}
                            render={(e) => `${e?.nombre}`}
                            disabled={!isAfectaMomentoPresupuestal}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="aplica_finalidad_gasto"
                          label="Aplica Finalidad del Gasto"
                          hasFeedback
                          rules={rules.required}
                        >
                          <Select
                            dataSource={basicSelect}
                            render={(e) => `${e?.concepto}`}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="momento_presupuestales_comprobacion"
                          label="Momentos Presupuestales en la Comprobación"
                          rules={rulesAfectaMomentoPresupuestal.required}
                          hasFeedback
                        >
                          <Select
                            mode="multiple"
                            disabled={!isAfectaMomentoPresupuestal}
                            dataSource={dataMomentosPresupuestales}
                            render={(e) => `${e?.nombre}`}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="aplica_cuenta_contable_beneficiario"
                          label="Aplica Cuenta Contable del Beneficiario"
                          hasFeedback
                          rules={rules.required}
                        >
                          <Select
                            dataSource={basicSelect}
                            onChange={onHandleChangeAplicaCuentaContableBeneficiario}
                            render={(e) => `${e?.concepto}`}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="rubro_cuenta_contable"
                          label="Rubro Cuenta Contable"
                          rules={rulesAfectaCuentaContableBeneficiario.required}
                          hasFeedback
                        >
                          <Select
                            dataSource={dataCuentaBeneficiarioContable}
                            disabled={!isAfectaCuentaContableBeneficiario}
                            render={(e) => `${e.descripcion}`}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="aplica_kardex"
                          label="Aplica Kardex"
                          hasFeedback
                          rules={rules.required}
                        >
                          <Select
                            dataSource={basicSelect}
                            render={(e) => `${e?.concepto}`}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="tipo_documento_operativo"
                          label="Tipo Documento Operativo"
                          rules={rules.required}
                          hasFeedback
                        >
                          <Select
                            dataSource={dataCatalogoDocumentosOperativos}
                            render={(e) => `${e?.descripcion}`}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </TabPane>
              </Tabs>
            </Card>
          )}
        <Modal
          visible={visibleModalCreate}
          closable={false}
          maskClosable={!loading}
          keyboard={!loading}
          footer={null}
          title={(
            <FormSubmitControls
              label={`${selectedRowKeysChildren.length > 0 ? 'Editar' : 'Agregar'} Texto Adicional`}
              onFinish={onFinishTextoAdicional}
              onCancel={onModalCancel}
              loading={loading}
            />
          )}
          onCancel={onModalCancel}
          forceRender
        >
          <Form
            form={formTextoAdicional}
            layout="vertical"
            name="contacto"
            onFinish={onFinishTextoAdicional}
            initialValues={{ estados_globales: 1 }}
          >
            <Col span={24}>
              <Form.Item
                name="descripcion"
                label="Descripcion"
                rules={rules.required}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="fin_de_vigencia"
                label="vigente Hasta"
                rules={rulesFechaVigente.required}
                hasFeedback
              >
                <DatePicker
                  placeholder=""
                  format="DD/MM/YYYY"
                />
              </Form.Item>
            </Col>
          </Form>
        </Modal>
        <Modal
          visible={visibleModalCreateImporteFijo}
          closable={false}
          maskClosable={!loading}
          keyboard={!loading}
          footer={null}
          title={(
            <FormSubmitControls
              label={`${selectedRowKeysChildren.length > 0 ? 'Editar' : 'Agregar'} Importe Fijo`}
              onFinish={onFinishImporteFijo}
              onCancel={onModalCancelImporteFijo}
              loading={loading}
            />
          )}
          onCancel={onModalCancelImporteFijo}
          forceRender
        >
          <Form
            form={formImporteFijo}
            layout="vertical"
            name="contacto"
            onFinish={onFinishImporteFijo}
          >
            <Col span={24}>
              <Form.Item
                name="importe"
                label="Importe"
                rules={rules.required}
              >
                <InputNumber
                  formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  defaultValue={0.00}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="fin_de_vigencia"
                label="vigente Hasta"
                rules={rulesFechaVigente.required}
                hasFeedback
              >
                <DatePicker
                  placeholder=""
                  format="DD/MM/YYYY"
                />
              </Form.Item>
            </Col>
          </Form>
        </Modal>
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => {
            setVisibleAlert(false);
            setSelectedRowKeys([]);
          }}
          visible={visibleAlert}
          content={`${form.getFieldValue('descripcion')}`}
          loading={loading}
        />
      </Spin>
    </Row>
  );
}

export default SolicitudCuentasInternas;
