import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import { useSelector } from 'react-redux';
import {
  Row,
  Col,
  Spin,
  Form,
  Button,
  Input,
  // Modal,
  // DatePicker,
  // Card,
  Modal,
  TreeSelect,
  message,
} from 'antd';
import FormSubmitControls from 'components/FormSubmitControls';
import Table from 'components/Table';
import ModalDelete from 'components/ModalDelete';
import API from 'utils/api';
import { onError, onSuccess } from 'utils/handlers';
// import Select from 'components/Select';
import { isMutable } from 'utils/estadosGlobales';
import Select from 'components/Select';
import { useSelector } from 'react-redux';
import NumericInput from 'components/NumericInput';

const baseURI = '/presupuestos/actividad-fid/';

function Actividades({
  selectedRowKey,
  setActividadId,
  setActividadFid,

}) {
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  const periodoFiscal = useSelector(({ auth }) => auth.periodoFiscalSelected);
  const [form] = Form.useForm();
  const [formPorcentajeTotal] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [mutable, setMutable] = useState(true);
  const [porcentajeActual, setPorcentajeActual] = useState();

  const [treeData, setTreeData] = useState([]);
  const [contentTypes, setContentTypes] = useState([]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await API.get(baseURI, {
        params: {
          programa_fid: selectedRowKey[0],
        },
      });
      setData(res.data);
      setActividadFid(res.data);
      if (res.data.length !== 0) {
        let porcentajeTotal = 0;
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < res.data.length; i++) {
          porcentajeTotal += res.data[i].porcentaje;
        }
        setTimeout(() => {
          formPorcentajeTotal.setFieldsValue({
            porcentaje: porcentajeTotal,
          });
        });
      } else {
        setTimeout(() => {
          formPorcentajeTotal.setFieldsValue({
            porcentaje: 0,
          });
        });
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const getURValuesToSend = () => {
    const unidad_responsable = form.getFieldValue('area_responsable');
    if (unidad_responsable) {
      const [object_id, nivel] = unidad_responsable.split('/');
      const content_type = contentTypes.find((ct) => ct.nivel.toString() === nivel).id;
      return { object_id, content_type };
    }
    return { object_id: undefined, content_type: undefined };
  };

  const getURForTreeSelect = (_data) => {
    if (_data.area_content_type) {
      const { nivel } = contentTypes.find((ct) => ct.id === _data.area_content_type);
      const { area_content_id } = _data;
      setTimeout(() => {
        form.setFieldsValue({ area_responsable: `${area_content_id}/${nivel}` });
      });
    }
  };

  const normalizeDataUrs = (_data) => {
    if (_data) {
      const output = _data.map((item) => {
        const clone = { ...item };
        const unidad_responsable = normalizeDataUrs(clone.unidad_responsable);
        const centro_de_costo = normalizeDataUrs(clone.centro_de_costo);
        if (!unidad_responsable.length && !centro_de_costo.length) {
          delete clone.unidad_responsable;
          delete clone.centro_de_costo;
          return ({
            title: clone.descripcion,
            value: `${clone.id}/${clone.nivel}`,
          });
        }
        if (unidad_responsable) {
          return ({
            title: clone.descripcion,
            value: `${clone.id}/${clone.nivel}`,
            children: unidad_responsable,
            // disabled: true,
          });
        }
        return ({
          title: clone.descripcion,
          value: `${clone.id}/${clone.nivel}`,
          children: centro_de_costo,
          // disabled: true,
        });
      });
      return output;
    }
    return false;
  };

  useEffect(() => {
    const fetchAll = async () => {
      await fetchData();
      const resUR = await API.get('estructura-organizacional/unidades-de-trabajo');
      const formattedUr = normalizeDataUrs(resUR.data);
      setTreeData(formattedUr);
      const resContentTypes = await API.get('catalogos/content-types-ur');
      setContentTypes(resContentTypes.data);
    };
    fetchAll();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setSelectedRowKeys([]);
    setActividadId();
    form.resetFields();
    setVisible(false);
    setMutable(true);
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const contentValues = getURValuesToSend();
      const values = {
        ...form.getFieldValue(),
        area_content_type: contentValues?.content_type,
        area_content_id: contentValues?.object_id,
        periodo_fiscal: periodoFiscal.id,
        programa_fid: selectedRowKey[0],
      };
      const porcentajeTotal = formPorcentajeTotal.getFieldValue();
      let porcentajeDeRevision = (porcentajeTotal.porcentaje + parseFloat(values.porcentaje));
      if (selectedRowKeys[0]) {
        porcentajeDeRevision -= porcentajeActual;
      }

      const completedValues = {
        ...values,
        porcentaje_total: porcentajeDeRevision,
      };
      if (porcentajeDeRevision <= 100) {
        if (!selectedRowKeys.length) {
          const res = await API.post(baseURI, completedValues);
          if (res?.status === 201) {
            onSuccess(res, 'Agregado correctamente');
            onCancel();
            await fetchData();
          }
        } else {
          const [key] = selectedRowKeys;
          const res = await API.put(`${baseURI}${key}/`, completedValues);
          if (res?.status === 200) {
            onSuccess(res, 'Actualizado correctamente');
            onCancel();
            await fetchData();
          }
        }
      } else {
        const mensaje = `El porcentaje total final es %${porcentajeDeRevision.toString()} por lo que sobrepasa del %100`;
        message.error(mensaje);
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`${baseURI}${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          setVisibleAlert(false);
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    setActividadId(record.id);
    form.setFieldsValue(record);
    setMutable(isMutable(record));
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const columns = [
    {
      titleText: 'Clave',
      dataIndex: 'clave',
      key: 'clave',
      width: 50,
    },
    {
      titleText: 'Nombre',
      dataIndex: 'nombre',
      key: 'nombre',
      width: 150,
    },
    {
      titleText: 'Porcentaje %',
      dataIndex: 'porcentaje',
      key: 'porcentaje',
      width: 150,
    },
    {
      titleText: 'Objetivo',
      dataIndex: 'objetivo',
      key: 'objetivo',
      width: 150,
    },

  ];

  const requiredRule = {
    required: true,
    message: 'El campo es requerido',
  };

  const rules = {
    required: [requiredRule],
  };

  const onClickEdit = () => {
    setVisible(true);
    const [key] = selectedRowKeys;
    const match = data.find((e) => e.id === key);
    setPorcentajeActual(match.porcentaje);
    getURForTreeSelect(match);
    setTimeout(() => {
      form.setFieldsValue({
        ...match,
      });
    });
  };

  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        <Form
          name="general"
          form={formPorcentajeTotal}
          layout="vertical"
          onFinish={onFinish}
          scrollToFirstError
          initialValues={{
            estados_globales: 1,

          }}
        >
          <Row gutter={10}>
            <Col span={8}>
              <NumericInput
                label="Porcentaje Total"
                name="porcentaje"
                allowZero
                disabled
                decimal
                prefix="%"
              />
            </Col>
          </Row>
        </Form>

        <Table
          cols={columns}
          data={data}
          rowSelection={rowSelection}
          handleOnRowClick={handleOnRowClick}
          loading={loading}
          controls={{
            onClickAdd,
            onClickEdit,
            onClickDelete,
          }}
          mobileColIndex={0}
        />

        <Modal
          visible={visible}
          title={(
            <FormSubmitControls
              label={`${!selectedRowKeys.length ? 'Agregar' : 'Editar'} Actividad`}
              onFinish={onFinish}
              onCancel={onCancel}
              mutable={mutable}
            />
            )}
          onCancel={onCancel}
          footer={null}
          closable={false}
          maskClosable={!loading}
          keyboard={!loading}
          forceRender
        >
          <Form
            name="general"
            form={form}
            layout="vertical"
            onFinish={onFinish}
            scrollToFirstError
            initialValues={{
              estados_globales: 1,

            }}
          >
            <Row gutter={10}>
              <Col span={24}>
                <Form.Item
                  rules={rules.required}
                  label="Clave"
                  name="clave"
                  hasFeedback
                >
                  <Input
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  rules={rules.required}
                  label="Nombre"
                  name="nombre"
                  hasFeedback
                >
                  <Input
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  rules={rules.required}
                  label="Objetivo"
                  name="objetivo"
                  hasFeedback
                >
                  <Input.TextArea
                    autoSize={{ minRows: 2, maxRows: 3 }}
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col xspan={24}>
                <NumericInput
                  label="Porcentaje"
                  name="porcentaje"
                  allowZero
                  decimal
                  prefix="%"
                />
              </Col>
              <Col span={24}>
                <Form.Item
                  rules={rules.required}
                  label="Observaciones"
                  name="observaciones"
                  hasFeedback
                >
                  <Input
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Área Ejecutora"
                  name="area_responsable"
                >
                  <TreeSelect
                    showSearch
                    treeNodeFilterProp="title"
                    style={{ width: '100%' }}
                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                    treeData={treeData}
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="estados_globales"
                  label="Estado"
                  rules={rules.required}
                >
                  <Select
                    dataSource={estadosGlobales}
                  />
                </Form.Item>
              </Col>
              <Form.Item hidden>
                <Button htmlType="submit" />
              </Form.Item>
            </Row>
          </Form>
        </Modal>

        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => setVisibleAlert(false)}
          visible={visibleAlert}
          content="El identificador seleccionado"
          loading={loading}
        />
      </Spin>
    </Row>
  );
}

Actividades.propTypes = {
  selectedRowKey: PropTypes.arrayOf(PropTypes.number),
  setActividadId: PropTypes.func.isRequired,
  setActividadFid: PropTypes.func.isRequired,

};

Actividades.defaultProps = {
  selectedRowKey: [],
};
export default Actividades;
