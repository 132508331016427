import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Row,
  Col,
  Spin,
  Form,
  Button,
  Input,
  Modal,
} from 'antd';
import FormSubmitControls from 'components/FormSubmitControls';
import Table from 'components/Table';
import ModalDelete from 'components/ModalDelete';
import API from 'utils/api';
import { onError, onSuccess } from 'utils/handlers';
import Select from 'components/Select';
import { isMutable } from 'utils/estadosGlobales';

export const permissionAreaDeUbicacionEspecifica = {
  permissionModel: 'areadeubicacionespecifica',
};

const baseURI = '/controlpatrimonial/areas-de-ubicaciones-especificas/';

// eslint-disable-next-line react/prop-types
function AreaDeUbicacionEspecifica({ permission }) {
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [areaDeUbicacionGeneral, setAreaDeUbicacionGeneral] = useState([]);
  const [mutable, setMutable] = useState(true);

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await API.get(baseURI);
      setData(res.data);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const fetchAll = async () => {
    try {
      setLoading(true);
      const resUbiGen = await API.get('/controlpatrimonial/areas-de-ubicaciones-generales/');
      setAreaDeUbicacionGeneral(resUbiGen.data || []);
      await fetchData();
      setLoading(false);
    } catch (error) {
      onError(error, setLoading);
    }
  };

  useEffect(() => {
    fetchAll();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setSelectedRowKeys([]);
    form.resetFields();
    setVisible(false);
    setMutable(true);
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      if (!selectedRowKeys.length) {
        const res = await API.post(baseURI, values);
        if (res?.status === 201) {
          onSuccess(res, 'Agregado correctamente');
          onCancel();
          await fetchData();
        }
      } else {
        const [key] = selectedRowKeys;
        const res = await API.put(`${baseURI}${key}/`, values);
        if (res?.status === 200) {
          onSuccess(res, 'Actualizado correctamente');
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`${baseURI}${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          setVisibleAlert(false);
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    setTimeout(() => form.setFieldsValue(record));
    setMutable(isMutable(record));
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const columns = [
    {
      titleText: 'Clave',
      dataIndex: 'clave',
      key: 'clave',
      width: 50,
    },
    {
      titleText: 'Descripción',
      dataIndex: 'descripcion',
      key: 'descripcion',
      width: 150,
    },
    {
      titleText: 'Ubicación General',
      dataIndex: 'ubicacion_general',
      key: 'ubicacion_general',
      width: 150,
      render: (val) => `${areaDeUbicacionGeneral.find((a) => a.id === val)?.clave} 
      - ${areaDeUbicacionGeneral.find((a) => a.id === val)?.descripcion}`,
    },
    {
      titleText: 'Estado',
      dataIndex: 'estados_globales',
      key: 'estados_globales',
      width: 300,
      render: (val) => estadosGlobales.find((t) => t.id === val)?.descripcion,
    },
  ];

  const requiredRule = {
    required: true,
    message: 'El campo es requerido',
  };

  const rules = {
    required: [requiredRule],
  };

  const onClickEdit = () => {
    setVisible(true);
  };

  const initialValues = {
    estados_globales: 1,
  };

  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        <Table
          cols={columns}
          data={data}
          rowSelection={rowSelection}
          permission={permission}
          handleOnRowClick={handleOnRowClick}
          loading={loading}
          controls={{
            onClickAdd,
            onClickEdit,
            onClickDelete,
          }}
          mobileColIndex={0}
        />
        <Modal
          visible={visible}
          title={(
            <FormSubmitControls
              label={`${!selectedRowKeys.length ? 'Agregar' : 'Editar'} Área Especifica`}
              onFinish={onFinish}
              onCancel={onCancel}
              mutable={mutable}
            />
            )}
          onCancel={onCancel}
          footer={null}
          closable={false}
          maskClosable={!loading}
          keyboard={!loading}
          forceRender
        >
          <Form
            name="general"
            form={form}
            layout="vertical"
            onFinish={onFinish}
            scrollToFirstError
            initialValues={initialValues}
          >
            <Row>
              <Col span={24}>
                <Form.Item
                  name="clave"
                  label="Clave"
                  required
                >
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Descripción"
                  rules={rules.required}
                  name="descripcion"
                  hasFeedback
                >
                  <Input.TextArea
                    autoSize={{ minRows: 4, maxRows: 4 }}
                    allowClear
                    disabled={!mutable}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="ubicacion_general"
                  label="Ubicación General"
                >
                  <Select
                    dataSource={areaDeUbicacionGeneral}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="estados_globales"
                  rules={rules.required}
                  label="Estado"
                  hasFeedback
                >
                  <Select
                    disabled={!selectedRowKeys.length || !mutable}
                    dataSource={estadosGlobales}
                  />
                </Form.Item>
              </Col>
              <Form.Item hidden>
                <Button htmlType="submit" />
              </Form.Item>
            </Row>
          </Form>
        </Modal>
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => setVisibleAlert(false)}
          visible={visibleAlert}
          content="El identificador seleccionado"
          loading={loading}
        />
      </Spin>
    </Row>
  );
}

export default AreaDeUbicacionEspecifica;
