/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { AuditOutlined } from '@ant-design/icons';
import ClasificacionObjetoDeGasto, { permissionClasificacionDeObjetoDeGasto } from 'views/Contabilidad/ClasificacionObjetoDeGasto';
import PresupuestosAnuales, { permissionPresupuestoAnualIngreso, permissionPresupuestoAnualEgreso } from 'views/Presupuestos/PresupuestosAnuales/Presupuestos';
import FuenteDeFinanciamiento, { permissionFuenteDeFinanciamiento } from 'views/Contabilidad/FuenteDeFinanciamiento';
// import Ingresos from 'views/Presupuestos/PresupuestoDeIngresos/PresupuestoAnual';
import TiposDeReservas from 'views/Presupuestos/PresupuestosAnuales/Reservas';
import CRI, { permissionClase } from 'views/Contabilidad/CatalogosCRI/Clases';
import ConceptosDeIngresos, { permissionConceptoDeIngreso } from 'views/Presupuestos/Catalogos/ConceptosDeIngreso';
import AvancePresupuestal, { permissionRequisicion } from 'views/Presupuestos/PresupuestosAnuales/AvancePresupuestal';
import FuncionesPresupuestales, { permissionFuncionesPresupuestales } from 'views/Presupuestos/Catalogos/FuncionesPresupuestales';
import CapitulosCOG, { permissionCapituloCOG } from 'views/Contabilidad/CapitulosCOG';
import NivelesDeDesarrollo, { permissionNivelPlanDeDesarrollo } from 'views/Configuracion/Catalogos/NivelesDeDesarrollo/Index';
import CatalogoTipoReservas, { permissionCatalogoTipoReservas } from '../views/Presupuestos/Catalogos/CatalogoDeTipoDeReservas';
import ModificacionesPresupuestalesIngresos from '../views/Presupuestos/PresupuestoIngresos/ModificacionesPresupuestalesIngresos';
import KardexSaldosMomentos from '../views/Configuracion/Presupuestos/KardexSaldosMomentos';

export default {
  title: 'Presupuestos',
  icon: () => <AuditOutlined />,
  showInSidebar: true,
  children: [
    {
      title: 'Presupuestos de Ingresos',
      icon: null,
      type: 'private',
      showInSidebar: true,
      children: [
        {
          title: 'Presupuesto Anual de Ingresos',
          icon: null,
          component: PresupuestosAnuales,
          type: 'private',
          showInSidebar: true,
          ...permissionPresupuestoAnualIngreso,
          catalogsModels: [
            'tipodepresupuesto',
            'clase',
          ],
        },
        {
          title: 'Avance Presupuestal de Ingresos',
          icon: null,
          component: null,
          type: 'private',
          showInSidebar: true,
        },
        {
          title: 'Modificaciones Presupuestales de Ingresos',
          icon: null,
          component: ModificacionesPresupuestalesIngresos,
          type: 'private',
          showInSidebar: true,
        },
        // {
        //   title: 'Avance Presupuestal de Ingresos',
        //   icon: null,
        //   component: AvancePresupuestalIngresos,
        //   type: 'private',
        //   showInSidebar: true,
        // },
        {
          title: 'Modificaciones',
          icon: null,
          component: null,
          type: 'private',
          showInSidebar: true,
        },
        {
          title: 'Kárdex y Saldos Ingresos',
          icon: null,
          component: null,
          type: 'private',
          showInSidebar: true,
        },
      ],
    },
    {
      title: 'Presupuesto de Egresos',
      icon: null,
      showInSidebar: true,
      children: [
        {
          title: 'Presupuesto Anual de Egresos',
          icon: null,
          component: PresupuestosAnuales,
          type: 'private',
          showInSidebar: true,
          ...permissionPresupuestoAnualEgreso,
          catalogsModels: ['tipodepresupuesto'],
        },
        {
          title: 'Avance Presupuestal de Egresos',
          icon: null,
          component: AvancePresupuestal,
          type: 'private',
          showInSidebar: true,
          ...permissionRequisicion,
        },
        {
          title: 'Movimientos Presupuestlaes',
          icon: null,
          component: null,
          type: 'private',
          showInSidebar: true,
        },
        {
          title: 'Reservas Presupuestales',
          icon: null,
          component: TiposDeReservas,
          type: 'private',
          showInSidebar: true,
        },
        {
          title: 'Kárdex y Saldos de Momentos',
          icon: null,
          component: KardexSaldosMomentos,
          type: 'private',
          showInSidebar: true,
        },
      ],
    },
    {
      title: 'Reservas',
      icon: null,
      component: TiposDeReservas,
      type: 'private',
      showInSidebar: true,
      permissionModel: 'reservapresupuestaldeegreso',
      catalogsModels: ['catalogodetipodereserva'],
    },
    {
      title: 'Reportes',
      icon: null,
      component: TiposDeReservas,
      type: 'private',
      showInSidebar: true,
    },
    {
      title: 'Catálogos',
      icon: null,
      type: 'private',
      showInSidebar: true,
      children: [
        {
          title: 'Conceptos de Ingresos',
          icon: null,
          component: ConceptosDeIngresos,
          type: 'private',
          showInSidebar: true,
          ...permissionConceptoDeIngreso,
          catalogsModels: [
            'configuraciondemascara',
            'naturalezadeingreso',
            'clase',
            'fuentedeingreso',
            'clasificadoreconomico',
            'clasificaciondefuentedefinanciamientointerna',
            'canaldepago',
            'catalogodecanaldepago',
          ],
        },
        {
          title: 'Niveles de Desarrollo',
          icon: null,
          component: NivelesDeDesarrollo,
          type: 'private',
          showInSidebar: true,
          ...permissionNivelPlanDeDesarrollo,
        },
        {
          title: 'Funciones Presupuestales',
          icon: null,
          component: FuncionesPresupuestales,
          type: 'private',
          showInSidebar: true,
          ...permissionFuncionesPresupuestales,
        },
        {
          title: 'Clasificador por Rubro de Ingreso',
          icon: null,
          component: CRI,
          type: 'private',
          showInSidebar: true,
          ...permissionClase,
        },
        {
          title: 'Clasificador por Objeto del Gasto',
          icon: null,
          component: ClasificacionObjetoDeGasto,
          type: 'private',
          showInSidebar: true,
          ...permissionClasificacionDeObjetoDeGasto,
        },
        {
          title: 'Capitulo de COG',
          icon: null,
          component: CapitulosCOG,
          type: 'private',
          showInSidebar: true,
          ...permissionCapituloCOG,
        },
        {
          title: 'Catálogo Tipo De Reservas',
          icon: null,
          component: CatalogoTipoReservas,
          type: 'private',
          showInSidebar: true,
          ...permissionCatalogoTipoReservas,
        },
        {
          title: 'Fuentes de Financiamiento',
          icon: null,
          component: FuenteDeFinanciamiento,
          type: 'private',
          showInSidebar: true,
          ...permissionFuenteDeFinanciamiento,
          catalogsModels: [
            'clasificaciondefuentedefinanciamiento',
          ],
        },
      ],
    },
  ],
};
