import React from 'react';
import SingleForm from 'components/SingleForm';

export const permissionGiroEmpresarial = {
  permissionModel: 'giroempresarial',
};

// eslint-disable-next-line react/prop-types
function GirosEmpresariales({ permission }) {
  return (
    <SingleForm
      withoutState
      needsKey
      permission={permission}
      baseURI="catalogos/giro-empresarial/"
      title="Giro"
    />
  );
}

export default GirosEmpresariales;
