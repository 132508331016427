import React from 'react';
import SingleForm from 'components/SingleForm';
import {
  getEncabezadosDeDocumentos,
  postEncabezadosDeDocumentos,
  patchEncabezadosDeDocumentos,
  deleteEncabezadosDeDocumentos,
} from 'api/configuracion/encabezadosDeDocumentos';

export const permissionEncabezadoDocumento = {
  permissionModel: 'encabezadodedocumento',
};

// eslint-disable-next-line react/prop-types
function ConfiguracionDeEncabezados({ permission }) {
  return (
    <SingleForm
      title="Configuracion de encabezados"
      get={getEncabezadosDeDocumentos}
      post={postEncabezadosDeDocumentos}
      patch={patchEncabezadosDeDocumentos}
      delete={deleteEncabezadosDeDocumentos}
      permission={permission}
    />
  );
}
export default ConfiguracionDeEncabezados;
