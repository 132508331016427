import React from 'react';
import SingleForm from 'components/SingleForm';

export const permissionTipoDeObra = {
  permissionModel: 'tipodeobra',
};

// eslint-disable-next-line react/prop-types
function TipoDeObra({ permission }) {
  return (
    <SingleForm
      permission={permission}
      baseURI="obrapublica/tipo-de-obra/"
      title="Tipo De Obra"
    />
  );
}

export default TipoDeObra;
