export default [
  {
    clave: '00032-001',
    concepto: 'COLEGIATURA',
    clasificador_rubro_de_ingresos: '7100 - Ingresos Escolares',
    unidad_responsable: '32 - Mexicano-Italiano',
    fuente_financiamiento: '144 - Ingresos Propios',
    control_interno: '0100',
    fundamento: '',
    causa_iva: false,
    estados_globales: 4,
    clave_presupuestal: '7100-0100-0032-144',
    id: 2,
    afectable: true,
    aplica_control_interno: true,
    cri: ' 0071 - Ingresos Escolares',
    es_ingreso: true,
  },
  {
    clave: '00032-002',
    concepto: 'EXÁMNES DE NIVELACIÓN',
    clasificador_rubro_de_ingresos: '7101 - Ingresos Escolares',
    unidad_responsable: '33 - Mexicano-Italiano',
    fuente_financiamiento: '145 - Ingresos Propios',
    control_interno: '0300',
    fundamento: '',
    causa_iva: false,
    estados_globales: 4,
    clave_presupuestal: '7100-0300-0032-144',
    id: 3,
    afectable: true,
    aplica_control_interno: true,
    cri: ' 0071 - Ingresos Escolares',
    es_ingreso: true,
  },
  {
    clave: '00032-003',
    concepto: 'OTROS INGRESOS EDUCATIVOS',
    clasificador_rubro_de_ingresos: '7102 - Ingresos Escolares',
    unidad_responsable: '34 - Mexicano-Italiano',
    fuente_financiamiento: '146 - Ingresos Propios',
    control_interno: '0300',
    fundamento: '',
    causa_iva: false,
    estados_globales: 4,
    clave_presupuestal: '7100-0300-0032-144',
    id: 4,
    afectable: true,
    aplica_control_interno: true,
    cri: ' 0071 - Ingresos Escolares',
    es_ingreso: true,
  },
  {
    clave: '00032-004',
    concepto: 'VENTA DE MATERIAL DIDÁCTICO',
    clasificador_rubro_de_ingresos: '7103 - Ingresos Escolares',
    unidad_responsable: '35 - Mexicano-Italiano',
    fuente_financiamiento: '147 - Ingresos Propios',
    control_interno: '0400',
    fundamento: '',
    causa_iva: false,
    estados_globales: 4,
    clave_presupuestal: '7100-0400-0032-144',
    id: 5,
    afectable: true,
    aplica_control_interno: true,
    cri: ' 0071 - Ingresos Escolares',
    es_ingreso: true,
  },
  {
    clave: '00032-005',
    concepto: 'CAPACITACIÓN Y ADIESTRAMIENTO',
    clasificador_rubro_de_ingresos: '7104 - Ingresos Escolares',
    unidad_responsable: '36 - Mexicano-Italiano',
    fuente_financiamiento: '148 - Ingresos Propios',
    control_interno: '0600',
    fundamento: '',
    causa_iva: false,
    estados_globales: 4,
    clave_presupuestal: '7100-0600-0032-144',
    id: 6,
    afectable: true,
    aplica_control_interno: true,
    cri: ' 0071 - Ingresos Escolares',
    es_ingreso: true,
  },
  {
    clave: '00032-006',
    concepto: 'ASISTENCIA TÉCNICA',
    clasificador_rubro_de_ingresos: '7105 - Ingresos Escolares',
    unidad_responsable: '37 - Mexicano-Italiano',
    fuente_financiamiento: '149 - Ingresos Propios',
    control_interno: '0200',
    fundamento: '',
    causa_iva: false,
    estados_globales: 4,
    clave_presupuestal: '7100-0200-0032-144',
    id: 7,
    afectable: true,
    aplica_control_interno: true,
    cri: ' 0071 - Ingresos Escolares',
    es_ingreso: true,
  },
  {
    clave: '00032-007',
    concepto: 'SERVICIOS TECNOLLÓGICOS',
    clasificador_rubro_de_ingresos: '7106 - Ingresos Escolares',
    unidad_responsable: '38 - Mexicano-Italiano',
    fuente_financiamiento: '150 - Ingresos Propios',
    control_interno: '0200',
    fundamento: '',
    causa_iva: false,
    estados_globales: 4,
    clave_presupuestal: '7100-0200-0032-144',
    id: 8,
    afectable: true,
    aplica_control_interno: true,
    cri: ' 0071 - Ingresos Escolares',
    es_ingreso: true,
  },
  {
    clave: '00032-008',
    concepto: 'RENTA DE CAFETERÍA',
    clasificador_rubro_de_ingresos: '7107 - Ingresos Escolares',
    unidad_responsable: '39 - Mexicano-Italiano',
    fuente_financiamiento: '151 - Ingresos Propios',
    control_interno: '1000',
    fundamento: '',
    causa_iva: false,
    estados_globales: 4,
    clave_presupuestal: '7100-1000-0032-144',
    id: 9,
    afectable: true,
    aplica_control_interno: true,
    cri: ' 0071 - Ingresos Escolares',
    es_ingreso: true,
  },
  {
    clave: '00032-009',
    concepto: 'FOTOCOPIADO',
    clasificador_rubro_de_ingresos: '7108 - Ingresos Escolares',
    unidad_responsable: '40 - Mexicano-Italiano',
    fuente_financiamiento: '152 - Ingresos Propios',
    control_interno: '1000',
    fundamento: '',
    causa_iva: false,
    estados_globales: 4,
    clave_presupuestal: '7100-1000-0032-144',
    id: 10,
    afectable: true,
    aplica_control_interno: true,
    cri: ' 0071 - Ingresos Escolares',
    es_ingreso: true,
  },
  {
    clave: '00032-010',
    concepto: 'OTROS SERV ADICIONALES',
    clasificador_rubro_de_ingresos: '7109 - Ingresos Escolares',
    unidad_responsable: '41 - Mexicano-Italiano',
    fuente_financiamiento: '153 - Ingresos Propios',
    control_interno: '0800',
    fundamento: '',
    causa_iva: false,
    estados_globales: 4,
    clave_presupuestal: '7100-0800-0032-144',
    id: 11,
    afectable: true,
    aplica_control_interno: true,
    cri: ' 0071 - Ingresos Escolares',
    es_ingreso: true,
  },
  {
    clave: '00032-011',
    concepto: 'DONATIVOS EN EFECTIVO',
    clasificador_rubro_de_ingresos: '7110 - Ingresos Escolares',
    unidad_responsable: '42 - Mexicano-Italiano',
    fuente_financiamiento: '154 - Ingresos Propios',
    control_interno: '0900',
    fundamento: '',
    causa_iva: false,
    estados_globales: 4,
    clave_presupuestal: '7100-0900-0032-144',
    id: 12,
    afectable: true,
    aplica_control_interno: true,
    cri: ' 0071 - Ingresos Escolares',
    es_ingreso: true,
  },
  {
    clave: '00032-012',
    concepto: 'PRODUCTOS FINANCIEROS',
    clasificador_rubro_de_ingresos: '7111 - Ingresos Escolares',
    unidad_responsable: '43 - Mexicano-Italiano',
    fuente_financiamiento: '155 - Ingresos Propios',
    control_interno: '1100',
    fundamento: '',
    causa_iva: false,
    estados_globales: 4,
    clave_presupuestal: '7100-1100-0032-144',
    id: 13,
    afectable: true,
    aplica_control_interno: true,
    cri: ' 0071 - Ingresos Escolares',
    es_ingreso: true,
  },
  {
    clave: '00032-013',
    concepto: 'INGRESOS DIVERSOS',
    clasificador_rubro_de_ingresos: '7112 - Ingresos Escolares',
    unidad_responsable: '44 - Mexicano-Italiano',
    fuente_financiamiento: '156 - Ingresos Propios',
    control_interno: '1100',
    fundamento: '',
    causa_iva: false,
    estados_globales: 4,
    clave_presupuestal: '7100-1100-0032-144',
    id: 14,
    afectable: true,
    aplica_control_interno: true,
    cri: ' 0071 - Ingresos Escolares',
    es_ingreso: true,
  },
  {
    clave: '00032-014',
    concepto: 'CURSO DE ALINEACION C.E.',
    clasificador_rubro_de_ingresos: '7113 - Ingresos Escolares',
    unidad_responsable: '45 - Mexicano-Italiano',
    fuente_financiamiento: '157 - Ingresos Propios',
    control_interno: '1400',
    fundamento: '',
    causa_iva: false,
    estados_globales: 4,
    clave_presupuestal: '7100-1400-0032-144',
    id: 15,
    afectable: true,
    aplica_control_interno: true,
    cri: ' 0071 - Ingresos Escolares',
    es_ingreso: true,
  },
  {
    clave: '00032-015',
    concepto: 'PROCESO DE EVALUACION C.E.',
    clasificador_rubro_de_ingresos: '7114 - Ingresos Escolares',
    unidad_responsable: '46 - Mexicano-Italiano',
    fuente_financiamiento: '158 - Ingresos Propios',
    control_interno: '1400',
    fundamento: '',
    causa_iva: false,
    estados_globales: 4,
    clave_presupuestal: '7100-1400-0032-144',
    id: 16,
    afectable: true,
    aplica_control_interno: true,
    cri: ' 0071 - Ingresos Escolares',
    es_ingreso: true,
  },
];
