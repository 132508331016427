/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Col,
  Form,
  message,
  Row,
  Spin,
} from 'antd';
import FormSubmitControls from 'components/FormSubmitControls';
import Table from 'components/Table';
import Select from 'components/Select';
import { onError, onSuccess } from 'utils/handlers';
import API from 'utils/api';
import ModalDelete from 'components/ModalDelete';
import NumericInput from 'components/NumericInput';
import { toInteger } from 'lodash';

const baseURI = '/presupuestos/asignacion-de-presupuestacion-nivel-mir/';

function AgregarPresupuesto({
  selectedRowKey: programa_con_gestion_mir,
  nivelDePresupuestacion,
  areas,
}) {
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [mutable, setMutable] = useState(true);
  const [cog, setCog] = useState([]);
  const [data, setData] = useState([]);
  const [tiposDeGatos, setTiposDeGatos] = useState([]);
  const [fuentesFinanciamiento, setFuentesFinanciamiento] = useState([]);
  // const asignacionPorMes = Form.useWatch('cantidad_asignada_por_mes', form) || false;
  const igualPorMes = Form.useWatch('cantidad_igual_por_mes', form) || false;
  const porServicio = Form.useWatch('por_producto_o_servicio', form) || false;

  const [tablaCog, setTablaCog] = useState([]);
  const [tablaTipos, setTablaTipos] = useState([]);
  const [tablaFuentes, setTablaFuentes] = useState([]);

  const [ur, setUr] = useState([]);
  const [uo, setUo] = useState([]);
  const [cc, setCC] = useState([]);
  const [contentTypes, setContentTypes] = useState([]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const resData = await API.get('/presupuestos/asignacion-de-presupuestacion-nivel-mir/');
      setData(resData.data);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };
  const getURForTreeSelect = (_data) => {
    if (_data.area) {
      const { nivel } = contentTypes.find((ct) => ct.id === _data.area);
      const { area_content_id } = _data;
      return { nivel, area_content_id };
    }
    return null;
  };
  const fetchAll = async () => {
    try {
      setLoading(true);
      // Esta parte es para llenar la tabla en el fetchData
      const resCog = await API.get('/contabilidad/clasificaciones-objeto-gasto-interno/');
      setTablaCog(resCog.data);
      const resTipoDeGasto = await API.get('/catalogos/clasificaciones-tipo-de-gasto/');
      setTablaTipos(resTipoDeGasto.data);
      const resFuentes = await API.get('/contabilidad/clasificaciones-de-fuentes-de-financiamiento-interna/');
      setTablaFuentes(resFuentes.data);

      const resUnidadesR = await API.get('/estructura-organizacional/unidades-responsables/');
      setUr(resUnidadesR.data || []);

      const resUO = await API.get('/estructura-organizacional/unidades-operativas/');
      setUo(resUO.data || []);

      const resCentroCostos = await API.get('/estructura-organizacional/centros-de-costos/');
      setCC(resCentroCostos.data || []);

      const resContentTypes = await API.get('catalogos/content-types-ur');
      setContentTypes(resContentTypes.data);
      await fetchData();
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const getFromUr = async (arr = [], uri = '', setter = null) => {
    if (arr?.length !== 0) {
      const promises = arr.map((e) => API.get(`${uri}${e}`));
      const responses = await Promise.all(promises);
      const datas = responses.map((e) => e.data);
      setter(datas);
    }
  };

  const setSelects = async (obj = {}) => {
    if (obj?.cog_interno?.length) {
      await getFromUr(obj?.cog_interno, '/contabilidad/clasificaciones-objeto-gasto-interno/', setCog);
    } else {
      setCog([]);
      message.warn('El área responsable seleccionado no cuenta con COG seleccionados.');
    }
    if (obj?.tipo_de_gasto?.length) {
      await getFromUr(obj?.tipo_de_gasto, '/catalogos/clasificaciones-tipo-de-gasto/', setTiposDeGatos);
    } else {
      setTiposDeGatos([]);
      message.warn('El área responsable seleccionado no cuenta con Tipos de Gastos seleccionados.');
    }
    if (obj?.fuentes_de_financiamiento_interna?.length) {
      await getFromUr(obj?.fuentes_de_financiamiento_interna, '/contabilidad/clasificaciones-de-fuentes-de-financiamiento-interna/', setFuentesFinanciamiento);
    } else {
      setFuentesFinanciamiento([]);
      message.warn('El área responsable seleccionado no cuenta con Fuentes de Financiamiento seleccionadas.');
    }
  };

  const fetchSelectsData = async (val) => {
    try {
      setLoading(true);
      const resArea = areas.find((e) => e.id === val);
      const { nivel, area_content_id } = getURForTreeSelect(resArea);
      // ----------------------------
      if (nivel === 1) {
        const resUO = uo.find((e) => e.id === area_content_id);
        setSelects(resUO);
      } else if (nivel === 2) {
        const resUR = ur.find((e) => e.id === area_content_id);
        setSelects(resUR);
      } else if (nivel === 3) {
        const resCC = cc.find((e) => e.id === area_content_id);
        setSelects(resCC);
      }

      setLoading(false);
    } catch (error) {
      onError(error, setLoading);
    }
  };

  useEffect(() => {
    fetchAll();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    // setSelectedRowKeys([]);
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      if (toInteger(values.presupuesto_aprobado >= toInteger(values.presupuesto_asignado))) {
        if (!selectedRowKeys.length) {
          const response = await API.post(baseURI, values);
          if (response.status === 201) {
            onSuccess(response, 'Agregado Correctamente');
            await fetchData();
            onCancel();
          }
        } else {
          const [key] = selectedRowKeys;
          const response = await API.put(`${baseURI}${key}/`, values);
          if (response.status === 200) {
            onSuccess(response, 'Editado Correctamente');
            await fetchData();
            onCancel();
          }
        }
      } else {
        message.warn('El presupuesto asignado no puede ser mayor al presupuesto aprobado');
      }

      setLoading(false);
    } catch (err) {
      onError(err, setLoading, [form]);
    }
  };

  const columns = [
    {
      titleText: 'COG',
      dataIndex: 'cog',
      key: 'cog',
      render: (val) => tablaCog?.find((e) => e.id === val)?.concepto,
      width: 300,
    },
    {
      titleText: 'Fuente De Financiamiento',
      dataIndex: 'fuente_de_financiamiento',
      key: 'fuente_de_financiamiento',
      render: (val) => tablaFuentes?.find((e) => e.id === val)?.nombre,
      width: 300,
    },
    {
      titleText: 'Tipo de Gasto',
      dataIndex: 'tipo_de_gasto',
      key: 'tipo_de_gasto',
      render: (val) => tablaTipos?.find((e) => e.id === val)?.nombre,
      width: 300,
    },
    {
      titleText: 'Presupuesto Aprobado',
      dataIndex: 'presupuesto_aprobado',
      key: 'preupuesto_aprobado',
      width: 200,
    },
    {
      titleText: 'Presupuesto Asignado',
      dataIndex: 'presupuesto_asignado',
      key: 'presupuesto_asignado',
      width: 200,
    },
    {
      titleText: 'Presupuesto Por Asignar',
      dataIndex: 'presupuesto_por_asignar',
      key: 'presupuesto_por_asignado',
      width: 200,
    },
    {
      titleText: 'Estado',
      dataIndex: 'estados_globales',
      key: 'estados_globales',
      width: 160,
      render: (id) => estadosGlobales.find((eg) => eg.id === id)?.descripcion,
    },
  ];

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    setTimeout(() => {
      form.setFieldsValue(record);
    });
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const onClickEdit = async () => {
    try {
      setLoading(true);
      const [key] = selectedRowKeys;
      const match = data.find((e) => e.id === key);
      setTimeout(() => {
        form.setFieldsValue(match);
      });
      setLoading(false);
      setVisible(true);
    } catch (error) {
      onError(error, setLoading);
    }
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const deleteItem = async () => {
    try {
      const [key] = selectedRowKeys;
      if (programa_con_gestion_mir) {
        if (selectedRowKeys.length) {
          const response = await API.delete(`${baseURI}${key}/`);
          if (response?.status === 204) {
            onSuccess(response, 'Eliminado correctamente');
            onCancel();
            await fetchData();
            setVisibleAlert(false);
          }
        }
      }
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onChangeMeses = () => {
    const values = form.getFieldsValue();
    if (values?.presupuesto_aprobado) {
      if (values.cantidad_igual_por_mes === false) {
        const presupuestoAprobado = toInteger(values.presupuesto_aprobado);
        const presupuestoAsignado = (toInteger(values.importe_de_enero)
                        + toInteger(values.importe_de_febrero)
                        + toInteger(values.importe_de_marzo)
                        + toInteger(values.importe_de_abril)
                        + toInteger(values.importe_de_mayo)
                        + toInteger(values.importe_de_junio)
                        + toInteger(values.importe_de_julio)
                        + toInteger(values.importe_de_agosto)
                        + toInteger(values.importe_de_septiembre)
                        + toInteger(values.importe_de_octubre)
                        + toInteger(values.importe_de_noviembre)
                        + toInteger(values.importe_de_diciembre)
        );
        const presupuestoPorAsignar = (toInteger(values.presupuesto_aprobado
                          - presupuestoAsignado));
        if (presupuestoAprobado < presupuestoAsignado) {
          message.warn('El presupuesto de los meses excede el presupuesto aprobado');
        }

        setTimeout(
          form.setFieldsValue({
            presupuesto_asignado: presupuestoAsignado,
            presupuesto_por_asignar: presupuestoPorAsignar,
          }),
        );
      } else {
        const cantidadPorMes = toInteger(values.presupuesto_aprobado) / 12;
        const cero = 0;
        setTimeout(
          form.setFieldsValue({
            importe_de_enero: cantidadPorMes.toFixed(2),
            importe_de_febrero: cantidadPorMes.toFixed(2),
            importe_de_marzo: cantidadPorMes.toFixed(2),
            importe_de_abril: cantidadPorMes.toFixed(2),
            importe_de_mayo: cantidadPorMes.toFixed(2),
            importe_de_junio: cantidadPorMes.toFixed(2),
            importe_de_julio: cantidadPorMes.toFixed(2),
            importe_de_agosto: cantidadPorMes.toFixed(2),
            importe_de_septiembre: cantidadPorMes.toFixed(2),
            importe_de_octubre: cantidadPorMes.toFixed(2),
            importe_de_noviembre: cantidadPorMes.toFixed(2),
            importe_de_diciembre: cantidadPorMes.toFixed(2),
            presupuesto_asignado: values.presupuesto_aprobado,
            presupuesto_por_asignar: cero,
          }),
        );
      }
    }
  };

  const requiredRule = {
    required: mutable,
    message: 'Este campo es requerido',
  };

  const rules = {
    required: [requiredRule],
  };

  return (
    <Row className="container" justify="center">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible ? (
          <Table
            cols={columns}
            data={data}
            rowSelection={rowSelection}
            handleOnRowClick={handleOnRowClick}
            controls={{
              onClickAdd,
              onClickEdit,
              onClickDelete,
            }}
            mobileColIndex={0}
          />
        ) : (
          <Card
            bordered={false}
            className="form-container"
            title={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Presupuesto`}
            extra={(
              <FormSubmitControls
                onCancel={onCancel}
                onFinish={() => onFinish()}
                allowAuthorize
                allowCancel
                loading={loading}
                mutable={mutable}
                selectedRowKeys={selectedRowKeys}
                baseURI={baseURI}
                callback={(estados_globales) => {
                  const [key] = selectedRowKeys;
                  const normalized = [...data]
                    .map((e) => (e.id === key ? { ...e, estados_globales } : e));
                  const match = normalized.find((e) => e.id === key);
                  form.setFieldsValue(match);
                  setMutable(false);
                  setData(normalized);
                }}
              />
            )}
          >
            <Form
              form={form}
              layout="vertical"
              name="general"
              onFinish={() => onFinish()}
              className={!mutable && 'without-feedback'}
              scrollToFirstError
              initialValues={{
                estados_globales: 1,
                cantidad_igual_por_mes: false,
                cantidad_asignada_por_mes: false,
                por_producto_o_servicio: false,
                nivel: nivelDePresupuestacion[0],
                importe_de_enero: 0,
                importe_de_febrero: 0,
                importe_de_marzo: 0,
                importe_de_abril: 0,
                importe_de_mayo: 0,
                importe_de_junio: 0,
                importe_de_julio: 0,
                importe_de_agosto: 0,
                importe_de_septiembre: 0,
                importe_de_octubre: 0,
                importe_de_noviembre: 0,
                importe_de_diciembre: 0,
                presupuesto_aprobado: 0,
              }}
            >
              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  name="area"
                  label="Area MIR"
                >
                  <Select
                    dataSource={areas}
                    labelProp="tipo"
                    onChange={(val) => {
                      fetchSelectsData(val);
                      form.resetFields(['fuente_de_financiamiento', 'tipo_de_gasto', 'cog']);
                    }}
                  />
                </Form.Item>
              </Col>
              <Row gutter={10}>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="cog"
                    rules={rules.required}
                    label="Clasificador por Objeto del Gasto"
                  >
                    <Select
                      dataSource={cog}
                      labelProp="concepto"
                      keyProp="clave"
                      keyLabelRender
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="fuente_de_financiamiento"
                    rules={rules.required}
                    label="Fuente de Financiamiento"
                  >
                    <Select
                      dataSource={fuentesFinanciamiento}
                      labelProp="nombre"
                      keyProp="clave"
                      keyLabelRender
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="tipo_de_gasto"
                    rules={rules.required}
                    label="Tipo de Gasto"
                  >
                    <Select
                      dataSource={tiposDeGatos}
                      labelProp="nombre"
                      keyProp="clave"
                      keyLabelRender
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <NumericInput
                    label="Presupuesto Aprobado"
                    name="presupuesto_aprobado"
                    decimal
                    prefix="$"
                    onChange={() => {
                      onChangeMeses();
                    }}
                  />
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <NumericInput
                    label="Presupuesto Asignado"
                    name="presupuesto_asignado"
                    decimal
                    prefix="$"
                    disabled
                  />
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <NumericInput
                    label="Presupuesto Por Asignar"
                    name="presupuesto_por_asignar"
                    decimal
                    allowNegative
                    prefix="$"
                    disabled
                  />
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="cantidad_igual_por_mes"
                    label="Cantidad Igual Por Mes"
                  >
                    <Select
                      trueFalse
                      disabled={porServicio}
                      onChange={(val) => {
                        onChangeMeses();
                        const values = form.getFieldsValue();
                        if (val === false) {
                          setTimeout(() => {
                            form.setFieldsValue({
                              importe_de_enero: 0,
                              importe_de_febrero: 0,
                              importe_de_marzo: 0,
                              importe_de_abril: 0,
                              importe_de_mayo: 0,
                              importe_de_junio: 0,
                              importe_de_julio: 0,
                              importe_de_agosto: 0,
                              importe_de_septiembre: 0,
                              importe_de_octubre: 0,
                              importe_de_noviembre: 0,
                              importe_de_diciembre: 0,
                              presupuesto_por_asignar: values?.presupuesto_aprobado || 0,
                              presupuesto_asignado: 0,
                            });
                          });
                        } else if (
                          values?.presupuesto_aprobado === 0 || !values?.presupuesto_aprobado) {
                          message.warning('No se asignó ningun presupuesto');
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
                {/* <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="cantidad_asignada_por_mes"
                    label="Cantidad Asignada Por Mes"
                  >
                    <Select
                      trueFalse
                      disabled={igualPorMes || porServicio}

                    />
                  </Form.Item>
                </Col> */}
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="por_producto_o_servicio"
                    label="Por Producto o servicio"
                  >
                    <Select
                      trueFalse
                      // disabled={igualPorMes}
                      disabled
                    />
                  </Form.Item>
                </Col>
              </Row>
              {!porServicio && (
              <Row gutter={10}>
                <Col xs={24} sm={24} md={8}>
                  <NumericInput
                    label="Enero"
                    name="importe_de_enero"
                    decimal
                    allowZero
                    prefix="$"
                    disabled={igualPorMes}
                    onChange={() => {
                      onChangeMeses();
                    }}
                  />
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <NumericInput
                    label="Febrero"
                    name="importe_de_febrero"
                    decimal
                    allowZero
                    prefix="$"
                    disabled={igualPorMes}
                    onChange={() => {
                      onChangeMeses();
                    }}
                  />
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <NumericInput
                    label="Marzo"
                    name="importe_de_marzo"
                    decimal
                    allowZero
                    prefix="$"
                    disabled={igualPorMes}
                    onChange={() => {
                      onChangeMeses();
                    }}
                  />
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <NumericInput
                    label="Abril"
                    name="importe_de_abril"
                    decimal
                    allowZero
                    prefix="$"
                    disabled={igualPorMes}
                    onChange={() => {
                      onChangeMeses();
                    }}
                  />
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <NumericInput
                    label="Mayo"
                    name="importe_de_mayo"
                    decimal
                    allowZero
                    prefix="$"
                    disabled={igualPorMes}
                    onChange={() => {
                      onChangeMeses();
                    }}
                  />
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <NumericInput
                    label="Junio"
                    name="importe_de_junio"
                    decimal
                    allowZero
                    prefix="$"
                    disabled={igualPorMes}
                    onChange={() => {
                      onChangeMeses();
                    }}
                  />
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <NumericInput
                    label="Julio"
                    name="importe_de_julio"
                    decimal
                    allowZero
                    prefix="$"
                    disabled={igualPorMes}
                    onChange={() => {
                      onChangeMeses();
                    }}
                  />
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <NumericInput
                    label="Agosto"
                    name="importe_de_agosto"
                    decimal
                    allowZero
                    prefix="$"
                    disabled={igualPorMes}
                    onChange={() => {
                      onChangeMeses();
                    }}
                  />
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <NumericInput
                    label="Septiembre"
                    name="importe_de_septiembre"
                    decimal
                    allowZero
                    prefix="$"
                    disabled={igualPorMes}
                    onChange={() => {
                      onChangeMeses();
                    }}
                  />
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <NumericInput
                    label="Octubre"
                    name="importe_de_octubre"
                    decimal
                    allowZero
                    prefix="$"
                    disabled={igualPorMes}
                    onChange={() => {
                      onChangeMeses();
                    }}
                  />
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <NumericInput
                    label="Noviembre"
                    name="importe_de_noviembre"
                    decimal
                    allowZero
                    prefix="$"
                    disabled={igualPorMes}
                    onChange={() => {
                      onChangeMeses();
                    }}
                  />
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <NumericInput
                    label="Diciembre"
                    name="importe_de_diciembre"
                    decimal
                    allowZero
                    prefix="$"
                    disabled={igualPorMes}
                    onChange={() => {
                      onChangeMeses();
                    }}
                  />
                </Col>
              </Row>
              )}

              <Form.Item
                name="nivel"
                label="Nivel"
                hidden
              >
                <NumericInput />
              </Form.Item>

              <Form.Item hidden>
                <Button htmlType="submit" />
              </Form.Item>
            </Form>
          </Card>
        )}
      </Spin>
      <ModalDelete
        onDelete={deleteItem}
        onCancel={() => {
          setVisibleAlert(false);
          setSelectedRowKeys([]);
        }}
        visible={visibleAlert}
        content="Eliminar la Matriz seleccionada"
      />
    </Row>
  );
}

AgregarPresupuesto.propTypes = {
  selectedRowKey: PropTypes.number,
  nivelDePresupuestacion: PropTypes.number,
  areas: PropTypes.arrayOf(PropTypes.object),
};

AgregarPresupuesto.defaultProps = {
  selectedRowKey: 0,
  nivelDePresupuestacion: 0,
  areas: [],
};

export default AgregarPresupuesto;
