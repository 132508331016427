import React from 'react';
import SingleForm from 'components/SingleForm';
import { getTiposDePoliza } from 'api/configuracion/';

export const permissionTipoPoliza = {
  permissionModel: 'tipodepoliza',
};

// eslint-disable-next-line react/prop-types
function TiposPoliza({ permission }) {
  return (
    <SingleForm
      title="Tipos De Póliza"
      withoutState
      get={getTiposDePoliza}
      permission={permission}
      readOnly
    />
  );
}

export default TiposPoliza;
