import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Spin,
  Card,
  Tabs,
  message,
  DatePicker,
  Tooltip,
} from 'antd';

import {
  ImportOutlined,
  UnorderedListOutlined,
  DollarOutlined,
  UserOutlined,
  FolderOutlined,
  SnippetsOutlined,
  ApartmentOutlined,
  SolutionOutlined,
  EyeOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';

import { SET_URS } from 'store/reducers/catalogs/index';
import getFormattedValues, { formatReceived } from 'utils/formatValues';
import API from 'utils/api';
import { onError, onSuccess } from 'utils/handlers';
import Table from 'components/Table';
import FormSubmitControls from 'components/FormSubmitControls';
import ModalDelete from 'components/ModalDelete';
import Select from 'components/Select';
import { isMutable, isAuthorized } from 'utils/estadosGlobales';
import makeMessager from 'utils/complexFormsMessages';
import { InputSearchPro } from 'components/InputSearch';
import fetchSome from 'utils/fetchSome';
import Compradores from './Compradores';
import Partidas from './Partidas';
import Criterios from './Criterios';
import Anexos from './Anexos';
import SolicitudesDeProductos from './SolicitudesDeProductos';
import Observaciones from './Observaciones';
import Justificaciones from './Justificaciones';

const { TabPane } = Tabs;

const baseURI = 'adquisiciones/requisiciones/';

const uris = {
  1: '/unidades-operativas/',
  2: '/unidades-responsables/',
  3: '/centros-de-costos/',
};

const levelSorter = (a, b) => a.nivel - b.nivel;

function ConfiguracionDePolizas() {
  const history = useHistory();
  const [form] = Form.useForm();
  const [formDetalles] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);

  const [prioridades, setPrioridades] = useState([]);
  const [estadosDeReq, setEstadosReq] = useState([]);
  const [tiposRequisicion, setTiposRequisicion] = useState([]);

  const [mutable, setMutable] = useState(true);
  const [currentTabKey, setCurrentTabKey] = useState('general');
  const [usuarios, setUsuarios] = useState([]);

  const complexForms = [];
  const basicKeys = [
    'general',
    'anexos',
    'detalles',
    'partidas',
    'criterios',
    'compradores',
    'solicitudes',
    'observaciones',
    'justificaciones',
    'cabecera',
  ];
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  const user = useSelector(({ auth }) => auth.user);

  const urs = useSelector(({ catalogs }) => catalogs.urs).sort(levelSorter);
  const [ursFound, setUrsFound] = useState([]);

  const [urContentType, setUrContentType] = useState();

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await API.get(baseURI);
      setData(formatReceived(res.data));
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const fetchAll = async () => {
    try {
      setLoading(true);

      const resUsers = await API.get('usuarios/usuarios/');
      setUsuarios(resUsers.data);

      const resPrioridades = await API.get('adquisiciones/prioridades-de-las-adquisiciones/');
      setPrioridades(resPrioridades.data);

      const resEstados = await API.get('adquisiciones/estados-de-las-requisiciones/');
      setEstadosReq(resEstados.data);

      const resConfigs = await API.get('adquisiciones/configuraciones-de-requisiciones/');
      const { data: configuraciones } = resConfigs;

      const resTipos = await API.get('adquisiciones/tipos-de-operaciones/');
      const filteredTipos = [];
      configuraciones.forEach((e) => {
        if (e) {
          const match = resTipos.data
            .filter((i) => i.id === e.tipo_de_operacion && e.estados_globales === 4);
          filteredTipos.push(...match);
        }
      });
      setTiposRequisicion(filteredTipos);

      if (!urs?.length) {
        fetchSome('/catalogos/content-types-ur/', SET_URS);
      }

      await fetchData();
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchAll();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setSelectedRowKeys([]);
    setVisible(false);
    form.resetFields();
    formDetalles.resetFields();
    setMutable(true);
    setCurrentTabKey('general');
    setUrsFound([]);
  };

  const onClickEdit = async (responseData = null, fromCallback = false) => {
    try {
      setLoading(true);
      if (fromCallback) {
        const [key] = selectedRowKeys;
        const match = responseData || data.find((item) => item.id === key);
        setUrContentType(match.tipo_de_unidad_responsable);
        const { nivel } = urs.find((e) => e.id === match.tipo_de_unidad_responsable);

        const resUR = await API
          .get(`/estructura-organizacional${uris[nivel]}${match.unidad_responsable_id}`);
        setUrsFound([resUR.data]);
        setTimeout(() => {
          form.setFieldsValue(formatReceived(match));
          formDetalles.setFieldsValue(formatReceived(match));
        });
      }
      setVisible(true);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onFinish = async (_values, _continue = false, isAuthorizing = false) => {
    try {
      setLoading(true);
      await form.validateFields();
      await formDetalles.validateFields();
      const values = {
        ...form.getFieldsValue(),
        ...formDetalles.getFieldsValue(),
      };
      const formatedValues = getFormattedValues(values);
      if (!selectedRowKeys.length) {
        const response = await API.post(baseURI, {
          ...formatedValues,
          tipo_de_unidad_responsable: urContentType,
        });
        if (response?.status === 201) {
          if (_continue) {
            onSuccess(response, 'Agregado correctamente');
            await fetchData();
            setSelectedRowKeys([response.data.id]);
            onClickEdit(response.data, true);
          } else {
            onSuccess(response.data, 'Agregado correctamente');
            onCancel();
            await fetchData();
          }
        }
      } else if (!isAuthorizing) {
        const [key] = selectedRowKeys;
        const response = await API.put(`${baseURI}${key}/`, {
          ...formatedValues,
          tipo_de_unidad_responsable: urContentType,
        });
        if (response?.status === 200) {
          if (_continue) {
            await fetchData();
            onClickEdit(response.data, true);
          } else {
            onSuccess(response, 'Actualizado correctamente');
            onCancel();
            await fetchData();
          }
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading, [form, formDetalles], setCurrentTabKey);
    }
  };

  const handleOnFinish = (_, _continue = false, isAuthorizing = false) => {
    const match = complexForms.find((e) => e.key === currentTabKey);
    if (match) {
      match.formInstance.onFinishHandler(null, _continue);
    } else {
      onFinish(null, _continue, isAuthorizing);
    }
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`${baseURI}${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          setVisibleAlert(false);
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    form.setFieldsValue(record);
    formDetalles.setFieldsValue(record);
    setMutable(isMutable(record));
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const onClickDelete = () => {
    const [key] = selectedRowKeys;
    const match = data.find((e) => e.id === key);
    if (isMutable(match)) {
      setVisibleAlert(true);
    } else if (isAuthorized(match)) {
      message.warn('No se puede eliminar una configuración autorizada');
    } else {
      message.warn('No se puede eliminar una configuración cancelada');
    }
  };

  const columns = [
    {
      titleText: 'Folio de Requisición',
      dataIndex: 'folio_de_requisicion',
      key: 'folio_de_requisicion',
      width: 300,
    },
    {
      titleText: 'Fecha de Registro',
      dataIndex: 'fecha_de_registro',
      key: 'fecha_de_registro',
      width: 300,
      render: (e) => e?.format('DD/MM/YYYY'),
    },
    {
      titleText: 'Prioridad',
      dataIndex: 'prioridad',
      key: 'prioridad',
      width: 200,
      render: (val) => {
        const colors = {
          1: '#ff4949',
        };
        const color = colors[val];
        const text = prioridades.find((e) => e.id === val)?.descripcion;
        return (
          <span style={{ color }}>{text}</span>
        );
      },
    },
    {
      titleText: 'Usuario que Elaboró',
      dataIndex: 'usuario_que_elaboro',
      key: 'usuario_que_elaboro',
      width: 300,
      render: (e) => usuarios.find((i) => i.id === e)?.email,
    },
    {
      titleText: 'Solicitante',
      dataIndex: 'solicitante',
      key: 'solicitante',
      width: 300,
      render: (e) => usuarios.find((i) => i.id === e)?.email,
    },
    {
      titleText: 'Comprador',
      dataIndex: 'comprador',
      key: 'comprador',
      width: 300,
      render: (e) => usuarios.find((i) => i.id === e)?.email,
    },
    {
      titleText: 'Estado',
      key: 'estados_globales',
      dataIndex: 'estados_globales',
      width: 150,
      render: (e) => estadosGlobales.find((i) => i.id === e)?.descripcion,
    },
  ];

  const requiredRule = {
    required: true,
    message: 'El campo es requerido',
  };

  const rules = {
    required: [requiredRule],
  };

  const showMsg = makeMessager(setCurrentTabKey, fetchData);

  const onChangeTabKey = (key) => {
    const fromBasic = basicKeys.includes(currentTabKey);
    const toBasic = basicKeys.includes(key);
    const match = complexForms.find((e) => e.key === currentTabKey);
    if (!match) {
      if (fromBasic && toBasic) {
        setCurrentTabKey(key);
      } else if (fromBasic) {
        showMsg(key);
      } else {
        setCurrentTabKey(key);
      }
    } else {
      match.formInstance.onFinishHandler(key, true);
    }
  };

  const resetAndValidateURs = () => {
    setUrsFound([]);
    form.resetFields(['object_id']);
    form.validateFields(['object_id', 'q_ur']);
  };

  const busquedaUR = async () => {
    try {
      setLoading(true);
      await form.validateFields(['tipo_de_unidad_responsable', 'q_ur']);

      const { tipo_de_unidad_responsable, q_ur: q } = form.getFieldsValue();
      setUrContentType(tipo_de_unidad_responsable);
      const { nivel } = urs.find((e) => e.id === tipo_de_unidad_responsable);

      const response = await API.get(`/estructura-organizacional${uris[nivel]}`, {
        params: {
          q,
          estados_globales: 4,
        },
      });
      if (response?.status === 200 && response?.data?.length) {
        form.setFieldsValue({
          unidad_responsable_id: response.data[0].id,
        });
        setUrsFound([response.data[0]]);
      } else if (response?.status === 204) {
        message.info('No se encontraron coincidencias');
        setUrsFound([]);
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible ? (
          <Table
            cols={columns}
            data={data}
            rowSelection={rowSelection}
            handleOnRowClick={handleOnRowClick}
            controls={{
              onClickAdd,
              onClickEdit: () => onClickEdit(null, true),
              onClickDelete,
            }}
            mobileColIndex={0}
          />
        ) : (
          <Col span={24} style={{ height: '100%' }}>
            <Card
              className="form-container"
              title={(
                <FormSubmitControls
                  label={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Requisición`}
                  onCancel={onCancel}
                  onFinish={basicKeys
                    .concat(complexForms.map((e) => e.key))
                    .includes(currentTabKey)
                    ? handleOnFinish : null}
                  selectedRowKeys={selectedRowKeys}
                  allowAuthorize
                  allowCancel
                  baseURI={baseURI}
                  loading={loading}
                  allowSaveAndContinue
                  callback={(estados_globales) => {
                    const [key] = selectedRowKeys;
                    const normalized = [...data]
                      .map((e) => (e.id === key ? { ...e, estados_globales } : e));
                    setMutable(false);
                    setData(normalized);
                    const match = normalized.find((e) => e.id === key);
                    form.setFieldsValue(match);
                    onClickEdit(match, true);
                  }}
                  mutable={mutable}
                />
              )}
              bordered={false}
            >
              {!!selectedRowKeys.length && (
                <Form
                  layout="vertical"
                  form={formDetalles}
                  className="without-feedback"
                >
                  <Row>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="folio_de_requisicion"
                        label="Folio de Requisición"
                      >
                        <Input
                          allowClear
                          disabled
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              )}
              <Tabs
                type="card"
                activeKey={currentTabKey}
                onChange={mutable ? onChangeTabKey : setCurrentTabKey}
              >
                <TabPane
                  tab={(
                    <span>
                      <ImportOutlined />
                      General
                    </span>
                  )}
                  key="general"
                  forceRender
                >
                  <Form
                    layout="vertical"
                    name="general"
                    form={form}
                    onFinish={(values) => onFinish(values)}
                    scrollToFirstError
                    initialValues={{
                      estados_globales: 1,
                      usuario_que_elaboro: user.id,
                    }}
                  >
                    <Row gutter={10}>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="fecha_de_registro"
                          rules={rules.required}
                          label="Fecha de Registro"
                          hasFeedback
                        >
                          <DatePicker
                            format="DD/MM/YYYY"
                            placeholder=""
                            allowClear
                            disabled={!mutable}
                          />
                        </Form.Item>
                      </Col>
                      <InputSearchPro
                        label="Unidad responsable"
                        rules={rules.required}
                        tooltip="Búsqueda por clave o descripción"
                        name="q_ur"
                        onPressEnter={() => busquedaUR()}
                        prefix={{
                          style: {
                            marginTop: 'auto',
                            width: '45%',
                            minWidth: 180,
                          },
                          name: 'tipo_de_unidad_responsable',
                          rules: rules.required,
                          label: 'Nivel',
                          render: (e) => e.model,
                          dataSource: urs,
                          onChange: (e) => {
                            setUrContentType(e);
                            resetAndValidateURs();
                          },
                        }}
                        breakPoints={{
                          sm: 24,
                          xs: 24,
                          md: 16,
                        }}
                        inputProps={{
                          onPressEnter: () => busquedaUR(),
                          onChange: ({ target: { value } }) => {
                            if (!value) {
                              resetAndValidateURs();
                            }
                          },
                        }}
                        resultProps={{
                          name: 'unidad_responsable_id',
                          label: 'Unidad responsable',
                          dataSource: ursFound,
                          keyLabelRender: true,
                          onClear: resetAndValidateURs,
                          breakPoints: {
                            sm: 24,
                            xs: 24,
                            md: 16,
                          },
                        }}
                      />
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="usuario_que_elaboro"
                          rules={rules.required}
                          label="Usuario que elaboró"
                          hasFeedback
                        >
                          <Select dataSource={usuarios} disabled={!mutable} labelProp="email" />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="solicitante"
                          rules={rules.required}
                          label="Solicitante"
                          hasFeedback
                        >
                          <Select dataSource={usuarios} disabled={!mutable} labelProp="email" />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="comprador"
                          rules={rules.required}
                          label="Comprador"
                          hasFeedback
                        >
                          <Select dataSource={usuarios} disabled={!mutable} labelProp="email" />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="orden_de_compra"
                          rules={rules.required}
                          label="Orden de Compra"
                          hasFeedback
                        >
                          <Input
                            allowClear
                            disabled={!mutable}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="tipo_de_operacion"
                          rules={rules.required}
                          label={(
                            <>
                              Tipo de operación
                              {' '}
                              <Tooltip
                                title={(
                                  <span>
                                    Es necesaria una configuración de
                                    requisición para poder usar los tipos de operaciones
                                    <Button
                                      style={{
                                        color: '#1890FF',
                                        background: 'transparent',
                                        border: 'none',
                                      }}
                                      onClick={() => {
                                        history.push('/adquisiciones/requisiciones/configuraciones-de-requisiciones');
                                      }}
                                    >
                                      Ir

                                    </Button>
                                  </span>
                                )}
                              >
                                <QuestionCircleOutlined />
                              </Tooltip>
                            </>
                          )}
                          hasFeedback
                        >
                          <Select
                            dataSource={tiposRequisicion}
                            disabled={!mutable}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="prioridad"
                          rules={rules.required}
                          label="Prioridad"
                          hasFeedback
                        >
                          <Select
                            dataSource={prioridades}
                            disabled={!mutable}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="estado_la_requisicion"
                          rules={rules.required}
                          label="Estado de la Requisición"
                          hasFeedback
                        >
                          <Select
                            dataSource={estadosDeReq}
                            disabled={!mutable}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item hidden>
                      <Button htmlType="submit" />
                    </Form.Item>
                  </Form>
                </TabPane>
                <TabPane
                  tab={(
                    <span>
                      <UnorderedListOutlined />
                      Detalles
                    </span>
                  )}
                  key="detalles"
                  forceRender
                >
                  <Form
                    layout="vertical"
                    name="detalles"
                    form={formDetalles}
                    onFinish={(values) => onFinish(values, false)}
                    scrollToFirstError
                    initialValues={{ estados_globales: 1 }}
                  >
                    <Row gutter={10}>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="orden_de_compra"
                          rules={rules.required}
                          label="Orden de Compra"
                          hasFeedback
                        >
                          <Input
                            allowClear
                            disabled={!mutable}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="folio_agrupador"
                          rules={rules.required}
                          label="Folio Agrupador"
                          hasFeedback
                        >
                          <Input
                            allowClear
                            disabled={!mutable}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="folio_presupuestal"
                          rules={rules.required}
                          label="Folio Presupuestal"
                          hasFeedback
                        >
                          <Input
                            allowClear
                            disabled={!mutable}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="requisicion_consolidada"
                          rules={rules.required}
                          label="Requisición Consolidada"
                          hasFeedback
                        >
                          <Input
                            allowClear
                            disabled={!mutable}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="nombre_del_almacen"
                          rules={rules.required}
                          label="Almacén"
                          hasFeedback
                        >
                          <Input
                            allowClear
                            disabled={!mutable}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="otro_punto_de_entrega"
                          label="Otro punto de entrega"
                          hasFeedback
                        >
                          <Input
                            allowClear
                            disabled={!mutable}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item hidden>
                      <Button htmlType="submit" />
                    </Form.Item>
                  </Form>
                </TabPane>
                <TabPane
                  // tab="Compradores"
                  tab={(
                    <span>
                      <UserOutlined />
                      Compradores
                    </span>
                  )}
                  key="compradores"
                  disabled={!selectedRowKeys.length}
                >
                  <Compradores requisicionId={selectedRowKeys[0]} disabled={!mutable} />
                </TabPane>
                <TabPane
                  // tab="Partidas"
                  tab={(
                    <span>
                      <DollarOutlined />
                      Partidas
                    </span>
                  )}
                  key="partidas"
                  disabled={!selectedRowKeys.length}
                >
                  <Partidas requisicionId={selectedRowKeys[0]} disabled={!mutable} />
                </TabPane>
                <TabPane
                  // tab="Criterios"
                  tab={(
                    <span>
                      <SnippetsOutlined />
                      Criterios
                    </span>
                  )}
                  key="criterios"
                  disabled={!selectedRowKeys.length}
                >
                  <Criterios requisicionId={selectedRowKeys[0]} disabled={!mutable} />
                </TabPane>
                <TabPane
                  // tab="Anexos"
                  tab={(
                    <span>
                      <ApartmentOutlined />
                      Anexos
                    </span>
                  )}
                  key="anexos"
                  disabled={!selectedRowKeys.length}
                >
                  <Anexos requisicionId={selectedRowKeys[0]} disabled={!mutable} />
                </TabPane>
                <TabPane
                  // tab="Solicitudes"
                  tab={(
                    <span>
                      <SolutionOutlined />
                      Solicitudes
                    </span>
                  )}
                  key="solicitudes"
                  disabled={!selectedRowKeys.length}
                >
                  <SolicitudesDeProductos requisicionId={selectedRowKeys[0]} disabled={!mutable} />
                </TabPane>
                <TabPane
                  // tab="Observaciones"
                  tab={(
                    <span>
                      <EyeOutlined />
                      Observaciones
                    </span>
                  )}
                  key="observaciones"
                  disabled={!selectedRowKeys.length}
                >
                  <Observaciones requisicionId={selectedRowKeys[0]} disabled={!mutable} />
                </TabPane>
                <TabPane
                  // tab="Justificaciones"
                  tab={(
                    <span>
                      <FolderOutlined />
                      Justificaciones
                    </span>
                  )}
                  key="justificaciones"
                  disabled={!selectedRowKeys.length}
                >
                  <Justificaciones requisicionId={selectedRowKeys[0]} disabled={!mutable} />
                </TabPane>
              </Tabs>
            </Card>
          </Col>
        )}

        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => setVisibleAlert(false)}
          visible={visibleAlert}
          content={`requisición ${form.getFieldValue('operacion')}`}
          loading={loading}
        />
      </Spin>
    </Row>
  );
}

export default ConfiguracionDePolizas;
