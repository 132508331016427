import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Spin,
  Form,
} from 'antd';
import API from 'utils/api';
import { onError } from 'utils/handlers';
import Select from 'components/Select';

// eslint-disable-next-line react/prop-types
function Origenes({ form }) {
  const [loading, setLoading] = useState(false);
  const [estadoCampo, setEstadoCampo] = useState([]);

  const fetchAll = async () => {
    try {
      setLoading(true);
      const resEstadoCampo = await API.get('/controlpatrimonial/estados-del-campo/');
      setEstadoCampo(resEstadoCampo.data);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchAll();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        <Form
          name="origenes"
          form={form}
          layout="vertical"
          scrollToFirstError
        >
          <Row gutter={10}>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="monto_de_origen"
                label="Monto Origen"
              >
                <Select
                  dataSource={estadoCampo}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="fecha"
                label="Fecha"
              >
                <Select
                  dataSource={estadoCampo}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="observaciones_de_origen"
                label="Observaciones Origen"
              >
                <Select
                  dataSource={estadoCampo}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="documento_comprobatorio"
                label="Documento Comprobatorio"
              >
                <Select
                  dataSource={estadoCampo}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="origen"
                label="Origen"
              >
                <Select
                  dataSource={estadoCampo}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="nombre_del_proveedor"
                label="Nombre del Proveedor"
              >
                <Select
                  dataSource={estadoCampo}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="numero_de_comprobante"
                label="Número de Comprobante"
              >
                <Select
                  dataSource={estadoCampo}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="fecha_de_expedicion"
                label="Fecha de Expedición"
              >
                <Select
                  dataSource={estadoCampo}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="fecha_documento"
                label="Fecha del Documento"
              >
                <Select
                  dataSource={estadoCampo}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="lugar_de_expedicion"
                label="Lugar de Expedición"
              >
                <Select
                  dataSource={estadoCampo}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="observaciones_de_la_factura"
                label="Observaciones de la Factura"
              >
                <Select
                  dataSource={estadoCampo}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="otros_factura"
                label="Otros Factura"
              >
                <Select
                  dataSource={estadoCampo}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="numero_de_escritura"
                label="Número de Escritura"
              >
                <Select
                  dataSource={estadoCampo}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="fecha_de_escritura"
                label="Fecha de Escritura"
              >
                <Select
                  dataSource={estadoCampo}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="lugar_de_elaboracion_de_escritura"
                label="Lugar de Elaboración de la Escritura"
              >
                <Select
                  dataSource={estadoCampo}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="numero_notario_escritura"
                label="Número del Notario de la Escritura"
              >
                <Select
                  dataSource={estadoCampo}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="folio_registro_publico"
                label="Folio del Registro Público"
              >
                <Select
                  dataSource={estadoCampo}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="nombre_vendedor"
                label="Nombre Vendedor"
              >
                <Select
                  dataSource={estadoCampo}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="otros_escritura"
                label="Otros Escritura"
              >
                <Select
                  dataSource={estadoCampo}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="fecha_contrato"
                label="Fecha del Contrato"
              >
                <Select
                  dataSource={estadoCampo}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="lugar_elaboracion_contrato_convenio"
                label="Lugar de Elaboración del Contrato Convenio"
              >
                <Select
                  dataSource={estadoCampo}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="nombre_contrato"
                label="Nombre Contrato"
              >
                <Select
                  dataSource={estadoCampo}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="contrato_notariado"
                label="Contrato Notariado"
              >
                <Select
                  dataSource={estadoCampo}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="numero_acta"
                label="Número del Acta"
              >
                <Select
                  dataSource={estadoCampo}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="fecha_acta"
                label="Fecha del Acta"
              >
                <Select
                  dataSource={estadoCampo}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="numero_notario_contrato_convenio"
                label="Número del Notario Contrato Convenio"
              >
                <Select
                  dataSource={estadoCampo}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="lugar_protocolizacion"
                label="Lugar Protocolización"
              >
                <Select
                  dataSource={estadoCampo}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="otros_contrato_convenio"
                label="Otros Contrato Convenio"
              >
                <Select
                  dataSource={estadoCampo}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="fecha_convenio"
                label="Fecha Convenio"
              >
                <Select
                  dataSource={estadoCampo}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="lugar_elaboracion_documento"
                label="Lugar de Elaboración del Documento"
              >
                <Select
                  dataSource={estadoCampo}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="folio_documento"
                label="Folio del Documento"
              >
                <Select
                  dataSource={estadoCampo}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="descripcion_documento"
                label="Descripción Documento"
              >
                <Select
                  dataSource={estadoCampo}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>

    </Row>
  );
}

export default Origenes;
