/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Transfer,
  Tree,
  Input,
  TreeSelect,
  Form,
  Typography,
  Col,
} from 'antd';

import { filterArbolado } from 'utils/filters';

const { Search } = Input;
const { Text } = Typography;

const isChecked = (selectedKeys, eventKey) => selectedKeys.indexOf(eventKey) !== -1;

const reducer = (_acum, _curr, level) => {
  const acum = [..._acum];
  const curr = { ..._curr };
  // Función que retorna el nodo actual eliminando su prop 'children'
  const currValue = () => {
    delete curr.children;
    return ({ ...curr, level: curr.level ?? level - 1 });
  };
  if (curr.children?.length) {
    return acum
      // Concatenamos la prop 'children' para emular flatting
      .concat(curr.children
        ?.map((e) => ({ ...e, parentKey: curr.key, level: e.level ?? level })))
      // concatenamos nodo actual
      .concat((currValue()));
  }
  return acum.concat((currValue()));
};

const flatten = (list = [], disabled) => {
  let output = JSON.parse(JSON.stringify([...list]));
  let level = 1;
  while (output.some((e) => e.children?.length)) {
    output = output
    // Reducimos el array
    // eslint-disable-next-line no-loop-func
      .reduce((acum, curr) => reducer(acum, curr, level), []);
    level += 1;
  }
  return disabled ? output.map((e) => ({ ...e, checkable: false, selectable: false })) : output;
};

function CustomTransfer({
  dataSource,
  filterTreeNode,
  formItemName,
  label,
  rules,
  form,
  nested,
  mixed,
  ...restProps
}) {
  const [transferDataSource, setTransferDataSource] = useState([]);
  const [targetKeys, setTargetKeys] = useState([]);
  const [leftData, setLeftData] = useState([]);
  const [rightData, setRightData] = useState([]);
  const [componentKey, setComponentKey] = useState();
  const showRigthCustomTree = false;

  useEffect(() => {
    const flatted = flatten(dataSource, restProps.disabled);
    setTransferDataSource(flatted);
    // eslint-disable-next-line
  }, [dataSource]);

  const onCheck = (e, onItemSelect, checkedKeys, selectedKeys) => {
    const { node } = e;
    const { key, selectable } = node;
    if (selectable) {
      onItemSelect(key, !isChecked(checkedKeys, key));
    } else {
      const flatted = [];
      const flat = (list = []) => {
        list.forEach((item) => {
          const clone = { ...item };
          const { children } = clone;
          delete clone.children;
          flatted.push(clone);
          flat(children);
        });
      };
      flat(node?.children || []);
      let selectableKeys = flatted
        .filter((item) => item.selectable && !targetKeys.includes(item.key))
        .map((i) => i.key.toString());
      const parentHasKeysSelected = selectableKeys.length
        && selectableKeys.some((k) => selectedKeys?.includes(k));
      const parentHasAllKeysSelected = selectableKeys.length
        && selectableKeys.every((k) => selectedKeys?.includes(k));
      if (parentHasKeysSelected) {
        selectableKeys = selectableKeys.filter((k) => !targetKeys?.includes(k));
      } else if (parentHasAllKeysSelected) {
        selectableKeys = selectableKeys.filter((k) => targetKeys?.includes(k));
      }
      selectableKeys.forEach((k) => {
        setTimeout(() => {
          let check = false;
          if (parentHasAllKeysSelected) {
            check = false;
          } else if (parentHasKeysSelected) {
            check = true;
          } else {
            check = true;
          }
          onItemSelect(k, check);
        });
      });
    }
  };

  function SearchTree(props) {
    const [autoExpandParent, setAutoExpandParent] = useState(true);
    const [searchValue, setSearchValue] = useState('');
    const [expandedKeys, setExpandedKeys] = useState([]);
    const { treeData: _treeData } = props;

    const onExpand = (_expandedKeys) => {
      setExpandedKeys(_expandedKeys);
      setAutoExpandParent(false);
    };

    let timeoutSearch = null;
    // eslint-disable-next-line
    useEffect(() => () => clearTimeout(timeoutSearch), []);

    const onChange = (value) => {
      if (value) {
        const newExpandedKeys = transferDataSource
          .map((item) => {
            const title = item.title?.props?.children.toLowerCase() || item.title;
            if (title.toLowerCase().indexOf(value) > -1) {
              return item.parentKey;
            }
            return null;
          })
          .filter((item) => item);
        setExpandedKeys(newExpandedKeys);
        setSearchValue(value);
        setAutoExpandParent(true);
      } else {
        setExpandedKeys([]);
        setSearchValue(value);
        setAutoExpandParent(true);
      }
    };

    const loop = (data) => data.map((item) => {
      const title = item.title?.props?.children.toLowerCase() || item.title;
      const index = title?.toLowerCase().indexOf(searchValue);
      const beforeStr = title.substr(0, index);
      const afterStr = title.substr(index + searchValue.length);
      const titleNode = index > -1 ? (
        <span>
          {beforeStr}
          <span className="site-tree-search-value">
            {title.replace(beforeStr, '').replace(afterStr, '')}
          </span>
          {afterStr}
        </span>
      ) : (
        <span>{title}</span>
      );
      if (item.children) {
        return { ...item, title: titleNode, children: loop(item.children) };
      }

      return {
        ...item,
        title: titleNode,
      };
    });

    return (
      <>
        <Search
          allowClear
          className="search-tree-transfer"
          placeholder="Buscar..."
          onChange={(e) => {
            const value = e.target.value?.toLowerCase();
            clearTimeout(timeoutSearch);
            timeoutSearch = setTimeout(() => {
              onChange(value);
            }, 500);
          }}
          onPressEnter={(e) => e.preventDefault()}
        />
        <Tree
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          onExpand={onExpand}
          expandedKeys={expandedKeys}
          autoExpandParent={autoExpandParent}
          treeData={loop(_treeData)}
        />
      </>
    );
  }

  SearchTree.propTypes = {
    treeData: PropTypes.array.isRequired,
  };

  useEffect(() => {
    let timeout = null;
    timeout = setTimeout(() => {
      const keys = form.getFieldValue(formItemName) || [];
      const keysAsString = keys.map((k) => k.toString());
      if (nested && mixed) {
        form.setFieldsValue({ [`${formItemName}`]: keysAsString });
        setTargetKeys(keysAsString);
      } else if (!nested) {
        setTargetKeys(keys);
        keys.map((k) => k.toString());
        form.setFieldsValue({ [`${formItemName}`]: keysAsString });
      }
    });

    const key = new Date().getTime();
    setComponentKey(`${key}_transfer`);

    return () => { clearTimeout(timeout); };
    // eslint-disable-next-line
  }, []);

  const cleanData = (data = []) => [...data].map((e) => {
    if (e.selectable !== undefined) {
      e.checkable = true;
      e.disabled = false;
    }
    if (e.children) {
      return {
        ...e,
        children: cleanData(e.children),
      };
    }
    return e;
  });

  const generateTree = (
    treeNodes = [],
  ) => treeNodes.map(({ children = [], ...item }) => {
    const flatted = flatten(children);
    const selectableChildren = flatted.filter((e) => e.selectable);
    const allSelectableChildsSelected = selectableChildren.length && selectableChildren
      .filter((e) => targetKeys.includes(e.key)).length === selectableChildren.length;

    const disabled = !item.selectable
      ? allSelectableChildsSelected : targetKeys.includes(item.key);
    if (children.length) {
      return ({
        ...item,
        disabled,
        checkable: !!selectableChildren.length,
        children: generateTree(children, targetKeys),
      });
    }
    return ({
      ...item,
      disabled: !item.selectable || disabled,
      checkable: item.selectable,
    });
  });

  useEffect(() => {
    if (nested) {
      const left = generateTree(dataSource, targetKeys);
      setLeftData(left);

      const filterArboladoCleaned = filterArbolado(
        targetKeys,
        cleanData(dataSource),
        { mixed },
      );
      setRightData(filterArboladoCleaned);
    }
    // eslint-disable-next-line
  }, [targetKeys, dataSource]);

  return (
    <React.Fragment key={componentKey}>
      <Col xs={0} sm={0} md={24} style={{ marginBottom: 20 }}>
        {!restProps.disabled && (
          <Text style={{ color: '#000000DA' }}>
            {label}
          </Text>
        )}
        <Transfer
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...restProps}
          titles={['Disponibles', restProps.disabled ? label : 'Seleccionados']}
          targetKeys={targetKeys}
          dataSource={transferDataSource}
          className="tree-transfer"
          render={(item) => item.title}
          showSelectAll={false}
          onChange={(keys) => {
            setTargetKeys(keys);
            if (mixed) {
              form.setFieldsValue({ [`${formItemName}`]: keys.map((k) => k.toString()) });
            } else {
              form.setFieldsValue({ [`${formItemName}`]: keys });
            }
          }}
        >
          {/* eslint-disable-next-line consistent-return */}
          {({ direction, onItemSelect, selectedKeys }) => {
            const checkedKeys = [...selectedKeys, ...targetKeys];
            if (nested) {
              if (direction === 'left') {
                return (
                  <SearchTree
                    blockNode
                    checkable={!restProps.disabled}
                    checkedKeys={checkedKeys}
                    treeData={leftData}
                    onCheck={(_, e) => onCheck(e, onItemSelect, checkedKeys, selectedKeys)}
                    onSelect={(_, e) => onCheck(e, onItemSelect, checkedKeys, selectedKeys)}
                  />
                );
              }

              if (showRigthCustomTree) {
                return (
                  <SearchTree
                    blockNode
                    checkable={!restProps.disabled}
                    checkedKeys={checkedKeys}
                    treeData={rightData}
                    onCheck={(_, e) => onCheck(e, onItemSelect, checkedKeys, selectedKeys)}
                    onSelect={(_, e) => onCheck(e, onItemSelect, checkedKeys, selectedKeys)}
                  />
                );
              }
            }
          }}
        </Transfer>
      </Col>
      <Col xs={24} sm={24} md={0}>
        <Form.Item
          name={formItemName}
          label={label}
          rules={rules}
          hasFeedback
        >
          <TreeSelect
            disabled={restProps.disabled}
            showSearch
            multiple
            showArrow
            treeNodeFilterProp="title"
            style={{ width: '100%' }}
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            treeData={dataSource}
            filterTreeNode={filterTreeNode}
            onChange={(keys) => {
              setTargetKeys(keys.map((k) => k.toString()));
            }}
          />
        </Form.Item>
      </Col>
    </React.Fragment>
  );
}

CustomTransfer.propTypes = {
  dataSource: PropTypes.array.isRequired,
  rules: PropTypes.array,
  filterTreeNode: PropTypes.func.isRequired,
  formItemName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  form: PropTypes.any.isRequired,
  nested: PropTypes.bool,
  mixed: PropTypes.bool,
};

CustomTransfer.defaultProps = {
  rules: [],
  nested: false,
  mixed: false,
};

export default CustomTransfer;
