/* eslint-disable no-console */
import { store } from '../store/index';
import { SET_PERIODO_FISCAL_SELECTED } from '../store/reducers/auth';

export default (io) => {
  io.on('connect', () => { console.log('connected'); });
  io.on('connect_error', (error) => { console.log(error); });
  io.on('periodo_fiscal_vigente', ({ current }) => {
    if (current) {
      const { periodoFiscalSelected } = store.getState().auth;
      if (current?.id === periodoFiscalSelected?.id) {
        store.dispatch({
          type: SET_PERIODO_FISCAL_SELECTED,
          payload: current,
        });
      } else if (periodoFiscalSelected?.vigente) {
        store.dispatch({
          type: SET_PERIODO_FISCAL_SELECTED,
          payload: {
            ...periodoFiscalSelected,
            vigente: false,
          },
        });
      }
    }
  });
};
