import React from 'react';
import {
  Row,
  Col,
} from 'antd';
import logo from 'assets/logo.png';
import '../index.scss';

// eslint-disable-next-line react/prop-types
function Card({ children }) {
  return (
    <Row
      align="center"
      style={{
        backgroundColor: '#EBF3FA',
        height: '100vh',
      }}
    >
      <Col className="auth-card-container">
        <Row align="center">
          <img
            src={logo}
            alt="logo"
            className="auth-logo"
          />
        </Row>
        <div
          className="customCard"
          style={{
            padding: '35px 50px',
            backgroundColor: '#FFFFFF',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {children}
        </div>
      </Col>
    </Row>
  );
}

export default Card;
