export default [{
  id: 1,
  fecha_de_elaboracion: '06/01/2021',
  folio_orden_de_compra: 'CO-2021-06-0036',
  folio_agrupador: 'FA-2021-0004',
  tipo_de_operacion: 'Bienes Almacenables',
  folio_requisicion: 'RC-2021-06-0024',
  proveedor: 'La Nacional, SA de CV',
  importe_total: '27458.36',
  folio_presupuestal: 'FP-2021-0018',
  folio_recepcion: 'RA-2021-0456',
  cuenta_por_pagar: 'CP-2021-07-0203',
  pago: 'TR.2021-07-0273',
  tipo_de_contrato: 'Abierto',
  estados_globales: 4,
  ur_solicitante: 'Tesorería',
  proceso_adquisitivo: 'Adjudicación Directa',
  inicia: '9/1/2021',
  termina: '12/31/2021',
  prioridad: 'Baja',
  justificacion_de_requisicion: 'Compra de papelería de acuerdo al plan de compras',
  unidad_responsable: 'Contabilidad',
  lugar_de_entrega: 'Av. Juárez No. 521',
  data_table: [{
    id: 1,
    partida_presupuestal: '2021-01-E-252-21120-0032-01-01-2121-01-144',
    cog: '2121',
    descripcion: 'Hojas de papel',
    unidad_de_medida: 'Caja',
    cantidad: '8',
    precio_unitario: '1200',
    sub_total: '9600',
    iva: '1536',
    total: '11136',
  },
  {
    id: 2,
    partida_presupuestal: '2021-01-E-252-21120-0032-01-01-2121-01-144',
    cog: '2121',
    descripcion: 'Folders beige',
    unidad_de_medida: 'Caja',
    cantidad: '2',
    precio_unitario: '150',
    sub_total: '300',
    iva: '48',
    total: '348',
  },
  {
    id: 3,
    partida_presupuestal: '2021-01-E-252-21120-0032-01-01-2121-01-144',
    cog: '2121',
    descripcion: 'Carpetas',
    unidad_de_medida: 'Pieza',
    cantidad: '6',
    precio_unitario: '58',
    sub_total: '348',
    iva: '55.68',
    total: '403.68',
  },
  {
    id: 4,
    partida_presupuestal: '2021-01-E-252-21120-0032-01-01-2121-01-144',
    cog: '2121',
    descripcion: 'Plumas',
    unidad_de_medida: 'Caja',
    cantidad: '3',
    precio_unitario: '35',
    sub_total: '105',
    iva: '16.80',
    total: '121.80',
  },
  ],
},
{
  id: 2,
  fecha_de_elaboracion: '06/18/2021',
  folio_orden_de_compra: 'CO-2021-06-0037',
  folio_agrupador: 'FA-2021-0018',
  tipo_de_operacion: 'Servicios',
  folio_requisicion: 'RC-2021-06-0038',
  proveedor: 'La Vanguardista, SA de CV',
  importe_total: '389038.45',
  folio_presupuestal: 'FP-2021-0039',
  folio_recepcion: 'RA-2021-0457',
  cuenta_por_pagar: 'CP-2021-08-0064',
  pago: 'TR.2021-08-0902',
  tipo_de_contrato: 'Abierto',
  estados_globales: 4,
  ur_solicitante: 'Tesorería',
  proceso_adquisitivo: 'Adjudicación Directa',
  inicia: '9/1/2021',
  termina: '12/31/2021',
  prioridad: 'Baja',
  justificacion_de_requisicion: 'Compra de papelería de acuerdo al plan de compras',
  unidad_responsable: 'Tesorería',
  lugar_de_entrega: 'Av. Juárez No. 521',
  data_table: [{
    id: 5,
    partida_presupuestal: '2021-01-E-252-21120-0071-01-01-2121-01-144',
    cog: '2121',
    descripcion: 'Cajas archivo muerto',
    unidad_de_medida: 'Pieza',
    cantidad: '12',
    precio_unitario: '65',
    sub_total: '780',
    iva: '124.80',
    total: '904.80',
  },
  {
    id: 6,
    partida_presupuestal: '2021-01-E-252-21120-0071-01-01-2121-01-144',
    cog: '2121',
    descripcion: 'Cuadernos',
    unidad_de_medida: 'Pieza',
    cantidad: '6',
    precio_unitario: '44',
    sub_total: '264',
    iva: '42.24',
    total: '306.24',
  },
  {
    id: 7,
    partida_presupuestal: '2021-01-E-252-21120-0071-01-01-2121-01-144',
    cog: '2121',
    descripcion: 'Carpetas',
    unidad_de_medida: 'Pieza',
    cantidad: '15',
    precio_unitario: '58',
    sub_total: '870',
    iva: '139.20',
    total: '1009.20',
  },
  {
    id: 8,
    partida_presupuestal: '2021-01-E-252-21120-0071-01-01-2121-01-144',
    cog: '2121',
    descripcion: 'Hojas de papel',
    unidad_de_medida: 'Caja',
    cantidad: '4',
    precio_unitario: '1200',
    sub_total: '4800',
    iva: '768',
    total: '5568',
  },
  ],
},
{
  id: 3,
  fecha_de_elaboracion: '06/19/2021',
  folio_orden_de_compra: 'CO-2021-06-0038',
  folio_agrupador: 'FA-2021-0022',
  tipo_de_operacion: 'Bienes No Almacenables',
  folio_requisicion: 'RC-2021-06-0045',
  proveedor: 'Integradora, SA de CV',
  importe_total: '189352.90',
  folio_presupuestal: 'FP-2021-0051',
  folio_recepcion: 'RA-2021-0458',
  cuenta_por_pagar: 'CP-2021-08-0345',
  pago: 'TR.2021-09-0521',
  tipo_de_contrato: 'Abierto',
  estados_globales: 4,
  ur_solicitante: 'Tesorería',
  proceso_adquisitivo: 'Adjudicación Directa',
  inicia: '9/1/2021',
  termina: '12/31/2021',
  prioridad: 'Baja',
  justificacion_de_requisicion: 'Compra de papelería de acuerdo al plan de compras',
  unidad_responsable: 'Recursos Humanos',
  lugar_de_entrega: 'Av. Juárez No. 521',
  data_table: [{
    id: 9,
    partida_presupuestal: '2021-01-E-252-21120-0075-01-01-2121-01-144',
    cog: '2121',
    descripcion: 'Grapas estándar',
    unidad_de_medida: 'Caja',
    cantidad: '3',
    precio_unitario: '28',
    sub_total: '84',
    iva: '13.44',
    total: '97.44',
  },
  {
    id: 10,
    partida_presupuestal: '2021-01-E-252-21120-0075-01-01-2121-01-144',
    cog: '2121',
    descripcion: 'Plumas',
    unidad_de_medida: 'Caja',
    cantidad: '2',
    precio_unitario: '35',
    sub_total: '70',
    iva: '11.20',
    total: '81.20',
  },
  {
    id: 11,
    partida_presupuestal: '2021-01-E-252-21120-0075-01-01-2121-01-144',
    cog: '2121',
    descripcion: 'Folders beige',
    unidad_de_medida: 'Caja',
    cantidad: '3',
    precio_unitario: '150',
    sub_total: '450',
    iva: '72',
    total: '522',
  },
  {
    id: 12,
    partida_presupuestal: '2021-01-E-252-21120-0075-01-01-2121-01-144',
    cog: '2121',
    descripcion: 'Hojas de papel',
    unidad_de_medida: 'Caja',
    cantidad: '5',
    precio_unitario: '1200',
    sub_total: '6000',
    iva: '960',
    total: '6960',
  },
  ],
},
];
