// Constants
export const SET_LADAS = 'CATALOGS__SET_LADAS';
export const SET_ESTADOS_GLOBALES = 'CATALOGS__SET_ESTADOS_GLOBALES';
export const SET_GENEROS = 'CATALOGS__SET_GENEROS';
export const SET_METODOS_DE_PAGO = 'CATALOGS__SET_METODOS_DE_PAGO';
export const SET_TIPOS_VIALIDADES = 'CATALOGS__SET_TIPOS_VIALIDADES';
export const SET_CUENTAS_CONTABLES = 'CATALOGS__SET_CUENTAS_CONTABLES';
export const SET_CONFIG_MASCARA_CONTABLE = 'CATALOGS__SET_CONFIG_MASCARA_CONTABLE';
export const SET_TIPOS_PODER = 'CATALOGS__SET_TIPOS_PODER';
export const SET_PERIODOS = 'CATALOGS__SET_PERIODOS';
export const SET_TIPOS_PRESUPUESTOS = 'CATALOGS__SET_TIPOS_PRESUPUESTOS';
export const SET_COG = 'CATALOGS__SET_COG';
export const SET_FUENTES_DE_FINANCIAMIENTO = 'CATALOGS__SET_FUENTES_DE_FINANCIAMIENTO';
export const CLEAR_CATALOGS = 'CATALOGS__CLEAR_CATALOGS';
export const SET_MESES = 'CATALOGS__SET_MESES';
export const SET_URS = 'CATALOGS__SET_URS';
export const SET_UNIDADES_DE_MEDIDA = 'CATALOGS__SET_UNIDADES_DE_MEDIDA';
export const SET_TIPOS_DE_PRESUPUESTOS_DE_EGRESO = 'CATALOGS__SET_TIPOS_DE_PRESUPUESTOS_DE_EGRESO';
export const SET_TIPOS_CUENTAS_CONTABLES = 'CATALOGS__SET_TIPOS_CUENTAS_CONTABLES';
export const SET_CUCOP = 'CATALOGS__SET_CUCOP';
export const SET_TIPOS_DE_COSTEO = 'CATALOGS__SET_TIPOS_DE_COSTEO';
export const SET_CUENTAS_PADRES = 'CATALOGS__SET_CUENTAS_PADRES';
export const SET_CABMS = 'CATALOGS__SET_CABMS';
export const SET_NATURALEZAS_DE_INGRESO = 'CATALOGS__SET_NATURALEZAS_DE_INGRESO';
export const SET_CLASIFICADORES_ECONOMICOS = 'CATALOGS__SET_CLASIFICADORES_ECONOMICOS';
export const SET_FUENTES_DE_INGRESO = 'CATALOGS__SET_FUENTES_DE_INGRESO';
export const SET_MOMENTOS_PRESUPUESTALES_DE_INGRESO = 'CATALOGS__SET_MOMENTOS_PRESUPUESTALES_DE_INGRESO';
export const SET_MOMENTOS_PRESUPUESTALES_DE_EGRESO = 'CATALOGS__SET_MOMENTOS_PRESUPUESTALES_DE_EGRESO';
export const SET_AFECTACIONES_PRESUPUESTALES = 'CATALOGS__SET_AFECTACIONES_PRESUPUESTALES';
export const SET_CLASIFICACIONES_DE_CUENTA_CONTABLES = 'CATALOGS__SET_CLASIFICACIONES_DE_CUENTA_CONTABLES';
export const SET_MODULOS_DE_POLIZAS = 'CATALOGS__SET_MODULOS_DE_POLIZAS';
export const SET_SECCIONES_DE_POLIZAS = 'CATALOGS__SET_SECCIONES_DE_POLIZAS';
export const SET_DOCUMENTOS_DE_POLIZA = 'CATALOGS__SET_DOCUMENTOS_DE_POLIZA';
export const SET_TIPOS_DE_POLIZA = 'CATALOGS__SET_TIPOS_DE_POLIZA';
export const SET_VARIABLES_DE_POLIZA = 'CATALOGS__SET_VARIABLES_DE_POLIZA';
export const SET_CONCEPTOS_DE_POLIZA = 'CATALOGS__SET_CONCEPTOS_DE_POLIZA';
export const SET_TIPOS_DE_PLANES = 'CATALOGS__SET_TIPOS_DE_PLANES';
export const SET_TIPOS_DE_DEPOSITOS = 'CATALOGS__SET_TIPOS_DE_DEPOSITOS';

const initialState = {
  ladas: [],
  estadosGlobales: [],
  tiposVialidades: [],
  cuentasContables: [],
  tiposDePoder: [],
  configMascaraContable: null,
  metodosDePago: [],
  generos: [],
  meses: [],
  urs: [],
  unidadesDeMedida: [],
  tiposDePresupuestosDeEgreso: [],
  cog: [],
  fuentesDeFinanciamiento: [],
  tiposDeCuentasContables: [],
  cucop: [],
  tiposDeCosteos: [],
  cuentasPadres: [],
  cabms: [],
  naturalezasDeIngreso: [],
  clasificadoresEconomicos: [],
  fuentesDeIngreso: [],
  momentosPresupuestalesDeIngreso: [],
  momentosPresupuestalesDeEgreso: [],
  afectacionesPresupuestales: [],
  clasificacionesDeCuentasContables: [],
  modulosDePolizas: [],
  seccionesDePolizas: [],
  documentosDePoliza: [],
  tiposDePoliza: [],
  variablesDePoliza: [],
  conceptosDePoliza: [],
  tiposDePlanes: [],
  tiposDeDepositos: [],
};

// Action handlers
const setLadas = (state, payload) => ({ ...state, ladas: payload });
const setEstadosGlobales = (state, payload) => ({ ...state, estadosGlobales: payload });
const setTiposVialidades = (state, payload) => ({ ...state, tiposVialidades: payload });
const setCuentasContables = (state, payload) => ({ ...state, cuentasContables: payload });
const setTiposDePoder = (state, payload) => ({ ...state, tiposDePoder: payload });
const setPeriodos = (state, payload) => ({ ...state, periodos: payload });
const setTiposPresupuestos = (state, payload) => ({ ...state, tiposPresupuestos: payload });
const setGeneros = (state, payload) => ({ ...state, generos: payload });
const setMetodosDePago = (state, payload) => ({ ...state, metodosDePago: payload });
const setCOG = (state, payload) => ({ ...state, cog: payload });
const setFF = (state, payload) => ({ ...state, fuentesDeFinanciamiento: payload });
const setCUCOP = (state, payload) => ({ ...state, cucop: payload });
const setCABMS = (state, payload) => ({ ...state, cabms: payload });
const setNaturalezasDeIngreso = (state, payload) => ({ ...state, naturalezasDeIngreso: payload });
const setFuentesDeIngreso = (state, payload) => ({ ...state, fuentesDeIngreso: payload });
const setClasificadoresEconomicos = (state, payload) => ({
  ...state,
  clasificadoresEconomicos: payload,
});
const setConfigMascaraContable = (state, payload) => {
  const config = payload.find((c) => c.tipo === 1);
  return ({ ...state, configMascaraContable: config || null });
};
const setMeses = (state, payload) => ({ ...state, meses: payload });
const setTiposDeCosteos = (state, payload) => ({ ...state, tiposDeCosteos: payload });
const setUrs = (state, payload) => ({ ...state, urs: payload });
const setUnidadesDeMedida = (state, payload) => ({ ...state, unidadesDeMedida: payload });
const setTiposDePresupuestosDeEgreso = (state, payload) => ({
  ...state,
  tiposDePresupuestosDeEgreso: payload,
});
const setTiposDeCuentasContables = (state, payload) => (
  { ...state, tiposDeCuentasContables: payload });
const setCuentasPadres = (state, payload) => (
  { ...state, cuentasPadres: payload });
const setMomentosPresupuestalesDeIngreso = (state, payload) => (
  { ...state, momentosPresupuestalesDeIngreso: payload });
const setMomentosPresupuestalesDeEgreso = (state, payload) => (
  { ...state, momentosPresupuestalesDeEgreso: payload });
const setAfectacionesPresupuestales = (state, payload) => (
  { ...state, afectacionesPresupuestales: payload });
const setClasificacionesDeCuentasContables = (state, payload) => (
  { ...state, clasificacionesDeCuentasContables: payload });
const setModulosDePolizas = (state, payload) => (
  { ...state, modulosDePolizas: payload });
const setSeccionesDePolizas = (state, payload) => ({ ...state, seccionesDePolizas: payload });
const setDocumentosDePoliza = (state, payload) => ({ ...state, documentosDePoliza: payload });
const setTiposDePoliza = (state, payload) => ({ ...state, tiposDePoliza: payload });
const setVariablesDePoliza = (state, payload) => ({ ...state, variablesDePoliza: payload });
const setConceptosDePoliza = (state, payload) => ({ ...state, conceptosDePoliza: payload });
const setTiposDeDepositos = (state, payload) => ({ ...state, tiposDeDepositos: payload });
const setTiposDePlanes = (state, payload) => ({ ...state, tiposDePlanes: payload });

const clear = () => ({ ...initialState });

const ACTION_HANDLER = {
  CLEAR: clear,
  [CLEAR_CATALOGS]: clear,
  [SET_LADAS]: setLadas,
  [SET_ESTADOS_GLOBALES]: setEstadosGlobales,
  [SET_TIPOS_VIALIDADES]: setTiposVialidades,
  [SET_CUENTAS_CONTABLES]: setCuentasContables,
  [SET_CONFIG_MASCARA_CONTABLE]: setConfigMascaraContable,
  [SET_TIPOS_PODER]: setTiposDePoder,
  [SET_PERIODOS]: setPeriodos,
  [SET_TIPOS_PRESUPUESTOS]: setTiposPresupuestos,
  [SET_MESES]: setMeses,
  [SET_URS]: setUrs,
  [SET_GENEROS]: setGeneros,
  [SET_METODOS_DE_PAGO]: setMetodosDePago,
  [SET_UNIDADES_DE_MEDIDA]: setUnidadesDeMedida,
  [SET_TIPOS_DE_PRESUPUESTOS_DE_EGRESO]: setTiposDePresupuestosDeEgreso,
  [SET_COG]: setCOG,
  [SET_FUENTES_DE_FINANCIAMIENTO]: setFF,
  [SET_TIPOS_CUENTAS_CONTABLES]: setFF,
  [SET_CUCOP]: setCUCOP,
  [SET_CABMS]: setCABMS,
  [SET_TIPOS_DE_COSTEO]: setTiposDeCosteos,
  [SET_TIPOS_CUENTAS_CONTABLES]: setTiposDeCuentasContables,
  [SET_CUENTAS_PADRES]: setCuentasPadres,
  [SET_NATURALEZAS_DE_INGRESO]: setNaturalezasDeIngreso,
  [SET_CLASIFICADORES_ECONOMICOS]: setClasificadoresEconomicos,
  [SET_FUENTES_DE_INGRESO]: setFuentesDeIngreso,
  [SET_MOMENTOS_PRESUPUESTALES_DE_INGRESO]: setMomentosPresupuestalesDeIngreso,
  [SET_MOMENTOS_PRESUPUESTALES_DE_EGRESO]: setMomentosPresupuestalesDeEgreso,
  [SET_AFECTACIONES_PRESUPUESTALES]: setAfectacionesPresupuestales,
  [SET_CLASIFICACIONES_DE_CUENTA_CONTABLES]: setClasificacionesDeCuentasContables,
  [SET_MODULOS_DE_POLIZAS]: setModulosDePolizas,
  [SET_SECCIONES_DE_POLIZAS]: setSeccionesDePolizas,
  [SET_DOCUMENTOS_DE_POLIZA]: setDocumentosDePoliza,
  [SET_TIPOS_DE_POLIZA]: setTiposDePoliza,
  [SET_VARIABLES_DE_POLIZA]: setVariablesDePoliza,
  [SET_CONCEPTOS_DE_POLIZA]: setConceptosDePoliza,
  [SET_TIPOS_DE_PLANES]: setTiposDePlanes,
  [SET_TIPOS_DE_DEPOSITOS]: setTiposDeDepositos,
};

export default (state = initialState, { type, payload }) => {
  const handler = ACTION_HANDLER[type];
  return handler ? handler(state, payload) : state;
};
