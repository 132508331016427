import React from 'react';
import SingleForm from 'components/SingleForm';

export const permissionSituacionDeObra = {
  permissionModel: 'situaciondeobra',
};

// eslint-disable-next-line react/prop-types
function SituacionDeObra({ permission }) {
  return (
    <SingleForm
      permission={permission}
      baseURI="obrapublica/situacion-de-obra/"
      title="Situación De Obra"
    />
  );
}

export default SituacionDeObra;
