/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Spin,
  Typography,
  Modal,
  TreeSelect,
  message,
} from 'antd';
import FormSubmitControls from 'components/FormSubmitControls';
import API from 'utils/api';
import { onError, onSuccess } from 'utils/handlers';
import Select from 'components/Select';
// import { useSelector } from 'react-redux';
import ModalDelete from 'components/ModalDelete';
import Table from 'components/Table';
import NumericInput from 'components/NumericInput';

function MetasFID({
  selectedRowKey,
  medidaDelIndicador,
  frecuenciaDeMedicion,
  factibilidadMeta,
  tipoDeMeta,
  indicadorId,
  indicadoresData,
  setMetas,
  // actividadId,

}) {
  const baseURI = '/presupuestos/meta-de-programa-presupuestario-fid/';
  const [formGeneral] = Form.useForm();
  const mostrarPorcentaje = Form.useWatch('tipo_valor_meta', formGeneral);
  const [formResponsable] = Form.useForm();
  // const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [visibleResponsable, setVisibleResponsable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [empleados, setEmpleados] = useState([]);
  const [puestos, setPuestos] = useState([]);
  const [contentTypes, setContentTypes] = useState([]);
  const [treeData, setTreeData] = useState([]);
  const [calendarizacionSeleccionada, setCalendarizacionSeleccionada] = useState(0);
  const formInstances = [
    formGeneral,
  ];

  const fetchData = async () => {
    try {
      setLoading(true);
      if (selectedRowKey[0] !== 0) {
        const res = await API.get(baseURI, {
          params: {
            programa_fid: selectedRowKey[0],
          },
        });
        if (res?.status === 200) {
          setMetas(res.data);
          setData(res.data);
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const getURForTreeSelect = (_data) => {
    if (_data.content_type) {
      const { nivel } = contentTypes.find((ct) => ct.id === _data.content_type);
      const { object_id } = _data;
      setTimeout(() => {
        formResponsable.setFieldsValue({ area_responsable: `${object_id}/${nivel}` });
      });
    }
  };

  const onCancel = () => {
    setVisible(false);
    setVisibleResponsable(false);
    formGeneral.resetFields();
    formResponsable.resetFields();
    setSelectedRowKeys([]);
    setCalendarizacionSeleccionada();
  };

  const onCancelResponsable = () => {
    setVisibleResponsable(false);
  };

  const normalizeDataUrs = (_data) => {
    if (_data) {
      const output = _data.map((item) => {
        const clone = { ...item };
        const unidad_responsable = normalizeDataUrs(clone.unidad_responsable);
        const centro_de_costo = normalizeDataUrs(clone.centro_de_costo);
        if (!unidad_responsable.length && !centro_de_costo.length) {
          delete clone.unidad_responsable;
          delete clone.centro_de_costo;
          return ({
            title: clone.descripcion,
            value: `${clone.id}/${clone.nivel}`,
          });
        }
        if (unidad_responsable) {
          return ({
            title: clone.descripcion,
            value: `${clone.id}/${clone.nivel}`,
            children: unidad_responsable,
            // disabled: true,
          });
        }
        return ({
          title: clone.descripcion,
          value: `${clone.id}/${clone.nivel}`,
          children: centro_de_costo,
          // disabled: true,
        });
      });
      return output;
    }
    return false;
  };

  useEffect(() => {
    let mounted = true;
    const fetchAll = async () => {
      try {
        setLoading(true);
        if (mounted) {
          setLoading(false);
          await fetchData();
        }

        const resEmpleados = await API.get('/estructura-organizacional/empleados/');
        setEmpleados(resEmpleados.data.map((e) => ({
          ...e,
          nombre_completo: `${e.numero_de_empleado || ' '} - ${e.nombre || ' '} ${e.apellido_paterno || ' '}`,
          nombre_y_apellido: `${e.nombre || ' '} ${e.apellido_paterno || ' '} ${e.apellido_materno || ' '}`,
        })));

        const resPuestos = await API.get('/estructura-organizacional/puestos/');
        setPuestos(resPuestos.data);

        const resContentTypes = await API.get('catalogos/content-types-ur');
        setContentTypes(resContentTypes.data);

        const resUR = await API.get('estructura-organizacional/unidades-de-trabajo');
        const formattedUr = normalizeDataUrs(resUR.data);
        setTreeData(formattedUr);
      } catch (err) {
        onError(err, setLoading);
      }
    };
    fetchAll();
    return () => { mounted = false; };
    // eslint-disable-next-line
  }, [selectedRowKey[0]]);

  const onFinish = async () => {
    try {
      await formGeneral.validateFields();
      const values = {
        ...formGeneral.getFieldsValue(),
        programa_fid: selectedRowKey[0],
        indicador_fid: indicadorId,
      };
      const metasKeys = Object.keys(values).filter((e) => /\d+/.test(e));
      const _metas = [];
      metasKeys.forEach((e) => {
        _metas.push(values[e]);
        delete values[e];
      });
      if (selectedRowKeys.length === 0) {
        const res = await API.post(baseURI, values);
        if (res?.status === 201) {
          const resMetasValues = await API.get(baseURI);
          if (_metas) {
            const promises = [];
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < _metas.length; i++) {
              _metas[i].numero = i + 1;
              _metas[i].variable_a = parseFloat(_metas[i].variable_a, 10);
              _metas[i].variable_b = parseFloat(_metas[i].variable_b, 10);
              _metas[i].meta_fid = resMetasValues.data[resMetasValues.data.length - 1].id;
              promises.push(API.post('/presupuestos/calendarizacion-de-meta-de-ppfid/', _metas[i]));
            }
            const responses = await Promise.allSettled(promises);
            if (!responses.every((response) => response.status === 'fulfilled')) {
              message.info('No se agrego la meta');
            }
          }
          onCancel();
          await fetchData();
        }
      } else {
        const [key] = selectedRowKeys;
        const res = await API.patch(`${baseURI}${key}/`, values);
        if (res?.status === 200) {
          if (_metas) {
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < _metas.length; i++) {
              _metas[i].numero = i + 1;
              _metas[i].variable_a = parseFloat(_metas[i].variable_a, 10);
              _metas[i].variable_b = parseFloat(_metas[i].variable_b, 10);
              _metas[i].resultado = parseFloat(_metas[i].resultado, 10);
              _metas[i].meta_fid = key;
              // eslint-disable-next-line no-await-in-loop
              const resMetas = await API.put(`${'/presupuestos/calendarizacion-de-meta-de-ppfid/'}${_metas[i].id}`, _metas[i]);
              if (!resMetas?.status === 201) {
                message.info('No se edito la meta');
              }
            }
          }
          onCancel();
          await fetchData();
        }
      }

      setLoading(false);
    } catch (err) {
      onError(err, formInstances, setLoading);
    }
  };

  const columns = [
    {
      titleText: 'Meta de Administración',
      dataIndex: 'meta_de_administracion',
      key: 'meta_de_administracion',
      width: 350,
    },
    {
      titleText: 'Tipo de Valor de la Meta',
      dataIndex: 'tipo_valor_meta',
      key: 'tipo_valor_meta',
      width: 350,
      render: (id) => tipoDeMeta.find((e) => e.id === id)?.descripcion,

    },
    {
      titleText: 'Justificación de factibilidad de la Meta',
      dataIndex: 'justificacion_factibilidad_meta',
      key: 'justificacion_factibilidad_meta',
      width: 350,
    },
  ];

  const calendarizacionesMap = {
    1: ['Enero - Diciembre'],

    2: ['1er Semestre Enero - Junio',
      '2do Semestre Julio - Diciembre',
    ],

    3: ['1er Cuatrimestre Enero - Abril',
      '2do Cuatrimestre Mayo - Agosto',
      '3er Cuatrimestre Septiembre - Diciembre',
    ],

    4: ['1er Trimestre Enero - Marzo',
      '2do Trimestre Abril - Junio',
      '3er Trimestre Julio - Septiembre',
      '4to Trimestre Octubre - Diciembre',
    ],

    5: ['1er Bimestre Enero - Febrero',
      '2do Bimestre Marzo - Abril',
      '3er Bimestre Mayo - Junio',
      '4to Bimestre Julio - Agosto',
      '5to Bimestre Septiembre - Octubre',
      '6to Bimestre Noviembre - Diciembre',
    ],

    6: ['Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ],

  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
  };

  const onClickAdd = async () => {
    onCancel();
    if (indicadorId) {
      const selectedIndicador = indicadoresData.find((e) => e.id === indicadorId);
      setTimeout(() => {
        formGeneral.setFieldsValue({
          // ...selectedIndicador,
          formula_de_calculo: selectedIndicador?.formula,
          calendarizacion: selectedIndicador?.frecuencia_de_medicion,
          variable_de_calculo_a: selectedIndicador?.variable_a,
          variable_de_calculo_b: selectedIndicador?.variable_b,
          unidad_variable_a: selectedIndicador?.unidad_a,
          unidad_variable_b: selectedIndicador?.unidad_b,
        });
      });
      setCalendarizacionSeleccionada(selectedIndicador?.frecuencia_de_medicion);
    }

    setVisible(true);
  };

  const onClickEdit = async () => {
    try {
      setLoading(true);
      const [key] = selectedRowKeys;
      const match = data.find((e) => e.id === key);
      if (match?.calendarizacion) {
        setCalendarizacionSeleccionada(match.calendarizacion);
      }
      const resMetas = await API.get('/presupuestos/calendarizacion-de-meta-de-ppfid/', {
        params: {
          meta_fid: key,
        },
      });
      const valuesMetas = Object.fromEntries(resMetas.data.map((e) => [e.numero - 1, e]));
      setTimeout(() => {
        formGeneral.setFieldsValue({
          ...valuesMetas,
          ...match,
        });
      });
      if (match?.indicador_fid) {
        const selectedIndicador = indicadoresData.find((e) => e.id === match?.indicador_fid);
        setTimeout(() => {
          formGeneral.setFieldsValue({
          // ...selectedIndicador,
            formula_de_calculo: selectedIndicador?.formula,
            calendarizacion: selectedIndicador?.frecuencia_de_medicion,
            variable_de_calculo_a: selectedIndicador?.variable_a,
            variable_de_calculo_b: selectedIndicador?.variable_b,
            unidad_variable_a: selectedIndicador?.unidad_a,
            unidad_variable_b: selectedIndicador?.unidad_b,
          });
        });
      }
      if (match?.responsable) {
        const resEmpleado = empleados.find((e) => e.id === match?.responsable);
        getURForTreeSelect(resEmpleado);
        setTimeout(() => {
          formResponsable.setFieldsValue({
            ...resEmpleado,
          });
        });
      }
      setLoading(false);
    } catch (error) {
      onError(error, setLoading);
    }
    setVisible(true);
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const deleteItem = async () => {
    try {
      const [key] = selectedRowKeys;
      if (selectedRowKey) {
        if (selectedRowKeys.length) {
          const response = await API.delete(`${baseURI}${key}/`);
          if (response?.status === 204) {
            onSuccess(response, 'Eliminado correctamente');
            onCancel();
            await fetchData();
            setVisibleAlert(false);
          }
        }
      }
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const calculate = (expression, a, b) => {
    const newString = expression.replace('(', '')
      .replace(')', '')
      .replace('a', a.toString())
      .replace('b', b.toString())
      .replace('A', a.toString())
      .replace('B', b.toString());

    if (newString.substr(-1) !== '/'
    && newString.substr(-1) !== '*'
    && newString.substr(-1) !== '-'
    && newString.substr(-1) !== '+') {
      const regex = /(\d+)([+\-*/]\d+)*/g;
      const isValid = regex.test(newString);
      if (isValid) {
      // eslint-disable-next-line no-new-func
        return Function(`
      'use strict';
      try {
        return (${newString});
      } catch {
        return 0
      }
      `)();
      }
    }
    return 0;
  };

  const requiredRule = {
    message: 'Este campo es requerido',
  };

  const rules = {
    required: [requiredRule],
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  return (
    <Row align="center" justify="center" className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible ? (
          <Table
            cols={columns}
            data={data}
            rowSelection={rowSelection}
            handleOnRowClick={handleOnRowClick}
            controls={{
              onClickAdd,
              onClickEdit,
              onClickDelete,
            }}
            mobileColIndex={0}
          />
        ) : (
          <Card
            bordered={false}
            className="form-container"
            title={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Indicador`}
            extra={(
              <FormSubmitControls
                onFinish={onFinish}
                onCancel={onCancel}
                loading={loading}
              />
            )}
          >

            <Form
              layout="vertical"
              form={formGeneral}
              onFinish={onFinish}
              scrollToFirstError
              initialValues={{
                estados_globales: 1,
                tipo_valor_meta: 1,
              }}
            >
              <Row gutter={10}>
                <Col xs={24} sm={24} md={24}>
                  <Form.Item
                    label="Meta de Administración"
                    rules={rules.required}
                    name="meta_de_administracion"
                  >
                    <Input.TextArea
                      autoSize={{ minRows: 4, maxRows: 4 }}
                      allowClear
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={10}>
                <Col xs={24} sm={24} md={16}>
                  <Form.Item
                    required
                    label="Variable de Cálculo a"
                    name="variable_de_calculo_a"
                  >
                    <Input
                      allowClear
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    label="Unidad de Variable a"
                    name="unidad_variable_a"
                    required
                  >
                    <Select labelProp="nombre" dataSource={medidaDelIndicador} disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={16}>
                  <Form.Item
                    rules={rules.required}
                    label="Variable de Calculo b"
                    name="variable_de_calculo_b"
                    required
                  >
                    <Input
                      allowClear
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    label="Unidad de Variable b"
                    name="unidad_variable_b"
                    required
                  >
                    <Select labelProp="nombre" dataSource={medidaDelIndicador} disabled />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={10}>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    label="Factibilidad de Meta"
                    name="factibilidad_meta"
                  >
                    <Select
                      dataSource={factibilidadMeta}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    label="Tipo Valor Meta"
                    name="tipo_valor_meta"
                  >
                    <Select
                      dataSource={tipoDeMeta}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    rules={rules.required}
                    label="Justificacón de Factibilidad de Meta"
                    name="justificacion_factibilidad_meta"
                  >
                    <Input.TextArea
                      autoSize={{ minRows: 4, maxRows: 4 }}
                      allowClear
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    label="Calendarización"
                    name="calendarizacion"
                  >
                    <Select
                      dataSource={frecuenciaDeMedicion}
                      onChange={(val) => {
                        setCalendarizacionSeleccionada(val);
                      }}
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={5}>
                  <Form.Item
                    rules={rules.required}
                    label="Formula de Cálculo"
                    name="formula_de_calculo"
                  >
                    <Input
                      allowClear
                      disabled
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Typography.Title level={4}>Metas</Typography.Title>
              {calendarizacionSeleccionada && calendarizacionesMap[calendarizacionSeleccionada]
                .map((label, i) => (
                  <Row gutter={10}>
                    <Col xs={24} sm={24} md={6}>
                      <h3>{label}</h3>
                    </Col>
                    <Col xs={24} sm={24} md={6}>
                      <NumericInput
                        label={i === 0 ? 'Variable A' : ''}
                        name={[i, 'variable_a']}
                        decimal
                        allowZero
                        onChange={() => {
                          const values = {
                            ...formGeneral.getFieldsValue(),
                          };
                          if (values?.formula_de_calculo) {
                            const metasKeys = Object.keys(values).filter((e) => /\d+/.test(e));
                            const _metas = [];
                            metasKeys.forEach((e) => {
                              _metas.push(values[e]);
                              delete values[e];
                            });
                            // eslint-disable-next-line no-plusplus
                            for (let j = 0; j < _metas.length; j++) {
                              if (_metas[j]?.variable_a && _metas[j]?.variable_b) {
                                _metas[j].resultado = calculate(
                                  values.formula_de_calculo,
                                  _metas[j].variable_a,
                                  _metas[j].variable_b,
                                ).toFixed(2);
                              }
                            }
                            setTimeout(() => {
                              formGeneral.setFieldsValue({
                                ..._metas,
                              });
                            });
                          }
                        }}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={6}>
                      <NumericInput
                        label={i === 0 ? 'Variable B' : ''}
                        name={[i, 'variable_b']}
                        decimal
                        allowZero
                        onChange={() => {
                          const values = {
                            ...formGeneral.getFieldsValue(),
                          };
                          if (values?.formula_de_calculo) {
                            const metasKeys = Object.keys(values).filter((e) => /\d+/.test(e));
                            const _metas = [];
                            metasKeys.forEach((e) => {
                              _metas.push(values[e]);
                              delete values[e];
                            });
                            // eslint-disable-next-line no-plusplus
                            for (let j = 0; j < _metas.length; j++) {
                              if (_metas[j]?.variable_a && _metas[j]?.variable_b) {
                                _metas[j].resultado = calculate(
                                  values.formula_de_calculo,
                                  _metas[j].variable_a,
                                  _metas[j].variable_b,
                                ).toFixed(2);
                              }
                            }
                            setTimeout(() => {
                              formGeneral.setFieldsValue({
                                ..._metas,
                              });
                            });
                          }
                        }}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={6}>
                      <NumericInput
                        label={i === 0 ? 'Resultado' : ''}
                        name={[i, 'resultado']}
                        prefix={mostrarPorcentaje === 2 ? '%' : ' '}
                        decimal
                        allowZero
                        allowNegative
                        disabled
                      />
                    </Col>
                    <Form.Item
                      name={[i, 'id']}
                      hidden
                    >
                      <Input allowClear />
                    </Form.Item>
                  </Row>
                ))}
              <Typography.Title level={4}>Rango de Evaluación</Typography.Title>
              <Row gutter={10}>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    label=" "
                  >
                    <Button
                      type="primary"
                      style={{
                        background: 'rgba(13, 255, 43, 0.5)',
                        borderColor: 'transparent',
                        width: 300,
                      }}
                      disabled
                    >
                      Resultados Aceptables
                    </Button>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    required
                    label="De"
                    name="resultados_aceptables_minimos"
                  >
                    <Input
                      allowClear
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    required
                    label="Hasta"
                    name="resultados_aceptables_maximos"
                  >
                    <Input
                      allowClear
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={10}>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    label=" "
                  >
                    <Button
                      type="primary"
                      style={{
                        background: 'rgba(255, 228, 0, 0.5)',
                        borderColor: 'transparent',
                        width: 300,
                      }}
                      disabled
                    >
                      Resultados por debajo de lo aceptable
                    </Button>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    required
                    label="De"
                    name="resultado_debajo_aceptable_minimo"
                  >
                    <Input
                      allowClear
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    required
                    label="Hasta"
                    name="resultados_debajo_aceptable_maximo"
                  >
                    <Input
                      allowClear
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={10}>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    label=" "
                  >
                    <Button
                      type="primary"
                      style={{
                        background: 'rgba(255, 0, 0, 0.3)',
                        borderColor: 'transparent',
                        width: 300,
                      }}
                      disabled
                    >
                      Resultados Inaceptables o inexistentes
                    </Button>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    required
                    label="De"
                    name="minimo_inaceptable_o_inexistente"
                  >
                    <Input
                      allowClear
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    required
                    label="Hasta"
                    name="maximo_inaceptable_o_inexistente"
                  >
                    <Input
                      allowClear
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item hidden>
                <Button htmlType="submit" />
              </Form.Item>
            </Form>
          </Card>
        )}
      </Spin>
      <Modal
        visible={visibleResponsable}
        title={(
          <FormSubmitControls
            label="Responsable del indicador"
            onCancel={onCancelResponsable}
          />
          )}
        closable={false}
        maskClosable={!loading}
        keyboard={!loading}
        onCancel={onCancelResponsable}
        footer={null}
        forceRender
      >
        <Form
          layout="vertical"
          form={formResponsable}
          scrollToFirstError
        >
          <Col span={24}>
            <Form.Item
              label="Nombre"
              name="nombre_y_apellido"
            >
              <Input
                disabled
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Puesto"
              name="puesto"
            >
              <Select
                dataSource={puestos}
                labelProp="descripcion"
                disabled
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Área Responsble"
              name="area_responsable"
            >
              <TreeSelect
                disabled
                showSearch
                treeNodeFilterProp="title"
                style={{ width: '100%' }}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                treeData={treeData}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Correo"
              name="correo_electronico"
            >
              <Input
                disabled
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Teléfono"
              name="numero_de_telefono"
            >
              <Input
                disabled
              />
            </Form.Item>
          </Col>
        </Form>
      </Modal>
      <ModalDelete
        onDelete={deleteItem}
        onCancel={() => {
          setVisibleAlert(false);
          setSelectedRowKeys([]);
        }}
        visible={visibleAlert}
        content="Eliminar la Matriz seleccionada"
      />
    </Row>
  );
}

MetasFID.propTypes = {
  selectedRowKey: PropTypes.arrayOf(PropTypes.number),
  medidaDelIndicador: PropTypes.arrayOf(PropTypes.object).isRequired,
  frecuenciaDeMedicion: PropTypes.arrayOf(PropTypes.object).isRequired,
  factibilidadMeta: PropTypes.func.isRequired,
  tipoDeMeta: PropTypes.func.isRequired,
  indicadorId: PropTypes.number.isRequired,
  indicadoresData: PropTypes.arrayOf(PropTypes.object).isRequired,
  setMetas: PropTypes.func.isRequired,
};

MetasFID.defaultProps = {
  selectedRowKey: [],
};

export default MetasFID;
