import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Spin,
  // Modal,
  Card,
  Tabs,
  // message,
  // Typography,
} from 'antd';

import API from 'utils/api';
import { onError, onSuccess } from 'utils/handlers';
import Table from 'components/Table';
import FormSubmitControls from 'components/FormSubmitControls';
import ModalDelete from 'components/ModalDelete';
import Select from 'components/Select';
import { isMutable } from 'utils/estadosGlobales';
// import createClave from 'utils/keyGenerator';
// import { isMutable, isAuthorized } from 'utils/estadosGlobales';
import _data from 'mocks/cuadroComparativo';
// import currencyFormatter from 'utils/currencyFormatter';

function CuadroComparativo() {
  const { TabPane } = Tabs;

  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);

  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRowKeysCompras, setSelectedRowKeysCompras] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);
  const [mutable, setMutable] = useState(true);
  const [selected, setSelected] = useState();
  const inputDescripcion = useRef();

  const fetchData = async () => {
    try {
      setLoading(true);
      // const res = await API.get(baseURI);
      setData(_data);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchData();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    setSelectedRowKeys([]);
    setSelected();
    setMutable(true);
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      if (!selectedRowKeys.length) {
        const response = await API.post('', values);
        if (response?.status === 201) {
          onSuccess(response, 'Agregado correctamente');
          onCancel();
          await fetchData();
        }
      } else {
        const [key] = selectedRowKeys;
        const response = await API.put(`${key}/`, values);
        if (response?.status === 200) {
          onSuccess(response, 'Actualizado correctamente');
          onCancel();
          await fetchData();
        }
      }
      onCancel();
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  // const deleteItem = async () => {
  //   try {
  //     setLoading(true);
  //     if (selectedRowKeys.length) {
  //       const [key] = selectedRowKeys;
  //       const response = await API.delete(`${key}/`);
  //       if (response?.status === 204) {
  //         onSuccess(response, 'Eliminado correctamente');
  //         setVisibleAlert(false);
  //         onCancel();
  //         await fetchData();
  //       }
  //     }
  //     setLoading(false);
  //   } catch (err) {
  //     onError(err, setLoading);
  //   }
  // };

  const handleOnRowClick = (record) => {
    setSelected(record);
    setSelectedRowKeys([record.id]);
    setMutable(isMutable(record));
    form.setFieldsValue(record);
  };

  const handleOnRowClickCompras = (record) => {
    setSelectedRowKeysCompras([record.id]);
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const rowSelectionCompras = {
    selectedRowKeys: selectedRowKeysCompras,
    type: 'radio',
  };

  // const onClickAdd = () => {
  //   onCancel();
  //   createClave(data, form);
  //   setVisible(true);
  // };

  const onClickEdit = () => setVisible(true);

  // const onClickDelete = () => {
  //   const [key] = selectedRowKeys;
  //   const match = data.find((e) => e.id === key);
  //   if (isMutable(match)) {
  //     setVisibleAlert(true);
  //   } else if (isAuthorized(match)) {
  //     message.warn('No se puede eliminar un registro autorizado');
  //   } else {
  //     message.warn('No se puede eliminar un registro cancelado');
  //   }
  // };

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
  };

  const columnsProveedores = [
    {
      titleText: 'Clave',
      dataIndex: 'clave',
      key: 'clave',
      width: 400,
    },
    {
      titleText: 'Nombre del Proveedor',
      dataIndex: 'nombre_del_proveedor',
      key: 'nombre_del_proveedor',
      width: 200,
    },
    {
      titleText: '',
      dataIndex: '',
      key: '',
      width: 200,
    },
    {
      titleText: 'Tasa de IVA',
      dataIndex: 'tasa_de_iva',
      key: 'tasa_de_iva',
      width: 200,
    },
    {
      titleText: 'Asignación',
      dataIndex: 'asignacion',
      key: 'asignacion',
      width: 300,
    },
  ];
  const columnsComparativosSecond = [
    {
      titleText: '108  |  Papelería de Oficina, SA de CV',
      dataIndex: '',
      key: '',
      width: 400,
    },
    {
      titleText: 'Producto',
      dataIndex: 'producto',
      key: 'producto',
      width: 200,
    },
    {
      titleText: 'Unidad de Medida',
      dataIndex: 'unidad_de_medida',
      key: 'unidad_de_medida',
      width: 200,
    },
    {
      titleText: 'Cantidad',
      dataIndex: 'cantidad',
      key: 'cantidad',
      width: 100,
    },
    {
      titleText: 'Precio',
      dataIndex: 'precio',
      key: 'precio',
      width: 100,
    },
    {
      titleText: 'Subtotal',
      dataIndex: 'subtotal',
      key: 'subtotal',
      width: 100,
    },
    {
      titleText: 'IVA',
      dataIndex: 'iva',
      key: 'iva',
      width: 100,
    },
    {
      titleText: 'Total',
      dataIndex: 'total',
      key: 'total',
      width: 100,
    },
  ];
  const columnsComparativosThird = [
    {
      titleText: '064 | Insumos de Oficina, SA CV',
      dataIndex: '',
      key: '',
      width: 400,
    },
    {
      titleText: 'Producto',
      dataIndex: 'producto',
      key: 'producto',
      width: 200,
    },
    {
      titleText: 'Unidad de Medida',
      dataIndex: 'unidad_de_medida',
      key: 'unidad_de_medida',
      width: 200,
    },
    {
      titleText: 'Cantidad',
      dataIndex: 'cantidad',
      key: 'cantidad',
      width: 100,
    },
    {
      titleText: 'Precio',
      dataIndex: 'precio',
      key: 'precio',
      width: 100,
    },
    {
      titleText: 'Subtotal',
      dataIndex: 'subtotal',
      key: 'subtotal',
      width: 100,
    },
    {
      titleText: 'IVA',
      dataIndex: 'iva',
      key: 'iva',
      width: 100,
    },
    {
      titleText: 'Total',
      dataIndex: 'total',
      key: 'total',
      width: 100,
    },
  ];
  const columnsProductos = [
    {
      titleText: 'Clave Presupuestal',
      dataIndex: 'clave_presupuestal',
      key: 'clave_presupuestal',
      width: 400,
    },
    {
      titleText: 'COG',
      dataIndex: 'cog',
      key: 'cog',
      width: 100,
    },
    {
      titleText: 'Clave Producto',
      dataIndex: 'clave_producto',
      key: 'clave_producto',
      width: 200,
    },
    {
      titleText: 'Producto',
      dataIndex: 'producto',
      key: 'producto',
      width: 200,
    },
    {
      titleText: 'Unidad de Medida',
      dataIndex: 'unidad_de_medida',
      key: 'unidad_de_medida',
      width: 300,
    },
    {
      titleText: 'Cantidad',
      dataIndex: 'cantidad',
      key: 'cantidad',
      width: 100,
    },
    {
      titleText: 'Asignado',
      dataIndex: 'asignado',
      key: 'asignado',
      width: 100,
    },
    {
      titleText: 'Por Asignar',
      dataIndex: 'por_asignar',
      key: 'por_asignar',
      width: 200,
    },
  ];

  const columns = [
    {
      titleText: 'No. Requisición Individual',
      dataIndex: 'no_requisicion_individual',
      key: 'no_requisicion_individual',
      width: 300,
    },
    {
      titleText: 'No. Requisición Consolidada',
      dataIndex: 'no_requisicion_consolidada',
      key: 'no_requisicion_consolidada',
      width: 300,
    },
    {
      titleText: 'Folio Agrupador',
      dataIndex: 'folio_agrupador',
      key: 'folio_agrupador',
      width: 200,
    },
    {
      titleText: 'Tipo Operación',
      dataIndex: 'tipo_operacion',
      key: 'tipo_operacion',
      width: 200,
    },
    {
      titleText: 'Prioridad',
      dataIndex: 'prioridad',
      key: 'prioridad',
      width: 100,
    },
    {
      titleText: 'Proceso Adquisitivo',
      dataIndex: 'proceso_adquisitivo',
      key: 'proceso_adquisitivo',
      width: 200,
    },
    {
      titleText: 'No. Cuadro Comparativo',
      dataIndex: 'no_cuadro_comparativo',
      key: 'no_cuadro_comparativo',
      width: 200,
    },
    {
      titleText: 'Fecha Elaboración',
      dataIndex: 'fecha_elaboracion',
      key: 'fecha_elaboracion',
      width: 200,
    },
    {
      titleText: 'Elaboró',
      dataIndex: 'elaboro',
      key: 'elaboro',
      width: 200,
    }, {
      titleText: 'Puesto',
      dataIndex: 'puesto',
      key: 'puesto',
      width: 100,
    }, {
      titleText: 'Área',
      dataIndex: 'area',
      key: 'area',
      width: 150,
    }, {
      titleText: 'Estatus',
      dataIndex: 'estatus',
      key: 'estatus',
      width: 100,
    }, {
      titleText: 'Fecha Asignación',
      dataIndex: 'fecha_asignacion',
      key: 'fecha_asignacion',
      width: 200,
    }, {
      titleText: 'Proveedor Asignado',
      dataIndex: 'proveedor_asignado',
      key: 'proveedor_asignado',
      width: 200,
    }, {
      titleText: 'Observaciones',
      dataIndex: 'observaciones',
      key: 'observaciones',
      width: 400,
    }, {
      titleText: 'Orden de Compra',
      dataIndex: 'orden_de_compra',
      key: 'orden_de_compra',
      width: 200,
    }, {
      titleText: 'Recepción',
      dataIndex: 'recepcion',
      key: 'recepcion',
      width: 200,
    }, {
      titleText: 'Cuenta por Pagar',
      dataIndex: 'cuenta_por_pagar',
      key: 'cuenta_por_pagar',
      width: 200,
    }, {
      titleText: 'Pago',
      dataIndex: 'pago',
      key: 'pago',
      width: 200,
    },
  ];
  const columnsComparativosFirst = [
    {
      titleText: 'Clave Presupuestal',
      dataIndex: 'clave_presupuestal',
      key: 'clave_presupuestal',
      width: 400,
    },
    {
      titleText: 'COG',
      dataIndex: 'cog',
      key: 'cog',
      width: 100,
    },
    {
      titleText: 'Clave Producto',
      dataIndex: 'clave_producto',
      key: 'clave_producto',
      width: 200,
    },
    {
      titleText: 'Producto',
      dataIndex: 'producto',
      key: 'producto',
      width: 200,
    },
    {
      titleText: 'Unidad de Medida',
      dataIndex: 'unidad_de_medida',
      key: 'unidad_de_medida',
      width: 300,
    },
    {
      titleText: 'Cantidad',
      dataIndex: 'cantidad',
      key: 'cantidad',
      width: 100,
    },
    {
      titleText: 'Precio Estudio Mercado (Precio)',
      dataIndex: 'precio',
      key: 'precio',
      width: 300,
    },
    {
      titleText: 'Precio Estudio Mercado (IVA)',
      dataIndex: 'iva',
      key: 'iva',
      width: 300,
    },
    {
      titleText: 'Precio Estudio Mercado (Total)',
      dataIndex: 'total',
      key: 'total',
      width: 300,
    },
    {
      titleText: '108  |  Papelería de Oficina, SA de CV (Precio)',
      dataIndex: 'precio_s',
      key: 'precio_s',
      width: 300,
    },
    {
      titleText: '108  |  Papelería de Oficina, SA de CV (IVA)',
      dataIndex: 'iva_s',
      key: 'iva_s',
      width: 300,
    },
    {
      titleText: '108  |  Papelería de Oficina, SA de CV (Total)',
      dataIndex: 'total_s',
      key: 'total_s',
      width: 300,
    },
    {
      titleText: '201  |  La Surtidora de Oficinas, SA de CV (Precio)',
      dataIndex: 'precio_t',
      key: 'precio_t',
      width: 400,
    },
    {
      titleText: '201  |  La Surtidora de Oficinas, SA de CV (IVA)',
      dataIndex: 'iva_t',
      key: 'iva_t',
      width: 400,
    },
    {
      titleText: '201  |  La Surtidora de Oficinas, SA de CV  (Total)',
      dataIndex: 'total_t',
      key: 'total_t',
      width: 400,
    },
    {
      titleText: '201  |  064  |  Insumos de Oficina, SA de CV (Precio)',
      dataIndex: 'precio_f',
      key: 'precio_f',
      width: 400,
    },
    {
      titleText: '201  |  064  |  Insumos de Oficina, SA de CV (IVA)',
      dataIndex: 'iva_f',
      key: 'iva_f',
      width: 400,
    },
    {
      titleText: '201  |  064  |  Insumos de Oficina, SA de CV  (Total)',
      dataIndex: 'total_f',
      key: 'total_f',
      width: 400,
    },
    {
      titleText: 'Mejor Precio',
      dataIndex: 'mejor_precio',
      key: 'mejor_precio',
      width: 200,
    },
    {
      titleText: 'Asignación',
      dataIndex: 'asignacion',
      key: 'asignacion',
      width: 200,
    },
  ];

  const controls = () => ({
    onClickEdit,
  });

  const getRules = () => {
    if (!mutable) {
      return null;
    }
    return rules.required;
  };

  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible ? (
          <Table
            cols={columns}
            data={data}
            rowSelection={rowSelection}
            handleOnRowClick={handleOnRowClick}
            controls={controls()}
            mobileColIndex={0}
          />
        ) : (
          <Card
            className="form-container"
            title={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Cuadro Comparativo`}
            extra={(
              <FormSubmitControls
                onFinish={mutable ? onFinish : false}
                onCancel={onCancel}
                loading={loading}
                baseURI="a"
                selectedRowKeys={selectedRowKeys}
                allowAuthorize
                allowCancel

              />
            )}
            bordered={false}
          >
            <Tabs
              // onChange={!mutable ? setCurrentTabKey : onChangeTabKey}
              // activeKey={currentTabKey}
              type="card"
            >
              <TabPane tab="Productos" key="general">
                <Form
                  layout="vertical"
                  form={form}
                  onFinish={onFinish}
                  scrollToFirstError
              // className={!mutable ? 'without-feedback' : ''}
                  initialValues={{
                    area_requierente: '0801 | Recursos Humanos',
                  }}
                >
                  <Row gutter={10}>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="fecha_elaboracion"
                        rules={getRules()}
                        label="Fecha Elaboración"
                        hasFeedback
                      >
                        <Input
                          allowClear
                          ref={inputDescripcion}
                          disabled={!mutable}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="no_requisicion_individual"
                        rules={getRules()}
                        label="No. Requisición"
                        hasFeedback
                      >
                        <Input
                          disabled={!mutable}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="tipo_operacion"
                        rules={getRules()}
                        label="Tipo Operación"
                        hasFeedback
                      >
                        <Input
                          disabled={!mutable}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="elaboro"
                        rules={getRules()}
                        label="Elaboró"
                        hasFeedback
                      >
                        <Input
                          disabled={!mutable}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="no_requisicion_consolidada"
                        rules={getRules()}
                        label="No. Requisición Consolidada"
                        hasFeedback
                      >
                        <Input
                          disabled={!mutable}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={10}>

                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="prioridad"
                        rules={getRules()}
                        label="Prioridad"
                        hasFeedback
                      >
                        <Input
                          disabled={!mutable}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="puesto"
                        rules={getRules()}
                        label="Puesto"
                        hasFeedback
                      >
                        <Input
                          disabled={!mutable}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="folio_agrupador"
                        rules={getRules()}
                        label="Folio Agrupador"
                        hasFeedback
                      >
                        <Input
                          disabled={!mutable}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="proceso_adquisitivo"
                        rules={getRules()}
                        label="Proceso Adquisitivo"
                        hasFeedback
                      >
                        <Input
                          disabled={!mutable}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="area"
                        rules={getRules()}
                        label="Área"
                        hasFeedback
                      >
                        <Input
                          disabled={!mutable}
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="area_requierente"
                        rules={getRules()}
                        label="Área Requierente"
                        hasFeedback
                      >
                        <Input
                          disabled={!mutable}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="proveedor_asignado"
                        rules={getRules()}
                        label="Proveedores) Asignado(s)"
                        hasFeedback
                      >
                        <Input
                          disabled={!mutable}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="no_cuadro_comparativo"
                        label="No. Cuadro Comparativo"
                        rules={getRules()}
                      >
                        <Select
                          disabled={!selectedRowKeys.length || !mutable}
                          dataSource={estadosGlobales}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="fecha_asignacion"
                        rules={getRules()}
                        label="Fecha Asignación"
                        hasFeedback
                      >
                        <Input
                          allowClear
                          ref={inputDescripcion}
                          disabled={!mutable}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24}>
                      <Form.Item
                        name="observaciones"
                        rules={getRules()}
                        label="observaciones"
                        hasFeedback
                      >
                        <Input.TextArea
                          autoSize={{ minRows: 4, maxRows: 4 }}
                          disabled={!mutable}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item hidden>
                    <Button htmlType="submit" />
                  </Form.Item>
                </Form>
                <Table
                  cols={columnsProductos}
                  data={selected?.data_table}
                  rowSelection={rowSelectionCompras}
                  handleOnRowClick={handleOnRowClickCompras}
                  controls={controls()}
                  mobileColIndex={0}
                  disabled={!mutable}
                />
              </TabPane>
              <TabPane tab="Criterios" key="criterios">
                <Form
                  layout="vertical"
                  form={form}
                  onFinish={onFinish}
                  scrollToFirstError
              // className={!mutable ? 'without-feedback' : ''}
                  initialValues={{
                  }}
                />
              </TabPane>
              <TabPane tab="Proveedores" key="proveedores">
                <Form
                  layout="vertical"
                  form={form}
                  onFinish={onFinish}
                  scrollToFirstError
              // className={!mutable ? 'without-feedback' : ''}
                  initialValues={{
                    area_requierente: '0801 | Recursos Humanos',
                  }}
                >
                  <Row gutter={10}>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="fecha_elaboracion"
                        rules={getRules()}
                        label="Fecha Elaboración"
                        hasFeedback
                      >
                        <Input
                          allowClear
                          ref={inputDescripcion}
                          disabled={!mutable}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="no_requisicion_individual"
                        rules={getRules()}
                        label="No. Requisición"
                        hasFeedback
                      >
                        <Input
                          disabled={!mutable}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="tipo_operacion"
                        rules={getRules()}
                        label="Tipo Operación"
                        hasFeedback
                      >
                        <Input
                          disabled={!mutable}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="elaboro"
                        rules={getRules()}
                        label="Elaboró"
                        hasFeedback
                      >
                        <Input
                          disabled={!mutable}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="no_requisicion_consolidada"
                        rules={getRules()}
                        label="No. Requisición Consolidada"
                        hasFeedback
                      >
                        <Input
                          disabled={!mutable}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={10}>

                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="prioridad"
                        rules={getRules()}
                        label="Prioridad"
                        hasFeedback
                      >
                        <Input
                          disabled={!mutable}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="puesto"
                        rules={getRules()}
                        label="Puesto"
                        hasFeedback
                      >
                        <Input
                          disabled={!mutable}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="folio_agrupador"
                        rules={getRules()}
                        label="Folio Agrupador"
                        hasFeedback
                      >
                        <Input
                          disabled={!mutable}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="proceso_adquisitivo"
                        rules={getRules()}
                        label="Proceso Adquisitivo"
                        hasFeedback
                      >
                        <Input
                          disabled={!mutable}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="area"
                        rules={getRules()}
                        label="Área"
                        hasFeedback
                      >
                        <Input
                          disabled={!mutable}
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="area_requierente"
                        rules={getRules()}
                        label="Área Requierente"
                        hasFeedback
                      >
                        <Input
                          disabled={!mutable}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="proveedor_asignado"
                        rules={getRules()}
                        label="Proveedores) Asignado(s)"
                        hasFeedback
                      >
                        <Input
                          disabled={!mutable}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="no_cuadro_comparativo"
                        label="No. Cuadro Comparativo"
                        rules={getRules()}
                      >
                        <Select
                          disabled={!selectedRowKeys.length || !mutable}
                          dataSource={estadosGlobales}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="fecha_asignacion"
                        rules={getRules()}
                        label="Fecha Asignación"
                        hasFeedback
                      >
                        <Input
                          allowClear
                          ref={inputDescripcion}
                          disabled={!mutable}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24}>
                      <Form.Item
                        name="observaciones"
                        rules={getRules()}
                        label="observaciones"
                        hasFeedback
                      >
                        <Input.TextArea
                          autoSize={{ minRows: 4, maxRows: 4 }}
                          disabled={!mutable}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item hidden>
                    <Button htmlType="submit" />
                  </Form.Item>
                </Form>
                <Table
                  cols={columnsProveedores}
                  data={selected?.data_table_proveedores}
                  rowSelection={rowSelectionCompras}
                  handleOnRowClick={handleOnRowClickCompras}
                  controls={controls()}
                  mobileColIndex={0}
                  disabled={!mutable}
                />
                <Table
                  cols={columnsProductos}
                  data={selected?.data_table}
                  rowSelection={rowSelectionCompras}
                  handleOnRowClick={handleOnRowClickCompras}
                  controls={controls()}
                  mobileColIndex={0}
                  disabled={!mutable}
                />
              </TabPane>
              <TabPane tab="Comparativa" key="comparativa">
                <Form
                  layout="vertical"
                  form={form}
                  onFinish={onFinish}
                  scrollToFirstError
              // className={!mutable ? 'without-feedback' : ''}
                  initialValues={{
                    area_requierente: '0801 | Recursos Humanos',
                  }}
                >
                  <Row gutter={10}>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="fecha_elaboracion"
                        rules={getRules()}
                        label="Fecha Elaboración"
                        hasFeedback
                      >
                        <Input
                          allowClear
                          ref={inputDescripcion}
                          disabled={!mutable}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="no_requisicion_individual"
                        rules={getRules()}
                        label="No. Requisición"
                        hasFeedback
                      >
                        <Input
                          disabled={!mutable}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="tipo_operacion"
                        rules={getRules()}
                        label="Tipo Operación"
                        hasFeedback
                      >
                        <Input
                          disabled={!mutable}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="elaboro"
                        rules={getRules()}
                        label="Elaboró"
                        hasFeedback
                      >
                        <Input
                          disabled={!mutable}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="no_requisicion_consolidada"
                        rules={getRules()}
                        label="No. Requisición Consolidada"
                        hasFeedback
                      >
                        <Input
                          disabled={!mutable}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={10}>

                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="prioridad"
                        rules={getRules()}
                        label="Prioridad"
                        hasFeedback
                      >
                        <Input
                          disabled={!mutable}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="puesto"
                        rules={getRules()}
                        label="Puesto"
                        hasFeedback
                      >
                        <Input
                          disabled={!mutable}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="folio_agrupador"
                        rules={getRules()}
                        label="Folio Agrupador"
                        hasFeedback
                      >
                        <Input
                          disabled={!mutable}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="proceso_adquisitivo"
                        rules={getRules()}
                        label="Proceso Adquisitivo"
                        hasFeedback
                      >
                        <Input
                          disabled={!mutable}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="area"
                        rules={getRules()}
                        label="Área"
                        hasFeedback
                      >
                        <Input
                          disabled={!mutable}
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="area_requierente"
                        rules={getRules()}
                        label="Área Requierente"
                        hasFeedback
                      >
                        <Input
                          disabled={!mutable}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="proveedor_asignado"
                        rules={getRules()}
                        label="Proveedores) Asignado(s)"
                        hasFeedback
                      >
                        <Input
                          disabled={!mutable}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="no_cuadro_comparativo"
                        label="No. Cuadro Comparativo"
                        rules={getRules()}
                      >
                        <Select
                          disabled={!selectedRowKeys.length || !mutable}
                          dataSource={estadosGlobales}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="fecha_asignacion"
                        rules={getRules()}
                        label="Fecha Asignación"
                        hasFeedback
                      >
                        <Input
                          allowClear
                          ref={inputDescripcion}
                          disabled={!mutable}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24}>
                      <Form.Item
                        name="observaciones"
                        rules={getRules()}
                        label="observaciones"
                        hasFeedback
                      >
                        <Input.TextArea
                          autoSize={{ minRows: 4, maxRows: 4 }}
                          disabled={!mutable}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item hidden>
                    <Button htmlType="submit" />
                  </Form.Item>
                </Form>
                <Table
                  cols={columnsComparativosFirst}
                  data={selected?.data_table_comparativos_first}
                  rowSelection={rowSelectionCompras}
                  handleOnRowClick={handleOnRowClickCompras}
                  controls={controls()}
                  mobileColIndex={0}
                  disabled={!mutable}
                />
                <Table
                  cols={columnsComparativosSecond}
                  data={selected?.data_table_comparativos_second}
                  rowSelection={rowSelectionCompras}
                  handleOnRowClick={handleOnRowClickCompras}
                  controls={controls()}
                  mobileColIndex={0}
                  disabled={!mutable}
                />
                <Table
                  cols={columnsComparativosThird}
                  data={selected?.data_table_comparativos_third}
                  rowSelection={rowSelectionCompras}
                  handleOnRowClick={handleOnRowClickCompras}
                  controls={controls()}
                  mobileColIndex={0}
                  disabled={!mutable}
                />
              </TabPane>
              <TabPane tab="Observaciones" key="observaciones">
                <Form
                  layout="vertical"
                  form={form}
                  onFinish={onFinish}
                  scrollToFirstError
              // className={!mutable ? 'without-feedback' : ''}
                  initialValues={{
                  }}
                />
              </TabPane>
            </Tabs>
          </Card>
        )}
        <ModalDelete
          // onDelete={deleteItem}
          onCancel={() => {
            setVisibleAlert(false);
            setSelectedRowKeys([]);
          }}
          visible={visibleAlert}
          content={`${form.getFieldValue('descripcion')}`}
          loading={loading}
        />
      </Spin>
    </Row>
  );
}

export default CuadroComparativo;
