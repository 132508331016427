import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Form, Row, Spin, Col, Input, Modal,
} from 'antd';

import API from 'utils/api';
import Table from 'components/Table';
import Select from 'components/Select';
import { onError, onInfo, onSuccess } from 'utils/handlers';
import FormSubmitControls from 'components/FormSubmitControls';
import Rutas from '../../../../utils/rutas-endpoints';

function ConceptosCargosBancarios() {
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visibleModalCreate, setVisibleModalCreate] = useState(false);
  const [
    dataGridConceptosCargosBancarios,
    setDataGridConceptosCargosBancarios,
  ] = useState([]);
  const [dataEstadosGlobales, setDataEstadosGlobales] = useState([]);
  const [dataClavesPresupuestales, setDataClavesPresupuestales] = useState([]);
  const [selected, setSelected] = useState({});
  const [isAdding, setIsAdding] = useState(false);
  let defaultValueEstado = 1;

  const user = useSelector(({ auth }) => auth.user);

  const requiredRule = {
    required: true,
    message: 'El campo es requerido',
  };
  const rules = {
    required: [requiredRule],
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      setDataClavesPresupuestales([]);
      setDataEstadosGlobales([]);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchData();
    return () => API.tryCancel;
  }, []);

  const consultarGridConceptosCargosBancarios = async () => {
    const res = await API.get(Rutas.conceptosBancarios);
    const conceptosCargosBancarios = res.data;
    const gridConceptosCargosBancarios = [];

    for (let i = 0; i < conceptosCargosBancarios.length; i += 1) {
      gridConceptosCargosBancarios[i] = {
        id: conceptosCargosBancarios[i].id,
        clave_presupuestal_descripcion:
          conceptosCargosBancarios[i].clave_presupuestal_descripcion,
        cog_datos: conceptosCargosBancarios[i].cog_datos,
        concepto: conceptosCargosBancarios[i].concepto,
        estado: conceptosCargosBancarios[i].estado,
      };
    }

    setDataGridConceptosCargosBancarios(gridConceptosCargosBancarios);
  };

  const consultarEstadosGlobales = async () => {
    const res = await API.get(Rutas.estadosGlobales);
    const estadosGlobales = res.data.filter((e) => e.descripcion === 'Activo' || e.descripcion === 'Inactivo');

    const defaultValue = estadosGlobales.filter((e) => e.descripcion === 'Activo');
    if (defaultValue) {
      defaultValueEstado = defaultValue[0].id;
    }

    setDataEstadosGlobales(estadosGlobales);
  };

  const consultarClavesPresupuestales = async () => {
    const res = await API.get(Rutas.clavePresupuestalCog);
    const claves = res.data;
    setDataClavesPresupuestales(claves);
  };

  useEffect(() => {
    consultarGridConceptosCargosBancarios();
  }, [dataGridConceptosCargosBancarios.length]);

  useEffect(() => {
    consultarEstadosGlobales();
  }, [dataEstadosGlobales.length]);

  useEffect(() => {
    consultarClavesPresupuestales();
  }, [dataClavesPresupuestales.length]);

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    setSelected(record);
  };

  const onCancel = () => {
    setVisibleModalCreate(false);
    form.resetFields();
    setSelectedRowKeys([]);
  };

  const onFinish = async () => {
    const values = form.getFieldsValue();
    values.usuario_que_elaboro = user.id;
    try {
      await form.validateFields();

      if (!selectedRowKeys.length) {
        const response = await API.post(Rutas.conceptosBancarios, values);

        if (response?.status === 201) {
          onSuccess(response, 'Agregado Correctamente.');
          onCancel();
          consultarGridConceptosCargosBancarios();
        }
      } else {
        const [key] = selectedRowKeys;
        const response = await API.put(
          `${Rutas.conceptosBancarios}${key}/`,
          values,
        );

        if (response.status === 200) {
          onSuccess(response, 'Actualizado Correctamente.');
          onCancel();
          consultarGridConceptosCargosBancarios();
        }
      }
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onClickAdd = () => {
    setIsAdding(true);
    setSelectedRowKeys([]);
    setVisibleModalCreate(true);
  };

  const onClickEdit = () => {
    setIsAdding(false);
    setVisibleModalCreate(true);
    setTimeout(() => {
      const clavePresupuestal = dataClavesPresupuestales
        .find((e) => e.clave_presupuestal_visible === selected.clave_presupuestal_descripcion);

      const estado = dataEstadosGlobales
        .find((e) => e.descripcion === selected.estado);

      const formValue = {
        concepto: selected.concepto,
        clave_presupuestal: clavePresupuestal
          ? clavePresupuestal.id
          : null,
        cog_datos: selected.cog_datos,
        estados_globales: estado ? estado.id : null,
      };
      form.setFieldsValue(formValue);
    });
  };

  const onClickDelete = async () => {
    try {
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.patch(`${Rutas.conceptosBancarios}${key}/eliminar/`);

        if (response?.status === 200) {
          onSuccess(response, 'Eliminado Correctamente.');
          onCancel();
          consultarGridConceptosCargosBancarios();
        }
        if (response?.status === 400) {
          onInfo(response.message);
        }
      }
    } catch (error) {
      onError(error, setLoading);
    }
  };

  const changeClavePresupuestal = async (e) => {
    const clavePresupuestal = dataClavesPresupuestales
      .find((y) => y.id === e);
    form.setFieldsValue({ cog_datos: clavePresupuestal.cog_datos });
  };

  const columns = [
    {
      titleText: 'Concepto',
      dataIndex: 'concepto',
      key: 'concepto',
      width: 300,
    },
    {
      titleText: 'Clave Presupuestal',
      dataIndex: 'clave_presupuestal_descripcion',
      key: 'clave_presupuestal_descripcion',
      width: 250,
    },
    {
      titleText: 'COG',
      dataIndex: 'cog_datos',
      key: 'cog_datos',
      width: 150,
    },
    {
      titleText: 'Estado',
      dataIndex: 'estado',
      key: 'estado',
      width: 200,
    },
  ];

  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        <Table
          cols={columns}
          data={dataGridConceptosCargosBancarios}
          rowSelection={rowSelection}
          handleOnRowClick={handleOnRowClick}
          mobileColIndex={0}
          controls={{
            onClickAdd,
            onClickEdit,
            onClickDelete,
          }}
        />
        <Modal
          visible={visibleModalCreate}
          closable={false}
          maskClosable={!loading}
          keyboard={!loading}
          footer={null}
          title={(
            <FormSubmitControls
              label={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Concepto de Cargos Bancarios`}
              onFinish={onFinish}
              onCancel={onCancel}
              loading={loading}
            />
          )}
          onCancel={onCancel}
        >
          <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
            scrollToFirstError
            name="conceptos_cargos_bancarios"
          >
            <Row gutter={10}>
              <Col span={24}>
                <Form.Item
                  name="concepto"
                  label="Concepto"
                  rules={[
                    requiredRule,
                    {
                      whitespace: true,
                    },
                  ]}
                  shouldUpdate
                >
                  <Input allowClear />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col span={24}>
                <Form.Item
                  name="clave_presupuestal"
                  label="Clave Presupuestal"
                  rules={rules.required}
                  shouldUpdate
                >
                  <Select
                    dataSource={dataClavesPresupuestales}
                    labelProp="clave_presupuestal_visible"
                    onChange={changeClavePresupuestal}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col span={24}>
                <Form.Item name="cog_datos" label="COG" shouldUpdate>
                  <Input allowClear disabled />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col span={24}>
                <Form.Item name="estados_globales" label="Estado" shouldUpdate>
                  <Select
                    dataSource={dataEstadosGlobales}
                    defaultValue={defaultValueEstado}
                    disabled={isAdding}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </Spin>
    </Row>
  );
}

export default ConceptosCargosBancarios;
