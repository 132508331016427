import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Form,
  Col,
  Button,
  TreeSelect,
  Typography,
  Spin,
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import API from 'utils/api';
import FormSubmitControls from 'components/FormSubmitControls';
import Select from 'components/Select';
import { onError, onSuccess } from 'utils/handlers';
import { UPDATE_ENTIDAD_INFO } from 'store/reducers/auth';

function ModalConfig({
  visible,
  setVisible,
  setSavedConfig,
  normalizeTreeData,
  filterTreeNode,
}) {
  const entidad = useSelector(({ auth }) => auth.entidad);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [clasificacionAdministrativa, setClasificacionesAdministrativa] = useState([]);
  const [conceptoUr, setConceptoUr] = useState([]);

  useEffect(() => {
    const fetchAll = async () => {
      try {
        setLoading(true);
        const resClasificacionAdministrativa = await API
          .get('catalogos/clasificaciones-administrativas/');
        const normalizedAdministrativas = normalizeTreeData(resClasificacionAdministrativa.data);
        setClasificacionesAdministrativa(normalizedAdministrativas);

        const resConceptosUr = await API.get('contabilidad/conceptos-unidad-responsable/');
        setConceptoUr(resConceptosUr.data || []);
        setLoading(false);
      } catch (err) {
        onError(err, setLoading);
      }
    };

    fetchAll();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      values.entidad = entidad.id;
      const res = await API.post(
        '/estructura-organizacional/entidades/configuracion-de-ur-administrador/',
        values,
      );
      if (res?.status === 200) {
        onSuccess(res, 'Configuración guardada correctamente');
        dispatch({
          type: UPDATE_ENTIDAD_INFO,
          payload: {
            ...entidad,
            configuracion_de_ur: res.data,
          },
        });
        onCancel();
        setSavedConfig(true);
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es requerido.',
      },
    ],
  };

  return (
    <Modal
      visible={visible}
      title={(
        <FormSubmitControls
          label="Configuración de UR&apos;s"
          onFinish={onFinish}
          onCancel={onCancel}
          loading={loading}
        />
      )}
      closable={false}
      onCancel={onCancel}
      footer={null}
      width={550}
      maskClosable={!loading}
      keyboard={!loading}
    >
      <Spin tip="Cargando..." spinning={loading}>
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          scrollToFirstError
        >
          <Col span={24}>
            <Form.Item
              name="clasificacion_administrativa"
              rules={rules.required}
              label="Clasificación Administrativa"
              hasFeedback
            >
              <TreeSelect
                showSearch
                treeNodeFilterProp="title"
                style={{ width: '100%' }}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                treeData={normalizeTreeData(clasificacionAdministrativa)}
                filterTreeNode={filterTreeNode}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="concepto_de_ur"
              rules={rules.required}
              label="Concepto de UR"
              hasFeedback
            >
              <Select dataSource={conceptoUr} />
            </Form.Item>
          </Col>
          <Form.Item hidden>
            <Button htmlType="submit" />
          </Form.Item>
        </Form>
        <Typography.Text>
          No cuenta con una configuración para Unidades Responsables.
          Por favor, registre su configuración en este formulario.
        </Typography.Text>
        <br />
      </Spin>
    </Modal>
  );
}

ModalConfig.propTypes = {
  visible: PropTypes.bool,
  setVisible: PropTypes.func.isRequired,
  setSavedConfig: PropTypes.func.isRequired,
  normalizeTreeData: PropTypes.func.isRequired,
  filterTreeNode: PropTypes.func.isRequired,
};

ModalConfig.defaultProps = {
  visible: false,
};

export default ModalConfig;
