import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Form,
  Input,
  Button,
  Spin,
  Col,
  Modal,
  message,
  Tooltip,
  DatePicker,
} from 'antd';
import { SearchOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import API from 'utils/api';
import fetchSome from 'utils/fetchSome';
import Select from 'components/Select';
import { onError } from 'utils/handlers';
import { SET_CONFIG_MASCARA_CONTABLE } from 'store/reducers/catalogs';
import { DATE_FRONTEND_FORMAT } from 'utils/formatValues';
import FormSubmitControls from 'components/FormSubmitControls';
import Table from 'components/Table';
import ModalDelete from 'components/ModalDelete';
import BusquedaClavesContables from 'components/BusquedaClavesContables';

const baseURI = 'configuraciones/claves-de-terceros/';

function ClavesContablesTerceros({
  currentURI,
  selectedRowKey,
  uriTerceros,
}) {
  const configMascaraContable = useSelector(({ catalogs }) => catalogs.configMascaraContable);
  const history = useHistory();
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);
  const [visibleBusqueda, setVisibleBusqueda] = useState(false);
  const [selectedClave, setSelectedClave] = useState();
  const [mascara, setMascara] = useState();
  const [pattern, setPattern] = useState();
  const [hasAllConfigurations, setHasAllConfigurations] = useState(false);
  const [separador, setSeparador] = useState();
  const [concepto, setConcepto] = useState([]);

  const fetchData = async () => {
    try {
      if (selectedRowKey) {
        const resCatalogo = await API.get(`${currentURI}/${selectedRowKey}/`);
        if (resCatalogo?.status === 200) {
          const { clave_de_tercero } = resCatalogo.data;
          if (clave_de_tercero?.length) {
            if (typeof clave_de_tercero[0] === 'number') {
              const promises = [];
              clave_de_tercero.forEach((id) => {
                const res = API.get(`${baseURI}${id}/`);
                promises.push(res);
              });
              const responses = promises.length ? await Promise.all(promises) : [];
              const datos = responses.map((res) => res.data);
              setData(datos);
            } else {
              setData(clave_de_tercero);
            }
          }
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const fetchAll = async () => {
    const resConcepto = await API.get(uriTerceros);
    setConcepto(resConcepto.data);
    if (configMascaraContable) {
      setMascara(configMascaraContable?.mascara);
      const separator = configMascaraContable?.separador;
      setSeparador(separator);
      const _segmentos = configMascaraContable?.mascara?.split(separator) || [];
      if (_segmentos?.length) {
        // eslint-disable-next-line prefer-template
        const patt = _segmentos.reduce((acum, curr, idx) => `${acum}[0-9]{${curr.length}}`
          + (idx !== _segmentos.length - 1 ? separator : '$'), '^');
        const regExp = new RegExp(`${patt}`);
        setPattern(regExp);
      }
    } else {
      await fetchSome('configuraciones/configuraciones-de-mascaras/', SET_CONFIG_MASCARA_CONTABLE);
    }

    await fetchData();
  };

  const showAlertConfigs = () => {
    message.info(
      (
        <>
          <span>
            No se han registrado/autorizado todas las Configuraciones de Segmentos Contables
          </span>
          <Button
            type="link"
            onClick={() => {
              history.push('/contabilidad/configuraciones/segmentos-contables');
              message.destroy();
            }}
          >
            Ir
          </Button>
        </>
      ), 3,
    );
  };

  const hasAllConfigs = async () => {
    const res = await API.get('configuraciones/configuraciones-de-segmentos-contables/');
    if (res?.status === 200) {
      const configuraciones = res.data.filter((r) => r.estados_globales === 4);
      if (configuraciones.length === 10) {
        setHasAllConfigurations(true);
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    const validateConfigs = async () => {
      setLoading(true);
      if (await hasAllConfigs()) {
        fetchAll();
      } else {
        showAlertConfigs();
        setLoading(false);
      }
    };
    validateConfigs();

    return () => API.tryCancel;
    // eslint-disable-next-line
  }, [selectedRowKey]);

  const onCancel = () => {
    form.resetFields();
    setVisible(false);
    setVisibleBusqueda(false);
    setSelectedRowKeys([]);
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      if (selectedRowKey) {
        if (!selectedRowKeys.length) {
          const res = await API.post(baseURI, values);
          if (res?.status === 201) {
            const keys = data.map((item) => item.id);
            const resPatch = await API.patch(`${currentURI}/${selectedRowKey}/`, {
              clave_de_tercero: [...keys, res.data.id],
            });
            if (resPatch?.status === 200) {
              onCancel();
              await fetchData();
            }
          }
        } else {
          const [key] = selectedRowKeys;
          const res = await API.put(`${baseURI}${key}/`, values);
          if (res?.status === 200) {
            onCancel();
            await fetchData();
          }
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const setClaveValues = (values = {}) => {
    form.setFieldsValue({
      cuenta_contable: values.id,
      cuenta_contable__clave: values.clave,
      cuenta_contable__nombre: values.nombre,
    });
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    form.setFieldsValue({ ...record, ...setClaveValues(record) });
  };

  const onClickAdd = () => {
    onCancel();
    if (hasAllConfigurations) {
      onCancel();
      form.setFieldsValue();
      setVisible(true);
    } else {
      showAlertConfigs();
    }
  };

  const onClickEdit = async () => {
    try {
      const [key] = selectedRowKeys;
      const match = data.find((e) => e.id === key);
      const { cuenta_contable } = match;
      if (cuenta_contable.id) {
        setClaveValues(cuenta_contable);
        setVisible(true);
      } else {
        const res = await API.get(`${baseURI}${cuenta_contable}/`);
        if (res?.status === 200) {
          setClaveValues(res.data);
          setVisible(true);
        }
      }
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      const [key] = selectedRowKeys;
      if (selectedRowKey) {
        const response = await API.delete(`${baseURI}${key}/`);
        if (response?.status === 204) {
          onCancel();
          setVisibleAlert(false);
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const required = {
    required: true,
    message: 'El campo es requerido',
  };

  const rules = {
    required: [
      required,
    ],
    cuenta_contable__clave: [
      required,
      {
        validator: async (_, value) => {
          if (value?.length === mascara?.length) {
            if (pattern && (!pattern.test(value) || !form.getFieldValue('cuenta_contable'))) {
              throw new Error('Introduzca una clave válida');
            }
          }
        },
      },
    ],
  };

  const columns = [
    {
      titleText: 'Cuenta Contable',
      dataIndex: 'cuenta_contable',
      key: 'cuenta_contable_clave',
      width: 250,
    },
    {
      titleText: 'Concepto',
      dataIndex: 'descripcion',
      key: 'descripcion',
      width: 250,
    },
    {
      titleText: 'Cuenta Contable Nombre',
      dataIndex: 'clave_contable_nombre',
      key: 'clave_contable_nombre',
      width: 250,
    },
    {
      titleText: 'Fecha Inactivacion',
      dataIndex: 'fecha_inactivacion',
      key: 'fecha_inactivacion',
      width: 250,
    },
  ];

  const onCancelBusqueda = () => {
    setVisibleBusqueda(false);
    setSelectedClave();
  };

  const onFinishBusqueda = () => {
    if (selectedClave) {
      setClaveValues(selectedClave);
      setVisibleBusqueda(false);
    } else {
      message.info('No ha seleccionado una Cuenta Contable');
    }
  };

  const fetch = async (clave = form.getFieldValue('cuenta_contable__clave')) => {
    try {
      setLoading(true);
      const res = await API.get('contabilidad/cuentas-contables/', {
        params: {
          clave,
          tipo_de_afectacion: 7,
        },
      });
      if (res?.status === 200 && res.data?.length) {
        const claveContable = res.data[0];
        setClaveValues(claveContable);
        form.setFieldsValue({
          cuenta_contable__clave: claveContable.clave,
          clave_contable__nombre: claveContable.nombre,
        });
        setTimeout(() => {
          form.validateFields(['cuenta_contable__clave']);
        });
      } else {
        setClaveValues();
      }
      setLoading(false);
    } catch (err) {
      setClaveValues();
    }
  };

  const onChangeClaveContable = (e) => {
    const { target: { value }, keyCode } = e;
    let val = value;
    if (keyCode) {
      const char = String.fromCharCode(keyCode).toLowerCase();
      val += char;
    }
    if (mascara.charAt(val.length) === separador) {
      val += separador;
    }
    if (val.length === mascara?.length) {
      fetch(val);
    } else if (!val?.length) {
      form.setFieldsValue({ cuenta_contable: undefined });
    }
    setTimeout(() => {
      form.setFieldsValue({ cuenta_contable__clave: val });
    });
  };

  const getLabel = () => {
    if (visibleBusqueda) {
      return 'Búsqueda Cuentas';
    }
    return `${selectedRowKeys.length ? 'Editar' : 'Agregar'} Cuenta Contable`;
  };

  // eslint-disable-next-line react/prop-types
  function PInput({ value }) {
    return (
      <p disabled className="ant-input ant-input-disabled auto-height">
        {value}
      </p>
    );
  }

  return (
    <Spin tip="Cargando..." spinning={loading}>
      <Table
        cols={columns}
        data={data}
        rowSelection={rowSelection}
        handleOnRowClick={handleOnRowClick}
        controls={{
          onClickAdd,
          onClickEdit,
          onClickDelete,
        }}
        allowSort={false}
        allowSearch={false}
      />
      <Modal
        visible={visible}
        closable={false}
        maskClosable={!loading}
        keyboard={!loading}
        footer={null}
        title={(
          <FormSubmitControls
            label={getLabel()}
            onFinish={visibleBusqueda ? onFinishBusqueda : onFinish}
            onCancel={visibleBusqueda ? onCancelBusqueda : onCancel}
            loading={loading}
          />
        )}
        onCancel={onCancel}
        width={visibleBusqueda ? '80%' : '50%'}
        forceRender
        centered
      >
        {visibleBusqueda ? (
          <BusquedaClavesContables callback={setSelectedClave} />
        ) : (
          <Form
            form={form}
            layout="vertical"
            name="clave_contable_tercero"
            onFinish={onFinish}
          >
            <Col span={24}>
              <Form.Item
                name="descripcion"
                rules={rules.required}
                label="Concepto"
                hasFeedback
              >
                <Select dataSource={concepto} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item noStyle>
                <Input.Group className="input-button" style={{ display: 'flex' }} compact>
                  <Form.Item
                    rules={rules.cuenta_contable__clave}
                    name="cuenta_contable_clave"
                    hasFeedback
                    label={(
                      <span>
                        <span style={{ paddingRight: 5 }}>
                          Cuenta Contable
                        </span>
                        {mascara && (
                          <Tooltip title={(
                            <span>
                              {`Debe concordar con la mascara ${mascara}`}
                            </span>
                          )}
                          >
                            <QuestionCircleOutlined />
                          </Tooltip>
                        )}
                      </span>
                    )}
                  >
                    <Input
                      onChange={onChangeClaveContable}
                      maxLength={mascara?.length}
                      allowClear
                    />
                  </Form.Item>
                  <Form.Item style={{ marginTop: 24 }}>
                    <Button onClick={() => setVisibleBusqueda(true)}>
                      <SearchOutlined />
                    </Button>
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="clave_contable_nombre"
                label="Nombre Cuenta Contable"
              >
                <PInput />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="fecha_inactivacion"
                label="Fecha De Inactivación"
              >
                <DatePicker format={DATE_FRONTEND_FORMAT} />
              </Form.Item>
            </Col>
            <Form.Item name="cuenta_contable" hidden>
              <PInput disabled />
            </Form.Item>
            <Form.Item hidden>
              <Button htmlType="submit" />
            </Form.Item>
          </Form>
        )}
      </Modal>
      <ModalDelete
        onDelete={deleteItem}
        onCancel={() => setVisibleAlert(false)}
        visible={visibleAlert}
        content={`Cuenta Contable ${form.getFieldValue('descripcion')}`}
        loading={loading}
      />
    </Spin>
  );
}

ClavesContablesTerceros.propTypes = {
  selectedRowKey: PropTypes.number,
  currentURI: PropTypes.string.isRequired,
  uriTerceros: PropTypes.string,
};

ClavesContablesTerceros.defaultProps = {
  selectedRowKey: undefined,
  uriTerceros: null,
};

export default ClavesContablesTerceros;
