import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Spin,
  Card,
  message,
  Tabs,
} from 'antd';
import {
  ArrowLeftOutlined,
  EditOutlined,
  UserOutlined,
  InfoCircleOutlined,
  EnvironmentOutlined,
  SettingOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import { UPDATE_ENTIDAD_INFO } from 'store/reducers/auth';
import { RFC_PATTERN } from 'utils/patterns';
import API from 'utils/api';
import getFormattedValues, { hasFiles } from 'utils/formatValues';
import FormSubmitCustomControls from 'components/FormSubmitCustomControls';
import FormSubmitControls from 'components/FormSubmitControls';
import Direccion from 'components/Direccion/index';
import AvatarUploader from 'components/AvatarUploader';
import LadaNumero from 'components/LadaNumero';
import Administrators from 'components/Entity/Administrators';
import Configurations from 'components/Entity/Configurations/index';
import ConfiguracionesUR from 'components/Entity/ConfiguracionesUR';
import { onError, onSuccess } from 'utils/handlers';
import Cuentas from 'components/Entity/Cuentas';

export const permissionEntidad = {
  permissionModel: 'entidad',
};

const { TabPane } = Tabs;
const currentURI = '/estructura-organizacional/entidades/';

// eslint-disable-next-line react/prop-types
function Entidad() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [currentTabKey, setCurrentTabKey] = useState('general');
  const [disabled, setDisabled] = useState(true);
  const [formAddress, setFormAddress] = useState();
  const [formConfigurations, setFormConfigurations] = useState();
  const [formConfigurationsUR, setFormConfigurationsUR] = useState();

  const basicKeys = ['general', 'administradores'];
  const complexForms = [
    { key: 'direccion', formInstance: formAddress },
    { key: 'configurations', formInstance: formConfigurations },
    { key: 'configuracionesUR', formInstance: formConfigurationsUR },
  ];

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await API.get(currentURI);
      if (res?.data) {
        form.setFieldsValue({
          ...res.data,
        });
      } else {
        message.warn('Este usuario no pertenece a una Entidad Municipal');
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onCancel = () => {
    setDisabled(true);
    fetchData();
  };

  const onFinish = async (_values, _continue = false) => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      const files = ['logotipo'];
      const config = hasFiles(values, files) ? {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      } : {};
      const formattedValues = getFormattedValues(values, {
        files,
        formData: config.headers,
        clean: false,
      });
      if (values.id) {
        const response = await API.patch(`${currentURI}`, formattedValues, config);
        if (response?.status === 200) {
          dispatch({
            type: UPDATE_ENTIDAD_INFO,
            payload: response.data,
          });
          onSuccess(response, 'Información actualizada correctamente', 2.5);
          if (!_continue) {
            onCancel();
          }
          await fetchData();
        }
      } else {
        const response = await API.post(`${currentURI}`, formattedValues, config);
        if (response?.status === 201) {
          dispatch({
            type: UPDATE_ENTIDAD_INFO,
            payload: response.data,
          });
          onSuccess(response, 'Agregado correctamente');
          await fetchData();
          if (!_continue) {
            onCancel();
          }
        }
      }
      setLoading(false);
      return true;
    } catch (err) {
      onError(err, setLoading, [form], setCurrentTabKey);
      return false;
    }
  };

  useEffect(() => {
    fetchData();

    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const rules = {
    rfc: [
      {
        required: true,
        message: 'El campo es requerido',
      },
      {
        pattern: RFC_PATTERN,
        message: 'Ingrese un RFC válido',
      },
    ],
    required: [
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
  };

  const showMsg = (tabKey) => {
    message.info({
      content: (
        <>
          <br />
          <Row style={{ width: '100%' }}>
            Los cambios que hayan sido realizados serán descartados.
          </Row>
          <Row align="middle" style={{ width: '100%' }}>
            ¿Desea continuar?
            <Button
              type="link"
              onClick={() => {
                setCurrentTabKey(tabKey);
                message.destroy();
                fetchData();
              }}
            >
              Si
            </Button>
            <Button
              type="link"
              onClick={() => message.destroy()}
            >
              No
            </Button>
          </Row>
        </>
      ),
      duration: 0,
    });
  };

  const onChangeTabKey = (key) => {
    const fromBasic = basicKeys.includes(currentTabKey);
    const toBasic = basicKeys.includes(key);
    const match = complexForms.find((e) => e.key === currentTabKey);
    if (!match) {
      if (fromBasic && toBasic) {
        setCurrentTabKey(key);
      } else if (fromBasic) {
        showMsg(key);
      } else {
        setCurrentTabKey(key);
      }
    } else {
      match.formInstance.onFinishHandler(key, true);
    }
  };

  const handleOnFinish = (vals, _continue = false) => {
    const match = complexForms.find((e) => e.key === currentTabKey);
    if (match) {
      match.formInstance.onFinishHandler(null, _continue);
    } else {
      onFinish(null, _continue);
    }
  };

  return (
    <Row align="center" justify="center" className="container">
      <Col span={24}>
        <Spin tip="Cargando..." spinning={loading}>
          <Card
            className="form-container"
            title="Entidad Municipal"
            extra={disabled ? (
              <FormSubmitCustomControls
                controls={[
                  {
                    onClick: () => history.push('/dashboard'),
                    icon: <ArrowLeftOutlined />,
                    text: 'Regresar',
                  },
                  {
                    onClick: () => setDisabled(false),
                    icon: <EditOutlined />,
                    text: 'Editar',
                  },
                ]}
              />
            ) : (
              <FormSubmitControls
                onFinish={basicKeys
                  .concat(complexForms.map((e) => e.key))
                  .includes(currentTabKey)
                  ? handleOnFinish : null}
                onCancel={onCancel}
                mutable={!disabled}
                allowSaveAndContinue
                onCancelText="Cancelar"
              />
            )}
            bordered={false}
          >
            <Tabs
              onChange={disabled ? setCurrentTabKey : onChangeTabKey}
              activeKey={currentTabKey}
              type="card"
            >
              <TabPane
                tab={(
                  <span>
                    <InfoCircleOutlined />
                    Información General
                  </span>
                )}
                key="general"
              >
                <Form
                  layout="vertical"
                  form={form}
                  onFinish={onFinish}
                  scrollToFirstError
                >
                  <Row gutter={10}>
                    <Col
                      xs={24}
                      sm={24}
                      md={8}
                      lg={6}
                    >
                      <AvatarUploader
                        disabled={disabled}
                        imageURL={form.getFieldValue('logotipo')}
                        formItemLabel="Logotipo oficial"
                        formItemName="logotipo"
                        form={form}
                      />
                    </Col>
                    <Col
                      xs={24}
                      sm={24}
                      md={16}
                      lg={18}
                    >
                      <Row gutter={10}>
                        <Col
                          xs={24}
                          sm={24}
                          md={12}
                          xl={8}
                          xxl={8}
                        >
                          <Form.Item
                            name="nombre"
                            rules={rules.required}
                            label="Nombre"
                          >
                            <Input allowClear disabled={disabled} />
                          </Form.Item>
                        </Col>
                        <Col
                          xs={24}
                          sm={24}
                          md={12}
                          xl={8}
                          xxl={8}
                        >
                          <Form.Item
                            name="RFC"
                            label="RFC"
                            rules={rules.rfc}
                          >
                            <Input
                              className="upper"
                              maxLength={12}
                              disabled={disabled}
                            />
                          </Form.Item>
                        </Col>
                        <LadaNumero
                          rules={rules}
                          form={form}
                          breakPoints={{
                            md: 24,
                            lg: 12,
                            xl: 8,
                            xxl: 8,
                          }}
                          disabled={disabled}
                          hasFeedback={!disabled}
                        />
                        <Col
                          xs={24}
                          sm={24}
                          md={24}
                          lg={12}
                          xl={8}
                          xxl={8}
                        >
                          <Form.Item
                            name="razon_social"
                            label="Razón social"
                            rules={rules.required}
                          >
                            <Input allowClear disabled={disabled} />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Form.Item hidden name="id">
                    <Input allowClear />
                  </Form.Item>
                  <Form.Item hidden>
                    <Button htmlType="submit" />
                  </Form.Item>
                </Form>
              </TabPane>
              <TabPane
                tab={(
                  <span>
                    <UserOutlined />
                    Administradores
                  </span>
                )}
                key="administradores"
                forceRender
              >
                <Administrators disabled={disabled} />
              </TabPane>
              <TabPane
                tab={(
                  <span>
                    <EnvironmentOutlined />
                    Dirección
                  </span>
                )}
                key="direccion"
                forceRender
              >
                <Col span={24}>
                  <Direccion
                    useParentID={false}
                    parentID
                    currentURI={currentURI}
                    setLoading={setLoading}
                    setFormAddress={setFormAddress}
                    setCurrentTabKey={setCurrentTabKey}
                    callback={(values, _continue) => {
                      if (!_continue) {
                        onCancel();
                      }
                    }}
                    mutable={!disabled}
                  />
                </Col>
              </TabPane>
              <TabPane
                tab={(
                  <span>
                    <SettingOutlined />
                    Configuraciones
                  </span>
                )}
                key="configurations"
                forceRender
              >
                <Configurations
                  onError={onError}
                  setLoading={setLoading}
                  callback={async (values, _continue) => {
                    if (!_continue) {
                      onCancel();
                      await fetchData();
                    }
                  }}
                  setCurrentTabKey={setCurrentTabKey}
                  setForm={setFormConfigurations}
                  disabled={disabled}
                />
              </TabPane>
              <TabPane
                tab={(
                  <span>
                    <SettingOutlined />
                    Configuración de URs
                  </span>
                )}
                key="configuracionesUR"
                forceRender
              >
                <ConfiguracionesUR
                  editing={disabled}
                  setFormConfigurations={setFormConfigurationsUR}
                  setCurrentTabKey={setCurrentTabKey}
                  callback={onCancel}
                />
              </TabPane>
              <TabPane
                tab={(
                  <span>
                    <UnorderedListOutlined />
                    Cuentas
                  </span>
                )}
                key="cuentas"
                forceRender
              >
                <Cuentas />
              </TabPane>
            </Tabs>
          </Card>
        </Spin>
      </Col>
    </Row>
  );
}

export default Entidad;
