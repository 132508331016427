import API from 'utils/api';
import { onError } from 'utils/handlers';

const baseURI = 'controlpatrimonial/';

/**
 * GET Naturalezas De Cuenta Contable
 * @returns {Promise<Array<object>>}
 */
// eslint-disable-next-line import/prefer-default-export
export const getOrigenes = async () => {
  try {
    const response = await API.get(`${baseURI}origenes`);
    return response.data;
  } catch (err) {
    onError(err);
    return [];
  }
};

/**
 * GET Uso
 * @returns {Promise<Array<object>>}
 */
// eslint-disable-next-line import/prefer-default-export
export const getUso = async () => {
  try {
    const response = await API.get(`${baseURI}uso`);
    return response.data;
  } catch (err) {
    onError(err);
    return [];
  }
};

/**
 * GET Motivos De Baja
 * @returns {Promise<Array<object>>}
 */
// eslint-disable-next-line import/prefer-default-export
export const getMotivosDeBaja = async () => {
  try {
    const response = await API.get(`${baseURI}motivos-de-baja`);
    return response.data;
  } catch (err) {
    onError(err);
    return [];
  }
};

/**
 * GET Documentos Para Baja
 * @returns {Promise<Array<object>>}
 */
// eslint-disable-next-line import/prefer-default-export
export const getDocumentosParaBaja = async () => {
  try {
    const response = await API.get(`${baseURI}documento-de-bajas`);
    return response.data;
  } catch (err) {
    onError(err);
    return [];
  }
};

/**
 * GET Estados De La Asignacion
 * @returns {Promise<Array<object>>}
 */
// eslint-disable-next-line import/prefer-default-export
export const getEstadoDeLaAsignacion = async () => {
  try {
    const response = await API.get(`${baseURI}estatus-de-asignaciones`);
    return response.data;
  } catch (err) {
    onError(err);
    return [];
  }
};

/**
 * GET Estados Del Bien
 * @returns {Promise<Array<object>>}
 */
// eslint-disable-next-line import/prefer-default-export
export const getEstadoDelBien = async () => {
  try {
    const response = await API.get(`${baseURI}estados-de-los-bienes`);
    return response.data;
  } catch (err) {
    onError(err);
    return [];
  }
};
