/* eslint-disable no-param-reassign */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Form,
  Input,
  Row,
  Col,
  Spin,
  // Card,
  Modal,
  Button,
  Typography,
  message,
} from 'antd';
import Table from 'components/Table';
import FormSubmitControls from 'components/FormSubmitControls';
import ModalDelete from 'components/ModalDelete';
import Select from 'components/Select';
import NumericInput from 'components/NumericInput';
import API from 'utils/api';
import createKey from 'utils/keyGenerator';
import { onError, onSuccess } from 'utils/handlers';
import ClasificadoresDeFuenteDeIngresos from 'components/Catalogos/ClasificadoresDeFuenteDeIngresos';
import { isMutable } from 'utils/estadosGlobales';

const baseURI = 'contabilidad/proyectos/';

function Proyectos({
  programa: programas,
  contentType,
  objectId,
  cogInterno,
  tiposDeGasto,
  clasificacionesProgramatica,
  fuentesDeFinanciamientoInterno,
}) {
  const { primaryText } = useSelector(({ app }) => app.theme);
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);
  const [mutable, setMutable] = useState(true);

  const fetchData = async () => {
    try {
      setLoading(true);
      if (programas) {
        const res = await API.get(baseURI, {
          params: {
            programas,
            programa: programas,
          },
        });
        setData(res.data);
      } else {
        setData([]);
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchData();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, [programas]);

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    setSelectedRowKeys([]);
  };

  const onFinish = async (values) => {
    try {
      setLoading(true);
      values.programas = programas;
      values.programa = programas;
      values.anio_de_aplicacion = 2021;
      if (!selectedRowKeys.length) {
        const response = await API.post(baseURI, values);
        if (response?.status === 201) {
          onSuccess(response, 'Agregado correctamente');
          onCancel();
          await fetchData();
          return response.data;
        }
      } else {
        const [key] = selectedRowKeys;
        const response = await API.put(`${baseURI}${key}/`, values);
        if (response?.status === 200) {
          onSuccess(response, 'Actualizado correctamente');
          onCancel();
          await fetchData();
          return response.data;
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
    return null;
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`${baseURI}${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          setVisibleAlert(false);
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    setTimeout(() => form.setFieldsValue(record));
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickAdd = () => {
    onCancel();
    createKey(data, form);
    setVisible(true);
  };

  const onClickEdit = (_match = null) => {
    const [key] = selectedRowKeys;
    const match = _match || data.find((i) => i.id === key);
    setMutable(isMutable(match));
    setVisible(true);
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const columns = [
    {
      titleText: 'Clave',
      dataIndex: 'clave',
      key: 'clave',
      width: 100,
    },
    {
      titleText: 'Nombre',
      dataIndex: 'nombre',
      key: 'nombre',
      width: 200,
    },
    {
      titleText: 'Estado',
      dataIndex: 'estados_globales',
      key: 'estados_globales',
      width: 150,
      render: (val) => estadosGlobales.find((item) => item.id === val)?.descripcion,
    },
  ];

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
  };

  function Formulario({ modal }) {
    let props;
    if (modal) {
      props = {
        span: 24,
      };
    } else {
      props = {
        xs: 24,
        sm: 24,
        md: 8,
      };
    }
    return (
      <>
        <Form
          name={`${modal ? 'modal_' : ''}proyecto`}
          layout="vertical"
          form={form}
          onFinish={() => onFinish(form.getFieldsValue())}
          scrollToFirstError
          initialValues={{ estados_globales: 1 }}

        >
          <Row gutter={10}>
            <Col {...props}>
              <NumericInput
                name="clave"
                label="Clave"
                required
                maxLength={10}
                disabled={!!selectedRowKeys.length}
              />
            </Col>
            <Col {...props}>
              <Form.Item
                name="nombre"
                label="Nombre"
                rules={rules.required}
                hasFeedback
              >
                <Input />
              </Form.Item>
            </Col>
            <Col {...props}>
              <Form.Item
                name="estados_globales"
                label="Estado"
                rules={rules.required}
              >
                <Select disabled={!selectedRowKeys.length} dataSource={estadosGlobales} />
              </Form.Item>
            </Col>
            <Form.Item hidden>
              <Button htmlType="submit" />
            </Form.Item>
          </Row>
        </Form>
        {!modal && (
        <>
          <Typography.Title level={4}>Clasificadores de fuentes de ingresos</Typography.Title>
          <ClasificadoresDeFuenteDeIngresos
            proyecto={selectedRowKeys[0]}
            contentType={contentType}
            objectId={objectId}
            cogInterno={cogInterno}
            mutableParent={mutable}
            tiposDeGasto={tiposDeGasto}
            clasificacionesProgramatica={clasificacionesProgramatica}
            fuentesDeFinanciamientoInterno={fuentesDeFinanciamientoInterno}
          />
        </>
        )}
      </>
    );
  }

  Formulario.propTypes = {
    modal: PropTypes.bool,
  };

  Formulario.defaultProps = {
    modal: false,
  };

  const validateAuthorizable = async () => {
    try {
      setLoading(true);
      const [proyecto] = selectedRowKeys;
      const response = await API.get('/estructura-organizacional/clasificadores-de-fuentes-de-ingreso-ur/', {
        params: {
          proyecto,
        },
      });
      if (!response?.data.length) {
        message.warn('No se puede autorizar Proyecto sin Clasificadores de fuente de ingresos');
        setLoading(false);
        return false;
      }
      setLoading(false);
      return true;
    } catch (err) {
      onError(err, setLoading);
      return false;
    }
  };

  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!(visible && selectedRowKeys.length) && (
          <Table
            allowImport
            baseURI={baseURI}
            cols={columns}
            data={data}
            rowSelection={rowSelection}
            handleOnRowClick={handleOnRowClick}
            controls={{
              onClickAdd,
              onClickEdit,
              onClickDelete,
            }}
          />
        )}
        {(visible && !!selectedRowKeys.length) && (
          <Row>
            <Col
              span={24}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <span style={{ color: primaryText, fontSize: 16, paddingInlineStart: 15 }}>
                {selectedRowKeys.length ? 'Editar' : 'Agregar'}
                {' '}
                Proyecto
              </span>
              <FormSubmitControls
                allowAuthorize
                useSuffix
                onFinish={() => onFinish(form.getFieldsValue())}
                onCancel={onCancel}
                loading={loading}
                mutable={mutable}
                baseURI={baseURI}
                validateAuthorizable={validateAuthorizable}
                selectedRowKeys={selectedRowKeys}
                callback={(estados_globales) => {
                  const [key] = selectedRowKeys;
                  const normalized = [...data]
                    .map((e) => (e.id === key ? { ...e, estados_globales } : e));
                  setMutable(false);
                  setData(normalized);
                }}
              />
            </Col>
            <Col span={24}>
              <Formulario />
            </Col>
          </Row>
        )}
      </Spin>
      {!selectedRowKeys.length && (
        <Modal
          visible={visible && !selectedRowKeys.length}
          title={(
            <FormSubmitControls
              label={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Proyecto`}
              onFinish={() => onFinish(form.getFieldsValue())}
              onCancel={onCancel}
              loading={loading}
            />
          )}
          closable={false}
          maskClosable={!loading}
          keyboard={!loading}
          onCancel={onCancel}
          footer={null}
          forceRender
        >
          <Formulario modal />
        </Modal>
      )}
      <ModalDelete
        onDelete={deleteItem}
        onCancel={() => setVisibleAlert(false)}
        visible={visibleAlert}
        content={`Proyecto ${form.getFieldValue('nombre')}`}
        loading={loading}
      />
    </Row>
  );
}

Proyectos.propTypes = {
  programa: PropTypes.number,
  contentType: PropTypes.number.isRequired,
  objectId: PropTypes.number.isRequired,
  cogInterno: PropTypes.arrayOf(PropTypes.object),
  fuentesDeFinanciamientoInterno: PropTypes.arrayOf(PropTypes.object),
  tiposDeGasto: PropTypes.arrayOf(PropTypes.object),
  clasificacionesProgramatica: PropTypes.arrayOf(PropTypes.object),
};

Proyectos.defaultProps = {
  programa: null,
  cogInterno: [],
  fuentesDeFinanciamientoInterno: [],
  tiposDeGasto: [],
  clasificacionesProgramatica: [],
};

export default Proyectos;
