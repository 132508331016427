/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Spin,
  Typography,
  Modal,
} from 'antd';
import FormSubmitControls from 'components/FormSubmitControls';
import API from 'utils/api';
import { onError, onSuccess } from 'utils/handlers';
import Select from 'components/Select';
import { useSelector } from 'react-redux';
import ModalDelete from 'components/ModalDelete';
import Table from 'components/Table';

function IndicadoresFID({
  selectedRowKey,
  medidaDelIndicador,
  frecuenciaDeMedicion,
  comportamiento,
  setIndicadorId,
  setIndicadoresData,
  // actividadId,

}) {
  const baseURI = '/presupuestos/indicador-de-programa-presupuestario-fid/';
  const [formGeneral] = Form.useForm();
  const [formResponsable] = Form.useForm();
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [visibleResponsable, setVisibleResponsable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [empleados, setEmpleados] = useState([]);
  const [puestos, setPuestos] = useState([]);
  const [contentTypes, setContentTypes] = useState([]);
  const formInstances = [
    formGeneral,
    formResponsable,
  ];

  const fetchData = async () => {
    try {
      setLoading(true);
      if (selectedRowKey[0] !== 0) {
        const res = await API.get(baseURI, {
          params: {
            programa_fid: selectedRowKey[0],
          },
        });
        if (res?.status === 200) {
          setData(res.data);
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const getURForTreeSelect = (_data) => {
    if (_data.content_type) {
      const { nivel } = contentTypes.find((ct) => ct.id === _data.content_type);
      const { object_id } = _data;
      setTimeout(() => {
        formResponsable.setFieldsValue({ area_responsable: `${object_id}/${nivel}` });
      });
    }
  };

  const onCancel = () => {
    setVisible(false);
    setVisibleResponsable(false);
    formGeneral.resetFields();
    formResponsable.resetFields();
    setSelectedRowKeys([]);
    setIndicadorId();
  };

  const onCancelResponsable = () => {
    setVisibleResponsable(false);
  };

  const onClickResponsable = () => {
    setVisibleResponsable(true);
  };

  useEffect(() => {
    let mounted = true;
    const fetchAll = async () => {
      try {
        setLoading(true);
        if (mounted) {
          setLoading(false);
          await fetchData();
        }

        const resEmpleados = await API.get('/estructura-organizacional/empleados/');
        setEmpleados(resEmpleados.data.map((e) => ({
          ...e,
          nombre_completo: `${e.numero_de_empleado || ' '} - ${e.nombre || ' '} ${e.apellido_paterno || ' '}`,
          nombre_y_apellido: `${e.nombre || ' '} ${e.apellido_paterno || ' '} ${e.apellido_materno || ' '}`,
        })));

        const resPuestos = await API.get('/estructura-organizacional/puestos/');
        setPuestos(resPuestos.data);

        const resContentTypes = await API.get('catalogos/content-types-ur');
        setContentTypes(resContentTypes.data);
      } catch (err) {
        onError(err, setLoading);
      }
    };
    fetchAll();
    return () => { mounted = false; };
    // eslint-disable-next-line
  }, [selectedRowKey[0]]);

  const onFinish = async () => {
    try {
      await formGeneral.validateFields();
      const values = {
        ...formGeneral.getFieldsValue(),
        programa_fid: selectedRowKey[0],
      };
      if (selectedRowKeys.length === 0) {
        const res = await API.post(baseURI, values);
        if (res?.status === 201) {
          onCancel();
          await fetchData();
          const resIndicadores = await API.get(baseURI, {
            params: {
              programa_fid: selectedRowKey[0],
            },
          });
          setIndicadoresData(resIndicadores.data);
        }
      } else {
        const [key] = selectedRowKeys;
        const res = await API.patch(`${baseURI}${key}/`, values);
        if (res?.status === 200) {
          onCancel();
          await fetchData();
        }
      }

      setLoading(false);
    } catch (err) {
      onError(err, formInstances, setLoading);
    }
  };

  const columns = [
    {
      titleText: 'Indicador',
      dataIndex: 'indicador',
      key: 'indicador',
      width: 350,
    },
    {
      titleText: 'Definición de Indicador',
      dataIndex: 'definicion_de_indicador',
      key: 'definicion_de_indicador',
      width: 350,
    },
    {
      titleText: 'Metodo de Cálculo',
      dataIndex: 'metodo',
      key: 'metodo',
      width: 350,
    },
    {
      titleText: 'Formula de Cálculo',
      dataIndex: 'formula',
      key: 'formula',
      width: 300,
    },
    {
      titleText: 'Frecuencia de Medición',
      dataIndex: 'frecuencia_de_medicion',
      key: 'frecuencia_de_medicion',
      width: 300,
      render: (id) => frecuenciaDeMedicion.find((e) => e.id === id)?.descripcion,
    },
    {
      titleText: 'Estado',
      dataIndex: 'estados_globales',
      key: 'estados_globales',
      width: 160,
      render: (id) => estadosGlobales.find((eg) => eg.id === id)?.descripcion,
    },
  ];

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    setIndicadorId(record.id);
    setTimeout(() => {
      formGeneral.setFieldsValue(record);
    });
  };

  const onClickAdd = async () => {
    onCancel();

    setVisible(true);
  };

  const onClickEdit = async () => {
    try {
      setLoading(true);
      const [key] = selectedRowKeys;
      const match = data.find((e) => e.id === key);
      // Si el match tiene respnsable del indicador, entonces rellenara los campos del modal
      if (match?.responsable) {
        const resEmpleado = empleados.find((e) => e.id === match?.responsable);
        getURForTreeSelect(resEmpleado);
        setTimeout(() => {
          formResponsable.setFieldsValue({
            ...resEmpleado,
          });
        });
      }
      setLoading(false);
    } catch (error) {
      onError(error, setLoading);
    }
    setVisible(true);
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const deleteItem = async () => {
    try {
      const [key] = selectedRowKeys;
      if (selectedRowKey) {
        if (selectedRowKeys.length) {
          const response = await API.delete(`${baseURI}${key}/`);
          if (response?.status === 204) {
            onSuccess(response, 'Eliminado correctamente');
            onCancel();
            await fetchData();
            setVisibleAlert(false);
          }
        }
      }
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const requiredRule = {
    message: 'Este campo es requerido',
  };

  const rules = {
    required: [requiredRule],
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  return (
    <Row align="center" justify="center" className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible ? (
          <Table
            cols={columns}
            data={data}
            rowSelection={rowSelection}
            handleOnRowClick={handleOnRowClick}
            controls={{
              onClickAdd,
              onClickEdit,
              onClickDelete,
            }}
            mobileColIndex={0}
          />
        ) : (
          <Card
            bordered={false}
            className="form-container"
            title={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Indicador`}
            extra={(
              <FormSubmitControls
                onFinish={onFinish}
                onCancel={onCancel}
                loading={loading}
              />
            )}
          >

            <Form
              layout="vertical"
              form={formGeneral}
              onFinish={onFinish}
              scrollToFirstError
              initialValues={{
                estados_globales: 1,
              }}
            >
              <Form.Item
                name="id"
                hidden
              >
                <Input />
              </Form.Item>
              <Row gutter={10}>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    rules={rules.required}
                    required
                    label="Indicador"
                    name="indicador"
                  >
                    <Input.TextArea
                      autoSize={{ minRows: 4, maxRows: 4 }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    rules={rules.required}
                    required
                    label="Definición del Indicador"
                    name="definicion_de_indicador"
                  >
                    <Input.TextArea
                      autoSize={{ minRows: 4, maxRows: 4 }}
                      allowClear
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Typography.Title level={4}>Verificación</Typography.Title>
              <Row gutter={10}>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    label="Fuente de Verificación"
                    name="fuente_de_verificacion"
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    label="Medio de Verificación"
                    name="medio_de_verificacion"
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={10}>

                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    required
                    label="Unidad de Medida"
                    name="medida_de_indicador"
                  >
                    <Select labelProp="nombre" dataSource={medidaDelIndicador} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    required
                    label="Sentido del Indicador"
                    name="sentido_de_indicador"
                  >
                    <Select dataSource={comportamiento} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    required
                    label="Frecuencia de Medición"
                    name="frecuencia_de_medicion"
                  >
                    <Select
                      labelProp="descripcion"
                      dataSource={frecuenciaDeMedicion}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    required
                    label="Responsable del Indicador"
                    name="responsable"
                  >
                    <Select
                      dataSource={empleados}
                      labelProp="nombre_completo"
                      onChange={(val) => {
                        const resEmpleado = empleados.find((e) => e.id === val);
                        getURForTreeSelect(resEmpleado);
                        setTimeout(() => {
                          formResponsable.setFieldsValue({
                            ...resEmpleado,
                          });
                        });
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    label=" "
                  >
                    <Button
                      onClick={onClickResponsable}
                    >
                      Info del Responsable
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
              <Typography.Title level={4}>Valores</Typography.Title>
              <Row gutter={10}>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    required
                    label="Variable de Cálculo a"
                    name="variable_a"
                  >
                    <Input
                      allowClear
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={6}>
                  <Form.Item
                    label="Unidad de Variable a"
                    name="unidad_a"
                    required
                  >
                    <Select
                      labelProp="nombre"
                      dataSource={medidaDelIndicador}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={6}>
                  <Form.Item
                    rules={rules.required}
                    label="Método de Cálculo"
                    name="metodo"
                    tooltip="Descripción de la Operación Matematica"
                    required
                  >
                    <Input
                      allowClear
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    rules={rules.required}
                    label="Variable de Calculo b"
                    name="variable_b"
                    required
                  >
                    <Input
                      allowClear
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={6}>
                  <Form.Item
                    label="Unidad de Variable b"
                    name="unidad_b"
                    required
                  >
                    <Select
                      labelProp="nombre"
                      dataSource={medidaDelIndicador}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={6}>
                  <Form.Item
                    rules={rules.required}
                    label="Formula de Cálculo"
                    name="formula"
                    tooltip="Operación Matematica"
                    required
                  >
                    <Input
                      allowClear
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    label="Estado"
                    name="estados_globales"
                  >
                    <Select
                      dataSource={estadosGlobales}
                      disabled={!selectedRowKeys.length}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item hidden>
                <Button htmlType="submit" />
              </Form.Item>
            </Form>
          </Card>
        )}
      </Spin>
      <Modal
        visible={visibleResponsable}
        title={(
          <FormSubmitControls
            label="Responsable del indicador"
            onCancel={onCancelResponsable}
          />
          )}
        closable={false}
        maskClosable={!loading}
        keyboard={!loading}
        onCancel={onCancelResponsable}
        footer={null}
        forceRender
      >
        <Form
          layout="vertical"
          form={formResponsable}
          scrollToFirstError
        >
          <Col span={24}>
            <Form.Item
              label="Nombre"
              name="nombre_y_apellido"
            >
              <Input
                disabled
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Puesto"
              name="puesto"
            >
              <Select
                dataSource={puestos}
                labelProp="descripcion"
                disabled
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Correo"
              name="correo_electronico"
            >
              <Input
                disabled
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Teléfono"
              name="numero_de_telefono"
            >
              <Input
                disabled
              />
            </Form.Item>
          </Col>
        </Form>
      </Modal>
      <ModalDelete
        onDelete={deleteItem}
        onCancel={() => {
          setVisibleAlert(false);
          setSelectedRowKeys([]);
        }}
        visible={visibleAlert}
        content="Eliminar la Matriz seleccionada"
      />
    </Row>
  );
}

IndicadoresFID.propTypes = {
  selectedRowKey: PropTypes.arrayOf(PropTypes.number),
  medidaDelIndicador: PropTypes.arrayOf(PropTypes.object).isRequired,
  frecuenciaDeMedicion: PropTypes.arrayOf(PropTypes.object).isRequired,
  comportamiento: PropTypes.arrayOf(PropTypes.object).isRequired,
  setIndicadorId: PropTypes.func.isRequired,
  setIndicadoresData: PropTypes.func.isRequired,
};

IndicadoresFID.defaultProps = {
  selectedRowKey: [],
};

export default IndicadoresFID;
