import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Card,
  Form,
  Row,
  Spin,
  Col,
  Input,
  InputNumber,
  DatePicker,
  Button,
} from 'antd';

import {
  CheckCircleOutlined,
  ArrowLeftOutlined,
  SaveOutlined,
  FilePdfOutlined,
  FileExcelOutlined,
} from '@ant-design/icons';
import API from 'utils/api';
import Select from 'components/Select';
import {
  onError,
  onSuccess,
  onInfo,
  onWarning,
} from 'utils/handlers';
import currencyFormatter from 'utils/currencyFormatter';
import Table from 'components/Table';
import moment from 'moment';
import Rutas from '../../utils/rutas-endpoints';

function CargosBancariosGrid() {
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [dataGridCargosBancarios, setDataGridCargosBancarios] = useState([]);
  const [dataCuentasBancarias, setDataCuentasBancarias] = useState([]);
  const [dataCargosBancarios, setDataCargosBancarios] = useState([]);
  const [cargoBancario, setCargoBancario] = useState({});
  const [dataUsuario, setDataUsuario] = useState({});
  const [waiting, setWaiting] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [onAdd, setOnAdd] = useState(false);
  const [onEdit, setOnEdit] = useState(false);

  const periodoFiscalSelected = useSelector(({ auth }) => {
    const value = form.getFieldValue('fecha');
    if (value
      && !value.isBetween(moment(auth.periodoFiscalSelected.fecha_inicial),
        moment(auth.periodoFiscalSelected.fecha_final))) {
      form.setFieldsValue({
        fecha: null,
      });
      form.validateFields();
    }
    return auth.periodoFiscalSelected;
  });

  const requiredRule = {
    required: true,
    message: 'El campo es requerido',
  };
  const rules = {
    required: [requiredRule],
  };

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    setSelectedRowKeys([]);
    setWaiting(false);
    setOnAdd(false);
    setOnEdit(false);
    setIsUpdated(false);
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      setDataCuentasBancarias([]);
      setDataCargosBancarios([]);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchData();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const consultarUsuario = async () => {
    const resUsuario = await API.get('usuarios/id/');
    const usuario = await resUsuario.data;
    setDataUsuario(usuario);

    form.setFieldsValue({
      usuario_que_elaboro: `${usuario.first_name} ${usuario.last_name} - ${usuario.id}`,
      puesto_elaboro: usuario.puesto_usuario,
      usuario_area: usuario.usuario_area,
    });
  };

  const calculaTotal = () => {
    const importe = form.getFieldValue('importe');
    const iva = form.getFieldValue('iva');
    let suma = 0.00;

    // eslint-disable-next-line
    if ((Number.isNaN(importe) || importe !== undefined)) {
      suma = importe + 0.00;
      form.setFieldsValue({
        total: suma,
      });
      if (Number.isNaN(iva) || iva !== undefined) {
        suma = importe + iva;
        form.setFieldsValue({
          total: suma,
        });
      }
    }
  };

  const getDataCargosBancarios = async () => {
    setLoading(true);
    const response = await API.get(Rutas.cargosBancarios);
    const cargosBancarios = response.data;
    const gridDataCargosBancarios = [];
    cargosBancarios.forEach((cargo, index) => {
      const name_completed = `${cargo.data_usuario.first_name} ${cargo.data_usuario.last_name}`;
      gridDataCargosBancarios[index] = {
        id: cargo.id,
        fecha: cargo.fecha,
        clabe_interbancaria: cargo.clabe_interbancaria,
        concepto_descripcion: cargo.concepto_descripcion,
        importe: cargo.importe,
        iva: cargo.iva,
        total: cargo.total,
        clave_presupuestal: cargo.clave_presupuestal,
        cog_datos: cargo.cog_datos,
        estado_descripcion: cargo.estado_descripcion,
        observaciones: cargo.observaciones,
        poliza_descripcion: cargo.data_poliza,
        moviemiento_presupuestal: cargo.moviemiento_presupuestal,
        concepto_de_cargo_bancario_desc: cargo.concepto_de_cargo_bancario_desc,
        puestoElaboro: cargo.data_usuario.puesto_usuario,
        areaElaboro: cargo.data_usuario.usuario_area,
        nombreElaboro: name_completed,
        numero_de_cargo_bancario: cargo.numero_de_cargo_bancario,
      };
    });
    setIsUpdated(false);
    setDataGridCargosBancarios(gridDataCargosBancarios);
    setLoading(false);
  };

  const consultarCuentasBancarias = async () => {
    const res = await API.get(Rutas.cuentasBancarias);
    const cuentas = await res.data;

    setDataCuentasBancarias(cuentas);
  };

  const consultarCargosBancarios = async () => {
    const res = await API.get(Rutas.conceptosBancarios);
    const cargos = await res.data;
    const cargosBancarios = cargos.filter((item) => item.estados_globales === 1);

    setDataCargosBancarios(cargosBancarios);
  };

  const consultarClavePresupuestal$Cog = async (idConcepto) => {
    const dataRest = await API.get(`${Rutas.conceptosBancarios}${idConcepto}/`);
    const clavePresupuestal = await dataRest.data;
    form.setFieldsValue({
      clave_presupuestal: clavePresupuestal.clave_presupuestal_descripcion,
      cog_datos: clavePresupuestal.cog_datos,
    });
  };

  const handleSelect = (e) => {
    consultarClavePresupuestal$Cog(e);
  };

  const crearDataCargoBancario = (data) => {
    const fechaFormated = moment(data.fecha);

    let cargoBancarioObj = {};
    cargoBancarioObj = {
      fecha: fechaFormated,
      numero_de_cargo_bancario: data.numero_de_cargo_bancario,
      cuenta_bancaria: data.cuenta_bancaria,
      clabe_interbancaria: data.clabe_interbancaria,
      concepto_de_cargo_bancario: data.concepto_de_cargo_bancario,
      concepto_de_cargo_bancario_desc: data.concepto_de_cargo_bancario_desc,
      importe: data.importe,
      iva: data.iva,
      total: data.total,
      clave_presupuestal: data.clave_presupuestal,
      cog_datos: data.cog_datos,
      usuario_que_elaboro: `${data.data_usuario.first_name} ${data.data_usuario.last_name} ${data.data_usuario.id}`,
      usuario_que_elaboro_id: data.data_usuario.id,
      puesto_elaboro: data.data_usuario.puesto_usuario,
      usuario_area: data.data_usuario.usuario_area,
      poliza: data.poliza,
      moviemiento_presupuestal: data.moviemiento_presupuestal,
      estados_globales: data.estado_descripcion,
      observaciones: data.observaciones,
    };

    return cargoBancarioObj;
  };

  const consultarDataCargosBancarios = async (idCargoBancario) => {
    setLoading(true);
    const dataRest = await API.get(`${Rutas.cargosBancarios}${idCargoBancario}/`);
    const cargoBancarioRest = await dataRest.data;
    const cargoBancarioAjustado = crearDataCargoBancario(cargoBancarioRest);

    setCargoBancario(cargoBancarioAjustado);
    form.setFieldsValue(cargoBancarioAjustado);
    setLoading(false);
  };

  useEffect(() => {
    getDataCargosBancarios();
  }, []);

  useEffect(() => {
    consultarCargosBancarios();
    // eslint-disable-next-line
  }, [dataCargosBancarios.length]);

  useEffect(() => {
    consultarCuentasBancarias();
    // eslint-disable-next-line
  }, [dataCuentasBancarias.length]);

  useEffect(() => {
    const [key] = selectedRowKeys;
    if (waiting) {
      consultarDataCargosBancarios(key);
    }
    // eslint-disable-next-line
  }, [waiting]);

  useEffect(() => {
    fetchData();
    return () => API.tryCancel;
  }, []);

  useEffect(() => {
    if (isUpdated) {
      getDataCargosBancarios();
    }
  }, [isUpdated]);

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    // setTimeout(() => form.setFieldsValue(record));
    if (record.estado_descripcion === 'Activo') {
      setOnEdit(true);
    }
  };

  const onActivar = () => {
    setOnAdd(true);
  };

  const disabledDate = (current) => {
    // eslint-disable-next-line
    const val = moment(current).isBetween(periodoFiscalSelected.fecha_inicial, periodoFiscalSelected.fecha_final);
    return !val;
  };

  function getConceptoDescripcion(idConcepto) {
    const conceptoEncontrado = dataCargosBancarios.find((item) => item.id === idConcepto);
    const conceptoDesc = conceptoEncontrado.concepto;

    return conceptoDesc;
  }

  function getClabeInterbancaria(cuentaBancaria) {
    const cuenta = dataCuentasBancarias.find((item) => item.id === cuentaBancaria);
    const clabeInterbanciaria = cuenta.clabe_interbancaria;

    return clabeInterbanciaria;
  }

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickAdd = () => {
    const fechaSis = moment();
    form.setFieldsValue({
      fecha: fechaSis,
    });
    setSelectedRowKeys([]);
    consultarUsuario();
    setOnAdd(true);
    setVisible(true);
  };

  const onClickEdit = () => {
    setWaiting(true);

    setVisible(true);
  };

  const onClickDelete = async () => {
    try {
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const dataToDelete = dataGridCargosBancarios.find((item) => item.id === key);

        if (dataToDelete.estado_descripcion === 'Autorizado') {
          onInfo('Solo se puede eliminar registros con estado activo.');
        } else {
          const response = await API.patch(`${Rutas.cargosBancarios}${key}/eliminar/`);
          if (response?.status === 200) {
            onSuccess(response, 'Eliminado correctamente.');
            onCancel();
            getDataCargosBancarios();
          }
        }
      }
    } catch (error) {
      onError(error, setLoading);
    }
  };

  const onClickSaveForm = async () => {
    try {
      setLoading(true);
      await form.validateFields();

      const values = form.getFieldsValue();
      const { fecha } = values;

      if (fecha !== null || fecha !== '') {
        const date = moment(fecha).format('YYYY-MM-DD');
        values.fecha = date;
      }

      values.usuario_que_elaboro = dataUsuario.id;
      values.estados_globales = 1;

      if (selectedRowKeys.length === 0) {
        // eslint-disable-next-line
        if (!moment(fecha).isBetween(periodoFiscalSelected.fecha_inicial, periodoFiscalSelected.fecha_final)) {
          onWarning('La fecha seleccionada no se encuentra dentro del periodo fiscal.');
          setTimeout(() => {
            onInfo('Por favor, cambie la fecha, a una dentro del periodo fiscal en curso.');
          }, 3500);
        } else {
          values.concepto_descripcion = getConceptoDescripcion(values.concepto_de_cargo_bancario);
          values.clabe_interbancaria = getClabeInterbancaria(values.cuenta_bancaria);
          const response = await API.post(Rutas.cargosBancarios, values);

          if (response?.status === 201) {
            onSuccess(response, 'Agregado correctamente.');
            onCancel();
            getDataCargosBancarios();
          }

          setVisible(false);
        }
      } else {
        const [key] = selectedRowKeys;
        values.concepto_de_cargo_bancario = cargoBancario.concepto_de_cargo_bancario;
        values.concepto_de_cargo_bancario_desc = cargoBancario.concepto_de_cargo_bancario_desc;
        values.clabe_interbancaria = cargoBancario.clabe_interbancaria;
        values.cuenta_bancaria = cargoBancario.cuenta_bancaria;
        values.usuario_que_elaboro = cargoBancario.usuario_que_elaboro_id;

        const response = await API.patch(`${Rutas.cargosBancarios}${key}/`, values);

        if (response?.status === 200) {
          onSuccess(response, 'Actualizado correctamente.');
          form.resetFields();
          onCancel();
          getDataCargosBancarios();
        }
      }

      setOnEdit(false);
      setLoading(false);
      setOnAdd(false);
    } catch (error) {
      onError(error, setLoading);
    }
  };

  const onAutorizar = () => {
    const [key] = selectedRowKeys;
    const array = [];
    const obj = {
      id: key,
    };

    const rowSelected = dataGridCargosBancarios.find((item) => item.id === key);

    if (rowSelected.estado_descripcion === 'Autorizado') {
      onInfo('Acción no realizada, solo se pueden autorizar registros con estado activo.');
    } else {
      obj.id = rowSelected.id;
      array.push(obj);
      const resApi = API.patch(`${Rutas.cargosBancarios}autorizar/`, array);
      onSuccess(resApi, 'Autorizado correctamente.');
      onCancel();
      setIsUpdated(true);
      getDataCargosBancarios();
    }
  };

  const onDescargarPDF = () => { };

  const onDescargarExcel = () => { };

  const columns = [
    {
      titleText: 'Fecha',
      dataIndex: 'fecha',
      key: 'fecha',
      width: 200,
    },
    {
      titleText: 'No. de Cargo Bancario',
      dataIndex: 'numero_de_cargo_bancario',
      key: 'numero_de_cargo_bancario',
      width: 250,
    },
    {
      titleText: 'Cuenta Bancaria',
      dataIndex: 'clabe_interbancaria',
      key: 'clabe_interbancaria',
      width: 300,
    },
    {
      titleText: 'Concepto',
      dataIndex: 'concepto_descripcion',
      key: 'concepto_descripcion',
      width: 250,
    },
    {
      titleText: 'Importe',
      dataIndex: 'importe',
      key: 'importe',
      width: 150,
      render: (val) => (val > 0 ? currencyFormatter(val || 0) : '-'),
    },
    {
      titleText: 'IVA',
      dataIndex: 'iva',
      key: 'iva',
      width: 150,
      render: (val) => (val > 0 ? currencyFormatter(val || 0) : '-'),
    },
    {
      titleText: 'Total',
      dataIndex: 'total',
      key: 'total',
      width: 150,
      render: (val) => (val > 0 ? currencyFormatter(val || 0) : '-'),
    },
    {
      titleText: 'Clave Presupuestal',
      dataIndex: 'clave_presupuestal',
      key: 'clave_presupuestal',
      width: 250,
    },
    {
      titleText: 'COG',
      dataIndex: 'cog_datos',
      key: 'cog_datos',
      width: 150,
    },
    {
      titleText: 'Elaboró',
      dataIndex: 'nombreElaboro',
      key: 'nombreElaboro',
      width: 250,
    },
    {
      titleText: 'Puesto',
      dataIndex: 'puestoElaboro',
      key: 'puestoElaboro',
      width: 250,
    },
    {
      titleText: 'Área',
      dataIndex: 'areaElaboro',
      key: 'areaElaboro',
      width: 220,
    },
    {
      titleText: 'Póliza',
      dataIndex: 'poliza_descripcion',
      key: 'poliza_descripcion',
      width: 700,
    },
    {
      titleText: 'Movimiento Presupuestal',
      dataIndex: 'moviemiento_presupuestal',
      key: 'moviemiento_presupuestal',
      width: 250,
    },
    {
      titleText: 'Estado',
      dataIndex: 'estado_descripcion',
      key: 'estado_descripcion',
      width: 200,
    },
    {
      titleText: 'Observaciones',
      dataIndex: 'observaciones',
      key: 'observaciones',
      width: 250,
    },
  ];

  const onFinish = () => {
  };

  const initialValues = {
    estados_globales: 'Activo',
    iva: 0,
    importe: 0,
    total: 0,
  };

  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible ? (
          <Table
            cols={columns}
            data={dataGridCargosBancarios}
            rowSelection={rowSelection}
            handleOnRowClick={handleOnRowClick}
            mobileColIndex={0}
            controls={{
              onClickAdd,
              onClickEdit,
              onClickDelete,
            }}
            customActions={[
              {
                onClick: onAutorizar,
                text: 'Autorizar',
                icon: <CheckCircleOutlined />,
                disabledBySelection: true,
              },
              {
                onClick: onDescargarPDF,
                icon: <FilePdfOutlined />,
                text: 'Descargar PDF',
                disabledBySelection: false,
              },
              {
                onClick: onDescargarExcel,
                icon: <FileExcelOutlined />,
                text: 'Descargar Excel',
                disabledBySelection: false,
              },
            ]}
          />

        ) : (
          <Card
            className="form-container"
            title={`${selectedRowKeys.length > 0 ? 'Editar' : 'Agregar'} Cargo Bancario`}
            extra={(
              <>
                {(onAdd || onEdit) && (
                  <Button
                    icon={<SaveOutlined />}
                    className="ant-btn ant-btn-link"
                    onClick={onClickSaveForm}
                  >
                    Guardar
                  </Button>
                )}

                {(waiting && onEdit) && (
                  <Button
                    icon={<CheckCircleOutlined />}
                    className="ant-btn ant-btn-link"
                    onClick={onAutorizar}
                  >
                    Autorizar
                  </Button>
                )}

                <Button
                  icon={<ArrowLeftOutlined />}
                  className="ant-btn ant-btn-link"
                  onClick={onCancel}
                >
                  Regresar
                </Button>
              </>
            )}
            bordered={false}
          >
            <Row gutter={10}>
              <Col md={24} sm={24} xs={24}>
                <Form
                  layout="vertical"
                  form={form}
                  onFinish={onFinish}
                  scrollToFirstError
                  name="cargo_bancario"
                  initialValues={initialValues}
                >
                  <Row gutter={10}>
                    <Col xs={12} sm={24} md={8}>
                      <Form.Item name="fecha" label="Fecha" rules={rules.required} hasFeedback>
                        <DatePicker placeholder="" format="DD/MM/YYYY" disabledDate={disabledDate} onChange={onActivar} />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={24} md={8}>
                      <Form.Item
                        name="numero_de_cargo_bancario"
                        label="No. de Cargo Bancario"
                      >
                        <Input
                          disabled
                          allowClear
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={24} md={8}>
                      <Form.Item
                        name="cuenta_bancaria"
                        label="Cuenta Bancaria"
                        rules={rules.required}
                      >
                        <Select
                          dataSource={dataCuentasBancarias}
                          render={(e) => `${e?.banco_nombre} - ${e?.numero_de_cuenta}`}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={24} md={8}>
                      <Form.Item
                        name="concepto_de_cargo_bancario"
                        label="Concepto"
                        rules={rules.required}
                      >
                        <Select
                          dataSource={dataCargosBancarios}
                          onChange={handleSelect}
                          render={(e) => `${e?.concepto}`}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={24} md={8}>
                      <Form.Item
                        name="importe"
                        label="Importe"
                        rules={rules.required}
                      >
                        <InputNumber
                          formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                          onBlur={calculaTotal}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={24} md={8}>
                      <Form.Item
                        name="iva"
                        label="IVA"
                        rules={rules.required}
                      >
                        <InputNumber
                          formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                          onBlur={calculaTotal}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={24} md={8}>
                      <Form.Item
                        name="total"
                        label="Total"
                        rules={rules.required}
                      >
                        <InputNumber
                          formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                          disabled
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={24} md={8}>
                      <Form.Item
                        name="clave_presupuestal"
                        label="Clave Presupuestal"
                      >
                        <Input
                          disabled
                          allowClear
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={24} md={8}>
                      <Form.Item name="cog_datos" label="COG">
                        <Input
                          disabled
                          allowClear
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={24} md={8}>
                      <Form.Item name="usuario_que_elaboro" label="Elaboró">
                        <Input disabled allowClear />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={24} md={8}>
                      <Form.Item name="puesto_elaboro" label="Puesto">
                        <Input disabled allowClear />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={24} md={8}>
                      <Form.Item name="usuario_area" label="Área">
                        <Input disabled allowClear />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={24} md={8}>
                      <Form.Item
                        name="poliza"
                        label="Póliza"
                      >
                        <Input
                          disabled
                          allowClear
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={24} md={8}>
                      <Form.Item
                        name="movimiento_presupuestal"
                        label="Movimiento Presupuestal"
                      >
                        <Input
                          disabled
                          allowClear
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={24} md={8}>
                      <Form.Item name="estados_globales" label="Estado">
                        <Input disabled allowClear />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={24} md={8}>
                      <Form.Item name="observaciones" label="Observaciones">
                        <Input allowClear />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Card>
        )}
      </Spin>
    </Row>
  );
}

export default CargosBancariosGrid;
