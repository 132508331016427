import React, { useState, useEffect } from 'react';
import {
  Form,
  Row,
  Spin,
  // Modal,
} from 'antd';

import API from 'utils/api';
import { onError } from 'utils/handlers';
import Table from 'components/Table';
import ModalDelete from 'components/ModalDelete';
import _data from 'mocks/EstudioDeMercado';

export const permissionEstudioDeMercado = {
  permissionModel: 'estudiodemercado',
};

// eslint-disable-next-line react/prop-types
function EstudioDeMercado({ permission }) {
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      setLoading(true);
      setData(_data);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchData();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    setTimeout(() => form.setFieldsValue(record));
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickAdd = () => { };

  const onClickEdit = () => { };

  const onClickDelete = () => { };

  const columns = [
    {
      titleText: 'Número de Estudio',
      dataIndex: 'no_de_estudio',
      key: 'no_de_estudio',
      width: 100,
    },
    {
      titleText: 'Fecha',
      dataIndex: 'fecha',
      key: 'fecha',
      width: 350,
    },
    {
      titleText: 'Elaboró',
      dataIndex: 'elaboro',
      key: 'elaboro',
      width: 220,
    },
    {
      titleText: 'Solicitante',
      dataIndex: 'solicitante',
      key: 'solicitante',
      width: 220,
    },
    {
      titleText: 'Puesto Solicitante',
      dataIndex: 'puesto_solicitante',
      key: 'puesto_solicitante',
      width: 250,
    },
    {
      titleText: 'Unidad Organizacional',
      dataIndex: 'unidad_organizacional',
      key: 'unidad_organizacional',
      width: 200,
    },
    {
      titleText: 'Tipo de Producto de Servicio',
      dataIndex: 'tipo_de_producto_servicio',
      key: 'tipo_de_producto_servicio',
      width: 200,
    },
    {
      titleText: 'Numero de Requisición',
      dataIndex: 'numero_requisicion',
      key: 'numero_requisicion',
      width: 200,
    },
    {
      titleText: 'Folio Agrupador',
      key: 'folio_agrupador',
      dataIndex: 'folio_agrupador',
      width: 150,
    },
  ];

  const controls = () => ({
    onClickAdd,
    onClickEdit,
    onClickDelete,
  });

  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        <Table
          cols={columns}
          data={data}
          rowSelection={rowSelection}
          handleOnRowClick={handleOnRowClick}
          controls={controls()}
          mobileColIndex={0}
          permission={permission}
        />
        <ModalDelete
          onCancel={() => {
            setVisibleAlert(false);
            setSelectedRowKeys([]);
          }}
          visible={visibleAlert}
          content={`${form.getFieldValue('descripcion')}`}
          loading={loading}
        />
      </Spin>
    </Row>
  );
}

export default EstudioDeMercado;
