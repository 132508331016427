import API from 'utils/api';
import { onError } from 'utils/handlers';

const baseURI = 'almacen/';

/**
 * GET CABMS
 * @returns {Promise<Array<object>>}
 */
// eslint-disable-next-line import/prefer-default-export
export const getCABMS = async () => {
  try {
    const response = await API.get(`${baseURI}cabm`);
    return response.data;
  } catch (err) {
    onError(err);
    return [];
  }
};

/**
 * GET CUCOPS
 * @returns {Promise<Array<object>>}
 */
// eslint-disable-next-line import/prefer-default-export
export const getCUCOPS = async () => {
  try {
    const response = await API.get(`${baseURI}cucop`);
    return response.data;
  } catch (err) {
    onError(err);
    return [];
  }
};
