/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { HomeOutlined } from '@ant-design/icons';
import Resguardos, { permissionResguardo } from 'views/ControlPatrimonial/Resguargos';
import AreaDeUbicacionGeneral, { permissionAreaDeUbicacionGeneral } from 'views/Configuracion/Catalogos/ControlPatrimonial/AreaDeUbicacionGeneral';
import AreaDeUbicacionEspecifica, { permissionAreaDeUbicacionEspecifica } from 'views/Configuracion/Catalogos/ControlPatrimonial/AreaDeUbicacionEspecifica';
import AreaDeUbicacionParticular, { permissionAreaDeUbicacionParticular } from 'views/Configuracion/Catalogos/ControlPatrimonial/AreaDeUbicacionParticular';
import DepreciacionAmortizacionYDeterioros from 'views/ControlPatrimonial/DepreciacionAmortizacionYDeterioro/index';
import InventarioDeBienes from 'views/ControlPatrimonial/InventarioDeBienes';
import InventarioDeBienInmueble, { permissionClaseBienInmueble } from 'views/ControlPatrimonial/inventarioDeBienInmueble';
import ActivosFijos, { permissionActivoFijo } from 'views/ControlPatrimonial/ActivosFijos';
import ClasificadorDeActivos, { permissionCatalogoClasificadorDeActivo } from 'views/ControlPatrimonial/ClasificadorDeActivos';
import FamiliaActivoFijo from 'views/Configuracion/Catalogos/CatalogoActivoFijo/index';
import AreaDeUbicacionFisica from 'views/Configuracion/Catalogos/ControlPatrimonial/AreaDeUbicacionFisica';

export default {
  title: 'Control Patrimonial',
  icon: () => <HomeOutlined />,
  showInSidebar: true,
  type: 'private',
  children: [
    {
      title: 'Administración de Bienes',
      icon: null,
      showInSidebar: true,
      type: 'private',
      children: [
        {
          title: 'Recepciones',
          icon: null,
          showInSidebar: true,
          component: null,
          type: 'private',
        },
        {
          title: 'Alta de Bienes',
          icon: null,
          showInSidebar: true,
          component: ActivosFijos,
          type: 'private',
          ...permissionActivoFijo,
        },
        {
          title: 'Baja de Bienes',
          icon: null,
          showInSidebar: true,
          component: null,
          type: 'private',
        },
      ],
    },
    {
      title: 'Resguardos',
      icon: null,
      showInSidebar: true,
      type: 'private',
      children: [
        {
          title: 'Alta de Resguardos',
          icon: null,
          showInSidebar: true,
          component: Resguardos,
          type: 'private',
          ...permissionResguardo,
        },
        {
          title: 'Baja de Resguardos',
          icon: null,
          showInSidebar: true,
          component: null,
          type: 'private',
        },
        {
          title: 'Resguardos de Traslado',
          icon: null,
          showInSidebar: true,
          component: null,
          type: 'private',
        },
      ],
    },
    {
      title: 'Depreciación, Amortizacion y Deterioro',
      icon: null,
      showInSidebar: true,
      component: DepreciacionAmortizacionYDeterioros,
      type: 'private',
    },
    {
      title: 'Inventario de Bienes Muebles',
      icon: null,
      showInSidebar: true,
      component: InventarioDeBienes,
      type: 'private',
    },
    {
      title: 'Inventario de Bienes Inmuebles',
      icon: null,
      showInSidebar: true,
      component: InventarioDeBienInmueble,
      type: 'private',
      ...permissionClaseBienInmueble,
    },
    {
      title: 'Catálogos',
      icon: null,
      showInSidebar: true,
      type: 'private',
      children: [

        {
          title: 'Área De Ubicación Fisica',
          icon: null,
          showInSidebar: true,
          component: AreaDeUbicacionFisica,
          type: 'private',
        },
        {
          title: 'Área De Ubicación General',
          icon: null,
          showInSidebar: true,
          component: AreaDeUbicacionGeneral,
          type: 'private',
          ...permissionAreaDeUbicacionGeneral,
        },
        {
          title: 'Área De Ubicación Específica',
          icon: null,
          showInSidebar: true,
          component: AreaDeUbicacionEspecifica,
          type: 'private',
          ...permissionAreaDeUbicacionEspecifica,
        },
        {
          title: 'Área De Ubicación Particular',
          icon: null,
          showInSidebar: true,
          component: AreaDeUbicacionParticular,
          type: 'private',
          ...permissionAreaDeUbicacionParticular,
        },
        {
          title: 'Catálogo Operativo Activo Fijo',
          icon: null,
          showInSidebar: true,
          type: 'private',
          component: FamiliaActivoFijo,
        },
        //! Este no se encuentra dentro del mockup pero tiene funcionalidad
        {
          title: 'Clasificador de Activos',
          icon: null,
          component: ClasificadorDeActivos,
          type: 'private',
          showInSidebar: true,
          ...permissionCatalogoClasificadorDeActivo,
        },
      ],
    },
  ],
};
