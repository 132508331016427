import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Button,
  Row,
  Col,
  message,
  Input,
} from 'antd';
import { UPDATE_ENTIDAD_INFO } from 'store/reducers/auth';
import { useDispatch } from 'react-redux';
import API from 'utils/api';
import LadaNumero from 'components/LadaNumero';
import Select from 'components/Select';
import { onError } from 'utils/handlers';
import Rutas from 'utils/rutas-endpoints';

const currentURI = '/estructura-organizacional/entidades/configuracion/';

function Configurations({
  setLoading,
  callback,
  setCurrentTabKey,
  setForm,
  disabled,
}) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [monedas, setMonedas] = React.useState([]);
  const [folioAgrupador, setFolioAgrupador] = useState({});

  const getFolioAgrupador = async (idFolio) => {
    const resApi = await API.get(`${Rutas.configuracionFolios}${idFolio}/`);
    const folio = await resApi.data;
    setFolioAgrupador(folio);
    form.setFieldsValue({
      folio_agrupador: folio.nombre,
    });
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await API.get(currentURI);
      getFolioAgrupador(res.data.folio_agrupador);
      if (res.status === 200) {
        form.setFieldsValue({
          ...res.data,
          moneda: res.data?.moneda?.id,
        });
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onFinish = async (_values, _continue = true) => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      values.folio_agrupador = folioAgrupador.id;

      const response = await API.post(currentURI, values);
      if (response?.status === 200) {
        message.info('Información actualizada correctamente');
        dispatch({
          type: UPDATE_ENTIDAD_INFO,
          payload: {
            configuracion: response.data,
          },
        });
        setTimeout(async () => {
          callback(response.data, _continue);
          if (_continue) {
            await fetchData();
          }
        });
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const showAddressMsg = (tabKey) => {
    message.info({
      content: (
        <>
          <br />
          <Row style={{ width: '100%' }}>
            Los cambios que hayan sido realizados serán descartados.
          </Row>
          <Row align="middle" style={{ width: '100%' }}>
            ¿Desea continuar?
            <Button
              type="link"
              onClick={() => {
                setCurrentTabKey(tabKey);
                message.destroy();
                fetchData();
              }}
            >
              Si
            </Button>
            <Button
              type="link"
              onClick={() => message.destroy()}
            >
              No
            </Button>
          </Row>
        </>
      ),
    });
  };

  const onFinishHandler = async (tabKey = null, _continue = true) => {
    try {
      if (tabKey) {
        showAddressMsg(tabKey);
      } else {
        await onFinish(null, _continue);
      }
    } catch (err) {
      onError(err);
    }
  };

  useEffect(() => {
    const fetchAll = async () => {
      try {
        const resMonedas = await API.get('/catalogos/monedas/');
        setMonedas(resMonedas.data);

        await fetchData();
      } catch (err) {
        onError(err, setLoading);
      }
    };
    fetchAll();
    setTimeout(() => {
      form.onFinishHandler = onFinishHandler;
      setForm(form);
    });
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const requiredRule = {
    required: true,
    message: 'El campo es requerido',
  };

  const rules = {
    required: [requiredRule],
    lada: [requiredRule],
  };

  return (
    <Col span={24}>
      <Form
        form={form}
        name="configurations"
        layout="vertical"
        onFinish={() => onFinish()}
      >
        <Row gutter={10}>
          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>
          <Col xs={24} sm={24} md={8}>
            <LadaNumero
              rules={rules}
              disabled={disabled}
              ladaOnly
              form={form}
            />
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Form.Item
              rules={rules.required}
              name="moneda"
              label="Moneda"
              hasFeedback
            >
              <Select
                disabled={disabled}
                dataSource={monedas}
                render={(e) => `${e.codigo_de_la_moneda} - ${e.descripcion}`}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Form.Item
              rules={rules.required}
              name="utilizar_redondeo"
              label="¿Utilizar redondeo?"
              hasFeedback
            >
              <Select
                disabled={disabled}
                trueFalse
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Form.Item
              rules={rules.required}
              name="permitir_fecha_capturada_folios"
              label="¿Permitir Fecha en los Folios?"
              hasFeedback
            >
              <Select
                disabled={disabled}
                trueFalse
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Form.Item
              name="folio_agrupador"
              label="Folio Agrupador"
            >
              <Input
                disabled
              />
            </Form.Item>
          </Col>
          <Form.Item hidden>
            <Button htmlType="submit" />
          </Form.Item>
        </Row>
      </Form>
    </Col>
  );
}

Configurations.propTypes = {
  callback: PropTypes.func,
  setLoading: PropTypes.func.isRequired,
  setForm: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  setCurrentTabKey: PropTypes.func.isRequired,
};

Configurations.defaultProps = {
  callback: () => { },
  disabled: false,
};

export default Configurations;
