import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Form,
  Upload,
  Button,
  Tooltip,
  message,
  Typography,
} from 'antd';

import {
  UploadOutlined,
  EyeOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';

import Previewer from './Previewer';

const { Text } = Typography;

function Uploader({
  file,
  title,
  limitMB,
  fullSize,
  formItemName,
  allowedExtensions,
  allowPreview,
  rules,
  ...rest
}) {
  const defaultBreakPoints = {
    xs: 24,
    sm: 24,
    md: 8,
  };
  const [fileList, setFileList] = useState([]);
  const [visible, setVisible] = useState(false);
  const [iFrameUrl, setIFrameUrl] = useState(null);

  const getProps = () => (fullSize ? { span: 24 } : defaultBreakPoints);
  const accept = allowedExtensions.map((e) => `.${e}`).join(',');
  const formats = allowedExtensions.join(', ').toUpperCase();

  const preview = () => {
    if (file && typeof file === 'string') {
      setIFrameUrl(file);
      setVisible(true);
    }
  };

  return (
    <>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Col {...getProps()}>
        <Form.Item
          name={formItemName}
          label={(
            <>
              <Text style={{ marginRight: 5 }}>{title}</Text>
              {(allowPreview && file && typeof file === 'string') && (
                <Tooltip title="Click para ver preview">
                  <Button
                    type="link"
                    onClick={preview}
                    icon={<EyeOutlined />}
                  />
                </Tooltip>
              )}
              {(!allowPreview && file && typeof file === 'string') && (
                <Tooltip
                  title="Por motivos de seguridad este archivo
                    no es accesible, si desea modificarlo suba un archivo"
                >
                  <CheckCircleOutlined style={{ marginLeft: 10, color: '#1bc943' }} />
                </Tooltip>
              )}
            </>
          )}
          rules={rules}
        >
          <Upload
            beforeUpload={() => false}
            onRemove={() => {
              setFileList([]);
            }}
            onChange={(e) => {
              const { name } = e.file;
              if (e.file.status !== 'removed') {
                const isValidExt = allowedExtensions.includes(name.split('.').pop());

                if (!isValidExt) {
                  message.warn(`Solo se admiten archivos en formato ${formats}`);
                }
                const isLmt = e.file.size / 1024 / 1024 < limitMB;
                if (!isLmt) {
                  message.warn(`El archivo no debe sobrepasar los ${limitMB}MB`);
                }
                if (isValidExt && isLmt) {
                  setFileList([e.file]);
                } else {
                  setFileList([]);
                }
              }
            }}
            fileList={fileList}
            accept={accept}
            disabled={!!rest.disabled}
          >
            <Button disabled={!!rest.disabled}>
              <UploadOutlined />
              {' '}
              Subir archivo
            </Button>
          </Upload>
        </Form.Item>
      </Col>
      {allowPreview && (
        <Previewer
          visible={visible}
          setVisible={setVisible}
          iFrameUrl={iFrameUrl}
        />
      )}
    </>
  );
}

Uploader.propTypes = {
  allowPreview: PropTypes.bool,
  fullSize: PropTypes.bool,
  file: PropTypes.string,
  title: PropTypes.string.isRequired,
  limitMB: PropTypes.number.isRequired,
  formItemName: PropTypes.string.isRequired,
  allowedExtensions: PropTypes.arrayOf(PropTypes.string).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  rules: PropTypes.arrayOf(PropTypes.object),
};

Uploader.defaultProps = {
  fullSize: false,
  file: null,
  allowPreview: true,
  rules: [],
};

export default Uploader;
