export default [{
  clave_presupuestal: '2021-01-E-252-21120-0032-01-01-2121-01-144',
  aprobado: '405934',
  modificaciones: '',
  vigente: '405934',
  pre_comprometido: '12009',
  disponible: '393925',
  comprometido: '12009',
  devengado: '12009',
  ejercido: '12009',
  pagado: '12009',
},
{
  clave_presupuestal: '2021-01-E-252-21120-0071-01-01-2121-01-144',
  aprobado: '750842',
  modificaciones: '',
  vigente: '750842',
  pre_comprometido: '7788',
  disponible: '743054',
  comprometido: '7788',
  devengado: '7788',
  ejercido: '7788',
  pagado: '7788',
},
{
  clave_presupuestal: '2021-01-E-252-21120-0075-01-01-2121-01-144',
  aprobado: '480302',
  modificaciones: '',
  vigente: '480302',
  pre_comprometido: '7661',
  disponible: '472641',
  comprometido: '7661',
  devengado: '7661',
  ejercido: '7661',
  pagado: '7661',
},
{
  clave_presupuestal: '2021-01-E-252-21120-0074-01-01-2121-01-144',
  aprobado: '637249',
  modificaciones: '',
  vigente: '637249',
  pre_comprometido: '',
  disponible: '637249',
  comprometido: '',
  devengado: '',
  ejercido: '',
  pagado: '',
},
{
  clave_presupuestal: '2021-01-E-252-21120-0076-01-01-2121-01-144',
  aprobado: '28394',
  modificaciones: '',
  vigente: '28394',
  pre_comprometido: '',
  disponible: '28394',
  comprometido: '',
  devengado: '',
  ejercido: '',
  pagado: '',
},
{
  clave_presupuestal: '2021-01-E-252-21120-0077-01-01-2121-01-144',
  aprobado: '367482',
  modificaciones: '',
  vigente: '367482',
  pre_comprometido: '',
  disponible: '367482',
  comprometido: '',
  devengado: '',
  ejercido: '',
  pagado: '',
},
{
  clave_presupuestal: '2021-01-E-252-21120-0078-01-01-2121-01-144',
  aprobado: '467293',
  modificaciones: '',
  vigente: '467293',
  pre_comprometido: '',
  disponible: '467293',
  comprometido: '',
  devengado: '',
  ejercido: '',
  pagado: '',
},
{
  clave_presupuestal: '2021-01-E-252-21120-0110-01-01-2121-01-144',
  aprobado: '293401',
  modificaciones: '',
  vigente: '293401',
  pre_comprometido: '',
  disponible: '293401',
  comprometido: '',
  devengado: '',
  ejercido: '',
  pagado: '',
},
{
  clave_presupuestal: '2021-01-E-252-21120-0131-01-01-2121-01-144',
  aprobado: '562391',
  modificaciones: '',
  vigente: '562391',
  pre_comprometido: '',
  disponible: '562391',
  comprometido: '',
  devengado: '',
  ejercido: '',
  pagado: '',
},
{
  clave_presupuestal: '2021-01-E-252-21120-0168-01-01-2121-01-144',
  aprobado: '502912',
  modificaciones: '',
  vigente: '502912',
  pre_comprometido: '',
  disponible: '502912',
  comprometido: '',
  devengado: '',
  ejercido: '',
  pagado: '',
},
{
  clave_presupuestal: '2021-01-E-252-21120-0182-01-01-2121-01-144',
  aprobado: '493061',
  modificaciones: '',
  vigente: '493061',
  pre_comprometido: '',
  disponible: '493061',
  comprometido: '',
  devengado: '',
  ejercido: '',
  pagado: '',
},
{
  clave_presupuestal: '2021-01-E-252-21120-0234-01-01-2121-01-144',
  aprobado: '381053',
  modificaciones: '',
  vigente: '381053',
  pre_comprometido: '',
  disponible: '381053',
  comprometido: '',
  devengado: '',
  ejercido: '',
  pagado: '',
},
{
  clave_presupuestal: '2021-01-E-252-21120-0235-01-01-2121-01-144',
  aprobado: '284106',
  modificaciones: '',
  vigente: '284106',
  pre_comprometido: '',
  disponible: '284106',
  comprometido: '',
  devengado: '',
  ejercido: '',
  pagado: '',
},
{
  clave_presupuestal: '2021-01-E-252-21120-0258-01-01-2121-01-144',
  aprobado: '620147',
  modificaciones: '',
  vigente: '620147',
  pre_comprometido: '',
  disponible: '620147',
  comprometido: '',
  devengado: '',
  ejercido: '',
  pagado: '',
},
{
  clave_presupuestal: '2021-01-E-252-21120-0260-01-01-2121-01-144',
  aprobado: '723015',
  modificaciones: '',
  vigente: '723015',
  pre_comprometido: '',
  disponible: '723015',
  comprometido: '',
  devengado: '',
  ejercido: '',
  pagado: '',
},
{
  clave_presupuestal: '2021-01-E-252-21120-0281-01-01-2121-01-144',
  aprobado: '520519',
  modificaciones: '',
  vigente: '520519',
  pre_comprometido: '',
  disponible: '520519',
  comprometido: '',
  devengado: '',
  ejercido: '',
  pagado: '',
},
{
  clave_presupuestal: '2021-01-E-252-21120-0293-01-01-2121-01-144',
  aprobado: '429015',
  modificaciones: '',
  vigente: '429015',
  pre_comprometido: '',
  disponible: '429015',
  comprometido: '',
  devengado: '',
  ejercido: '',
  pagado: '',
},
{
  clave_presupuestal: '2021-01-E-252-21120-0322-01-01-2121-01-144',
  aprobado: '39163',
  modificaciones: '',
  vigente: '39163',
  pre_comprometido: '',
  disponible: '39163',
  comprometido: '',
  devengado: '',
  ejercido: '',
  pagado: '',
},
{
  clave_presupuestal: '2021-01-E-252-21120-0527-01-01-2121-01-144',
  aprobado: '629501',
  modificaciones: '',
  vigente: '629501',
  pre_comprometido: '',
  disponible: '629501',
  comprometido: '',
  devengado: '',
  ejercido: '',
  pagado: '',
},
{
  clave_presupuestal: '2021-01-E-252-21120-0702-01-01-2121-01-144',
  aprobado: '739104',
  modificaciones: '',
  vigente: '739104',
  pre_comprometido: '',
  disponible: '739104',
  comprometido: '',
  devengado: '',
  ejercido: '',
  pagado: '',
},
];
