import React, { useState, useEffect } from 'react';
import {
  Form,
  Row,
  Spin,
  Col,
  Input,
  Modal,
  Select,
} from 'antd';

import API from 'utils/api';
import { onError } from 'utils/handlers';
import Table from 'components/Table';
import FormSubmitControls from 'components/FormSubmitControls';

function FinalidadGasto() {
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visibleModalAgregar, setVisibleModalAgregar] = useState(false);

  const requiredRule = {
    required: true,
    message: 'El campo es requerido',
  };
  const rules = {
    required: [requiredRule],
  };
  const fetchData = async () => {
    try {
      setLoading(true);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchData();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    setTimeout(() => form.setFieldsValue(record));
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickAdd = () => {
    setVisibleModalAgregar(true);
  };

  const onClickEdit = () => {
    setVisibleModalAgregar(true);
  };

  const onClickDelete = () => {};

  const columns = [
    {
      titleText: 'Clave',
      dataIndex: 'concepto',
      key: 'concepto',
      width: 200,
    },
    {
      titleText: 'Concepto',
      dataIndex: 'concepto',
      key: 'concepto',
      width: 200,
    },
    {
      titleText: 'Rubro',
      dataIndex: 'rubro',
      key: 'rubro',
      width: 200,
    },
    {
      titleText: 'COG',
      dataIndex: 'cog',
      key: 'cog',
      width: 200,
    },
    {
      titleText: 'Cuenta Contable',
      dataIndex: 'cuenta_contable',
      key: 'cuenta_contable',
      width: 200,
    },
    {
      titleText: 'Estado',
      dataIndex: 'estado',
      key: 'estado',
      width: 200,
    },
  ];

  const onFinish = () => ({});

  const onCancelModalFinalidadGasto = () => {
    setVisibleModalAgregar(false);
  };
  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        <Table
          cols={columns}
          data={[]}
          rowSelection={rowSelection}
          handleOnRowClick={handleOnRowClick}
          mobileColIndex={0}
          controls={{
            onClickAdd,
            onClickEdit,
            onClickDelete,
          }}
        />
        <Modal
          visible={visibleModalAgregar}
          closable={false}
          maskClosable={!loading}
          keyboard={!loading}
          footer={null}
          title={(
            <FormSubmitControls
              label={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Finalidad de Gasto`}
              onFinish={onFinish}
              onCancel={onCancelModalFinalidadGasto}
              loading={loading}
            />
        )}
          onCancel={onCancelModalFinalidadGasto}
          forceRender
        >
          <Form
            form={form}
            layout="vertical"
            name="finalidad_gasto"
            onFinish={onFinish}
            initialValues={{ estados_globales: 1 }}
          >
            <Col span={24}>
              <Form.Item
                name="rubro"
                label="Rubro"
                rules={rules.required}
              >
                <Select allowClear />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="clave"
                label="Clave"
                rules={rules.required}
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="concepto_finalidad"
                label="Concepto de Finalidad"
                rules={rules.required}
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="requiere_cog"
                label="Requiere COG Específico"
                rules={rules.required}
              >
                <Select allowClear />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="clasificador_objeto_gasto"
                label="Clasificador por Objeto del Gasto"
              >
                <Select allowClear />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="requiere_cuenta_contable"
                label="Requiere Cuenta Contable"
                rules={rules.required}
              >
                <Select allowClear />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="cuenta_contable"
                label="Cuenta Contable"
              >
                <Select allowClear />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="estado"
                label="Estado"
              >
                <Input allowClear disabled defaultValue="Activo" />
              </Form.Item>
            </Col>
          </Form>
        </Modal>
      </Spin>
    </Row>
  );
}

export default FinalidadGasto;
