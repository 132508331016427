import React, { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
import {
  Form,
  Button,
  Row,
  Col,
  Spin,
  Input,
  Card,
  message,
} from 'antd';

import { FilePdfOutlined, SearchOutlined, FileExcelOutlined } from '@ant-design/icons';

import Table from 'components/Table';
import FormSubmitControls from 'components/FormSubmitControls';
import API, { baseURL } from 'utils/api';
import {
  onError,
} from 'utils/handlers';
import Select from 'components/Select';
import { formatReceived } from 'utils/formatValues';

export const permissionBalanzaDeComprobacion = {
  permissionModel: 'balanzadecomprobacion',
};

const baseURI = 'contabilidad/balanzas-de-comprobaciones/';
// eslint-disable-next-line react/prop-types
function BalanzaDeComprobacion({ permission }) {
  const [form] = Form.useForm();
  const [formBusqueda] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState([]);

  const [periodos, setPeriodos] = useState([]);

  const fetchData = async () => {
    try {
      setLoading(true);

      const resPeriodos = await API.get('/configuraciones/periodos-de-balanza-de-comprobacion/');
      setPeriodos(resPeriodos.data);

      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchData();

    return () => API.source.cancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setVisible(false);
    setSelectedRowKeys([]);
    form.resetFields();
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    setTimeout(() => form.setFieldsValue(record));
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const columns = [
    {
      titleText: 'Cuenta',
      dataIndex: 'cuenta',
      key: 'cuenta',
      width: 400,
    },
    {
      titleText: 'Nombre',
      dataIndex: 'nombre',
      key: 'nombre',
      width: 400,
    },
  ];

  const required = {
    message: 'El campo es requerido',
    required: true,
  };

  const rules = {
    required: [required],
  };

  const fetch = async () => {
    try {
      setLoading(true);
      await formBusqueda.validateFields();
      const values = formBusqueda.getFieldsValue();

      const response = await API.post(baseURI, values);
      if (response?.status === 200 && response.data.length) {
        const formattedData = formatReceived(response.data);
        setData(formattedData);
      } else {
        message.info('No se encontraron coincidencias');
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onClickPreviewPDF = async () => {
    try {
      setLoading(true);
      await formBusqueda.validateFields();
      const values = formBusqueda.getFieldsValue();
      const res = await API.post(`${baseURI}request-download/`, values);
      if (res?.status === 200 && res.data) {
        const { token } = res.data;
        window.open(`${baseURL}contabilidad/balanzas-de-comprobaciones/pdf/?token=${token}`, '_blank');
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const downloadExcel = async () => {
    try {
      setLoading(true);
      await formBusqueda.validateFields();
      const values = formBusqueda.getFieldsValue();
      const res = await API.post(`${baseURI}request-download/`, values);
      if (res?.status === 200 && res.data) {
        const { token } = res.data;
        window.open(`${baseURL}contabilidad/balanzas-de-comprobaciones/excel/?token=${token}`, '_blank');
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onClickExpand = () => setVisible(true);

  return (
    <Row align="center" justify="center" className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible ? (
          <>
            <Form
              form={formBusqueda}
              layout="vertical"
              onFinish={fetch}
            >
              <Row gutter={10}>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    label="Ejercicio"
                    rules={rules.required}
                    name=""
                  >
                    <Select dataSource={periodos} labelProp="nombr e" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    label="Mes"
                    rules={rules.required}
                    name=""
                  >
                    <Select dataSource={periodos} labelProp="nombr e" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    label="Periodo"
                    rules={rules.required}
                    name=""
                  >
                    <Select
                      dataSource={periodos}
                      placeholder="Del mes"
                      labelProp="nombr e"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    label="."
                    rules={rules.required}
                    name=""
                  >
                    <Select
                      dataSource={periodos}
                      placeholder="Al mes"
                      labelProp="nombr e"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    label="Nivel"
                    rules={rules.required}
                    name=""
                  >
                    <Select dataSource={[{
                      id: 1,
                      descripcion: 'Todos',
                    }, {
                      id: 2,
                      descripcion: 'Cuentas de Mayor',
                    }, {
                      id: 3,
                      descripcion: 'Cuentas de Último Nivel',
                    }]}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    label="Cuentas"
                    rules={rules.required}
                    name=""
                  >
                    <Select dataSource={[{
                      id: 1,
                      descripcion: 'Todas',
                    }, {
                      id: 2,
                      descripcion: 'Con movimientos',
                    }, {
                      id: 3,
                      descripcion: 'Con saldo final diferente de cero',
                    }, {
                      id: 4,
                      descripcion: 'Con moviminetos y saldo final diferente de cero',
                    }]}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24}>
                  <Form.Item
                    name=""
                    rules={rules.required}
                    label="Comienza con"
                  >
                    <Input
                      allowClear
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name=""
                    rules={rules.required}
                    label="Rango"
                  >
                    <Input
                      allowClear
                      placeholder="De la cuenta"

                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name=""
                    rules={rules.required}
                    label="."
                  >
                    <Input
                      allowClear
                      placeholder="A la cuenta"
                    />
                  </Form.Item>
                </Col>
                <Col style={{ display: 'flex', alignItems: 'center' }}>
                  <Button
                    onClick={fetch}
                    icon={<SearchOutlined />}
                    disabled
                  >
                    Buscar
                  </Button>
                </Col>
              </Row>
              <Form.Item hidden>
                <Button htmlType="submit" />
              </Form.Item>
            </Form>
            <Table
              cols={columns}
              permission={permission}
              data={data}
              rowSelection={rowSelection}
              mobileColIndex={0}
              handleOnRowClick={handleOnRowClick}
              controls={{
                onClickExpand,
              }}
              customActions={[
                {
                  onClick: onClickPreviewPDF,
                  icon: <FilePdfOutlined />,
                  text: 'Descargar PDF',
                  disabledBySelection: false,
                },
                {
                  onClick: downloadExcel,
                  icon: <FileExcelOutlined />,
                  text: 'Descargar Excel',
                  disabledBySelection: false,
                },
              ]}
              allowExpand
            />
          </>
        ) : (
          <Col span={24} style={{ height: '100%' }}>
            <Card
              className="form-container"
              title={(
                <FormSubmitControls
                  label={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Reserva`}
                  onCancel={onCancel}

                />
              )}
              bordered={false}
            >
              <Form
                layout="vertical"
                form={form}
                scrollToFirstError
                className="without-feedback"
                initialValues={{ estados_globales: 1 }}
              >
                <Row gutter={10}>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="cuenta"
                      label="Cuenta"
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="nombre"
                      label="Nombre"
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="nivel"
                      label="Nivel"
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="saldo_inicial"
                      label="Saldo Inicial"
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="naturaleza"
                      label="Naturaleza"
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="afectable"
                      label="¿Es afectable?"
                    >
                      <Select trueFalse disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="tiene_movimientos"
                      label="¿Tiene movimientos?"
                    >
                      <Select trueFalse disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="cargos"
                      label="Cargos"
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="abonos"
                      label="Abonos"
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="saldo_final"
                      label="Saldo Final"
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item hidden>
                  <Button htmlType="submit" />
                </Form.Item>
              </Form>
            </Card>
          </Col>
        )}
      </Spin>
    </Row>
  );
}

export default BalanzaDeComprobacion;
