/* eslint-disable react/forbid-prop-types */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Input,
  Button,
  Row,
  Spin,
  Col,
} from 'antd';
import API from 'utils/api';
import { onError } from 'utils/handlers';
import NumericInput from 'components/NumericInput';
import makeMessager from 'utils/complexFormsMessages';
import Select from '../Select';

function DatosAdicionales({
  selectedRowKey: cuenta_bancaria,
  loading,
  setLoading,
  setForm,
  setCurrentTabKey,
}) {
  const baseURI = 'tesoreria/datos-adicionales-de-cuentas-bancarias/';
  const [form] = Form.useForm();

  const fetchData = async () => {
    try {
      setLoading(true);
      if (cuenta_bancaria) {
        const res = await API.get(baseURI, {
          params: {
            cuenta_bancaria,
          },
        });
        if (res?.status === 200) {
          setTimeout(() => form.setFieldsValue(res.data[0]));
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line no-use-before-define
    form.onFinishHandler = onFinishHandler;
    setForm(form);
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, [cuenta_bancaria]);

  const onCancel = () => {
    form.resetFields();
  };

  const onFinish = async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue();
      if (cuenta_bancaria) {
        if (!values.id) {
          values.cuenta_bancaria = cuenta_bancaria;
          const res = await API.post(baseURI, values);
          if (res?.status === 201) {
            onCancel();
            await fetchData();
          }
        } else {
          const res = await API.patch(`${baseURI}${values.id}/`, values);
          if (res?.status === 200) {
            onCancel();
            await fetchData();
          }
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const showMsg = makeMessager(setCurrentTabKey, fetchData);

  const onFinishHandler = async (tabKey = null, _continue = false) => {
    try {
      if (tabKey) {
        showMsg(tabKey);
      } else {
        // eslint-disable-next-line no-use-before-define
        await onFinish(null, _continue);
      }
    } catch (err) {
      onError(err, setLoading);
    }
  };

  return (
    <Row align="center" justify="center" className="container">
      <Spin tip="Cargando..." spinning={loading}>
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          scrollToFirstError
          initialValues={{ estados_globales: 1 }}
        >
          <Form.Item
            name="id"
            hidden
          >
            <Input />
          </Form.Item>
          <Row gutter={10}>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="plaza"
                label="Plaza"
                hasFeedback
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="sucursal"
                label="Sucursal"
                hasFeedback
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="numero_contrato"
                label="Número de Contrato"
                hasFeedback
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="numero_cliente"
                label="Número de Cliente"
                hasFeedback
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <NumericInput
                name="saldo_minimo"
                label="Saldo Mínimo"
              />
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="utiliza_chequera"
                label="¿Utiliza Chequera?"
                hasFeedback
              >
                <Select allowClear trueFalse />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="numero_de_cheque_inicial"
                label="Número de Cheque Inicial"
                hasFeedback
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="realiza_transferencias"
                label="¿Realiza Transferencias?"
                hasFeedback
              >
                <Select allowClear trueFalse />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="acceso_portal_bancario"
                label="¿Tiene Acceso a Portal Bancario?"
                hasFeedback
              >
                <Select allowClear trueFalse />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item hidden>
            <Button htmlType="submit" />
          </Form.Item>
        </Form>
      </Spin>
    </Row>
  );
}

DatosAdicionales.propTypes = {
  selectedRowKey: PropTypes.number,
  loading: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
  setForm: PropTypes.func.isRequired,
  setCurrentTabKey: PropTypes.func.isRequired,
};

DatosAdicionales.defaultProps = {
  selectedRowKey: 0,
};

export default DatosAdicionales;
