import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Spin,
  Card,
  TreeSelect,
  Tabs,
  message,
} from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import Table from 'components/Table';
import API from 'utils/api';
import FormSubmitControls from 'components/FormSubmitControls';
import ModalDelete from 'components/ModalDelete';
import Transfer from 'components/Transfer';
import LadaNumero from 'components/LadaNumero';
import Select from 'components/Select';
import fetchSome from 'utils/fetchSome';
import Direccion from 'components/Direccion';
import { filterArbolado, findItemNested } from 'utils/filters';
import { discartNullValues } from 'utils/formatValues';
import { isMutable, isAuthorized } from 'utils/estadosGlobales';
import { onError, onSuccess } from 'utils/handlers';
import makeThreeFromCOG from 'utils/makeTree';
import { UPDATE_ENTIDAD_INFO } from 'store/reducers/auth';
import { SET_TIPOS_PRESUPUESTOS } from 'store/reducers/catalogs';
import ModalConfig from './ModalConfig';
import Programas from './Programas';

export const permissionMomentoUnidadOperativa = {
  permissionModel: 'unidadoperativa',
};

export const permissionMomentoUnidadResponsable = {
  permissionModel: 'unidadresponsable',
};

export const permissionMomentoCentroDeCostos = {
  permissionModel: 'centrodecostos',
};

const { TabPane } = Tabs;

// eslint-disable-next-line react/prop-types
const UnidadesResponsables = ({ permission }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [formConfigPresupuestal] = Form.useForm();
  const [formConfigFamilias] = Form.useForm();
  const [formConfigEntidad] = Form.useForm();
  const [formMisionVision] = Form.useForm();
  const user = useSelector(({ auth }) => auth.user);
  const entidad = useSelector(({ auth }) => auth.entidad);
  const tiposPresupuestos = useSelector(({ catalogs }) => catalogs.tiposPresupuestos);
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  const collapsedSidebar = useSelector(({ app }) => app.collapsedSidebar);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);
  const [esUrPresupuestal, setEsUrPresupuestal] = useState(false);
  const [currentTabKey, setCurrentTabKey] = useState('general');

  const [unidadesOperativas, setUnidadesOperativas] = useState([]);
  const [unidadesResponsables, setUnidadesResponsables] = useState([]);
  const [tipoClaveUr, setTiposClaveUr] = useState([]);
  const [tipoDeUr, setTiposUr] = useState([]);
  const [clasificacionAdministrativa, setClasificacionAdministrativa] = useState([]);
  const [configSegmentoPresupuestalEgresos, setConfigSegmentoPresupuestalEgresos] = useState([]);
  const [configSegmentoPresupuestalIngresos, setConfigSegmentoPresupuestalIngresos] = useState([]);
  const [programatica, setProgramatica] = useState([]);
  const [funcionales, setFuncionales] = useState([]);
  const [clasificacionesGastoInterno, setClasificacionesGastoInterno] = useState([]);
  const [fuentesDeFinanciamiento, setFuentesDeFinanciamiento] = useState([]);
  const [tiposDeGasto, setTiposDeGasto] = useState([]);
  const [clasesCRI, setClasesCRI] = useState([]);
  const [contentTypes, setContentTypes] = useState([]);

  const [filteredAdministrativas, setFilteredAdministrativas] = useState([]);
  const [filteredFuncionales, setFilteredFuncionales] = useState([]);
  const [filteredProgramatica, setFilteredProgramatica] = useState([]);
  const [filteredTiposDeGasto, setFilteredTiposDeGasto] = useState([]);
  const [filteredCOGInterno, setFilteredCOGInterno] = useState([]);
  const [filteredClasificacionesFF, setFilteredClasificacionesFF] = useState([]);

  const [visibleConfig, setVisibleConfig] = useState(false);
  const [savedConfig, setSavedConfig] = useState(false);
  const [mutable, setMutable] = useState(true);
  const [formAddress, setFormAddress] = useState();

  const [funcionesPresupuestales, setFuncionesPresupuestales] = useState([]);

  const currentURI = window.location.href.split('/').pop();
  const completeURI = `estructura-organizacional/${currentURI}/`;

  const basicKeys = ['general', 'configEntidad', 'configPresupuestos', 'configFamilias', 'misionVision'];
  const complexForms = [{ key: 'direccion', formInstance: formAddress }];
  const formInstances = [
    form,
    formConfigPresupuestal,
    formConfigFamilias,
    formConfigEntidad,
    formMisionVision,
  ];

  const onClickTitle = (e) => {
    const element = e.target;
    if (element) {
      let parent = element.parentElement;
      parent = parent?.parentElement;
      const clickableSpan = parent?.previousSibling;
      if (clickableSpan) {
        clickableSpan.click();
      }
    }
  };

  const renderTitle = (title, bold) => (
    // eslint-disable-next-line
    <span onClick={onClickTitle} className={bold ? 'bold' : ''}>
      {title}
    </span>
  );

  const normalizeTreeData = (_data, keepValue = true) => {
    if (_data) {
      const output = _data.map((item) => {
        const clone = { ...item };
        const children = normalizeTreeData(clone.children, keepValue);
        const title = renderTitle(`${clone.clave} - ${clone.concepto || clone.nombre}`, true);
        if (keepValue) {
          clone.value = clone.id;
        }
        if (!children.length) {
          delete clone.children;
          return ({
            ...clone,
            title,
            selectable: true,
          });
        }
        return ({
          ...clone,
          title,
          children,
          selectable: false,
        });
      });
      return output;
    }
    return [];
  };

  const normalizeDataForTransfer = (_data, prop, nested = true) => {
    if (_data?.length) {
      const output = _data.map((item) => {
        const clone = { ...item };
        const children = normalizeDataForTransfer(clone.children, prop);
        const identificator = nested
          ? `${clone.id.toString()}${item.seleccionable ? '' : '_parent'}` : clone.id;
        delete clone.children;
        if (!children.length) {
          return ({
            ...clone,
            value: identificator,
            title: `${item.clave} - ${item[prop]}`,
            key: identificator,
            selectable: item.seleccionable,
            checkable: item.seleccionable,
          });
        }
        return ({
          ...clone,
          value: identificator,
          title: `${item.clave} - ${item[prop]}`,
          children,
          disabled: true,
          selectable: item.seleccionable,
          checkable: item.seleccionable,
          key: identificator,
        });
      });
      return output;
    }
    return [];
  };

  const normalizePlainDataForTransfer = (
    _data = [],
    prop,
  ) => _data.map((item) => ({
    ...item,
    value: item.id,
    title: `${item.clave} - ${item[prop]}`,
    key: item.id,
    selectable: item.seleccionable,
    checkable: item.seleccionable,
  }));

  const normalizeDataCRI = (
    _data = [],
    excludeLvl3 = false,
    currLvl = 1,
  ) => _data.map((item) => {
    const clone = { ...item };
    const children = normalizeDataCRI(!excludeLvl3
      || currLvl !== 2 ? clone.children : [], excludeLvl3, currLvl + 1);
    const selectable = currLvl === 3;
    if (!children.length) {
      delete clone.children;
      return ({
        ...clone,
        key: `${clone.clave}_${clone.nivel}`,
        title: `${clone.clave} - ${clone.denominacion}`,
        value: `${clone.clave}_${clone.nivel}`,
        selectable,
      });
    }
    return ({
      ...clone,
      key: `${clone.clave}_${clone.nivel}`,
      children,
      title: `${clone.clave} - ${clone.denominacion}`,
      value: `${clone.clave}_${clone.nivel}`,
      selectable,
    });
  });

  const normalizeCog = (_data = []) => _data.map((item) => {
    const clone = { ...item };
    const children = clone.children?.length && typeof clone.id === 'string'
      ? normalizeCog(clone.children) : [];
    if (!children.length || typeof clone.id === 'number') {
      delete clone.children;
      return ({
        ...clone,
        key: clone.clave,
        clave: clone.clave,
        disabled: !clone.seleccionable,
      });
    }
    return ({
      ...clone,
      key: clone.clave,
      children,
      disabled: !clone.seleccionable,
    });
  });

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    formConfigPresupuestal.resetFields();
    formConfigFamilias.resetFields();
    formConfigEntidad.resetFields();
    formMisionVision.resetFields();
    setSelectedRowKeys([]);
    setCurrentTabKey('general');
    setMutable(true);
    setEsUrPresupuestal(false);
    if (currentURI !== 'unidades-operativas') {
      setFilteredFuncionales([]);
      setFilteredAdministrativas([]);
      setFilteredProgramatica([]);
      setFilteredTiposDeGasto([]);
      setFilteredCOGInterno([]);
      setFilteredClasificacionesFF([]);
    }
  };

  const fetchData = async () => {
    try {
      const resOperativas = await API.get('estructura-organizacional/unidades-operativas/');
      if (resOperativas?.status === 200) {
        setUnidadesOperativas(resOperativas.data || []);
        if (currentURI === 'unidades-operativas') {
          setData(resOperativas.data || []);
        }
      }
      if (currentURI !== 'unidades-operativas') {
        const resResponsables = await API.get('estructura-organizacional/unidades-responsables/');
        if (resResponsables?.status === 200) {
          setUnidadesResponsables(resResponsables.data || []);
          if (currentURI === 'unidades-responsables') {
            setData(resResponsables.data || []);
          }
        }
      }
      if (currentURI === 'centros-de-costos') {
        const resCentrosDeCostos = await API.get('estructura-organizacional/centros-de-costos/');
        if (resCentrosDeCostos?.status === 200) {
          setData(resCentrosDeCostos.data || []);
        }
      }
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const fetchAll = async () => {
    try {
      setLoading(true);
      if (!tiposPresupuestos?.length) {
        await fetchSome('catalogos/tipos-de-presupuestos/', SET_TIPOS_PRESUPUESTOS);
      }

      const resTipoClaveDeUr = await API.get('contabilidad/tipos-de-clave-unidad-responsable/');
      setTiposClaveUr(resTipoClaveDeUr.data || []);

      const resConfigSegmentoPresupuestalEgresos = await API
        .get('configuraciones/configuraciones-de-segmentos-presupuestales/', {
          params: {
            estados_globales: 4,
            tipo_de_presupuesto: 2,
          },
        });
      setConfigSegmentoPresupuestalEgresos(resConfigSegmentoPresupuestalEgresos.data || []);

      const resConfigSegmentoPresupuestalIngresos = await API
        .get('configuraciones/configuraciones-de-segmentos-presupuestales/', {
          params: {
            estados_globales: 4,
            tipo_de_presupuesto: 1,
          },
        });

      setConfigSegmentoPresupuestalIngresos(resConfigSegmentoPresupuestalIngresos.data || []);

      const resTiposUr = await API.get('contabilidad/tipos-unidad-responsable/');
      setTiposUr(resTiposUr.data || []);

      const resFuncionales = await API.get('catalogos/clasificaciones-funcionales/');
      const mappedFuncionales = normalizeTreeData(resFuncionales.data || []);
      setFuncionales(mappedFuncionales);

      const resClasificacionAdministrativa = await API.get('catalogos/clasificaciones-administrativas');
      const mappedAdministrativa = normalizeTreeData(resClasificacionAdministrativa.data || []);
      setClasificacionAdministrativa(mappedAdministrativa);

      const resProgramatica = await API.get('catalogos/clasificaciones-programaticas/');
      const mappedProgramatica = normalizeTreeData(resProgramatica.data || []);
      setProgramatica(mappedProgramatica);

      const resCOGInterno = await API.get('/contabilidad/clasificaciones-objeto-gasto/');
      const cogTree = makeThreeFromCOG(resCOGInterno.data.map((e) => (!e.seleccionable ? ({
        ...e,
        id: `${e.id}`,
      }) : e)).sort((a, b) => a.id - b.id));
      const normalizedCog = normalizeCog(cogTree);

      const mappedCOGInterno = normalizeDataForTransfer(normalizedCog, 'concepto');
      setClasificacionesGastoInterno(mappedCOGInterno);

      const resFinanciamiento = await API
        .get('contabilidad/clasificaciones-de-fuentes-de-financiamiento/');
      const fuentesFTree = makeThreeFromCOG(resFinanciamiento.data.map((e) => (!e.seleccionable ? ({
        ...e,
        id: `${e.id}`,
      }) : e)).sort((a, b) => a.id - b.id));
      const normalizedFF = normalizeCog(fuentesFTree);
      const mappedFF = normalizeDataForTransfer(normalizedFF, 'nombre');
      setFuentesDeFinanciamiento(mappedFF);

      const resTiposDeGasto = await API.get('catalogos/clasificaciones-tipo-de-gasto/');
      const mappedTiposGasto = normalizePlainDataForTransfer(resTiposDeGasto.data, 'nombre');
      setTiposDeGasto(mappedTiposGasto);

      const resClaseCRI = await API.get('contabilidad/cri/');
      const mappedClases = normalizeDataCRI(resClaseCRI.data);
      setClasesCRI(mappedClases);

      const resFuncionesPresupuestales = await API.get('catalogos/funcion-presupuestal/');
      setFuncionesPresupuestales(resFuncionesPresupuestales.data || []);

      if (currentURI === 'unidades-operativas') {
        setFilteredFuncionales(mappedFuncionales);
        setFilteredAdministrativas(mappedAdministrativa);
        setFilteredProgramatica(mappedProgramatica);
        setFilteredCOGInterno(mappedCOGInterno);
        setFilteredClasificacionesFF(mappedFF);
        setFilteredTiposDeGasto(mappedTiposGasto);
      }

      const resContentTypes = await API.get('catalogos/content-types-ur');
      setContentTypes(resContentTypes.data);

      await fetchData();
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const fetchBase = async () => {
    try {
      setLoading(true);
      const res = await API.get('/estructura-organizacional/entidades/configuracion-de-ur-administrador/');
      if ((res.status === 200 && res.data)) {
        setSavedConfig(true);
        dispatch({
          type: UPDATE_ENTIDAD_INFO,
          payload: {
            ...entidad,
            configuracion_de_ur: res.data,
          },
        });
        await fetchAll();
      } else {
        setVisibleConfig(true);
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchBase();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (savedConfig) {
      fetchAll();
    }
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, [savedConfig]);

  const filterCatalogs = (match) => {
    if (match) {
      const clasificacionesFuncionalesFiltered = filterArbolado(
        [match.clasificacion_funcional],
        JSON.parse(JSON.stringify(funcionales)),
      );
      setFilteredFuncionales(normalizeTreeData(clasificacionesFuncionalesFiltered));
      form.setFieldsValue({ clasificacion_funcional: match.clasificacion_funcional });

      const programaticaFiltered = filterArbolado(
        match.clasificacion_programatica,
        JSON.parse(JSON.stringify(programatica)),
      );
      setFilteredProgramatica(normalizeTreeData(programaticaFiltered));

      const clasificacionesAdministrativasFiltered = filterArbolado(
        match.clasificacion_administrativa,
        JSON.parse(JSON.stringify(clasificacionAdministrativa)),
      );
      setFilteredAdministrativas(normalizeTreeData(clasificacionesAdministrativasFiltered));

      const COGInternoFiltered = filterArbolado(
        match.cog_interno,
        JSON.parse(JSON.stringify(clasificacionesGastoInterno)),
        { mixed: true },
      );
      setFilteredCOGInterno(COGInternoFiltered);

      const ffFiltered = filterArbolado(
        match.fuentes_de_financiamiento_interna,
        JSON.parse(JSON.stringify(fuentesDeFinanciamiento)),
        { mixed: true },
      );
      setFilteredClasificacionesFF(ffFiltered);

      const tiposGastoFiltered = tiposDeGasto.filter((e) => match.tipo_de_gasto?.includes(e.id));
      setFilteredTiposDeGasto(tiposGastoFiltered);
    }
  };

  const onClickEdit = async (responseData = null) => {
    // form.resetFields();
    // formConfigEntidad.resetFields();
    // formConfigPresupuestal.resetFields();
    const [key] = selectedRowKeys;
    const match = responseData || data.find((i) => i.id === key);
    const { es_UR_presupuestal_de_ingreso } = match;
    match.unidad_operativa = match.unidad_operativa?.id || match.unidad_operativa;
    match.unidad_responsable = match.unidad_responsable?.id || match.unidad_responsable;
    setEsUrPresupuestal(es_UR_presupuestal_de_ingreso);
    setMutable(isMutable(match));
    if (match.unidad_operativa) {
      const mtch = unidadesOperativas.find((e) => e.id === match.unidad_operativa);
      filterCatalogs(mtch);
    } else if (match.unidad_responsable) {
      const mtch = unidadesResponsables.find((e) => e.id === match.unidad_responsable);
      filterCatalogs(mtch);
    }
    let clase_CRI = [];
    if (match?.clase_CRI?.length) {
      const promises = match.clase_CRI.map((e) => API.get(`/configuraciones/clases/${e}`));
      const responses = promises.length ? await Promise.all(promises) : [];
      const dataCRI = responses.map((r) => r.data);
      clase_CRI = dataCRI.map((e) => `${e.clave}_${e.nivel}`);
    }
    const values = discartNullValues({
      ...match,
      es_UR_presupuestal_de_ingreso,
      clase_CRI,
    });
    setVisible(true);
    setTimeout(() => {
      form.setFieldsValue(values);
      formMisionVision.setFieldsValue({ ...values });
      formConfigPresupuestal.setFieldsValue({ ...values });
      formConfigFamilias.setFieldsValue({ ...values });
      formConfigEntidad.setFieldsValue({
        ...values,
        es_UR_presupuestal_de_ingreso,
        clase_CRI,
      });
    });
    form.setFieldsValue(values);
    formConfigPresupuestal.setFieldsValue({ ...values });
    formMisionVision.setFieldsValue({ ...values });
    formConfigEntidad.setFieldsValue({
      ...values,
      es_UR_presupuestal_de_ingreso,
      clase_CRI,
    });
  };

  const onFinish = async (_values, _continue = false) => {
    try {
      setLoading(true);
      await form.validateFields();
      await formConfigPresupuestal.validateFields();
      await formConfigFamilias.validateFields();
      await formConfigEntidad.validateFields();
      await formMisionVision.validateFields();
      const values = {
        ...form.getFieldsValue(),
        ...formConfigPresupuestal.getFieldsValue(),
        ...formConfigFamilias.getFieldsValue(),
        ...formConfigEntidad.getFieldsValue(),
        ...formMisionVision.getFieldValue(),
      };
      if (!esUrPresupuestal) {
        values.clase_CRI = [];
      } else {
        let matchCRI = [];
        if (values.clase_CRI.some((e) => typeof e === 'string')) {
          matchCRI = values.clase_CRI.map((e) => findItemNested(clasesCRI, e, 'key'));
        } else {
          matchCRI = values.clase_CRI.map((e) => findItemNested(clasesCRI, e));
        }
        values.clase_CRI = matchCRI.map((e) => e.id);
      }
      if (!selectedRowKeys.length) {
        const response = await API.post(completeURI, values);
        if (response?.status === 201) {
          if (_continue) {
            onSuccess(response, 'Agregado correctamente');
            await fetchData();
            setSelectedRowKeys([response.data.id]);
            onClickEdit(response.data);
          } else {
            onSuccess(response, 'Agregado correctamente');
            onCancel();
            await fetchData();
          }
          setLoading(false);
          return response.data;
        }
      } else {
        if (!esUrPresupuestal) {
          values.clase_CRI = [];
        }
        const [rowKey] = selectedRowKeys;
        const response = await API.patch(`${completeURI}${rowKey}/`, values);
        if (response?.status === 200) {
          if (_continue) {
            await fetchData();
            onClickEdit(response.data);
          } else {
            onSuccess(response, 'Actualizado correctamente');
            onCancel();
            await fetchData();
          }
          setLoading(false);
          return response.data;
        }
      }
    } catch (err) {
      onError(err, setLoading, formInstances, setCurrentTabKey);
    }
    return null;
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      const [key] = selectedRowKeys;
      if (key) {
        const response = await API.delete(`${completeURI}${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          onCancel();
          setVisibleAlert(false);
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const requiredRule = {
    required: true,
    message: 'El campo es requerido',
  };

  const rules = {
    required: [requiredRule],
    clave: [
      requiredRule,
      {
        validator: async (rule, val) => {
          const value = val?.toString();
          const { unidad_operativa, unidad_responsable } = form.getFieldsValue();
          if (value) {
            if (unidad_operativa) {
              let { clave } = unidadesOperativas.find((e) => e.id === unidad_operativa) || {};
              clave = clave?.toString();
              if (value.length <= clave?.length) {
                throw new Error('La clave debe tener una longitud mayor a la clave de Unidad Operativa');
              } else if (value.substr(0, clave.length) !== clave) {
                throw new Error('La clave debe comenzar con la clave de la Unidad Operativa');
              }
            } else if (unidad_responsable) {
              let { clave } = unidadesResponsables.find((e) => e.id === unidad_responsable) || {};
              clave = clave?.toString();
              if (value.length <= clave?.length) {
                throw new Error('La clave debe tener una longitud mayor a la clave de Unidad Responsable');
              } else if (value.substr(0, clave.length) !== clave) {
                throw new Error('La clave debe comenzar con la clave de la Unidad Responsable');
              }
            }
          }
        },
      },
    ],
    correo_electronico: [
      {
        type: 'email',
        message: 'Ingrese un correo electrónico válido',
      },
    ],
    portal_web: [
      {
        type: 'url',
        message: 'Ingrese una URL válida',
      },
    ],
  };

  const columns = [
    {
      titleText: 'Clave',
      dataIndex: 'clave',
      key: 'clave',
      width: 100,
    },
    {
      titleText: 'Descripción',
      dataIndex: 'descripcion',
      key: 'descripcion',
      width: 250,
    },
    {
      titleText: 'Tipo de UR',
      dataIndex: 'tipo_de_ur',
      key: 'tipo_de_ur',
      width: 150,
      render: (val) => tipoDeUr.find((g) => g.id === val)?.nombre,
    },
    {
      titleText: 'Tipo de Clave UR',
      dataIndex: 'tipo_de_clave_de_ur',
      key: 'tipo_de_clave_de_ur',
      width: 180,
      render: (val) => tipoClaveUr.find((g) => g.id === val)?.nombre,
    },
    {
      titleText: 'Estado',
      dataIndex: 'estados_globales',
      key: 'estados_globales',
      width: 120,
      render: (val) => (estadosGlobales.find((g) => g.id === val)?.descripcion),
    },
  ];

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
  };

  const onClickAdd = () => {
    onCancel();
    if (savedConfig) {
      setVisible(true);
      setSelectedRowKeys([]);
      form.resetFields();
    } else {
      setVisibleConfig(true);
    }
  };

  const onClickDelete = () => {
    const [key] = selectedRowKeys;
    const match = data.find((i) => i.id === key);
    if (match.estados_globales < 4) {
      setVisibleAlert(true);
    } else {
      const status = match.estados_globales === 4 ? 'autorizados' : 'cancelados';
      message.warn(`No se pueden eliminar registros ${status}`);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const filterTreeNode = (input, node) => {
    const title = node.title?.props.children.toLowerCase() || node.title;
    if (title && title.includes(input.toLowerCase())) {
      return true;
    }
    if (node.children) {
      return filterTreeNode(input, node.children);
    }
    return false;
  };

  let timeoutFilter;

  const filterSelect = async (id, centroDeCosto = false) => {
    try {
      setLoading(true);
      const match = (centroDeCosto ? unidadesResponsables : unidadesOperativas)
        .find((u) => u.id === id);
      form.setFieldsValue({ clave: match.clave });
      filterCatalogs(match);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const getTitle = (pluralize = false) => {
    let output = '';
    switch (currentURI) {
      case 'unidades-operativas':
        output = pluralize ? 'Unidades Operativas' : 'Unidad Operativa';
        break;
      case 'unidades-responsables':
        output = pluralize ? 'Unidades Responsables' : 'Unidad Responsable';
        break;
      default:
        output = pluralize ? 'Centros de Costos' : 'Centro de Costos';
        break;
    }

    return output;
  };

  const setTimeoutFilter = (input, node) => setTimeout(() => filterTreeNode(input, node), 1000);

  const validateAuthorizable = (match = null) => {
    let parentMatch = null;
    if (currentURI !== 'unidades-operativas') {
      parentMatch = match.unidad_operativa
        ? unidadesOperativas.find((e) => e.id === match.unidad_operativa)
        : unidadesResponsables.find((e) => e.id === match.unidad_responsable);
    }

    if (parentMatch && parentMatch.estados_globales !== 4) {
      const parentType = match.unidad_operativa ? 'Operativa' : 'Responsable';
      const parentToStr = `${match.clave} - ${match.descripcion}`;
      message.warn(`La Unidad ${parentType} padre '${parentToStr}' no se encuentra autorizada`, 5);
      return false;
    }

    const {
      cog_interno,
      fuentes_de_financiamiento_interna,
      tipo_de_gasto,
    } = match;

    const fields = [
      {
        name: 'cog_interno',
        data: cog_interno,
        label: 'Clasificación de Objeto de Gasto Interno',
      },
      {
        name: 'fuentes_de_financiamiento_interna',
        data: fuentes_de_financiamiento_interna,
        label: 'Clasificación de Fuentes de Financiamiento Interno',
      },
      {
        name: 'tipo_de_gasto',
        data: tipo_de_gasto,
        label: 'Tipos de Gasto',
      },
    ];

    const invalidFields = fields.filter((e) => !e.data.length);
    const forms = formInstances.map((e) => {
      const errorFields = Object.keys(e.getFieldsValue())
        .filter((k) => invalidFields.some((i) => i.name === k));
      return {
        instance: e,
        errorFields,
      };
    });
    const formsWithErrors = forms.filter((e) => !!e.errorFields.length);
    if (formsWithErrors.length) {
      const [f] = formsWithErrors;
      setCurrentTabKey(f.instance.__INTERNAL__.name);
      setTimeout(() => {
        f.instance.scrollToField(f.errorFields[0]);
      });
    }

    if (invalidFields.length) {
      message.warn({
        content: (
          <Col>
            <strong>Para autorizar es requerido complete los siguientes campos:</strong>
            <br />
            <br />
            {invalidFields.map((item) => (
              <React.Fragment key={item.name}>
                <p style={{ margin: 0, display: 'block', textAlign: 'left' }}>
                  <strong style={{ marginRight: 3 }}>
                    {item.label}
                  </strong>
                </p>
              </React.Fragment>
            ))}
            <Button
              icon={<CloseCircleOutlined />}
              onClick={() => message.destroy()}
              type="link"
              style={{
                position: 'absolute',
                top: -30,
                right: -20,
              }}
            />
          </Col>
        ),
        duration: 5,
      });
    }
    return !invalidFields.length;
  };

  const showMsg = (tabKey) => {
    message.info({
      content: (
        <>
          <br />
          <Row style={{ width: '100%' }}>
            Los cambios que hayan sido realizados serán descartados.
          </Row>
          <Row align="middle" style={{ width: '100%' }}>
            ¿Desea continuar?
            <Button
              type="link"
              onClick={() => {
                setCurrentTabKey(tabKey);
                message.destroy();
                onClickEdit();
              }}
            >
              Si
            </Button>
            <Button
              type="link"
              onClick={() => message.destroy()}
            >
              No
            </Button>
          </Row>
        </>
      ),
      duration: 0,
    });
  };

  const onChangeTabKey = (key) => {
    const fromBasic = basicKeys.includes(currentTabKey);
    const toBasic = basicKeys.includes(key);
    const match = complexForms.find((e) => e.key === currentTabKey);
    if (!match) {
      if (fromBasic && toBasic) {
        setCurrentTabKey(key);
      } else if (fromBasic) {
        showMsg(key);
      } else {
        setCurrentTabKey(key);
      }
    } else {
      match.formInstance.onFinishHandler(key, true);
    }
  };

  const handleOnFinish = (vals, _continue = false) => {
    const match = complexForms.find((e) => e.key === currentTabKey);
    if (match) {
      match.formInstance.onFinishHandler(null, _continue);
    }
    return onFinish(null, _continue);
  };

  const filterTree = (input, node) => {
    clearTimeout(timeoutFilter);
    timeoutFilter = setTimeoutFilter(input, node);
    return timeoutFilter ? filterTreeNode(input, node) : true;
  };

  const getContentTypeFor = (uri) => {
    let nivel = null;
    switch (uri) {
      case 'unidades-operativas':
        nivel = 1;
        break;
      case 'unidades-responsables':
        nivel = 2;
        break;
      case 'centros-de-costos':
        nivel = 3;
        break;
      default:
        break;
    }
    return contentTypes.find((e) => e.nivel === nivel)?.id;
  };
  const authorizeAll = async () => {
    setLoading(true);
    const promises = data.map((e) => API.patch(`${completeURI}/${e.id}`, { estados_globales: 4 }));
    await Promise.all(promises);
    await fetchData();
    setLoading(false);
  };

  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible ? (
          <Table
            // allowImport
            baseURI={completeURI}
            cols={columns}
            permission={{ ...permission, export: true, import: true }}
            data={data}
            rowSelection={rowSelection}
            handleOnRowClick={handleOnRowClick}
            controls={{
              onClickAdd,
              onClickEdit,
              onClickDelete,
            }}
            customActions={[
              user?.email.includes('sigob') && {
                icon: <CheckCircleOutlined />,
                text: 'Autorizar Todos',
                onClick: authorizeAll,
                disabledBySelection: false,
              },
            ]}
            disabled={!mutable}
            allowedExtensions={['xlsx', 'csv', 'json']}
          />
        ) : (
          <Card
            className="form-container"
            title={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} ${getTitle()}`}
            extra={(
              <FormSubmitControls
                onFinish={basicKeys
                  .concat(complexForms.map((e) => e.key))
                  .includes(currentTabKey)
                  ? handleOnFinish : null}
                onCancel={onCancel}
                allowSaveAndContinue
                allowAuthorize
                allowCancel
                mutable={mutable}
                baseURI={completeURI}
                selectedRowKeys={selectedRowKeys}
                validateAuthorizable={validateAuthorizable}
                callback={(estados_globales) => {
                  const [key] = selectedRowKeys;
                  const normalized = [...data]
                    .map((e) => (e.id === key ? { ...e, estados_globales } : e));
                  setMutable(false);
                  setData(normalized);
                  const match = normalized.find((e) => e.id === key);
                  onClickEdit(match);
                }}
              />
            )}
            bordered={false}
            bodyStyle={{ padding: 5, overflow: 'hidden' }}
            style={{ overflow: 'scroll !important', height: '100% !important' }}
          >
            <Tabs
              onChange={mutable ? onChangeTabKey : setCurrentTabKey}
              activeKey={currentTabKey}
              type="card"
            >
              <TabPane tab="General" key="general" forceRender>
                <Form
                  layout="vertical"
                  form={form}
                  name="general"
                  onFinish={mutable ? onFinish : null}
                  scrollToFirstError
                  initialValues={{ estados_globales: 1 }}
                >
                  <Row gutter={10}>
                    {currentURI === 'unidades-responsables' && (
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="unidad_operativa"
                          rules={rules.required}
                          label="Unidad Operativa"
                          hasFeedback
                        >
                          <Select
                            onChange={(value) => filterSelect(value)}
                            dataSource={unidadesOperativas}
                            keyLabelRender
                            disabled={!mutable || !!selectedRowKeys.length}
                          />
                        </Form.Item>
                      </Col>
                    )}

                    {currentURI === 'centros-de-costos' && (
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="unidad_responsable"
                          rules={rules.required}
                          label="Unidad Responsable"
                          hasFeedback
                        >
                          <Select
                            onChange={(value) => filterSelect(value, true)}
                            dataSource={unidadesResponsables}
                            disabled={!mutable || !!selectedRowKeys.length}
                          />
                        </Form.Item>
                      </Col>
                    )}

                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="clave"
                        rules={rules.required}
                        label="Clave"
                        hasFeedback
                        normalize={(val) => val?.toUpperCase()}
                      >
                        <Input
                          className="upper"
                          disabled={!mutable}
                          allowClear
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="descripcion"
                        rules={rules.required}
                        label="Descripción"
                        hasFeedback
                      >
                        <Input allowClear disabled={!mutable} />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="correo_electronico"
                        rules={rules.correo_electronico}
                        label="Correo Electrónico"
                        hasFeedback
                      >
                        <Input allowClear disabled={!mutable} />
                      </Form.Item>
                    </Col>

                    <LadaNumero
                      form={form}
                      disabled={!mutable}
                    />

                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="portal_web"
                        rules={rules.portal_web}
                        label="Portal Web"
                        hasFeedback
                      >
                        <Input allowClear disabled={!mutable} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="estados_globales"
                        label="Estado"
                        hasFeedback
                      >
                        <Select dataSource={estadosGlobales} disabled />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item hidden>
                    <Button htmlType="submit" />
                  </Form.Item>
                </Form>
              </TabPane>

              <TabPane tab="Configuración Presupuestal" key="configEntidad" forceRender>
                <Form
                  layout="vertical"
                  name="configEntidad"
                  form={formConfigEntidad}
                  onFinish={onFinish}
                  scrollToFirstError
                  initialValues={{ es_UR_presupuestal_de_ingreso: false }}
                >
                  <Row gutter={10}>
                    <Col
                      xs={24}
                      sm={24}
                      md={12}
                      lg={collapsedSidebar ? 8 : 12}
                      xl={8}
                    >
                      <Form.Item
                        name="es_UR_presupuestal_de_ingreso"
                        rules={rules.required}
                        label="¿Es UR Presupuestal de Ingreso?"
                        hasFeedback
                      >
                        <Select
                          onChange={(val) => setEsUrPresupuestal(!!val)}
                          disabled={!mutable}
                          trueFalse
                        />
                      </Form.Item>
                    </Col>
                    {esUrPresupuestal && (
                      <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={collapsedSidebar ? 8 : 12}
                        xl={8}
                      >
                        <Form.Item
                          name="config_de_seg_pres_ingresos"
                          rules={rules.required}
                          label="Configuración Presupuestal Ingresos"
                          hasFeedback
                        >
                          <Select
                            dataSource={configSegmentoPresupuestalIngresos}
                            disabled={!mutable}
                            render={(item) => `${item.identificador}-${tiposPresupuestos
                              ?.find((e) => e.id === item.tipo_de_presupuesto)
                              ?.nombre}`}
                          />
                        </Form.Item>
                      </Col>
                    )}
                    <Col
                      xs={24}
                      sm={24}
                      md={12}
                      lg={collapsedSidebar ? 8 : 12}
                      xl={8}
                    >
                      <Form.Item
                        name="config_de_seg_pres_egresos"
                        rules={rules.required}
                        label="Configuración Presupuestal Egresos"
                        hasFeedback
                      >
                        <Select
                          dataSource={configSegmentoPresupuestalEgresos}
                          disabled={!mutable}
                          render={(item) => `${item.identificador}-${tiposPresupuestos
                            ?.find((e) => e.id === item.tipo_de_presupuesto)
                            ?.nombre}`}
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={12} xl={8}>
                      <Form.Item
                        name="clasificacion_administrativa"
                        label="Clasificación Administrativa"
                        rules={rules.required}
                        hasFeedback
                      >
                        <TreeSelect
                          showSearch
                          showArrow
                          multiple
                          treeNodeFilterProp="title"
                          style={{ width: '100%' }}
                          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                          treeData={filteredAdministrativas}
                          filterTreeNode={(input, node) => {
                            clearTimeout(timeoutFilter);
                            timeoutFilter = setTimeoutFilter(input, node);
                            return timeoutFilter ? filterTreeNode(input, node) : true;
                          }}
                          // disabled={!mutable || currentURI !== 'unidades-operativas'}
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={12} xl={8}>
                      <Form.Item
                        name="tipo_de_ur"
                        rules={rules.required}
                        label="Tipo de UR"
                        hasFeedback
                      >
                        <Select
                          disabled={!mutable}
                          labelProp="nombre"
                          dataSource={tipoDeUr}
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={12} xl={8}>
                      <Form.Item
                        name="tipo_de_clave_de_ur"
                        rules={rules.required}
                        label="Tipo de Clave de UR"
                        hasFeedback
                      >
                        <Select
                          disabled={!mutable}
                          labelProp="nombre"
                          dataSource={tipoClaveUr}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="funciones_presupuestales"
                        label="Función Presupuestal"
                        hasFeedback
                      >
                        <Select
                          dataSource={funcionesPresupuestales}
                          disabled={!mutable}
                          labelProp="nombre"
                          mode="multiple"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} xl={8}>
                      <Form.Item
                        name="clasificacion_programatica"
                        label="Clasificación Programática"
                        rules={rules.required}
                        hasFeedback
                      >
                        <TreeSelect
                          showSearch
                          multiple
                          showArrow
                          treeNodeFilterProp="title"
                          style={{ width: '100%' }}
                          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                          treeData={filteredProgramatica}
                          filterTreeNode={filterTree}
                          disabled={!mutable}
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={12} xl={8}>
                      <Form.Item
                        name="clasificacion_funcional"
                        label="Clasificación Funcional"
                        rules={rules.required}
                        hasFeedback
                      >
                        <TreeSelect
                          showSearch
                          showArrow
                          multiple
                          treeNodeFilterProp="title"
                          style={{ width: '100%' }}
                          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                          treeData={filteredFuncionales}
                          filterTreeNode={(input, node) => {
                            clearTimeout(timeoutFilter);
                            timeoutFilter = setTimeoutFilter(input, node);
                            return timeoutFilter ? filterTreeNode(input, node) : true;
                          }}
                          // disabled={!mutable || currentURI !== 'unidades-operativas'}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item hidden>
                    <Button htmlType="submit" />
                  </Form.Item>
                </Form>
              </TabPane>

              <TabPane tab="Elementos Presupuestales" key="configPresupuestos" forceRender>
                <Form
                  layout="vertical"
                  name="configPresupuestos"
                  form={formConfigPresupuestal}
                  onFinish={onFinish}
                  scrollToFirstError
                  initialValues={{
                    cog_interno: [],
                    fuentes_de_financiamiento_interna: [],
                    programa: [],
                    tipo_de_gasto: [],
                  }}
                >
                  <Row gutter={10}>
                    <Transfer
                      dataSource={clasesCRI}
                      filterTreeNode={filterTree}
                      label="Clase CRI"
                      formItemName="clase_CRI"
                      form={formConfigPresupuestal}
                      disabled={!mutable}
                      rules={rules.required}
                      nested
                      mixed
                    />

                    <Transfer
                      dataSource={filteredCOGInterno}
                      treeData={filteredCOGInterno}
                      filterTreeNode={filterTree}
                      label="Clasificación de Objeto de Gasto Interno"
                      formItemName="cog_interno"
                      form={formConfigPresupuestal}
                      disabled={!mutable}
                      nested
                      mixed
                    />

                    <Transfer
                      dataSource={filteredClasificacionesFF}
                      treeData={filteredClasificacionesFF}
                      filterTreeNode={filterTree}
                      label="Clasificación de Fuentes de Financiamiento Interno"
                      formItemName="fuentes_de_financiamiento_interna"
                      form={formConfigPresupuestal}
                      disabled={!mutable}
                      nested
                      mixed
                    />

                    <Transfer
                      dataSource={filteredTiposDeGasto}
                      filterTreeNode={filterTree}
                      label="Tipos de Gasto"
                      formItemName="tipo_de_gasto"
                      form={formConfigPresupuestal}
                      disabled={!mutable}
                    />
                  </Row>
                  <Form.Item hidden>
                    <Button htmlType="submit" />
                  </Form.Item>
                </Form>
              </TabPane>
              <TabPane tab="Configuración Familias" key="configFamilias" forceRender>
                <Form
                  layout="vertical"
                  name="configFamilias"
                  form={formConfigFamilias}
                  onFinish={onFinish}
                  scrollToFirstError
                  initialValues={{
                    cog_interno_hijas: [],
                    fuentes_de_financiamiento_interna: [],
                    programa: [],
                    tipo_de_gasto: [],
                  }}
                >
                  <Row gutter={10}>
                    <Transfer
                      dataSource={clasesCRI}
                      filterTreeNode={filterTree}
                      label="Clase CRI Hijas"
                      formItemName="clase_cri_hijas"
                      form={formConfigFamilias}
                      disabled={!mutable}
                      nested
                      mixed
                    />

                    <Transfer
                      dataSource={filteredCOGInterno}
                      treeData={filteredCOGInterno}
                      filterTreeNode={filterTree}
                      label="Clasificación de Objeto de Gasto Interno Hijas"
                      formItemName="cog_interno_aplicable_para_hijas"
                      form={formConfigFamilias}
                      disabled={!mutable}
                      nested
                      // mixed
                    />

                    <Transfer
                      dataSource={filteredClasificacionesFF}
                      treeData={filteredClasificacionesFF}
                      filterTreeNode={filterTree}
                      label="Clasificación de Fuentes de Financiamiento Hijas"
                      formItemName="fuentes_de_financiamiento_hijas"
                      form={formConfigFamilias}
                      disabled={!mutable}
                      nested
                      mixed
                    />

                    <Transfer
                      dataSource={filteredTiposDeGasto}
                      filterTreeNode={filterTree}
                      label="Tipos de Gasto Hijas"
                      formItemName="tipo_de_gasto_hijas"
                      form={formConfigFamilias}
                      disabled={!mutable}
                    />
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="funciones_presupuestales_hijas"
                        label="Función Presupuestal"
                        hasFeedback
                      >
                        <Select
                          dataSource={funcionesPresupuestales}
                          disabled={!mutable}
                          labelProp="nombre"
                          mode="multiple"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} xl={8}>
                      <Form.Item
                        name="clasificacion_funcional_hijas"
                        label="Clasificación Funcional"
                        hasFeedback
                      >
                        <TreeSelect
                          showSearch
                          showArrow
                          multiple
                          treeNodeFilterProp="title"
                          style={{ width: '100%' }}
                          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                          treeData={filteredFuncionales}
                          filterTreeNode={(input, node) => {
                            clearTimeout(timeoutFilter);
                            timeoutFilter = setTimeoutFilter(input, node);
                            return timeoutFilter ? filterTreeNode(input, node) : true;
                          }}
                          // disabled={!mutable || currentURI !== 'unidades-operativas'}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} xl={8}>
                      <Form.Item
                        name="clasificacion_administrativa_hijas"
                        label="Clasificación Administrativa Hijas"
                        rules={rules.required}
                        hasFeedback
                      >
                        <TreeSelect
                          showSearch
                          showArrow
                          multiple
                          treeNodeFilterProp="title"
                          style={{ width: '100%' }}
                          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                          treeData={filteredAdministrativas}
                          filterTreeNode={(input, node) => {
                            clearTimeout(timeoutFilter);
                            timeoutFilter = setTimeoutFilter(input, node);
                            return timeoutFilter ? filterTreeNode(input, node) : true;
                          }}
                          // disabled={!mutable || currentURI !== 'unidades-operativas'}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item hidden>
                    <Button htmlType="submit" />
                  </Form.Item>
                </Form>
              </TabPane>

              <TabPane
                tab="Programas y Proyectos"
                key="programasProyectos"
                forceRender
                disabled={!selectedRowKeys.length}
              >
                <Programas
                  authorizedUr={isAuthorized(data.find((e) => e.id === selectedRowKeys[0]))}
                  contentType={getContentTypeFor(currentURI)}
                  objectId={selectedRowKeys[0]}
                  cogInterno={normalizeTreeData(filterArbolado(
                    formConfigPresupuestal.getFieldValue('cog_interno'),
                    JSON.parse(JSON.stringify(clasificacionesGastoInterno)),
                    { mixed: true },
                  ), false)}
                  tiposDeGasto={tiposDeGasto
                    .filter((e) => formConfigPresupuestal
                      .getFieldValue('tipo_de_gasto')
                      ?.some((i) => i.toString() === e.clave.toString()))}
                  clasificacionesProgramatica={normalizeTreeData(filterArbolado(
                    formConfigPresupuestal.getFieldValue('clasificacion_programatica'),
                    JSON.parse(JSON.stringify(programatica)),
                  ), false)}
                  fuentesDeFinanciamientoInterno={normalizeTreeData(filterArbolado(
                    formConfigPresupuestal.getFieldValue('fuentes_de_financiamiento_interna'),
                    JSON.parse(JSON.stringify(fuentesDeFinanciamiento)),
                    { mixed: true },
                  ), false)}
                />
              </TabPane>

              <TabPane
                tab="Dirección"
                key="direccion"
                forceRender
                disabled={!selectedRowKeys.length}
              >
                <Direccion
                  currentURI={completeURI}
                  parentID={selectedRowKeys[0]}
                  setLoading={setLoading}
                  setFormAddress={setFormAddress}
                  setCurrentTabKey={setCurrentTabKey}
                  addressPropName="direccion"
                  callback={(values, _continue) => {
                    if (!_continue) {
                      onCancel();
                    } else {
                      const [key] = selectedRowKeys;
                      const clone = [...data];
                      const mappedData = clone.map((e) => (e.id === key
                        ? ({ ...e, direccion: values?.id }) : e));
                      setData([...mappedData]);
                    }
                  }}
                  mutable={mutable}
                />
              </TabPane>

              <TabPane
                tab="Misión y Visión"
                key="misionVision"
                forceRender
              >
                <Form
                  layout="vertical"
                  name="misionVision"
                  form={formMisionVision}
                  onFinish={onFinish}
                  scrollToFirstError
                  initialValues={{ es_UR_presupuestal_de_ingreso: false }}
                >
                  <Row gutter={10}>
                    <Col span={12}>
                      <Form.Item
                        name="mision"
                        label="Misión"
                      >
                        <Input.TextArea autoSize={{ minRows: 3, maxRows: 3 }} />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="vision"
                        label="Visión"
                      >
                        <Input.TextArea autoSize={{ minRows: 3, maxRows: 3 }} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </TabPane>
            </Tabs>
          </Card>
        )}
        <ModalConfig
          visible={visibleConfig}
          setVisible={setVisibleConfig}
          setSavedConfig={setSavedConfig}
          normalizeTreeData={normalizeTreeData}
          filterTreeNode={filterTree}
        />
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => setVisibleAlert(false)}
          visible={visibleAlert}
          content={`${getTitle()} ${form.getFieldValue('descripcion')}`}
          loading={loading}
        />
      </Spin>
    </Row>
  );
};

export default UnidadesResponsables;
