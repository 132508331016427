/* eslint-disable no-plusplus */
/**
 * Returns dataset as three.
 *
 * Time Complexity: O(n)
 * @param source
 * @returns
 */
const makeTree = (source, param = 'clave_padre_id') => {
  const data = [...source].sort((a, b) => a.id - b.id);
  const parents = [];
  const children = {};

  for (let i = 0; i < data.length; i++) {
    if (!data[i][param]) {
      parents.push(data[i]);
    }

    data[i].children = [];

    if (data[i][param]) {
      if (children[data[i][param]]) {
        children[data[i][param]].push(data[i]);
      } else {
        children[data[i][param]] = [data[i]];
      }
    }
  }

  for (let i = 0; i < data.length; i++) {
    if (children[data[i].id]) {
      data[i].children = children[data[i].id];
    }
  }

  return parents;
};

export default makeTree;
