export default [
  {
    id: 1,
    fecha: '13/8/2021',
    folio_pago: 'TR-2021-07-0273',
    folio_agrupador: 'FA-2021-0004',
    folio_cxp: 'CP-2021-07-0203',
    beneficiario: 'La Nacional,SA de CV',
    monto: 27458.36,
    banco: 'Bancomer',
    cuenta_bancaria: '192837465',
    estados_globales: 4,
    folio_presupuestal: 'FP-2021-0732',
    forma_de_pago: 'Transferencia',
    no_transferencia: '564738',
    data_table: [
      {
        cog: '21101',
        descripcion: 'Papelería y Útiles de oficina',
        id: 1,
        iva: 1656.48,
        partida_presupuestal: '2021-1-311111-504-M-152-53-001-155-21101-1-11721',
        subtotal: 10353.00,
        total: 12009.48,
      },
      {
        cog: '21101',
        descripcion: 'Papelería y Útiles de oficina',
        id: 2,
        iva: 1074.24,
        partida_presupuestal: '2021-1-311111-510-M-152-83-001-155-21101-1-11721',
        subtotal: 6714.00,
        total: 7788.24,
      },
      {
        cog: '21101',
        descripcion: 'Papelería y Útiles de oficina',
        id: 3,
        iva: 1056.64,
        partida_presupuestal: '2021-1-311111-504-M-152-53-001-155-21101-1-11721',
        subtotal: 6604.00,
        total: 7660.64,
      },
    ],
  },
  {
    id: 2,
    fecha: '14/9/2021',
    folio_pago: 'TR-2021-08-0902',
    folio_agrupador: 'FA-2021-0018',
    folio_cxp: 'CP-2021-08-0064',
    beneficiario: 'La Vanguardista, SA de CV',
    monto: 389038.45,
    banco: 'Bancomer',
    cuenta_bancaria: '192837465',
    estados_globales: 4,
    folio_presupuestal: 'FP-2021-0891',
    forma_de_pago: 'Transferencia',
    no_transferencia: '987496',
    data_table: [
      {
        cog: '21101',
        descripcion: 'Papelería y Útiles de oficina',
        id: 1,
        iva: 1656.48,
        partida_presupuestal: '2021-1-311111-504-M-152-53-001-155-21101-1-11721',
        subtotal: 10353.00,
        total: 12009.48,
      },
      {
        cog: '21101',
        descripcion: 'Papelería y Útiles de oficina',
        id: 2,
        iva: 1074.24,
        partida_presupuestal: '2021-1-311111-510-M-152-83-001-155-21101-1-11721',
        subtotal: 6714.00,
        total: 7788.24,
      },
      {
        cog: '21101',
        descripcion: 'Papelería y Útiles de oficina',
        id: 3,
        iva: 1056.64,
        partida_presupuestal: '2021-1-311111-504-M-152-53-001-155-21101-1-11721',
        subtotal: 6604.00,
        total: 7660.64,
      },
    ],
  },
  {
    id: 3,
    fecha: '30/9/2021',
    folio_pago: 'TR-2021-09-0521',
    folio_agrupador: 'FA-2021-0022',
    folio_cxp: 'CP-2021-08-0345',
    beneficiario: 'Integradora, SA de CV',
    monto: 189352.90,
    banco: 'Bancomer',
    cuenta_bancaria: '192837465',
    estados_globales: 4,
    folio_presupuestal: 'FP-2021-0992',
    forma_de_pago: 'Transferencia',
    no_transferencia: '794582',
    data_table: [
      {
        cog: '21101',
        descripcion: 'Papelería y Útiles de oficina',
        id: 1,
        iva: 1656.48,
        partida_presupuestal: '2021-1-311111-504-M-152-53-001-155-21101-1-11721',
        subtotal: 10353.00,
        total: 12009.48,
      },
      {
        cog: '21101',
        descripcion: 'Papelería y Útiles de oficina',
        id: 2,
        iva: 1074.24,
        partida_presupuestal: '2021-1-311111-510-M-152-83-001-155-21101-1-11721',
        subtotal: 6714.00,
        total: 7788.24,
      },
      {
        cog: '21101',
        descripcion: 'Papelería y Útiles de oficina',
        id: 3,
        iva: 1056.64,
        partida_presupuestal: '2021-1-311111-504-M-152-53-001-155-21101-1-11721',
        subtotal: 6604.00,
        total: 7660.64,
      },
    ],
  },
];
