/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Form,
  Input,
  Row,
  Col,
  Spin,
  // Card,
  Modal,
  Button,
  Typography,
} from 'antd';
import Table from 'components/Table';
import FormSubmitControls from 'components/FormSubmitControls';
import ModalDelete from 'components/ModalDelete';
import Select from 'components/Select';
import NumericInput from 'components/NumericInput';
import API from 'utils/api';
import createKey from 'utils/keyGenerator';
import { onError, onSuccess } from 'utils/handlers';
import Proyectos from './Proyectos';

const baseURI = 'contabilidad/programas/';

function Programas({
  contentType: content_type,
  objectId: object_id,
  authorizedUr,
  cogInterno,
  tiposDeGasto,
  clasificacionesProgramatica,
  fuentesDeFinanciamientoInterno,
}) {
  const { primaryText } = useSelector(({ app }) => app.theme);
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  const periodoFiscalSelected = useSelector(({ auth }) => auth.periodoFiscalSelected);
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      setLoading(true);
      if (content_type && object_id) {
        const res = await API.get(baseURI, {
          params: {
            content_type_ur: content_type,
            object_id_ur: object_id,
            periodo_fiscal: periodoFiscalSelected?.id,
          },
        });
        setData(res.data);
      } else {
        setData([]);
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchData();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, [content_type, object_id, periodoFiscalSelected]);

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    setSelectedRowKeys([]);
  };

  const onFinish = async (values) => {
    try {
      setLoading(true);
      const _values = {
        ...values,
        content_type_ur: content_type,
        object_id_ur: object_id,
        periodo_fiscal: periodoFiscalSelected?.id,
        anio_de_aplicacion: periodoFiscalSelected?.periodo,
      };
      if (!selectedRowKeys.length) {
        const response = await API.post(baseURI, _values);
        if (response?.status === 201) {
          onSuccess(response, 'Agregado correctamente');
          onCancel();
          await fetchData();
        }
      } else {
        const [key] = selectedRowKeys;
        const response = await API.put(`${baseURI}${key}/`, values);
        if (response?.status === 200) {
          onSuccess(response, 'Actualizado correctamente');
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`${baseURI}${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          setVisibleAlert(false);
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    setTimeout(() => form.setFieldsValue(record));
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickAdd = () => {
    onCancel();
    createKey(data, form);
    setVisible(true);
  };

  const onClickEdit = () => {
    setVisible(true);
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const columns = [
    {
      titleText: 'Clave',
      dataIndex: 'clave',
      key: 'clave',
      width: 100,
    },
    {
      titleText: 'Nombre',
      dataIndex: 'nombre',
      key: 'nombre',
      width: 200,
    },
    {
      titleText: 'Estado',
      dataIndex: 'estados_globales',
      key: 'estados_globales',
      width: 150,
      render: (val) => estadosGlobales.find((item) => item.id === val)?.descripcion,
    },
  ];

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
  };

  function Formulario({ modal }) {
    let props;
    if (modal) {
      props = {
        span: 24,
      };
    } else {
      props = {
        xs: 24,
        sm: 24,
        md: 8,
      };
    }
    return (
      <>
        <Form
          name={`${modal ? 'modal_' : ''}programa`}
          layout="vertical"
          form={form}
          onFinish={() => onFinish(form.getFieldsValue())}
          scrollToFirstError
          initialValues={{ estados_globales: 1 }}
        >
          <Row gutter={10}>
            <Col {...props}>
              <NumericInput
                name="clave"
                label="Clave"
                required
                disabled={!!selectedRowKeys.length}
                maxLength={10}
              />
            </Col>
            <Col {...props}>
              <Form.Item
                name="nombre"
                label="Nombre"
                rules={rules.required}
                hasFeedback
              >
                <Input />
              </Form.Item>
            </Col>
            <Col {...props}>
              <Form.Item
                name="estados_globales"
                label="Estado"
                rules={rules.required}
              >
                <Select disabled={!selectedRowKeys.length} dataSource={estadosGlobales} />
              </Form.Item>
            </Col>
            <Form.Item hidden>
              <Button htmlType="submit" />
            </Form.Item>
          </Row>
        </Form>
        {!modal && (
          <>
            <Typography.Title level={4}>Proyecto</Typography.Title>
            <Proyectos
              programa={selectedRowKeys[0]}
              contentType={content_type}
              objectId={object_id}
              cogInterno={cogInterno}
              tiposDeGasto={tiposDeGasto}
              clasificacionesProgramatica={clasificacionesProgramatica}
              fuentesDeFinanciamientoInterno={fuentesDeFinanciamientoInterno}
            />
          </>
        )}
      </>
    );
  }

  Formulario.propTypes = {
    modal: PropTypes.bool,
  };

  Formulario.defaultProps = {
    modal: false,
  };

  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {(!(visible && selectedRowKeys.length) || (visible && !authorizedUr)) && (
          <Table
            allowImport
            baseURI={baseURI}
            cols={columns}
            data={data}
            rowSelection={rowSelection}
            handleOnRowClick={handleOnRowClick}
            controls={{
              onClickAdd,
              onClickEdit,
              onClickDelete,
            }}
          />
        )}
        {(visible && !!selectedRowKeys.length && authorizedUr) && (
          <Row style={{ height: '100%' }}>
            <Col span={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span style={{ color: primaryText, fontSize: 16, paddingInlineStart: 15 }}>
                {selectedRowKeys.length ? 'Editar' : 'Agregar'}
                {' '}
                Programa
              </span>
              <FormSubmitControls
                onFinish={() => onFinish(form.getFieldsValue())}
                onCancel={onCancel}
                loading={loading}
              />
            </Col>
            <Col span={24}>
              <Formulario />
            </Col>
          </Row>
        )}
      </Spin>
      <Modal
        visible={(visible && !selectedRowKeys.length) || (visible && !authorizedUr)}
        title={(
          <FormSubmitControls
            label={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Programa`}
            onFinish={() => onFinish(form.getFieldsValue())}
            onCancel={onCancel}
            loading={loading}
          />
        )}
        closable={false}
        maskClosable={!loading}
        keyboard={!loading}
        onCancel={onCancel}
        footer={null}
        forceRender
      >
        <Formulario modal />
      </Modal>
      <ModalDelete
        onDelete={deleteItem}
        onCancel={() => setVisibleAlert(false)}
        visible={visibleAlert}
        content={`Programa ${form.getFieldValue('nombre')}`}
        loading={loading}
      />
    </Row>
  );
}

Programas.propTypes = {
  contentType: PropTypes.number,
  objectId: PropTypes.number,
  authorizedUr: PropTypes.bool,
  cogInterno: PropTypes.arrayOf(PropTypes.object),
  fuentesDeFinanciamientoInterno: PropTypes.arrayOf(PropTypes.object),
  tiposDeGasto: PropTypes.arrayOf(PropTypes.object),
  clasificacionesProgramatica: PropTypes.arrayOf(PropTypes.object),
};

Programas.defaultProps = {
  contentType: null,
  objectId: null,
  authorizedUr: false,
  cogInterno: [],
  fuentesDeFinanciamientoInterno: [],
  tiposDeGasto: [],
  clasificacionesProgramatica: [],
};

export default Programas;
