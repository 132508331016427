import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Form,
  Button,
  Row,
  Col,
  Spin,
  Card,
  Typography,
  Input,
  DatePicker,
  TreeSelect,
} from 'antd';
import { onError } from 'utils/handlers';
import Table from 'components/Table';
import FormSubmitControls from 'components/FormSubmitControls';
import FormItemLabel from 'components/FormItemLabel';
import { createDecimalRegExp } from 'utils/patterns';
import ModalDelete from 'components/ModalDelete';
import NumericInput from 'components/NumericInput';
import Select from 'components/Select';
import getFormatedValues, { formatReceived } from 'utils/formatValues';
import currencyFormater from 'utils/currencyFormatter';
import makeTree from 'utils/makeTree';
import { getImpuestos } from 'api/configuracion';
import { getCUCOPS } from 'api/almacen';
import {
  deleteServicio,
  patchServicio,
  postServicio,
  getServicios,
  baseURI,
} from 'api/configuracion/servicio';
import { getCOGs } from 'api/contabilidad/cog';

const { Text } = Typography;

export const permissionServicios = {
  permissionModel: 'servicio',
};

// eslint-disable-next-line react/prop-types
function Servicios({ permission }) {
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  const [decimal] = createDecimalRegExp(2);
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);
  const [cucop, setCucop] = useState([]);
  const [impuestos, setImpuestos] = useState([]);
  const [cog, setCog] = useState({});
  const [flatCOGS, setFlatCOGS] = useState([]);

  const fetchData = async () => {
    setLoading(true);
    const _data = await getServicios();
    setData(_data);
    setLoading(false);
  };

  const normalizeCog = (_data = []) => _data.map((item) => {
    const clone = { ...item };
    const children = clone.children?.length && typeof clone.id === 'string'
      ? normalizeCog(clone.children) : [];
    if (!children.length || typeof clone.id === 'number') {
      delete clone.children;
      return ({
        ...clone,
        key: clone.clave,
        clave: clone.clave,
        disabled: !clone.seleccionable,
      });
    }
    return ({
      ...clone,
      key: clone.clave,
      children,
      disabled: !clone.seleccionable,
    });
  });

  const normalizeData = (_data, prop = 'concepto', nested = true) => {
    if (_data?.length) {
      const output = _data.map((item) => {
        const clone = { ...item };
        const children = normalizeData(clone.children, prop);
        const identificator = nested
          ? `${clone.id.toString()}${item.seleccionable ? '' : '_parent'}` : clone.id;
        delete clone.children;
        if (!children.length) {
          return ({
            ...clone,
            value: parseInt(identificator, 10),
            title: `${item.clave} - ${item[prop]}`,
            key: parseInt(identificator, 10),
            selectable: item.seleccionable,
            checkable: item.seleccionable,
          });
        }
        return ({
          ...clone,
          value: identificator,
          title: `${item.clave} - ${item[prop]}`,
          children,
          disabled: true,
          selectable: item.seleccionable,
          checkable: item.seleccionable,
          key: identificator,
        });
      });
      return output;
    }
    return [];
  };

  useEffect(() => {
    let mounted = true;
    const fetchAll = async () => {
      setLoading(true);
      const _impuestos = await getImpuestos();
      const _cucop = await getCUCOPS();
      const _cogs = await getCOGs();
      const tree = makeTree(_cogs.map((e) => (!e.seleccionable ? ({
        ...e,
        id: `${e.id}`,
      }) : e)).sort((a, b) => a.id - b.id));
      const _data = await getServicios();
      if (mounted) {
        setImpuestos(_impuestos);
        setCucop(_cucop);
        setCog(normalizeData(normalizeCog(tree)));
        setFlatCOGS(_cogs);
        setData(_data);
        setLoading(false);
      }
    };
    fetchAll();
    return () => { mounted = false; };
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    setSelectedRowKeys([]);
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = {
        ...getFormatedValues(form.getFieldsValue()),
        cog: cog.id,
      };
      const [key] = selectedRowKeys;
      const saved = await (key ? patchServicio(key, values) : postServicio(values));
      if (saved) {
        await fetchData();
        onCancel();
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const deleteItem = async () => {
    setLoading(true);
    const [key] = selectedRowKeys;
    const deleted = await deleteServicio(key);
    if (deleted) {
      await fetchData();
    }
    setLoading(false);
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    setTimeout(() => form.setFieldsValue(formatReceived({ ...record })));
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const onClickEdit = async () => {
    setVisible(true);
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const onClickExport = () => { };

  const columns = [
    {
      titleText: 'Clave',
      dataIndex: 'clave',
      key: 'clave',
      width: 100,
    },
    {
      titleText: 'Descripción',
      dataIndex: 'descripcion',
      key: 'descripcion',
      width: 200,
    },
    {
      titleText: 'COG',
      dataIndex: 'cog',
      key: 'cog',
      render: (val) => flatCOGS.find((e) => e.id === val)?.clave,
      width: 100,
    },
    {
      titleText: 'Último Precio de Compra',
      dataIndex: 'precio_de_la_ultima_compra',
      key: 'precio_de_la_ultima_compra',
      render: (val) => currencyFormater(val),
      width: 200,
      align: 'right',
    },
  ];

  const filterTreeNode = (input, node) => {
    const title = node.title?.props.children.toLowerCase() || node.title;
    if (title && title.includes(input.toLowerCase())) {
      return true;
    }
    if (node.children) {
      return filterTreeNode(input, node.children);
    }
    return false;
  };

  let timeoutFilter;

  const setTimeoutFilter = (input, node) => setTimeout(() => filterTreeNode(input, node), 1000);

  const filterTree = (input, node) => {
    clearTimeout(timeoutFilter);
    timeoutFilter = setTimeoutFilter(input, node);
    return timeoutFilter ? filterTreeNode(input, node) : true;
  };

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
    clave: [
      {
        required: true,
        message: 'El campo es requerido',
      },
      {
        validator: async (rule, val) => {
          const clone = [...data];
          const match = clone.find((e) => e.clave === val);
          if (match && !selectedRowKeys.length) {
            throw new Error('La clave ya se ha dado de alta');
          }
        },
      },
    ],
    precio: [
      {
        required: true,
        message: 'El campo es requerido',
      },
      {
        validator: async (rule, val) => {
          if (val && !decimal.test(val)) {
            throw new Error('Ingrese un valor decimal válido');
          }
        },
      },
    ],
  };

  return (
    <Row align="center" justify="center" className="container">
      <Col span={24}>
        <Spin tip="Cargando..." spinning={loading}>
          {!visible ? (
            <Table
              cols={columns}
              data={data}
              rowSelection={rowSelection}
              handleOnRowClick={handleOnRowClick}
              controls={{
                onClickAdd,
                onClickEdit,
                onClickDelete,
                onClickExport,
              }}
              rowKey="id"
              baseURI={baseURI}
              permission={permission}
              allowImport
            />
          ) : (
            <Col span={24} style={{ height: '100%' }}>
              <Card
                className="form-container"
                title={(
                  <FormSubmitControls
                    label={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Servicio`}
                    onFinish={() => onFinish()}
                    onCancel={onCancel}
                    permission={permission}
                    allo
                  />
                )}
                bordered={false}
              >
                <Form
                  layout="vertical"
                  form={form}
                  onFinish={() => onFinish()}
                  initialValues={{ estados_globales: 1 }}
                  scrollToFirstError
                >
                  <Row gutter={10}>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item
                        name="clave"
                        rules={rules.clave}
                        label="Clave"
                      >
                        <Input autoComplete="off" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item
                        name="cog"
                        rules={rules.required}
                        label={(
                          <FormItemLabel
                            longTitle="Clasificador por Objeto del Gasto"
                            shortTitle="COG"
                            hideOn={['sm', 'md']}
                          />
                        )}
                      >
                        <TreeSelect
                          showSearch
                          showArrow
                          treeNodeFilterProp="title"
                          style={{ width: '100%' }}
                          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                          treeData={cog}
                          filterTreeNode={filterTree}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={10}>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item
                        name="descripcion"
                        rules={rules.required}
                        label="Descripción"
                      >
                        <Input.TextArea autoSize={{ minRows: 4, maxRows: 4 }} allowClear />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item
                        name="observaciones"
                        label="Observaciones"
                      >
                        <Input.TextArea autoSize={{ minRows: 4, maxRows: 4 }} allowClear />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <NumericInput
                        label="Último Precio de Compra"
                        rules={rules.precio}
                        name="precio_de_la_ultima_compra"
                      />
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="fecha_del_precio_de_la_ultima_compra"
                        label={(
                          <FormItemLabel
                            longTitle="Fecha del Último Precio de Compra"
                            shortTitle="Último Precio"
                            hideOn={['sm', 'md']}
                          />
                        )}
                      >
                        <DatePicker
                          placeholder=""
                          format="DD/MM/YYYY"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="cucop"
                        label="CUCOP"
                      >
                        <Select dataSource={cucop} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="impuesto"
                        label="Impuesto"
                      >
                        <Select dataSource={impuestos} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="estados_globales"
                        label="Estado"
                      >
                        <Select dataSource={estadosGlobales} disabled={!selectedRowKeys.length} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Col span={0}>
                    <Form.Item>
                      <Button htmlType="submit" />
                    </Form.Item>
                  </Col>
                </Form>
              </Card>
            </Col>
          )}
          <ModalDelete
            onDelete={deleteItem}
            onCancel={() => setVisibleAlert(false)}
            visible={visibleAlert}
            content={(
              <>
                <Text style={{ fontSize: 16 }}>
                  {`¿Desea eliminar el servicio ${form.getFieldValue('descripcion')}?`}
                </Text>
                <Text>
                  Solo será posible si no ha sido utilizado en otros procesos
                </Text>
              </>
            )}
          />
        </Spin>
      </Col>
    </Row>
  );
}

export default Servicios;
