import React from 'react';
import SingleForm from 'components/SingleForm';

export const permissionMomentoPresupuestalDeIngreso = {
  permissionModel: 'momentopresupuestaldeingreso',
};

function MomentosPresupuestalesDeIngresos() {
  return (
    <SingleForm
      baseURI="/catalogos/momentos-presupuestales-de-ingresos/"
      readOnly
      needsKey
      needsName
      needsDescription={false}
      withoutState
    />
  );
}

export default MomentosPresupuestalesDeIngresos;
