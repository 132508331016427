import React, { useState, useEffect } from 'react';
import {
  Card,
  Form,
  Row,
  Spin,
  Col,
  Input,
  Button,
  Select,
  Tabs,
  Modal,
  Space,
  DatePicker,
} from 'antd';

import {
  ArrowLeftOutlined,
  SaveOutlined,
  FilePdfOutlined,
  FileExcelOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';
import API from 'utils/api';
import { onError } from 'utils/handlers';
import Table from 'components/Table';
import FormSubmitControls from 'components/FormSubmitControls';

function ModificacionesPresupuestalesIngresos() {
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleModalModificaciones, setVisibleModalModificaciones] = useState(false);
  const [visibleModalTraspasos, setVisibleModalTraspasos] = useState(false);
  const [currentTabKey, setCurrentTab] = useState('');
  const [mutable, setMutable] = useState(true);

  const { TabPane } = Tabs;

  const requiredRule = {
    required: true,
    message: 'El campo es requerido',
  };
  const rules = {
    required: [requiredRule],
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchData();
    return () => API.tryCancel;
  }, []);

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    setTimeout(() => form.setFieldsValue(record));
  };

  const onClickEdit = () => {};

  const onClickDelete = () => {};

  const onAutorizar = () => {};

  const onExportarPDF = () => {};

  const onExportarExcel = () => {};

  const onChangeTabKey = () => {};

  const onClickAddModificaciones = () => {
    setVisibleModalModificaciones(true);
  };

  const onClickEditModificaciones = () => {};

  const onClickDeleteModificaciones = () => {};

  const onAutorizarModificaciones = () => {};

  const onExportarPDFModificaciones = () => {};

  const onExportarExcelModificaciones = () => {};

  const onClickAddTraspasos = () => {
    setVisibleModalTraspasos(true);
  };

  const onClickEditTraspasos = () => {};

  const onClickDeleteTraspasos = () => {};

  const onAutorizarTraspasos = () => {};

  const onExportarPDFTraspasos = () => {};

  const onExportarExcelTraspasos = () => {};

  const columns = [
    {
      titleText: 'Clave Presupuestal',
      dataIndex: 'clave_presupuestal',
      key: 'clave_presupuestal',
      width: 250,
    },
    {
      titleText: 'Clasificador por Rubro de Ingreso',
      dataIndex: 'clasificador_rubro_ingreso',
      key: 'clasificador_rubro_ingreso',
      width: 250,
    },
    {
      titleText: 'Área',
      dataIndex: 'area',
      key: 'area',
      width: 150,
    },
    {
      titleText: 'Fuentes de Financiamiento',
      dataIndex: 'fuentes_financiamiento',
      key: 'fuentes_financiamiento',
      width: 250,
    },
    {
      titleText: 'Concepto de Ingreso',
      dataIndex: 'concepto_ingreso',
      key: 'concepto_ingreso',
      width: 200,
    },
    {
      titleText: 'Cuenta Contable',
      dataIndex: 'cuenta_contable',
      key: 'cuenta_contable',
      width: 300,
    },
    {
      titleText: 'Fecha',
      dataIndex: 'fecha',
      key: 'fecha',
      width: 150,
    },
    {
      titleText: 'Periodo',
      dataIndex: 'periodo',
      key: 'periodo',
      width: 150,
    },
    {
      titleText: 'Folio',
      dataIndex: 'folio',
      key: 'folio',
      width: 200,
    },
    {
      titleText: 'Movimiento',
      dataIndex: 'movimiento',
      key: 'movimiento',
      width: 200,
    },
    {
      titleText: 'Comportamiento',
      dataIndex: 'comportamiento',
      key: 'comportamiento',
      width: 200,
    },
    {
      titleText: 'Presupuesto Anterior',
      dataIndex: 'presupuesto_anterior',
      key: 'presupuesto_anterior',
      width: 200,
    },
    {
      titleText: 'Importe de Movimiento',
      dataIndex: 'importe_movimiento',
      key: 'importe_movimiento',
      width: 200,
    },
    {
      titleText: 'Presupuesto Vigente',
      dataIndex: 'presupuesto_vigente',
      key: 'presupuesto_vigente',
      width: 200,
    },
    {
      titleText: 'Estado',
      dataIndex: 'estado',
      key: 'estado',
      width: 150,
    },
  ];

  const columnsModificaciones = [
    {
      titleText: 'Clave Presupuestal',
      dataIndex: 'clave_presupuestal',
      key: 'clave_presupuestal',
      width: 250,
    },
    {
      titleText: 'Clasificador por Rubro de Ingreso',
      dataIndex: 'clasificador_rubro_ingreso',
      key: 'clasificador_rubro_ingreso',
      width: 250,
    },
    {
      titleText: 'Área',
      dataIndex: 'area',
      key: 'area',
      width: 150,
    },
    {
      titleText: 'Fuentes de Financiamiento',
      dataIndex: 'fuentes_financiamiento',
      key: 'fuentes_financiamiento',
      width: 250,
    },
    {
      titleText: 'Concepto de Ingreso',
      dataIndex: 'concepto_ingreso',
      key: 'concepto_ingreso',
      width: 200,
    },
    {
      titleText: 'Periodo',
      dataIndex: 'periodo',
      key: 'periodo',
      width: 250,
    },
    {
      titleText: 'Presupuesto Anterior',
      dataIndex: 'presupuesto_anterior',
      key: 'presupuesto_anterior',
      width: 200,
    },
    {
      titleText: 'Aumento',
      dataIndex: 'aumento',
      key: 'aumento',
      width: 200,
    },
    {
      titleText: 'Disminución',
      dataIndex: 'disminucion',
      key: 'disminucion',
      width: 200,
    },
    {
      titleText: 'Presupuesto Vigente',
      dataIndex: 'presupuesto_vigente',
      key: 'presupuesto_vigente',
      width: 200,
    },
    {
      titleText: 'Estado',
      dataIndex: 'estado',
      key: 'estado',
      width: 150,
    },
  ];

  const columnsTraspasos = [
    {
      titleText: 'Clave Presupuestal',
      dataIndex: 'clave_presupuestal',
      key: 'clave_presupuestal',
      width: 250,
    },
    {
      titleText: 'Clasificador por Rubro de Ingreso',
      dataIndex: 'clasificador_rubro_ingreso',
      key: 'clasificador_rubro_ingreso',
      width: 250,
    },
    {
      titleText: 'Área',
      dataIndex: 'area',
      render: () => false,
      key: 'area',
      width: 150,
    },
    {
      titleText: 'Fuentes de Financiamiento',
      dataIndex: 'fuentes_financiamiento',
      render: () => false,
      key: 'fuentes_financiamiento',
      width: 250,
    },
    {
      titleText: 'Concepto de Ingreso',
      dataIndex: 'concepto_ingreso',
      render: () => false,
      key: 'concepto_ingreso',
      width: 200,
    },
    {
      titleText: 'Periodo',
      dataIndex: 'periodo',
      key: 'periodo',
      width: 250,
    },
    {
      titleText: 'Presupuesto Anterior',
      dataIndex: 'presupuesto_anterior',
      key: 'presupuesto_anterior',
      width: 200,
    },
    {
      titleText: 'Aumento',
      dataIndex: 'aumento',
      key: 'aumento',
      width: 200,
    },
    {
      titleText: 'Disminución',
      dataIndex: 'disminucion',
      key: 'disminucion',
      width: 200,
    },
    {
      titleText: 'Presupuesto Vigente',
      dataIndex: 'presupuesto_vigente',
      key: 'presupuesto_vigente',
      width: 200,
    },
    {
      titleText: 'Estado',
      dataIndex: 'estado',
      key: 'estado',
      width: 150,
    },
  ];

  const onFinish = () => ({});
  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    setSelectedRowKeys([]);
    setMutable(true);
  };

  const onFinishModificaciones = () => ({});
  const onCancelModificaciones = () => {
    setVisibleModalModificaciones(false);
  };

  const onFinishTraspasos = () => ({});
  const onCancelTraspasos = () => {
    setVisibleModalTraspasos(false);
  };
  const showTabModificaciones = () => {
    setVisible(true);
    setCurrentTab('modificaciones');
    setMutable(true);
  };

  const showTabTraspasos = () => {
    setVisible(true);
    setMutable(true);
    setCurrentTab('traspasos');
  };

  function ActionButtons() {
    return (
      <Space wrap>
        <Button type="link" onClick={showTabModificaciones}>
          Modificaciones
        </Button>
        <Button type="link" onClick={showTabTraspasos}>
          Traspasos
        </Button>
      </Space>
    );
  }

  function PresupuestoIngresosGrid() {
    return (
      <Card
        title={<ActionButtons />}
        bordered={false}
      >
        <Table
          cols={columns}
          data={[]}
          handleOnRowClick={handleOnRowClick}
          rowSelection={rowSelection}
          mobileColIndex={0}
          controls={{
            onClickEdit,
            onClickDelete,
          }}
          customActions={[
            {
              onClick: onAutorizar,
              text: 'Autorizar',
              icon: <CheckCircleOutlined />,
              disabledBySelection: false,
              className: 'success',
            },
            {
              onClick: onExportarPDF,
              icon: <FilePdfOutlined />,
              text: 'Exportar a PDF',
              disabledBySelection: false,
            },
            {
              onClick: onExportarExcel,
              icon: <FileExcelOutlined />,
              text: 'Exportar a Excel',
              disabledBySelection: false,
            },
          ]}
        />
      </Card>
    );
  }

  function TabsModificaciones() {
    return (
      <>
        <Card
          title={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Modificaciones Presupuestales de Ingresos`}
          extra={(
            <>
              <Button
                icon={<SaveOutlined />}
                className="ant-btn ant-btn-link"
                onClick={onFinish}
              >
                Guardar
              </Button>
              <Button
                icon={<ArrowLeftOutlined />}
                className="ant-btn ant-btn-link"
                onClick={onCancel}
              >
                Regresar
              </Button>
            </>
      )}
          bordered={false}
        />
        <Tabs
          activeKey={currentTabKey}
          onChange={mutable ? setCurrentTab : onChangeTabKey}
          type="card"
        >
          <TabPane
            tab="Modificaciones"
            key="modificaciones"
            forceRender
          >
            <Modificaciones />
          </TabPane>
          <TabPane
            tab="Traspasos"
            key="traspasos"
            forceRender
          >
            <Traspasos />
          </TabPane>
        </Tabs>

      </>
    );
  }

  function Modificaciones() {
    return (
      <>
        <Form
          layout="vertical"
          form={form}
          scrollToFirstError
          name="modificaciones"
        >
          <Row gutter={10}>
            <Col span={8}>
              <Form.Item
                name="fecha"
                label="Fecha"
              >
                <DatePicker placeholder="" format="DD/MM/YYYY" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="folio"
                label="Folio"
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="observaciones"
                label="Observaciones"
              >
                <Input allowClear />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Table
          cols={columnsModificaciones}
          data={[]}
          handleOnRowClick={handleOnRowClick}
          rowSelection={rowSelection}
          mobileColIndex={0}
          controls={{
            onClickAdd: onClickAddModificaciones,
            onClickEdit: onClickEditModificaciones,
            onClickDelete: onClickDeleteModificaciones,
          }}
          customActions={[
            {
              onClick: onAutorizarModificaciones,
              text: 'Autorizar',
              icon: <CheckCircleOutlined />,
              disabledBySelection: false,
              className: 'success',
            },
            {
              onClick: onExportarPDFModificaciones,
              icon: <FilePdfOutlined />,
              text: 'Exportar a PDF',
              disabledBySelection: false,
            },
            {
              onClick: onExportarExcelModificaciones,
              icon: <FileExcelOutlined />,
              text: 'Exportar a Excel',
              disabledBySelection: false,
            },
          ]}
        />
        <Modal
          visible={visibleModalModificaciones}
          closable={false}
          maskClosable={!loading}
          keyboard={!loading}
          footer={null}
          title={(
            <FormSubmitControls
              label={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Modificaciones`}
              onFinish={onFinishModificaciones}
              onCancel={onCancelModificaciones}
              loading={loading}
            />
              )}
          onCancel={onCancel}
          forceRender
        >
          <Form
            layout="vertical"
            form={form}
            onFinish={onFinishModificaciones}
            scrollToFirstError
            name="desglose"
          >
            <Row gutter={10}>
              <Col span={24}>
                <Form.Item
                  name="clave_presupuestal"
                  label="Clave Presupuestal"
                  rules={rules.required}
                >
                  <Select datasource={[]} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col span={24}>
                <Form.Item
                  name="clasificador_rubro_ingreso"
                  label="Clasificador por Rubro de Ingreso"
                >
                  <Input allowClear disabled />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col span={24}>
                <Form.Item
                  name="area"
                  label="Área"
                >
                  <Input allowClear disabled />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col span={24}>
                <Form.Item
                  name="fuente_financiamiento"
                  label="Fuente de Financiamiento"
                >
                  <Input allowClear disabled />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col span={24}>
                <Form.Item
                  name="concepto_ingreso"
                  label="Concepto de Ingreso"
                >
                  <Input allowClear disabled />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col span={24}>
                <Form.Item
                  name="periodo"
                  label="Periodo"
                  rules={rules.required}
                >
                  <Select datasource={[]} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col span={24}>
                <Form.Item
                  name="movimiento"
                  label="Movimiento"
                  rules={rules.required}
                >
                  <Select datasource={[]} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col span={24}>
                <Form.Item
                  name="importe"
                  label="Importe"
                  rules={rules.required}
                >
                  <Input allowClear />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col span={24}>
                <Form.Item
                  name="estado"
                  label="Estado"
                  rules={rules.required}
                >
                  <Select datasource={[]} disabled />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>

      </>
    );
  }

  function Traspasos() {
    return (
      <>
        <Form
          layout="vertical"
          form={form}
          scrollToFirstError
          name="traspasos"
        >
          <Row gutter={10}>
            <Col span={8}>
              <Form.Item
                name="fecha"
                label="Fecha"
              >
                <DatePicker placeholder="" format="DD/MM/YYYY" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="folio"
                label="Folio"
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="observaciones"
                label="Observaciones"
              >
                <Input allowClear />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Table
          cols={columnsTraspasos}
          data={[]}
          handleOnRowClick={handleOnRowClick}
          rowSelection={rowSelection}
          mobileColIndex={0}
          controls={{
            onClickAdd: onClickAddTraspasos,
            onClickEdit: onClickEditTraspasos,
            onClickDelete: onClickDeleteTraspasos,
          }}
          customActions={[
            {
              onClick: onAutorizarTraspasos,
              text: 'Autorizar',
              icon: <CheckCircleOutlined />,
              disabledBySelection: false,
              className: 'success',
            },
            {
              onClick: onExportarPDFTraspasos,
              icon: <FilePdfOutlined />,
              text: 'Exportar a PDF',
              disabledBySelection: false,
            },
            {
              onClick: onExportarExcelTraspasos,
              icon: <FileExcelOutlined />,
              text: 'Exportar a Excel',
              disabledBySelection: false,
            },
          ]}
        />
        <Modal
          visible={visibleModalTraspasos}
          closable={false}
          maskClosable={!loading}
          keyboard={!loading}
          footer={null}
          title={(
            <FormSubmitControls
              label={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Traspasos`}
              onFinish={onFinishTraspasos}
              onCancel={onCancelTraspasos}
              loading={loading}
            />
    )}
          onCancel={onCancel}
          forceRender
        >
          <Form
            layout="vertical"
            form={form}
            onFinish={onFinishTraspasos}
            scrollToFirstError
            name="desglose"
          >
            <Row gutter={10}>
              <Col span={24}>
                <Form.Item
                  name="clave_presupuestal"
                  label="Clave Presupuestal"
                  rules={rules.required}
                >
                  <Select datasource={[]} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col span={24}>
                <Form.Item
                  name="clasificador_rubro_ingreso"
                  label="Clasificador por Rubro de Ingreso"
                >
                  <Input allowClear disabled />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col span={24}>
                <Form.Item
                  name="area"
                  label="Área"
                >
                  <Input allowClear disabled />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col span={24}>
                <Form.Item
                  name="fuente_financiamiento"
                  label="Fuente de Financiamiento"
                >
                  <Input allowClear disabled />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col span={24}>
                <Form.Item
                  name="concepto_ingreso"
                  label="Concepto de Ingreso"
                >
                  <Input allowClear disabled />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col span={24}>
                <Form.Item
                  name="periodo"
                  label="Periodo"
                  rules={rules.required}
                >
                  <Select datasource={[]} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col span={24}>
                <Form.Item
                  name="movimiento"
                  label="Movimiento"
                  rules={rules.required}
                >
                  <Select datasource={[]} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col span={24}>
                <Form.Item
                  name="importe"
                  label="Importe"
                  rules={rules.required}
                >
                  <Input allowClear />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col span={24}>
                <Form.Item
                  name="estado"
                  label="Estado"
                  rules={rules.required}
                >
                  <Select datasource={[]} disabled />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>

      </>
    );
  }

  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible ? <PresupuestoIngresosGrid /> : <TabsModificaciones />}
      </Spin>
    </Row>
  );
}

export default ModificacionesPresupuestalesIngresos;
