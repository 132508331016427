import React from 'react';
import SingleForm from 'components/SingleForm';
import { getTipoDeCuentaContable } from 'api/catalogos';

export const permissionTipoCuentaContable = {
  permissionModel: 'tipodecuentacontable',
};

// eslint-disable-next-line react/prop-types
function TiposDeCuentaContable({ permission }) {
  return (
    <SingleForm
      title="Tipos de cuenta contable"
      withoutState
      readOnly
      permission={permission}
      get={getTipoDeCuentaContable}
    />
  );
}

export default TiposDeCuentaContable;
