/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Form,
  Button,
  Row,
  Col,
  Spin,
  message,
  Card,
  Modal,
} from 'antd';

import Table from 'components/Table';
import FormSubmitControls from 'components/FormSubmitControls';
import ModalDelete from 'components/ModalDelete';
import NumericInput from 'components/NumericInput';
import Select from 'components/Select';
import API from 'utils/api';
import { onError, onSuccess } from 'utils/handlers';
import { isMutable } from 'utils/estadosGlobales';
import Segmentos from './Segmentos';

export const permissionConfigSegmentoPresupuestal = {
  permissionModel: 'configuraciondesegmentopresupuestal',
};

const baseURI = 'configuraciones/configuraciones-de-segmentos-presupuestales/';

// eslint-disable-next-line react/prop-types
function ConfiguracionSegmentos({ permission }) {
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  const periodoFiscalSelected = useSelector(({ auth }) => auth.periodoFiscalSelected);
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);
  const [tiposPresupuestos, setTiposPresupuestos] = useState([]);
  const [mutable, setMutable] = useState(true);

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await API.get(baseURI, {
        params: {
          periodo_fiscal: periodoFiscalSelected.id,
        },
      });
      setData(res.data);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [periodoFiscalSelected]);

  useEffect(() => {
    const fetchAll = async () => {
      try {
        setLoading(true);
        const restipo = await API.get('catalogos/tipos-de-presupuestos/');
        setTiposPresupuestos(restipo.data || []);

        await fetchData();
        setLoading(false);
      } catch (err) {
        onError(err, setLoading);
      }
    };
    fetchAll();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    setSelectedRowKeys([]);
  };

  const onFinish = async (values) => {
    try {
      setLoading(true);
      if (!selectedRowKeys.length) {
        const response = await API.post(baseURI, values);
        if (response?.status === 201) {
          onSuccess(response, 'Agregado correctamente');
          onCancel();
          await fetchData();
        }
      } else {
        const [key] = selectedRowKeys;
        const response = await API.put(`${baseURI}${key}/`, values);
        if (response?.status === 200) {
          onSuccess(response, 'Actualizado correctamente');
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    setMutable(isMutable(record));
    setTimeout(() => form.setFieldsValue({ ...record }));
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const onClickEdit = (authorized) => {
    setVisible(true);
    const [key] = selectedRowKeys;
    const match = authorized || data.find((e) => e.id === key);
    setMutable(isMutable(match));
    setTimeout(() => {
      form.setFieldsValue(match);
    });
  };

  const onClickDelete = () => {
    if (mutable) {
      setVisibleAlert(true);
    } else {
      message.info('No se pueden eliminar los Segmentos autorizados');
    }
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`${baseURI}${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          onCancel();
          setVisibleAlert(false);
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const columns = [
    {
      titleText: 'Identificador',
      dataIndex: 'identificador',
      key: 'identificador',
      width: 140,
    },
    {
      titleText: 'Tipo',
      dataIndex: 'tipo_de_presupuesto',
      key: 'tipo_de_presupuesto',
      width: 130,
      render: (val) => tiposPresupuestos.find((g) => g.id === val)?.nombre,
    },
    {
      titleText: 'Estado',
      dataIndex: 'estados_globales',
      key: 'estados_globales',
      width: 120,
      render: (val) => (estadosGlobales.find((g) => g.id === val)?.descripcion),
    },
  ];

  const handleSubmit = () => {
    form.submit();
  };

  function Formulario() {
    let props;
    const modal = !selectedRowKeys.length;
    if (modal) {
      props = {
        span: 24,
      };
    } else {
      props = {
        xs: 24,
        sm: 24,
        md: 8,
      };
    }
    return (
      <Form
        layout="vertical"
        form={form}
        onFinish={() => onFinish(form.getFieldsValue())}
        scrollToFirstError
        initialValues={{ estados_globales: 1, periodo_fiscal: periodoFiscalSelected?.id }}
      >
        <Row gutter={10}>
          <Col {...props}>
            <NumericInput
              name="identificador"
              label="Identificador"
              required
              disabled={!!selectedRowKeys.length}
            />
          </Col>

          <Col {...props}>
            <Form.Item
              name="tipo_de_presupuesto"
              rules={rules.required}
              label="Tipo de Presupuesto"
              hasFeedback
            >
              <Select
                disabled={!!selectedRowKeys.length}
                dataSource={tiposPresupuestos}
                labelProp="nombre"
              />
            </Form.Item>
          </Col>

          <Col {...props}>
            <Form.Item
              name="periodo_fiscal"
              rules={rules.required}
              label="Ejercicio"
              hasFeedback
            >
              <Select
                disabled
                dataSource={[periodoFiscalSelected]}
                render={(item) => item.periodo}
              />
            </Form.Item>
          </Col>

          {!modal && (
            <Segmentos
              idConfiguracion={selectedRowKeys[0]}
              isMutable={mutable}
            />
          )}
          <Form.Item hidden>
            <Button htmlType="submit" />
          </Form.Item>
        </Row>
      </Form>
    );
  }

  const getDisplayName = () => {
    const [key] = selectedRowKeys;
    const configuracion = data.find((g) => g.id === key);
    const tipo = tiposPresupuestos.find((p) => p.id === configuracion?.tipo_de_presupuesto);
    return `${tipo?.nombre} - ${configuracion?.identificador}`;
  };

  const normalizeDataAutorized = (estados_globales) => {
    const [key] = selectedRowKeys;
    const normalized = [...data]
      .map((e) => (e.id === key ? { ...e, estados_globales } : e));
    setMutable(false);
    setData(normalized);
    const match = normalized.find((e) => e.id === key);
    onClickEdit(match, true);
  };

  return (
    <Row id="presupuestos-segmentos" className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!(visible && selectedRowKeys.length) && (
          <Table
            cols={columns}
            data={data}
            permission={permission}
            rowSelection={rowSelection}
            handleOnRowClick={handleOnRowClick}
            controls={{
              onClickAdd,
              onClickEdit,
              onClickDelete,
            }}
          />
        )}
        {(visible && !!selectedRowKeys.length) && (
          <Card
            className="form-container"
            title={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Configuración Segmento`}
            extra={(
              <FormSubmitControls
                onFinish={() => handleSubmit()}
                onCancel={onCancel}
                mutable={mutable}
                loading={loading}
                baseURI={baseURI}
                callback={normalizeDataAutorized}
                selectedRowKeys={selectedRowKeys}
                allowAuthorize
                allowCancel
              />
            )}
            bordered={false}
          >
            <Formulario />
          </Card>
        )}
      </Spin>
      {!selectedRowKeys.length && (
        <Modal
          visible={visible && !selectedRowKeys.length}
          title={(
            <FormSubmitControls
              label={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Segmento`}
              onFinish={() => handleSubmit()}
              onCancel={onCancel}
              loading={loading}
            />
          )}
          closable={false}
          maskClosable={!loading}
          keyboard={!loading}
          onCancel={onCancel}
          footer={null}
          forceRender
        >
          <Formulario modal />
        </Modal>
      )}
      <ModalDelete
        onDelete={deleteItem}
        onCancel={() => setVisibleAlert(false)}
        visible={visibleAlert}
        content={`Configuración de Segmentos ${getDisplayName()}`}
        loading={loading}
      />
    </Row>
  );
}

export default ConfiguracionSegmentos;
