import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import API from 'utils/api';
import { onError } from 'utils/handlers';
import Table from './Table';
import Select from './Select';

function SearchDireccion({
  callback,
  setLoading,
  setSelected,
}) {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [filteredStates, setFilteredStates] = useState([]);
  const [filteredMunicipalities, setFilteredMunicipalities] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const resStates = await API.get('catalogos/estados/');
        setFilteredStates(resStates.data);
        setLoading(false);
      } catch (err) {
        onError(err, setLoading);
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  const changeState = async (stateID) => {
    try {
      form.setFieldsValue({
        estado: stateID,
        municipio: undefined,
      });
      setFilteredMunicipalities([]);
      const res = await API.get('catalogos/municipios/', {
        params: {
          estado: stateID,
        },
      });
      const municipalitiesOfState = res.data;
      setFilteredMunicipalities(municipalitiesOfState);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
  };

  const columns = [
    {
      titleText: 'Código',
      dataIndex: 'd_codigo',
      key: 'd_codigo',
      render: (text, record) => (
        <Button
          type="link"
          className="primary-text no-color"
          onClick={() => callback(record)}
          style={{ padding: 0 }}
        >
          {text}
        </Button>
      ),
    },
    {
      titleText: 'Asentamiento',
      dataIndex: 'd_asenta',
      key: 'd_asenta',
    },
    {
      titleText: 'Tipo de asentamiento',
      dataIndex: 'd_tipo_asenta',
      key: 'd_tipo_asenta',
    },
    {
      titleText: 'Municipio',
      dataIndex: 'nom_mun',
      key: 'nom_mun',
    },
    {
      titleText: 'Estado',
      dataIndex: 'nombre_de_AGEE',
      key: 'nombre_de_AGEE',
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onFinish = async (values) => {
    try {
      setLoading(true);
      const { asentamiento, municipio } = values;
      const res = await API.get('catalogos/codigos-postales/', {
        params: {
          d_asenta: asentamiento,
          municipio,
        },
      });
      const resData = res.data.map((item) => ({
        ...item,
        nom_mun: item.municipio.nom_mun,
        nombre_de_AGEE: item.municipio.estado.nombre_de_AGEE,
      }));
      setData(resData);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onValuesChange = async (changedValues) => {
    const {
      pais,
      estado,
    } = changedValues;
    if (pais >= 0) {
      // changeCountry(pais);
    } else if (estado >= 0) {
      await changeState(estado);
    }
  };

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        name="busqueda_codigos_postales"
        initialValues={{ pais: 'México' }}
        onValuesChange={onValuesChange}
        onFinish={onFinish}
        scrollToFirstError
      >
        <Row gutter={10}>
          <Col md={6} sm={24} xs={24}>
            <Form.Item
              label="Estado"
              name="estado"
              rules={rules.required}
            >
              <Select dataSource={filteredStates} labelProp="nombre_de_AGEE" />
            </Form.Item>
          </Col>

          <Col md={6} sm={24} xs={24}>
            <Form.Item
              label="Municipio"
              name="municipio"
              rules={rules.required}
            >
              <Select data={filteredMunicipalities} labelProp="nom_mun" />
            </Form.Item>
          </Col>

          <Col md={6} sm={24} xs={24}>
            <Form.Item
              label="Asentamiento/Colonia"
              name="asentamiento"
            >
              <Input allowClear />
            </Form.Item>
          </Col>
          <Col md={6} sm={24} xs={24}>
            <Form.Item>
              <Button
                type="link"
                htmlType="submit"
                style={{ marginTop: 20 }}
              >
                <SearchOutlined />
                {' '}
                Buscar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Table
        cols={columns}
        data={data}
        noControls
        allowSort={false}
        allowSearch={false}
        rowSelection={rowSelection}
        onRow={(record) => ({
          onDoubleClick: () => callback(record),
          onClick: () => {
            setSelectedRowKeys([record.id]);
            setSelected(record);
          },
        })}
      />
    </>
  );
}

SearchDireccion.propTypes = {
  callback: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  setSelected: PropTypes.func.isRequired,
};

export default SearchDireccion;
