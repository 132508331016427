import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import {
  Button,
  Col,
  Form,
  Modal,
  Row,
  Spin,
  Typography,
  message,
  Grid,
} from 'antd';

import Table from 'components/Table';
import ModalDelete from 'components/ModalDelete';
import FormSubmitControls from 'components/FormSubmitControls';
import Select from 'components/Select';
import NumericInput from 'components/NumericInput';
import fetchSome from 'utils/fetchSome';
import { onError, onSuccess } from 'utils/handlers';
import { SET_UNIDADES_DE_MEDIDA } from 'store/reducers/catalogs/index';
import API from 'utils/api';
import currencyFormater from 'utils/currencyFormatter';
import { createDecimalRegExp } from 'utils/patterns';
import { InputSearchPro } from 'components/InputSearch';
import { isMutable } from 'utils/estadosGlobales';

const { Text } = Typography;
const { useBreakpoint } = Grid;

function MontoDetallado({
  baseURI,
  data,
  setData,
  callback,
}) {
  const screen = useBreakpoint();
  const unidadesDeMedida = useSelector(({ catalogs }) => catalogs.unidadesDeMedida);
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [conceptos, setConceptos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [decimal] = createDecimalRegExp(2);
  const [productoFound, setProductoFound] = useState([]);
  const [esProducto, setEsProducto] = useState(true);
  const [mutable, setMutable] = useState(true);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await API.get(baseURI);
      setData(response.data);
      callback(response.data);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const fetchAll = async () => {
    try {
      setLoading(true);
      const res = await API.get('/configuraciones/conceptos-de-productos/');
      setConceptos(res.data || []);
      if (!unidadesDeMedida.length) {
        fetchSome('/configuraciones/unidades-de-medida/', SET_UNIDADES_DE_MEDIDA);
      }
      await fetchData();
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useState(() => {
    fetchAll();
  }, [baseURI]);

  const onCancel = () => {
    setVisible(false);
    setSelectedRowKeys([]);
    form.resetFields();
    setEsProducto(true);
    setProductoFound([]);
    setMutable(true);
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      if (!esProducto) {
        values.servicio = values.producto;
        delete values.producto;
      }
      if (!selectedRowKeys.length) {
        const response = await API.post(baseURI, values);
        if (response?.status === 201) {
          onSuccess(response, 'Agregado correctamente');
          onCancel();
          await fetchData();
        }
      } else {
        const [key] = selectedRowKeys;
        const response = await API.put(`${baseURI}${key}/`, values);
        if (response?.status === 200) {
          onSuccess(response, 'Actualizado correctamente');
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
    importe: [
      {
        validator: async (_, val) => {
          if (val && !decimal.test(val)) {
            throw new Error('Ingrese un valor decimal válido');
          }
        },
      },
    ],
  };

  const columns = [
    {
      titleText: 'Concepto',
      dataIndex: 'concepto_del_producto',
      key: 'concepto_del_producto',
      render: (val) => conceptos.find((e) => e.id === val)?.descripcion,
      width: 200,
    },
    {
      titleText: 'Cantidad',
      dataIndex: 'cantidad',
      key: 'cantidad',
      width: 200,
    },
    {
      titleText: 'Precio Unitario',
      dataIndex: 'precio_unitario',
      key: 'precio_unitario',
      render: (val) => currencyFormater(val),
      align: 'right',
      width: 200,
    },
    {
      titleText: 'Precio con IVA',
      dataIndex: 'precio_total_del_producto',
      key: 'precio_total_del_producto',
      render: (val) => currencyFormater(val),
      align: 'right',
      width: 200,
    },
    {
      titleText: 'Sub Total',
      dataIndex: 'sub_total',
      key: 'sub_total',
      render: (val) => currencyFormater(val),
      align: 'right',
      width: 200,
    },
    {
      titleText: 'Total',
      dataIndex: 'total',
      key: 'total',
      render: (val) => currencyFormater(val),
      align: 'right',
      width: 200,
    },
    {
      titleText: 'IVA',
      dataIndex: 'IVA',
      key: 'IVA',
      render: (val) => `${currencyFormater(val, true)} %`,
      width: 100,
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    form.setFieldsValue({ ...record });
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const onClickEdit = async () => {
    try {
      setLoading(true);
      const clone = [...data];
      const [key] = selectedRowKeys;
      const match = clone.find((e) => e.id === key);
      const idBien = match?.producto || match?.servicio;
      let uri = 'configuraciones/productos/';
      const es_producto = !!match.servicio;
      if (!es_producto) {
        uri = 'configuraciones/servicios/';
      }
      const response = await API.get(`${uri}${idBien}`);
      setProductoFound([response.data]);
      form.setFieldsValue({
        ...match,
        es_producto,
      });
      setMutable(isMutable(match));
      setVisible(true);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`${baseURI}${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          setVisibleAlert(false);
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const busquedaProducto = async () => {
    try {
      setLoading(true);
      await form.validateFields(['clave']);
      const params = form.getFieldsValue();
      const uri = esProducto ? 'configuraciones/productos/' : 'configuraciones/servicios/';
      const response = await API.get(uri, {
        params,
      });
      const [product] = response.data;
      if (response?.status === 200 && response?.data?.length) {
        form.setFieldsValue({
          ...product,
          producto: product.id,
          precio_unitario: product.precio_unitario,
          iva: product?.IVA,
        });
        setProductoFound([product]);
      } else if (response?.status === 204) {
        message.info('No se encontraron coincidencias');
        setProductoFound([]);
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const resetAndValidateProductos = () => {
    setProductoFound([]);
    form.resetFields(['clave']);
    form.validateFields(['clave']);
  };

  return (
    <Row>
      <Spin tip="Cargando..." spinning={loading}>
        <Col span={24}>
          <Table
            mobileCols={[columns[0]]}
            cols={columns}
            data={data}
            rowSelection={rowSelection}
            handleOnRowClick={handleOnRowClick}
            controls={{
              onClickAdd,
              onClickEdit,
              onClickDelete,
            }}
            allowExport={false}
            rowKey="key"
          />
          <Modal
            visible={visible}
            title={(
              <FormSubmitControls
                label={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Detallado Mensual`}
                onFinish={onFinish}
                onCancel={onCancel}
              />
            )}
            width={screen.md ? '80%' : '100%'}
            onCancel={onCancel}
            footer={null}
            closable={false}
            forceRender
          >
            <Form
              layout="vertical"
              form={form}
              onFinish={onFinish}
              scrollToFirstError
              initialValues={{ es_producto: true, estados_globales: 1 }}
            >
              <Row gutter={10}>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="es_producto"
                    label="¿Es producto o servicio?"
                  >
                    <Select
                      disabled={!mutable}
                      dataSource={[
                        { id: true, descripcion: 'Producto' },
                        { id: false, descripcion: 'Servicio' },
                      ]}
                      onChange={setEsProducto}
                    />
                  </Form.Item>
                </Col>
                <InputSearchPro
                  disabled={!mutable}
                  label={esProducto ? 'Producto' : 'Servicio'}
                  tooltip={`Búsqueda por clave del ${esProducto ? 'Producto' : 'Servicio'} `}
                  name="clave"
                  rules={rules.required}
                  onPressEnter={() => busquedaProducto()}
                  breakPoints={{
                    sm: 24,
                    xs: 24,
                    md: 16,
                  }}
                  inputProps={{
                    onPressEnter: () => busquedaProducto(),
                    onChange: ({ target: { value } }) => {
                      if (!value) {
                        resetAndValidateProductos();
                      }
                    },
                  }}
                  resultProps={{
                    name: 'producto',
                    label: esProducto ? 'Producto' : 'Servicio',
                    dataSource: productoFound,
                    labelProp: 'descripcion',
                    rules: rules.required,
                    keyLabelRender: true,
                    keyProp: 'clave',
                    onClear: resetAndValidateProductos,
                    breakPoints: {
                      sm: 24,
                      xs: 24,
                      md: 16,
                    },
                  }}
                />
                <Col xs={24} sm={24} md={8}>
                  <NumericInput
                    name="importe_total"
                    rules={rules.required}
                    label="Importe total"
                    hasFeedback
                    disabled={!mutable}
                  />
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="concepto_del_producto"
                    rules={rules.required}
                    label="Concepto"
                    hasFeedback
                  >
                    <Select dataSource={conceptos} disabled={!mutable} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <NumericInput
                    name="cantidad"
                    rules={rules.required}
                    label="Cantidad"
                    hasFeedback
                    disabled={!mutable}
                    onChange={() => {
                      const { cantidad, precio_unitario } = form.getFieldsValue();
                      const precio_total_del_producto = precio_unitario * cantidad;
                      if (!Number.isNaN(precio_total_del_producto)) {
                        form.setFieldsValue({
                          precio_total_del_producto,
                        });
                      }
                    }}
                  />
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <NumericInput
                    name="precio_unitario"
                    rules={rules.required}
                    label="Precio Unitario"
                    disabled={!mutable}
                    hasFeedback
                    onChange={() => {
                      const { cantidad, precio_unitario } = form.getFieldsValue();
                      const precio_total_del_producto = precio_unitario * cantidad;
                      if (!Number.isNaN(precio_total_del_producto)) {
                        form.setFieldsValue({
                          precio_total_del_producto,
                        });
                      }
                    }}
                  />
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <NumericInput
                    name="iva"
                    rules={rules.required}
                    label="IVA (%)"
                    hasFeedback
                    disabled={!mutable}
                  />
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <NumericInput
                    name="precio_total_del_producto"
                    rules={rules.required}
                    label="Precio total del producto"
                    hasFeedback
                    disabled
                  />
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <NumericInput
                    name="sub_total"
                    rules={rules.required}
                    label="Subtotal"
                    hasFeedback
                    disabled={!mutable}
                  />
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <NumericInput
                    name="total"
                    rules={rules.required}
                    label="Total"
                    hasFeedback
                    disabled={!mutable}
                  />
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="unidad_de_medida"
                    rules={rules.required}
                    label="Unidad de Medida"
                    hasFeedback
                  >
                    <Select
                      disabled={!mutable}
                      dataSource={unidadesDeMedida.filter((u) => u.descripcion !== '')}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    label="Estado"
                    name="estados_globales"
                  >
                    <Select
                      dataSource={estadosGlobales}
                      disabled={!selectedRowKeys.length || !mutable}
                    />
                  </Form.Item>
                </Col>
                <Form.Item hidden>
                  <Button htmlType="submit" />
                </Form.Item>
              </Row>
            </Form>
          </Modal>
        </Col>
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => setVisibleAlert(false)}
          visible={visibleAlert}
          content={(
            <Text style={{ fontSize: 16 }}>
              ¿Desea eliminar el detallado mensual seleccionado?
            </Text>
        )}
        />
      </Spin>
    </Row>
  );
}

MontoDetallado.propTypes = {
  baseURI: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  setData: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired,
};

export default MontoDetallado;
