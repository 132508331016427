import { store } from 'store';

export const ActionsNames = {
  view: 'view',
  add: 'add',
  change: 'change',
  delete: 'delete',
  authorize: 'autorizar',
  cancel: 'delete',
  export: 'exportar',
  import: 'importar',
  sync: 'sincronizar',
};

export const permissionbypass = {
  add: true,
  import: true,
  export: true,
  delete: true,
  authorize: true,
  cancel: true,
  view: true,
  change: true,
  sync: true,
  hasAllViewPermission: true,
};

// Rutas accesibles para todos los usuarios
const alwaysAllowedRoutes = [
  '/dashboard',
  '/configuracion/entidad-municipal',
  '/configuracion/cuenta-de-usuario',
];

// Rutas que aun no se encuentran disponibles (permitir acceso por ahora)
const currentExceptions = [
  '/contabilidad/reportes-gubernamentales/cuenta-publica',
  '/contabilidad/reportes-gubernamentales/conac',
  '/contabilidad/reportes-gubernamentales/disciplina-financiera',
  '/contabilidad/reportes-gubernamentales/otros-reportes/conciliacion-contable-presupuestal',
  '/presupuesto-basado-en-resultados/reportes',
  '/presupuesto-basado-en-resultados/catalogos/unidad-de-medida-mir',
  '/presupuestos/presupuestos-de-ingresos/avance-presupuestal-de-ingresos',
  '/presupuestos/presupuestos-de-ingresos/modificaciones-y-traspasos',
  '/presupuestos/presupuesto-de-egresos/modificaciones',
  '/presupuestos/presupuesto-de-egresos/traspasos',
  '/presupuestos/presupuesto-de-egresos/recalendarizaciones',
  '/adquisiciones/requisiciones/solicitudes',
  '/adquisiciones/requisiciones/consolidacion',
  '/adquisiciones/requisiciones/asignar-comprador',
  '/adquisiciones/requisiciones/procesos/licitaciones',
  '/adquisiciones/requisiciones/procesos/invitacion-a-3',
  '/adquisiciones/requisiciones/procesos/compra-directa',
  '/adquisiciones/requisiciones/cuadro-comparativo/solicitud-de-propuestas',
  '/adquisiciones/requisiciones/cuadro-comparativo/cotizaciones',
  '/adquisiciones/requisiciones/cuadro-comparativo/comparativo',
  '/adquisiciones/requisiciones/remesas',
  '/adquisiciones/requisiciones/recepciones/bienes-no-almacenables',
  '/adquisiciones/requisiciones/devoluciones-bienes-no-almacenables',
  '/adquisiciones/expediente-digital',
  '/adquisiciones/penalizaciones',
  '/adquisiciones/cerrar-contratos',
  '/adquisiciones/reportes',
  '/adquisiciones/catalogos/conceptos-de-penalizacion',
  '/almacen/entradas/recepciones',
  '/almacen/entradas/devoluciones-al-almacen',
  '/almacen/salidas/vales-de-salida',
  '/almacen/salidas/devoluciones-al-proveedor',
  '/almacen/kardex-de-productos',
  '/almacen/reportes/existencias',
  '/almacen/reportes/inventarios',
  '/obra-publica/control-de-obra/programas',
  '/obra-publica/control-de-obra/obras',
  '/obra-publica/control-de-obra/contratos',
  '/obra-publica/control-de-obra/convenios',
  '/obra-publica/pagos/anticipos',
  '/obra-publica/pagos/estimaciones',
  '/obra-publica/pagos/liquidaciones',
  '/obra-publica/expediente-digital',
  '/obra-publica/reportes',
  '/obra-publica/catalogos/afianzadoras',
  '/obra-publica/catalogos/penalizaciones',
  '/tesoreria/cuentas-internas/solicitud-pago-directo',
  '/tesoreria/cuentas-internas/comprobacion-de-gastos',
  '/tesoreria/cuentas-por-pagar',
  '/tesoreria/pagos-directos',
  '/tesoreria/anticipos',
  '/tesoreria/contra-recibo',
  '/tesoreria/solicitud-de-pago',
  '/tesoreria/bancos/depositos',
  '/tesoreria/bancos/salidas',
  '/tesoreria/reportes',
  '/control-patrimonial/administracion-de-bienes/recepciones',
  '/control-patrimonial/administracion-de-bienes/alta-de-bienes',
  '/control-patrimonial/administracion-de-bienes/baja-de-bienes',
  '/control-patrimonial/resguardos/baja-de-resguardos',
  '/control-patrimonial/resguardos/resguardos-de-traslado',
  '/control-patrimonial/depreciacion/amortizacion/deterioro',
  '/control-patrimonial/inventario-de-bienes',
  '/control-patrimonial/reportes',
  '/control-patrimonial/catalogos/area-general',
  '/control-patrimonial/catalogos/area-especifica',
  '/control-patrimonial/catalogos/ubicaciones',
  '/configuracion/catalogos/presupuesto-basado-en-resultados/frecuencia-de-medicion',
  '/configuracion/catalogos/presupuesto-basado-en-resultados/tipo-de-dimension',
  '/configuracion/catalogos/presupuesto-basado-en-resultados/tipo-de-indicador',
  '/configuracion/catalogos/adquisiciones/tipo-de-adquisiciones',
  '/configuracion/catalogos/adquisiciones/tipo-de-movimiento-corporativo',
  '/configuracion/catalogos/almacen/tipo-de-almacen',
  '/configuracion/catalogos/obra-publica/tipos-de-fianzas',
  '/configuracion/catalogos/obra-publica/tipos-de-obras',
  '/configuracion/catalogos/obra-publica/tipos-de-anticipos',
  '/configuracion/catalogos/obra-publica/modalidad-de-asignacion',
  '/configuracion/catalogos/obra-publica/tipos-de-contratos',
  '/configuracion/catalogos/obra-publica/tipo-de-convenio',
  '/configuracion/catalogos/tesoreria/tipo-de-cuenta-bancaria',
  '/configuracion/catalogos/tesoreria/tipo-beneficiarios',
  '/configuracion/catalogos/control-patrimonial/tipo-de-aquisicion',
  '/configuracion/catalogos/polizas-contables',
  '/configuracion/accesos/perfil-de-usuarios',
  '/configuracion/otros/ejercicios',
  '/configuracion/otros/blockchain',
  '/configuracion/otros/firma-electronica',
  '/configuracion/otros/bitacora',
  '/utilerias/tipos-de-documento-para-tramite',
  '/reportes-gubernamentales',

  // componentes con mocks
  'presupuestos/presupuesto-de-egresos/avance-presupuestal-de-egresos',
  'adquisiciones/requisiciones/orden-de-compra',
  'adquisiciones/requisiciones/remisiones',
  'adquisiciones/requisiciones/cuentas-por-pagar',
  'adquisiciones/requisiciones/pagos',
  'adquisiciones/requisiciones/conceptos',
  'adquisiciones/requisiciones/ingresos',

  // usando componentes dulicados
  'presupuestos/reportes', // 'presupuestos/reservas'
];

const allExceptions = [...alwaysAllowedRoutes, ...currentExceptions];

/**
  * Función para identificar si el usuario es un administrador
  * @returns {boolean}
 */
export const isAdmin = () => {
  const entidad = store.getState()?.auth?.entidad;
  const user = store.getState()?.auth?.user;
  return entidad?.administradores.includes(user.id);
};

/**
  * Función para identificar si el usuario tiene el permiso para una acción específica
  * @param {string} model - Modelo
  * @param {string} type - Tipo de accion
  * @returns {boolean}
 */
export const hasActionPermission = (model, type) => {
  const user = store.getState()?.auth?.user;
  return user?.permisos
    ?.includes(`${type}_${model}`.toLowerCase()) || false;
};

/**
  * Función para obtener los permisos de un usuario para un modelo específico
  * @param {string} model - Modelo
  * @param {string[]} [catalogsModels] - Catálogos del Modelo
  * @param {Object[]} [tabsModels] - Modelos de los tabs de la ruta
  * @param {string} tabsModels[].model - Nombre del modelos
  * @param {string[]} tabsModels[].catalogs - Catálogos del Modelo
  * @param {boolean} [affectView] - Afectar vista si no tiene permisos de catálogos
  * @returns {Object} {add,view,change,delete,catalogs:{},tabs:{},hasAllViewPermission...}
 */
export const getModelPermission = (
  model,
  catalogsModels = [],
  tabsModels = [],
  affectView = false,
) => {
  const keys = Object.keys(ActionsNames);
  const entries = keys.map((key) => [key, hasActionPermission(model, ActionsNames[key])]);
  const permission = Object.fromEntries(entries);
  permission.model = model;
  const catalogsPermission = catalogsModels.map((m) => getModelPermission(m));
  if (catalogsModels.length) {
    permission.catalogs = Object.fromEntries(
      catalogsModels.map((e, idx) => [e, catalogsPermission[idx]]),
    );
  }
  if (tabsModels.length) {
    const tabsPermission = tabsModels
      .map((m) => getModelPermission(m.model, m.catalogs));
    permission.tabs = Object.fromEntries(
      tabsModels.map((e, idx) => [e.model, tabsPermission[idx]]),
    );
  }
  permission.hasAllViewPermission = permission.view && catalogsPermission.every((e) => e.view);
  permission.view = affectView ? permission.hasAllViewPermission : permission.view;
  return permission;
};

/**
  * Función para identificar si el usuario tiene el permiso de vista para una ruta específica
  * @param {string} path - Path de la ruta
  * @param {string} permissionModel - Nombre del modelo base de la ruta
  * @param {string[]} [catalogsModels=] - Nombre de los modelos de las
  * dependencias que tiene la ruta
  * @param {string[]} [tabsModels=] - Nombre de los modelos de los tabs de la ruta
  * @returns {boolean}
 */
export const routePermission = (path, permissionModel, catalogsModels = [], tabsModels = []) => {
  // console.log({ path, permissionModel, catalogsModels });
  const permission = getModelPermission(permissionModel, catalogsModels, tabsModels, false);
  return allExceptions.some((e) => e?.includes(path))
    || (permission.view && permission.hasAllViewPermission);
};
