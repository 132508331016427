import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Tooltip,
  Grid,
} from 'antd';

import {
  QuestionCircleOutlined,
} from '@ant-design/icons';

function FormItemLabel({
  shortTitle,
  longTitle,
  hideOn,
  extra,
}) {
  const screens = Grid.useBreakpoint();
  const screensAsEntries = Object.entries(screens).filter(([, val]) => val);
  const activeBreakpoint = screensAsEntries.pop()?.shift();

  return (
    <React.Fragment key={shortTitle}>
      <Row>
        {hideOn.includes(activeBreakpoint) ? (
          <>
            <span style={{ paddingRight: 5 }}>{shortTitle}</span>
            <Tooltip title={extra ? `${longTitle}, ${extra}` : longTitle} key={shortTitle}>
              <QuestionCircleOutlined />
            </Tooltip>
          </>
        )
          : longTitle}
      </Row>
    </React.Fragment>
  );
}

FormItemLabel.propTypes = {
  shortTitle: PropTypes.string.isRequired,
  longTitle: PropTypes.string.isRequired,
  extra: PropTypes.string,
  hideOn: PropTypes.arrayOf(PropTypes.string),
};

FormItemLabel.defaultProps = {
  hideOn: ['md'],
  extra: '',
};

export default FormItemLabel;
