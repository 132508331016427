import React from 'react';
import SingleForm from 'components/SingleForm';

export const permissionJustificaciones = {
  permissionModel: 'justificaciondeusuarioexterno',
};

// eslint-disable-next-line react/prop-types
function Justificaciones({ permission }) {
  return (
    <SingleForm
      baseURI="usuarios/justificaciones-de-usuario-externo/"
      title="Justificación"
      permission={permission}
    />
  );
}

export default Justificaciones;
