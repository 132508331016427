import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
  UserOutlined,
  LogoutOutlined,
  QuestionCircleOutlined,
  ArrowLeftOutlined,
  EditOutlined,
} from '@ant-design/icons';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Button,
  Tag,
  Layout,
  Menu,
  Avatar,
  Drawer,
  Typography,
  Modal,
  Tabs,
  Collapse,
} from 'antd';
import 'antd/dist/antd.min.css';
import '../index.scss';
import GlobalStyles from 'themes/global';
import { defSigob } from 'themes';
import { getRoutes } from 'routes/index';
import { LOGOUT } from 'store/reducers/auth';
import LOGO from 'assets/logo.png';
import { faqs, foo } from 'mocks/hardcodedStuff';
import { CLEAR_APP, TOGGLE_SIDEBAR } from 'store/reducers/app/index';
import { CLEAR_CATALOGS } from 'store/reducers/catalogs/index';
import Bread from './Bread';
import Footer from './Footer';
import ThemeConfig from './ThemeConfig';
import LayoutHeader from './LayoutHeader';
import ModalChangePassword from './ModalChangePassword';
import ModalChangeFiscalPeriod from './ModalChangeFiscalPeriod';

const { Panel } = Collapse;
const { TabPane } = Tabs;
const { SubMenu } = Menu;
const { Content, Sider } = Layout;
const { Text } = Typography;

function AppLayout({ children }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(({ auth }) => auth.user);
  const roles = useSelector(({ auth }) => auth.roles);
  const theme = useSelector(({ app }) => app.theme);

  const periodoFiscalSelected = useSelector(({ auth }) => auth.periodoFiscalSelected);
  const collapsedSidebar = useSelector(({ app }) => app.collapsedSidebar);
  const [collapsed, setCollapsed] = useState(collapsedSidebar);
  const [isMobile, setIsMobile] = useState(false);

  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [visibleHelp, setVisibleHelp] = useState(false);

  const [margin, setMargin] = useState();
  const [drawerContent, setDrawerContent] = useState();
  const [drawerTitle, setDrawerTitle] = useState();
  const [collapsedWidth, setCollapsedWidth] = useState(80);
  const [helpImage, setHelpImage] = useState();
  const [faqsList, setFaqsList] = useState([]);

  // const [openKeys, setOpenKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);

  const getSelectedKeys = () => {
    const currentPath = location.pathname;
    const splitedPath = currentPath.split('/').filter((x) => x.length);
    return splitedPath.map((s, is) => `/${splitedPath.filter((a, i) => i <= is).join('/')}`);
  };

  useEffect(() => {
    const selected = getSelectedKeys();
    setSelectedKeys(selected);
    // setOpenKeys(currentPath);
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    if (isMobile) {
      setMargin(0);
      setCollapsedWidth(0);
      setCollapsed(true);
      dispatch({
        type: TOGGLE_SIDEBAR,
        payload: true,
      });
    } else {
      setMargin(collapsedSidebar ? 80 : 300);
      setCollapsedWidth(80);
    }
    // eslint-disable-next-line
  }, [isMobile]);

  useEffect(() => {
    setHelpImage(LOGO);
    setFaqsList(faqs);
  }, []);

  function User() {
    return (
      <div className="user-info">
        <Row>
          <Row className="avatar-wrapper">
            {/* eslint-disable-next-line no-use-before-define */}
            {UserAvatar(false)}
          </Row>
          <Col
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Text strong>
              {`${user?.first_name || ''} ${user?.last_name || ''} ${user?.second_last_name || ''}`}
            </Text>
            <Text>
              {user?.email}
            </Text>

            {!!roles?.length && (
            <Col className="card">
              <Text style={{
                fontSize: 16,
                marginBottom: 5,
              }}
              >
                Roles
              </Text>
              <Row>
                {roles.map((role) => (
                  <React.Fragment key={role.id}>
                    <Tag>
                      {role.name.split('-').shift().trim()}
                    </Tag>
                  </React.Fragment>
                ))}
              </Row>
            </Col>
            )}

            <Col className="card">
              <Text style={{
                fontSize: 16,
                marginBottom: 5,
              }}
              >
                Información del usuario
              </Text>
              {!!user?.unidad_responsable && (
              <Text>
                Unidad responsable:
                {' '}
                {user?.unidad_responsable}
              </Text>
              )}
              {!!user?.funcionario?.puesto && (
              <Text>
                Puesto:
                {' '}
                {user?.funcionario.puesto?.descripcion}
              </Text>
              )}
              {!!user?.funcion && (
              <Text>
                Función:
                {' '}
                {user?.funcion?.descripcion}
              </Text>
              )}
              {!!user?.justificacion && (
              <Text>
                Justificación:
                {' '}
                {user?.justificacion?.descripcion}
              </Text>
              )}
            </Col>

            <Row className="card">
              <Button
                type="link"
                onClick={() => {
                  history.push('/configuracion/cuenta-de-usuario');
                }}
              >
                <UserOutlined />
                Perfil de Usuario
              </Button>
              <Button
                type="link"
                className="update-password no-color"
                onClick={() => {
                  setVisiblePassword(true);
                  setVisibleDrawer(false);
                }}
              >
                <EditOutlined />
                Actualizar Contraseña
              </Button>
              <Button
                type="link"
                danger
                className="logout no-color"
                onClick={() => {
                  dispatch({ type: LOGOUT });
                  dispatch({ type: CLEAR_APP });
                  dispatch({ type: CLEAR_CATALOGS });
                  history.push('/inicio-de-sesion');
                }}
              >
                <LogoutOutlined />
                Cerrar sesión
              </Button>
            </Row>
            <br />
            <br />
            {periodoFiscalSelected && (
            <ModalChangeFiscalPeriod />
            )}
          </Col>
        </Row>
      </div>
    );
  }

  const onClickAvatar = () => {
    setDrawerContent(User);
    setDrawerTitle('Usuario');
    setVisibleDrawer(true);
  };

  const onClickSetting = () => {
    setDrawerContent(<ThemeConfig />);
    setVisibleDrawer(true);
    setDrawerTitle('Configuración de tema');
  };

  const notifications = () => (foo.map((item) => (
    <Row
      key={item.id}
      className={`notification-item ${item.readed ? 'readed' : ''}`}
    >
      <Col>
        <Text style={{ color: '#000' }} strong>
          {item.title}
        </Text>
        <br />
        <Text>
          {item.content}
        </Text>
        <br />
        <Text>{item.date}</Text>
      </Col>
    </Row>
  )));

  const onClickNotifications = () => {
    setDrawerContent(notifications);
    setDrawerTitle('Notificaciones');
    setVisibleDrawer(true);
  };

  function UserAvatar() {
    return (
      <Avatar
        src={user?.foto}
        icon={user?.foto ? null : <UserOutlined />}
        size="large"
      />
    );
  }

  function Help() {
    return (
      <>
        <Button
          id="help-button"
          type="primary"
          shape="circle"
          onClick={() => setVisibleHelp(true)}
          icon={<QuestionCircleOutlined />}
        />
        <Modal
          title={(
            <Row justify="space-between">
              Ayuda
              <Button
                type="link"
                onClick={() => setVisibleHelp(false)}
              >
                <ArrowLeftOutlined />
                Regresar
              </Button>
            </Row>
        )}
          visible={visibleHelp}
          className="modal-help"
          onCancel={() => setVisibleHelp(false)}
          footer={null}
          centered
          closable={false}
        >
          <Tabs defaultActiveKey="media" style={{ width: '100%' }}>
            <TabPane tab="Media" key="media">
              <Row className="img-wrapper">
                <img src={helpImage} alt="demo-help" />
              </Row>
            </TabPane>
            <TabPane tab="FAQ" key="faq">
              <Collapse accordion>
                {faqsList.map((item) => (
                  <Panel header={item.question} key={item.id}>
                    <p>{item.answer}</p>
                  </Panel>
                ))}
              </Collapse>
            </TabPane>
            <TabPane tab="Otros" key="other">
              Más contenido sobre otras preguntas...
            </TabPane>
          </Tabs>
        </Modal>
      </>
    );
  }

  function LinkTo({ to, title }) {
    return (
      <Link to={to}>
        {title}
      </Link>
    );
  }

  LinkTo.propTypes = {
    to: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  };

  const toggle = () => {
    setCollapsed(!collapsed);
    dispatch({
      type: TOGGLE_SIDEBAR,
      payload: !collapsed,
    });
    if (isMobile) {
      setMargin(0);
      setCollapsedWidth(0);
    } else {
      setMargin(!collapsed ? 80 : 300);
      setCollapsedWidth(!collapsed ? 80 : 300);
    }
  };

  const getMenuItems = (
    _routes,
    parentPath = '',
  ) => _routes.map((route) => {
    const {
      title,
      children: routeChildren,
      path,
    } = route;
    const fullPath = `${parentPath}${path}`;
    const Icon = route.icon ? <route.icon /> : <span />;
    if (routeChildren?.length) {
      return (
        <SubMenu
          title={title}
          key={fullPath}
          icon={Icon}
        >
          {(collapsedSidebar && fullPath.split('/').length === 2) && (
            <Menu.Item
              // title={title}
              key={`${fullPath}_header`}
              icon={Icon}
              className="ant-menu-item-active ant-menu-item-active-fixed"
              style={{
                boxShadow: `inset -1px -1px 2px 0px ${theme.backgroundColor}`,
                // borderWidth: '0 1px 1px 0',
                // borderColor: theme.backgroundColor,
                // borderStyle: 'solid',
              }}
            >
              {title}
            </Menu.Item>
          )}
          {getMenuItems(routeChildren, fullPath)}
        </SubMenu>
      );
    }

    return (
      <Menu.Item
        key={fullPath}
        icon={Icon}
        onClick={isMobile ? toggle : null}
      >
        <LinkTo to={fullPath} title={title} />
      </Menu.Item>
    );
  });

  const _routes = getRoutes();
  const routes = periodoFiscalSelected?.vigente
    ? _routes : _routes.filter((e, i) => i !== 2);

  return (
    <ThemeProvider theme={theme || defSigob}>
      <GlobalStyles />
      <Layout>
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          collapsedWidth={collapsedWidth}
          id="left-sider"
          theme="dark"
          width={300}
          breakpoint="md"
          onBreakpoint={(broken) => {
            setIsMobile(broken);
          }}
          style={{
            position: 'absolute',
            top: 64,
            left: 0,
          }}
        >
          <Menu
            id="side-menu"
            mode="inline"
            // TOFIX: submenu popup does not work in controlled mode
            // selectedKeys={selectedKeys}
            // openKeys={collapsedSidebar ? [] : openKeys}
            defaultSelectedKeys={getSelectedKeys()}
            // defaultOpenKeys={collapsedSidebar ? [] : openKeys}
            // onOpenChange={setOpenKeys}
          >
            {getMenuItems(routes)}
          </Menu>
        </Sider>
        <Layout
          className="site-layout"
          style={{ marginLeft: collapsed ? margin : 300, transition: 'all 0.2s' }}
        >
          <LayoutHeader
            isMobile={isMobile}
            toggle={toggle}
            collapsed={collapsed}
            onClickAvatar={onClickAvatar}
            onClickNotifications={onClickNotifications}
            onClickSetting={onClickSetting}
          />
          <Content
            style={{
              left: margin,
              width: `calc(100vw - ${margin}px)`,
              padding: '15px 25px 0 25px',
            }}
          >
            <Bread
              routes={routes}
              isMobile={isMobile}
              currentPath={selectedKeys}
            />
            <div
              className="customCard"
              style={{
                height: `calc(100vh - ${isMobile ? 140 : 175}px)`,
                overflowY: 'scroll',
                minHeight: 230,
              }}
            >
              { children }
            </div>
            <Footer margin={margin} />
          </Content>
          <Drawer
            id="layot-drawer"
            title={drawerTitle}
            placement="right"
            width={isMobile ? '100%' : 400}
            destroyOnClose
            onClose={() => {
              setVisibleDrawer(false);
            }}
            visible={visibleDrawer}
            closable
          >
            {drawerContent}
          </Drawer>
        </Layout>
      </Layout>
      <ModalChangePassword
        visible={visiblePassword}
        setVisible={setVisiblePassword}
      />
      <Help />
    </ThemeProvider>
  );
}

AppLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppLayout;
