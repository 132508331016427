import React, { useState, useEffect } from 'react';
import {
  Card,
  Form,
  Row,
  Spin,
  Tabs,
  Col,
  Input,
  DatePicker,
  Select,
  Space,
  Typography,
  Modal,
} from 'antd';

import API from 'utils/api';
import { onError } from 'utils/handlers';
import {
  FileExcelOutlined, FilePdfOutlined,
} from '@ant-design/icons';
import Table from 'components/Table';
import FormSubmitControls from 'components/FormSubmitControls';
import Uploader from 'components/Uploader';
import _data from 'mocks/solicitudCuentasInternas';

const { TabPane } = Tabs;
const { TextArea } = Input;
const { Text } = Typography;

function FlujoSolicitudCuentasInternas() {
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleModalCreate, setVisibleModalCreate] = useState(false);
  // eslint-disable-next-line max-len
  const [visibleModalCreateTerceroRelacionado, setvisibleModalCreateTerceroRelacionado] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [data, setData] = useState([]);
  const [solicitantes, setSolicitantes] = useState([]);
  const [currentTabKey, setCurrentTabKey] = useState('solicitud');
  const [conceptosSolicitud, setConceptosSolicitud] = useState([]);
  const [prioridades, setPrioridades] = useState([]);
  const [finalidadGastos, setFinalidadGastos] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [mutable, setMutable] = useState(true);
  const basicKeys = [
    'Solicitud',
    'Documento Soporte',
    'Tercero Relacionado',
    'Flujo de Proceso Operativo',
  ];
  const requiredRule = {
    required: true,
    message: 'El campo es requerido',
  };
  const rules = {
    required: [requiredRule],
  };
  const fetchData = async () => {
    try {
      setLoading(true);
      setData(_data);
      setSolicitantes([]);
      setConceptosSolicitud([]);
      setPrioridades([]);
      setFinalidadGastos([]);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchData();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    setTimeout(() => form.setFieldsValue(record));
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickAdd = () => {
    setVisible(true);
  };

  const onClickAddSolicitud = () => {
    setVisibleModalCreate(true);
  };
  const onClickAddTerceroRelacionado = () => {
    setvisibleModalCreateTerceroRelacionado(true);
  };

  const onClickEdit = () => { };

  const onClickDelete = () => { };

  const onChangeTabKey = () => {};

  const onAplicarReserva = () => {

  };

  const onRegresar = () => {

  };

  const onRechazar = () => {};

  const onAutorizar = () => {
  };

  const columns = [
    {
      titleText: 'No. Cuenta Interna',
      dataIndex: 'numero_de_cuenta_interna',
      key: 'numero_de_cuenta_interna',
      width: 200,
    },
    {
      titleText: 'Fecha de Elaboración',
      dataIndex: 'fecha_de_elaboracion',
      key: 'fecha_de_elaboracion',
      width: 200,
    },
    {
      titleText: 'Folio Agrupador',
      dataIndex: 'folio_agrupador',
      key: 'folio_agrupador',
      width: 220,
    },
    {
      titleText: 'Concepto de Solicitud',
      dataIndex: 'concepto_solicitud',
      key: 'concepto_solicitud',
      width: 220,
    },
    {
      titleText: 'Concepto General',
      dataIndex: 'concepto_general',
      key: 'concepto_general',
      width: 200,
    },
    {
      titleText: 'Tipo de Solicitud',
      dataIndex: 'tipo_solicitud',
      key: 'tipo_solicitud',
      width: 200,
    },
    {
      titleText: 'Solicitante',
      dataIndex: 'solicitante',
      key: 'solicitante',
      width: 200,
    },
    {
      titleText: 'Puesto del Solicitante',
      dataIndex: 'puesto_solicitante',
      key: 'puesto_solicitante',
      width: 200,
    },
    {
      titleText: 'Área del Solicitante',
      dataIndex: 'area_del_solicitante',
      key: 'area_del_solicitante',
      width: 200,
    },
    {
      titleText: 'Beneficiario',
      dataIndex: 'beneficiario',
      key: 'beneficiario',
      width: 200,
    },
    {
      titleText: 'Puesto del Beneficiario',
      dataIndex: 'puesto_beneficiario',
      key: 'puesto_beneficiario',
      width: 200,
    },
    {
      titleText: 'Área del Beneficiario',
      dataIndex: 'area_del_beneficiario',
      key: 'area_del_beneficiario',
      width: 200,
    },
    {
      titleText: 'Justificación',
      dataIndex: 'justificacion',
      key: 'justificacion',
      width: 200,
    },
    {
      titleText: 'Tercero Relacionado',
      dataIndex: 'tercero_relacionado',
      key: 'tercero_relacionado',
      width: 200,
    },
    {
      titleText: 'Documento Soporte',
      dataIndex: 'documento_soporte',
      key: 'documento_soporte',
      width: 200,
    },
    {
      titleText: 'Periodo de Pago',
      dataIndex: 'periodo_pago',
      key: 'periodo_pago',
      width: 200,
    },
    {
      titleText: 'Importe Total',
      dataIndex: 'importe_total',
      key: 'importe_total',
      width: 200,
    },
    {
      titleText: 'Prioridad',
      dataIndex: 'prioridad',
      key: 'prioridad',
      width: 200,
    },
    {
      titleText: 'Fecha Requerida de Pago',
      dataIndex: 'fecha_requerida_pago',
      key: 'fecha_requerida_pago',
      width: 250,
    },
    {
      titleText: 'Observaciones',
      dataIndex: 'observaciones',
      key: 'observaciones',
      width: 200,
    },
    {
      titleText: 'Estatus de Solicitud',
      dataIndex: 'status_solicitud',
      key: 'status_solicitud',
      width: 200,
    },
    {
      titleText: 'Estatus de Comprobación',
      dataIndex: 'status_comprobacion',
      key: 'status_comprobacion',
      width: 240,
    },
    {
      titleText: 'No. de Poliza',
      dataIndex: 'no_poliza',
      key: 'no_poliza',
      width: 200,
    },
    {
      titleText: 'No. de Movimiento Presupuestal',
      dataIndex: 'no_movimiento_presupuestal',
      key: 'no_movimiento_presupuestal',
      width: 250,
    },
    {
      titleText: 'No.de Contra-Recibo',
      dataIndex: 'no_contra_recibo',
      key: 'no_contra_recibo',
      width: 200,
    },
    {
      titleText: 'No. de Cuenta por Pagar',
      dataIndex: 'no_cuenta_pagar',
      key: 'no_cuenta_pagar',
      width: 200,
    },
    {
      titleText: 'No. de Orden Pago',
      dataIndex: 'no_orden_pago',
      key: 'no_orden_pago',
      width: 200,
    },
    {
      titleText: 'No. de Pago',
      dataIndex: 'no_pago',
      key: 'no_pago',
      width: 200,
    },
  ];

  const columnsSolicitud = [
    {
      titleText: 'Rubro',
      dataIndex: 'rubro',
      key: 'rubro',
      width: 150,
    },
    {
      titleText: 'Clave Presupuestal',
      dataIndex: 'clave_presupuestal',
      key: 'clave_presupuestal',
      width: 150,
    },
    {
      titleText: 'COG',
      dataIndex: 'cog',
      key: 'cog',
      width: 150,
    },
    {
      titleText: 'Concepto COG',
      dataIndex: 'concepto_cog',
      key: 'concepto_cog',
      width: 200,
    },
    {
      titleText: 'Techo Presupuestal',
      dataIndex: 'techo_presupuestal',
      key: 'techo_presupuestal',
      width: 200,
    },
    {
      titleText: 'Importe',
      dataIndex: 'importe',
      key: 'importe',
      width: 150,
    },
  ];

  const columnsDocumentoSoporte = [
    {
      titleText: 'Documento Soporte',
      dataIndex: 'documento_soporte',
      key: 'documento_soporte',
      width: 150,
    },
    {
      titleText: 'Fecha',
      dataIndex: 'fecha',
      key: 'fecha',
      width: 150,
    },
    {
      titleText: 'Folio',
      dataIndex: 'folio',
      key: 'folio',
      width: 150,
    },
    {
      titleText: 'Importe Total',
      dataIndex: 'importe_total',
      key: 'importe_total',
      width: 150,
    },
  ];

  const columnsTerceroRelacionado = [
    {
      titleText: 'Tercero',
      dataIndex: 'tercero',
      key: 'tercero',
      width: 200,
    },
    {
      titleText: 'Concepto',
      dataIndex: 'concepto',
      key: 'concepto',
      width: 200,
    },
    {
      titleText: 'Importe',
      dataIndex: 'importe',
      key: 'importe',
      width: 200,
    },
  ];

  const columnsFlujoProceso = [
    {
      titleText: 'No.',
      dataIndex: 'no',
      key: 'no',
      width: 100,
    },
    {
      titleText: 'Actividad',
      dataIndex: 'actividad',
      key: 'concepto',
      width: 150,
    },
    {
      titleText: 'Rol Firma',
      dataIndex: 'rol_firma',
      key: 'rol_firma',
      width: 150,
    },
    {
      titleText: 'Fecha de Realización',
      dataIndex: 'fecha_realizacion',
      key: 'fecha_realizacion',
      width: 200,
    },
    {
      titleText: 'Puesto',
      dataIndex: 'puesto',
      key: 'puesto',
      width: 150,
    },
    {
      titleText: 'Empleado',
      dataIndex: 'empleado',
      key: 'empleado',
      width: 150,
    },
    {
      titleText: 'Área',
      dataIndex: 'area',
      key: 'area',
      width: 150,
    },
    {
      titleText: 'Estatus de Proceso',
      dataIndex: 'estatus_proceso',
      key: 'estatus_proceso',
      width: 200,
    },
    {
      titleText: 'Documento a Adjuntar',
      dataIndex: 'documento_adjuntar',
      key: 'documento_adjuntar',
      width: 250,
    },
    {
      titleText: 'Documento que se Adjunta',
      dataIndex: 'documento_adjunta',
      key: 'documento_adjunta',
      width: 250,
    },
    {
      titleText: 'Falta Adjunto',
      dataIndex: 'falta_adjunto',
      key: 'falta_adjunto',
      width: 250,
    },
    {
      titleText: 'Autorizado/Rechazado',
      dataIndex: 'autorizado_rechazado',
      key: 'autorizado_rechazado',
      width: 200,
    },
    {
      titleText: 'Causas de Rechazo',
      dataIndex: 'causas_rechazo',
      key: 'causas_rechazo',
      width: 200,
    },
    {
      titleText: 'Observaciones',
      dataIndex: 'observaciones',
      key: 'observaciones',
      width: 150,
    },
    {
      titleText: 'Días de Dilación',
      dataIndex: 'dias_de_dilacion',
      key: 'dias_de_dilacion',
      width: 150,
    },
  ];

  const controls = () => ({
    onClickAdd,
    onClickEdit,
    onClickDelete,
  });

  const onFinish = () => ({});
  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    setSelectedRowKeys([]);
    setMutable(true);
    setCurrentTabKey('solicitud');
  };
  const onCancelModalSolicitud = () => {
    setVisibleModalCreate(false);
  };
  const onCancelModalTerceroRelacionado = () => {
    setvisibleModalCreateTerceroRelacionado(false);
  };
  const complexForms = [{ key: 'solicitud', formInstance: null }];
  const handleOnFinish = (vals, _continue = false) => {
    const match = complexForms.find((e) => e.key === currentTabKey);
    if (match) {
      match.formInstance.onFinishHandler(null, _continue);
    }
  };

  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible
          ? (
            <Table
              cols={columns}
              data={[]}
              rowSelection={rowSelection}
              handleOnRowClick={handleOnRowClick}
              controls={controls()}
              mobileColIndex={0}
              customActions={[
                {
                  icon: <FilePdfOutlined />,
                  text: 'Descargar PDF',
                  disabledBySelection: false,
                },
                {
                  icon: <FileExcelOutlined />,
                  text: 'Descargar Excel',
                  disabledBySelection: false,
                },
              ]}
            />
          )
          : (
            <>
              <Row gutter={10}>
                <Col md={24} sm={24} xs={24}>
                  <Form
                    layout="vertical"
                    form={form}
                    onFinish={onFinish}
                    scrollToFirstError
                    name="solicitud"
                  >
                    <Row gutter={10}>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="numero_de_solicitud"
                          label="No. de Solicitud"
                        >
                          <Input allowClear disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="fecha_elaboracion"
                          rules={[]}
                          label="Fecha de Elaboración"
                          hasFeedback
                        >
                          <Input allowClear disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="folio_agrupado"
                          rules={[]}
                          label="Folio Agrupador"
                          hasFeedback
                        >
                          <Input allowClear disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="solicitante"
                          rules={rules.required}
                          label="Solicitante"
                          hasFeedback
                        >
                          <Select dataSource={solicitantes} />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="puesto_solicitante"
                          label="Puesto Solicitante"
                          hasFeedback
                        >
                          <Input allowClear disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="area_solicitante"
                          label="Área Solicitante"
                          hasFeedback
                        >
                          <Input allowClear disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="elaboro"
                          label="Elaboró"
                          hasFeedback
                        >
                          <Input allowClear disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="puesto_elaboro"
                          label="Puesto Elaboró"
                          hasFeedback
                        >
                          <Input allowClear disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="area_elaboro"
                          label="Área Elaboró"
                          hasFeedback
                        >
                          <Input allowClear disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="justificacion"
                          label="Justificación"
                          hasFeedback
                        >
                          <Input allowClear disabled={!!selectedRowKeys.length} />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="observaciones"
                          label="Observaciones"
                          hasFeedback
                        >
                          <TextArea rows={6} />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="estado"
                          label="Estado"
                          hasFeedback
                        >
                          <Input allowClear disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="concepto_solicitud"
                          rules={rules.required}
                          label="Concepto de Solicitud"
                          hasFeedback
                        >
                          <Select dataSource={conceptosSolicitud} />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="concepto_general"
                          label="Concepto General"
                          hasFeedback
                        >
                          <Input allowClear disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="prioridad"
                          rules={rules.required}
                          label="Prioridad"
                          hasFeedback
                        >
                          <Select dataSource={prioridades} />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="fecha_requerida_pago"
                          rules={rules.required}
                          label="Fecha Requerida de Pago"
                          hasFeedback
                        >
                          <DatePicker
                            placeholder=""
                            format="DD/MM/YYYY"
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="periodo_pago"
                          label="Periodo de Pago"
                          rules={rules.required}
                          hasFeedback
                        >
                          <Input allowClear disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="finalidad_gastos"
                          rules={rules.required}
                          label="Finalidad del Gasto"
                          hasFeedback
                        >
                          <Select dataSource={finalidadGastos} />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="forma_pago"
                          label="Forma de Pago"
                          hasFeedback
                        >
                          <Input allowClear />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="banco"
                          label="Banco"
                          hasFeedback
                        >
                          <Input allowClear disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="clabe_interbancaria"
                          label="Clabe Interbancaria"
                          hasFeedback
                        >
                          <Input allowClear disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="fecha_pago"
                          label="Fecha de Pago"
                          hasFeedback
                        >
                          <Input allowClear disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="fecha_maxima_cmprobacion"
                          label="Fecha Máxima de Comprobación"
                          hasFeedback
                        >
                          <Input allowClear disabled />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
              <Card
                className="form-container"
                title={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Solicitud`}
                extra={(
                  <FormSubmitControls
                    onFinish={basicKeys
                      .concat(complexForms.map((e) => e.key))
                      .includes(currentTabKey)
                      ? handleOnFinish : null}
                    onCancel={onCancel}
                    loading={loading}
                  />
                )}
                bordered={false}
              >
                <Tabs
                  activeKey={currentTabKey}
                  onChange={mutable ? setCurrentTabKey : onChangeTabKey}
                  type="card"
                >
                  <TabPane
                    tab="Solicitud"
                    key="solicitud"
                    forceRender
                  >
                    <Space direction="vertical">
                      <Row gutter={10}>
                        <Col xs={12} sm={12} md={12}>
                          <Text>COG</Text>
                        </Col>
                        <Col xs={12} sm={12} md={12} span={8}>
                          <Input allowClear disabled defaultValue="37501 Víaticos del pais" />
                        </Col>
                      </Row>
                      <Row gutter={10}>
                        <Col xs={12} sm={12} md={12}>
                          <Text>Unidad Responsable</Text>
                        </Col>
                        <Col xs={12} sm={12} md={12} span={8}>
                          <Input allowClear disabled defaultValue="504 contabilidad" />
                        </Col>
                      </Row>
                      <Row gutter={10}>
                        <Col xs={12} sm={12} md={12}>
                          <Text>Fuente de Financiamiento</Text>
                        </Col>
                        <Col xs={12} sm={12} md={12} span={8}>
                          <Input allowClear disabled defaultValue="11721 Recursos municipales" />
                        </Col>
                      </Row>
                      <Row gutter={10}>
                        <Col xs={12} sm={12} md={12}>
                          <Text>Programa</Text>
                        </Col>
                        <Col xs={12} sm={12} md={12} span={8}>
                          <Input allowClear disabled defaultValue="Contabilidad y egresos" />
                        </Col>
                      </Row>
                      <Row gutter={10}>
                        <Col xs={12} sm={12} md={12}>
                          <Text>Proyecto</Text>
                        </Col>
                        <Col xs={12} sm={12} md={12} span={8}>
                          <Input allowClear disabled defaultValue="001 operación" />
                        </Col>
                      </Row>
                    </Space>
                    <Table
                      cols={columnsSolicitud}
                      data={[]}
                      rowSelection={rowSelection}
                      handleOnRowClick={handleOnRowClick}
                      customActions={[
                        {
                          onClick: onAplicarReserva,
                          text: 'Aplicar Reserva',
                          disabledBySelection: false,
                        },
                        {
                          onClick: onAutorizar,
                          text: 'Autorizar',
                          disabledBySelection: false,
                        },
                        {
                          onClick: onRechazar,
                          text: 'Rechazar',
                          disabledBySelection: false,
                        },
                        {
                          onClick: onRegresar,
                          text: 'Regresar',
                          disabledBySelection: false,
                        },
                        {
                          icon: <FilePdfOutlined />,
                          text: 'Descargar PDF',
                          disabledBySelection: false,
                        },
                        {
                          icon: <FileExcelOutlined />,
                          text: 'Descargar Excel',
                          disabledBySelection: false,
                        },
                      ]}
                      controls={{
                        onClickAdd,
                      }}
                      mobileColIndex={0}
                    />
                  </TabPane>
                  <TabPane
                    tab="Documento Soporte"
                    key="documento_soporte"
                    forceRender
                  >
                    <Form
                      layout="vertical"
                    >
                      <Row gutter={10}>
                        <Col span={24}>
                          <Uploader
                            title="Adjuntar Documento Soporte"
                            limitMB={50}
                            file={form.getFieldValue('documento_soporte')}
                            formItemName="documento_soporte"
                            allowedExtensions={['pdf']}
                            allowPreview
                          />
                        </Col>
                      </Row>
                    </Form>
                    <Table
                      cols={columnsDocumentoSoporte}
                      data={[]}
                      rowSelection={rowSelection}
                      handleOnRowClick={handleOnRowClick}
                      mobileColIndex={0}
                      controls={{
                        onClickAdd: () => onClickAddSolicitud(),
                        onClickDelete,
                        onClickEdit,
                      }}
                      customActions={[
                        {
                          onClick: onRegresar,
                          text: 'Regresar',
                          disabledBySelection: false,
                        },
                        {
                          icon: <FilePdfOutlined />,
                          text: 'Descargar PDF',
                          disabledBySelection: false,
                        },
                        {
                          icon: <FileExcelOutlined />,
                          text: 'Descargar Excel',
                          disabledBySelection: false,
                        },
                      ]}
                    />
                  </TabPane>
                  <TabPane
                    tab="Tercero Relacionado"
                    key="tercero_relacionado"
                    forceRender
                  >
                    <Table
                      cols={columnsTerceroRelacionado}
                      data={[]}
                      rowSelection={rowSelection}
                      handleOnRowClick={handleOnRowClick}
                      controls={{
                        onClickAdd: () => onClickAddTerceroRelacionado(),
                        onClickDelete,
                        onClickEdit,
                      }}
                      customActions={[
                        {
                          onClick: onRegresar,
                          text: 'Regresar',
                          disabledBySelection: false,
                        },
                        {
                          icon: <FilePdfOutlined />,
                          text: 'Descargar PDF',
                          disabledBySelection: false,
                        },
                        {
                          icon: <FileExcelOutlined />,
                          text: 'Descargar Excel',
                          disabledBySelection: false,
                        },
                      ]}
                      mobileColIndex={0}
                    />
                  </TabPane>
                  <TabPane
                    tab="Flujo de Proceso Operativo"
                    key="flujo_proceso_operativo"
                    forceRender
                  >
                    <Table
                      cols={columnsFlujoProceso}
                      data={[]}
                      rowSelection={rowSelection}
                      mobileColIndex={0}
                      scrollToFirstError
                      customActions={[
                        {
                          icon: <FilePdfOutlined />,
                          text: 'Descargar PDF',
                          disabledBySelection: false,
                        },
                        {
                          icon: <FileExcelOutlined />,
                          text: 'Descargar Excel',
                          disabledBySelection: false,
                        },
                      ]}
                      handleOnRowClick={handleOnRowClick}
                    />
                  </TabPane>
                </Tabs>
              </Card>
            </>
          )}
        <Modal
          visible={visibleModalCreate}
          closable={false}
          maskClosable={!loading}
          keyboard={!loading}
          footer={null}
          title={(
            <FormSubmitControls
              label={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Comprobante de Solicitud de Pago`}
              onFinish={onFinish}
              onCancel={onCancelModalSolicitud}
              loading={loading}
            />
        )}
          onCancel={onCancelModalSolicitud}
          forceRender
        >
          <Form
            form={form}
            layout="vertical"
            name="contacto"
            onFinish={onFinish}
            initialValues={{ estados_globales: 1 }}
          >
            <Col span={24}>
              <Form.Item
                name="tipo_comprobante"
                label="Tipo de Comprobante"
                rules={rules.required}
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="fecha"
                label="Fecha"
                rules={rules.required}
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="folio"
                label="Folio"
                hasFeedback
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="importe"
                label="Importe"
                rules={rules.required}
                hasFeedback
              >
                <Input allowClear />
              </Form.Item>
            </Col>
          </Form>
        </Modal>
        <Modal
          visible={visibleModalCreateTerceroRelacionado}
          closable={false}
          maskClosable={!loading}
          keyboard={!loading}
          footer={null}
          title={(
            <FormSubmitControls
              label={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Tercero Relacionado`}
              onFinish={onFinish}
              onCancel={onCancelModalTerceroRelacionado}
              loading={loading}
            />
        )}
          onCancel={onCancelModalTerceroRelacionado}
          forceRender
        >
          <Form
            form={form}
            layout="vertical"
            name="contacto"
            onFinish={onFinish}
            initialValues={{ estados_globales: 1 }}
          >
            <Col span={24}>
              <Form.Item
                name="tercero"
                label="Tercero"
                rules={rules.required}
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="concepto"
                label="Concepto"
                rules={rules.required}
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="importe"
                label="Importe"
                rules={rules.required}
                hasFeedback
              >
                <Input allowClear />
              </Form.Item>
            </Col>
          </Form>
        </Modal>
      </Spin>
    </Row>
  );
}

export default FlujoSolicitudCuentasInternas;
