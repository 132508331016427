/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Button,
  Col,
  Form,
  Modal,
  Row,
  Spin,
  Typography,
  message,
  Card,
} from 'antd';

import Table from 'components/Table';
import ModalDelete from 'components/ModalDelete';
import FormSubmitControls from 'components/FormSubmitControls';
import Select from 'components/Select';
import { onError, onSuccess } from 'utils/handlers';
import API from 'utils/api';
import { isMutable } from 'utils/estadosGlobales';
import { InputSearchPro } from 'components/InputSearch';
import fetchSome from 'utils/fetchSome';
import { SET_URS } from 'store/reducers/catalogs';

import ConfigsPresupuestos from 'components/Catalogos/ConfigPresupuestos';
import { filterArbolado } from 'utils/filters';
import makeThree from 'utils/makeTree';

export const permissionConfiguracionPresupuestalIngreso = {
  permissionModel: 'configuracionpresupuestaldeingreso',
};

const { Text } = Typography;

const baseURI = '/configuraciones/configuraciones-presupuestales-de-ingresos/';

const levelSorter = (a, b) => {
  if (a.nivel < b.nivel) {
    return -1;
  }
  if (a.nivel > b.nivel) {
    return 1;
  }
  return 0;
};

const uris = {
  1: '/unidades-operativas/',
  2: '/unidades-responsables/',
  3: '/centros-de-costos/',
};

// eslint-disable-next-line react/prop-types
function ConfiguracionesDePresupuestosDeIngreso({ permission }) {
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  const periodoFiscalSelected = useSelector(({ auth }) => auth.periodoFiscalSelected);
  const urs = useSelector(({ catalogs }) => catalogs.urs).sort(levelSorter);
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mutable, setMutable] = useState(true);

  const [ursFound, setUrsFound] = useState([]);
  const [urContentType, setUrContentType] = useState();

  const [catalogosCRI, setCatalogosCRI] = useState([]);
  const [clases, setClases] = useState([]);
  const [filteredCRI, setFilteredCRI] = useState([]);

  const [fuentes, setFuentes] = useState([]);
  const [filteredFuentes, setFilteredFuentes] = useState();
  const [flatFuentes, setFlatFuentes] = useState();
  const [conceptosDeIngresos, setConceptosDeIngresos] = useState([]);

  const normalizeDataCRI = (
    _data = [],
    excludeLvl3 = false,
    currLvl = 1,
  ) => _data.map((item) => {
    const clone = { ...item };
    const children = normalizeDataCRI(!excludeLvl3
      || currLvl !== 2 ? clone.children : [], excludeLvl3, currLvl + 1);
    const selectable = currLvl === 3;
    if (!children.length) {
      delete clone.children;
      return ({
        ...clone,
        key: `${clone.clave}`,
        title: `${clone.clave} - ${clone.denominacion}`,
        value: clone.clave,
        selectable,
        disabled: clone.seleccionable,
      });
    }
    return ({
      ...clone,
      key: `${clone.clave}`,
      children,
      title: `${clone.clave} - ${clone.denominacion}`,
      value: clone.clave,
      selectable,
      disabled: !clone.seleccionable,
    });
  });

  const normalize = (_data = []) => _data.map((item) => {
    const clone = { ...item };
    const children = clone.children?.length && !clone.seleccionable
      ? normalize(clone.children) : [];
    if (!children.length) {
      delete clone.children;
      return ({
        ...clone,
        key: clone.clave,
        value: clone.clave,
        clave: clone.clave,
        seleccionable: !clone.seleccionable,
        disabled: !clone.seleccionable,
        title: `${clone.clave} - ${clone.nombre}`,
      });
    }
    return ({
      ...clone,
      key: `${clone.clave}_padre`,
      value: `${clone.clave}_padre`,
      children,
      disabled: !clone.seleccionable,
      seleccionable: clone.seleccionable,
      title: `${clone.clave} - ${clone.nombre}`,
    });
  });

  const fetchData = async () => {
    try {
      setLoading(true);
      if (!urs?.length) {
        await fetchSome('/catalogos/content-types-ur/', SET_URS);
      }
      const response = await API.get(baseURI, {
        params: {
          periodo_fiscal: periodoFiscalSelected.id,
        },
      });
      setData(response.data);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const fetchAll = async () => {
    try {
      setLoading(true);

      const resClases = await API.get('configuraciones/clases/');
      setClases(resClases.data);

      const resClaseCRI = await API.get('contabilidad/cri/');
      const mappedClases = normalizeDataCRI(resClaseCRI.data);
      setCatalogosCRI(mappedClases);

      const resFF = await API.get('contabilidad/clasificaciones-de-fuentes-de-financiamiento/');
      setFlatFuentes(resFF.data);
      const fuentesFTree = makeThree(resFF.data);
      setFuentes(normalize(fuentesFTree));

      const resConceptosDeIngresos = await API.get('configuraciones/conceptos-de-ingresos/');
      setConceptosDeIngresos(resConceptosDeIngresos.data);

      await fetchData();
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useState(() => {
    fetchAll();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setVisible(false);
    setSelectedRowKeys([]);
    form.resetFields();
    setMutable(true);
    setUrsFound([]);
    setUrContentType({});
  };

  const onFinish = async (values, _continue) => {
    try {
      setLoading(true);
      await form.validateFields();
      // const values = form.getFieldsValue();
      // eslint-disable-next-line no-param-reassign
      values.content_type_ur = urContentType;
      if (!selectedRowKeys.length) {
        const response = await API.post(baseURI, values);
        if (response?.status === 201) {
          onSuccess(response, 'Agregado correctamente');
          if (!_continue) {
            onCancel();
            await fetchData();
          }
          return response.data;
        }
      } else {
        const [key] = selectedRowKeys;
        const response = await API.put(`${baseURI}${key}/`, values);
        if (response?.status === 200) {
          onSuccess(response, 'Actualizado correctamente');
          if (!_continue) {
            onCancel();
            await fetchData();
          }
          return response.data;
        }
      }
      setLoading(false);
      return null;
    } catch (err) {
      onError(err, setLoading);
      return null;
    }
  };

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
  };

  const columns = [
    {
      titleText: 'Ejercicio',
      dataIndex: 'periodo_fiscal',
      key: 'periodo_fiscal',
      width: 200,
      render: (val) => [periodoFiscalSelected].find((e) => e.id === val)?.periodo,
    },
    {
      titleText: 'Estado',
      dataIndex: 'estados_globales',
      key: 'estados_globales',
      width: 200,
      render: (val) => estadosGlobales.find((t) => t.id === val)?.descripcion,
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    form.setFieldsValue({
      ...record,
    });
    setMutable(isMutable(record));
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const filterCatalogs = (match) => {
    if (match) {
      if (match?.clase_CRI.length) {
        const keysClases = match.clase_CRI.map((e) => clases.find((i) => i.id === e)?.clave);
        const filteredCRIData = filterArbolado(
          keysClases,
          JSON.parse(JSON.stringify(catalogosCRI)),
          { mixed: true },
        );
        setFilteredCRI(filteredCRIData);
      }

      if (match?.fuentes_de_financiamiento_interna.length) {
        let keysFF = [];
        if (match.fuentes_de_financiamiento_interna.every((e) => typeof e === 'number')) {
          keysFF = match.fuentes_de_financiamiento_interna
            .map((e) => flatFuentes.find((c) => c.id === e)?.clave);
        } else {
          keysFF = match.fuentes_de_financiamiento_interna.map((e) => e.clave);
        }
        const filteredFF = filterArbolado(
          keysFF,
          JSON.parse(JSON.stringify(fuentes)),
          { mixed: true },
        );
        setFilteredFuentes(filteredFF);
      }
    }
  };

  const onClickEdit = async () => {
    try {
      setLoading(true);
      const [key] = selectedRowKeys;
      const clone = [...data];
      const match = clone.find((e) => e.id === key);
      setUrContentType(match.content_type_ur);
      const { nivel } = urs.find((e) => e.id === match.content_type_ur);
      const resUR = await API
        .get(`/estructura-organizacional${uris[nivel]}${match.object_id_ur}`);
      setUrsFound([resUR.data]);
      filterCatalogs(resUR.data);
      setVisible(true);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`${baseURI}${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          setVisibleAlert(false);
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const busquedaUR = async (values) => {
    try {
      setLoading(true);
      await form.validateFields(['content_type', 'q_ur']);
      const { content_type, q_ur: q } = values;
      if (content_type && q) {
        setUrContentType(content_type);
        const { nivel } = urs.find((e) => e.id === content_type);
        const response = await API.get(`/estructura-organizacional${uris[nivel]}`, {
          params: {
            q,
            estados_globales: 4,
            es_UR_presupuestal_de_ingreso: true,
          },
        });

        if (response?.status === 200 && response?.data?.length) {
          form.setFieldsValue({
            object_id_ur: response.data[0].id,
          });
          setUrsFound([response.data[0]]);
        } else if (response?.status === 204) {
          message.info('No se encontraron coincidencias');
          setUrsFound([]);
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const resetAndValidateURs = () => {
    setUrsFound([]);
    form.resetFields(['object_id']);
    form.validateFields(['object_id', 'q_ur']);
  };

  function Formulario({ modal }) {
    let props;
    let inpSearchProps;
    if (modal) {
      props = {
        span: 24,
      };
      inpSearchProps = {
        md: 24,
      };
    } else {
      props = {
        xs: 24,
        sm: 24,
        md: 8,
      };
      inpSearchProps = {
        xs: 24,
        sm: 24,
        md: 16,
        lg: 8,
      };
    }

    const onPressEnter = () => {
      const values = form.getFieldsValue();
      if (!loading && values) {
        busquedaUR(values);
      }
    };

    return (
      <Form
        layout="vertical"
        form={form}
        onFinish={() => onFinish(form.getFieldsValue())}
        scrollToFirstError
        initialValues={{
          estados_globales: 1,
          periodo_fiscal: periodoFiscalSelected.id,
        }}
      >
        <Row gutter={10}>
          <InputSearchPro
            label="Unidad responsable"
            tooltip="Búsqueda por clave o descripción"
            name="q_ur"
            onPressEnter={onPressEnter}
            prefix={{
              style: {
                marginTop: 'auto',
                width: '45%',
                minWidth: 180,
              },
              name: 'content_type',
              label: 'Nivel',
              render: (e) => e.model,
              dataSource: urs,
            }}
            breakPoints={inpSearchProps}
            inputProps={{
              onPressEnter,
              onChange: ({ target: { value } }) => {
                if (!value) {
                  resetAndValidateURs();
                }
              },
            }}
            resultProps={{
              name: 'object_id_ur',
              label: 'Unidad responsable',
              dataSource: ursFound,
              keyLabelRender: true,
              onClear: resetAndValidateURs,
              disabled: !mutable,
              breakPoints: inpSearchProps,
            }}
          />
          <Col {...props}>
            <Form.Item
              label="Ejercicio"
              rules={rules.required}
              name="periodo_fiscal"
            >
              <Select
                dataSource={[periodoFiscalSelected]}
                disabled
                render={(e) => `${e.periodo} - ${e.identificador}`}
              />
            </Form.Item>
          </Col>
          <Col {...props}>
            <Form.Item
              label="Estado"
              rules={rules.required}
              name="estados_globales"
            >
              <Select
                dataSource={estadosGlobales}
                disabled={!selectedRowKeys.length || !mutable}
              />
            </Form.Item>
          </Col>
          {!modal && (
            <ConfigsPresupuestos
              idConfiguracion={selectedRowKeys[0]}
              isMutable={mutable}
              clasesCRI={filteredCRI}
              fuentes={filteredFuentes}
              flatCRI={clases}
              flatFuentes={flatFuentes}
              disabled={!mutable}
              conceptosDeIngresos={conceptosDeIngresos}
            />
          )}
          <Form.Item hidden>
            <Button htmlType="submit" />
          </Form.Item>
        </Row>
      </Form>
    );
  }

  Formulario.propTypes = {
    modal: PropTypes.bool,
  };

  Formulario.defaultProps = {
    modal: false,
  };

  const normalizeDataAuthorized = (estados_globales) => {
    const [key] = selectedRowKeys;
    const normalized = [...data]
      .map((e) => (e.id === key ? { ...e, estados_globales } : e));
    setMutable(false);
    const match = normalized.find((e) => e.id === key);
    setData(normalized);
    form.setFieldsValue(match);
  };

  const validateAuthorizable = async (match) => {
    try {
      setLoading(true);
      const response = await API.get('configuraciones/cri-de-configuraciones-de-presupuestos/', {
        params: {
          configuracion_de_presupuesto: match.id,
        },
      });

      if (response.status === 200 && response.data.length) {
        setLoading(false);
        return true;
      }
      message.warn('Es necesario tener mínimo una configuración registrada');
      setLoading(false);
      return false;
    } catch (err) {
      onError(err, setLoading);
      return false;
    }
  };

  return (
    <Row>
      <Spin tip="Cargando..." spinning={loading}>
        <Col span={24}>
          {!(visible && selectedRowKeys.length) && (
            <Table
              mobileColIndex={0}
              permission={permission}
              cols={columns}
              data={data}
              rowSelection={rowSelection}
              handleOnRowClick={handleOnRowClick}
              controls={{
                onClickAdd,
                onClickEdit,
                onClickDelete,
              }}
              allowImport
              baseURI={baseURI}
            />
          )}
          {(visible && !!selectedRowKeys.length) && (
          <Card
            className="form-container"
            title={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Configuración`}
            extra={(
              <FormSubmitControls
                onFinish={() => onFinish(form.getFieldsValue())}
                onCancel={onCancel}
                mutable={mutable}
                loading={loading}
                baseURI={baseURI}
                callback={normalizeDataAuthorized}
                selectedRowKeys={selectedRowKeys}
                allowAuthorize
                allowCancel
                useSuffix
                validateAuthorizable={validateAuthorizable}
              />
            )}
            bordered={false}
          >
            <Formulario />
          </Card>
          )}
          {!selectedRowKeys.length && (
            <Modal
              visible={visible}
              title={(
                <FormSubmitControls
                  label={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Configuración`}
                  onFinish={() => onFinish(form.getFieldsValue())}
                  onCancel={onCancel}
                />
          )}
              onCancel={onCancel}
              footer={null}
              closable={false}
              forceRender
            >
              <Formulario modal />
            </Modal>
          )}
        </Col>
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => setVisibleAlert(false)}
          visible={visibleAlert}
          content={(
            <Text style={{ fontSize: 16 }}>
              ¿Desea eliminar la configuración seleccionada?
            </Text>
          )}
        />
      </Spin>
    </Row>
  );
}

export default ConfiguracionesDePresupuestosDeIngreso;
