import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Spin,
  Modal,
  message,
} from 'antd';
import { onError } from 'utils/handlers';
import Table from 'components/Table';
import FormSubmitControls from 'components/FormSubmitControls';
import ModalDelete from 'components/ModalDelete';
import Select from 'components/Select';
import createClave from 'utils/keyGenerator';
import { isMutable, isAuthorized } from 'utils/estadosGlobales';

function SingleForm({
  title,
  needsKey,
  needsConcepto,
  readOnly,
  withoutState,
  descripcionTextArea,
  needsDescription,
  needsName,
  customFormItems,
  get,
  post,
  patch,
  delete: _delete,
  permission,
}) {
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);

  const columns = [
    needsKey && {
      titleText: 'Clave',
      dataIndex: 'clave',
      key: 'clave',
      width: 100,
    },
    needsName && {
      titleText: 'Nombre',
      dataIndex: 'nombre',
      key: 'nombre',
      width: 200,
    },
    needsConcepto && {
      titleText: 'Concepto',
      dataIndex: 'concepto',
      key: 'concepto',
      width: 100,
    },
    needsDescription && {
      titleText: 'Descripción',
      dataIndex: 'descripcion',
      key: 'descripcion',
      width: !descripcionTextArea ? 200 : 450,
    },
    !withoutState && {
      titleText: 'Estado',
      key: 'estados_globales',
      dataIndex: 'estados_globales',
      width: 150,
      render: (val) => estadosGlobales.find((e) => e.id === val)?.descripcion,
    },
  ].filter((e) => e);

  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);
  const [mutable, setMutable] = useState(true);

  const inputKeyRef = useRef();
  const inputConceptoRef = useRef();
  const inputNameRef = useRef();
  const inputDescriptionRef = useRef();

  const fetchData = async () => {
    setLoading(true);
    const _data = await get();
    setData(_data);
    setLoading(false);
  };

  const useFetch = () => {
    useEffect(() => {
      fetchData();
      // eslint-disable-next-line
    }, []);
  };

  useFetch();

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    setSelectedRowKeys([]);
    setMutable(true);
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      const [key] = selectedRowKeys;
      const success = await (key ? patch(key, values) : post(values));
      if (success) {
        onCancel();
        await fetchData();
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const deleteItem = async () => {
    setLoading(true);
    const [key] = selectedRowKeys;
    const deleted = await _delete(key);
    if (deleted) {
      setVisibleAlert(false);
      onCancel();
      await fetchData();
    }
    setLoading(false);
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    setMutable(isMutable(record));
    setTimeout(() => form.setFieldsValue(record));
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickAdd = () => {
    onCancel();
    createClave(data, form);
    setVisible(true);
  };

  const onClickEdit = () => setVisible(true);

  const onClickDelete = () => {
    const [key] = selectedRowKeys;
    const match = data.find((e) => e.id === key);
    if (isMutable(match)) {
      setVisibleAlert(true);
    } else {
      message.warn(`No se puede eliminar un ${title} ${isAuthorized(match) ? 'autorizado' : 'cancelado'}`);
    }
  };

  const onClickExpand = () => setVisible(true);

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
  };

  const controls = () => {
    if (readOnly) {
      return {
        onClickExpand,
      };
    }
    return {
      onClickAdd,
      onClickEdit,
      onClickDelete,
    };
  };

  const getTitle = () => {
    if (readOnly) {
      return 'Vista previa';
    }
    return `${selectedRowKeys.length ? 'Editar' : 'Agregar'}`;
  };

  const getRules = () => {
    if (readOnly || !mutable) {
      return null;
    }
    return rules.required;
  };

  useEffect(() => {
    if (visible) {
      if (needsKey) {
        setTimeout(inputKeyRef.current.focus);
      } else if (needsConcepto) {
        setTimeout(inputConceptoRef.current.focus);
      } else if (needsName) {
        setTimeout(inputNameRef.current.focus);
      } else if (needsDescription) {
        setTimeout(inputDescriptionRef.current.focus);
      }
    }
    // eslint-disable-next-line
  }, [visible]);

  const disabled = !mutable || readOnly;

  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        <Table
          cols={columns}
          data={data}
          rowSelection={rowSelection}
          handleOnRowClick={handleOnRowClick}
          controls={controls()}
          mobileColIndex={0}
          permission={permission}
          hasAllViewPermission
        />
        <Modal
          visible={visible}
          title={(
            <FormSubmitControls
              label={getTitle()}
              onFinish={!readOnly || mutable ? () => onFinish() : null}
              onCancel={onCancel}
              loading={loading}
              mutable={mutable}
              permission={permission}
            />
          )}
          onCancel={onCancel}
          footer={null}
          closable={false}
          maskClosable={!loading}
          keyboard={!loading}
          forceRender
        >
          <Form
            layout="vertical"
            form={form}
            onFinish={() => onFinish()}
            scrollToFirstError
            className={disabled ? 'without-feedback' : ''}
            initialValues={{
              estados_globales: 1,
            }}
          >
            {needsKey && (
              <Col span={24}>
                <Form.Item
                  name="clave"
                  rules={getRules()}
                  label="Clave"
                  hasFeedback={!readOnly}
                >
                  <Input
                    allowClear
                    ref={inputKeyRef}
                    disabled={selectedRowKeys.length || disabled}
                  />
                </Form.Item>
              </Col>
            )}
            {needsConcepto && (
              <Col span={24}>
                <Form.Item
                  name="concepto"
                  rules={getRules()}
                  label="Concepto"
                  hasFeedback={!readOnly}
                >
                  <Input
                    allowClear
                    ref={inputConceptoRef}
                    disabled={disabled}
                  />
                </Form.Item>
              </Col>
            )}
            {needsName && (
              <Col span={24}>
                <Form.Item
                  name="nombre"
                  rules={getRules()}
                  label="Nombre"
                  hasFeedback={!readOnly}
                >
                  <Input
                    allowClear
                    ref={inputNameRef}
                    disabled={disabled}
                  />
                </Form.Item>
              </Col>
            )}
            {needsDescription && (
              <Col span={24}>
                <Form.Item
                  name="descripcion"
                  rules={getRules()}
                  label="Descripción"
                  hasFeedback={!readOnly}
                >
                  {!descripcionTextArea ? (
                    <Input
                      allowClear
                      ref={inputDescriptionRef}
                      disabled={disabled}
                    />
                  ) : (
                    <Input.TextArea
                      allowClear
                      autoSize={{ minRows: 4, maxRows: 4 }}
                      ref={inputDescriptionRef}
                      disabled={disabled}
                    />
                  )}
                </Form.Item>
              </Col>
            )}
            {!!customFormItems && (
              <Col span={24}>
                {customFormItems}
              </Col>
            )}
            {!withoutState && (
              <Col span={24}>
                <Form.Item
                  name="estados_globales"
                  label="Estado"
                  rules={getRules()}
                  hasFeedback={!readOnly}
                >
                  <Select
                    disabled={!selectedRowKeys.length || !mutable || readOnly}
                    dataSource={estadosGlobales}
                  />
                </Form.Item>
              </Col>
            )}
            <Form.Item name="id" hidden>
              <Input />
            </Form.Item>
            <Form.Item hidden>
              <Button htmlType="submit" />
            </Form.Item>
          </Form>
        </Modal>
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => {
            setVisibleAlert(false);
            setSelectedRowKeys([]);
          }}
          visible={visibleAlert}
          content={`${title} ${form.getFieldValue('descripcion')}`}
          loading={loading}
        />
      </Spin>
    </Row>
  );
}

SingleForm.propTypes = {
  title: PropTypes.string,
  needsKey: PropTypes.bool,
  readOnly: PropTypes.bool,
  withoutState: PropTypes.bool,
  needsConcepto: PropTypes.bool,
  descripcionTextArea: PropTypes.bool,
  needsDescription: PropTypes.bool,
  needsName: PropTypes.bool,
  customFormItems: PropTypes.node,
  get: PropTypes.func,
  post: PropTypes.func,
  patch: PropTypes.func,
  delete: PropTypes.func,
  permission: PropTypes.shape({
    add: PropTypes.bool.isRequired,
    authorize: PropTypes.bool.isRequired,
    cancel: PropTypes.bool.isRequired,
    change: PropTypes.bool.isRequired,
    delete: PropTypes.bool.isRequired,
    export: PropTypes.bool.isRequired,
    import: PropTypes.bool.isRequired,
    sync: PropTypes.bool.isRequired,
    view: PropTypes.bool.isRequired,
  }),
};

SingleForm.defaultProps = {
  needsKey: false,
  readOnly: false,
  needsConcepto: false,
  withoutState: false,
  descripcionTextArea: false,
  needsDescription: true,
  title: 'undefined',
  needsName: false,
  customFormItems: <span />,
  get: null,
  post: null,
  patch: null,
  delete: null,
  permission: null,
};

export default SingleForm;
