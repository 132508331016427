import React, { useState, useEffect } from 'react';
import {
  Form,
  Button,
  Row,
  Col,
  Spin,
  message,
  Input,
  Modal,
} from 'antd';
import PropTypes from 'prop-types';

import {
  DoubleRightOutlined,
  RightOutlined,
} from '@ant-design/icons';

import Table from 'components/Table';
import FormSubmitControls from 'components/FormSubmitControls';
import ModalDelete from 'components/ModalDelete';
import Select from 'components/Select';
import API from 'utils/api';
import { onError, onSuccess } from 'utils/handlers';

const baseURI = 'configuraciones/configuraciones-de-segmentos-contables/';

function Segmentos({
  mascara,
  separador,
  isMutable,
  setSegmentos,
  idConfiguracion,
}) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [tiposSegmentos, setTiposSegmentos] = useState([]);
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const lenghts = mascara.split(separador).map((e) => e.length);
      if (idConfiguracion) {
        const res = await API.get(`${baseURI}${idConfiguracion}/segmentos/`);
        if (res?.status === 200) {
          let dataWithKeys = res.data.sort((a, b) => a.posicion - b.posicion);
          dataWithKeys = dataWithKeys.map((item, idx) => ({
            ...item,
            tamanio_de_mascara: lenghts[idx],
            modificable: item.posicion !== 1,
            disabled: item.posicion === 1,
            cuenta: item.cuenta?.id || item.cuenta,
            tipo: item.tipo?.id || item.tipo,
          }));
          setSegmentos(dataWithKeys);
          setData(dataWithKeys);
        }
      } else {
        const withoutInitial = lenghts.filter((_, i) => i !== 0);
        const defSegmentos = withoutInitial.map((e, idx) => ({
          id: idx + 2,
          posicion: idx + 2,
          tipo: 9,
          tamanio_de_mascara: e,
          disabled: false,
          modificable: true,
        }));
        const initial = [{
          id: 1,
          posicion: 1,
          tipo: 1,
          tamanio_de_mascara: 5,
          disabled: true,
          modificable: false,
        }, ...defSegmentos];
        setSegmentos(initial);
        setData(initial);
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const fetchAll = async () => {
    try {
      setLoading(true);
      const resTipos = await API.get('catalogos/tipos-de-segmentos-contables/');
      setTiposSegmentos(resTipos.data || []);
      await fetchData();
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchAll();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchData();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, [idConfiguracion]);

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    setSelectedRowKeys([]);
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      const clone = [...data];
      if (!idConfiguracion) {
        const [id] = selectedRowKeys;
        const res = clone
          .map((item, idx) => (item.id === id
            ? {
              ...values,
              posicion: idx + 1,
              modificable: true,
            }
            : item))
          .map((item, idx) => ({ ...item, id: idx }));
        setData(res);
        setSegmentos(res);
        onCancel();
      } else {
        const [key] = selectedRowKeys;
        const res = await API.patch(`${baseURI}${idConfiguracion}/segmentos/${key}/`, values);
        if (res?.status === 200) {
          onSuccess(res, 'Actualizado correctamente');
          onCancel();
          await fetchData();
        }
      }

      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const deleteItem = async () => {
    try {
      const [key] = selectedRowKeys;
      const clone = data.filter((item) => item.id !== key);
      const normalized = clone.map((item, id) => ({ ...item, id }));
      setData(normalized);
      setSegmentos(normalized);
      onCancel();
      setVisibleAlert(false);
    } catch (err) {
      onError(err);
    }
  };

  const handleOnRowClick = (record) => {
    const clone = { ...record };
    const { tipo } = clone;
    const _tipo = typeof tipo === 'number'
      ? tipo : tipo.id;
    delete clone.tipo;
    if (isMutable && !clone.disabled) {
      setSelectedRowKeys([clone.id]);
      form.setFieldsValue({ ...clone, tipo: _tipo });
    }
  };

  const rowSelection = {
    type: 'radio',
    selectedRowKeys,
    getCheckboxProps: (record) => ({ disabled: record.disabled }),
  };

  const onClickEdit = () => {
    const [key] = selectedRowKeys;
    if (key && isMutable) {
      const match = data.find((e) => e.id === key);
      if (isMutable && match.modificable) {
        setVisible(true);
      } else {
        message.info('No se pueden editar los segmentos previos');
      }
    }
  };

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
  };

  const columns = [
    {
      titleText: 'Posición',
      dataIndex: 'posicion',
      key: 'posicion',
      width: 100,
    },
    {
      titleText: 'Tipo',
      dataIndex: 'tipo',
      key: 'tipo',
      width: 130,
      render: (val) => tiposSegmentos.find((t) => t.id === val)?.nombre || val?.nombre,
    },
    {
      titleText: 'Tamaño',
      dataIndex: 'tamanio_de_mascara',
      key: 'tamanio_de_mascara',
      width: 100,
    },
  ];

  const moveToLocal = (where) => {
    const [key] = selectedRowKeys;
    const match = data.find((item) => item.id === key);
    const filtered = data.filter((item) => item.id !== key);
    switch (where) {
      case 'first':
        filtered.splice(1, 0, match);
        break;
      case 'up':
        filtered.splice(match.posicion - 2, 0, match);
        break;
      case 'down':
        filtered.splice(match.posicion, 0, match);
        break;
      case 'last':
        filtered.splice(data.length, 0, match);
        break;
      default:
        break;
    }
    const lenghts = mascara.split(separador).map((e) => e.length);
    const final = filtered.map((i, idx) => ({
      id: idx,
      tipo: i.tipo,
      posicion: idx + 1,
      tamanio_de_mascara: lenghts[idx],
    })).sort((a, b) => a.posicion.toString().localeCompare(b.posicion));
    setData(final);
    setSegmentos(final);
  };

  const moveTo = async (where) => {
    try {
      setLoading(true);
      if (idConfiguracion) {
        const [key] = selectedRowKeys;
        const match = data.find((item) => item.id === key);
        let posicion = 0;
        switch (where) {
          case 'first':
            posicion = 2;
            break;
          case 'up':
            posicion = match.posicion - 1;
            break;
          case 'down':
            posicion = match.posicion + 1;
            break;
          case 'last':
            posicion = data.length;
            break;
          default:
            posicion = match.posicion;
            break;
        }
        const res = await API.patch(`${baseURI}${key}/`, {
          posicion,
        });
        if (res?.status === 200) {
          await fetchData();
        }
      } else {
        moveToLocal(where);
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const sortTypes = () => {
    const sorted = tiposSegmentos.map((t) => ({
      ...t,
      disabled: !!data.find((i) => (i.tipo.id === t.id || i.tipo === t.id)
        && t.nombre.toLowerCase() !== 'control interno'),
    }));
    setTiposSegmentos(sorted.sort((a, b) => a.disabled.toString().localeCompare(b.disabled)));
  };

  useEffect(() => {
    sortTypes();
    // eslint-disable-next-line
  }, [data]);

  const restProps = isMutable ? {
    rowSelection,
    handleOnRowClick,
  } : {};

  function SortControls() {
    const [key] = selectedRowKeys;
    const prioridad = data.find((e) => e.id === key)?.prioridad;
    return (
      <Col className="sorting">
        <Button
          icon={<DoubleRightOutlined rotate={-90} />}
          disabled={!key || prioridad <= 3}
          onClick={() => moveTo('first')}
          type="link"
        />
        <Button
          icon={<RightOutlined rotate={-90} />}
          disabled={!key || prioridad < 3}
          onClick={() => moveTo('up')}
          type="link"
        />
        <Button
          icon={<RightOutlined rotate={90} />}
          disabled={!key || prioridad === data.length}
          onClick={() => moveTo('down')}
          type="link"
        />
        <Button
          icon={<DoubleRightOutlined rotate={90} />}
          disabled={!key || prioridad === data.length || prioridad === data.length - 1}
          onClick={() => moveTo('last')}
          type="link"
        />
      </Col>
    );
  }

  return (
    <Row id="presupuestos-segmentos" className="container">
      <Spin tip="Cargando..." spinning={loading}>
        <Row>
          <Col style={{ width: `calc(100% - ${isMutable ? 50 : 0}px)` }}>
            <Table
              cols={columns}
              data={data}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...restProps}
              controls={{
                onClickEdit,
              }}
              allowEdit={isMutable}
              allowSort={false}
              allowSearch={false}
            />
          </Col>
          {isMutable && <SortControls />}
        </Row>
        <Modal
          visible={visible}
          title={(
            <FormSubmitControls
              label={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Segmento`}
              onFinish={onFinish}
              onCancel={onCancel}
              loading={loading}
            />
          )}
          closable={false}
          maskClosable={!loading}
          keyboard={!loading}
          onCancel={onCancel}
          footer={null}
        >
          <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
            scrollToFirstError
          >
            <Row gutter={10}>
              <Col span={24}>
                <Form.Item
                  label="Tamaño"
                  name="tamanio_de_mascara"
                  rules={rules.required}
                  hasFeedback
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="tipo"
                  label="Tipo"
                  rules={rules.required}
                  hasFeedback
                >
                  <Select dataSource={tiposSegmentos} labelProp="nombre" />
                </Form.Item>
              </Col>
              <Form.Item hidden>
                <Button htmlType="submit" />
              </Form.Item>
            </Row>
          </Form>
        </Modal>
      </Spin>
      <ModalDelete
        onDelete={deleteItem}
        onCancel={() => setVisibleAlert(false)}
        visible={visibleAlert}
        content={`Segmento en posición ${form.getFieldValue('posicion')}`}
        loading={loading}
      />
    </Row>
  );
}

Segmentos.propTypes = {
  currentURI: PropTypes.string,
  mascara: PropTypes.string,
  separador: PropTypes.string,
  idConfiguracion: PropTypes.number,
  isMutable: PropTypes.bool.isRequired,
  setSegmentos: PropTypes.func.isRequired,
};

Segmentos.defaultProps = {
  mascara: 'XXXXX',
  separador: null,
  currentURI: null,
  idConfiguracion: null,
};

export default Segmentos;
