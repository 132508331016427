import React, { useState, useEffect } from 'react';
import {
  Form,
  Button,
  Row,
  Col,
  Spin,
  message,
  Modal,
  Input,
} from 'antd';
import PropTypes from 'prop-types';
import {
  DoubleRightOutlined,
  RightOutlined,
} from '@ant-design/icons';

import API from 'utils/api';
import { onError, onSuccess } from 'utils/handlers';
import Table from 'components/Table';
import NumericInput from 'components/NumericInput';
import FormSubmitControls from 'components/FormSubmitControls';
import ModalDelete from 'components/ModalDelete';
import Select from 'components/Select';

function SegmentosFoliador({
  isMutable,
  setSegmentos,
  idConfiguracion,
}) {
  const baseURI = '/configuraciones/configuraciones-de-folios/:id/segmentos/'
    .replace(':id', idConfiguracion);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [data, setData] = useState([]);
  const [tiposCaracteres, setTiposCaracteres] = useState([]);
  const [valores, setValores] = useState([]);
  const [filterdValores, setFilterdValores] = useState([]);
  const [consecutivos, setConsecutivos] = useState([]);
  const [isConsecutivo, setIsConsecutivo] = useState(false);
  const [isFijo, setIsFijo] = useState(false);

  const fetchData = async () => {
    try {
      setLoading(true);
      if (idConfiguracion) {
        const res = await API.get(baseURI);
        if (res?.status === 200) {
          let dataWithKeys = res.data.map((item) => ({
            ...item,
            modificable: false,
            deletable: false,
          }));
          dataWithKeys = dataWithKeys
            .sort((a, b) => a.posicion.toString().localeCompare(b.posicion));
          setSegmentos(dataWithKeys);
          setData(dataWithKeys);
        } else {
          const segmentoDefault = [{
            tipo_de_segmento: 2,
            longitud: 4,
            folio_siguiente: 1,
            id: 1,
            posicion: 1,
            modificable: true,
            deletable: false,
          }];
          setSegmentos(segmentoDefault);
          setData(segmentoDefault);
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const fetchAll = async () => {
    try {
      setLoading(true);
      const resTiposCaracteres = await API.get('/configuraciones/tipos-de-segmentos-de-folios/');
      setTiposCaracteres(resTiposCaracteres.data);
      const resConsecutivos = await API.get('catalogos/periodos/');
      setConsecutivos(resConsecutivos.data);
      const resValores = await API.get('/configuraciones/valores-de-segmentos-de-folios/');
      setValores(resValores.data.filter((e) => e.id !== 1));
      await fetchData();
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchAll();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [idConfiguracion]);

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    setSelectedRowKeys([]);
    setIsConsecutivo(false);
    setFilterdValores([]);
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      const clone = [...data];
      if (!idConfiguracion) {
        if (!selectedRowKeys.length) {
          clone.push({
            ...values,
            posicion: clone.length + 1,
            id: clone.length + 1,
            modificable: true,
            deletable: true,
          });
          setData(clone);
          setSegmentos(clone);
          onCancel();
        } else {
          const [key] = selectedRowKeys;
          const res = clone.map((item) => (item.id === key
            ? { ...values, posicion: item.posicion, key } : item));
          setData(res);
          setSegmentos(res);
          onCancel();
        }
      } else {
        const res = await API.post(baseURI, {
          ...values,
          posicion: data.length + 1,
          configuracion_de_folio: idConfiguracion,
        });
        if (res && res.status === 201) {
          onSuccess(res, 'Agregado correctamente');
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const deleteItem = () => {
    const [key] = selectedRowKeys;
    const clone = data.filter((item) => item.id !== key);
    const normalized = clone.map((item, idx) => ({ ...item, id: idx }));
    setData(normalized);
    setSegmentos(normalized);
    onCancel();
    setVisibleAlert(false);
  };

  const handleOnRowClick = (record) => {
    if (!record.disabled) {
      setSelectedRowKeys([record.id]);
      form.setFieldsValue(record);
    }
  };

  const rowSelection = {
    type: 'radio',
    selectedRowKeys,
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const onClickEdit = () => {
    const [key] = selectedRowKeys;
    const match = data.find((e) => e.id === key);
    if (match.modificable) {
      setVisible(true);
    } else {
      message.info('No se pueden editar los segmentos previos');
      setSelectedRowKeys([]);
    }
  };

  const onClickDelete = () => {
    const [key] = selectedRowKeys;
    const match = data.find((e) => e.id === key);
    if (match && match.deletable) {
      setVisibleAlert(true);
    } else {
      message.info('No se pueden eliminar los segmentos previos');
    }
  };

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
  };

  const getTipoCaracter = (record, returnId = false) => {
    const valor = valores.find((v) => v.id === record.valor);
    let id = valor ? valor.tipo_de_segmento : 2;
    id = record.valor_fijo ? 4 : id;
    if (returnId) {
      return id;
    }
    const tipo = tiposCaracteres.find((t) => t.id === id);
    return tipo ? tipo.descripcion || tipo.nombre : '';
  };

  const columns = [
    {
      titleText: 'Posición',
      dataIndex: 'posicion',
      key: 'posicion',
      width: 100,
    },
    {
      titleText: 'Tipo de caracter',
      dataIndex: 'tipo_de_segmento',
      key: 'tipo_de_segmento',
      width: 150,
      render: (val, record) => getTipoCaracter(record),
    },
    {
      titleText: 'Longitud',
      dataIndex: 'longitud',
      key: 'longitud',
      width: 100,
    },
    {
      titleText: 'Valor fijo/siguiente',
      dataIndex: 'valor',
      key: 'valor',
      width: 180,
      render: (val, record) => {
        let output;
        let valor;
        const tipo = getTipoCaracter(record, true);
        switch (tipo) {
          case 1:
            valor = valores.find((t) => t.id === val);
            output = valor ? valor.clave : '';
            break;
          case 2:
            output = record.folio_siguiente || '';
            break;
          case 3:
            valor = valores.find((t) => t.id === val);
            output = valor ? valor.clave : '';
            break;
          case 4:
            output = record.valor_fijo;
            break;
          default:
            break;
        }
        return output || '';
      },
    },
  ];

  const moveToLocal = (where) => {
    const [key] = selectedRowKeys;
    const match = data.find((item) => item.id === key);
    const filtered = data.filter((item) => item.id !== key);
    switch (where) {
      case 'first':
        filtered.splice(0, 0, match);
        break;
      case 'up':
        filtered.splice(match.posicion - 2, 0, match);
        break;
      case 'down':
        filtered.splice(match.posicion, 0, match);
        break;
      case 'last':
        filtered.splice(data.length, 0, match);
        break;
      default:
        break;
    }
    const final = filtered.map((i, idx) => ({ ...i, posicion: idx + 1 }));
    setData(final);
    setSegmentos(final);
  };

  const moveTo = async (where) => {
    try {
      setLoading(true);
      if (idConfiguracion) {
        const [key] = selectedRowKeys;
        const match = data.find((item) => item.id === key);
        let posicion = 0;
        switch (where) {
          case 'first':
            posicion = 1;
            break;
          case 'up':
            posicion = match.posicion - 1;
            break;
          case 'down':
            posicion = match.posicion + 1;
            break;
          case 'last':
            posicion = data.length;
            break;
          default:
            posicion = match.posicion;
            break;
        }
        const res = await API.patch(`${baseURI}${key}/`, {
          posicion,
        });
        if (res?.status === 200) {
          await fetchData();
        }
      } else {
        moveToLocal(where);
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const sortTypes = (arr = tiposCaracteres) => {
    const sorted = arr.map((t) => ({
      ...t,
      disabled: !!data.find((i) => i.tipo_de_segmento_contable === t.id
        && t.descripcion.toLowerCase() !== 'control interno'),
    }));
    setTiposCaracteres(sorted.sort((a, b) => a.disabled.toString().localeCompare(b.disabled)));
  };

  useEffect(() => {
    sortTypes();
    // eslint-disable-next-line
  }, [data]);

  const restProps = isMutable ? {
    rowSelection,
    handleOnRowClick,
  } : {};

  function SortControls() {
    const [key] = selectedRowKeys;
    const prioridad = data.find((e) => e.id === key)?.prioridad;
    return (
      <Col className="sorting">
        <Button
          icon={<DoubleRightOutlined rotate={-90} />}
          disabled={!key || prioridad <= 3}
          onClick={() => moveTo('first')}
          type="link"
        />
        <Button
          icon={<RightOutlined rotate={-90} />}
          disabled={!key || prioridad < 3}
          onClick={() => moveTo('up')}
          type="link"
        />
        <Button
          icon={<RightOutlined rotate={90} />}
          disabled={!key || prioridad === data.length}
          onClick={() => moveTo('down')}
          type="link"
        />
        <Button
          icon={<DoubleRightOutlined rotate={90} />}
          disabled={!key || prioridad === data.length || prioridad === data.length - 1}
          onClick={() => moveTo('last')}
          type="link"
        />
      </Col>
    );
  }

  const onChangeValor = (val) => {
    if ([1, 2, 4].includes(val)) {
      form.setFieldsValue({ longitud: 2 });
    } else if (val === 3) {
      form.setFieldsValue({ longitud: 3 });
    } else if (val === 5) {
      form.setFieldsValue({ longitud: 4 });
    }
  };

  return (
    <Row id="presupuestos-segmentos" className="container">
      <Spin tip="Cargando..." spinning={loading}>
        <Row>
          <Col style={{ width: `calc(100% - ${isMutable ? 50 : 0}px)` }}>
            <Table
              cols={columns}
              data={data}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...restProps}
              controls={{
                onClickAdd,
                onClickEdit,
                onClickDelete,
              }}
              allowSearch={false}
              allowSort={false}
              allowAdd={isMutable}
              allowEdit={!idConfiguracion}
              allowDelete={!idConfiguracion}
            />
          </Col>
          {isMutable && <SortControls />}
        </Row>
        <Modal
          visible={visible}
          title={(
            <FormSubmitControls
              onFinish={onFinish}
              onCancel={onCancel}
              loading={loading}
              label={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Segmento`}
            />
          )}
          closable={false}
          maskClosable={!loading}
          keyboard={!loading}
          onCancel={onCancel}
          footer={null}
        >
          <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
            scrollToFirstError
            onValuesChange={(changedValues, allValues) => {
              const { valor_fijo, valor } = changedValues;
              if (valor_fijo) {
                form.setFieldsValue({ longitud: valor_fijo.length });
              } else if (valor && allValues.tipo_de_segmento !== 3) {
                const val = valores.find((v) => v.id === valor);
                form.setFieldsValue({ longitud: val.clave.length });
              } else if (valor_fijo === '') {
                form.setFieldsValue({ longitud: 0 });
              }
            }}
          >
            <Col span={24}>
              <Form.Item
                name="tipo_de_segmento"
                label="Tipo de caracter"
                rules={rules.required}
                hasFeedback
              >
                <Select
                  labelProp="nombre"
                  dataSource={tiposCaracteres}
                  onChange={async (val) => {
                    if (val) {
                      setIsConsecutivo(val === 2);
                      setIsFijo(val === 4);
                      setFilterdValores(valores.filter((e) => e.tipo_de_segmento === val));
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <NumericInput
                disabled
                label="Longitud"
                name="longitud"
                required
              />
            </Col>
            {(!isConsecutivo && !isFijo && form.getFieldValue('tipo_de_segmento')) && (
              <Col span={24}>
                <Form.Item
                  label="Valor"
                  name="valor"
                  hasFeedback
                  rules={rules.required}
                >
                  <Select
                    dataSource={filterdValores}
                    keyLabelRender
                    onChange={form.getFieldValue('tipo_de_segmento') === 3
                      ? onChangeValor : null}
                  />
                </Form.Item>
              </Col>
            )}
            {isConsecutivo && (
              <Col span={24}>
                <NumericInput
                  label="Valor inicial"
                  name="folio_siguiente"
                  required
                  onChange={(a) => {
                    const { value } = a.target;
                    if (value) {
                      form.setFieldsValue({ longitud: value.length });
                    }
                  }}
                />
              </Col>
            )}
            {isFijo && (
              <Col span={24}>
                <Form.Item
                  label="Valor fijo"
                  name="valor_fijo"
                  rules={rules.required}
                  hasFeedback
                >
                  <Input maxLength={10} className="uppercase" />
                </Form.Item>
              </Col>
            )}
            {isConsecutivo && (
              <Col span={24}>
                <Form.Item
                  name="catalogo_de_periodo"
                  rules={rules.required}
                  label="Consecutivo"
                  hasFeedback
                >
                  <Select dataSource={consecutivos} labelProp="nombre" />
                </Form.Item>
              </Col>
            )}
            <Form.Item hidden>
              <Button htmlType="submit" />
            </Form.Item>
          </Form>
        </Modal>
      </Spin>
      <ModalDelete
        onDelete={deleteItem}
        onCancel={() => setVisibleAlert(false)}
        visible={visibleAlert}
        content={`Segmento en posición ${form.getFieldValue('posicion')}`}
        loading={loading}
      />
    </Row>
  );
}

SegmentosFoliador.propTypes = {
  idConfiguracion: PropTypes.string,
  isMutable: PropTypes.bool.isRequired,
  setSegmentos: PropTypes.func.isRequired,
};

SegmentosFoliador.defaultProps = {
  idConfiguracion: null,
};

export default SegmentosFoliador;
