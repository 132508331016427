import { store } from 'store/index';

const symbols = {
  MXP: '$',
  HNL: '',
  SM: 'SM',
  UMA: 'UMA',
};

const formatter = new Intl.NumberFormat('en-us', {
  style: 'currency',
  currency: 'USD',
});

const currencyFormatter = (val, withoutCurrencySymbol = false) => {
  const value = parseFloat(val) || 0;
  let output = formatter.format(value);
  output = output.replace('$', '');
  if (withoutCurrencySymbol) {
    return output;
  }
  const codigo = store.getState().auth?.entidad?.configuracion?.moneda?.codigo_de_la_moneda
   || 'MXP';
  const symbol = symbols[codigo];
  return `${symbol}${output}`;
};

export default currencyFormatter;
