import API from 'utils/api';
import { onError } from 'utils/handlers';

const baseURI = 'configuraciones/';

/**
 * GET Unidades De Medida
 * @returns {Promise<Array<object>>}
 */
// eslint-disable-next-line import/prefer-default-export
export const getUnidadesDeMedida = async () => {
  try {
    const response = await API.get(`${baseURI}unidades-de-medida`);
    return response.data;
  } catch (err) {
    onError(err);
    return [];
  }
};

/**
 * GET Tipo De Firma
 * @returns {Promise<Array<object>>}
 */
// eslint-disable-next-line import/prefer-default-export
export const getTipoDeFirma = async () => {
  try {
    const response = await API.get(`${baseURI}tipos-de-firma-de-cuentas-bancarias`);
    return response.data;
  } catch (err) {
    onError(err);
    return [];
  }
};

/**
 * GET Impuestos
 * @returns {Promise<Array<object>>}
 */
// eslint-disable-next-line import/prefer-default-export
export const getImpuestos = async () => {
  try {
    const response = await API.get(`${baseURI}impuestos`);
    return response.data;
  } catch (err) {
    onError(err);
    return [];
  }
};

/**
 * GET Tipos De Póliza
 * @returns {Promise<Array<object>>}
 */
// eslint-disable-next-line import/prefer-default-export
export const getTiposDePoliza = async () => {
  try {
    const response = await API.get(`${baseURI}impuestos`);
    return response.data;
  } catch (err) {
    onError(err);
    return [];
  }
};
