/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect, useRef } from 'react';
import {
  Button,
  Col,
  Form,
  Modal,
  Row,
  Spin,
  Tag,
  TreeSelect,
} from 'antd';
import PropTypes from 'prop-types';

import Table from 'components/Table';
import FormSubmitControls from 'components/FormSubmitControls';
import ModalDelete from 'components/ModalDelete';
import Select from 'components/Select';
import API from 'utils/api';
import { onError, onSuccess } from 'utils/handlers';
import { findItemNested } from 'utils/filters';

function ConfigsPresupuestos({
  idConfiguracion,
  mutable,
  clasesCRI,
  fuentes,
  flatCRI,
  flatFuentes,
  conceptosDeIngresos,
  disabled,
}) {
  const baseURI = 'configuraciones/cri-de-configuraciones-de-presupuestos/';
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [filteredConceptos, setFilteredConceptos] = useState([]);

  const fetchData = async () => {
    try {
      setLoading(true);

      if (idConfiguracion) {
        const res = await API.get(baseURI, {
          params: {
            configuracion_de_presupuesto: idConfiguracion,
          },
        });
        if (res?.status === 200) {
          setData(res.data);
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchData();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, [idConfiguracion]);

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    setSelectedRowKeys([]);
    setFilteredConceptos([]);
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      values.clase_cri = findItemNested(clasesCRI, values.clase_cri, 'clave')?.id;
      values.fuente_de_financiamiento = findItemNested(fuentes, values.fuente_de_financiamiento, 'clave')?.id;
      values.configuracion_de_presupuesto = idConfiguracion;
      if (!selectedRowKeys.length) {
        const response = await API.post(baseURI, values);
        if (response?.status === 201) {
          onSuccess(response, 'Agregado correctamente');
          onCancel();
          await fetchData();
        }
      } else {
        const [key] = selectedRowKeys;
        values.clase_cri = findItemNested(clasesCRI, values.clase_cri)?.id;
        values.fuente_de_financiamiento = findItemNested(
          fuentes,
          values.fuente_de_financiamiento,
        )?.id;
        const response = await API.put(`${baseURI}${key}/`, values);
        if (response?.status === 200) {
          onSuccess(response, 'Actualizado correctamente');
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`${baseURI}${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          setVisibleAlert(false);
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    const clase_cri = flatCRI.find((e) => e.id === record.clase_cri)?.clave;
    const fuente_de_financiamiento = flatFuentes
      .find((e) => e.id === record.fuente_de_financiamiento)?.clave;
    const _filteredConceptos = conceptosDeIngresos.filter((e) => e.clase_cri === record.clase_cri);
    setFilteredConceptos(_filteredConceptos);
    setSelectedRowKeys([record.id]);
    form.setFieldsValue({
      ...record,
      clase_cri,
      fuente_de_financiamiento,
    });
  };

  const rowSelection = {
    type: 'radio',
    selectedRowKeys,
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const onClickEdit = () => {
    setVisible(true);
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
  };

  const columns = [
    {
      titleText: 'Clasificación por Rubro de Ingreso',
      dataIndex: 'clase_cri',
      key: 'clase_cri',
      width: 300,
      render: (val) => {
        const match = flatCRI.find((e) => e.id === val);
        if (match) {
          return `${match?.clave || ''} - ${match?.denominacion || ''}`;
        }
        return '-';
      },
    },
    {
      titleText: 'Fuente de Financiamiento',
      dataIndex: 'fuente_de_financiamiento',
      key: 'fuente_de_financiamiento',
      width: 300,
      render: (val) => {
        const match = flatFuentes.find((e) => e.id === val);
        if (match) {
          return `${match?.clave || ''} - ${match?.nombre || ''}`;
        }
        return '-';
      },
    },
    {
      titleText: 'Conceptos de Ingresos',
      dataIndex: 'conceptos_de_ingreso',
      key: 'conceptos_de_ingreso',
      width: 300,
      render: (val = []) => {
        if (val?.length && conceptosDeIngresos?.length) {
          const matches = val.map((e) => conceptosDeIngresos?.find((i) => i?.id === e));
          if (matches.length) {
            const output = matches.map((e) => (
              <Tag key={e?.id}>{`${e.clave} - ${e.descripcion}`}</Tag>
            ));
            return output;
          }
        }
        return (<span />);
      },
    },
  ];

  const timeoutFilter = useRef();

  const filterTreeNode = (input, node) => {
    const title = node.title?.props?.children.toLowerCase() || node.title?.toLowerCase();
    if (title && title.includes(input.toLowerCase())) {
      return true;
    }
    if (node?.children) {
      return filterTreeNode(input, node.children);
    }
    return false;
  };

  const setTimeoutFilter = (input, node) => setTimeout(() => filterTreeNode(input, node), 1000);

  const filterTree = (input, node) => {
    clearTimeout(timeoutFilter.current);
    timeoutFilter.current = setTimeoutFilter(input, node);
    return timeoutFilter ? filterTreeNode(input, node) : true;
  };

  const onChangeCRI = (value) => {
    if (value) {
      const match = flatCRI.find((e) => e.clave === value);
      const _filtered = conceptosDeIngresos.filter((e) => e.clase_cri === match.id);
      setFilteredConceptos(_filtered);
    } else {
      setFilteredConceptos([]);
    }
  };

  return (
    <Row id="presupuestos-segmentos" className="container">
      <Spin tip="Cargando..." spinning={loading}>
        <Row>
          <Table
            mobileColIndex={0}
            cols={columns}
            data={data}
            handleOnRowClick={handleOnRowClick}
            rowSelection={rowSelection}
            controls={{
              onClickAdd,
              onClickEdit,
              onClickDelete,
            }}
            allowSearch={false}
            allowSort={false}
            allowImport
            baseURI={baseURI}
            disabled={disabled}
          />
        </Row>
      </Spin>
      <Modal
        visible={visible}
        title={(
          <FormSubmitControls
            label={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Configuración de Presupuesto`}
            onFinish={onFinish}
            onCancel={onCancel}
            loading={loading}
          />
        )}
        width={565}
        closable={false}
        maskClosable={!loading}
        keyboard={!loading}
        onCancel={onCancel}
        footer={null}
      >
        <Form
          layout="vertical"
          form={form}
          name="segmento_presupuestal"
          onFinish={onFinish}
          scrollToFirstError
          initialValues={{ estados_globales: 1 }}
        >
          <Col span={24}>
            <Form.Item
              name="clase_cri"
              rules={rules.required}
              label="CRI"
              hasFeedback
            >
              <TreeSelect
                showSearch
                showArrow
                treeNodeFilterProp="title"
                style={{ width: '100%' }}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                treeData={clasesCRI}
                filterTreeNode={filterTree}
                disabled={!mutable}
                onChange={onChangeCRI}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="fuente_de_financiamiento"
              rules={rules.required}
              label="Fuente de financiamiento"
              hasFeedback
            >
              <TreeSelect
                showSearch
                showArrow
                treeNodeFilterProp="title"
                style={{ width: '100%' }}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                treeData={fuentes}
                filterTreeNode={filterTree}
                disabled={!mutable}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="conceptos_de_ingreso"
              label="Concepto de ingreso"
              rules={rules.required}
              hasFeedback
            >
              <Select
                dataSource={filteredConceptos}
                disabled={!mutable}
                mode="multiple"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="estados_globales"
              rules={rules.required}
              label="Estado"
              hasFeedback
            >
              <Select globalStates disabled={!selectedRowKeys.length || !mutable} />
            </Form.Item>
          </Col>
          <Form.Item hidden>
            <Button htmlType="submit" />
          </Form.Item>
        </Form>
      </Modal>
      <ModalDelete
        onDelete={deleteItem}
        onCancel={() => setVisibleAlert(false)}
        visible={visibleAlert}
        content="la configuración seleccionada"
      />
    </Row>
  );
}

ConfigsPresupuestos.propTypes = {
  idConfiguracion: PropTypes.number,
  mutable: PropTypes.bool,
  clasesCRI: PropTypes.arrayOf(PropTypes.array),
  fuentes: PropTypes.arrayOf(PropTypes.array),
  flatCRI: PropTypes.arrayOf(PropTypes.object),
  flatFuentes: PropTypes.arrayOf(PropTypes.object),
  disabled: PropTypes.bool.isRequired,
  conceptosDeIngresos: PropTypes.arrayOf(PropTypes.object).isRequired,
};

ConfigsPresupuestos.defaultProps = {
  idConfiguracion: null,
  mutable: true,
  clasesCRI: [],
  fuentes: [],
  flatCRI: [],
  flatFuentes: [],
};

export default ConfigsPresupuestos;
