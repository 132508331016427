export default [
  {
    id: 1,
    no_requisicion_individual: 'RE-2021-06-0037',
    no_requisicion_consolidada: '',
    folio_agrupador: 'FA-2021-0018',
    tipo_operacion: 'Servicios',
    prioridad: 'Regular',
    proceso_adquisitivo: 'Directa',
    no_cuadro_comparativo: 'CC-2021-06-00089',
    fecha_elaboracion: '6/18/2021',
    elaboro: 'Gilberto Ocampo Zeres',
    puesto: 'Auxiliar de Compras',
    area: '0400  |  Adquisiciones',
    estatus: 'Activo',
    fecha_asignacion: '',
    proveedor_asignado: '',
    observaciones: '',
    orden_de_compra: '',
    recepcion: '',
    cuenta_por_pagar: '',
    pago: '',
    '': '',
  },
  {
    id: 2,
    no_requisicion_individual: 'RE-2021-06-0038',
    no_requisicion_consolidada: '',
    folio_agrupador: 'FA-2021-0022',
    tipo_operacion: 'Bienes No Almacenables',
    prioridad: 'Media',
    proceso_adquisitivo: 'Directa',
    no_cuadro_comparativo: 'CC-2021-06-00090',
    fecha_elaboracion: '6/19/2021',
    elaboro: 'Gilberto Ocampo Zeres',
    puesto: 'Auxiliar de Compras',
    area: '0400  |  Adquisiciones',
    estatus: 'Autorizado',
    fecha_asignacion: '6/24/2021',
    proveedor_asignado: '0108 |Papelería Oficina, SA de CV, 0064 |Insumos de Oficina, SA de CV',
    observaciones: '',
    orden_de_compra: '',
    recepcion: '',
    cuenta_por_pagar: '',
    pago: '',
    '': '',
    data_table: [{
      id: 1,
      clave_presupuestal: '2021-1-311111-504-M-152-53-001-155-21101-1-11721',
      '': '',
      cog: 21101,
      clave_producto: 'PA-000391',
      producto: 'Grapas estándar',
      unidad_de_medida: 'Caja',
      cantidad: 3,
      asignado: 3,
      por_asignar: '-',
    },
    {
      id: 2,
      clave_presupuestal: '2021-1-311111-504-M-152-53-001-155-21101-1-11721',
      '': '',
      cog: 21101,
      clave_producto: 'PA-000951',
      producto: 'Plumas',
      unidad_de_medida: 'Caja',
      cantidad: 2,
      asignado: 2,
      por_asignar: '-',
    },
    {
      id: 3,
      clave_presupuestal: '2021-1-311111-504-M-152-53-001-155-21101-1-11721',
      '': '',
      cog: 21101,
      clave_producto: 'PA-000852',
      producto: 'Folders beige',
      unidad_de_medida: 'Caja',
      cantidad: 3,
      asignado: 3,
      por_asignar: '-',
    },
    {
      id: 4,
      clave_presupuestal: '2021-1-311111-504-M-152-53-001-155-21101-1-11721',
      '': '',
      cog: 21101,
      clave_producto: 'PA-000145',
      producto: 'Hojas de papel',
      unidad_de_medida: 'Caja',
      cantidad: 5,
      asignado: 5,
      por_asignar: '-',
    },
    ],
    data_table_proveedores: [
      {
        clave: 108,
        nombre_del_proveedor: 'Papelería de Oficina, SA de CV',
        '': '',
        tasa_de_iva: '16%',
        asignacion: 'Asignado',
      },
      {
        clave: 201,
        nombre_del_proveedor: 'La Surtidora de Oficinas, SA de CV',
        '': '',
        tasa_de_iva: '16%',
        asignacion: '',
      },
      {
        clave: 64,
        nombre_del_proveedor: 'Insumos de Oficina, SA de CV',
        '': '',
        tasa_de_iva: '16%',
        asignacion: 'Asignado',
      },
    ],
    data_table_comparativos_second: [
      {
        producto: 'Grapas estándar',
        unidad_de_medida: 'Caja',
        cantidad: '3',
        precio: 24.14,
        subtotal: 72.41,
        iva: 11.59,
        total: 84.00,
      },
      {
        producto: 'Hojas de papel',
        unidad_de_medida: 'Caja',
        cantidad: '5',
        precio: 1034.48,
        subtotal: 5172.41,
        iva: 827.59,
        total: 6000.00,
      },
      {
        subtotal: 5244.83,
        iva: 839.17,
        total: 6084.00,
      },
    ],
    data_table_comparativos_third: [
      {
        producto: 'Plumas',
        unidad_de_medida: 'Caja',
        cantidad: '2',
        precio: 30.17,
        subtotal: 60.34,
        iva: 9.66,
        total: 70.00,
      },
      {
        producto: 'Folders beige',
        unidad_de_medida: 'Caja',
        cantidad: '3',
        precio: 129.31,
        subtotal: 129.31,
        iva: 62.07,
        total: 450.00,
      },
      {
        subtotal: 448.28,
        iva: 71.72,
        total: 520.00,
      },
    ],
    data_table_comparativos_first: [{
      clave_presupuestal: '2021-1-311111-504-M-152-53-001-155-21101-1-11721',
      cog: 21101,
      clave_producto: 'PA-000391',
      producto: 'Grapas estándar',
      unidad_de_medida: 'Caja',
      cantidad: 3,
      precio: 29.09,
      iva: 4.66,
      total: 33.75,
      precio_s: 24.14,
      iva_s: 3.86,
      total_s: 28.00,
      precio_t: 25.86,
      iva_t: 4.14,
      total_t: 30.00,
      precio_f: 27.59,
      iva_f: 4.41,
      total_f: 32.00,
      mejor_precio: 'Papelería Oficina',
      asignacion: 'Asignado',
    },
    {
      clave_presupuestal: '2021-1-311111-504-M-152-53-001-155-21101-1-11721',
      cog: 21101,
      clave_producto: 'PA-000951',
      producto: 'Plumas',
      unidad_de_medida: 'Caja',
      cantidad: 2,
      precio: 31.03,
      iva: 4.97,
      total: 36.00,
      precio_s: 32.76,
      iva_s: 5.24,
      total_s: 38.00,
      precio_t: 34.48,
      iva_t: 5.52,
      total_t: 40.00,
      precio_f: 30.17,
      iva_f: 4.83,
      total_f: 35.00,
      mejor_precio: 'Insumos de Oficina',
      asignacion: 'Asignado',
    },
    {
      clave_presupuestal: '2021-1-311111-504-M-152-53-001-155-21101-1-11721',
      cog: 21101,
      clave_producto: 'PA-000852',
      producto: 'Folders beige',
      unidad_de_medida: 'Caja',
      cantidad: 3,
      precio: 127.59,
      iva: 20.41,
      total: 148.00,
      precio_s: 146.55,
      iva_s: 23.45,
      total_s: 170.00,
      precio_t: 155.17,
      iva_t: 24.83,
      total_t: 180.00,
      precio_f: 129.31,
      iva_f: 20.69,
      total_f: 150.00,
      mejor_precio: 'Insumos de Oficina',
      asignacion: 'Asignado',
    },
    {
      clave_presupuestal: '2021-1-311111-504-M-152-53-001-155-21101-1-11721',
      cog: 21101,
      clave_producto: 'PA-000145',
      producto: 'Hojas de papel',
      unidad_de_medida: 'Caja',
      cantidad: 5,
      precio: 1037.93,
      iva: 166.07,
      total: 1204.00,
      precio_s: 1034.48,
      iva_s: 165.52,
      total_s: 1200.00,
      precio_t: 1077.59,
      iva_t: 172.41,
      total_t: 1250.00,
      precio_f: 1120.69,
      iva_f: 179.31,
      total_f: 1300,
      mejor_precio: 'Papelería Oficina',
      asignacion: 'Asignado',
    },
    ],
  },
  {
    id: 3,
    no_requisicion_individual: '',
    no_requisicion_consolidada: 'RC-2021-06-0024',
    folio_agrupador: 'FA-2021-0064',
    tipo_operacion: 'Bienes Almacenables',
    prioridad: 'Media',
    proceso_adquisitivo: 'Licitación',
    no_cuadro_comparativo: 'CC-2021-06-00091',
    fecha_elaboracion: '6/1/2021',
    elaboro: 'Antonio Rea Ríos',
    puesto: 'Auxiliar de Compras',
    area: '0400  |  Adquisiciones',
    estatus: 'Activo',
    fecha_asignacion: '',
    proveedor_asignado: '',
    observaciones: '',
    orden_de_compra: '',
    recepcion: '',
    cuenta_por_pagar: '',
    pago: '',
    '': '',
  },
  {
    id: 4,
    no_requisicion_individual: '',
    no_requisicion_consolidada: 'RC-2021-06-0025',
    folio_agrupador: 'FA-2021-0072',
    tipo_operacion: 'Bienes Almacenables',
    prioridad: 'Media',
    proceso_adquisitivo: 'Invitación a 3',
    no_cuadro_comparativo: 'CC-2021-06-00092',
    fecha_elaboracion: '6/18/2021',
    elaboro: 'Antonio Rea Ríos',
    puesto: 'Auxiliar de Compras',
    area: '0400  |  Adquisiciones',
    estatus: 'Activo',
    fecha_asignacion: '',
    proveedor_asignado: '',
    observaciones: '',
    orden_de_compra: '',
    recepcion: '',
    cuenta_por_pagar: '',
    pago: '',
    '': '',
  },

  // {
  //   'No. Requisición Individual': '',
  //   'No. Requisición Consolidada': '',
  //   'Folio Agrupador': '',
  //   'Tipo Operación': '',
  //   Prioridad: '',
  //   'Proceso Adquisitivo': '',
  //   'No. Cuadro Comparativo': '',
  //   'Fecha Elaboración': '',
  //   Elaboró: '',
  //   Puesto: '',
  //   Área: '',
  //   Estatus: '',
  //   'Fecha Asignación': '',
  //   'Proveedor Asignado': '',
  //   Observaciones: '',
  //   'Orden de Compra': '',
  //   Recepción: '',
  //   'Cuenta por Pagar': '',
  //   Pago: '',
  //   '': '',
  // },
  // {
  //   'No. Requisición Individual': 'Productos',
  //   'No. Requisición Consolidada': '',
  //   'Folio Agrupador': '',
  //   'Tipo Operación': '',
  //   Prioridad: '',
  //   'Proceso Adquisitivo': '',
  //   'No. Cuadro Comparativo': '',
  //   'Fecha Elaboración': '',
  //   Elaboró: '',
  //   Puesto: '',
  //   Área: '',
  //   Estatus: '',
  //   'Fecha Asignación': '',
  //   'Proveedor Asignado': '',
  //   Observaciones: '',
  //   'Orden de Compra': '',
  //   Recepción: '',
  //   'Cuenta por Pagar': '',
  //   Pago: '',
  //   '': '',
  // },
  // {
  //   'No. Requisición Individual': 'Fecha Elaboración',
  //   'No. Requisición Consolidada': 44365,
  //   'Folio Agrupador': 'No. Requisición',
  //   'Tipo Operación': 'RE-2021-06-0038',
  //   Prioridad: 'Tipo Operación',
  //   'Proceso Adquisitivo': 'Bienes No Almacenables',
  //   'No. Cuadro Comparativo': '',
  //   'Fecha Elaboración': '',
  //   Elaboró: '',
  //   Puesto: '',
  //   Área: '',
  //   Estatus: '',
  //   'Fecha Asignación': '',
  //   'Proveedor Asignado': '',
  //   Observaciones: '',
  //   'Orden de Compra': '',
  //   Recepción: '',
  //   'Cuenta por Pagar': '',
  //   Pago: '',
  //   '': '',
  // },
  // {
  //   'No. Requisición Individual': 'Elaboró',
  //   'No. Requisición Consolidada': 44366,
  //   'Folio Agrupador': 'No. Requisición Consolidada',
  //   'Tipo Operación': '',
  //   Prioridad: 'Prioridad',
  //   'Proceso Adquisitivo': 'Media',
  //   'No. Cuadro Comparativo': '',
  //   'Fecha Elaboración': '',
  //   Elaboró: '',
  //   Puesto: '',
  //   Área: '',
  //   Estatus: '',
  //   'Fecha Asignación': '',
  //   'Proveedor Asignado': '',
  //   Observaciones: '',
  //   'Orden de Compra': '',
  //   Recepción: '',
  //   'Cuenta por Pagar': '',
  //   Pago: '',
  //   '': '',
  // },
  // {
  //   'No. Requisición Individual': 'Puesto',
  //   'No. Requisición Consolidada': 'Auxiliar de Compras',
  //   'Folio Agrupador': 'Folio Agrupador',
  //   'Tipo Operación': 'FA-2021-0022',
  //   Prioridad: 'Proceso Adquisitivo',
  //   'Proceso Adquisitivo': 'Directa',
  //   'No. Cuadro Comparativo': '',
  //   'Fecha Elaboración': '',
  //   Elaboró: '',
  //   Puesto: '',
  //   Área: '',
  //   Estatus: '',
  //   'Fecha Asignación': '',
  //   'Proveedor Asignado': '',
  //   Observaciones: '',
  //   'Orden de Compra': '',
  //   Recepción: '',
  //   'Cuenta por Pagar': '',
  //   Pago: '',
  //   '': '',
  // },
  // {
  //   'No. Requisición Individual': 'Área',
  //   'No. Requisición Consolidada': '0400  |  Adquisiciones',
  //   'Folio Agrupador': 'Área Requierente',
  //   'Tipo Operación': '0801 | Recursos Humanos',
  //   Prioridad: 'Proveedores) Asignado(s)',
  //   'Proceso Adquisitivo': 'Papelería Oficina, SA de CV',
  //   'No. Cuadro Comparativo': '',
  //   'Fecha Elaboración': '',
  //   Elaboró: '',
  //   Puesto: '',
  //   Área: '',
  //   Estatus: '',
  //   'Fecha Asignación': '',
  //   'Proveedor Asignado': '',
  //   Observaciones: '',
  //   'Orden de Compra': '',
  //   Recepción: '',
  //   'Cuenta por Pagar': '',
  //   Pago: '',
  //   '': '',
  // },
  // {
  //   'No. Requisición Individual': 'No. Cuadro Comparativo',
  //   'No. Requisición Consolidada': 'CC-2021-06-00090',
  //   'Folio Agrupador': 'Fecha Asignación',
  //   'Tipo Operación': 44371,
  //   Prioridad: '',
  //   'Proceso Adquisitivo': 'Insumos de Oficina, SA de CV',
  //   'No. Cuadro Comparativo': '',
  //   'Fecha Elaboración': '',
  //   Elaboró: '',
  //   Puesto: '',
  //   Área: '',
  //   Estatus: '',
  //   'Fecha Asignación': '',
  //   'Proveedor Asignado': '',
  //   Observaciones: '',
  //   'Orden de Compra': '',
  //   Recepción: '',
  //   'Cuenta por Pagar': '',
  //   Pago: '',
  //   '': '',
  // },
  // {
  //   'No. Requisición Individual': 'Observaciones',
  //   'No. Requisición Consolidada': '',
  //   'Folio Agrupador': '',
  //   'Tipo Operación': '',
  //   Prioridad: '',
  //   'Proceso Adquisitivo': '',
  //   'No. Cuadro Comparativo': '',
  //   'Fecha Elaboración': '',
  //   Elaboró: '',
  //   Puesto: '',
  //   Área: '',
  //   Estatus: '',
  //   'Fecha Asignación': '',
  //   'Proveedor Asignado': '',
  //   Observaciones: '',
  //   'Orden de Compra': '',
  //   Recepción: '',
  //   'Cuenta por Pagar': '',
  //   Pago: '',
  //   '': '',
  // },
  // {
  //   'No. Requisición Individual': 'Clave Presupuestal',
  //   'No. Requisición Consolidada': '',
  //   'Folio Agrupador': 'COG',
  //   'Tipo Operación': 'Clave Producto',
  //   Prioridad: 'Producto',
  //   'Proceso Adquisitivo': 'Unidad de Medida',
  //   'No. Cuadro Comparativo': 'Cantidad',
  //   'Fecha Elaboración': 'Asignado',
  //   Elaboró: 'Por Asignar',
  //   Puesto: '',
  //   Área: '',
  //   Estatus: '',
  //   'Fecha Asignación': '',
  //   'Proveedor Asignado': '',
  //   Observaciones: '',
  //   'Orden de Compra': '',
  //   Recepción: '',
  //   'Cuenta por Pagar': '',
  //   Pago: '',
  //   '': '',
  // },
  // {
  //   'No. Requisición Individual': '2021-1-311111-504-M-152-53-001-155-21101-1-11721',
  //   'No. Requisición Consolidada': '',
  //   'Folio Agrupador': 21101,
  //   'Tipo Operación': 'PA-000391',
  //   Prioridad: 'Grapas estándar',
  //   'Proceso Adquisitivo': 'Caja',
  //   'No. Cuadro Comparativo': 3,
  //   'Fecha Elaboración': 3,
  //   Elaboró: '-',
  //   Puesto: '',
  //   Área: '',
  //   Estatus: '',
  //   'Fecha Asignación': '',
  //   'Proveedor Asignado': '',
  //   Observaciones: '',
  //   'Orden de Compra': '',
  //   Recepción: '',
  //   'Cuenta por Pagar': '',
  //   Pago: '',
  //   '': '',
  // },
  // {
  //   'No. Requisición Individual': '2021-1-311111-504-M-152-53-001-155-21101-1-11721',
  //   'No. Requisición Consolidada': '',
  //   'Folio Agrupador': 21101,
  //   'Tipo Operación': 'PA-000951',
  //   Prioridad: 'Plumas',
  //   'Proceso Adquisitivo': 'Caja',
  //   'No. Cuadro Comparativo': 2,
  //   'Fecha Elaboración': 2,
  //   Elaboró: '-',
  //   Puesto: '',
  //   Área: '',
  //   Estatus: '',
  //   'Fecha Asignación': '',
  //   'Proveedor Asignado': '',
  //   Observaciones: '',
  //   'Orden de Compra': '',
  //   Recepción: '',
  //   'Cuenta por Pagar': '',
  //   Pago: '',
  //   '': '',
  // },
  // {
  //   'No. Requisición Individual': '2021-1-311111-504-M-152-53-001-155-21101-1-11721',
  //   'No. Requisición Consolidada': '',
  //   'Folio Agrupador': 21101,
  //   'Tipo Operación': 'PA-000852',
  //   Prioridad: 'Folders beige',
  //   'Proceso Adquisitivo': 'Caja',
  //   'No. Cuadro Comparativo': 3,
  //   'Fecha Elaboración': 3,
  //   Elaboró: '-',
  //   Puesto: '',
  //   Área: '',
  //   Estatus: '',
  //   'Fecha Asignación': '',
  //   'Proveedor Asignado': '',
  //   Observaciones: '',
  //   'Orden de Compra': '',
  //   Recepción: '',
  //   'Cuenta por Pagar': '',
  //   Pago: '',
  //   '': '',
  // },
  // {
  //   'No. Requisición Individual': '2021-1-311111-504-M-152-53-001-155-21101-1-11721',
  //   'No. Requisición Consolidada': '',
  //   'Folio Agrupador': 21101,
  //   'Tipo Operación': 'PA-000145',
  //   Prioridad: 'Hojas de papel',
  //   'Proceso Adquisitivo': 'Caja',
  //   'No. Cuadro Comparativo': 5,
  //   'Fecha Elaboración': 5,
  //   Elaboró: '-',
  //   Puesto: '',
  //   Área: '',
  //   Estatus: '',
  //   'Fecha Asignación': '',
  //   'Proveedor Asignado': '',
  //   Observaciones: '',
  //   'Orden de Compra': '',
  //   Recepción: '',
  //   'Cuenta por Pagar': '',
  //   Pago: '',
  //   '': '',
  // },
  // {
  //   'No. Requisición Individual': '',
  //   'No. Requisición Consolidada': '',
  //   'Folio Agrupador': '',
  //   'Tipo Operación': '',
  //   Prioridad: '',
  //   'Proceso Adquisitivo': '',
  //   'No. Cuadro Comparativo': '',
  //   'Fecha Elaboración': '',
  //   Elaboró: '',
  //   Puesto: '',
  //   Área: '',
  //   Estatus: '',
  //   'Fecha Asignación': '',
  //   'Proveedor Asignado': '',
  //   Observaciones: '',
  //   'Orden de Compra': '',
  //   Recepción: '',
  //   'Cuenta por Pagar': '',
  //   Pago: '',
  //   '': '',
  // },
  // {
  //   'No. Requisición Individual': '',
  //   'No. Requisición Consolidada': '',
  //   'Folio Agrupador': '',
  //   'Tipo Operación': '',
  //   Prioridad: '',
  //   'Proceso Adquisitivo': '',
  //   'No. Cuadro Comparativo': '',
  //   'Fecha Elaboración': '',
  //   Elaboró: '',
  //   Puesto: '',
  //   Área: '',
  //   Estatus: '',
  //   'Fecha Asignación': '',
  //   'Proveedor Asignado': '',
  //   Observaciones: '',
  //   'Orden de Compra': '',
  //   Recepción: '',
  //   'Cuenta por Pagar': '',
  //   Pago: '',
  //   '': '',
  // },
  // {
  //   'No. Requisición Individual': '',
  //   'No. Requisición Consolidada': '',
  //   'Folio Agrupador': '',
  //   'Tipo Operación': '',
  //   Prioridad: '',
  //   'Proceso Adquisitivo': '',
  //   'No. Cuadro Comparativo': '',
  //   'Fecha Elaboración': '',
  //   Elaboró: '',
  //   Puesto: '',
  //   Área: '',
  //   Estatus: '',
  //   'Fecha Asignación': '',
  //   'Proveedor Asignado': '',
  //   Observaciones: '',
  //   'Orden de Compra': '',
  //   Recepción: '',
  //   'Cuenta por Pagar': '',
  //   Pago: '',
  //   '': '',
  // },
  // {
  //   'No. Requisición Individual': '',
  //   'No. Requisición Consolidada': '',
  //   'Folio Agrupador': '',
  //   'Tipo Operación': '',
  //   Prioridad: '',
  //   'Proceso Adquisitivo': '',
  //   'No. Cuadro Comparativo': '',
  //   'Fecha Elaboración': '',
  //   Elaboró: '',
  //   Puesto: '',
  //   Área: '',
  //   Estatus: '',
  //   'Fecha Asignación': '',
  //   'Proveedor Asignado': '',
  //   Observaciones: '',
  //   'Orden de Compra': '',
  //   Recepción: '',
  //   'Cuenta por Pagar': '',
  //   Pago: '',
  //   '': '',
  // },
  // {
  //   'No. Requisición Individual': '',
  //   'No. Requisición Consolidada': '',
  //   'Folio Agrupador': '',
  //   'Tipo Operación': '',
  //   Prioridad: '',
  //   'Proceso Adquisitivo': '',
  //   'No. Cuadro Comparativo': '',
  //   'Fecha Elaboración': '',
  //   Elaboró: '',
  //   Puesto: '',
  //   Área: '',
  //   Estatus: '',
  //   'Fecha Asignación': '',
  //   'Proveedor Asignado': '',
  //   Observaciones: '',
  //   'Orden de Compra': '',
  //   Recepción: '',
  //   'Cuenta por Pagar': '',
  //   Pago: '',
  //   '': '',
  // },
  // {
  //   'No. Requisición Individual': '',
  //   'No. Requisición Consolidada': '',
  //   'Folio Agrupador': '',
  //   'Tipo Operación': '',
  //   Prioridad: '',
  //   'Proceso Adquisitivo': '',
  //   'No. Cuadro Comparativo': '',
  //   'Fecha Elaboración': '',
  //   Elaboró: '',
  //   Puesto: '',
  //   Área: '',
  //   Estatus: '',
  //   'Fecha Asignación': '',
  //   'Proveedor Asignado': '',
  //   Observaciones: '',
  //   'Orden de Compra': '',
  //   Recepción: '',
  //   'Cuenta por Pagar': '',
  //   Pago: '',
  //   '': '',
  // },
  // {
  //   'No. Requisición Individual': 'Proveedores',
  //   'No. Requisición Consolidada': '',
  //   'Folio Agrupador': '',
  //   'Tipo Operación': '',
  //   Prioridad: '',
  //   'Proceso Adquisitivo': '',
  //   'No. Cuadro Comparativo': '',
  //   'Fecha Elaboración': '',
  //   Elaboró: '',
  //   Puesto: '',
  //   Área: '',
  //   Estatus: '',
  //   'Fecha Asignación': '',
  //   'Proveedor Asignado': '',
  //   Observaciones: '',
  //   'Orden de Compra': '',
  //   Recepción: '',
  //   'Cuenta por Pagar': '',
  //   Pago: '',
  //   '': '',
  // },
  // {
  //   'No. Requisición Individual': 'Fecha Elaboración',
  //   'No. Requisición Consolidada': 44365,
  //   'Folio Agrupador': 'No. Requisición',
  //   'Tipo Operación': 'RE-2021-06-0038',
  //   Prioridad: 'Tipo Operación',
  //   'Proceso Adquisitivo': 'Bienes No Almacenables',
  //   'No. Cuadro Comparativo': '',
  //   'Fecha Elaboración': '',
  //   Elaboró: '',
  //   Puesto: '',
  //   Área: '',
  //   Estatus: '',
  //   'Fecha Asignación': '',
  //   'Proveedor Asignado': '',
  //   Observaciones: '',
  //   'Orden de Compra': '',
  //   Recepción: '',
  //   'Cuenta por Pagar': '',
  //   Pago: '',
  //   '': '',
  // },
  // {
  //   'No. Requisición Individual': 'Elaboró',
  //   'No. Requisición Consolidada': 44366,
  //   'Folio Agrupador': 'No. Requisición Consolidada',
  //   'Tipo Operación': '',
  //   Prioridad: 'Prioridad',
  //   'Proceso Adquisitivo': 'Media',
  //   'No. Cuadro Comparativo': '',
  //   'Fecha Elaboración': '',
  //   Elaboró: '',
  //   Puesto: '',
  //   Área: '',
  //   Estatus: '',
  //   'Fecha Asignación': '',
  //   'Proveedor Asignado': '',
  //   Observaciones: '',
  //   'Orden de Compra': '',
  //   Recepción: '',
  //   'Cuenta por Pagar': '',
  //   Pago: '',
  //   '': '',
  // },
  // {
  //   'No. Requisición Individual': 'Puesto',
  //   'No. Requisición Consolidada': 'Auxiliar de Compras',
  //   'Folio Agrupador': 'Folio Agrupador',
  //   'Tipo Operación': 'FA-2021-0022',
  //   Prioridad: 'Proceso Adquisitivo',
  //   'Proceso Adquisitivo': 'Directa',
  //   'No. Cuadro Comparativo': '',
  //   'Fecha Elaboración': '',
  //   Elaboró: '',
  //   Puesto: '',
  //   Área: '',
  //   Estatus: '',
  //   'Fecha Asignación': '',
  //   'Proveedor Asignado': '',
  //   Observaciones: '',
  //   'Orden de Compra': '',
  //   Recepción: '',
  //   'Cuenta por Pagar': '',
  //   Pago: '',
  //   '': '',
  // },
  // {
  //   'No. Requisición Individual': 'Área',
  //   'No. Requisición Consolidada': '0400  |  Adquisiciones',
  //   'Folio Agrupador': 'Área Requirente',
  //   'Tipo Operación': '0801 | Recursos Humanos',
  //   Prioridad: 'Proveedores) Asignado(s)',
  //   'Proceso Adquisitivo': 'Papelería Oficina, SA de CV',
  //   'No. Cuadro Comparativo': '',
  //   'Fecha Elaboración': '',
  //   Elaboró: '',
  //   Puesto: '',
  //   Área: '',
  //   Estatus: '',
  //   'Fecha Asignación': '',
  //   'Proveedor Asignado': '',
  //   Observaciones: '',
  //   'Orden de Compra': '',
  //   Recepción: '',
  //   'Cuenta por Pagar': '',
  //   Pago: '',
  //   '': '',
  // },
  // {
  //   'No. Requisición Individual': 'No. Cuadro Comparativo',
  //   'No. Requisición Consolidada': 'CC-2021-06-00090',
  //   'Folio Agrupador': 'Fecha Asignación',
  //   'Tipo Operación': 44371,
  //   Prioridad: '',
  //   'Proceso Adquisitivo': 'Insumos de Oficina, SA de CV',
  //   'No. Cuadro Comparativo': '',
  //   'Fecha Elaboración': '',
  //   Elaboró: '',
  //   Puesto: '',
  //   Área: '',
  //   Estatus: '',
  //   'Fecha Asignación': '',
  //   'Proveedor Asignado': '',
  //   Observaciones: '',
  //   'Orden de Compra': '',
  //   Recepción: '',
  //   'Cuenta por Pagar': '',
  //   Pago: '',
  //   '': '',
  // },
  // {
  //   'No. Requisición Individual': 'Observaciones',
  //   'No. Requisición Consolidada': '',
  //   'Folio Agrupador': '',
  //   'Tipo Operación': '',
  //   Prioridad: '',
  //   'Proceso Adquisitivo': '',
  //   'No. Cuadro Comparativo': '',
  //   'Fecha Elaboración': '',
  //   Elaboró: '',
  //   Puesto: '',
  //   Área: '',
  //   Estatus: '',
  //   'Fecha Asignación': '',
  //   'Proveedor Asignado': '',
  //   Observaciones: '',
  //   'Orden de Compra': '',
  //   Recepción: '',
  //   'Cuenta por Pagar': '',
  //   Pago: '',
  //   '': '',
  // },
  // {
  //   'No. Requisición Individual': 'Clave',
  //   'No. Requisición Consolidada': 'Nombre del Proveedor',
  //   'Folio Agrupador': '',
  //   'Tipo Operación': 'Tasa de IVA',
  //   Prioridad: 'Asignación',
  //   'Proceso Adquisitivo': '',
  //   'No. Cuadro Comparativo': '',
  //   'Fecha Elaboración': '',
  //   Elaboró: '',
  //   Puesto: '',
  //   Área: '',
  //   Estatus: '',
  //   'Fecha Asignación': '',
  //   'Proveedor Asignado': '',
  //   Observaciones: '',
  //   'Orden de Compra': '',
  //   Recepción: '',
  //   'Cuenta por Pagar': '',
  //   Pago: '',
  //   '': '',
  // },
  // {
  //   'No. Requisición Individual': 108,
  //   'No. Requisición Consolidada': 'Papelería de Oficina, SA de CV',
  //   'Folio Agrupador': '',
  //   'Tipo Operación': '16%',
  //   Prioridad: 'Asignado',
  //   'Proceso Adquisitivo': '',
  //   'No. Cuadro Comparativo': '',
  //   'Fecha Elaboración': '',
  //   Elaboró: '',
  //   Puesto: '',
  //   Área: '',
  //   Estatus: '',
  //   'Fecha Asignación': '',
  //   'Proveedor Asignado': '',
  //   Observaciones: '',
  //   'Orden de Compra': '',
  //   Recepción: '',
  //   'Cuenta por Pagar': '',
  //   Pago: '',
  //   '': '',
  // },
  // {
  //   'No. Requisición Individual': 201,
  //   'No. Requisición Consolidada': 'La Surtidora de Oficinas, SA de CV',
  //   'Folio Agrupador': '',
  //   'Tipo Operación': '16%',
  //   Prioridad: '',
  //   'Proceso Adquisitivo': '',
  //   'No. Cuadro Comparativo': '',
  //   'Fecha Elaboración': '',
  //   Elaboró: '',
  //   Puesto: '',
  //   Área: '',
  //   Estatus: '',
  //   'Fecha Asignación': '',
  //   'Proveedor Asignado': '',
  //   Observaciones: '',
  //   'Orden de Compra': '',
  //   Recepción: '',
  //   'Cuenta por Pagar': '',
  //   Pago: '',
  //   '': '',
  // },
  // {
  //   'No. Requisición Individual': 64,
  //   'No. Requisición Consolidada': 'Insumos de Oficina, SA de CV',
  //   'Folio Agrupador': '',
  //   'Tipo Operación': '16%',
  //   Prioridad: 'Asignado',
  //   'Proceso Adquisitivo': '',
  //   'No. Cuadro Comparativo': '',
  //   'Fecha Elaboración': '',
  //   Elaboró: '',
  //   Puesto: '',
  //   Área: '',
  //   Estatus: '',
  //   'Fecha Asignación': '',
  //   'Proveedor Asignado': '',
  //   Observaciones: '',
  //   'Orden de Compra': '',
  //   Recepción: '',
  //   'Cuenta por Pagar': '',
  //   Pago: '',
  //   '': '',
  // },
  // {
  //   'No. Requisición Individual': '',
  //   'No. Requisición Consolidada': '',
  //   'Folio Agrupador': '',
  //   'Tipo Operación': '',
  //   Prioridad: '',
  //   'Proceso Adquisitivo': '',
  //   'No. Cuadro Comparativo': '',
  //   'Fecha Elaboración': '',
  //   Elaboró: '',
  //   Puesto: '',
  //   Área: '',
  //   Estatus: '',
  //   'Fecha Asignación': '',
  //   'Proveedor Asignado': '',
  //   Observaciones: '',
  //   'Orden de Compra': '',
  //   Recepción: '',
  //   'Cuenta por Pagar': '',
  //   Pago: '',
  //   '': '',
  // },
  // {
  //   'No. Requisición Individual': '',
  //   'No. Requisición Consolidada': '',
  //   'Folio Agrupador': '',
  //   'Tipo Operación': '',
  //   Prioridad: '',
  //   'Proceso Adquisitivo': '',
  //   'No. Cuadro Comparativo': '',
  //   'Fecha Elaboración': '',
  //   Elaboró: '',
  //   Puesto: '',
  //   Área: '',
  //   Estatus: '',
  //   'Fecha Asignación': '',
  //   'Proveedor Asignado': '',
  //   Observaciones: '',
  //   'Orden de Compra': '',
  //   Recepción: '',
  //   'Cuenta por Pagar': '',
  //   Pago: '',
  //   '': '',
  // },
  // {
  //   'No. Requisición Individual': 'Comparativo',
  //   'No. Requisición Consolidada': '',
  //   'Folio Agrupador': '',
  //   'Tipo Operación': '',
  //   Prioridad: '',
  //   'Proceso Adquisitivo': '',
  //   'No. Cuadro Comparativo': '',
  //   'Fecha Elaboración': '',
  //   Elaboró: '',
  //   Puesto: '',
  //   Área: '',
  //   Estatus: '',
  //   'Fecha Asignación': '',
  //   'Proveedor Asignado': '',
  //   Observaciones: '',
  //   'Orden de Compra': '',
  //   Recepción: '',
  //   'Cuenta por Pagar': '',
  //   Pago: '',
  //   '': '',
  // },
  // {
  //   'No. Requisición Individual': 'Fecha Elaboración',
  //   'No. Requisición Consolidada': 44365,
  //   'Folio Agrupador': 'No. Requisición',
  //   'Tipo Operación': 'RE-2021-06-0038',
  //   Prioridad: 'Tipo Operación',
  //   'Proceso Adquisitivo': 'Bienes No Almacenables',
  //   'No. Cuadro Comparativo': '',
  //   'Fecha Elaboración': '',
  //   Elaboró: '',
  //   Puesto: '',
  //   Área: '',
  //   Estatus: '',
  //   'Fecha Asignación': '',
  //   'Proveedor Asignado': '',
  //   Observaciones: '',
  //   'Orden de Compra': '',
  //   Recepción: '',
  //   'Cuenta por Pagar': '',
  //   Pago: '',
  //   '': '',
  // },
  // {
  //   'No. Requisición Individual': 'Elaboró',
  //   'No. Requisición Consolidada': 44366,
  //   'Folio Agrupador': 'No. Requisición Consolidada',
  //   'Tipo Operación': 0,
  //   Prioridad: 'Prioridad',
  //   'Proceso Adquisitivo': 'Media',
  //   'No. Cuadro Comparativo': '',
  //   'Fecha Elaboración': '',
  //   Elaboró: '',
  //   Puesto: '',
  //   Área: '',
  //   Estatus: '',
  //   'Fecha Asignación': '',
  //   'Proveedor Asignado': '',
  //   Observaciones: '',
  //   'Orden de Compra': '',
  //   Recepción: '',
  //   'Cuenta por Pagar': '',
  //   Pago: '',
  //   '': '',
  // },
  // {
  //   'No. Requisición Individual': 'Puesto',
  //   'No. Requisición Consolidada': 'Auxiliar de Compras',
  //   'Folio Agrupador': 'Folio Agrupador',
  //   'Tipo Operación': 'FA-2021-0022',
  //   Prioridad: 'Proceso Adquisitivo',
  //   'Proceso Adquisitivo': 'Directa',
  //   'No. Cuadro Comparativo': '',
  //   'Fecha Elaboración': '',
  //   Elaboró: '',
  //   Puesto: '',
  //   Área: '',
  //   Estatus: '',
  //   'Fecha Asignación': '',
  //   'Proveedor Asignado': '',
  //   Observaciones: '',
  //   'Orden de Compra': '',
  //   Recepción: '',
  //   'Cuenta por Pagar': '',
  //   Pago: '',
  //   '': '',
  // },
  // {
  //   'No. Requisición Individual': 'Área',
  //   'No. Requisición Consolidada': '0400  |  Adquisiciones',
  //   'Folio Agrupador': 'Área Requirente',
  //   'Tipo Operación': '0801 | Recursos Humanos',
  //   Prioridad: 'Proveedores) Asignado(s)',
  //   'Proceso Adquisitivo': 'Papelería Oficina, SA de CV',
  //   'No. Cuadro Comparativo': '',
  //   'Fecha Elaboración': '',
  //   Elaboró: '',
  //   Puesto: '',
  //   Área: '',
  //   Estatus: '',
  //   'Fecha Asignación': '',
  //   'Proveedor Asignado': '',
  //   Observaciones: '',
  //   'Orden de Compra': '',
  //   Recepción: '',
  //   'Cuenta por Pagar': '',
  //   Pago: '',
  //   '': '',
  // },
  // {
  //   'No. Requisición Individual': 'No. Cuadro Comparativo',
  //   'No. Requisición Consolidada': 'CC-2021-06-00090',
  //   'Folio Agrupador': 'Fecha Asignación',
  //   'Tipo Operación': 44371,
  //   Prioridad: '',
  //   'Proceso Adquisitivo': 'Insumos de Oficina, SA de CV',
  //   'No. Cuadro Comparativo': '',
  //   'Fecha Elaboración': '',
  //   Elaboró: '',
  //   Puesto: '',
  //   Área: '',
  //   Estatus: '',
  //   'Fecha Asignación': '',
  //   'Proveedor Asignado': '',
  //   Observaciones: '',
  //   'Orden de Compra': '',
  //   Recepción: '',
  //   'Cuenta por Pagar': '',
  //   Pago: '',
  //   '': '',
  // },
  // {
  //   'No. Requisición Individual': 'Observaciones',
  //   'No. Requisición Consolidada': '',
  //   'Folio Agrupador': '',
  //   'Tipo Operación': '',
  //   Prioridad: '',
  //   'Proceso Adquisitivo': '',
  //   'No. Cuadro Comparativo': '',
  //   'Fecha Elaboración': '',
  //   Elaboró: '',
  //   Puesto: '',
  //   Área: '',
  //   Estatus: '',
  //   'Fecha Asignación': '',
  //   'Proveedor Asignado': '',
  //   Observaciones: '',
  //   'Orden de Compra': '',
  //   Recepción: '',
  //   'Cuenta por Pagar': '',
  //   Pago: '',
  //   '': '',
  // },
  // {
  //   'No. Requisición Individual': 'Clave Presupuestal',
  //   'No. Requisición Consolidada': 'COG',
  //   'Folio Agrupador': 'Clave Producto',
  //   'Tipo Operación': 'Producto',
  //   Prioridad: 'Unidad de Medida',
  //   'Proceso Adquisitivo': 'Cantidad',
  //   'No. Cuadro Comparativo': 'Precio Estudio Mercado',
  //   'Fecha Elaboración': '',
  //   Elaboró: '',
  //   Puesto: '108  |  Papelería de Oficina, SA de CV',
  //   Área: '',
  //   Estatus: '',
  //   'Fecha Asignación': '201  |  La Surtidora de Oficinas, SA de CV',
  //   'Proveedor Asignado': '',
  //   Observaciones: '',
  //   'Orden de Compra': '064  |  Insumos de Oficina, SA de CV',
  //   Recepción: '',
  //   'Cuenta por Pagar': '',
  //   Pago: 'Mejor Precio',
  //   '': 'Asignación',
  // },
  // {
  //   'No. Requisición Individual': '',
  //   'No. Requisición Consolidada': '',
  //   'Folio Agrupador': '',
  //   'Tipo Operación': '',
  //   Prioridad: '',
  //   'Proceso Adquisitivo': '',
  //   'No. Cuadro Comparativo': 'Precio',
  //   'Fecha Elaboración': 'IVA',
  //   Elaboró: 'Total',
  //   Puesto: 'Precio',
  //   Área: 'IVA',
  //   Estatus: 'Total',
  //   'Fecha Asignación': 'Precio',
  //   'Proveedor Asignado': 'IVA',
  //   Observaciones: 'Total',
  //   'Orden de Compra': 'Precio',
  //   Recepción: 'IVA',
  //   'Cuenta por Pagar': 'Total',
  //   Pago: '',
  //   '': '',
  // },
  // {
  //   'No. Requisición Individual': '2021-1-311111-504-M-152-53-001-155-21101-1-11721',
  //   'No. Requisición Consolidada': 21101,
  //   'Folio Agrupador': 'PA-000391',
  //   'Tipo Operación': 'Grapas estándar',
  //   Prioridad: 'Caja',
  //   'Proceso Adquisitivo': 3,
  //   'No. Cuadro Comparativo': 29.09,
  //   'Fecha Elaboración': 4.66,
  //   Elaboró: 33.75,
  //   Puesto: 24.14,
  //   Área: 3.86,
  //   Estatus: 28,
  //   'Fecha Asignación': 25.86,
  //   'Proveedor Asignado': 4.14,
  //   Observaciones: 30,
  //   'Orden de Compra': 27.59,
  //   Recepción: 4.41,
  //   'Cuenta por Pagar': 32,
  //   Pago: 'Papelería Oficina',
  //   '': 'þ',
  // },
  // {
  //   'No. Requisición Individual': '2021-1-311111-504-M-152-53-001-155-21101-1-11721',
  //   'No. Requisición Consolidada': 21101,
  //   'Folio Agrupador': 'PA-000951',
  //   'Tipo Operación': 'Plumas',
  //   Prioridad: 'Caja',
  //   'Proceso Adquisitivo': 2,
  //   'No. Cuadro Comparativo': 31.03,
  //   'Fecha Elaboración': 4.97,
  //   Elaboró: 36,
  //   Puesto: 32.76,
  //   Área: 5.24,
  //   Estatus: 38,
  //   'Fecha Asignación': 34.48,
  //   'Proveedor Asignado': 5.52,
  //   Observaciones: 40,
  //   'Orden de Compra': 30.17,
  //   Recepción: 4.83,
  //   'Cuenta por Pagar': 35,
  //   Pago: 'Insumos de Oficina',
  //   '': 'þ',
  // },
  // {
  //   'No. Requisición Individual': '2021-1-311111-504-M-152-53-001-155-21101-1-11721',
  //   'No. Requisición Consolidada': 21101,
  //   'Folio Agrupador': 'PA-000852',
  //   'Tipo Operación': 'Folders beige',
  //   Prioridad: 'Caja',
  //   'Proceso Adquisitivo': 3,
  //   'No. Cuadro Comparativo': 127.59,
  //   'Fecha Elaboración': 20.41,
  //   Elaboró: 148,
  //   Puesto: 146.55,
  //   Área: 23.45,
  //   Estatus: 170,
  //   'Fecha Asignación': 155.17,
  //   'Proveedor Asignado': 24.83,
  //   Observaciones: 180,
  //   'Orden de Compra': 129.31,
  //   Recepción: 20.69,
  //   'Cuenta por Pagar': 150,
  //   Pago: 'Insumos de Oficina',
  //   '': 'þ',
  // },
  // {
  //   'No. Requisición Individual': '2021-1-311111-504-M-152-53-001-155-21101-1-11721',
  //   'No. Requisición Consolidada': 21101,
  //   'Folio Agrupador': 'PA-000145',
  //   'Tipo Operación': 'Hojas de papel',
  //   Prioridad: 'Caja',
  //   'Proceso Adquisitivo': 5,
  //   'No. Cuadro Comparativo': '1,037.93',
  //   'Fecha Elaboración': 166.07,
  //   Elaboró: '1,204.00',
  //   Puesto: '1,034.48',
  //   Área: 165.52,
  //   Estatus: '1,200.00',
  //   'Fecha Asignación': '1,077.59',
  //   'Proveedor Asignado': 172.41,
  //   Observaciones: '1,250.00',
  //   'Orden de Compra': '1,120.69',
  //   Recepción: 179.31,
  //   'Cuenta por Pagar': '1,300.00',
  //   Pago: 'Papelería Oficina',
  //   '': 'þ',
  // },
  // {
  //   'No. Requisición Individual': '',
  //   'No. Requisición Consolidada': '',
  //   'Folio Agrupador': '',
  //   'Tipo Operación': '',
  //   Prioridad: '',
  //   'Proceso Adquisitivo': '',
  //   'No. Cuadro Comparativo': '',
  //   'Fecha Elaboración': '',
  //   Elaboró: '',
  //   Puesto: '',
  //   Área: '',
  //   Estatus: '',
  //   'Fecha Asignación': '',
  //   'Proveedor Asignado': '',
  //   Observaciones: '',
  //   'Orden de Compra': '',
  //   Recepción: '',
  //   'Cuenta por Pagar': '',
  //   Pago: '',
  //   '': '',
  // },
  // {
  //   'No. Requisición Individual': 'Resumen',
  //   'No. Requisición Consolidada': '',
  //   'Folio Agrupador': '',
  //   'Tipo Operación': '',
  //   Prioridad: '',
  //   'Proceso Adquisitivo': '',
  //   'No. Cuadro Comparativo': '',
  //   'Fecha Elaboración': '',
  //   Elaboró: '',
  //   Puesto: '',
  //   Área: '',
  //   Estatus: '',
  //   'Fecha Asignación': '',
  //   'Proveedor Asignado': '',
  //   Observaciones: '',
  //   'Orden de Compra': '',
  //   Recepción: '',
  //   'Cuenta por Pagar': '',
  //   Pago: '',
  //   '': '',
  // },
  // {
  //   'No. Requisición Individual': '108  |  Papelería de Oficina, SA de CV',
  //   'No. Requisición Consolidada': '',
  //   'Folio Agrupador': 'Producto',
  //   'Tipo Operación': 'Unidad de Medida',
  //   Prioridad: 'Cantidad',
  //   'Proceso Adquisitivo': 'Precio',
  //   'No. Cuadro Comparativo': 'Subtotal',
  //   'Fecha Elaboración': 'IVA',
  //   Elaboró: 'Total',
  //   Puesto: '',
  //   Área: '',
  //   Estatus: '',
  //   'Fecha Asignación': '',
  //   'Proveedor Asignado': '',
  //   Observaciones: '',
  //   'Orden de Compra': '',
  //   Recepción: '',
  //   'Cuenta por Pagar': '',
  //   Pago: '',
  //   '': '',
  // },
  // {
  //   'No. Requisición Individual': '',
  //   'No. Requisición Consolidada': '',
  //   'Folio Agrupador': 'Grapas estándar',
  //   'Tipo Operación': 'Caja',
  //   Prioridad: 3,
  //   'Proceso Adquisitivo': 24.14,
  //   'No. Cuadro Comparativo': 72.41,
  //   'Fecha Elaboración': 11.59,
  //   Elaboró: 84,
  //   Puesto: '',
  //   Área: '',
  //   Estatus: '',
  //   'Fecha Asignación': '',
  //   'Proveedor Asignado': '',
  //   Observaciones: '',
  //   'Orden de Compra': '',
  //   Recepción: '',
  //   'Cuenta por Pagar': '',
  //   Pago: '',
  //   '': '',
  // },
  // {
  //   'No. Requisición Individual': '',
  //   'No. Requisición Consolidada': '',
  //   'Folio Agrupador': 'Hojas de papel',
  //   'Tipo Operación': 'Caja',
  //   Prioridad: 5,
  //   'Proceso Adquisitivo': 1034.48,
  //   'No. Cuadro Comparativo': 5172.41,
  //   'Fecha Elaboración': 827.59,
  //   Elaboró: 6000,
  //   Puesto: '',
  //   Área: '',
  //   Estatus: '',
  //   'Fecha Asignación': '',
  //   'Proveedor Asignado': '',
  //   Observaciones: '',
  //   'Orden de Compra': '',
  //   Recepción: '',
  //   'Cuenta por Pagar': '',
  //   Pago: '',
  //   '': '',
  // },
  // {
  //   'No. Requisición Individual': '',
  //   'No. Requisición Consolidada': '',
  //   'Folio Agrupador': '',
  //   'Tipo Operación': '',
  //   Prioridad: '',
  //   'Proceso Adquisitivo': '',
  //   'No. Cuadro Comparativo': 5244.83,
  //   'Fecha Elaboración': 839.17,
  //   Elaboró: 6084,
  //   Puesto: '',
  //   Área: '',
  //   Estatus: '',
  //   'Fecha Asignación': '',
  //   'Proveedor Asignado': '',
  //   Observaciones: '',
  //   'Orden de Compra': '',
  //   Recepción: '',
  //   'Cuenta por Pagar': '',
  //   Pago: '',
  //   '': '',
  // },
  // {
  //   'No. Requisición Individual': '',
  //   'No. Requisición Consolidada': '',
  //   'Folio Agrupador': '',
  //   'Tipo Operación': '',
  //   Prioridad: '',
  //   'Proceso Adquisitivo': '',
  //   'No. Cuadro Comparativo': '',
  //   'Fecha Elaboración': '',
  //   Elaboró: '',
  //   Puesto: '',
  //   Área: '',
  //   Estatus: '',
  //   'Fecha Asignación': '',
  //   'Proveedor Asignado': '',
  //   Observaciones: '',
  //   'Orden de Compra': '',
  //   Recepción: '',
  //   'Cuenta por Pagar': '',
  //   Pago: '',
  //   '': '',
  // },
  // {
  //   'No. Requisición Individual': '064 | Insumos de Oficina, SA CV',
  //   'No. Requisición Consolidada': '',
  //   'Folio Agrupador': 'Producto',
  //   'Tipo Operación': 'Unidad de Medida',
  //   Prioridad: 'Cantidad',
  //   'Proceso Adquisitivo': 'Precio',
  //   'No. Cuadro Comparativo': 'Subtotal',
  //   'Fecha Elaboración': 'IVA',
  //   Elaboró: 'Total',
  //   Puesto: '',
  //   Área: '',
  //   Estatus: '',
  //   'Fecha Asignación': '',
  //   'Proveedor Asignado': '',
  //   Observaciones: '',
  //   'Orden de Compra': '',
  //   Recepción: '',
  //   'Cuenta por Pagar': '',
  //   Pago: '',
  //   '': '',
  // },
  // {
  //   'No. Requisición Individual': '',
  //   'No. Requisición Consolidada': '',
  //   'Folio Agrupador': 'Plumas',
  //   'Tipo Operación': 'Caja',
  //   Prioridad: 2,
  //   'Proceso Adquisitivo': 30.17,
  //   'No. Cuadro Comparativo': 60.34,
  //   'Fecha Elaboración': 9.66,
  //   Elaboró: 70,
  //   Puesto: '',
  //   Área: '',
  //   Estatus: '',
  //   'Fecha Asignación': '',
  //   'Proveedor Asignado': '',
  //   Observaciones: '',
  //   'Orden de Compra': '',
  //   Recepción: '',
  //   'Cuenta por Pagar': '',
  //   Pago: '',
  //   '': '',
  // },
  // {
  //   'No. Requisición Individual': '',
  //   'No. Requisición Consolidada': '',
  //   'Folio Agrupador': 'Folders beige',
  //   'Tipo Operación': 'Caja',
  //   Prioridad: 3,
  //   'Proceso Adquisitivo': 129.31,
  //   'No. Cuadro Comparativo': 387.93,
  //   'Fecha Elaboración': 62.07,
  //   Elaboró: 450,
  //   Puesto: '',
  //   Área: '',
  //   Estatus: '',
  //   'Fecha Asignación': '',
  //   'Proveedor Asignado': '',
  //   Observaciones: '',
  //   'Orden de Compra': '',
  //   Recepción: '',
  //   'Cuenta por Pagar': '',
  //   Pago: '',
  //   '': '',
  // },
  // {
  //   'No. Requisición Individual': '',
  //   'No. Requisición Consolidada': '',
  //   'Folio Agrupador': '',
  //   'Tipo Operación': '',
  //   Prioridad: '',
  //   'Proceso Adquisitivo': '',
  //   'No. Cuadro Comparativo': 448.28,
  //   'Fecha Elaboración': 71.72,
  //   Elaboró: 520,
  //   Puesto: '',
  //   Área: '',
  //   Estatus: '',
  //   'Fecha Asignación': '',
  //   'Proveedor Asignado': '',
  //   Observaciones: '',
  //   'Orden de Compra': '',
  //   Recepción: '',
  //   'Cuenta por Pagar': '',
  //   Pago: '',
  //   '': '',
  // },
];
