import React, { useState, useEffect } from 'react';
import {
  Row,
  Form,
  Col,
  Spin,
  Modal,
  Input,
} from 'antd';
import { useSelector } from 'react-redux';
import API from 'utils/api';
import Table from 'components/Table';
import FormSubmitControls from 'components/FormSubmitControls';
import Select from 'components/Select';
import { onError } from 'utils/handlers';

export const permissionTipoCuentaBancaria = {
  permissionModel: 'tipodefirmadecuentabancaria',
};

// eslint-disable-next-line react/prop-types
function TipoDeFirmaDeCuentaBancaria({ permission }) {
  const [form] = Form.useForm();
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [visible, setVisible] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await API.get('/configuraciones/tipos-de-firma-de-cuentas-bancarias/');
      setData(response.data);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchData();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setVisible(false);
    setSelectedRowKeys([]);
    form.resetFields();
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    form.setFieldsValue(record);
  };

  const rowSelection = {
    type: 'radio',
    selectedRowKeys,
  };

  const columns = [
    {
      titleText: 'Descripción',
      dataIndex: 'descripcion',
      key: 'descripcion',
      width: 300,
    },
    {
      titleText: 'Estado',
      dataIndex: 'estados_globales',
      key: 'estados_globales',
      width: 250,
      render: (val) => estadosGlobales.find((item) => item.id === val)?.descripcion,
    },

  ];

  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        <Table
          cols={columns}
          data={data}
          pemission={permission}
          dataSource={dataSource}
          rowSelection={rowSelection}
          setDataSource={setDataSource}
          handleOnRowClick={handleOnRowClick}
          controls={{
            onClickExpand: () => (
              setVisible(true)
            ),
          }}
          mobileColIndex={0}
        />
        <Modal
          visible={visible}
          title={(
            <FormSubmitControls onCancel={onCancel} label="Vista Previa" />
            )}
          onCancel={onCancel}
          footer={null}
          closable={false}
        >
          <Form
            layout="vertical"
            form={form}
            className="without-feedback"
          >
            <Col span={24}>
              <Form.Item
                label="Descripción"
                name="descripcion"
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Estado Global"
                name="estados_globales"
              >
                <Select disabled dataSource={estadosGlobales} />
              </Form.Item>
            </Col>
          </Form>
        </Modal>
      </Spin>
    </Row>
  );
}

export default TipoDeFirmaDeCuentaBancaria;
