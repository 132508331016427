import API from 'utils/api';
import { onSuccess, onError } from 'utils/handlers';

export const baseURI = 'control-patrimonial/resguardos/';

/**
 * GET Resguardos
 * @returns {Promise<object[]>}
 */
export const getResguardos = async (params) => {
  try {
    const response = await API.get(baseURI, { params });
    return response.data;
  } catch (err) {
    onError(err);
    return [];
  }
};

/**
 * GET Resguardos
 * @returns {Promise<object>}
 */
export const getResguardo = async (id) => {
  try {
    const response = await API.get(`${baseURI}/${id}`);
    return response.data;
  } catch (err) {
    onError(err);
    return null;
  }
};

/**
 * POST Resguardos
 * @param {object} values
 * @returns {Promise<object|boolean>}
 */
export const postResguardos = async (values) => {
  try {
    const response = await API.post(baseURI, values);
    onSuccess(response, 'Agregado correctamente');
    return response.data;
  } catch (err) {
    onError(err);
    return false;
  }
};

/**
 * PATCH Resguardos
 * @param {number} id
 * @param {object} values
 * @returns {Promise<object|boolean>}
 */
export const patchResguardos = async (id, values) => {
  try {
    const response = await API.patch(`${baseURI}${id}`, values);
    onSuccess(response, 'Editado correctamente');
    return response.data;
  } catch (err) {
    onError(err);
    return false;
  }
};

/**
 * DELETE Resguardos
 * @param {number} id
 * @returns {Promise<boolean>}
 */
export const deleteResguardos = async (id) => {
  try {
    const response = await API.delete(`${baseURI}${id}`);
    onSuccess(response, 'Eliminado correctamente');
    return true;
  } catch (err) {
    onError(err);
    return false;
  }
};
