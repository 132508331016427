import React, { useState, useEffect } from 'react';
import {
  Card, Form, Row, Spin, Col, Input, DatePicker, Select, Button,
} from 'antd';

import API from 'utils/api';
import { onError } from 'utils/handlers';
import {
  CheckCircleOutlined,
  ArrowLeftOutlined,
  StopOutlined,
  FilePdfOutlined,
  FileExcelOutlined,
} from '@ant-design/icons';
import Table from 'components/Table';

function OrdenDePago() {
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [visibleAddAccounts, setVisibleAddAccounts] = useState(false);
  const [estado, setEstado] = useState([]);
  const requiredRule = {
    required: true,
    message: 'El campo es requerido',
  };
  const rules = {
    required: [requiredRule],
  };
  const fetchData = async () => {
    try {
      setLoading(true);
      setEstado([]);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchData();
    return () => API.tryCancel;
  }, []);

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    setTimeout(() => form.setFieldsValue(record));
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'check',
  };

  const onClickAdd = () => {
    setVisibleEdit(true);
  };

  const onClickEdit = () => {
    setVisibleEdit(true);
  };

  const onClickDelete = () => {};

  const onClickCancel = () => {};

  const onAutorizar = () => {};

  const onClickAddEditOrder = () => {
    setVisibleAddAccounts(true);
  };

  const onClickDeleteEditOrder = () => {};

  const onAutorizarEditEditOrder = () => {
    setVisibleAddAccounts(true);
  };

  const onAceptar = () => {};

  const onRegresar = () => {
    setVisibleAddAccounts(false);
  };

  const onDescargarPDF = () => {};

  const onDescargarExcel = () => {};

  const columns = [
    {
      titleText: 'No. de Orden de Pago',
      dataIndex: 'numero_orden_de_pago',
      key: 'numero_orden_de_pago',
      width: 200,
    },
    {
      titleText: 'Fecha de Orden de Pago',
      dataIndex: 'fecha_orden_de_pago',
      key: 'fecha_orden_de_pago',
      width: 200,
    },
    {
      titleText: 'Elaboró',
      dataIndex: 'elaboro',
      key: 'elaboro',
      width: 250,
    },
    {
      titleText: 'Puesto',
      dataIndex: 'puesto',
      key: 'puesto',
      width: 220,
    },
    {
      titleText: 'Área',
      dataIndex: 'area',
      key: 'area',
      width: 220,
    },
    {
      titleText: 'Importe por Pagar',
      dataIndex: 'importe_por_pagar',
      key: 'importe_por_pagar',
      width: 200,
    },
    {
      titleText: 'Fecha Programada de Pago',
      dataIndex: 'fecha_programada_de_pago',
      key: 'fecha_programada_de_pago',
      width: 250,
    },
    {
      titleText: 'Estado',
      dataIndex: 'estado',
      key: 'estado',
      width: 200,
    },
  ];

  const columnsEditOrder = [
    {
      titleText: 'No. de Cuenta por Pagar',
      dataIndex: 'numero_cuenta_por_pagar',
      key: 'numero_cuenta_por_pagar',
      width: 200,
    },
    {
      titleText: 'No. de Contra-Recibo',
      dataIndex: 'numero_contra_recibo',
      key: 'numero_contra_recibo',
      width: 200,
    },
    {
      titleText: 'Factura',
      dataIndex: 'factura',
      key: 'factura',
      width: 300,
    },
    {
      titleText: 'Comprobante',
      dataIndex: 'comprobante',
      key: 'comprobante',
      width: 200,
    },
    {
      titleText: 'Beneficiario',
      dataIndex: 'beneficiario',
      key: 'beneficiario',
      width: 250,
    },
    {
      titleText: 'Folio Agrupador',
      dataIndex: 'folio_agrupador',
      key: 'folio_agrupador',
      width: 200,
    },
    {
      titleText: 'Concepto General',
      dataIndex: 'concepto_general',
      key: 'concepto_general',
      width: 200,
    },
    {
      titleText: 'Importe por Pagar',
      dataIndex: 'importe_por_pagar',
      key: 'importe_por_pagar',
      width: 200,
    },
  ];

  const columnsAddAccounts = [
    {
      titleText: 'No. de Cuenta por Pagar',
      dataIndex: 'numero_cuenta_por_pagar',
      key: 'numero_cuenta_por_pagar',
      width: 200,
    },
    {
      titleText: 'Fecha de CxP',
      dataIndex: 'fecha_cxp',
      key: 'fecha_cxp',
      width: 200,
    },
    {
      titleText: 'Factura',
      dataIndex: 'factura',
      key: 'factura',
      width: 300,
    },
    {
      titleText: 'Beneficiario',
      dataIndex: 'beneficiario',
      key: 'beneficiario',
      width: 250,
    },
    {
      titleText: 'Importe Neto',
      dataIndex: 'importe_neto',
      key: 'importe_neto',
      width: 200,
    },
    {
      titleText: 'Folio Agrupador',
      dataIndex: 'folio_agrupador',
      key: 'folio_agrupador',
      width: 200,
    },
    {
      titleText: 'No. de Contra-Recibo',
      dataIndex: 'numero_contra_recibo',
      key: 'numero_contra_recibo',
      width: 200,
    },
    {
      titleText: 'Concepto General',
      dataIndex: 'concepto_general',
      key: 'concepto_general',
      width: 200,
    },
    {
      titleText: 'Estado',
      dataIndex: 'estado',
      key: 'estado',
      width: 200,
    },
  ];

  const onFinish = () => ({});
  const onCancel = () => {
    setVisibleEdit(false);
    form.resetFields();
    setSelectedRowKeys([]);
  };

  function ResultOrderGrid() {
    return (
      <Table
        cols={columns}
        data={[]}
        rowSelection={rowSelection}
        handleOnRowClick={handleOnRowClick}
        mobileColIndex={0}
        controls={{
          onClickAdd,
          onClickEdit,
          onClickDelete,
        }}
        customActions={[
          {
            onClick: onAutorizar,
            text: 'Autorizar',
            icon: <CheckCircleOutlined />,
            disabledBySelection: false,
            className: 'success',
          },
          {
            onClick: onClickCancel,
            text: 'Cancelar',
            icon: <StopOutlined />,
            disabledBySelection: false,
          },
          {
            onClick: onDescargarPDF,
            icon: <FilePdfOutlined />,
            text: 'Descargar PDF',
            disabledBySelection: false,
          },
          {
            onClick: onDescargarExcel,
            icon: <FileExcelOutlined />,
            text: 'Descargar Excel',
            disabledBySelection: false,
          },
        ]}
      />
    );
  }

  function ResultEditOrderGrid() {
    return !visibleAddAccounts ? (
      <>
        <Card
          extra={(
            <Button
              icon={<ArrowLeftOutlined />}
              className="ant-btn ant-btn-link"
              onClick={onCancel}
            >
              Regresar
            </Button>
        )}
          bordered={false}
        >
          <Row gutter={10}>
            <Col md={24} sm={24} xs={24}>
              <Form
                layout="vertical"
                form={form}
                onFinish={onFinish}
                scrollToFirstError
                name="orden_pago"
              >
                <Row gutter={10}>
                  <Col xs={12} sm={24} md={12}>
                    <Form.Item
                      name="numero_orden_de_pago"
                      label="No. de Orden de Pago"
                    >
                      <Input allowClear disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={12} sm={24} md={12}>
                    <Form.Item name="estado" label="Estado">
                      <Select datasource={estado} disabled />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={10}>
                  <Col xs={12} sm={24} md={12}>
                    <Form.Item
                      name="fecha_orden_de_pago"
                      label="Fecha de Orden de Pago"
                    >
                      <DatePicker placeholder="" format="DD/MM/YYYY" disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={12} sm={24} md={12}>
                    <Form.Item name="importe_total" label="Importe Total">
                      <Input allowClear disabled />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={10}>
                  <Col xs={12} sm={24} md={12}>
                    <Form.Item
                      name="fecha_programada_de_pago"
                      label="Fecha Programada de Pago"
                      rules={rules.required}
                    >
                      <DatePicker placeholder="" format="DD/MM/YYYY" />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Card>
        <Table
          cols={columnsEditOrder}
          data={[]}
          mobileColIndex={0}
          scrollToFirstError
          rowSelection={rowSelection}
          handleOnRowClick={handleOnRowClick}
          controls={{
            onClickEdit: onClickAddEditOrder,
            onClickDelete: onClickDeleteEditOrder,
          }}
          customActions={[
            {
              onClick: onAutorizarEditEditOrder,
              text: 'Autorizar',
              icon: <CheckCircleOutlined />,
              disabledBySelection: false,
              className: 'success',
            },
            {
              onClick: onCancel,
              text: 'Cancelar',
              icon: <StopOutlined />,
              disabledBySelection: false,
            },
          ]}
        />
      </>
    ) : (
      <>
        <Card
          extra={(
            <Button
              icon={<ArrowLeftOutlined />}
              className="ant-btn ant-btn-link"
              onClick={onRegresar}
            >
              Regresar
            </Button>
        )}
          bordered={false}
        />
        <Table
          cols={columnsAddAccounts}
          data={[]}
          rowSelection={rowSelection}
          handleOnRowClick={handleOnRowClick}
          mobileColIndex={0}
          customActions={[
            {
              onClick: onAceptar,
              text: 'Aceptar',
              icon: <CheckCircleOutlined />,
              disabledBySelection: false,
              className: 'success',
            },
          ]}
        />

      </>
    );
  }

  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visibleEdit ? <ResultOrderGrid /> : <ResultEditOrderGrid />}
      </Spin>
    </Row>
  );
}

export default OrdenDePago;
