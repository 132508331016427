import React from 'react';
import SingleForm from 'components/SingleForm';
import { getCABMS } from 'api/almacen';

export const permissionCabm = {
  permissionModel: 'cabm',
};

// eslint-disable-next-line react/prop-types
function CAMB({ permission }) {
  return (
    <SingleForm
      title="Tipo de operación"
      withoutState
      readOnly
      permission={permission}
      get={getCABMS}
    />
  );
}
export default CAMB;
