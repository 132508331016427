/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Transfer,
  Col,
  Select,
  Form,
  Typography,
} from 'antd';
import Pagination from './Pagination';

const { Text } = Typography;

function PlainTransfer({
  dataSource: _dataSource,
  formItemName,
  label,
  rules,
  form,
  nested,
  filterProp,
  pagination,
  callbackPagination,
  disabled,
  showSelectAll,
  ...restProps
}) {
  const [targetKeys, setTargetKeys] = useState([]);
  const [currentData, setCurrentData] = useState([]);
  const [page, setPage] = useState(1);
  const [q, setQ] = useState();
  const ref = useRef();

  const dataSource = (_dataSource?.results || _dataSource).concat(currentData).map((e) => ({
    ...e,
    key: e.id,
    title: `${e.clave ? `${e.clave} - ` : ''}${e[filterProp]}`,
  }));

  useEffect(() => {
    setTimeout(() => {
      const _keys = form.getFieldValue(formItemName) || [];
      if (!Array.isArray(_dataSource)) {
        setCurrentData(_keys.filter((e) => !_dataSource.results.some((e2) => e2.id === e.id)));
        form.setFieldsValue({ [formItemName]: _keys.map((e) => e.id) });
      }
      setTargetKeys(_keys.map((e) => e?.id || e));
    });
    // eslint-disable-next-line
  }, []);

  const handleChange = (_q) => {
    if (callbackPagination) {
      setQ(_q);
      clearTimeout(ref.current);
      ref.current = setTimeout(() => callbackPagination(page, _q), 2000);
      setPage(1);
    }
  };

  return (
    <div style={{ overflowX: 'scroll' }}>
      <Col xs={0} sm={0} md={24}>
        <Text style={{ color: '#000000DA' }}>
          {label}
        </Text>
        <Transfer
          titles={['Disponibles', 'Seleccionados']}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...restProps}
          disabled={disabled}
          targetKeys={targetKeys}
          dataSource={dataSource}
          className="plain-transfer"
          render={(item) => item.title}
          showSelectAll={showSelectAll}
          filterOption={(input, opt) => opt.title?.toLowerCase().trim()
            .indexOf(input?.toLowerCase().trim()) >= 0}
          onChange={(keys) => {
            setTargetKeys(keys);
            const validKeys = keys
              .filter((k) => typeof k === 'number')
              .map((k) => parseInt(k, 10));
            form.setFieldsValue({ [`${formItemName}`]: validKeys });
          }}
          pagination={pagination}
          showSearch
          oneWay
        />
        {!pagination ? (
          <Pagination
            count={_dataSource?.count}
            page={page}
            setPage={setPage}
            callback={callbackPagination}
          />
        ) : (<br />)}
      </Col>
      <Col xs={24} sm={24} md={0}>
        <Form.Item
          name={formItemName}
          label={label}
          rules={rules}
          hasFeedback
        >
          <Select
          // eslint-disable-next-line react/jsx-props-no-spreading
            {...restProps}
            disabled={disabled}
            showSearch
            mode="multiple"
            showArrow
            onSearch={handleChange}
            onClear={() => {
              handleChange(undefined);
              if (restProps.onClear) {
                restProps.onClear();
              }
            }}
            allowClear
            style={{ width: '100%' }}
            optionFilterProp="children"
            filterOption={(input, opt) => opt.children.toLowerCase()
              .indexOf(input.toLowerCase()) >= 0}
            onChange={(keys) => {
              setTargetKeys(keys.map((k) => k.toString()));
            }}
            onPopupScroll={(e) => {
              if (callbackPagination && dataSource.next) {
                const { scrollHeight, scrollTop, clientHeight } = e.target;
                if (scrollHeight - scrollTop === clientHeight
                    && scrollTop !== 0) {
                  setPage(page + 1);
                  callbackPagination(page + 1, q);
                }
              }
            }}
          >
            {dataSource.map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {item.title}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
    </div>
  );
}

PlainTransfer.propTypes = {
  dataSource: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]).isRequired,
  rules: PropTypes.array,
  formItemName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  form: PropTypes.any.isRequired,
  nested: PropTypes.bool,
  filterProp: PropTypes.string,
  currentData: PropTypes.array,
  pagination: PropTypes.bool,
  callbackPagination: PropTypes.func,
  disabled: PropTypes.bool,
  showSelectAll: PropTypes.bool,
};

PlainTransfer.defaultProps = {
  rules: [],
  nested: false,
  filterProp: null,
  currentData: [],
  pagination: true,
  callbackPagination: null,
  disabled: false,
  showSelectAll: false,
};

export default PlainTransfer;
