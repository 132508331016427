import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Row,
  Col,
  Spin,
  Form,
} from 'antd';
import API from 'utils/api';
import { onError, onSuccess } from 'utils/handlers';
import Select from 'components/Select';
import makeMessager from 'utils/complexFormsMessages';

const baseURI = 'controlpatrimonial/controles-y-seguimientos/';

function ControlYSeguimientos({
  activo,
  setForm,
  setCurrentTabKey,
}) {
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    try {
      setLoading(true);
      if (activo.length) {
        const res = await API.get(baseURI, {
          params: {
            activo_fijo: activo[0],
          },
        });
        form.setFieldsValue(res.data[0]);
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const showMsg = makeMessager(setCurrentTabKey, fetchData);

  const onFinishHandler = async (tabKey = null, _continue = true) => {
    try {
      if (tabKey) {
        showMsg(tabKey);
      } else {
        // eslint-disable-next-line no-use-before-define
        await onFinish(null, _continue);
      }
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    form.onFinishHandler = onFinishHandler;
    setForm(form);
    fetchData();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    form.resetFields();
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      const [activo_fijo] = activo;
      values.activo_fijo = activo_fijo;
      if (!values.id) {
        const res = await API.post(baseURI, values);
        if (res?.status === 201) {
          onSuccess(res, 'Agregado correctamente');
          onCancel();
          await fetchData();
        }
      } else {
        const res = await API.put(`${baseURI}${values.id}/`, values);
        if (res?.status === 200) {
          onSuccess(res, 'Actualizado correctamente');
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        <Form
          name="controlSeguimiento"
          form={form}
          layout="vertical"
          onFinish={onFinish}
          scrollToFirstError
          initialValues={{ estados_globales: 1 }}
        >
          <Row gutter={10}>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="parque_vehicular"
                label="Parque Vehícular"
              >
                <Select trueFalse />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="registro_inmobilario"
                label="Registro Inmobiliario"
              >
                <Select trueFalse />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="otros_bienes"
                label="Otros Bienes"
              >
                <Select trueFalse />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                label="Estado"
                // rules={rules.required}
                name="estados_globales"
                hasFeedback
              >
                <Select
                  dataSource={estadosGlobales}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>

    </Row>
  );
}

ControlYSeguimientos.propTypes = {
  activo: PropTypes.arrayOf(PropTypes.number).isRequired,
  setForm: PropTypes.func.isRequired,
  setCurrentTabKey: PropTypes.func.isRequired,
};

export default ControlYSeguimientos;
