/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Form,
  Input,
  Button,
  Spin,
  Row,
  Col,
  Modal,
  message,
  Tooltip,
  Typography,
} from 'antd';

import {
  QuestionCircleOutlined,
  SaveOutlined,
  ArrowLeftOutlined,
  EnvironmentOutlined,
} from '@ant-design/icons';

import API from 'utils/api';
import fetchSome from 'utils/fetchSome';
import { onError } from 'utils/handlers';
import { SET_TIPOS_VIALIDADES } from 'store/reducers/catalogs';
import NumericInput from './NumericInput';
import Select from './Select';
import FormSubmitCustomControls from './FormSubmitCustomControls';
import SearchDireccion from './SearchDireccion';

const { Text } = Typography;
const { TextArea } = Input;

function ModalDireccion({
  parentID,
  disabled,
  callback,
  currentURI,
  form,
}) {
  const tiposVialidades = useSelector(({ catalogs }) => catalogs.tiposVialidades);
  const [formDireccion] = Form.useForm();
  const [direccionStr, setDireccionStr] = useState();
  const [direccionObj, setDireccionObj] = useState();
  const [visibleDireccion, setVisibleDireccion] = useState(false);
  const [visibleNoSeCodigo, setVisibleNoSeCodigo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingPartial, setLoadingPartial] = useState(false);
  const [filteredColonies, setFilteredColonies] = useState([]);
  const [selected, setSelected] = useState();

  const formatValues = (values) => {
    const keys = Object.keys(values);
    const clone = {};
    keys.forEach((prop) => {
      const value = values[prop];
      if (value && value !== 'N/A') {
        clone[prop] = value;
      }
    });
    return clone;
  };

  const getDireccion = async (formValues = null) => {
    try {
      if (formValues) {
        const codigo_postal = filteredColonies.find((e) => e.id === formValues.codigo_postal);
        const formattedValues = formatValues({
          ...formValues,
          estado: codigo_postal.municipio.estado.nombre_de_AGEE || undefined,
          municipio: codigo_postal.municipio.nom_mun || undefined,
          codigo_postal: codigo_postal.d_codigo,
          colonia: codigo_postal.id,
        });
        formDireccion.setFieldsValue(formattedValues);
        setDireccionObj(formattedValues);
        setDireccionStr(`${formValues.calle_principal} #${formValues.numero_exterior},
        ${codigo_postal.d_tipo_asenta || ''}
        ${codigo_postal.d_asenta || ''},
        ${codigo_postal.d_codigo}, 
        ${codigo_postal.municipio.nom_mun || ''}, 
        ${codigo_postal.municipio.estado.nombre_de_AGEE || ''}`);
      } else {
        setLoadingPartial(true);
        const resParent = await API.get(`${currentURI}/${parentID}/`);
        if (resParent.direccion) {
          const data = resParent.direccion;
          const resColonies = await API.get('/catalogos/codigos-postales/', {
            params: {
              d_codigo: data.codigo_postal.d_codigo,
            },
          });
          const formattedValues = formatValues({
            ...data,
            estado: data.codigo_postal.municipio.estado.nombre_de_AGEE || undefined,
            municipio: data.codigo_postal.municipio.nom_mun || undefined,
            codigo_postal: data.codigo_postal.d_codigo,
            colonia: data.codigo_postal.id,
          });
          formDireccion.setFieldsValue(formattedValues);
          setDireccionObj(formattedValues);
          setFilteredColonies(resColonies.data || []);
          setDireccionStr(`${data.calle_principal} #${data.numero_exterior},
          ${data.codigo_postal.d_tipo_asenta || ''}
          ${data.codigo_postal.d_asenta || ''},
          ${data.codigo_postal.d_codigo}, 
          ${data.codigo_postal.municipio.nom_mun || ''}, 
          ${data.codigo_postal.municipio.estado.nombre_de_AGEE || ''}`);
        }
        setLoadingPartial(false);
      }
    } catch (err) {
      onError(err, setLoadingPartial);
    }
  };

  useEffect(() => {
    if (parentID) {
      getDireccion();
    } else {
      setDireccionStr();
    }
    // eslint-disable-next-line
  }, [parent]);

  const onCancel = () => {
    if (direccionObj) {
      formDireccion.resetFields();
      setTimeout(() => {
        formDireccion.setFieldsValue(direccionObj);
      });
    }
    setVisibleDireccion(false);
  };

  const onFinish = async () => {
    try {
      await formDireccion.validateFields();
      const formValues = formDireccion.getFieldsValue();
      const values = {
        ...formValues,
        codigo_postal: formValues.colonia,
      };
      delete values.estado;
      delete values.municipio;
      delete values.pais;
      delete values.colonia;
      if (parentID) {
        if (direccionStr) {
          const resPatch = await API.patch(`${currentURI}${parentID}/`, {
            direccion: values,
          });
          if (resPatch?.status === 200) {
            setVisibleDireccion(false);
            setTimeout(() => {
              getDireccion(resPatch.direccion);
            });
          }
        }
      } else {
        callback(values);
        setTimeout(() => {
          getDireccion(values);
        });
        form.setFieldsValue({ direccion: JSON.stringify(values) });
        setVisibleDireccion(false);
      }
    } catch (err) {
      onError(err, setLoadingPartial);
    }
  };

  const fetch = async () => {
    try {
      if (!tiposVialidades || !tiposVialidades.length) {
        fetchSome('/catalogos/tipos-de-vialidad/', SET_TIPOS_VIALIDADES);
      }
    } catch (err) {
      onError(err, setLoadingPartial);
    }
  };

  useEffect(() => {
    fetch();

    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  let timeout = null;
  const getFromPostalCode = (d_codigo) => {
    try {
      setLoading(true);
      clearTimeout(timeout);
      if (d_codigo.length === 5 && parseInt(d_codigo, 10)) {
        timeout = setTimeout(async () => {
          setLoading(true);
          const res = await API.get('/catalogos/codigos-postales/', {
            params: {
              d_codigo,
            },
          });
          if (res.data && res.data.length) {
            const [firstItem] = res.data;
            if (res.data.length === 1) {
              formDireccion.setFieldsValue({
                pais: 'México',
                codigo_postal: firstItem.d_codigo,
                estado: firstItem.municipio.estado.nombre_de_AGEE,
                municipio: firstItem.municipio.nom_mun,
                colonia: firstItem.id,
              });
              setFilteredColonies([firstItem]);
            } else {
              formDireccion.setFieldsValue({
                pais: 'México',
                codigo_postal: firstItem.d_codigo,
                estado: firstItem.municipio.estado.nombre_de_AGEE,
                municipio: firstItem.municipio.nom_mun,
                colonia: undefined,
              });
              setFilteredColonies(res.data);
            }
          } else {
            message.warn('No se encontró información relacionada al código postal brindado');
            formDireccion.resetFields();
            setFilteredColonies([]);
          }
          setLoading(false);
        }, 300);
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoadingPartial);
    }
  };

  const noSeMiCodigoPostal = () => {
    setVisibleNoSeCodigo(true);
  };

  const onCancelNoSeCodigo = () => {
    setVisibleNoSeCodigo(false);
    setSelected();
  };

  const rules = {
    required: [
      {
        message: 'El campo es requerido',
        required: true,
      },
    ],
    entre_calle: [
      {
        validator: async (rule, value) => {
          if (!value && !!formDireccion.getFieldValue('tipo_de_vialidad_entre')) {
            throw new Error('El campo es requerido');
          }
        },
      },
    ],
    tipo_de_vialidad_entre: [
      {
        validator: async (rule, value) => {
          if (!value && !!formDireccion.getFieldValue('entre_calle')) {
            throw new Error('El campo es requerido');
          }
        },
      },
    ],
    y_calle: [
      {
        validator: async (rule, value) => {
          if (!value && !!formDireccion.getFieldValue('tipo_de_vialidad_y_calle')) {
            throw new Error('El campo es requerido');
          }
        },
      },
    ],
    tipo_de_vialidad_y_calle: [
      {
        validator: async (rule, value) => {
          if (!value && !!formDireccion.getFieldValue('y_calle')) {
            throw new Error('El campo es requerido');
          }
        },
      },
    ],
  };

  const onFinishSearch = (record) => {
    const values = record || selected;
    if (values) {
      formDireccion.setFieldsValue({
        estado: values.municipio.estado.nombre_de_AGEE,
        municipio: values.municipio.nom_mun,
        colonia: values.id,
        codigo_postal: values.d_codigo,
      });
      setFilteredColonies([values]);
      onCancelNoSeCodigo();
    } else {
      message.warn('Seleccione un elemento');
    }
  };

  const cancelControl = {
    text: 'Cancelar',
    onClick: onCancel,
    icon: <ArrowLeftOutlined />,
  };

  const saveControl = {
    text: 'Guardar',
    onClick: onFinish,
    icon: <SaveOutlined />,
  };

  const backControl = {
    text: 'Regresar',
    onClick: onCancelNoSeCodigo,
    icon: <ArrowLeftOutlined />,
  };

  const saveSelectionControl = {
    text: 'Guardar',
    onClick: () => onFinishSearch(),
    icon: <SaveOutlined />,
  };

  const getControls = () => {
    if (visibleNoSeCodigo) {
      return [backControl, saveSelectionControl];
    }
    return [cancelControl, saveControl];
  };

  return (
    <>
      <Row gutter={10}>
        {direccionStr && (
          <Col style={{ display: 'flex', flex: 1 }}>
            <Text>
              {direccionStr}
            </Text>
          </Col>
        )}
        <Col>
          <Button
            disabled={disabled}
            onClick={() => setVisibleDireccion(true)}
            className="primary-on-hover"
            loading={loadingPartial}
          >
            {!loadingPartial && <EnvironmentOutlined style={{ marginRight: 5 }} />}
            {`${direccionStr ? 'Editar' : 'Agregar'} Dirección`}
          </Button>
        </Col>
      </Row>
      {!parentID && (
        <Form.Item name="direccion" hidden>
          <Input />
        </Form.Item>
      )}
      <br />
      <Modal
        width="90%"
        visible={visibleDireccion}
        closable={false}
        maskClosable={!loading}
        keyboard={!loading}
        footer={null}
        title={(
          <FormSubmitCustomControls
            label={`${direccionStr ? 'Editar' : 'Agregar'} Dirección`}
            controls={getControls()}
            loading={loading}
          />
        )}
        onCancel={onCancel}
        forceRender
        centered
      >
        <Spin tip="Cargando..." spinning={loading}>
          {!visibleNoSeCodigo ? (
            <Form
              form={formDireccion}
              layout="vertical"
              name="form_direccion"
              initialValues={{ pais: 'México' }}
              onFinish={async () => {
                await onFinish();
              }}
              onValuesChange={async (changedValues) => {
                if (changedValues.codigo_postal) {
                  getFromPostalCode(changedValues.codigo_postal);
                }
              }}
            >
              <Row gutter={10}>
                <Col span={24}>
                  <Form.Item
                    label={(
                      <>
                        <span style={{ marginRight: 5 }}>
                          Código Postal
                        </span>
                        <Tooltip
                          title="Ingresar este campo rellenara
                            automáticamente estado, municipio y las colonias disponibles"
                        >
                          <QuestionCircleOutlined className="primary-on-hover" />
                        </Tooltip>
                      </>
                    )}
                  >
                    <Input.Group compact>
                      <Col xs={24} sm={24} md={8}>
                        <NumericInput
                          name="codigo_postal"
                          maxLength={6}
                        />
                      </Col>
                      <Form.Item noStyle>
                        <Button
                          type="link"
                          onClick={noSeMiCodigoPostal}
                        >
                          No sé mi código
                        </Button>
                      </Form.Item>
                    </Input.Group>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="pais"
                    label="País"
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="estado"
                    label="Estado"
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="municipio"
                    label="Municipio"
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    label="Colonia"
                    name="colonia"
                    rules={rules.required}
                  >
                    <Select dataSource={filteredColonies} labelProp="d_asenta" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="numero_exterior"
                    label="Número Exterior"
                    rules={rules.required}
                  >
                    <Input maxLength={5} allowClear />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="numero_interior"
                    label="Número Interior"
                  >
                    <Input maxLength={5} allowClear />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <Form.Item>
                    <Input.Group style={{ display: 'flex' }} className="select-input" compact>
                      <Form.Item
                        name="tipo_de_vialidad"
                        rules={rules.required}
                        label="Tipo"
                      >
                        <Select style={{ width: 130 }} dataSource={tiposVialidades} />
                      </Form.Item>
                      <Form.Item
                        name="calle_principal"
                        label="Vialidad Principal"
                        rules={rules.required}
                      >
                        <Input maxLength={170} allowClear />
                      </Form.Item>
                    </Input.Group>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <Form.Item>
                    <Input.Group style={{ display: 'flex' }} className="select-input" compact>
                      <Form.Item
                        name="tipo_de_vialidad_entre"
                        label="Tipo"
                        rules={rules.tipo_de_vialidad_entre}
                      >
                        <Select style={{ width: 130 }} dataSource={tiposVialidades} />
                      </Form.Item>
                      <Form.Item
                        name="entre_calle"
                        rules={rules.entre_calle}
                        label="Entre Vialidad"
                      >
                        <Input maxLength={170} allowClear />
                      </Form.Item>
                    </Input.Group>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <Form.Item>
                    <Input.Group style={{ display: 'flex' }} className="select-input" compact>
                      <Form.Item
                        name="tipo_de_vialidad_y_calle"
                        label="Tipo"
                        rules={rules.tipo_de_vialidad_y_calle}
                      >
                        <Select style={{ width: 130 }} dataSource={tiposVialidades} />
                      </Form.Item>
                      <Form.Item
                        name="y_calle"
                        rules={rules.y_calle}
                        label="Y Vialidad"
                      >
                        <Input maxLength={170} allowClear />
                      </Form.Item>
                    </Input.Group>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={16} lg={16} xl={12}>
                  <Form.Item
                    name="descripcion_de_la_ubicacion"
                    label="Descripción de la Ubicación"
                  >
                    <TextArea autoSize={{ minRows: 2, maxRows: 3 }} maxLength={250} />
                  </Form.Item>
                </Col>
                <Form.Item hidden>
                  <Button htmlType="submit" />
                </Form.Item>
              </Row>
            </Form>
          ) : (
            <SearchDireccion
              setLoading={setLoading}
              callback={onFinishSearch}
              setSelected={setSelected}
            />
          )}
        </Spin>
      </Modal>
    </>
  );
}

ModalDireccion.propTypes = {
  parentID: PropTypes.number,
  disabled: PropTypes.bool,
  callback: PropTypes.func,
  currentURI: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
};

ModalDireccion.defaultProps = {
  parentID: null,
  disabled: false,
  callback: () => {},
};

export default ModalDireccion;
