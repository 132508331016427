import React, { useState, useEffect } from 'react';
import {
  Form,
  Row,
  Spin,
  Col,
  Input,
  Select,
  Card,
  Tabs,
  Divider,
  Typography,
} from 'antd';

import API from 'utils/api';
import { onError } from 'utils/handlers';
import {
  FileExcelOutlined,
  FilePdfOutlined,
  CheckCircleOutlined,
  CloseOutlined,
  StopOutlined,
} from '@ant-design/icons';
import Table from 'components/Table';
import FormSubmitControls from 'components/FormSubmitControls';
import Uploader from 'components/Uploader';

const { TabPane } = Tabs;
const { Text } = Typography;

function RecepcionOrdenCompra() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([1, 2]);
  const [currentTabKey, setCurrentTabKey] = useState('recepcion');
  const [mutable, setMutable] = useState(true);
  const [currentTabFactura, setCurrentTabFactura] = useState('general');
  const [mutableFactura, setMutableFactura] = useState(true);
  const [visibleFactura, setVisibleFactura] = useState(false);
  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    setSelectedRowKeys([1, 2]);
    setMutable(true);
  };
  const fetchData = async () => {
    try {
      setLoading(true);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchData();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    setTimeout(() => form.setFieldsValue(record));
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onChangeTabKey = () => {};

  const onFinish = () => ({});

  const onClickEdit = () => {
    setVisible(true);
    setMutableFactura(true);
  };

  const onAutorizar = () => {};

  const onRechazar = () => {};

  const onCancelar = () => {};

  const onDownloadPdf = () => {};

  const onDownLoadExcel = () => {};

  const onChangeTabFactura = () => {};

  const onExportPDFProceso = () => {};

  const onExportExcelProceso = () => {};

  const onAgregarFileFactura = () => {
    setVisibleFactura(true);
  };

  const columns = [
    {
      titleText: 'No. de Requisición Individual',
      dataIndex: 'no_requisicion_individual',
      key: 'no_requisicion_individual',
      width: 250,
    },
    {
      titleText: 'No. de Requisición Consolidada',
      dataIndex: 'no_requisicion_consolidada',
      key: 'no_requisicion_consolidada',
      width: 250,
    },
    {
      titleText: 'No. de Orden de Compra',
      dataIndex: 'no_orden_compra',
      key: 'no_orden_compra',
      width: 200,
    },
    {
      titleText: 'No. de Remesa',
      dataIndex: 'no_remesa',
      key: 'no_remesa',
      width: 150,
    },
    {
      titleText: 'Folio Agrupador',
      dataIndex: 'folio_agrupador',
      key: 'folio_agrupador',
      width: 200,
    },
    {
      titleText: 'Tipo de Operación',
      dataIndex: 'tipo_de_operacion',
      key: 'tipo_de_operacion',
      width: 200,
    },
    {
      titleText: 'Proveedor Asignado',
      dataIndex: 'proveedor_asignado',
      key: 'proveedor_asignado',
      width: 200,
    },
    {
      titleText: 'No. de Contrato',
      dataIndex: 'no_contrato',
      key: 'no_contrato',
      width: 150,
    },
    {
      titleText: 'Fecha de Contrato',
      dataIndex: 'fecha_contradeto',
      key: 'fecha_contradeto',
      width: 200,
    },
    {
      titleText: 'No. de Recepción de Orden de Compra',
      dataIndex: 'no_recepcion_orden_compra',
      key: 'no_recepcion_orden_compra',
      width: 300,
    },
    {
      titleText: 'Fecha de Elaboración',
      dataIndex: 'fecha_elaboracion',
      key: 'fecha_elaboracion',
      width: 200,
    },
    {
      titleText: 'Elaboró',
      dataIndex: 'elaboro',
      key: 'elaboro',
      width: 150,
    },
    {
      titleText: 'Puesto',
      dataIndex: 'puesto',
      key: 'puesto',
      width: 150,
    },
    {
      titleText: 'Área',
      dataIndex: 'area',
      key: 'area',
      width: 150,
    },
    {
      titleText: 'Estado',
      dataIndex: 'estado',
      key: 'estado',
      width: 150,
    },
    {
      titleText: 'Pólizas',
      dataIndex: 'polizas',
      key: 'polizas',
      width: 150,
    },
    {
      titleText: 'Movimiento Presupuestal',
      dataIndex: 'movimiento_presupuestal',
      key: 'movimiento_presupuestal',
      width: 200,
    },
    {
      titleText: 'Cuenta por Pagar',
      dataIndex: 'cuenta_por_pagar',
      key: 'cuenta_por_pagar',
      width: 200,
    },
    {
      titleText: 'Pago',
      dataIndex: 'pago',
      key: 'pago',
      width: 150,
    },
  ];

  const columnsRecepcion = [
    {
      titleText: 'Clave Presupuestal',
      dataIndex: 'clave_presupuestal',
      key: 'clave_presupuestal',
      width: 200,
    },
    {
      titleText: 'COG',
      dataIndex: 'cog',
      key: 'cog',
      width: 150,
    },
    {
      titleText: 'Finalidad del Gasto',
      dataIndex: 'finalidad_del_gasto',
      key: 'finalidad_del_gasto',
      width: 200,
    },
    {
      titleText: 'Clave de Producto',
      dataIndex: 'clave_de_producto',
      key: 'clave_de_producto',
      width: 200,
    },
    {
      titleText: 'Producto',
      dataIndex: 'producto',
      key: 'producto',
      width: 200,
    },
    {
      titleText: 'Unidad de Medida',
      dataIndex: 'unidad_de_medida',
      key: 'unidad_de_medida',
      width: 200,
    },
    {
      titleText: 'Cantidad',
      dataIndex: 'cantidad',
      key: 'cantidad',
      width: 200,
    },
    {
      titleText: 'Cantidad Recibida',
      dataIndex: 'cantidad_recibida',
      key: 'cantidad_recibida',
      width: 200,
    },
    {
      titleText: 'Cantidad por Recibir',
      dataIndex: 'cantidad_por_recibir',
      key: 'cantidad_por_recibir',
      width: 200,
    },
    {
      titleText: 'Precio Unitario',
      dataIndex: 'precio_unitario',
      key: 'precio_unitario',
      width: 200,
    },
    {
      titleText: 'Subtotal',
      dataIndex: 'subtotal',
      key: 'subtotal',
      width: 200,
    },
    {
      titleText: 'IVA',
      dataIndex: 'iva',
      key: 'iva',
      width: 150,
    },
    {
      titleText: 'Total',
      dataIndex: 'total',
      key: 'total',
      width: 150,
    },
  ];

  const columnFactura = [
    {
      titleText: 'Folio Fiscal',
      dataIndex: 'folio_fiscal',
      key: 'folio_fiscal',
      width: 200,
    },
    {
      titleText: 'Folio',
      dataIndex: 'folio',
      key: 'folio',
      width: 200,
    },
    {
      titleText: 'Nombre de Emisor',
      dataIndex: 'nombre_emisor',
      key: 'nombre_emisor',
      width: 200,
    },
    {
      titleText: 'Tipo CFDI',
      dataIndex: 'tipo_cfdi',
      key: 'tipo_cfdi',
      width: 200,
    },
    {
      titleText: 'RFC',
      dataIndex: 'rfc',
      key: 'rfc',
      width: 200,
    },
    {
      titleText: 'Importe Total',
      dataIndex: 'importe_total',
      key: 'importe_total',
      width: 200,
    },
  ];

  const columnFlujo = [
    {
      titleText: 'No.',
      dataIndex: 'no',
      key: 'no',
      width: 200,
    },
    {
      titleText: 'Actividad',
      dataIndex: 'actividad',
      key: 'actividad',
      width: 200,
    },
    {
      titleText: 'Rol de Firma',
      dataIndex: 'rol_firma',
      key: 'rol_firma',
      width: 200,
    },
    {
      titleText: 'Fecha de Realización',
      dataIndex: 'fecha_realizacion',
      key: 'fecha_realizacion',
      width: 220,
    },
    {
      titleText: 'Puesto',
      dataIndex: 'puesto',
      key: 'puesto',
      width: 200,
    },
    {
      titleText: 'Empleado',
      dataIndex: 'empleado',
      key: 'empleado',
      width: 200,
    },
    {
      titleText: 'Área',
      dataIndex: 'area',
      key: 'area',
      width: 200,
    },
    {
      titleText: 'Estado de Proceso',
      dataIndex: 'estado_proceso',
      key: 'area',
      width: 200,
    },
    {
      titleText: 'Documento a Adjuntar',
      dataIndex: 'documento_adjuntar',
      key: 'documento_adjuntar',
      width: 200,
    },
    {
      titleText: 'Documento que se Adjunta',
      dataIndex: 'documento_adjuntar',
      key: 'documento_que_adjunta',
      width: 250,
    },
    {
      titleText: 'Falta Adjunto',
      dataIndex: 'falta_adjunto',
      key: 'falta_adjunto',
      width: 200,
    },
    {
      titleText: 'Autorizado/Rechazado',
      dataIndex: 'autorizado_rechazado',
      key: 'autorizado_rechazado',
      width: 200,
    },
    {
      titleText: 'Causas de Rechazo',
      dataIndex: 'causas_rechazo',
      key: 'causas_rechazo',
      width: 200,
    },
    {
      titleText: 'Observaciones',
      dataIndex: 'observaciones',
      key: 'observaciones',
      width: 200,
    },
    {
      titleText: 'Días de Dilación',
      dataIndex: 'dias_dilacion',
      key: 'dias_dilacion',
      width: 200,
    },
  ];
  function AddFactura() {
    return (
      <>
        <Form
          layout="vertical"
        >
          <Row gutter={10}>
            <Col xs={12} sm={12} md={4}>
              <Text>Cargar CFDI</Text>
            </Col>
            <Col xs={24} sm={24} md={11}>
              <Uploader
                limitMB={50}
                file={form.getFieldValue('cfdi')}
                formItemName="cfdi"
                allowedExtensions={['pdf']}
                allowPreview={false}
              />
            </Col>
            <Col xs={24} sm={24} md={12} />
          </Row>
        </Form>
        <Row gutter={10}>
          <Col md={24} sm={24} xs={24}>
            <Form
              layout="vertical"
              labelAlign="left"
              labelWrap
            >
              <Row gutter={10}>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="nombre_receptor"
                    label="Nombre de Receptor"
                    hasFeedback
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="rfc_receptor"
                    label="RFC de Receptor"
                    hasFeedback
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="version_cfdi"
                    label="Versión CFDI"
                    hasFeedback
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="folio_cfdi"
                    label="Folio CFDI"
                    hasFeedback
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="folio_factura"
                    label="Folio de Factura"
                    hasFeedback
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="fecha"
                    label="Fecha"
                    hasFeedback
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="lugar_expedicion"
                    label="Lugar de Expedición"
                    hasFeedback
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="tipo_cfdi"
                    label="Tipo de CFDI"
                    hasFeedback
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="nombre_proveedor"
                    label="Nombre de Proveedor"
                    hasFeedback
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="RFC"
                    label="RFC"
                    hasFeedback
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="regimen_fiscal"
                    label="Régimen Fiscal"
                    hasFeedback
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="uso_cdfi"
                    label="Uso CFDI"
                    hasFeedback
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="metodo_pago"
                    label="Método de Pago"
                    hasFeedback
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="forma_pago"
                    label="Forma de Pago"
                    hasFeedback
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="moneda"
                    label="Moneda"
                    hasFeedback
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="subtotal"
                    label="Subtotal"
                    hasFeedback
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="iva"
                    label="IVA"
                    hasFeedback
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="total"
                    label="Total"
                    hasFeedback
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="isr"
                    label="Retención ISR"
                    hasFeedback
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="retencioniva"
                    label="Retención IVA"
                    hasFeedback
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="retencion_cedular"
                    label="Retención Cedular"
                    hasFeedback
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="neto"
                    label="Neto"
                    hasFeedback
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </>
    );
  }

  function Blank() {
    return <Row />;
  }

  function TabsFactura() {
    return (
      <Tabs
        activeKey={currentTabFactura}
        onChange={mutableFactura ? setCurrentTabFactura : onChangeTabFactura}
        type="card"
      >
        <TabPane
          tab="General"
          key="general"
          forceRender
        >
          <Table
            cols={columnFactura}
            data={[]}
            rowSelection={rowSelection}
            handleOnRowClick={handleOnRowClick}
            mobileColIndex={0}
            controls={{
              onClickAdd: () => onAgregarFileFactura(),
            }}
          />
          <Divider plain> </Divider>
          {
          visibleFactura ? (<AddFactura />) : (<Blank />)
        }
        </TabPane>
      </Tabs>
    );
  }

  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible ? (
          <Table
            cols={columns}
            data={[]}
            rowSelection={rowSelection}
            handleOnRowClick={handleOnRowClick}
            mobileColIndex={0}
            controls={
              {
                onClickEdit,
              }
            }
            allowEdit
            allowExport
            customActions={[
              {
                onClick: onAutorizar,
                text: 'Autorizar',
                icon: <CheckCircleOutlined />,
                disabledBySelection: false,
              },
              {
                onClick: onRechazar,
                text: 'Rechazar',
                icon: <CloseOutlined />,
                disabledBySelection: false,
              },
              {
                onClick: onCancelar,
                text: 'Cancelar',
                icon: <StopOutlined />,
                disabledBySelection: false,
              },
              {
                onClick: onDownloadPdf,
                icon: <FilePdfOutlined />,
                text: 'Descargar PDF',
                disabledBySelection: false,
              },
              {
                onClick: onDownLoadExcel,
                icon: <FileExcelOutlined />,
                text: 'Descargar Excel',
                disabledBySelection: false,
              },
            ]}
          />
        ) : (
          <>
            <Form
              form={form}
              layout="vertical"
              name="inversion"
              onFinish={onFinish}
              labelAlign="left"
              labelWrap
            >
              <Row gutter={10}>
                <Col xs={12} sm={12} md={8}>
                  <Form.Item
                    name="fecha_de_Elaboración"
                    label="Fecha de Elaboración"
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={8}>
                  <Form.Item
                    name="no_de_requisición_invidividual"
                    label="No. de Requisición Invidividual"
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={8}>
                  <Form.Item
                    name="no_de_cuadro_comparativo"
                    label="No. de Cuadro Comparativo"
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={8}>
                  <Form.Item
                    name="fecha_compromiso"
                    label="Fecha Compromiso"
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={8}>
                  <Form.Item
                    name="elaboro"
                    label="Elaboró"
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={8}>
                  <Form.Item
                    name="no_requisicion_consolidada"
                    label="No. de Requisición Consolidada"
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={8}>
                  <Form.Item
                    name="proveedor"
                    label="Proveedor"
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={8}>
                  <Form.Item
                    name="fecha_recepcion"
                    label="Fecha de Recepción"
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={8}>
                  <Form.Item
                    name="puesto"
                    label="Puesto"
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={8}>
                  <Form.Item
                    name="folio_agrupador"
                    label="Folio Agrupador"
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={8}>
                  <Form.Item
                    name="no_contrato"
                    label="No. de Contrato"
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={8}>
                  <Form.Item
                    name="dia_retraso"
                    label="Días de Retraso"
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={8}>
                  <Form.Item
                    name="area"
                    label="Área"
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={8}>
                  <Form.Item
                    name="area_requirente"
                    label="Área Requirente"
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={8}>
                  <Form.Item
                    name="no_de_orden_compra"
                    label="No. de Orden de Compra"
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={8}>
                  <Form.Item
                    name="aplica_penalizacion"
                    label="¿Aplica penalización?"
                  >
                    <Select allowClear trueFalse />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={8}>
                  <Form.Item
                    name="no_de_cuenta_recepcion"
                    label="No. de Recepción"
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={8}>
                  <Form.Item
                    name="tipo_operacion"
                    label="Tipo de Operación"
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={8}>
                  <Form.Item
                    name="no_de_remesa"
                    label="No. de Remesa"
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={8}>
                  <Form.Item
                    name="penalizacion"
                    label="Penalización"
                  >
                    <Select allowClear trueFalse />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={8}>
                  <Form.Item
                    name="recepcion_conformidad"
                    label="Recepción de Conformidad"
                  >
                    <Input allowClear />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="observaciones"
                    label="Observaciones"
                  >
                    <Input.TextArea allowClear />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={8}>
                  <Form.Item
                    name="estado"
                    label="Estado"
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <Card
              className="form-container"
              title={`${currentTabKey.includes('recepcion', 'flujo_proceso')
                ? 'Recepción de Orden de Compra'
                : 'Remesas de Orden de Compra'}`}
              extra={(
                <FormSubmitControls
                  onCancel={onCancel}
                  loading={loading}
                />
            )}
              bordered={false}
            >
              <Tabs
                activeKey={currentTabKey}
                onChange={mutable ? setCurrentTabKey : onChangeTabKey}
                type="card"
              >
                <TabPane
                  tab="Recepción"
                  key="recepcion"
                >
                  <Table
                    cols={columnsRecepcion}
                    data={[]}
                    rowSelection={rowSelection}
                    handleOnRowClick={handleOnRowClick}
                    mobileColIndex={0}
                  />
                </TabPane>
                <TabPane
                  tab="Anexos"
                  key="anexos"
                >
                  <Blank />
                </TabPane>
                <TabPane
                  tab="Factura"
                  key="factura"
                  forceRender
                >
                  <Row gutter={10}>
                    <Col span={24}>
                      <TabsFactura />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane
                  tab="Flujo de Proceso"
                  key="flujo_proceso"
                  forceRender
                >
                  <Table
                    cols={columnFlujo}
                    data={[]}
                    rowSelection={rowSelection}
                    handleOnRowClick={handleOnRowClick}
                    mobileColIndex={0}
                    customActions={[
                      {
                        onClick: onExportPDFProceso,
                        text: 'Descargar PDF',
                        icon: <FilePdfOutlined />,
                        disabledBySelection: false,
                      },
                      {
                        onClick: onExportExcelProceso,
                        icon: <FileExcelOutlined />,
                        text: 'Descargar Excel',
                        disabledBySelection: false,
                      },
                    ]}
                  />

                </TabPane>
              </Tabs>
            </Card>
          </>
        )}
      </Spin>
    </Row>
  );
}

export default RecepcionOrdenCompra;
