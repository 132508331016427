import React from 'react';
import SingleForm from 'components/SingleForm';
import { getUnidadesDeMedida } from 'api/configuracion';

export const permissionUnidadDeMedida = {
  permissionModel: 'unidaddemedida',
};

// eslint-disable-next-line react/prop-types
function UnidadesDeMedida({ permission }) {
  return (
    <SingleForm
      title="Unidades De Medida"
      withoutState
      readOnly
      permission={permission}
      get={getUnidadesDeMedida}
    />
  );
}
export default UnidadesDeMedida;
