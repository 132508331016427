import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Form,
  Input,
  // Button,
  Row,
  Col,
  Spin,
  Card,
} from 'antd';

import API from 'utils/api';
import { onError } from 'utils/handlers';
import Table from 'components/Table';
import FormSubmitControls from 'components/FormSubmitControls';
import Select from 'components/Select';

export const permissionClaseBienInmueble = {
  permissionModel: 'clasebieninmueble',
};

const baseURI = '/controlpatrimonial/bien-inmueble/';

// eslint-disable-next-line react/prop-types
function InventarioDeBienInmueble({ permission }) {
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState([]);
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  const [currentPage, setCurrentPage] = useState(1);

  const fetchData = async (page = 1, q = null) => {
    try {
      setLoading(true);
      setCurrentPage(page);
      const response = await API.get(baseURI, { params: { page, ...q } });
      if (response?.status === 200) {
        setData(response.data);
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };
  // const fetchAll = async () => {
  //   try {
  //     setLoading(true);
  //     const resFolios = await API.get('/configuraciones/configuraciones-de-folios/');
  //     setFolios(resFolios.data || []);
  //     await fetchData();
  //     setLoading(false);
  //   } catch (error) {
  //     onError(error, setLoading);
  //   }
  // };

  useEffect(() => {
    fetchData();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    setSelectedRowKeys([]);
  };

  // const onFinish = async () => {
  //   try {
  //     setLoading(true);
  //     await form.validateFields();
  //     const values = getFormattedValues(form.getFieldsValue());
  //     if (!selectedRowKeys.length) {
  //       const response = await API.post(baseURI, values);
  //       if (response?.status === 201) {
  //         if (response.data) {
  //           onSuccess(response, 'Agregado correctamente');
  //           onCancel();
  //           await fetchData();
  //         }
  //       }
  //     } else {
  //       const [key] = selectedRowKeys;
  //       const response = await API.put(`${baseURI}${key}/`, values);
  //       if (response?.status === 200) {
  //         if (response.data) {
  //           onSuccess(response, 'Actualizado correctamente');
  //           onCancel();
  //           await fetchData();
  //         }
  //       }
  //     }
  //     setLoading(false);
  //   } catch (err) {
  //     onError(err, setLoading);
  //   }
  // };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    form.setFieldsValue(record);
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  // const onClickAdd = () => {
  //   onCancel();
  //   setVisible(true);
  // };

  // const onClickEdit = () => {
  //   setVisible(true);
  // };

  // const onClickDelete = () => {
  //   setVisibleAlert(true);
  // };

  const columns = [
    {
      titleText: 'Clave',
      dataIndex: 'clave',
      key: 'clave',
      width: 150,
    },
    {
      titleText: 'Clave CONAC',
      dataIndex: 'concatenado',
      key: 'concatenado',
      width: 150,
    },
    {
      titleText: 'Concepto',
      dataIndex: 'concepto',
      key: 'concepto',
      width: 200,
    },
    {
      titleText: 'Descripción',
      dataIndex: 'descripcion',
      key: 'descripcion',
      width: 200,
    },
    {
      titleText: 'Nombre',
      dataIndex: 'nombre',
      key: 'nombre',
      width: 200,
    },

  ];

  // const rules = {
  //   required: [
  //     {
  //       required: true,
  //       message: 'El campo es requerido',
  //     },
  //   ],
  //   RFC: [
  //     {
  //       required: true,
  //       message: 'El campo es requerido',
  //     },
  //     {
  //       pattern: RFC_PATTERN,
  //       message: 'Ingrese un RFC válido',
  //     },
  //   ],
  // };

  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible
          ? (
            <Table
              allowImport
              baseURI={baseURI}
              cols={columns}
              data={data.results}
              permission={permission}
              rowSelection={rowSelection}
              handleOnRowClick={handleOnRowClick}
              currentPage={currentPage}
              loading={loading}
              noControls
              pagination={false}
              paginationCallback={fetchData}
              count={data.count}
              mobileColIndex={0}
            />
          ) : (
            <Card
              className="form-container"
              title="Vista Previa"
              extra={(
                <FormSubmitControls onCancel={onCancel} loading={loading} />
              )}
              bordered={false}
            >
              <Form
                layout="vertical"
                form={form}
                scrollToFirstError
              >
                <Row gutter={10}>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      label="Clave"
                      name="clave"
                      hasFeedback
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      label="Concatenado"
                      name="concatenado"
                      hasFeedback
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      label="Concepto"
                      name="concepto"
                      hasFeedback
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      label="Descripción"
                      name="descripcion"
                      hasFeedback
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      label="Nombre"
                      name="nombre"
                      hasFeedback
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="concatenado"
                      label="Concatenado"
                      hasFeedback
                    />
                    <Input disabled />
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="sub_clase"
                      label="Subclase"
                      hasFeedback
                    >
                      <Select dataSource={estadosGlobales} disabled />
                    </Form.Item>
                  </Col>
                  {/* <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="foliador"
                      label="Foliador"
                      hasFeedback
                    >
                      <Select dataSource={folios} disabled />
                    </Form.Item>
                  </Col> */}
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="estados_globales"
                      label="Estado"
                      hasFeedback
                    >
                      <Select dataSource={estadosGlobales} disabled />
                    </Form.Item>
                  </Col>
                  {/* <Form.Item hidden>
                    <Button htmlType="submit" />
                  </Form.Item> */}
                </Row>
              </Form>
              {/* <ModalDireccion
                currentURI={baseURI}
                parent={data.find((item) => item.id === selectedRowKeys[0])}
                form={form}
              /> */}
            </Card>
          )}

      </Spin>
    </Row>
  );
}

export default InventarioDeBienInmueble;
