import API from 'utils/api';
import { onSuccess, onError } from 'utils/handlers';

const baseURI = 'adquisiciones/catalogo-de-criterio-de-requisicion/';

/**
 * GET Catálogo De Criterio De Requisición
 * @returns {Promise<Array<object>>}
 */
export const getCatalogoDeCriterioDeRequesicion = async () => {
  try {
    const response = await API.get(baseURI);
    return response.data;
  } catch (err) {
    onError(err);
    return [];
  }
};

/**
 * POST Catálogo De Criterio De Requisición
 * @param {object} values
 * @returns {Promise<object|boolean>}
 */
export const postCatalogoDeCriterioDeRequesicion = async (values) => {
  try {
    const response = await API.post(baseURI, values);
    onSuccess(response, 'Agregado correctamente');
    return response.data;
  } catch (err) {
    onError(err);
    return false;
  }
};

/**
 * PATCH Catálogo De Criterio De Requisición
 * @param {number} id
 * @param {object} values
 * @returns {Promise<object|boolean>}
 */
export const patchCatalogoDeCriterioDeRequesicion = async (id, values) => {
  try {
    const response = await API.patch(`${baseURI}${id}`, values);
    onSuccess(response, 'Editado correctamente');
    return response.data;
  } catch (err) {
    onError(err);
    return false;
  }
};

/**
 * DELETE Catálogo de Criterio de Requisición
 * @param {number} id
 * @returns {Promise<boolean>}
 */
export const deleteCatalogoDeCriterioDeRequesicion = async (id) => {
  try {
    const response = await API.delete(`${baseURI}${id}`);
    onSuccess(response, 'Eliminado correctamente');
    return true;
  } catch (err) {
    onError(err);
    return false;
  }
};
