import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Row,
  Col,
  Spin,
  Modal,
  message,
  Card,
  Tabs,
  Button,
  TreeSelect,
} from 'antd';
import { useSelector } from 'react-redux';
import API from 'utils/api';
import fetchSome from 'utils/fetchSome';
import { onError, onSuccess } from 'utils/handlers';
import Table from 'components/Table';
import FormSubmitControls from 'components/FormSubmitControls';
import ModalDelete from 'components/ModalDelete';
import Select from 'components/Select';
import { customIntegerNormalizer } from 'utils/normalizers';
// ?Se comento esto ya que daba error al intentar hacer un pr y yo no modifique este modulo
import { isMutable } from 'utils/estadosGlobales';
import BusquedaClavesContables from 'components/BusquedaClavesContables';
import { InputSearchPro } from 'components/InputSearch';
import CuentasDeInvercionOTarjetasDePago from 'components/Catalogos/CuentasDeInvercionOTarjetasDePago';
import FondosDeCuentasBancarias from 'components/Tesoreria/Fondos';
import DatosAdicionales from 'components/Tesoreria/DatosAdicionales';
import makeMessager from 'utils/complexFormsMessages';
import { SET_URS, SET_CONFIG_MASCARA_CONTABLE } from 'store/reducers/catalogs';
import { useHistory } from 'react-router-dom';

export const permissionCuentasBancarias = {
  permissionModel: 'cuentabancaria',
};

const { TabPane } = Tabs;
const baseURI = 'configuraciones/cuentas-bancarias/';

const levelSorter = (a, b) => {
  if (a.nivel < b.nivel) {
    return -1;
  }
  if (a.nivel > b.nivel) {
    return 1;
  }
  return 0;
};

// eslint-disable-next-line react/prop-types
function CuentasBancarias({ permission }) {
  const history = useHistory();
  const urs = useSelector(({ catalogs }) => catalogs.urs).sort(levelSorter);
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [mutable, setMutable] = useState(true);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [bancos, setBancos] = useState([]);
  const [metodosPago, setMetodosPago] = useState([]);
  const [tiposDeFirmas, setTiposDeFirmas] = useState([]);
  const [finalidades, setFinalidades] = useState([]);
  const [tipo, setTipo] = useState([]);
  const [selectedClave, setSelectedClave] = useState();
  const [visibleBusquedaCuentas, setVisibleBusquedaCuentas] = useState(false);
  const [currentTabKey, setCurrentTabKey] = useState('general');
  const [treeData, setTreeData] = useState([]);
  const [cuentaContableFound, setCuentaContableFound] = useState([]);
  const [formDatosAdicionales, setFormDatosAdicionales] = useState(null);
  const complexForms = [{ key: 'datos_adicionales', formInstance: formDatosAdicionales }];
  const basicKeys = ['general'];

  const configMascaraContable = useSelector(({ catalogs }) => catalogs.configMascaraContable);
  const [mascara, setMascara] = useState();
  const [separador, setSeparador] = useState();
  const [pattern, setPattern] = useState();
  const [hasAllConfigurations, setHasAllConfigurations] = useState(false);
  const [contentTypes, setContentTypes] = useState([]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await API.get(baseURI);
      setData(res.data);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const getURValuesToSend = () => {
    const unidad_responsable = form.getFieldValue('area_responsable');
    if (unidad_responsable) {
      const [object_id, nivel] = unidad_responsable.split('/');
      const content_type = contentTypes.find((ct) => ct.nivel.toString() === nivel).id;
      return { object_id, content_type };
    }
    return { object_id: undefined, content_type: undefined };
  };

  const getURForTreeSelect = (_data) => {
    if (_data.content_type_URs) {
      const { nivel } = contentTypes.find((ct) => ct.id === _data.content_type_URs);
      const { object_id_URs } = _data;
      setTimeout(() => {
        form.setFieldsValue({ area_responsable: `${object_id_URs}/${nivel}` });
      });
    }
  };

  const showAlertConfigs = () => {
    message.info(
      (
        <>
          <span>
            No se han registrado/autorizado todas las Configuraciones de Segmentos Contables
          </span>
          <Button
            type="link"
            onClick={() => {
              history.push('/configuracion/contabilidad/segmentos-contables');
              message.destroy();
            }}
          >
            Ir
          </Button>
        </>
      ), 3,
    );
  };

  const hasAllConfigs = async () => {
    const res = await API.get('configuraciones/configuraciones-de-segmentos-contables/');
    if (res?.status === 200) {
      const configuraciones = res.data.filter((r) => r.estados_globales === 4);
      if (configuraciones.length === 10) {
        setHasAllConfigurations(true);
        return true;
      }
    }
    return false;
  };

  const normalizeDataUrs = (_data) => {
    if (_data) {
      const output = _data.map((item) => {
        const clone = { ...item };
        const unidad_responsable = normalizeDataUrs(clone.unidad_responsable);
        const centro_de_costo = normalizeDataUrs(clone.centro_de_costo);
        if (!unidad_responsable.length && !centro_de_costo.length) {
          delete clone.unidad_responsable;
          delete clone.centro_de_costo;
          return ({
            title: clone.descripcion,
            value: `${clone.id}/${clone.nivel}`,
          });
        }
        if (unidad_responsable) {
          return ({
            title: clone.descripcion,
            value: `${clone.id}/${clone.nivel}`,
            children: unidad_responsable,
            // disabled: true,
          });
        }
        return ({
          title: clone.descripcion,
          value: `${clone.id}/${clone.nivel}`,
          children: centro_de_costo,
          // disabled: true,
        });
      });
      return output;
    }
    return false;
  };

  const fetchAll = async () => {
    try {
      if (configMascaraContable) {
        setMascara(configMascaraContable?.mascara);
        const separator = configMascaraContable?.separador;
        setSeparador(separator);
        const _segmentos = configMascaraContable?.mascara?.split(separator) || [];
        if (_segmentos?.length) {
          // eslint-disable-next-line prefer-template
          const patt = _segmentos.reduce((acum, curr, idx) => `${acum}[0-9]{${curr.length}}`
            + (idx !== _segmentos.length - 1 ? separator : '$'), '^');
          const regExp = new RegExp(`${patt}`);
          setPattern(regExp);
        }
      } else {
        await fetchSome('configuraciones/configuraciones-de-mascaras/', SET_CONFIG_MASCARA_CONTABLE);
      }
      setLoading(true);
      const resBancos = await API.get('/configuraciones/bancos/');
      setBancos(resBancos.data);

      const resFirmas = await API.get('/configuraciones/tipos-de-firma-de-cuentas-bancarias/');
      setTiposDeFirmas(resFirmas.data);

      const resFinalidades = await API.get('/configuraciones/finalidad-de-las-cuentas-bancarias/');
      setFinalidades(resFinalidades.data);

      const resTipo = await API.get('/configuraciones/tipos-de-cuentas-bancarias/');
      setTipo(resTipo.data);

      const resUR = await API.get('estructura-organizacional/unidades-de-trabajo');
      const formattedUr = normalizeDataUrs(resUR.data);
      setTreeData(formattedUr);

      const resContentTypes = await API.get('catalogos/content-types-ur');
      setContentTypes(resContentTypes.data);

      const resMetodosPago = await API.get('/catalogos/metodos-de-pago/');
      setMetodosPago(resMetodosPago.data);

      if (!urs?.length) {
        fetchSome('/catalogos/content-types-ur/', SET_URS);
      }

      await fetchData();
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    const validateConfigs = async () => {
      setLoading(true);
      if (await hasAllConfigs()) {
        fetchAll();
      } else {
        showAlertConfigs();
        setLoading(false);
      }
    };
    validateConfigs();

    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setVisible(false);
    setMutable(true);
    form.resetFields();
    setCuentaContableFound([]);
    setSelectedRowKeys([]);
    setCurrentTabKey('general');
  };

  const onFinish = async (_values, _continue = false) => {
    try {
      setLoading(true);
      await form.validateFields();
      const contentValues = getURValuesToSend();
      const values = {
        ...form.getFieldValue(),
        content_type_URs: contentValues?.content_type,
        object_id_URs: contentValues?.object_id,
      };
      delete values.clave_contable__clave;
      delete values.q_ur;
      if (!values.object_id) {
        delete values.content_type;
      }
      if (!selectedRowKeys.length) {
        const response = await API.post(baseURI, values);
        if (response?.status === 201) {
          if (!_continue) {
            onSuccess(response, 'Agregado correctamente');
            onCancel();
            await fetchData();
          } else {
            await fetchData();
            setSelectedRowKeys([response.data.id]);
          }
        }
      } else {
        const [key] = selectedRowKeys;
        const response = await API.put(`${baseURI}${key}/`, values);
        if (response?.status === 200) {
          if (!_continue) {
            onSuccess(response, 'Actualizado correctamente');
            onCancel();
            await fetchData();
          } else {
            await fetchData();
            setSelectedRowKeys([response.data.id]);
          }
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`/configuraciones/cuentas-bancarias/${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          setVisibleAlert(false);
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    setMutable(isMutable(record));
    getURForTreeSelect(record);
    setTimeout(() => {
      form.setFieldsValue({
        ...record,
        cuenta_contable: record.cuenta_contable?.id,
      });
    });
    setCuentaContableFound([record.cuenta_contable]);
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickAdd = () => {
    onCancel();
    if (hasAllConfigurations) {
      onCancel();
      form.setFieldsValue();
      setVisible(true);
    } else {
      showAlertConfigs();
    }
  };

  const onClickEdit = () => setVisible(true);

  const onClickDelete = () => setVisibleAlert(true);

  const showMsg = makeMessager(setCurrentTabKey, fetchData);

  const columns = [
    {
      titleText: 'Banco',
      dataIndex: 'banco',
      key: 'banco',
      width: 250,
      render: (val) => bancos.find((item) => item.id === val).nombre,
    },
    {
      titleText: 'Número de cuenta',
      dataIndex: 'numero_de_cuenta',
      key: 'numero_de_cuenta',
      width: 250,
    },
    {
      titleText: 'CLABE Interbancaria',
      dataIndex: 'clabe_interbancaria',
      key: 'clabe_interbancaria',
      width: 250,
    },
    {
      titleText: 'Cuenta contable',
      key: 'cuenta_contable',
      dataIndex: 'cuenta_contable',
      render: (cuenta_contable) => cuenta_contable?.nombre,
      width: 300,
    },
    {
      titleText: 'Finalidad',
      dataIndex: 'finalidad',
      key: 'finalidad',
      width: 250,
      render: (val) => finalidades.find((e) => e.id === val)?.descripcion,
    },
    {
      titleText: 'Estado',
      key: 'estados_globales',
      dataIndex: 'estados_globales',
      render: (val) => estadosGlobales.find((e) => e.id === val)?.descripcion,
      width: 150,
    },
  ];

  const required = {
    required: true,
    message: 'El campo es requerido',
  };

  const rules = {
    required: [
      required,
    ],
    clave_contable__clave: [
      required,
      {
        validator: async (rule, value) => {
          if (value && pattern && (!pattern.test(value))) {
            throw new Error('Introduzca una clave válida');
          }
        },
      },
    ],
    numero_de_cuenta: [
      required,
      {
        validator: async (rule, val) => {
          const value = val?.toString() || '';
          if (value) {
            if (value.length < 10) {
              throw new Error('El campo debe tener una longitud mínima de 10 dígitos');
            }
          }
        },
      },
    ],
    clabe_interbancaria: [
      required,
      {
        validator: async (rule, val) => {
          const value = val?.toString() || '';
          if (value) {
            if (value.length < 18) {
              throw new Error('El campo debe tener una longitud mínima de 18 dígitos');
            }
          }
        },
      },
    ],
    q_ur: [
      {
        validator: async (rule, val) => {
          const { content_type } = form.getFieldsValue();
          if (content_type && !val) {
            throw new Error('El campo es requerido');
          }
        },
      },
    ],
    content_type: [
      {
        validator: async (rule, val) => {
          const { q_ur } = form.getFieldsValue();
          if (q_ur && !val) {
            throw new Error('El campo es requerido');
          }
        },
      },
    ],
  };

  const onChangeTabKey = (key) => {
    const fromBasic = basicKeys.includes(currentTabKey);
    const toBasic = basicKeys.includes(key);
    const match = complexForms.find((e) => e.key === currentTabKey);
    if (!match) {
      if (fromBasic && toBasic) {
        setCurrentTabKey(key);
      } else if (fromBasic) {
        showMsg(key);
      } else {
        setCurrentTabKey(key);
      }
    } else {
      match.formInstance.onFinishHandler(key, true);
    }
  };

  const onFinishBusqueda = () => {
    if (selectedClave) {
      setCuentaContableFound([selectedClave]);
      setTimeout(() => form.setFieldsValue({
        cuenta_contable: selectedClave.id,
      }));
      setVisibleBusquedaCuentas(false);
    } else {
      message.info('No ha seleccionado una Cuenta Contable');
    }
  };

  const fetchCuentaContable = async () => {
    try {
      form.validateFields(['clave_contable__clave']);
      const clave = form.getFieldValue('clave_contable__clave');
      if (clave) {
        setLoading(true);
        const res = await API.get('contabilidad/cuentas-contables/', {
          params: {
            clave,
            tipo: 7,
          },
        });
        if (res?.status === 200 && res.data?.length) {
          const claveContable = res.data[0];
          if (claveContable.tipo === 7) {
            setCuentaContableFound([claveContable]);
            setTimeout(() => {
              form.setFieldsValue({
                cuenta_contable: claveContable.id,
              });
            });
            setTimeout(() => {
              form.validateFields(['cuenta_contable']);
            });
          }
        } else {
          message.info('No se encontraron coincidencias o la cuenta no es afectable');
          setCuentaContableFound([]);
        }
        setLoading(false);
      }
    } catch (err) {
      onError(err, setLoading);
      setCuentaContableFound([]);
    }
  };

  const resetAndValidateCuentaContable = () => {
    setCuentaContableFound([]);
    form.resetFields(['cuenta_contable']);
    form.validateFields(['cuenta_contable']);
  };

  const handleOnFinish = (vals, _continue = false) => {
    const match = complexForms.find((e) => e.key === currentTabKey);
    if (match) {
      match.formInstance.onFinishHandler(null, _continue);
    } else {
      onFinish(null, _continue);
    }
  };

  const onChangeClaveContable = (e) => {
    const { target: { value }, keyCode } = e;
    let val = value;
    if (keyCode) {
      const char = String.fromCharCode(keyCode).toLowerCase();
      val += char;
    }
    if (mascara.charAt(val.length) === separador) {
      val += separador;
    }
    if (val.length === mascara?.length) {
      setTimeout(fetchCuentaContable);
    } else if (!val?.length) {
      resetAndValidateCuentaContable();
    }
    setTimeout(() => {
      form.setFieldsValue({ clave_contable__clave: val });
    });
  };

  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {visible ? (
          <Card
            className="form-container no-padding"
            title={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Cuenta Bancaria`}
            extra={(
              <FormSubmitControls
                onFinish={basicKeys
                  .concat(complexForms.map((e) => e.key))
                  .includes(currentTabKey)
                  ? handleOnFinish : null}
                allowSaveAndContinue
                onCancel={onCancel}
                loading={loading}
              />
            )}
            bordered={false}
          >
            <Tabs
              onChange={!mutable ? setCurrentTabKey : onChangeTabKey}
              activeKey={currentTabKey}
              type="card"
            >
              <TabPane tab="Cuenta Bancaria" key="general">
                <Form
                  layout="vertical"
                  form={form}
                  onFinish={onFinish}
                  scrollToFirstError
                  initialValues={{ estados_globales: 1 }}
                >
                  <Row gutter={10}>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="banco"
                        label="Banco"
                        rules={rules.required}
                        hasFeedback
                      >
                        <Select dataSource={bancos} labelProp="nombre" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        label="Método De Pago"
                        name="metodo_de_pago"
                        rules={rules.required}
                      >
                        <Select dataSource={metodosPago} labelProp="concepto" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="numero_de_cuenta"
                        label="Número de cuenta"
                        rules={rules.numero_de_cuenta}
                        hasFeedback
                        normalize={(val) => customIntegerNormalizer(val, true)}
                      >
                        <Input maxLength={12} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="clabe_interbancaria"
                        label="CLABE"
                        rules={rules.clabe}
                        hasFeedback
                        normalize={(e) => e.toUpperCase()}
                      >
                        <Input maxLength={21} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="tipo_de_firma"
                        label="Tipo de firma"
                        rules={rules.required}
                        hasFeedback
                      >
                        <Select dataSource={tiposDeFirmas} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="finalidad"
                        label="Finalidad"
                        rules={rules.required}
                        hasFeedback
                      >
                        <Select dataSource={finalidades} />
                      </Form.Item>
                    </Col>

                    <InputSearchPro
                      name="clave_contable__clave"
                      onClickAdvanced={() => setVisibleBusquedaCuentas(true)}
                      rules={rules.clave_contable__clave}
                      label="Cuenta Contable"
                      tooltip={`Debe concordar con la mascara ${mascara}`}
                      inputProps={{
                        onPressEnter: () => fetchCuentaContable(),
                        onChange: onChangeClaveContable,
                        maxLength: mascara?.length,
                      }}
                      breakPoints={{
                        md: 24,
                        lg: 12,
                        xl: 12,
                      }}
                      resultProps={{
                        breakPoints: {
                          md: 16,
                        },
                        name: 'cuenta_contable',
                        label: 'Cuenta Contable',
                        rules: rules.required,
                        dataSource: cuentaContableFound,
                        keyLabelRender: true,
                        keyProp: 'clave',
                        labelProp: 'nombre',
                        onClear: resetAndValidateCuentaContable,
                      }}
                    />
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        label="Área"
                        name="area_responsable"
                      >
                        <TreeSelect
                          showSearch
                          treeNodeFilterProp="title"
                          style={{ width: '100%' }}
                          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                          treeData={treeData}
                          allowClear
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="estados_globales"
                        label="Estado"
                        rules={rules.required}
                        hasFeedback
                      >
                        <Select
                          disabled={!selectedRowKeys.length}
                          dataSource={estadosGlobales}
                        />
                      </Form.Item>
                    </Col>
                    {/* <InputSearchPro
                      label="Area Responsable"
                      tooltip="Búsqueda por clave o descripción"
                      name="q_ur"
                      rules={rules.q_ur}
                      onPressEnter={() => busquedaUR()}
                      prefix={{
                        style: {
                          marginTop: 'auto',
                          width: '45%',
                          minWidth: 180,
                        },
                        rules: rules.content_type,
                        name: 'content_type',
                        label: 'Nivel',
                        render: (e) => e.model,
                        dataSource: urs,
                        onChange: resetAndValidateURs,
                      }}
                      breakPoints={{
                        md: 24,
                        lg: 12,
                        xl: 12,
                      }}
                      inputProps={{
                        onChange: ({ target: { value } }) => {
                          if (!value) {
                            resetAndValidateURs();
                          }
                        },
                      }}
                      resultProps={{
                        name: 'object_id',
                        label: 'Area Responsable',
                        dataSource: ursFound,
                        keyLabelRender: true,
                        onClear: resetAndValidateURs,
                      }}
                    /> */}
                    <Col span={24}>
                      <Form.Item
                        name="observaciones"
                        label="Observaciones"
                        hasFeedback
                      >
                        <Input.TextArea autoSize={{ minRows: 2, maxRows: 3 }} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item hidden>
                    <Button htmlType="submit" />
                  </Form.Item>
                </Form>
              </TabPane>
              <TabPane tab="Inversiones" key="cuentas_de_inversion">
                <CuentasDeInvercionOTarjetasDePago
                  mascara={mascara}
                  pattern={pattern}
                  separador={separador}
                  selectedRowKey={selectedRowKeys[0]}
                  finalidades={finalidades}
                  cuentasDeInversion
                />
              </TabPane>
              <TabPane tab="Fondos" key="fondos">
                <FondosDeCuentasBancarias
                  mascara={mascara}
                  pattern={pattern}
                  separador={separador}
                  selectedRowKey={selectedRowKeys[0]}
                  tipo={tipo}
                />
              </TabPane>
              <TabPane tab="Tarjetas de pago" key="tarjetas_de_pago">
                <CuentasDeInvercionOTarjetasDePago
                  mascara={mascara}
                  pattern={pattern}
                  separador={separador}
                  selectedRowKey={selectedRowKeys[0]}
                  finalidades={finalidades}
                />
              </TabPane>
              <TabPane tab="Datos Adicionales" key="datos_adicionales">
                <DatosAdicionales
                  setForm={setFormDatosAdicionales}
                  loading={loading}
                  setLoading={setLoading}
                  selectedRowKey={selectedRowKeys[0]}
                  setCurrentTabKey={setCurrentTabKey}
                />
              </TabPane>
            </Tabs>
          </Card>
        ) : (
          <Table
            allowImport
            baseURI={baseURI}
            cols={columns}
            mobileCols={[columns[0], columns[1]]}
            data={data}
            dataSource={dataSource}
            rowSelection={rowSelection}
            permission={permission}
            setDataSource={setDataSource}
            handleOnRowClick={handleOnRowClick}
            controls={{
              onClickAdd,
              onClickEdit,
              onClickDelete,
            }}
          />
        )}
        <Modal
          visible={visibleBusquedaCuentas}
          title={(
            <Row justify="space-between">
              Búsqueda Avanzada Cuentas
              <FormSubmitControls
                onCancel={() => setVisibleBusquedaCuentas(false)}
                onFinish={onFinishBusqueda}
              />
            </Row>
          )}
          onCancel={() => setVisibleBusquedaCuentas(false)}
          closable={false}
          footer={null}
          width={800}
          forceRender
          centered
        >
          <BusquedaClavesContables callback={(cuentaContable) => {
            setSelectedClave(cuentaContable);
          }}
          />
        </Modal>
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => setVisibleAlert(false)}
          visible={visibleAlert}
          content={`Cuenta Bancaria ${form.getFieldValue('numero_de_cuenta')}`}
          loading={loading}
        />
      </Spin>
    </Row>
  );
}

export default CuentasBancarias;
