import React from 'react';
import SingleForm from 'components/SingleForm';

export const permissionTipoDeConvenio = {
  permissionModel: 'tipodeconvenio',
};

// eslint-disable-next-line react/prop-types
function TipoDeConvenios({ permission }) {
  return (
    <SingleForm
      permission={permission}
      baseURI="obrapublica/tipo-de-convenio/"
      title="Tipo De Convenios"
    />
  );
}

export default TipoDeConvenios;
