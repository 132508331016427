import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Form,
  Input,
  // Button,
  Row,
  Col,
  Spin,
  Card,
} from 'antd';

// import getFormattedValues from 'utils/formatValues';
import API from 'utils/api';
// import { RFC_PATTERN } from 'utils/patterns';
import { onError, onSuccess } from 'utils/handlers';
import Table from 'components/Table';
import FormSubmitControls from 'components/FormSubmitControls';
import ModalDelete from 'components/ModalDelete';
// import ModalDireccion from 'components/ModalDireccion';
import Select from 'components/Select';

export const permissionBanco = {
  permissionModel: 'banco',
};

const baseURI = 'configuraciones/bancos/';

// eslint-disable-next-line react/prop-types
function Bancos({ permission }) {
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await API.get(baseURI);
      setData(res.data);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchData();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    setSelectedRowKeys([]);
  };

  // const onFinish = async () => {
  //   try {
  //     setLoading(true);
  //     await form.validateFields();
  //     const values = getFormattedValues(form.getFieldsValue());
  //     if (!selectedRowKeys.length) {
  //       const response = await API.post(baseURI, values);
  //       if (response?.status === 201) {
  //         if (response.data) {
  //           onSuccess(response, 'Agregado correctamente');
  //           onCancel();
  //           await fetchData();
  //         }
  //       }
  //     } else {
  //       const [key] = selectedRowKeys;
  //       const response = await API.put(`${baseURI}${key}/`, values);
  //       if (response?.status === 200) {
  //         if (response.data) {
  //           onSuccess(response, 'Actualizado correctamente');
  //           onCancel();
  //           await fetchData();
  //         }
  //       }
  //     }
  //     setLoading(false);
  //   } catch (err) {
  //     onError(err, setLoading);
  //   }
  // };

  const deleteItem = async () => {
    try {
      setLoading(true);
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`${baseURI}${key}/`);
        if (response && response.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          setVisibleAlert(false);
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    setTimeout(() => form.setFieldsValue(record));
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  // const onClickAdd = () => {
  //   onCancel();
  //   setVisible(true);
  // };

  // const onClickEdit = () => {
  //   setVisible(true);
  // };

  // const onClickDelete = () => {
  //   setVisibleAlert(true);
  // };

  const columns = [
    {
      titleText: 'Nombre',
      key: 'nombre',
      dataIndex: 'nombre',
      width: 300,
    },
    {
      titleText: 'RFC',
      key: 'RFC',
      dataIndex: 'RFC',
      width: 200,
    },
    {
      titleText: 'Razón Social',
      key: 'razon_social',
      dataIndex: 'razon_social',
      width: 300,
    },
    {
      titleText: 'Estado',
      key: 'estado',
      dataIndex: 'estado',
      width: 250,
      render: (val) => (val ? 'Activo' : 'Inactivo'),
    },
  ];

  // const rules = {
  //   required: [
  //     {
  //       required: true,
  //       message: 'El campo es requerido',
  //     },
  //   ],
  //   RFC: [
  //     {
  //       required: true,
  //       message: 'El campo es requerido',
  //     },
  //     {
  //       pattern: RFC_PATTERN,
  //       message: 'Ingrese un RFC válido',
  //     },
  //   ],
  // };

  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible
          ? (
            <Table
              cols={columns}
              data={data}
              rowSelection={rowSelection}
              handleOnRowClick={handleOnRowClick}
              permission={permission}
              controls={{
                onClickExpand: () => setVisible(true),
              }}
              allowExpand
              mobileColIndex={0}
            />
          ) : (
            <Card
              className="form-container"
              title="Vista Previa"
              extra={(
                <FormSubmitControls onCancel={onCancel} loading={loading} />
              )}
              bordered={false}
            >
              <Form
                layout="vertical"
                form={form}
                scrollToFirstError
              >
                <Row gutter={10}>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      label="Nombre"
                      name="nombre"
                      hasFeedback
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="RFC"
                      label="RFC"
                      hasFeedback
                      normalize={(val) => val?.toUpperCase()}
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="razon_social"
                      label="Razón Social"
                      hasFeedback
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="estados_globales"
                      label="Estado"
                      hasFeedback
                    >
                      <Select dataSource={estadosGlobales} disabled />
                    </Form.Item>
                  </Col>
                  {/* <Form.Item hidden>
                    <Button htmlType="submit" />
                  </Form.Item> */}
                </Row>
              </Form>
              {/* <ModalDireccion
                currentURI={baseURI}
                parent={data.find((item) => item.id === selectedRowKeys[0])}
                form={form}
              /> */}
            </Card>
          )}
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => setVisibleAlert(false)}
          visible={visibleAlert}
          content={`Banco ${form.getFieldValue('nombre')}`}
          loading={loading}
        />
      </Spin>
    </Row>
  );
}

export default Bancos;
