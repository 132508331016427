import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  Row,
  Col,
  Spin,
  Form,
  Input,
  Button,
  TreeSelect,
  Modal,
} from 'antd';
import FormSubmitControls from 'components/FormSubmitControls';
import API from 'utils/api';
import ModalDelete from 'components/ModalDelete';
import Table from 'components/Table';
import { onError, onSuccess } from 'utils/handlers';
import { toInteger } from 'utils/normalizers';
import { integerRegExp } from 'utils/patterns';
import { findItemNested } from 'utils/filters';

export const permissionClase = {
  permissionModel: 'clase',
};

const baseURI = 'configuraciones/clases/';

// eslint-disable-next-line react/prop-types
function Clases({ permission }) {
  const timeoutFilter = useRef();
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [dataTree, setDataTree] = useState([]);
  const [selected, setSelected] = useState();
  const entidad = useSelector(({ auth }) => auth.entidad);
  const [flatClases, setFlatClases] = useState([]);

  const onClickTitle = (e) => {
    const element = e.target;
    if (element) {
      let parent = element.parentElement;
      parent = parent ? parent.parentElement : null;
      if (parent) {
        const clickableSpan = parent.previousSibling;
        if (clickableSpan) {
          clickableSpan.click();
        }
      }
    }
  };

  const renderTitle = (title, bold) => (
    // eslint-disable-next-line
    <span onClick={onClickTitle} className={bold ? 'bold' : ''}>
      {title}
    </span>
  );

  const normalizeData = (
    _data = [],
    isSelectable = (_item = {}) => (!_item.children?.length),
    excludeLvl3 = false,
    currLvl = 1,
  ) => _data.map((item) => {
    const clone = { ...item };
    const children = normalizeData(!excludeLvl3
      || currLvl !== 2 ? clone.children : [], isSelectable, excludeLvl3, currLvl + 1);
    if (!children.length) {
      delete clone.children;
      return ({
        ...clone,
        key: `${clone.clave}`,
        title: renderTitle(`${clone.clave} - ${clone.denominacion}`),
        value: clone.clave,
        selectable: isSelectable(clone),
      });
    }
    return ({
      ...clone,
      key: `${clone.clave}`,
      children,
      title: renderTitle(`${clone.clave} - ${clone.denominacion}`),
      value: clone.clave,
      selectable: isSelectable(clone),
    });
  });

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await API.get('contabilidad/cri/');
      setData(normalizeData(res.data));
      setDataTree(normalizeData(res.data, (_item) => (_item.nivel === 2), true));
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const fetchAll = async () => {
    try {
      setLoading(true);
      const resClases = await API.get('configuraciones/clases/');
      setFlatClases(resClases.data);

      await fetchData();
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchAll();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setVisible(false);
    setSelectedRowKeys([]);
    form.resetFields();
    setSelected({ selectable: true });
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      const match = findItemNested(dataTree, values?.tipo, 'clave');
      values.tipo = match?.id;
      values.pais = entidad.catalogo_de_pais;
      if (!values?.id) {
        const response = await API.post(baseURI, values);
        if (response?.status === 201) {
          onSuccess(response, 'Agregado correctamente', 2.5);
          onCancel();
          await fetchData();
        }
      } else {
        await form.validateFields();
        const response = await API.put(`${baseURI}${values.id}/`, values);
        if (response?.status === 200) {
          onSuccess(response, 'Actualizado correctamente');
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      const values = form.getFieldsValue();
      if (values?.id) {
        const response = await API.delete(`${baseURI}${values.id}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          setVisibleAlert(false);
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    const match = flatClases.find((e) => e?.clave === record?.clave);
    const tipoMatch = findItemNested(dataTree, match?.tipo);
    setSelectedRowKeys([record.key]);
    setSelected({ ...record });
    form.setFieldsValue({
      ...record,
      tipo: tipoMatch?.value,
    });
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const onClickEdit = () => {
    setVisible(true);
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const columns = [
    {
      titleText: 'Clave',
      dataIndex: 'clave',
      key: 'clave',
      width: 90,
    },
    {
      titleText: 'Denominación',
      dataIndex: 'denominacion',
      key: 'denominacion',
      width: 200,
    },
    {
      titleText: 'Nivel',
      dataIndex: 'nivel',
      key: 'nivel',
      width: 100,
    },
  ];

  const requiredRule = {
    required: true,
    message: 'El campo es requerido',
  };

  const rules = {
    required: [requiredRule],
    clave: [
      requiredRule,
      {
        validator: async (rule, value) => {
          const parent = dataTree.find((e) => e.id === form.getFieldValue('tipo'));
          if (value) {
            if (!integerRegExp.test(value)) {
              throw new Error('Ingrese un número entero válido');
            } else if (value.indexOf(parent?.clave) !== 0) {
              throw new Error('El campo debe incluir al inicio la clave de Tipo');
            } else if (value.length === parent?.clave?.length) {
              throw new Error('La longitud de la clave debe ser mayor a la clave del Tipo');
            }
          }
        },
      },
    ],
  };

  const onClickExpand = () => setVisible(true);

  const getControls = () => {
    if (selected?.selectable) {
      return {
        onClickAdd,
        onClickEdit,
        onClickDelete,
      };
    }
    return {
      onClickAdd,
      onClickExpand,
    };
  };

  const onSelectTipo = async (i) => {
    try {
      // eslint-disable-next-line no-param-reassign
      // const clone = [...data];
      const match = findItemNested(dataTree, i, 'clave');
      let newClave;
      let beginWithZero = false;
      if (match.children?.length) {
        const { clave } = match.children[match.children.length - 1];
        beginWithZero = clave[0] === '0';
        newClave = parseInt(clave, 10) + 1;
      } else {
        newClave = `${match.clave}1`;
      }
      if (beginWithZero) {
        newClave = `0${newClave}`;
      }
      form.setFieldsValue({ clave: newClave });
    } catch (err) {
      onError(err);
    }
  };

  const filterTreeNode = (input, node) => {
    const title = node.title?.props.children.toLowerCase() || node.title;
    if (title && title.includes(input.toLowerCase())) {
      return true;
    }
    if (node.children) {
      return filterTreeNode(input, node.children);
    }
    return false;
  };

  const setTimeoutFilter = (input, node) => setTimeout(() => filterTreeNode(input, node), 1000);

  const filterTree = (input, node) => {
    clearTimeout(timeoutFilter.current);
    timeoutFilter.current = setTimeoutFilter(input, node);
    return timeoutFilter ? filterTreeNode(input, node) : true;
  };

  return (
    <Row align="center" justify="center" className="container">
      <Spin tip="Cargando..." spinning={loading}>
        <Table
          cols={columns}
          permission={permission}
          data={data}
          rowSelection={rowSelection}
          handleOnRowClick={handleOnRowClick}
          controls={getControls()}
          allowEdit={selected?.selectable}
          allowDelete={selected?.selectable}
          rowKey="key"
          childrenProp="children"
          filterNested
          allowExpand
          allowImport
          baseURI={baseURI}
          allowedExtensions={['xlsx', 'csv', 'json']}
        />
      </Spin>
      <Modal
        visible={visible}
        title={(
          <FormSubmitControls
            label={!selected?.selectable
              ? 'Vista Previa'
              : `${selectedRowKeys.length ? 'Editar' : 'Agregar'} Clase`}
            onFinish={selected?.selectable ? onFinish : null}
            onCancel={onCancel}
            loading={loading}
          />
          )}
        onCancel={onCancel}
        footer={null}
        closable={false}
        keyboard={!loading}
        maskClosable={!loading}
        forceRender
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          scrollToFirstError
          initialValues={{ estados_globales: 1 }}
        >
          <Row>
            {(selected?.selectable || !selectedRowKeys.length) && (
              <Col span={24}>
                <Form.Item
                  name="tipo"
                  label="Tipo"
                  rules={rules.required}
                >
                  <TreeSelect
                    onSelect={onSelectTipo}
                    showSearch
                    treeNodeFilterProp="title"
                    style={{ width: '100%' }}
                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                    treeData={dataTree}
                    allowClear
                    filterTreeNode={filterTree}
                    disabled={!selected?.selectable || selectedRowKeys.length}
                    showArrow={!selectedRowKeys.length}
                  />
                </Form.Item>
              </Col>
            )}
            <Col span={24}>
              <Form.Item
                name="clave"
                label="Clave"
                rules={rules.required}
                normalize={toInteger}
              >
                <Input disabled={!!selectedRowKeys.length} allowClear />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="denominacion"
                label="Denominación"
                rules={rules.required}
                hasFeedback
              >
                <Input
                  disabled={!selected?.selectable}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Form.Item name="id" hidden>
              <Input disabled />
            </Form.Item>
            <Form.Item hidden>
              <Button htmlType="submit" />
            </Form.Item>
          </Row>
        </Form>
      </Modal>
      <ModalDelete
        onDelete={deleteItem}
        onCancel={() => {
          setVisibleAlert(false);
          onCancel();
        }}
        visible={visibleAlert}
        content={`Clase ${form.getFieldValue('denominacion')}`}
        loading={loading}
      />
    </Row>
  );
}

export default Clases;
