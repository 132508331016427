import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Form,
  Button,
  Row,
  Col,
  Spin,
  Card,
  Input,
  message,
} from 'antd';
import API from 'utils/api';
import FormSubmitControls from 'components/FormSubmitControls';
import Table from 'components/Table';
import ModalDelete from 'components/ModalDelete';
import Select from 'components/Select';
import { onError, onSuccess } from 'utils/handlers';
import { isMutable } from 'utils/estadosGlobales';
import Segmentos from './Segmentos';
import ModalConfig from './ModalConfig';

export const permissionConfigSegmentoContable = {
  permissionModel: 'configuraciondesegmentocontable',
};

const baseURI = 'configuraciones/configuraciones-de-segmentos-contables-y-segmentos/';

// eslint-disable-next-line react/prop-types
function SegmentosContable({ permission }) {
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [cuentasPadres, setCuentasPadres] = useState([]);
  const [segmentos, setSegmentos] = useState([]);
  const [data, setData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [visible, setVisible] = useState(false);
  const [visibleConfig, setVisibleConfig] = useState(false);
  const [savedConfig, setSavedConfig] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [mutable, setMutable] = useState(false);
  const [separador, setSeparador] = useState();
  const [mascara, setMascara] = useState();

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await API.get(baseURI);
      setData(response.data);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const capitalizeFirstLetter = (word) => word.charAt(0).toUpperCase() + word.slice(1);

  const fetchAll = async () => {
    try {
      setLoading(true);
      const resCuentas = await API.get('catalogos/cuentas-padre-conac/');
      const formattedCuentas = resCuentas.data.map((e) => ({
        ...e,
        nombre: capitalizeFirstLetter(e.nombre.toLowerCase()),
      }));
      setCuentasPadres(formattedCuentas);

      await fetchData();
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const fetchBase = async () => {
    try {
      setLoading(true);
      const res = await API.get('configuraciones/configuraciones-de-mascaras/');
      if (res?.status === 200) {
        const config = res.data?.find((c) => c.tipo === 1);
        if (config) {
          const { mascara: mask, separador: separator } = config;
          setSeparador(separator);
          setMascara(mask);
          setSavedConfig(true);
          await fetchAll();
        } else {
          setVisibleConfig(true);
        }
      } else {
        setVisibleConfig(true);
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchBase();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (savedConfig) {
      fetchBase();
    }
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, [savedConfig]);

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    setSelectedRowKeys([]);
    setMutable(true);
    setTimeout(() => {
      form.setFieldsValue({ mascara });
    });
  };

  const validateProgramaYProyecto = () => {
    if (segmentos.some((e) => [2, 3].includes(e.tipo))
    && !(segmentos.some((e) => e.tipo === 2)
    && segmentos.some((e) => e.tipo === 3))) {
      throw new Error('error_segmentos - No se puede usar un segmento de tipo Proyecto sin un segmento de tipo Programa y viceversa.');
    }
  };

  const postSegmentos = async (id) => {
    const { catalogo_de_cuenta_padre, estados_globales } = form.getFieldsValue();
    validateProgramaYProyecto();
    const promises = segmentos.filter((e) => e.posicion > 1)
      .map((segmento) => API.post(`configuraciones/configuraciones-de-segmentos-contables/${id}/segmentos`, {
        ...segmento,
        separador,
        catalogo_de_cuenta_padre,
        estados_globales,
      }));
    const responses = promises.length ? await Promise.all(promises) : [];
    return responses.map((res) => (res && res.data ? res.data.id : null)).filter((i) => !!i);
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      const [key] = selectedRowKeys;
      const clone = [...data];
      const match = clone.find((e) => e.cuenta === values.cuenta && e.estados_globales === 4);
      validateProgramaYProyecto();

      if (!match) {
        if (!selectedRowKeys.length) {
          const response = await API.post(baseURI, values);
          if (response?.status === 201) {
            await postSegmentos(response.data.id);
            onSuccess(response, 'Información guardada correctamente');
            onCancel();
            await fetchData();
          }
        } else {
          const response = await API.put(`${baseURI}${key}/`, values);
          if (response?.status === 200) {
            if (!response?.data?.segmentos_contables?.length) {
              await postSegmentos(response.data.id);
            }
            onSuccess(response, 'Información actualizada correctamente');
            onCancel();
            await fetchData();
          }
        }
      } else {
        message.warn('Ya existe una configuración para esta cuenta');
      }
      setLoading(false);
    } catch (err) {
      if (err.message?.includes('error_segmentos') && !err?.errorFields?.length) {
        message.warn(err.message.split(' - ')[1]);
        setLoading(false);
      } else {
        onError(err, setLoading);
      }
    }
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`${baseURI}${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          setVisibleAlert(false);
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    setMutable(isMutable(record));
    setTimeout(() => form.setFieldsValue({ ...record, mascara }));
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickAdd = () => {
    onCancel();
    if (savedConfig) {
      setVisible(true);
    } else {
      setVisibleConfig(true);
    }
  };

  const onClickEdit = (record = null) => {
    const [key] = selectedRowKeys;
    const match = record || data.find((e) => e.id === key);
    setMutable(isMutable(match));
    setVisible(true);
    setTimeout(() => {
      form.setFieldsValue({ ...match, mascara });
    });
  };

  const onClickDelete = () => {
    if (mutable) {
      setVisibleAlert(true);
    } else {
      message.info('No se puede eliminar una Configuración de Segmentos Contables autorizada');
      onCancel();
    }
  };

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
  };

  const columns = [
    {
      titleText: 'Nombre',
      dataIndex: 'nombre',
      key: 'nombre',
      width: 200,
    },
    {
      titleText: 'Cuenta padre',
      dataIndex: 'cuenta',
      key: 'cuenta',
      width: 200,
      render: (val) => cuentasPadres.find((c) => c.id === val)?.nombre,
    },
    {
      titleText: 'Estado',
      dataIndex: 'estados_globales',
      key: 'estados_globales',
      width: 200,
      render: (val) => estadosGlobales.find((t) => t.id === val)?.descripcion,
    },
  ];

  const sortCuentasPadres = () => {
    const sorted = cuentasPadres.map((cp) => ({
      ...cp,
      disabled: !!data
        .find((i) => i.cuenta === cp.id && i.estados_globales === 4),
    }));
    setCuentasPadres(sorted.sort((a, b) => a.disabled.toString().localeCompare(b.disabled)));
  };

  useEffect(() => {
    sortCuentasPadres();
    // eslint-disable-next-line
  }, [data]);

  const getName = () => {
    const id = form.getFieldValue('cuenta');
    const cuentaPadre = cuentasPadres.find((c) => c.id === id);
    return cuentaPadre?.descripcion;
  };

  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible ? (
          <Table
            cols={columns}
            permission={permission}
            data={data}
            rowSelection={rowSelection}
            handleOnRowClick={handleOnRowClick}
            controls={{
              onClickAdd,
              onClickEdit,
              onClickDelete,
            }}
            mobileColIndex={0}
            baseURI={baseURI}
            justImport
          />
        ) : (
          <Card
            className="form-container"
            title="Configurar Segmentos Contables"
            extra={(
              <FormSubmitControls
                onFinish={onFinish}
                onCancel={onCancel}
                baseURI={baseURI}
                mutable={mutable}
                selectedRowKeys={selectedRowKeys}
                callback={(estados_globales) => {
                  const [key] = selectedRowKeys;
                  const normalized = data.map((e) => {
                    if (e.id === key) {
                      setMutable(false);
                      return {
                        ...e,
                        estados_globales,
                      };
                    }
                    return e;
                  });
                  setData(normalized);
                  const match = normalized.find((e) => e.id === key);
                  onClickEdit(match);
                }}
                loading={loading}
                allowAuthorize
                allowCancel
              />
            )}
            bordered={false}
          >
            <Form
              layout="vertical"
              form={form}
              onFinish={onFinish}
              scrollToFirstError
              initialValues={{ estados_globales: 1 }}
            >
              <Row gutter={10}>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="cuenta"
                    label="Cuenta"
                    rules={rules.required}
                  >
                    <Select
                      disabled={!!selectedRowKeys.length}
                      dataSource={cuentasPadres}
                      labelProp="nombre"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="mascara"
                    label="Mascara"
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="estados_globales"
                    rules={rules.required}
                    label="Estado"
                    hasFeedback
                  >
                    <Select dataSource={estadosGlobales} disabled />
                  </Form.Item>
                </Col>
                <Form.Item hidden>
                  <Button htmlType="submit" />
                </Form.Item>
                <Segmentos
                  mascara={mascara}
                  isMutable={mutable}
                  separador={separador}
                  setSegmentos={setSegmentos}
                  idConfiguracion={selectedRowKeys[0]}
                />
              </Row>
            </Form>
          </Card>
        )}
        <ModalConfig
          visible={visibleConfig}
          setVisible={setVisibleConfig}
          setSavedConfig={setSavedConfig}
        />
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => setVisibleAlert(false)}
          visible={visibleAlert}
          content={`configuración de segmentos contables ${getName()}`}
          loading={loading}
        />
      </Spin>
    </Row>
  );
}

export default SegmentosContable;
