import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Input,
  Button,
  Spin,
  Row,
  Col,
  Card,
  Typography,
  DatePicker,
} from 'antd';
import getFormattedValues, { hasFiles, formatReceived } from 'utils/formatValues';
import API from 'utils/api';
import { onError, onSuccess } from 'utils/handlers';
import Select from 'components/Select';
import CurrencyInput from 'components/CurrencyInput';
import FormSubmitControls from '../FormSubmitControls';
import NumericInput from '../NumericInput';
import Table from '../Table';
import ModalDelete from '../ModalDelete';
import Uploader from '../Uploader';
import Accionistas from './Accionistas';

const baseURI = 'configuraciones/datos-corporativos/';

function DatosCorporativos({
  currentURI,
  selectedRowKey,
}) {
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);
  const [movimiento, setMovimiento] = useState([]);

  const fetchData = async () => {
    try {
      setLoading(true);
      if (selectedRowKey) {
        const resCatalogo = await API.get(`${currentURI}${selectedRowKey}/`);
        if (resCatalogo?.status === 200) {
          const { datos_corporativos } = resCatalogo.data;
          let list = [];
          if (datos_corporativos?.length) {
            if (typeof datos_corporativos[0] !== 'number') {
              list = datos_corporativos.map((d) => d.id);
            } else {
              list = datos_corporativos;
            }
          }
          const promises = [];
          list.forEach((id) => {
            const res = API.get(`${baseURI}${id}/`);
            promises.push(res);
          });
          const responses = promises.length ? await Promise.all(promises) : [];
          const datos = responses.map((res) => formatReceived(res.data));
          setData(datos);
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const fetchAll = async () => {
    try {
      setLoading(true);
      const resMovimiento = await API.get('/adquisiciones/catalogo-de-movimientos-corporativos/');
      setMovimiento(resMovimiento.data || []);
      await fetchData();
      setLoading(false);
    } catch (error) {
      onError(error, setLoading);
    }
  };
  useEffect(() => {
    fetchAll();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, [selectedRowKey]);

  const onCancel = () => {
    form.resetFields();
    setVisible(false);
    setSelectedRowKeys([]);
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      const files = ['acta_constitutiva'];
      const config = hasFiles(values, files) ? {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      } : {};
      const formattedValues = getFormattedValues(values, {
        files,
        formData: config.headers,
        clean: false,
      });
      if (selectedRowKey) {
        const [key] = selectedRowKeys;
        if (!selectedRowKeys.length) {
          const response = await API.post(baseURI, formattedValues, config);
          if (response?.status === 201) {
            const keys = data.map((item) => item.id);
            const resPatch = await API.patch(`${currentURI}${selectedRowKey}/`, {
              datos_corporativos: [...keys, response.data.id],
            });
            if (resPatch?.status === 200) {
              onCancel();
              await fetchData();
            }
          }
        } else {
          const response = await API.put(`${baseURI}${key}/`, formattedValues, config);
          if (response?.status === 200) {
            onCancel();
            await fetchData();
          }
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    form.setFieldsValue(record);
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const onClickEdit = () => {
    setVisible(true);
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      const [key] = selectedRowKeys;
      if (selectedRowKey) {
        const response = await API.delete(`${baseURI}${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          setVisibleAlert(false);
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
  };

  const columns = [
    {
      titleText: 'No. de Acta',
      dataIndex: 'numero_de_acta',
      key: 'numero_de_acta',
      width: 200,
    },
    {
      titleText: 'Fecha de Acta',
      dataIndex: 'fecha_de_acta',
      key: 'fecha_de_acta',
      width: 200,
      render: (val) => (val?.format('DD/MM/YYYY')),
    },
    {
      titleText: 'Concepto',
      dataIndex: 'concepto_de_acta',
      key: 'concepto_de_acta',
      width: 200,
    },
    {
      titleText: 'No. de Notaria',
      dataIndex: 'numero_de_notaria',
      key: 'numero_de_notaria',
      width: 200,
    },
    {
      titleText: 'Ciudad de Notaria',
      dataIndex: 'ciudad_de_notaria',
      key: 'ciudad_de_notaria',
      width: 200,
    },
    {
      titleText: 'Nombre de Notario',
      dataIndex: 'nombre_de_notario',
      key: 'nombre_de_notario',
      width: 200,
    },
    {
      titleText: 'Capital Social',
      dataIndex: 'capital_social',
      key: 'capital_social',
      width: 200,
    },
  ];

  return (
    <Spin tip="Cargando..." spinning={loading}>
      {!visible ? (
        <Table
          cols={columns}
          data={data}
          rowSelection={rowSelection}
          handleOnRowClick={handleOnRowClick}
          controls={{
            onClickAdd,
            onClickEdit,
            onClickDelete,
          }}
          allowSort={false}
          allowSearch={false}
        />
      ) : (
        <Card
          className="form-container auto-heigth"
          title={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Datos Corporativos`}
          extra={(
            <FormSubmitControls
              onFinish={onFinish}
              onCancel={onCancel}
              loading={loading}
            />
          )}
          bordered={false}
        >
          <Form
            layout="vertical"
            form={form}
            name="datos_corporativos"
            onFinish={onFinish}
            scrollToFirstError
          >
            <Row gutter={10}>
              <Col xs={24} sm={24} md={8}>
                <NumericInput
                  name="numero_de_acta"
                  label="No. de Acta"
                  required
                />
              </Col>
              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  name="fecha_de_acta"
                  label="Fecha de Acta"
                  hasFeedback
                  rules={rules.required}
                >
                  <DatePicker
                    placeholder=""
                    format="DD/MM/YYYY"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  name="concepto_de_acta"
                  label="Concepto"
                  hasFeedback
                  rules={rules.required}
                >
                  <Select
                    dataSource={movimiento}
                    labelProp="nombre"
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  name="numero_de_notaria"
                  label="No. de Notaria"
                  hasFeedback
                  rules={rules.required}
                >
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  name="ciudad_de_notaria"
                  label="Ciudad de Notaria"
                  hasFeedback
                  rules={rules.required}
                >
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  name="nombre_de_notario"
                  label="Nombre de Notario"
                  hasFeedback
                  rules={rules.required}
                >
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  name="capital_social"
                  label="Capital Social"
                >
                  <CurrencyInput
                    decimal
                    required
                  />
                </Form.Item>
              </Col>
              <Uploader
                title="Acta constitutiva"
                limitMB={50}
                file={form.getFieldValue('acta_constitutiva')}
                formItemName="acta_constitutiva"
                allowedExtensions={['pdf']}
              />
              <Form.Item hidden>
                <Button htmlType="submit" />
              </Form.Item>
            </Row>
          </Form>
          {!!selectedRowKeys.length && (
            <>
              <Typography.Title level={4}>Accionistas</Typography.Title>
              <Accionistas selectedRowKey={selectedRowKeys[0]} />
            </>
          )}
        </Card>
      )}
      <ModalDelete
        onDelete={deleteItem}
        onCancel={() => setVisibleAlert(false)}
        visible={visibleAlert}
        content={`Dato Corporativo ${form.getFieldValue('numero_de_acta')}`}
        loading={loading}
      />
    </Spin>
  );
}

DatosCorporativos.propTypes = {
  selectedRowKey: PropTypes.number.isRequired,
  currentURI: PropTypes.string.isRequired,
};

export default DatosCorporativos;
