import API from 'utils/api';
import { onSuccess, onError } from 'utils/handlers';

const baseURI = 'contabilidad/clasificaciones-objeto-gasto/';

/**
 * GET Clasificaciones por Objeto del Gasto
 * @returns {Promise<Array<Object>>}
 */
export const getCOGs = async () => {
  try {
    const response = await API.get(baseURI);
    return response.data;
  } catch (err) {
    onError(err);
    return [];
  }
};

/**
 * GET Clasificacion por Objeto del Gasto
 * @returns {Promise<Object>}
 */
export const getCOG = async (id) => {
  try {
    const response = await API.get(`${baseURI}${id}/`);
    return response.data;
  } catch (err) {
    onError(err);
    return null;
  }
};

/**
 * POST Clasificaciones por Objeto del Gasto
 * @param {object} values
 * @returns {Promise<object|boolean>}
 */
export const postCOG = async (values) => {
  try {
    const response = await API.post(baseURI, values);
    onSuccess(response, 'Agregado correctamente');
    return response.data;
  } catch (err) {
    onError(err);
    return false;
  }
};

/**
 * PATCH Clasificaciones por Objeto del Gasto
 * @param {number} id
 * @param {object} values
 * @returns {Promise<object|boolean>}
 */
export const patchCOG = async (id, values) => {
  try {
    const response = await API.patch(`${baseURI}${id}`, values);
    onSuccess(response, 'Editado correctamente');
    return response.data;
  } catch (err) {
    onError(err);
    return false;
  }
};

/**
 * DELETE Clasificaciones por Objeto del Gasto
 * @param {number} id
 * @returns {Promise<boolean>}
 */
export const deleteCOG = async (id) => {
  try {
    const response = await API.delete(`${baseURI}${id}`);
    onSuccess(response, 'Eliminado correctamente');
    return true;
  } catch (err) {
    onError(err);
    return false;
  }
};
