const rutaGridOtrosDeposito = '/tesoreria/otros-depositos/';
const rutaPolizas = '/configuraciones/polizas/';
const rutaConceptoOtrosDepositos = 'tesoreria/concepto-de-otros-depositos/';
const rutaLiquidacionInterna = '/tesoreria/liquidaciones-internas/';
const rutaEmpleados = '/estructura-organizacional/empleados/';
const rutaEstadosGlobales = '/catalogos/estados-globales/';
const rutaCargosBancarios = '/tesoreria/cargos-bancarios/';
const rutaCuentasBancarias = '/configuraciones/cuentas-bancarias/';
const rutaConceptoCargosBancarios = '/tesoreria/concetos-de-cargos-bancarios/';
const rutaClavesPresupuestales = '/configuraciones/presupuestos-anuales-de-egreso/';
const rutaClavePresupuestalCog = '/tesoreria/cog-presupuestal/';
const rutaTranferenciasInternas = '/tesoreria/transferencias-internas/';
const rutaCatalogoTiposInversion = '/tesoreria/tipos-de-inversiones/';
const rutaCatalogoPlazosInversion = '/tesoreria/plazos-de-inversiones/';
const rutaInversionesYRetiros = '/tesoreria/inversiones-y-retiros/';
const rutaInversiones = '/tesoreria/inversiones/';
const rutaRetiroInversiones = '/tesoreria/retiro-de-inversiones/';
const rutaRetirosCri = '/tesoreria/retiros-cri/';
const rutaConfiguracionFolios = 'configuraciones/configuraciones-de-folios/';
const rutaDocumentosOperativos = '/configuraciones/documentos-operativos/';
const rutaDocumentosPolizas = '/configuraciones/documentos-de-polizas/';
const rutaSolicitudCuentasInternas = '/tesoreria/catalogos-de-conceptos-de-solicitudes-de-cuentas-internas/';
const rutaTextoAdicional = '/tesoreria/textos-adicionales-de-cuentas-internas/';
const rutaImporteFijo = '/tesoreria/importes-fijos-de-cuentas-internas/';
const rutaMovimientosPoliza = '/configuraciones/movimientos-de-polizas/';
const rutaTipoSolicitud = '/tesoreria/tipos-de-solicitudes/';
const rutaTipoComprobacion = '/tesoreria/tipos-de-comprobaciones/';
const rutaRubroCuentaBeneficiarioContable = '/configuraciones/claves-de-terceros/';
const rutaMomentosPresupuestales = '/catalogos/momentos-presupuestales-de-egresos/';

const rutas = {
  gridOtrosDeposito: rutaGridOtrosDeposito,
  polizas: rutaPolizas,
  otrosDepositos: rutaConceptoOtrosDepositos,
  empleados: rutaEmpleados,
  liquidacionInterna: rutaLiquidacionInterna,
  estadosGlobales: rutaEstadosGlobales,
  cargosBancarios: rutaCargosBancarios,
  cuentasBancarias: rutaCuentasBancarias,
  conceptosBancarios: rutaConceptoCargosBancarios,
  clavesPresupuestales: rutaClavesPresupuestales,
  clavePresupuestalCog: rutaClavePresupuestalCog,
  tranferenciasInternas: rutaTranferenciasInternas,
  catalogoTiposInversion: rutaCatalogoTiposInversion,
  catalogoPlazosInversion: rutaCatalogoPlazosInversion,
  inversionesYRetiros: rutaInversionesYRetiros,
  inversionesBancarias: rutaInversiones,
  retiroInversiones: rutaRetiroInversiones,
  retirosCRI: rutaRetirosCri,
  configuracionFolios: rutaConfiguracionFolios,
  documentosOperativos: rutaDocumentosOperativos,
  documentosPolizas: rutaDocumentosPolizas,
  solicitudCuentasInternas: rutaSolicitudCuentasInternas,
  textoAdicional: rutaTextoAdicional,
  importeFijo: rutaImporteFijo,
  conceptoMovimientoPolizas: rutaMovimientosPoliza,
  tipoSolicitud: rutaTipoSolicitud,
  tipoComprobacion: rutaTipoComprobacion,
  cuentaBeneficiarioContable: rutaRubroCuentaBeneficiarioContable,
  momentosPresupuestales: rutaMomentosPresupuestales,
};

export default rutas;
