import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Row,
  Col,
  Spin,
  Form,
  Input,
  Button,
  Card,
  Tabs,
  DatePicker,
  TreeSelect,
} from 'antd';
import FormSubmitControls from 'components/FormSubmitControls';
import API from 'utils/api';
import Table from 'components/Table';
import Select from 'components/Select';
import makeMessager from 'utils/complexFormsMessages';
import { onError, onSuccess } from 'utils/handlers';
import ModalDelete from 'components/ModalDelete';
import NumericInput from 'components/NumericInput';
import Direccion from 'components/Direccion';
import { isMutable } from 'utils/estadosGlobales';
import getFormatedValues, { formatReceived } from 'utils/formatValues';
import makeThree from 'utils/makeTree';
import ImportesAnuales from 'components/Catalogos/ImportesAnuales';

export const permissionProgramaGeneralDeObra = {
  permissionModel: 'programageneraldeobra',
};

const baseURI = '/obrapublica/programas-generales-de-obras/';

const baseURIs = [
  baseURI,
  '/obrapublica/tipo-de-obra/',
  '/obrapublica/situacion-de-obra/',
  '/obrapublica/modalidad-de-ejecucion/',
  '/adquisiciones/procesos-adquisitivos/',
  '/contabilidad/clasificaciones-de-fuentes-de-financiamiento/',
  // '',
];
const { TabPane } = Tabs;

// eslint-disable-next-line react/prop-types
function ProgramaGeneralDeObra({ permission }) {
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  const [form] = Form.useForm();
  const [formImportes] = Form.useForm();
  const [formContratista] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [tipoObra, setTipoObra] = useState([]);
  const [situacionObra, setSituacionObra] = useState([]);
  const [modalidadEjecucion, setModalidadEjecucion] = useState([]);
  const [formaAdjudicacion, setFormaAdjudicacion] = useState([]);
  const [fuentesFinanciamiento, setFuentesFinanciamiento] = useState([]);
  const [listadoContratos, setListadoContratos] = useState([]);
  const [formAddress, setFormAddress] = useState(null);
  const formInstances = [form, formImportes, formContratista, formAddress];

  const basicKeys = [
    'generalTab',
    'importeTab',
    'contratistaTab',
  ];

  const complexForms = [{ key: 'direccion', formInstance: formAddress }];

  const [mutable, setMutable] = useState(true);
  const [currentTabKey, setCurrentTabKey] = useState('generalTab');

  const normalize = (_data = []) => _data.map((item) => {
    const clone = { ...item };
    const children = clone.children?.length && !clone.seleccionable
      ? normalize(clone.children) : [];
    if (!children.length) {
      delete clone.children;
      return ({
        ...clone,
        key: clone.clave,
        value: clone.clave,
        clave: clone.clave,
        seleccionable: !clone.seleccionable,
        disabled: !clone.seleccionable,
        title: `${clone.clave} - ${clone.nombre}`,
      });
    }
    return ({
      ...clone,
      key: `${clone.clave}_padre`,
      value: `${clone.clave}_padre`,
      children,
      disabled: !clone.seleccionable,
      seleccionable: clone.seleccionable,
      title: `${clone.clave} - ${clone.nombre}`,
    });
  });

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await API.get(baseURI);
      setData(formatReceived(response.data));
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    let mounted = true;
    const fetchAll = async () => {
      try {
        setLoading(true);
        const promises = baseURIs.map((uri) => API.get(uri));
        const [
          _programaGeneralObra,
          _tipoObra,
          _situacionObra,
          _modalidadEjecucion,
          _formaAdjudicacion,
          _fuentesFinanciamiento,
          _listadoContratos,
        ] = (await Promise.all(promises)).map((response) => response.data);
        if (mounted) {
          setData(formatReceived(_programaGeneralObra));
          setTipoObra(_tipoObra || []);
          setSituacionObra(_situacionObra || []);
          setModalidadEjecucion(_modalidadEjecucion || []);
          setFormaAdjudicacion(_formaAdjudicacion || []);
          setFuentesFinanciamiento(normalize(makeThree(_fuentesFinanciamiento)) || []);
          setListadoContratos(_listadoContratos || []);
          setLoading(false);
        }
      } catch (err) {
        onError(err, setLoading);
      }
    };
    fetchAll();
    return () => { mounted = false; };
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setSelectedRowKeys([]);
    form.resetFields();
    setCurrentTabKey('generalTab');
    setVisible(false);
    setMutable(true);
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      await formContratista.validateFields();
      await formImportes.validateFields();
      const values = {
        ...form.getFieldsValue(),
        ...formContratista.getFieldsValue(),
        ...formImportes.getFieldsValue(),
      };
      const formatedValues = getFormatedValues(values);
      if (!selectedRowKeys.length) {
        const response = await API.post(baseURI, formatedValues);
        if (response?.status === 201) {
          onSuccess(response, 'Agregado correctamente');
          onCancel();
          await fetchData();
        }
      } else {
        const [key] = selectedRowKeys;
        const response = await API.put(`${baseURI}${key}/`, formatedValues);
        if (response?.status === 200) {
          onSuccess(response, 'Actualizado correctamente');
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading, formInstances, setCurrentTabKey);
    }
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`/obrapublica/programas-generales-de-obras/${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          setVisibleAlert(false);
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    setMutable(isMutable(record));
    setTimeout(() => {
      setSelectedRowKeys([record.id]);
      form.setFieldsValue(record);
      formContratista.setFieldsValue(record);
      formImportes.setFieldsValue(record);
    });
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const showMsg = makeMessager(setCurrentTabKey, fetchData);

  const onChangeTabKey = (key) => {
    const fromBasic = basicKeys.includes(currentTabKey);
    const toBasic = basicKeys.includes(key);
    const match = complexForms.find((e) => e.key === currentTabKey);
    if (!match) {
      if (fromBasic && toBasic) {
        setCurrentTabKey(key);
      } else if (fromBasic) {
        showMsg(key);
      } else {
        setCurrentTabKey(key);
      }
    } else {
      match.formInstance.onFinishHandler(key, true);
    }
  };

  const handleOnFinish = (vals, _continue = false) => {
    const match = complexForms.find((e) => e.key === currentTabKey);
    if (match) {
      match.formInstance.onFinishHandler(null, _continue);
    } else {
      onFinish(null, _continue);
    }
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const ejercicioAdjudicacion = [
    {
      label: 'Plurianual',
      value: false,
    },
    {
      label: 'Un solo ejercicio',
      value: true,
    },
  ];

  const columns = [
    {
      titleText: 'Clave',
      dataIndex: 'clave',
      key: 'clave',
      width: 150,
    },
    {
      titleText: 'Concepto de Obra',
      dataIndex: 'concepto_de_obra',
      key: 'concepto_de_obra',
      width: 300,
    },
    {
      titleText: 'Tipo de Obra',
      dataIndex: 'tipo_de_obra',
      key: 'tipo_de_obra',
      width: 300,
      render: (val) => (tipoObra.find((e) => e.id === val)?.descripcion),
    },
    {
      titleText: 'Situación de Obra',
      dataIndex: 'situacion_de_obra',
      key: 'situacion_de_obra',
      width: 300,
      render: (val) => (situacionObra.find((e) => e.id === val)?.descripcion),
    },
    {
      titleText: 'Modalidad de Ejecución',
      dataIndex: 'modalidad_de_ejecucion',
      key: 'modalidad_de_ejecucion',
      width: 300,
      render: (val) => (modalidadEjecucion.find((e) => e.id === val)?.descripcion),
    },
    {
      titleText: 'Forma de Adjudicación',
      dataIndex: 'forma_de_adjudicacion',
      key: 'forma_de_adjudicacion',
      width: 300,
      render: (val) => (formaAdjudicacion.find((e) => e.id === val)?.descripcion),
    },
    {
      titleText: 'Ejercicio de Realización',
      dataIndex: 'plurianual',
      key: 'plurianual',
      width: 300,
    },
    {
      titleText: 'Importe Total',
      dataIndex: 'importe_total',
      key: 'importe_total',
      width: 300,
    },
    {
      titleText: 'Fuente de Financiamiento',
      dataIndex: 'monto',
      key: 'monto',
      width: 300,
    },
    {
      titleText: 'Estado',
      dataIndex: 'estados_globales',
      key: 'estados_globales',
      width: 150,
      render: (val) => (estadosGlobales.find((e) => e.id === val)?.descripcion),
    },
    {
      titleText: 'No. Documento de Adjudicación',
      dataIndex: '',
      key: '',
      width: 300,
    },
    {
      titleText: 'Fecha de Adjudicación',
      dataIndex: 'fecha_de_adjudicacion',
      key: 'fecha_de_adjudicacion',
      width: 300,
      render: (val) => (val)?.format('DD/MM/YYYY'),
    },
    {
      titleText: 'Contratista Asignado',
      dataIndex: 'contratista_asignado',
      key: 'contratista_asignado',
      width: 300,
    },
    {
      titleText: 'No. de Contrato',
      dataIndex: 'numero_contrato',
      key: 'numero_contrato',
      width: 300,
    },
    {
      titleText: 'Fecha de Contrato',
      dataIndex: 'fecha_de_contrato',
      key: 'fecha_de_contrato',
      width: 300,
      render: (val) => (val)?.format('DD/MM/YYYY'),
    },
    {
      titleText: 'Fecha Inicio de Contrato',
      dataIndex: 'fecha_inicio_en_contrato',
      key: 'fecha_inicio_en_contrato',
      width: 300,
      render: (val) => (val)?.format('DD/MM/YYYY'),
    },
    {
      titleText: 'Fecha Terminación de Contrato',
      dataIndex: 'fecha_terminacion_en_contrato',
      key: 'fecha_terminacion_en_contrato',
      width: 300,
      render: (val) => (val)?.format('DD/MM/YYYY'),
    },
  ];

  const onClickEdit = async () => {
    try {
      setLoading(true);
      const [key] = selectedRowKeys;
      const match = data.find((e) => e.id === key);
      setTimeout(() => {
        setSelectedRowKeys([match.id]);
        form.setFieldsValue(match);
        formContratista.setFieldsValue(match);
        formImportes.setFieldsValue(match);
      });
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
    setVisible(true);
  };

  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible ? (
          <Table
            cols={columns}
            data={data}
            permission={permission}
            rowSelection={rowSelection}
            handleOnRowClick={handleOnRowClick}
            loading={loading}
            controls={{
              onClickAdd,
              onClickEdit,
              onClickDelete,
            }}
            mobileColIndex={0}
          />
        ) : (
          <Card
            className="form-container"
            title={(
              <FormSubmitControls
                label={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Programa General de Obra`}
                onCancel={onCancel}
                onFinish={basicKeys
                  .concat(complexForms.map((e) => e.key))
                  .includes(currentTabKey)
                  ? handleOnFinish : null}
              />
            )}
            bordered={false}
          >
            <Tabs
              type="card"
              activeKey={currentTabKey}
              onChange={!mutable ? setCurrentTabKey : onChangeTabKey}
            >
              <TabPane
                key="generalTab"
                tab="General"
                forceRender
              >
                <Form
                  name="general"
                  form={form}
                  layout="vertical"
                  onFinish={onFinish}
                  scrollToFirstError
                  initialValues={{ estados_globales: 1 }}
                >
                  <Row gutter={10}>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="clave"
                        label="Clave"
                        hasFeedback
                      >
                        <Input
                          allowClear
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="concepto_de_obra"
                        label="Concepto de Obra"
                        hasFeedback
                      >
                        <Input
                          allowClear
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="tipo_de_obra"
                        label="Tipo de Obra"
                        hasFeedback
                      >
                        <Select
                          dataSource={tipoObra}

                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="situacion_de_obra"
                        label="Situación de Obra"
                        hasFeedback
                      >
                        <Select
                          dataSource={situacionObra}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="modalidad_de_ejecucion"
                        label="Modalidad de Ejecución"
                        hasFeedback
                      >
                        <Select
                          dataSource={modalidadEjecucion}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="forma_de_adjudicacion"
                        label="Forma de Adjudicación"
                        hasFeedback
                      >
                        <Select
                          dataSource={formaAdjudicacion}
                          labelProp="concepto"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        label="Estado"
                        name="estados_globales"
                      >
                        <Select
                          dataSource={estadosGlobales}
                          disabled={!selectedRowKeys.length || !mutable}
                        />
                      </Form.Item>
                    </Col>
                    <Form.Item hidden>
                      <Button htmlType="submit" />
                    </Form.Item>
                  </Row>
                </Form>
              </TabPane>
              <TabPane
                tab="Dirección"
                key="direccion"
                disabled={!selectedRowKeys.length}
              >
                <Direccion
                  currentURI={baseURI}
                  parentID={selectedRowKeys[0]}
                  setLoading={setLoading}
                  setFormAddress={setFormAddress}
                  addressPropName="direccion"
                  setCurrentTabKey={setCurrentTabKey}
                  callback={(values, _continue) => {
                    if (!_continue) {
                      onCancel();
                    } else {
                      const [key] = selectedRowKeys;
                      const clone = [...data];
                      const mappedData = clone.map((e) => (e.id === key
                        ? ({ ...e, direccion: values?.id }) : e));
                      setData([...mappedData]);
                    }
                  }}
                  mutable={mutable}
                />
              </TabPane>
              <TabPane
                key="importeTab"
                tab="Importes"
                forceRender
                disabled={!selectedRowKeys.length}
              >
                <Form
                  form={formImportes}
                  onFinish={onFinish}
                  name="importeTab"
                  layout="vertical"
                >
                  <Row gutter={10}>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="plurianual"
                        label="Ejercicio de Adjudicación"
                        hasFeedback
                      >
                        <Select
                          dataSource={ejercicioAdjudicacion}
                          labelProp="label"
                          valueProp="value"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <NumericInput
                        name="importe_total"
                        label="Importe Total"
                      />
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="fuente_de_financiamiento"
                        label="Fuente de Financiamiento"
                        hasFeedback
                      >
                        <TreeSelect
                          showSearch
                          multiple
                          showArrow
                          treeNodeFilterProp="title"
                          style={{ width: '100%' }}
                          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                          treeData={fuentesFinanciamiento}
                          disabled={!mutable}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="numero_anios"
                        label="No. Años"
                        hasFeedback
                      >
                        <Input
                          allowClear
                        />
                      </Form.Item>
                    </Col>
                    <ImportesAnuales
                      selectedRowKey={selectedRowKeys[0]}
                    />
                  </Row>
                </Form>
              </TabPane>
              <TabPane
                disabled={!selectedRowKeys.length}
                key="contratistaTab"
                tab="Contratista"
                forceRender
              >
                <Form
                  form={formContratista}
                  onFinish={onFinish}
                  name="contratistaTab"
                  layout="vertical"
                >
                  <Row gutter={10}>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        // TODO: Este campo esta pendiente en el caso de uso parece
                        name=""
                        label="No. Documento de Adjudicación"
                        hasFeedback
                      >
                        <Select
                          dataSource={listadoContratos}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="fecha_de_adjudicacion"
                        label="Fecha de Adjudicación"
                        hasFeedback
                      >
                        <DatePicker
                          placeholder=""
                          format="DD/MM/YYYY"
                          allowClear
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="numero_contrato"
                        label="No. de Contrato"
                        hasFeedback
                      >
                        <Input
                          allowClear
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="contratista_asignado"
                        label="Contratista Asignado"
                        hasFeedback
                      >
                        <Input
                          allowClear
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="fecha_de_contrato"
                        label="Fecha de Contrato"
                        hasFeedback
                      >
                        <DatePicker
                          placeholder=""
                          format="DD/MM/YYYY"
                          allowClear
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="fecha_inicio_en_contrato"
                        label="Fecha de Inicio de Contrato"
                        hasFeedback
                      >
                        <DatePicker
                          placeholder=""
                          format="DD/MM/YYYY"
                          allowClear
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="fecha_terminacion_en_contrato"
                        label="Fecha de Terminacion de Contrato"
                        hasFeedback
                      >
                        <DatePicker
                          placeholder=""
                          format="DD/MM/YYYY"
                          allowClear
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </TabPane>
            </Tabs>
          </Card>
        )}
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => setVisibleAlert(false)}
          visible={visibleAlert}
          content="La Obra Seleccionada"
          loading={loading}
        />
      </Spin>
    </Row>
  );
}

export default ProgramaGeneralDeObra;
