import API from 'utils/api';
import { onSuccess, onError } from 'utils/handlers';

const baseURI = 'control-patrimonial/clasificaciones-de-activos/';

/**
 * GET Clasificaciones De Activo
 * @returns {Promise<Array<Object>>}
 */
export const getClasificacionesDeActivos = async () => {
  try {
    const response = await API.get(baseURI);
    return response.data;
  } catch (err) {
    onError(err);
    return [];
  }
};

/**
 * POST Clasificaciones De Activo
 * @param {object} values
 * @returns {Promise<object|boolean>}
 */
export const postClasificacionesDeActivos = async (values) => {
  try {
    const response = await API.post(baseURI, values);
    onSuccess(response, 'Agregado correctamente');
    return response.data;
  } catch (err) {
    onError(err);
    return false;
  }
};

/**
 * PATCH Clasificaciones De Activo
 * @param {number} id
 * @param {object} values
 * @returns {Promise<object|boolean>}
 */
export const patchClasificacionesDeActivos = async (id, values) => {
  try {
    const response = await API.patch(`${baseURI}${id}`, values);
    onSuccess(response, 'Editado correctamente');
    return response.data;
  } catch (err) {
    onError(err);
    return false;
  }
};

/**
 * DELETE Clasificaciones De Activo
 * @param {number} id
 * @returns {Promise<boolean>}
 */
export const deleteClasificacionesDeActivos = async (id) => {
  try {
    const response = await API.delete(`${baseURI}${id}`);
    onSuccess(response, 'Eliminado correctamente');
    return true;
  } catch (err) {
    onError(err);
    return false;
  }
};
