import React from 'react';
import { InputNumber } from 'antd';

const locale = 'en-us';
const config = {
  style: 'currency',
  currency: 'USD',
};

function CurrencyInput(props) {
  /**
    ** Formatea el valor a mostrar en el input
    */
  const currencyFormatter = (value) => {
    let val = parseFloat(value);
    if (value && Number.isNaN(val)) {
      val = parseFloat(value.replace('.', ''));
    } else if (!value) {
      val = 0;
    }
    return new Intl.NumberFormat(locale, config).format(val).replace('$', '');
  };
  /**
    ** Formatea el valor de entrada al input
    */
  const currencyParser = (val) => {
    try {
      // Asignar un valor a 'val' si este esta vacío
      if (typeof val === 'string' && !val.length) {
        // eslint-disable-next-line no-param-reassign
        val = '0.0';
      }

      // Removerr decimales si se borra el punto
      if (!val.toString().includes('.')) {
        // eslint-disable-next-line no-param-reassign
        val = val.toString().substr(0, val.toString().length - 2);
        // eslint-disable-next-line no-param-reassign
        val += '.00';
      }

      // Detectando y parseando entre coma y punto
      const group = new Intl.NumberFormat(locale).format(1111).replace(/1/g, '');
      const decimal = new Intl.NumberFormat(locale).format(1.1).replace(/1/g, '');
      let reversedVal = val.replace(new RegExp(`\\${group}`, 'g'), '');
      reversedVal = reversedVal.replace(new RegExp(`\\${decimal}`, 'g'), '.');

      // Mantenemos solo números y punto
      reversedVal = reversedVal.replace(/[^0-9.]/g, '');

      // Checamos si faltan los decimales
      const digitsAfterDecimalCount = (reversedVal.split('.')[1] || []).length;
      const needsDigitsAppended = digitsAfterDecimalCount > 2;

      if (needsDigitsAppended) {
        reversedVal *= 10 ** (digitsAfterDecimalCount - 2);
      }
      // Retornamos valor si es válido, de otra forma retornamos 0
      return Number.isNaN(reversedVal) ? 0 : reversedVal;
    } catch (error) {
      return 0;
    }
  };

  return (
    <>
      <span className="currency-symbol">
        $
      </span>
      <InputNumber
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        formatter={currencyFormatter}
        parser={currencyParser}
        className="currency-input"
      />
    </>
  );
}

export default CurrencyInput;
