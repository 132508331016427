import React, { useState } from 'react';
import {
  Modal,
  Row,
  Form,
  Col,
  Button,
  Input,
  Tooltip,
  Typography,
  Spin,
} from 'antd';
import PropTypes from 'prop-types';
import { QuestionCircleOutlined } from '@ant-design/icons';
import API from 'utils/api';
import FormSubmitControls from 'components/FormSubmitControls';
import Separador from 'components/Separador';
import { onError, onSuccess } from 'utils/handlers';

const { Text } = Typography;

function ModalConfig({
  visible,
  setVisible,
  setSavedConfig,
}) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      values.mascara = values.mascara[values.mascara.length - 1] === values.separador
        ? values.mascara.substr(0, values.mascara.length - 2) : values.mascara;
      const res = await API.post('configuraciones/configuraciones-de-mascaras/', {
        ...values,
        tipo: 1,
        estados_globales: 4,
      });
      if (res?.status === 201) {
        onSuccess(res, 'Configuración guardada correctamente');
        onCancel();
      }
      setSavedConfig(true);
      setLoading(false);
    } catch (err) {
      onError(err);
    }
  };

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es requerido.',
      },
    ],
    mascara: [
      {
        validator: async (rule, value) => {
          const separador = form.getFieldValue('separador');
          const pattern = `^[0\\${separador}]+$`;
          const regExp = new RegExp(pattern);
          if (value) {
            if (regExp.test(value)) {
              const segmentos = value.split(separador).filter((s) => s.length);
              if (segmentos.length < 2) {
                throw new Error('La mascara no puedo constar de un solo segmento');
              } else if (segmentos[0].length !== 5) {
                throw new Error('La longitud del primer segmento debe ser de 5');
              }
            } else {
              throw new Error('Ingrese una mascara válida.');
            }
          } else {
            throw new Error('El campo es requerido.');
          }
        },
      },
    ],
  };

  function InputMascara(props) {
    const onChangeInput = (e) => {
      const value = e.target.value.toString().toUpperCase();
      const { onChange } = props;
      const separador = form.getFieldValue('separador');
      const regExReplace = new RegExp(`\\${separador}+`, 'g');
      const formattedValue = value.replace(regExReplace, separador);
      onChange(formattedValue);
    };

    return (
      <Input
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        onChange={onChangeInput}
        allowClear
      />
    );
  }

  InputMascara.propTypes = {
    onChange: PropTypes.func,
  };

  InputMascara.defaultProps = {
    onChange: () => {},
  };

  return (
    <Modal
      visible={visible}
      title={(
        <FormSubmitControls
          label="Configuracion de Mascara Global"
          onFinish={onFinish}
          onCancel={onCancel}
          loading={loading}
        />
      )}
      closable={false}
      onCancel={onCancel}
      footer={null}
      width={550}
      // maskClosable={!loading}
      // keyboard={!loading}
    >
      <Spin spinning={loading} tip="Cargando...">
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          onValuesChange={(changed, all) => {
            const { mascara } = all;
            if (mascara) {
              form.validateFields();
            }
          }}
          scrollToFirstError
          initialValues={{ mascara: '00000', separador: '-' }}
        >
          <Row gutter={10}>
            <Col span={24}>
              <Separador rule={rules.required} />
            </Col>
            <Col span={24}>
              <Form.Item
                label={(
                  <>
                    <span style={{ marginRight: 5 }}>
                      Mascara
                    </span>
                    <Tooltip
                      title="Ejemplo: 00000-000-00"
                    >
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </>
              )}
                name="mascara"
                rules={rules.mascara}
                hasFeedback
                normalize={(val) => val.replaceAll(new RegExp(`[^${form.getFieldValue('separador')}]`, 'g'), '0')}
              >
                <InputMascara />
              </Form.Item>
            </Col>
            <Form.Item hidden>
              <Button htmlType="submit" />
            </Form.Item>
          </Row>
        </Form>
        <Text>
          No cuenta con una configuración de máscara para segmentos contables.
          Por favor, registre su configuración en este formulario.
        </Text>
        <br />
      </Spin>
    </Modal>
  );
}

ModalConfig.propTypes = {
  setVisible: PropTypes.func.isRequired,
  setSavedConfig: PropTypes.func.isRequired,
  visible: PropTypes.bool,
};

ModalConfig.defaultProps = {
  visible: false,
};

export default ModalConfig;
