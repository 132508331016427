/**
 * Filtra los elementos de una estructura de datos "arbolada"
 * @param {Array<Number>} targets Parámetros de búsqueda
 * @param {Array<Object>} data Estructura de datos "arbolada"
 * @returns {Array<Object>} Estructura de datos "arbolada" filtrada
 */
export const filterArbolado = (
  targets = [],
  data = [],
  config = {},
) => {
  const mixed = config.mixed || false;
  const childrenProp = config.childrenProp || 'children';
  const prop = mixed ? 'key' : 'id';
  // eslint-disable-next-line no-param-reassign
  targets = targets.map((e) => (mixed ? e.toString() : parseInt(e, 10)));
  const reducer = (_acum, _curr, level) => {
    const acum = [..._acum];
    const curr = { ..._curr };
    // Función que retorna el nodo actual eliminando su prop 'children'
    const currValue = () => {
      delete curr[childrenProp];
      return ({ ...curr, level: curr.level ?? level - 1 });
    };
    if (curr[childrenProp]?.length) {
      return acum
        // Concatenamos la prop 'children' para emular flatting
        .concat(curr[childrenProp]
          ?.map((e) => ({ ...e, parentKey: curr[prop], level: e.level ?? level })))
        // concatenamos nodo actual
        .concat((currValue()));
    }
    return acum.concat((currValue()));
  };

  // flatted values with parentKey
  let flatted = JSON.parse(JSON.stringify([...data]));
  let level = 1;
  while (flatted.some((e) => e[childrenProp]?.length)) {
    flatted = flatted
    // Reducimos el array
    // eslint-disable-next-line no-loop-func
      .reduce((acum, curr) => reducer(acum, curr, level), []);
    level += 1;
  }

  // Filtramos 'flatted' para obtener solo los que hagan match con nuestros targets
  const filtered = JSON
    .parse(JSON.stringify([...flatted.filter((e) => targets.includes(e[prop]))]));

  // Obteniendo el nivel más 'bajo' o 'profundo'
  const initialLevel = ([...filtered].sort((a, b) => a.level > b.level)
    .shift()?.level || -1) + 1;

  /**
    * Función para obtener los elementos del nivel superior
    * con sus 'hijos' aplicables inyectados
  */
  const getFilt = (arr, lvl, flattedArr) => {
    const currLvl = (lvl - 1);
    const filteredByCurrentLevel = flattedArr
      .filter((e) => e.level === currLvl);
    return filteredByCurrentLevel.map((e) => {
      const children = arr
        .concat(flattedArr.filter((c) => c[childrenProp] && !arr.some((x) => c[prop] === x[prop])))
        .filter((c) => c.parentKey === e[prop])
        // eslint-disable-next-line no-param-reassign
        .map((c) => { delete c.level; return c; });
      if (children.length) {
        return { ...e, children };
      }
      if (lvl === 1 && arr.some((x) => x[prop] === e[prop])) {
        return { ...e, children: [] };
      }
      return e;
    });
  };
  /**
    * Iteramos a partir del nivel mas bajo de nuestra estructura para comenzar
    * a filtrar e inyectar valores hacia los padres
  */
  for (let lvl = initialLevel; lvl >= 0; lvl -= 1) {
    // Obtenemos elementos del nivel actual
    const filt = getFilt(filtered.filter((c) => c.level <= lvl), lvl, flatted);
    // Filtramos 'flatted' con solo los elementos de nivel igual o menor
    flatted = flatted.filter((e) => e.level <= lvl)
      // Sobreescribimos si hay match en 'filt'
      .map((e) => {
        const match = filt.find((c) => c[prop] === e[prop]);
        if (match) {
          return match;
        }
        return e;
      });
  }

  // Filtramos y limpiamos el primer nivel para obtener la salida
  const output = flatted.filter((e) => e[childrenProp]).map((e) => {
    if (!e[childrenProp].length) {
      delete e[childrenProp];
    }
    delete e.level;
    return e;
  });

  // Retornamos salida
  return output;
};

export const findItemNested = (arr, id, prop = 'id', childrenProp = 'children') => (
  // eslint-disable-next-line
  arr.reduce((accum, item) => {
    if (accum) return accum;
    if (item[prop] === id) return item;
    if (item[childrenProp]?.length) {
      return findItemNested(
        item[childrenProp],
        id,
        prop,
        childrenProp,
      );
    }
  }, null)
);

export default { filterArbolado, findItemNested };
