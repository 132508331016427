import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import {
  Row,
  Col,
  Form,
  Card,
  Button,
} from 'antd';

import Table from 'components/Table';
import FormSubmitControls from 'components/FormSubmitControls';
import Select from 'components/Select';
import fetchSome from 'utils/fetchSome';
import { onError, onSuccess } from 'utils/handlers';
import { SET_UNIDADES_DE_MEDIDA } from 'store/reducers/catalogs/index';
import API from 'utils/api';
import currencyFormater from 'utils/currencyFormatter';
import NumericInput from 'components/NumericInput';
import MontoDetalldo from './MontoDetallado';

function TableMontoDetallado({ baseURI, callback }) {
  const unidadesDeMedida = useSelector(({ catalogs }) => catalogs.unidadesDeMedida);
  const meses = useSelector(({ catalogs }) => catalogs.meses);
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentMonth, setCurrentMonth] = useState('');
  const [data, setData] = useState([]);

  const [dataDetalle, setDataDetalle] = useState([]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await API.get(baseURI);
      setData(response.data);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const fetchAll = async () => {
    try {
      setLoading(true);
      if (!unidadesDeMedida.length) {
        fetchSome('/configuraciones/unidades-de-medida/', SET_UNIDADES_DE_MEDIDA);
      }
      await fetchData();
      setLoading(false);
    } catch (err) {
      onError(err);
    }
  };

  useState(() => {
    fetchAll();
  }, []);

  const onCancel = () => {
    setVisible(false);
    setSelectedRowKeys([]);
    form.resetFields();
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      await callback(null, true);
      const values = form.getFieldsValue();
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.put(`${baseURI}${key}/`, values);
        if (response?.status === 200) {
          onSuccess(response, 'Actualizado correctamente');
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const columns = [
    {
      titleText: 'Mes',
      dataIndex: 'mes',
      key: 'mes',
      render: (e) => meses.find((mes) => mes.id === e)?.nombre,
    },
    {
      titleText: 'Importe',
      dataIndex: 'importe',
      key: 'importe',
      render: (val) => currencyFormater(val),
      align: 'right',
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const handleOnRowClick = (record) => {
    const monthMatch = meses.find((mes) => mes.id === record.mes);
    setCurrentMonth(monthMatch.nombre);
    setSelectedRowKeys([record.id]);
    form.setFieldsValue({ ...record });
  };

  const onClickEdit = () => {
    setVisible(true);
  };

  return (
    <Row>
      <Col span={24}>
        {!visible ? (
          <Table
            mobileCols={[columns[0]]}
            cols={columns}
            data={data}
            rowSelection={rowSelection}
            handleOnRowClick={handleOnRowClick}
            allowAdd={false}
            allowDelete={false}
            controls={{
              onClickEdit,
            }}
            allowExport={false}
            rowKey="id"
          />
        ) : (
          <Card
            className="form-container no-padding"
            title={`${selectedRowKeys.length
              ? 'Editar' : 'Agregar'} detalles del mes de ${currentMonth}`}
            extra={(
              <FormSubmitControls
                onFinish={onFinish}
                onCancel={onCancel}
                loading={loading}
              />
            )}
            bordered={false}
          >
            <Form
              layout="vertical"
              onFinish={onFinish}
              form={form}
            >
              <Row gutter={10}>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    label="Mes"
                    name="mes"
                  >
                    <Select labelProp="nombre" dataSource={meses} disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <NumericInput
                    name="importe"
                    required
                    label="Importe"
                  />
                </Col>
                <Form.Item hidden>
                  <Button htmlType="submit" />
                </Form.Item>
                <Col span={24}>
                  <MontoDetalldo
                    baseURI={`${baseURI}${selectedRowKeys[0]}/detallado/`}
                    data={dataDetalle}
                    setData={setDataDetalle}
                    callback={(allDetalles) => {
                      const ttlImporte = allDetalles.reduce((acum, curr) => acum + curr.total, 0);
                      form.setFieldsValue({ importe: ttlImporte });
                    }}
                  />
                </Col>
              </Row>
            </Form>
          </Card>
        )}
      </Col>
    </Row>
  );
}

TableMontoDetallado.propTypes = {
  baseURI: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired,
};

export default TableMontoDetallado;
