import React, { useState, useEffect } from 'react';
import {
  Card,
  Form,
  Row,
  Spin,
  Tabs,
  Col,
  Input,
  Select,
  Modal,
  DatePicker,
} from 'antd';

import API from 'utils/api';
import { onError } from 'utils/handlers';
import FormSubmitControls from 'components/FormSubmitControls';
import {
  FileExcelOutlined,
  FilePdfOutlined,
} from '@ant-design/icons';
import Table from 'components/Table';

const { TabPane } = Tabs;

function FinActivoFijo() {
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentTabKey, setCurrentTabKey] = useState('valor_conac');
  const [mutable, setMutable] = useState(true);
  const [visibleModalAgregarFinalidad, setVisibleModalAgregarFinalidad] = useState(false);
  const [visibleModalAgregarUma, setVisibleModalAgregarUma] = useState(false);
  const [visibleModalAgregarCta, setVisibleModalAgregarCta] = useState(false);
  const fetchData = async () => {
    try {
      setLoading(true);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchData();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    setTimeout(() => form.setFieldsValue(record));
  };
  const requiredRule = {
    required: true,
    message: 'El campo es requerido',
  };
  const rules = {
    required: [requiredRule],
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickAdd = () => {
    setMutable(true);
    setVisibleModalAgregarFinalidad(true);
  };

  const onClickEdit = () => {
    setVisibleModalAgregarFinalidad(true);
  };

  const onClickAddUma = () => {
    setVisibleModalAgregarUma(true);
  };

  const onClickDeleteUma = () => {
    setVisibleModalAgregarUma(true);
  };

  const onClickDelete = () => {};

  const onClickAddCta = () => {
    setVisibleModalAgregarCta(true);
  };

  const onClickEditarCta = () => {
    setVisibleModalAgregarCta(true);
  };

  const onClickDeleteCta = () => {};

  const onChangeTabKey = () => {};

  const onDownloadPdf = () => {};

  const onDownLoadExcel = () => {};

  const onCancelModalFinalidad = () => {
    setVisibleModalAgregarFinalidad(false);
  };

  const onCancelModalUma = () => {
    setVisibleModalAgregarUma(false);
  };

  const onCancelModalCta = () => {
    setVisibleModalAgregarCta(false);
  };

  const columns = [
    {
      titleText: 'No. de Umas',
      dataIndex: 'no_umas',
      key: 'no_umas',
      width: 200,
    },
    {
      titleText: 'Inicio de Vigencia',
      dataIndex: 'inicio_vigencia',
      key: 'inicio_vigencia',
      width: 200,
    },
    {
      titleText: 'Fin de Vigencia',
      dataIndex: 'fin_vigencia',
      key: 'fin_vigencia',
      width: 200,
    },
  ];

  const columnsUma = [
    {
      titleText: 'Mes',
      dataIndex: 'mes',
      key: 'mes',
      width: 200,
    },
    {
      titleText: 'UMA',
      dataIndex: 'uma',
      key: 'uma',
      width: 200,
    },
    {
      titleText: 'Importe',
      dataIndex: 'importe',
      key: 'importe',
      width: 200,
    },
  ];

  const columnsCuentasContables = [
    {
      titleText: 'COG',
      dataIndex: 'cog',
      key: 'cog',
      width: 200,
    },
    {
      titleText: 'Concepto COG',
      dataIndex: 'concepto_cog',
      key: 'concepto_cog',
      width: 200,
    },
    {
      titleText: 'Cuenta Contable',
      dataIndex: 'cuenta_contable',
      key: 'cuenta_contable',
      width: 200,
    },
    {
      titleText: 'Estado',
      dataIndex: 'estado',
      key: 'estado',
      width: 200,
    },
  ];

  const onFinish = () => ({});

  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        <>
          <Row gutter={10}>
            <Col md={24} sm={24} xs={24}>
              <Form
                layout="horizontal"
                labelAlign="left"
                labelWrap
                onFinish={onFinish}
                scrollToFirstError
                name="conac"
              >
                <Row gutter={10}>
                  <Col xs={24} sm={24} md={12}>
                    <Form.Item
                      name="aplica_finalidad_conac"
                      label="Aplica Finalidad de Conac"
                    >
                      <Select allowClear trueFalse />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
          <Card
            className="form-container"
            title="Finalidad Conac para Administración de Activo Fijo"
            bordered={false}
          >
            <Tabs
              activeKey={currentTabKey}
              onChange={mutable ? setCurrentTabKey : onChangeTabKey}
              type="card"
            >
              <TabPane
                tab="Valor Conac"
                key="valor_conac"
                forceRender
              >
                <Table
                  cols={columns}
                  data={[]}
                  rowSelection={rowSelection}
                  handleOnRowClick={handleOnRowClick}
                  mobileColIndex={0}
                  controls={{
                    onClickAdd,
                    onClickEdit,
                    onClickDelete,
                  }}
                />
              </TabPane>
              <TabPane
                tab="UMA"
                key="uma"
                forceRender
              >
                <Row gutter={10}>
                  <Col span={24}>
                    <Table
                      cols={columnsUma}
                      data={[]}
                      rowSelection={rowSelection}
                      handleOnRowClick={handleOnRowClick}
                      mobileColIndex={0}
                      controls={{
                        onClickAdd: () => onClickAddUma(),
                        onClickEdit: () => onClickDeleteUma(),
                      }}
                    />
                  </Col>
                </Row>
              </TabPane>
              <TabPane
                tab="Cuentas Contables"
                key="cuentas_contables"
                forceRender
              >
                <Table
                  cols={columnsCuentasContables}
                  data={[]}
                  rowSelection={rowSelection}
                  handleOnRowClick={handleOnRowClick}
                  mobileColIndex={0}
                  controls={{
                    onClickAdd: () => onClickAddCta(),
                    onClickEdit: () => onClickEditarCta(),
                    onClickDelete: () => onClickDeleteCta(),
                  }}
                  customActions={[
                    {
                      onClick: onDownloadPdf,
                      icon: <FilePdfOutlined />,
                      text: 'Descargar PDF',
                      disabledBySelection: false,
                    },
                    {
                      onClick: onDownLoadExcel,
                      icon: <FileExcelOutlined />,
                      text: 'Descargar Excel',
                      disabledBySelection: false,
                    },
                  ]}
                />
              </TabPane>
            </Tabs>
          </Card>
          <Modal
            visible={visibleModalAgregarFinalidad}
            closable={false}
            maskClosable={!loading}
            keyboard={!loading}
            footer={null}
            title={(
              <FormSubmitControls
                label={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Finalidad Conac`}
                onFinish={onFinish}
                onCancel={onCancelModalFinalidad}
                loading={loading}
              />
        )}
            onCancel={onCancelModalFinalidad}
            forceRender
          >
            <Form
              form={form}
              layout="vertical"
              name="conac"
              onFinish={onFinish}
              initialValues={{ estados_globales: 1 }}
            >
              <Col span={24}>
                <Form.Item
                  name="valor_conac"
                  label="Valor Conac"
                  rules={rules.required}
                >
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="ejercicio"
                  label="Ejercicio"
                  rules={rules.required}
                >
                  <Select allowClear />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="inicio_vigencia"
                  label="Inicio Vigencia"
                  rules={rules.required}
                >
                  <DatePicker allowClear />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="fin_vigencia"
                  label="Fin Vigencia"
                >
                  <DatePicker allowClear />
                </Form.Item>
              </Col>
            </Form>
          </Modal>
          <Modal
            visible={visibleModalAgregarUma}
            closable={false}
            maskClosable={!loading}
            keyboard={!loading}
            footer={null}
            title={(
              <FormSubmitControls
                label={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} UMA`}
                onFinish={onFinish}
                onCancel={onCancelModalUma}
                loading={loading}
              />
        )}
            onCancel={onCancelModalUma}
            forceRender
          >
            <Form
              form={form}
              layout="vertical"
              name="conac"
              onFinish={onFinish}
              initialValues={{ estados_globales: 1 }}
            >
              <Col span={24}>
                <Form.Item
                  name="ejercicio"
                  label="Ejercicio"
                >
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="mes"
                  label="Mes"
                  rules={rules.required}
                >
                  <Select allowClear />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="valor_uma"
                  label="Valor UMA"
                  rules={rules.required}
                >
                  <Input allowClear />
                </Form.Item>
              </Col>
            </Form>
          </Modal>
          <Modal
            visible={visibleModalAgregarCta}
            closable={false}
            maskClosable={!loading}
            keyboard={!loading}
            footer={null}
            title={(
              <FormSubmitControls
                label={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Cuenta Contable`}
                onFinish={onFinish}
                onCancel={onCancelModalCta}
                loading={loading}
              />
        )}
            onCancel={onCancelModalCta}
            forceRender
          >
            <Form
              form={form}
              layout="vertical"
              name="conac"
              onFinish={onFinish}
              initialValues={{ estados_globales: 1 }}
            >
              <Col span={24}>
                <Form.Item
                  name="clasificador_gadto"
                  label="Clasificador por Objeto del Gasto"
                  rules={rules.required}
                >
                  <Select allowClear />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="cuenta_contable"
                  label="Cuenta Contable"
                  rules={rules.required}
                >
                  <Select allowClear />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="estado"
                  label="Estado"
                >
                  <Input allowClear disabled defaultValue="Activo" />
                </Form.Item>
              </Col>
            </Form>
          </Modal>
        </>
      </Spin>
    </Row>
  );
}

export default FinActivoFijo;
