import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Row,
  Col,
  Spin,
  Form,
  Input,
  Modal,
  Button,
} from 'antd';
import Table from 'components/Table';
import FormSubmitControls from 'components/FormSubmitControls';
import API from 'utils/api';
import { onError, onSuccess } from 'utils/handlers';
import ModalDelete from 'components/ModalDelete';
import Select from 'components/Select';
import keyGenerator from 'utils/keyGenerator';

export const permissionCapituloCOG = {
  permissionModel: 'capitulocog',
};

const { TextArea } = Input;
const baseURI = '/configuraciones/capitulo-cog/';

// eslint-disable-next-line react/prop-types
function CapitulosCOG({ permission }) {
  const [form] = Form.useForm();
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [visibleAlert, setVisibleAlert] = useState(false);

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await API.get(baseURI);
      setData(res.data);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onCancel = () => {
    setVisible(false);
    setSelectedRowKeys([]);
    form.resetFields();
  };

  useEffect(() => {
    fetchData();
    return () => API.tryCancel;
  // eslint-disable-next-line
  }, []);

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      if (!selectedRowKeys.length) {
        const response = await API.post(baseURI, values);
        onSuccess(response, 'Agregado correctamente');
        onCancel();
        fetchData();
      } else {
        const [id] = selectedRowKeys;
        const response = await API.put(`${baseURI}${id}/`, values);
        onSuccess(response, 'Actualizado correctamente');
        onCancel();
        fetchData();
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    form.setFieldsValue(record);
  };

  const columns = [
    {
      titleText: 'Clave',
      dataIndex: 'clave',
      key: 'clave',
      width: 200,
    },
    {
      titleText: 'Nombre',
      dataIndex: 'nombre',
      key: 'nombre',
      width: 300,
    },
    {
      titleText: 'Descripción',
      dataIndex: 'descripcion',
      key: 'descripcion',
      width: 400,
    },
    {
      titleText: 'Estado',
      dataIndex: 'estados_globales',
      key: 'estados_globales',
      render: (val) => estadosGlobales.find(({ id }) => id === val)?.descripcion,
      width: 100,
    },
  ];

  const onClickAdd = () => {
    onCancel();
    keyGenerator(data, form);
    setVisible(true);
  };

  const onClickEdit = () => {
    setVisible(true);
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      const [id] = selectedRowKeys;
      const response = await API.delete(`${baseURI}${id}/`);
      onSuccess(response, 'Eliminado correctamente');
      onCancel();
      fetchData();
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const rules = {
    required: [{ required: true, message: 'Este campo es requerido' }],
  };

  return (
    <Row>
      <Spin spinning={loading}>
        <Table
          mobileColIndex={0}
          rowSelection={rowSelection}
          cols={columns}
          permission={permission}
          data={data}
          loading={loading}
          handleOnRowClick={handleOnRowClick}
          controls={{
            onClickAdd,
            onClickEdit,
            onClickDelete,
          }}
        />
        <Modal
          title={(
            <FormSubmitControls
              onCancel={onCancel}
              onFinish={onFinish}
              label={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} capitulo de COG`}
            />
        )}
          visible={visible}
          onCancel={onCancel}
          footer={null}
          closable={false}
        >
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            initialValues={{ estados_globales: 1 }}
          >
            <Col span={24}>
              <Form.Item
                name="clave"
                rules={rules.required}
                label="Clave"
              >
                <Input allowClear disabled={selectedRowKeys.length} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="nombre"
                rules={rules.required}
                label="Nombre"
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="descripcion"
                rules={rules.required}
                label="Descripción"
              >
                <TextArea autoSize={{ minRows: 4, maxRows: 4 }} allowClear />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="estados_globales"
                rules={rules.required}
                label="Estado"
              >
                <Select dataSource={estadosGlobales} disabled={!selectedRowKeys.length} />
              </Form.Item>
            </Col>
            <Form.Item hidden>
              <Button htmlType="submit" />
            </Form.Item>
          </Form>
        </Modal>
        <ModalDelete
          content="Capitulo de COG"
          visible={visibleAlert}
          onCancel={() => setVisibleAlert(false)}
          onDelete={deleteItem}
        />
      </Spin>
    </Row>
  );
}

export default CapitulosCOG;
