import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Row,
  Col,
  Spin,
  Card,
  Tabs,
  Button,
  message,
  DatePicker,
} from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { normalizeCurrentUri } from 'utils/URIFormat';
import CustomTable from 'components/Table';
import API from 'utils/api';
import { RFC_PATTERN, CURP_PATTERN, digitoVerificador } from 'utils/patterns';
import FormSubmitControls from 'components/FormSubmitControls';
import FormItemLabel from 'components/FormItemLabel';
import ModalDelete from 'components/ModalDelete';
import Direccion from 'components/Direccion';
import Contactos from 'components/Catalogos/Contactos';
import RepresentantesLegales from 'components/Catalogos/RepresentantesLegales';
import DatosCorporativos from 'components/Catalogos/DatosCorporativos';
import Retenciones from 'components/Catalogos/Retenciones';
import ClavesContablesTerceros from 'components/Catalogos/ClavesContablesTerceros';
import DocumentosTramites from 'components/Catalogos/DocumentosTramites';
import Select from 'components/Select';
import { onError, onSuccess } from 'utils/handlers';
import makeMessager from 'utils/complexFormsMessages';
import { isMutable } from 'utils/estadosGlobales';
import getFormattedValues, { formatReceived } from 'utils/formatValues';
import DatosDePago from 'components/Catalogos/DatosDePago';

const { TabPane } = Tabs;

function Administracion() {
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);
  const [contentTypesTerceros, setContentTypesTerceros] = useState([]);
  const [formAddress, setFormAddress] = useState();

  const currentURI = window.location.href.split('/').pop();
  const baseURI = `configuraciones/${currentURI}`;

  const [currentTabKey, setCurrentTabKey] = useState('general');
  const [clasificadoresDeGasto, setClasificadoresDeGasto] = useState([]);
  const [requiredID, setRequiredID] = useState(false);
  const [requiredRFC, setRequiredRFC] = useState(false);
  const [requiredCURP, setRequiredCURP] = useState(false);
  const [mutable, setMutable] = useState(true);
  const [formasDePago, setFormasDePago] = useState([]);
  const [estratificacion, setEstratificacion] = useState([]);
  // const [selectedRegimen, setSelectedRegimen] = useState(0);

  const basicKeys = ['general'];
  const complexForms = [{ key: 'direccion', formInstance: formAddress }];
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await API.get(baseURI);
      setData(formatReceived(response.data));
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };
  const showMsg = makeMessager(setCurrentTabKey, fetchData);

  useEffect(() => {
    const fetchAll = async () => {
      try {
        setLoading(true);
        const resClasificadores = await API.get('contabilidad/clasificaciones-objeto-gasto-interno/');
        setClasificadoresDeGasto(resClasificadores.data);

        const resContentTypes = await API.get('catalogos/content-types-terceros/');
        setContentTypesTerceros(resContentTypes.data);

        const resFormasDePago = await API.get('catalogos/formas-de-pago/');
        setFormasDePago(resFormasDePago.data);

        const resEstratificacion = await API.get('catalogos/estratificaciones/');
        setEstratificacion(resEstratificacion.data);

        await fetchData();
        setLoading(false);
      } catch (err) {
        onError(err, setLoading);
      }
    };
    fetchAll();

    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    setSelectedRowKeys([]);
    setCurrentTabKey('general');
    setMutable(true);
  };

  const onFinish = async (_values, _continue = false) => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      if (values.identificador_fiscal_de_extranjeros) {
        values.curp = null;
        values.rfc = null;
      } else if (values.curp) {
        // ? values.rfc = null;   Se elimino por que borraba el rfc y nodejaba guardar
        values.identificador_fiscal_de_extranjeros = null;
      } else {
        values.curp = null;
        values.identificador_fiscal_de_extranjeros = null;
      }
      if (!selectedRowKeys.length) {
        const response = await API.post(baseURI, getFormattedValues(values));
        if (response?.status === 201) {
          if (!_continue) {
            onSuccess(response, 'Agregado correctamente');
            onCancel();
            await fetchData();
          } else {
            await fetchData();
            setSelectedRowKeys([response.data.id]);
            setMutable(isMutable(response.data));
            return response.data;
          }
        }
      } else {
        const [key] = selectedRowKeys;
        const response = await API.patch(`${baseURI}/${key}/`, getFormattedValues(values));
        if (response?.status === 200) {
          if (!_continue) {
            onSuccess(response, 'Actualizado correctamente');
            onCancel();
            await fetchData();
          } else {
            await fetchData();
            setSelectedRowKeys([response.data.id]);
            setMutable(isMutable(response.data));
            return response.data;
          }
        }
      }
      setLoading(false);
      return null;
    } catch (err) {
      if (_continue) {
        setLoading(false);
        throw err;
      } else {
        onError(err, setLoading);
      }
      return null;
    }
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`${baseURI}/${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          setVisibleAlert(false);
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    // setSelectedRegimen(record.regimen_fiscal);
    setSelectedRowKeys([record.id]);
    form.setFieldsValue(record);
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickEdit = () => {
    try {
      const [key] = selectedRowKeys;
      const match = data.find((item) => item.id === key);
      const {
        es_extranjero,
        es_persona_moral,
      } = match;
      setMutable(isMutable(match));
      setRequiredCURP(!es_extranjero && !es_persona_moral);
      setRequiredRFC(!es_extranjero);
      setRequiredID(es_extranjero);
      // setSelectedRegimen(match?.regimen_fiscal || 0);
      setTimeout(() => {
        form.setFieldsValue({
          ...match,
        });
      });
      setVisible(true);
    } catch (err) {
      onError(err);
    }
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const columns = [
    {
      titleText: 'Clave',
      key: 'clave',
      dataIndex: 'clave',
      width: 150,
    },
    {
      titleText: 'Tipo Contribuyente',
      key: 'es_persona_moral',
      dataIndex: 'es_persona_moral',
      width: 200,
      render: (val) => (val ? 'Persona Moral' : 'Persona Física'),
    },
    {
      titleText: 'Nacionalidad',
      key: 'es_extranjero',
      dataIndex: 'es_extranjero',
      width: 200,
      render: (val) => (val ? 'Extranjera' : 'Nacional'),
    },
    {
      titleText: 'Nombre o Razón Social',
      key: 'razon_social',
      dataIndex: 'razon_social',
      width: 300,
    },
    {
      titleText: 'RFC',
      key: 'rfc',
      dataIndex: 'rfc',
      width: 250,
    },
    {
      titleText: 'COG',
      key: 'clasificador_de_objeto_de_gasto',
      dataIndex: 'clasificador_de_objeto_de_gasto',
      width: 250,
    },
    // {
    //   titleText: 'CURP',
    //   key: 'curp',
    //   dataIndex: 'curp',
    //   width: 250,
    // },
    {
      titleText: 'ID Fiscal Extranjero',
      key: 'identificador_fiscal_de_extranjeros',
      dataIndex: 'identificador_fiscal_de_extranjeros',
      width: 250,
    },
    {
      titleText: 'Estado',
      key: 'estados_globales',
      dataIndex: 'estados_globales',
      width: 250,
      render: (val) => estadosGlobales.find((e) => e.id === val)?.descripcion,
    }, {
      titleText: 'Estratificación',
      key: 'estratificacion',
      dataIndex: 'estratificacion',
      width: 250,
      render: (val) => formasDePago.find((e) => e.id === val)?.descripcion,
    }, {
      titleText: 'Forma De Pago',
      key: 'forma_de_pago',
      dataIndex: 'forma_de_pago',
      width: 250,
      render: (val) => formasDePago.find((e) => e.id === val)?.descripcion,
    },
  ];

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
    rfc: [
      {
        required: true,
        message: 'El campo es requerido',
      },
      {
        validator: async (rule, value) => {
          if (requiredRFC) {
            if (value) {
              if (!RFC_PATTERN.test(value)
                || (form.getFieldValue('es_persona_moral') && value.length === 13)
                || (!form.getFieldValue('es_persona_moral') && value.length === 12)) {
                throw new Error('Ingrese un rfc válido');
              }
            }
          }
        },
      },
    ],
    CURP: [
      {
        validator: async (rule, value) => {
          if (requiredCURP) {
            if (value) {
              const validado = value.toUpperCase().match(CURP_PATTERN);
              if (!validado || validado[2] !== digitoVerificador(validado[1])) {
                throw new Error('Ingrese una CURP válida');
              }
            } else {
              throw new Error('El campo es requerido');
            }
          }
        },
      },
    ],
    identificador_fiscal_de_extranjeros: [
      {
        validator: async (rule, value) => {
          if (requiredID && !value) {
            throw new Error('El campo es requerido');
          }
        },
      },
    ],
    correo_electronico: [
      {
        type: 'email',
        message: 'Ingrese un correo electrónico válido',
      },
    ],
    pagina_web: [
      {
        type: 'url',
        message: 'Ingrese una URL válida',
      },
    ],
  };

  const isMoralOps = [{ descripcion: 'Persona Moral', id: true },
    { descripcion: 'Persona Física', id: false }];
  const nacionalidadOps = [{ descripcion: 'Nacional', id: false },
    { descripcion: 'Extranjera', id: true }];

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const onTabClick = async (key) => {
    const fromBasic = basicKeys.includes(currentTabKey);
    const toBasic = basicKeys.includes(key);
    const match = complexForms.find((e) => e.key === currentTabKey);
    if (!match) {
      if (fromBasic && toBasic) {
        setCurrentTabKey(key);
      } else if (fromBasic) {
        showMsg(key);
      } else {
        setCurrentTabKey(key);
      }
    } else {
      match.formInstance.onFinishHandler(key, true);
    }
  };

  const getContentTypeTerceros = () => {
    let model;
    switch (currentURI) {
      case 'clientes':
        model = 'cliente';
        break;
      case 'contratistas':
        model = 'contratista';
        break;
      case 'proveedores':
        model = 'proveedores';
        break;

      default:
        break;
    }
    return contentTypesTerceros.find((c) => c.model === model)?.id;
  };

  const handleOnFinish = (vals, _continue = false) => {
    const match = complexForms.find((e) => e.key === currentTabKey);
    if (match) {
      match.formInstance.onFinishHandler(null, _continue);
    }
    return onFinish(null, _continue);
  };

  const validateAuthorizable = async (match = {}) => {
    try {
      setLoading(true);
      const errors = [];
      if (!match?.datos_de_pago?.length) {
        errors.push({ message: 'Se necesita mínimo una forma de pago', tab: 'datos_de_pago' });
      }
      if (!match?.retencion?.length && currentURI !== 'acreedores') {
        errors.push({ message: 'Se necesita mínimo una retención', tab: 'retenciones' });
      }
      if (!match?.clave_de_tercero?.length) {
        errors.push({ message: 'Se necesita mínimo una cuenta contable', tab: 'claves_contables' });
      }
      if (errors.length) {
        message.warn({
          content: (
            <Col>
              <strong>Para autorizar es requerido complete los siguientes datos:</strong>
              <br />
              <br />
              {errors.map((item, idx) => (
                // eslint-disable-next-line react/no-array-index-key
                <React.Fragment key={idx}>
                  <Row justify="space-between">
                    <strong style={{ marginRight: 3 }}>
                      {item.message}
                    </strong>
                  </Row>
                </React.Fragment>
              ))}
              <Button
                icon={<CloseCircleOutlined />}
                onClick={() => message.destroy()}
                type="link"
                style={{
                  position: 'absolute',
                  top: -30,
                  right: -20,
                }}
              />
            </Col>
          ),
          duration: 6,
        });
        setLoading(false);
        return false;
      }
      setLoading(false);
      return true;
    } catch (err) {
      onError(err, setLoading);
      return false;
    }
  };

  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible ? (
          <CustomTable
            allowImport
            baseURI={baseURI}
            cols={columns}
            data={data}
            rowSelection={rowSelection}
            handleOnRowClick={handleOnRowClick}
            controls={{
              onClickAdd,
              onClickEdit,
              onClickDelete,
            }}
          />
        ) : (
          <Card
            className="form-container no-padding"
            title={(
              <FormSubmitControls
                label={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} ${normalizeCurrentUri(currentURI)}`}
                onFinish={basicKeys
                  .concat(complexForms.map((e) => e.key))
                  .includes(currentTabKey)
                  ? handleOnFinish : null}
                selectedRowKeys={selectedRowKeys}
                allowSaveAndContinue
                validateAuthorizable={validateAuthorizable}
                mutable={mutable}
                onCancel={onCancel}
                loading={loading}
                baseURI={baseURI}
                allowAuthorize
                allowCancel
                callback={(estados_globales) => {
                  const [key] = selectedRowKeys;
                  const normalized = [...data]
                    .map((e) => (e.id === key ? { ...e, estados_globales } : e));
                  setMutable(false);
                  setData(normalized);
                  const match = normalized.find((e) => e.id === key);
                  onClickEdit(match, true);
                }}
              />
            )}
            bordered={false}
          >
            <Tabs
              onChange={mutable ? onTabClick : setCurrentTabKey}
              activeKey={currentTabKey}
              type="card"
            >
              <TabPane tab="General" key="general">
                <Form
                  layout="vertical"
                  form={form}
                  onFinish={onFinish}
                  scrollToFirstError
                  onValuesChange={(_, allValues) => {
                    const { es_extranjero, es_persona_moral } = allValues;
                    setRequiredCURP(!es_extranjero && !es_persona_moral);
                    setRequiredRFC(!es_extranjero);
                    setRequiredID(es_extranjero);
                  }}
                  initialValues={{
                    es_persona_moral: true,
                    es_extranjero: false,
                    estados_globales: 1,
                  }}
                >
                  <Row gutter={10}>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="clave"
                        rules={rules.required}
                        label="Clave"
                        hasFeedback
                      >
                        <Input allowClear disabled={!mutable} />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="es_persona_moral"
                        rules={rules.required}
                        label="Tipo de Contribuyente"
                        hasFeedback
                      >
                        <Select dataSource={isMoralOps} disabled={!mutable} />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="es_extranjero"
                        rules={rules.required}
                        label="Nacional"
                        hasFeedback
                      >
                        <Select dataSource={nacionalidadOps} disabled={!mutable} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="razon_social"
                        rules={rules.required}
                        label="Nombre o Razón Social"
                        hasFeedback
                      >
                        <Input allowClear disabled={!mutable} />
                      </Form.Item>
                    </Col>

                    {requiredRFC && (
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="rfc"
                          rules={rules.rfc}
                          label="RFC"
                          hasFeedback
                          normalize={(val) => val?.toUpperCase()}
                        >
                          <Input maxLength={14} allowClear disabled={!mutable} />
                        </Form.Item>
                      </Col>
                    )}

                    {requiredCURP && (
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="curp"
                          rules={rules.CURP}
                          label="CURP"
                          hasFeedback
                          normalize={(val) => val?.toUpperCase()}
                        >
                          <Input allowClear disabled={!mutable} />
                        </Form.Item>
                      </Col>
                    )}

                    {requiredID && (
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="identificador_fiscal_de_extranjeros"
                          rules={rules.identificador_fiscal_de_extranjeros}
                          label="ID Fiscal Extranjero"
                          hasFeedback
                          normalize={(val) => val?.toUpperCase()}
                        >
                          <Input allowClear disabled={!mutable} />
                        </Form.Item>
                      </Col>
                    )}

                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="pagina_web"
                        rules={rules.pagina_web}
                        label="Página web"
                        hasFeedback
                      >
                        <Input allowClear disabled={!mutable} />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="estados_globales"
                        label="Estado"
                        rules={rules.required}
                        hasFeedback
                      >
                        <Select
                          disabled={!mutable || !selectedRowKeys.length}
                          dataSource={estadosGlobales}
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="clasificador_de_objeto_de_gasto"
                        label={(
                          <FormItemLabel
                            longTitle="Clasificador por Objeto del Gasto"
                            shortTitle="COG"
                            hideOn={['md', 'lg']}
                          />
                        )}
                        hasFeedback
                      >
                        <Select
                          mode="multiple"
                          dataSource={clasificadoresDeGasto}
                          keyLabelRender
                          labelProp="concepto"
                          disabled={!mutable}
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="fecha_de_inicio_de_operaciones"
                        rules={rules.required}
                        label="Fecha De Inicio De Operaciones"
                        hasFeedback
                      >
                        <DatePicker format="DD/MM/YYYY" placeholder="" />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="estratificacion"
                        label="Estratificación"
                        hasFeedback
                      >
                        <Select dataSource={estratificacion} disabled={!mutable} />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="forma_de_pago"
                        rules={rules.required}
                        label="Forma De Pago"
                        hasFeedback
                      >
                        <Select dataSource={formasDePago} disabled={!mutable} />
                      </Form.Item>
                    </Col>

                    <Form.Item hidden>
                      <Button htmlType="submit" />
                    </Form.Item>
                  </Row>
                </Form>
              </TabPane>
              <TabPane
                key="datos_pago"
                tab="Datos Pago"
                disabled={!selectedRowKeys.length}
                forceRender={selectedRowKeys.length}
              >
                <DatosDePago
                  activo={selectedRowKeys[0]}
                  currentURI={baseURI}
                />

              </TabPane>
              <TabPane tab="Contactos" key="contactos" disabled>
                <Col span={24}>
                  <Contactos
                    currentURI={baseURI}
                    selectedRowKey={selectedRowKeys[0]}
                  />
                </Col>
              </TabPane>
              <TabPane tab="Dirección" key="direccion" disabled>
                <Col span={24}>
                  <Direccion
                    currentURI={baseURI}
                    parentID={selectedRowKeys[0]}
                    disabled={!mutable}
                    setLoading={setLoading}
                    setFormAddress={setFormAddress}
                    setCurrentTabKey={setCurrentTabKey}
                    callback={(values, _continue) => {
                      if (!_continue) {
                        onCancel();
                      } else {
                        const [key] = selectedRowKeys;
                        const clone = [...data];
                        const mappedData = clone
                          .map((e) => (e.id === key ? ({ ...e, direccion: values?.id }) : e));
                        setData(mappedData);
                      }
                    }}
                  />
                </Col>
              </TabPane>
              <TabPane
                tab="Datos Corporativos"
                key="datos_corporativos"
                disabled
              >
                <DatosCorporativos
                  currentURI={`configuraciones/${currentURI}/`}
                  selectedRowKey={selectedRowKeys[0]}
                />
              </TabPane>
              <TabPane
                tab="Representante Legal"
                key="representantes_legales"
                disabled
              >
                <Col span={24}>
                  <RepresentantesLegales
                    currentURI={baseURI}
                    selectedRowKey={selectedRowKeys[0]}
                    content_type_terceros={getContentTypeTerceros()}
                  />
                </Col>
              </TabPane>
              <TabPane
                tab="Retenciones"
                key="retenciones"
                disabled
              >
                <Col span={24}>
                  <Retenciones
                    currentURI={baseURI}
                    selectedRowKey={selectedRowKeys[0]}
                    es_persona_moral={form.getFieldValue('es_persona_moral')}
                  />
                </Col>
              </TabPane>
              <TabPane
                tab="Cuentas Contables"
                key="claves_contables"
                disabled
              >
                <Col span={24}>
                  <ClavesContablesTerceros
                    uriTerceros={baseURI}
                    currentURI={baseURI}
                    selectedRowKey={selectedRowKeys[0]}
                  />
                </Col>
              </TabPane>
              <TabPane tab="Documentos" key="documentos" disabled={!selectedRowKeys.length}>
                <Col span={24}>
                  <DocumentosTramites
                    currentURI={baseURI}
                    selectedRowKey={selectedRowKeys[0]}
                  />
                </Col>
              </TabPane>
            </Tabs>
          </Card>
        )}
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => setVisibleAlert(false)}
          visible={visibleAlert}
          content={`${normalizeCurrentUri(currentURI, true)} ${form.getFieldValue('nombre_comercial')}?`}
          loading={loading}
        />
      </Spin>
    </Row>
  );
}

export default Administracion;
