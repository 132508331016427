import React from 'react';
import SingleForm from 'components/SingleForm';

export const permissionTipoDeEstimacion = {
  permissionModel: 'tipodeestimacion',
};

// eslint-disable-next-line react/prop-types
function TipoDeEstimaciones({ permission }) {
  return (
    <SingleForm
      permission={permission}
      baseURI="obrapublica/tipo-de-estimacion/"
      title="Tipo De Estimaciones"
    />
  );
}

export default TipoDeEstimaciones;
