import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Form,
  Button,
  Row,
  Col,
  Spin,
  Modal,
  DatePicker,
  Input,
  message,
} from 'antd';
import Table from 'components/Table';
import API from 'utils/api';
import FormSubmitControls from 'components/FormSubmitControls';
import ModalDelete from 'components/ModalDelete';
import { onError, onSuccess } from 'utils/handlers';
import { InputSearchPro } from 'components/InputSearch';
import { SET_URS } from 'store/reducers/catalogs';
import fetchSome from 'utils/fetchSome';
import getFormattedValues, { formatReceived } from 'utils/formatValues';
// import Select from 'components/Select';

const levelSorter = (a, b) => {
  if (a.nivel < b.nivel) {
    return -1;
  }
  if (a.nivel > b.nivel) {
    return 1;
  }
  return 0;
};

function Compradores({
  requisicionId,
  disabled,
}) {
  const baseURI = 'adquisiciones/requisiciones/:id/compradores/'.replace(':id', requisicionId);
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);
  const urs = useSelector(({ catalogs }) => catalogs.urs).sort(levelSorter);
  const [ursFound, setUrsFound] = useState([]);
  const [empleadoFound, setEmpleadoFound] = useState([]);
  const [urContentType, setUrContentType] = useState();
  // const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await API.get(baseURI);
      setData(formatReceived(res.data));
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };
  const fetchAll = async () => {
    try {
      setLoading(true);

      if (!urs?.length) {
        fetchSome('/catalogos/content-types-ur/', SET_URS);
      }

      await fetchData();
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchAll();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, [requisicionId]);

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    setSelectedRowKeys([]);
    setUrsFound([]);
    setEmpleadoFound([]);
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      values.tipo_de_unidad_responsable = urContentType;
      const formatedValues = getFormattedValues(values, {}, true);
      if (!selectedRowKeys.length) {
        const response = await API.post(baseURI, formatedValues);
        if (response?.status === 201) {
          onSuccess(response, 'Agregado correctamente');
          onCancel();
        }
      } else {
        const [key] = selectedRowKeys;
        const response = await API.put(`${baseURI}${key}/`, formatedValues);
        if (response?.status === 200) {
          onSuccess(response, 'Actualizado correctamente');
          onCancel();
        }
      }
      await fetchData();
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const deleteItem = async () => {
    try {
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`${baseURI}${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          setVisibleAlert(false);
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    // setTimeout(() => form.setFieldsValue(formatReceived(record)));
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const onClickEdit = async () => {
    try {
      setLoading(true);
      const [key] = selectedRowKeys;
      const { data: resData } = await API.get(`${baseURI}${key}/`);
      setUrContentType(resData.tipo_de_unidad_responsable);
      const { nivel } = urs.find((e) => e.id === resData.tipo_de_unidad_responsable);
      const uris = {
        1: '/unidades-operativas/',
        2: '/unidades-responsables/',
        3: '/centros-de-costos/',
      };
      const resUR = await API
        .get(`/estructura-organizacional${uris[nivel]}${resData.unidad_responsable_id}`);
      setUrsFound([resUR.data]);
      const resComprador = await API
        .get(`estructura-organizacional/empleados/${resData?.comprador}`);
      setEmpleadoFound([{
        ...resComprador.data,
        nombre_completo: `${resComprador.data
          ?.nombre} ${resComprador.data?.apellido_paterno || ''} ${resComprador.data?.apellido_materno || ''}`,
      }]);

      setTimeout(() => {
        form.setFieldsValue({
          ...formatReceived(resData),
          comprador: resComprador.data.id,
          object_id: resUR.data.id,
        });
      });
      setVisible(true);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const columns = [
    {
      titleText: 'Fecha de Requisición',
      dataIndex: 'fecha_de_requisicion',
      key: 'fecha_de_requisicion',
      width: 350,
      render: (e) => e?.format('DD/MM/YYYY'),
    },
    {
      titleText: 'Estado',
      dataIndex: 'estado',
      key: 'estado',
      width: 200,
    },
  ];

  const requiredRule = {
    required: true,
    message: 'El campo es requerido',
  };

  const rules = {
    required: [requiredRule],
  };

  const busquedaUR = async () => {
    try {
      setLoading(true);
      await form.validateFields(['content_type', 'q_ur']);
      const { content_type, q_ur: q } = form.getFieldsValue();
      setUrContentType(content_type);
      const { nivel } = urs.find((e) => e.id === content_type);
      const uris = {
        1: '/unidades-operativas/',
        2: '/unidades-responsables/',
        3: '/centros-de-costos/',
      };
      const response = await API.get(`/estructura-organizacional${uris[nivel]}`, {
        params: {
          q,
          estados_globales: 4,
        },
      });

      if (response?.status === 200 && response?.data?.length) {
        form.setFieldsValue({
          unidad_responsable_id: response.data[0].id,
        });
        setUrsFound([response.data[0]]);
      } else if (response?.status === 204) {
        message.info('No se encontraron coincidencias');
        setUrsFound([]);
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const busquedaEmpleado = async () => {
    try {
      setLoading(true);
      await form.validateFields(['q_empleado']);
      const { q_empleado: q } = form.getFieldsValue();
      const response = await API.get('estructura-organizacional/empleados/', {
        params: {
          numero_de_empleado: q,
        },
      });
      if (response?.status === 200 && response?.data?.length) {
        form.setFieldsValue({
          comprador: response.data[0].id,
        });
        const results = response.data.map((e) => ({
          ...e,
          nombre_completo: `${e?.nombre} ${e?.apellido_paterno || ''} ${e?.apellido_materno || ''}`,
        }));
        setEmpleadoFound([results[0]]);
      } else {
        message.info('No se encontraron coincidencias');
        setEmpleadoFound([]);
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const resetAndValidateURs = () => {
    setUrsFound([]);
    form.resetFields(['object_id']);
    form.validateFields(['object_id', 'q_ur']);
  };

  const resetAndValidateEmpleados = () => {
    setEmpleadoFound([]);
    form.resetFields(['comprador']);
    form.validateFields(['comprador']);
  };

  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        <Table
          cols={columns}
          data={data}
          rowSelection={rowSelection}
          handleOnRowClick={handleOnRowClick}
          controls={{
            onClickAdd,
            onClickEdit,
            onClickDelete,
          }}
          mobileColIndex={0}
          disabled={disabled}
        />
        <Modal
          visible={visible}
          title={(
            <FormSubmitControls
              label={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Comprador`}
              onFinish={onFinish}
              onCancel={onCancel}
              loading={loading}
            />
          )}
          closable={false}
          maskClosable={!loading}
          keyboard={!loading}
          onCancel={onCancel}
          footer={null}
          forceRender
        >
          <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
            scrollToFirstError
          >
            <Row>
              <Col span={24}>
                <Form.Item
                  name="fecha_de_requisicion"
                  rules={rules.required}
                  label="Fecha"
                  hasFeedback
                >
                  <DatePicker
                    placeholder=""
                    format="DD/MM/YYYY"
                    allowClear
                  />
                </Form.Item>
              </Col>
              <InputSearchPro
                label="Comprador"
                tooltip="Búsqueda por No. de Empleado"
                name="q_empleado"
                onPressEnter={() => busquedaEmpleado()}
                breakPoints={{
                  md: 24,
                }}
                inputProps={{
                  onPressEnter: () => busquedaEmpleado(),
                  onChange: ({ target: { value } }) => {
                    if (!value) {
                      resetAndValidateEmpleados();
                    }
                  },
                }}
                resultProps={{
                  name: 'comprador',
                  label: 'Comprador',
                  dataSource: empleadoFound,
                  labelProp: 'nombre_completo',
                  keyLabelRender: true,
                  keyProp: 'numero_de_empleado',
                  onClear: resetAndValidateEmpleados,
                  breakPoints: {
                    md: 24,
                  },
                }}
              />
              <InputSearchPro
                label="Unidad responsable"
                tooltip="Búsqueda por clave o descripción"
                name="q_ur"
                onPressEnter={() => busquedaUR()}
                prefix={{
                  style: {
                    marginTop: 'auto',
                    width: '45%',
                    minWidth: 180,
                  },
                  name: 'content_type',
                  label: 'Nivel',
                  render: (e) => e.model,
                  dataSource: urs,
                  onChange: resetAndValidateURs,
                }}
                breakPoints={{
                  md: 24,
                }}
                inputProps={{
                  onPressEnter: () => busquedaUR(),
                  onChange: ({ target: { value } }) => {
                    if (!value) {
                      resetAndValidateURs();
                    }
                  },
                }}
                resultProps={{
                  name: 'unidad_responsable_id',
                  label: 'Unidad responsable',
                  dataSource: ursFound,
                  keyLabelRender: true,
                  onClear: resetAndValidateURs,
                  breakPoints: {
                    md: 24,
                  },
                }}
              />
              {!!selectedRowKeys.length && (
                <Col span={24}>
                  <Form.Item
                    name="estado"
                    rules={rules.required}
                    label="Estado"
                    hasFeedback
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
              )}
              <Form.Item hidden>
                <Button htmlType="submit" />
              </Form.Item>
            </Row>
          </Form>
        </Modal>
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => setVisibleAlert(false)}
          visible={visibleAlert}
          content={`El comprador ${form.getFieldValue('concepto')}`}
          loading={loading}
        />
      </Spin>
    </Row>
  );
}

Compradores.propTypes = {
  requisicionId: PropTypes.number,
  disabled: PropTypes.bool.isRequired,
};

Compradores.defaultProps = {
  requisicionId: 0,
};

export default Compradores;
