import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Form,
  Input,
  Button,
  Spin,
  Row,
  Col,
  Modal,
} from 'antd';

import API from 'utils/api';
import { onError } from 'utils/handlers';
import NumericInput from '../NumericInput';
import FormSubmitControls from '../FormSubmitControls';
import Table from '../Table';
import ModalDelete from '../ModalDelete';
import Select from '../Select';

const baseURI = 'configuraciones/datos-de-pago/';

function DatosDePago({
  currentURI,
  selectedRowKey,
}) {
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);
  const [bancos, setBancos] = useState([]);
  const [metodosDePago, setMetodosDePago] = useState([]);
  const [requiredDatosBancarios, setRequiredDatosBancarios] = useState(false);

  const fetchData = async () => {
    try {
      if (selectedRowKey) {
        setLoading(true);
        let uri = currentURI;
        if (currentURI[currentURI.length - 1] !== '/') {
          uri = `${uri}/`;
        }
        const resCatalogo = await API.get(`${uri}${selectedRowKey}/`);
        if (resCatalogo && resCatalogo.status === 200) {
          const { datos_de_pago } = resCatalogo.data;
          let datosList = [];
          if (datos_de_pago?.length) {
            if (typeof datos_de_pago[0] !== 'number') {
              datosList = datos_de_pago.map((d) => d.id);
            } else {
              datosList = datos_de_pago;
            }
          }
          const promises = [];
          datosList.forEach((id) => {
            const res = API.get(`${baseURI}${id}/`);
            promises.push(res);
          });
          const responses = promises.length ? await Promise.all(promises) : [];
          const datos = responses.map((res) => res.data);
          setData(datos);
        }
        setLoading(false);
      }
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    const fetchAll = async () => {
      try {
        const resMetodos = await API.get('/catalogos/metodos-de-pago/');
        setMetodosDePago(resMetodos.data || []);
        const resBancos = await API.get('/configuraciones/bancos/');
        setBancos(resBancos.data || []);

        await fetchData();
      } catch (err) {
        onError(err, setLoading);
      }
    };
    fetchAll();

    return () => API.tryCancel;
    // eslint-disable-next-line
  }, [selectedRowKey]);

  const onCancel = () => {
    form.resetFields();
    setVisible(false);
    setSelectedRowKeys([]);
    setRequiredDatosBancarios(false);
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      if (selectedRowKey) {
        const [key] = selectedRowKeys;
        if (!selectedRowKeys.length) {
          const res = await API.post(baseURI, values);
          if (res?.status === 201) {
            const keys = data.map((item) => item.id);
            let uri = currentURI;
            if (currentURI[currentURI.length - 1] !== '/') {
              uri = `${uri}/`;
            }
            const resPatch = await API.patch(`${uri}${selectedRowKey}/`, {
              datos_de_pago: [...keys, res.data.id],
            });
            if (resPatch?.status === 200) {
              onCancel();
              await fetchData();
            }
          }
        } else {
          const res = await API.put(`${baseURI}${key}/`, values);
          if (res?.status === 200) {
            onCancel();
            await fetchData();
          }
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    form.setFieldsValue(record);
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const onClickEdit = () => {
    const [key] = selectedRowKeys;
    const { metodo_de_pago } = data.find((e) => e.id === key);
    const metodoDePago = metodosDePago.find((m) => m.id === metodo_de_pago);
    setRequiredDatosBancarios(metodoDePago.requiere_datos_bancarios);
    setVisible(true);
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      const [key] = selectedRowKeys;
      if (selectedRowKey) {
        const response = await API.delete(`${baseURI}${key}/`);
        if (response?.status === 204) {
          setVisibleAlert(false);
          onCancel();
          await fetchData();
        }
        setLoading(false);
      }
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
  };

  const columns = [
    {
      titleText: 'Método de pago',
      dataIndex: 'metodo_de_pago',
      key: 'metodo_de_pago',
      render: (val) => (metodosDePago.find((item) => item.id === val)?.concepto),
      width: 200,
    },
    {
      titleText: 'Banco',
      dataIndex: 'banco',
      key: 'banco',
      render: (val) => (val ? bancos.find((item) => item.id === val)?.nombre : 'N/A'),
      width: 200,
    },
    {
      titleText: 'Sucursal',
      dataIndex: 'sucursal',
      key: 'sucursal',
      render: (val) => (val || 'N/A'),
      width: 200,
    },
    {
      titleText: 'Cuenta o Tarjeta',
      dataIndex: 'numero_de_cuenta',
      key: 'numero_de_cuenta',
      render: (val) => (val || 'N/A'),
      width: 200,
    },
    {
      titleText: 'Clabe Interbancaria',
      dataIndex: 'clabe_interbancaria',
      key: 'clabe_interbancaria',
      render: (val) => (val || 'N/A'),
      width: 200,
    },
    {
      titleText: 'Finalidad',
      dataIndex: 'finalidad',
      key: 'finalidad',
      render: (val) => (val || 'N/A'),
      width: 200,
    },
    {
      titleText: 'Preferente',
      dataIndex: 'preferente',
      key: 'preferente',
      render: (val) => (val ? 'Sí' : 'No'),
      width: 120,
    },
    {
      titleText: 'Estado',
      dataIndex: 'estados_globales',
      key: 'estados_globales',
      render: (val) => estadosGlobales.find((e) => e.id === val)?.descripcion,
      width: 120,
    },
  ];

  return (
    <Spin tip="Cargando..." spinning={loading}>
      <Table
        cols={columns}
        data={data}
        rowSelection={rowSelection}
        handleOnRowClick={handleOnRowClick}
        controls={{
          onClickAdd,
          onClickEdit,
          onClickDelete,
        }}
        allowSort={false}
        allowSearch={false}
      />
      <Modal
        visible={visible}
        closable={false}
        maskClosable={!loading}
        keyboard={!loading}
        footer={null}
        title={(
          <FormSubmitControls
            label={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Datos de Pago`}
            onFinish={onFinish}
            onCancel={onCancel}
            loading={loading}
          />
        )}
        onCancel={onCancel}
        forceRender
        width={requiredDatosBancarios ? '80%' : '50%'}
      >
        <Form
          form={form}
          layout="vertical"
          name="datos_de_pago"
          onFinish={onFinish}
          initialValues={{ estados_globales: 1, preferente: false }}
        >
          <Row gutter={10}>
            <Col xs={24} sm={24} md={requiredDatosBancarios ? 12 : 24}>
              <Form.Item
                label="Forma de pago"
                name="metodo_de_pago"
                rules={rules.required}
              >
                <Select
                  dataSource={metodosDePago}
                  onChange={(val) => {
                    if (val) {
                      const match = metodosDePago.find((i) => i.id === val);
                      if (match.requiere_datos_bancarios) {
                        setRequiredDatosBancarios(true);
                        form.resetFields(
                          ['numero_de_cuenta', 'banco', 'sucursal', 'clabe_interbancaria'],
                        );
                      } else {
                        setRequiredDatosBancarios(false);
                      }
                    }
                  }}
                  labelProp="concepto"
                />
              </Form.Item>
            </Col>
            {requiredDatosBancarios && (
              <>
                <Col xs={24} sm={24} md={12}>
                  <NumericInput
                    name="numero_de_cuenta"
                    label="No. de cuenta"
                    required
                  />
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <NumericInput
                    name="clabe_interbancaria"
                    label="Clabe Interbancaria"
                    required
                  />
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="banco"
                    label="Banco"
                    rules={rules.required}
                  >
                    <Select dataSource={bancos} labelProp="nombre" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="sucursal"
                    label="Sucursal"
                    rules={rules.required}
                  >
                    <Input allowClear />
                  </Form.Item>
                </Col>
              </>
            )}
            <Col xs={24} sm={24} md={requiredDatosBancarios ? 12 : 24}>
              <Form.Item
                name="finalidad"
                label="Finalidad"
                rules={rules.required}
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={requiredDatosBancarios ? 12 : 24}>
              <Form.Item
                name="preferente"
                label="Preferente"
                rules={rules.required}
              >
                <Select trueFalse />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={requiredDatosBancarios ? 12 : 24}>
              <Form.Item
                name="estados_globales"
                label="Estado"
                rules={rules.required}
              >
                <Select disabled={!selectedRowKeys.length} dataSource={estadosGlobales} />
              </Form.Item>
            </Col>
            <Form.Item hidden>
              <Button htmlType="submit" />
            </Form.Item>
          </Row>
        </Form>
      </Modal>
      <ModalDelete
        onDelete={deleteItem}
        onCancel={() => setVisibleAlert(false)}
        visible={visibleAlert}
        content={`dato de pago ${form.getFieldValue('numero_de_cuenta')}`}
        loading={loading}
      />
    </Spin>
  );
}

DatosDePago.propTypes = {
  selectedRowKey: PropTypes.number,
  currentURI: PropTypes.string.isRequired,
};

DatosDePago.defaultProps = {
  selectedRowKey: undefined,
};

export default DatosDePago;
