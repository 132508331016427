import React, { useState, useEffect } from 'react';
import {
  Form,
  Row,
  Spin,
  // Modal,
} from 'antd';

import API from 'utils/api';
import { onError } from 'utils/handlers';
import Table from 'components/Table';
import ModalDelete from 'components/ModalDelete';
import _data from 'mocks/bancos';

function CargosBancarios() {
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      setLoading(true);
      setData(_data);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchData();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    setTimeout(() => form.setFieldsValue(record));
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickAdd = () => { };

  const onClickEdit = () => { };

  const onClickDelete = () => { };

  const columns = [
    {
      titleText: 'Fecha',
      dataIndex: 'fecha',
      key: 'fecha',
      width: 100,
    },
    {
      titleText: 'Cuenta Bancaria',
      dataIndex: 'cuenta_bancaria',
      key: 'cuenta_bancaria',
      width: 350,
    },
    {
      titleText: 'Concepto',
      dataIndex: 'concepto',
      key: 'concepto',
      width: 220,
    },
    {
      titleText: 'Importe',
      dataIndex: 'importe',
      key: 'importe',
      width: 220,
    },
    {
      titleText: 'IVA',
      dataIndex: 'iva',
      key: 'iva',
      width: 250,
    },
    {
      titleText: 'Total',
      dataIndex: 'total',
      key: 'total',
      width: 150,
      align: 'right',
    },
    {
      titleText: 'Clave Presupuestal',
      dataIndex: 'clave_presupuestal',
      key: 'fundamento',
      width: 200,
    },
    {
      titleText: 'COG',
      dataIndex: 'cog',
      key: 'cog',
      width: 200,
    },
    {
      titleText: 'Elaboro',
      key: 'elaboro',
      dataIndex: 'elaboro',
      width: 150,
    },
    {
      titleText: 'Puesto',
      dataIndex: 'puesto',
      key: 'puesto',
      width: 200,
    },
    {
      titleText: 'Área',
      dataIndex: 'area',
      key: 'area',
      width: 200,
    },
    {
      titleText: 'Poliza',
      dataIndex: 'poliza',
      key: 'poliza',
      width: 200,
    },
    {
      titleText: 'Movimiento Presupuestal',
      dataIndex: 'movimiento_presupuestal',
      key: 'movimiento_presupuestal',
      width: 200,
    },
    {
      titleText: 'Estatus',
      dataIndex: 'estatus',
      key: 'estatus',
      width: 200,
    },
    {
      titleText: 'Observaciones',
      dataIndex: 'observaciones',
      key: 'observaciones',
      width: 200,
    },
  ];

  const controls = () => ({
    onClickAdd,
    onClickEdit,
    onClickDelete,
  });

  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        <Table
          cols={columns}
          data={data}
          rowSelection={rowSelection}
          handleOnRowClick={handleOnRowClick}
          controls={controls()}
          mobileColIndex={0}
        />
        <ModalDelete
          onCancel={() => {
            setVisibleAlert(false);
            setSelectedRowKeys([]);
          }}
          visible={visibleAlert}
          content={`${form.getFieldValue('descripcion')}`}
          loading={loading}
        />
      </Spin>
    </Row>
  );
}

export default CargosBancarios;
