import React, { useState, useEffect, useRef } from 'react';
// import { useSelector } from 'react-redux';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Spin,
  // Modal,
  Card,
  message,
  // Typography,
} from 'antd';

import API from 'utils/api';
import { onError, onSuccess } from 'utils/handlers';
import Table from 'components/Table';
import FormSubmitControls from 'components/FormSubmitControls';
import ModalDelete from 'components/ModalDelete';
// import Select from 'components/Select';
import createClave from 'utils/keyGenerator';
import { isMutable, isAuthorized } from 'utils/estadosGlobales';
import _data from 'mocks/avancePresupuestal';
import currencyFormatter from 'utils/currencyFormatter';

export const permissionRequisicion = {
  permissionModel: 'requisicion',
};

// eslint-disable-next-line react/prop-types
function Requisiciones({ permission }) {
  // const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);

  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  // const [selectedRowKeysCompras, setSelectedRowKeysCompras] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);
  const [mutable, setMutable] = useState(true);
  // const [selected, setSelected] = useState();
  const inputDescripcion = useRef();

  const fetchData = async () => {
    try {
      setLoading(true);
      // const res = await API.get(baseURI);
      setData(_data);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchData();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    setSelectedRowKeys([]);
    // setSelected();
    setMutable(true);
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      // await form.validateFields();
      // const values = form.getFieldsValue();
      // if (!selectedRowKeys.length) {
      //   const response = await API.post('', values);
      //   if (response?.status === 201) {
      //     onSuccess(response, 'Agregado correctamente');
      //     onCancel();
      //     await fetchData();
      //   }
      // } else {
      //   const [key] = selectedRowKeys;
      //   const response = await API.put(`${key}/`, values);
      //   if (response?.status === 200) {
      //     onSuccess(response, 'Actualizado correctamente');
      //     onCancel();
      //     await fetchData();
      //   }
      // }
      onCancel();
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          setVisibleAlert(false);
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    // setSelected(record);
    setSelectedRowKeys([record.id]);
    setMutable(isMutable(record));
    form.setFieldsValue(record);
  };

  // const handleOnRowClickCompras = (record) => {
  //   setSelectedRowKeysCompras([record.id]);
  // };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  // const rowSelectionCompras = {
  //   selectedRowKeys: selectedRowKeysCompras,
  //   type: 'radio',
  // };

  const onClickAdd = () => {
    onCancel();
    createClave(data, form);
    setVisible(true);
  };

  const onClickEdit = () => setVisible(true);

  const onClickDelete = () => {
    const [key] = selectedRowKeys;
    const match = data.find((e) => e.id === key);
    if (isMutable(match)) {
      setVisibleAlert(true);
    } else if (isAuthorized(match)) {
      message.warn('No se puede eliminar un registro autorizado');
    } else {
      message.warn('No se puede eliminar un registro cancelado');
    }
  };

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
  };

  // const columnsCompras = [
  //   {
  //     titleText: 'Partida Presupuestal',
  //     dataIndex: 'partida_presupuestal',
  //     key: 'partida_presupuestal',
  //     width: 400,
  //   },
  //   {
  //     titleText: 'COG',
  //     dataIndex: 'cog',
  //     key: 'cog',
  //     width: 100,
  //   },
  //   {
  //     titleText: 'Descripción',
  //     dataIndex: 'descripcion',
  //     key: 'descripcion',
  //     width: 200,
  //   },
  //   {
  //     titleText: 'Unidad de Medida',
  //     dataIndex: 'unidad_medida',
  //     key: 'unidad_medida',
  //     width: 200,
  //   },
  //   {
  //     titleText: 'Cantidad',
  //     dataIndex: 'cantidad',
  //     key: 'cantidad',
  //     width: 100,
  //     align: 'right',

  //   },
  //   {
  //     titleText: 'Precio Unitario',
  //     dataIndex: 'precio_unitario',
  //     key: 'precio_unitario',
  //     width: 150,
  //     align: 'right',

  //     render: (a) => currencyFormatter(a || 0),
  //   },
  //   {
  //     titleText: 'Subtotal',
  //     dataIndex: 'sub_total',
  //     key: 'sub_total',
  //     width: 150,
  //     align: 'right',
  //     render: (a) => currencyFormatter(a || 0),
  //   },
  //   {
  //     titleText: 'IVA',
  //     dataIndex: 'iva',
  //     key: 'iva',
  //     width: 150,
  //     align: 'right',
  //     render: (a) => currencyFormatter(a || 0),
  //   },
  //   {
  //     titleText: 'Total',
  //     dataIndex: 'total',
  //     key: 'total',
  //     width: 150,
  //     align: 'right',
  //     render: (a) => currencyFormatter(a || 0),
  //   },
  //   {
  //     titleText: 'Techo Presupuestal',
  //     dataIndex: 'techo_presupuestal',
  //     key: 'techo_presupuestal',
  //     width: 150,
  //     align: 'right',
  //     render: (a) => currencyFormatter(a || 0),
  //   },
  //   {
  //     titleText: 'Verificacion de Existencia',
  //     dataIndex: 'verificacion_existencia',
  //     key: 'verificacion_existencia',
  //     width: 150,
  //     align: 'right',
  //     render: (a) => currencyFormatter(a || 0),
  //   },
  // ];

  const columns = [
    {
      titleText: 'Clave Presupuestal',
      dataIndex: 'clave_presupuestal',
      key: 'clave_presupuestal',
      width: 400,
    },
    {
      titleText: 'Aprobado',
      dataIndex: 'aprobado',
      key: 'aprobado',
      width: 200,
      align: 'right',
      render: (e) => currencyFormatter(e || 0),
    },
    {
      titleText: 'Modificaciones',
      dataIndex: 'modificaciones',
      key: 'modificaciones',
      width: 200,
    },
    {
      titleText: 'Vigente',
      dataIndex: 'vigente',
      key: 'vigente',
      width: 200,
      align: 'right',
      render: (e) => currencyFormatter(e || 0),

    },
    {
      titleText: 'Pre-Comprometido',
      dataIndex: 'pre_comprometido',
      key: 'pre_comprometido',
      width: 200,
      align: 'right',
      render: (e) => currencyFormatter(e || 0),

    },
    {
      titleText: 'Disponible',
      dataIndex: 'disponible',
      key: 'disponible',
      width: 200,
      align: 'right',
      render: (e) => currencyFormatter(e || 0),

    },
    {
      titleText: 'Comprometido',
      dataIndex: 'comprometido',
      key: 'comprometido',
      width: 200,
      align: 'right',
      render: (e) => currencyFormatter(e || 0),

    },
    {
      titleText: 'Devengado',
      dataIndex: 'devengado',
      key: 'devengado',
      width: 200,
      align: 'right',
      render: (e) => currencyFormatter(e || 0),

    },
    {
      titleText: 'Ejercido',
      dataIndex: 'ejercido',
      key: 'ejercido',
      width: 200,
      align: 'right',
      render: (e) => currencyFormatter(e || 0),

    },
    {
      titleText: 'Pagado',
      dataIndex: 'pagado',
      key: 'pagado',
      width: 200,
      align: 'right',
      render: (e) => currencyFormatter(e || 0),

    },
  ];

  const controls = () => ({
    onClickAdd,
    onClickEdit,
    onClickDelete,
  });

  const getRules = () => {
    if (!mutable) {
      return null;
    }
    return rules.required;
  };

  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible ? (
          <Table
            cols={columns}
            data={data}
            permission={permission}
            rowSelection={rowSelection}
            handleOnRowClick={handleOnRowClick}
            controls={controls()}
            mobileColIndex={0}
          />
        ) : (
          <Card
            className="form-container"
            title={`${selectedRowKeys.length ? 'Editar' : 'Agregar'}`}
            extra={(
              <FormSubmitControls
                onFinish={mutable ? onFinish : false}
                onCancel={onCancel}
                loading={loading}
                baseURI="a"
                selectedRowKeys={selectedRowKeys}
                allowAuthorize
                allowCancel
                callback={(estados_globales) => {
                  const [key] = selectedRowKeys;
                  const normalized = data.map((e) => {
                    if (e.id === key) {
                      setMutable(false);
                      return {
                        ...e,
                        estados_globales,
                      };
                    }
                    return e;
                  });
                  setData(normalized);
                  const match = normalized.find((e) => e.id === key);
                  onClickEdit(match);
                }}
              />
            )}
            bordered={false}
          >
            <Form
              layout="vertical"
              form={form}
              onFinish={onFinish}
              scrollToFirstError
            >
              <Row gutter={10}>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="clave_presupuestal"
                    rules={getRules()}
                    label="Clave Presupuestal"
                    hasFeedback
                  >
                    <Input
                      allowClear
                      ref={inputDescripcion}
                      disabled={!mutable}
                    />
                  </Form.Item>
                </Col>

              </Row>
              <Row gutter={10}>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="aprobado"
                    rules={getRules()}
                    label="Aprovado"
                    hasFeedback
                  >
                    <Input
                      disabled={!mutable}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="modificaciones"
                    rules={getRules()}
                    label="Modificaciones"
                    hasFeedback
                  >
                    <Input
                      disabled={!mutable}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="vigente"
                    rules={getRules()}
                    label="Vigente"
                    hasFeedback
                  >
                    <Input
                      disabled={!mutable}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="pre_comprometido"
                    rules={getRules()}
                    label="Pre-Comprometido"
                    hasFeedback
                  >
                    <Input
                      disabled={!mutable}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="disponible"
                    rules={getRules()}
                    label="Disponible"
                    hasFeedback
                  >
                    <Input
                      disabled={!mutable}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="comprometido"
                    rules={getRules()}
                    label="Comprometido"
                    hasFeedback
                  >
                    <Input
                      disabled={!mutable}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="devengado"
                    rules={getRules()}
                    label="Devengado"
                    hasFeedback
                  >
                    <Input
                      disabled={!mutable}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="ejercido"
                    rules={getRules()}
                    label="Ejercido"
                    hasFeedback
                  >
                    <Input
                      disabled={!mutable}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="pagado"
                    rules={getRules()}
                    label="Pagado"
                    hasFeedback
                  >
                    <Input
                      disabled={!mutable}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item hidden>
                <Button htmlType="submit" />
              </Form.Item>
            </Form>
            {/* <Table
              cols={columnsCompras}
              data={selected?.data_table}
              rowSelection={rowSelectionCompras}
              handleOnRowClick={handleOnRowClickCompras}
              controls={controls()}
              mobileColIndex={0}
              disabled={!mutable}
            /> */}
          </Card>
        )}
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => {
            setVisibleAlert(false);
            setSelectedRowKeys([]);
          }}
          visible={visibleAlert}
          content={`${form.getFieldValue('descripcion')}`}
          loading={loading}
        />
      </Spin>
    </Row>
  );
}

export default Requisiciones;
