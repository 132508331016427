import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  Col,
  Form,
  Modal,
  Row,
  Spin,
  Input,
  DatePicker,
  Card,
  message,
} from 'antd';

import Table from 'components/Table';
import ModalDelete from 'components/ModalDelete';
import FormSubmitControls from 'components/FormSubmitControls';
import Select from 'components/Select';
import { onError, onSuccess } from 'utils/handlers';
import API from 'utils/api';
import { isMutable } from 'utils/estadosGlobales';
import getFormattedValues, { formatReceived } from 'utils/formatValues';
import MetasDeObejtivos from 'components/Catalogos/MetasDeObjetivos';

export const permissionObjetivoDeDesarrolloSostenible = {
  permissionModel: 'objetivodedesarrollosostenible',
};

const baseURI = 'catalogos/objetivo-de-desarrollo-sustentable/';
const baseURIs = [
  'catalogos/objetivo-de-desarrollo-sustentable/',
  '/catalogos/meta-de-objetivo-de-desarrollo-sustentable/',
];

// eslint-disable-next-line react/prop-types
function ObjetivosDeDesarrolloSustentable({ permission }) {
  const { estadosGlobales } = useSelector(({ catalogs }) => catalogs);
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mutable, setMutable] = useState(true);
  const [isMeta, setIsMeta] = useState(false);
  const [current, setCurrent] = useState(null);

  const normalizeData = (_data) => _data.map((item) => {
    const clone = { ...item };
    const children = normalizeData(clone?.children || []);
    if (!children?.length) {
      delete clone.children;
      return {
        ...clone,
        key: `${clone.nivel}_${clone?.id || ''}`,
      };
    }
    return {
      ...clone,
      children,
      key: `${clone.nivel}_${clone?.id || ''}`,
    };
  });

  const getIdAndLevel = () => selectedRowKeys[0].split('_').map((e) => parseInt(e, 10));

  const fetchData = async (fromCallback = false) => {
    try {
      setLoading(true);
      const promises = baseURIs.map((uri) => API.get(uri));
      const [_objetivos, _metas] = (await Promise.all(promises))
        .map((res) => formatReceived(res.data));
      const formattedObjetivos = _objetivos.map((objetivo) => ({
        ...objetivo,
        nivel: 1,
        children: objetivo.metas_de_desarrollo_sostenible
          .map((m) => ({
            ..._metas.find((e) => e.id === m),
            nivel: 2,
          })),
      }));
      setData(normalizeData(formattedObjetivos));
      if (fromCallback) {
        const match = normalizeData(formattedObjetivos).find((e) => e.key === current.key);
        setCurrent(match);
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useState(() => {
    fetchData();
    // fetchData();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setVisible(false);
    setSelectedRowKeys([]);
    form.resetFields();
    setMutable(true);
    setIsMeta(false);
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      const [periodo_inicial, periodo_final] = values.periodos;
      const formattedValues = getFormattedValues({
        ...values,
        periodo_inicial,
        periodo_final,
      });
      if (!selectedRowKeys.length) {
        const response = await API.post(baseURI, formattedValues);
        if (response?.status === 201) {
          onSuccess(response, 'Agregado correctamente');
          onCancel();
          await fetchData();
        }
      } else {
        const [key] = selectedRowKeys;
        const response = await API.put(`${baseURI}${key}/`, formattedValues);
        if (response?.status === 200) {
          onSuccess(response, 'Actualizado correctamente');
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const rules = {
    required: [
      {
        required: mutable,
        message: 'El campo es requerido',
      },
    ],
  };

  const columns = [
    {
      titleText: 'Nombre',
      dataIndex: 'nombre',
      key: 'nombre',
      width: 200,
    },
    {
      titleText: 'No. Meta',
      dataIndex: 'numero',
      key: 'numero',
      width: 100,
    },
    {
      titleText: 'Descripción',
      dataIndex: 'descripcion',
      key: 'descripcion',
      width: 500,
    },
    {
      titleText: 'Periodo Inicial',
      dataIndex: 'periodo_inicial',
      key: 'periodo_inicial',
      width: 200,
      render: (val) => val?.format('YYYY'),
    },
    {
      titleText: 'Periodo Final',
      dataIndex: 'periodo_final',
      key: 'periodo_final',
      width: 200,
      render: (val) => val?.format('YYYY'),
    },
    {
      titleText: 'Estado',
      dataIndex: 'estados_globales',
      key: 'estados_globales',
      width: 200,
      render: (val) => estadosGlobales.find((t) => t.id === val)?.descripcion,
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.key]);
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const onClickEdit = () => {
    const [key] = selectedRowKeys;
    const match = data.find((e) => e.key === key);
    const nivel = parseInt(key.split('_')[0], 10);
    if (nivel !== 2) {
      setVisible(true);
      setTimeout(() => {
        form.setFieldsValue({
          ...match,
          periodos: [
            match.periodo_inicial,
            match.periodo_final,
          ],
        });
      });
      setCurrent(match);
      setIsMeta(nivel === 2);
      setMutable(isMutable(match));
    } else {
      message.warn('Se debe editar el Objetivo para editar la meta');
    }
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      const [id] = getIdAndLevel();
      // const [id,level] = getIdAndLevel();
      if (selectedRowKeys.length) {
        // const response = await API.delete(`${baseURIs[level - 1]}${id}/`);
        const response = await API.delete(`${baseURI}${id}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          setVisibleAlert(false);
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  return (
    <Row>
      <Spin tip="Cargando..." spinning={loading}>
        {(
          (!visible && !selectedRowKeys.length)
          || (!visible && selectedRowKeys.length)
          || (visible && !selectedRowKeys.length)) && (
          <Table
            allowImport
            baseURI={baseURI}
            permission={permission}
            mobileCols={[columns[0]]}
            cols={columns}
            data={data}
            rowSelection={rowSelection}
            handleOnRowClick={handleOnRowClick}
            controls={{
              onClickAdd,
              onClickEdit,
              onClickDelete,
            }}
            filterNested
            allowExport={false}
            rowKey="key"
          />
        )}
        {(visible && !!selectedRowKeys.length) && (
        <Card
          className="form-container"
          title="Editar Objetivo"
          extra={(
            <FormSubmitControls
              onCancel={onCancel}
              loading={loading}
            />
             )}
        >
          <MetasDeObejtivos
            parent={current}
            callback={fetchData}
          />
        </Card>
        )}
      </Spin>
      <Modal
        visible={visible && !selectedRowKeys.length}
        title={(
          <FormSubmitControls
            label={`${selectedRowKeys.length ? 'Editar Objetivo' : 'Agregar Objetivo'}`}
            onFinish={onFinish}
            onCancel={onCancel}
          />
           )}
        onCancel={onCancel}
        footer={null}
        closable={false}
        forceRender
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          scrollToFirstError
          initialValues={{ estados_globales: 1 }}
          className={!mutable && 'without-feedback'}
        >
          <Row>
            <Col span={24}>
              <Form.Item
                label="Nombre"
                rules={rules.required}
                name="nombre"
                hasFeedback={mutable}
              >
                <Input allowClear disabled={!mutable} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Descripción"
                rules={rules.required}
                name="descripcion"
                hasFeedback={mutable}
              >
                <Input.TextArea
                  autoSize={{ minRows: 3, maxRows: 3 }}
                  allowClear
                  disabled={!mutable}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                rules={rules.required}
                label="Periodo de Duración"
                name="periodos"
                hasFeedback={mutable}
              >
                <DatePicker.RangePicker
                  format="YYYY"
                  disabled={!mutable}
                  placeholder=""
                  picker="year"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Estado"
                rules={rules.required}
                name="estados_globales"
                hasFeedback={mutable}
              >
                <Select
                  dataSource={estadosGlobales}
                  disabled={!selectedRowKeys.length || !mutable}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item hidden>
            <Button htmlType="submit" />
          </Form.Item>
        </Form>
      </Modal>

      <ModalDelete
        onDelete={deleteItem}
        onCancel={() => setVisibleAlert(false)}
        visible={visibleAlert}
        content={`${isMeta ? 'la meta' : 'el objetivo'} ${form
          .getFieldValue(isMeta ? 'descripcion' : 'nombre')}`}
      />
    </Row>
  );
}

export default ObjetivosDeDesarrolloSustentable;
