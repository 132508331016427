/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Form,
  Row,
  Spin,
  Tabs,
  Col,
  Input,
  DatePicker,
  Card,
  Button,
  InputNumber,
} from 'antd';

import API from 'utils/api';
import {
  onError,
  onInfo,
  onSuccess,
  onWarning,
} from 'utils/handlers';
import {
  FileExcelOutlined,
  FilePdfOutlined,
  CheckCircleOutlined,
  ArrowLeftOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import currencyFormatter from 'utils/currencyFormatter';
import Table from 'components/Table';
import Select from 'components/Select';
import moment from 'moment';
import Rutas from '../../utils/rutas-endpoints';

const { TabPane } = Tabs;
const arrayReinversionAutomatica = [{ id: 1, concepto: 'Si' }, { id: 2, concepto: 'No' }];

function InversionesBancarias() {
  const [form] = Form.useForm();
  const [formRetiro] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [currentTabKey, setCurrentTabKey] = useState('inversion'); // Para Definir que Tab Esta Activa
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [mutable, setMutable] = useState(true);
  const [dataUsuario, setDataUsuario] = useState({});
  const [dataGridInversionesBancarias, setDataGridInversionesBancarias] = useState([]);
  const [catalogoDataCuentasBancarias, setCatalogoDataCuentasBancarias] = useState([]);
  const [catalogoDataCuentaInversion, setCatalogoDataCuentaInversion] = useState([]);
  const [catalogoDataTipoInversion, setCatalogoDataTipoInversion] = useState([]);
  const [catalogoDataPlazo, setCatalogoDataPlazo] = useState([]);
  const [catalogoDataCri, setCatalogoDataCri] = useState([]);
  const [waiting, setWaiting] = useState(false);
  const [disabledTabInversion, setDisabledTabInversion] = useState(false);
  const [disabledTabRetiro, setDisabledTabRetiro] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [onAdd, setOnAdd] = useState(false);
  const [onEdit, setOnEdit] = useState(false);
  const [dataInversionesBancarias, setDataInversionesBancarias] = useState([]);

  const periodoFiscalSelected = useSelector(({ auth }) => {
    const value = form.getFieldValue('fecha');
    if (value
      && !value.isBetween(moment(auth.periodoFiscalSelected.fecha_inicial),
        moment(auth.periodoFiscalSelected.fecha_final))) {
      form.setFieldsValue({
        fecha: null,
      });
      form.validateFields();
    }

    const valueRetiro = formRetiro.getFieldValue('fecha');
    if (valueRetiro
      && !valueRetiro.isBetween(moment(auth.periodoFiscalSelected.fecha_inicial),
        moment(auth.periodoFiscalSelected.fecha_final))) {
      formRetiro.setFieldsValue({
        fecha: null,
      });
      formRetiro.validateFields();
    }
    return auth.periodoFiscalSelected;
  });

  // METODOS

  const basicKeys = [
    'Inversión',
    'Retiro',
  ];
  const requiredRule = {
    required: true,
    message: 'El campo es requerido',
  };
  const rules = {
    required: [requiredRule],
  };

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    formRetiro.resetFields();
    setSelectedRowKeys([]);
    setMutable(true);
    setWaiting(false);
    setOnAdd(false);
    setOnEdit(false);
    setIsUpdated(false);
    setDisabledTabInversion(false);
    setDisabledTabRetiro(false);
    setCurrentTabKey('inversion');
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      setCatalogoDataCuentasBancarias([]);
      setCatalogoDataTipoInversion([]);
      setCatalogoDataCuentaInversion([]);
      setCatalogoDataPlazo([]);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const consultarUsuario = async () => {
    const resUsuario = await API.get('usuarios/id/');
    const usuario = await resUsuario.data;

    form.setFieldsValue({
      usuario_que_elaboro: `${usuario.id} - ${usuario.first_name} ${usuario.last_name}`,
      puesto_elaboro: usuario.puesto_usuario,
      usuario_area: usuario.usuario_area,
    });

    formRetiro.setFieldsValue({
      usuario_que_elaboro: `${usuario.id} - ${usuario.first_name} ${usuario.last_name}`,
      puesto_elaboro: usuario.puesto_usuario,
      usuario_area: usuario.usuario_area,
    });

    setDataUsuario(usuario);
  };

  const getDataGridFormat = async () => {
    setLoading(true);
    const restApi = await API.get(Rutas.inversionesYRetiros);
    const dataGrid = await restApi.data;
    const dataGridInversiones = [];

    dataGrid.forEach((item, index) => {
      const nombreCompleto = `${item.elaboro.id} - ${item.elaboro.first_name} ${item.elaboro.last_name}`;
      // eslint-disable-next-line no-nested-ternary
      const reinversion_automatica = item.tipo_de_movimiento === 'Inversión' ? (item.reinversión_automatica === 'False' ? 'No' : 'Sí') : '';
      dataGridInversiones[index] = {
        id: index,
        id_entidad: item.id,
        fecha: item.fecha,
        numero_de_inversion: item.numero_folio,
        folio_agrupador: item.folio_agrupador,
        cuenta_de_inversiones: `${item.cuenta_de_inversiones.banco} ${item.cuenta_de_inversiones.banco_nombre} ${item.cuenta_de_inversiones.numero_de_cuenta}`,
        movimiento: item.tipo_de_movimiento,
        tipo_de_inversion: item.tipo_de_inversion,
        plazo: item.plazo_de_inversion,
        fecha_de_vencimiento: item.fecha_de_vencimiento,
        tasa_de_intereses: item.tasa_de_intereses,
        reinversion_automatica,
        importe: item.importe,
        intereses: item.interes,
        clave_presupuestal_intereses: item.clave_presupuestal_de_intereses,
        concepto_de_ingreso: item.concepto_de_ingreso,
        saldo_de_inversion: item.saldo_inversion,
        elaboro: nombreCompleto,
        puesto: item.elaboro.puesto_usuario,
        area: item.elaboro.usuario_area,
        poliza: item.poliza, // Pendiente de validar con Lupita que campos tiene el objeto poliza
        movimiento_presupuestal: item.movimiento_presupuestal,
        status: item.estado,
        observaciones: item.observaciones,
      };
    });
    setDataGridInversionesBancarias(dataGridInversiones);
    setLoading(false);
  };

  const consultarDataCatalogoCuentasBancarias = async () => {
    const restApi = await API.get(Rutas.cuentasBancarias);
    const cuentas = await restApi.data;

    setCatalogoDataCuentasBancarias(cuentas);
  };

  const consultarDataCatalogoTipoInversion = async () => {
    const resApi = await API.get(Rutas.catalogoTiposInversion);
    const dataApi = await resApi.data;
    const tipoInversion = dataApi.filter((item) => item.estado === 'Activo');

    setCatalogoDataTipoInversion(tipoInversion);
  };

  const consultarDataCatalogoPlazo = async () => {
    const resApi = await API.get(Rutas.catalogoPlazosInversion);
    const dataApi = await resApi.data;
    const plazos = dataApi.filter((item) => item.estado === 'Activo');

    setCatalogoDataPlazo(plazos);
  };

  const consultarDataCatalogoRetirosCri = async () => {
    const resApi = await API.get(Rutas.retirosCRI);
    const dataApi = await resApi.data;

    setCatalogoDataCri(dataApi);
  };

  const consultarDataInversionesBancarias = async () => {
    const restApi = await API.get(Rutas.inversionesBancarias);
    const inversionesBancarias = restApi.data;

    const inversionesBancariasFiltered = [];

    inversionesBancarias.forEach((e) => {
      if (e?.saldo > 0
        && moment(e?.fecha_de_vencimiento).isSameOrAfter(moment(), 'day')
        && e?.estados_globales === 4) {
        inversionesBancariasFiltered.push(e);
      }
    });

    setDataInversionesBancarias(inversionesBancariasFiltered);
  };

  const getCuentaInversion = async (idInversion) => {
    const url = '/configuraciones/cuentas-de-inversion-y-tarjetas-de-pago';
    const resServer = await API.get(url, {
      params: {
        es_cuenta_de_inversion: true,
        cuenta_bancaria: idInversion,
      },
    });
    const cuentaInversion = resServer.data;

    setCatalogoDataCuentaInversion(cuentaInversion);
  };

  const getDescripcionCuentaInversion = async (idCuentaInversion) => {
    const cuenta = catalogoDataCuentaInversion.find((item) => item.id === idCuentaInversion);
    form.setFieldsValue({
      concepto_de_inversion: cuenta?.cuenta_contable?.nombre,
    });
  };

  const getDataInversionBancaria = (data) => {
    const fechaFormated = moment(data.fecha);
    const fechaVencimientoFormated = moment(data.fecha_de_vencimiento);
    const tasa_interes = `${Number(data.tasa_de_intereses).toFixed(2)}%`;
    const reinversionAutomatica = data.reinversión_automatica === false ? 'No' : 'Sí';
    getCuentaInversion(data.inversion);

    let inversionBancaria = {};
    inversionBancaria = {
      fecha: fechaFormated,
      numero_de_inversion: data.numero_de_inversion,
      folio_agrupador: data.folio_agrupador,
      cuenta_de_retiro: data.data_cuenta_retiro.id,
      cuenta_de_retiro_desc: `${data.data_cuenta_retiro.banco_nombre} - ${data.data_cuenta_retiro.clabe_interbancaria}`,
      inversion: data.inversion,
      concepto_de_inversion: data.concepto_de_inversion,
      tipo_de_inversion: data.tipo_de_inversion,
      tipo_de_inversion_desc: data.data_tipo_inversion.concepto,
      plazo_de_inversion: data.data_plazo_inversion.id,
      plazo_desc: data.data_plazo_inversion.concepto,
      fecha_de_vencimiento: fechaVencimientoFormated,
      tasa_de_intereses: tasa_interes,
      reinversion_automatica: reinversionAutomatica,
      importe_de_inversion: data.importe_de_inversion,
      usuario_que_elaboro: data.datos_de_quien_elaboro,
      usuario_que_elaboro_id: data.usuario_que_elaboro,
      puesto_elaboro: data.data_usuario.puesto_usuario,
      usuario_area: data.data_usuario.usuario_area,
      poliza: data.poliza,
      estado: data.estado_descripcion,
      observaciones: data.observaciones,
    };

    return inversionBancaria;
  };

  const consultarInversionBancaria = async (idInversion) => {
    setLoading(true);
    const dataRest = await API.get(`${Rutas.inversionesBancarias}${idInversion}/`);
    const dataInversion = await dataRest.data;
    const inversionBancariaAjustada = getDataInversionBancaria(dataInversion);

    form.setFieldsValue(inversionBancariaAjustada);
    setLoading(false);
  };

  const getDataRetiro = (data) => {
    const fechaFormated = moment(data.fecha);
    // const fechaVencimientoFormated = moment(data.fecha_de_vencimiento);

    let retiro = {};
    retiro = {
      fecha: fechaFormated,
      numero_inversion: data.numero_de_retiro,
      folio_agrupador: data.data_inversion.folio_agrupador,
      tipo_movimiento: 'Retiro',
      inversion: data.inversion,
      saldo_inversion: data.data_inversion.saldo,
      concepto_de_inversion: data.data_inversion.concepto_de_inversion,
      cuenta_deposito: data.data_inversion.cuenta_de_retiro,
      importe_de_retiro: data.importe_de_retiro,
      importe_de_intereses: data.importe_de_intereses,
      importe_total: data.importe_total,
      concepto_de_intereses: data.concepto_de_intereses,
      clave_presupuestal_de_intereses: data.clave_presupuestal_de_intereses,
      usuario_que_elaboro: data.datos_de_quien_elaboro,
      usuario_que_elaboro_id: data.usuario_que_elaboro,
      puesto_elaboro: data.data_usuario.puesto_usuario,
      usuario_area: data.data_usuario.usuario_area,
      poliza: data.poliza,
      estado: data.estado_descripcion,
      observaciones: data.observaciones,
      cuenta_de_inversion: data.cuenta_de_inversion,
    };
    return retiro;
  };

  const consultarInformacionRetiro = async (idRetiro) => {
    setLoading(true);
    const dataRest = await API.get(`${Rutas.retiroInversiones}${idRetiro}/`);
    const dataRetiro = await dataRest.data;
    const retiro = getDataRetiro(dataRetiro);

    formRetiro.setFieldsValue(retiro);
    setLoading(false);
  };

  const getClavePresupuestal = (idClave) => {
    const dataRest = catalogoDataCri.find((item) => item.id === idClave);
    formRetiro.setFieldsValue({
      clave_presupuestal_de_intereses: dataRest.clave_presupuestal_interes,
    });
  };

  // EFECTOS
  useEffect(() => {
    fetchData();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getDataGridFormat();
  }, [dataGridInversionesBancarias.length]);

  useEffect(() => {
    const [key] = selectedRowKeys;
    const rowSelected = dataGridInversionesBancarias.find((item) => item.id === key);
    if (waiting) {
      if (rowSelected.movimiento === 'Inversión') {
        consultarInversionBancaria(rowSelected.id_entidad);
      } else if (rowSelected.movimiento === 'Retiro') {
        consultarInformacionRetiro(rowSelected.id_entidad);
      } else {
        onWarning('No ha seleccionado un registro valido.');
      }
    }
    // eslint-disable-next-line
  }, [waiting]);

  useEffect(() => {
    if (isUpdated) {
      getDataGridFormat();
    }
  }, [isUpdated]);

  useEffect(() => {
    if (onAdd || onEdit) {
      consultarDataCatalogoCuentasBancarias();
      consultarDataCatalogoPlazo();
      consultarDataCatalogoRetirosCri();
      consultarDataCatalogoTipoInversion();
      consultarDataInversionesBancarias();
    }
  }, [onAdd, onEdit]);

  // EVENTOS
  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    if (record.movimiento === 'Inversión') {
      setCurrentTabKey('inversion');
    } else if (record.movimiento === 'Retiro') {
      setCurrentTabKey('retiro');
    }
    if (record.status === 'Activo') {
      setOnEdit(true);
    }
  };

  const onSelectCuentaRetiro = (e) => {
    form.setFieldsValue({
      inversion: null,
      concepto_de_inversion: null,
    });
    if (e) {
      onChangeCaptureMovimiento(e);
      getCuentaInversion(e);
    } else {
      setCatalogoDataCuentaInversion([]);
    }
  };

  const disabledDate = (current) => {
    const customDate = moment();
    return current && current < moment(customDate, 'DD/MM/YYYY');
  };

  const onHandleSelect = (e) => {
    getDescripcionCuentaInversion(e);
  };

  const handleOnCogChange = (e) => {
    getClavePresupuestal(e);
  };

  const onActivar = () => {
    setOnAdd(true);
  };

  const calculaTotal = () => {
    const importe = formRetiro.getFieldValue('importe_de_retiro');
    const intereses = formRetiro.getFieldValue('importe_de_intereses');
    let suma = 0.00;

    // eslint-disable-next-line
    if ((Number.isNaN(importe) || importe !== undefined)) {
      suma = importe + 0.00;
      formRetiro.setFieldsValue({
        importe_total: suma,
      });
      if (Number.isNaN(intereses) || intereses !== undefined) {
        suma = importe + intereses;
        formRetiro.setFieldsValue({
          importe_total: suma,
        });
      }
    }
  };

  const disabledCurrentDate = (current) => {
    // eslint-disable-next-line
    const val = moment(current).isBetween(periodoFiscalSelected.fecha_inicial, periodoFiscalSelected.fecha_final);
    return !val;
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onFinish = async () => {
  };

  const onChangeTabKey = () => {};

  const onClickAdd = () => {
    const fechaSis = moment();
    form.setFieldsValue({
      fecha: fechaSis,
    });
    formRetiro.setFieldsValue({
      fecha: fechaSis,
    });
    setSelectedRowKeys([]);
    consultarUsuario();
    setOnAdd(true);
    setVisible(true);
  };

  const onClickEdit = () => {
    setWaiting(true);
    setVisible(true);
  };

  const onClickDelete = async () => {
    try {
      if (selectedRowKeys.length > 0) {
        const [key] = selectedRowKeys;
        const dataToDelete = dataGridInversionesBancarias.find((item) => item.id === key);

        if (dataToDelete.movimiento === 'Inversión') {
          if (dataToDelete.status === 'Autorizado') {
            onWarning('Solo se puede eliminar registros con estado activo.');
          } else {
            const response = await API.delete(`${Rutas.inversionesBancarias}${dataToDelete.id_entidad}`);
            if (response?.status === 204) {
              onSuccess(response, 'Registro de inversión eliminado correctamente.');
              onCancel();
              getDataGridFormat();
            }
          }
        } else if (dataToDelete.movimiento === 'Retiro') {
          if (dataToDelete.status === 'Autorizado') {
            onWarning('Solo se puede eliminar registros con estado activo.');
          } else {
            const response = await API.delete(`${Rutas.retiroInversiones}${dataToDelete.id_entidad}`);
            if (response?.status === 204) {
              onSuccess(response, 'Registro de retiro eliminado correctamente.');
              onCancel();
              getDataGridFormat();
            }
          }
        }
      } else {
        onInfo('No hay un registro seleccionado.');
      }
    } catch (error) {
      onError(error, setLoading);
    }
  };

  const onChangeCaptureMovimiento = () => {
    if (currentTabKey === 'retiro') {
      setDisabledTabInversion(true);
    }
    if (currentTabKey === 'inversion') {
      setDisabledTabRetiro(true);
    }
  };

  const onAutorizar = async () => {
    const [key] = selectedRowKeys;
    const array = [];
    const obj = {
      id: key,
    };

    const rowSelected = dataGridInversionesBancarias.find((item) => item.id === key);

    if (rowSelected.movimiento === 'Inversión') {
      if (rowSelected.status === 'Autorizado') {
        onInfo('Acción no realizada, solo se pueden autorizar registros con estado activo.');
      } else {
        obj.id = rowSelected.id_entidad;
        array.push(obj);
        const resApi = await API.patch(`${Rutas.inversionesBancarias}autorizar/`, array);
        if (resApi.status === 200) {
          onSuccess(resApi.data.message, 'Autorizado correctamente');
          onCancel();
          setIsUpdated(true);
          getDataGridFormat();
        }
      }
    } else if (rowSelected.movimiento === 'Retiro') {
      if (rowSelected.status === 'Autorizado') {
        onInfo('Acción no realizada, solo se pueden autorizar registros con estado activo.');
      } else {
        obj.id = rowSelected.id_entidad;
        array.push(obj);
        const resApi = API.patch(`${Rutas.retiroInversiones}autorizar/`, array);
        onSuccess(resApi, 'Autorizado correctamente');
        onCancel();
        setIsUpdated(true);
        getDataGridFormat();
      }
    } else {
      onInfo('No hay un registro seleccionado.', rowSelected);
    }
  };

  const onDownloadPdf = () => { };

  const onDownLoadExcel = () => { };

  const complexForms = [{ key: 'inversion' }, { key: 'retiro' }];
  const handleOnFinish = async () => {
    const match = complexForms.find((e) => e.key === currentTabKey);
    if (match) {
      try {
        setLoading(true);

        await form.validateFields();
        formRetiro.resetFields();

        const values = form.getFieldsValue();
        const { fecha, fecha_de_vencimiento } = values;

        if ((fecha !== null || fecha !== '') || (fecha_de_vencimiento !== null || fecha_de_vencimiento !== '')) {
          const date = moment(fecha).format('YYYY-MM-DD');
          const endDate = moment(fecha_de_vencimiento).format('YYYY-MM-DD');
          values.fecha = date;
          values.fecha_de_vencimiento = endDate;
        }

        values.tasa_de_intereses = Number(values.tasa_de_intereses.replace('%', ''));
        if (currentTabKey === 'inversion') {
          values.datos_de_quien_elaboro = values.usuario_que_elaboro;
          values.usuario_que_elaboro = dataUsuario.id;

          if (selectedRowKeys.length === 0) {
            const response = await API.post(Rutas.inversionesBancarias, values);
            if (response?.status === 201) {
              onSuccess(response, 'Agregado correctamente.');
              onCancel();
              getDataGridFormat();
            }
          } else {
            const [key] = selectedRowKeys;
            const registro = dataGridInversionesBancarias.find((item) => item.id === key);
            const response = await API.patch(`${Rutas.inversionesBancarias}${registro.id_entidad}/`, values);
            if (response?.status === 200) {
              onSuccess(response, 'Actualizado correctamente.');
              form.resetFields();
              onCancel();
              getDataGridFormat();
            }
          }
        }
        setOnEdit(false);
        setOnAdd(false);
        setLoading(false);
      } catch (error) {
        onError(error, setLoading);
      }
    } if (match.key === 'retiro') {
      try {
        setLoading(true);
        await formRetiro.validateFields();
        form.resetFields();

        const values = formRetiro.getFieldsValue();
        const { fecha, fecha_de_vencimiento } = values;

        if ((fecha !== null || fecha !== '') || (fecha_de_vencimiento !== null || fecha_de_vencimiento !== '')) {
          const date = moment(fecha).format('YYYY-MM-DD');
          const endDate = moment(fecha_de_vencimiento).format('YYYY-MM-DD');
          values.fecha = date;
          values.fecha_de_vencimiento = endDate;
        }

        values.datos_de_quien_elaboro = values.usuario_que_elaboro;
        values.usuario_que_elaboro = dataUsuario.id;

        if (currentTabKey === 'retiro') {
          if (selectedRowKeys.length === 0) {
            if (values.importe_de_retiro <= values.saldo_inversion) {
              const response = await API.post(Rutas.retiroInversiones, values);
              if (response?.status === 201) {
                onSuccess(response, 'Agregado correctamente.');
                onCancel();
                getDataGridFormat();
              }
            } else {
              onWarning('El importe de retiro, no puede ser mayor al saldo de la inversión');
              setTimeout(() => {
                onInfo('Por favor, Ingrese un Importe de Retiro Valido');
              }, 3500);
            }
          } else {
            const [key] = selectedRowKeys;
            const registro = dataGridInversionesBancarias.find((item) => item.id === key);
            const response = await API.patch(`${Rutas.retiroInversiones}${registro.id_entidad}/`, values);
            if (response?.status === 200) {
              onSuccess(response, 'Actualizado correctamente.');
              form.resetFields();
              onCancel();
              getDataGridFormat();
            }
          }
        }
        setOnEdit(false);
        setOnAdd(false);
        setLoading(false);
      } catch (error) {
        onError(error, setLoading);
      }
    }
  };

  const onChangeCuentaInversiones = (e) => {
    formRetiro.setFieldsValue({
      concepto_de_inversion: null,
      cuenta_deposito: null,
      folio_agrupador: null,
      saldo_inversion: null,
    });

    if (e) {
      onChangeCaptureMovimiento();
      const cuentaInversiones = dataInversionesBancarias.find((f) => f.id === e);
      formRetiro.setFieldsValue({
        concepto_de_inversion: cuentaInversiones.concepto_de_inversion,
        cuenta_deposito: cuentaInversiones.data_inversion.id,
        folio_agrupador: cuentaInversiones.folio_agrupador,
        saldo_inversion: cuentaInversiones.saldo,
      });
    }
  };

  const onChangeImporteRetiro = (e) => {
    const value = parseInt(e, 10);
    const inversionId = formRetiro.getFieldValue('inversion');
    const inversion = dataInversionesBancarias.find((f) => f.id === inversionId);

    if (inversion.saldo >= value) {
      setOnAdd(true);
    } else {
      formRetiro.setFieldsValue({ importe_retiro: null });
      onInfo('El importe de retiro no puede ser mayor al saldo de la inversión.');
    }
  };

  const onSetFormatProcent = () => {
    const value = `${Number(form.getFieldValue('tasa_de_intereses')).toFixed(2)}%`;
    form.setFieldsValue({ tasa_de_intereses: value });
  };

  const columns = [
    {
      titleText: 'Fecha',
      dataIndex: 'fecha',
      key: 'fecha',
      width: 200,
    },
    {
      titleText: 'No. de Inversión',
      dataIndex: 'numero_de_inversion',
      key: 'numero_de_inversion',
      width: 220,
    },
    {
      titleText: 'Folio Agrupador',
      dataIndex: 'folio_agrupador',
      key: 'folio_agrupador',
      width: 200,
    },
    {
      titleText: 'Cuenta de Inversiones',
      dataIndex: 'cuenta_de_inversiones',
      key: 'cuenta_de_inversiones',
      width: 200,
    },
    {
      titleText: 'Movimiento',
      dataIndex: 'movimiento',
      key: 'movimiento',
      width: 200,
    },
    {
      titleText: 'Tipo de Inversión',
      dataIndex: 'tipo_de_inversion',
      key: 'tipo_de_inversion',
      width: 200,
    },
    {
      titleText: 'Plazo',
      dataIndex: 'plazo',
      key: 'plazo',
      width: 200,
    },
    {
      titleText: 'Fecha de Vencimiento',
      dataIndex: 'fecha_de_vencimiento',
      key: 'fecha_de_vencimiento',
      width: 200,
    },
    {
      titleText: 'Tasa de Intereses',
      dataIndex: 'tasa_de_intereses',
      key: 'tasa_de_intereses',
      width: 200,
      render: (val) => ((val < 0 || val === '' || val === null) ? '-' : `${(val / 1).toFixed(2)}%`),
    },
    {
      titleText: 'Reinversión Automática',
      dataIndex: 'reinversion_automatica',
      key: 'reinversion_automatica',
      width: 200,
    },
    {
      titleText: 'Importe',
      dataIndex: 'importe',
      key: 'importe',
      width: 200,
      render: (val) => (val > 0 ? currencyFormatter(val || 0) : currencyFormatter(val || 0)),
    },
    {
      titleText: 'Intereses',
      dataIndex: 'tasa_de_intereses',
      key: 'tasa_de_intereses',
      width: 200,
      render: (val) => (val > 0 ? currencyFormatter(val || 0) : currencyFormatter(val || 0)),
    },
    {
      titleText: 'Clave Presupuestal de Intereses',
      dataIndex: 'clave_presupuestal_intereses',
      key: 'clave_presupuestal_intereses',
      width: 250,
    },
    {
      titleText: 'Concepto de Ingreso',
      dataIndex: 'concepto_de_ingreso',
      key: 'concepto_de_ingreso',
      width: 200,
    },
    {
      titleText: 'Saldo de Inversión',
      dataIndex: 'saldo_de_inversion',
      key: 'saldo_de_inversion',
      width: 200,
      render: (val) => (val > 0 ? currencyFormatter(val || 0) : '-'),
    },
    {
      titleText: 'Elaboró',
      dataIndex: 'elaboro',
      key: 'elaboro',
      width: 200,
    },
    {
      titleText: 'Puesto',
      dataIndex: 'puesto',
      key: 'puesto',
      width: 200,
    },
    {
      titleText: 'Área',
      dataIndex: 'area',
      key: 'area',
      width: 200,
    },
    {
      titleText: 'Póliza',
      dataIndex: 'poliza',
      key: 'poliza',
      width: 700,
    },
    {
      titleText: 'Movimiento Presupuestal',
      dataIndex: 'movimiento_presupuestal',
      key: 'movimiento_presupuestal',
      width: 200,
    },
    {
      titleText: 'Estado',
      dataIndex: 'status',
      key: 'status',
      width: 200,
    },
    {
      titleText: 'Observaciones',
      dataIndex: 'observaciones',
      key: 'observaciones',
      width: 200,
    },
  ];
  const initialValuesRetiro = {
    estado: 'Activo',
    tipo_movimiento: 'Retiro',
    importe_de_intereses: 0,
  };
  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible ? (
          <Table
            cols={columns}
            data={dataGridInversionesBancarias}
            rowSelection={rowSelection}
            handleOnRowClick={handleOnRowClick}
            mobileColIndex={0}
            controls={{
              onClickAdd,
              onClickEdit,
              onClickDelete,
            }}
            customActions={[
              {
                onClick: onAutorizar,
                text: 'Autorizar',
                icon: <CheckCircleOutlined />,
                disabledBySelection: true,
              },
              {
                onClick: onDownloadPdf,
                icon: <FilePdfOutlined />,
                text: 'Descargar PDF',
                disabledBySelection: false,
              },
              {
                onClick: onDownLoadExcel,
                icon: <FileExcelOutlined />,
                text: 'Descargar Excel',
                disabledBySelection: false,
              },
            ]}
          />
        ) : (
          <Card
            className="form-container"
            // eslint-disable-next-line
            title={currentTabKey === 'inversion' ? (selectedRowKeys.length > 0 ? 'Editar Inversión' : 'Agregar Inversión') : (selectedRowKeys.length > 0 ? 'Editar Retiro' : 'Agregar Retiro')}
            extra={(
              <>

                {(onAdd || onEdit) && (
                  <Button
                    icon={<SaveOutlined />}
                    className="ant-btn ant-btn-link"
                    onClick={basicKeys
                      .concat(complexForms.map((e) => e.key))
                      .includes(currentTabKey)
                      ? handleOnFinish : null}
                  >
                    Guardar
                  </Button>
                )}

                {(waiting && onEdit) && (
                  <Button
                    icon={<CheckCircleOutlined />}
                    className="ant-btn ant-btn-link"
                    onClick={onAutorizar}
                  >
                    Autorizar
                  </Button>
                )}

                <Button
                  icon={<ArrowLeftOutlined />}
                  className="ant-btn ant-btn-link"
                  onClick={onCancel}
                >
                  Regresar
                </Button>
              </>
            )}
            bordered={false}
          >
            <Tabs
              activeKey={currentTabKey}
              onChange={mutable ? setCurrentTabKey : onChangeTabKey}
              onTabClick={onChangeCaptureMovimiento}
            >
              <TabPane tab="Inversión" key="inversion" disabled={(currentTabKey === 'retiro' && waiting) || disabledTabInversion} forceRender>
                <Form
                  form={form}
                  layout="vertical"
                  name="inversion"
                  onFinish={onFinish}
                  labelAlign="left"
                  labelWrap
                  initialValues={{ estado: 'Activo', tipo_movimiento: 'Inversión' }}
                >
                  <Row gutter={10}>
                    <Col xs={12} sm={12} md={8}>
                      <Form.Item
                        name="fecha"
                        label="Fecha"
                        rules={rules.required}
                        hasFeedback
                      >
                        <DatePicker
                          placeholder=""
                          format="DD/MM/YYYY"
                          onChange={onActivar}
                          disabledDate={disabledCurrentDate}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={8}>
                      <Form.Item name="numero_de_inversion" label="Numero de Inversión">
                        <Input
                          disabled
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={8}>
                      <Form.Item name="folio_agrupador" label="Folio Agrupador">
                        <Input
                          disabled
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={8}>
                      <Form.Item name="tipo_movimiento" label="Tipo de Movimiento">
                        <Input
                          disabled
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={8}>
                      <Form.Item name="cuenta_de_retiro" label="Cuenta de Retiro" rules={rules.required}>
                        <Select
                          dataSource={catalogoDataCuentasBancarias}
                          render={(e) => `${e?.banco_nombre} - ${e?.clabe_interbancaria}`}
                          onChange={onSelectCuentaRetiro}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={8}>
                      <Form.Item
                        name="inversion"
                        label="Cuenta de Inversiones"
                        rules={rules.required}
                      >
                        <Select
                          dataSource={catalogoDataCuentaInversion}
                          render={(e) => `${e?.data_cuenta_bancaria.banco_nombre} - ${e?.data_cuenta_bancaria.clabe_interbancaria}`}
                          onChange={onHandleSelect}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={8}>
                      <Form.Item
                        name="concepto_de_inversion"
                        label="Concepto de Inversión"
                      >
                        <Input disabled />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={8}>
                      <Form.Item
                        name="tipo_de_inversion"
                        label="Tipo de Inversión"
                        rules={rules.required}
                      >
                        <Select
                          dataSource={catalogoDataTipoInversion}
                          render={(e) => `${e?.concepto}`}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={8}>
                      <Form.Item
                        name="plazo_de_inversion"
                        label="Plazo"
                        rules={rules.required}
                      >
                        <Select
                          dataSource={catalogoDataPlazo}
                          render={(e) => `${e?.concepto}`}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={8}>
                      <Form.Item
                        name="fecha_de_vencimiento"
                        label="Fecha de Vencimiento"
                        rules={rules.required}
                        hasFeedback
                      >
                        <DatePicker
                          placeholder=""
                          format="DD/MM/YYYY"
                          disabledDate={disabledDate}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={8}>
                      <Form.Item
                        name="tasa_de_intereses"
                        label="Tasa de Intereses"
                        rules={rules.required}
                      >
                        <InputNumber
                          style={{ width: '100%' }}
                          decimalSeparator=","
                          onBlur={onSetFormatProcent}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={8}>
                      <Form.Item
                        name="reinversion_automatica"
                        label="Reinversión Automática"
                        rules={rules.required}
                      >
                        <Select
                          dataSource={arrayReinversionAutomatica}
                          render={(e) => `${e?.concepto}`}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={8}>
                      <Form.Item
                        name="importe_de_inversion"
                        label="Importe de Inversión"
                        rules={rules.required}
                      >
                        <InputNumber
                          formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={8}>
                      <Form.Item name="usuario_que_elaboro" label="Elaboró">
                        <Input allowClear disabled />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={8}>
                      <Form.Item name="puesto_elaboro" label="Puesto">
                        <Input allowClear disabled />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={8}>
                      <Form.Item name="usuario_area" label="Área">
                        <Input allowClear disabled />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={8}>
                      <Form.Item name="poliza" label="Póliza">
                        <Input allowClear disabled />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={8}>
                      <Form.Item name="estado" label="Estado">
                        <Input allowClear disabled />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={8}>
                      <Form.Item name="observaciones" label="Observaciones">
                        <Input.TextArea showCount maxLength={100} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </TabPane>

              <TabPane tab="Retiro" key="retiro" disabled={(currentTabKey === 'inversion' && waiting) || disabledTabRetiro} forceRender>
                <Form
                  form={formRetiro}
                  layout="vertical"
                  name="retiro"
                  onFinish={onFinish}
                  labelAlign="left"
                  labelWrap
                  initialValues={initialValuesRetiro}
                >
                  <Row gutter={10}>
                    <Col xs={12} sm={12} md={8}>
                      <Form.Item
                        name="fecha"
                        label="Fecha"
                        rules={rules.required}
                        hasFeedback
                      >
                        <DatePicker placeholder="" format="DD/MM/YYYY" disabledDate={disabledCurrentDate} onChange={onActivar} />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={8}>
                      <Form.Item name="numero_inversion" label="No. de Retiro">
                        <Input
                          disabled
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={8}>
                      <Form.Item name="folio_agrupador" label="Folio Agrupador">
                        <Input
                          disabled
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={8}>
                      <Form.Item name="tipo_movimiento" label="Tipo Movimiento">
                        <Input
                          disabled
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={8}>
                      <Form.Item
                        name="inversion"
                        label="Cuenta de Inversiones"
                        rules={rules.required}
                      >
                        <Select
                          dataSource={dataInversionesBancarias}
                          render={(e) => `${e?.id} ${e?.data_inversion.banco_nombre} ${e?.data_inversion.numero_de_cuenta}`}
                          onChange={onChangeCuentaInversiones}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={8}>
                      <Form.Item
                        name="saldo_inversion"
                        label="Saldo de la Inversión"
                      >
                        <InputNumber
                          formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                          disabled
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={8}>
                      <Form.Item
                        name="concepto_de_inversion"
                        label="Concepto de Inversión"
                      >
                        <Select allowClear disabled />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={8}>
                      <Form.Item
                        name="cuenta_deposito"
                        label="Cuenta de Depósito"
                      >
                        <Select
                          dataSource={catalogoDataCuentasBancarias}
                          render={(e) => `${e?.banco_nombre} - ${e?.clabe_interbancaria}`}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={8}>
                      <Form.Item
                        name="importe_de_retiro"
                        label="Importe de Retiro"
                        rules={rules.required}
                      >
                        <InputNumber
                          formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                          onChange={onChangeImporteRetiro}
                          onBlur={calculaTotal}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={8}>
                      <Form.Item
                        name="importe_de_intereses"
                        label="Importe de Intereses"
                      >
                        <InputNumber
                          formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                          onChange={onChangeImporteRetiro}
                          onBlur={calculaTotal}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={8}>
                      <Form.Item name="importe_total" label="Total">
                        <InputNumber
                          formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                          disabled
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={8}>
                      <Form.Item name="concepto_de_intereses" label="Concepto de Intereses">
                        <Select
                          dataSource={catalogoDataCri}
                          render={(e) => `${e?.clave_presupuestal_visible}`}
                          onChange={handleOnCogChange}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={8}>
                      <Form.Item name="clave_presupuestal_de_intereses" label="Clave Presupuestal de Intereses">
                        <Input allowClear disabled />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={8}>
                      <Form.Item name="usuario_que_elaboro" label="Elaboró">
                        <Input allowClear disabled />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={8}>
                      <Form.Item name="puesto_elaboro" label="Puesto">
                        <Input allowClear disabled />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={8}>
                      <Form.Item name="usuario_area" label="Área">
                        <Input allowClear disabled />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={8}>
                      <Form.Item name="poliza" label="Póliza">
                        <Input allowClear disabled />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={8}>
                      <Form.Item name="movimiento_presupuestal" label="Movimiento Presupuestal">
                        <Input allowClear disabled />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={8}>
                      <Form.Item name="estado" label="Estado">
                        <Input allowClear disabled />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={8}>
                      <Form.Item name="observaciones" label="Observaciones">
                        <Input.TextArea showCount maxLength={100} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </TabPane>
            </Tabs>
          </Card>
        )}
      </Spin>
    </Row>
  );
}

export default InversionesBancarias;
