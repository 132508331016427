import React from 'react';
import SingleForm from 'components/SingleForm';

function FinalidadCuentaBancaria() {
  return (
    <SingleForm
      baseURI="/configuraciones/finalidad-de-las-cuentas-bancarias/"
      title="Finalidad de Cuenta Bancaria"
    />
  );
}

export default FinalidadCuentaBancaria;
