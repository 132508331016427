import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Input,
  Col,
  Row,
  Spin,
  Form,
  Button,
  Card,
  Typography,
} from 'antd';
import {
  EyeOutlined,
} from '@ant-design/icons';
import API from 'utils/api';
import FormSubmitControls from 'components/FormSubmitControls';
import Previewer from 'components/Previewer';
import CustomTable from 'components/Table';
import { onError } from 'utils/handlers';
import Select from 'components/Select';
import { isMutable } from 'utils/estadosGlobales';
import DetallesDePoliza from 'components/Catalogos/DetallesDePolizas';
import {
  SET_DOCUMENTOS_DE_POLIZA,
  SET_MODULOS_DE_POLIZAS,
  SET_SECCIONES_DE_POLIZAS,
  SET_TIPOS_DE_POLIZA,
} from 'store/reducers/catalogs';
import fetchSome from 'utils/fetchSome';

export const permissionPoliza = {
  permissionModel: 'poliza',
};

const { useForm } = Form;

const baseURI = '/configuraciones/polizas/';

// eslint-disable-next-line react/prop-types
function Polizas({ permission }) {
  const {
    estadosGlobales,
    modulosDePolizas,
    seccionesDePolizas,
    documentosDePoliza,
    tiposDePoliza,
  } = useSelector((state) => state.catalogs);
  const periodoFiscal = useSelector(({ auth }) => auth.periodoFiscalSelected);
  const collapsedSidebar = useSelector(({ app }) => app.collapsedSidebar);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [formInfo] = useForm();
  const [loading, setLoading] = useState(false);
  const [mutable, setMutable] = useState(false);
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [base64, setBase64] = useState();
  const [visiblePreview, setVisiblePreview] = useState(false);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await API.get(baseURI, {
        params: {
          periodo_fiscal: periodoFiscal.id,
        },
      });
      setData(response.data);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    const fetch = async () => {
      try {
        setLoading(true);

        if (!modulosDePolizas?.length) {
          await fetchSome('configuraciones/modulos-de-polizas/', SET_MODULOS_DE_POLIZAS);
        }

        if (!seccionesDePolizas?.length) {
          await fetchSome('configuraciones/secciones-de-polizas/', SET_SECCIONES_DE_POLIZAS);
        }

        if (!documentosDePoliza?.length) {
          await fetchSome('configuraciones/documentos-de-polizas/', SET_DOCUMENTOS_DE_POLIZA);
        }

        if (!documentosDePoliza?.length) {
          await fetchSome('configuraciones/tipos-de-polizas/', SET_TIPOS_DE_POLIZA);
        }

        await fetchData();

        setLoading(false);
      } catch (err) {
        onError(err, setLoading);
      }
    };

    fetch();

    return () => API.tryCancel;
    // eslint-disable-next-line
  }, [periodoFiscal]);

  const onCancel = () => {
    setBase64();
    setVisible();
    // form.resetFields();
    setSelectedRowKeys([]);
    formInfo.resetFields();
  };

  const onClickExpand = async () => {
    try {
      setLoading(true);
      const [key] = selectedRowKeys;
      const res = await API.get(`${baseURI}${key}/`);
      if (res?.status === 200) {
        formInfo.setFieldsValue(res.data);
        setVisible(true);
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const columns = [
    {
      titleText: 'Fecha',
      dataIndex: 'fecha',
      key: 'fecha',
      width: 150,
    },
    {
      titleText: 'Módulo',
      dataIndex: 'modulo',
      key: 'modulo',
      width: 150,
      render: (val) => modulosDePolizas.find((e) => e.id === val)?.nombre,
    },
    {
      titleText: 'Tipo de Póliza',
      dataIndex: 'tipo',
      key: 'tipo',
      width: 150,
      render: (val) => tiposDePoliza.find((e) => e.id === val)?.nombre,
    },
    {
      titleText: 'Folio Póliza',
      dataIndex: 'folio',
      key: 'folio',
      width: 250,
    },
    {
      titleText: 'Documento Operación',
      dataIndex: 'documento',
      key: 'documento',
      width: 200,
      render: (val) => documentosDePoliza.find((e) => e.id === val)?.nombre,
    },
    {
      titleText: 'Folio Documento Operación',
      dataIndex: 'folio_de_documento',
      key: 'folio_de_documento',
      width: 250,
    },
    {
      titleText: 'Folio Agrupador',
      dataIndex: 'folio_agrupador',
      key: 'folio_agrupador',
      width: 250,
    },
    {
      titleText: 'Sección',
      dataIndex: 'seccion',
      key: 'seccion',
      width: 150,
      render: (val) => seccionesDePolizas.find((e) => e.id === val)?.nombre,
    },
    {
      titleText: 'Concepto',
      dataIndex: 'concepto',
      key: 'concepto',
      width: 400,
    },
    {
      titleText: 'Cargo',
      dataIndex: 'cargo',
      key: 'cargo',
      width: 150,
    },
    {
      titleText: 'Descripción',
      dataIndex: 'descripcion',
      key: 'descripcion',
      width: 400,
    },

  ];

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    setMutable(isMutable(record));
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickPreview = async () => {
    try {
      setLoading(true);
      const [key] = selectedRowKeys;
      const res = await API.get(`${baseURI}${key}/pdf/`);
      if (res?.status === 200 && res.data) {
        setBase64(`data:application/pdf;base64,${res.data.data}`);
        setVisiblePreview(true);
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  return (
    <Row align="center" justify="center" className="container">
      <Spin
        tip="Cargando..."
        spinning={loading}
      >
        {!visible ? (
          <CustomTable
            cols={columns}
            data={data}
            rowSelection={rowSelection}
            mobileColIndex={0}
            permission={permission}
            loading={loading}
            handleOnRowClick={handleOnRowClick}
            controls={{
              onClickExpand,
            }}
            allowSort={false}
            allowAdd={false}
            allowDelete={false}
            allowEdit={false}
            allowExpand
            rowKey="id"
            customActions={[
              {
                onClick: onClickPreview,
                icon: <EyeOutlined />,
                text: 'Ver Póliza',
                disabled: selectedRowKeys.length,
              },
            ]}
          />
        ) : (
          <Card
            className="form-container"
            title="Información Póliza"
            extra={(
              <FormSubmitControls
                onCancel={onCancel}
                mutable={mutable}
                callback={(id, observaciones) => {
                  const [key] = selectedRowKeys;
                  const normalized = data.map((e) => {
                    if (e.id === key) {
                      const match = estadosGlobales.find((eg) => eg.id === id);
                      formInfo.setFieldsValue({
                        estados_globales: match.descripcion,
                        observaciones,
                      });
                      setMutable(false);
                      return {
                        ...e,
                        estados_globales: id,
                      };
                    }
                    return e;
                  });
                  setData(normalized);
                }}
                allowAuthorize
                allowCancel
                baseURI={baseURI}
                selectedRowKeys={selectedRowKeys}
              />
            )}
            bordered={false}
          >
            <Form
              layout="vertical"
              form={formInfo}
              className="no-margin"
            >
              <Row gutter={10}>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="modulo"
                    label="Módulo"
                  >
                    <Select dataSource={modulosDePolizas} disabled labelProp="nombre" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="concepto"
                    label="Concepto"
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="folio_de_documento"
                    label={collapsedSidebar ? 'Folio del Documento' : 'Folio'}
                    tooltip={!collapsedSidebar ? 'Folio del Documento' : null}
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="folio_agrupador"
                    label="Folio Agrupador"
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="folio"
                    label="Folio"
                  >
                    <Input allowClear disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="documento"
                    label="Documento"
                  >
                    <Select dataSource={documentosDePoliza} disabled labelProp="nombre" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="tipo"
                    label="Tipo"
                  >
                    <Select dataSource={tiposDePoliza} disabled labelProp="nombre" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="seccion"
                    label="Sección"
                  >
                    <Select dataSource={seccionesDePolizas} disabled labelProp="nombre" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="estados_globales"
                    label="Estado"
                  >
                    <Select globalStates disabled />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={10} justify="center">
                <Col xs={24} sm={24} md={8}>
                  <Button
                    onClick={onClickPreview}
                    block
                    style={{ marginTop: 22 }}
                    icon={<EyeOutlined />}
                  >
                    <Typography.Text>
                      Ver Póliza
                    </Typography.Text>
                  </Button>
                </Col>

                <Col span={24}>
                  <Form.Item
                    name="descripcion"
                    label="Descripción"
                  >
                    <Input.TextArea autoSize={{ maxRows: 5, minRows: 4 }} allowClear disabled />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Typography.Title level={4}>Detalles de Póliza</Typography.Title>
                </Col>
                <Col span={24}>
                  <DetallesDePoliza polizaParent={selectedRowKeys} />
                </Col>
              </Row>
            </Form>
          </Card>
        )}
      </Spin>
      <Previewer
        base64={base64}
        baseURL={baseURI}
        id={selectedRowKeys[0]}
        visible={visiblePreview}
        setVisible={setVisiblePreview}
      />
    </Row>
  );
}

export default Polizas;
