import { store } from 'store';
import { SET_URS } from 'store/reducers/catalogs';
import API from 'utils/api';
import { onError } from 'utils/handlers';

const baseURI = 'catalogos/';

/**
 * GET Naturalezas De Cuenta Contable
 * @returns {Promise<Array<object>>}
 */
export const getNaturalezasCuentasContables = async () => {
  try {
    const response = await API.get(`${baseURI}naturalezas-de-cuentas-contables`);
    return response.data;
  } catch (err) {
    onError(err);
    return [];
  }
};

/**
 * GET Naturalezas De Ingresos
 * @returns {Promise<Array<object>>}
 */
export const getNaturalezasDeIngresos = async () => {
  try {
    const response = await API.get(`${baseURI}naturalezas-de-ingreso`);
    return response.data;
  } catch (err) {
    onError(err);
    return [];
  }
};

/**
 * GET Tipo De Cuenta Contable
 * @returns {Promise<Array<object>>}
 */
export const getTipoDeCuentaContable = async () => {
  try {
    const response = await API.get(`${baseURI}tipos-de-cuentas-contables`);
    return response.data;
  } catch (err) {
    onError(err);
    return [];
  }
};

/**
 * GET Momentos Presupuestales De Egresos
 * @returns {Promise<Array<object>>}
 */
export const getMomentosPresupuestalesDeEgresos = async () => {
  try {
    const response = await API.get(`${baseURI}momentos-presupuestales-de-egresos`);
    return response.data;
  } catch (err) {
    onError(err);
    return [];
  }
};

/**
 * GET Formas De Pago
 * @returns {Promise<Array<object>>}
 */
export const getFormasDePago = async () => {
  try {
    const response = await API.get(`${baseURI}formas-de-pago`);
    return response.data;
  } catch (err) {
    onError(err);
    return [];
  }
};

/**
 * GET Clasificació Programática
 * @returns {Promise<Array<object>>}
 */
export const getClasificacionProgramatica = async () => {
  try {
    const response = await API.get(`${baseURI}clasificaciones-programaticas`);
    return response.data;
  } catch (err) {
    onError(err);
    return [];
  }
};

/**
 * GET Clasificació Administrativa
 * @returns {Promise<Array<object>>}
 */
export const getClasificacionAdministrativa = async () => {
  try {
    const response = await API.get(`${baseURI}clasificaciones-administrativas`);
    return response.data;
  } catch (err) {
    onError(err);
    return [];
  }
};

/**
 * GET Clasificació Funcional
 * @returns {Promise<Array<object>>}
 */
export const getClasificacionFuncional = async () => {
  try {
    const response = await API.get(`${baseURI}clasificaciones-funcionales`);
    return response.data;
  } catch (err) {
    onError(err);
    return [];
  }
};

/**
 * GET Clasificación Tipo De Gasto
 * @returns {Promise<Array<object>>}
 */
export const getClasificacionTipoDeGasto = async () => {
  try {
    const response = await API.get(`${baseURI}clasificaciones-tipo-de-gasto`);
    return response.data;
  } catch (err) {
    onError(err);
    return [];
  }
};

/**
 * GET Content Types URs
 */
export const getContentTypesURs = async () => {
  try {
    if (!store.getState()?.catalogs?.urs?.length) {
      const response = await API.get(`${baseURI}content-types-ur`);
      store.dispatch({
        type: SET_URS,
        payload: response.data,
      });
    }
  } catch (err) {
    onError(err);
  }
};
