import React from 'react';
import SingleForm from 'components/SingleForm';
import { getMomentosPresupuestalesDeEgresos } from 'api/catalogos';

export const permissionMomentoPresupuestalDeEgreso = {
  permissionModel: 'momentopresupuestaldeegreso',
};

// eslint-disable-next-line react/prop-types
function MomentosPresupuestalesDeEgresos({ permission }) {
  return (
    <SingleForm
      title="Momentos Presupuestales De Egreso"
      readOnly
      withoutState
      permission={permission}
      get={getMomentosPresupuestalesDeEgresos}
    />
  );
}

export default MomentosPresupuestalesDeEgresos;
