/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Col,
  Form,
  Input,
  Row,
  Spin,
  Typography,
  message,
  Tooltip,
} from 'antd';
import FormSubmitControls from 'components/FormSubmitControls';
// import getFormatedValues, { formatReceived } from 'utils/formatValues';
import API from 'utils/api';
import { onError } from 'utils/handlers';
import Select from 'components/Select';
import { useSelector } from 'react-redux';
import ModalDelete from 'components/ModalDelete';
import Table from 'components/Table';
import NumericInput from 'components/NumericInput';
import AvanceActividadFID from './AvanceActividadFID';

function AvancesFID({
  selectedRowKey: programa_presupuestario_fid,
  metas,
  indicadoresData,
  actividadFid,

}) {
  const baseURI = '/presupuestos/avance-general-de-ppfid/';
  // const periodoFiscal = useSelector(({ auth }) => auth.periodoFiscalSelected);
  const [form] = Form.useForm();
  const [formFinanciero] = Form.useForm();
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [enablePresupuesto, setEnablePresupuesto] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [calendarizacionSeleccionada, setCalendarizacionSeleccionada] = useState();

  const [presupuestoAnual, setPresupuestoAnual] = useState();
  const [presupuestosAnuales, setPresupuestosAnuales] = useState([]);

  const [meta, setMeta] = useState();

  const [avanceID, setAvanceID] = useState();
  const [allowAdd, setAllowAdd] = useState(false);

  const [disableAdd, setDisableAdd] = useState(false);
  const formInstances = [
    form,
    formFinanciero,
  ];
  const fetchData = async () => {
    try {
      setLoading(true);
      if (programa_presupuestario_fid) {
        const res = await API.get(baseURI, {
          params: {
            programa_fid: programa_presupuestario_fid,
            numero: 1,
          },
        });
        if (res.data.length !== 0) {
          setDisableAdd(true);
        }
        if (res?.status === 200) {
          setData(res.data);
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onCancel = async () => {
    setVisible(false);
    setAllowAdd(false);
    form.resetFields();
    setMeta({});
    setSelectedRowKeys([]);
    setCalendarizacionSeleccionada();
    setPresupuestoAnual();
    setDisableAdd(false);
    await fetchData();
  };

  useEffect(() => {
    let mounted = true;
    const fetchAll = async () => {
      try {
        setLoading(true);
        if (mounted) {
          setLoading(false);
          await fetchData();
        }

        const resPresupuesto = await API.get('/configuraciones/presupuestos-anuales-de-egreso/');
        setPresupuestosAnuales(resPresupuesto.data);
      } catch (err) {
        onError(err, setLoading);
      }
    };
    fetchAll();
    return () => { mounted = false; };
    // eslint-disable-next-line
  }, [programa_presupuestario_fid]);

  // console.log(response.data.reduce((acum, current) => acum + current.importe, 0));

  const getAsgnacionesMensuales = async (id) => {
    try {
      setLoading(true);
      const response = await API.get(`/configuraciones/presupuestos-anuales-de-egreso/${id}/asignaciones-mensuales/`);
      const resPresupuesto = presupuestosAnuales.find((e) => e.id === id);
      setPresupuestoAnual(resPresupuesto.monto_total);
      const asignaciones = [];
      let valorModulo = 0;
      switch (calendarizacionSeleccionada) {
        case 1:
          valorModulo = 12;
          break;
        case 2:
          valorModulo = 6;
          break;
        case 3:
          valorModulo = 4;
          break;
        case 4:
          valorModulo = 3;
          break;
        case 5:
          valorModulo = 2;
          break;
        case 6:
          valorModulo = 1;
          break;
        default:
          message.info('No se encontro calendarizacion');
          break;
      }
      // eslint-disable-next-line no-plusplus
      for (let i = 0, suma = 0, mod = 1, numero = 0; i < response.data.length; i++, mod++) {
        suma += response.data[i].importe;
        if (mod % valorModulo === 0) {
          asignaciones.push({
            numero: numero + 1,
            aprobado_con_modificaciones: suma,
            ejercido: 0,
          });
          mod = 0;
          suma = 0;
          numero += 1;
        }
      }
      const values = Object.fromEntries(asignaciones.map((e) => [e.numero - 1, e]));
      setTimeout(() => {
        form.setFieldsValue({
          ...values,
        });
      });
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const getAvanceFinanciero = () => {
    try {
      const values = {
        ...form.getFieldsValue(),
      };
      const metasKeys = Object.keys(values).filter((e) => /\d+/.test(e));
      // Aqui saco los datos de las tablas y las meto en arrays
      const _metas = [];
      metasKeys.forEach((e) => {
        _metas.push(values[e]);
        delete values[e];
      });

      const formattedValues = _metas.map((e) => ({
        ...e,
        diferencia_en_pesos: e.aprobado_con_modificaciones - parseFloat(e?.ejercido || 0),
        ejercido: parseFloat(e.ejercido),
      }));

      // Avance Financiero Acumulado
      // eslint-disable-next-line no-plusplus
      for (let i = 0, aprob = 0, ejer = 0, dif = 0; i < formattedValues.length; i++) {
        // Aprob = Acumulador del presupuesto aprobado
        aprob += formattedValues[i].aprobado_con_modificaciones;
        formattedValues[i].aprobado_con_modificaciones_acumulado = aprob;

        // Ejer = Acumulador del presupuesto ejercido
        ejer += formattedValues[i].ejercido;
        formattedValues[i].ejercido_acumulado = ejer.toFixed(2);

        // Dif = Acumulador de la diferencia de pesos
        dif += formattedValues[i].diferencia_en_pesos;
        formattedValues[i].diferencia_en_pesos_acumulado = dif.toFixed(2);

        // Calculo del Avance Planeado en Porcentaje Acumulado
        formattedValues[i].planeado_acumulado = ((formattedValues[i]
          .aprobado_con_modificaciones_acumulado * 100) / presupuestoAnual).toFixed(2);

        // Calculo del Avance Planeado en Porcentaje Acumulado
        formattedValues[i].real_acumulado = ((formattedValues[i]
          .ejercido_acumulado * 100) / presupuestoAnual).toFixed(2);

        // Calculo de diferecias de porcentaje acumulado
        formattedValues[i].diferencia_acumulado = (formattedValues[i]
          .planeado_acumulado
        - formattedValues[i].real_acumulado).toFixed(2);
      }
      setTimeout(() => {
        form.setFieldsValue({
          ...formattedValues,
        });
      });
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();

      const values = {
        ...form.getFieldsValue(),
        programa_fid: programa_presupuestario_fid[0],
      };
      const metasKeys = Object.keys(values).filter((e) => /\d+/.test(e));
      const _metas = [];
      metasKeys.forEach((e) => {
        _metas.push(values[e]);
        delete values[e];
      });
      if (!selectedRowKeys.length) {
        const fullValues = [];
        const promises = [];
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < _metas.length; i++) {
          fullValues.push({
            numero: i + 1,
            ...values,
            ..._metas[i],
          });
          promises.push(API.post('/presupuestos/avance-general-de-ppfid/', fullValues[i]));
        }
        const responses = await Promise.allSettled(promises);
        if (!responses.every((response) => response.status === 'fulfilled')) {
          message.info('No se agrego la meta');
        } else {
          message.info('Se agrego correctamente');
          onCancel();
          await fetchData();
        }
      } else {
        const resMetas = await API.get(baseURI, {
          params: {
            programa_fid: values.programa_fid,
            presupuesto_anual_de_egresos: values.presupuesto_anual_de_egresos,
            meta_de_programa_presupuestario_fid: values.meta_de_programa_presupuestario_fid,
          },
        });
        const sortedMetas = resMetas.data.sort((x, y) => x.numero - y.numero);
        const promises = [];
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < _metas.length; i++) {
          promises.push(API.patch(`${baseURI}${sortedMetas[i].id}`, _metas[i]));
        }
        const responses = await Promise.allSettled(promises);
        if (!responses.every((response) => response.status === 'fulfilled')) {
          message.info('No se agrego la meta');
        } else {
          message.info('Se edito correctamente');
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading, formInstances);
    }
  };

  const columns = [
    {
      titleText: 'Meta de Programa FID',
      dataIndex: 'meta_de_programa_presupuestario_fid',
      key: 'meta_de_programa_presupuestario_fid',
      width: 350,
      render: (val) => metas.find((e) => e.id === val)?.meta_de_administracion,
    },
    {
      titleText: 'Presupuesto Anual de Egresos',
      dataIndex: 'presupuesto_anual_de_egresos',
      key: 'presupuesto_anual_de_egresos',
      width: 350,
      render: (val) => presupuestosAnuales.find((e) => e.id === val)?.clave_presupuestal_visible,
    },
    {
      titleText: 'Estado',
      dataIndex: 'estados_globales',
      key: 'estados_globales',
      width: 160,
      render: (id) => estadosGlobales.find((eg) => eg.id === id)?.descripcion,
    },
  ];

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    setTimeout(() => {
      form.setFieldsValue(record);
    });
  };

  const onClickAdd = async () => {
    onCancel();
    setVisible(true);
  };

  const onClickEdit = async () => {
    try {
      setLoading(true);
      setAllowAdd(true);
      const [key] = selectedRowKeys;
      setAvanceID(key);
      const match = data.find((e) => e.id === key);
      const matchMeta = metas.find((e) => e.id === match.meta_de_programa_presupuestario_fid);
      setMeta(matchMeta);
      setCalendarizacionSeleccionada(matchMeta.calendarizacion);
      setPresupuestoAnual(match.presupuesto_anual_de_egresos);
      const resMetas = await API.get(baseURI, {
        params: {
          programa_fid: match.programa_fid,
          presupuesto_anual_de_egresos: match.presupuesto_anual_de_egresos,
          meta_de_programa_presupuestario_fid: match.meta_de_programa_presupuestario_fid,
        },
      });
      const valuesMetas = Object.fromEntries(resMetas.data.map((e) => [e.numero - 1, e]));

      setTimeout(() => {
        form.setFieldsValue({
          // nivel: toInteger(match.nivel),
          meta_de_programa_presupuestario_fid: match.meta_de_programa_presupuestario_fid,
          presupuesto_anual_de_egresos: match.presupuesto_anual_de_egresos,
          ...valuesMetas,
        });
      });

      setLoading(false);
    } catch (error) {
      onError(error, setLoading);
    }
    setVisible(true);
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const deleteItem = async () => {
    try {
      const [key] = selectedRowKeys;
      if (programa_presupuestario_fid) {
        if (selectedRowKeys.length) {
          const avanceUno = data.find((e) => e.id === key);
          const resMetas = await API.get(baseURI, {
            params: {
              programa_fid: avanceUno.programa_fid,
              presupuesto_anual_de_egresos: avanceUno.presupuesto_anual_de_egresos,
              meta_de_programa_presupuestario_fid: avanceUno.meta_de_programa_presupuestario_fid,
            },
          });
          const sortedMetas = resMetas.data.sort((x, y) => x.numero - y.numero);
          const promises = [];
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < sortedMetas.length; i++) {
            promises.push(API.delete(`${baseURI}${sortedMetas[i].id}/`));
          }
          const responses = await Promise.allSettled(promises);
          if (!responses.every((response) => response.status === 'fulfilled')) {
            message.info('No se elimino el avance');
          } else {
            message.info('Se elimino correctamente');
            onCancel();
            await fetchData();
            setVisibleAlert(false);
          }
        }
      }
    } catch (err) {
      onError(err, setLoading);
    }
  };

  // const requiredRule = {
  //   message: 'Este campo es requerido',
  // };

  // const rules = {
  //   required: [requiredRule],
  // };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const calendarizacionesMap = {
    1: ['Enero - Diciembre'],

    2: ['1er Semestre Enero - Junio',
      '2do Semestre Julio - Diciembre',
    ],

    3: ['1er Cuatrimestre Enero - Abril',
      '2do Cuatrimestre Mayo - Agosto',
      '3er Cuatrimestre Septiembre - Diciembre',
    ],

    4: ['1er Trimestre Enero - Marzo',
      '2do Trimestre Abril - Junio',
      '3er Trimestre Julio - Septiembre',
      '4to Trimestre Octubre - Diciembre',
    ],

    5: ['1er Bimestre Enero - Febrero',
      '2do Bimestre Marzo - Abril',
      '3er Bimestre Mayo - Junio',
      '4to Bimestre Julio - Agosto',
      '5to Bimestre Septiembre - Octubre',
      '6to Bimestre Noviembre - Diciembre',
    ],

    6: ['Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ],
  };

  return (
    <Row align="center" justify="center" className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible ? (
          <Table
            cols={columns}
            data={data}
            rowSelection={rowSelection}
            handleOnRowClick={handleOnRowClick}
            controls={{
              onClickAdd,
              onClickEdit,
              onClickDelete,
            }}
            mobileColIndex={0}
            allowAdd={!disableAdd}
          />
        ) : (
          <Card
            bordered={false}
            className="form-container"
            title={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Avance de Programa Presupuestarios`}
            extra={(
              <FormSubmitControls
                onFinish={onFinish}
                onCancel={onCancel}
                loading={loading}
              />
            )}
          >

            <Form
              layout="vertical"
              form={form}
              onFinish={onFinish}
              scrollToFirstError
            >
              <Row gutter={10}>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="meta_de_programa_presupuestario_fid"
                    label="Meta de Programa FID"
                  >
                    <Select
                      dataSource={metas}
                      labelProp="meta_de_administracion"
                      onChange={(val) => {
                        if (val !== null) {
                          setEnablePresupuesto(true);
                          const resMeta = metas.find((e) => e.id === val);
                          setMeta(resMeta);
                          setCalendarizacionSeleccionada(resMeta.calendarizacion);
                        }
                      }}
                      disabled={!selectedRowKeys}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="presupuesto_anual_de_egresos"
                    label="Presupuesto Anual De Egresos"
                  >
                    <Select
                      dataSource={presupuestosAnuales}
                      labelProp="clave_presupuestal_visible"
                      onChange={(val) => {
                        getAsgnacionesMensuales(val);
                      }}
                      disabled={!enablePresupuesto}
                    />
                  </Form.Item>
                </Col>
              </Row>
              {calendarizacionSeleccionada && (
              <Typography.Title level={4}>
                Avance Financiero
              </Typography.Title>
              )}

              {/* Avances financieros --------------- --------------------------------*/}
              {calendarizacionSeleccionada
                    && calendarizacionesMap[calendarizacionSeleccionada].map((label, i) => (
                      <Row gutter={10}>
                        <Col xs={24} sm={24} md={6}>
                          <h3>{label}</h3>
                        </Col>
                        <Col xs={24} sm={24} md={6}>
                          <NumericInput
                            label={i === 0 ? ('Presupuesto aprobado') : ('')}
                            name={[i, 'aprobado_con_modificaciones']}
                            prefix="$"
                            decimal
                            disabled
                            allowZero
                          />
                        </Col>
                        <Col xs={24} sm={24} md={6}>
                          <NumericInput
                            label={i === 0 ? ('Presupuesto Ejercido') : ('')}
                            name={[i, 'ejercido']}
                            prefix="$"
                            decimal
                            allowZero
                            onChange={() => {
                              getAvanceFinanciero();
                            }}
                          />
                        </Col>
                        <Col xs={24} sm={24} md={6}>
                          <NumericInput
                            label={i === 0 ? ('Diferencia en pesos') : ('')}
                            name={[i, 'diferencia_en_pesos']}
                            prefix="$"
                            decimal
                            allowZero
                            disabled
                          />
                        </Col>
                        <Form.Item
                          name={[i, 'id']}
                          hidden
                        >
                          <Input allowClear />
                        </Form.Item>
                      </Row>
                    ))}

              {/* Avance FinancieroAcumulado ----------------------------------------- */}
              {calendarizacionSeleccionada && (
              <Typography.Title level={5}>
                Avance Financiero Acumulado
              </Typography.Title>
              )}

              {calendarizacionSeleccionada
                    && calendarizacionesMap[calendarizacionSeleccionada].map((label, i) => (
                      <Row gutter={10}>
                        <Col xs={24} sm={24} md={6}>
                          <h3>{label}</h3>
                        </Col>
                        <Col xs={24} sm={24} md={6}>
                          <NumericInput
                            label={i === 0 ? ('Presupuesto Aprobado Acumulado') : ('')}
                            name={[i, 'aprobado_con_modificaciones_acumulado']}
                            decimal
                            disabled
                            allowZero
                            prefix="$"
                          />
                        </Col>
                        <Col xs={24} sm={24} md={6}>
                          <NumericInput
                            label={i === 0 ? ('Presupuesto Ejercido Acumulado') : ('')}
                            name={[i, 'ejercido_acumulado']}
                            decimal
                            disabled
                            allowZero
                            prefix="$"
                          />
                        </Col>
                        <Col xs={24} sm={24} md={6}>
                          <NumericInput
                            label={i === 0 ? ('Diferencia en pesos Acumulado') : ('')}
                            name={[i, 'diferencia_en_pesos_acumulado']}
                            decimal
                            allowZero
                            disabled
                            prefix="$"
                          />
                        </Col>
                        <Form.Item
                          name={[i, 'id']}
                          hidden
                        >
                          <Input allowClear />
                        </Form.Item>
                      </Row>
                    ))}

              {calendarizacionSeleccionada && (
              <Typography.Title level={5}>
                Avance Financiero Acumulado en Porcentaje
              </Typography.Title>
              )}
              {calendarizacionSeleccionada
                    && calendarizacionesMap[calendarizacionSeleccionada].map((label, i) => (
                      <Row gutter={10}>
                        <Col xs={24} sm={24} md={6}>
                          <h3>{label}</h3>
                        </Col>
                        <Col xs={24} sm={24} md={6}>
                          <NumericInput
                            label={i === 0 ? ('Avance en Porcentaje Acumulado') : ('')}
                            name={[i, 'planeado_acumulado']}
                            decimal
                            disabled
                            allowZero
                            prefix="%"
                          />
                        </Col>
                        <Col xs={24} sm={24} md={6}>
                          <NumericInput
                            label={i === 0 ? ('Avance Real Acumulado') : ('')}
                            name={[i, 'real_acumulado']}
                            decimal
                            disabled
                            allowZero
                            prefix="%"
                          />
                        </Col>
                        <Col xs={24} sm={24} md={6}>
                          <NumericInput
                            label={i === 0 ? ('Diferencia en % Acumulado') : ('')}
                            name={[i, 'diferencia_acumulado']}
                            decimal
                            allowZero
                            disabled
                            prefix="%"
                          />
                        </Col>
                        <Form.Item
                          name={[i, 'id']}
                          hidden
                        >
                          <Input allowClear />
                        </Form.Item>
                      </Row>
                    ))}

              {/* Justificaciones de diferencias de avance financiero -----------------*/}
              {calendarizacionSeleccionada && (
              <Typography.Title level={4}>
                Justificaciones De Diferenecias
              </Typography.Title>
              )}
              {calendarizacionSeleccionada
                    && calendarizacionesMap[calendarizacionSeleccionada].map((label, i) => (
                      <Row gutter={10}>
                        <Col xs={24} sm={24} md={24}>
                          <h3>{label}</h3>
                        </Col>
                        <Col xs={24} sm={24} md={24}>
                          <Form.Item
                              // label="Justificación de diferencias"
                            name={[i, 'justificacion']}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Form.Item
                          name={[i, 'id']}
                          hidden
                        >
                          <Input allowClear />
                        </Form.Item>
                      </Row>
                    ))}
              <Tooltip
                title="El avance fiico solo se puede agregar al editar el Avance Financiero."
              >
                <Typography.Title
                  level={4}
                >
                  Avance Fisico
                </Typography.Title>
              </Tooltip>
              <AvanceActividadFID
                meta={meta}
                metasFID={metas}
                indicadoresData={indicadoresData}
                avanceID={avanceID}
                presupuestoAnual={presupuestoAnual}
                allowAdd={allowAdd}
                actividadFid={actividadFid}
              />
            </Form>
          </Card>
        )}
      </Spin>
      <ModalDelete
        onDelete={deleteItem}
        onCancel={() => {
          setVisibleAlert(false);
          setSelectedRowKeys([]);
        }}
        visible={visibleAlert}
        content="el avance seleccionado"
      />
    </Row>
  );
}

AvancesFID.propTypes = {
  selectedRowKey: PropTypes.number,
  metas: PropTypes.arrayOf(PropTypes.object).isRequired,
  indicadoresData: PropTypes.arrayOf(PropTypes.shape()),
  actividadFid: PropTypes.arrayOf(PropTypes.shape()),
};

AvancesFID.defaultProps = {
  selectedRowKey: 0,
  indicadoresData: [],
  actividadFid: [],
};

export default AvancesFID;
