import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Spin,
  Row,
  Col,
  Button,
  message,
  Input,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import API from 'utils/api';
import { useSelector } from 'react-redux';
import { onError } from 'utils/handlers';
import { SET_CUENTAS_PADRES } from 'store/reducers/catalogs';
import fetchSome from 'utils/fetchSome';
import FormItemLabel from './FormItemLabel';
import Table from './Table';
import Select from './Select';

const integerNormalizer = (val) => parseInt(`0${val}`, 10) || null;

// eslint-disable-next-line react/prop-types
function NumericInput({ label, name }) {
  return (
    <Form.Item
      label={label}
      name={name}
      normalize={integerNormalizer}
    >
      <Input />
    </Form.Item>
  );
}

function BusquedaClavesContables({
  callback,
}) {
  const cuentasPadres = useSelector(({ catalogs }) => catalogs.cuentasPadres);
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      try {
        if (!cuentasPadres?.length) {
          await fetchSome('catalogos/clasificaciones-de-cuentas-contables/', SET_CUENTAS_PADRES);
        }
      } catch (err) {
        onError(err, setLoading);
      }
    };
    fetch();
    // eslint-disable-next-line
  }, []);

  const onFinishForm = async () => {
    try {
      setLoading(true);
      const values = form.getFieldsValue();
      const entries = Object.entries(values);
      const validEntries = entries.filter((e) => e[1]);
      const params = Object.fromEntries(validEntries);
      if (validEntries.length) {
        const res = await API.get('contabilidad/cuentas-contables/', {
          params,
        });
        if (res?.status === 200) {
          if (res.data.length) {
            setData(res.data);
          } else {
            message.info('No se encontraron coincidencias');
            setData([]);
          }
        }
      } else {
        message.info('Introduzca al menos un parámetro de búsqueda');
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onClickRadio = (record) => {
    setSelectedRowKeys([record.id]);
  };

  const onRow = (record) => ({
    onClick: () => {
      onClickRadio(record);
      callback(record);
    },
  });

  const rowSelection = {
    type: 'radio',
    selectedRowKeys,
  };

  const columns = [
    {
      titleText: 'Cuenta Contable',
      dataIndex: 'clave',
      key: 'clave',
      width: 200,
    },
    {
      titleText: 'Nombre',
      dataIndex: 'nombre',
      key: 'nombre',
      width: 200,
    },
  ];

  return (
    <Spin tip="Cargando..." spinning={loading}>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinishForm}
      >
        <Row gutter={10}>
          <Col xs={24} sm={24} md={8}>
            <Form.Item
              name="nombre"
              label="Nombre"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Form.Item
              name="numero"
              label="Cuenta padre"
            >
              <Select dataSource={cuentasPadres} keyLabelRender labelProp="nombre" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <NumericInput
              name="fuente_de_financiamiento__clave"
              label={(
                <FormItemLabel
                  longTitle="Fuente de Financiamiento"
                  shortTitle="FF"
                  hideOn={['sm', 'md']}
                />
              )}
            />
          </Col>
          <Col xs={24} sm={24} md={8}>
            <NumericInput
              name="CRI__clave"
              label={(
                <FormItemLabel
                  longTitle="Clasificador por Rubro de Ingresos"
                  shortTitle="CRI"
                  hideOn={['sm', 'md']}
                />
              )}
            />
          </Col>
          <Col xs={24} sm={24} md={8}>
            <NumericInput
              name="clasificador_de_objeto_de_gasto__clave"
              label={(
                <FormItemLabel
                  longTitle="Clasificador por Objeto del Gasto"
                  shortTitle="COG"
                  hideOn={['sm', 'md']}
                />
              )}
            />
          </Col>
          <Col xs={24} sm={24} md={8}>
            <NumericInput
              name="proyecto__clave"
              label="Proyecto"
            />
          </Col>
          <Col xs={24} sm={24} md={8}>
            <NumericInput
              name="URs__clave"
              label="Unidad Responsable"
            />
          </Col>
          <Col xs={0} sm={0} md={8} />
          <Col xs={24} sm={24} md={8}>
            <Form.Item>
              <Button
                type="link"
                htmlType="submit"
                style={{ marginTop: 20 }}
                className="primary-on-hover"
                icon={<SearchOutlined />}
              >
                Buscar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Table
        cols={columns}
        data={data}
        rowSelection={rowSelection}
        onRow={onRow}
        noControls
      />
    </Spin>
  );
}

BusquedaClavesContables.propTypes = {
  callback: PropTypes.func,
};

BusquedaClavesContables.defaultProps = {
  callback: () => {},
};

export default BusquedaClavesContables;
