import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Spin,
  message,
  Modal,
} from 'antd';
import PropTypes from 'prop-types';

import {
  DoubleRightOutlined,
  RightOutlined,
} from '@ant-design/icons';

import Table from 'components/Table';
import NumericInput from 'components/NumericInput';
import FormSubmitControls from 'components/FormSubmitControls';
import ModalDelete from 'components/ModalDelete';
import Select from 'components/Select';
import API from 'utils/api';
import { onError, onSuccess } from 'utils/handlers';

function Segmentos({
  idConfiguracion,
  isMutable,
}) {
  const baseURI = 'configuraciones/configuraciones-de-segmentos-presupuestales/:id/segmentos/'
    .replace(':id', idConfiguracion);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await API.get(baseURI);
      if (res?.status === 200) {
        setData(res.data);
        setLoading(false);
      }
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchData();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, [idConfiguracion]);

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    setSelectedRowKeys([]);
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      const [key] = selectedRowKeys;
      if (disabled) {
        const match = data.find((e) => e.id === key);
        values.prioridad = match.prioridad;
        values.nombre_de_segmento = match.nombre_de_segmento;
        values.longitud_maxima = match.longitud_maxima;
      }
      values.configuracion_de_segmento = idConfiguracion;
      if (!selectedRowKeys.length) {
        values.prioridad = data.length + 1;
        const response = await API.post(baseURI, values);
        if (response?.status === 201) {
          onSuccess(response, 'Agregado correctamente');
          onCancel();
          await fetchData();
        }
      } else {
        const response = await API.patch(`${baseURI}${key}/`, values);
        if (response?.status === 200) {
          onSuccess(response, 'Actualizado correctamente');
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`${baseURI}${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          setVisibleAlert(false);
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    setDisabled(!record.modificable);
    form.setFieldsValue(record);
  };

  const rowSelection = {
    type: 'radio',
    selectedRowKeys,
  };

  const onClickAdd = () => {
    onCancel();
    setDisabled(false);
    form.resetFields();
    form.setFieldsValue({ prioridad: data.length + 1 });
    setSelectedRowKeys([]);
    setVisible(true);
  };

  const onClickEdit = () => {
    setVisible(true);
  };

  const onClickDelete = () => {
    const [key] = selectedRowKeys;
    const match = data.find((e) => e.id === key);
    if (match?.modificable) {
      setVisibleAlert(true);
    } else {
      message.info('No se pueden eliminar los segmentos predefinidos');
      setDisabled(true);
    }
  };

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
  };

  const moveTo = async (where) => {
    try {
      setLoading(true);
      const [key] = selectedRowKeys;
      const match = data.find((item) => item.id === key);
      let prioridad = 0;
      switch (where) {
        case 'first':
          prioridad = 1;
          break;
        case 'up':
          prioridad = match.prioridad - 1;
          break;
        case 'down':
          prioridad = match.prioridad + 1;
          break;
        case 'last':
          prioridad = data.length;
          break;
        default:
          prioridad = match.prioridad;
          break;
      }
      const res = await API.patch(`${baseURI}${key}/`, {
        prioridad,
      });
      if (res?.status === 200) {
        await fetchData();
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const columns = [
    {
      titleText: 'Secuencia',
      dataIndex: 'prioridad',
      key: 'prioridad',
      width: isMutable ? 100 : 80,
      align: isMutable ? 'left' : 'center',
    },
    {
      titleText: 'Nombre',
      dataIndex: 'nombre',
      key: 'nombre',
      width: 200,
    },
    {
      titleText: 'Longitud Máxima',
      dataIndex: 'longitud_maxima',
      key: 'longitud_maxima',
      width: 150,
    },
    {
      titleText: 'Visible',
      dataIndex: 'visible',
      key: 'visible',
      width: 100,
      render: (val) => (val ? 'Sí' : 'No'),
    },
    {
      titleText: 'Integra Llave Única',
      dataIndex: 'integra_llave_unica',
      key: 'integra_llave_unica',
      width: 150,
      render: (val) => (val ? 'Sí' : 'No'),
    },
  ];

  const restProps = isMutable ? {
    rowSelection,
    handleOnRowClick,
  } : {};

  function SortControls() {
    const [key] = selectedRowKeys;
    const prioridad = data.find((e) => e.id === key)?.prioridad;
    return (
      <Col className="sorting">
        <Button
          icon={<DoubleRightOutlined rotate={-90} />}
          disabled={!key || prioridad <= 3}
          onClick={() => moveTo('first')}
          type="link"
        />
        <Button
          icon={<RightOutlined rotate={-90} />}
          disabled={!key || prioridad < 3}
          onClick={() => moveTo('up')}
          type="link"
        />
        <Button
          icon={<RightOutlined rotate={90} />}
          disabled={!key || prioridad === data.length}
          onClick={() => moveTo('down')}
          type="link"
        />
        <Button
          icon={<DoubleRightOutlined rotate={90} />}
          disabled={!key || prioridad === data.length || prioridad === data.length - 1}
          onClick={() => moveTo('last')}
          type="link"
        />
      </Col>
    );
  }

  return (
    <Row id="presupuestos-segmentos" className="container">
      <Spin tip="Cargando..." spinning={loading}>
        <Row>
          <Col style={{ width: `calc(100% - ${isMutable ? 50 : 0}px)` }}>
            <Table
              cols={columns}
              data={data}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...restProps}
              controls={isMutable ? {
                onClickAdd,
                onClickEdit,
                onClickDelete,
              } : null}
              noControls
              allowSearch={false}
              allowSort={false}
            />
          </Col>
          {isMutable && <SortControls />}
        </Row>
      </Spin>
      <Modal
        visible={visible}
        title={(
          <FormSubmitControls
            label={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Segmento`}
            onFinish={onFinish}
            onCancel={onCancel}
            loading={loading}
          />
        )}
        closable={false}
        maskClosable={!loading}
        keyboard={!loading}
        onCancel={onCancel}
        footer={null}
      >
        <Form
          layout="vertical"
          form={form}
          name="segmento_presupuestal"
          onFinish={onFinish}
          scrollToFirstError
          initialValues={{ visible: true, integra_llave_unica: true }}
        >
          <Col span={24}>
            <Form.Item
              name="prioridad"
              rules={rules.required}
              label="Secuencia"
              hasFeedback
            >
              <Input allowClear disabled />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="nombre"
              rules={rules.required}
              label="Nombre de Segmento"
              hasFeedback
            >
              <Input allowClear disabled={disabled} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <NumericInput
              name="longitud_maxima"
              label="Longitud Máxima"
              disabled={disabled}
            />
          </Col>
          <Col span={24}>
            <Form.Item
              name="visible"
              rules={rules.required}
              label="Visible"
              hasFeedback
            >
              <Select trueFalse />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="integra_llave_unica"
              rules={rules.required}
              label="Integra Llave Única"
              hasFeedback
            >
              <Select trueFalse />
            </Form.Item>
          </Col>
          <Form.Item hidden>
            <Button htmlType="submit" />
          </Form.Item>
        </Form>
      </Modal>
      <ModalDelete
        onDelete={deleteItem}
        onCancel={() => setVisibleAlert(false)}
        visible={visibleAlert}
        content={`Segmento ${form.getFieldValue('nombre_de_segmento')}`}
      />
    </Row>
  );
}

Segmentos.propTypes = {
  idConfiguracion: PropTypes.number,
  isMutable: PropTypes.bool,
};

Segmentos.defaultProps = {
  idConfiguracion: null,
  isMutable: true,
};

export default Segmentos;
