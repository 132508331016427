import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Spin,
  Modal,
} from 'antd';
import Table from 'components/Table';
import API from 'utils/api';
import FormSubmitControls from 'components/FormSubmitControls';
import ModalDelete from 'components/ModalDelete';
import Select from 'components/Select';
import { onError, onSuccess } from 'utils/handlers';
import NumericInput from 'components/NumericInput';
import currencyFormatter from 'utils/currencyFormatter';

export const permissionProcesosAdquisitivos = {
  permissionModel: 'procesoadquisitivo',
};

const baseURI = 'adquisiciones/procesos-adquisitivos/';

// eslint-disable-next-line react/prop-types
function ProcesosAdquisitivos({ permission }) {
  const { simbolo } = useSelector(({ auth }) => auth?.entidad?.configuracion?.moneda) || '$';
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  const periodoFiscalSelected = useSelector(({ auth }) => auth.periodoFiscalSelected);

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await API.get(baseURI);
      setData(res.data);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchData();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    setSelectedRowKeys([]);
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      values.periodo_fiscal = periodoFiscalSelected?.id;
      if (!selectedRowKeys.length) {
        const response = await API.post(baseURI, values);
        if (response?.status === 201) {
          onSuccess(response, 'Agregado correctamente');
          onCancel();
        }
      } else {
        const [key] = selectedRowKeys;
        const response = await API.put(`${baseURI}${key}/`, values);
        if (response?.status === 200) {
          onSuccess(response, 'Actualizado correctamente');
          onCancel();
        }
      }
      await fetchData();
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const deleteItem = async () => {
    try {
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`${baseURI}${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          setVisibleAlert(false);
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    setTimeout(() => form.setFieldsValue(record));
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const onClickEdit = () => {
    setVisible(true);
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const columns = [
    {
      titleText: 'Concepto',
      dataIndex: 'concepto',
      key: 'concepto',
      width: 350,
    },
    {
      titleText: 'Desde',
      dataIndex: 'desde',
      key: 'desde',
      width: 150,
      render: (e) => currencyFormatter(e || 0),
      align: 'right',
    },
    {
      titleText: 'Hasta',
      dataIndex: 'hasta',
      key: 'hasta',
      width: 150,
      render: (e) => currencyFormatter(e || 0),
      align: 'right',
    },
    {
      titleText: 'Estado',
      dataIndex: 'estados_globales',
      key: 'estados_globales',
      width: 200,
      render: (e) => estadosGlobales.find((i) => i.id === e)?.descripcion,
    },
  ];

  const requiredRule = {
    required: true,
    message: 'El campo es requerido',
  };

  const rules = {
    required: [requiredRule],
    desde: [
      requiredRule,
      {
        validator: async () => {
          const { desde, hasta } = form.getFieldsValue();
          if (desde > hasta) {
            throw new Error('Este campo no puede ser mayor al campo "Hasta"');
          }
        },
      },
    ],
    hasta: [
      requiredRule,
      {
        validator: async () => {
          const { desde, hasta } = form.getFieldsValue();
          if (hasta < desde) {
            throw new Error('Este campo no puede ser menor al campo "Desde"');
          }
        },
      },
    ],
  };

  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        <Table
          cols={columns}
          data={data}
          rowSelection={rowSelection}
          handleOnRowClick={handleOnRowClick}
          controls={{
            onClickAdd,
            onClickEdit,
            onClickDelete,
          }}
          mobileColIndex={0}
          permission={permission}
        />
        <Modal
          visible={visible}
          title={(
            <FormSubmitControls
              label={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Proceso Adquisitivo`}
              onFinish={onFinish}
              onCancel={onCancel}
              loading={loading}
            />
          )}
          closable={false}
          maskClosable={!loading}
          keyboard={!loading}
          onCancel={onCancel}
          footer={null}
          forceRender
        >
          <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
            scrollToFirstError
            initialValues={{ estados_globales: 1 }}
          >
            <Row>
              <Col span={24}>
                <Form.Item
                  name="concepto"
                  rules={rules.required}
                  label="Concepto"
                  hasFeedback
                >
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col span={24}>
                <NumericInput
                  name="desde"
                  label={`Desde (${simbolo})`}
                  decimal
                  rules={rules.desde}
                />
              </Col>
              <Col span={24}>
                <NumericInput
                  name="hasta"
                  label={`Hasta (${simbolo})`}
                  decimal
                  rules={rules.hasta}
                />
              </Col>
              <Col span={24}>
                <Form.Item
                  name="estados_globales"
                  label="Estado"
                  rules={rules.required}
                  hasFeedback
                >
                  <Select dataSource={estadosGlobales} disabled={!selectedRowKeys.length} />
                </Form.Item>
              </Col>
              <Form.Item hidden>
                <Button htmlType="submit" />
              </Form.Item>
            </Row>
          </Form>
        </Modal>
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => setVisibleAlert(false)}
          visible={visibleAlert}
          content={`El proceso adquisitivo ${form.getFieldValue('concepto')}`}
          loading={loading}
        />
      </Spin>
    </Row>
  );
}

export default ProcesosAdquisitivos;
