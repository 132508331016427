import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Row,
  Spin,
} from 'antd';
import ModalDelete from 'components/ModalDelete';
import FormSubmitControls from 'components/FormSubmitControls';
import Table from 'components/Table';
import { formatReceived } from 'utils/formatValues';
import API from 'utils/api';
import { onError, onSuccess } from 'utils/handlers';
import Select from 'components/Select';
import { isMutable } from 'utils/estadosGlobales';
import NumericInput from 'components/NumericInput';
import currencyFormatter from 'utils/currencyFormatter';

const baseURI = 'contabilidad/desglose-de-concepto-de-ingresos/';

function DesgloseDeConceptosDeIngreso({
  ingreso_recaudado,
  conceptosDeIngreso,
  disabled,
}) {
  const {
    estadosGlobales,
  } = useSelector((state) => state.catalogs);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [mutable, setMutable] = useState(true);

  const fetchData = async () => {
    try {
      setLoading(true);
      if (ingreso_recaudado?.id) {
        const response = await API.get(baseURI, {
          params: { ingreso_recaudado: ingreso_recaudado.id },
        });
        if (response.status === 200) {
          setData(formatReceived(response.data));
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };
  useEffect(() => {
    fetchData();
    return () => API.source.cancel;
    // eslint-disable-next-line
  },[])

  const onCancel = () => {
    setVisible(false);
    setSelectedRowKeys([]);
    form.resetFields();
    setMutable(true);
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    form.setFieldsValue(record);
    setMutable(isMutable(record));
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      if (!selectedRowKeys.length) {
        const response = await API.post(baseURI, values);
        if (response?.status === 201) {
          onSuccess(response, 'Agregado correctamente');
          onCancel();
          await fetchData();
        }
      } else {
        const [key] = selectedRowKeys;
        const response = await API.patch(`${baseURI}${key}/`, values);
        if (response?.status === 200) {
          onSuccess(response, 'Actualizado correctamente');
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const columns = [
    {
      titleText: 'Concepto de Ingreso',
      dataIndex: 'concepto_de_ingreso',
      key: 'concepto_de_ingreso',
      width: 300,
      render: (id) => conceptosDeIngreso.find((e) => e.id === id)?.descripcion,
    },
    {
      titleText: 'Clave',
      dataIndex: 'clave',
      key: 'clave',
      width: 150,
    },
    {
      titleText: 'Descripción',
      dataIndex: 'descripcion',
      key: 'descripcion',
      width: 600,
    },
    {
      titleText: 'Clave Presupuestal',
      dataIndex: 'clave_presupuestal',
      key: 'clave_presupuestal',
      width: 400,
    },
    {
      titleText: 'Importe',
      dataIndex: 'importe',
      key: 'importe',
      width: 150,
      render: (e) => currencyFormatter(e || 0),
      align: 'right',
    },
    {
      titleText: 'IVA',
      dataIndex: 'iva',
      key: 'iva',
      width: 150,
      render: (e) => `${e || 0} %`,
      align: 'right',
    },
    {
      titleText: 'Total',
      dataIndex: 'total',
      key: 'total',
      width: 150,
      render: (e) => currencyFormatter(e || 0),
      align: 'right',
    },
  ];

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`${baseURI}${key}/`);
        if (response?.status === 204) {
          setVisibleAlert(false);
          onSuccess(response, 'Eliminado correctamente', 2.5);
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onClickAdd = async () => {
    setVisible(true);
  };

  const onClickEdit = async () => {
    try {
      setLoading(true);
      const [key] = selectedRowKeys;
      const match = [...data].find((e) => e.id === key);
      let output = {};
      if (match?.concepto_de_ingreso) {
        const criId = conceptosDeIngreso.find((e) => e.id === match.concepto_de_ingreso).clase_cri;

        const response = await API.get('configuraciones/presupuestos-anules-de-ingreso/', {
          params: {
            clase_cri: criId,
            object_id: ingreso_recaudado.unidad_responsable_id,
          },
        });
        if (response.status === 200 && response.data?.length) {
          const [{ clave_presupuestal_completa }] = response.data;
          output = {
            concepto_de_ingresos: match?.concepto_de_ingreso,
            clave_presupuestal: clave_presupuestal_completa,
          };
        } else {
          message.warn('No se encontró una clave presupuestal que coincidiera ');
          output = { concepto_de_ingresos: match?.concepto_de_ingreso };
        }
      }
      form.setFieldsValue({ ...match, ...output });
      setVisible(true);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const onBlurIva = () => {
    let { iva, importe } = form.getFieldsValue();
    if (iva && importe) {
      importe = typeof importe === 'string' ? parseFloat(importe) : importe;
      iva = typeof iva === 'string' ? parseFloat(iva) : iva;
      const total = ((importe * iva) / 100) + importe;
      if (!Number.isNaN(total)) {
        form.setFieldsValue({ total });
      }
    }
  };

  const onChangeConceptos = async (concepto_de_ingresos) => {
    try {
      setLoading(true);
      let output = {};
      if (concepto_de_ingresos) {
        const criId = conceptosDeIngreso.find((e) => e.id === concepto_de_ingresos).clase_cri;
        const resClavePresupuestal = await API
          .get('configuraciones/presupuestos-anules-de-ingreso/', {
            params: {
              clase_cri: criId,
              object_id: ingreso_recaudado.unidad_responsable_id,
              content_type: ingreso_recaudado.tipo_de_unidad_responsable,
            },
          });
        // console.log(resClavePresupuestal.data);
        if (resClavePresupuestal.status === 200 && resClavePresupuestal.data?.length) {
          const [{ clave_presupuestal_completa }] = resClavePresupuestal.data;
          output = { concepto_de_ingresos, clave_presupuestal: clave_presupuestal_completa };
        } else {
          message.warn('No se encontró una clave presupuestal que coincidiera ');
          output = { concepto_de_ingresos };
        }
        form.setFieldsValue(output);
      }

      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  return (
    <Row align="center" justify="center" className="container">
      <Col span={24}>
        <Spin tip="Cargando..." spinning={loading}>
          {!visible ? (
            <Table
              cols={columns}
              data={data}
              allowImport
              baseURI={baseURI}
              mobileColIndex={2}
              rowSelection={rowSelection}
              handleOnRowClick={handleOnRowClick}
              controls={{
                onClickAdd,
                onClickEdit,
                onClickDelete,
              }}
              loading={loading}
              disabled={disabled}
            />
          ) : (
            <Col span={24}>
              <Card
                className="form-container"
                title={(
                  <FormSubmitControls
                    label={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Detalle`}
                    onFinish={onFinish}
                    onCancel={onCancel}
                    loading={loading}
                    mutable={mutable}
                  />
                )}
                bordered={false}
              >
                <Form
                  layout="vertical"
                  form={form}
                  onFinish={onFinish}
                  initialValues={{ estados_globales: 1, ingreso_recaudado }}
                  scrollToFirstError
                  name="general"
                >
                  <Row gutter={10}>
                    <Form.Item name="ingreso_recaudado" hidden>
                      <Input />
                    </Form.Item>
                    <Col xs={24} sm={24} md={16}>
                      <Form.Item
                        name="concepto_de_ingreso"
                        rules={rules.required}
                        label="Concepto de ingresos"
                      >
                        <Select
                          keyLabelRender
                          dataSource={conceptosDeIngreso}
                          onChange={onChangeConceptos}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="clave"
                        rules={rules.required}
                        label="Clave"
                      >
                        <Input allowClear />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        name="clave_presupuestal"
                        rules={rules.required}
                        label="Clave Presupuestal"
                      >
                        <Input allowClear />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={16}>
                      <Form.Item
                        name="descripcion"
                        rules={rules.required}
                        label="Descripción"
                      >
                        <Input.TextArea autoSize={{ minRows: 4, maxRows: 4 }} allowClear />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <NumericInput
                        name="importe"
                        rules={rules.required}
                        label="Importe"
                        decimal
                        onBlur={onBlurIva}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <NumericInput
                        name="iva"
                        rules={rules.required}
                        label="IVA (%)"
                        decimal
                        onBlur={onBlurIva}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <NumericInput
                        name="total"
                        rules={rules.required}
                        label="Total"
                        decimal
                      />
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="estados_globales"
                        rules={rules.required}
                        label="Estado"
                      >
                        <Select
                          dataSource={estadosGlobales}
                          disabled={!selectedRowKeys.length}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item hidden>
                    <Button htmlType="submit" />
                  </Form.Item>
                </Form>
              </Card>
            </Col>
          )}
          <ModalDelete
            onDelete={deleteItem}
            onCancel={() => setVisibleAlert(false)}
            visible={visibleAlert}
            content={`el desglose ${form.getFieldValue('descripcion')}`}
          />
        </Spin>
      </Col>
    </Row>
  );
}

DesgloseDeConceptosDeIngreso.propTypes = {
  ingreso_recaudado: PropTypes.shape({
    id: PropTypes.number,
    unidad_responsable_id: PropTypes.number,
    tipo_de_unidad_responsable: PropTypes.number,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  conceptosDeIngreso: PropTypes.arrayOf(PropTypes.object).isRequired,
  disabled: PropTypes.bool.isRequired,
};
DesgloseDeConceptosDeIngreso.defaultProps = {
  ingreso_recaudado: null,
};
export default DesgloseDeConceptosDeIngreso;
