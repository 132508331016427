import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Redirect,
  Route,
} from 'react-router';

function AuthRoute({
  type,
  path,
  children,
}) {
  const { user, isAuthenticated } = useSelector(({ auth }) => auth);

  if (type === 'guest' && isAuthenticated && user) {
    return <Redirect to="/dashboard" />;
  }
  if (type === 'private' && !isAuthenticated && !user) {
    return <Redirect to="/inicio-de-sesion" />;
  }

  return (
    <Route path={path} exact>
      { children }
    </Route>
  );
}

AuthRoute.propTypes = {
  type: PropTypes.oneOf(['guest', 'private']),
  path: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
  ]).isRequired,
};

AuthRoute.defaultProps = {
  type: 'guest',
};

export default AuthRoute;
