/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Form,
  Button,
  Spin,
  Row,
  Col,
  Modal,
  TreeSelect,
} from 'antd';
import API from 'utils/api';
import { onError } from 'utils/handlers';
import FormSubmitControls from 'components/FormSubmitControls';
import Table from 'components/Table';
import ModalDelete from 'components/ModalDelete';
import Select from 'components/Select';
import { findItemNested } from 'utils/filters';

const baseURI = '/estructura-organizacional/clasificadores-de-fuentes-de-ingreso-ur/';

function ClasificadoresDeFuenteDeIngresos({
  proyecto,
  cogInterno,
  mutableParent,
  objectId: object_id_urs,
  contentType: content_type_urs,
  fuentesDeFinanciamientoInterno,
  tiposDeGasto,
  clasificacionesProgramatica,
}) {
  const timeoutFilter = useRef();
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);

  const filterTreeNode = (input, node) => {
    const title = node.title?.props.children.toLowerCase() || node.title;
    if (title && title.includes(input.toLowerCase())) {
      return true;
    }
    if (node.children) {
      return filterTreeNode(input, node.children);
    }
    return false;
  };

  const setTimeoutFilter = (input, node) => setTimeout(() => filterTreeNode(input, node), 1000);

  const filterTree = (input, node) => {
    clearTimeout(timeoutFilter.current);
    timeoutFilter.current = setTimeoutFilter(input, node);
    return timeoutFilter ? filterTreeNode(input, node) : true;
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      if (proyecto) {
        const response = await API.get(baseURI, {
          params: {
            proyecto,
          },
        });
        if (response?.status === 200) {
          setData(response.data);
        }
      } else {
        setData([]);
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchData();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, [proyecto]);

  const onCancel = () => {
    form.resetFields();
    setVisible(false);
    setSelectedRowKeys([]);
  };

  const onFinish = async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue();
      values.proyecto = proyecto;
      values.object_id_urs = object_id_urs;
      values.content_type_urs = content_type_urs;
      if (proyecto) {
        if (!selectedRowKeys.length) {
          const res = await API.post(baseURI, values);
          if (res?.status === 201) {
            onCancel();
            await fetchData();
          }
        } else {
          const [key] = selectedRowKeys;
          const res = await API.put(`${baseURI}${key}/`, values);
          if (res?.status === 200) {
            onCancel();
            await fetchData();
          }
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    const clone = { ...record };
    clone.cog = clone.cog.toString();
    clone.fuente_de_financiamiento = clone.fuente_de_financiamiento.toString();
    form.setFieldsValue(clone);
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const onClickEdit = () => {
    setVisible(true);
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const deleteItem = async () => {
    try {
      const [key] = selectedRowKeys;
      setLoading(true);
      if (proyecto) {
        const response = await API.delete(`${baseURI}${key}/`);
        if (response?.status === 204) {
          setVisibleAlert(false);
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
  };

  const getDescriptor = () => {
    const { cog, fuente_de_financiamiento } = form.getFieldsValue();
    const _cog = findItemNested(cogInterno, parseInt(cog, 10));
    const _fuente = findItemNested(
      fuentesDeFinanciamientoInterno,
      parseInt(fuente_de_financiamiento, 10),
    );
    return `${_cog?.concepto} - ${_fuente?.nombre}`.trim();
  };

  const columns = [
    {
      titleText: 'COG',
      key: 'cog',
      dataIndex: 'cog',
      width: 300,
      render: (id) => {
        const match = findItemNested(cogInterno, id);
        return `${match.clave} - ${match.concepto}`;
      },
    },
    {
      titleText: 'Fuente de financiamiento',
      key: 'fuente_de_financiamiento',
      dataIndex: 'fuente_de_financiamiento',
      width: 150,
      render: (id) => {
        const match = findItemNested(fuentesDeFinanciamientoInterno, id);
        return `${match.clave} - ${match.nombre}`;
      },
    },
  ];

  return (
    <Spin tip="Cargando..." spinning={loading}>
      <Table
        cols={columns}
        data={data}
        rowSelection={rowSelection}
        handleOnRowClick={handleOnRowClick}
        controls={{
          onClickAdd,
          onClickEdit,
          onClickDelete,
        }}
        allowAdd={mutableParent}
        allowDelete={mutableParent}
        allowSort={false}
        allowSearch={false}
        baseURI={baseURI}
        justImport
      />
      <Modal
        visible={visible}
        closable={false}
        maskClosable={!loading}
        keyboard={!loading}
        footer={null}
        title={(
          <FormSubmitControls
            label={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Clasificador de fuente de ingresos`}
            onFinish={mutableParent ? onFinish : null}
            onCancel={onCancel}
            loading={loading}
          />
        )}
        onCancel={onCancel}
        forceRender
        width={600}
        destroyOnClose
      >
        <Form
          form={form}
          layout="vertical"
          name="clasificador_fuente_ur"
          onFinish={onFinish}
          initialValues={{ estados_globales: 1 }}
        >
          <Row>
            <Col span={24}>
              <Form.Item
                label="COG"
                name="cog"
                rules={rules.required}
              >
                <TreeSelect
                  showSearch
                  showArrow
                  treeNodeFilterProp="title"
                  style={{ width: '100%' }}
                  dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                  treeData={cogInterno}
                  filterTreeNode={filterTree}
                  disabled={!mutableParent}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Fuente de financiamiento"
                name="fuente_de_financiamiento"
                rules={rules.required}
              >
                <TreeSelect
                  showSearch
                  showArrow
                  treeNodeFilterProp="title"
                  style={{ width: '100%' }}
                  dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                  treeData={fuentesDeFinanciamientoInterno}
                  filterTreeNode={filterTree}
                  disabled={!mutableParent}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="clasificaciones_programaticas"
                label="Clasificación Programática"
                rules={rules.required}
              >
                <TreeSelect
                  showSearch
                  multiple
                  showArrow
                  treeNodeFilterProp="title"
                  style={{ width: '100%' }}
                  dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                  treeData={clasificacionesProgramatica}
                  filterTreeNode={filterTree}
                  disabled={!mutableParent}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Tipos de Gasto"
                name="tipos_de_gastos"
                rules={rules.required}
              >
                <Select
                  mode="multiple"
                  labelProp="title"
                  dataSource={tiposDeGasto}
                  disabled={!mutableParent}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Estado"
                name="estados_globales"
                rules={rules.required}
              >
                <Select
                  disabled={!selectedRowKeys.length || !mutableParent}
                  dataSource={estadosGlobales}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item hidden>
            <Button htmlType="submit" />
          </Form.Item>
        </Form>
      </Modal>
      <ModalDelete
        onDelete={deleteItem}
        onCancel={() => setVisibleAlert(false)}
        visible={visibleAlert}
        content={`Clasificador de fuente de ingresos ${getDescriptor()}`}
        loading={loading}
      />
    </Spin>
  );
}

ClasificadoresDeFuenteDeIngresos.propTypes = {
  proyecto: PropTypes.number,
  contentType: PropTypes.number.isRequired,
  objectId: PropTypes.number.isRequired,
  cogInterno: PropTypes.arrayOf(PropTypes.object),
  fuentesDeFinanciamientoInterno: PropTypes.arrayOf(PropTypes.object),
  mutableParent: PropTypes.bool,
  tiposDeGasto: PropTypes.arrayOf(PropTypes.object),
  clasificacionesProgramatica: PropTypes.arrayOf(PropTypes.object),
};

ClasificadoresDeFuenteDeIngresos.defaultProps = {
  proyecto: undefined,
  cogInterno: [],
  fuentesDeFinanciamientoInterno: [],
  tiposDeGasto: [],
  clasificacionesProgramatica: [],
  mutableParent: true,
};

export default ClasificadoresDeFuenteDeIngresos;
