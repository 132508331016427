import React from 'react';
import SingleForm from 'components/SingleForm';
import { getCUCOPS } from 'api/almacen';

export const permissionCUCOP = {
  permissionModel: 'cucop',
};

// eslint-disable-next-line react/prop-types
function CUCOP({ permission }) {
  return (
    <SingleForm
      title="Tipo de operación"
      withoutState
      readOnly
      permission={permission}
      get={getCUCOPS}
    />
  );
}
export default CUCOP;
