import React, { useState, useEffect } from 'react';
import {
  Form,
  Row,
  Spin,
  Col,
  Input,
  Modal,
} from 'antd';

import { onError, onSuccess } from 'utils/handlers';
import API from 'utils/api';
import Table from 'components/Table';
import Select from 'components/Select';
import FormSubmitControls from 'components/FormSubmitControls';
import rutas from 'utils/rutas-endpoints';

function CatalogoOtrosDepositos() {
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visibleModalAgregar, setVisibleModalAgregar] = useState(false);
  const [dataGridCatalogos, setDataGridCatalogos] = useState([]);
  const [dataEstadosGlobales, setDataEstadosGlobales] = useState([]);

  const requiredRule = {
    required: true,
    message: 'El campo es requerido',
  };
  const rules = {
    required: [requiredRule],
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onCancel = () => {
    setVisibleModalAgregar(false);
    form.resetFields();
    setSelectedRowKeys([]);
  };

  useEffect(() => {
    fetchData();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    setTimeout(() => form.setFieldsValue(record));
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const consultarCatalogos = async () => {
    const res = await API.get(rutas.otrosDepositos);
    const catalogos = res.data;

    setDataGridCatalogos(catalogos);
  };

  const consultarEstadosGlobales = async () => {
    const res = await API.get(rutas.estadosGlobales);
    const estadosGlobales = res.data;
    const newEstadosGlobales = estadosGlobales.filter((item) => (item.descripcion === 'Activo' || item.descripcion === 'Inactivo'));

    setDataEstadosGlobales(newEstadosGlobales);
  };

  useEffect(() => {
    consultarCatalogos();
  }, []);

  useEffect(() => {
    consultarEstadosGlobales();
  }, [dataEstadosGlobales.length]);

  const onClickAdd = () => {
    setVisibleModalAgregar(true);
  };

  const onClickEdit = () => {
    setVisibleModalAgregar(true);
  };

  const onClickDelete = async () => {
    try {
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`${rutas.otrosDepositos} ${key}/`);

        if (response?.status === 204) {
          onSuccess(response, 'Eliminado Exitosamente!');
          onCancel();
          consultarCatalogos();
        }
      }
    } catch (error) {
      onError(error, setLoading);
    }
  };

  const columns = [
    {
      titleText: 'Concepto',
      dataIndex: 'concepto',
      key: 'concepto',
      width: 200,
    },
    {
      titleText: 'Estado',
      dataIndex: 'estado',
      key: 'estado',
      width: 200,
    },
  ];

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();

      const values = form.getFieldsValue();

      if (!selectedRowKeys.length) {
        const response = await API.post(rutas.otrosDepositos, values);

        if (response?.status === 201) {
          onSuccess(response, 'Agregado Correctamente!.');
          onCancel();
          consultarCatalogos();
        }
      } else {
        const [key] = selectedRowKeys;
        const response = await API.patch(`${rutas.otrosDepositos} ${key}/`, values);

        if (response.status === 200) {
          onSuccess(response, 'Actualizado Correctamente!.');
          form.resetFields();
          onCancel();
          consultarCatalogos();
        }
      }

      setLoading(false);
    } catch (error) {
      onError(error, setLoading);
    }
  };

  const onCancelModalSaldoInicial = () => {
    setVisibleModalAgregar(false);
  };
  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        <Table
          cols={columns}
          data={dataGridCatalogos}
          rowSelection={rowSelection}
          handleOnRowClick={handleOnRowClick}
          mobileColIndex={0}
          controls={{
            onClickAdd,
            onClickEdit,
            onClickDelete,
          }}
        />
        <Modal
          visible={visibleModalAgregar}
          closable={false}
          maskClosable={!loading}
          keyboard={!loading}
          footer={null}
          title={(
            <FormSubmitControls
              label={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Depósito`}
              onFinish={onFinish}
              onCancel={onCancelModalSaldoInicial}
              loading={loading}
            />
        )}
          onCancel={onCancelModalSaldoInicial}
          forceRender
        >
          <Form
            form={form}
            layout="vertical"
            name="otros_depositos"
            onFinish={onFinish}
            initialValues={{ estados_globales: 1 }}
          >
            <Col span={24}>
              <Form.Item
                name="concepto"
                label="Concepto"
                rules={rules.required}
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="estados_globales"
                label="Estado"
              >
                <Select dataSource={dataEstadosGlobales} />
              </Form.Item>
            </Col>
          </Form>
        </Modal>
      </Spin>
    </Row>
  );
}

export default CatalogoOtrosDepositos;
