import React from 'react';
import PropTypes from 'prop-types';
import SingleForm from 'components/SingleForm';

export const model = 'observaciondelarequisicion';
function Observaciones({
  requisicionId,
  disabled,
  permission,
}) {
  const baseURI = `/adquisiciones/requisiciones/${requisicionId}/observaciones/`;
  return (
    <SingleForm
      baseURI={baseURI}
      title="Observación"
      descripcionTextArea
      readOnly={disabled}
      permission={permission}
    />
  );
}

Observaciones.propTypes = {
  requisicionId: PropTypes.number,
  disabled: PropTypes.bool.isRequired,
  permission: PropTypes.shape({}).isRequired,
};

Observaciones.defaultProps = {
  requisicionId: 0,
};

export default Observaciones;
