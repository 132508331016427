import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Spin,
  Form,
  Card,
  Input,
  Button,
  DatePicker,
  message,
  Typography,
  Modal,
} from 'antd';

import Moment from 'moment';
import getFormattedValues, { hasFiles, formatReceived } from '../../../utils/formatValues';
import FormSubmitControls from '../../../components/FormSubmitControls';
import API from '../../../utils/api';
import ModalDelete from '../../../components/ModalDelete';
import Table from '../../../components/Table';
import Uploader from '../../../components/Uploader';
import Select from '../../../components/Select';
import { isMutable, isAuthorized } from '../../../utils/estadosGlobales';
import { onError, onSuccess } from '../../../utils/handlers';

export const permissionPeriodoFiscal = {
  permissionModel: 'periodofiscal',
};

const { Text } = Typography;

const baseURI = 'configuraciones/periodos-fiscales/';

// eslint-disable-next-line react/prop-types
function PeriodoFiscal({ permission }) {
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [disabledVigente, setDisabledVigente] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [visibleConfirm, setVisibleConfirm] = useState(false);

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await API.get(baseURI);
      setData(formatReceived(res.data));
      setLoading(false);
    } catch (error) {
      onError(error, setLoading);
    }
  };

  useEffect(() => {
    fetchData();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setVisibleConfirm(false);
    setVisible(false);
    setSelectedRowKeys([]);
    setDisabledVigente(false);
    setDisabled(false);
    form.resetFields();
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      values.periodo = values.periodo?.format('YYYY');
      const files = ['documento_anexo_PDF', 'documento_anexo_word'];
      const config = hasFiles(values, files) ? {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      } : {};
      const formattedvalues = getFormattedValues(values, {
        files,
        formData: config.headers,
        clean: true,
      });
      if (!selectedRowKeys.length) {
        const res = await API.post(baseURI, formattedvalues, config);
        if (res?.status === 201) {
          onSuccess(res, 'Agregado correctamente', 2.5);
          onCancel();
          await fetchData();
        }
      } else {
        const [key] = selectedRowKeys;
        const res = await API.put(`${baseURI}${key}/`, formattedvalues, config);
        if (res?.status === 200) {
          onSuccess(res, 'Actualizado correctamente', 2.5);
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (error) {
      onError(error, setLoading);
    }
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`${baseURI}${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente', 2.5);
          onCancel();
          setVisibleAlert(false);
        }
        await fetchData();
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    form.setFieldsValue(record);
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const onClickEdit = () => {
    try {
      setVisible(true);
      const [key] = selectedRowKeys;
      const match = data.find((e) => e.id === key);
      const currYear = new Moment().format('YYYY');
      setDisabledVigente(match.periodo.toString() !== currYear);
      setDisabled(!isMutable(match) || match.vigente);
      form.setFieldsValue({
        periodo: new Moment(match.periodo, 'YYYY'),
      });
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onClickDelete = () => {
    const [key] = selectedRowKeys;
    const match = data.find((e) => e.id === key);
    if (isMutable(match.estados_globales) && !match.vigente) {
      setVisibleAlert(true);
    } else if (match.vigente) {
      message.info('No se puede eliminar el Ejercicio vigente');
    } else if (isAuthorized(match)) {
      message.info('No se puede eliminar un Ejercicio autorizado');
    } else {
      message.info('No se puede eliminar un Ejercicio cancelado');
    }
  };

  const columns = [
    {
      titleText: 'Periodo',
      dataIndex: 'periodo',
      key: 'periodo',
      width: 100,
    },
    {
      titleText: 'Identificador',
      dataIndex: 'identificador',
      key: 'identificador',
      width: 150,
    },
    {
      titleText: 'Fecha Inicial',
      dataIndex: 'fecha_inicial',
      key: 'fecha_inicial',
      width: 150,
      render: (val) => val?.format('DD/MM/YYYY'),
    },
    {
      titleText: 'Fecha Final',
      dataIndex: 'fecha_final',
      key: 'fecha_final',
      width: 150,
      render: (val) => val?.format('DD/MM/YYYY'),
    },
    {
      titleText: 'Fecha Autorización',
      dataIndex: 'fecha_autorizacion',
      key: 'fecha_autorizacion',
      width: 180,
      render: (val) => val?.format('DD/MM/YYYY'),
    },
    {
      titleText: 'Vigente',
      dataIndex: 'vigente',
      key: 'vigente',
      width: 100,
      render: (val) => (val ? 'Si' : 'No'),
    },
  ];

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
    fecha_autorizacion: [
      {
        required: true,
        message: 'El campo es requerido',
      },
      {
        validator: async () => {
          const { fecha_inicial, fecha_autorizacion } = form.getFieldsValue();
          if (fecha_inicial && fecha_autorizacion
            && fecha_inicial.diff(fecha_autorizacion, 'days') <= 0) {
            throw new Error('Este campo debe ser menor que la Fecha Inicial');
          }
        },
      },
    ],
  };

  const setDates = (val) => {
    const year = val?.format('YYYY');
    if (year) {
      const currYear = new Moment().format('YYYY');
      const filtered = [...data].filter((e) => e.periodo.toString() === year);
      form.setFieldsValue({
        fecha_inicial: new Moment(`${year}-01-01`),
        fecha_final: new Moment(`${year}-12-31`),
        fecha_autorizacion: new Moment(`${year - 1}-12-31`),
        identificador: filtered.length + 1,
      });
      const { vigente } = form.getFieldsValue();
      if (vigente && year !== currYear) {
        form.setFieldsValue({ vigente: false });
      }
      setDisabledVigente(year !== currYear);
    } else {
      form.resetFields([
        'fecha_inicial',
        'fecha_final',
        'fecha_autorizacion',
        'identificador',
      ]);
    }
  };

  const handleOnFinish = async () => {
    try {
      await form.validateFields();
      const vigente = form.getFieldValue('vigente');
      if (vigente) {
        setVisibleConfirm(true);
      } else {
        onFinish();
      }
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const getDisplayName = () => {
    const [key] = selectedRowKeys;
    const { periodo, identificador } = key
      ? data.find((e) => e.id === key) : form.getFieldsValue();
    return `${periodo?.format?.('YYYY') || periodo} - ${identificador}`;
  };

  return (
    <Row align="center" justify="center" className="container">
      <Col span={24}>
        <Spin tip="Cargando..." spinning={loading}>
          {!visible ? (
            <Table
              cols={columns}
              data={data}
              permission={permission}
              handleOnRowClick={handleOnRowClick}
              rowSelection={rowSelection}
              controls={{
                onClickAdd,
                onClickEdit,
                onClickDelete,
              }}
              loading={loading}
              rowKey="id"
            />
          ) : (
            <Col span={24} style={{ height: '100%' }}>
              <Card
                className="form-container"
                title={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Ejercicio`}
                extra={(
                  <FormSubmitControls
                    onFinish={handleOnFinish}
                    onCancel={onCancel}
                    mutable={!disabled}
                  />
                )}
                bordered={false}
              >
                <Form
                  layout="vertical"
                  form={form}
                  onFinish={handleOnFinish}
                  scrollToFirstError
                  initialValues={{ vigente: false }}
                >
                  <Row gutter={10}>
                    <Col
                      xs={24}
                      sm={24}
                      md={8}
                    >
                      <Form.Item
                        name="periodo"
                        label="Periodo"
                        rules={rules.required}
                        hasFeedback
                      >
                        <DatePicker
                          disabled={selectedRowKeys.length}
                          onChange={setDates}
                          picker="year"
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      xs={24}
                      sm={24}
                      md={8}
                    >
                      <Form.Item
                        name="identificador"
                        label="Identificador"
                        rules={rules.required}
                        hasFeedback
                      >
                        <Input allowClear disabled={disabled} />
                      </Form.Item>
                    </Col>
                    <Col
                      xs={24}
                      sm={24}
                      md={8}
                    >
                      <Form.Item
                        name="fecha_inicial"
                        label="Fecha inicial"
                        rules={rules.required}
                        hasFeedback
                      >
                        <DatePicker
                          placeholder=""
                          format="DD/MM/YYYY"
                          disabled
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      xs={24}
                      sm={24}
                      md={8}
                    >
                      <Form.Item
                        name="fecha_final"
                        label="Fecha final"
                        rules={rules.required}
                        hasFeedback
                      >
                        <DatePicker
                          placeholder=""
                          format="DD/MM/YYYY"
                          disabled
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      xs={24}
                      sm={24}
                      md={8}
                    >
                      <Form.Item
                        name="fecha_autorizacion"
                        label="Fecha de autorización"
                        rules={rules.fecha_autorizacion}
                        hasFeedback
                      >
                        <DatePicker
                          placeholder=""
                          format="DD/MM/YYYY"
                          disabled={disabled}
                          disabledDate={(d) => parseInt(d.format('YYYY'), 10)
                            !== parseInt(form.getFieldValue('periodo')?.format('YYYY'), 10) - 1}
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      xs={24}
                      sm={24}
                      md={8}
                    >
                      <Form.Item
                        name="vigente"
                        label="Vigente"
                        hasFeedback
                        rules={rules.required}
                      >
                        <Select disabled={disabled || disabledVigente} trueFalse />
                      </Form.Item>
                    </Col>
                    <Uploader
                      title="Documento Anexo PDF"
                      limitMB={50}
                      file={form.getFieldValue('documento_anexo_PDF')}
                      formItemName="documento_anexo_PDF"
                      allowedExtensions={['pdf']}
                      disabled={disabled}
                    />
                    <Uploader
                      title="Documento Anexo Word"
                      limitMB={50}
                      file={form.getFieldValue('documento_anexo_word')}
                      formItemName="documento_anexo_word"
                      allowedExtensions={['doc', 'docx']}
                      disabled={disabled}
                    />
                  </Row>
                  <Form.Item hidden>
                    <Button htmlType="submit" />
                  </Form.Item>
                </Form>
              </Card>
            </Col>
          )}
          <Modal
            title={(
              <Row justify="space-between">
                Confirmación
                <FormSubmitControls
                  onCancel={() => setVisibleConfirm(false)}
                  onFinish={onFinish}
                />
              </Row>
            )}
            visible={visibleConfirm}
            footer={null}
            closable={false}
          >
            <Text style={{ fontSize: 16 }}>
              {`¿Desea registrar el Ejercicio ${getDisplayName()} como vigente?`}
            </Text>
          </Modal>
          <ModalDelete
            onDelete={deleteItem}
            onCancel={() => {
              setVisibleAlert(false);
              setSelectedRowKeys([]);
            }}
            visible={visibleAlert}
            content={`Ejercicio ${getDisplayName()}`}
          />
        </Spin>
      </Col>
    </Row>
  );
}

export default PeriodoFiscal;
