import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Spin,
  Card,
  Tabs,
  TreeSelect,
  Checkbox,
  message,
  Modal,
} from 'antd';
import {
  ImportOutlined,
  DollarOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';
import API from 'utils/api';
import FormSubmitControls from 'components/FormSubmitControls';
import Table from 'components/Table';
import ModalDelete from 'components/ModalDelete';
import fetchSome from 'utils/fetchSome';
import { onError, onSuccess } from 'utils/handlers';
import {
  SET_NATURALEZAS_DE_INGRESO,
  SET_CLASIFICADORES_ECONOMICOS,
  SET_FUENTES_DE_INGRESO,
  SET_CONFIG_MASCARA_CONTABLE,
  SET_URS,
} from 'store/reducers/catalogs/index';
import Select from 'components/Select';
import { findItemNested } from 'utils/filters';
import { isMutable } from 'utils/estadosGlobales';
import makeTree from 'utils/makeTree';
import { InputSearchPro } from 'components/InputSearch';
import FormItemLabel from 'components/FormItemLabel';
import BusquedaClavesContables from 'components/BusquedaClavesContables';

export const permissionConceptoDeIngreso = {
  permissionModel: 'conceptodeingreso',
};

const { TabPane } = Tabs;
const baseURI = 'configuraciones/conceptos-de-ingresos/';

// eslint-disable-next-line react/prop-types
function ConceptoDeIngreso({ permission }) {
  const timeoutFilter = useRef();
  const user = useSelector(({ auth }) => auth.user);
  const collapsedSidebar = useSelector(({ app }) => app.collapsedSidebar);
  const periodoFiscalSelected = useSelector(({ auth }) => auth.periodoFiscalSelected);
  const {
    fuentesDeIngreso,
    naturalezasDeIngreso,
    clasificadoresEconomicos,
    configMascaraContable,
    estadosGlobales,
  } = useSelector((state) => state.catalogs);
  const urs = useSelector(({ catalogs }) => catalogs.urs);
  const [form] = Form.useForm();
  const [formCanalesDePago] = Form.useForm();
  const [formContabilidad] = Form.useForm();
  const esIngresoPresupuestal = Form.useWatch('es_ingreso_presupuestal', form) || false;
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);
  const [clases, setClases] = useState([]);
  const [fundamentos, setFundamentos] = useState([]);
  const [currentTabKey, setCurrentTabKey] = useState('general');
  const [mutable, setMutable] = useState(true);
  const [visibleBusquedaCuentas, setVisibleBusquedaCuentas] = useState(false);
  const [selectedClave, setSelectedClave] = useState();

  const [cuentasContables, setCuentasContables] = useState([]);

  const [padrones, setPadrones] = useState([]);
  const [canalesDePago, setCanalesDePago] = useState([]);

  const [flatFuentes, setFlatFuentes] = useState([]);
  const [fuentes, setFuentes] = useState([]);
  const [cuentaContableFound, setCuentaContableFound] = useState([]);
  const [mascara, setMascara] = useState();
  const [separador, setSeparador] = useState();
  const [pattern, setPattern] = useState();

  const [treeData, setTreeData] = useState([]);
  const [contentTypes, setContentTypes] = useState([]);

  const onClickTitle = (e) => {
    const element = e.target;
    if (element) {
      let parent = element.parentElement;
      parent = parent ? parent.parentElement : null;
      if (parent) {
        const clickableSpan = parent.previousSibling;
        if (clickableSpan) {
          clickableSpan.click();
        }
      }
    }
  };

  const renderTitle = (title, bold) => (
    // eslint-disable-next-line
    <span onClick={onClickTitle} className={bold ? 'bold' : ''}>
      {title}
    </span>
  );

  const normalizeData = (
    _data = [],
    excludeLvl3 = false,
    currLvl = 1,
  ) => _data.map((item) => {
    const clone = { ...item };
    const children = normalizeData(!excludeLvl3
      || currLvl !== 2 ? clone.children : [], excludeLvl3, currLvl + 1);
    const selectable = currLvl === 3;
    if (!children?.length) {
      delete clone.children;
      return ({
        ...clone,
        key: `${clone.clave}`,
        title: renderTitle(`${clone.clave} - ${clone.denominacion}`, !selectable),
        value: clone.clave,
        selectable,
      });
    }
    return ({
      ...clone,
      key: `${clone.clave}`,
      children,
      title: renderTitle(`${clone.clave} - ${clone.denominacion}`, !selectable),
      value: clone.clave,
      selectable,
    });
  });

  const normalizeDataUrs = (_data) => {
    if (_data) {
      const output = _data.map((item) => {
        const clone = { ...item };
        const unidad_responsable = normalizeDataUrs(clone.unidad_responsable);
        const centro_de_costo = normalizeDataUrs(clone.centro_de_costo);
        if (!unidad_responsable.length && !centro_de_costo.length) {
          delete clone.unidad_responsable;
          delete clone.centro_de_costo;
          return ({
            title: clone.descripcion,
            value: `${clone.id}/${clone.nivel}`,
          });
        }
        if (unidad_responsable) {
          return ({
            title: clone.descripcion,
            value: `${clone.id}/${clone.nivel}`,
            children: unidad_responsable,
            // disabled: true,
          });
        }
        return ({
          title: clone.descripcion,
          value: `${clone.id}/${clone.nivel}`,
          children: centro_de_costo,
          // disabled: true,
        });
      });
      return output;
    }
    return false;
  };

  const normalize = (_data = []) => _data.map((item) => {
    const clone = { ...item };
    const children = clone.children?.length && !clone.seleccionable
      ? normalize(clone.children) : [];
    if (!children?.length) {
      delete clone.children;
      return ({
        ...clone,
        key: clone.clave,
        value: clone.clave,
        clave: clone.clave,
        seleccionable: !clone.seleccionable,
        disabled: !clone.seleccionable,
        title: renderTitle(`${clone.clave} - ${clone.nombre}`, !clone.seleccionable),
      });
    }
    return ({
      ...clone,
      key: `${clone.clave}_padre`,
      value: `${clone.clave}_padre`,
      children,
      disabled: !clone.seleccionable,
      seleccionable: clone.seleccionable,
      title: renderTitle(`${clone.clave} - ${clone.nombre}`, !clone.seleccionable),
    });
  });

  const fetchData = async () => {
    try {
      setLoading(true);
      if (periodoFiscalSelected) {
        const res = await API.get(baseURI, {
          params: {
            periodo_fiscal: periodoFiscalSelected?.id,
          },
        });
        setTimeout(() => {
          setData(res.data);
        });
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const setConfigsMask = (config) => {
    setMascara(config?.mascara);
    const separator = config?.separador;
    setSeparador(separator);
    const _segmentos = config?.mascara?.split(separator) || [];
    if (_segmentos?.length) {
      // eslint-disable-next-line prefer-template
      const patt = _segmentos.reduce((acum, curr, idx) => `${acum}[0-9]{${curr.length}}`
        + (idx !== _segmentos.length - 1 ? separator : '$'), '^');
      const regExp = new RegExp(`${patt}`);
      setPattern(regExp);
    }
  };

  const fetchAll = async () => {
    try {
      setLoading(true);

      if (configMascaraContable) {
        setConfigsMask(configMascaraContable);
      } else {
        await fetchSome('configuraciones/configuraciones-de-mascaras/', SET_CONFIG_MASCARA_CONTABLE);
        const resConfig = await API.get('configuraciones/configuraciones-de-mascaras/');
        setConfigsMask(resConfig.data[0]);
      }

      if (!naturalezasDeIngreso?.length) {
        await fetchSome('/catalogos/naturalezas-de-ingreso/', SET_NATURALEZAS_DE_INGRESO);
      }

      const resClases = await API.get('/contabilidad/cri/');
      setClases(normalizeData(resClases.data) || []);

      const resFundaments = await API.get('configuraciones/fundamentos-legales/');
      setFundamentos(resFundaments.data || []);

      if (!fuentesDeIngreso?.length) {
        await fetchSome('catalogos/fuentes-de-ingreso/', SET_FUENTES_DE_INGRESO);
      }

      if (!clasificadoresEconomicos?.length) {
        await fetchSome('catalogos/clasificadores-economicos/', SET_CLASIFICADORES_ECONOMICOS);
      }

      if (!urs?.length) {
        await fetchSome('catalogos/content-types-ur', SET_URS);
      }

      const resCuentas = await API.get('contabilidad/cuentas-contables/');
      setCuentasContables(resCuentas.data || []);

      const resPadrones = await API.get('catalogos/padrones/');
      setPadrones(resPadrones.data);

      const resFF = await API.get('contabilidad/clasificaciones-de-fuentes-de-financiamiento/');
      setFlatFuentes(resFF.data);
      const fuentesFTree = makeTree(resFF.data);
      setFuentes(normalize(fuentesFTree));

      const resUR = await API.get('estructura-organizacional/unidades-de-trabajo');
      const formattedUr = normalizeDataUrs(resUR.data);
      setTreeData(formattedUr);
      const resContentTypes = await API.get('catalogos/content-types-ur');
      setContentTypes(resContentTypes.data);

      const resCanalesDePago = await API.get('/configuraciones/canales-de-pago/');
      const resCanalPadre = await API.get('/catalogos/canales-de-pago/');
      setCanalesDePago(resCanalesDePago.data.map((e) => ({
        ...e,
        descripcion: resCanalPadre.data.find((i) => e.canal_padre === i.id)?.nombre,
      })) || []);

      await fetchData();
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    formContabilidad.resetFields();
    formCanalesDePago.resetFields();
    setCurrentTabKey('general');
    setSelectedRowKeys([]);
    setMutable(true);
    setCuentaContableFound([]);
  };

  const getURValuesToSend = () => {
    const unidad_responsable = form.getFieldValue('unidad_responsable');
    if (unidad_responsable) {
      const [object_id, nivel] = unidad_responsable.split('/');
      const content_type = contentTypes.find((ct) => ct.nivel.toString() === nivel).id;
      return { object_id, content_type };
    }
    return { object_id: undefined, content_type: undefined };
  };

  const getURForTreeSelect = (_data) => {
    if (_data.unidad_responsable && _data.unidad_responsable) {
      const { nivel } = contentTypes.find((ct) => ct.id === _data.unidad_responsable);
      const { unidad_responsable_content_id } = _data;
      form.setFieldsValue({ unidad_responsable: `${unidad_responsable_content_id}/${nivel}` });
    }
  };
  useEffect(() => {
    onCancel();
    setTimeout(fetchAll);

    return () => API.tryCancel;
    // eslint-disable-next-line
  }, [periodoFiscalSelected]);

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      // TODO averiguar a fondo porque la linea de abajo hace que truene la app
      // await formContabilidad.validateFields();
      await formCanalesDePago.validateFields();
      const contentValues = getURValuesToSend();
      const values = {
        ...form.getFieldsValue(),
        ...formContabilidad.getFieldsValue(),
        ...formCanalesDePago.getFieldsValue(),
        unidad_responsable: contentValues?.content_type,
        unidad_responsable_content_id: contentValues?.object_id,
        periodo_fiscal: periodoFiscalSelected?.id,
      };
      values.clase_cri = findItemNested(clases, values.clase_cri, 'clave')?.id;
      values.fuente_de_financiamiento = flatFuentes
        .find((e) => e.clave === values.fuente_de_financiamiento)?.id;
      if (!selectedRowKeys.length) {
        const response = await API.post(baseURI, values);
        if (response?.status === 201) {
          onSuccess(response, 'Agregado correctamente');
          onCancel();
          await fetchData();
        }
      } else {
        const [key] = selectedRowKeys;
        await form.validateFields();
        const response = await API.put(`${baseURI}${key}/`, values);
        if (response?.status === 200) {
          onSuccess(response, 'Actualizado correctamente');
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading, [form, formContabilidad, formCanalesDePago], setCurrentTabKey);
    }
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`${baseURI}${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          onCancel();
          await fetchData();
          setVisibleAlert(false);
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    setTimeout(() => {
      form.setFieldsValue(record);
      // formContabilidad.setFieldsValue(record);
      formCanalesDePago.setFieldsValue(record);
    });
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const onClickEdit = async () => {
    try {
      setLoading(true);
      const [key] = selectedRowKeys;
      const match = data.find((e) => e.id === key);
      let clase_cri;
      if (match.clase_cri) {
        const resClaseCri = await API.get(`/configuraciones/clases/${match?.clase_cri}`);
        const matchCri = findItemNested(clases, resClaseCri.data.clave, 'clave');
        clase_cri = matchCri;
      }
      let fuente_de_financiamiento;
      if (match?.fuente_de_financiamiento) {
        fuente_de_financiamiento = flatFuentes
          .find((e) => e.id === match.fuente_de_financiamiento)?.clave;
      }
      if (match?.cuenta_contable) {
        const resCC = await API.get(`contabilidad/cuentas-contables/${match.cuenta_contable}`);
        setTimeout(() => {
          form.setFieldsValue({
            cuenta_contable: match.cuenta_contable,
          });
        });
        setCuentaContableFound([resCC.data]);
      }
      setTimeout(() => {
        form.setFieldsValue({
          ...match,
          // clase_cri: matchCri?.value,
          clase_cri,
          fuente_de_financiamiento,
        });
        getURForTreeSelect(match);
        formContabilidad.setFieldsValue(match);
        formCanalesDePago.setFieldsValue(match);
      });

      setMutable(isMutable(match));
      setVisible(true);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const requiredRule = {
    required: true,
    message: 'El campo es requerido',
  };

  const rules = {
    required: [requiredRule],
    cuenta_contable__clave: [
      {
        validator: async (rule, value) => {
          if (value && pattern && (!pattern.test(value))) {
            throw new Error('Introduzca una clave válida');
          }
        },
      },
    ],
  };

  const render = (val) => (val ? 'Si' : 'No');

  const columns = [
    {
      titleText: 'Clave',
      dataIndex: 'clave',
      key: 'clave',
      width: 200,
    },
    {
      titleText: 'Nombre',
      dataIndex: 'descripcion',
      key: 'descripcion',
      width: 300,
    },
    {
      titleText: 'Clasificador Rubro de Ingreso',
      dataIndex: 'clase_cri',
      key: 'clase_cri',
      width: 300,
      render: (val) => {
        const match = findItemNested(clases, val);
        return `${match?.clave || ''} - ${match?.denominacion || ''}`;
      },
    },

    {
      titleText: 'Fuente de financiamiento',
      key: 'fuente_de_financiamiento',
      dataIndex: 'fuente_de_financiamiento',
      width: 300,
      render: (val) => {
        const match = flatFuentes.find((e) => e.id === val);
        if (match) {
          return `${match?.clave || ''} - ${match?.nombre || ''}`;
        }
        return '-';
      },
    },
    {
      titleText: 'Cuenta Contable',
      dataIndex: 'cuenta_contable',
      key: 'cuenta_contable',
      width: 300,
      render: (val) => cuentasContables.find((e) => e.id === val)?.clave,
    },
    {
      titleText: 'Nombre Cuenta Contable',
      dataIndex: 'cuenta_contable',
      key: 'cuenta_contable',
      width: 200,
      render: (val) => cuentasContables.find((e) => e.id === val)?.nombre,
    },
    {
      titleText: 'Causa IVA',
      dataIndex: 'causa_iva',
      key: 'causa_iva',
      width: 100,
      render,
    },
    {
      titleText: 'Observaciones',
      dataIndex: 'observaciones',
      key: 'observaciones',
      width: 300,
    },
    {
      titleText: 'Estado',
      dataIndex: 'estados_globales',
      key: 'estados_globales',
      width: 150,
      render: (val) => (estadosGlobales.find((item) => item.id === val)?.descripcion),
    },
  ];

  const createClave = () => {
    if (!selectedRowKeys.length) {
      let newClave;
      const clone = [...data];
      const { clase_cri } = form.getFieldsValue();
      const match = findItemNested(clases, clase_cri, 'key');
      if (match) {
        newClave = `${match.clave}${clone?.length}`;
        const matchConcepto = clone.find((e) => e.clave === newClave);
        if (matchConcepto) {
          newClave = `${match.clave}${clone.length + 1}`;
        }
        form.setFieldsValue({ clave: newClave });
      }
    }
  };

  const filterTreeNode = (input, node) => {
    const title = node.title?.props.children.toLowerCase() || node.title;
    if (title && title.includes(input.toLowerCase())) {
      return true;
    }
    if (node.children) {
      return filterTreeNode(input, node.children);
    }
    return false;
  };

  const setTimeoutFilter = (input, node) => setTimeout(() => filterTreeNode(input, node), 1000);

  const filterTree = (input, node) => {
    clearTimeout(timeoutFilter.current);
    timeoutFilter.current = setTimeoutFilter(input, node);
    return timeoutFilter ? filterTreeNode(input, node) : true;
  };

  const resetAndValidateCuentaContable = () => {
    setCuentaContableFound([]);
    formContabilidad.resetFields(['cuenta_contable']);
    formContabilidad.validateFields(['cuenta_contable']);
  };

  const fetchCuentaContable = async () => {
    try {
      form.validateFields(['clave_contable__clave']);
      const clave = form.getFieldValue('clave_contable__clave');
      if (clave) {
        setLoading(true);
        const res = await API.get('contabilidad/cuentas-contables/', {
          params: {
            // clave_contable: clave,
            clave,
            // tipo_de_cuenta_contable: 1,
            // tipo: 4,
          },
        });
        if (res?.status === 200 && res.data?.length) {
          const claveContable = res.data[0];
          setCuentaContableFound([claveContable]);
          form.setFieldsValue({
            cuenta_contable: claveContable.id,
          });
          setTimeout(() => {
            form.validateFields(['cuenta_contable']);
          });
        } else {
          message.info('No se encontraron coincidencias');
          setCuentaContableFound([]);
        }
        setLoading(false);
      }
    } catch (err) {
      onError(err, setLoading);
      setCuentaContableFound([]);
    }
  };

  const onChangeClaveContable = (e) => {
    const { target: { value }, keyCode } = e;
    let val = value;
    if (keyCode) {
      const char = String.fromCharCode(keyCode).toLowerCase();
      val += char;
    }
    if (mascara.charAt(val.length) === separador) {
      val += separador;
    }
    if (val.length === mascara?.length) {
      setTimeout(fetchCuentaContable);
    } else if (!val?.length) {
      resetAndValidateCuentaContable();
    }
    setTimeout(() => {
      form.setFieldsValue({ clave_contable__clave: val });
    });
  };

  const onFinishBusqueda = () => {
    if (selectedClave) {
      setCuentaContableFound([selectedClave]);
      setTimeout(() => {
        form.setFieldsValue({
          cuenta_contable: selectedClave.id,
        });
      });
      setVisibleBusquedaCuentas(false);
    } else {
      message.info('No ha seleccionado una Clave Contable');
    }
  };
  const authorizeAll = async () => {
    setLoading(true);
    const promises = data.map((e) => API.patch(`${baseURI}${e.id}/`, { estados_globales: 4 }));
    await Promise.all(promises);
    await fetchData();
    setLoading(false);
  };

  return (
    <Row align="middle" justify="center" className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible ? (
          <Table
            cols={columns}
            permission={permission}
            data={data}
            handleOnRowClick={handleOnRowClick}
            rowSelection={rowSelection}
            controls={{
              onClickAdd,
              onClickEdit,
              onClickDelete,
            }}
            customActions={[
              user?.email.includes('sigob') && {
                icon: <CheckCircleOutlined />,
                text: 'Autorizar Todos',
                onClick: authorizeAll,
                disabledBySelection: false,
              },
            ]}
            loading={loading}
            rowKey="id"
            baseURI={baseURI}
            allowImport
          />
        ) : (
          <Card
            className="form-container"
            title={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Concepto de Ingresos`}
            extra={(
              <FormSubmitControls
                onFinish={onFinish}
                onCancel={onCancel}
                mutable={mutable}
                baseURI={baseURI}
                loading={loading}
                selectedRowKeys={selectedRowKeys}
                allowAuthorize
                allowCancel
                callback={(estados_globales) => {
                  const [key] = selectedRowKeys;
                  const normalized = data.map((e) => {
                    if (e.id === key) {
                      setMutable(false);
                      return {
                        ...e,
                        estados_globales,
                      };
                    }
                    return e;
                  });
                  setData(normalized);
                  form.setFieldsValue({ estados_globales });
                }}
              />
            )}
            bordered={false}
          >
            <Tabs
              onChange={setCurrentTabKey}
              activeKey={currentTabKey}
              defaultActiveKey="general"
              type="card"
            >
              <TabPane
                tab={(
                  <span>
                    <ImportOutlined />
                    General
                  </span>
                )}
                key="general"
                forceRender
              >
                <Form
                  layout="vertical"
                  name="general"
                  form={form}
                  onFinish={onFinish}
                  scrollToFirstError
                  initialValues={{
                    estados_globales: 1,
                    fuente_de_ingreso: 3,
                    clasificador_economico: 1,
                    naturaleza_de_ingresos: 2,
                    es_ingreso_presupuestal: true,
                    causa_iva: true,
                  }}
                >
                  <Row gutter={10}>
                    <Col
                      xs={24}
                      sm={24}
                      md={8}
                    >
                      <Form.Item
                        name="es_ingreso_presupuestal"
                        label={collapsedSidebar ? '¿Es ingreso presupuestal?' : '¿Es presupuestal?'}
                        hasFeedback
                        rules={rules.required}
                      >
                        <Select disabled={!mutable} trueFalse />
                      </Form.Item>
                    </Col>
                    <Col
                      xs={24}
                      sm={24}
                      md={8}
                    >
                      <Form.Item
                        rules={rules.required}
                        name="clave"
                        label="Clave"
                        hasFeedback
                      >
                        <Input allowClear disabled={selectedRowKeys.length || !mutable} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        rules={rules.required}
                        name="descripcion"
                        label="Nombre"
                        hasFeedback
                      >
                        <Input
                          disabled={!mutable}
                          allowClear
                        />
                      </Form.Item>
                    </Col>
                    {esIngresoPresupuestal && (
                    <Col
                      xs={24}
                      sm={24}
                      md={8}
                    >
                      <Form.Item
                        name="clase_cri"
                        label="CRI"
                        hasFeedback
                      >
                        <TreeSelect
                          onSelect={createClave}
                          showSearch
                          treeNodeFilterProp="title"
                          style={{ width: '100%' }}
                          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                          treeData={clases}
                          allowClear
                          disabled={!mutable}
                          filterTreeNode={filterTree}
                        />
                      </Form.Item>
                    </Col>
                    )}

                    {esIngresoPresupuestal && (
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          label="Unidad Responsable / Operativa / Centro de Costo"
                          name="unidad_responsable"
                        >
                          <TreeSelect
                            showSearch
                            treeNodeFilterProp="title"
                            style={{ width: '100%' }}
                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                            treeData={treeData}
                          />
                        </Form.Item>
                      </Col>
                    )}

                    {!esIngresoPresupuestal && (
                    <InputSearchPro // ?
                      name="clave_contable__clave"
                      onClickAdvanced={() => setVisibleBusquedaCuentas(true)}
                      rules={rules.clave_contable__clave}
                      label="Búsqueda Cuenta Contable"
                      tooltip={`Debe concordar con la mascara ${mascara}`}
                      inputProps={{
                        onPressEnter: () => fetchCuentaContable(),
                        onChange: onChangeClaveContable,
                        maxLength: mascara?.length,
                      }}
                      breakPoints={{
                        xs: 24,
                        sm: 24,
                        md: 8,
                      }}
                      resultProps={{
                        name: 'cuenta_contable',
                        label: 'Cuenta Contable',
                        rules: rules.required,
                        dataSource: cuentaContableFound,
                        keyLabelRender: true,
                        keyProp: 'clave',
                        labelProp: 'nombre',
                        onClear: resetAndValidateCuentaContable,
                        breakPoints: {
                          xs: 24,
                          sm: 24,
                          md: 8,
                        },
                      }}
                    />
                    )}
                    {esIngresoPresupuestal && (
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="fuente_de_financiamiento"
                        label={(
                          <FormItemLabel
                            shortTitle="FF"
                            longTitle="Fuente de financiamiento"
                          />
                        )}
                        hasFeedback
                      >
                        <TreeSelect
                          showSearch
                          showArrow={mutable}
                          treeNodeFilterProp="title"
                          style={{ width: '100%' }}
                          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                          treeData={fuentes}
                          filterTreeNode={filterTree}
                          disabled={!mutable}
                        />
                      </Form.Item>
                    </Col>
                    )}
                    <Col
                      xs={24}
                      sm={24}
                      md={8}
                    >
                      <Form.Item
                        name="causa_iva"
                        label="¿Causa IVA?"
                        hasFeedback
                      >
                        <Select trueFalse disabled={!mutable} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="observaciones"
                        label="Observaciones"
                        hasFeedback
                      >
                        <Input.TextArea
                          disabled={!mutable}
                          allowClear
                          autoSize={{ minRows: 3, maxRows: 5 }}
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      xs={24}
                      sm={24}
                      md={8}
                    >
                      <Form.Item
                        name="estados_globales"
                        label="Estado"
                        hasFeedback
                        rules={rules.required}
                      >
                        <Select
                          disabled={!selectedRowKeys.length || !mutable}
                          dataSource={estadosGlobales}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item hidden>
                    <Button htmlType="submit" />
                  </Form.Item>
                </Form>

              </TabPane>
              <TabPane
                tab={(
                  <span>
                    <DollarOutlined />
                    Otros
                  </span>
                )}
                key="canalesDePago"
                forceRender
              >
                <Form
                  form={formCanalesDePago}
                  layout="vertical"
                  onFinish={onFinish}
                  name="canalesDePago"
                >
                  <Form.Item
                    label=""
                    name="canales_de_pago"
                  >
                    <Row gutter={10}>
                      <Col
                        xs={24}
                        sm={24}
                        md={8}
                      >
                        <Form.Item
                          name="padron"
                          label="Padrón"
                          hasFeedback
                        >
                          <Select dataSource={padrones} disabled={!mutable} labelProp="nombre" />
                        </Form.Item>
                      </Col>
                      <Col
                        xs={24}
                        sm={24}
                        md={8}
                      >
                        <Form.Item
                          name="naturaleza_de_ingresos"
                          label={collapsedSidebar ? 'Naturaleza de ingresos' : 'Naturaleza'}
                          hasFeedback
                        >
                          <Select
                            disabled={!mutable}
                            dataSource={naturalezasDeIngreso}
                            labelProp="nombre"
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        xs={24}
                        sm={24}
                        md={8}
                      >
                        <Form.Item
                          name="fundamento_legal"
                          label="Fundamento legal"
                          hasFeedback
                        >
                          <Select
                            dataSource={fundamentos}
                            labelProp="denominacion"
                            disabled={!mutable}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item label="Canales de Pago">
                      <Checkbox.Group style={{ width: '100%' }}>
                        <Row gutter={[10, 5]}>
                          {canalesDePago.map((item) => (
                            <Col
                              xs={24}
                              sm={24}
                              md={8}
                              key={item.id}
                            >
                              <Checkbox disabled={!mutable} value={item.id}>
                                {item.descripcion}
                              </Checkbox>
                            </Col>
                          ))}
                        </Row>
                      </Checkbox.Group>
                    </Form.Item>
                  </Form.Item>
                </Form>
              </TabPane>
            </Tabs>
          </Card>
        )}
        <Modal
          visible={visibleBusquedaCuentas}
          title={(
            <Row justify="space-between">
              Búsqueda Avanzada Cuentas
              <FormSubmitControls
                onCancel={() => setVisibleBusquedaCuentas(false)}
                onFinish={onFinishBusqueda}
              />
            </Row>
            )}
          onCancel={() => setVisibleBusquedaCuentas(false)}
          closable={false}
          footer={null}
          width={800}
          forceRender
          centered
        >
          <BusquedaClavesContables callback={setSelectedClave} />
        </Modal>
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => {
            setVisibleAlert(false);
            setSelectedRowKeys([]);
          }}
          visible={visibleAlert}
          content={`Concepto de Ingresos ${form.getFieldValue('descripcion')}`}
          loading={loading}
        />
      </Spin>
    </Row>
  );
}

export default ConceptoDeIngreso;
