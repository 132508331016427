import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Card,
  Form,
  Row,
  Spin,
  Col,
  Input,
  InputNumber,
  DatePicker,
  Button,
} from 'antd';

import {
  SaveOutlined,
  FilePdfOutlined,
  FileExcelOutlined,
  ArrowLeftOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';
import API from 'utils/api';
import Select from 'components/Select';
import {
  onError,
  onInfo,
  onWarning,
  onSuccess,
} from 'utils/handlers';
import Table from 'components/Table';
import currencyFormatter from 'utils/currencyFormatter';
import moment from 'moment';
import Rutas from '../../utils/rutas-endpoints';

function TransferenciasInternas() {
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [dataUsuario, setDataUsuario] = useState({});
  const [cuentasBancariasEntrada, setCuentasBancariasEntrada] = useState([]);
  const [cuentasBancariasSalida, setCuentasBancariasSalida] = useState([]);
  const [objCuentaBancariaEntreada, setObjCuentaBancariaEntrada] = useState({});
  const [objCuentaBancariaSalida, setObjCuentaBancariaSalida] = useState({});
  const [dataGridTransferenciasInternas, setDataGridTransferenciasInternas] = useState([]);
  const [waiting, setWaiting] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [onAdd, setOnAdd] = useState(false);
  const [onEdit, setOnEdit] = useState(false);

  const periodoFiscalSelected = useSelector(({ auth }) => {
    const value = form.getFieldValue('fecha');
    if (value
      && !value.isBetween(moment(auth.periodoFiscalSelected.fecha_inicial),
        moment(auth.periodoFiscalSelected.fecha_final))) {
      form.setFieldsValue({
        fecha: null,
      });
      form.validateFields();
    }
    return auth.periodoFiscalSelected;
  });

  const requiredRule = {
    required: true,
    message: 'El campo es requerido',
  };
  const rules = {
    required: [requiredRule],
  };

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    setSelectedRowKeys([]);
    setObjCuentaBancariaEntrada({});
    setObjCuentaBancariaSalida({});
    setWaiting(false);
    setOnAdd(false);
    setOnEdit(false);
    setIsUpdated(false);
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      setCuentasBancariasEntrada([]);
      setCuentasBancariasSalida([]);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchData();
    return () => API.tryCancel;
  }, []);

  const consultarUsuario = async () => {
    const resUsuario = await API.get('usuarios/id/');
    const usuario = await resUsuario.data;
    setDataUsuario(usuario);

    form.setFieldsValue({
      usuario_que_elaboro: `${usuario.id} - ${usuario.first_name} ${usuario.last_name}`,
      puesto_elaboro: usuario.puesto_usuario,
      usuario_area: usuario.usuario_area,
    });
  };

  const getDataTransferenciasInternas = async () => {
    setLoading(true);
    const response = await API.get(Rutas.tranferenciasInternas);
    const tranferenciasInternas = await response.data;
    const gridDataTranferencias = [];
    tranferenciasInternas.forEach((transferencia, index) => {
      const name_completed = `${transferencia.data_usuario.first_name} ${transferencia.data_usuario.last_name}`;
      const poliza = transferencia.data_poliza;
      gridDataTranferencias[index] = {
        id: transferencia.id,
        fecha: transferencia.fecha,
        numero_de_transferencia_interna: transferencia.numero_de_transferencia_interna,
        importe: transferencia.importe,
        observaciones: transferencia.observaciones,
        puesto: transferencia.data_usuario.puesto_usuario,
        area: transferencia.data_usuario.usuario_area,
        elaboro: name_completed,
        cuenta_bancaria_entrada: transferencia.data_cuenta_entrada.numero_de_cuenta,
        cuenta_bancaria_salida: transferencia.data_cuenta_salida.numero_de_cuenta,
        estado: transferencia.estado,
        poliza,
      };
    });
    setDataGridTransferenciasInternas(gridDataTranferencias);
    setLoading(false);
  };

  const getClabeInterbancaria = (cuentaBancariaID, e_s) => {
    const cuenta = cuentasBancariasEntrada.find((item) => item.id === cuentaBancariaID);
    if (e_s === 'E') {
      setObjCuentaBancariaEntrada(cuenta);
    }

    if (e_s === 'S') {
      setObjCuentaBancariaSalida(cuenta);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const consultarCuentaBancariaEntrada = async () => {
    const resApi = await API.get(Rutas.cuentasBancarias);
    const cuentasBancariasE = await resApi.data;

    setCuentasBancariasEntrada(cuentasBancariasE);
  };

  const consultarCuentaBancariaSalida = async () => {
    const resApi = await API.get(Rutas.cuentasBancarias);
    const cuentasBancariasS = await resApi.data;

    setCuentasBancariasSalida(cuentasBancariasS);
  };

  const crearDataTransferenciaInterna = (data) => {
    const fechaFormated = moment(data.fecha);

    let transferenciaInterna = {};
    transferenciaInterna = {
      fecha: fechaFormated,
      numero_de_transferencia_interna: data.numero_de_transferencia_interna,
      cuenta_bancaria_entrada: data.cuenta_bancaria_entrada,
      cuenta_bancaria_salida: data.cuenta_bancaria_salida,
      data_cuenta_entrada: data.data_cuenta_entrada,
      data_cuenta_salida: data.data_cuenta_salida,
      importe: data.importe,
      usuario_que_elaboro: data.datos_de_quien_elabora,
      usuario_que_elaboro_id: data.usuario_que_elaboro,
      puesto_elaboro: data.data_usuario.puesto_usuario,
      usuario_area: data.data_usuario.usuario_area,
      poliza: '',
      estado: data.estado,
      observaciones: data.observaciones,
    };

    return transferenciaInterna;
  };

  const consultarTransferenciaInterna = async (idTransferencia) => {
    setLoading(true);
    const dataRest = await API.get(`${Rutas.tranferenciasInternas}${idTransferencia}/`);
    const transferenciaRest = dataRest.data;
    const transferenciaTransformada = crearDataTransferenciaInterna(transferenciaRest);
    form.setFieldsValue(transferenciaTransformada);
    setLoading(false);
  };

  // EFECTOS
  useEffect(() => {
    getDataTransferenciasInternas();
  }, []);

  useEffect(() => {
    consultarCuentaBancariaEntrada();
  }, [cuentasBancariasEntrada.length]);

  useEffect(() => {
    consultarCuentaBancariaSalida();
  }, [cuentasBancariasSalida.length]);

  useEffect(() => {
    const [key] = selectedRowKeys;
    if (waiting) {
      consultarTransferenciaInterna(key);
    }
    // eslint-disable-next-line
  }, [waiting]);

  useEffect(() => {
    if (isUpdated) {
      getDataTransferenciasInternas();
    }
  }, [isUpdated]);

  // FUNCIONES

  function handleOnSelect(e, e_s) {
    getClabeInterbancaria(e, e_s);
    setOnAdd(true);
  }

  // EVENTOS

  const onClickAdd = () => {
    const fechaSis = moment();
    form.setFieldsValue({
      fecha: fechaSis,
    });
    setSelectedRowKeys([]);
    consultarUsuario();
    setOnAdd(true);
    setVisible(true);
  };

  const onClickEdit = () => {
    setWaiting(true);
    setVisible(true);
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    // setTimeout(() => form.setFieldsValue(record));
    if (record.estado === 'Activo') {
      setOnEdit(true);
    }
  };

  const onActivar = () => {
    setOnAdd(true);
  };

  const disabledDate = (current) => {
    // eslint-disable-next-line
    const val = moment(current).isBetween(periodoFiscalSelected.fecha_inicial, periodoFiscalSelected.fecha_final);
    return !val;
  };

  const onClickDelete = async () => {
    try {
      if (selectedRowKeys.length > 0) {
        const [key] = selectedRowKeys;
        const rowSelected = dataGridTransferenciasInternas.find((item) => item.id === key);

        if (rowSelected.estado === 'Autorizado') {
          onWarning('Solo se puede eliminar registros con estado activo.');
        } else {
          const response = await API.patch(`${Rutas.tranferenciasInternas}${key}/eliminar/`);
          if (response?.status === 200) {
            onSuccess(response, 'Eliminado correctamente.');
            onCancel();
            getDataTransferenciasInternas();
          }
        }
      }
    } catch (error) {
      onError(error, setLoading);
    }
  };

  const onClickAutorizar = () => {
    const [key] = selectedRowKeys;
    const array = [];
    const obj = {
      id: key,
    };

    const rowSelected = dataGridTransferenciasInternas.find((item) => item.id === key);

    if (rowSelected.estado !== 'Activo') {
      onWarning('Acción no realizada, solo se pueden autorizar registros con estado activo.');
    } else {
      obj.id = rowSelected.id;
      array.push(obj);
      const resApi = API.patch(`${Rutas.tranferenciasInternas}autorizar/`, array);
      onSuccess(resApi, 'Autorizado correctamente.');
      onCancel();
      setIsUpdated(true);
      getDataTransferenciasInternas();
    }
  };

  const onClickSaveForm = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      const { fecha } = values;
      const dateFormat = 'YYYY-MM-DD';

      if (fecha != null || fecha !== '') {
        const date = moment(fecha).format('YYYY-MM-DD');
        values.fecha = date;
      }

      const fechaFormated = moment(fecha).format(dateFormat);

      values.clabe_interbancaria_entrada = objCuentaBancariaEntreada.clabe_interbancaria;
      values.clabe_interbancaria_salida = objCuentaBancariaSalida.clabe_interbancaria;
      values.datos_de_quien_elabora = values.usuario_que_elaboro;
      values.usuario_que_elaboro = dataUsuario.id;
      values.estados_globales = 1;

      if (selectedRowKeys.length === 0) {
        // eslint-disable-next-line
        if (!moment(fechaFormated).isBetween(periodoFiscalSelected.fecha_inicial, periodoFiscalSelected.fecha_final)) {
          onWarning('La fecha seleccionada no se encuentra dentro del periodo fiscal.');
          setTimeout(() => {
            onInfo('Por favor, cambie la fecha, a una dentro del periodo fiscal en curso.');
          }, 3500);
        }

        if ((Object.keys(objCuentaBancariaEntreada).length === 0)
          || (Object.keys(objCuentaBancariaSalida).length === 0)) {
          onWarning('Campos requeridos vacios.');
          await form.validateFields();
        } else if (objCuentaBancariaEntreada.id === objCuentaBancariaSalida.id) {
          onWarning('La cuenta de entrada no puede ser igual que la de salida.');
          setTimeout(() => {
            onInfo('Por favor, seleccione una cuenta de salida distinta.');
          }, 3500);
        } else {
          const response = await API.post(Rutas.tranferenciasInternas, values);

          if (response?.status === 201) {
            onSuccess(response, 'Agregado correctamente.');
            onCancel();
            getDataTransferenciasInternas();
          }
        }
      } else {
        const [key] = selectedRowKeys;
        // eslint-disable-next-line
        if (!moment(fechaFormated).isBetween(periodoFiscalSelected.fecha_inicial, periodoFiscalSelected.fecha_final)) {
          onWarning('La fecha seleccionada no se encuentra dentro del periodo fiscal.');
          setTimeout(() => {
            onInfo('Por favor, cambie la fecha, a una dentro del periodo fiscal en curso.');
          }, 3500);
        }
        if (values.cuenta_bancaria_entrada === values.cuenta_bancaria_salida) {
          onWarning('La cuenta de entrada no puede ser igual que la de salida.');
          setTimeout(() => {
            onInfo('Por favor, seleccione una cuenta de salida distinta.');
          }, 3500);
        } else {
          const response = await API.patch(`${Rutas.tranferenciasInternas}${key}/`, values);
          if (response?.status === 200) {
            onSuccess(response, 'Actualizado correctamente.');
            form.resetFields();
            onCancel();
            getDataTransferenciasInternas();
          }
        }
      }

      setOnEdit(false);
      setOnAdd(false);
      setLoading(false);
    } catch (error) {
      onError(error, setLoading);
    }
  };

  const onDescargarPDF = () => { };

  const onDescargarExcel = () => { };

  const columns = [
    {
      titleText: 'Fecha',
      dataIndex: 'fecha',
      key: 'fecha',
      width: 200,
    },
    {
      titleText: 'No. de Transferencia Interna',
      dataIndex: 'numero_de_transferencia_interna',
      key: 'numero_de_transferencia_interna',
      width: 250,
    },
    {
      titleText: 'Cuenta Bancaria de Entrada',
      dataIndex: 'cuenta_bancaria_entrada',
      key: 'cuenta_bancaria_entrada',
      width: 250,
    },
    {
      titleText: 'Cuenta Bancaria de Salida',
      dataIndex: 'cuenta_bancaria_salida',
      key: 'cuenta_bancaria_salida',
      width: 250,
    },
    {
      titleText: 'Importe',
      dataIndex: 'importe',
      key: 'importe',
      width: 200,
      render: (val) => (val > 0 ? currencyFormatter(val || 0) : '-'),
    },
    {
      titleText: 'Elaboró',
      dataIndex: 'elaboro',
      key: 'elaboro',
      width: 250,
    },
    {
      titleText: 'Puesto',
      dataIndex: 'puesto',
      key: 'puesto',
      width: 250,
    },
    {
      titleText: 'Área',
      dataIndex: 'area',
      key: 'area',
      width: 220,
    },
    {
      titleText: 'Póliza',
      dataIndex: 'poliza',
      key: 'poliza',
      width: 700,
    },
    {
      titleText: 'Estado',
      dataIndex: 'estado',
      key: 'estado',
      width: 200,
    },
    {
      titleText: 'Observaciones',
      dataIndex: 'observaciones',
      key: 'observaciones',
      width: 250,
    },
  ];

  const onFinish = () => ({});

  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible ? (
          <Table
            cols={columns}
            data={dataGridTransferenciasInternas}
            rowSelection={rowSelection}
            handleOnRowClick={handleOnRowClick}
            mobileColIndex={0}
            controls={{
              onClickAdd,
              onClickEdit,
              onClickDelete,
            }}
            customActions={[
              {
                onClick: onClickAutorizar,
                text: 'Autorizar',
                icon: <CheckCircleOutlined />,
                disabledBySelection: true,
              },
              {
                onClick: onDescargarPDF,
                icon: <FilePdfOutlined />,
                text: 'Descargar PDF',
                disabledBySelection: false,
              },
              {
                onClick: onDescargarExcel,
                icon: <FileExcelOutlined />,
                text: 'Descargar Excel',
                disabledBySelection: false,
              },
            ]}
          />
        ) : (
          <Card
            title={`${selectedRowKeys.length > 0 ? 'Editar' : 'Agregar'} Transferencia Interna`}
            extra={(
              <>
                {(onAdd || onEdit) && (
                  <Button
                    icon={<SaveOutlined />}
                    className="ant-btn ant-btn-link"
                    onClick={onClickSaveForm}
                  >
                    Guardar
                  </Button>
                )}

                {(waiting && onEdit) && (
                  <Button
                    icon={<CheckCircleOutlined />}
                    className="ant-btn ant-btn-link"
                    onClick={onClickAutorizar}
                  >
                    Autorizar
                  </Button>
                )}
                <Button
                  icon={<ArrowLeftOutlined />}
                  className="ant-btn ant-btn-link"
                  onClick={onCancel}
                >
                  Regresar
                </Button>
              </>
            )}
            bordered={false}
          >
            <Row gutter={10}>
              <Col md={24} sm={24} xs={24}>
                <Form
                  form={form}
                  layout="vertical"
                  name="transferencia_interna"
                  onFinish={onFinish}
                  labelAlign="left"
                  labelWrap
                  initialValues={{ estado: 'Activo' }}
                >
                  <Row gutter={10}>
                    <Col xs={12} sm={24} md={8}>
                      <Form.Item name="fecha" label="Fecha" rules={rules.required} hasFeedback>
                        <DatePicker placeholder="" format="DD/MM/YYYY" disabledDate={disabledDate} onChange={onActivar} />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={24} md={8}>
                      <Form.Item
                        name="numero_de_transferencia_interna"
                        label="No. de Transferencia Interna"
                      >
                        <Input allowClear disabled />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={24} md={8}>
                      <Form.Item
                        name="cuenta_bancaria_entrada"
                        label="Cuenta Bancaria de Entrada"
                        rules={rules.required}
                      >
                        <Select
                          onChange={(e) => handleOnSelect(e, 'E')}
                          dataSource={cuentasBancariasEntrada}
                          render={(e) => `${e?.banco_nombre} - ${e?.clabe_interbancaria}`}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={24} md={8}>
                      <Form.Item
                        name="cuenta_bancaria_salida"
                        label="Cuenta Bancaria de Salida"
                        rules={rules.required}
                      >
                        <Select
                          onChange={(e) => handleOnSelect(e, 'S')}
                          dataSource={cuentasBancariasSalida}
                          render={(e) => `${e?.banco_nombre} - ${e?.clabe_interbancaria}`}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={24} md={8}>
                      <Form.Item
                        name="importe"
                        label="Importe"
                        rules={rules.required}
                      >
                        <InputNumber
                          formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                          defaultValue={0.00}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={24} md={8}>
                      <Form.Item name="usuario_que_elaboro" label="Elaboró">
                        <Input allowClear disabled />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={24} md={8}>
                      <Form.Item name="puesto_elaboro" label="Puesto">
                        <Input allowClear disabled />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={24} md={8}>
                      <Form.Item name="usuario_area" label="Área">
                        <Input allowClear disabled />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={24} md={8}>
                      <Form.Item
                        name="poliza"
                        label="Póliza"
                      >
                        <Input allowClear disabled />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={24} md={8}>
                      <Form.Item name="estado" label="Estado">
                        <Input allowClear disabled />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={24} md={8}>
                      <Form.Item name="observaciones" label="Observaciones">
                        <Input allowClear />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Card>
        )}
      </Spin>
    </Row>
  );
}

export default TransferenciasInternas;
