import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Spin,
  Card,
  Tabs,
  Modal,
  message,
  TreeSelect,
  DatePicker,
} from 'antd';

import {
  ImportOutlined,
  DollarCircleOutlined,
  DatabaseOutlined,
  CalculatorOutlined,
  // FileOutlined,
} from '@ant-design/icons';

import {
  onError,
  onSuccess,
} from 'utils/handlers';
import Table from 'components/Table';
import API from 'utils/api';
import FormSubmitControls from 'components/FormSubmitControls';
import ModalDelete from 'components/ModalDelete';
import Select from 'components/Select';
import NumericInput from 'components/NumericInput';
import FormItemLabel from 'components/FormItemLabel';
import fetchSome from 'utils/fetchSome';
import {
  SET_UNIDADES_DE_MEDIDA,
  SET_CUCOP,
  SET_TIPOS_DE_COSTEO,
  SET_CONFIG_MASCARA_CONTABLE,
} from 'store/reducers/catalogs';
import getFormatedValues, { formatReceived, hasFiles } from 'utils/formatValues';
import AvatarUploader from 'components/AvatarUploader';
import { InputSearchPro } from 'components/InputSearch';
import BusquedaClavesContables from 'components/BusquedaClavesContables';
// import DocumentosTramites from 'components/Catalogos/DocumentosTramites';
import makeThreeFromCOG from 'utils/makeTree';

export const permissionProducto = {
  permissionModel: 'producto',
};

const { TabPane } = Tabs;

const baseURI = 'configuraciones/productos/';

// eslint-disable-next-line react/prop-types
function Productos({ permission }) {
  const configMascaraContable = useSelector(({ catalogs }) => catalogs.configMascaraContable);
  const {
    estadosGlobales,
    cucop,
    unidadesDeMedida,
    tiposDeCosteos,
  } = useSelector((state) => state.catalogs);
  const [formClaves] = Form.useForm();
  const [formGeneral] = Form.useForm();
  const [formPrecio] = Form.useForm();
  const [formAlmacen] = Form.useForm();
  const [formContabilidad] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);
  const [tiposDeProducto, setTiposDeProducto] = useState([]);
  const [activeTab, setActiveTab] = useState('clave');
  const [withIVA, setWithIVA] = useState(false);
  const [ubicacionesEnAlmacen, setUbicacionesEnAlmacen] = useState([]);
  const [conceptos, setConceptos] = useState([]);
  const [perecedero, setPerecedero] = useState(false);
  const [tipoDeProduct, setTipoDeProduct] = useState(1);
  const [cog, setCog] = useState([]);
  const [visibleBusquedaCuentas, setVisibleBusquedaCuentas] = useState(false);
  const [cuentaContableFound, setCuentaContableFound] = useState([]);
  const [mascara, setMascara] = useState();
  const [separador, setSeparador] = useState();
  const [pattern, setPattern] = useState();
  const [selectedClave, setSelectedClave] = useState();
  const [familias, setFamilias] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [filteredCats, setFilteredCats] = useState([]);
  const [flatCOGS, setFlatCOGS] = useState([]);
  const [cuentasContablesFlat, setCuentasContablesFlat] = useState([]);
  const [wcucop, setWcucop] = useState([]);

  const formInstances = [
    formClaves,
    formGeneral,
    formAlmacen,
    formPrecio,
    formContabilidad,
  ];

  const uris = [
    '/configuraciones/tipos-de-productos/',
    '/configuraciones/ubicaciones-de-los-almacenes/',
    '/configuraciones/conceptos-de-productos/',
    'contabilidad/clasificaciones-objeto-gasto/',
    '/configuraciones/categorias-del-producto/',
    '/configuraciones/familia-de-productos/',
    'contabilidad/cuentas-contables/',
    baseURI,
  ];

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await API.get(baseURI);
      setData(formatReceived(res.data) || []);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const normalizeCog = (_data = []) => _data.map((item) => {
    const clone = { ...item };
    const children = clone.children?.length && typeof clone.id === 'string'
      ? normalizeCog(clone.children) : [];
    if (!children.length || typeof clone.id === 'number') {
      delete clone.children;
      return ({
        ...clone,
        key: clone.clave,
        clave: clone.clave,
        disabled: !clone.seleccionable,
      });
    }
    return ({
      ...clone,
      key: clone.clave,
      children,
      disabled: !clone.seleccionable,
    });
  });

  const normalizeData = (_data, prop = 'concepto', nested = true) => {
    if (_data?.length) {
      const output = _data.map((item) => {
        const clone = { ...item };
        const children = normalizeData(clone.children, prop);
        const identificator = nested
          ? `${clone.id.toString()}${item.seleccionable ? '' : '_parent'}` : clone.id;
        delete clone.children;
        if (!children.length) {
          return ({
            ...clone,
            value: parseInt(identificator, 10),
            title: `${item.clave} - ${item[prop]}`,
            key: parseInt(identificator, 10),
            selectable: item.seleccionable,
            checkable: item.seleccionable,
          });
        }
        return ({
          ...clone,
          value: identificator,
          title: `${item.clave} - ${item[prop]}`,
          children,
          disabled: true,
          selectable: item.seleccionable,
          checkable: item.seleccionable,
          key: identificator,
        });
      });
      return output;
    }
    return [];
  };

  useEffect(() => {
    let mounted = true;
    const fetchAll = async () => {
      try {
        setLoading(true);

        if (configMascaraContable) {
          setMascara(configMascaraContable?.mascara);
          const separator = configMascaraContable?.separador;
          setSeparador(separator);
          const _segmentos = configMascaraContable?.mascara?.split(separator) || [];
          if (_segmentos?.length) {
            // eslint-disable-next-line prefer-template
            const patt = _segmentos.reduce((acum, curr, idx) => `${acum}[0-9]{${curr.length}}`
              + (idx !== _segmentos.at(-1) ? separator : '$'), '^');
            const regExp = new RegExp(`${patt}`);
            setPattern(regExp);
          }
        } else {
          await fetchSome('configuraciones/configuraciones-de-mascaras/', SET_CONFIG_MASCARA_CONTABLE);
        }

        const promises = uris.map((uri) => API.get(uri));
        const [
          _tipoDeProducto,
          _ubicaciones,
          _conceptos,
          _COGs,
          _categorias,
          _familias,
          _cuentasContables,
          _data,
        ] = (await Promise.all(promises)).map((response) => response.data);

        if (mounted) {
          setTiposDeProducto(_tipoDeProducto || []);
          setUbicacionesEnAlmacen(_ubicaciones || []);
          setConceptos(_conceptos || []);
          const tree = makeThreeFromCOG(_COGs.map((e) => (!e.seleccionable ? ({
            ...e,
            id: `${e.id}`,
          }) : e)));
          setCog(normalizeData(normalizeCog(tree)));
          setFlatCOGS(_COGs);
          setCategorias(_categorias || []);
          setFamilias(_familias || []);
          setCuentasContablesFlat(_cuentasContables);
          setData(formatReceived(_data));
        }
        if (!cucop.length) {
          await fetchSome('/almacen/cucop', SET_CUCOP);
        }
        if (!tiposDeCosteos.length) {
          await fetchSome('/configuraciones/tipos-de-costeos/', SET_TIPOS_DE_COSTEO);
        }
        if (!unidadesDeMedida.length) {
          await fetchSome('/configuraciones/unidades-de-medida/', SET_UNIDADES_DE_MEDIDA);
        }
        setLoading(false);
      } catch (err) {
        onError(err, setLoading);
      }
    };
    fetchAll();

    return () => { mounted = false; };
    // eslint-disable-next-line
  }, []);

  const patchFile = async (id) => {
    try {
      const files = formAlmacen.getFieldValue(['imagen']);
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      const formData = new FormData();
      formData.append('imagen', files.file);
      await API.patch(`configuraciones/productos/${id}/`, formData, config);
    } catch {
      message.warn('Ha ocurrido un error al enviar la imagen, por favor, intentelo de nuevo.');
    }
  };

  const onCancel = () => {
    setVisible(false);
    setWithIVA(false);
    setPerecedero(false);
    setVisibleAlert(false);
    formClaves.resetFields();
    formGeneral.resetFields();
    formPrecio.resetFields();
    formAlmacen.resetFields();
    formContabilidad.resetFields();
    setSelectedRowKeys([]);
    setActiveTab('clave');
    setTipoDeProduct(1);
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await formClaves.validateFields();
      await formGeneral.validateFields();
      await formPrecio.validateFields();
      await formAlmacen.validateFields();
      await formContabilidad.validateFields();
      const values = {
        ...formClaves.getFieldValue(),
        ...formGeneral.getFieldsValue(),
        ...formPrecio.getFieldsValue(),
        ...formAlmacen.getFieldsValue(),
        ...formContabilidad.getFieldsValue(),
      };

      const formattedValues = getFormatedValues(values);
      delete formattedValues.imagen;
      if (!selectedRowKeys.length) {
        const response = await API.post(baseURI, formattedValues);
        if (response?.status === 201) {
          if (hasFiles(values, ['imagen'])) {
            await patchFile(response.data.id);
          }
          onSuccess(response, 'Agregado correctamente', 2.5);
          onCancel();
          await fetchData();
        }
      } else {
        const [key] = selectedRowKeys;
        const response = await API.put(`${baseURI}${key}/`, formattedValues);
        if (response?.status === 200) {
          if (hasFiles(values, ['imagen'])) {
            await patchFile(response.data.id);
          }
          onSuccess(response, 'Actualizado correctamente', 2.5);
          onCancel();
          await fetchData();
        }
      }
      await fetchData();
      setLoading(false);
    } catch (err) {
      onError(
        err,
        setLoading,
        formInstances,
        setActiveTab,
      );
    }
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      if (selectedRowKeys.length) {
        const [rowKey] = selectedRowKeys;
        const response = await API.delete(`${baseURI}${rowKey}`);
        if (response && response.status === 204) {
          onSuccess(response, 'Eliminado correctamente', 2.5);
          onCancel();
          fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const filterCategoria = (familia) => {
    let output = [];
    if (familia) {
      output = categorias.filter((e) => e.familia_del_producto === familia);
    }
    formGeneral.resetFields(['categoria']);
    setFilteredCats(output);
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    // filtered
    setWithIVA(record.esta_exento_IVA);
    setPerecedero(record.es_perecedero);
    setTipoDeProduct(record.tipo_de_producto);
    formClaves.setFieldsValue(formatReceived({ ...record }));
    formGeneral.setFieldsValue(formatReceived({ ...record }));
    formPrecio.setFieldsValue(formatReceived({ ...record }));
    formAlmacen.setFieldsValue({
      ...record,
      clave_contable: record.clave_contable?.id,
    });
    formContabilidad.setFieldsValue({ ...record });
    setCuentaContableFound([record.clave_contable]);
    setCuentaContableFound(record.clave_contable ? [record.clave_contable] : []);
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };
  const resetAndValidateCuentaContable = () => {
    setCuentaContableFound([]);
    formContabilidad.resetFields(['clave_contable', 'cuenta_contable_conac']);
    formContabilidad.validateFields(['clave_contable', 'cuenta_contable_conac']);
  };

  const clearFields = () => {
    formClaves.resetFields();
    formGeneral.resetFields();
    formPrecio.resetFields();
    formAlmacen.resetFields();
    formContabilidad.resetFields();
  };

  const onClickAdd = () => {
    onCancel();
    resetAndValidateCuentaContable();
    clearFields();
    setVisible(true);
  };

  const onClickEdit = async () => {
    try {
      setLoading(true);
      const [key] = selectedRowKeys;
      const clone = [...data];
      const match = clone.find((e) => e.id === key);
      // const resCC = await API.get(`contabilidad/cuentas-contables/${match?.cuenta_contable}`);
      const familia_del_producto = categorias.find((e) => e.id === match.categoria_del_producto);
      filterCategoria(familia_del_producto.id);
      const formattedMatch = formatReceived({
        ...match,
        familia_del_producto: familia_del_producto.id,
        // cuenta_contable: resCC.data.id,
        // cuenta_contable_conac: resCC.data.cuenta_padre,
      });
      // setCuentaContableFound([resCC.data]);
      setWithIVA(formattedMatch.esta_exento_IVA);
      setPerecedero(formattedMatch.es_perecedero);
      setTipoDeProduct(formattedMatch.tipo_de_producto);
      setTimeout(() => {
        formClaves.setFieldsValue({ ...formattedMatch });
        formGeneral.setFieldsValue({ ...formattedMatch });
        formPrecio.setFieldsValue({ ...formattedMatch });
        formAlmacen.setFieldsValue({ ...formattedMatch });
        formContabilidad.setFieldsValue({ ...formattedMatch });
      });
      setVisible(true);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const onClickExport = () => { };

  const rules = {
    clave_contable__clave: [
      {
        required: true,
        message: 'El campo es requerido',
      },
      {
        validator: async (_, value) => {
          if (value && pattern && (!pattern.test(value))) {
            throw new Error('Introduzca una clave válida');
          }
        },
      },
    ],
    required: [
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
    caducidad: [
      {
        required: perecedero,
        message: 'El campo es requerido',
      },
    ],
    conditional_required: [
      {
        required: tipoDeProduct === 1,
        message: 'El campo es requerido',
      },
    ],
  };

  const columns = [
    {
      titleText: 'Clave Interna',
      dataIndex: 'clave_interna',
      key: 'clave_interna',
      width: 200,
    },
    {
      titleText: 'Clave',
      dataIndex: 'clave',
      key: 'clave',
      width: 150,
    },
    {
      titleText: 'Descripción',
      dataIndex: 'descripcion',
      key: 'descripcion',
      width: 350,
    },
    {
      titleText: 'Tipo de producto',
      dataIndex: 'tipo_de_producto',
      key: 'tipo_de_producto',
      render: (val) => tiposDeProducto.find((e) => e.id === val)?.descripcion,
      width: 200,
    },
    {
      titleText: 'COG',
      dataIndex: 'cog',
      key: 'cog',
      render: (val) => flatCOGS.find((e) => e.id === val)?.clave,
      width: 100,
    },
    {
      titleText: 'Ubicación en el Almacen',
      dataIndex: 'ubicacion_en_el_almacen',
      key: 'ubicacion_en_el_almacen',
      render: (val) => ubicacionesEnAlmacen.find((e) => e.id === val)?.descripcion,
      width: 200,
    },
    {
      titleText: 'IVA',
      dataIndex: 'IVA',
      key: 'IVA',
      render: (val) => (val ? `${val}%` : '0%'),
      width: 100,
    },
    {
      titleText: 'Estado',
      dataIndex: 'estados_globales',
      key: 'estados_globales',
      render: (val) => estadosGlobales.find((e) => e.id === val)?.descripcion,
      width: 150,
    },
  ];

  const onFinishBusqueda = () => {
    if (selectedClave) {
      setCuentaContableFound([selectedClave]);
      setVisibleBusquedaCuentas(false);
    } else {
      message.info('No ha seleccionado una Cuenta Contable');
    }
  };

  const fetchCuentaContable = async () => {
    try {
      formContabilidad.validateFields(['clave_contable__clave']);
      const clave = formContabilidad.getFieldValue('clave_contable__clave');
      if (clave) {
        setLoading(true);
        const res = await API.get('contabilidad/cuentas-contables/', {
          params: {
            clave,
            tipo_de_cuenta_contable: 7,
          },
        });
        if (res?.status === 200 && res.data?.length) {
          const claveContable = res.data[0];
          if (claveContable.clave.startsWith('115')) {
            setCuentaContableFound([claveContable]);
            formContabilidad.setFieldsValue({
              cuenta_contable: claveContable.id,
              cuenta_contable_conac: claveContable.cuenta_padre,
            });
            setTimeout(() => {
              formContabilidad.validateFields(['cuenta_contable']);
            });
          } else {
            message.info('La cuenta contable no es una cuenta de productos.');
          }
        } else {
          message.info('No se encontraron coincidencias');
          setCuentaContableFound([]);
        }
        setLoading(false);
      }
    } catch (err) {
      onError(err, setLoading);
      setCuentaContableFound([]);
    }
  };

  const fetchCucop = async () => {
    try {
      const clave = formClaves.getFieldsValue();
      if (clave) {
        setLoading(true);
        const res = await API.get('almacen/cucop/', {
          params: {
            clave: clave.cucop_clave,
          },
        });
        if (res?.status === 200 && res.data?.length) {
          const claveCucop = res.data[0];
          if (claveCucop.clave) {
            setWcucop([claveCucop]);
            formClaves.setFieldsValue({
              cucop: claveCucop.id,
              partidaEspecifica: claveCucop?.partida_especifica,
              cabm: claveCucop?.cabm,
              tipoDeContratacion: claveCucop?.tipo_de_contratacion,
            });
            setTimeout(() => {
              formClaves.validateFields(['cucop']);
            });
          } else {
            message.info('CUCOP incorrecto');
          }
        } else {
          message.info('No se encontraron coincidencias');
          setWcucop([]);
        }
        setLoading(false);
      }
    } catch (err) {
      onError(err, setLoading);
      setWcucop([]);
    }
  };

  const onChangeClaveContable = (e) => {
    const { target: { value }, keyCode } = e;
    let val = value;
    if (keyCode) {
      const char = String.fromCharCode(keyCode).toLowerCase();
      val += char;
    }
    if (mascara?.charAt(val.length) === separador) {
      val += separador;
    }
    if (val.length === mascara?.length) {
      setTimeout(fetchCuentaContable);
    } else if (!val?.length) {
      resetAndValidateCuentaContable();
    }
    setTimeout(() => {
      formContabilidad.setFieldsValue({ clave_contable__clave: val });
    });
  };

  const filterTreeNode = (input, node) => {
    const title = node.title?.props.children.toLowerCase() || node.title;
    if (title && title.includes(input.toLowerCase())) {
      return true;
    }
    if (node.children) {
      return filterTreeNode(input, node.children);
    }
    return false;
  };

  let timeoutFilter;

  const setTimeoutFilter = (input, node) => setTimeout(() => filterTreeNode(input, node), 1000);

  const filterTree = (input, node) => {
    clearTimeout(timeoutFilter);
    timeoutFilter = setTimeoutFilter(input, node);
    return timeoutFilter ? filterTreeNode(input, node) : true;
  };

  return (
    <Row align="center" justify="center" className="container">
      <Col span={24}>
        <Spin
          tip="Cargando..."
          spinning={loading}
        >
          {!visible
            ? (
              <Table
                allowImport
                baseURI={baseURI}
                cols={columns}
                permission={permission}
                mobileCols={columns}
                data={data}
                rowSelection={rowSelection}
                handleOnRowClick={handleOnRowClick}
                controls={{
                  onClickAdd,
                  onClickEdit,
                  onClickDelete,
                  onClickExport,
                }}
              />
            )
            : (
              <Col span={24} style={{ height: '100%' }}>
                <Card
                  className="form-container"
                  title={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Producto`}
                  extra={(
                    <FormSubmitControls onFinish={onFinish} onCancel={onCancel} />
                  )}
                  bordered={false}
                >
                  <Tabs
                    defaultActiveKey="clave"
                    type="card"
                    onChange={setActiveTab}
                    activeKey={activeTab}
                  >
                    <TabPane
                      tab={(
                        <span>
                          <ImportOutlined />
                          Clave
                        </span>
                      )}
                      key="clave"
                      forceRender
                    >
                      <Form
                        form={formClaves}
                        layout="vertical"
                        onFinish={onFinish}
                        name="clave"
                        initialValues={{
                          estados_globales: 1,
                          es_perecedero: false,
                          tipo_de_producto: 1,
                          es_pedimento: false,
                        }}
                      >
                        <Row gutter={10}>
                          <Col xs={24} sm={24} md={8}>
                            <Form.Item
                              label="Clave"
                              name="clave"
                              tooltip="Esta clave se genera automaticamente al guardar el producto"
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={8}>
                            <Form.Item
                              label="Clave Interna"
                              rules={rules.required}
                              name="clave_interna"
                            >
                              <Input allowClear />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={8}>
                            <Form.Item
                              label="Clave Anterior"
                              name="clave_anterior"
                            >
                              <Input allowClear />
                            </Form.Item>
                          </Col>
                          {/* <Col xs={24} sm={24} md={8} lg={12} xl={8}>
                            <Form.Item
                              name="cucop"
                              rules={rules.required}
                              label="CUCOP"
                              tooltip="Clasificador Único de Contrataciones Públicas"
                            >
                              <Select dataSource={cucop} keyLabelRender />
                            </Form.Item>
                          </Col> */}
                          <InputSearchPro
                            name="cucop_clave"
                            rules={rules.cucop}
                            label="Búsqueda CUCOP"
                            inputProps={{
                              onPressEnter: () => fetchCucop(),
                            }}
                            breakPoints={{
                              xs: 24,
                              sm: 24,
                              md: 16,
                            }}
                            resultProps={{
                              name: 'cucop',
                              label: 'CUCOP',
                              rules: rules.required,
                              dataSource: wcucop,
                              keyLabelRender: true,
                              keyProp: 'clave',
                              labelProp: 'descripcion',
                              breakPoints: {
                                md: 16,
                              },
                            }}
                          />
                          <Col xs={24} sm={24} md={8}>
                            <Form.Item
                              label="Partida Específica"
                              name="partidaEspecifica"
                            >
                              <Input disabled />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={8}>
                            <Form.Item
                              label="CAMB"
                              name="cabm"
                            >
                              <Input disabled />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={8}>
                            <Form.Item
                              label="Tipo de Contratación"
                              name="tipoDeContratacion"
                            >
                              <Input disabled />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={8}>
                            <Form.Item
                              name="cog"
                              rules={rules.required}
                              label={(
                                <FormItemLabel
                                  longTitle="Clasificador por Objeto del Gasto"
                                  shortTitle="COG"
                                  hideOn={['sm', 'md']}
                                />
                              )}
                            >
                              <TreeSelect
                                showSearch
                                showArrow
                                treeNodeFilterProp="title"
                                style={{ width: '100%' }}
                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                treeData={cog}
                                filterTreeNode={filterTree}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                            <Form.Item
                              name="cuenta_contable_conac"
                              label="Cuenta Contable CONAC"
                            >
                              <Select
                                dataSource={cuentasContablesFlat}
                                disabled
                                labelProp="nombre"
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={8}>
                            <Form.Item
                              label="Código QR"
                              name="codigo_QR"
                            >
                              <Input allowClear />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={8}>
                            <Form.Item
                              label="Código de Barras"
                              name="codigo_de_barras"
                            >
                              <Input allowClear />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Form.Item hidden>
                          <Button htmlType="submit" />
                        </Form.Item>
                      </Form>
                    </TabPane>
                    <TabPane
                      tab={(
                        <span>
                          <ImportOutlined />
                          General
                        </span>
                      )}
                      key="general"
                      forceRender
                    >
                      <Form
                        form={formGeneral}
                        layout="vertical"
                        onFinish={onFinish}
                        name="general"
                        initialValues={{
                          estados_globales: 1,
                          es_perecedero: false,
                          tipo_de_producto: 1,
                          es_pedimento: false,
                        }}
                      >
                        <Row gutter={10}>
                          <Col xs={24} sm={24} md={8}>
                            <Form.Item
                              label="Familia"
                              rules={rules.required}
                              name="familia_del_producto"
                            >
                              <Select
                                dataSource={familias}
                                onChange={filterCategoria}
                                labelProp="nombre"
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={8}>
                            <Form.Item
                              label="Categoría"
                              rules={rules.required}
                              name="categoria_del_producto"
                            >
                              <Select
                                labelProp="nombre"
                                dataSource={filteredCats}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={8}>
                            <Form.Item
                              label="Descripción"
                              rules={rules.required}
                              name="descripcion"
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={10}>
                          <Col xs={24} sm={24} md={8}>
                            <Form.Item
                              label="Tipo de Producto"
                              rules={rules.required}
                              name="tipo_de_producto"
                            >
                              <Select dataSource={tiposDeProducto} onChange={setTipoDeProduct} />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={8}>
                            <Form.Item
                              name="unidad_de_medida"
                              rules={rules.required}
                              label="Unidad de Medida"
                            >
                              <Select
                                dataSource={unidadesDeMedida
                                  .filter((u) => u.descripcion !== '')}
                                keyLabelRender
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={8}>
                            <Form.Item
                              name="unidad_equivalente"
                              label="Unidad Equivalente"
                            >
                              <Select
                                dataSource={unidadesDeMedida
                                  .filter((u) => u.descripcion !== '')}
                                mode="multiple"
                                keyLabelRender
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={8}>
                            <Form.Item
                              label="¿Es pedimento?"
                              name="es_pedimento"
                            >
                              <Select
                                trueFalse
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={8}>
                            <Form.Item
                              label="¿Es Perecedero?"
                              name="es_perecedero"
                            >
                              <Select trueFalse onChange={setPerecedero} />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={8}>
                            <Form.Item
                              label="¿Es lote?"
                              name="lote"
                            >
                              <Select
                                trueFalse
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={8}>
                            <Form.Item
                              label="Estado"
                              name="estados_globales"
                            >
                              <Select
                                dataSource={estadosGlobales}
                                disabled={!selectedRowKeys.length}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={8}>
                            <Form.Item
                              label="Observaciones"
                              name="observaciones"
                            >
                              <Input.TextArea autoSize={{ minRows: 3, maxRows: 3 }} allowClear />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Form.Item hidden>
                          <Button htmlType="submit" />
                        </Form.Item>
                      </Form>
                    </TabPane>
                    <TabPane
                      tab={(
                        <span>
                          <DollarCircleOutlined />
                          Precio
                        </span>
                      )}
                      key="precio"
                      forceRender
                    >
                      <Form
                        layout="vertical"
                        form={formPrecio}
                        onFinish={onFinish}
                        name="precio"
                        initialValues={{ esta_exento_IVA: false, IVA: 16 }}
                      >
                        <Row gutter={10}>
                          <Col xs={24} sm={24} md={8}>
                            <NumericInput
                              label="Precio Unitario"
                              name="precio_unitario"
                            />
                          </Col>
                          <Col xs={24} sm={24} md={8}>
                            <Form.Item
                              label="Tipo de Coste"
                              name="tipo_de_coste"
                            >
                              <Select dataSource={tiposDeCosteos} />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={8}>
                            <NumericInput
                              label="Último Precio de Compra"
                              name="ultimo_precio_de_compra"
                            />
                          </Col>
                          <Col xs={24} sm={24} md={8}>
                            <Form.Item
                              label="Fecha del Último Precio de Compra"
                              name="fecha_del_ultimo_precio_de_compra"
                            >
                              <DatePicker
                                placeholder=""
                                format="DD/MM/YYYY"
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={8}>
                            <Form.Item
                              label="¿Excento de IVA?"
                              name="esta_exento_IVA"
                            >
                              <Select trueFalse onChange={setWithIVA} />
                            </Form.Item>
                          </Col>
                          {!withIVA && (
                            <Col xs={24} sm={24} md={8}>
                              <NumericInput
                                label="IVA"
                                name="IVA"
                                required={!withIVA}
                              />
                            </Col>
                          )}
                        </Row>
                        <Form.Item hidden>
                          <Button htmlType="submit" />
                        </Form.Item>
                      </Form>
                    </TabPane>
                    {tipoDeProduct === 1 && (
                      <TabPane
                        tab={(
                          <span>
                            <DatabaseOutlined />
                            Almacen
                          </span>
                        )}
                        key="almacen"
                        forceRender={tipoDeProduct === 1}
                        disabled={!tipoDeProduct === 1}
                      >
                        <Form
                          layout="vertical"
                          form={formAlmacen}
                          onFinish={onFinish}
                          name="almacen"
                        >
                          <Row gutter={10}>
                            <Col xs={24} sm={24} md={8}>
                              <AvatarUploader
                                form={formAlmacen}
                                imageURL={formAlmacen.getFieldValue('imagen')}
                                formItemName="imagen"
                              />
                            </Col>
                            <Col xs={24} sm={24} md={8}>
                              <NumericInput
                                label="Existencia Mínima"
                                name="existencia_minima_del_producto"
                              />
                            </Col>
                            <Col xs={24} sm={24} md={8}>
                              <NumericInput
                                label="Existencia Máxima"
                                name="existencia_maxima_del_producto"
                              />
                            </Col>
                            <Col xs={24} sm={24} md={8}>
                              <NumericInput
                                label="Punto de Reorden"
                                name="punto_de_reorden"
                              />
                            </Col>
                            <Col xs={24} sm={24} md={8}>
                              <NumericInput
                                label="Capacidad de Almacenamiento"
                                name="capacidad_de_almacenamiento"
                              />
                            </Col>
                            <Col xs={24} sm={24} md={8}>
                              <Form.Item
                                name="ubicacion_en_el_almacen"
                                label="Ubicación en el Almacen"
                                required={rules.conditional_required}
                              >
                                <Select dataSource={ubicacionesEnAlmacen} />
                              </Form.Item>
                            </Col>
                            {/* <Col xs={24} sm={24} md={8}>
                              <NumericInput
                                label="Cantidad"
                                name="cantidad"
                              />
                            </Col> */}
                          </Row>
                        </Form>
                      </TabPane>
                    )}
                    <TabPane
                      tab={(
                        <span>
                          <CalculatorOutlined />
                          Datos Contables
                        </span>
                      )}
                      key="contabilidad"
                      forceRender
                    >
                      <Form
                        layout="vertical"
                        form={formContabilidad}
                        onFinish={onFinish}
                        name="contabilidad"
                      >
                        <Row gutter={10}>
                          <InputSearchPro
                            name="clave_contable__clave"
                            onClickAdvanced={() => setVisibleBusquedaCuentas(true)}
                            rules={rules.clave_contable__clave}
                            label="Búsqueda Cuenta Contable"
                            tooltip={(
                              <>
                                <span>
                                  Debe concordar con la mascara
                                  {' '}
                                  {mascara}
                                </span>
                                <br />
                                <span> El primer segmento debe comenzar con 115</span>
                              </>
                            )}
                            inputProps={{
                              onPressEnter: () => fetchCuentaContable(),
                              onChange: onChangeClaveContable,
                              maxLength: mascara?.length,
                            }}
                            breakPoints={{
                              xs: 24,
                              sm: 24,
                              md: 12,
                            }}
                            resultProps={{
                              name: 'cuenta_contable',
                              label: 'Cuenta Contable',
                              rules: rules.required,
                              dataSource: cuentaContableFound,
                              keyLabelRender: true,
                              labelProp: 'nombre',
                              onClear: resetAndValidateCuentaContable,
                              breakPoints: {
                                md: 12,
                              },
                            }}
                          />
                          <Col xs={24} sm={24} md={8}>
                            <Form.Item
                              name="concepto"
                              rules={rules.required}
                              label="Concepto"
                            >
                              <Select dataSource={conceptos} />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Form.Item hidden>
                          <Button htmlType="submit" />
                        </Form.Item>
                      </Form>
                    </TabPane>
                    {/* <TabPane
                      key="documentos"
                      tab={(
                        <span>
                          <FileOutlined />
                          Documentos
                        </span>
                      )}
                      disabled={!selectedRowKeys.length}
                    >
                      <Col span={24}>
                        <DocumentosTramites
                          currentURI="configuraciones/productos"
                          selectedRowKey={selectedRowKeys[0]}
                          allowedExtensions={['PNG', 'JPEG', 'JPG']}
                        />
                      </Col>
                    </TabPane> */}
                  </Tabs>
                </Card>
              </Col>
            )}
          <Modal
            visible={visibleBusquedaCuentas}
            title={(
              <Row justify="space-between">
                Búsqueda Avanzada Cuentas
                <FormSubmitControls
                  onCancel={() => setVisibleBusquedaCuentas(false)}
                  onFinish={onFinishBusqueda}
                />
              </Row>
            )}
            onCancel={() => setVisibleBusquedaCuentas(false)}
            closable={false}
            footer={null}
            width={800}
            forceRender
            centered
          >
            <BusquedaClavesContables callback={setSelectedClave} />
          </Modal>
          <ModalDelete
            onDelete={deleteItem}
            onCancel={() => setVisibleAlert(false)}
            visible={visibleAlert}
            content={`el Producto ${formGeneral.getFieldValue('descripcion')}`}
            loading={loading}
          />
        </Spin>
      </Col>
    </Row>
  );
}

export default Productos;
