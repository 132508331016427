import React from 'react';
import SingleForm from 'components/SingleForm';

export const permissionMedidaDelIndicador = {
  permissionModel: 'medidadelindicador',
};

// eslint-disable-next-line react/prop-types
function MedidasDelIndicador({ permission }) {
  return (
    <SingleForm
      baseURI="presupuestos/medida-del-indicador/"
      title="Medidas"
      permission={permission}
      needsName
      needsDescription={false}
    />
  );
}
export default MedidasDelIndicador;
