import React, { useState, useEffect } from 'react';
import {
  Form,
  Row,
  Spin,
  Col,
  Input,
  Modal,
} from 'antd';

import API from 'utils/api';
import { onError } from 'utils/handlers';
import Table from 'components/Table';
import FormSubmitControls from 'components/FormSubmitControls';

function ConceptoPenalizaciones() {
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visibleModalAgregar, setVisibleModalAgregar] = useState(false);

  const requiredRule = {
    required: true,
    message: 'El campo es requerido',
  };
  const rules = {
    required: [requiredRule],
  };
  const fetchData = async () => {
    try {
      setLoading(true);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchData();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    setTimeout(() => form.setFieldsValue(record));
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickAdd = () => {
    setVisibleModalAgregar(true);
  };

  const onClickEdit = () => {
    setVisibleModalAgregar(true);
  };

  const onClickDelete = () => {};

  const columns = [
    {
      titleText: 'Concepto',
      dataIndex: 'concepto',
      key: 'concepto',
      width: 200,
    },
    {
      titleText: 'Valor',
      dataIndex: 'valor',
      key: 'valor',
      width: 200,
    },
    {
      titleText: 'Aplicación',
      dataIndex: 'aplicacion',
      key: 'aplicacion',
      width: 200,
    },
    {
      titleText: 'Observaciones',
      dataIndex: 'observaciones',
      key: 'observaciones',
      width: 200,
    },
    {
      titleText: 'Estado',
      dataIndex: 'estado',
      key: 'estado',
      width: 200,
    },
  ];

  const onFinish = () => ({});

  const onCancelModalPenalizaciones = () => {
    setVisibleModalAgregar(false);
  };
  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        <Table
          cols={columns}
          data={[]}
          rowSelection={rowSelection}
          handleOnRowClick={handleOnRowClick}
          mobileColIndex={0}
          controls={{
            onClickAdd,
            onClickEdit,
            onClickDelete,
          }}
        />
        <Modal
          visible={visibleModalAgregar}
          closable={false}
          maskClosable={!loading}
          keyboard={!loading}
          footer={null}
          title={(
            <FormSubmitControls
              label={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Finalidad de Gasto`}
              onFinish={onFinish}
              onCancel={onCancelModalPenalizaciones}
              loading={loading}
            />
        )}
          onCancel={onCancelModalPenalizaciones}
          forceRender
        >
          <Form
            form={form}
            layout="vertical"
            name="finalidad_gasto"
            onFinish={onFinish}
            initialValues={{ estados_globales: 1 }}
          >
            <Col span={24}>
              <Form.Item
                name="concepto"
                label="Concepto"
                rules={rules.required}
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="valor"
                label="valor"
                rules={rules.required}
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="aplicacion"
                label="Aplicación"
                rules={rules.required}
              >
                <Input.TextArea
                  autoSize={{ minRows: 4, maxRows: 4 }}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="observaciones"
                label="Observaciones"
              >
                <Input.TextArea
                  autoSize={{ minRows: 2, maxRows: 2 }}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="estado"
                label="Estado"
              >
                <Input allowClear disabled defaultValue="Activo" />
              </Form.Item>
            </Col>
          </Form>
        </Modal>
      </Spin>
    </Row>
  );
}

export default ConceptoPenalizaciones;
