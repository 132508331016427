import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Row,
  Col,
  Spin,
  Card,
  Form,
  Button,
  DatePicker,
  // Modal,
  // message,
  // Input,
} from 'antd';
import FormSubmitControls from 'components/FormSubmitControls';
import Table from 'components/Table';
import ModalDelete from 'components/ModalDelete';
import API from 'utils/api';
// import fetchSome from 'utils/fetchSome';
import { onError, onSuccess } from 'utils/handlers';
import Select from 'components/Select';
import { isMutable } from 'utils/estadosGlobales';
import NumericInput from 'components/NumericInput';
import currencyFormatter from 'utils/currencyFormatter';
import { createDecimalRegExp } from 'utils/patterns';
import formatValues, { DATE_FRONTEND_FORMAT, formatReceived } from 'utils/formatValues';
import ValoresDeRescate from './ValoresDeRescate';
import BitacorasDeAfectacion from './BitacorasDeAfectacion';

const baseURI = 'controlpatrimonial/afectaciones-de-activos-fijos/';

function Afectaciones({ activo }) {
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  const [decimal] = createDecimalRegExp(4, { allowMinus: true });
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const [mutable, setMutable] = useState(true);

  const fetchData = async () => {
    try {
      setLoading(true);
      if (activo.length) {
        const res = await API.get(baseURI, {
          params: {
            activo_fijo: activo[0],
          },
        });
        setData(formatReceived(res.data));
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchData();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setSelectedRowKeys([]);
    form.resetFields();
    setVisible(false);
    setMutable(true);
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      const [activo_fijo] = activo;
      values.activo_fijo = activo_fijo;
      if (!selectedRowKeys.length) {
        const res = await API.post(baseURI, formatValues(values));
        if (res?.status === 201) {
          onSuccess(res, 'Agregado correctamente');
          onCancel();
          await fetchData();
        }
      } else {
        const [key] = selectedRowKeys;
        const res = await API.put(`${baseURI}${key}/`, formatValues(values));
        if (res?.status === 200) {
          onSuccess(res, 'Actualizado correctamente');
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`${baseURI}${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          setVisibleAlert(false);
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    setTimeout(() => form.setFieldsValue(record));
    setMutable(isMutable(record));
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const columns = [
    {
      titleText: 'Monto',
      dataIndex: 'monto',
      key: 'monto',
      width: 150,
      align: 'right',
      render: (val) => currencyFormatter(val),
    },
    {
      titleText: 'Estado',
      dataIndex: 'estados_globales',
      key: 'estados_globales',
      width: 150,
      render: (val) => (estadosGlobales.find((e) => e.id === val)?.descripcion),
    },
  ];

  const onClickEdit = () => {
    setVisible(true);
  };

  const requiredRule = {
    required: mutable,
    message: 'Este campo es requerido',
  };

  const rules = {
    required: [requiredRule],
    decimal_rules: [
      // requiredRule,
      {
        validator: async (_, val) => {
          if (val && !decimal.test(val)) {
            throw new Error('Ingrese un valor decimal válido');
          }
        },
      },
    ],
  };

  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible ? (
          <Table
            cols={columns}
            data={data}
            rowSelection={rowSelection}
            handleOnRowClick={handleOnRowClick}
            loading={loading}
            controls={{
              onClickAdd: !data.length && onClickAdd,
              onClickEdit,
              onClickDelete,
            }}
            mobileColIndex={0}
          />
        ) : (
          <Card
            className="form-container"
            title={`${!selectedRowKeys.length ? 'Agregar' : 'Editar'} Afectación de Activo Fijo`}
            extra={(
              <FormSubmitControls
                onFinish={onFinish}
                onCancel={onCancel}
                allowAuthorize
                allowCancel
                mutable={mutable}
                baseURI={baseURI}
                selectedRowKeys={selectedRowKeys}
                callback={(estados_globales) => {
                  const [key] = selectedRowKeys;
                  const normalized = data.map((e) => {
                    if (e.id === key) {
                      setMutable(false);
                      return {
                        ...e,
                        estados_globales,
                      };
                    }
                    return e;
                  });
                  setData(normalized);
                  form.setFieldsValue({ estados_globales });
                }}
              />
            )}
            bordered={false}
          >
            <Form
              name="general"
              form={form}
              layout="vertical"
              onFinish={onFinish}
              scrollToFirstError
              initialValues={{ estados_globales: 1 }}
            >
              <Row gutter={10}>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="fecha_de_ultima_depreciacion_amortizacion_o_deteriodo"
                    label="Fecha de último cálculo"
                    tooltip="Fecha de última depreciacion, amortización o deterioro"
                  >
                    <DatePicker format={DATE_FRONTEND_FORMAT} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <NumericInput
                    name="monto"
                    label="Monto"
                    required
                  />
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <NumericInput
                    name="monto_depreciado"
                    label="Monto depreciado"
                    required
                  />
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <NumericInput
                    name="monto_por_depreciar"
                    label="Monto por depreciar"
                    required
                    rules={rules.decimal_rules}
                  />
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <NumericInput
                    name="porcentaje_de_afectacion"
                    label="Porcentaje por afectar"
                    required
                  />
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <NumericInput
                    name="valor_de_rescate"
                    label="Valor de rescate"
                  />
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <NumericInput
                    name="dias_totales_desperdiciados"
                    label="Días desperdiciados"
                    required
                  />
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <NumericInput
                    name="dias_totales_depreciados"
                    label="Días depreciados"
                    required
                  />
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <NumericInput
                    name="dias_totales_por_depreciar"
                    label="Días por depreciar"
                    rules={rules.decimal_rules}
                    required
                  />
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    label="Estado"
                    name="estados_globales"
                  >
                    <Select
                      dataSource={estadosGlobales}
                      disabled={!selectedRowKeys.length || !mutable}
                    />
                  </Form.Item>
                </Col>
                <Form.Item hidden>
                  <Button htmlType="submit" />
                </Form.Item>
              </Row>
              <ValoresDeRescate />
              <BitacorasDeAfectacion />
            </Form>
          </Card>
        )}
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => setVisibleAlert(false)}
          visible={visibleAlert}
          content="la afectación seleccionada"
          loading={loading}
        />
      </Spin>
    </Row>
  );
}

Afectaciones.propTypes = {
  activo: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default Afectaciones;
