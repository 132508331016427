/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Row,
  Spin,
  Tabs,
  Tag,
} from 'antd';
import { isMutable } from 'utils/estadosGlobales';
import { onError, onSuccess } from 'utils/handlers';
import API from 'utils/api';
import FormSubmitControls from 'components/FormSubmitControls';
import keyGenerator from 'utils/keyGenerator';
import ModalDelete from 'components/ModalDelete';
import NumericInput from 'components/NumericInput';
import Select from 'components/Select';
import Table from 'components/Table';
import Transfer from 'components/PlainTransfer';

const { TabPane } = Tabs;

const baseURI = 'configuraciones/pasos-de-configuraciones-del-flujo/';

function ConfiguracionDePasosDeFlujo({
  actividades,
  disabled,
  parentId,
  roles,
  ur,
  urContentType,
  usuarios,
}) {
  const { estadosGlobales } = useSelector((state) => state.catalogs);
  const [form] = Form.useForm();
  const [formTime] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);
  const [mutable, setMutable] = useState(true);
  const [currentTabKey, setCurrentTabKey] = useState('general');

  const [requireEmail, setRequireEmail] = useState(false);

  const basicKeys = ['general', 'tiempo'];
  const complexForms = [];

  const fetchData = async () => {
    try {
      setLoading(true);
      if (parentId.length) {
        const [configuracion_de_flujo_de_proceso_operativo] = parentId;
        const response = await API.get(baseURI, {
          params: { configuracion_de_flujo_de_proceso_operativo },
        });
        const sortedData = response.data.sort((a, b) => a.paso - b.paso);
        setData(sortedData || []);
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [parentId]);

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    formTime.resetFields();
    setCurrentTabKey('general');
    setMutable(true);
    setSelectedRowKeys([]);
    setRequireEmail(false);
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const [configuracion_de_flujo_de_proceso_operativo] = parentId;
      const values = {
        ...form.getFieldsValue(),
        ...formTime.getFieldsValue(),
        configuracion_de_flujo_de_proceso_operativo,
      };
      if (!selectedRowKeys.length) {
        const response = await API.post(baseURI, values);
        if (response?.status === 201) {
          onSuccess(response, 'Agregado correctamente');
          await fetchData();
          onCancel();
        }
      } else {
        const [key] = selectedRowKeys;
        const response = await API.put(`${baseURI}${key}/`, values);
        if (response?.status === 200) {
          onSuccess(response, 'Actualizado correctamente');
          await fetchData();
          onCancel();
        }
      }

      setLoading(false);
    } catch (err) {
      onError(err, setLoading, [form, formTime], setCurrentTabKey);
    }
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`${baseURI}${key}/`);
        if (response && response.status === 204) {
          onSuccess(response, 'Eliminado correctamente', 2.5);
          onCancel();
          fetchData();
          setVisibleAlert(false);
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    form.setFieldsValue(record);
    formTime.setFieldsValue(record);
    setMutable(isMutable(record));
    setSelectedRowKeys([record.id]);
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickEdit = () => {
    setVisible(true);
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const columns = [
    {
      titleText: 'Paso',
      dataIndex: 'paso',
      key: 'paso',
      width: 100,
    },
    {
      titleText: 'Actividad',
      dataIndex: 'actividad',
      key: 'actividad',
      width: 150,
      render: (id) => actividades.find((e) => e.id === id)?.descripcion,
    },
    {
      titleText: 'Rol Responsable',
      dataIndex: 'rol_responsable',
      key: 'rol_responsable',
      width: 300,
      render: (val = []) => {
        if (val?.length && roles?.length) {
          const matches = val.map((e) => roles?.find((i) => i?.id === e));
          if (matches.length) {
            const output = matches.map((e) => (
              <Tag key={e?.id}>{e.name}</Tag>
            ));
            return output;
          }
        }
        return (<span />);
      },
    },
    {
      titleText: 'Rol del Firmante',
      dataIndex: 'rol_del_firmante',
      key: 'rol_del_firmante',
      width: 200,
      render: (id) => roles.find((e) => e.id === id)?.name,

    },
    {
      titleText: '¿Requiere archivo adjunto?',
      dataIndex: 'requiere_archivo_adjunto',
      key: 'requiere_archivo_adjunto',
      width: 300,
      render: (val) => (val ? 'Sí' : 'No'),
    },
    {
      titleText: 'Estado',
      dataIndex: 'estados_globales',
      key: 'estados_globales',
      width: 190,
      render: (id) => estadosGlobales.find((e) => e.id === id)?.descripcion,
    },
  ];

  const requiredRule = {
    required: true,
    message: 'El campo es requerido',
  };

  const rules = {
    required: [
      requiredRule,
    ],
    correo: [
      requiredRule,
      {
        type: 'email',
        message: 'Introduzca un correo válido',
      },
    ],
  };

  const showMsg = (tabKey) => {
    message.info({
      content: (
        <>
          <br />
          <Row style={{ width: '100%' }}>
            Los cambios que hayan sido realizados serán descartados.
          </Row>
          <Row align="middle" style={{ width: '100%' }}>
            ¿Desea continuar?
            <Button
              type="link"
              onClick={() => {
                setCurrentTabKey(tabKey);
                message.destroy();
                onClickEdit();
              }}
            >
              Si
            </Button>
            <Button
              type="link"
              onClick={() => message.destroy()}
            >
              No
            </Button>
          </Row>
        </>
      ),
      duration: 0,
    });
  };

  const onChangeTabKey = (key) => {
    const fromBasic = basicKeys.includes(currentTabKey);
    const toBasic = basicKeys.includes(key);
    const match = complexForms.find((e) => e.key === currentTabKey);
    if (!match) {
      if (fromBasic && toBasic) {
        setCurrentTabKey(key);
      } else if (fromBasic) {
        showMsg(key);
      } else {
        setCurrentTabKey(key);
      }
    } else {
      match.formInstance.onFinishHandler(key, true);
    }
  };

  const onClickAdd = () => {
    keyGenerator(data, form, { keyProp: 'paso' });
    setVisible(true);
  };

  return (
    <Row align="center" justify="center" className="container">
      <Col span={24}>
        <Spin tip="Cargando..." spinning={loading}>
          {!visible ? (
            <Table
              cols={columns}
              data={data}
              rowSelection={rowSelection}
              handleOnRowClick={handleOnRowClick}
              controls={{
                onClickAdd,
                onClickEdit,
                onClickDelete,
              }}
              mobileColIndex={0}
              disabled={disabled}
              rowKey="id"
            />
          ) : (
            <Col span={24} style={{ height: '100%' }}>
              <Card
                className="form-container"
                title={(
                  <FormSubmitControls
                    label={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Paso`}
                    mutable={mutable}
                    onCancel={onCancel}
                    onFinish={onFinish}
                  />
                )}
                bordered={false}
              >
                <Tabs
                  activeKey={currentTabKey}
                  onChange={mutable ? onChangeTabKey : setCurrentTabKey}
                  type="card"
                >
                  <TabPane
                    forceRender
                    key="general"
                    tab="General"
                  >
                    <Form
                      layout="vertical"
                      name="general"
                      form={form}
                      onFinish={onFinish}
                      initialValues={{
                        estados_globales: 1,
                        content_type: urContentType,
                        object_id: ur?.id,
                      }}
                      scrollToFirstError
                    >
                      <Form.Item name="content_type" hidden>
                        <Input />
                      </Form.Item>
                      <Form.Item name="object_id" hidden>
                        <Input />
                      </Form.Item>
                      <Row gutter={10}>
                        <Col xs={24} sm={24} md={8}>
                          <NumericInput
                            name="paso"
                            label="Paso"
                            required
                          />
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                          <Form.Item
                            name="actividad"
                            label="Actividad"
                          >
                            <Select dataSource={actividades} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                          <Form.Item
                            name="rol_del_firmante"
                            label="Rol del firmante"
                          >
                            <Select dataSource={roles} labelProp="name" />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={16}>
                          <Form.Item
                            name="funcionario"
                            rules={rules.required}
                            label="Funcionario"
                            hasFeedback
                          >
                            <Select dataSource={usuarios} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                          <Form.Item
                            name="muestra_alerta"
                            label="¿Mostrar alerta?"
                            hasFeedback
                          >
                            <Select trueFalse />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                          <Form.Item
                            name="estados_globales"
                            label="Estado"
                            rules={rules.required}
                            hasFeedback
                          >
                            <Select
                              disabled={!selectedRowKeys.length}
                              globalStates
                            />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Transfer
                            dataSource={roles}
                            label="Roles"
                            form={form}
                            formItemName="rol_responsable"
                            filterProp="name"
                            rules={rules.required}
                          />
                        </Col>
                      </Row>
                      <Form.Item hidden>
                        <Button htmlType="submit" />
                      </Form.Item>
                    </Form>
                  </TabPane>
                  <TabPane
                    forceRender
                    key="tiempo"
                    tab="Tiempo de Resolución"
                  >
                    <Form
                      form={formTime}
                      layout="vertical"
                      name="tiempo"
                      onFinish={onFinish}
                      initialValues={{
                        envio_de_correo: false,
                        aplica_dias_habiles: true,
                        requiere_archivo_adjunto: true,
                      }}
                      scrollToFirstError
                    >
                      <Row gutter={10}>
                        <Col xs={24} sm={24} md={8}>
                          <NumericInput
                            name="tiempo_asignado"
                            label="Tiempo asignado"
                            required
                          />
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                          <Form.Item
                            name="aplica_dias_habiles"
                            label="¿Aplican días hábiles?"
                            hasFeedback
                          >
                            <Select trueFalse />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                          <NumericInput
                            name="dias_para_mostrar_alerta"
                            label="Días para mostrar alerta"
                          />
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                          <Form.Item
                            name="requiere_archivo_adjunto"
                            label="¿Requiere archivo adjunto?"
                            hasFeedback
                          >
                            <Select trueFalse />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                          <Form.Item
                            name="envio_de_correo"
                            label="¿Enviar correo?"
                            hasFeedback
                          >
                            <Select trueFalse onChange={setRequireEmail} />
                          </Form.Item>
                        </Col>
                        {requireEmail && (
                        <Col xs={24} sm={24} md={8}>
                          <Form.Item
                            name="correo_destinatorio"
                            rules={rules.correo}
                            label="Correo"
                          >
                            <Input allowClear />
                          </Form.Item>
                        </Col>
                        )}
                        <Form.Item hidden>
                          <Button htmlType="submit" />
                        </Form.Item>
                      </Row>
                    </Form>
                  </TabPane>
                </Tabs>
              </Card>
            </Col>
          )}
          <ModalDelete
            onDelete={deleteItem}
            onCancel={() => setVisibleAlert(false)}
            visible={visibleAlert}
            content={`Eliminar el Paso Número ${form.getFieldValue('paso')}`}
          />
        </Spin>
      </Col>
    </Row>
  );
}

ConfiguracionDePasosDeFlujo.propTypes = {
  actividades: PropTypes.arrayOf(PropTypes.object),
  disabled: PropTypes.bool.isRequired,
  parentId: PropTypes.arrayOf(PropTypes.number),
  roles: PropTypes.arrayOf(PropTypes.object),
  ur: PropTypes.objectOf(PropTypes.any).isRequired,
  urContentType: PropTypes.number.isRequired,
  usuarios: PropTypes.arrayOf(PropTypes.object),
};

ConfiguracionDePasosDeFlujo.defaultProps = {
  actividades: [],
  parentId: [],
  roles: [],
  usuarios: [],
};

export default ConfiguracionDePasosDeFlujo;
