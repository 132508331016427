/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Spin,
  Modal,
  message,
  Typography,
} from 'antd';
// import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import API from 'utils/api';
import { onError, onSuccess } from 'utils/handlers';
import Table from 'components/Table';
import FormSubmitControls from 'components/FormSubmitControls';
import ModalDelete from 'components/ModalDelete';
import Select from 'components/Select';
import NumericInput from 'components/NumericInput';
import FormItemLabel from 'components/FormItemLabel';
import { isMutable } from 'utils/estadosGlobales';
import { useSelector } from 'react-redux';
import PlanesDeDesarrollo from './PlanesDeDesarrollo';
import PlanDeDesarrolloGrid from './PlanDeDesarrolloGrid';
// import ModalConfigPlan from './ModalConfigPlan';
// import PlanesDeDesarrollo from './PlanesDeDesarrollo';

export const permissionNivelPlanDeDesarrollo = {
  permissionModel: 'niveldelplandedesarrollo',
};

const baseURI = 'catalogos/nivel-del-plan-de-desarrollo/';
const planURI = '/configuraciones/plan-de-desarrollo/';
const { useForm } = Form;

// eslint-disable-next-line react/prop-types
function NivelesDeDesarrollo({ permission }) {
  const { estadosGlobales } = useSelector(({ catalogs }) => catalogs);
  const [form] = useForm();
  const [formMostrar] = useForm();
  const [formNiveles] = Form.useForm();
  const mostrarTabla = Form.useWatch('mostrar_tabla', formMostrar) || false;
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  // const [visibleTipoDeDesarrollo, setVisibleTipoDeDesarrollo] = useState(false);
  const [data, setData] = useState([]);
  const [tiposDePlanes, setTiposDePlanes] = useState([]);
  const [mutable, setMutable] = useState(true);
  const [plan, setPlan] = useState(0);
  const [disableForm, setDisableForm] = useState(false);
  // const [visibleConfig, setVisibleConfig] = useState(false);

  // const [objetivos, setObjetivos] = useState([]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await API.get(baseURI);
      setData(res.data);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const fetchAll = async () => {
    try {
      setLoading(true);

      // const resObjetivos = await API.get('catalogos/objetivo-de-desarrollo-sustentable/');
      // setObjetivos(resObjetivos.data);

      const resTipos = await API.get('/catalogos/tipos-de-planes-de-desarrollo/');
      setTiposDePlanes(resTipos.data || []);

      const resTipo = await API.get(planURI);
      if (resTipo.data.length > 0) {
        setTimeout(() => formNiveles.setFieldsValue(resTipo.data[0]));
        setPlan(resTipo.data[0].id);
        setDisableForm(true);
      }

      await fetchData();
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchAll();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    setSelectedRowKeys([]);
    setMutable(true);
  };

  const validateAuthorizable = async (match = {}) => {
    try {
      setLoading(true);
      const nivel_de_plan_de_desarrollo = match?.id || selectedRowKeys[0];
      const response = await API.get('catalogos/nivel-del-plan-de-desarrollo/', {
        params: {
          nivel_de_plan_de_desarrollo,
        },
      });
      if (response.status === 200 && response.data?.length) {
        return response.data;
      }

      setLoading(false);
      return null;
    } catch (err) {
      onError(err, setLoading);
      return null;
    }
  };

  const onFinishNiveles = async () => {
    try {
      setLoading(true);
      await formNiveles.validateFields();
      const values = formNiveles.getFieldsValue();
      if (values.fecha_inicial > 1999 && values.fecha_final > 1999) {
        if (values.fecha_inicial <= values.fecha_final) {
          const response = await API.post(planURI, values);
          if (response.status === 201) {
            onSuccess(response, 'Se guardo correctamente la configuracion');
            setDisableForm(true);
            await fetchAll();
          }
        } else {
          message.warn('El año inicial no puede ser mayo que el año final');
        }
      } else {
        message.warn('El año inicial o final no es valido, el formato debe ser del año 2000 en adelante');
      }
      setLoading(false);
    } catch (error) {
      onError(error, setLoading);
    }
  };

  const onFinish = async (values) => {
    try {
      setLoading(true);
      await form.validateFields();
      // const values = form.getFieldsValue();
      const planes = await validateAuthorizable();
      if (!planes?.length && selectedRowKeys?.length) {
        message.warn('No se han agregado planes de desarrollo.');
      } else if (!selectedRowKeys.length) {
        const response = await API.post(baseURI, values);
        if (response?.status === 201) {
          onSuccess(response, 'Agregado correctamente');
          onCancel();
          await fetchAll();
          return response.data;
        }
      } else {
        const [key] = selectedRowKeys;
        const response = await API.put(`${baseURI}${key}/`, values);
        if (response?.status === 200) {
          onSuccess(response, 'Actualizado correctamente');
          onCancel();
          await fetchData();
          return response.data;
        }
      }
      setLoading(false);
      return null;
    } catch (err) {
      onError(err, setLoading);
      return null;
    }
  };

  // const onFinishNiveles = async () => {

  // };

  const deleteItem = async () => {
    try {
      setLoading(true);
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`${baseURI}${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          setVisibleAlert(false);
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
  };

  // const render = (val) => (val
  //   ? (
  //     <span style={{ color: '#1bc943' }}>
  //       <CheckOutlined />
  //     </span>
  //   ) : (
  //     <span style={{ color: '#ff4d4f' }}>
  //       <CloseOutlined />
  //     </span>
  //   ));

  const render = (val) => (val ? 'Si' : 'No');

  const columns = [
    {
      titleText: 'Nombre',
      dataIndex: 'nombre_del_nivel',
      key: 'nombre_del_nivel',
      width: 300,
    },
    {
      titleText: '¿Requiere Objetivo?',
      dataIndex: 'objetivo',
      key: 'objetivo',
      width: 200,
      render,
    },
    {
      titleText: '¿Requiere PED?',
      dataIndex: 'plan_estatal_de_desarrollo',
      key: 'plan_estatal_de_desarrollo',
      width: 200,
      render,
    },
    {
      titleText: '¿Requiere PND?',
      dataIndex: 'plan_nacional_de_desarrollo',
      key: 'plan_nacional_de_desarrollo',
      width: 200,
      render,
    },
    {
      titleText: '¿Requiere PMD?',
      dataIndex: 'plan_municipal_de_desarrollo',
      key: 'plan_municipal_de_desarrollo',
      width: 200,
      render,
    },
    {
      titleText: '¿Requiere ODS?',
      dataIndex: 'objetivos_de_desarrollo_sostenible',
      key: 'objetivos_de_desarrollo_sustentable',
      width: 250,
      render,
    },
    {
      titleText: '¿Requiere DR?',
      dataIndex: 'dependencia_responsable',
      key: 'dependencia_responsable',
      width: 250,
      render,
    },
    {
      titleText: 'Estado',
      dataIndex: 'estados_globales',
      key: 'estados_globales',
      width: 300,
      render: (val) => estadosGlobales.find((t) => t.id === val)?.descripcion,
    },
  ];

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    setMutable(isMutable(record));
    setTimeout(() => form.setFieldsValue(record));
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const onClickEdit = async () => {
    setVisible(true);
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  function Formulario({ modal }) {
    let bp = {
      span: 12,
    };
    if (!modal) {
      bp = {
        md: 8,
        sm: 24,
        xs: 24,
      };
    }

    const initialValues = {
      estados_globales: 1,
      objetivo: false,
      plan_nacional_de_desarrollo: false,
      plan_estatal_de_desarrollo: false,
      plan_municipal_de_desarrollo: false,
      objetivos_de_desarrollo_sostenible: false,
      mas_de_un_objetivo_sostenible: false,
      dependencia_responsable: false,
      mas_de_una_dependencia_responsable: false,
      plan_de_desarrollo: plan,
    };

    return (

      <Spin spinning={loading} tip="Cargando...">
        <Form
          layout="vertical"
          form={form}
          onFinish={() => onFinish(form.getFieldsValue())}
          scrollToFirstError
          initialValues={initialValues}
        >
          <Row gutter={10}>
            <Col {...bp}>
              <Form.Item
                name="nombre_del_nivel"
                rules={rules.required}
                label="Nombre"
                hasFeedback
              >
                <Input allowClear disabled={!mutable} />
              </Form.Item>
            </Col>
            <Form.Item
              name="plan_de_desarrollo"
              hidden
            >
              <Input />
            </Form.Item>
            <Col {...bp}>
              <Form.Item
                name="objetivo"
                label={(
                  <FormItemLabel
                    longTitle="¿Requiere Objetivo?"
                    shortTitle="¿Requiere Objetivo?"
                  />
                )}
              >
                <Select trueFalse disabled={!mutable} />
              </Form.Item>
            </Col>
            <Col {...bp}>
              <Form.Item
                name="plan_nacional_de_desarrollo"
                label={(
                  <FormItemLabel
                    longTitle="¿Requiere Alineacion al Plan Nacional de Desarrollo?"
                    shortTitle="¿Requiere PND?"
                  />
                )}
              >
                <Select trueFalse disabled={!mutable} />
              </Form.Item>
            </Col>
            <Col {...bp}>
              <Form.Item
                name="plan_estatal_de_desarrollo"
                label={(
                  <FormItemLabel
                    longTitle="¿Requiere Alineación Plan Estatal de Desarrollo?"
                    shortTitle="¿Requiere PED?"
                  />
                )}
              >
                <Select trueFalse disabled={!mutable} />
              </Form.Item>
            </Col>
            <Col {...bp}>
              <Form.Item
                name="plan_municipal_de_desarrollo"
                label={(
                  <FormItemLabel
                    longTitle="¿Requiere Alineación Plan Municipal de Desarrollo?"
                    shortTitle="¿Requiere PMD?"
                  />
                )}
              >
                <Select trueFalse disabled={!mutable} />
              </Form.Item>
            </Col>
            <Col {...bp}>
              <Form.Item
                name="objetivos_de_desarrollo_sostenible"
                label={(
                  <FormItemLabel
                    longTitle="¿Requiere Objetivos de Desarrollo Sostenible?"
                    shortTitle="¿Requiere ODS?"
                  />
                )}
              >
                <Select trueFalse disabled={!mutable} />
              </Form.Item>
            </Col>
            <Col {...bp}>
              <Form.Item
                name="dependencia_responsable"
                label={(
                  <FormItemLabel
                    longTitle="¿Requiere Dependencias Responsables?"
                    shortTitle="¿Requiere DR?"
                  />
                )}
              >
                <Select trueFalse disabled={!mutable} />
              </Form.Item>
            </Col>
            <Col {...bp}>
              <Form.Item
                label="Estado"
                rules={rules.required}
                name="estados_globales"
              >
                <Select
                  dataSource={estadosGlobales}
                  disabled={!selectedRowKeys.length || !mutable}
                />
              </Form.Item>
            </Col>
            <Form.Item hidden>
              <Button htmlType="submit" />
            </Form.Item>
          </Row>
        </Form>
      </Spin>
    );
  }

  Formulario.propTypes = {
    modal: PropTypes.bool,
  };

  Formulario.defaultProps = {
    modal: false,
  };

  const normalizeDataAuthorized = (estados_globales) => {
    const [key] = selectedRowKeys;
    const normalized = [...data]
      .map((e) => (e.id === key ? { ...e, estados_globales } : e));
    setMutable(false);
    setData(normalized);
    const match = normalized.find((e) => e.id === key);
    onClickEdit(match, true);
    onCancel();
  };

  const initialValuesMostrar = {
    mostrar_tabla: false,
  };
  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        <Row justify="end">
          <FormSubmitControls
            mutable={!disableForm}
            onFinish={() => onFinishNiveles()}
            loading={loading}
          />
        </Row>
        <Form
          layout="vertical"
          form={formNiveles}
          onFinish={() => onFinishNiveles()}
          scrollToFirstError
        >
          <Row gutter={10}>
            <Col md={8} sm={24} xs={24}>
              <Form.Item
                label="Tipo de plan de desarrollo"
                name="tipo_de_plan_seleccionado"
              >
                <Select
                  dataSource={tiposDePlanes}
                  labelProp="nombre"
                  disabled={disableForm}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <NumericInput
                name="fecha_inicial"
                label="Año Inicial"
                required
                disabled={disableForm}
              />
            </Col>
            <Col xs={24} sm={24} md={8}>
              <NumericInput
                name="fecha_final"
                label="Año Final"
                required
                disabled={disableForm}
              />
            </Col>
          </Row>
        </Form>
        {disableForm && (
          <Form
            layout="vertical"
            form={formMostrar}
            scrollToFirstError
            initialValues={initialValuesMostrar}
          >
            <Col md={8} sm={24} xs={24}>
              <Form.Item
                name="mostrar_tabla"
                label="Mostrar la tabla de registros"
              >
                <Select
                  trueFalse
                  onChange={(val) => {
                    if (val) {
                      onCancel();
                    }
                  }}
                />
              </Form.Item>
            </Col>
          </Form>
        )}
        {!mostrarTabla ? (
          <>
            <Col md={24} sm={24} xs={24}>
              <Typography.Title
                level={4}
              >
                Tabla de Registros de los Niveles de Desarrollo
              </Typography.Title>
            </Col>
            {!(visible && selectedRowKeys.length) && (
              <Table
                allowImport
                baseURI={baseURI}
                cols={columns}
                data={data}
                permission={permission}
                rowSelection={rowSelection}
                handleOnRowClick={handleOnRowClick}
                allowAdd={disableForm}
                controls={{
                  onClickAdd,
                  onClickEdit,
                  onClickDelete,
                }}
              />
            )}
            {(visible && !!selectedRowKeys.length) && (
              <Card
                className="form-container"
                title={(
                  <FormSubmitControls
                    label={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Nivel`}
                    onFinish={() => onFinish(form.getFieldsValue())}
                    onCancel={onCancel}
                    loading={loading}
                    allowAuthorize
                    baseURI={baseURI}
                    selectedRowKeys={selectedRowKeys}
                    allowCancel
                    mutable={mutable}
                    callback={normalizeDataAuthorized}
                  />
                )}
                bordered={false}
              >
                <Formulario />
              </Card>
            )}
            {!selectedRowKeys.length && (
              <Modal
                visible={visible && !selectedRowKeys.length}
                title={(
                  <FormSubmitControls
                    label={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Nivel`}
                    onFinish={() => onFinish(form.getFieldsValue())}
                    onCancel={onCancel}
                    loading={loading}
                  />
                )}
                width={1024}
                closable={false}
                maskClosable={!loading}
                keyboard={!loading}
                onCancel={onCancel}
                footer={null}
                forceRender
              >
                <Formulario modal />
              </Modal>
            )}
            <Col md={24} sm={24} xs={24}>
              <Typography.Title
                level={4}
              >
                Tabla de Registros del Plan De Desarrollo
              </Typography.Title>
            </Col>
            <PlanDeDesarrolloGrid />
          </>
        ) : (
          <Row gutter={10}>
            <Col md={24} sm={24} xs={24}>
              <Typography.Title
                level={4}
              >
                Tabla de Registros del Plan De Desarrollo
              </Typography.Title>
            </Col>
            <Col md={24} sm={24} xs={24}>
              <PlanesDeDesarrollo nivelesDeDesarrollo={data} planDeDesarrollo={plan} />
            </Col>
          </Row>
        )}
        {/* <ModalConfigPlan
          visible={visibleConfig}
          setVisible={setVisibleConfig}
        /> */}
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => setVisibleAlert(false)}
          visible={visibleAlert}
          content={`el nivel ${form.getFieldValue('nombre_del_nivel')}`}
        />
      </Spin>
    </Row>
  );
}

export default NivelesDeDesarrollo;
