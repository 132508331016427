import React, { useState, useEffect } from 'react';
import {
  Card,
  Form,
  Row,
  Spin,
  Col,
  Input,
  Button,
  Select,
  Tabs,
  Modal,
} from 'antd';

import {
  ArrowLeftOutlined,
  SaveOutlined,
  FilePdfOutlined,
  FileExcelOutlined,
  SearchOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';
import API from 'utils/api';
import { onError } from 'utils/handlers';
import Table from 'components/Table';
import FormSubmitControls from 'components/FormSubmitControls';

function ConceptosSolicitudPago() {
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visiblePartida, setVisiblePartida] = useState(false);
  const [visibleModalDesglose, setVisibleModalDesglose] = useState(false);
  const [currentTabKey, setCurrentTabKey] = useState('concepto');
  const [mutable, setMutable] = useState(true);

  const { TabPane } = Tabs;

  const requiredRule = {
    required: true,
    message: 'El campo es requerido',
  };
  const rules = {
    required: [requiredRule],
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchData();
    return () => API.tryCancel;
  }, []);

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    setTimeout(() => form.setFieldsValue(record));
  };

  const onClickAdd = () => {
    setVisible(true);
  };

  const onClickEdit = () => {};

  const onClickDelete = () => {};

  const onClickSaveForm = () => {};

  const onDescargarPDF = () => {};

  const onDescargarExcel = () => {};

  const onChangeTabKey = () => {};

  const onClickAddPartida = () => {};

  const onClickDeletePartida = () => {};

  const onSearchCOG = () => {
    setVisiblePartida(true);
  };

  const onSearchClavePresupuestal = () => {};

  const onSearchClavePresupuestalGrid = () => {};

  const onAceptarClavePresupuestal = () => {
    setVisiblePartida(false);
  };

  const onClickAddDesglose = () => {
    setVisibleModalDesglose(true);
  };

  const onClickEditDesglose = () => {};

  const onClickDeleteDesglose = () => {};

  const onExportarPDF = () => {};

  const onExportarExcel = () => {};

  const columns = [
    {
      titleText: 'Concepto de Solicitud',
      dataIndex: 'concepto_solicitud',
      key: 'concepto_solicitud',
      width: 250,
    },
    {
      titleText: 'Tipo de Tercero',
      dataIndex: 'tipo_tercero',
      key: 'tipo_tercero',
      width: 250,
    },
    {
      titleText: 'Concepto General',
      dataIndex: 'concepto_general',
      key: 'concepto_general',
      width: 200,
    },
    {
      titleText: 'Beneficiario',
      dataIndex: 'beneficiario',
      key: 'beneficiario',
      width: 300,
    },
    {
      titleText: 'Forma de Pago',
      dataIndex: 'forma_pago',
      key: 'forma_pago',
      width: 150,
    },
    {
      titleText: 'Aplica Presupuesto',
      dataIndex: 'aplica_presupuesto',
      key: 'aplica_presupuesto',
      width: 200,
    },
    {
      titleText: 'Momento Presupuestal',
      dataIndex: 'momento_presupuestal',
      key: 'momento_presupuestal',
      width: 350,
    },
    {
      titleText: 'Finalidad del Gasto',
      dataIndex: 'finalidad_gasto',
      key: 'finalidad_gasto',
      width: 200,
    },
    {
      titleText: 'Aplica CFDI',
      dataIndex: 'aplica_cfdi',
      key: 'aplica_cfdi',
      width: 150,
    },
    {
      titleText: 'Tipo de Comprobante',
      dataIndex: 'tipo_comprobante',
      key: 'tipo_comprobante',
      width: 200,
    },
    {
      titleText: 'Aplica Desglose',
      dataIndex: 'aplica_desglose',
      key: 'aplica_desglose',
      width: 200,
    },
    {
      titleText: 'Aplica Contra-Recibo',
      dataIndex: 'aplica_contra_recibo',
      key: 'aplica_contra_recibo',
      width: 200,
    },
    {
      titleText: 'Observaciones',
      dataIndex: 'observaciones',
      key: 'observaciones',
      width: 250,
    },
    {
      titleText: 'Estado',
      dataIndex: 'estado',
      key: 'estado',
      width: 150,
    },
  ];

  const columnsPartidas = [
    {
      titleText: 'Rubro',
      dataIndex: 'rubro',
      key: 'rubro',
      width: 200,
    },
    {
      titleText: 'Clave Presupuestal',
      dataIndex: 'clave_presupuestal',
      key: 'clave_presupuestal',
      width: 250,
    },
    {
      titleText: 'COG',
      dataIndex: 'cog',
      key: 'cog',
      width: 150,
    },
    {
      titleText: 'Concepto de COG',
      dataIndex: 'concepto_cog',
      key: 'concepto_cog',
      width: 250,
    },
  ];

  const columnsClavePresupuestal = [
    {
      titleText: 'Clave Presupuestal',
      dataIndex: 'clave_presupuestal',
      key: 'clave_presupuestal',
      width: 250,
    },
    {
      titleText: 'COG',
      dataIndex: 'cog',
      key: 'cog',
      width: 200,
    },
    {
      titleText: 'Fuente de Financiamiento',
      dataIndex: 'fuente_financiamiento',
      key: 'fuente_financiamiento',
      width: 200,
    },
    {
      titleText: 'Programa',
      dataIndex: 'programa',
      key: 'programa',
      width: 150,
    },
    {
      titleText: 'Proyecto',
      dataIndex: 'proyecto',
      key: 'proyecto',
      width: 150,
    },
    {
      titleText: 'Área',
      dataIndex: 'area',
      key: 'area',
      width: 150,
    },
  ];

  const columnsDesglose = [
    {
      titleText: 'Rubro',
      dataIndex: 'rubro',
      key: 'rubro',
      width: 200,
    },
    {
      titleText: 'Clave Presupuestal',
      dataIndex: 'clave_presupuestal',
      key: 'clave_presupuestal',
      width: 250,
    },
    {
      titleText: 'Cuenta Contable',
      dataIndex: 'cuenta_contable',
      key: 'cuenta_contable',
      width: 150,
    },
    {
      titleText: 'Cargo/Abono',
      dataIndex: 'cargo_abono',
      key: 'cargo_abono',
      width: 150,
    },
  ];

  const onFinish = () => ({});
  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    setSelectedRowKeys([]);
    setMutable(true);
  };

  const onFinishDesglose = () => ({});
  const onCancelDesglose = () => {
    setVisibleModalDesglose(false);
  };

  function CatalogoSolicitudPagosGrid() {
    return (
      <Table
        cols={columns}
        data={[]}
        rowSelection={rowSelection}
        handleOnRowClick={handleOnRowClick}
        mobileColIndex={0}
        controls={{
          onClickAdd,
          onClickEdit,
          onClickDelete,
        }}
        customActions={[
          {
            onClick: onDescargarPDF,
            icon: <FilePdfOutlined />,
            text: 'Descargar PDF',
            disabledBySelection: false,
          },
          {
            onClick: onDescargarExcel,
            icon: <FileExcelOutlined />,
            text: 'Descargar Excel',
            disabledBySelection: false,
          },
        ]}
      />
    );
  }

  function TabsAgregarCatalogoSolicitud() {
    return (
      <>
        <Card
          title={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Concepto de Solicitud de Pago`}
          extra={(
            <>
              <Button
                icon={<SaveOutlined />}
                className="ant-btn ant-btn-link"
                onClick={onClickSaveForm}
              >
                Guardar
              </Button>
              <Button
                icon={<ArrowLeftOutlined />}
                className="ant-btn ant-btn-link"
                onClick={onCancel}
              >
                Regresar
              </Button>
            </>
      )}
          bordered={false}
        />
        <Tabs
          activeKey={currentTabKey}
          onChange={mutable ? setCurrentTabKey : onChangeTabKey}
          type="card"
        >
          <TabPane
            tab="Concepto"
            key="concepto"
            forceRender
          >
            <CatalogoSolicitudPagosForm />
          </TabPane>
          <TabPane
            tab="Desglose"
            key="desglose"
            forceRender
          >
            <Desglose />
          </TabPane>
          <TabPane
            tab="Partidas"
            key="partidas"
            forceRender
          >
            <Partidas />
          </TabPane>
        </Tabs>

      </>
    );
  }

  function CatalogoSolicitudPagosForm() {
    return (
      <Row gutter={10}>
        <Col md={24} sm={24} xs={24}>
          <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
            scrollToFirstError
            name="transferencia_interna"
          >
            <Row gutter={10}>
              <Col xs={12} sm={24} md={8}>
                <Form.Item
                  name="concepto_solicitud"
                  label="Concepto de Solicitud"
                  rules={rules.required}
                >
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col xs={12} sm={24} md={8}>
                <Form.Item
                  name="tipo_tercero"
                  label="Tipo de Tercero"
                  rules={rules.required}
                >
                  <Select dataSource={[]} />
                </Form.Item>
              </Col>
              <Col xs={12} sm={24} md={8}>
                <Form.Item
                  name="concepto_general"
                  label="Concepto General"
                  rules={rules.required}
                >
                  <Select dataSource={[]} />
                </Form.Item>
              </Col>
              <Col xs={12} sm={24} md={8}>
                <Form.Item
                  name="finalidad_gasto"
                  label="Finalidad del Gasto"
                  rules={rules.required}
                >
                  <Select dataSource={[]} />
                </Form.Item>
              </Col>
              <Col xs={12} sm={24} md={8}>
                <Form.Item
                  name="beneficiario"
                  label="Beneficiario"
                  rules={rules.required}
                >
                  <Select dataSource={[]} />
                </Form.Item>
              </Col>
              <Col xs={12} sm={24} md={8}>
                <Form.Item
                  name="forma_Pago"
                  label="Forma de Pago"
                  rules={rules.required}
                >
                  <Select dataSource={[]} />
                </Form.Item>
              </Col>
              <Col xs={12} sm={24} md={8}>
                <Form.Item name="banco" label="Banco">
                  <Input allowClear disabled />
                </Form.Item>
              </Col>
              <Col xs={12} sm={24} md={8}>
                <Form.Item name="clabe_interbancaria" label="Clabe Interbancaria">
                  <Input allowClear disabled />
                </Form.Item>
              </Col>
              <Col xs={12} sm={24} md={8}>
                <Form.Item
                  name="otros_datos_pago"
                  label="Otros Datos de Pago"
                  rules={rules.required}
                >
                  <Select dataSource={[]} />
                </Form.Item>
              </Col>
              <Col xs={12} sm={24} md={8}>
                <Form.Item name="numero_convenio_ciec" label="No. de Convenio CIEC">
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col xs={12} sm={24} md={8}>
                <Form.Item name="numero_referencia" label="No. de Referencia">
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col xs={12} sm={24} md={8}>
                <Form.Item name="pago_referenciado" label="Pago Referenciado">
                  <Select dataSource={[]} disabled />
                </Form.Item>
              </Col>
              <Col xs={12} sm={24} md={8}>
                <Form.Item
                  name="afecta_presupuesto"
                  label="Afecta Presupuesto"
                  rules={rules.required}
                >
                  <Select dataSource={[]} />
                </Form.Item>
              </Col>
              <Col xs={12} sm={24} md={8}>
                <Form.Item
                  name="momentos_presupuestales"
                  label="Momentos Presupuestales"
                  rules={rules.required}
                >
                  <Select dataSource={[]} />
                </Form.Item>
              </Col>
              <Col xs={12} sm={24} md={8}>
                <Form.Item
                  name="aplica_cfdi"
                  label="Aplica CFDI"
                  rules={rules.required}
                >
                  <Select dataSource={[]} />
                </Form.Item>
              </Col>
              <Col xs={12} sm={24} md={8}>
                <Form.Item
                  name="tipo_comprobante"
                  label="Tipo de Comprobante"
                >
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col xs={12} sm={24} md={8}>
                <Form.Item
                  name="aplica_desglose"
                  label="Aplica Desglose"
                  rules={rules.required}
                >
                  <Select dataSource={[]} />
                </Form.Item>
              </Col>
              <Col xs={12} sm={24} md={8}>
                <Form.Item
                  name="aplica_contra_recibo"
                  label="Aplica Contra-Recibo"
                  rules={rules.required}
                >
                  <Select dataSource={[]} />
                </Form.Item>
              </Col>
              <Col xs={12} sm={24} md={8}>
                <Form.Item name="estado" label="Estado">
                  <Select dataSource={[]} disabled />
                </Form.Item>
              </Col>
              <Col xs={12} sm={24} md={8}>
                <Form.Item name="observaciones" label="Observaciones">
                  <Input allowClear />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    );
  }

  function Partidas() {
    return !visiblePartida ? (
      <Card className="form-container">
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          scrollToFirstError
          name="conceptos_cargos_bancarios"
        >
          <Row gutter={10}>
            <Col span={24}>
              <Form.Item
                name="COG"
                label="cog"
              >
                <Input.Search allowClear onSearch={onSearchCOG} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col span={24}>
              <Form.Item
                name="area"
                label="Área"
              >
                <Input allowClear disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col span={24}>
              <Form.Item name="fuente_financiamiento" label="Fuente de Financiamiento">
                <Input allowClear disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col span={24}>
              <Form.Item name="programa" label="Programa">
                <Input allowClear disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col span={24}>
              <Form.Item name="proyecto" label="Proyecto">
                <Input allowClear disabled />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Table
          cols={columnsPartidas}
          data={[]}
          rowSelection={rowSelection}
          handleOnRowClick={handleOnRowClick}
          mobileColIndex={0}
          controls={{
            onClickAdd: onClickAddPartida,
            onClickDelete: onClickDeletePartida,
          }}
        />
      </Card>
    ) : (
      <Card className="form-container">
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          scrollToFirstError
          name="conceptos_cargos_bancarios"
        >
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item
                name="clave_presupuestal_search"
                label="Clave Presupuestal"
              >
                <Input.Search allowClear onSearch={onSearchClavePresupuestal} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Table
          cols={columnsClavePresupuestal}
          data={[]}
          handleOnRowClick={handleOnRowClick}
          mobileColIndex={0}
          customActions={[
            {
              onClick: onSearchClavePresupuestalGrid,
              text: 'Buscar',
              icon: <SearchOutlined />,
              disabledBySelection: false,
            },
            {
              onClick: onAceptarClavePresupuestal,
              text: 'Aceptar',
              icon: <CheckCircleOutlined />,
              disabledBySelection: false,
            },
          ]}
        />
      </Card>
    );
  }

  function Desglose() {
    return (
      <>
        <Table
          cols={columnsDesglose}
          data={[]}
          handleOnRowClick={handleOnRowClick}
          rowSelection={rowSelection}
          mobileColIndex={0}
          controls={{
            onClickAdd: onClickAddDesglose,
            onClickEdit: onClickEditDesglose,
            onClickDelete: onClickDeleteDesglose,
          }}
          customActions={[
            {
              onClick: onExportarPDF,
              icon: <FilePdfOutlined />,
              text: 'Exportar a PDF',
              disabledBySelection: false,
            },
            {
              onClick: onExportarExcel,
              icon: <FileExcelOutlined />,
              text: 'Exportar a Excel',
              disabledBySelection: false,
            },
          ]}
        />
        <Modal
          visible={visibleModalDesglose}
          closable={false}
          maskClosable={!loading}
          keyboard={!loading}
          footer={null}
          title={(
            <FormSubmitControls
              label={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Concepto de Cargos Bancarios`}
              onFinish={onFinishDesglose}
              onCancel={onCancelDesglose}
              loading={loading}
            />
    )}
          onCancel={onCancel}
          forceRender
        >
          <Form
            layout="vertical"
            form={form}
            onFinish={onFinishDesglose}
            scrollToFirstError
            name="desglose"
          >
            <Row gutter={10}>
              <Col span={24}>
                <Form.Item
                  name="rubro"
                  label="Rubro"
                  rules={rules.required}
                >
                  <Input allowClear />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col span={24}>
                <Form.Item
                  name="cuenta_contable"
                  label="Cuenta Contable"
                  rules={rules.required}
                >
                  <Select dataSource={[]} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col span={24}>
                <Form.Item
                  name="cargo_abono"
                  label="Cargo/Abono"
                  rules={rules.required}
                >
                  <Input allowClear />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>

      </>
    );
  }

  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible ? <CatalogoSolicitudPagosGrid /> : <TabsAgregarCatalogoSolicitud />}
      </Spin>
    </Row>
  );
}

export default ConceptosSolicitudPago;
