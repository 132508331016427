import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  message,
  Tooltip,
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import API from 'utils/api';
import fetchSome from 'utils/fetchSome';
import makeMessager from 'utils/complexFormsMessages';
import { onError } from 'utils/handlers';
import { SET_TIPOS_VIALIDADES } from 'store/reducers/catalogs';
import Select from '../Select';
import ModalSearch from './ModalSearch';
import NumericInput from '../NumericInput';

export const model = 'direccion';

function Direccion({
  useParentID,
  parentID,
  setLoading,
  callback,
  currentURI: _currentURI,
  setFormAddress,
  addressPropName,
  setCurrentTabKey,
  mutable: _mutable,
  permission,
}) {
  const currentURI = _currentURI.at(-1) !== '/' ? `${_currentURI}/` : _currentURI;
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  const [formDireccion] = Form.useForm();
  const tiposVialidades = useSelector(({ catalogs }) => catalogs.tiposVialidades);
  const collpasedSidebar = useSelector(({ app }) => app.collpasedSidebar);
  const [visibleNoSeCodigo, setVisibleNoSeCodigo] = useState(false);
  const [filteredColonies, setFilteredColonies] = useState([]);
  const [mutable, setMutable] = useState(true);

  const formatValues = (values) => {
    const keys = Object.keys(values);
    const clone = {};
    keys.forEach((prop) => {
      const value = values[prop];
      if (value && value !== 'N/A') {
        clone[prop] = value;
      }
    });
    return clone;
  };

  const getCodigoPostal = async (codigoPostal) => {
    if (!codigoPostal?.d_codigo) {
      const resCP = await API.get(`/catalogos/codigos-postales/${codigoPostal}/`);
      return resCP.data;
    }
    return codigoPostal;
  };

  const getDireccion = async () => {
    try {
      setLoading(true);
      formDireccion.resetFields();
      let formattedValues = {};
      if (parentID) {
        const resParent = await API.get(`${currentURI}${useParentID ? parentID : ''}`);
        if (resParent?.status === 200) {
          const parent = resParent.data;
          const addresData = parent[`${addressPropName}`];
          if (addresData?.id) {
            const resCodigoPostal = await getCodigoPostal(addresData.codigo_postal);
            const resColonies = await API.get('/catalogos/codigos-postales/', {
              params: {
                d_codigo: resCodigoPostal.d_codigo,
              },
            });
            // console.log(addresData);
            formattedValues = formatValues({
              ...addresData,
              estado: resCodigoPostal.municipio.estado.nombre_de_AGEE || undefined,
              municipio: resCodigoPostal.municipio.nom_mun || undefined,
              codigo_postal: resCodigoPostal.d_codigo,
              colonia: resCodigoPostal.id,
            });
            setFilteredColonies(resColonies.data || []);
          }
        }
      }
      setMutable(_mutable && ((!formattedValues.id && permission?.add)
        || (formattedValues.id && permission?.change)));
      setTimeout(() => {
        // eslint-disable-next-line no-use-before-define
        formDireccion.onFinishHandler = onFinishHandler;
        if (setFormAddress) {
          setFormAddress(formDireccion);
        }
      });
      setTimeout(() => {
        formDireccion.setFieldsValue(formattedValues);
      });
      setTimeout(() => {
        setLoading(false);
      });
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const showMsg = makeMessager(setCurrentTabKey, getDireccion);

  /**
    * Metodo para manejar peticiones
   */
  const onFinish = async (_values, handleError = true) => {
    try {
      setLoading(true);
      const values = formDireccion.getFieldsValue();
      const keys = Object.keys(values);
      const validProps = keys.filter((prop) => values[prop]);
      if (validProps.length !== 1) {
        await formDireccion.validateFields();
        if (parentID) {
          const resPatch = await API.patch(`${currentURI}/${useParentID ? parentID : ''}`, {
            [`${addressPropName}`]: {
              ...values,
              codigo_postal: values.colonia,
            },
          });
          if (resPatch?.status === 200) {
            message.info('Dirección agregada correctamente');
            setTimeout(async () => {
              callback(resPatch.data.address, handleError);
              if (handleError) {
                await getDireccion();
              }
            });
          }
        }
      }
      setTimeout(() => {
        setLoading(false);
      });
    } catch (err) {
      if (handleError) {
        onError(err, setLoading);
      }
    }
  };

  const onFinishHandler = async (tabKey = null, _continue = true) => {
    try {
      if (tabKey) {
        showMsg(tabKey);
      } else {
        await onFinish(null, _continue);
      }
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    const fetch = async () => {
      try {
        if (!tiposVialidades?.length) {
          fetchSome('/catalogos/tipos-de-vialidad/', SET_TIPOS_VIALIDADES);
        }
      } catch (err) {
        onError(err, setLoading);
      }
    };
    fetch();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const fetch = async () => {
      await getDireccion();
    };
    fetch();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, [parentID]);

  const getFromPostalCode = (param) => {
    try {
      setLoading(true);
      setTimeout(async () => {
        const res = await API.get('/catalogos/codigos-postales/', {
          params: {
            d_codigo: param,
          },
        });
        if (res?.data?.length) {
          const [firstItem] = res.data;
          if (res.data.length === 1) {
            formDireccion.setFieldsValue({
              colonia: firstItem.id,
              codigo_postal: firstItem.d_codigo,
              estado: firstItem.municipio.estado.nombre_de_AGEE,
              municipio: firstItem.municipio.nom_mun,
            });
            setFilteredColonies([firstItem]);
          } else {
            formDireccion.setFieldsValue({
              pais: 'México',
              codigo_postal: firstItem.d_codigo,
              estado: firstItem.municipio.estado.nombre_de_AGEE,
              municipio: firstItem.municipio.nom_mun,
              colonia: undefined,
            });
            setFilteredColonies(res.data);
          }
        } else {
          message.info('No se encontro información relacionada al código postal brindado');
          formDireccion.setFieldsValue({
            pais: 'México',
            codigo_postal: undefined,
            estado: undefined,
            municipio: undefined,
            colonia: undefined,
          });
          setFilteredColonies([]);
        }
      }, 300);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const rules = {
    required: [
      {
        message: 'El campo es requerido',
        required: true,
      },
    ],
    entre_calle: [
      {
        validator: async (rule, value) => {
          if (!value && !!formDireccion.getFieldValue('tipo_de_vialidad_entre')) {
            throw new Error('El campo es requerido');
          }
        },
      },
    ],
    tipo_de_vialidad_entre: [
      {
        validator: async (rule, value) => {
          if (!value && !!formDireccion.getFieldValue('entre_calle')) {
            throw new Error('El campo es requerido');
          }
        },
      },
    ],
    y_calle: [
      {
        validator: async (rule, value) => {
          if (!value && !!formDireccion.getFieldValue('tipo_de_vialidad_y_calle')) {
            throw new Error('El campo es requerido');
          }
        },
      },
    ],
    tipo_de_vialidad_y_calle: [
      {
        validator: async (rule, value) => {
          if (!value && !!formDireccion.getFieldValue('y_calle')) {
            throw new Error('El campo es requerido');
          }
        },
      },
    ],
  };

  const onFinishSearch = (values) => {
    if (values) {
      formDireccion.setFieldsValue({
        estado: values.municipio.estado.nombre_de_AGEE,
        municipio: values.municipio.nom_mun,
        colonia: values.id,
        codigo_postal: values.d_codigo,
      });
      setFilteredColonies([values]);
    } else {
      message.warn('Seleccione un elemento');
    }
  };

  return (
    <>
      <Col span={24}>
        <Form
          name={addressPropName}
          form={formDireccion}
          layout="vertical"
          initialValues={{ pais: 'México', estados_globales: 1 }}
          onFinish={async () => onFinish()}
          onValuesChange={async (changedValues) => {
            if (changedValues.codigo_postal?.length === 5) {
              getFromPostalCode(changedValues.codigo_postal);
            }
          }}
        >
          <Row gutter={10}>
            <Col xs={24} sm={24} md={16} lg={12}>
              <Form.Item
                label={(
                  <>
                    <span style={{ marginRight: 5 }}>
                      Código Postal
                    </span>
                    {mutable && (
                      <Tooltip
                        title="Ingresar este campo llenara
                            automáticamente estado, municipio y las colonias disponibles"
                      >
                        <QuestionCircleOutlined className="primary-on-hover" />
                      </Tooltip>
                    )}
                  </>
                )}
                style={{ marginBottom: 0 }}
              >
                <Input.Group compact>
                  <div style={{ width: 200 }}>
                    <NumericInput name="codigo_postal" maxLength={6} disabled={!mutable} />
                  </div>
                  <Form.Item>
                    <Button
                      type="link"
                      onClick={() => setVisibleNoSeCodigo(true)}
                      className="primary-on-hover"
                      // disabled={!mutable}
                    >
                      No sé mi código
                    </Button>
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>
            <Col span={24} />
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="pais"
                label="País"
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="estado"
                label="Estado"
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="municipio"
                label="Municipio"
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                label="Colonia"
                name="colonia"
                rules={rules.required}
              >
                <Select dataSource={filteredColonies} labelProp="d_asenta" disabled={!mutable} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="numero_exterior"
                label="Número Exterior"
                rules={rules.required}
              >
                <Input maxLength={20} disabled={!mutable} allowClear />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="numero_interior"
                label="Número Interior"
              >
                <Input maxLength={20} disabled={!mutable} allowClear />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={collpasedSidebar ? 8 : 12}>
              <Form.Item noStyle>
                <Input.Group style={{ display: 'flex' }} className="select-input" compact>
                  <Form.Item
                    name="tipo_de_vialidad"
                    rules={rules.required}
                    label="Tipo"
                  >
                    <Select dataSource={tiposVialidades} disabled={!mutable} />
                  </Form.Item>
                  <Form.Item
                    name="calle_principal"
                    label="Vialidad Principal"
                    rules={rules.required}
                  >
                    <Input maxLength={170} disabled={!mutable} allowClear />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={collpasedSidebar ? 8 : 12}>
              <Form.Item noStyle>
                <Input.Group style={{ display: 'flex' }} className="select-input" compact>
                  <Form.Item
                    name="tipo_de_vialidad_entre"
                    label="Tipo"
                    rules={rules.tipo_de_vialidad_entre}
                  >
                    <Select dataSource={tiposVialidades} disabled={!mutable} />
                  </Form.Item>
                  <Form.Item
                    name="entre_calle"
                    rules={rules.entre_calle}
                    label="Entre Vialidad"
                    className="affix-wrapper-without-padding"
                  >
                    <Input maxLength={170} disabled={!mutable} allowClear />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={collpasedSidebar ? 8 : 12}>
              <Form.Item noStyle>
                <Input.Group style={{ display: 'flex' }} className="select-input" compact>
                  <Form.Item
                    name="tipo_de_vialidad_y_calle"
                    label="Tipo"
                    rules={rules.tipo_de_vialidad_y_calle}
                  >
                    <Select dataSource={tiposVialidades} disabled={!mutable} />
                  </Form.Item>
                  <Form.Item
                    name="y_calle"
                    rules={rules.y_calle}
                    label="Y Vialidad"
                  >
                    <Input maxLength={170} disabled={!mutable} allowClear />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="descripcion_de_la_ubicacion"
                label="Descripción de la Ubicación"
              >
                <Input.TextArea
                  autoSize={{ minRows: 2, maxRows: 3 }}
                  maxLength={250}
                  disabled={!mutable}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="estados_globales"
                label="Estado Global"
                rules={rules.required}
                hasFeedback
              >
                <Select
                  disabled
                  dataSource={estadosGlobales}
                />
              </Form.Item>
            </Col>
            <Form.Item hidden>
              <Button htmlType="submit" />
            </Form.Item>
          </Row>
        </Form>
      </Col>
      <ModalSearch
        setLoading={setLoading}
        callback={onFinishSearch}
        setVisible={setVisibleNoSeCodigo}
        visible={visibleNoSeCodigo}
      />
    </>
  );
}

Direccion.propTypes = {
  parentID: PropTypes.number,
  callback: PropTypes.func,
  currentURI: PropTypes.string.isRequired,
  addressPropName: PropTypes.string,
  setLoading: PropTypes.func.isRequired,
  setFormAddress: PropTypes.func.isRequired,
  setCurrentTabKey: PropTypes.func.isRequired,
  mutable: PropTypes.bool,
  useParentID: PropTypes.bool,
  permission: PropTypes.shape({
    add: PropTypes.bool.isRequired,
    authorize: PropTypes.bool.isRequired,
    cancel: PropTypes.bool.isRequired,
    change: PropTypes.bool.isRequired,
    delete: PropTypes.bool.isRequired,
    export: PropTypes.bool.isRequired,
    import: PropTypes.bool.isRequired,
    sync: PropTypes.bool.isRequired,
    view: PropTypes.bool.isRequired,
  }).isRequired,
};

Direccion.defaultProps = {
  parentID: null,
  callback: () => { },
  addressPropName: 'direccion',
  mutable: true,
  useParentID: true,
};

export default Direccion;
