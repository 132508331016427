/* eslint-disable no-param-reassign */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Button,
  Grid,
  message,
} from 'antd';
import {
  ArrowLeftOutlined,
  SaveOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { onError } from 'utils/handlers';
import API from 'utils/api';
import Moment from 'moment';
import { DATE_FORMAT } from 'utils/formatValues';
import ModalChangeStatus from './ModalChangeStatus';

function FormSubmitControls({
  allowAuthorize,
  allowCancel,
  allowSaveAndContinue,
  baseURI,
  callback,
  label,
  loading,
  mutable,
  observationPropName,
  onCancel,
  onCancelText,
  onFinish,
  onFinishText,
  onFinishIcon,
  requireObservation,
  selectedRowKeys,
  useSuffix,
  validateAuthorizable,
  permission,
  allowDestroyModal,
  requireAutorizationDate,
  requireCancelDate,
  authorizationPropName,
  cancelPropName,
}) {
  const screens = Grid.useBreakpoint();
  const [visibleAuthorize, setVisibleAuthorize] = useState(false);
  const [visibleCancel, setVisibleCancel] = useState(false);
  const currentDate = new Moment().format(DATE_FORMAT);

  // normalize/validate params
  allowAuthorize = allowAuthorize && mutable && !!selectedRowKeys.length
    && (permission?.authorize || !permission);
  allowCancel = allowCancel && mutable && !!selectedRowKeys.length
    && (permission?.cancel || !permission);
  allowSaveAndContinue = onFinish && allowSaveAndContinue && mutable;

  const onCancelStatus = async (values) => {
    try {
      const [key] = selectedRowKeys;
      values.estados_globales = 5;
      if (requireCancelDate) {
        // eslint-disable-next-line no-param-reassign
        values[cancelPropName] = currentDate;
      }
      const res = await API.patch(`${baseURI}${key}/`, values);
      if (res?.status === 200) {
        setVisibleCancel(false);
        callback(5, values[observationPropName]);
      }
    } catch (err) {
      onError(err);
    }
  };

  const onAuthorizeStatus = async () => {
    try {
      const data = validateAuthorizable ? await onFinish(true) : true;
      const validated = !validateAuthorizable || validateAuthorizable(data);
      if (allowDestroyModal && !validated) {
        setVisibleAuthorize(false);
      }
      if (validated) {
        const [key] = selectedRowKeys;
        const URI = `${baseURI}/${key}/${useSuffix ? 'estado/' : ''}`.replace(/\/\//, '/');
        const response = await API[useSuffix ? 'post' : 'patch'](URI, {
          estados_globales: 4,
          [authorizationPropName]: requireAutorizationDate ? currentDate : null,
        });
        if (response?.status === 200) {
          if (useSuffix && response.data.detail) {
            message.info(response.data.detail);
          }
          setVisibleAuthorize(false);
          callback(4, response.data);
        }
      }
    } catch (err) {
      onError(err);
    }
  };

  const controls = (
    <Row className="form-submit-controls">
      {onCancel && (
        <Button
          type="link"
          onClick={onCancel}
          disabled={loading}
        >
          <ArrowLeftOutlined />
          {screens.md && onCancelText}
        </Button>
      )}
      {(allowCancel && mutable && !!selectedRowKeys.length && onFinish) && (
        <Button
          type="link"
          onClick={() => setVisibleCancel(true)}
          disabled={loading}
          className="no-color danger"
        >
          <CloseCircleOutlined />
          {screens.md && 'Cancelar'}
        </Button>
      )}
      {(allowAuthorize && mutable && !!selectedRowKeys.length && onFinish) && (
        <Button
          type="link"
          onClick={() => setVisibleAuthorize(true)}
          disabled={loading}
          className="no-color success"
        >
          <CheckCircleOutlined />
          {screens.md && 'Autorizar'}
        </Button>
      )}
      {(onFinish && mutable) && (
        <Button
          type="link"
          onClick={() => onFinish(false)}
          disabled={loading}
        >
          {onFinishIcon}
          {screens.md && onFinishText}
        </Button>
      )}
      {(onFinish && allowSaveAndContinue && mutable) && (
        <Button
          type="link"
          onClick={() => onFinish(true)}
          disabled={loading}
        >
          <EditOutlined />
          {screens.md && `${onFinishText} y continuar`}
        </Button>
      )}
    </Row>
  );

  if (label) {
    return (
      <>
        {label && (
          <Row justify="space-between" align="middle">
            {label}
            {controls}
          </Row>
        )}
        {((allowAuthorize || allowCancel) && mutable && !!selectedRowKeys.length) && (
          <ModalChangeStatus
            onSubmit={visibleAuthorize ? onAuthorizeStatus : onCancelStatus}
            onCancel={() => {
              setVisibleAuthorize(false);
              setVisibleCancel(false);
            }}
            visible={visibleAuthorize || visibleCancel}
            onCancelText={onCancelText}
            authorize={visibleAuthorize}
            requireObservation={requireObservation}
            observationPropName={observationPropName}
          />
        )}
      </>
    );
  }

  return (
    <>
      {controls}
      {((allowAuthorize || allowCancel) && mutable && !!selectedRowKeys.length) && (
        <ModalChangeStatus
          onSubmit={visibleAuthorize ? onAuthorizeStatus : onCancelStatus}
          onCancel={() => {
            setVisibleAuthorize(false);
            setVisibleCancel(false);
          }}
          visible={visibleAuthorize || visibleCancel}
          onCancelText={onCancelText}
          authorize={visibleAuthorize}
          requireObservation={requireObservation}
          observationPropName={observationPropName}
          destroyOnClose
        />
      )}
    </>
  );
}

FormSubmitControls.propTypes = {
  allowAuthorize: PropTypes.bool,
  allowCancel: PropTypes.bool,
  allowSaveAndContinue: PropTypes.bool,
  baseURI: PropTypes.string,
  callback: PropTypes.func,
  label: PropTypes.string,
  loading: PropTypes.bool,
  mutable: PropTypes.bool,
  observationPropName: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onCancelText: PropTypes.string,
  onFinish: PropTypes.func,
  onFinishIcon: PropTypes.node,
  onFinishText: PropTypes.string,
  requireObservation: PropTypes.bool,
  selectedRowKeys: PropTypes.arrayOf(PropTypes.number),
  useSuffix: PropTypes.bool,
  validateAuthorizable: PropTypes.func,
  permission: PropTypes.shape({
    add: PropTypes.bool.isRequired,
    authorize: PropTypes.bool.isRequired,
    cancel: PropTypes.bool.isRequired,
    change: PropTypes.bool.isRequired,
    delete: PropTypes.bool.isRequired,
    export: PropTypes.bool.isRequired,
    import: PropTypes.bool.isRequired,
    sync: PropTypes.bool.isRequired,
    view: PropTypes.bool.isRequired,
  }),
  allowDestroyModal: PropTypes.bool,
  requireAutorizationDate: PropTypes.bool,
  requireCancelDate: PropTypes.bool,
  authorizationPropName: PropTypes.string,
  cancelPropName: PropTypes.string,
};

FormSubmitControls.defaultProps = {
  allowAuthorize: false,
  allowCancel: false,
  allowSaveAndContinue: false,
  baseURI: null,
  callback: null,
  label: null,
  loading: false,
  mutable: true,
  observationPropName: null,
  onCancelText: 'Regresar',
  onFinish: null,
  onFinishText: 'Guardar',
  onFinishIcon: <SaveOutlined />,
  requireObservation: false,
  selectedRowKeys: [],
  useSuffix: false,
  validateAuthorizable: null,
  permission: null,
  allowDestroyModal: false,
  requireAutorizationDate: false,
  requireCancelDate: false,
  authorizationPropName: 'fecha_de_autorizacion',
  cancelPropName: 'fecha_de_cancelacion',
};

export default FormSubmitControls;
