import React from 'react';
import SingleForm from 'components/SingleForm';

export const permissionModalidadEjecucion = {
  permissionModel: 'modalidaddeejecucion',
};

function ModalidadDeEjecucion() {
  return (
    <SingleForm
      baseURI="obrapublica/modalidad-de-ejecucion/"
      title="Modalidad De Ejecución"
    />
  );
}

export default ModalidadDeEjecucion;
