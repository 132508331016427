import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Spin,
  Card,
  message,
} from 'antd';
import {
  EyeOutlined,
} from '@ant-design/icons';
import { onError } from 'utils/handlers';
import Table from 'components/Table';
import FormSubmitControls from 'components/FormSubmitControls';
import ModalDelete from 'components/ModalDelete';
import Select from 'components/Select';
import createClave from 'utils/keyGenerator';
import { isMutable, isAuthorized } from 'utils/estadosGlobales';
import Uploader from 'components/Uploader';
import Previewer from 'components/Previewer';
import {
  deleteAnexoRequisicion,
  getAnexosRequisicion,
  patchAnexoRequisicion,
  postAnexoRequisicion,
} from 'api/adquisiciones/requisiciones';

export const model = 'documentoanexo';

function Anexos({
  requisicionId,
  disabled,
  permission,
}) {
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);
  const [mutable, setMutable] = useState(true);
  const [currentFile, setCurrentFile] = useState();
  const [visiblePreview, setVisiblePreview] = useState(false);

  const tipoOptions = [
    {
      id: 'carta_tecnica',
      descripcion: 'Carta Técnica',
    },
    {
      id: 'carta_descriptiva',
      descripcion: 'Carta Descriptiva',
    },
    {
      id: 'otro_documento',
      descripcion: 'Otro Documento',
    },
  ];

  const fetchData = async () => {
    setLoading(true);
    const _data = await getAnexosRequisicion(requisicionId);
    setData(_data);
    setLoading(false);
  };

  useEffect(() => {
    let mounted = true;
    const _fetchData = async () => {
      setLoading(true);
      const _data = await getAnexosRequisicion(requisicionId);
      if (mounted) {
        setData(_data);
        setLoading(false);
      }
    };
    _fetchData();

    return () => { mounted = false; };
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    setSelectedRowKeys([]);
    setMutable(true);
    setCurrentFile();
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      const [key] = selectedRowKeys;
      const saved = await (key
        ? patchAnexoRequisicion(requisicionId, key, values)
        : postAnexoRequisicion(requisicionId, values));
      if (saved) {
        onCancel();
        await fetchData();
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const deleteItem = async () => {
    setLoading(true);
    const [key] = selectedRowKeys;
    const deleted = await deleteAnexoRequisicion(requisicionId, key);
    if (deleted) {
      setVisibleAlert(false);
      onCancel();
      await fetchData();
    }
    setLoading(false);
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    setMutable(disabled ? !disabled : isMutable(record));
    setTimeout(() => form.setFieldsValue(record));
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickAdd = () => {
    onCancel();
    createClave(data, form);
    setVisible(true);
  };

  const onClickEdit = () => setVisible(true);

  const onClickDelete = () => {
    const [key] = selectedRowKeys;
    const match = data.find((e) => e.id === key);
    if (isMutable(match)) {
      setVisibleAlert(true);
    } else if (isAuthorized(match)) {
      message.warn('No se puede eliminar un registro autorizado');
    } else {
      message.warn('No se puede eliminar un registro cancelado');
    }
  };

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
  };

  const columns = [
    {
      titleText: 'Descripción',
      dataIndex: 'descripcion',
      width: 200,
    },
    {
      titleText: 'Tipo',
      dataIndex: 'tipo',
      width: 150,
      render: (e) => tipoOptions.find((i) => i.id === e)?.descripcion,
    },
    {
      titleText: 'Estado',
      dataIndex: 'estados_globales',
      width: 100,
      render: (e) => estadosGlobales.find((i) => i.id === e)?.descripcion,
    },
  ];

  const controls = () => {
    if (!disabled) {
      return {
        onClickAdd,
        onClickEdit,
        onClickDelete,
      };
    }
    return {
      onClickExpand: () => setVisible(true),
    };
  };

  const onClickPreview = () => {
    const clone = [...data];
    const [key] = selectedRowKeys;
    const match = clone.find((e) => e.id === key);
    setCurrentFile(match);
    setVisiblePreview(true);
  };

  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible ? (
          <Table
            cols={columns}
            data={data}
            rowSelection={rowSelection}
            handleOnRowClick={handleOnRowClick}
            controls={controls()}
            mobileColIndex={0}
            customActions={[
              {
                onClick: onClickPreview,
                icon: <EyeOutlined />,
                text: 'Ver Foto',
                disabled: selectedRowKeys.length,
              },
            ]}
            permission={permission}
          />
        ) : (
          <Card
            className="form-container"
            title={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Anexo`}
            extra={(
              <FormSubmitControls
                onFinish={mutable ? onFinish : null}
                onCancel={onCancel}
                loading={loading}
                permission={permission}
              />
            )}
            bordered={false}
          >
            <Form
              layout="vertical"
              form={form}
              onFinish={onFinish}
              scrollToFirstError
              initialValues={{
                estados_globales: 1,
              }}
            >
              <Row gutter={10}>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="descripcion"
                    rules={rules.required}
                    label="Descripción"
                    hasFeedback
                  >
                    <Input
                      allowClear
                      disabled={disabled}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="tipo"
                    rules={rules.required}
                    label="Tipo"
                    hasFeedback
                  >
                    <Select
                      dataSource={tipoOptions}
                      disabled={disabled}
                    />
                  </Form.Item>
                </Col>
                <Uploader
                  title="Documento"
                  limitMB={50}
                  file={form.getFieldValue('documento')}
                  formItemName="documento"
                  allowedExtensions={['pdf']}
                  rules={rules.required}
                  disabled={disabled}
                />
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    name="estados_globales"
                    rules={rules.required}
                    label="Estado"
                    hasFeedback
                  >
                    <Select
                      dataSource={estadosGlobales}
                      disabled={!selectedRowKeys.length || disabled}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item hidden>
                <Button htmlType="submit" />
              </Form.Item>
            </Form>
          </Card>
        )}
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => {
            setVisibleAlert(false);
            setSelectedRowKeys([]);
          }}
          visible={visibleAlert}
          content={`el Anexo ${form.getFieldValue('descripcion')}`}
          loading={loading}
        />
        <Previewer
          visible={visiblePreview}
          setVisible={setVisiblePreview}
          iFrameUrl={currentFile?.documento}
        />
      </Spin>
    </Row>
  );
}

Anexos.propTypes = {
  requisicionId: PropTypes.number,
  disabled: PropTypes.bool.isRequired,
  permission: PropTypes.shape({}).isRequired,
};

Anexos.defaultProps = {
  requisicionId: 0,
};

export default Anexos;
