import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Button,
  Grid,
} from 'antd';

function FormSubmitCustomControls({ label, controls, loading }) {
  const screens = Grid.useBreakpoint();

  function Controls() {
    return (
      <Row>
        {controls.map(({
          onClick, icon, text, ...rest
        }, idx) => (
          <Button
          // eslint-disable-next-line react/jsx-props-no-spreading
            {...rest}
            type="link"
            onClick={onClick}
            key={rest.key || idx}
            disabled={loading}
          >
            {icon}
            {screens.md && text}
          </Button>
        ))}
      </Row>
    );
  }

  if (label) {
    return (
      <Row align="center" justify="space-between">
        {label}
        <Controls />
      </Row>
    );
  }

  return (<Controls />);
}

FormSubmitCustomControls.propTypes = {
  controls: PropTypes.arrayOf(PropTypes.exact({
    onClick: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
    icon: PropTypes.object.isRequired,
  })).isRequired,
  loading: PropTypes.bool,
  label: PropTypes.string,
};

FormSubmitCustomControls.defaultProps = {
  loading: false,
  label: null,
};

export default FormSubmitCustomControls;
