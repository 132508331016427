import React, { useState } from 'react';
import {
  Layout,
  Typography,
  Col,
  Menu,
  Row,
  Drawer,
  Dropdown,
  Button,
  Carousel,
} from 'antd';
import { connect } from 'react-redux';
import {
  MenuOutlined,
  DownOutlined,
  UserOutlined,
} from '@ant-design/icons';

import './index.scss';
import { Link } from 'react-router-dom';
import CardLanding from 'components/CardLanding';
import logo from 'assets/logo.png';
import Elipse from 'assets/elipse1.png';
import Elipse2 from 'assets/Ellipse2.png';
import Ilustracion1 from 'assets/ilustracion1.png';
import Ilustracion2 from 'assets/imgFooter.png';
// import { withTheme } from 'styled-components';

const {
  Header,
  Content,
} = Layout;
const { Text } = Typography;

const menu = (
  <Menu>
    <Menu.Item>
      <Link to="/inicio-de-sesion">
        Inicio de sesión
      </Link>
    </Menu.Item>
    <Menu.Item>
      <Link to="/#">
        Registro
      </Link>
    </Menu.Item>
  </Menu>
);

function Landing() {
  const [collapsed, setCollapsed] = useState(false);

  function CustomHeader(mode) {
    return (
      <Menu
        mode={mode}
        defaultSelectedKeys={['0']}
        onClick={({ key }) => {
          if (key) {
            const section = document.getElementById(key);
            section.scrollIntoView({ block: 'center', behavior: 'smooth' });
            setTimeout(() => {
              // window.scrollBy(0, -64);
            });
            setCollapsed(false);
          }
        }}
        style={{
          backgroundColor: 'transparent',
        }}
      >
        <Menu.Item key="inicio">
          Inicio
        </Menu.Item>
        <Menu.Item key="expediente-digital">
          Expediente Digital
        </Menu.Item>
        <Menu.Item key="seguridad">
          Seguridad
        </Menu.Item>
        <Menu.Item key="interoperalidad">
          Interoperabilidad
        </Menu.Item>
      </Menu>
    );
  }

  const styles = {
    Ovalo: {
      position: 'absolute',
      width: '90vw',
      left: '-30vw',
      top: '-20vh',
    },
    ovalo2: {
      position: 'absolute',
      width: '61vw',
      right: 0,
      top: 0,
    },
    ilustracion1: {
      width: '100%',

    },
    ilustracion2: {
      position: 'absolute',
      width: '100vw',
    },
    contentStyle: {
      height: '27vw',
    },
  };

  return (
    <Layout className="landing">
      <Header
        id="nav"
        className="no-shadow"
      >
        <Row align="middle">
          <Col xs={2} sm={2} md={0} className="toggleIcons">
            <MenuOutlined className="trigger" onClick={() => setCollapsed(!collapsed)} />
          </Col>
          <Col className="logo">
            <img src={logo} alt="logo" style={{ height: 65, paddingLeft: 50 }} />
          </Col>
          <Col xs={0} sm={0} md={12}>
            <Row>
              {CustomHeader('horizontal')}
            </Row>
          </Col>

          <Dropdown overlay={menu}>
            {/* eslint-disable-next-line */}
            <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()} style={{ color: 'white' }} >
              <UserOutlined className="user-icon-color" />
              {' '}
              SAC
              {' '}
              <DownOutlined className="user-icon-color" />
            </a>
          </Dropdown>
        </Row>
      </Header>

      <Content style={{ width: '100vw', overflow: 'hidden !important' }}>
        <Row
          id="inicio"
          className="contenedor-seccion"
          style={{
            height: 'calc(100vh + 64px)',
            width: '100vw',
            overflow: 'hidden !important',
          }}
        >

          <img src={Elipse} alt="elipse" className="image" style={styles.Ovalo} />
          <img src={Elipse2} alt="elipse" className="image" style={styles.ovalo2} />

          <Col
            xs={24}
            sm={24}
            md={12}
            className="contenedorTitulo"
          >
            <Col>
              <Text className="no-bold header-text">
                Sistema de Armonización Contable, Administra e Integra
                todas las operaciones
                <strong>
                  {' '}
                  financieras y presupuestales.
                </strong>
              </Text>
            </Col>
            <br />

            <Button shape="round" className="buttonLogin">
              REGISTRARSE
            </Button>
          </Col>
        </Row>

        <Row id="expediente-digital" className="contenedor-seccion">
          <Row style={{ width: '100%' }}>
            <Text className="TituloPortafolio">
              GRP
            </Text>
          </Row>
          <br />
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            overflowX: 'scroll',
            height: 450,
            padding: 20,
          }}
          >
            <CardLanding
              nombre="SIR"
              descripcion="Sistema integral de recaudación"
              color="linear-gradient(180deg, #12322B 0%, #1A473D 100%)"
              url="https://sir.migob.mx/"
            />
            <CardLanding
              nombre="MI CUENTAMX"
              descripcion="Esta plataforma utiliza protocolos de
              seguridad como OpenID Connect el cual es un protocolo
              de autorización digital"
              color="linear-gradient(180deg, #9D2449 0%, #D83265 100%)"
              url="https://micuenta.mx/"
            />
            <CardLanding
              nombre="SIPAE"
              descripcion="Sistema Integral de Recaudación es una solución integral
              para la administración de ingresos municipales."
              color="linear-gradient(180deg, #9D2449 0%, #D83265 100%)"
              url="https://sipae.migob.mx/"
            />
            <CardLanding
              nombre="SOLICITUDES"
              descripcion="Sistema Integral de Recaudación es una solución integral
              para la administración de ingresos municipales."
              color="linear-gradient(180deg, #9D2449 0%, #D83265 100%)"
              url="https://solicitudes.migob.mx/"
            />
            <CardLanding
              nombre="CATASTRO"
              descripcion="Sistema Integral de Recaudación es una solución integral
              para la administración de ingresos municipales."
              color="linear-gradient(180deg, #9D2449 0%, #D83265 100%)"
              url="https://catastro.migob.mx/"
            />
            <CardLanding
              nombre="OFICIO"
              descripcion="Sistema Integral de Recaudación es una solución integral
              para la administración de ingresos municipales."
              color="linear-gradient(180deg, #9D2449 0%, #D83265 100%)"
              url="https://oficios.migob.mx/"
            />
            <CardLanding
              nombre="TRAMITES"
              descripcion="Sistema Integral de Recaudación es una solución integral
              para la administración de ingresos municipales."
              color="linear-gradient(180deg, #9D2449 0%, #D83265 100%)"
              url="https://tramites.migob.mx/"
            />
          </div>
        </Row>

        <Row style={{ marginTop: '15vw' }}>
          <Col
            xs={24}
            sm={24}
            md={12}
          >
            <img src={Ilustracion1} alt="iluistracion1" className="image" style={styles.ilustracion1} />
          </Col>

          <Col
            xs={24}
            sm={24}
            md={12}
            style={{ padding: '30px 60px' }}
          >
            <Carousel className="carta-descripciones">
              <div>
                <Text className="titulos-tarjetas">
                  Controla tu información
                </Text>
                <p className="texto-tarjetas">
                  La plataforma de SAC te permite registrar tu información personal,
                  como nombre, domicilio, y datos de identificación que recurrentemente
                  son solicitados para tramites de gobierno, asi mismo puedes cargar
                  documentos de requisitos en tu portafolio digital como comprobantes de
                  domicilio los cualestu decides cuando compartirlos.
                </p>
              </div>
              <div>
                <Text className="titulos-tarjetas">
                  Ahorra Tiempo y dinero
                </Text>
                <p className="texto-tarjetas">
                  Mediante el uso de esta plataforma y la plataforma de MiGobMX
                  te permitirá tener acceso a información relevante de los Gobiernos
                  Municipales, así como la posibilidad de realizar, pagos y tramites
                  completamente de manera electrónica. Sin salir de casa, sin filas
                  y sin perdida de tiempo.
                </p>
              </div>
              <div>
                <Text className="titulos-tarjetas">
                  Seguridad
                </Text>
                <p className="texto-tarjetas">
                  Esta plataforma utiliza protocolos de seguridad como
                  OpenID Connect el cual es un protocolo de autorización
                  digital, el cual permite identificar en todo momento la
                  identidad del usuario lo cual permite tener un control de
                  toda la información personal que este en SAC.
                </p>
              </div>
              <div>
                <Text className="titulos-tarjetas">
                  Interoperabilidad
                </Text>
                <p className="texto-tarjetas">
                  La plataforma de SAC cuenta con componentes que permitirán
                  interoperar con otras dependencias gubernamentales para
                  compartir información de forma segura y controlada
                  por el propietario de esta.
                </p>
              </div>
            </Carousel>
            <Col />
          </Col>
        </Row>

        <Row style={{ marginTop: '20vw' }}>
          <Row>
            <img src={Ilustracion2} alt="iluistracion2" className="image2" style={styles.ilustracion2} />
          </Row>
          <Col style={{ marginTop: '20vw', paddingLeft: 26 }}>
            <Text style={{
              fontFamily: 'Segoe UI !important',
              fontStyle: 'normal',
              fontWeight: 'bold',
              fontSize: '24px',
              lineHeight: '32px',

              letterSpacing: '-0.02em',
              color: '#FFFFFF',

            }}
            >
              Descarga la APP de SAC para Android o IOS

            </Text>
            <br />
            <p
              className="no-bold footer-text"
            >
              para que puedas tener los servicios de gobierno en la palma de tu mano.
              SAC es una plataforma de Identidad Digital Ciudadana que permite el acceso a servicios
              electrónicos de Gobierno en un modelo de autogestión, teniendo siempre el control de
              tu información.
            </p>
          </Col>
        </Row>

      </Content>

      <Drawer
        title=""
        placement="left"
        visible={collapsed}
        onClose={() => setCollapsed(false)}
      >
        <Col span={24}>
          <Row>
            {CustomHeader('inline')}
          </Row>
        </Col>
      </Drawer>

    </Layout>
  );
}

const mapStateToProps = (state) => state;

export default connect(mapStateToProps)(Landing);
