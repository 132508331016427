import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Spin,
  Form,
} from 'antd';
import API from 'utils/api';
import { onError } from 'utils/handlers';
import Select from 'components/Select';

// eslint-disable-next-line react/prop-types
function Identificacion({ form }) {
  const [loading, setLoading] = useState(false);
  const [estadoCampo, setEstadoCampo] = useState([]);

  const fetchAll = async () => {
    try {
      setLoading(true);
      const resEstadoCampo = await API.get('/controlpatrimonial/estados-del-campo/');
      setEstadoCampo(resEstadoCampo.data);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchAll();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        <Form
          name="identificacion"
          form={form}
          layout="vertical"
          scrollToFirstError
        >
          <Row gutter={10}>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="observaciones_identififcacion"
                label="Observaciones Identificación"
              >
                <Select
                  dataSource={estadoCampo}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>

    </Row>
  );
}

export default Identificacion;
