import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  Col,
  Form,
  Modal,
  Row,
  Spin,
  Typography,
} from 'antd';

import {
  SET_DOCUMENTOS_DE_POLIZA,
} from 'store/reducers/catalogs';

import Table from 'components/Table';
import ModalDelete from 'components/ModalDelete';
import FormSubmitControls from 'components/FormSubmitControls';
import Select from 'components/Select';
import { onError, onSuccess } from 'utils/handlers';
import API from 'utils/api';
import { isMutable } from 'utils/estadosGlobales';
import fetchSome from 'utils/fetchSome';
import Rutas from 'utils/rutas-endpoints';

export const permissionFoliadorDocumento = {
  permissionModel: 'foliodedocumentooperativo',
};

const { Text } = Typography;

const baseURI = '/configuraciones/folios-de-documentos-operativos/';

// eslint-disable-next-line react/prop-types
function FolioDeDocumentosOperativos({ permission }) {
  const { estadosGlobales, documentosDePoliza } = useSelector(({ catalogs }) => catalogs);
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mutable, setMutable] = useState(true);

  const [foliadores, setFoliadores] = useState([]);
  const [documentos, setDocumentos] = useState([]);

  const [dataCatalogoDocumentosOperativos, setDataCatalogoDocumentosOperativos] = useState([]);

  const tipoDocumento = [{ id: 1, concepto: 'Documento Operativo' }, { id: 2, concepto: 'Póliza' }];
  const [tipoDocumentoSelected, setTipoDocumentoSelected] = useState(0);

  const consultarCatalogoDocumentosOperativos = async () => {
    setDocumentos(dataCatalogoDocumentosOperativos);
  };

  const consultarCatalogoPolizas = () => {
    setDocumentos(documentosDePoliza);
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await API.get(baseURI);
      response.data.map((item) => {
        // eslint-disable-next-line no-param-reassign
        item.documento = item.documento_operativo ? (`operativo-${item.documento_operativo}`) : (`poliza-${item.documento}`);
        return item;
      });
      setData(response.data);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const fetchAll = async () => {
    try {
      setLoading(true);

      const resFoliadores = await API.get('/configuraciones/configuraciones-de-folios/');
      setFoliadores(resFoliadores.data || []);

      const consApi = await API.get(Rutas.documentosOperativos);
      const resApi = await consApi.data;
      setDataCatalogoDocumentosOperativos(resApi);

      if (!documentosDePoliza?.length) {
        await fetchSome('configuraciones/documentos-de-polizas/', SET_DOCUMENTOS_DE_POLIZA);
      }

      await fetchData();
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchAll();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setVisible(false);
    setSelectedRowKeys([]);
    form.resetFields();
    setMutable(true);
  };

  const onHandleChangeDoc = (e) => {
    setTipoDocumentoSelected(e);
    if (e === 1) {
      consultarCatalogoDocumentosOperativos();
    }

    if (e === 2) {
      consultarCatalogoPolizas();
    }
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();

      if (!selectedRowKeys.length) {
        const response = await API.post(baseURI, values);
        if (response?.status === 201) {
          onSuccess(response, 'Agregado correctamente');
          onCancel();
          await fetchData();
        }
      } else {
        const [key] = selectedRowKeys;
        const response = await API.put(`${baseURI}${key}/`, values);
        if (response?.status === 200) {
          onSuccess(response, 'Actualizado correctamente');
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
  };

  const columns = [
    {
      titleText: 'Configuración de Folio',
      dataIndex: 'configuracion',
      key: 'configuracion',
      width: 200,
      render: (val) => foliadores.find((t) => t.id === val)?.nombre,
    },
    {
      titleText: 'Documento',
      dataIndex: 'documento',
      key: 'documento',
      width: 200,
      render: (val) => {
        const valArr = val.split('-');
        if (valArr[0] === 'operativo') {
          return dataCatalogoDocumentosOperativos.find((t) => t.id === +valArr[1])?.descripcion;
        }
        return documentosDePoliza.find((t) => t.id === +valArr[1])?.nombre;
      },
    },
    {
      titleText: 'Estado',
      dataIndex: 'estados_globales',
      key: 'estados_globales',
      width: 200,
      render: (val) => estadosGlobales.find((t) => t.id === val)?.descripcion,
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    const recordAux = { ...record };
    const recordArr = recordAux.documento.split('-');
    // eslint-disable-next-line prefer-destructuring, no-param-reassign
    recordAux.documento = +recordArr[1];
    form.setFieldsValue({ ...recordAux });
    form.setFieldsValue({
      tipo_documento: record.documento_operativo ? 1 : 2,
    });
    setMutable(isMutable(record));
  };

  const onClickAdd = () => {
    onCancel();
    consultarCatalogoDocumentosOperativos();
    consultarCatalogoPolizas();
    setVisible(true);
  };

  const onClickEdit = () => {
    onHandleChangeDoc(form.getFieldValue('tipo_documento'));
    setVisible(true);
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`${baseURI}${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          setVisibleAlert(false);
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  return (
    <Row>
      <Spin tip="Cargando..." spinning={loading}>
        <Col span={24}>
          <Table
            mobileCols={[columns[0]]}
            cols={columns}
            permission={permission}
            data={data}
            rowSelection={rowSelection}
            handleOnRowClick={handleOnRowClick}
            controls={{
              onClickAdd,
              onClickEdit,
              onClickDelete,
            }}
            allowExport={false}
            rowKey="key"
          />
          <Modal
            visible={visible}
            title={(
              <FormSubmitControls
                label={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Folio`}
                onFinish={onFinish}
                onCancel={onCancel}
              />
            )}
            onCancel={onCancel}
            footer={null}
            closable={false}
            forceRender
          >
            <Form
              layout="vertical"
              form={form}
              onFinish={onFinish}
              scrollToFirstError
              initialValues={{ estados_globales: 1 }}
            >
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="Configuración de Folio"
                    rules={rules.required}
                    name="configuracion"
                  >
                    <Select
                      dataSource={foliadores}
                      disabled={!mutable}
                      labelProp="nombre"
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="Tipo de Documento"
                    rules={rules.required}
                    name="tipo_documento"
                    hasFeedback
                  >
                    <Select
                      dataSource={tipoDocumento}
                      disabled={!mutable}
                      onChange={(e) => onHandleChangeDoc(e)}
                      render={(e) => `${e?.concepto}`}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="Documento"
                    rules={rules.required}
                    name={tipoDocumentoSelected === 1 ? 'documento_operativo' : 'documento'}
                    hasFeedback
                  >
                    <Select
                      dataSource={documentos}
                      disabled={!mutable}
                      render={(e) => `${e?.nombre ? e.nombre : e.descripcion}`}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="Estado"
                    rules={rules.required}
                    name="estados_globales"
                  >
                    <Select
                      dataSource={estadosGlobales}
                      disabled={!selectedRowKeys.length || !mutable}
                    />
                  </Form.Item>
                </Col>
                <Form.Item hidden>
                  <Button htmlType="submit" />
                </Form.Item>
              </Row>
            </Form>
          </Modal>
        </Col>
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => setVisibleAlert(false)}
          visible={visibleAlert}
          content={(
            <Text style={{ fontSize: 16 }}>
              ¿Desea eliminar el folio seleccionado?
            </Text>
          )}
        />
      </Spin>
    </Row>
  );
}

export default FolioDeDocumentosOperativos;
