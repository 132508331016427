import React, { useState } from 'react';
import Table from 'components/Table';
import ModalDelete from 'components/ModalDelete';
import FormSubmitControls from 'components/FormSubmitControls';
import Select from 'components/Select';
import { onError, onSuccess } from 'utils/handlers';
import API from 'utils/api';
import { isMutable } from 'utils/estadosGlobales';
import getFormattedValues, { formatReceived } from 'utils/formatValues';
import { useSelector } from 'react-redux';
import Moment from 'moment';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Spin,
} from 'antd';

export const permissionFuncionesPresupuestales = {
  permissionModel: 'funcionespresupuestales',
};

const baseURI = 'catalogos/funcion-presupuestal/';

// eslint-disable-next-line react/prop-types
function FuncionesPresupuestales({ permission }) {
  const { estadosGlobales } = useSelector(({ catalogs }) => catalogs);
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mutable, setMutable] = useState(true);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await API.get(baseURI);
      setData(formatReceived(response.data));
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const fetchAll = async () => {
    try {
      setLoading(true);

      await fetchData();
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useState(() => {
    fetchAll();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setVisible(false);
    setSelectedRowKeys([]);
    form.resetFields();
    setMutable(true);
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      if (values.estados_globales === 5) {
        values.fecha_cancelacion = new Moment();
      }
      const formattedValues = getFormattedValues(values);
      if (!selectedRowKeys.length) {
        const response = await API.post(baseURI, formattedValues);
        if (response?.status === 201) {
          onSuccess(response, 'Agregado correctamente');
          onCancel();
          await fetchData();
        }
      } else {
        const [key] = selectedRowKeys;
        const response = await API.put(`${baseURI}${key}/`, formattedValues);
        if (response?.status === 200) {
          onSuccess(response, 'Actualizado correctamente');
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es requerido',
      },
    ],
  };

  const columns = [
    {
      titleText: 'No.',
      dataIndex: 'numero',
      key: 'numero',
      width: 200,
    },
    {
      titleText: 'Nombre',
      dataIndex: 'nombre',
      key: 'nombre',
      width: 200,
    },
    {
      titleText: 'Objetivo',
      dataIndex: 'objetivo',
      key: 'objetivo',
      width: 200,
    },
    {
      titleText: 'Estado',
      dataIndex: 'estados_globales',
      key: 'estados_globales',
      width: 200,
      render: (val) => estadosGlobales.find((t) => t.id === val)?.descripcion,
    },
    {
      titleText: 'Fecha Inactivo',
      dataIndex: 'fecha_cancelacion',
      key: 'fecha_cancelacion',
      width: 200,
      render: (val) => (Moment.isMoment(val) ? val.format('DD/MM/YYYY') : '-'),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    form.setFieldsValue(formatReceived(record));
    setMutable(isMutable(record));
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const onClickEdit = () => {
    setVisible(true);
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`${baseURI}${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          setVisibleAlert(false);
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  return (
    <Row>
      <Spin tip="Cargando..." spinning={loading}>
        <Col span={24}>
          <Table
            mobileCols={[columns[0]]}
            cols={columns}
            permission={permission}
            data={data}
            rowSelection={rowSelection}
            handleOnRowClick={handleOnRowClick}
            controls={{
              onClickAdd,
              onClickEdit,
              onClickDelete,
            }}
            allowExport={false}
            rowKey="key"
          />
          <Modal
            visible={visible}
            title={(
              <FormSubmitControls
                label={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Función`}
                onFinish={onFinish}
                onCancel={onCancel}
              />
            )}
            onCancel={onCancel}
            footer={null}
            closable={false}
            forceRender
          >
            <Form
              layout="vertical"
              form={form}
              onFinish={onFinish}
              scrollToFirstError
              initialValues={{ estados_globales: 1 }}
            >
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="Numero"
                    rules={rules.required}
                    name="numero"
                    hasFeedback
                  >
                    <Input
                      allowClear
                      disabled={!mutable}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="Nombre"
                    rules={rules.required}
                    name="nombre"
                    hasFeedback
                  >
                    <Input allowClear disabled={!mutable} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="Objetivo"
                    rules={rules.required}
                    name="objetivo"
                    hasFeedback
                  >
                    <Input.TextArea
                      autoSize={{ minRows: 3, maxRows: 3 }}
                      allowClear
                      disabled={!mutable}
                    />
                  </Form.Item>
                </Col>
                {!mutable && (
                  <Col span={24}>
                    <Form.Item
                      label="Fecha de cancelación"
                      name="fecha_inactivo"
                      hasFeedback
                    >
                      <DatePicker disabled format="DD/MM/YYYY" placeholder="" />
                    </Form.Item>
                  </Col>
                )}
                <Col span={24}>
                  <Form.Item
                    label="Estado"
                    rules={rules.required}
                    name="estados_globales"
                  >
                    <Select
                      dataSource={estadosGlobales}
                      disabled={!selectedRowKeys.length || !mutable}
                    />
                  </Form.Item>
                </Col>
                <Form.Item hidden>
                  <Button htmlType="submit" />
                </Form.Item>
              </Row>
            </Form>
          </Modal>
        </Col>
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => setVisibleAlert(false)}
          visible={visibleAlert}
          content={`la funcionPresupuestal ${form.getFieldValue('nombre')}`}
        />
      </Spin>
    </Row>
  );
}

export default FuncionesPresupuestales;
