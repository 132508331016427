import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Row,
  Col,
  Spin,
  Input,
  Modal,
  DatePicker,
  Typography,
} from 'antd';

import Table from 'components/Table';
import FormSubmitControls from 'components/FormSubmitControls';
import API from 'utils/api';
import { onSuccess, onError } from 'utils/handlers';
import ModalDelete from 'components/ModalDelete';
import NumericInput from 'components/NumericInput';

const baseURI = 'catalogos/meta-de-objetivo-de-desarrollo-sustentable/';

function MetasDeObejtivos({
  parent,
  callback,
}) {
  const [formTable] = Form.useForm();
  const [formParent] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const fetchAll = async () => {
    try {
      setLoading(true);
      if (parent?.id) {
        setData(parent.children);
        formParent.setFieldsValue({
          ...parent,
          periodos: [
            parent.periodo_inicial,
            parent.periodo_final,
          ],
        });
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchAll();

    return () => API.source.cancel;
    // eslint-disable-next-line
  }, [parent]);

  const onCancel = () => {
    setVisible(false);
    setSelectedRowKeys([]);
    formTable.resetFields();
    setVisibleAlert(false);
  };

  const patchMetas = async (id) => {
    try {
      setLoading(true);
      await API.patch(`/catalogos/objetivo-de-desarrollo-sustentable/${parent.id}`, {
        metas_de_desarrollo_sostenible: [...parent.metas_de_desarrollo_sostenible, id],
      });
      setLoading(false);
    } catch (error) {
      onError(error, setLoading);
    }
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await formTable.validateFields();
      const values = formTable.getFieldsValue();
      const [key] = selectedRowKeys;
      if (!selectedRowKeys.length) {
        const response = await API.post(baseURI, values);
        await patchMetas(response.data.id);
        if (response?.status === 201) {
          onSuccess(response, 'Agregado correctamente');
          onCancel();
          await callback(true);
        }
      } else {
        const response = await API.put(`${baseURI}${key}/`, values);
        if (response?.status === 200) {
          onSuccess(response, 'Actualizado correctamente');
          onCancel();
          await callback(true);
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`${baseURI}${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          setVisibleAlert(false);
          onCancel();
          await callback(true);
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onClickAdd = () => {
    setVisible(true);
    setSelectedRowKeys([]);
    formTable.resetFields();
  };

  const onClickEdit = () => {
    setVisible(true);
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const handleOnRowClick = (record) => {
    const _key = parent ? record.id : record.key;
    setSelectedRowKeys([_key]);
    formTable.setFieldsValue(record);
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const columns = [
    {
      titleText: 'Número',
      dataIndex: 'numero',
      key: 'numero',
      width: 30,
    },
    {
      titleText: 'Descripcion',
      dataIndex: 'descripcion',
      key: 'descripcion',
      width: 300,
    },

  ];

  const rules = {
    required: [
      {
        required: true,
        message: 'El campo es Requerido',
      },
    ],
  };

  return (
    <Row align="center" justify="center" className="container">
      <Spin tip="Cargando..." spinning={loading}>
        <Form
          layout="vertical"
          form={formParent}
        >
          <Row gutter={10}>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                label="Nombre"
                rules={rules.required}
                name="nombre"
              >
                <Input allowClear disabled />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={8}>
              <Form.Item
                rules={rules.required}
                label="Periodo de Duración"
                name="periodos"
                disabled
              >
                <DatePicker.RangePicker
                  format="YYYY"
                  placeholder=""
                  picker="year"
                  disabled
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                label="Descripción"
                rules={rules.required}
                name="descripcion"
              >
                <Input.TextArea
                  autoSize={{ minRows: 3, maxRows: 3 }}
                  allowClear
                  disabled
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Typography.Title level={4}>
          Metas
        </Typography.Title>
        <Table
          cols={columns}
          data={data}
          rowSelection={rowSelection}
          mobileColIndex={0}
          rowKey={parent ? 'id' : 'key'}
          handleOnRowClick={handleOnRowClick}
          controls={{
            onClickAdd,
            onClickEdit,
            onClickDelete,
          }}
          allowImport
          baseURI={baseURI}
        />
        <Modal
          visible={visible}
          closable={false}
          footer={null}
          title={(
            <FormSubmitControls
              label={`${selectedRowKeys.length ? 'Editar' : 'Agregar'}`}
              onFinish={onFinish}
              onCancel={onCancel}
              loading={loading}
            />
        )}
          keyboard={!loading}
          maskClosable={!loading}
          onCancel={onCancel}
          forceRender
        >
          <Form
            layout="vertical"
            form={formTable}
            onFinish={onFinish}
            scrollToFirstError
          >
            <Row gutter={10}>
              <Col span={24}>
                <NumericInput
                  name="numero"
                  label="Número"
                  required
                />
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Descripción"
                  rules={rules.required}
                  name="descripcion"
                >
                  <Input.TextArea
                    autoSize={{ minRows: 3, maxRows: 3 }}
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Form.Item
                hidden
                name="id"
              >
                <Input />
              </Form.Item>
            </Row>
          </Form>
        </Modal>
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => {
            setVisibleAlert(false);
            setSelectedRowKeys([]);
          }}
          visible={visibleAlert}
          content="La meta seleccionada"
        />
      </Spin>
    </Row>
  );
}
MetasDeObejtivos.propTypes = {
  parent: PropTypes.shape({
    id: PropTypes.number,
    nombre: PropTypes.string,
    periodo_inicial: PropTypes.shape({}),
    periodo_final: PropTypes.shape({}),
    children: PropTypes.arrayOf(PropTypes.shape({})),
    metas_de_desarrollo_sostenible: PropTypes.arrayOf(PropTypes.number),
  }),
  callback: PropTypes.func.isRequired,

};
MetasDeObejtivos.defaultProps = {
  parent: null,
};

export default MetasDeObejtivos;
