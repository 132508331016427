import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import { useSelector } from 'react-redux';
import {
  Form,
  Button,
  Row,
  Col,
  Spin,
  Input,
  message,
} from 'antd';

import API from 'utils/api';
import { onError, onSuccess } from 'utils/handlers';
// import Select from 'components/Select';
// import FormItemLabel from 'components/FormItemLabel';
import makeMessager from 'utils/complexFormsMessages';

const ayudaPoblacionReferencia = 'Es el universo total de población (con o sin problema), relacionada con el área o sector en el que se está definiendo el problema.';
const ayudaPoblacionProblema = 'Dentro de la Población de Referencia, esta parte de la población al no ser afectada por el problema queda fuera de los alcances del programa.';
const ayudaPoblacionPotencia = 'Es aquella parte de la población de referencia que se encuentra afectada por el problema o será afectada por él y, que requiere de los servicios o bienes que proveerá el programa presupuestario. Esta población se considera como elegible para ser atendida y justifica la existencia del programa presupuestario.';
const ayudaPoblacionObjetivo = 'Es el número de beneficiarios, que corresponde a un subgrupo de la población potencial (personas, familias, empresas, instituciones) que el programa atenderá en un período determinado, partiendo de criterios de focalización con base en las restricciones de recursos humanos y presupuestales. En algunos casos, la población objetivo corresponde con la población potencial, siempre y cuando las restricciones de recursos permitan la atención del total de la población potencial.';
const ayudaPoblacionPostergada = 'Es la población que, por algún criterio de focalización o característica específica, el programa no está en condiciones reales de atender';

function Cobertura({
  mutable,
  fatherId,
  setCurrentTabKey,
  coberturaID,
  fatherURI,
  setFormCobertura,
}) {
  const baseURI = 'presupuestos/cobertura-arbol-de-problemas-y-objetivos/';
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  // const [seccion, setSeccion] = useState();

  const formatValues = (values) => {
    const keys = Object.keys(values);
    const clone = {};
    keys.forEach((prop) => {
      const value = values[prop];
      if (value && value !== 'N/A') {
        clone[prop] = value;
      }
    });
    return clone;
  };

  const getCobertura = async () => {
    try {
      setLoading(true);
      let formattedValues = {};
      if (fatherId && coberturaID) {
        const resCobertura = await API.get(`${baseURI}${coberturaID}`);
        formattedValues = formatValues(resCobertura.data);
      }
      setTimeout(() => {
        form.setFieldsValue(formattedValues);
      });
      setLoading(false);
    } catch (error) {
      onError(error, setLoading);
    }
  };

  const showMsg = makeMessager(setCurrentTabKey, getCobertura);

  const onFinishHandler = async (tabKey = null, _continue = true) => {
    try {
      if (tabKey) {
        showMsg(tabKey);
      } else {
        // eslint-disable-next-line no-use-before-define
        await onFinish(null, _continue);
      }
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    // eslint-disable-next-line no-use-before-define
    form.onFinishHandler = onFinishHandler;
    setFormCobertura(form);
    const fetch = async () => {
      await getCobertura();
    };
    fetch();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, [coberturaID]);

  const patchDiagnostico = async (coberturaIdentificacion) => {
    try {
      setLoading(true);
      const resPatch = await API.patch(`${fatherURI}/${fatherId}`, {
        cobertura: coberturaIdentificacion,
      });
      if (resPatch.status === 200) {
        message.info('Cobertura Agregada Correctamente');
      }
      setLoading(false);
    } catch (error) {
      onError(error, setLoading);
    }
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      // const [key] = fatherId;
      if (coberturaID) {
        const response = await API.put(`${baseURI}${coberturaID}`, values);
        if (response?.status === 200) {
          onSuccess(response, 'Actualizado Correctamente');
        }
      } else {
        // values.situacion_no_deseada = values.id;
        const response = await API.post(baseURI, values);
        if (response?.status === 201) {
          onSuccess(response, 'Agregado Correctamente');
          await patchDiagnostico(response.data.id);
          await getCobertura();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  // const onFinish = async () => {
  //   try {
  //     setLoading(true);
  //     await form.validateFields();
  //     const values = form.getFieldsValue();
  //     if (!coberturaID) {
  //       const response = await API.post(baseURI, values);
  //       if (response?.status === 201) {
  //         onSuccess(response, 'Agregado Correctamente');
  //         await patchDiagnostico(response.data.id);
  //         await getCobertura();
  //       }
  //     } else {
  //       const response = await API.put(`${baseURI}${coberturaID}`, values);
  //       if (response?.status === 200) {
  //         onSuccess(response, 'Actualizado Correctamente');
  //       }
  //     }
  //     setLoading(false);
  //   } catch (err) {
  //     onError(err, setLoading);
  //   }
  // };

  const rules = {

    required: [
      {
        message: 'El campo es requerido',
        required: true,
      },
    ],
  };

  return (
    <Row align="center" justify="center" className="container">
      <Spin tip="Cargando..." spinning={loading}>
        <Form
          name="situacionTab"
          form={form}
          layout="vertical"
          onFinish={onFinish}
          scrollToFirstError
          initialValues={{
            ayuda_poblacion_de_referencia: ayudaPoblacionReferencia,
            ayuda_poblacion_no_afecta_al_problema: ayudaPoblacionProblema,
            ayuda_poblacion_potencia: ayudaPoblacionPotencia,
            ayuda_poblacion_objetivo_o_area_enfoque: ayudaPoblacionObjetivo,
            ayuda_poblacion_postergada: ayudaPoblacionPostergada,
          }}
        >

          <Row gutter={10}>
            <Col xs={24} sm={24} md={16}>
              <Form.Item
                tooltip={ayudaPoblacionReferencia}
                name="poblacion_de_referencia"
                rules={rules.required}
                label="Población de Referencia"
              >
                <Input.TextArea
                  autoSize={{ minRows: 2, maxRows: 5 }}
                  allowClear
                  disabled={!mutable}
                />
              </Form.Item>
            </Col>
            <Form.Item
              name="ayuda_poblacion_de_referencia"
              hidden
            >
              <Input.TextArea />
            </Form.Item>
            <Col xs={24} sm={24} md={16}>
              <Form.Item
                tooltip={ayudaPoblacionProblema}
                name="poblacion_no_afecta_al_problema"
                label="Población  no afecta al problema"
              >
                <Input.TextArea
                  autoSize={{ minRows: 2, maxRows: 4 }}
                  allowClear
                  disabled={!mutable}
                />
              </Form.Item>
            </Col>
            <Form.Item
              name="ayuda_poblacion_no_afecta_al_problema"
              hidden
            >
              <Input.TextArea />
            </Form.Item>
            <Col xs={24} sm={24} md={16}>
              <Form.Item
                tooltip={ayudaPoblacionPotencia}
                name="poblacion_potencial"
                label="Población Potencial"
              >
                <Input.TextArea
                  autoSize={{ minRows: 2, maxRows: 4 }}
                  allowClear
                  disabled={!mutable}
                />
              </Form.Item>
            </Col>
            <Form.Item
              name="ayuda_poblacion_potencia"
              hidden
            >
              <Input.TextArea />
            </Form.Item>
            <Col xs={24} sm={24} md={16}>
              <Form.Item
                tooltip={ayudaPoblacionObjetivo}
                name="poblacion_objetivo_o_area_enfoque"
                rules={rules.required}
                label="Población Objetivo o Área de Enfoque"
              >
                <Input.TextArea
                  autoSize={{ minRows: 2, maxRows: 4 }}
                  allowClear
                  disabled={!mutable}
                />
              </Form.Item>
            </Col>
            <Form.Item
              name="ayuda_poblacion_objetivo_o_area_enfoque"
              hidden
            >
              <Input.TextArea />
            </Form.Item>
            <Col xs={24} sm={24} md={16}>
              <Form.Item
                tooltip={ayudaPoblacionPostergada}
                name="poblacion_postergada"
                rules={rules.required}
                label="Población Postergada"
              >
                <Input.TextArea
                  autoSize={{ minRows: 2, maxRows: 4 }}
                  allowClear
                  disabled={!mutable}
                />
              </Form.Item>
            </Col>
            <Form.Item
              name="ayuda_poblacion_postergada"
              hidden
            >
              <Input.TextArea />
            </Form.Item>
          </Row>
          <Form.Item hidden name="id">
            <Input />
          </Form.Item>
          <Form.Item hidden>
            <Button htmlType="submit" />
          </Form.Item>
        </Form>
      </Spin>
    </Row>
  );
}

Cobertura.propTypes = {
  // setForm: PropTypes.func.isRequired,
  mutable: PropTypes.bool.isRequired,
  setCurrentTabKey: PropTypes.func.isRequired,
  fatherId: PropTypes.arrayOf(PropTypes.number),
  fatherURI: PropTypes.string.isRequired,
  setFormCobertura: PropTypes.func.isRequired,
  coberturaID: PropTypes.arrayOf(PropTypes.number),
  // callback: PropTypes.func,
};

Cobertura.defaultProps = {
  fatherId: [],
  // callback: () => { },
  coberturaID: 0,
};

export default Cobertura;
