import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Spin,
  Modal,
  message,
} from 'antd';
import { useSelector } from 'react-redux';
import { integerRegExp } from 'utils/patterns';
import { SET_COG } from 'store/reducers/catalogs';
import Table from 'components/Table';
import FormSubmitControls from 'components/FormSubmitControls';
import ModalDelete from 'components/ModalDelete';
import NumericInput from 'components/NumericInput';
import Select from 'components/Select';
import { findItemNested } from 'utils/filters';
import API from 'utils/api';
import fetchSome from 'utils/fetchSome';
import createKey from 'utils/keyGenerator';
import { onError, onSuccess } from 'utils/handlers';
import makeThreeFromCOG from 'utils/makeTree';
import { isMutable } from 'utils/estadosGlobales';

export const permissionClasificacionDeObjetoDeGasto = {
  permissionModel: 'clasificaciondeobjetodegasto',
};

const baseURI = 'contabilidad/clasificaciones-objeto-gasto-interno/';

// eslint-disable-next-line react/prop-types
function ObjetoDeGasto({ permission }) {
  const [form] = Form.useForm();
  const cog = useSelector(({ catalogs }) => catalogs.cog);
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [selected, setSelected] = useState();
  const [mutable, setMutable] = useState(true);

  const normalizeData = (_data = []) => _data.map((item) => {
    const clone = { ...item };
    const children = clone.children?.length && typeof clone.id === 'string'
      ? normalizeData(clone.children) : [];
    if (!children.length || typeof clone.id === 'number') {
      delete clone.children;
      return ({
        ...clone,
        key: clone.clave,
        clave: clone.clave,
        disabled: !clone.seleccionable,
      });
    }
    return ({
      ...clone,
      key: clone.clave,
      children,
      disabled: !clone.seleccionable,
    });
  });

  const fetchData = async () => {
    try {
      setLoading(true);
      const URI = '/contabilidad/clasificaciones-objeto-gasto/';
      const response = await API.get(URI);
      const sortedData = response.data.sort((a, b) => a.id - b.id);
      const tree = makeThreeFromCOG(sortedData
        .map((e) => (!e.seleccionable ? ({
          ...e,
          id: `${e.id}`,
        }) : e)));
      setData(normalizeData(tree));
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    const fetchAll = async () => {
      try {
        setLoading(true);
        if (!cog.length) {
          await fetchSome('catalogos/clasificaciones-objeto-gasto/', SET_COG);
        }
        await fetchData();
        setLoading(false);
      } catch (err) {
        onError(err, setLoading);
      }
    };
    fetchAll();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    setSelectedRowKeys([]);
    setMutable(true);
    setDisabled(false);
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      values.seleccionable = true;
      if (!selectedRowKeys.length) {
        const response = await API.post(baseURI, values);
        if (response?.status === 201) {
          onSuccess(response, 'Agregado correctamente');
          onCancel();
          await fetchData();
        }
      } else {
        await form.validateFields();
        const response = await API.put(`${baseURI}${selected.id}/`, values);
        if (response?.status === 200) {
          onSuccess(response, 'Actualizado correctamente');
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`${baseURI}${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          setVisibleAlert(false);
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    setDisabled(record.disabled);
    setMutable(isMutable(record));
    setSelected(record);
    setSelectedRowKeys([record.key]);
    form.setFieldsValue({
      ...record,
      clave_padre: record.clave_padre_id,
    });
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickAdd = () => {
    onCancel();
    setSelected();
    setDisabled(false);
    setVisible(true);
  };

  const onClickEdit = () => {
    setVisible(true);
  };

  const onClickDelete = () => {
    if (selected?.seleccionable) {
      setVisibleAlert(true);
    } else {
      message.warn('No es posible eliminar este registro.');
    }
  };

  const columns = [
    {
      titleText: 'Clave',
      dataIndex: 'clave',
      key: 'clave',
      width: 150,
    },
    {
      titleText: 'Concepto',
      dataIndex: 'concepto',
      key: 'concepto',
      width: 350,
    },
    {
      titleText: 'Descripción',
      dataIndex: 'descripcion',
      key: 'descripcion',
      width: 900,
    },
  ];

  const requiredRule = {
    required: true,
    message: 'El campo es requerido',
  };

  const rules = {
    required: [requiredRule],
    clave: [
      requiredRule,
      {
        validator: async (r, val) => {
          const clone = [...data];
          const value = val?.toString() || '';
          const match = clone.find((e) => e.clave === value);
          const parent = findItemNested(cog, form.getFieldValue('clave_padre'));
          if (value) {
            if (!integerRegExp.test(value)) {
              throw new Error('Ingrese un número entero válido');
            } else if (value.indexOf(parent?.clave) !== 0) {
              throw new Error('El campo debe incluir al inicio la clave de padre');
            } else if (value.length === parent?.clave?.length) {
              throw new Error('La longitud de la clave debe ser mayor a la clave de padre');
            } else if (match && !selectedRowKeys.length) {
              throw new Error('La clave ya ha sido dada de alta.');
            }
          }
        },
      },
    ],
    concepto: [
      requiredRule,
      {
        validator: async (r, val) => {
          const clone = [...data];
          const match = clone.find((e) => e.concepto === val);
          if (val) {
            if (match && !selectedRowKeys.length) {
              throw new Error('El concepto debe ser único.');
            }
          }
        },
      },
    ],
  };

  const onChange = async (val) => {
    if (val) {
      const parent = findItemNested(cog, val);
      createKey(data, form, { filter: { prop: 'children', parent } });
    } else {
      form.resetFields(['clave']);
    }
  };

  const onClickExpand = () => {
    setVisible(true);
  };

  const getControls = () => {
    if (selected?.seleccionable) {
      return {
        onClickAdd,
        onClickEdit,
        onClickDelete,
      };
    }
    return {
      onClickAdd,
      onClickExpand,
    };
  };

  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        <Table
          cols={columns}
          data={data}
          rowSelection={rowSelection}
          handleOnRowClick={handleOnRowClick}
          permission={{ ...permission, export: true }}
          controls={getControls()}
          allowEdit={selected?.seleccionable}
          allowDelete={selected?.seleccionable}
          rowKey="key"
          childrenProp="children"
          filterNested
          allowExpand
          justImport
          baseURI={baseURI}
          allowedExtensions={['xlsx', 'csv', 'json']}
        />
        <Modal
          visible={visible}
          title={(
            <FormSubmitControls
              label={selected?.disabled
                ? 'Vista Previa'
                : `${selectedRowKeys.length ? 'Editar' : 'Agregar'} Clasificación de Gasto`}
              onFinish={selected?.disabled ? null : onFinish}
              onCancel={onCancel}
              loading={loading}
            />
          )}
          onCancel={onCancel}
          footer={null}
          closable={false}
          maskClosable={!loading}
          keyboard={!loading}
          forceRender
        >
          <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
            initialValues={{ estados_globales: 1 }}
            scrollToFirstError
          >
            {!selected?.disabled && (
              <Col span={24}>
                <Form.Item
                  name="clave_padre"
                  label="Clave Padre"
                  rules={rules.required}
                  hasFeedback
                >
                  <Select
                    dataSource={cog}
                    onSelect={onChange}
                    disabled={!!selectedRowKeys.length || disabled}
                    labelProp="concepto"
                    keyLabelRender
                    tree
                  />
                </Form.Item>
              </Col>
            )}
            <Col span={24}>
              <NumericInput
                name="clave"
                label="Clave"
                rules={rules.clave}
                disabled={!!selectedRowKeys.length || disabled}
                hasFeedback
              />
            </Col>
            <Col span={24}>
              <Form.Item
                name="concepto"
                rules={rules.concepto}
                label="Concepto"
                hasFeedback
              >
                <Input allowClear disabled={disabled || !mutable} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="descripcion"
                rules={rules.required}
                label="Descripción"
                hasFeedback
              >
                <Input.TextArea
                  disabled={disabled || !mutable}
                  autoSize={{ minRows: 4, maxRows: 4 }}
                  allowClear
                />
              </Form.Item>
            </Col>
            {(mutable || selected?.seleccionable) && (
              <Col span={24}>
                <Form.Item
                  name="estados_globales"
                  label="Estado"
                  rules={rules.required}
                >
                  <Select
                    disabled={!selectedRowKeys.length || !mutable}
                    dataSource={estadosGlobales}
                  />
                </Form.Item>
              </Col>
            )}
            <Form.Item hidden>
              <Button htmlType="submit" />
            </Form.Item>
          </Form>
        </Modal>
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => setVisibleAlert(false)}
          visible={visibleAlert}
          content={`la Clasificación Objeto de Gasto Interno ${form.getFieldValue('concepto')}`}
          loading={loading}
        />
      </Spin>
    </Row>
  );
}

export default ObjetoDeGasto;
