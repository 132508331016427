/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Tabs,
  Spin,
} from 'antd';
import FormSubmitControls from 'components/FormSubmitControls';
// import getFormatedValues, { formatReceived } from 'utils/formatValues';
import API from 'utils/api';
import { onError, onSuccess } from 'utils/handlers';
import Select from 'components/Select';
import { useSelector } from 'react-redux';
import ModalDelete from 'components/ModalDelete';
import Table from 'components/Table';
import { toInteger } from 'lodash';
import AgregarPresupuesto from './AgregarPresupuesto';

const { TabPane } = Tabs;

function FichasTecnicasDelIndicador({
  selectedRowKey: programa_presupuestario_mir,
  nivelesDeIndicador,
  matrices,
  areas,

}) {
  // const baseURI = '/presupuestos/ficha-tecnica-del-indicador/';
  const baseURI = '/presupuestos/niveles-de-presupuestacion-mir/';
  const [formGeneral] = Form.useForm();
  const [formConfiguracion] = Form.useForm();
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentTabKey, setCurrentTabKey] = useState('configuracion');
  const [data, setData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [configuraciones, setConfiguraciones] = useState([]);
  const [presupuestoVisible, setPresupuestoVisible] = useState(false);
  const formInstances = [
    formGeneral,
    formConfiguracion,
  ];

  const fetchData = async () => {
    try {
      setLoading(true);
      if (programa_presupuestario_mir) {
        const res = await API.get(baseURI, {
          params: {
            programa_presupuestario_mir,
          },
        });
        const resConfiguracion = await API.get('/presupuestos/configuracion-de-presupuestacion-mir/');
        setConfiguraciones(resConfiguracion.data);

        if (res?.status === 200) {
          setData(res.data);
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const fetchConfiguraciones = async () => {
    setLoading(true);
    const responseConfiguracion = await API.get('/presupuestos/configuracion-de-presupuestacion-mir/');
    setLoading(false);
    return responseConfiguracion.data;
  };

  const onCancel = () => {
    setVisible(false);
    formGeneral.resetFields();
    formConfiguracion.resetFields();
    setCurrentTabKey('configuracion');
    setSelectedRowKeys([]);
    setPresupuestoVisible(false);
  };

  const AutofillGeneral = (val) => {
    try {
      setLoading(true);
      const resMatriz = matrices.find((e) => e.id === val);
      if (resMatriz) {
        formGeneral.setFieldsValue({
          nivel: resMatriz.nivel_del_indicador,
          clave: resMatriz.clave,
          descripcion: resMatriz.resumen_narrativo,
        });
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };
  useEffect(() => {
    let mounted = true;
    const fetchAll = async () => {
      try {
        setLoading(true);
        if (mounted) {
          setLoading(false);
          await fetchData();
        }
      } catch (err) {
        onError(err, setLoading);
      }
    };
    fetchAll();
    return () => { mounted = false; };
    // eslint-disable-next-line
  }, [programa_presupuestario_mir]);

  const onFinish = async () => {
    try {
      setLoading(true);
      await formGeneral.validateFields();
      await formConfiguracion.validateFields();

      const valuesConfiguracion = {
        ...formConfiguracion.getFieldsValue(),
      };

      if (!selectedRowKeys.length) {
        const responseConfiguracion = await API.post('/presupuestos/configuracion-de-presupuestacion-mir/', valuesConfiguracion);
        if (responseConfiguracion.status === 201) {
          const resConfiguracion = await fetchConfiguraciones();
          const valuesGeneral = {
            ...formGeneral.getFieldsValue(),
            presupuestacion_mir: resConfiguracion[resConfiguracion.length - 1].id,
          };
          const responseGeneral = await API.post(baseURI, valuesGeneral);
          if (responseGeneral.status === 201) {
            onSuccess(responseGeneral, 'Agregado Correctamente');
            onCancel();
          }
          await fetchData();
        }
      } else {
        const [key] = selectedRowKeys;
        const valuesGeneral = {
          ...formGeneral.getFieldsValue(),
        };
        const response = await API.put(`${baseURI}${key}/`, valuesGeneral);
        if (response.status === 200) {
          const responseConfiguracion = await API.put(`${'/presupuestos/configuracion-de-presupuestacion-mir/'}${valuesGeneral.presupuestacion_mir}/`, valuesConfiguracion);
          if (responseConfiguracion.status === 200) {
            onSuccess(responseConfiguracion, 'Editado Correctamente');
            onCancel();
            await fetchData();
          }
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading, formInstances, setCurrentTabKey);
    }
  };

  const columns = [
    {
      titleText: 'Nivel',
      dataIndex: 'nivel',
      key: 'nivel',
      width: 350,
      render: (nivel) => nivelesDeIndicador.find((e) => e.id === toInteger(nivel))?.descripcion,
    },
    {
      titleText: 'Clave',
      dataIndex: 'clave',
      key: 'clave',
      width: 350,
    },
    {
      titleText: 'Resumen Narrativo',
      dataIndex: 'descripcion',
      key: 'descripcion',
      width: 350,
    },
    {
      titleText: 'Estado',
      dataIndex: 'estados_globales',
      key: 'estados_globales',
      width: 160,
      render: (id) => estadosGlobales.find((eg) => eg.id === id)?.descripcion,
    },
  ];

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    setTimeout(() => {
      formGeneral.setFieldsValue(record);
      formConfiguracion.setFieldsValue(record);
    });
  };

  const onClickAdd = async () => {
    onCancel();
    setVisible(true);
  };

  const onClickEdit = async () => {
    try {
      setLoading(true);
      const [key] = selectedRowKeys;
      const match = data.find((e) => e.id === key);
      const matchConfiguracion = configuraciones.find((e) => e.id === match.presupuestacion_mir);
      setTimeout(() => {
        formGeneral.setFieldsValue({
          nivel: toInteger(match.nivel),
        });
        formConfiguracion.setFieldsValue({
          ...matchConfiguracion,
        });
      });
      setPresupuestoVisible(true);

      setLoading(false);
    } catch (error) {
      onError(error, setLoading);
    }
    setVisible(true);
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const deleteItem = async () => {
    try {
      const [key] = selectedRowKeys;
      if (programa_presupuestario_mir) {
        if (selectedRowKeys.length) {
          const response = await API.delete(`${baseURI}${key}/`);
          if (response?.status === 204) {
            onSuccess(response, 'Eliminado correctamente');
            onCancel();
            await fetchData();
            setVisibleAlert(false);
          }
        }
      }
    } catch (err) {
      onError(err, setLoading);
    }
  };

  // const requiredRule = {
  //   message: 'Este campo es requerido',
  // };

  // const rules = {
  //   required: [requiredRule],
  // };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  return (
    <Row align="center" justify="center" className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible ? (
          <Table
            cols={columns}
            data={data}
            rowSelection={rowSelection}
            handleOnRowClick={handleOnRowClick}
            controls={{
              onClickAdd,
              onClickEdit,
              onClickDelete,
            }}
            mobileColIndex={0}
          />
        ) : (
          <Card
            bordered={false}
            className="form-container"
            title={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Presupuestacion`}
            extra={(
              <FormSubmitControls
                onFinish={onFinish}
                onCancel={onCancel}
                loading={loading}
              />
            )}
          >
            <Tabs
              activeKey={currentTabKey}
              onChange={setCurrentTabKey}
              // type="card"
              tabPosition="left"

            >
              <TabPane
                key="configuracion"
                tab="Configuracion"
                forceRender
              >
                <Form
                  layout="vertical"
                  form={formConfiguracion}
                  onFinish={onFinish}
                  scrollToFirstError
                  initialValues={{
                    es_a_nivel_actividad: false,
                  }}
                >
                  <Form.Item
                    name="id"
                    hidden
                  >
                    <Input />
                  </Form.Item>
                  <Row gutter={10}>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item
                        label="¿Es nivel actividad?"
                        name="es_a_nivel_actividad"
                      >
                        <Select
                          trueFalse
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item
                        label="Matriz de indicador"
                        name="matriz_del_indicador"
                      >
                        <Select
                          dataSource={matrices}
                          labelProp="clave"
                          onChange={(val) => {
                            AutofillGeneral(val);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item hidden>
                    <Button htmlType="submit" />
                  </Form.Item>
                </Form>
              </TabPane>
              <TabPane
                key="general"
                tab="General"
                forceRender
                // disabled={!selectedRowKeys.length}

              >
                <Form
                  layout="vertical"
                  form={formGeneral}
                  onFinish={onFinish}
                  scrollToFirstError
                >
                  <Row gutter={10}>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item
                        name="nivel"
                        label="Nivel de la MIR"
                      >
                        <Select
                          dataSource={nivelesDeIndicador}
                          disabled
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item
                        name="clave"
                        label="Clave"
                      >
                        <Input disabled />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item
                        name="descripcion"
                        label="Resumen Narrativo"
                      >
                        <Input.TextArea
                          autoSize={{ minRows: 2, maxRows: 3 }}
                          disabled
                        />
                      </Form.Item>
                    </Col>
                    {presupuestoVisible && (
                      <Col xs={24} sm={24} md={24}>
                        <AgregarPresupuesto
                          nivelDePresupuestacion={selectedRowKeys}
                          selectedRowKey={programa_presupuestario_mir}
                          areas={areas}

                        />
                      </Col>
                    )}
                  </Row>
                  <Form.Item
                    name="presupuestacion_mir"
                    hidden
                  >
                    <Input />
                  </Form.Item>
                </Form>
              </TabPane>
            </Tabs>
          </Card>
        )}
      </Spin>
      <ModalDelete
        onDelete={deleteItem}
        onCancel={() => {
          setVisibleAlert(false);
          setSelectedRowKeys([]);
        }}
        visible={visibleAlert}
        content="Eliminar la Matriz seleccionada"
      />
    </Row>
  );
}

FichasTecnicasDelIndicador.propTypes = {
  selectedRowKey: PropTypes.number,
  nivelesDeIndicador: PropTypes.arrayOf(PropTypes.object).isRequired,
  matrices: PropTypes.arrayOf(PropTypes.object),
  areas: PropTypes.arrayOf(PropTypes.object),
};

FichasTecnicasDelIndicador.defaultProps = {
  selectedRowKey: 0,
  matrices: [],
  areas: [],
};

export default FichasTecnicasDelIndicador;
