/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Tabs,
  Spin,
  Typography,
  message,
  Modal,
} from 'antd';
import FormSubmitControls from 'components/FormSubmitControls';
import getFormatedValues, { formatReceived } from 'utils/formatValues';
import API from 'utils/api';
import { onError, onSuccess } from 'utils/handlers';
import Select from 'components/Select';
import { useSelector } from 'react-redux';
import ModalDelete from 'components/ModalDelete';
import Table from 'components/Table';
import NumericInput from 'components/NumericInput';

const { TabPane } = Tabs;

function FichasTecnicasDelIndicador({
  selectedRowKey: programa_presupuestario_mir,
  medidaDelIndicador,
  // puestos,
  tipoDeIndicador,
  frecuenciaDeMedicion,
  dimensionDeIndicador,
  comportamiento,
  tipoDeMeta,
  periodosFiscales,
  calendarizacion,
  factibilidadMeta,
  nivelesDeIndicador,
  matrices,
  matrizId,

}) {
  const baseURI = '/presupuestos/ficha-tecnica-del-indicador/';
  const [formGeneral] = Form.useForm();
  const [formMetas] = Form.useForm();
  const [formSemaforizacion] = Form.useForm();
  const [formResponsable] = Form.useForm();
  const mostrarPorcentaje = Form.useWatch('tipo_valor_meta', formMetas);
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [visibleResponsable, setVisibleResponsable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentTabKey, setCurrentTabKey] = useState('general');
  const [data, setData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [anioBase, setAnioBase] = useState(true);
  const [supuestos, setSupuestos] = useState([]);
  // const calendarizacionSeleccionada = Form.useWatch('frecuencia_de_medicion', formMetas);
  const [calendarizacionSeleccionada, setCalendarizacionSeleccionada] = useState(0);
  const [metas, setMetas] = useState([]);
  const [empleados, setEmpleados] = useState([]);
  const [puestos, setPuestos] = useState([]);
  const [contentTypes, setContentTypes] = useState([]);
  const formInstances = [
    formGeneral,
    formMetas,
    formSemaforizacion,
    formResponsable,
  ];

  const fetchData = async () => {
    try {
      setLoading(true);
      if (programa_presupuestario_mir) {
        const res = await API.get(baseURI, {
          params: {
            programa_presupuestario_mir,
          },
        });
        if (res?.status === 200) {
          setData(formatReceived(res.data));
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const fetchSupuestos = async (mir) => {
    try {
      setLoading(true);
      const resSupuestos = await API.get('presupuestos/supuesto-matriz-del-indicador/', {
        params: {
          mir,
        },
      });
      setSupuestos(resSupuestos.data);
      setLoading(false);
    } catch (error) {
      onError(error, setLoading);
    }
  };

  const getURForTreeSelect = (_data) => {
    if (_data.content_type) {
      const { nivel } = contentTypes.find((ct) => ct.id === _data.content_type);
      const { object_id } = _data;
      setTimeout(() => {
        formResponsable.setFieldsValue({ area_responsable: `${object_id}/${nivel}` });
      });
    }
  };

  const setValues = async (val) => {
    if (val) {
      const match = matrices.find((e) => e.id === val);
      setTimeout(() => {
        formGeneral.setFieldsValue({
          matriz_de_indicadores: matrizId,
          nivel_del_indicador: match?.nivel_del_indicador,
          resumen_narrativo: match?.resumen_narrativo,
          fuente_de_verificacion: match?.fuente_de_verificacion,
          medio_de_verificacion: match?.medio_de_verificacion,
          indicador: match?.indicador,
        });
      });
      await fetchSupuestos(val);
    }
  };

  const onCancel = () => {
    setVisible(false);
    setVisibleResponsable(false);
    formGeneral.resetFields();
    formMetas.resetFields();
    formSemaforizacion.resetFields();
    formResponsable.resetFields();
    setCalendarizacionSeleccionada();
    setSupuestos([]);
    setCurrentTabKey('general');
    setSelectedRowKeys([]);
  };

  const onCancelResponsable = () => {
    setVisibleResponsable(false);
  };

  const onClickResponsable = () => {
    setVisibleResponsable(true);
  };

  useEffect(() => {
    let mounted = true;
    const fetchAll = async () => {
      try {
        setLoading(true);
        if (mounted) {
          setLoading(false);
          await fetchData();
        }

        const resEmpleados = await API.get('/estructura-organizacional/empleados/');
        setEmpleados(resEmpleados.data.map((e) => ({
          ...e,
          nombre_completo: `${e.numero_de_empleado || ' '} - ${e.nombre || ' '} ${e.apellido_paterno || ' '}`,
          nombre_y_apellido: `${e.nombre || ' '} ${e.apellido_paterno || ' '} ${e.apellido_materno || ' '}`,
        })));

        const resPuestos = await API.get('/estructura-organizacional/puestos/');
        setPuestos(resPuestos.data);

        const resContentTypes = await API.get('catalogos/content-types-ur');
        setContentTypes(resContentTypes.data);
      } catch (err) {
        onError(err, setLoading);
      }
    };
    fetchAll();
    return () => { mounted = false; };
    // eslint-disable-next-line
  }, [programa_presupuestario_mir]);

  const onFinish = async () => {
    try {
      await formGeneral.validateFields();
      await formMetas.validateFields();
      await formSemaforizacion.validateFields();
      const values = {
        ...formGeneral.getFieldsValue(),
        ...formMetas.getFieldsValue(),
        ...formSemaforizacion.getFieldsValue(),
      };
      const metasKeys = Object.keys(values).filter((e) => /\d+/.test(e));
      const _metas = [];
      metasKeys.forEach((e) => {
        _metas.push(values[e]);
        delete values[e];
      });
      const formattedValues = getFormatedValues(values);
      if (programa_presupuestario_mir) {
        if (!formattedValues.id) {
          formattedValues.programa_presupuestario_mir = programa_presupuestario_mir;
          const res = await API.post(baseURI, formattedValues);
          if (res?.status === 201) {
            const fichasValues = await API.get(baseURI);
            if (_metas) {
              const promises = [];
              // eslint-disable-next-line no-plusplus
              for (let i = 0; i < _metas.length; i++) {
                _metas[i].numero = i + 1;
                _metas[i].variable_a = parseInt(_metas[i].variable_a, 10);
                _metas[i].variable_b = parseInt(_metas[i].variable_b, 10);
                _metas[i].ficha = fichasValues.data[fichasValues.data.length - 1].id;
                promises.push(API.post('/presupuestos/calendarizacion-de-meta-mir/', _metas[i]));
              }
              const responses = await Promise.allSettled(promises);
              if (!responses.every((response) => response.status === 'fulfilled')) {
                message.info('No se agrego la meta');
              }
            }
            onCancel();
            await fetchData();
          }
        } else {
          const res = await API.patch(`${baseURI}${formattedValues.id}/`, formattedValues);
          if (res?.status === 200) {
            if (_metas) {
              const promises = [];
              // eslint-disable-next-line no-plusplus
              for (let i = 0; i < _metas.length; i++) {
                _metas[i].numero = i + 1;
                _metas[i].variable_a = parseFloat(_metas[i].variable_a, 10);
                _metas[i].variable_b = parseFloat(_metas[i].variable_b, 10);
                _metas[i].resultado = parseFloat(_metas[i].resultado, 10);
                _metas[i].ficha = metas[0].ficha;
                promises.push(API.put(`${'/presupuestos/calendarizacion-de-meta-mir/'}${_metas[i].id}`, _metas[i]));
              }
              const responses = await Promise.allSettled(promises);
              if (!responses.every((response) => response.status === 'fulfilled')) {
                message.info('No se agrego la meta');
              }
            }
            onCancel();
            await fetchData();
          }
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, formInstances, setLoading, setCurrentTabKey);
    }
  };

  const calculate = (expression, a, b) => {
    const newString = expression.replace('(', '')
      .replace(')', '')
      .replace('a', a.toString())
      .replace('b', b.toString())
      .replace('A', a.toString())
      .replace('B', b.toString());

    if (newString.substr(-1) !== '/'
    && newString.substr(-1) !== '*'
    && newString.substr(-1) !== '-'
    && newString.substr(-1) !== '+') {
      const regex = /(\d+)([+\-*/]\d+)*/g;
      const isValid = regex.test(newString);
      if (isValid) {
      // eslint-disable-next-line no-new-func
        return Function(`
      'use strict';
      try {
        return (${newString});
      } catch {
        return 0
      }
      `)();
      }
    }
    return 0;
  };

  const columns = [
    {
      titleText: 'Indicador',
      dataIndex: 'definicion_del_indicador',
      key: 'definicion_del_indicador',
      width: 350,
    },
    {
      titleText: 'Metodo de Cálculo',
      dataIndex: 'metodo_de_calculo',
      key: 'metodo_de_calculo',
      width: 350,
    },
    {
      titleText: 'Formula de Cálculo',
      dataIndex: 'formula_de_calculo',
      key: 'formula_de_calculo',
      width: 300,
    },
    {
      titleText: 'Frecuencia de Medición',
      dataIndex: 'frecuencia_de_medicion',
      key: 'frecuencia_de_medicion',
      width: 300,
      render: (id) => frecuenciaDeMedicion.find((e) => e.id === id)?.descripcion,
    },
    {
      titleText: 'Dimensión del Indicador',
      dataIndex: 'dimension_del_indicador',
      key: 'dimension_del_indicador',
      width: 300,
      render: (id) => dimensionDeIndicador.find((e) => e.id === id)?.descripcion,

    },
    {
      titleText: 'Tipo del Indicador',
      dataIndex: 'tipo_de_indicador',
      key: 'tipo_de_indicador',
      width: 300,
      render: (id) => tipoDeIndicador.find((e) => e.id === id)?.descripcion,
    },
    {
      titleText: 'Estado',
      dataIndex: 'estados_globales',
      key: 'estados_globales',
      width: 160,
      render: (id) => estadosGlobales.find((eg) => eg.id === id)?.descripcion,
    },
  ];

  const columnsSupuestos = [
    {
      titleText: 'Supuestos',
      dataIndex: 'descripcion',
      key: 'descripcion',
      width: 350,
    },
    {
      titleText: 'Estado',
      dataIndex: 'estados_globales',
      key: 'estados_globales',
      width: 160,
      render: (id) => estadosGlobales.find((eg) => eg.id === id)?.descripcion,
    },
  ];
  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    setTimeout(() => {
      formGeneral.setFieldsValue(record);
      formMetas.setFieldsValue(record);
      formSemaforizacion.setFieldsValue(record);
    });
  };

  const onClickAdd = async () => {
    onCancel();
    if (matrizId) {
      setValues(matrizId);
    }

    setVisible(true);
  };

  const onClickEdit = async () => {
    try {
      setLoading(true);
      const [key] = selectedRowKeys;
      const match = data.find((e) => e.id === key);
      // Si el match tiene respnsable del indicador, entonces rellenara los campos del modal
      if (match?.responsable_del_indicador) {
        const resEmpleado = empleados.find((e) => e.id === match?.responsable_del_indicador);
        getURForTreeSelect(resEmpleado);
        setTimeout(() => {
          formResponsable.setFieldsValue({
            ...resEmpleado,
          });
        });
      }
      const resMetas = await API.get('/presupuestos/calendarizacion-de-meta-mir/', {
        params: {
          ficha: key,
        },
      });
      const valuesMetas = Object.fromEntries(resMetas.data.map((e) => [e.numero - 1, e]));
      setTimeout(() => {
        formMetas.setFieldsValue({
          ...valuesMetas,
          ...match,
        });
        formSemaforizacion.setFieldsValue({
          ...match,
        });
      });
      setCalendarizacionSeleccionada(match.frecuencia_de_medicion);
      setMetas(resMetas.data);
      // console.log(resMetas.data);
      if (match?.matriz_de_indicadores) {
        const matchMatriz = matrices.find((e) => e.id === match.matriz_de_indicadores);
        setTimeout(async () => {
          formGeneral.setFieldsValue({
            ...match,
            nivel_del_indicador: matchMatriz?.nivel_del_indicador,
            resumen_narrativo: matchMatriz?.resumen_narrativo,
            fuente_de_verificacion: matchMatriz?.fuente_de_verificacion,
            medio_de_verificacion: matchMatriz?.medio_de_verificacion,
            indicador: matchMatriz?.indicador,
          });
          await fetchSupuestos(match?.matriz_de_indicadores);
        });
      }
      setLoading(false);
    } catch (error) {
      onError(error, setLoading);
    }
    setVisible(true);
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const deleteItem = async () => {
    try {
      const [key] = selectedRowKeys;
      if (programa_presupuestario_mir) {
        if (selectedRowKeys.length) {
          const response = await API.delete(`${baseURI}${key}/`);
          if (response?.status === 204) {
            onSuccess(response, 'Eliminado correctamente');
            onCancel();
            await fetchData();
            setVisibleAlert(false);
          }
        }
      }
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const requiredRule = {
    message: 'Este campo es requerido',
  };

  const rules = {
    required: [requiredRule],
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const calendarizacionesMap = {
    1: ['Enero - Diciembre'],

    2: ['1er Semestre Enero - Junio',
      '2do Semestre Julio - Diciembre',
    ],

    3: ['1er Cuatrimestre Enero - Abril',
      '2do Cuatrimestre Mayo - Agosto',
      '3er Cuatrimestre Septiembre - Diciembre',
    ],

    4: ['1er Trimestre Enero - Marzo',
      '2do Trimestre Abril - Junio',
      '3er Trimestre Julio - Septiembre',
      '4to Trimestre Octubre - Diciembre',
    ],

    5: ['1er Bimestre Enero - Febrero',
      '2do Bimestre Marzo - Abril',
      '3er Bimestre Mayo - Junio',
      '4to Bimestre Julio - Agosto',
      '5to Bimestre Septiembre - Octubre',
      '6to Bimestre Noviembre - Diciembre',
    ],

    6: ['Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ],

  };

  return (
    <Row align="center" justify="center" className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible ? (
          <Table
            cols={columns}
            data={data}
            rowSelection={rowSelection}
            handleOnRowClick={handleOnRowClick}
            controls={{
              onClickAdd,
              onClickEdit,
              onClickDelete,
            }}
            mobileColIndex={0}
          />
        ) : (
          <Card
            bordered={false}
            className="form-container"
            title={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Ficha`}
            extra={(
              <FormSubmitControls
                onFinish={onFinish}
                onCancel={onCancel}
                loading={loading}
              />
            )}
          >
            <Tabs
              activeKey={currentTabKey}
              onChange={setCurrentTabKey}
              // type="card"
              tabPosition="left"
            >
              <TabPane
                key="general"
                tab="Indicador"
                forceRender
              >
                <Form
                  layout="vertical"
                  form={formGeneral}
                  onFinish={onFinish}
                  scrollToFirstError
                  initialValues={{
                    estados_globales: 1,
                  }}
                >
                  <Form.Item
                    name="id"
                    hidden
                  >
                    <Input />
                  </Form.Item>
                  <Row gutter={0}>
                    <Col xs={24} sm={24} md={4}>
                      <Form.Item
                        name="matriz_de_indicadores"
                        label="Clave"
                        required
                      >
                        <Select
                          dataSource={matrices}
                          labelProp="clave"
                          disabled
                          // onChange={setValues}
                        />

                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={4}>
                      <Form.Item
                        label="Nivel de la MIR"
                        name="nivel_del_indicador"
                      >
                        <Select
                          dataSource={nivelesDeIndicador}
                          disabled
                        />
                      </Form.Item>
                    </Col>

                  </Row>
                  <Row gutter={10}>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item
                        rules={rules.required}
                        label="Resumen Narrativo"
                        name="resumen_narrativo"
                      >
                        <Input.TextArea
                          autoSize={{ minRows: 2, maxRows: 2 }}
                          disabled
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <Table
                        cols={columnsSupuestos}
                        data={supuestos}
                        noControls
                      />
                    </Col>
                  </Row>
                  <Typography.Title level={4}>Verificación</Typography.Title>
                  <Row gutter={10}>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item
                        label="Fuente de Verificación"
                        name="fuente_de_verificacion"
                      >
                        <Input disabled />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item
                        label="Medio de Verificación"
                        name="medio_de_verificacion"
                      >
                        <Input disabled />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={10}>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item
                        rules={rules.required}
                        label="Indicador"
                        name="indicador"
                      >
                        <Input.TextArea
                          autoSize={{ minRows: 4, maxRows: 4 }}
                          disabled
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item
                        required
                        label="Definición del Indicador"
                        name="definicion_del_indicador"
                      >
                        <Input.TextArea
                          autoSize={{ minRows: 4, maxRows: 4 }}
                          allowClear
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={6}>
                      <Form.Item
                        label="Tipo de Indicador"
                        name="tipo_de_indicador"
                        required
                      >
                        <Select dataSource={tipoDeIndicador} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={6}>
                      <Form.Item
                        required
                        label="Dimensión del Indicador"
                        name="dimension_del_indicador"
                      >
                        <Select dataSource={dimensionDeIndicador} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={6}>
                      <Form.Item
                        required
                        label="Unidad de Medida"
                        name="medida_del_indicador"
                      >
                        <Select labelProp="nombre" dataSource={medidaDelIndicador} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={6}>
                      <Form.Item
                        required
                        label="Sentido del Indicador"
                        name="sentido_del_indicador"
                      >
                        <Select dataSource={comportamiento} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={6}>
                      <Form.Item
                        required
                        label="Frecuencia de Medición"
                        name="calendarizacion"
                      >
                        <Select
                          labelProp="nombre"
                          dataSource={calendarizacion}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={6}>
                      <Form.Item
                        required
                        label="Responsable del Indicador"
                        name="responsable_del_indicador"
                      >
                        <Select
                          dataSource={empleados}
                          labelProp="nombre_completo"
                          onChange={(val) => {
                            const resEmpleado = empleados.find((e) => e.id === val);
                            getURForTreeSelect(resEmpleado);
                            setTimeout(() => {
                              formResponsable.setFieldsValue({
                                ...resEmpleado,
                              });
                            });
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={6}>
                      <Form.Item
                        label=" "
                      >
                        <Button
                          onClick={onClickResponsable}
                        >
                          Info del Responsable
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Typography.Title level={4}>Valores</Typography.Title>
                  <Row gutter={10}>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item
                        required
                        label="Variable de Cálculo a"
                        name="variable_de_calculo_a"
                      >
                        <Input
                          allowClear
                          onChange={() => {
                            const values = formGeneral.getFieldValue();
                            setTimeout(() => {
                              formMetas.setFieldsValue({
                                variable_de_calculo_a: values.variable_de_calculo_a,
                              });
                            });
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={6}>
                      <Form.Item
                        label="Unidad de Variable a"
                        name="unidad_variable_a"
                        required
                      >
                        <Select
                          labelProp="nombre"
                          dataSource={medidaDelIndicador}
                          onChange={() => {
                            const values = formGeneral.getFieldValue();
                            setTimeout(() => {
                              formMetas.setFieldsValue({
                                unidad_variable_a: values.unidad_variable_a,
                              });
                            });
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={6}>
                      <Form.Item
                        rules={rules.required}
                        label="Método de Cálculo"
                        name="metodo_de_calculo"
                        tooltip="Descripción de la Operación Matematica"
                        required
                      >
                        <Input
                          allowClear
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item
                        rules={rules.required}
                        label="Variable de Calculo b"
                        name="variable_de_calculo_b"
                        required
                      >
                        <Input
                          allowClear
                          onChange={() => {
                            const values = formGeneral.getFieldValue();
                            setTimeout(() => {
                              formMetas.setFieldsValue({
                                variable_de_calculo_b: values.variable_de_calculo_b,
                              });
                            });
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={6}>
                      <Form.Item
                        label="Unidad de Variable b"
                        name="unidad_variable_b"
                        required
                      >
                        <Select
                          labelProp="nombre"
                          dataSource={medidaDelIndicador}
                          onChange={() => {
                            const values = formGeneral.getFieldValue();
                            setTimeout(() => {
                              formMetas.setFieldsValue({
                                unidad_variable_b: values.unidad_variable_b,
                              });
                            });
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={5}>
                      <Form.Item
                        rules={rules.required}
                        label="Formula de Cálculo"
                        name="formula_de_calculo"
                        tooltip="Operación Matematica"
                        required
                      >
                        <Input
                          allowClear
                          onChange={() => {
                            const values = formGeneral.getFieldValue();
                            setTimeout(() => {
                              formMetas.setFieldsValue({
                                formula_de_calculo: values.formula_de_calculo,
                              });
                            });
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        label="Estado"
                        name="estados_globales"
                      >
                        <Select
                          dataSource={estadosGlobales}
                          disabled={!selectedRowKeys.length}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item hidden>
                    <Button htmlType="submit" />
                  </Form.Item>
                </Form>
              </TabPane>
              <TabPane
                key="metas"
                tab="Metas"
                forceRender
              >
                <Form
                  layout="vertical"
                  form={formMetas}
                  onFinish={onFinish}
                  scrollToFirstError
                  initialValues={{ aplica_anio_base: true }}
                  onValuesChange={(values) => {
                    if ('formula_de_calculo' in values) {
                      if (values.formula_de_calculo.includes('=')) {
                        message.warn('La formula no puede llevar "="');
                      }
                    }
                  }}
                >
                  <Row gutter={10}>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        label="¿Aplica Año Base?"
                        name="aplica_anio_base"
                      >
                        <Select trueFalse onChange={setAnioBase} />
                      </Form.Item>
                    </Col>
                    {anioBase && (
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          label="Ejercicio"
                          name="anio_base"
                        >
                          <Select labelProp="periodo" dataSource={periodosFiscales} />
                        </Form.Item>
                      </Col>
                    )}
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        label="Factibilidad de Meta"
                        name="factibilidad_de_meta"
                      >
                        <Select
                          dataSource={factibilidadMeta}
                        />
                      </Form.Item>
                    </Col>
                    {anioBase && (
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          label="Justificación Año Base"
                          name="justificacion_anio_base"
                        >
                          <Input.TextArea
                            autoSize={{ minRows: 4, maxRows: 4 }}
                            allowClear
                          />
                        </Form.Item>
                      </Col>
                    )}
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        label="Meta de Administración"
                        rules={rules.required}
                        name="meta_de_administracion"
                      >
                        <Input.TextArea
                          autoSize={{ minRows: 4, maxRows: 4 }}
                          allowClear
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        rules={rules.required}
                        label="Justificacón de Factibilidad de Meta"
                        name="justificacion_factibilidad_de_meta"
                      >
                        <Input.TextArea
                          autoSize={{ minRows: 4, maxRows: 4 }}
                          allowClear
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        label="Tipo Valor Meta"
                        name="tipo_valor_meta"
                      >
                        <Select
                          dataSource={tipoDeMeta}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        label="Calendarización"
                        name="frecuencia_de_medicion"
                      >
                        <Select
                          dataSource={frecuenciaDeMedicion}
                          onChange={setCalendarizacionSeleccionada}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={5}>
                      <Form.Item
                        rules={rules.required}
                        label="Formula de Cálculo"
                        name="formula_de_calculo"
                      >
                        <Input
                          allowClear
                          disabled
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={3}>
                      <Form.Item
                        label=" "
                      >
                        <Button
                          onClick={() => {
                            const values = {
                              ...formMetas.getFieldsValue(),
                            };
                            if (values?.formula_de_calculo) {
                              const metasKeys = Object.keys(values).filter((e) => /\d+/.test(e));
                              const _metas = [];
                              metasKeys.forEach((e) => {
                                _metas.push(values[e]);
                                delete values[e];
                              });
                              // eslint-disable-next-line no-plusplus
                              for (let j = 0; j < _metas.length; j++) {
                                if (_metas[j]?.variable_a && _metas[j]?.variable_b) {
                                  _metas[j].resultado = calculate(
                                    values.formula_de_calculo,
                                    _metas[j].variable_a,
                                    _metas[j].variable_b,
                                  ).toFixed(2);
                                }
                              }
                              setTimeout(() => {
                                formMetas.setFieldsValue({
                                  ..._metas,
                                });
                              });
                            }
                          }}
                        >
                          Recalcular
                        </Button>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item
                        required
                        label="Variable de Cálculo a"
                        name="variable_de_calculo_a"
                      >
                        <Input
                          allowClear
                          disabled
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={6}>
                      <Form.Item
                        label="Unidad de Variable a"
                        name="unidad_variable_a"
                        required
                      >
                        <Select labelProp="nombre" dataSource={medidaDelIndicador} disabled />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item
                        rules={rules.required}
                        label="Variable de Calculo b"
                        name="variable_de_calculo_b"
                        required
                      >
                        <Input
                          allowClear
                          disabled
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={6}>
                      <Form.Item
                        label="Unidad de Variable b"
                        name="unidad_variable_b"
                        required
                      >
                        <Select labelProp="nombre" dataSource={medidaDelIndicador} disabled />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Typography.Title level={4}>Metas</Typography.Title>
                  {calendarizacionSeleccionada && calendarizacionesMap[calendarizacionSeleccionada]
                    .map((label, i) => (
                      <Row gutter={10}>
                        <Col xs={24} sm={24} md={6}>
                          <h3>{label}</h3>
                        </Col>
                        <Col xs={24} sm={24} md={6}>
                          <NumericInput
                            label={i === 0 ? 'Variable A' : ''}
                            name={[i, 'variable_a']}
                            decimal
                            allowZero
                            onChange={() => {
                              const values = {
                                ...formMetas.getFieldsValue(),
                              };
                              if (values?.formula_de_calculo) {
                                const metasKeys = Object.keys(values).filter((e) => /\d+/.test(e));
                                const _metas = [];
                                metasKeys.forEach((e) => {
                                  _metas.push(values[e]);
                                  delete values[e];
                                });
                                // eslint-disable-next-line no-plusplus
                                for (let j = 0; j < _metas.length; j++) {
                                  if (_metas[j]?.variable_a && _metas[j]?.variable_b) {
                                    _metas[j].resultado = calculate(
                                      values.formula_de_calculo,
                                      _metas[j].variable_a,
                                      _metas[j].variable_b,
                                    ).toFixed(2);
                                  }
                                }
                                setTimeout(() => {
                                  formMetas.setFieldsValue({
                                    ..._metas,
                                  });
                                });
                              }
                            }}
                          />
                        </Col>
                        <Col xs={24} sm={24} md={6}>
                          <NumericInput
                            label={i === 0 ? 'Variable B' : ''}
                            name={[i, 'variable_b']}
                            decimal
                            allowZero
                            onChange={() => {
                              const values = {
                                ...formMetas.getFieldsValue(),
                              };
                              if (values?.formula_de_calculo) {
                                const metasKeys = Object.keys(values).filter((e) => /\d+/.test(e));
                                const _metas = [];
                                metasKeys.forEach((e) => {
                                  _metas.push(values[e]);
                                  delete values[e];
                                });
                                // eslint-disable-next-line no-plusplus
                                for (let j = 0; j < _metas.length; j++) {
                                  if (_metas[j]?.variable_a && _metas[j]?.variable_b) {
                                    _metas[j].resultado = calculate(
                                      values.formula_de_calculo,
                                      _metas[j].variable_a,
                                      _metas[j].variable_b,
                                    ).toFixed(2);
                                  }
                                }
                                setTimeout(() => {
                                  formMetas.setFieldsValue({
                                    ..._metas,
                                  });
                                });
                              }
                            }}
                          />
                        </Col>
                        <Col xs={24} sm={24} md={6}>
                          <NumericInput
                            label={i === 0 ? 'Resultado' : ''}
                            name={[i, 'resultado']}
                            prefix={mostrarPorcentaje === 2 ? '%' : ' '}
                            decimal
                            allowZero
                            allowNegative
                            disabled
                          />
                        </Col>
                        <Form.Item
                          name={[i, 'id']}
                          hidden
                        >
                          <Input allowClear />
                        </Form.Item>
                      </Row>
                    ))}
                </Form>
              </TabPane>
              <TabPane
                key="semaforizacion"
                tab="Semaforización"
                forceRender
              >
                <Form
                  layout="vertical"
                  form={formSemaforizacion}
                  onFinish={onFinish}
                  scrollToFirstError
                >
                  <Typography.Title level={4}>Rango de Evaluación</Typography.Title>
                  <Row gutter={10}>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        label=" "
                      >
                        <Button
                          type="primary"
                          style={{
                            background: 'rgba(13, 255, 43, 0.5)',
                            borderColor: 'transparent',
                            width: 300,
                          }}
                          disabled
                        >
                          Resultados Aceptables
                        </Button>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        required
                        label="De"
                        name="resultados_aceptables_minimo"
                      >
                        <Input
                          allowClear
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        required
                        label="Hasta"
                        name="resultados_aceptables_maximo"
                      >
                        <Input
                          allowClear
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={10}>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        label=" "
                      >
                        <Button
                          type="primary"
                          style={{
                            background: 'rgba(255, 228, 0, 0.5)',
                            borderColor: 'transparent',
                            width: 300,
                          }}
                          disabled

                        >
                          Resultados por debajo de lo aceptable
                        </Button>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        required
                        label="De"
                        name="resultados_por_debajo_aceptable_minimo"
                      >
                        <Input
                          allowClear
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        required
                        label="Hasta"
                        name="resultados_por_debajo_aceptable_maximo"
                      >
                        <Input
                          allowClear
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={10}>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        label=" "
                      >
                        <Button
                          type="primary"
                          style={{
                            background: 'rgba(255, 0, 0, 0.3)',
                            borderColor: 'transparent',
                            width: 300,
                          }}
                          disabled
                        >
                          Resultados Inaceptables o inexistentes
                        </Button>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        required
                        label="De"
                        name="resultados_inaceptables_o_inexistentes"
                      >
                        <Input
                          allowClear
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        required
                        label="Hasta"
                        name="resultados_inaceptables_o_inexistentes_maximo"
                      >
                        <Input
                          allowClear
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </TabPane>
            </Tabs>
          </Card>
        )}
      </Spin>
      <Modal
        visible={visibleResponsable}
        title={(
          <FormSubmitControls
            label="Responsable del indicador"
            onCancel={onCancelResponsable}
          />
          )}
        closable={false}
        maskClosable={!loading}
        keyboard={!loading}
        onCancel={onCancelResponsable}
        footer={null}
        forceRender
      >
        <Form
          layout="vertical"
          form={formResponsable}
          scrollToFirstError
        >
          <Col span={24}>
            <Form.Item
              label="Nombre"
              name="nombre_y_apellido"
            >
              <Input
                disabled
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Puesto"
              name="puesto"
            >
              <Select
                dataSource={puestos}
                labelProp="descripcion"
                disabled
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Correo"
              name="correo_electronico"
            >
              <Input
                disabled
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Teléfono"
              name="numero_de_telefono"
            >
              <Input
                disabled
              />
            </Form.Item>
          </Col>
        </Form>
      </Modal>
      <ModalDelete
        onDelete={deleteItem}
        onCancel={() => {
          setVisibleAlert(false);
          setSelectedRowKeys([]);
        }}
        visible={visibleAlert}
        content="Eliminar la Matriz seleccionada"
      />
    </Row>
  );
}

FichasTecnicasDelIndicador.propTypes = {
  selectedRowKey: PropTypes.number,
  matrizId: PropTypes.number,
  medidaDelIndicador: PropTypes.arrayOf(PropTypes.object).isRequired,
  // puestos: PropTypes.arrayOf(PropTypes.object).isRequired,
  tipoDeIndicador: PropTypes.arrayOf(PropTypes.object).isRequired,
  dimensionDeIndicador: PropTypes.arrayOf(PropTypes.object).isRequired,
  frecuenciaDeMedicion: PropTypes.arrayOf(PropTypes.object).isRequired,
  comportamiento: PropTypes.arrayOf(PropTypes.object).isRequired,
  tipoDeMeta: PropTypes.arrayOf(PropTypes.object).isRequired,
  calendarizacion: PropTypes.arrayOf(PropTypes.object).isRequired,
  factibilidadMeta: PropTypes.arrayOf(PropTypes.object).isRequired,
  periodosFiscales: PropTypes.arrayOf(PropTypes.object).isRequired,
  nivelesDeIndicador: PropTypes.arrayOf(PropTypes.object).isRequired,
  matrices: PropTypes.arrayOf(PropTypes.object),
};

FichasTecnicasDelIndicador.defaultProps = {
  selectedRowKey: 0,
  matrizId: 0,
  matrices: [],
};

export default FichasTecnicasDelIndicador;
