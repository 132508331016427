export default [
  {
    documento: 'Corte de Caja',
    folio_ingresos: 'IN-2021-01-0001',
    no_corte_caja: 'CC-2021-01-0001',
    concepto_operacion: 'Ingresos cobrados',
    descripcion: 'Corte de la caja No. 45 Turno 1',
    banco: 'Bancomer',
    cuenta_bancaria: '82930471655',
    subtotal: '321,098.00',
    iva: '-',
    total: '321,098.00',
    folio_presupuestal: 'FPI-2021-0001',
    polizas_contables: 'PI-2021-01-0001,  PD-2021-01-0010',
    id: 1,
    estados_globales: 4,
    data_table: [
      {
        clave: 'IP-0001',
        concepto_ingreso: 'Predial urbano',
        clasificador_rubro_de_ingresos: '7100 - Impuesto Predial',
        unidad_responsable: '1001  Recaudación',
        fuente_financiamiento: '141- Ingresos propios',
        Subtotal: 83029,
        IVA: '-',
        total: 83029,
        id: 1,
      },
      {
        clave: 'IP-0002',
        concepto_ingreso: 'Predial suburbano',
        clasificador_rubro_de_ingresos: '7100 - Impuesto Predial',
        unidad_responsable: '1001  Recaudación',
        fuente_financiamiento: '141- Ingresos propios',
        Subtotal: 34921,
        IVA: '-',
        total: 34921,
        id: 2,
      },
      {
        clave: 'IP-0003',
        concepto_ingreso: 'Predial rústico',
        clasificador_rubro_de_ingresos: '7100 - Impuesto Predial',
        unidad_responsable: '1001  Recaudación',
        fuente_financiamiento: '141- Ingresos propios',
        Subtotal: 18219,
        IVA: '-',
        total: 18219,
        id: 3,
      },
      {
        clave: 'OI-0001',
        concepto_ingreso: 'Multas',
        clasificador_rubro_de_ingresos: '6200 - Aprovechamientos varios',
        unidad_responsable: '1001  Recaudación',
        fuente_financiamiento: '141- Ingresos propios',
        Subtotal: 6213,
        IVA: '-',
        total: 6213,
        id: 4,
      },
      {
        clave: 'OI-0002',
        concepto_ingreso: 'Recargos',
        clasificador_rubro_de_ingresos: '6200 - Aprovechamientos varios',
        unidad_responsable: '1001  Recaudación',
        fuente_financiamiento: '141- Ingresos propios',
        Subtotal: 3928,
        IVA: '-',
        total: 3928,
        id: 5,
      },
      {
        clave: 'OI-0003',
        concepto_ingreso: 'Actualización',
        clasificador_rubro_de_ingresos: '6200- Aprovechamientos varios',
        unidad_responsable: '1001  Recaudación',
        fuente_financiamiento: '141- Ingresos propios',
        Subtotal: 1821,
        IVA: '-',
        total: 1821,
        id: 6,
      },
      {
        clave: 'DES-0001',
        concepto_ingreso: 'Descuentos',
        clasificador_rubro_de_ingresos: '',
        unidad_responsable: '',
        fuente_financiamiento: '',
        Subtotal: '',
        IVA: '',
        total: '',
        id: 7,
      },
      {
        clave: 'ANT-001',
        concepto_ingreso: 'Anticipos',
        clasificador_rubro_de_ingresos: '',
        unidad_responsable: '',
        fuente_financiamiento: '',
        Subtotal: '',
        IVA: '',
        total: 8509,
        id: 8,
      },
    ],
  },
  {
    documento: 'Depósito Directo',
    folio_ingresos: 'IN-2021-01-0002',
    no_corte_caja: '',
    concepto_operacion: 'Ingresos por convenio',
    descripcion: 'Convenio No. 2021-FIN-AEC-0064',
    banco: 'Bancomer',
    cuenta_bancaria: '82930471655',
    subtotal: '4,521,630.00',
    iva: '-',
    total: '4,521,630.00',
    folio_presupuestal: 'FPI-2021-0002',
    polizas_contables: 'PI-2021-01-0002,  PD-2021-01-0023',
    id: 2,
    estados_globales: 4,
    data_table: [
      {
        clave: 'IP-0001',
        concepto_ingreso: 'Predial urbano',
        clasificador_rubro_de_ingresos: '7100 - Impuesto Predial',
        unidad_responsable: '1001  Recaudación',
        fuente_financiamiento: '141- Ingresos propios',
        Subtotal: 83029,
        IVA: '-',
        total: 83029,
        id: 1,
      },
      {
        clave: 'IP-0002',
        concepto_ingreso: 'Predial suburbano',
        clasificador_rubro_de_ingresos: '7100 - Impuesto Predial',
        unidad_responsable: '1001  Recaudación',
        fuente_financiamiento: '141- Ingresos propios',
        Subtotal: 34921,
        IVA: '-',
        total: 34921,
        id: 2,
      },
      {
        clave: 'IP-0003',
        concepto_ingreso: 'Predial rústico',
        clasificador_rubro_de_ingresos: '7100 - Impuesto Predial',
        unidad_responsable: '1001  Recaudación',
        fuente_financiamiento: '141- Ingresos propios',
        Subtotal: 18219,
        IVA: '-',
        total: 18219,
        id: 3,
      },
      {
        clave: 'OI-0001',
        concepto_ingreso: 'Multas',
        clasificador_rubro_de_ingresos: '6200 - Aprovechamientos varios',
        unidad_responsable: '1001  Recaudación',
        fuente_financiamiento: '141- Ingresos propios',
        Subtotal: 6213,
        IVA: '-',
        total: 6213,
        id: 4,
      },
      {
        clave: 'OI-0002',
        concepto_ingreso: 'Recargos',
        clasificador_rubro_de_ingresos: '6200 - Aprovechamientos varios',
        unidad_responsable: '1001  Recaudación',
        fuente_financiamiento: '141- Ingresos propios',
        Subtotal: 3928,
        IVA: '-',
        total: 3928,
        id: 5,
      },
      {
        clave: 'OI-0003',
        concepto_ingreso: 'Actualización',
        clasificador_rubro_de_ingresos: '6200- Aprovechamientos varios',
        unidad_responsable: '1001  Recaudación',
        fuente_financiamiento: '141- Ingresos propios',
        Subtotal: 1821,
        IVA: '-',
        total: 1821,
        id: 6,
      },
      {
        clave: 'DES-0001',
        concepto_ingreso: 'Descuentos',
        clasificador_rubro_de_ingresos: '',
        unidad_responsable: '',
        fuente_financiamiento: '',
        Subtotal: '',
        IVA: '',
        total: '',
        id: 7,
      },
      {
        clave: 'ANT-001',
        concepto_ingreso: 'Anticipos',
        clasificador_rubro_de_ingresos: '',
        unidad_responsable: '',
        fuente_financiamiento: '',
        Subtotal: '',
        IVA: '',
        total: 8509,
        id: 8,
      },
    ],
  },
  {
    documento: 'Corte de Caja',
    folio_ingresos: 'IN-2021-01-0003',
    no_corte_caja: 'CC-2021-01-0001',
    concepto_operacion: 'Ingresos cobrados',
    descripcion: 'Corte de la caja No. 45 Turno 1',
    banco: 'Bancomer',
    cuenta_bancaria: '82930471655',
    subtotal: '465,321.00',
    iva: '-',
    total: '465,321.00',
    folio_presupuestal: 'FPI-2021-0003',
    polizas_contables: 'PI-2021-01-0003,  PD-2021-01-0048',
    id: 3,
    estados_globales: 4,
    data_table: [
      {
        clave: 'IP-0001',
        concepto_ingreso: 'Predial urbano',
        clasificador_rubro_de_ingresos: '7100 - Impuesto Predial',
        unidad_responsable: '1001  Recaudación',
        fuente_financiamiento: '141- Ingresos propios',
        Subtotal: 83029,
        IVA: '-',
        total: 83029,
        id: 1,
      },
      {
        clave: 'IP-0002',
        concepto_ingreso: 'Predial suburbano',
        clasificador_rubro_de_ingresos: '7100 - Impuesto Predial',
        unidad_responsable: '1001  Recaudación',
        fuente_financiamiento: '141- Ingresos propios',
        Subtotal: 34921,
        IVA: '-',
        total: 34921,
        id: 2,
      },
      {
        clave: 'IP-0003',
        concepto_ingreso: 'Predial rústico',
        clasificador_rubro_de_ingresos: '7100 - Impuesto Predial',
        unidad_responsable: '1001  Recaudación',
        fuente_financiamiento: '141- Ingresos propios',
        Subtotal: 18219,
        IVA: '-',
        total: 18219,
        id: 3,
      },
      {
        clave: 'OI-0001',
        concepto_ingreso: 'Multas',
        clasificador_rubro_de_ingresos: '6200 - Aprovechamientos varios',
        unidad_responsable: '1001  Recaudación',
        fuente_financiamiento: '141- Ingresos propios',
        Subtotal: 6213,
        IVA: '-',
        total: 6213,
        id: 4,
      },
      {
        clave: 'OI-0002',
        concepto_ingreso: 'Recargos',
        clasificador_rubro_de_ingresos: '6200 - Aprovechamientos varios',
        unidad_responsable: '1001  Recaudación',
        fuente_financiamiento: '141- Ingresos propios',
        Subtotal: 3928,
        IVA: '-',
        total: 3928,
        id: 5,
      },
      {
        clave: 'OI-0003',
        concepto_ingreso: 'Actualización',
        clasificador_rubro_de_ingresos: '6200- Aprovechamientos varios',
        unidad_responsable: '1001  Recaudación',
        fuente_financiamiento: '141- Ingresos propios',
        Subtotal: 1821,
        IVA: '-',
        total: 1821,
        id: 6,
      },
      {
        clave: 'DES-0001',
        concepto_ingreso: 'Descuentos',
        clasificador_rubro_de_ingresos: '',
        unidad_responsable: '',
        fuente_financiamiento: '',
        Subtotal: '',
        IVA: '',
        total: '',
        id: 7,
      },
      {
        clave: 'ANT-001',
        concepto_ingreso: 'Anticipos',
        clasificador_rubro_de_ingresos: '',
        unidad_responsable: '',
        fuente_financiamiento: '',
        Subtotal: '',
        IVA: '',
        total: 8509,
        id: 8,
      },
    ],
  },
  {
    documento: 'Corte de Caja',
    folio_ingresos: 'IN-2021-01-0004',
    no_corte_caja: 'CC-2021-01-0001',
    concepto_operacion: 'Ingresos cobrados',
    descripcion: 'Corte de la caja No. 45 Turno 1',
    banco: 'Bancomer',
    cuenta_bancaria: '82930471655',
    subtotal: '630,912.00',
    iva: '-',
    total: '630,912.00',
    folio_presupuestal: 'FPI-2021-0004',
    polizas_contables: 'PI-2021-01-0004,  PD-2021-01-0095',
    id: 4,
    estados_globales: 4,
    data_table: [
      {
        clave: 'IP-0001',
        concepto_ingreso: 'Predial urbano',
        clasificador_rubro_de_ingresos: '7100 - Impuesto Predial',
        unidad_responsable: '1001  Recaudación',
        fuente_financiamiento: '141- Ingresos propios',
        Subtotal: 83029,
        IVA: '-',
        total: 83029,
        id: 1,
      },
      {
        clave: 'IP-0002',
        concepto_ingreso: 'Predial suburbano',
        clasificador_rubro_de_ingresos: '7100 - Impuesto Predial',
        unidad_responsable: '1001  Recaudación',
        fuente_financiamiento: '141- Ingresos propios',
        Subtotal: 34921,
        IVA: '-',
        total: 34921,
        id: 2,
      },
      {
        clave: 'IP-0003',
        concepto_ingreso: 'Predial rústico',
        clasificador_rubro_de_ingresos: '7100 - Impuesto Predial',
        unidad_responsable: '1001  Recaudación',
        fuente_financiamiento: '141- Ingresos propios',
        Subtotal: 18219,
        IVA: '-',
        total: 18219,
        id: 3,
      },
      {
        clave: 'OI-0001',
        concepto_ingreso: 'Multas',
        clasificador_rubro_de_ingresos: '6200 - Aprovechamientos varios',
        unidad_responsable: '1001  Recaudación',
        fuente_financiamiento: '141- Ingresos propios',
        Subtotal: 6213,
        IVA: '-',
        total: 6213,
        id: 4,
      },
      {
        clave: 'OI-0002',
        concepto_ingreso: 'Recargos',
        clasificador_rubro_de_ingresos: '6200 - Aprovechamientos varios',
        unidad_responsable: '1001  Recaudación',
        fuente_financiamiento: '141- Ingresos propios',
        Subtotal: 3928,
        IVA: '-',
        total: 3928,
        id: 5,
      },
      {
        clave: 'OI-0003',
        concepto_ingreso: 'Actualización',
        clasificador_rubro_de_ingresos: '6200- Aprovechamientos varios',
        unidad_responsable: '1001  Recaudación',
        fuente_financiamiento: '141- Ingresos propios',
        Subtotal: 1821,
        IVA: '-',
        total: 1821,
        id: 6,
      },
      {
        clave: 'DES-0001',
        concepto_ingreso: 'Descuentos',
        clasificador_rubro_de_ingresos: '',
        unidad_responsable: '',
        fuente_financiamiento: '',
        Subtotal: '',
        IVA: '',
        total: '',
        id: 7,
      },
      {
        clave: 'ANT-001',
        concepto_ingreso: 'Anticipos',
        clasificador_rubro_de_ingresos: '',
        unidad_responsable: '',
        fuente_financiamiento: '',
        Subtotal: '',
        IVA: '',
        total: 8509,
        id: 8,
      },
    ],
  },
  {
    documento: 'Corte de Caja',
    folio_ingresos: 'IN-2021-01-0005',
    no_corte_caja: 'CC-2021-01-0001',
    concepto_operacion: 'Ingresos cobrados',
    descripcion: 'Corte de la caja No. 45 Turno 1',
    banco: 'Bancomer',
    cuenta_bancaria: '82930471655',
    subtotal: '691,304.00',
    iva: '-',
    total: '691,304.00',
    folio_presupuestal: 'FPI-2021-0005',
    polizas_contables: 'PI-2021-01-0005,  PD-2021-01-0148',
    id: 5,
    estados_globales: 4,
    data_table: [
      {
        clave: 'IP-0001',
        concepto_ingreso: 'Predial urbano',
        clasificador_rubro_de_ingresos: '7100 - Impuesto Predial',
        unidad_responsable: '1001  Recaudación',
        fuente_financiamiento: '141- Ingresos propios',
        Subtotal: 83029,
        IVA: '-',
        total: 83029,
        id: 1,
      },
      {
        clave: 'IP-0002',
        concepto_ingreso: 'Predial suburbano',
        clasificador_rubro_de_ingresos: '7100 - Impuesto Predial',
        unidad_responsable: '1001  Recaudación',
        fuente_financiamiento: '141- Ingresos propios',
        Subtotal: 34921,
        IVA: '-',
        total: 34921,
        id: 2,
      },
      {
        clave: 'IP-0003',
        concepto_ingreso: 'Predial rústico',
        clasificador_rubro_de_ingresos: '7100 - Impuesto Predial',
        unidad_responsable: '1001  Recaudación',
        fuente_financiamiento: '141- Ingresos propios',
        Subtotal: 18219,
        IVA: '-',
        total: 18219,
        id: 3,
      },
      {
        clave: 'OI-0001',
        concepto_ingreso: 'Multas',
        clasificador_rubro_de_ingresos: '6200 - Aprovechamientos varios',
        unidad_responsable: '1001  Recaudación',
        fuente_financiamiento: '141- Ingresos propios',
        Subtotal: 6213,
        IVA: '-',
        total: 6213,
        id: 4,
      },
      {
        clave: 'OI-0002',
        concepto_ingreso: 'Recargos',
        clasificador_rubro_de_ingresos: '6200 - Aprovechamientos varios',
        unidad_responsable: '1001  Recaudación',
        fuente_financiamiento: '141- Ingresos propios',
        Subtotal: 3928,
        IVA: '-',
        total: 3928,
        id: 5,
      },
      {
        clave: 'OI-0003',
        concepto_ingreso: 'Actualización',
        clasificador_rubro_de_ingresos: '6200- Aprovechamientos varios',
        unidad_responsable: '1001  Recaudación',
        fuente_financiamiento: '141- Ingresos propios',
        Subtotal: 1821,
        IVA: '-',
        total: 1821,
        id: 6,
      },
      {
        clave: 'DES-0001',
        concepto_ingreso: 'Descuentos',
        clasificador_rubro_de_ingresos: '',
        unidad_responsable: '',
        fuente_financiamiento: '',
        Subtotal: '',
        IVA: '',
        total: '',
        id: 7,
      },
      {
        clave: 'ANT-001',
        concepto_ingreso: 'Anticipos',
        clasificador_rubro_de_ingresos: '',
        unidad_responsable: '',
        fuente_financiamiento: '',
        Subtotal: '',
        IVA: '',
        total: 8509,
        id: 8,
      },
    ],
  },
  {
    documento: 'Depósito Directo',
    folio_ingresos: 'IN-2021-01-0002',
    no_corte_caja: '',
    concepto_operacion: 'Aportaciones federales',
    descripcion: 'Aportación Federal No. 1',
    banco: 'Bancomer',
    cuenta_bancaria: '82930471655',
    subtotal: '10,834,293.00',
    iva: '-',
    total: '10,834,293.00',
    folio_presupuestal: 'FPI-2021-0002',
    polizas_contables: 'PI-2021-01-0006,  PD-2021-01-0231',
    id: 6,
    estados_globales: 4,
    data_table: [
      {
        clave: 'IP-0001',
        concepto_ingreso: 'Predial urbano',
        clasificador_rubro_de_ingresos: '7100 - Impuesto Predial',
        unidad_responsable: '1001  Recaudación',
        fuente_financiamiento: '141- Ingresos propios',
        Subtotal: 83029,
        IVA: '-',
        total: 83029,
        id: 1,
      },
      {
        clave: 'IP-0002',
        concepto_ingreso: 'Predial suburbano',
        clasificador_rubro_de_ingresos: '7100 - Impuesto Predial',
        unidad_responsable: '1001  Recaudación',
        fuente_financiamiento: '141- Ingresos propios',
        Subtotal: 34921,
        IVA: '-',
        total: 34921,
        id: 2,
      },
      {
        clave: 'IP-0003',
        concepto_ingreso: 'Predial rústico',
        clasificador_rubro_de_ingresos: '7100 - Impuesto Predial',
        unidad_responsable: '1001  Recaudación',
        fuente_financiamiento: '141- Ingresos propios',
        Subtotal: 18219,
        IVA: '-',
        total: 18219,
        id: 3,
      },
      {
        clave: 'OI-0001',
        concepto_ingreso: 'Multas',
        clasificador_rubro_de_ingresos: '6200 - Aprovechamientos varios',
        unidad_responsable: '1001  Recaudación',
        fuente_financiamiento: '141- Ingresos propios',
        Subtotal: 6213,
        IVA: '-',
        total: 6213,
        id: 4,
      },
      {
        clave: 'OI-0002',
        concepto_ingreso: 'Recargos',
        clasificador_rubro_de_ingresos: '6200 - Aprovechamientos varios',
        unidad_responsable: '1001  Recaudación',
        fuente_financiamiento: '141- Ingresos propios',
        Subtotal: 3928,
        IVA: '-',
        total: 3928,
        id: 5,
      },
      {
        clave: 'OI-0003',
        concepto_ingreso: 'Actualización',
        clasificador_rubro_de_ingresos: '6200- Aprovechamientos varios',
        unidad_responsable: '1001  Recaudación',
        fuente_financiamiento: '141- Ingresos propios',
        Subtotal: 1821,
        IVA: '-',
        total: 1821,
        id: 6,
      },
      {
        clave: 'DES-0001',
        concepto_ingreso: 'Descuentos',
        clasificador_rubro_de_ingresos: '',
        unidad_responsable: '',
        fuente_financiamiento: '',
        Subtotal: '',
        IVA: '',
        total: '',
        id: 7,
      },
      {
        clave: 'ANT-001',
        concepto_ingreso: 'Anticipos',
        clasificador_rubro_de_ingresos: '',
        unidad_responsable: '',
        fuente_financiamiento: '',
        Subtotal: '',
        IVA: '',
        total: 8509,
        id: 8,
      },
    ],
  },
];
