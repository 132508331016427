import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Form,
  Row,
  Spin,
  Col,
  Input,
  Modal,
} from 'antd';

import API from 'utils/api';
import { onError, onSuccess, onInfo } from 'utils/handlers';
import Table from 'components/Table';
import Select from 'components/Select';
import FormSubmitControls from 'components/FormSubmitControls';
import Rutas from '../../../../utils/rutas-endpoints';

function CatalogoTipoInversion() {
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataTiposInversion, setDataTiposInversion] = useState([]);
  const [dataEstadosGlobales, setDataEstadosGlobales] = useState([]);
  const [visibleModalAgregar, setVisibleModalAgregar] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  let defaultValueEstado = 1;

  const user = useSelector(({ auth }) => auth.user);

  const requiredRule = {
    required: true,
    message: 'El campo es requerido',
  };
  const rules = {
    required: [requiredRule],
  };
  const fetchData = async () => {
    try {
      setLoading(true);
      setDataEstadosGlobales([]);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchData();
    return () => API.tryCancel;
  }, []);

  const consultarGridTiposInversion = async () => {
    const res = await API.get(Rutas.catalogoTiposInversion);
    const catalogoTiposInversion = res.data;
    const gridCatalogoTiposInversion = [];

    for (let i = 0; i < catalogoTiposInversion.length; i += 1) {
      gridCatalogoTiposInversion[i] = {
        id: catalogoTiposInversion[i].id,
        concepto: catalogoTiposInversion[i].concepto,
        estados_globales: catalogoTiposInversion[i].estado,
      };
    }

    setDataTiposInversion(gridCatalogoTiposInversion);
  };

  const consultarEstadosGlobales = async () => {
    const res = await API.get(Rutas.estadosGlobales);
    const estadosGlobales = res.data.filter((e) => e.descripcion === 'Activo' || e.descripcion === 'Inactivo');

    const defaultValue = estadosGlobales.filter((e) => e.descripcion === 'Activo');
    if (defaultValue) {
      defaultValueEstado = defaultValue[0].id;
    }

    setDataEstadosGlobales(estadosGlobales);
  };

  useEffect(() => {
    consultarEstadosGlobales();
  }, [dataEstadosGlobales.length]);

  useEffect(() => {
    consultarGridTiposInversion();
  }, [dataTiposInversion.length]);

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    setTimeout(() => {
      const estado = dataEstadosGlobales
        .find((e) => e.descripcion === record.estados_globales);

      const value = {
        id: record.id,
        concepto: record.concepto,
        estados_globales: estado.id,
      };
      form.setFieldsValue(value);
    });
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onCancel = () => {
    setVisibleModalAgregar(false);
    form.resetFields();
    setSelectedRowKeys([]);
  };

  const onFinish = async () => {
    const values = form.getFieldsValue();
    values.usuario_que_elaboro = user.id;
    try {
      await form.validateFields();

      if (!selectedRowKeys.length) {
        const response = await API.post(Rutas.catalogoTiposInversion, values);

        if (response?.status === 201) {
          onSuccess(response, 'Agregado Correctamente.');
          onCancel();
          consultarGridTiposInversion();
        }
      } else {
        const [key] = selectedRowKeys;
        const response = await API.put(
          `${Rutas.catalogoTiposInversion}${key}/`,
          values,
        );

        if (response.status === 200) {
          onSuccess(response, 'Actualizado Correctamente.');
          onCancel();
          consultarGridTiposInversion();
        }
      }
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onClickAdd = () => {
    setIsAdding(true);
    form.resetFields();
    setSelectedRowKeys([]);
    setVisibleModalAgregar(true);
  };

  const onClickEdit = () => {
    setIsAdding(false);
    setVisibleModalAgregar(true);
  };

  const onClickDelete = async () => {
    try {
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.patch(`${Rutas.catalogoTiposInversion}${key}/eliminar/`);

        if (response?.status === 200) {
          onSuccess(response, 'Eliminado Correctamente.');
          onCancel();
          consultarGridTiposInversion();
        }
        if (response?.status === 400) {
          onInfo(response.message);
        }
      }
    } catch (error) {
      onError(error, setLoading);
    }
  };

  const columns = [
    {
      titleText: 'Concepto',
      dataIndex: 'concepto',
      key: 'concepto',
      width: 200,
    },
    {
      titleText: 'Estado',
      dataIndex: 'estados_globales',
      key: 'estados_globales',
      width: 200,
    },
  ];

  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        <Table
          cols={columns}
          data={dataTiposInversion}
          rowSelection={rowSelection}
          handleOnRowClick={handleOnRowClick}
          mobileColIndex={0}
          controls={{
            onClickAdd,
            onClickEdit,
            onClickDelete,
          }}
        />
        <Modal
          visible={visibleModalAgregar}
          closable={false}
          maskClosable={!loading}
          keyboard={!loading}
          footer={null}
          title={(
            <FormSubmitControls
              label={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Tipo de Inversión`}
              onFinish={onFinish}
              onCancel={onCancel}
              loading={loading}
            />
        )}
          onCancel={onCancel}
          forceRender
        >
          <Form
            form={form}
            layout="vertical"
            name="tipos_inversion"
            onFinish={onFinish}
            initialValues={{ estados_globales: defaultValueEstado }}
          >
            <Col span={24}>
              <Form.Item
                name="concepto"
                label="Concepto"
                rules={rules.required}
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="estados_globales"
                label="Estado"
              >
                <Select
                  dataSource={dataEstadosGlobales}
                  disabled={isAdding}
                />
              </Form.Item>
            </Col>
          </Form>
        </Modal>
      </Spin>
    </Row>
  );
}

export default CatalogoTipoInversion;
