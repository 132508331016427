import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Row,
  Col,
  Spin,
  // Card,
  Form,
  Button,
  Input,
  Modal,
  // message,
  // Input,
} from 'antd';
import FormSubmitControls from 'components/FormSubmitControls';
import Table from 'components/Table';
import ModalDelete from 'components/ModalDelete';
import API from 'utils/api';
// import fetchSome from 'utils/fetchSome';
import { onError, onSuccess } from 'utils/handlers';
// import getFormattedValues from 'utils/formatValues';
import Select from 'components/Select';
import { isMutable } from 'utils/estadosGlobales';
import Uploader from 'components/Uploader';

const baseURI = 'controlpatrimonial/expedientes-de-activos-fijos/';

function ExpedientesDeActivos({ activo }) {
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const [tiposDoc, setTiposDoc] = useState([]);

  const [mutable, setMutable] = useState(true);

  const fetchData = async () => {
    try {
      setLoading(true);
      if (activo.length) {
        const res = await API.get(baseURI, {
          params: {
            activo_fijo: activo[0],
          },
        });
        setData(res.data);
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const fetchAll = async () => {
    try {
      setLoading(true);
      const resTipos = await API.get('/controlpatrimonial/tipos-de-documentos-de-activos-fijos/');
      setTiposDoc(resTipos.data);

      await fetchData();
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchAll();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setSelectedRowKeys([]);
    form.resetFields();
    setVisible(false);
    setMutable(true);
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      const [activo_fijo] = activo;
      values.activo_fijo = activo_fijo;
      if (!selectedRowKeys.length) {
        const res = await API.post(baseURI, values);
        if (res?.status === 201) {
          onSuccess(res, 'Agregado correctamente');
          onCancel();
          await fetchData();
        }
      } else {
        const [key] = selectedRowKeys;
        const res = await API.put(`${baseURI}${key}/`, values);
        if (res?.status === 200) {
          onSuccess(res, 'Actualizado correctamente');
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`${baseURI}${key}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          setVisibleAlert(false);
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    form.setFieldsValue(record);
    setMutable(isMutable(record));
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const columns = [
    {
      titleText: 'Descripción',
      dataIndex: 'descripcion',
      key: 'descripcion',
      width: 150,
    },
    {
      titleText: 'Estado',
      dataIndex: 'estados_globales',
      key: 'estados_globales',
      width: 150,
      render: (val) => (estadosGlobales.find((e) => e.id === val)?.descripcion),
    },
  ];

  const requiredRule = {
    required: true,
    message: 'El campo es requerido',
  };

  const rules = {
    required: [requiredRule],
  };

  const onClickEdit = () => {
    setVisible(true);
  };

  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        <Table
          cols={columns}
          data={data}
          rowSelection={rowSelection}
          handleOnRowClick={handleOnRowClick}
          loading={loading}
          controls={{
            onClickAdd,
            onClickEdit,
            onClickDelete,
          }}
          mobileColIndex={0}
        />
        <Modal
          visible={visible}
          title={(
            <FormSubmitControls
              label={`${!selectedRowKeys.length ? 'Agregar' : 'Editar'} Identificador`}
              onFinish={onFinish}
              onCancel={onCancel}
              mutable={mutable}
            />
          )}
          onCancel={onCancel}
          footer={null}
          closable={false}
          maskClosable={!loading}
          keyboard={!loading}
          forceRender
        >
          <Form
            name="general"
            form={form}
            layout="vertical"
            onFinish={onFinish}
            scrollToFirstError
            initialValues={{ estados_globales: 1 }}
          >
            <Row gutter={10}>
              <Col span={24}>
                <Form.Item
                  label="Descripción"
                  rules={rules.required}
                  name="descripcion"
                >
                  <Input.TextArea
                    autoSize={{ minRows: 4, maxRows: 4 }}
                    allowClear
                    disabled={!mutable}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Tipo de Documento"
                  rules={rules.required}
                  name="tipo_de_documento"
                >
                  <Select
                    dataSource={tiposDoc}
                    disabled={!mutable}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Estado"
                  rules={rules.required}
                  name="estados_globales"
                >
                  <Select
                    dataSource={estadosGlobales}
                    disabled={!selectedRowKeys.length || !mutable}
                  />
                </Form.Item>
              </Col>
              <Uploader
                title="Documento"
                limitMB={50}
                file={form.getFieldValue('documento')}
                formItemName="documento"
                allowedExtensions={['pdf']}
                rules={rules.required}
              />
              <Form.Item hidden>
                <Button htmlType="submit" />
              </Form.Item>
            </Row>
          </Form>
        </Modal>
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => setVisibleAlert(false)}
          visible={visibleAlert}
          content="El activo seleccionado"
          loading={loading}
        />
      </Spin>
    </Row>
  );
}

ExpedientesDeActivos.propTypes = {
  activo: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default ExpedientesDeActivos;
