import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Spin,
  Modal,
  TreeSelect,
} from 'antd';
import { useSelector } from 'react-redux';
import Table from 'components/Table';
import FormSubmitControls from 'components/FormSubmitControls';
import ModalDelete from 'components/ModalDelete';
import NumericInput from 'components/NumericInput';
import Select from 'components/Select';
import createKey from 'utils/keyGenerator';
import { findItemNested } from 'utils/filters';
import { integerRegExp } from 'utils/patterns';
import API from 'utils/api';
import { onError, onSuccess } from 'utils/handlers';
import makeThree from 'utils/makeTree';
import { isMutable } from 'utils/estadosGlobales';

export const permissionFuenteDeFinanciamiento = {
  permissionModel: 'clasificaciondefuentedefinanciamientointerna',
};

const baseURI = 'contabilidad/clasificaciones-de-fuentes-de-financiamiento-interna/';

// eslint-disable-next-line react/prop-types
function FuenteDeFinanciamiento({ permission }) {
  const [form] = Form.useForm();
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);
  const [fuentesDeFinanciamiento, setFuentesDeFinanciamiento] = useState([]);
  const [mutable, setMutable] = useState(true);

  const [selected, setSelected] = useState();

  const onClickTitle = (e) => {
    const element = e.target;
    if (element) {
      let parent = element.parentElement;
      parent = parent ? parent.parentElement : null;
      if (parent) {
        const clickableSpan = parent.previousSibling;
        if (clickableSpan) {
          clickableSpan.click();
        }
      }
    }
  };

  const renderTitle = (title, bold) => (
    // eslint-disable-next-line
    <span onClick={onClickTitle} className={bold ? 'bold' : ''}>
      {title}
    </span>
  );

  const normalize = (_data = []) => _data.map((item) => {
    const clone = { ...item };
    const children = clone.children?.length && !clone.seleccionable
      ? normalize(clone.children) : [];
    if (!children.length) {
      delete clone.children;
      return ({
        ...clone,
        key: clone.clave,
        clave: clone.clave,
        seleccionable: !clone.seleccionable,
        disabled: clone.seleccionable,
        title: renderTitle(`${clone.clave} - ${clone.nombre}`, !clone.seleccionable),
      });
    }
    return ({
      ...clone,
      key: `${clone.clave}_padre`,
      children,
      disabled: !clone.seleccionable,
      seleccionable: clone.seleccionable,
      title: renderTitle(`${clone.clave} - ${clone.nombre}`, clone.seleccionable),
    });
  });

  const formatDataTree = (
    _data = [],
    excludeLvl3 = false,
    currLvl = 1,
  ) => _data.map((item) => {
    const clone = { ...item };
    const children = formatDataTree(!excludeLvl3
      || currLvl !== 2 ? clone.children : [], excludeLvl3, currLvl + 1);
    if (!children.length) {
      delete clone.children;
      return ({
        ...clone,
        key: clone.id,
        title: renderTitle(`${clone.clave} - ${clone.nombre}`, clone.seleccionable),
        disabled: !clone.seleccionable || currLvl === 3,
        value: clone.id,
      });
    }
    return ({
      ...clone,
      key: clone.id,
      children,
      title: renderTitle(`${clone.clave} - ${clone.nombre}`, !clone.seleccionable),
      disabled: currLvl === 1,
      value: clone.id,
    });
  });

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await API.get('contabilidad/clasificaciones-de-fuentes-de-financiamiento/');
      const fuentesFTree = makeThree(response.data.sort((a, b) => a.id - b.id));
      setData(normalize(fuentesFTree));
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    const fetchAll = async () => {
      try {
        setLoading(true);
        const resFF = await API.get('catalogos/clasificaciones-de-fuentes-de-financiamiento/');
        const fuentesFTree = makeThree(resFF.data.map((e) => (!e.seleccionable ? ({
          ...e,
          id: `${e.id}`,
          clave_padre_id: e.clave_padre,
        }) : e)));
        const normalized = normalize(fuentesFTree);
        setFuentesDeFinanciamiento(formatDataTree(normalized));
        await fetchData();
        setLoading(false);
      } catch (err) {
        onError(err, setLoading);
      }
    };
    fetchAll();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setVisible(false);
    setSelected();
    form.resetFields();
    setSelectedRowKeys([]);
    setMutable(true);
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      values.seleccionable = true;
      if (!selectedRowKeys.length) {
        const response = await API.post(baseURI, values);
        if (response?.status === 201) {
          onSuccess(response, 'Agregado correctamente');
          onCancel();
          await fetchData();
        }
      } else {
        setLoading(true);
        await form.validateFields();
        const [key] = selectedRowKeys;
        const match = findItemNested(fuentesDeFinanciamiento, key, 'clave');
        const response = await API.put(`${baseURI}${match?.id}/`, values);
        if (response?.status === 200) {
          onSuccess(response, 'Actualizado correctamente');
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const match = findItemNested(fuentesDeFinanciamiento, key, 'clave');
        const response = await API.delete(`${baseURI}${match?.id}/`);
        if (response?.status === 204) {
          onSuccess(response, 'Eliminado correctamente');
          setVisibleAlert(false);
          onCancel();
          await fetchData();
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const handleOnRowClick = (record) => {
    setSelected(record);
    setMutable(isMutable(record));
    const matchParent = findItemNested(fuentesDeFinanciamiento, `${record.clave_padre_id}`);
    setSelectedRowKeys([record.key]);
    form.setFieldsValue({
      ...record,
      clave_padre: matchParent?.id,
    });
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const onClickEdit = () => {
    setVisible(true);
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const columns = [
    {
      titleText: 'Clave',
      dataIndex: 'clave',
      key: 'clave',
    },
    {
      titleText: 'Nombre',
      dataIndex: 'nombre',
      key: 'nombre',
    },
    {
      titleText: 'Descripción',
      dataIndex: 'descripcion',
      key: 'descripcion',
    },
  ];

  const requiredRule = {
    required: true,
    message: 'El campo es requerido',
  };

  const rules = {
    required: [requiredRule],
    clave: [
      requiredRule,
      {
        validator: async (rule, val) => {
          const value = val?.toString() || '';
          const parent = findItemNested(fuentesDeFinanciamiento, form.getFieldValue('clave_padre'));
          if (value) {
            if (!integerRegExp.test(value)) {
              throw new Error('Ingrese un número entero válido');
            } else if (value.indexOf(parent?.clave) !== 0) {
              throw new Error('El campo debe incluir al inicio la clave de padre');
            } else if (value.length === parent?.clave?.length) {
              throw new Error('La longitud de la clave debe ser mayor a la clave de padre');
            }
          }
        },
      },
    ],
  };

  const onChange = async (val) => {
    if (val) {
      const parent = findItemNested(fuentesDeFinanciamiento, val);
      createKey(data, form, { filter: { prop: 'clave_padre', parent } });
    } else {
      form.resetFields(['clave']);
    }
  };

  const onClickExpand = () => {
    setVisible(true);
  };

  const getControls = () => {
    if (selected?.seleccionable) {
      return {
        onClickAdd,
        onClickEdit,
        onClickDelete,
      };
    }
    return {
      onClickAdd,
      onClickExpand,
    };
  };

  return (
    <Row className="container">
      <Spin tip="Cargando..." spinning={loading}>
        <Table
          cols={columns}
          data={data}
          permission={permission}
          rowSelection={rowSelection}
          handleOnRowClick={handleOnRowClick}
          controls={getControls()}
          allowEdit={selected?.seleccionable}
          allowDelete={selected?.seleccionable}
          rowKey="key"
          childrenProp="children"
          filterNested
          allowExpand
          allowImport
          baseURI={baseURI}
          allowedExtensions={['xlsx', 'csv', 'json']}
        />
        <Modal
          visible={visible}
          title={(
            <FormSubmitControls
              label={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Fuente de Financiamiento`}
              onFinish={onFinish}
              onCancel={onCancel}
              loading={loading}
            />
          )}
          width={600}
          onCancel={onCancel}
          footer={null}
          closable={false}
          maskClosable={!loading}
          keyboard={!loading}
          forceRender
        >
          <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
            scrollToFirstError
            initialValues={{ estados_globales: 1 }}
          >
            {(selected?.seleccionable || selected?.clave_padre_id || !selectedRowKeys.length) && (
              <Col span={24}>
                <Form.Item
                  name="clave_padre"
                  label="Clave Padre"
                  rules={rules.required}
                >
                  <TreeSelect
                    onChange={onChange}
                    showSearch
                    treeNodeFilterProp="title"
                    style={{ width: '100%' }}
                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                    treeData={fuentesDeFinanciamiento}
                    allowClear
                    disabled={!mutable}
                  />
                </Form.Item>
              </Col>
            )}
            <Col span={24}>
              <NumericInput
                label="Clave"
                name="clave"
                rules={rules.clave}
                required
                disabled={!!selectedRowKeys.length || !mutable}
              />
            </Col>
            <Col span={24}>
              <Form.Item
                name="nombre"
                rules={rules.required}
                label="Nombre"
                hasFeedback
              >
                <Input allowClear disabled={!mutable} />
              </Form.Item>
            </Col>
            {(selected?.descripcion || !selectedRowKeys.length) && (
              <Col span={24}>
                <Form.Item
                  name="descripcion"
                  rules={rules.required}
                  label="Descripción"
                  hasFeedback
                >
                  <Input allowClear disabled={!mutable} />
                </Form.Item>
              </Col>
            )}
            <Col span={24}>
              <Form.Item
                name="estados_globales"
                label="Estado"
                rules={rules.required}
              >
                <Select
                  disabled={!selectedRowKeys.length || !mutable}
                  dataSource={estadosGlobales}
                />
              </Form.Item>
            </Col>
            <Form.Item hidden>
              <Button htmlType="submit" />
            </Form.Item>
          </Form>
        </Modal>
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => setVisibleAlert(false)}
          visible={visibleAlert}
          content={`Fuente de Financiamiento ${form.getFieldValue('nombre')}`}
          loading={loading}
        />
      </Spin>
    </Row>
  );
}

export default FuenteDeFinanciamiento;
