import React from 'react';
import SingleForm from 'components/SingleForm';
import { getPrioridades } from 'api/adquisiciones';

export const permissionPrioridades = {
  permissionModel: 'prioridaddelaadquisicion',
};

// eslint-disable-next-line react/prop-types
function Prioridades({ permission }) {
  return (
    <SingleForm
      title="Prioridad de operación"
      withoutState
      readOnly
      permission={permission}
      get={getPrioridades}
    />
  );
}
export default Prioridades;
