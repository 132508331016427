import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  Col,
  Form,
  Modal,
  Row,
  Spin,
  Input,
} from 'antd';

import Table from 'components/Table';
import FormSubmitControls from 'components/FormSubmitControls';
import Select from 'components/Select';
import { onError } from 'utils/handlers';
import API from 'utils/api';
import currencyFormatter from 'utils/currencyFormatter';

const baseURI = '/configuraciones/polizas/:id/detalles/';

function DetallesDePoliza({
  polizaParent,
}) {
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const [cuentasContables, setCuentasContables] = useState([]);
  const [selected, setSelected] = useState({});

  const fetchData = async () => {
    try {
      setLoading(true);
      if (polizaParent.length) {
        const [id] = polizaParent;
        const uri = baseURI.replace(':id', id);
        const response = await API.get(uri);
        setData(response.data);
        const mappedData = response.data.map((e) => e.cuenta_contable);
        const result = [];
        mappedData.forEach((item) => {
          if (!result.includes(item)) {
            result.push(item);
          }
        });
        const promises = result.map((e) => API.get(`contabilidad/cuentas-contables/${e}`));
        const responses = promises.length ? await Promise.all(promises) : [];
        const cContables = responses.map((res) => res.data);
        setCuentasContables(cContables);
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useState(() => {
    fetchData();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, [polizaParent]);

  const onCancel = () => {
    setVisible(false);
    setSelectedRowKeys([]);
    form.resetFields();
    setSelected({});
  };

  const columns = [
    {
      titleText: 'Cuenta Contable',
      dataIndex: 'cuenta_contable',
      key: 'cuenta_contable',
      width: 250,
      render: (val) => cuentasContables.find((e) => e.id === val)?.clave,
    },
    {
      titleText: 'Nombre',
      dataIndex: 'nombre',
      key: 'nombre',
      width: 200,
    },
    {
      titleText: 'Referencia',
      dataIndex: 'referencia',
      key: 'referencia',
      width: 200,
    },
    {
      titleText: 'Cargos',
      dataIndex: 'total_cargo',
      key: 'total_cargo',
      width: 200,
      align: 'right',
      render: (val) => (val > 0 ? currencyFormatter(val || 0) : '-'),
    },
    {
      titleText: 'Abonos',
      dataIndex: 'total_abono',
      key: 'total_abono',
      width: 200,
      align: 'right',
      render: (val) => (val > 0 ? currencyFormatter(val || 0) : '-'),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    form.setFieldsValue(record);
    setSelected(record);
  };

  const onClickExpand = () => {
    setVisible(true);
  };

  return (
    <Row>
      <Spin tip="Cargando..." spinning={loading}>
        <Col span={24}>
          <Table
            mobileCols={[columns[0]]}
            cols={columns}
            data={data}
            rowSelection={rowSelection}
            handleOnRowClick={handleOnRowClick}
            controls={{
              onClickExpand,
            }}
            allowExpand
          />
          <Modal
            visible={visible}
            title={(
              <FormSubmitControls
                label="Detalle de Póliza"
                onCancel={onCancel}
              />
          )}
            onCancel={onCancel}
            footer={null}
            closable={false}
            forceRender
          >
            <Form
              layout="vertical"
              form={form}
              className="without-feedback"
            >
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="Cuenta Contable"
                    name="cuenta_contable"
                  >
                    <Select
                      dataSource={cuentasContables}
                      keyLabelRender
                      labelProp="nombre"
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="Nombre"
                    name="nombre"
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="Referencia"
                    name="referencia"
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
                {selected?.total_cargo && (
                  <Col span={24}>
                    <Form.Item
                      label="Cargos"
                      name="total_cargo"
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                )}
                {selected?.total_abono && (
                  <Col span={24}>
                    <Form.Item
                      label="Abonos"
                      name="total_abono"
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                )}
                <Col span={24}>
                  <Form.Item
                    label="Estado"
                    name="estados_globales"
                  >
                    <Select
                      dataSource={estadosGlobales}
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Form.Item hidden>
                  <Button htmlType="submit" />
                </Form.Item>
              </Row>
            </Form>
          </Modal>
        </Col>
      </Spin>
    </Row>
  );
}

DetallesDePoliza.propTypes = {
  polizaParent: PropTypes.arrayOf(PropTypes.number),
};

DetallesDePoliza.defaultProps = {
  polizaParent: [],
};

export default DetallesDePoliza;
