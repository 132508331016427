import React from 'react';
import SingleForm from 'components/SingleForm';
import { getClasificacionProgramatica } from 'api/catalogos';

export const permissionClasificacionProgramatica = {
  permissionModel: 'clasificacionprogramatica',
};

// eslint-disable-next-line react/prop-types
function ClasificacionProgramatica({ permission }) {
  return (
    <SingleForm
      title="Clasificación programática"
      withoutState
      readOnly
      needsDescription={false}
      needsConcepto
      permission={permission}
      get={getClasificacionProgramatica}
    />
  );
}
export default ClasificacionProgramatica;
