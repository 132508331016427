import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Spin,
  Form,
  Input,
  Button,
  TreeSelect,
  Modal,
} from 'antd';
import FormSubmitControls from 'components/FormSubmitControls';
import API from 'utils/api';
import Table from 'components/Table';
import { onError } from 'utils/handlers';
import { toInteger } from 'utils/normalizers';

const baseURIs = [
  '/presupuestos/niveles-de-indicador/',
  '/presupuestos/tipos-de-indicador/',
  '/presupuestos/dimensiones-del-indicador/',
  '/presupuestos/frecuencias-de-medicion/',
];

function CatalogoPBR() {
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState();

  const [niveles, setNiveles] = useState([]);
  const [tipos, setTipos] = useState([]);
  const [dimensiones, setDimensiones] = useState([]);

  const onClickTitle = (e) => {
    const element = e.target;
    if (element) {
      let parent = element.parentElement;
      parent = parent ? parent.parentElement : null;
      if (parent) {
        const clickableSpan = parent.previousSibling;
        if (clickableSpan) {
          clickableSpan.click();
        }
      }
    }
  };

  const renderTitle = (title, bold) => (
    // eslint-disable-next-line
    <span onClick={onClickTitle} className={bold ? 'bold' : ''}>
      {title}
    </span>
  );

  const normalizeData = (
    _data = [],
    excludeLvl3 = false,
    currLvl = 1,
  ) => _data.map((item) => {
    const clone = { ...item };
    const children = normalizeData(!excludeLvl3
        || currLvl !== 2 ? clone.children : [], excludeLvl3, currLvl + 1);
    if (!children.length) {
      delete clone.children;
      return ({
        ...clone,
        key: `${clone.id}_${clone.nivel}_${Math.random()}`,
        title: renderTitle(clone.descripcion),
        value: `${clone.id}_${clone.nivel}_${Math.random()}`,

      });
    }
    return ({
      ...clone,
      key: `${clone.id}_${clone.nivel}_${Math.random()}`,
      children,
      title: renderTitle(clone.descripcion),
      value: `${clone.id}_${clone.nivel}_${Math.random()}`,
    });
  });

  const fetchData = async () => {
    try {
      setLoading(true);
      const promises = baseURIs.map((uri) => API.get(uri));
      const [
        _niveles,
        _tipo,
        _dimension,
        _frecuencia,
      ] = (await Promise.all(promises)).map((response) => response.data);

      const mappedDimensiones = _dimension.map((e) => ({
        ...e,
        nivel: 3,
        children: e.frecuencia_de_medicion.map((f) => ({
          ..._frecuencia.find((i) => i.id === f),
          nivel: 4,
        })),
      }));

      const mappedTipos = _tipo.map((e) => ({
        ...e,
        nivel: 2,
        children: e.dimension_del_indicador
          .map((d) => mappedDimensiones.find((i) => i.id === d)),
      }));

      const mappedNiveles = _niveles.map((e) => ({
        ...e,
        nivel: 1,
        children: e.tipo_de_indicador
          .map((t) => mappedTipos.find((i) => i.id === t)),
      }));
      setData(normalizeData(mappedNiveles));
      setNiveles(mappedNiveles);
      setTipos(mappedTipos);
      setDimensiones(mappedDimensiones);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchData();
    return () => API.tryCancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setVisible(false);
    setSelectedRowKeys([]);
    form.resetFields();
    setSelected({ selectable: true });
  };

  const handleOnRowClick = (record) => {
    const [id, lvl] = record.key.split('_').map((e) => parseInt(e, 10));
    let key = null;
    if (lvl === 2) {
      const match = niveles.find((e) => e.children.some((i) => i.id === id));
      key = `${match?.nivel}-${match?.descripcion}`;
    } else if (lvl === 3) {
      const match = tipos.find((e) => e.children.some((i) => i.id === id));
      key = `${match?.nivel}-${match?.descripcion}`;
    } else if (lvl === 4) {
      const match = dimensiones.find((e) => e.children.some((i) => i.id === id));
      key = `${match?.nivel}-${match?.descripcion}`;
    }
    setSelectedRowKeys([record.key]);
    setSelected({ ...record, father: key });
    form.setFieldsValue({ ...record, father: key });
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const columns = [
    {
      titleText: 'Descripción',
      dataIndex: 'descripcion',
      key: 'descripcion',
      width: 200,
    },
  ];

  return (
    <Row align="center" justify="center" className="container">
      <Spin tip="Cargando..." spinning={loading}>
        <Table
          cols={columns}
          data={data}
          rowSelection={rowSelection}
          handleOnRowClick={handleOnRowClick}
          rowKey="value"
          childrenProp="children"
          filterNested
          allowExpand
          controls={{ onClickExpand: () => setVisible(true) }}
          mobileColIndex={0}
        />
      </Spin>
      <Modal
        visible={visible}
        title={(
          <FormSubmitControls
            label={!selected?.selectable
              ? 'Vista Previa'
              : `${selectedRowKeys.length ? 'Editar' : 'Agregar'}`}
            onCancel={onCancel}
            loading={loading}
          />
        )}
        onCancel={onCancel}
        footer={null}
        closable={false}
        keyboard={!loading}
        maskClosable={!loading}
        forceRender
      >
        <Form
          layout="vertical"
          form={form}
          scrollToFirstError
          initialValues={{ estados_globales: 1 }}
        >
          <Row>
            {(selected?.father || !selectedRowKeys.length) && (
              <Col span={24}>
                <Form.Item
                  name="father"
                  label="Catálogo Padre"
                >
                  <TreeSelect
                    showSearch
                    treeNodeFilterProp="title"
                    style={{ width: '100%' }}
                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                    allowClear
                    disabled={!selected?.selectable || selectedRowKeys.length}
                    showArrow={!selectedRowKeys.length}
                  />
                </Form.Item>
              </Col>
            )}
            <Col span={24}>
              <Form.Item
                name="nombre_corto"
                label="Nombre Corto"
                normalize={toInteger}
              >
                <Input disabled={!!selectedRowKeys.length} allowClear />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="descripcion"
                label="Descripción"
                hasFeedback
              >
                <Input
                  disabled
                  allowClear
                />
              </Form.Item>
            </Col>
            <Form.Item name="id" hidden>
              <Input disabled />
            </Form.Item>
            <Form.Item name="nivel" hidden>
              <Input disabled />
            </Form.Item>
            <Form.Item hidden>
              <Button htmlType="submit" />
            </Form.Item>
          </Row>
        </Form>
      </Modal>
    </Row>
  );
}

export default CatalogoPBR;
