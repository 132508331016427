import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Form,
  Button,
  Row,
  Col,
  Spin,
  Input,
  Card,
  DatePicker,
  message,
} from 'antd';

import { FilePdfOutlined, SearchOutlined, FileExcelOutlined } from '@ant-design/icons';

import Table from 'components/Table';
import FormSubmitControls from 'components/FormSubmitControls';
import API, { baseURL } from 'utils/api';
import {
  onError,
} from 'utils/handlers';
import Select from 'components/Select';
import currencyFormater from 'utils/currencyFormatter';
import { formatReceived } from 'utils/formatValues';

const { RangePicker } = DatePicker;

const baseURI = 'contabilidad/libro-diario/';
function LibrosMayores() {
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  const [form] = Form.useForm();
  const [formBusqueda] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState([]);

  const [tiposDePoliza, setTiposDePoliza] = useState([]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const resTipos = await API.get('configuraciones/tipos-de-polizas/');
      setTiposDePoliza(resTipos.data);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchData();

    return () => API.source.cancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setVisible(false);
    setSelectedRowKeys([]);
    form.resetFields();
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.key]);
    form.setFieldsValue(record);
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const columns = [
    {
      titleText: 'Descripción',
      dataIndex: 'descripcion',
      key: 'descripcion',
      width: 400,
    },
    {
      titleText: 'Debe',
      dataIndex: 'debe',
      key: 'debe',
      width: 150,
      align: 'right',
      render: (v) => currencyFormater(v || 0),
    },
    {
      titleText: 'Haber',
      dataIndex: 'haber',
      key: 'haber',
      width: 150,
      align: 'right',
      render: (v) => currencyFormater(v || 0),
    },
    {
      titleText: 'Saldo',
      dataIndex: 'saldo',
      key: 'saldo',
      width: 150,
      align: 'right',
      render: (v) => currencyFormater(v || 0),
    },
    {
      titleText: 'Fecha',
      dataIndex: 'fecha',
      key: 'fecha',
      width: 200,
      render: (e) => e?.format('DD/MM/YYYY'),
    },
    {
      titleText: 'Número de Evento',
      dataIndex: 'numero_de_evento',
      key: 'numero_de_evento',
      width: 200,
    },
  ];

  const required = {
    message: 'El campo es requerido',
    required: true,
  };

  const rules = {
    required: [required],
  };

  const fetch = async () => {
    try {
      setLoading(true);
      await formBusqueda.validateFields();
      const values = formBusqueda.getFieldsValue();
      values.fecha_inicial = values.periodos[0].format('DD-MM-YYYY');
      values.fecha_final = values.periodos[1].format('DD-MM-YYYY');
      delete values.periodos;

      const response = await API.post(baseURI, values);
      if (response?.status === 200 && response.data.length) {
        const formattedData = formatReceived(response.data);
        setData(formattedData.map((e, i) => {
          delete e.id;
          return ({ ...e, key: i + 1 });
        }));
      } else {
        message.info('No se encontraron coincidencias');
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onClickPreviewPDF = async () => {
    try {
      setLoading(true);
      await formBusqueda.validateFields();
      const values = formBusqueda.getFieldsValue();
      values.fecha_inicial = values.periodos[0].format('DD-MM-YYYY');
      values.fecha_final = values.periodos[1].format('DD-MM-YYYY');
      delete values.periodos;
      const res = await API.post(`${baseURI}request-download/`, values);
      if (res?.status === 200 && res.data) {
        const { token } = res.data;
        window.open(`${baseURL}contabilidad/libro-diario/pdf/?token=${token}`, '_blank');
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const downloadExcel = async () => {
    try {
      setLoading(true);
      await formBusqueda.validateFields();
      const values = formBusqueda.getFieldsValue();
      values.fecha_inicial = values.periodos[0].format('DD-MM-YYYY');
      values.fecha_final = values.periodos[1].format('DD-MM-YYYY');
      delete values.periodos;
      const res = await API.post(`${baseURI}request-download/`, values);
      if (res?.status === 200 && res.data) {
        const { token } = res.data;
        window.open(`${baseURL}contabilidad/libro-diario/excel/?token=${token}`, '_blank');
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onClickExpand = () => setVisible(true);

  return (
    <Row align="center" justify="center" className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible ? (
          <>
            <Form
              form={formBusqueda}
              layout="vertical"
              onFinish={fetch}
            >
              <Row gutter={10}>
                <Col xs={24} sm={24} md={10}>
                  <Form.Item
                    label="Tipo de Póliza"
                    rules={rules.required}
                    name="tipo_de_poliza"
                  >
                    <Select dataSource={tiposDePoliza} labelProp="nombre" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={10}>
                  <Form.Item
                    label="Periodo"
                    rules={rules.required}
                    name="periodos"
                  >
                    <RangePicker placeholder="" format="DD/MM/YYYY" />
                  </Form.Item>
                </Col>
                <Col style={{ display: 'flex', alignItems: 'center' }}>
                  <Button
                    onClick={fetch}
                    icon={<SearchOutlined />}
                  >
                    Buscar
                  </Button>
                </Col>
              </Row>
              <Form.Item hidden>
                <Button htmlType="submit" />
              </Form.Item>
            </Form>
            <Table
              cols={columns}
              data={data}
              rowSelection={rowSelection}
              mobileColIndex={0}
              handleOnRowClick={handleOnRowClick}
              controls={{
                onClickExpand,
              }}
              customActions={[
                {
                  onClick: onClickPreviewPDF,
                  icon: <FilePdfOutlined />,
                  text: 'Descargar PDF',
                  disabledBySelection: false,
                },
                {
                  onClick: downloadExcel,
                  icon: <FileExcelOutlined />,
                  text: 'Descargar Excel',
                  disabledBySelection: false,
                },
              ]}
              rowKey="key"
              allowExpand
            />
          </>
        ) : (
          <Col span={24} style={{ height: '100%' }}>
            <Card
              className="form-container"
              title={(
                <FormSubmitControls
                  label={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Reserva`}
                  onCancel={onCancel}

                />
              )}
              bordered={false}
            >
              <Form
                layout="vertical"
                form={form}
                scrollToFirstError
                className="without-feedback"
                initialValues={{ estados_globales: 1 }}
              >
                <Row gutter={10}>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="fecha_inicial"
                      label="Fecha de la Solicitud"
                    >
                      <DatePicker disabled format="DD/MM/YYYY" placeholder="" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="fecha_final"
                      label="Fecha de Vencimiento"
                    >
                      <DatePicker format="DD/MM/YYYY" placeholder="" disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="saldo_inicial"
                      label="Saldo Inicial"
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="saldo_final"
                      label="Saldo Final"
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="cargo"
                      label="Cargo"
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="abono"
                      label="Abono"
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="saldo"
                      label="Saldo"
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="estados_globales"
                      label="Estado"
                    >
                      <Select dataSource={estadosGlobales} disabled />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item hidden>
                  <Button htmlType="submit" />
                </Form.Item>
              </Form>
            </Card>
          </Col>
        )}
      </Spin>
    </Row>
  );
}

export default LibrosMayores;
