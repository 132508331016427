import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Form,
  Button,
  Row,
  Col,
  Spin,
  Input,
  Card,
  Typography,
  DatePicker,
  message,
} from 'antd';

import Table from 'components/Table';
import FormSubmitControls from 'components/FormSubmitControls';
import API from 'utils/api';
import { onError, onSuccess } from 'utils/handlers';
import ModalDelete from 'components/ModalDelete';
import Select from 'components/Select';
import getFormattedValues, { formatReceived } from 'utils/formatValues';
import InputSearch from 'components/InputSearch';
import NumericInput from 'components/NumericInput';
import { createDecimalRegExp } from 'utils/patterns';
import currencyFormater from 'utils/currencyFormatter';
import CopiasReservas from './CopiarReservas';

const { Text } = Typography;

const baseURI = '/presupuestos/reservas-presupuestales-de-egresos/';
function Reservas() {
  const estadosGlobales = useSelector(({ catalogs }) => catalogs.estadosGlobales);
  const monedaDefault = useSelector(({ auth }) => auth.entidad.moneda);
  const [decimal] = createDecimalRegExp(monedaDefault);
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [data, setData] = useState([]);

  const [tiposDeReservas, setTiposDeReservas] = useState([]);
  const [solicitante, setSolicitante] = useState([]);
  const [elaboro, setElaboro] = useState([]);
  const [momento, setMomento] = useState([]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await API.get(baseURI);
      setData(formatReceived(response.data) || []);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const fetchAll = async () => {
    try {
      setLoading(true);

      const resTipos = await API.get('/configuraciones/catalogos-de-tipos-de-reserva/');
      setTiposDeReservas(resTipos.data || []);

      // const resElaboro = await API.get('/estructura-organizacional/empleados/');
      // setElaboro([resElaboro.data]);

      const resMomento = await API.get('/catalogos/momentos-presupuestales-de-ingresos/');
      setMomento(resMomento.data);
      await fetchData();
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  useEffect(() => {
    fetchAll();

    return () => API.source.cancel;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setVisible(false);
    setSelectedRowKeys([]);
    form.resetFields();
    setSolicitante([]);
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      const formatedValues = getFormattedValues(values);
      if (!selectedRowKeys.length) {
        const response = await API.post(baseURI, formatedValues);
        if (response?.status === 201) {
          onSuccess(response, 'Agregado correctamente');
        }
      } else {
        const [key] = selectedRowKeys;
        const response = await API.put(`${baseURI}${key}/`, formatedValues);
        if (response?.status === 200) {
          onSuccess(response, 'Actualizado correctamente');
        }
      }

      await fetchData();
      onCancel();

      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const deleteItem = async () => {
    try {
      setLoading(true);
      if (selectedRowKeys.length) {
        const [key] = selectedRowKeys;
        const response = await API.delete(`${baseURI}${key}/`);
        if (response && response.status === 204) {
          onSuccess(response, 'Eliminado correctamente', 2.5);
          onCancel();
          await fetchData();
          setVisibleAlert(false);
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const handleOnRowClick = (record) => {
    setSelectedRowKeys([record.id]);
    form.setFieldsValue(record);
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };
  const onClickEdit = async () => {
    try {
      setLoading(true);
      const [key] = selectedRowKeys;
      const clone = [...data];
      const match = clone.find((e) => e.id === key);
      const response = await API.get(`/estructura-organizacional/empleados/${match.solicitante}`);
      setSolicitante([response.data]);
      const resElaboro = await API.get(`/estructura-organizacional/empleados/${match.elaboro}`);
      setElaboro([resElaboro.data]);
      setVisible(true);
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const columns = [
    {
      titleText: 'No. Reserva',
      dataIndex: 'numero_reserva',
      key: 'numero_reserva',
      width: 250,
    },
    {
      titleText: 'Fecha De Solicitud',
      dataIndex: 'fecha_de_solicitud',
      key: 'fecha_de_solicitud',
      width: 250,
      render: (val) => val?.format('DD/MM/YYYY'),
    },
    {
      titleText: 'Folio Agrupador',
      dataIndex: 'folio_agrupador',
      key: 'folio_agrupador',
      width: 250,
    },
    {
      titleText: 'Elaboró',
      dataIndex: 'elaboro',
      key: 'elaboro',
      width: 250,
    },
    {
      titleText: 'Solicitante',
      dataIndex: 'solicitante',
      key: 'solicitante',
      width: 250,
    },
    {
      titleText: 'Puesto',
      dataIndex: 'puesto',
      key: 'puesto',
      width: 250,
    },
    {
      titleText: 'Unidad Responsable',
      dataIndex: 'unidad_responsable',
      key: 'unidad_responsable',
      width: 250,
    },
    {
      titleText: 'Tipo de Reserva',
      dataIndex: 'tipo_de_reserva',
      key: 'tipo_de_reserva',
      width: 250,
    },
    {
      titleText: 'Concepto',
      dataIndex: 'concepto',
      key: 'concepto',
      width: 250,
    },
    {
      titleText: 'Monto Reservado',
      dataIndex: 'monto_reservado',
      key: 'monto_reservado',
      width: 250,
      render: (val) => currencyFormater(val),
      align: 'right',
    },
    {
      titleText: 'Fecha de Vencimiento',
      dataIndex: 'fecha_de_vencimiento',
      key: 'fecha_de_vencimiento',
      width: 250,
      render: (val) => val?.format('DD/MM/YYYY'),
    },
    {
      titleText: 'Estado',
      key: 'estados_globales',
      dataIndex: 'estados_globales',
      render: (val) => estadosGlobales.find((e) => e.id === val)?.descripcion,
      width: 250,
    },
    {
      titleText: 'Fecha de Aplicación',
      dataIndex: 'fecha_de_aplicacion',
      key: 'fecha_de_aplicacion',
      width: 250,
    },
    {
      titleText: 'Documento Aplicación',
      dataIndex: 'documento_aplicacion',
      key: 'documento_aplicacion',
      width: 250,
    },
  ];

  const required = {
    required: true,
    message: 'El campo es Requerido',
  };

  const rules = {
    required: [required],
    monto: [
      required,
      {
        validator: async (rule, val) => {
          if (val && !decimal.test(val)) {
            throw new Error('Ingrese un valor decimal válido');
          }
        },
      },
    ],
  };

  const handleSearch = async () => {
    try {
      setLoading(true);
      await form.validateFields(['q']);
      const { q } = form.getFieldsValue(['q']);
      if (q) {
        const res = await API.get('/estructura-organizacional/empleados/', {
          params: {
            numero_de_empleado: q,
          },
        });
        if (res.status === 200) {
          const { data: resData } = res;
          if (resData.length === 1) {
            const [selected] = resData;
            form.setFieldsValue({
              solicitante: selected.id,
            });
            setSolicitante([selected]);
          } else {
            message.info('No se encontró algún empleado relacionado.');
          }
        }
      }
      setLoading(false);
    } catch (err) {
      onError(err, setLoading);
    }
  };

  const labelRender = (e) => (`
  ${e.correo_electronico || e.numero_de_empleado}
  - ${e.nombre} ${e.apellido_paterno} ${e.apellido_materno || ''}
  `);

  return (
    <Row align="center" justify="center" className="container">
      <Spin tip="Cargando..." spinning={loading}>
        {!visible ? (
          <Table
            cols={columns}
            data={data}
            rowSelection={rowSelection}
            rowKey="id"
            mobileColIndex={0}
            handleOnRowClick={handleOnRowClick}
            controls={{
              onClickAdd,
              onClickEdit,
              onClickDelete,
            }}
          />
        ) : (
          <Col span={24} style={{ height: '100%' }}>
            <Card
              className="form-container"
              title={(
                <FormSubmitControls
                  label={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Reserva`}
                  onFinish={onFinish}
                  onCancel={onCancel}
                />
              )}
              bordered={false}
            >
              <Form
                layout="vertical"
                form={form}
                scrollToFirstError
                onFinish={onFinish}
                initialValues={{ estados_globales: 1 }}
              >
                <Row gutter={10}>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="numero_reserva"
                      label="No. Reserva"
                    >
                      <Input allowClear />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="fecha_de_solicitud"
                      rules={rules.required}
                      label="Fecha de Solicitud"
                      hasFeedback
                    >
                      <DatePicker format="DD/MM/YYYY" placeholder="" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="folio_agrupador"
                      label="Folio Agrupador"
                    >
                      <Input allowClear disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    {!elaboro.length ? (
                      <InputSearch
                        label="Elaboró"
                        onPressEnter={handleSearch}
                        rules={rules.required}
                      />
                    ) : (
                      <Form.Item
                        label="Elaboro"
                        name="elaboro"
                      >
                        <Select
                          rules={rules.required}
                          dataSource={elaboro}
                          onChange={(val) => {
                            if (!val) {
                              setElaboro([]);
                              form.resetFields(['q']);
                            }
                          }}
                          render={labelRender}
                        />
                      </Form.Item>
                    )}
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    {!solicitante.length ? (
                      <InputSearch
                        label="Solicitante"
                        onPressEnter={handleSearch}
                        rules={rules.required}
                      />
                    ) : (
                      <Form.Item
                        label="Solicitante"
                        name="solicitante"
                      >
                        <Select
                          rules={rules.required}
                          dataSource={solicitante}
                          onChange={(val) => {
                            if (!val) {
                              setSolicitante([]);
                              form.resetFields(['q']);
                            }
                          }}
                          render={labelRender}
                        />
                      </Form.Item>
                    )}
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="puesto"
                      label="Puesto"
                    >
                      <Input allowClear disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="unidad_responsable"
                      label="Unidad Responsable"
                    >
                      <Input allowClear />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="tipo_de_reserva"
                      rules={rules.required}
                      label="Tipo de Reserva"
                      hasFeedback
                    >
                      <Select dataSource={tiposDeReservas} labelProp="concepto" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="concepto"
                      label="Concepto"
                    >
                      <Input allowClear disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <NumericInput
                      name="monto_reservado"
                      label="Monto"
                    />
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="momento"
                      label="Momento Presupuestal"
                    >
                      <Select dataSource={momento} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="fecha_vencimiento"
                      rules={rules.required}
                      label="Fecha de Vencimiento"
                      hasFeedback
                    >
                      <DatePicker format="DD/MM/YYYY" placeholder="" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="jusitificacion"
                      label="Justificación"
                    >
                      <Input allowClear disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="estados_globales"
                      rules={rules.required}
                      label="Estado"
                      hasFeedback
                    >
                      <Select dataSource={estadosGlobales} disabled={!selectedRowKeys.length} />
                    </Form.Item>
                  </Col>
                  <CopiasReservas />
                </Row>
                <Form.Item hidden>
                  <Button htmlType="submit" />
                </Form.Item>
              </Form>
            </Card>
          </Col>
        )}
        <ModalDelete
          onDelete={deleteItem}
          onCancel={() => setVisibleAlert(false)}
          visible={visibleAlert}
          content={(
            <Text style={{ fontSize: 16 }}>
              ¿Desea eliminar el presupuesto anual de ingreso seleccionado?
            </Text>
          )}
        />
      </Spin>
    </Row>
  );
}

export default Reservas;
